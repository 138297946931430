import type { ViewType } from '@lighthouse/core'
import React, { createContext, useContext, useMemo } from 'react'

export type ActionAdderContextValue = {
    dsId: string
    isForm?: boolean
    viewType?: ViewType
} | null

export const ActionAdderContext = createContext<ActionAdderContextValue>(null)

export const ActionAdderProvider = ({
    dsId,
    isForm,
    viewType,
    children
}: {
    dsId: string
    isForm?: boolean
    viewType?: ViewType
    children: React.ReactNode
}) => {
    const ctxValue = useMemo(() => ({ dsId, isForm, viewType }), [dsId, isForm, viewType])
    return <ActionAdderContext.Provider value={ctxValue}>{children}</ActionAdderContext.Provider>
}

export const useActionAdderContext = () => useContext(ActionAdderContext)

ActionAdderContext.displayName = 'ActionAdderContext'

