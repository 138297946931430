import { useCustomViewBlockContext } from '@lighthouse/block'
import type { RecordLikeProtocol, RichTextContentProtocol } from '@lighthouse/core'
import { generateText } from '@lighthouse/shared'
import { useCallback } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'

import { usePageDataSourceForVariableSelector } from './usePage'
import { useVariableValueRender } from './useVariableValueRender'

export function useRichTextToTitle() {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    const { record: customViewRecord } = useCustomViewBlockContext()

    const handleRenderTitle = useCallback(
        (v: RichTextContentProtocol, record?: RecordLikeProtocol) => {
            return generateText(v, {
                variable: {
                    renderLabel: v =>
                        renderLabel(v.attrs.value, {
                            viewRecord: record || customViewRecord
                        })
                }
            })
        },
        [customViewRecord, renderLabel]
    )

    return { handleRenderTitle }
}
