export interface GetStepPathParams {
    sourceX: number
    sourceY: number
    targetX: number
    targetY: number
    roundDistance?: number
}

export interface GetStepPathResult {
    path: string
    roundX: number
    roundY: number
    firstHalfCenterY: number
    lastHalfCenterY: number
}

export const getConditionSourceStepPath = (params: GetStepPathParams): GetStepPathResult => {
    const { sourceX, sourceY, targetX, targetY, roundDistance = 30 } = params

    const arcRadius = 10

    const roundY = Number.isInteger(roundDistance) ? targetY - roundDistance : (targetY - sourceY) * (1 - roundDistance) + sourceY

    const isLeftToRight = sourceX < targetX

    const hFlag = isLeftToRight ? -1 : 1
    const sweepFlag = isLeftToRight ? 1 : 0
    const dxFlag = isLeftToRight ? 1 : -1

    const path = `M ${sourceX} ${sourceY} v ${roundY - sourceY} h ${
        targetX - sourceX + hFlag * arcRadius
    } a ${arcRadius} ${arcRadius} 0 0 ${sweepFlag} ${dxFlag * arcRadius} ${arcRadius} v ${targetY - roundY}`

    const firstHalfCenterY = (roundY - sourceY) / 2 + sourceY
    const lastHalfCenterY = (targetY - roundY) / 2 + roundY

    return { path, roundX: sourceX, roundY, firstHalfCenterY, lastHalfCenterY }
}

export const getConditionTargetStepPath = (params: GetStepPathParams): GetStepPathResult => {
    const { sourceX, sourceY, targetX, targetY, roundDistance = 30 } = params

    const arcRadius = 10

    const roundY = Number.isInteger(roundDistance) ? targetY - roundDistance : (targetY - sourceY) * (1 - roundDistance) + sourceY

    const isLeftToRight = sourceX < targetX

    const hFlag = isLeftToRight ? -1 : 1
    const sweepFlag = isLeftToRight ? 0 : 1
    const dxFlag = isLeftToRight ? 1 : -1

    const path = `M ${sourceX} ${sourceY} v ${roundY - sourceY} a ${arcRadius} ${arcRadius} 0 0 ${sweepFlag} ${
        dxFlag * arcRadius
    } ${arcRadius} h ${targetX - sourceX + hFlag * arcRadius}  v ${targetY - roundY}`

    const firstHalfCenterY = (roundY - sourceY) / 2 + sourceY
    const lastHalfCenterY = (targetY - roundY) / 2 + roundY

    return { path, roundX: sourceX, roundY, firstHalfCenterY, lastHalfCenterY }
}
