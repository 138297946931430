import { flex } from '@byecode/ui'
import { useAtomAction, useAtomAsyncAction } from '@lighthouse/shared'
import React, { useEffect, useMemo } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { addRecordAtom } from '@/atoms/dataSource/action'
import { fetchPageAtom } from '@/atoms/page/action'
import { updateWorkflowListAtom } from '@/atoms/workflow/action'
import SettingAuthentication from '@/components/ApplicationSetting/SettingAuthentication'
import SettingDrawer from '@/components/ApplicationSetting/SettingDrawer'
import { ModuleType } from '@/components/ApplicationSetting/type'
import { useAppLayoutMode } from '@/hooks/useApplication'
import * as srv from '@/services'

import AccountPage from '../PageDetail/AccountPage'
import { PageLayout } from '../PageDetail/PageLayout'
import { PageLeftPanel } from './LeftPanel'

const Container = styled.div`
    position: relative;
    ${flex};
    height: 100%;
    display: flex;
    overflow: hidden;
`

const Content = styled.div`
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

interface PagePathParams {
    rootPageId?: string
    appId?: string
    envId?: string
    dsId?: string
    recordId?: string
    pageId: string
    stackId: string
}

const PageMain = ({ rootPageId, pageId, stackId, appId, envId, dsId, recordId }: PagePathParams) => {
    const { run: addRecord } = useAtomAction(addRecordAtom)

    const { value: pageRes, run: fetchPage, loading } = useAtomAsyncAction(fetchPageAtom)

    useEffect(() => {
        fetchPage({ pageId, rootPageId: rootPageId || pageId, stackId })
    }, [fetchPage, pageId, rootPageId, stackId])

    useAsyncRetry(async () => {
        if (appId && envId && dsId && recordId) {
            const { datasource, record } = await srv.getRecord({ appId, dsId, envId, recordId })
            addRecord({ records: [record] })
            return { dataSource: datasource, record }
        }
        return null
    }, [pageRes?.dsId, pageId, appId, dsId, recordId])

    return <PageLayout pageId={pageId} stackId={stackId} loading={!pageRes || loading || pageRes.id !== pageId} />
}

export const Page: React.FC<PagePathParams> = props => {
    const appMode = useAppLayoutMode()

    const { run: updateWorkflowList } = useAtomAction(updateWorkflowListAtom)

    useEffect(() => {
        updateWorkflowList()
    }, [updateWorkflowList])

    const isShowLoginPage = appMode.type === 'setting' && appMode.currentTab === ModuleType.login

    return (
        <Container>
            <Content style={{ width: isShowLoginPage ? 'calc(100% - 560px)' : 'auto' }}>
                <PageLeftPanel />
                {isShowLoginPage ? <AccountPage /> : <PageMain {...props} />}
            </Content>

            {isShowLoginPage && <SettingAuthentication />}
        </Container>
    )
}
