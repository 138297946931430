import { ColorPicker, Popover } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface PatchesProps {
    value: string
    onChange: (value: string) => void
}

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray-100);
    height: 32px;
    width: 32px;
    border-radius: 6px;
`

const SCxPatches = styled.div<{ background?: string }>`
    width: 20px;
    height: 20px;
    background: ${props => props.background};
    border-radius: 4px;
`

export const Patches: React.FC<PatchesProps> = ({ value, onChange }) => {
    return (
        <Popover width={262}>
            <Popover.Target>
                <SCxContainer>
                    <SCxPatches background={value} />
                </SCxContainer>
            </Popover.Target>
            <Popover.Dropdown>
                <ColorPicker value={value} onChange={onChange} />
            </Popover.Dropdown>
        </Popover>
    )
}
