import React from 'react'

import { useWorkSpace } from '@/hooks/useWorkSpace'

import { Member } from './Member'

export const SpaceMember: React.FC = () => {
    const workspace = useWorkSpace()

    return <Member workspace={workspace} />
}
