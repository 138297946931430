import { Divider, Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import {
    CollapseBox,
    getFormDatasourceOptions,
    getIsIncludesDisableDataSourceInWorkFlow,
    getTableIcon,
    nodeTypeOptions,
    SelectDataSource,
    singleRecordNodeType
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { SettingRecordContent } from '../../../SettingRecordContent'
import * as SC from '../../styles'

interface CreateSingleRecordActionConfigureProps {
    prefixName?: string
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const CreateSingleRecordActionConfigure: React.FC<CreateSingleRecordActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const listDs = useDataSourceList(appId, envId)
    const { control, setValue } = useFormContext()
    const { type } = useFlow()
    const isInWorkflow = !!type
    const selectDatasourceList = useMemo(
        () =>
            listDs.reduce<DataSourceAbstract[]>((listDs, item) => {
                const tableIcon = getTableIcon(item)
                if (!getIsIncludesDisableDataSourceInWorkFlow(item)) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [listDs]
    )

    const allSingleParentNodes = useMemo(
        () => allParentNodes.filter(item => singleRecordNodeType.has(item.data.nodeType)),
        [allParentNodes]
    )

    const dataSourceId: string = useWatch({
        control,
        name: `${prefixName}.dataSourceId`
    })

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>选择记录所在的数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.dataSourceId`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <SelectDataSource
                                            {...field}
                                            onChange={async dsId => {
                                                if(dsId === field.value){
                                                    return
                                                }
                                                const isConfirmed =
                                                    field.value === undefined || !isInWorkflow ? true : await promptSwitchDataSource()
                                                if (isConfirmed) {
                                                    field.onChange(dsId)
                                                    setValue(`${prefixName}.fields`, [])
                                                }
                                            }}
                                            searchable
                                            placeholder="请选择数据表"
                                            // disabled={!!dataSourceId}
                                            value={dataSourceId}
                                            dataSourceList={selectDatasourceList}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            {dataSourceId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="设置记录内容">
                        <SC.Content>
                            <SettingRecordContent
                                allParentNodes={allSingleParentNodes}
                                dataSourceId={dataSourceId}
                                prefixName={prefixName}
                            />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </SC.Container>
    )
}
