import type { WorkflowType } from '@lighthouse/shared'

export const workflowLabelMap: Record<WorkflowType, { label: string; icon: string; color: string }> = {
    automation: { label: '自动化', icon: 'FlowArrow', color: 'var(--color-green-500)' },
    action: { label: '动作流', icon: 'MouseClick', color: 'var(--color-blue-500)' },
    approval: { label: '审批流', icon: 'Approval', color: 'var(--color-purple-500)' }
}

export const workflowOptions: { value: WorkflowType; label: string; icon: string; color: string }[] = [
    { value: 'automation', label: '新建自动化', icon: 'FlowArrow', color: 'var(--color-green-500)' },
    { value: 'action', label: '新建动作流', icon: 'MouseClick', color: 'var(--color-blue-500)' }
    // { value: 'approval', label: '新建审批流', icon: 'Approval', color: 'var(--color-purple-500)' }
]

export const systemOperatorOptions = [{ value: 'committer', label: '流程发起人' }]
