import { Flex, Text } from '@byecode/ui'
import { spaceVersionEnum } from '@lighthouse/shared'
import React from 'react'

import { SpaceGradeTag } from '@/components/SpaceGrade'
import type { AllOtherPlatform, AppAccountType } from '@/services/types'

import * as CM from './styles'

export const otherPlatformNameMap: Record<AppAccountType, string> = {
    DING_TALK_ROBOT: '钉钉自定义机器人',
    WE_COM_ROBOT: '企业微信机器人',
    WE_COM_MAIL: '腾讯企业邮',
    ICLICK: 'iClick',
    ALIYUN_SMS: '阿里云短信',
    NET_EASE_126_MAIL: '126网易免费邮',
    WECHAT_OFFICIAL_ACCOUNT: '微信公众号',
    WECHAT_WEBSITE: '微信网站应用',
    WECHAT_PAY: '微信支付商户号',
    WE_COM_INTERNAL_APP: '企业微信内部应用',
    STRIPE: 'Stripe'
}

export const otherPlatformIconMap: Record<AppAccountType, string> = {
    DING_TALK_ROBOT: 'BrandLogoDingding',
    WE_COM_ROBOT: 'BrandLogoqiyeweixin',
    WE_COM_MAIL: 'BrandLogoWeCom',
    ICLICK: 'BrandLogoIClick',
    ALIYUN_SMS: 'aliyun',
    NET_EASE_126_MAIL: 'BrandLogoWeCom',
    WECHAT_OFFICIAL_ACCOUNT: 'WeChatLogoIconLibrary',
    WECHAT_WEBSITE: 'WeChatLogoIconLibrary',
    WECHAT_PAY: 'WechatPay',
    WE_COM_INTERNAL_APP: 'BrandLogoqiyeweixin',
    STRIPE: 'Stripe'
}

export const otherPlatformColorMap: Record<AppAccountType, string> = {
    DING_TALK_ROBOT: 'var(--color-blue-500)',
    WE_COM_ROBOT: 'var(--color-blue-500)',
    WE_COM_MAIL: 'var(--color-blue-500)',
    ICLICK: 'var(--color-orange-500)',
    ALIYUN_SMS: 'var(--color-blue-500)',
    NET_EASE_126_MAIL: 'var(--color-green-500)',
    WECHAT_OFFICIAL_ACCOUNT: 'var(--color-green-500)',
    WECHAT_WEBSITE: 'var(--color-green-500)',
    WECHAT_PAY: 'var(--color-green-500)',
    WE_COM_INTERNAL_APP: 'var(--color-green-500)',
    STRIPE: ''
}

export const OTHER_PLATFORMS_OPTIONS: { value: AppAccountType; label: React.ReactNode; icon: React.ReactNode }[] = [
    {
        value: 'WE_COM_ROBOT',
        label: (
            <Flex gap={8} alignItems="center">
                <Text> {otherPlatformNameMap['WE_COM_ROBOT']}</Text>
                <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />
            </Flex>
        ),
        icon: <CM.Icon type={otherPlatformIconMap['WE_COM_ROBOT']} />
    },
    {
        value: 'DING_TALK_ROBOT',
        label: (
            <Flex gap={8} alignItems="center">
                <Text> {otherPlatformNameMap['DING_TALK_ROBOT']}</Text>
                <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />
            </Flex>
        ),
        icon: <CM.Icon type={otherPlatformIconMap['DING_TALK_ROBOT']} />
    },
    {
        value: 'WE_COM_MAIL',
        label: otherPlatformNameMap['WE_COM_MAIL'],
        icon: <CM.Icon color="var(--color-blue-500)" type={otherPlatformIconMap['WE_COM_MAIL']} />
    },
    {
        value: 'ICLICK',
        label: otherPlatformNameMap['ICLICK'],
        icon: <CM.Icon color="var(--color-orange-500)" type={otherPlatformIconMap['ICLICK']} />
    },
    {
        value: 'ALIYUN_SMS',
        label: otherPlatformNameMap['ALIYUN_SMS'],
        icon: <CM.Icon color="var(--color-blue-500)" type={otherPlatformIconMap['ALIYUN_SMS']} />
    },
    {
        value: 'NET_EASE_126_MAIL',
        label: otherPlatformNameMap['NET_EASE_126_MAIL'],
        icon: <CM.Icon color="var(--color-green-500)" type={otherPlatformIconMap['NET_EASE_126_MAIL']} />
    },
    {
        value: 'WECHAT_OFFICIAL_ACCOUNT',
        label: otherPlatformNameMap['WECHAT_OFFICIAL_ACCOUNT'],
        icon: <CM.Icon color="var(--color-green-500)" type={otherPlatformIconMap['WECHAT_OFFICIAL_ACCOUNT']} />
    },
    // {
    //     value: 'WECHAT_WEBSITE',
    //     label: otherPlatformNameMap['WECHAT_WEBSITE'],
    //     icon: <CM.Icon color="var(--color-green-500)" type={otherPlatformIconMap['WECHAT_WEBSITE']} />
    // },
    {
        value: 'WECHAT_PAY',
        label: (
            <Flex gap={8} alignItems="center">
                <Text> {otherPlatformNameMap['WECHAT_PAY']}</Text>
                <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />
            </Flex>
        ),
        icon: <CM.Icon color="var(--color-green-500)" type={otherPlatformIconMap['WECHAT_PAY']} />
    },
    {
        value: 'STRIPE',
        label: (
            <Flex gap={8} alignItems="center">
                <Text> {otherPlatformNameMap['STRIPE']}</Text>
                <SpaceGradeTag value={spaceVersionEnum.ADVANCED} />
            </Flex>
        ),
        icon: <CM.Icon type={otherPlatformIconMap['STRIPE']} />
    }
    // {
    //     value: 'WE_COM_INTERNAL_APP',
    //     label: otherPlatformNameMap['WE_COM_INTERNAL_APP'],
    //     icon: <CM.Icon color="var(--color-green-500)" type={otherPlatformIconMap['WE_COM_INTERNAL_APP']} />
    // }
]

export const NONE_TEST_TYPES: AppAccountType[] = ['ICLICK', 'WECHAT_WEBSITE']

export const WECHAT_TYPES: AppAccountType[] = ['WECHAT_OFFICIAL_ACCOUNT', 'WECHAT_WEBSITE', 'WECHAT_PAY', 'STRIPE']

export const SPACE_GRADE_TYPES: AppAccountType[] = ['WECHAT_PAY', 'DING_TALK_ROBOT', 'WE_COM_ROBOT', 'STRIPE']
