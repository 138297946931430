import { Flex, Text } from '@byecode/ui'
import type { ApplicationSettingSEO, ApplicationWebsiteSetting } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { SearchEngineQueue, SearchEngineTag } from '@/components/SearchEngine'
import { SearchEngineQueueTable } from '@/components/SearchEngine/SearchEngineQueue/SearchEngineQueueTable'
import { useApplicationSetting } from '@/hooks/useApplication'
import { PageSubmissionSearchEngineEnum } from '@/services/types'

import SettingBlock from '../SettingBlock'
import { SettingCard } from '../SettingCard'
import { SettingItem } from '../SettingItem'
import * as CM from '../styles'

interface SettingSEOProps {}

const SCxContainer = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);
`

const SettingSEO: React.FC<SettingSEOProps> = () => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)

    const methods = useForm<ApplicationSettingSEO>({
        mode: 'onChange',
        defaultValues: appSetting?.seo
    })
    const { watch, control, reset } = methods

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingSEO) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        seo: value
                    }
                })
            }),
        [updateAppSetting]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingSEO)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <SCxContainer>
            <SettingCard title="提交页面给搜索引擎">
                <SettingItem label="搜索引擎管理">
                    <Flex style={{ marginTop: 4 }} direction="column" gap={16}>
                        <Text lineHeight="22px" size={14} color="var(--color-gray-400)">
                            页面链接将会排队提交给搜索引擎
                        </Text>
                        <div style={{ padding: '12px 0' }}>
                            <Controller
                                control={control}
                                name="baidu"
                                render={({ field }) => {
                                    return (
                                        <SearchEngineTag
                                            type={PageSubmissionSearchEngineEnum.BAIDU}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </div>
                        {/* <Controller
                            control={control}
                            name="bing"
                            render={({ field }) => {
                                return (
                                    <SearchEngineTag
                                        type={PageSubmissionSearchEngineEnum.BING}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                        <Controller
                            control={control}
                            name="google"
                            render={({ field }) => {
                                return (
                                    <SearchEngineTag
                                        type={PageSubmissionSearchEngineEnum.CHROME}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        /> */}
                    </Flex>
                </SettingItem>
                <SearchEngineQueueTable style={{ padding: 0, marginTop: 16 }} />
            </SettingCard>
        </SCxContainer>
    )
}

export default SettingSEO
