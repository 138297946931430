import type { ColumnType } from '@byecode/ui'
import { Table } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const data = [
    {
        id: 1,
        name: '是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服',
        sex: '男',
        age: 25,
        level: 'z2'
    },
    {
        id: 2,
        name: 'asf地方',
        sex: '女',
        age: 78,
        level: 'z4',
        children: [
            {
                id: 21,
                name: '但是公司',
                sex: '男',
                age: 24,
                level: 'z1'
            },
            {
                id: 22,
                name: '对方回合',
                sex: '女',
                age: 43,
                level: 'z2'
            },
            {
                id: 23,
                name: '从v从v发',
                sex: '男',
                age: 46,
                level: 'z2'
            }
        ]
    },
    {
        id: 3,
        name: '广东省刚刚',
        sex: '男',
        age: 24,
        level: 'z1'
    },
    {
        id: 4,
        name: '发广告官方',
        sex: '女',
        age: 43,
        level: 'z2',
        children: [
            {
                id: 41,
                name: '反对反对',
                sex: '女',
                age: 43,
                level: 'z2'
            },
            {
                id: 42,
                name: '建行工行',
                sex: '男',
                age: 46,
                level: 'z2',
                children: [
                    {
                        id: 421,
                        name: '对方的',
                        sex: '男',
                        age: 46,
                        level: 'z2'
                    },
                    {
                        id: 422,
                        name: '该方法',
                        sex: '男',
                        age: 35,
                        level: 'z3'
                    },
                    {
                        id: 423,
                        name: '嘎嘎嘎',
                        sex: '女',
                        age: 46,
                        level: 'z3'
                    }
                ]
            },
            {
                id: 43,
                name: '给贵方贵方',
                sex: '男',
                age: 35,
                level: 'z3'
            }
        ]
    },
    {
        id: 5,
        name: '地方很多',
        sex: '男',
        age: 46,
        level: 'z2'
    },
    {
        id: 6,
        name: '邓',
        sex: '男',
        age: 35,
        level: 'z3'
    },
    {
        id: 7,
        name: 'v吧v北国风光',
        sex: '女',
        age: 46,
        level: 'z3'
    }
]

const columns: ColumnType<typeof data[number]>[] = [
    {
        title: '姓名',
        key: 'name'
        // width: 320
    },
    {
        title: '性别',
        key: 'sex'
    },
    {
        title: '年龄',
        key: 'age'
    },
    {
        title: '职级',
        key: 'level'
    }
]

export const Tree: React.FC = () => {
    return (
        <Unit title="树形结构">
            <Table data={data} columns={columns} rowKey="id" />
        </Unit>
    )
}
