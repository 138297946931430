import { FilterWay } from '@lighthouse/core'
import { ListItem4ByecodeUi, SelectDataSource } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FieldSelect } from '@/components/FieldSelect'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDsListAndFieldOptions } from '@/hooks/useDataSource'

import { DataSetting } from './DataSetting'
import { getFieldOptions } from './help'

interface TextFilterProps {
    prefixName: string
}

export const TextFilter: React.FC<TextFilterProps> = ({ prefixName }) => {
    const { control } = useFormContext()
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pointer = useWatch({ control, name: `${prefixName}.pointer` })

    const { dataSourceList, fieldOptions, dataSource } = useDsListAndFieldOptions(currentAppId, envId, pointer)

    const textFieldOptions = useMemo(() => getFieldOptions(fieldOptions, dataSource, FilterWay.textFilter), [dataSource, fieldOptions])

    return (
        <>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项来源</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.pointer`}
                    render={({ field: { value, onChange } }) => {
                        return <SelectDataSource withinPortal size="lg" value={value} onChange={onChange} dataSourceList={dataSourceList} />
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.fieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={textFieldOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            {dataSource && <DataSetting prefixName={prefixName} dataSource={dataSource} />}
        </>
    )
}
