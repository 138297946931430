import { Button, Flex, IconFont, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { spaceVersionEnum } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { openSpaceGrade } from '@/components/SpaceGrade'
import { useSpaceQuota } from '@/shared/reusable'

const versionCardBg = getAssetUrl('common', 'version_card_bg.jpg')
const versionCardImg = getAssetUrl('common', 'version_card_img.png')

interface VersionCardProps {}

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 266px;
    padding: 45px 40px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-image: url(${versionCardBg});
    background-size: 100% 100%;
    overflow: hidden;

    @media (max-width: 1150px) {
        img {
            display: none;
        }
    }
`

const SCxIcon = styled(IconFont)`
    color: var(--color-main);
    font-size: 16px;
`



const SCxImage = styled(Image)`
    position: absolute;
    right: 0;
    height: 386px;
    bottom: -110px;
`

export const VersionCard: React.FunctionComponent<VersionCardProps> = props => {
    const { data } = useSpaceQuota()

    const isShowMask = (data?.currentVersionCode ?? 0) < spaceVersionEnum.BASIC

    if (!isShowMask) {
        return null
    }
    return (
        <SCxContainer>
            <Flex gap={4} direction="column">
                <Text size={16} color="var(--colo-black)" weight={500} style={{ lineHeight: '24px' }}>
                    升级到基础版
                </Text>
                <Text color="var(--color-gray-500)">流量分析和页面分析洞察你的应用数据。</Text>
            </Flex>
            <Flex gap={4} direction="column">
                <Flex gap={4}>
                    <Flex gap={8}>
                        <SCxIcon type="CheckboxTick" />
                        <Text>流量分析，掌握用户行为。</Text>
                    </Flex>
                    <Flex gap={8}>
                        <SCxIcon type="CheckboxTick" />
                        <Text>页面分析，优化用户体验。</Text>
                    </Flex>
                </Flex>
                <Flex gap={4}>
                    <Flex gap={8}>
                        <SCxIcon type="CheckboxTick" />
                        <Text>数据报告，提升决策效率。</Text>
                    </Flex>
                    <Flex gap={8}>
                        <SCxIcon type="CheckboxTick" />
                        <Text>定制功能，满足个性需求。</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Button
                type="primary"
                radius={8}
                style={{ width: 140 }}
                size="lg"
                icon={<IconFont type="CrownStar" size={16} color="#fff" />}
                onClick={() => openSpaceGrade('version_upgrade')}
            >
                升级到基础版
            </Button>
            <SCxImage src={versionCardImg} />
        </SCxContainer>
    )
}
