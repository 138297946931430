import { Button, flex, IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? 16}px;
    ${pointer}
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
`

export const PublishBtn = styled(Button)`
    position: relative;
    box-shadow: none;
    &.tips::before {
        position: absolute;
        top: -5px;
        right: -5px;
        content: '';
        width: 12px;
        height: 12px;
        background-color: var(--color-red-500);
        border-radius: 100%;
        border: 1px solid var(--color-white);
        /* box-sizing: border-box; */
        z-index: 1;
    }
`

export const InputContain = styled.div`
    width: 300px;
    ${flex}
    flex-shrink: 1;
    margin-right: 12px;
    align-items: center;
    justify-content: space-between;
`
export const DomainInput = styled(Input)`
    flex-shrink: 1;
    width: 210px;
    border-radius: 7px 0 0 7px;
    input {
        margin: 0;
    }
`
export const InputSuffix = styled.div`
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    font-size: var(--font-size-normal);
    border-radius: 0px 7px 7px 0px;
    border: 1px solid var(--color-gray-200);
    border-left: none;
    box-sizing: border-box;
    flex-shrink: 0;
`

export const PublishSettingContain = styled.div`
    min-width: 389px;
    overflow: hidden;
`
export const ToolContainer = styled.div`
    display: flex;
    gap: 12px;
    padding: 0 12px;
    margin: 0 12px;
    margin-top: 8px;
    height: 60px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);

    &.hoverStyle {
        cursor: pointer;

        &:hover {
            background: var(--color-gray-100);
        }
    }
`
