import type { DataSourceAbstract, FormContainerBlockConfig } from '@lighthouse/core'
import { USER_DATASOURCE } from '@lighthouse/shared'

import { DEFAULT_CONTAINER_DESIGN } from '@/components/BlockSettings/constants'

/**
 * 生成表单容器的配置
 *
 * @param {(undefined | DataSourceAbstract)} dataSource
 * @returns {FormContainerBlockConfig}
 */
export const generateFormContainerBlock = (dataSource: undefined | DataSourceAbstract): FormContainerBlockConfig => {
    return {
        pointer: !dataSource || dataSource.id === USER_DATASOURCE ? '' : dataSource.id,
        submitButtonTitle: '提交',
        action: {
            trigger: 'click',
            type: 'none',
            data: {
                none: {}
            }
        },
        rules: [],
        design: { ...DEFAULT_CONTAINER_DESIGN }
    }
}
