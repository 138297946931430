/**
 * save recent color to local storage
 * @param color
 * @returns
 */
export const saveRecentIconsToStorage = (icon: string) => {
    const recentColors = getRecentIconsFromStorage()
    if (recentColors.includes(icon)) {
        recentColors.splice(recentColors.indexOf(icon), 1)
    }
    const newColors = [icon, ...recentColors].slice(0, 10)

    localStorage.setItem('icon-picker-recent-icons', JSON.stringify(newColors))
    return newColors
}

/**
 * get recent color from local storage
 * @returns
 */
export const getRecentIconsFromStorage = (): string[] => {
    return JSON.parse(localStorage.getItem('icon-picker-recent-icons') || '[]')
}
