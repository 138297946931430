import { Text } from '@byecode/ui'
import { CopyButton } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import type { SpaceOrder } from '@/services/types'

const SCxContainer = styled.div`
    width: 100%;
    padding: 12px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--color-gray-100);
    border-radius: 8px;
`

const SCxInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxBox = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`


const SCxLabel = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-black);
`

const SCxValue = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

interface OrderListItemProps {
    data: SpaceOrder
}

export const OrderListItem: React.FC<OrderListItemProps> = ({ data }) => {
    return (
        <SCxContainer>
            <SCxInfo>
                <Text size={16} weight={500}>
                    {data.paidContent}
                </Text>
                <Text size={16} weight={500}>
                    ￥{data.amount}
                </Text>
            </SCxInfo>
            <SCxInfo>
                <SCxLabel>空间</SCxLabel>
                <SCxValue>{data.spaceName}</SCxValue>
            </SCxInfo>
            <SCxInfo>
                <SCxLabel>订单编号</SCxLabel>
                <SCxBox>
                    <SCxValue>{data.outTradeNo}</SCxValue>
                    <CopyButton value={data.outTradeNo} />
                </SCxBox>
            </SCxInfo>
            <SCxInfo>
                <SCxLabel>支付方式</SCxLabel>
                <SCxValue>{data.payType}</SCxValue>
            </SCxInfo>
            <SCxInfo>
                <SCxLabel>支付时间</SCxLabel>
                <SCxValue>{data.successTime}</SCxValue>
            </SCxInfo>
        </SCxContainer>
    )
}
