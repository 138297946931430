import { IconFont, pointer, Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { type Field, type UpstreamVariable, type VariableADTvalue, VariableType } from '@lighthouse/core'
import type { AggregatorNode, VariableOptions } from '@lighthouse/shared'
import {
    getAggregatorUpstreamOption,
    getUserDatasourceOptions,
    innerTypeJoinOperatorMap,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

const SCxContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`

const SCxRemove = styled.div`
    ${pointer}
    padding: 2px;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

const SelectStyle = {
    flex: 1,
    flexShrink: 0,
    overflow: 'hidden'
}

export interface MatchConditionProps {
    prefixName: string
    nodeList: AggregatorNode[]
    dataSourceList: DataSourceAbstract[]
    leftOptions: VariableOptions
    onRemove?: () => void
}

export const MatchCondition: React.FC<MatchConditionProps> = ({ prefixName, nodeList, dataSourceList, leftOptions, onRemove }) => {
    const { control, setValue } = useFormContext()

    const leftVariable = useWatch({
        control,
        name: `${prefixName}.left`
    })

    const operator = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const isShowRightField = useMemo(() => operator !== 'isEmpty' && operator !== 'isNotEmpty', [operator])

    const leftField = useMemo(() => {
        const variable: UpstreamVariable = leftVariable
        const node = find(item => item.id === variable?.upstreamVariable?.nodeId, nodeList)
        if (!node) {
            return
        }
        const fieldList = node.data?.result?.fieldList || []

        return find(item => item.id === variable.upstreamVariable?.fieldId, fieldList)
    }, [leftVariable, nodeList])

    const operatorOptions = useMemo(() => {
        if (!leftField) {
            return []
        }
        const { innerType } = leftField
        if (!innerType) {
            return []
        }
        return innerTypeJoinOperatorMap[innerType]
    }, [leftField])

    const rightOptions = useMemo(
        () =>
            getAggregatorUpstreamOption({
                aggregateNodeList: nodeList,
                dataSourceList,
                validateFieldType: fieldInnerType => {
                    if (!leftField?.innerType) {
                        return true
                    }
                    // const leftBaseType = innerTypeToBase[leftField?.innerType]
                    // if (!leftBaseType) {
                    //     return true
                    // }
                    return leftField?.innerType === fieldInnerType
                }
            }),
        [dataSourceList, leftField?.innerType, nodeList]
    )

    const rightUserOptions = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: field => !['file', 'video', 'notes'].includes(field.type)
            }),
        [dataSourceList]
    )

    const handleLeftChange = useCallback(
        (data: VariableADTvalue) => {
            setValue(`${prefixName}.left`, data)
            if (data.type !== VariableType.UPSTREAM) {
                return
            }
            const oldInnerType = leftField?.innerType
            const node = find(item => item.id === data?.upstreamVariable?.nodeId, nodeList)
            if (!node) {
                return
            }
            const fieldList = node.data?.result?.fieldList || []
            const field = find(item => item.id === data?.upstreamVariable?.fieldId, fieldList)
            if (oldInnerType !== field?.innerType) {
                setValue(`${prefixName}.operator`, '')
                setValue(`${prefixName}.right`, undefined)
            }
        },
        [leftField?.innerType, nodeList, prefixName, setValue]
    )

    return (
        <SCxContainer>
            <Controller
                control={control}
                name={`${prefixName}.left`}
                render={({ field }) => (
                    <VariableSelect withinPortal disableInput value={field.value} options={leftOptions} onChange={handleLeftChange} />
                )}
            />
            <Controller
                control={control}
                name={`${prefixName}.operator`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: {
                                width: 80,
                                flexShrink: 0
                            }
                        }}
                        disabled={!leftField}
                        value={field.value}
                        options={operatorOptions}
                        onChange={val => {
                            field.onChange(val)
                        }}
                    />
                )}
            />
            {isShowRightField && (
                <Controller
                    control={control}
                    name={`${prefixName}.right`}
                    render={({ field }) => (
                        <VariableSelect
                            withinPortal
                            field={leftField as Field}
                            userOption={rightUserOptions}
                            options={rightOptions}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            )}
            <SCxRemove onClick={onRemove}>
                <IconFont type="Close" size={16} />
            </SCxRemove>
        </SCxContainer>
    )
}
