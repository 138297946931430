import { Switch } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Switch size="xs" />
            <Switch size="sm" />
            <Switch />
            <Switch size="lg" />
        </Unit>
    )
}
