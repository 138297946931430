import type { FlowEdge, FlowNode } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { find, findIndex } from 'rambda'

import { getNodesWithPosition } from './getNodesPosition'

/**
 * 暂时只有 action 和 condition 节点可以被删除
 * @param node
 * @param nodes
 * @param edges
 * @returns
 */

export const deleteNode = (node: Pick<FlowNode, 'id' | 'type'>, nodes: FlowNode[], edges: FlowEdge[]) => {
    const { id, type } = node
    const newNodes = nodes.filter(({ id: nId }) => nId !== id)

    const thisNodeAsSource: FlowEdge[] = []
    const thisNodeAsTarget: FlowEdge[] = []

    for (const edge of edges) {
        if (edge.source === id) {
            thisNodeAsSource.push(edge)
        }
        if (edge.target === id) {
            thisNodeAsTarget.push(edge)
        }
    }

    const thisNodeAsSourceIsSingle = thisNodeAsSource.length === 1
    const thisNodeAsTargetIsSingle = thisNodeAsTarget.length === 1

    // 针对条件节点
    if (type === 'CONDITION') {
        // 如果前面和后面都就一个节点，则需要将后面的节点的 source 改为前面的节点
        if (thisNodeAsSourceIsSingle && thisNodeAsTargetIsSingle) {
            const [thisNodeAsSourceEdge] = thisNodeAsSource
            const [thisNodeAsTargetEdge] = thisNodeAsTarget

            let conditionTailNodeId = thisNodeAsTargetEdge.target

            // 开始进入条件节点的节点
            const startConditionNodeId = thisNodeAsTargetEdge.source
            const findTargetNodeEdge = (nodeId: string) =>
                find(edge => {
                    return edge.source === nodeId
                }, edges)
            const findIndexDeletionNodeId = (nodeId: string) => findIndex(({ id }) => id === nodeId, newNodes)
            const findDeletionNodeId = (nodeId: string) => find(({ id }) => id === nodeId, newNodes)

            const deletionEdgeIds: string[] = [thisNodeAsTargetEdge.id]
            // 条件节点结束后出来的节点
            let conditionEndNodeId = ''

            while (conditionTailNodeId) {
                const targetNodeEdge = findTargetNodeEdge(conditionTailNodeId)
                const targetNode = findDeletionNodeId(conditionTailNodeId)

                if (!targetNodeEdge) {
                    break
                }
                // 记录需要删除的边
                deletionEdgeIds.push(targetNodeEdge.id)
                if (targetNodeEdge.type === 'conditionTarget') {
                    //  targetNodeEdge.target
                    conditionEndNodeId = targetNodeEdge.target
                    break
                }
                // 删除动作节点
                if (targetNodeEdge.source !== conditionTailNodeId) {
                    newNodes.splice(findIndexDeletionNodeId(conditionTailNodeId), 1)
                }

                conditionTailNodeId = targetNodeEdge.target
            }

            const newEdges: FlowEdge[] = []

            if (!conditionTailNodeId) {
                return { nodes, edges }
            }

            for (const edge of edges) {
                if (deletionEdgeIds.includes(edge.id)) {
                    continue
                }
                newEdges.push(edge)
            }

            // 当删除条件节点后，如果条件节点被完全删除则需要将条件节点的 source 和 target 连接起来
            const isConditionNodeIsSingle = newEdges.filter(edge => edge.source === startConditionNodeId).length === 0
            if (isConditionNodeIsSingle) {
                newEdges.push({
                    id: nanoid(),
                    source: startConditionNodeId,
                    target: conditionEndNodeId,
                    type: 'common'
                })
            }

            return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
        }
        // 如果前面就一个节点，而后面有多个节点，则需要将后面的节点的 source 改为前面的节点
        // 如果后面就一个节点，则需要将前面的节点的 target 改为后面的节点
        if (thisNodeAsTargetIsSingle) {
            return { nodes, edges }
        }
    }

    // 针对动作节点
    if (type === 'ACTION') {
        // single source, base on it
        if (thisNodeAsSourceIsSingle && thisNodeAsTargetIsSingle) {
            const newEdges: FlowEdge[] = []

            const prevLevelNodeId = edges.find(edge => edge.target === id)?.source

            if (!prevLevelNodeId) {
                return { nodes, edges }
            }

            for (const edge of edges) {
                if (edge.target === id) {
                    continue
                }
                if (edge.source === id) {
                    edge.source = prevLevelNodeId
                }
                newEdges.push(edge)
            }
            return {
                nodes: getNodesWithPosition(newNodes, newEdges),
                edges: newEdges
            }
        }

        if (thisNodeAsSourceIsSingle && !thisNodeAsTargetIsSingle) {
            const newEdges: FlowEdge[] = []

            const [oldTargetNodeEdge] = thisNodeAsTarget
            const oldTargetNodeTargetNodeId = edges.find(edge => edge.source === oldTargetNodeEdge.target)?.target

            if (!oldTargetNodeTargetNodeId) {
                return { nodes, edges }
            }

            for (const edge of edges) {
                if (edge.target === id) {
                    edge.target = oldTargetNodeTargetNodeId
                }
                if (edge.source === id) {
                    continue
                }
                newEdges.push(edge)
            }
            return {
                nodes: getNodesWithPosition(newNodes, newEdges),
                edges: newEdges
            }
        }
        if (!thisNodeAsSourceIsSingle && thisNodeAsTargetIsSingle) {
            const newEdges: FlowEdge[] = []
            const [oldSourceNodeEdge] = thisNodeAsSource
            const oldSourceNodeSourceNodeId = edges.find(edge => edge.target === oldSourceNodeEdge.source)?.source

            if (!oldSourceNodeSourceNodeId) {
                return { nodes, edges }
            }

            for (const edge of edges) {
                if (edge.target === id) {
                    continue
                }
                if (edge.source === id) {
                    edge.source = oldSourceNodeSourceNodeId
                }
                newEdges.push(edge)
            }
            return {
                nodes: getNodesWithPosition(newNodes, newEdges),
                edges: newEdges
            }
        }
    }

    return { nodes, edges }
}
