import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

export interface PreviewCardProps {
    title?: string
    desc?: string
    imgUrl?: string
}

const SCxPreviewCardWrapper = styled.div`
    padding: 0 16px;
`

const SCxPreviewCardContentWrapper = styled.div`
    margin-bottom: 24px;
`

const SCxPreviewCardTitle = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`

const SCxPreviewMessageCard = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    padding: 20px;
    border-radius: 6px;
    background: var(--color-gray-100);
`

const SCxPreviewMessageWrapper = styled.div`
    flex: 1;
    padding: 12px;
    margin-right: 12px;
    border-radius: 8px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

const SCxPreviewMessageTitle = styled.div`
    margin-bottom: 8px;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxPreviewMessageDescWrapper = styled.div`
    display: flex;
`

const SCxPreviewMessageDesc = styled.div`
    flex: 1;
    margin-right: 8px;
    line-height: 1;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxPreviewMessageUserAvatar = styled.div`
    width: 44px;
    height: 44px;
    background-color: var(--color-gray-200);
    background-size: cover;
`

const SCxPreviewMessageIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    font-size: 32px;
    color: var(--color-gray-400);
    background-color: var(--color-gray-200);
`

const SCxPreviewTimelineCard = styled.div`
    flex: 1;
    display: flex;
    margin-top: 12px;
    padding: 20px;
    border-radius: 6px;
    background: var(--color-gray-100);
`

const SCxPreviewTimelineWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
`

const SCxPreviewTimelineContent = styled.div`
    flex: 1;
    margin-left: 12px;
`

const SCxPreviewTimelineTitle = styled.div``

const SCxPreviewTimelineMessageWrapper = styled.div`
    display: flex;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

const SCxPreviewTimelineMessageTitle = styled.div`
    flex: 1;
    margin-left: 8px;
    line-height: 1;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxPreviewTimelineMessageTime = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const PreviewCard: React.FC<PreviewCardProps> = ({ title, desc, imgUrl }) => {
    return (
        <SCxPreviewCardWrapper>
            <SCxPreviewCardContentWrapper>
                <SCxPreviewCardTitle>微信消息预览</SCxPreviewCardTitle>
                <SCxPreviewMessageCard>
                    <SCxPreviewMessageWrapper>
                        <SCxPreviewMessageTitle>{title}</SCxPreviewMessageTitle>
                        <SCxPreviewMessageDescWrapper>
                            <SCxPreviewMessageDesc>{desc}</SCxPreviewMessageDesc>
                            <SCxPreviewMessageUserAvatar style={{ backgroundImage: `url(${imgUrl})` }} />
                        </SCxPreviewMessageDescWrapper>
                    </SCxPreviewMessageWrapper>
                    <SCxPreviewMessageIcon>
                        <IconFont type="User" />
                    </SCxPreviewMessageIcon>
                </SCxPreviewMessageCard>
            </SCxPreviewCardContentWrapper>
            <SCxPreviewCardContentWrapper>
                <SCxPreviewCardTitle>朋友圈预览</SCxPreviewCardTitle>
                <SCxPreviewTimelineCard>
                    <SCxPreviewTimelineWrapper>
                        <SCxPreviewMessageIcon>
                            <IconFont type="User" />
                        </SCxPreviewMessageIcon>
                        <SCxPreviewTimelineContent>
                            <SCxPreviewMessageTitle>分享</SCxPreviewMessageTitle>
                            <SCxPreviewTimelineMessageWrapper>
                                <SCxPreviewMessageUserAvatar style={{ backgroundImage: `url(${imgUrl})` }} />
                                <SCxPreviewTimelineMessageTitle>{title}</SCxPreviewTimelineMessageTitle>
                            </SCxPreviewTimelineMessageWrapper>
                            <SCxPreviewTimelineMessageTime>1 分钟前</SCxPreviewTimelineMessageTime>
                        </SCxPreviewTimelineContent>
                    </SCxPreviewTimelineWrapper>
                </SCxPreviewTimelineCard>
            </SCxPreviewCardContentWrapper>
        </SCxPreviewCardWrapper>
    )
}
