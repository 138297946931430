import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import { Slider } from '@byecode/ui/components/Slider'
import { Text } from '@byecode/ui/components/Text'
import { Tooltip } from '@byecode/ui/components/Tooltip'
import { useMove } from '@byecode/ui/hooks/useMove'
import type { ApplicationSettingThemeColor, GradientMode, GradientStop } from '@lighthouse/core'
import { GRADIENT_MODE } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import chroma from 'chroma-js'
import produce, { current } from 'immer'
import React, { useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import { ListItem4ByecodeUi } from '../../ListItemMenu'
import { useFillPickerContext } from '../Provider'
import type { GradientProtocolOfKey } from '../type'
import { applyFillPickerValue4CssColorValue, getGradientCssText } from '../utils'

const GRADIENT_MODE_OPTIONS = [
    {
        label: (
            <Tooltip title="线性">
                <IconFont type="Gradient" size={16} />
            </Tooltip>
        ),
        value: GRADIENT_MODE.linear
    },
    {
        label: (
            <Tooltip title="径向">
                <IconFont type="RadialGradient" size={16} />
            </Tooltip>
        ),
        value: GRADIENT_MODE.radial
    },
    {
        label: (
            <Tooltip title="角度">
                <IconFont type="AngleGradient" size={16} />
            </Tooltip>
        ),
        value: GRADIENT_MODE.conic
    }
]

const Container = styled.div``

const GradientSlider = styled.div`
    flex: 1;
    position: relative;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
`

const Thumb = styled.button`
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -4px;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
`

const ThumbInner = styled.span`
    pointer-events: none;
    display: block;
    width: 21px;
    height: 21px;
    border: 3px solid #fff;
    border-radius: 50%;
`

interface GradientContainerProps {
    value: GradientProtocolOfKey
    onChange: (value: GradientProtocolOfKey) => void
    selectedGradientStopKey: string
    onGradientStopKeySelect: (key: string) => void
}

export const GradientContainer = ({ value, onChange, selectedGradientStopKey, onGradientStopKeySelect }: GradientContainerProps) => {
    const latestValue = useLatest(value)
    const mutableKey = useRef(selectedGradientStopKey)
    const isCreateFlag = useRef(false)

    const { palettes } = useFillPickerContext()

    const gradientCssText = useMemo(() => {
        return getGradientCssText({ mode: GRADIENT_MODE.linear, angle: 90, stops: value.stops }, palettes)
    }, [palettes, value.stops])

    const { ref } = useMove(
        ({ ratio: { x } }) => {
            if (isCreateFlag.current) {
                isCreateFlag.current = false
                return
            }
            onChange(
                produce(value, draft => {
                    const selectedStop = draft.stops.find(item => item.key === mutableKey.current)
                    if (selectedStop) {
                        selectedStop.percentage = x * 100
                        draft.stops.sort((a, b) => a.percentage - b.percentage)
                    }
                })
            )
        },
        {
            onStart: ({ event, ratio: { x } }) => {
                if (event.target instanceof HTMLElement) {
                    // 添加step
                    if (event.target === ref.current) {
                        isCreateFlag.current = true
                        const newKey = nanoid()

                        const rect = event.target.getBoundingClientRect()
                        const pointerColor = getPointerColorByElement(rect, event.x, event.y, value.stops, palettes)
                        onChange(
                            produce(value, draft => {
                                draft.stops.push({ color: pointerColor, percentage: x * 100, key: newKey })
                                draft.stops.sort((a, b) => a.percentage - b.percentage)
                            })
                        )
                        onGradientStopKeySelect(newKey)
                        mutableKey.current = newKey
                    } else {
                        // 拖动step
                        const dataKey = event.target.getAttribute('data-key')
                        if (dataKey) {
                            onGradientStopKeySelect(dataKey)
                            mutableKey.current = dataKey
                        }
                    }
                }
            }
        }
    )

    useHotkeys(
        'del, backspace',
        () => {
            const newValue = { ...latestValue.current, stops: latestValue.current.stops.filter(item => item.key !== mutableKey.current) }
            onChange(newValue)
            const newKey = newValue.stops[0].key
            onGradientStopKeySelect(newKey)
            mutableKey.current = newKey
        },
        { enabled: value.stops.length > 2 }
    )

    const [draftAngel, setDraftAngel] = useState(`${value.angle}°`)
    const handleAlphaChange = (str: string) => {
        const matches = /-?\d+(°?$)/.exec(str)
        if (matches) {
            const [v, angel] = matches
            const number = angel ? v.replace('°', '') : v
            const inRangeNumber = Math.max(-180, Math.min(180, Number(number)))
            setDraftAngel(`${inRangeNumber}°`)
            onChange({ ...value, angle: inRangeNumber })
        } else {
            setDraftAngel(`${value.angle}°`)
        }
    }

    return (
        <Container>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text size={14}>渐变方式</Text>
                <SegmentedControl
                    fullWidth
                    data={GRADIENT_MODE_OPTIONS}
                    value={value.mode}
                    onChange={v => onChange({ ...value, mode: v as GradientMode })}
                    style={{ width: 180 }}
                />
            </ListItem4ByecodeUi>

            {value.mode === GRADIENT_MODE.radial && (
                <>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text size={14}>渐变宽度</Text>
                        <Slider
                            min={0}
                            max={100}
                            style={{ width: 180 }}
                            value={value.width}
                            onChange={v => onChange({ ...value, width: v })}
                            inputProps={{
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text size={14}>渐变高度</Text>
                        <Slider
                            min={0}
                            max={100}
                            style={{ width: 180 }}
                            value={value.height}
                            onChange={v => onChange({ ...value, height: v })}
                            inputProps={{
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                        />
                    </ListItem4ByecodeUi>
                </>
            )}

            <ListItem4ByecodeUi alignItems="center" gap="8px">
                <GradientSlider ref={ref} style={{ background: gradientCssText }}>
                    {value.stops.map(item => (
                        <Thumb
                            key={item.key}
                            data-key={item.key}
                            style={{
                                left: `calc(${item.percentage}% - 12px)`,
                                zIndex: selectedGradientStopKey === item.key ? 2 : 1,
                                borderColor:
                                    selectedGradientStopKey === item.key
                                        ? applyFillPickerValue4CssColorValue(item.color, palettes)
                                        : undefined
                            }}
                        >
                            <ThumbInner style={{ backgroundColor: applyFillPickerValue4CssColorValue(item.color, palettes) }} />
                        </Thumb>
                    ))}
                </GradientSlider>
                <Input
                    placeholder=""
                    style={{ width: 43 }}
                    value={draftAngel}
                    onChange={e => {
                        const { value } = e.target
                        setDraftAngel(value)
                    }}
                    onBlur={e => {
                        const { value } = e.currentTarget
                        handleAlphaChange(value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            const { value } = e.currentTarget
                            handleAlphaChange(value)
                        }
                    }}
                    onFocus={e => {
                        e.target.select()
                    }}
                />
            </ListItem4ByecodeUi>
        </Container>
    )
}

function getPointerColorByElement(rect: DOMRect, x: number, y: number, stops: GradientStop[], palettes: ApplicationSettingThemeColor[]) {
    const { width, height, left, top } = rect
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    canvas.style.background = 'black'

    const context = canvas.getContext('2d')
    if (!context) {
        return '#ffffff'
    }

    const lineGradientColor = context.createLinearGradient(0, 0, width, height)
    stops.forEach(item => {
        lineGradientColor.addColorStop(item.percentage / 100, applyFillPickerValue4CssColorValue(item.color, palettes) || '')
    })
    context.fillStyle = lineGradientColor
    context.fillRect(0, 0, width, height)

    const [r, g, b, a] = context.getImageData(x - left, y - top, 1, 1).data
    return chroma(r, g, b, 'rgb')
        .alpha(Math.round((a * 100) / 255) / 100)
        .hex()
}
