import type { ChartViewOptions } from '@lighthouse/core'
import { ChartOptionProtocol, ChartType } from '@lighthouse/core'

import { DEFAULT_THEME_COLORS } from '../sharedStyle'

/**
 * 生成图标block的配置
 *
 * @param {ChartType} type
 * @returns {ChartOptionProtocol}
 */
export const generateChartBlock = (type: ChartType): ChartViewOptions => {
    if (type === ChartType.indicator) {
        return {
            chartType: type,
            appId: '',
            pointer: '',
            showHeadTitle: true,
            title: '指标卡',
            showLabel: false,
            showThousandthSplit: false,
            showUnit: false,
            fieldId: '',
            linkFilterController: {
                expression: {
                    where: 'AND',
                    conditions: []
                }
            }
            // calcType
        }
    }
    return {
        appId: '',
        pointer: '',
        chartType: type,
        dimensions: [],
        mainAxis: [],
        secondaryAxis: [],
        theme: DEFAULT_THEME_COLORS.value,
        colors: DEFAULT_THEME_COLORS.colors,
        mainMarkLines: [],
        secondaryMarkLines: [],
        aggregate: true,
        showHeadTitle: true,
        showLabel: false,
        showLegend: true,
        legendTranspose: 'bottom',
        stacking: 'group',
        showGridLine: true,
        xyAxis: 'X',
        sortMode: 'ASC',
        linkFilterController: {
            expression: {
                where: 'AND',
                conditions: []
            }
        }
    }
}
