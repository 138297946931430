import type { VideoBlockConfig } from '@lighthouse/core'

/**
 * 生成视频block的配置
 *
 * @returns {VideoBlockConfig}
 */
export const generateVideoBlock = (): VideoBlockConfig => {
    return {
        ratio: '4',
        radius: '0',
        isLoop: true,
        isAuto: true,
        isMute: true,
        isUsedVideoControl: true,
        fitType: 'auto'
    }
}
