import { singleTextEllipsis } from '@byecode/ui'
import { animated } from '@react-spring/web'
import styled, { css } from 'styled-components'

export const UploadButton = styled.div`
    align-self: flex-start;
    padding: 16px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--color-gray-500);
    background-color: var(--color-gray-100);
    &:hover {
        color: var(--color-gray-900);
        background-color: var(--color-gray-200);
    }
`
export const FileList = styled.ul`
    list-style: none;
`
export const FileListItemActions = styled.div`
    opacity: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: opacity 0.25s ease-out;
    & > * {
        cursor: pointer;
    }
`
export const FileListItem = styled(animated.li).withConfig<{ error?: boolean }>({ shouldForwardProp: p => p !== 'error' })`
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 5px;
    padding: 7px 8px;
    margin-top: 8px;
    border: 1px solid transparent;
    background-color: var(--color-gray-100);
    ${({ error }) =>
        error &&
        css`
            border-color: var(--color-red-500);
        `}
    &:hover {
        background-color: var(--color-gray-200);
        ${FileListItemActions} {
            opacity: 1;
        }
    }
`
export const FileListItemName = styled.div`
    flex: 1;
    font-size: 14px;
    color: var(--color-black);
    ${singleTextEllipsis()}
`
export const FileListItemLoadingMask = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`
export const FileListItemError = styled.div`
    color: var(--color-red-500);
    margin-top: 6px;
    font-size: 12px;
`
