import type { HttpResp } from '@/http'
import http from '@/http'

import { JsonContentType } from './constant'

export type AnalysisCoreIndicators = {
    avgDepth: number
    avgDuration: number
    avgPv: number
    bounceRate: number
    ip: number
    pv: number
    time: string
    uv: number
    visits: number
}

/** 获取页面分析 核心指标 */
export async function fetchAnalysisCore(): Promise<AnalysisCoreIndicators> {
    const res = await http.get<unknown, HttpResp<AnalysisCoreIndicators>>('api/v1/analysis/keyMetrics')
    return res.content
}

type FetchAnalysisTrafficTrendPayload = {
    type: 'HOUR' | 'DAY'
    startTime: string
    endTime: string
}
/** 获取页面分析 流量趋势 */
export async function fetchAnalysisTrafficTrend(payload: FetchAnalysisTrafficTrendPayload) {
    const res = await http.get<unknown, HttpResp<AnalysisCoreIndicators[]>>('api/v1/analysis/trafficTrend', { params: payload })
    return res.content
}

export type AnalysisTrafficTreeRes = {
    name: string
    children?: AnalysisTrafficTreeRes[]
} & AnalysisCoreIndicators

/** 获取页面分析 流量来源 */
export async function fetchAnalysisTrafficSource(payload: FetchAnalysisTrafficTrendPayload) {
    const res = await http.get<unknown, HttpResp<AnalysisTrafficTreeRes[]>>('api/v1/analysis/trafficSource', { params: payload })
    return res.content
}

/** 获取页面分析 地区分布 */
export async function fetchAnalysisTrafficByCity(payload: FetchAnalysisTrafficTrendPayload) {
    const res = await http.get<unknown, HttpResp<AnalysisTrafficTreeRes[]>>('api/v1/analysis/trafficByCity', { params: payload })
    return res.content
}

export enum StatisticalPlatForm {
    BAIDU = 'BAIDU',
    CHROME = 'CHROME',
    IPARLLAY = 'IPARLLAY',
    UMENG = 'UMENG'
}
export type AnalysisConfigure = {
    code: string
    type: StatisticalPlatForm
    title: string
    id: string
}
/** 获取统计配置 */
export async function fetchAnalysisConfigure() {
    const res = await http.get<unknown, HttpResp<{ statistical: AnalysisConfigure[] }>>('api/v1/app/getAnalysisConfig')
    return res.content.statistical
}
/** 创建统计 */
export async function createAnalysisConfigure(payload: Omit<AnalysisConfigure, 'id'>) {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createAnalysisConfig', payload, {
        headers: JsonContentType
    })
    return res.content
}
/** 更新统计配置 */
export async function UpdateAnalysisConfigure(payload: AnalysisConfigure) {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/app/updateAnalysisConfig', payload, {
        headers: JsonContentType
    })
    return res.success
}
/** 删除统计配置 */
export async function deleteAnalysisConfigure(id: string) {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/app/deleteAnalysisConfig', { params: { id } })
    return res.success
}

export type AnalysisPageVisitsRes = {
    list: (AnalysisCoreIndicators & {
        title: string
        description?: string
        image?: string
        pageUrl: string
    })[]
    currentPage: number
    pageSize: number
    total: number
}
/** 获取页面分析 页面访问 */
export async function fetchAnalysisVisit(pageNum: number, pageSize: number) {
    const res = await http.get<unknown, HttpResp<AnalysisPageVisitsRes>>('api/v1/analysis/pageAnalysis', {
        params: { currentPage: pageNum, pageSize }
    })
    return res.content
}
