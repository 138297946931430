import { Empty } from '@byecode/ui/components/Empty'
import { IconFont } from '@byecode/ui/components/IconFont'
import * as React from 'react'

interface PageEmptyProps {
    type: 'default' | 'data'
}

export const PageEmpty: React.FunctionComponent<PageEmptyProps> = ({ type }) => {
    return (
        <Empty
            icon={<IconFont size={32} color="var(--color-gray-400)" type={type === 'data' ? 'DataPage' : 'PageBroadContent'} />}
            description={type === 'data' ? '未搜索到数据页面' : '未搜索到普通页面'}
            styles={{
                root: {
                    border: '1.5px  dashed var(--color-gray-200)',
                    backgroundColor: 'var(--color-gray-25)',
                    borderRadius: 6,
                    height: 108,
                    margin: '6px 0'
                },
                content: {
                    fontSize: 12
                }
            }}
        />
    )
}
