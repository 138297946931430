import type { SelectItemProps } from '@byecode/ui'
import { Button, Divider, IconFont, Popover, SelectDropdown, SelectItem } from '@byecode/ui'
import { FilterWay } from '@lighthouse/core'
import { Text } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { filterWayOptions } from './constants'

export const SCxSelectItemWrapper = styled.div``

interface AddFilterItemProps {
    onAdd: (type: string) => void
}

const OptionComponent: React.FC<SelectItemProps> = ({ ...rest }) => {
    if (rest.value === FilterWay.cascadeFilter) {
        return (
            <SCxSelectItemWrapper>
                <Divider style={{ margin: '8px 0' }} />
                <SelectItem {...rest} />
            </SCxSelectItemWrapper>
        )
    }
    return <SelectItem {...rest} />
}

export const AddFilterItem: React.FC<AddFilterItemProps> = ({ onAdd }) => {
    const [open, setOpen] = useState(false)

    const handleSelect = useCallback(
        (type: string) => {
            onAdd(type)
            setOpen(false)
        },
        [onAdd]
    )

    return (
        <Popover withinPortal opened={open} onChange={setOpen} width={204}>
            <Popover.Target>
                <Button
                    style={{ justifyContent: 'flex-start', borderRadius: 100 }}
                    icon={<IconFont size={16} color="var(--color-gray-500)" type="Add" />}
                >
                    <Text>添加</Text>
                </Button>
            </Popover.Target>
            <Popover.Dropdown>
                <SelectDropdown
                    searchable
                    styles={{ selectDropdown: { maxHeight: 300 } }}
                    options={filterWayOptions}
                    onSelect={handleSelect}
                    optionComponent={OptionComponent}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
