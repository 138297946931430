import { Slider } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Slider style={{ width: 180 }} min={0} max={100} />

            <Slider style={{ width: 180 }} min={0} max={100} step={10} />
        </Unit>
    )
}
