import { backgroundTransitionOnClick, Button } from '@byecode/ui'
import styled from 'styled-components'

export const ExtraButton = styled(Button)`
    white-space: nowrap;
`

export const ActionList = styled.div`
    width: 240px;
    padding: 6px 0;
`

export const ActionItem = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 8px 6px 12px;
    font-size: var(--font-size-normal);
    cursor: pointer;
    ${backgroundTransitionOnClick}
`

export const ActionItemText = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`
