import { IconFont, SegmentedControl } from '@byecode/ui'
import { Enum } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { dataSourceAtom } from '../atoms'
import { VisibilityFilter } from '../Common/VisibilityFilter'
import { Basic } from './Basic'
import { Operate } from './Operate'

const SCxPaddingBox = styled.div`
    padding: 12px 16px;
`

const TabEnum = Enum('basic', 'operation', 'visible')

const CUSTOM_VIEW_TABS = [
    { value: TabEnum.basic, label: '基础', icon: <IconFont type="SlidersHorizontal" /> },
    { value: TabEnum.operation, label: '操作', icon: <IconFont type="Cursor" /> },
    { value: TabEnum.visible, label: '可见性', icon: <IconFont type="Eye" /> }
]

interface CustomViewSettingProps {
    id: string
}

export const CustomViewSetting: React.FC<CustomViewSettingProps> = ({ id }) => {
    const [currentTab, setCurrentTab] = useState<string>(TabEnum.basic)
    const dataSource = useAtomValue(dataSourceAtom)
    const tabs = useMemo(() => {
        if (!dataSource) {
            return CUSTOM_VIEW_TABS.map(item => ({
                ...item,
                disabled: item.value !== TabEnum.basic
            }))
        }
        return CUSTOM_VIEW_TABS
    }, [dataSource])

    return (
        <>
            <SCxPaddingBox>
                <SegmentedControl disabled={false} data={tabs} size="lg" fullWidth value={currentTab} onChange={setCurrentTab} />
            </SCxPaddingBox>
            {currentTab === TabEnum.basic && <Basic id={id} dataSource={dataSource} />}
            {dataSource && currentTab === TabEnum.operation && <Operate id={id} dataSource={dataSource} />}
            {dataSource && currentTab === TabEnum.visible && <VisibilityFilter currentDsId={dataSource.id} />}
        </>
    )
}
