import { IconFont, SegmentedControl, Tooltip } from '@byecode/ui'
import {
    type AggregatorJoinConfig as JoinConfig,
    type AggregatorNode,
    aggregatorJoinNodeIconMap,
    AggregatorJoinType
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { JoinMatchContent } from '@/components/JoinMatchContent'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import * as SC from '../styles'

interface AggregatorJoinConfigProps {
    nodeList: AggregatorNode[]
}

export const AggregatorJoinConfig: React.FC<AggregatorJoinConfigProps> = ({ nodeList }) => {
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { control } = useFormContext<JoinConfig>()
    const joinType = useWatch({
        control,
        name: 'joinType'
    })
    const joinQuestion = useMemo(
        () => (
            <SC.List>
                <SC.ListItem>
                    <SC.Paragraph>左连接：</SC.Paragraph>
                    <SC.Paragraph>返回左侧表格的全部数据，根据连接字段，逐行匹配并 连接右侧表格的数据，匹配不到则留空。</SC.Paragraph>
                </SC.ListItem>
                <SC.ListItem>
                    <SC.Paragraph>右连接：</SC.Paragraph>
                    <SC.Paragraph>返回左侧表格的全部数据，根据连接字段，逐行匹配并 连接右侧表格的数据，匹配不到则留空。</SC.Paragraph>
                </SC.ListItem>
                <SC.ListItem>
                    <SC.Paragraph>内连接：</SC.Paragraph>
                    <SC.Paragraph>返回左侧表格的全部数据，根据连接字段，逐行匹配并 连接右侧表格的数据，匹配不到则留空。</SC.Paragraph>
                </SC.ListItem>
            </SC.List>
        ),
        []
    )

    const segmentedControlData = useMemo(
        () => [
            {
                value: AggregatorJoinType.LEFT_JOIN,
                label: '左连接',
                icon: (
                    <IconFont
                        size={16}
                        fill={joinType === AggregatorJoinType.LEFT_JOIN ? 'var(--color-main)' : 'var(--color-gray-400)'}
                        type={aggregatorJoinNodeIconMap['LEFT_JOIN']}
                    />
                )
            },
            {
                value: AggregatorJoinType.RIGHT_JOIN,
                label: '右连接',
                icon: (
                    <IconFont
                        size={16}
                        fill={joinType === AggregatorJoinType.RIGHT_JOIN ? 'var(--color-main)' : 'var(--color-gray-400)'}
                        type={aggregatorJoinNodeIconMap['RIGHT_JOIN']}
                    />
                )
            },
            {
                value: AggregatorJoinType.INNER_JOIN,
                label: '内连接',
                icon: (
                    <IconFont
                        size={16}
                        fill={joinType === AggregatorJoinType.INNER_JOIN ? 'var(--color-main)' : 'var(--color-gray-400)'}
                        type={aggregatorJoinNodeIconMap['INNER_JOIN']}
                    />
                )
            }
        ],
        [joinType]
    )

    return (
        <SC.FormContainer>
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel>连接方式</SC.FormItemLabel>
                    <Tooltip title={joinQuestion}>
                        <SC.Icon size={16} type="Question" />
                    </Tooltip>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <Controller
                        name="joinType"
                        control={control}
                        render={({ field }) => (
                            <SegmentedControl data={segmentedControlData} value={field.value} onChange={field.onChange} />
                        )}
                    />
                </SC.FormItemContent>
            </SC.FormItem>
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel>满足条件</SC.FormItemLabel>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <JoinMatchContent prefixName="rules" nodeList={nodeList} dataSourceList={dataSourceList} />
                </SC.FormItemContent>
            </SC.FormItem>
        </SC.FormContainer>
    )
}
