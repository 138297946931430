import { Checkbox } from '@byecode/ui'
import type { AppUserGroup } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { useDataSourceUserGroups, useDataSourceUsers } from '@/shared/reusable'

import { userGroupsTitle } from '../constant'
import EditInput from '../EditInput'
import * as CM from '../styles'
import type { UserGroupTitleType } from '../type'
import * as SC from './styles'

interface UserGroupItemProps {
    group: AppUserGroup
    checked: boolean
    disabled?: boolean

    onChangeName?: (name: string, id: string) => void
    onSelectGroup?: (groupId: string) => void
}

const UserGroupItem: React.FC<UserGroupItemProps> = ({
    checked,
    group: { groupId, groupName, type: groupType, visiblePageCount },
    onChangeName,
    onSelectGroup,
    disabled
}) => {
    const { data: users = [] } = useDataSourceUsers()
    const groups = useDataSourceUserGroups()

    const renderColumn = useCallback(
        (type: UserGroupTitleType) => {
            switch (type) {
                case 'name': {
                    return (
                        <EditInput
                            readonly={disabled}
                            value={groupName}
                            onBlur={(value: string) => {
                                onChangeName?.(value, groupId)
                            }}
                            onChange={(value: string) => onChangeName?.(value, groupId)}
                            onReset={(val: string) => {
                                if (!val || groups.some(draft => draft.groupName === val)) {
                                    return groupName
                                }
                            }}
                        />
                    )
                }
                case 'visiblePage': {
                    return <>{visiblePageCount}</>
                }

                case 'usersNum': {
                    return <>{users.filter(draft => draft.groupId === groupId && draft.state !== 2 && !draft.isDepart).length ?? 0}</>
                }
                case 'checkbox': {
                    return (
                        <Checkbox
                            disabled={disabled}
                            size="xs"
                            checked={checked}
                            onChange={e => onSelectGroup?.(e.target.checked ? groupId : '')}
                        />
                    )
                }

                default: {
                    return null
                }
            }
        },
        [checked, disabled, groupId, groupName, groups, onChangeName, onSelectGroup, users, visiblePageCount]
    )

    return (
        <SC.RecordItem checked={checked}>
            {userGroupsTitle.map(({ id, ...rest }) => (
                <CM.Item key={id} {...rest}>
                    {renderColumn(id)}
                </CM.Item>
            ))}
        </SC.RecordItem>
    )
}

export default UserGroupItem
