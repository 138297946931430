import { Flex, IconFont, Popover, SegmentedControl, Switch } from '@byecode/ui'
import type { ChartValue } from '@lighthouse/core'
import { BasicHeader } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import cls from 'classnames'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { chartLineType } from '../../constants'

const SCxSetting = styled.div`
    width: 44px;
    height: 32px;
    /* margin-left: 6px; */
    border-radius: 6px;
    background: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxList = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    padding: 8px 16px;
`

const SCxIcon = styled(IconFont)`
    margin-right: 4px;
`

const SCxListItem = styled(Flex)`
    cursor: pointer;
    height: 44px;
    color: var(--color-gray-500);
    border-radius: 8px;
    &.active {
        background-color: var(--color-theme-3);
        color: var(--color-main);
        ${SCxIcon} {
            color: var(--color-main);
        }
    }

    &:hover {
        background-color: var(--color-theme-3);
        color: var(--color-main);
        ${SCxIcon} {
            color: var(--color-main);
        }
    }
`

const SCxText = styled.div`
    font-size: var(--font-size-sm);
`

const SCxConfigurator = styled.div``

const SCxConfiguratorItem = styled(Flex)`
    padding: 0 16px;
    height: 32px;
`

const SCxActionItem = styled(Flex)`
    padding: 0 16px;
    height: 32px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxLabel = styled.div`
    color: var(--color-gray-900);
    font-size: var(--font-size-normal);
`

const chartTypeList = [
    {
        label: '柱形',
        icon: 'ChartBar',
        value: 'bar'
    },
    {
        label: '折线',
        icon: 'ChartLine',
        value: 'line'
    }
    // {
    //     label: '散点',
    //     icon: 'ChartBar',
    //     value: 'scatter'
    // }
]

interface ChartTypeSelectorProps {
    index: number
    data: ChartValue
    onChange: (value: ChartValue) => void
    onDelete?: (index: number) => void
}

export const ChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({ index, data, onChange, onDelete }) => {
    const configurator = useMemo(() => {
        if (data.chartType === 'line') {
            return (
                <>
                    <Divider my={8} color="var(--color-gray-200)" />
                    <SCxConfigurator>
                        <SCxConfiguratorItem justifyContent="space-between" alignItems="center">
                            <SCxLabel>显示面积</SCxLabel>
                            <Switch
                                checked={data.showArea}
                                onChange={e =>
                                    onChange({
                                        ...data,
                                        showArea: e.target.checked
                                    })
                                }
                            />
                        </SCxConfiguratorItem>
                        {/* <SCxConfiguratorItem justifyContent="space-between" alignItems="center">
                            <SCxLabel>显示数据点</SCxLabel>
                            <Switch
                                checked={data.showSymbol}
                                onChange={e =>
                                    onChange({
                                        ...data,
                                        showSymbol: e.target.checked
                                    })
                                }
                            />
                        </SCxConfiguratorItem> */}
                        <SCxConfiguratorItem justifyContent="space-between" alignItems="center">
                            <SCxLabel>线条类型</SCxLabel>
                            <SegmentedControl
                                fullWidth
                                style={{ width: 113 }}
                                value={data.lineType}
                                data={chartLineType}
                                onChange={val =>
                                    onChange({
                                        ...data,
                                        lineType: val as ChartValue['lineType']
                                    })
                                }
                            />
                        </SCxConfiguratorItem>
                    </SCxConfigurator>
                </>
            )
        }
        return null
    }, [data, onChange])

    const chartIcon = useMemo(() => chartTypeList.find(item => item.value === data.chartType)?.icon || '', [data.chartType])

    return (
        <Popover width={250} position="bottom-end">
            <Popover.Target>
                <SCxSetting>
                    <IconFont type="DotsThreeVertical" size={16} fill="var(--color-gray-500)" />
                    <IconFont type={chartIcon} size={16} />
                </SCxSetting>
            </Popover.Target>
            <Popover.Dropdown>
                <BasicHeader style={{ padding: '0 16px' }}>图形类型</BasicHeader>
                <SCxList>
                    {chartTypeList.map(item => (
                        <SCxListItem
                            key={item.value}
                            className={cls({ active: item.value === data.chartType })}
                            justifyContent="center"
                            alignItems="center"
                            onClick={() =>
                                onChange({
                                    ...data,
                                    chartType: item.value
                                })
                            }
                        >
                            <SCxIcon type={item.icon} size={20} />
                            <SCxText>{item.label}</SCxText>
                        </SCxListItem>
                    ))}
                </SCxList>
                {configurator}
                <Divider my={8} color="var(--color-gray-200)" />
                <SCxConfigurator>
                    <SCxActionItem justifyContent="flex-start" alignItems="center" onClick={() => onDelete?.(index)}>
                        <SCxIcon type="Trash" />
                        <SCxLabel>删除</SCxLabel>
                    </SCxActionItem>
                </SCxConfigurator>
            </Popover.Dropdown>
        </Popover>
    )
}
