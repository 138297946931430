import { tinyButtons } from '@lighthouse/bui'
import { Tabs } from '@mantine/core'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'


const SCxPanel = styled(Tabs.Panel)`
    margin-top: 8px;
    position: relative;
    ${tinyButtons}

    & > div {
        margin-bottom: 12px;
    }
`

interface MergePanelProps {
    value: string
    children?: React.ReactNode
}

export const MergePanel: React.FC<MergePanelProps> = ({ value, children }) => {
    const panelRef = useRef<HTMLDivElement>(null)
    const handleScroll = useCallback(() => {
        const modifyElement = panelRef.current
        if (!modifyElement) {
            return
        }
        const { top: panelTop } = modifyElement.getBoundingClientRect()
        const { scrollTop } = modifyElement
        const mergeGroupDoms = modifyElement.querySelectorAll('.merge-group')
        mergeGroupDoms.forEach((mergeGroupDom, index) => {
            const { top } = mergeGroupDom.getBoundingClientRect()
            const itemRealTop = panelTop - top

            const headerDom = mergeGroupDom.querySelector('.merge-header') as HTMLDivElement
            const { height: headerHeight } = headerDom.getBoundingClientRect()
            const endTop = top + scrollTop - headerHeight - 12

            if (itemRealTop >= 0 && itemRealTop <= endTop) {
                //     // const transformTop = panelTop - top
                headerDom.style.transform = `translate3d(0px, ${itemRealTop}px, 0px)`
            }

            if (itemRealTop > endTop) {
                headerDom.style.transform = `translate3d(0px, ${endTop}px, 0px)`
            }

            if (itemRealTop < 0) {
                headerDom.style.transform = 'none'
            }
        })
    }, [])

    useEffect(() => {
        const modifyElement = panelRef.current
        if (!modifyElement) {
            return
        }
        modifyElement.addEventListener('scroll', handleScroll)
        return () => {
            modifyElement.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])


    return (
        <SCxPanel value={value} pt="xs" ref={panelRef}>
            {children}
        </SCxPanel>
    )
}
