import { SegmentedControl, Slider } from '@byecode/ui'
import type { CustomViewOptions } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { DIRECTION_OPTIONS, HORIZONTAL_FLEX_LAYOUT_OPTIONS, VERTICAL_FLEX_LAYOUT_OPTIONS } from '@/components/DesignSetting/LayoutSetting'

export const CardLayout: React.FC = () => {
    const { control, getValues } = useFormContext<CustomViewOptions>()

    const direction = getValues('design.direction')

    return (
        <Group4ByecodeUi label="卡片布局">
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>方向</Text>
                <Controller
                    control={control}
                    name="design.direction"
                    render={({ field }) => <SegmentedControl {...field} fullWidth data={DIRECTION_OPTIONS} style={{ width: 180 }} />}
                />
            </ListItem4ByecodeUi>

            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>水平边距</Text>
                <Controller
                    name="design.padding"
                    control={control}
                    render={({ field }) => (
                        <Slider
                            style={{ width: 180 }}
                            min={0}
                            max={12}
                            step={0.125}
                            value={field.value?.[0]}
                            onChange={v => {
                                const [, top = 0, , bottom = 0] = field.value || []
                                field.onChange([v, top, v, bottom])
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>垂直边距</Text>
                <Controller
                    name="design.padding"
                    control={control}
                    render={({ field }) => (
                        <Slider
                            style={{ width: 180 }}
                            min={0}
                            max={12}
                            step={0.125}
                            value={field.value?.[1]}
                            onChange={v => {
                                const [left = 0, , right = 0] = field.value || []
                                field.onChange([left, v, right, v])
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>间距</Text>
                <Controller
                    name="design.gap"
                    control={control}
                    render={({ field }) => (
                        <Slider style={{ width: 180 }} min={0} max={12} step={0.125} value={field.value} onChange={field.onChange} />
                    )}
                />
            </ListItem4ByecodeUi>
            {!direction || direction === DIRECTION.vertical ? (
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <Text>对齐</Text>
                    <Controller
                        control={control}
                        name="design.alignX"
                        render={({ field }) => (
                            <SegmentedControl {...field} fullWidth data={VERTICAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                        )}
                    />
                </ListItem4ByecodeUi>
            ) : (
                <>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>纵向对齐</Text>
                        <Controller
                            control={control}
                            name="design.alignY"
                            render={({ field }) => (
                                <SegmentedControl {...field} fullWidth data={HORIZONTAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>横向对齐</Text>
                        <Controller
                            control={control}
                            name="design.alignX"
                            render={({ field }) => (
                                <SegmentedControl {...field} fullWidth data={VERTICAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                </>
            )}
        </Group4ByecodeUi>
    )
}
