import { IconFont } from '@byecode/ui'
import { Checkbox } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback } from 'react'
import styled from 'styled-components'

export interface TableCheckerProps {
    index?: number
    checked?: boolean
    className?: string
    disableSelect?: boolean
    onChange?: (checked: boolean) => void
}

const TableRecordIndex = styled.p`
    color: var(--color-gray-400);
    display: block;
`

const TableRecordCheckerWrapper = styled(Checkbox)`
    justify-content: center;
    align-items: center;
    display: none;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-gray-400);
`

const TableCheckerWrapper = styled.div`
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-height: 40px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
    z-index: 200;

    &.checked,
    &:not(.table-check):hover {
        ${TableRecordCheckerWrapper} {
            display: flex;
        }
        ${TableRecordIndex} {
            display: none;
        }
        ${SCxIcon} {
            display: none;
        }
    }
`

export const TableChecker: React.FC<TableCheckerProps> = ({ index, checked, className = '', disableSelect, onChange }) => {
    const handleChange = useCallback(() => {
        if (disableSelect) {
            return
        }
        onChange?.(!checked)
    }, [checked, disableSelect, onChange])
    return (
        <TableCheckerWrapper className={cls({ checked }, { [className]: disableSelect })} onClick={handleChange}>
            {index === undefined ? <SCxIcon type="PropertyNumber" /> : <TableRecordIndex>{index + 1}</TableRecordIndex>}
            <TableRecordCheckerWrapper size="xs" checked={checked} onChange={handleChange} />
        </TableCheckerWrapper>
    )
}
