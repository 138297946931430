import { singleTextEllipsis } from '@byecode/ui'
import { Box } from '@byecode/ui/components/Box'
import { Button } from '@byecode/ui/components/Button'
import { CheckBoxGroup } from '@byecode/ui/components/Checkbox'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Popover } from '@byecode/ui/components/Popover'
import { Tooltip } from '@byecode/ui/components/Tooltip'
import { spaceVersionEnum } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { VersionOverlay } from '@/components/SpaceGrade'
import * as srv from '@/services'

import { Card } from '../components'
import type { ConditionType } from '../constants'
import { CONDITION_OPTIONS, CONDITION_TYPE } from '../constants'

const Header = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 4px;
`
const Title = styled(Box)`
    font-size: 14px;
    line-height: 22px;
    ${singleTextEllipsis()}
`
const Value = styled(Box)`
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 8px 0;
`
const Helper = styled(IconFont)`
    opacity: 0;
    cursor: pointer;
    color: var(--color-gray-400);
    &:hover {
        color: var(--color-gray-900);
    }
`
const ItemContainer = styled.div`
    overflow: hidden;
    &:hover {
        ${Helper} {
            opacity: 1;
        }
    }
`

interface ItemProps extends React.ComponentPropsWithRef<'div'> {
    title: string
    tooltip?: string
    value: string | number | undefined
}
const Item: FC<ItemProps> = ({ title, tooltip, value, ...rest }) => {
    return (
        <ItemContainer {...rest}>
            <Header>
                <Title>{title}</Title>
                <Tooltip
                    title={tooltip}
                    styles={{
                        tooltip: {
                            maxWidth: 300
                        }
                    }}
                >
                    <Helper type="Question" />
                </Tooltip>
            </Header>
            <Value>{value}</Value>
        </ItemContainer>
    )
}

interface CoreIndicatorsProps {
    defaultChecked?: ConditionType[]
}

export const CoreIndicators = ({ defaultChecked }: CoreIndicatorsProps) => {
    const [open, setOpen] = useState(false)

    const [checkedTemp, setCheckedTemp] = useState<ConditionType[]>(defaultChecked || [])
    const [ensureChecked, setEnsureChecked] = useState<ConditionType[]>(defaultChecked || [])

    const res = useAsyncRetry(() => srv.fetchAnalysisCore(), [])

    return (
        <VersionOverlay spaceVersion={spaceVersionEnum.BASIC}>
            <Card
                loading={res.loading}
                title="核心指标"
                extra={
                    <Popover width="auto" position="top-start" opened={open} onChange={setOpen}>
                        <Popover.Target>
                            <Button icon={<IconFont type="Setting" />}>指标设置</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Box px={16}>
                                <CheckBoxGroup
                                    value={checkedTemp}
                                    onChange={v => setCheckedTemp(v as ConditionType[])}
                                    size="xs"
                                    options={CONDITION_OPTIONS}
                                />
                                <Flex gap="12px" py={8} mt={8}>
                                    <Button
                                        style={{ flex: 1 }}
                                        onClick={() => {
                                            setCheckedTemp(ensureChecked)
                                            setOpen(false)
                                        }}
                                    >
                                        取消
                                    </Button>
                                    <Button
                                        style={{ flex: 1 }}
                                        type="primary"
                                        onClick={() => {
                                            setEnsureChecked(checkedTemp)
                                            setOpen(false)
                                        }}
                                    >
                                        确定
                                    </Button>
                                </Flex>
                            </Box>
                        </Popover.Dropdown>
                    </Popover>
                }
            >
                <Flex wrap="wrap" styles={{ root: { rowGap: 40 } }}>
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.pv)}
                        title="浏览次数"
                        tooltip="即通常说的PV（PageView）值，用户每打开1个网站页面，记录1个PV。用户多次打开同一页面PV累计多次。"
                        value={res.value?.pv.toLocaleString('en') ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.uv)}
                        title="独立访客"
                        tooltip="1天（00:00-24:00）之内，访问网站的不重复用户数（以浏览器cookie为依据），一天内同一访客多次访问网站只被计算1次。"
                        value={res.value?.uv.toLocaleString('en') ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.ip)}
                        title="IP"
                        tooltip="1天（00:00-24:00）之内，访问网站的不重复IP数。一天内相同IP地址多次访问网站只被计算1次。"
                        value={res.value?.ip.toLocaleString('en') ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.visits)}
                        title="访问次数"
                        tooltip="访客从进入网站到离开网站的一系列活动记为一次访问，也称会话(Session),1次访问可能包含多个PV。"
                        value={res.value?.visits.toLocaleString('en') ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.avgPv)}
                        title="人均浏览页数"
                        tooltip="平均每个独立访客产生的PV。人均浏览页数=浏览次数/独立访客。体现网站对访客的吸引程度。"
                        value={res.value?.avgPv.toFixed(2) ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.avgDepth)}
                        title="平均访问深度"
                        tooltip="平均每次访问产生的PV。平均访问深度=浏览次数/访问次数。体现网站对访客的吸引程度。"
                        value={res.value?.avgDepth.toFixed(2) ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.avgDuration)}
                        title="平均访问时长"
                        tooltip="平均每次访问在网站上的停留时间。平均访问时长=访问时长/访问次数。体现网站对访客的吸引程度。（PS：访问中最后1个页面的打开时间与访问开始时间之差。体现网站对访客的吸引程度。）"
                        value={res.value?.avgDuration.toFixed(2) ?? 0}
                    />
                    <Item
                        style={{ flex: ensureChecked.length >= 4 ? '0 0 25%' : 1 }}
                        hidden={!ensureChecked.includes(CONDITION_TYPE.bounceRate)}
                        title="跳出率"
                        tooltip="仅浏览了入口页面就离开网站的访问次数占总访问次数的比率。跳出率=只访问了入口页面即退出的次数/入口页面访问次数。（PS：跳出指仅浏览了1个页面就离开网站的访问行为，跳出次数越多则访客对网站兴趣越低或站内入口质量越差；）跳出率是用来评估落地页的质量"
                        value={`${((res.value?.bounceRate ?? 0) * 100).toFixed(2)}%`}
                    />
                </Flex>
            </Card>
        </VersionOverlay>
    )
}
