import type { Workflow } from '@lighthouse/shared'
import { atom } from 'jotai'

import * as srv from '@/services'

import { applyDraftPayload } from '../utils/applyDraftPayload'
import { workflowListAtom } from './state'

export const updateWorkflowListAtom = atom(null, async (_, set, payload?: Workflow[]) => {
    const data = await srv.listFlowNodes()
    set(workflowListAtom, payload || data)
})

export const fetchWorkflowAtom = atom(null, async (_, set, id: string) => {
    const data = await srv.fetchFlow(id)
    set(workflowListAtom, draft => {
        const flow = draft.find(item => item.id === id)
        applyDraftPayload(flow, data)
    })
    return data
})
