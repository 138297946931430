import { IconFont, Text, Toast } from '@byecode/ui'
import copyToClipboard from 'copy-to-clipboard'
import React from 'react'
import styled from 'styled-components'

const StyledUrlBox = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 6px;
    padding: 10px 12px;
    border-radius: 7px;
    background-color: var(--color-gray-100);
`

const ActionIcon = styled(IconFont)`
    cursor: pointer;
    font-size: 16px;
    color: var(--color-gray-400);
`

interface StyledUrlProps {
    shareUrl: string
}

export const StyledUrl: React.FC<StyledUrlProps> = ({ shareUrl }) => {
    return (
        <StyledUrlBox>
            <Text ellipsis size={12} lineHeight="16px" styles={{ root: { flex: 1 } }}>
                {shareUrl}
            </Text>
            <ActionIcon
                type="Copy"
                onClick={() => {
                    if (!shareUrl) {
                        return
                    }
                    const isCopied = copyToClipboard(shareUrl)
                    if (isCopied) {
                        Toast.success('复制成功')
                    }
                }}
            />
            <ActionIcon
                type="ArrowSquareIn"
                onClick={() => {
                    if (!shareUrl) {
                        return
                    }
                    window.open(shareUrl)
                }}
            />
        </StyledUrlBox>
    )
}
