import type { DraggableAttributes } from '@dnd-kit/core'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import type { ApplicationSettingNavbarButton, PageAbstract } from '@lighthouse/core'
import { ButtonSetting } from '@lighthouse/shared'
import { Collapse } from '@mantine/core'
import React, { useState } from 'react'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import * as CM from '../../styles'
import * as SC from './styles'

export interface NavBarBaseButtonProps {
    allPages: PageAbstract[]
    value: ApplicationSettingNavbarButton
    disabled?: boolean
    disableDrag?: boolean
    level?: number
    removeIcon?: string
    disableHandleEvent?: boolean
    activeId?: string
    style?: React.CSSProperties

    onChange?: (params: ApplicationSettingNavbarButton) => void
    onDelete?: (id: string) => void
    onCreate?: (id: string) => void
    handleDrag?: {
        attributes?: DraggableAttributes
        listeners?: SyntheticListenerMap
    }
}

const NavBarBaseButton = React.forwardRef<HTMLDivElement, NavBarBaseButtonProps>(
    (
        {
            value,
            disabled,
            disableDrag = false,
            level = 0,
            removeIcon = 'Trash',
            activeId,
            onChange,
            onDelete,
            onCreate,
            handleDrag,
            style,
            ...rest
        },
        ref
    ) => {
        const { name, parentId, id } = value
        const appId = useCurrentAppID()
        const envId = useCurrentEnvId()
        const [open, setOpen] = useState(false)
        const dataSourceList = useDataSourceList(appId, envId)

        return (
            <SC.Contain style={style} ref={ref} {...handleDrag?.attributes}>
                <SC.Header>
                    <SC.LeftFill>
                        {disableDrag || (
                            <SC.Handle {...handleDrag?.listeners}>
                                <CM.Icon type="DotsSix" color="var(--color-gray-400)" cursor="grab" />
                            </SC.Handle>
                        )}
                        <SC.Title>{name}</SC.Title>
                    </SC.LeftFill>
                    <SC.RightFill>
                        {onCreate && level < 2 && <CM.Icon type="Add" onClick={() => !disabled && onCreate?.(id)} />}
                        <CM.Icon type={removeIcon} onClick={() => !disabled && onDelete?.(id)} />
                        <CM.Icon type={open ? 'ArrowUpSmall' : 'ArrowDownSmall'} onClick={() => setOpen(!open)} />
                    </SC.RightFill>
                </SC.Header>
                <Collapse in={open && !activeId}>
                    <SC.Content>
                        <ButtonSetting
                            {...rest}
                            disabled={disabled}
                            value={value}
                            dataSourceList={dataSourceList}
                            onFetchAllViews={() => srv.getAllViews()}
                            onChange={val => onChange?.({ ...val, id, parentId })}
                        />
                    </SC.Content>
                </Collapse>
            </SC.Contain>
        )
    }
)

export default NavBarBaseButton
