import { Flex, IconFont, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationPrivateTemplateDevice, ApplicationPrivateTemplateInitDevice } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 118px;
    border-radius: 6px;
    padding: 4px 4px 0 4px;
    border: 1px solid var(--color-gray-200);
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
`

const SCxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxLogoWrapper = styled.div`
    display: flex;
    gap: 3px;
    align-items: center;
`

const SCxLogo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background: #E4E7EC;
`

const SCxLineWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    padding-top: 1px;
`

const SCxSpace = styled.div`
    height: 5px;
    border-radius: 4px;
    background: #eceff4;
`

const SCxTabs = styled(Flex)`
    width: 58px;
    height: 15px;
    border-radius: 4px;
    padding: 1px;
    background: #eceff4;
`

const SCxSCxTab = styled.div`
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--color-white);
`

const SCxSCxTabIcon = styled.div`
    width: 10px;
    height: 7px;
    background: #eceff4;
    border-radius: 2px;
`

const SCxButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0 4px;
    border-radius: 2px;
    background: var(--color-main);
    font-weight: 400;
`

const SCxContent = styled.div`
    margin-top: 7px;
    display: flex;
    justify-content: center;
    flex: 1;
`

const SCxDevice = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    height: 100%;
    border: 8px solid var(--color-gray-200);
    border-bottom: none;
    /* border-radius: 20px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    background-color: var(--color-gray-100);
`

const SCxDeviceHeader = styled.div`
    width: 100%;
    height: 25px;
    background-color: var(--color-gray-400);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
`

const SCxDeviceSpace = styled.div`
    height: 11px;
    border-radius: 6px;
    background: var(--color-gray-200);
`

const SCxMagnifier = styled.div`
    position: absolute;
    z-index: 20;
    top: 22px;
    right: 40px;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background-color: #f9fafb;
    border: 4px solid var(--color-white);
    padding-top: 18px;
    overflow: hidden;
`

const SCxShadow = styled(Image)`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
`

const SCxLine = styled.div`
    width: 100%;
    height: 0.5px;
    margin-bottom: 6px;
    background-color: var(--color-gray-200);
`

const SCxButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 2px;
`

const SCxPointer = styled(Image)`
    position: absolute;
    top: 70px;
    right: 52px;
    z-index: 30;
`

const pointerImage = getAssetUrl('application', 'pointer.png')
const shadowImage = getAssetUrl('application', 'shadow.png')

interface PreviewImageProps {
    mode?: ApplicationPrivateTemplateDevice
    initMode?: ApplicationPrivateTemplateInitDevice
}

export const PreviewImage: React.FC<PreviewImageProps> = ({ mode = 'all', initMode = 'desktop' }) => {
    const isInitMobile = initMode === 'mobile'

    const device = useMemo(() => {
        if(mode === 'all'){
            return initMode
        }
        return mode
    }, [initMode, mode])

    const deviceWidth = useMemo(() => {
        if(device === 'desktop'){
            return 302
        }
        return 190
    }, [device])

    return (
        <SCxContainer>
            <SCxHeader>
                <SCxLogoWrapper>
                    <SCxLogo />
                    <SCxLineWrapper>
                        <SCxSpace style={{ width: '15px' }} />
                        <SCxSpace style={{ width: '24px' }} />
                    </SCxLineWrapper>
                </SCxLogoWrapper>
                {mode === 'all' && (
                    <SCxTabs justifyContent={isInitMobile ? 'flex-start' : 'flex-end'}>
                        <SCxSCxTab>
                            <SCxSCxTabIcon />
                        </SCxSCxTab>
                    </SCxTabs>
                )}
                <SCxButton style={{ height: '14px' }}>
                    <IconFont fill="var(--color-white)" size={6} type="Duplicate" />
                    <Text color="var(--color-white)" size={5}>
                        复制应用
                    </Text>
                </SCxButton>
            </SCxHeader>
            <SCxContent>
                <SCxDevice width={deviceWidth}>
                    <SCxDeviceHeader>
                        <SCxDeviceSpace style={{ width: '22px', height: '8px' }} />
                        <SCxDeviceSpace style={{ width: '49px' }} />
                        <SCxDeviceSpace style={{ width: '16px', height: '8px' }} />
                    </SCxDeviceHeader>
                </SCxDevice>
            </SCxContent>
            <SCxShadow src={shadowImage} width={77} />
            <SCxMagnifier>
                <SCxLine />
                <SCxButtonWrapper>
                    <SCxButton style={{ width: '52px', height: '20px' }}>
                        <IconFont fill="var(--color-white)" size={8} type="Duplicate" />
                        <Text color="var(--color-white)" size={7.5}>
                            复制应用
                        </Text>
                    </SCxButton>
                </SCxButtonWrapper>
            </SCxMagnifier>
            <SCxPointer src={pointerImage} width={42} />
        </SCxContainer>
    )
}
