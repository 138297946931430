import { Toast } from '@byecode/ui'
import type { ModeProperty } from '@lighthouse/shared'
import { AccountBox, AccountLayout, ApplicationPreviewEnum, errorCodeMap, getApplicationPreviewType } from '@lighthouse/shared'
import { isWechatBrowser } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Auth } from './Auth'
// import { modeProperty } from './constants'
import { Forget } from './Forget'
import { ResetSuccess } from './Forget/ResetSuccess'
import { Login } from './Login'
import { Register } from './Register'
import * as SC from './styles'
import type { Mode } from './types'

export const Account: React.FC = () => {
    const isWechat = isWechatBrowser()
    const isMobile = getApplicationPreviewType() === ApplicationPreviewEnum.mobile

    const isWechatLogin = useMemo(() => {
        if (isMobile) {
            return isWechat
        }
        return true
    }, [isMobile, isWechat])
    const location = useLocation()
    const [mode, setMode] = useState<Mode>(isWechatLogin ? 'wechat' : 'login')
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const codeRef = useRef(code)

    const inviteCode = queryParams.get('invite_code')

    useEffect(() => {
        if (codeRef.current) {
            const msg = errorCodeMap?.[codeRef.current as keyof typeof errorCodeMap]
            if (msg) {
                Toast.warning(msg)
            }
        }
    }, [])

    const handleChangeMode = useCallback((value: Mode) => {
        setMode(value)
    }, [])

    // const logoDescribe = useMemo(() => (
    //     <>
    //         没有账号？<SC.FreeRegister onClick={() => handleChangeMode('register')} type='text'>免费注册</SC.FreeRegister>
    //     </>
    // ), [handleChangeMode])

    const modeProperty: Record<Mode, ModeProperty> = useMemo(() => {
        const logoDescribe = (
            <>
                没有账号？
                <SC.FreeRegister onClick={() => handleChangeMode('register')} type="text">
                    免费注册
                </SC.FreeRegister>
            </>
        )
        const wechatDescribe = (
            <>
                <SC.Text>请使用微信扫码关注公众号即可登录</SC.Text>
                <SC.Text>首次登录需绑定手机号</SC.Text>
            </>
        )
        return {
            login: {
                title: '账号密码登录',
                describe: logoDescribe
            },
            register: {
                // title: `欢迎来到${BRAND_INFO_MAP[getBrand()].account.keyword} 👋`,
                title: '验证码登录/注册',
                describe: '首次登录将自动注册一个账号'
            },
            verification: {
                title: '找回密码',
                describe: ''
            },
            resetPassword: {
                title: '重置密码',
                describe: ''
            },
            resetSuccess: {
                title: '',
                describe: ''
            },
            wechat: {
                title: '微信登录/注册',
                describe: wechatDescribe
            },
            bind: {
                title: '绑定手机号',
                describe: '首次使用微信登录需绑定手机号'
            }
        }
    }, [handleChangeMode])

    const content = useMemo(() => {
        switch (mode) {
            case 'login': {
                return <Login onChangeMode={handleChangeMode} />
            }
            case 'register': {
                return <Register inviteCode={inviteCode} onChangeMode={handleChangeMode} />
            }
            case 'verification':
            case 'resetPassword': {
                return <Forget onChangeMode={handleChangeMode} mode={mode} />
            }
            case 'resetSuccess': {
                return <ResetSuccess onChangeMode={handleChangeMode} />
            }
            case 'bind':
            case 'wechat': {
                return <Auth inviteCode={inviteCode} mode={mode} onChangeMode={handleChangeMode} />
            }
            default: {
                return null
            }
        }
    }, [handleChangeMode, inviteCode, mode])

    return (
        <AccountLayout>
            <AccountBox {...modeProperty[mode]} justifyContent="center">
                {content}
            </AccountBox>
        </AccountLayout>
    )
}
