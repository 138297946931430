import React from 'react'

import * as SC from './styles'

interface AccountLayoutProps {
    children?: React.ReactNode
}

export const MobileAccountLayout: React.FC<AccountLayoutProps> = ({ children }) => {
    return (
        <SC.Container>
            {/* <SC.IntroduceContainer>
                <SC.IntroduceWrapper>
                    <SC.IntroduceTitleHeader>
                        <SC.GradientText>自主实现</SC.GradientText>
                        <Flex direction="column" gap={6}>
                            <Text
                                size={16}
                                align="center"
                                style={{ lineHeight: '22px', letterSpacing: 3 }}
                                weight={800}
                                color="var(--color-gray-800)"
                            >
                                营销场景 & 内部管理
                            </Text>
                            <Text size={16} weight={800} style={{ lineHeight: '22px', letterSpacing: 3 }}>
                                应用互联
                            </Text>
                        </Flex>
                        <Flex direction="column">
                            <Flex>
                                <Text color="var(--color-black)" weight={700} size={10}>
                                    无需编程
                                </Text>
                                <Text size={10} weight={500} align="center" color="var(--color-gray-400)">
                                    ,任何人都能随心搭建
                                </Text>
                            </Flex>
                            <Text size={10} weight={500} color="var(--color-gray-400)">
                                令人惊艳的AI 驱动业务应用
                            </Text>
                        </Flex>
                    </SC.IntroduceTitleHeader>
                </SC.IntroduceWrapper>
            </SC.IntroduceContainer> */}
            <SC.FormContainer>{children}</SC.FormContainer>
        </SC.Container>
    )
}
