import { NavigationShowMode } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div<{ active?: boolean }>`
    width: 100%;
    height: 60px;
    border: ${({ active }) => (active ? '2px solid var(--color-main)' : '1px solid var(--color-gray-200)')};
    padding: ${({ active }) => (active ? 0 : '1px')};
    border-radius: 5px;
    overflow: hidden;
`

const SCxWrapper = styled.div`
    width: 100%;
    /* padding: 16px 0 0 16.5px; */
    position: relative;
    height: 100%;
`

const SCxContent = styled.div<{ boxShadow?: string } & Pick<React.CSSProperties, 'flexDirection'>>`
    position: absolute;
    top: 16px;
    left: 16.5px;
    width: 100%;
    height: 100%;
    box-shadow: ${({ boxShadow }) => boxShadow || '-1px -1px 4px 0px #0d19fc12'};
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
    overflow: hidden;
    border-top-left-radius: 6px;
    /* box-shadow: -1px -1px 0.7px 0px #8A8CB924; */
    background: var(--color-white);
    /* background: linear-gradient(137.8deg, #e4f2ff 4.58%, #fcefff 40.23%, #e6effb 75.88%); */
`

const SCxHorizontalHeader = styled.div<{ color?: string }>`
    height: 13px;
    border-top-left-radius: 5px;
    background-color: ${({ color }) => color || 'var(--color-main)'};
    display: flex;
    align-items: center;
    padding-left: 4px;
`

const SCxVerticalHeader = styled.div<{ color?: string }>`
    width: 20px;
    height: 100%;
    background: ${({ color }) => color || 'var(--color-main)'};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;
    gap: 10px;
`

const SCxIcon = styled.div<{ color?: string }>`
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background-color: ${({ color }) => color || 'var(--color-white)'};
`

const SCxBandList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5px;
`

const SCxBand = styled.div<{ color?: string }>`
    width: 10px;
    height: 1.5px;
    border-radius: 2px;
    background-color: ${({ color }) => color || 'var(--color-white)'};
`

const SCxBody = styled.div<{ color?: string }>`
    flex: 1;
    background: ${({ color }) => color || 'var(--color-white)'};
`

interface AppStyleProps {
    navigationShowMode: NavigationShowMode
    active?: boolean
    iconColor?: string
    navBarColor?: string
    contentColor?: string
    boxShadow?: string
    contentStyle?: React.CSSProperties
}

export const AppStyle: React.FC<AppStyleProps> = ({
    navigationShowMode,
    active,
    iconColor,
    navBarColor,
    contentColor,
    boxShadow,
    contentStyle
}) => {
    const header = useMemo(() => {
        if (navigationShowMode === NavigationShowMode.horizontal) {
            return (
                <>
                    <SCxHorizontalHeader color={navBarColor}>
                        <SCxIcon color={iconColor} />
                    </SCxHorizontalHeader>
                    <SCxBody color={contentColor} />
                </>
            )
        }
        return (
            <>
                <SCxVerticalHeader color={navBarColor}>
                    <SCxIcon color={iconColor} />
                    <SCxBandList>
                        <SCxBand color={iconColor} />
                        <SCxBand color={iconColor} />
                        <SCxBand color={iconColor} />
                    </SCxBandList>
                </SCxVerticalHeader>
                <SCxBody color={contentColor} />
            </>
        )
    }, [contentColor, iconColor, navBarColor, navigationShowMode])

    return (
        <SCxContainer active={active}>
            <SCxWrapper>
                <SCxContent
                    boxShadow={boxShadow}
                    style={contentStyle}
                    flexDirection={navigationShowMode === NavigationShowMode.horizontal ? 'column' : 'row'}
                >
                    {header}
                    <SCxBody color={contentColor} />
                </SCxContent>
            </SCxWrapper>
        </SCxContainer>
    )
}
