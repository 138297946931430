import type { FilterOption } from '@lighthouse/core'
import {
    type AppUser,
    type FilterCommonCondition,
    type GroupConfigure,
    type GroupRecordCount,
    type GroupTab,
    type Sorter,
    type ViewBlockAbstract,
    DIRECTION
} from '@lighthouse/core'
import {
    getFilterBlockItemIdsInFilter,
    resolveFilter,
    useAtomAction,
    useAtomData,
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'

import { filterBlockOptionsAtom, viewRecordListAtomFamily } from '@/atoms/blockRecordsDict/state'
import { blockCreatingListAtom, blockUpdateListAtom, pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily } from '@/atoms/storage/state'
import { viewTypeMap } from '@/atoms/utils/block'

import type { FetchViewDataPayload, GetGroupRecordCountPayload, ReloadDataPayload } from './types'

interface UseViewBlockConfigPayload {
    appId: string
    envId: string
    blockData: ViewBlockAbstract
    // initGroup: GroupConfigure
    groupConfigure: GroupConfigure
    quickFiltersRule: FilterCommonCondition[]
    searchVal: string
    sortCache: Sorter[]
    tabValue: string
    initTabList: GroupTab[]
    tabCountList: GroupRecordCount[]
    tabList: GroupTab[]
    personOptions: AppUser[]
    pageId: string
    currentRecordId?: string
    pageSize?: number
    // setGroupCache: (payload: GroupConfigure) => void
    setQuickFilterCache: (payload: FilterCommonCondition[] | undefined) => void
    setTabList: React.Dispatch<React.SetStateAction<GroupTab[]>>
    setTabValue: React.Dispatch<React.SetStateAction<string>>
    getGroupRecordCount: (params: GetGroupRecordCountPayload) => Promise<void>
    fetchViewData: (params: FetchViewDataPayload) => Promise<void>
}

export const useViewBlockConfig = (props: UseViewBlockConfigPayload) => {
    const {
        appId,
        envId,
        blockData,
        groupConfigure,
        quickFiltersRule,
        searchVal,
        sortCache,
        tabValue,
        initTabList,
        tabCountList,
        tabList,
        personOptions,
        pageId,
        currentRecordId,
        pageSize,
        setQuickFilterCache,
        setTabList,
        setTabValue,
        getGroupRecordCount,
        fetchViewData
    } = props
    const { id, config } = blockData
    const { pointer, filter, sorts, viewFieldSettings, viewType, pagination, quickFilter, direction, linkFilterController } = config
    const isMountedRef = useRef<boolean>(true)
    const groupByFieldIdRef = useRef(groupConfigure.groupByFieldId)
    const urlRef = useRef(false)
    const urlLocation = useLocation()
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterBlockIds = useMemo(() => filterBlockItemIds.map(item => item.blockId), [filterBlockItemIds])
    const filterValue = useAtomValue(filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: currentRecordId, filterBlockItemIds }))
    const filterBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(
            s => s[pageId]?.filter(block => block.type === 'filter' && filterBlockIds.includes(block.id)) || [],
            [filterBlockIds, pageId]
        )
    )
    const hasNeedUpdateYet = useAtomData(blockUpdateListAtom, useCallback(s => s.includes(id), [id]))

    const usedFilterOptions = useMemo(() => {
        return filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})
    }, [filterBlockItemIds, filterOptions])
    const [localFilterOptions, setLocalFilterOptions] = useState(usedFilterOptions)

    const { run: setPageBlocks } = useAtomAction(pageBlocksAtom)
    const { run: setViewRecordList } = useAtomAction(viewRecordListAtomFamily(id))

    const viewTypeRef = useRef(viewType)
    const hasNotCreatedYet = useAtomData(
        blockCreatingListAtom,
        useCallback(s => s.includes(id), [id])
    )

    const reloadData = useCallback(
        (params: ReloadDataPayload) => {
            const { groupConfigure, quickFilters, linkFilter, search, pageNum} = params
            if (groupConfigure?.groupByFieldId) {
                getGroupRecordCount({ groupConfigure, quickFilters, linkFilter, search })
                return
            }
            fetchViewData({
                quickFilters,
                linkFilter,
                sorts: sortCache,
                tab: tabValue,
                search,
                pageNum
            })
        },
        [fetchViewData, getGroupRecordCount, sortCache, tabValue]
    )

    const resolvedFilter = useMemo(() => {
        if (!linkFilterController) {
            return
        }
        return resolveFilter({
            filter: linkFilterController,
            extraParams: {
                filterBlockParams: {
                    filterValue,
                    filterOptions: localFilterOptions,
                    filterBlocks
                }
            }
        })
    }, [linkFilterController, filterValue, localFilterOptions, filterBlocks])

    const configRef = useRef({
        resolvedFilter,
        filter,
        sorts,
        viewFieldSettings
    })

    // 筛选控制器option变动
    useUpdateEffect(() => {
        if (equal(localFilterOptions, usedFilterOptions)) {
            return
        }
        setLocalFilterOptions(usedFilterOptions)
    }, [usedFilterOptions])

    useEffect(() => {
        if (hasNotCreatedYet || !isMountedRef.current) {
            return
        }
        isMountedRef.current = false
        reloadData({groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal, pageNum: 1})
    }, [fetchViewData, getGroupRecordCount, groupConfigure, hasNotCreatedYet, quickFiltersRule, reloadData, resolvedFilter, searchVal, sortCache, tabValue])

    // 切换回页面进行数据请求
    useUpdateEffect(() => {
        if (urlLocation.pathname.includes('/page')) {
            if (urlRef) {
                reloadData({ groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal })
            }
            urlRef.current = false
        }
        urlRef.current = true
    }, [urlLocation.pathname])

    // 监听filter
    useUpdateEffect(() => {
        if (equal(configRef.current.resolvedFilter, resolvedFilter) && equal(configRef.current.filter, filter)) {
            return
        }
        configRef.current.resolvedFilter = resolvedFilter
        configRef.current.filter = filter
        reloadData({
            groupConfigure,
            quickFilters: quickFiltersRule,
            linkFilter: resolvedFilter,
            search: searchVal,
            pageNum: 1
        })
    }, [filter, resolvedFilter])

    //  监听sorts
    useUpdateEffect(() => {
        if (equal(configRef.current.sorts, sorts)) {
            return
        }
        configRef.current.sorts = sorts
        // configRef.current.viewFieldSettings = viewFieldSettings
        reloadData({
            groupConfigure,
            quickFilters: quickFiltersRule,
            linkFilter: resolvedFilter,
            search: searchVal
        })
    }, [sorts])

    useUpdateEffect(() => {
        if (viewTypeRef.current !== viewType) {
            if (viewTypeMap[viewType] !== viewTypeMap[viewTypeRef.current]) {
                fetchViewData({ quickFilters: quickFiltersRule, linkFilter: resolvedFilter, sorts: sortCache, tab: tabValue, search: searchVal, pageNum: pagination.currentPage })
            }
            viewTypeRef.current = viewType
        }
    }, [viewType])

    // 当数据查看器进行增删改时 需要重载
    useUpdateEffect(() => {
        if (hasNeedUpdateYet) {
            fetchViewData({ quickFilters: quickFiltersRule, linkFilter: resolvedFilter, sorts: sortCache, tab: tabValue, search: searchVal, pageNum: 1 })
        }
    }, [hasNeedUpdateYet])

    useUpdateEffect(() => {
        fetchViewData({
            quickFilters: quickFiltersRule,
            linkFilter: resolvedFilter,
            sorts: sortCache,
            tab: tabValue,
            search: searchVal,
            pageNum: 1
        })
    }, [pointer, pagination.pageSize])

    // 切换 用户允许是否使用分组
    // useUpdateEffect(() => {
    //     getGroupRecordCount(groupCache, quickFiltersRule, searchVal)
    // }, [groupCache?.groupByFieldId, pointer])

    useUpdateEffect(() => {
        getGroupRecordCount({ groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal })
    }, [groupConfigure?.groupByFieldId])

    // useUpdateEffect(() => {
    //     setGroupCache(initGroup)
    // }, [initGroup])

    // 选中的分组被隐藏
    useUpdateEffect(() => {
        if (!tabCountList || tabCountList.length === 0) {
            return
        }
        const list = initTabList.reduce<GroupTab[]>((list, item) => {
            const data = find(v => v.value === item.value, tabCountList)
            const person = find(v => v.userId === item.value, personOptions)
            if (person?.isDepart) {
                return list
            }

            if (groupConfigure?.canHiddenTabValue && data?.count === 0) {
                return list
            }
            list.push({
                label: person?.isDepart ? `${item.label}(已注销)` : item.label,
                value: item.value,
                count: data?.count
            })
            return list
        }, [])
        if(!equal(tabList, list) || groupByFieldIdRef.current !== groupConfigure?.groupByFieldId){
            groupByFieldIdRef.current = groupConfigure?.groupByFieldId
            setTabList(list)
        }
    }, [initTabList, tabCountList, groupConfigure.canHiddenTabValue])

    // 选中的分组已不再分组列表中,进行切换
    useUpdateEffect(() => {
        const firstTab = tabList?.[0]?.value || ''
        if (groupConfigure?.groupByFieldId) {
            // setTabValue(firstTab)
            const tab = tabValue || firstTab
            setTabValue(tab)
            fetchViewData({
                quickFilters: quickFiltersRule,
                sorts: sortCache,
                linkFilter: resolvedFilter,
                tab,
                search: searchVal,
                pageNum: 1
            })
            return
        }
        const tab = find(v => v.value === tabValue, tabList)
        if (!tab && firstTab !== tabValue) {
            setTabValue(firstTab)
            fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab: firstTab,
                search: searchVal,
                pageNum: 1
            })
            return
        }

        if (tabList.length === 0 && tabCountList && tabCountList?.length > 0) {
            fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab: '',
                search: searchVal,
                pageNum: 1
            })
        }
    }, [tabList, groupConfigure?.groupByFieldId])

    useUpdateEffect(() => {
        if (quickFilter.mode === 'disable') {
            setQuickFilterCache(undefined)
            reloadData({ groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal, pageNum: 1 })
        }
    }, [quickFilter.mode])

    const [extraLoading, setExtraLoading] = useState(false)

    // 自定义视图从九宫格水平模式切换为其他模式
    useUpdateEffect(() => {
        ; (async () => {
            if (direction === DIRECTION.vertical) {
                setViewRecordList(s => {
                    return s.slice(0, pageSize)
                })

                setPageBlocks(draft => {
                    const currentBlocks = draft[pageId]
                    if (!currentBlocks) {
                        return
                    }

                    const draftBlock = currentBlocks.find(item => item.id === id)
                    if (!draftBlock) {
                        return
                    }

                    if (draftBlock.type !== 'view') {
                        return
                    }

                    draftBlock.config.pagination = {
                        ...pagination,
                        currentPage: 1
                    }
                })
                return
            }

            setExtraLoading(true)
            // setViewRecordList(s => [])
            await fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab: tabValue,
                search: searchVal,
                pageNum: 1
            })
            setExtraLoading(false)
        })()
    }, [direction])

    return { extraLoading, reloadData, resolvedFilter }
}
