import { getAssetUrl } from '@lighthouse/assets'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
body {
    @font-face {
        font-family: "DIN-Alternate-Bold";
        src: url(${getAssetUrl('font', 'DIN-Alternate-Bold.woff2')}) format('woff');
    }
}
`
