import { IconFont, Input, pointer, TextArea } from '@byecode/ui'
import styled, { css } from 'styled-components'

const itemCommonStyle = css`
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: var(--font-size-normal);
`

export const SettingPopoverWrapper = styled.div`
    min-width: 220px;
    padding: 12px 0;
`

export const TitleInput = styled(Input)`
    margin: 0 12px 12px 12px;
`

export const DescriptionInput = styled(TextArea)`
    width: 196px;
    margin: 0 12px;
`

export const ListItem = styled.div`
    ${itemCommonStyle}
    height: 36px;

    ${pointer}
    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ListItemIcon = styled(IconFont)`
    color: var(--color-gray-400);
    margin-right: 8px;
`

export const ListItemLabel = styled.div``

export const ListDivider = styled.div`
    height: 1px;
    background-color: var(--color-gray-100);
    margin: 12px 0;
`

export const ListDescriptionWrapper = styled.div`
    ${itemCommonStyle}
    height: 28px;
    justify-content: space-between;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const ListDescriptionItemLabel = styled.div``

export const ListDescriptionItemValue = styled.div``
