import { Tooltip } from '@byecode/ui'
import { Flex, Image, ScrollArea } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

interface BlockCardListProps {
    items: BlockTreeItem[]
    onCreate: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

interface BlockCardProps {
    data: BlockTreeItem
    onCreate?: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

const SCxList = styled(Flex)``

const SCxContainer = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: hidden;
`

const SCxStyleWrapper = styled.div<Pick<React.CSSProperties, 'backgroundColor'>>`
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 16px;
`

const SCxBlock = styled.div<Pick<React.CSSProperties, 'width' | 'height' | 'color'>>`
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    cursor: pointer;
    user-select: none;
    user-drag: none;
    img {
        -webkit-user-drag: none;
    }

    &:hover {
        ${SCxStyleWrapper} {
            background-color: ${({ color }) => color};
            filter: opacity(0.15);
        }
    }
`

const SCxText = styled.div<{ color?: string }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    bottom: 8px;
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-weight: 500;
    font-size: 12px;
`

export const BlockCard: React.FC<BlockCardProps> = ({ data: item, onCreate }) => {
    return (
        <Tooltip title={item.name} disablePortal>
            <SCxBlock width={110} height={110} color={item.color} onMouseDown={e => onCreate?.(e, item)}>
                <SCxStyleWrapper backgroundColor={item.backGroundColor} />
                <Image fit="cover" style={{ zIndex: 2, position: 'relative' }} radius={16} src={`${item.describeImage}?version=1.25.0`} />
                <SCxText color={item.color}>{item.name}</SCxText>
            </SCxBlock>
        </Tooltip>
    )
}

export const BlockCardList: React.FunctionComponent<BlockCardListProps> = ({ items, onCreate }) => {
    return (
        <SCxContainer>
            <ScrollArea scrollbarSize={0}>
                <SCxList gap={12} wrap="wrap">
                    {items.map(item => {
                        return <BlockCard data={item} key={item.name} onCreate={onCreate} />
                    })}
                </SCxList>
            </ScrollArea>
        </SCxContainer>
    )
}
