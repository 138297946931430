import { Input } from '@byecode/ui'
import { MorePopover } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

const SCxListItem = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
`

const SCxInput = styled(Input)`
    flex: 1;

    & input[type='number']::-webkit-outer-spin-button,
    & input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type='number'] {
        -moz-appearance: textfield;
    }
`

interface SettingOptionProps {
    prefixName: string
    onDuplicate: () => void
    onDelete: () => void
}

export const SettingOption: React.FC<SettingOptionProps> = ({ prefixName, onDuplicate, onDelete }) => {
    const { control, setValue } = useFormContext()

    // const min = useWatch({ name: `${prefixName}.value`, control })
    // const max = useWatch({ name: `${prefixName}.value`, control })

    return (
        <SCxListItem>
            <Controller
                name={`${prefixName}.label`}
                render={({ field }) => <SCxInput size="md" placeholder="输入标题" value={field.value} onChange={field.onChange} />}
            />
            <Controller
                name={`${prefixName}.value`}
                render={({ field: { value, onChange } }) => {
                    const [start, end] = value ?? [undefined, undefined]
                    return (
                        <>
                            <SCxInput
                                type="number"
                                size="md"
                                placeholder="最小值"
                                value={start === undefined ? '' : start}
                                onChange={ev => {
                                    const val = ev.target.value
                                    onChange([val ? Number(val) : undefined, end])
                                }}
                                onBlur={e => {
                                    if (end === undefined) {
                                        return
                                    }
                                    const newValue = e.target.value
                                    if (Number(newValue) >= Number(end)) {
                                        onChange([start, Number(newValue)])
                                    }
                                }}
                            />
                            <SCxInput
                                type="number"
                                size="md"
                                placeholder="最大值"
                                value={end === undefined ? '' : end}
                                onChange={ev => {
                                    const val = ev.target.value
                                    onChange([start, val ? Number(val) : undefined])
                                }}
                                onBlur={e => {
                                    const newValue = e.target.value
                                    if (newValue.trim() === '') {
                                        return
                                    }
                                    if (Number(newValue) <= Number(start)) {
                                        onChange([start, Number(start)])
                                    }
                                }}
                            />
                        </>
                    )
                }}
            />
            <MorePopover onDuplicate={onDuplicate} onDelete={onDelete} />
        </SCxListItem>
    )
}
