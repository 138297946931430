import { Button, Input } from '@byecode/ui'
import styled from 'styled-components'

// const iconBox = (): FlattenSimpleInterpolation => css`
//     position: relative;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     padding-left: 8px;
// `

// const tbRow = (): FlattenSimpleInterpolation => css`
//     &.Mui-selected {
//         background-color: #fafafa;
//     }
//     &.Mui-selected:hover {
//         background-color: #fafafa;
//     }
// `

export const Container = styled.div`
    height: 100%;
    background: var(--color-white);
    padding: 40px 36px 24px 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
`

export const SearchBar = styled(Input)`
    width: 240px;
    margin-left: 12px;
`

export const InviteBtn = styled(Button)`
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    border-color: var(--color-main);
    border-radius: 5px;
`

export const MemberTable = styled.div`
    width: 100%;
`

export const MemberHead = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    background: var(--color-gray-100);
    border-radius: 8px;
    overflow: hidden;
`

export const MemberTableBody = styled.div`
    width: 100%;
`

export const MemberRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 52px;
    border-radius: 6px;
    overflow: hidden;

    &:hover {
        background: var(--color-gray-100);
    }
`

export const MemberTableCell = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    min-width: ${({ width }) => (width ? `${width}px` : '200px')};
    flex: ${({ width }) => (width ? 'unset' : 1)};
    overflow: hidden;
    padding: 0 16px;
`

export const UserIconBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    height: 36px;
`

export const UserItemInfo = styled.div`
    margin-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`

export const UserText = styled.div`
    font-size: 14px;
    color: var(--color-gray-900);
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
`

export const UserDate = styled.div`
    font-size: 14px;
    color: var(--color-gray-500);
    font-weight: 400;
`

export const UserMobile = styled.div`
    margin-left: 12px;
    font-size: 12px;
    color: var(--color-gray-500);
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
`

export const TeamNum = styled.div`
    font-size: 14px;
    color: var(--color-gray-400);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 6px;
`

export const TagBox = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
`

export const RoleText = styled.div`
    color: var(--color-gray-400);
    font-size: 14px;
    margin-right: 20px;
`
