import type {
    CalendarViewOptions,
    CustomViewOptions,
    DataSourceAbstract,
    FieldType,
    GalleryViewOptions,
    GroupFieldValue,
    ViewBlockAbstract,
    ViewType
} from '@lighthouse/core'
import {
    ALIGNMENT,
    ARRANGEMENT,
    BACKGROUND_TYPE,
    BlockSize,
    BlockType,
    CUSTOM_VIEW_LAYOUT,
    DIRECTION,
    EDGE_MODE,
    GalleryAvatarShape,
    GalleryAvatarSize,
    POSITION
} from '@lighthouse/core'
import { getIsAppointField, SYSTEM_FIELD, USER_DATASOURCE, VIEW_BLOCK_NAME_MAP } from '@lighthouse/shared'

import { DEFAULT_VIEW_CONFIG } from '@/components/BlockSettings/constants'

import { COMMON_BLOCK_CONFIG } from '../sharedStyle'

/**
 * 生成画廊block的配置
 * @date 2024/4/19 - 22:14:08
 *
 * @param {(undefined | DataSourceAbstract)} dataSource
 * @returns {GalleryViewOptions}
 */
export const generateGalleryConfig = (dataSource: undefined | DataSourceAbstract): GalleryViewOptions => {
    if (!dataSource) {
        return {}
    }

    const fields = Object.values(dataSource.schema)

    // 用户表
    if (dataSource.id === USER_DATASOURCE) {
        const contentFields: GroupFieldValue[] = [
            {
                wrap: true,
                id: 'MOBILE'
            },
            {
                wrap: true,
                id: 'USER_GROUP'
            }
        ]

        return {
            contentTags: {
                fields: contentFields,
                show: true,
                showLabel: false
            },
            footTags: {
                fields: [],
                show: false,
                showLabel: false
            },
            headAvatar: {
                align: ALIGNMENT.left,
                position: POSITION.top,
                shape: GalleryAvatarShape.circle,
                show: false,
                size: GalleryAvatarSize.md
            },
            headCover: {
                field: 'AVATAR',
                objectFit: 'cover',
                ratio: '4:3',
                show: true
            },
            headTags: {
                fields: [],
                show: false,
                showLabel: false
            },
            headTitle: {
                field: 'USERNAME',
                show: true
            }
        }
    }

    // 普通表
    const coverField = fields.find(item => item.type === 'file')
    const textTypeSet = new Set<FieldType>(['text', 'phoneNumber', 'url', 'email'])
    const titleField = fields.find(item => textTypeSet.has(item.type))
    const contentFields = fields
        .reduce<GroupFieldValue[]>((total, current) => {
            if (current.id === 'ID') {
                return total
            }

            return [...total, { id: current.id, wrap: true }]
        }, [])
        .slice(0, 4)

    return {
        contentTags: {
            fields: contentFields,
            show: true,
            showLabel: false
        },
        footTags: {
            fields: [],
            show: false,
            showLabel: false
        },
        headAvatar: {
            align: ALIGNMENT.left,
            position: POSITION.top,
            shape: GalleryAvatarShape.circle,
            show: false,
            size: GalleryAvatarSize.md
        },
        headCover: {
            field: coverField?.id,
            objectFit: 'cover',
            ratio: '4:3',
            show: true
        },
        headTags: {
            fields: [],
            show: false,
            showLabel: true
        },
        headTitle: {
            field: titleField?.id,
            show: true
        }
    }
}

/**
 * 生成日历block的配置
 *
 * @param {DataSourceAbstract} dataSource
 * @returns {CalendarViewOptions}
 */
export const generateCalendarConfig = (dataSource: undefined | DataSourceAbstract): CalendarViewOptions => {
    if (!dataSource) {
        return {}
    }

    const fields = Object.values(dataSource.schema)
    const title = fields.find(item => item.type === 'text')
    const startDate = fields.find(item => item.type === 'date' && !getIsAppointField(item.id, SYSTEM_FIELD))
    const endDate =
        startDate && fields.find(item => item.type === 'date' && !getIsAppointField(item.id, SYSTEM_FIELD) && item.id !== startDate.id)

    return {
        scheduleTitleField: title?.id,
        scheduleStartDateField: startDate?.id,
        scheduleEndDateField: endDate?.id
    }
}

/**
 * 生成自定义视图block的配置
 *
 * @returns {CustomViewOptions}
 */
export const generateCustomConfig = (): CustomViewOptions => {
    return {
        layout: CUSTOM_VIEW_LAYOUT.waterfall,
        arrangement: ARRANGEMENT.auto,
        size: BlockSize.md,
        gap: 1,
        design: {
            direction: DIRECTION.vertical,
            padding: [1, 1, 1, 1],
            gap: 1,
            alignX: 'flex-start',
            radius: [8, 8, 8, 8],
            border: {
                type: BACKGROUND_TYPE.color,
                color: '#e4e7ec',
                mode: EDGE_MODE.each,
                each: [1, 1, 1, 1]
            }
        }
    }
}

type Params = {
    viewType: ViewType
    id: string
    appId?: string
    dataSource?: DataSourceAbstract
}
/**
 * 生成视图block
 *
 * @param {Params} param0
 * @param {ViewType} param0.viewType
 * @param {string} param0.id
 * @param {string} [param0.appId='']
 * @param {DataSourceAbstract} param0.dataSource
 * @returns {(undefined | ViewBlockAbstract)}
 */
export const generateViewBlock = ({ viewType, id, appId = '', dataSource }: Params): undefined | ViewBlockAbstract => {
    return {
        id,
        appId,
        type: BlockType.view,
        title: VIEW_BLOCK_NAME_MAP[viewType],
        config: {
            ...DEFAULT_VIEW_CONFIG,
            showTitle: viewType !== 'custom',
            canSetKanban: true,
            canSortKanban: true,
            viewType,
            appId,
            // ...generateGalleryConfig(dataSource),
            // ...generateCalendarConfig(dataSource),
            ...generateCustomConfig(),
            ...COMMON_BLOCK_CONFIG
        }
    }
}
