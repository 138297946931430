import { getAssetUrl } from '@lighthouse/assets'
import { TabsBlock } from '@lighthouse/block'
import type { TabsBlockAbstract, VariableADTvalue } from '@lighthouse/core'
import { ALIGNMENT } from '@lighthouse/core'
import { TEXT_FONT_NORMAL, useAppContainerContext, useAtomAction, useAtomData } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import produce from 'immer'
import React, { memo, useCallback, useEffect, useMemo } from 'react'

import { applicationSettingAtom } from '@/atoms/application/state'
import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

interface TabsControllerProps {
    blockData: TabsBlockAbstract
}

const TabsController = ({ blockData }: TabsControllerProps) => {
    const { associatedContainer, variant, variantMap, font, baseList } = blockData.config

    const { styleList } = variantMap[variant]

    const { scale } = useAppContainerContext()

    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const currentTab = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }
                if (associatedContainer) {
                    const view = s.blockRuntimeState.container?.[associatedContainer]?.currentView
                    if (view) {
                        return baseList.find(item => item.associatedView === view)?.id
                    }
                }

                // return s.state.blockRuntimeState.tabs?.[blockData.id].currentTab
            },
            [associatedContainer, baseList]
        )
    )

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const handleTabsChange = useCallback(
        (id: string) => {
            setPageStack(draft => {
                const pageStack = equalPageStack({ rootPageId, stackId })(draft)
                if (!pageStack) {
                    return
                }

                const view = baseList.find(item => item.id === id)?.associatedView
                if (view) {
                    pageStack.blockRuntimeState.container = {
                        ...pageStack.blockRuntimeState.container,
                        [associatedContainer]: {
                            ...pageStack.blockRuntimeState.container?.[associatedContainer],
                            currentView: view
                        }
                    }
                }

                pageStack.blockRuntimeState.tabs = {
                    ...pageStack.blockRuntimeState.tabs,
                    [blockData.id]: {
                        ...pageStack.blockRuntimeState.tabs?.[blockData.id],
                        currentTab: id
                    }
                }
            })
        },
        [associatedContainer, blockData.id, baseList, rootPageId, setPageStack, stackId]
    )

    /** **************************** 文本样式字体 start **************************** */
    const textPresets = useAtomData(
        applicationSettingAtom,
        useCallback(s => s?.theme.textPresetList ?? [], [])
    )

    const mergedBlockData = useMemo(() => {
        const advance = font?.advance
        if (advance) {
            const advanceOption = textPresets.find(item => item.id === advance)
            if (advanceOption) {
                const { fontFamily, fontSize, letterSpacing, lineClamp, lineHeight } = advanceOption
                return produce(blockData, draft => {
                    draft.config.font = {
                        ...draft.config.font,
                        align: draft.config.font?.align || ALIGNMENT.left,
                        advance,
                        fontFamily,
                        fontSize,
                        letterSpacing,
                        lineClamp,
                        lineHeight
                    }
                })
            }
        }

        return blockData
    }, [blockData, font?.advance, textPresets])

    useEffect(() => {
        const ff = mergedBlockData.config.font?.fontFamily
        if (!ff || ff === TEXT_FONT_NORMAL) {
            return
        }
        document.fonts.ready
            .then(async ffs => {
                const fontFaceList = [...ffs.values()]
                const isLoaded = fontFaceList.some(item => item.family === ff)
                if (!isLoaded) {
                    const fontUrl = `url(${getAssetUrl('font', `${ff}.woff2`)})`
                    const newFont = new FontFace(ff, fontUrl)
                    await newFont.load()
                    ffs.add(newFont)
                }
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [mergedBlockData.config.font?.fontFamily])
    /** **************************** 文本样式字体 end **************************** */

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    return (
        <TabsBlock
            blockData={mergedBlockData}
            scale={scale}
            value={currentTab}
            onChange={handleTabsChange}
            // style={style}
            parseVariableImage={parseBackgroundVariableImage}
        />
    )
}

export default memo(TabsController, equal)
