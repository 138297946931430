import { Breadcrumbs, Flex, Group, IconFont, Input, Menu, Modal, Select, Tooltip } from '@byecode/ui'
import type { DataSourceAbstract, ViewRecordOperateProtocol } from '@lighthouse/core'
import { PageType, RecordOpenType } from '@lighthouse/core'
import {
    getIsIncludesDisableDataSourceInWorkFlow,
    ListItem4ByecodeUi,
    OpenTypeData,
    SelectPage,
    useAtomAction,
    useAtomAsyncAction,
    useAtomData
} from '@lighthouse/shared'
import { nanoid, stopPropagation } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import { useAtomCallback } from 'jotai/utils'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useToggle } from 'react-use'

import { breadcrumbsHistoryAtom } from '@/atoms/application/state'
import { clonePageAtom, createPageAtom, openPageStackAtom, removePageAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageListAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { useRootPageContext } from '@/contexts/PageContext'
import { useAppLanguageList, useCurrentAppID, useLanguage } from '@/hooks/useApplication'
import { useBreadcrumbsHistoryBackEffect } from '@/hooks/useBreadcrumbs'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'

import { dataSourceAtom } from '../../atoms'
import { InnerDrawer } from '../../InnerDrawer'
import FormSegmentedControl from '../FormSegmentedControl'
import { listCommonStyles } from './constants'
import { StyledIconWrapper, StyledOptionButton } from './styles'

export type HandlePageInfo = { id: string; name?: string }
type CreateRecordValue = Partial<Pick<ViewRecordOperateProtocol, 'creatingConfig' | 'canCreateRecord'>>
interface CreateRecordProps {
    viewId?: string
    disabled?: boolean
    dataSource: DataSourceAbstract
    value: CreateRecordValue
    onChange?: (v: CreateRecordValue) => void
}

export const CreateRecordV2: FC<CreateRecordProps> = props => {
    const { value, dataSource } = props
    const [open, toggle] = useToggle(false)
    const { canCreateRecord, creatingConfig: { page = '' } = {} } = value ?? {}

    const isUserDataSource = useMemo(() => getIsIncludesDisableDataSourceInWorkFlow(dataSource), [dataSource])

    const disabled = isUserDataSource
    useBreadcrumbsHistoryBackEffect(page, () => {
        if (isUserDataSource) {
            return
        }
        toggle(true)
    })

    return (
        <>
            <ListItem4ByecodeUi
                disabled={disabled}
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => !disabled && toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <StyledIconWrapper active={canCreateRecord}>
                        <IconFont type="Add" size={18} fill={canCreateRecord ? 'var(--color-main)' : 'var(--color-gray-500)'} />
                    </StyledIconWrapper>
                    <div>添加记录</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canCreateRecord ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>

            {open && <CreateRecordConfigure {...props} disabled={disabled} onGoBack={() => toggle(false)} />}
        </>
    )
}

interface RecordConfigureProps {
    viewId?: string
    disabled?: boolean
    dataSource: DataSourceAbstract
    value: CreateRecordValue
    onChange?: (v: CreateRecordValue) => void
    onGoBack: () => void
}

const CreateRecordConfigure = ({ viewId, value, dataSource, onChange, onGoBack }: RecordConfigureProps) => {
    const { creatingConfig, canCreateRecord = false } = value ?? {}
    const { openType = RecordOpenType.page, label, page: selectedPage } = creatingConfig ?? {}
    const { id: dsId } = dataSource
    const appId = useCurrentAppID()

    const pageList = usePageList()
    const language = useLanguage()
    const languageList = useAppLanguageList()
    const disabledWithVersion = useIsDisabledWithVersion()

    useEffect(() => {
        if (creatingConfig && !!creatingConfig.page && !pageList.some(p => p.id === creatingConfig.page && p.type === PageType.creator)) {
            onChange?.({ ...value, creatingConfig: { ...creatingConfig, page: '' } })
        }
    }, [creatingConfig, onChange, pageList, value])

    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const { pageId = '', state } = lastPageMeta ?? {}
    const { selectedNode } = state ?? {}

    const { run: createPage, loading: createLoading } = useAtomAsyncAction(createPageAtom)
    const { run: removePage, loading: deleteLoading } = useAtomAsyncAction(removePageAtom)
    const { run: copyPage } = useAtomAsyncAction(clonePageAtom)
    const { run: setHistory } = useAtomAction(breadcrumbsHistoryAtom)

    const rootPageId = useRootPageContext()

    const { run: openPageStack } = useAtomAction(openPageStackAtom)

    const handleRecordAdd = useCallback(
        (pageId: string) => {
            openPageStack(
                stackFactory({
                    pageId,
                    rootPageId,
                    stackDisplayType: openType,
                    appId,
                    dsId,
                    viewId
                })
            )
        },
        [appId, dsId, openType, openPageStack, rootPageId, viewId]
    )

    const handleChange = useCallback(
        (params: Partial<CreateRecordValue>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    const onCreatePage = useAtomCallback(async get => {
        if (!dsId || createLoading) {
            return
        }
        const pageID = await createPage({ dsId, createMode: 'CAN_CREATE_RECORD', viewId })
        if (!pageID) {
            return
        }
        handleChange?.({ creatingConfig: { label: '', openType: RecordOpenType.modal, ...creatingConfig, page: pageID } })
        const list = get(pageListAtom)
        const pageName = list.find(item => item.id === pageID)?.name
        if (!pageName) {
            return
        }

        if (openType !== RecordOpenType.page) {
            setHistory(s => {
                s.push({
                    from: { page: pageId, block: selectedNode },
                    to: { page: pageID, block: undefined },
                    restored: false
                })
            })
        }

        handleRecordAdd(pageID)
    })

    /** 复制页面 */
    const onCopyPage = useCallback(
        async (id: string) => {
            const pageId = await copyPage({ id, lang: language })
            handleRecordAdd(pageId)
        },
        [copyPage, handleRecordAdd, language]
    )

    /** 删除页面 */
    const onDeletePage = useCallback(
        (id: string) => {
            if (deleteLoading) {
                return
            }
            // eslint-disable-next-line promise/catch-or-return
            Modal.confirm({
                title: '确认删除',
                content: '删除后,可能导致使用该表单的地方出现异常',
                okStatus: 'error'
            }).then(async confirm => {
                if (confirm) {
                    const res = await removePage(id)
                    if (res && id === selectedPage) {
                        handleChange({ creatingConfig: { label: '', openType: RecordOpenType.modal, ...value.creatingConfig, page: '' } })
                    }
                }
            })
        },
        [deleteLoading, handleChange, removePage, selectedPage, value.creatingConfig]
    )

    /** 编辑详情页 */
    const onEditPage = useCallback(
        (id: string) => {
            if (openType !== RecordOpenType.page) {
                setHistory(s => {
                    s.push({
                        from: { page: pageId, block: selectedNode },
                        to: { page: id, block: undefined },
                        restored: false
                    })
                })
            }
            handleRecordAdd(id)
        },
        [handleRecordAdd, openType, pageId, selectedNode, setHistory]
    )

    const viewsPageListOptions = useMemo(() => {
        return pageList
            .filter(item => item.appId === appId && item.dsId === dsId && item.type === PageType.creator)
            .map(item => ({
                label: item.name,
                value: item.id,
                lang: item.language,
                icon: <IconFont type="DataDetailPage" />,
                extra: (
                    <Flex gap="4px" onMouseDown={stopPropagation}>
                        <Tooltip title="编辑" placement="top">
                            <StyledOptionButton onClick={() => onEditPage(item.id)}>
                                <IconFont type="PencilSimple" />
                            </StyledOptionButton>
                        </Tooltip>
                        <Menu width={200}>
                            <Tooltip title="更多操作" placement="top">
                                <Menu.Target>
                                    <StyledOptionButton>
                                        <IconFont type="DotsThree" />
                                    </StyledOptionButton>
                                </Menu.Target>
                            </Tooltip>

                            <Menu.Dropdown>
                                <Menu.Item
                                    icon={<IconFont fill="var(--color-gray-400)" type="Duplicate" />}
                                    onClick={() => onCopyPage(item.id)}
                                >
                                    复制
                                </Menu.Item>
                                <Menu.Item
                                    icon={<IconFont fill="var(--color-gray-400)" type="Trash" />}
                                    onClick={() => {
                                        onDeletePage(item.id)
                                    }}
                                >
                                    删除
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                )
            }))
    }, [appId, dsId, onCopyPage, onDeletePage, onEditPage, pageList])

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '添加记录', onClick: onGoBack }]} />

            <Divider color="var(--color-gray-200)" />

            <Group
                mode="switch"
                label="通过表单添加记录"
                opened={canCreateRecord}
                onCollapseChange={v => handleChange({ ...creatingConfig, canCreateRecord: v })}
                switchProps={{
                    disabled: !dataSource || dataSource.type === 2 || disabledWithVersion
                }}
            >
                <ListItem4ByecodeUi gap="8px" justifyContent="space-between" alignItems="center">
                    <div>选择一个表单</div>
                    <SelectPage
                        style={{ width: 178 }}
                        prefix={
                            <div style={{ boxShadow: 'var(--box-shadow)' }}>
                                <IconFont type="DataDetailPage" />
                            </div>
                        }
                        searchable
                        options={viewsPageListOptions}
                        value={selectedPage}
                        languageList={languageList}
                        onChange={v => {
                            handleChange({ creatingConfig: { ...creatingConfig, page: v } })
                        }}
                        dropdownProps={{
                            extra: (
                                <>
                                    <Divider my={8} color="var(--color-gray-200)" />
                                    <ListItem4ByecodeUi
                                        style={{ color: 'var(--color-main)', padding: '0 16px', minHeight: 36, borderRadius: 'unset' }}
                                        gap="8px"
                                        alignItems="center"
                                        enableHover
                                        onClick={onCreatePage}
                                    >
                                        <IconFont type="Add" />
                                        新的数据表单
                                    </ListItem4ByecodeUi>
                                </>
                            ),
                            styles: {
                                item: {
                                    height: 32,
                                    padding: '0 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    '>div': {
                                        width: '100%'
                                    }
                                }
                            }
                        }}
                    />

                    {/* {selectedPage && (
                        <Button
                            style={{ height: 36, whiteSpace: 'nowrap' }}
                            icon={<IconFont type="edit" />}
                            onClick={() => {
                                const hasExist = viewsPageListOptions.some(item => item.value === selectedPage)
                                if (hasExist) {
                                    onEditPage(selectedPage)
                                }
                            }}
                        >
                            编辑表单页
                        </Button>
                    )} */}
                </ListItem4ByecodeUi>

                <FormSegmentedControl
                    label="打开方式"
                    value={openType}
                    onChange={val => handleChange({ creatingConfig: { ...creatingConfig, openType: val as RecordOpenType } })}
                    data={OpenTypeData}
                />

                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <div>按钮标题</div>
                    <Input
                        value={label}
                        onChange={e => handleChange({ creatingConfig: { ...creatingConfig, label: e.currentTarget.value } })}
                        styles={{ wrapper: { width: 180 } }}
                    />
                </ListItem4ByecodeUi>
            </Group>
        </InnerDrawer>
    )
}
