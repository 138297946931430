import type { AggregatorNodeType } from '@lighthouse/shared'
import React, { createContext, useMemo } from 'react'

export type DataAggregatorContextValue = {
    onAddNode: (node: AggregatorNodeType) => void
    onRemoveNode: (id: string) => void
} | null

export type DataAggregatorContextProps = DataAggregatorContextValue & {
    children: React.ReactNode
}

export const DataAggregatorContext = createContext<DataAggregatorContextValue>(null)

export const DataAggregatorProvider: React.FC<DataAggregatorContextProps> = ({ onAddNode, onRemoveNode, children }) => {
    const ctxValue = useMemo(() => ({ onAddNode, onRemoveNode }), [onAddNode, onRemoveNode])
    return <DataAggregatorContext.Provider value={ctxValue}>{children}</DataAggregatorContext.Provider>
}

DataAggregatorContext.displayName = 'DataAggregatorContext'
