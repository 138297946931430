import { Button, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const List = styled.div`
    width: 100%;
    margin-top: 28px;
`

export const ItemButton = styled(Button)`
    font-size: var(--font-size-normal);
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0 32px 0;
    height: 373px;
`

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--color-gray-200);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 27px;
`

export const ModalTitle = styled.div`
    font-size: 24px;
    font-weight: 400;
    color: var(--color-black);
`

export const QrcodeWrapper = styled.div`
    margin-top: 26px;
`

export const QRCode = styled.img`
    width: 163px;
    height: 163px;
`
