import type { AggregatorJoinType, AggregatorNodeType } from '../types'

export const aggregatorNodeTypeIconMap: Record<AggregatorNodeType, string> = {
    DATA_INPUT: 'NewTable',
    JOIN: 'JOIN',
    FIELD_SETTING: 'PropertyNote',
    FILTER: 'Filter',
    GROUP_STATISTICS: 'Charts',
    ADD_PLACEHOLDER: 'Add'
}

export const aggregatorJoinNodeIconMap: Record<AggregatorJoinType, string> = {
    LEFT_JOIN: 'LeftConnection',
    RIGHT_JOIN: 'RightConnection',
    INNER_JOIN: 'InternalConnection'
}

export const aggregatorNodeTypeDescription: Record<AggregatorNodeType, string> = {
    DATA_INPUT: '',
    JOIN: '连接后输出的表',
    FIELD_SETTING: '字段设置后输出的表',
    FILTER: '数据筛选后输出的表',
    GROUP_STATISTICS: '分组统计后输出的表',
    ADD_PLACEHOLDER: ''
}
