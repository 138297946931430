import { Flex, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'

import { getBrandInfo } from '../../utils'
import { Logo } from './LogoSvg'
import { ShadowSvg } from './ShadowSvg'
import * as SC from './styles'

interface WebAccountLayoutProps {
    children?: React.ReactNode
}

export const WebAccountLayout: React.FC<WebAccountLayoutProps> = ({ children }) => {
    const isHideIntroduceContainer = useMediaQuery('(max-width: 1024px)')

    return (
        <SC.Container>
            <SC.FormContainer>{children}</SC.FormContainer>
            {!isHideIntroduceContainer && (
                <SC.IntroduceContainer>
                    <SC.IntroduceBackgroundImg height="100%" src={getBrandInfo().account.backgroundImage} />
                    <SC.IntroduceWrapper>
                        <ShadowSvg />
                        <SC.IntroduceTitleHeader>
                            <SC.GradientText style={getBrandInfo().account.titleStyleColor}>自主实现</SC.GradientText>
                            <Text
                                size={36}
                                align="center"
                                style={{ lineHeight: '50px', letterSpacing: 7 }}
                                weight={600}
                                color="var(--color-gray-800)"
                            >
                                营销场景 & 内部管理
                            </Text>
                            <Text size={34} align="center" weight={600} style={{ lineHeight: '50px', letterSpacing: 7 }}>
                                应用互联
                            </Text>
                        </SC.IntroduceTitleHeader>
                        <SC.IntroduceFooter>
                            <Flex justify="center">
                                <Text color="var(--color-black)" weight={700} size={16}>
                                    无需编程，
                                </Text>
                                <Text size={16} align="center" style={{ lineHeight: '24px' }} color="var(--color-gray-500)">
                                    任何人都能随心搭建令人惊艳的AI 驱动业务应用
                                </Text>
                            </Flex>

                            {getBrandInfo().isLogo && <Logo height={23} width={112} />}

                            {getBrandInfo().app.isFilings && (
                                <SC.Filing>
                                    <Text color="var(--color-gray-400)" size={10}>
                                        @2023 ByeCode
                                    </Text>
                                    <a style={{ color: 'var(--color-gray-400)', fontSize: 10 }} href="https://beian.miit.gov.cn">
                                        鄂ICP备2021016197号-4
                                    </a>
                                </SC.Filing>
                            )}
                        </SC.IntroduceFooter>
                    </SC.IntroduceWrapper>
                </SC.IntroduceContainer>
            )}
        </SC.Container>
    )
}
