import { Button, ColorPicker, Flex, IconFont, Popover } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

interface ColorListProps {
    value?: string[]
    defaultColors?: string[]
    onChange: (value: string[]) => void
}

const SCxContainer = styled.div``

const SCxList = styled(Flex)`
    padding: 12px 0;
    display: grid;
    justify-content: space-between;
    grid-row-gap: 12px;
    grid-column-gap: auto;
    grid-template-columns: repeat(7, 28px);
`

const SCxListItem = styled.div`
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
`

const SCxPatches = styled.div<{ background?: string }>`
    width: 22px;
    height: 22px;
    background: ${props => props.background};
    border-radius: 4px;
`

const SCxFooter = styled(Flex)``

const SCxReset = styled(Button)`
    color: var(--color-main);
    position: relative;
    left: -6px;
`

const SCxRemark = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`

export const ColorList: React.FC<ColorListProps> = ({ value = [], onChange, defaultColors }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const handleChange = useCallback(
        (color: string, i: number) => {
            if (color) {
                const newValue = [...value]
                newValue[i] = color
                onChange(newValue)
            }
        },
        [onChange, value]
    )

    const handleReset = useCallback(() => {
        if (defaultColors && defaultColors.length > 0) {
            onChange(defaultColors)
        }
    }, [defaultColors, onChange])

    return (
        <SCxContainer>
            <SCxList>
                {value.map((color, index) => (
                    <Popover disabled={disabledWithVersion} withinPortal width="auto" key={index}>
                        <Popover.Target>
                            <SCxListItem>
                                <SCxPatches background={color} />
                            </SCxListItem>
                        </Popover.Target>
                        <Popover.Dropdown unstyled style={{ background: '#fff' }}>
                            <ColorPicker value={value[index]} onChange={color => handleChange(color, index)} />
                        </Popover.Dropdown>
                    </Popover>
                ))}
            </SCxList>

            <SCxFooter justifyContent="space-between" alignItems="center">
                <SCxReset type="text" onClick={handleReset} icon={<IconFont type="ArrowCounterClockwise" size={16} />}>
                    重置
                </SCxReset>
                <SCxRemark>点击方形可修改颜色</SCxRemark>
            </SCxFooter>
        </SCxContainer>
    )
}
