import { Badge } from '@byecode/ui'
import type { EnvDiffs } from '@lighthouse/shared'
import { getTableIcon, getTableIconColor } from '@lighthouse/shared'
import { Tabs } from '@mantine/core'
import { add } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { DataSourceGroup } from './DataSourceGroup'
import { MergeErrorList } from './MergeErrorList'
import { MergeFieldList } from './MergeFieldList'
import { MergePanel } from './MergePanel'
import { MergeUpdateList } from './MergeUpdateList'

const SCxTabText = styled.div<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? 'var(--color-black)' : 'var(--color-gray-400)')};
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
    display: flex;
    align-items: center;
    position: relative;
`

const TabStyles = {
    root: {
        display: 'flex',
        flexDirection: 'column' as const,
        overflow: 'hidden'
    },
    tabsList: {
        gap: '20px',
        borderColor: 'var(--color-gray-200)'
    },
    tab: {
        padding: '9px 0',

        '&:hover': {
            background: 'transparent'
        },
        '&[data-active]': {
            borderColor: 'var(--color-black)'
        },
        '&[data-active]:hover': {
            borderColor: 'var(--color-black)'
        }
    },
    tabLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px'
    },
    panel: {
        flex: 1,
        overflow: 'auto',
        paddingTop: '0px!important'
    }
}

type TabValue = 'modify' | 'error'

interface MergeDataSourceProps {
    data: EnvDiffs
}

export const MergeDataSource: React.FC<MergeDataSourceProps> = ({ data }) => {
    const [tabValue, setTabValue] = useState<TabValue>('modify')
    const tableColor = getTableIconColor()
    const tableIcon = getTableIcon()

    const handleTabChange = useCallback((val: TabValue) => {
        setTabValue(val)
    }, [])

    const modifyActiveStyle = useMemo(() => {
        if (tabValue === 'modify') {
            return {
                color: 'var(--color-main)',
                background: 'var(--color-main-tint)'
            }
        }
        return {
            color: 'var(--color-gray-400)',
            background: 'var(--color-gray-100)'
        }
    }, [tabValue])

    const errorActiveStyle = useMemo(() => {
        if (tabValue === 'error') {
            return {
                color: 'var(--color-red-500)',
                background: 'var(--color-red-50)'
            }
        }
        return {
            color: 'var(--color-gray-400)',
            background: 'var(--color-gray-100)'
        }
    }, [tabValue])

    const mergeLength = useMemo(() => {
        const addLength = data.additions?.length ?? 0
        const deleteLength = data.deletions?.length ?? 0
        const updateLength = data.updates?.length ?? 0
        return add(add(addLength, deleteLength), updateLength)
    }, [data.additions?.length, data.deletions?.length, data.updates?.length])

    const addModifyContent = useMemo(() => {
        if (!data.additions || data.additions.length === 0) {
            return null
        }
        return data.additions.map((item, index) => (
            <DataSourceGroup key={index} type="add" dsType={item.dsType} dsName={item.dsName} sync={false}>
                <MergeFieldList data={item.fields} />
            </DataSourceGroup>
        ))
    }, [data.additions])

    const deleteModifyContent = useMemo(() => {
        if (!data.deletions || data.deletions.length === 0) {
            return null
        }
        return data.deletions.map((item, index) => (
            <DataSourceGroup key={index} type="delete" dsType={item.dsType} dsName={item.dsName} sync={false}>
                <MergeFieldList data={item.fields} />
            </DataSourceGroup>
        ))
    }, [data.deletions])

    const updateModifyContent = useMemo(() => {
        if (!data.updates || data.updates.length === 0) {
            return null
        }
        return data.updates.map((item, index) => (
            <DataSourceGroup key={index} type="update" dsType={item.dsType} dsName={item.dsName} sync={false}>
                <MergeUpdateList data={item} />
            </DataSourceGroup>
        ))
    }, [data.updates])

    return (
        <Tabs value={tabValue} onTabChange={handleTabChange} styles={TabStyles}>
            <Tabs.List>
                <Tabs.Tab value="modify">
                    <SCxTabText isActive={tabValue === 'modify'}>改动</SCxTabText>
                    <Badge color={modifyActiveStyle.color} backgroundColor={modifyActiveStyle.background}>
                        {mergeLength}
                    </Badge>
                </Tabs.Tab>
                <Tabs.Tab value="error">
                    <SCxTabText isActive={tabValue === 'error'}>异常</SCxTabText>
                    <Badge color={errorActiveStyle.color} backgroundColor={errorActiveStyle.background}>
                        {data.errors?.length ?? 0}
                    </Badge>
                </Tabs.Tab>
            </Tabs.List>
            <MergePanel value="modify">
                <>
                    {addModifyContent}
                    {updateModifyContent}
                    {deleteModifyContent}
                </>
            </MergePanel>

            {/* <SCxPanel value="error" pt="xs">
                <SCxContainer>
                    <SCxLabel>
                        <SCxIcon type={tableIcon} fill={tableColor} />
                        <SCxTitle>数据源</SCxTitle>
                    </SCxLabel>
                    <Badge>新增</Badge>
                </SCxContainer>
            </SCxPanel> */}
            <MergePanel value="error">
                <>
                    {data.errors?.map((error, index) => (
                        <DataSourceGroup type="error" key={index} dsType={error.originDsType} dsName={error.originDsName} sync>
                            <MergeErrorList data={error} />
                        </DataSourceGroup>
                    ))}
                </>
            </MergePanel>
        </Tabs>
    )
}
