import { Box } from '@byecode/ui/components/Box'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import React from 'react'
import { Outlet } from 'react-router'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Aside = styled(Flex)`
    width: 254px;
    padding: 12px;
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
`

const ListItem = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
    padding: 0 12px;
    border-radius: 6px;
    color: var(--color-gray-500);
    font-size: 14px;
    line-height: 22px;
    &:hover,
    &.active {
        font-weight: 600;
        color: var(--color-main);
        background-color: var(--color-gray-100);
    }
`

export const AnalyzeLayout = () => {
    return (
        <Flex styles={{ root: { width: '100%', height: '100%', backgroundColor: 'var(--color-gray-50)' } }}>
            <Aside
                direction="column"
                styles={{
                    root: {
                        width: 254,
                        padding: 12,
                        background: '$colorError100'
                    }
                }}
            >
                <Flex direction="column" gap="4px">
                    <ListItem to="traffic">
                        <IconFont type="RateOfFlow" />
                        <Box>流量分析</Box>
                    </ListItem>
                    <ListItem to="page">
                        <IconFont type="PageBroadContent" />
                        <Box>页面分析</Box>
                    </ListItem>
                    {/* <ListItem to="event">
                        <IconFont type="ClickButton" />
                        <Box>事件分析</Box>
                    </ListItem> */}
                </Flex>

                <ListItem to="settings" style={{ marginTop: 'auto' }}>
                    <IconFont type="Setting" />
                    <Box>绑定自有统计</Box>
                </ListItem>
            </Aside>
            <Box style={{ flex: 1, padding: '16px 20px', overflow: 'auto' }}>
                <Outlet />
            </Box>
        </Flex>
    )
}
