import { getAssetUrl } from '@lighthouse/assets'
import { Text } from '@mantine/core'
import React from 'react'

import * as SC from './styles'
import type { AccountBoxProps } from './types'

export * from './types'

export const AccountBox: React.FunctionComponent<AccountBoxProps> = ({
    children,
    title,
    describe,
    width = 338,
    height,
    justifyContent
}) => {
    return (
        <SC.Aside height={height} justifyContent={justifyContent}>
            <SC.Box width={width}>
                <SC.Image src={getAssetUrl('common', 'logo.png')} alt="logo" />
                {title && (
                    <Text style={{ lineHeight: '32px', fontWeight: 600 }} align="center" size={24} color="var(--color-black)">
                        {title}
                    </Text>
                )}
                {describe && (
                    <Text style={{ lineHeight: '22px' }} align="center" size={14} color="var(--color-gray-500)">
                        {describe}
                    </Text>
                )}
                {children}
            </SC.Box>
        </SC.Aside>
    )
}
