import { ModalConfirm, Toast } from '@byecode/ui'
import type { EnvDiffs } from '@lighthouse/shared'
import { APPLICATION_ENV_PROD } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useAsyncFn, useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { type MergeModalState, MergeModal } from '@/components/Environment'
import { useAppEnvList, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

interface MergeConfirmProps {
    data: EnvDiffs | undefined
    onOpen?: (val: boolean) => void
}

export const MergeConfirm: React.FC<MergeConfirmProps> = ({ data, onOpen }) => {
    const [diffData, setDiffData] = useState<EnvDiffs | undefined>(data)
    const envList = useAppEnvList()
    const appEnvId = useCurrentEnvId()
    const [confirmOpen, setConfirmOpen] = useState(true)
    const [mergeOpen, setMergeOpen] = useState(false)
    const [state, setState] = useImmer<MergeModalState>({
        fromEnvId: appEnvId,
        toEnvId: APPLICATION_ENV_PROD
    })

    const { fromEnvId, toEnvId } = state
    const [{ loading }, run] = useAsyncFn(srv.getEnvDiffs)
    const fromEnv = useMemo(() => find(env => env.envId === fromEnvId, envList), [envList, fromEnvId])
    const toEnv = useMemo(() => find(env => env.envId === toEnvId, envList), [envList, toEnvId])

    const getDiffData = useCallback(async () => {
        const data = await run(fromEnvId, toEnvId)
        setDiffData(data)
    }, [fromEnvId, run, toEnvId])

    useUpdateEffect(() => {
        getDiffData()
    }, [fromEnvId, toEnvId])

    const content = useMemo(() => {
        const fromEnvName = fromEnv?.envName || ''
        const toEnvName = toEnv?.envName || ''
        return `发布更新版本前，需先将${fromEnvName}所连接的数据环境合并到${toEnvName}中`
    }, [fromEnv?.envName, toEnv?.envName])

    const handleClose = useCallback(() => {
        setMergeOpen(false)
        onOpen?.(false)
        setConfirmOpen(true)
    }, [onOpen])

    const handleOpenMerge = useCallback(() => {
        setMergeOpen(true)
        setConfirmOpen(false)
    }, [])

    const handleMerge = useCallback(async () => {
        const isMerge = await srv.mergeEnv(fromEnvId, toEnvId)
        if (isMerge) {
            Toast.success('合并成功')
            handleClose()
        }
        return isMerge
    }, [fromEnvId, handleClose, toEnvId])

    const handleReject = useCallback(() => {
        handleClose()
    }, [handleClose])

    return (
        <>
            {confirmOpen && (
                <ModalConfirm
                    title="检测发现数据表结构发生变化"
                    content={content}
                    cancelText="放弃发布"
                    okText="合并数据环境"
                    okStatus="primary"
                    onReject={handleReject}
                    onResolve={handleOpenMerge}
                />
            )}
            {mergeOpen && (
                <MergeModal
                    open={mergeOpen}
                    loading={loading}
                    data={data}
                    envList={envList}
                    envState={state}
                    onlyCurrentEnv
                    onChangeEnvId={setState}
                    onMerge={handleMerge}
                    onClose={handleClose}
                />
            )}
        </>
    )
}
