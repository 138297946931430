import { flex } from '@byecode/ui'
import type { StatusFile } from '@lighthouse/shared'
import { useAtomAction } from '@lighthouse/shared'
import { clone, find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { createAppTemplateAtom } from '@/atoms/application/action'
import { ImportLoading } from '@/components/ImportLoading'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { useTemplateList } from '@/shared/reusable'

import { blankTemplate } from './constant'
import TemplateBody from './TemplateBody'
import TemplateHeader from './TemplateHeader'
import TemplatePreview from './TemplatePreview'
import type { State, Template as TemplateType } from './types'

interface TemplateProps {
    mode: 'modal' | 'page'
    isBlankTemplate?: boolean
    onCreateBlankApp?: (id: string) => void
    onClose?: () => void
    onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
}

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 647px;
    font-style: normal;
    font-weight: 400;
    color: var(--color-black);
    background-color: var(--color-white);
    ${flex}
    flex-direction: column;
`

export const Template: React.FunctionComponent<TemplateProps> = ({
    isBlankTemplate,
    onCreateBlankApp,
    onChangeFileStatus,
    onClose,
    mode = 'page'
}) => {
    const spaceId = useCurrentSpaceID()
    const { run: createAppTemplate } = useAtomAction(createAppTemplateAtom)
    const { data: kindList = [] } = useTemplateList()
    const navigate = useNavigate()

    const [state, setState] = useImmer<State>({
        routes: [
            { id: 'allTemplate', name: '所有模板' }
            // { id: template1.id, name: '所有模板' }
        ],
        loading: false
    })

    const finalTemplateList = useMemo(() => {
        if (isBlankTemplate) {
            const newKindList = clone(kindList)
            if (newKindList.length > 0) {
                // const recommendList = newKindList ? newKindList?.[0]?.list : []
                // newKindList[0].list = [...blankTemplate[0].list, ...recommendList]
                // return newKindList
                return [...blankTemplate, ...newKindList]
            }
            return blankTemplate
        }
        return kindList
    }, [isBlankTemplate, kindList])

    const activeTemplate = useMemo(() => {
        const templateId = state.routes[1]?.id ?? ''
        const templateList = finalTemplateList.reduce<TemplateType[]>((preVal, { list }) => [...preVal, ...list], [])
        return find(item => item.id === templateId, templateList)
    }, [finalTemplateList, state.routes])

    const handlePageCreatApp = useCallback(
        (data?: TemplateType) => {
            if (!data?.id) {
                return
            }
            if (data.isBlankTemplate) {
                onCreateBlankApp?.(data.id)
                return
            }
            onClose?.()
            navigate({ pathname: `/create/templateApp`, search: `templateId=${data.id}&spaceId=${spaceId}` })
        },
        [navigate, onClose, onCreateBlankApp, spaceId]
    )

    const handleModalCreatApp = useCallback(
        async (data?: TemplateType) => {
            if (!data?.id) {
                return
            }
            if (data.isBlankTemplate) {
                onCreateBlankApp?.(data.id)
                return
            }
            setState(state => {
                state.loading = true
            })
            const createAppId = await createAppTemplate({ spaceId, templateId: data.id })
            onClose?.()
            setState(state => {
                state.loading = false
            })
            if (createAppId) {
                navigate({ pathname: `/${createAppId}` })
            }
        },
        [createAppTemplate, navigate, onClose, onCreateBlankApp, setState, spaceId]
    )

    const handleCreateApp = useCallback(
        (data?: TemplateType) => {
            mode === 'page' ? handlePageCreatApp(data) : handleModalCreatApp(data)
        },
        [handleModalCreatApp, handlePageCreatApp, mode]
    )

    const isShowTemplateList = state.routes.length === 1

    return (
        <Container>
            <TemplateHeader onClose={onClose} />
            {isShowTemplateList ? (
                <TemplateBody
                    list={finalTemplateList}
                    onLink={data =>
                        setState(state => {
                            state.routes = [...state.routes, data]
                        })
                    }
                    onCreate={handleCreateApp}
                    onChangeFileStatus={onChangeFileStatus}
                />
            ) : (
                activeTemplate && (
                    <TemplatePreview
                        onBack={() =>
                            setState(state => {
                                state.routes = [{ id: 'allTemplate', name: '所有模板' }]
                            })
                        }
                        loading={state.loading}
                        data={activeTemplate}
                        routes={state.routes}
                        onCreate={handleCreateApp}
                    />
                )
            )}
            <ImportLoading
                open={state.loading}
                hidePercent
                fileStatus={null}
                onClose={() =>
                    setState(state => {
                        state.loading = false
                    })
                }
            />
        </Container>
    )
}
