import { IconFont, Switch, Tooltip } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Group, Text } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

import { listCommonStyles } from './constants'
import { StyledIconWrapper } from './styles'

interface EditableProps {
    value?: boolean
    disabled?: boolean
    tooltipLabel?: string

    onChange?: (v: boolean) => void
}

export const Editable: FC<EditableProps> = ({ value, disabled, tooltipLabel = '', onChange }) => (
    <ListItem4ByecodeUi styles={listCommonStyles} enableHover={!disabled} enablePadding justifyContent="space-between" alignItems="center">
        <Group spacing="xs" noWrap>
            {/* <StyledIconWrapper active={value}>
                    <IconFont type="PencilSimple" size={18} fill={value ? 'var(--color-main)' : undefined} />
                </StyledIconWrapper> */}
            <Text>允许编辑</Text>
        </Group>
        {/* <Tooltip title={tooltipLabel} disabled={!disabled} placement="top-end" withArrow disablePortal> */}
        <Switch checked={value} /* disabled={disabled} */ onChange={e => /* !disabled && */ onChange?.(e.currentTarget.checked)} />
        {/* </Tooltip> */}
    </ListItem4ByecodeUi>
)
