import { Button } from '@byecode/ui/components/Button'
import * as React from 'react'
import styled from 'styled-components'

interface DragLoadingProps {}

const SCxContainer = styled.div`
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    bottom: 24px;
    z-index: 200;
`
const SCxText = styled.span`
    padding-left: 4px;
`
const DragLoading: React.FunctionComponent<DragLoadingProps> = () => {
    return (
        <SCxContainer>
            <Button
                style={{
                    background: 'var(--color-black)',
                    color: 'var(--color-white)',
                    borderStyle: 'none',
                    opacity: 1,
                    whiteSpace: 'nowrap'
                }}
                loading
            >
                <SCxText>正在添加组件</SCxText>
            </Button>
        </SCxContainer>
    )
}

export default DragLoading
