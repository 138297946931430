import { Button, IconFont } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Radius: React.FC = () => {
    return (
        <Unit title="圆角">
            <Button type="primary" loading radius={100}>
                加载中
            </Button>
            <Button danger radius={100}>
                点我
            </Button>
            <Button radius={100}>点我</Button>
            <Button type="primary" radius={100}>
                点我
            </Button>
            <Button icon={<IconFont type="BlockTable" />} disabled loading radius={100} />
        </Unit>
    )
}
