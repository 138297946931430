import { Divider, Group, Select } from '@byecode/ui'
import type { NodeTypes } from '@lighthouse/shared'
import { type FlowNode, canProvidedRecordInFlowNodeTypes, CURRENT_RECORD, getUpstreamNodeOption, nodeTypeOptions } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'

import { StripeAccountModal } from '@/components/OtherPlatFormModal'
import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useOtherPlatformList } from '@/shared/reusable'

import { FormItem } from '../../Common/FormItem'
import { STRIPE_PAY_OPTIONS, WECHAT_PAY_OPTIONS } from '../../constant'
import * as CM from '../../styles'
import { SELECT_RECORD_OPTIONS, SELECT_UPSTREAM_OPTIONS } from './constants'
import Pay from './Pay'
import * as SC from './styles'

interface StripeActionConfigureProps {
    allParentNodes?: FlowNode[]
    prefixName?: string
    actionTypeSwitcher?: React.ReactNode
}

interface State {
    mode: 'add' | 'update'
    opened: boolean
    id?: string
}

export const StripeActionConfigure: React.FunctionComponent<StripeActionConfigureProps> = ({
    prefixName = 'config',
    actionTypeSwitcher,
    allParentNodes
}) => {
    const { control, watch } = useFormContext()
    const { data: otherPlatform } = useOtherPlatformList()
    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })

    const { type } = useFlow()

    const isActionFlow = Boolean(type)

    const [operationType] = watch([`${prefixName}.operationType`])

    const accountId = otherPlatform?.find(item => item.type === 'STRIPE')

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const nodeOptions = useMemo(() => {
        return getUpstreamNodeOption(allParentNodes ?? [], dataSourceList ?? [], node =>
            canProvidedRecordInFlowNodeTypes.includes(node.data.nodeType)
        )
    }, [allParentNodes, dataSourceList])

    return (
        <CM.Container>
            <Group
                label="账号配置"
                styles={{
                    root: {
                        padding: '0 0 16px 0!important'
                    }
                }}
            >
                <WechatIntegratedCard type="STRIPE" />
            </Group>
            <Divider />
            <Group label="动作配置">
                <SC.Content>
                    {actionTypeSwitcher && (
                        <CM.FormItem>
                            <CM.FormItemLabelWrapper>
                                <CM.FormItemLabel>类型</CM.FormItemLabel>
                            </CM.FormItemLabelWrapper>
                            <CM.FormItemContent>
                                {actionTypeSwitcher || (
                                    <Controller
                                        name="nodeType"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                disabled
                                                value={field.value}
                                                options={nodeTypeOptions}
                                                onChange={val => field.onChange?.(val as NodeTypes)}
                                            />
                                        )}
                                    />
                                )}
                            </CM.FormItemContent>
                        </CM.FormItem>
                    )}
                    {accountId && (
                        <>
                            <FormItem label="执行操作" required>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.operationType`}
                                    render={({ field }) => (
                                        <Select
                                            placeholder="请选择"
                                            styles={{
                                                root: {
                                                    // margin: '0 16px'
                                                }
                                            }}
                                            disabled
                                            options={STRIPE_PAY_OPTIONS}
                                            {...field}
                                        />
                                    )}
                                />
                            </FormItem>
                            {operationType && (
                                <>
                                    <FormItem label="支付订单" required>
                                        <Select
                                            placeholder="请选择"
                                            disabled
                                            value={isActionFlow ? 'upstream' : CURRENT_RECORD}
                                            options={isActionFlow ? SELECT_UPSTREAM_OPTIONS : SELECT_RECORD_OPTIONS}
                                        />
                                    </FormItem>
                                    {isActionFlow && (
                                        <FormItem label="选择上游节点" required>
                                            <Controller
                                                control={control}
                                                name={`${prefixName}.${operationType === 'PAY' ? 'payConfig' : 'refundConfig'}.nodeId`}
                                                render={({ field }) => <Select placeholder="请选择" options={nodeOptions} {...field} />}
                                            />
                                        </FormItem>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </SC.Content>
            </Group>
            <Divider color="var(--color-gray-200)" />
            {operationType && accountId && <Pay allParentNodes={allParentNodes} prefixName={prefixName} />}
            <StripeAccountModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
        </CM.Container>
    )
}
