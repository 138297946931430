import { IconFont } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { getColorById } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

// import { getColorById } from '../../utils'

export interface SelectItemValue {
    label: string
    color?: string
}

interface UserTagItemProps {
    data: AppUser
    clearable?: boolean
    isHiddenUserId?: boolean
    display?: 'inline-flex' | 'flex'
    onRemove?: (userId: string) => void
    onItemClick?: (userId: string) => void
}

const SCxCellValue = styled.div<{ display: 'inline-flex' | 'flex' }>`
    display: ${({ display }) => display};
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 6px;
`

const SCxAvatar = styled.img`
    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-right: 4px;
    border-radius: 50%;
`

const SCxIcon = styled(IconFont)`
    margin-right: 4px;
`

const SCxAvatarText = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 20px;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    overflow: hidden;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const SCxText = styled.div`
    height: 100%;
    line-height: 21px;
    white-space: nowrap;
`

const SCxClearIcon = styled(IconFont)`
    margin-left: 4px;
`

export const UserTagItem: React.FC<UserTagItemProps> = ({ data, clearable, display = 'inline-flex', onRemove, onItemClick }) => {
    const { userId, username, avatar } = data
    const background = getColorById(userId)

    const avatarContent = useMemo(() => {
        if (userId === '{currentUserId}') {
            return <SCxIcon size={22} type="UserCircle" />
        }
        if (avatar) {
            return <SCxAvatar src={avatar} />
        }
        return <SCxAvatarText background={background}>{username.charAt(0)}</SCxAvatarText>
    }, [avatar, background, userId, username])

    return (
        <SCxCellValue key={userId} display={display} onClick={() => onItemClick?.(userId)}>
            {avatarContent}
            <SCxText>{username}</SCxText>
            {clearable && (
                <SCxClearIcon
                    onClick={event => {
                        event.stopPropagation()
                        onRemove?.(userId)
                    }}
                    type="Close"
                />
            )}
        </SCxCellValue>
    )
}
