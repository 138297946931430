import type { SendMsgDingTalkRobotRootNode, TiptapEditorJSONContent } from '@lighthouse/shared'
import { RichTextEditor } from '@lighthouse/shared'
import { turndownService } from '@lighthouse/tools'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { PersonConfig } from './PersonConfig'
import * as SC from './styles'

interface RichTextMsgConfigProps {}

export const RichTextMsgConfig: React.FunctionComponent<RichTextMsgConfigProps> = () => {
    const { control, register, watch, setValue } = useFormContext<Pick<SendMsgDingTalkRobotRootNode, 'config'>>()

    const handleChange = useCallback(
        (json: TiptapEditorJSONContent, html?: string) => {
            setValue('config.markdown.editorText', json)
            if (html) {
                const markdown = turndownService.turndown(html)
                setValue('config.markdown.text', markdown)
            }
        },
        [setValue]
    )

    return (
        <>
            <SC.Item>
                <SC.Text>
                    文本内容
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                <Controller
                    control={control}
                    name="config.markdown.editorText"
                    render={({ field: { value } }) => {
                        return (
                            <RichTextEditor
                                config={{
                                    // variable: { schema },
                                    heading: false,
                                    orderedList: false,
                                    bulletList: false,
                                    taskList: false,
                                    codeBlock: false,
                                    image: false,
                                    quote: false,
                                    line: false
                                }}
                                autofocus="end"
                                value={value}
                                styles={{
                                    editorContainer: {
                                        minHeight: 200,
                                        borderRadius: 6,
                                        marginTop: 12,
                                        border: '1px solid var(--color-gray-200)'
                                    }
                                }}
                                disableToolbar={false}
                                onChange={handleChange}
                            />
                        )
                    }}
                />
                <SC.Description>可以添加变量或填入文本，内容将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
            <PersonConfig />
        </>
    )
}
