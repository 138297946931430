import type { GroupProps, Option } from '@byecode/ui'
import { Button, Divider, Flex, Group, IconFont, Menu, Modal, SegmentedControl, Select, Text } from '@byecode/ui'
import type { AlignMent, ApplicationSettingTheme, TextAdvanceOption, TextProtocol } from '@lighthouse/core'
import { ListItem4ByecodeUi, TEXT_TEMPLATE_LIST, TextController, useAtomAction, useAtomData } from '@lighthouse/shared'
import { generateNameByAutoIncrement, nanoid } from '@lighthouse/tools'
import produce from 'immer'
import React, { useCallback, useMemo, useRef } from 'react'
import type { FieldPath } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import { updateAppVersionConfigAtom } from '@/atoms/application/action'
import { applicationSettingAtom } from '@/atoms/application/state'
import { useCurrentAppID } from '@/hooks/useApplication'

import { TEXT_ALIGN_OPTIONS } from '../constants'
import { AdvanceOptionExtra } from './AdvanceOptionExtra'

interface FormValue {
    font: TextProtocol & { advance: string; align?: AlignMent }
}

interface FontConfigureFormFragmentProps extends GroupProps {
    hideEllipsis?: boolean
}

export function FontConfigureFormFragment({ hideEllipsis, ...props }: FontConfigureFormFragmentProps) {
    const { control, getValues, setValue, watch } = useFormContext<FormValue>()
    const [advance, fontFamily, fontSize, letterSpacing, lineClamp, lineHeight] = watch([
        'font.advance',
        'font.fontFamily',
        'font.fontSize',
        'font.letterSpacing',
        'font.lineClamp',
        'font.lineHeight'
    ])

    const textValue: TextProtocol = useMemo(
        () => ({ fontFamily, fontSize, lineHeight, letterSpacing, lineClamp }),
        [fontFamily, fontSize, letterSpacing, lineClamp, lineHeight]
    )

    /** ********************************* 预设样式 start ********************************* */
    const appId = useCurrentAppID()
    const theme = useAtomData(
        applicationSettingAtom,
        useCallback(s => s.theme, [])
    )

    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)

    const onUpdate = useCallback(
        (values: ApplicationSettingTheme) => {
            updateAppVersionConfig({
                config: {
                    theme: values
                }
            })
        },
        [updateAppVersionConfig]
    )

    const handleDeleteAdvance = useCallback(
        async (id: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: '删除后，所有使用该预设的文本将会是“自定义”'
            })
            if (!isConfirm) {
                return
            }

            if (id === advance) {
                setValue('font.advance', '')
            }

            onUpdate({ ...theme, textPresetList: theme.textPresetList.filter(item => item.id !== id) })
        },
        [advance, onUpdate, setValue, theme]
    )

    // 新增预设
    const handleAddAdvance = useCallback(
        (values: Omit<TextAdvanceOption, 'id'>) => {
            const newValue = {
                ...values,
                id: nanoid(),
                name: generateNameByAutoIncrement(
                    values.name,
                    theme.textPresetList.map(item => item.name)
                )
            }
            onUpdate({ ...theme, textPresetList: [...theme.textPresetList, newValue] })

            // setValue('font.advance', newValue.id)
        },
        [onUpdate, theme]
    )

    const handleUpdateAdvance = useCallback(
        (value: TextAdvanceOption) => {
            onUpdate(
                produce(theme, draft => {
                    const index = draft.textPresetList.findIndex(item => item.id === value.id)
                    if (index === -1) {
                        return
                    }
                    draft.textPresetList[index] = value
                })
            )
        },
        [onUpdate, theme]
    )

    const rootRef = useRef<HTMLDivElement | null>(null)

    const advanceOptions = useMemo(() => {
        return theme.textPresetList.map<Option>(item => ({
            label: item.name,
            value: item.id,
            icon: <IconFont type="Text-e33om5n2" size={16} fill="var(--color-gray-400)" />,
            // describe: `${item.fontSize}/${item.lineHeight ?? 1}`,
            extra: (
                <AdvanceOptionExtra
                    popoverReference={rootRef}
                    advanceConfig={item}
                    handleUpdateAdvance={handleUpdateAdvance}
                    handleDeleteAdvance={handleDeleteAdvance}
                />
            )
        }))
    }, [handleDeleteAdvance, handleUpdateAdvance, theme.textPresetList])
    /** ********************************* 预设样式 end ********************************* */

    return (
        <Group ref={rootRef} {...props}>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>文本样式</Text>
                <Controller
                    control={control}
                    name="font.advance"
                    render={({ field }) => (
                        <Select
                            prefix={<IconFont type="Text-e33om5n2" size={20} />}
                            searchable
                            placeholder="添加文本样式"
                            options={advanceOptions}
                            clearable
                            value={field.value ?? ''}
                            onChange={field.onChange}
                            dropdownProps={{
                                extra: (
                                    <>
                                        <Menu trigger="hover" closeOnItemClick position="left-start">
                                            <Menu.Target>
                                                <Button
                                                    style={{
                                                        width: '100%',
                                                        padding: '0 16px',
                                                        color: 'var(--color-gray-900)',
                                                        borderRadius: 0,
                                                        justifyContent: 'flex-start',
                                                        gap: 8
                                                    }}
                                                    icon={
                                                        <Flex
                                                            alignItems="center"
                                                            styles={{
                                                                root: {
                                                                    borderRadius: 5,
                                                                    backgroundColor: 'var(--color-gray-200)'
                                                                }
                                                            }}
                                                        >
                                                            <IconFont type="Add2" size={16} color="var(--color-gray-400)" />
                                                        </Flex>
                                                    }
                                                    type="text"
                                                    // onClick={handleAddAdvance}
                                                >
                                                    <Text>新增文本样式</Text>
                                                </Button>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                {TEXT_TEMPLATE_LIST.map(item => (
                                                    <Menu.Item key={item.name} onClick={() => handleAddAdvance(item)}>
                                                        {item.name}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Dropdown>
                                        </Menu>
                                        <Divider my={8} />
                                    </>
                                )
                            }}
                            styles={{
                                root: { width: 180 },
                                dropdown: {
                                    minWidth: 'unset'
                                },
                                describe: {
                                    color: '$colorGray500'
                                },
                                itemsWrapper: {
                                    order: 2
                                },
                                dropdownExtra: {
                                    order: 1
                                },
                                suffix: {
                                    display: field.value ? undefined : 'none !important'
                                }
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>对齐</Text>
                <Controller
                    control={control}
                    name="font.align"
                    render={({ field }) => <SegmentedControl {...field} data={TEXT_ALIGN_OPTIONS} fullWidth style={{ width: 180 }} />}
                />
            </ListItem4ByecodeUi>

            {!advance && (
                <TextController
                    collapsible
                    hideEllipsis={hideEllipsis}
                    value={textValue}
                    onChange={v => {
                        ;(Object.keys(v) as FieldPath<TextProtocol>[]).forEach(k => {
                            if (getValues(`font.${k}`) === v[k]) {
                                return
                            }
                            setValue(`font.${k}`, v[k])
                        })
                    }}
                />
            )}
        </Group>
    )
}
