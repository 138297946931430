import type { AggregateResultField } from '../types'

type Noop = (id: string, fieldList: AggregateResultField[]) => void

type Listener = {
    id: string
    callback: Noop
}

/**
 * 聚合节点订阅上级节点输出
 * @date 2024/01/12 - 17:47:50
 *
 * @class AggregateNodeFieldList
 * @typedef {AggregateNodeFieldList}
 */
class AggregateNodeFieldList {
    #listeners: Listener[] = []

    subscribe(ids: string[], callback: Noop) {
        ids.forEach(id => {
            this.#listeners.push({ id, callback })
        })

        return () => {
            this.#listeners = this.#listeners.filter(item => !ids.includes(item.id))
        }
    }

    emit(id: string, fieldList: AggregateResultField[]): void {
        this.#listeners.forEach(listener => {
            if (listener.id === id) {
                listener.callback(id, fieldList)
            }
        })
    }

    clear(): void {
        this.#listeners = []
    }
}

export const aggregateNodeFieldList = new AggregateNodeFieldList()
