import { pointer, Text, Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { type UserProtocol,ActivityType } from '@lighthouse/core'
import { UserAvatar } from '@lighthouse/shared'
import { lightFormat } from 'date-fns'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { activityListAtom } from '@/atoms/workSpace/state'

const addBoldSvg = getAssetUrl('space', 'add_bold.svg')

const circleDashed = getAssetUrl('space', 'circle_dashed.svg')

const SCxInvite = styled.div`
    background: linear-gradient(97.03deg, #fcfcfc 2.06%, #eef0ff 93.97%);
    padding: 30px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
`

const SCxInviteList = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 19px 36px;
    width: 354px;
`

const SCxInviteItem = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('${circleDashed}');
`

const SCxInviteItemContent = styled.div`
    position: relative;
    z-index: 1;
    background-color: white;
    border-radius: 50%;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #eceeef 0%, var(--color-white) 100%);
`

const SCxAdd = styled.img``

const SCxFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`

const SCxDescription = styled.div`
    letter-spacing: 0.2px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

const SCxLink = styled.a`
    text-decoration: none;
    color: var(--color-gray-500);

    ${pointer}
    &:focus {
        color: var(--color-gray-500);
    }
`

interface InviteProps {
    inviteUsers: UserProtocol[]
}

export const Invite: React.FC<InviteProps> = ({ inviteUsers }) => {
    const activityList = useAtomValue(activityListAtom)
    const inviteList = useMemo(() => {
        return Array.from({ length: 9 }, (_, i) => {
            return inviteUsers?.[i]
        })
    }, [inviteUsers])

    const inviteActivity = useMemo(() => find(act => act.type === ActivityType.INVITATION_USER, activityList || []), [activityList])

    const lastItem = useMemo(() => {
        if (inviteUsers.length < 10) {
            return (
                <SCxInviteItem>
                    <SCxInviteItemContent>
                        <SCxAdd src={addBoldSvg} alt="add" />
                    </SCxInviteItemContent>
                </SCxInviteItem>
            )
        }
        if (inviteUsers.length === 10) {
            const user = inviteUsers[9]
            return (
                <Tooltip key={user.userId} title={user.username}>
                    <UserAvatar size="large" id={user.userId} name={user.username} avatar={user.avatar} />
                </Tooltip>
            )
        }
        return (
            <SCxInviteItem>
                <SCxInviteItemContent>
                    <Text weight={400} color="var(--color-gray-400)" size={12}>
                        +{inviteUsers.length - 10}
                    </Text>
                </SCxInviteItemContent>
            </SCxInviteItem>
        )
    }, [inviteUsers])

    return (
        <SCxInvite>
            <SCxInviteList>
                {inviteList.map((item, index) => {
                    if (item) {
                        return (
                            <Tooltip key={item.userId} title={item.username}>
                                <UserAvatar size="large" id={item.userId} name={item.username} avatar={item.avatar} />
                            </Tooltip>
                        )
                    }
                    return (
                        <SCxInviteItem key={index}>
                            <SCxInviteItemContent>
                                <SCxAdd src={addBoldSvg} alt="add" />
                            </SCxInviteItemContent>
                        </SCxInviteItem>
                    )
                })}
                {lastItem}
            </SCxInviteList>
            <SCxFooter>
                <SCxDescription>
                    定制用户不参与该活动，
                    <SCxLink href="https://www.byecode.com/demo" target="_blank">
                        详情点击
                    </SCxLink>
                </SCxDescription>
               {inviteActivity && <SCxDescription>
                    {lightFormat(inviteActivity?.startTime, 'yyyy年MM月dd日')}-{lightFormat(inviteActivity?.endTime, 'yyyy年MM月dd日')}，
                    <SCxLink
                        href="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gcevgxgrv071gxfp?singleDoc="
                        target="_blank"
                    >
                        活动规则
                    </SCxLink>
                </SCxDescription>}
            </SCxFooter>
        </SCxInvite>
    )
}
