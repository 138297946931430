import { hideScrollBar } from '@byecode/ui'
import { RIGHT_ASIDE_CLASS } from '@lighthouse/shared'
import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const Root = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-white);
    ${hideScrollBar()}
`

export const InnerDrawer = (props: React.ComponentPropsWithoutRef<'div'>) => {
    const hostEl = document.querySelector<HTMLElement>(`.${RIGHT_ASIDE_CLASS}`)

    if (!hostEl) {
        return null
    }

    return createPortal(<Root {...props} />, hostEl)
}
