import type { FC } from 'react'
import React, { useCallback, useEffect, useRef } from 'react'

import type { UseUploadFileSParameter } from '../../hooks'
import { useUploadFiles } from '../../hooks'
import { useUploadManageContext } from '../../UploadManage.context'

export interface UploadButtonProps extends React.ComponentPropsWithRef<'button'> {
    accept?: string
    multiple?: boolean
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
}

export const UploadButton: FC<UploadButtonProps> = props => {
    const { type = 'button', onClick, accept, multiple, uploadOptions, ...rest } = props

    const upload = useUploadFiles()
    const uploady = useUploadManageContext()

    const optionsRef = useRef<Pick<UseUploadFileSParameter, 'info' | 'options'>>(uploadOptions)
    optionsRef.current = uploadOptions

    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const input = inputRef.current

        if (!input) {
            return
        }

        function inputChange(e: Event) {
            const { files } = e.target as HTMLInputElement
            if (!files) {
                return
            }
            upload({ files: [...files], ...optionsRef.current })
            if (e.target) {
                ;(e.target as HTMLInputElement).value = ''
            }
        }

        input.addEventListener('change', inputChange)

        return () => {
            input.removeEventListener('change', inputChange)
        }
    }, [upload, uploady])

    const clickHandler = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            inputRef.current?.click()
            // uploady.showFileUpload({ autoUpload: false })
            onClick?.(e)
        },
        [onClick]
    )

    return (
        <>
            <button type={type} onClick={clickHandler} {...rest} />
            <input ref={inputRef} style={{ display: 'none' }} type="file" accept={accept} multiple={multiple} />
        </>
    )
}
