import { APPLICATION_VERSION_PROD } from '@lighthouse/shared'

import { useCurrentAppVersionId } from './useApplication'

/**
 * 根据当前应用版本判断是否禁用
 * PROD 版本禁用
 * @returns {boolean} isDisabled
 */
export const useIsDisabledWithVersion = () => {
    return false
}
