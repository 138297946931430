import { Breadcrumbs, Flex, IconFont } from '@byecode/ui'
import type { DataSourceAbstract, FilterCommonCondition, TableColumn, ViewField } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi, ModeSegmented, QuickFilter, viewNotFilterSettingFieldTypes } from '@lighthouse/shared'
import { Enum } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import type { BlockSettingsFormValue } from '..'
import { InnerDrawer } from '../InnerDrawer'
import { listCommonStyles } from './UserOperate/constants'
import { StyledIconWrapper } from './UserOperate/styles'

const SCxHeader = styled.div`
    padding: 12px 16px;
`

const SCxPopupTitle = styled.div`
    margin-bottom: 8px;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);
`

const TabEnum = Enum('normal', 'tile', 'disable')

const TABS = [
    { value: TabEnum.normal, label: '普通模式', icon: 'PropertySingleSelect' },
    { value: TabEnum.tile, label: '平铺模式', icon: 'StackSimple' },
    { value: TabEnum.disable, label: '不可筛选', icon: 'Forbidden' }
]

interface QuickFilterConfigureProps {
    list: string[]
    columns: ViewField[]
    dataSourceList: DataSourceAbstract[]
    onBack: () => void
}

export const QuickFilterConfigure: React.FC<QuickFilterConfigureProps> = ({ list, columns, dataSourceList, onBack }) => {
    const { control, watch } = useFormContext<BlockSettingsFormValue>()
    const disabledWithVersion = useIsDisabledWithVersion()
    const mode = watch('quickFilter.mode')
    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '筛选', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <SCxHeader>
                <SCxPopupTitle>筛选模式</SCxPopupTitle>
                <Controller
                    control={control}
                    name="quickFilter.mode"
                    render={({ field: { value, onChange } }) => (
                        <ModeSegmented disabled={disabledWithVersion} options={TABS} value={value} onChange={onChange} />
                    )}
                />
            </SCxHeader>
            {mode && mode !== 'disable' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group4ByecodeUi label="可供筛选的字段">
                        <Controller
                            control={control}
                            name="quickFilter.fieldIds"
                            render={({ field: { onChange } }) => {
                                return (
                                    <QuickFilter
                                        noSettingFields={viewNotFilterSettingFieldTypes}
                                        columns={columns}
                                        quickFilterRules={list}
                                        disablePortal
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </Group4ByecodeUi>
                </>
            )}
        </InnerDrawer>
    )
}

interface QuickFilterSettingProps {
    schema: DataSourceAbstract['schema']
    columns: ViewField[]
    dataSourceList: DataSourceAbstract[]
}

export const QuickFilterSetting: React.FC<QuickFilterSettingProps> = ({ schema, columns, dataSourceList }) => {
    const { watch } = useFormContext<BlockSettingsFormValue>()
    const [open, toggle] = useToggle(false)
    const quickFilterRules = watch('quickFilter.fieldIds')
    const mode = watch('quickFilter.mode')
    const list = useMemo(() => quickFilterRules?.filter(id => Boolean(schema[id])), [quickFilterRules, schema])
    const isActive = mode !== 'disable'
    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <StyledIconWrapper active={isActive}>
                        <IconFont type="Filter" size={18} fill={isActive ? 'var(--color-main)' : 'var(--color-gray-500)'} />
                    </StyledIconWrapper>
                    <div>筛选</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{isActive ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>

            {open && <QuickFilterConfigure list={list} columns={columns} dataSourceList={dataSourceList} onBack={() => toggle(false)} />}
        </>
    )
}
