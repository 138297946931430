import { Box, Button, Flex, IconFont, Modal, Popover, Text, Toast, Tooltip } from '@byecode/ui'
import { APPLICATION_VERSION_STATUS } from '@lighthouse/core'
import type { EnvDiffs } from '@lighthouse/shared'
import { APPLICATION_ENV_PROD, timeDistance, useApplicationContext, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { formatDistanceToNow } from 'date-fns'
import { useSetAtom } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'

import { mergeAppVersionAtom, updateAppStatusAtom } from '@/atoms/application/action'
import { appAtom, AppPublishStateAtom } from '@/atoms/application/state'
import {
    ExistPoolUserError,
    ExistSyncTableAndPoolUserError,
    ExistSyncTableError,
    PrivateTemplatePolicy,
    PrivateTemplatePolicyCheck
} from '@/components/ApplicationPublish/PublishWebsite/PrivateTemplateNotice'
import { UrlPreview } from '@/components/ApplicationPublish/PublishWebsite/UrlPreview'
import { useAppEnvList, useApplicationPrivateTemplate, useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import { PublishedContent } from '../../ApplicationHeader/PublishedContent'
import { MergeConfirm } from './MergeConfirm'

interface ShareButtonProps {
    popoverOpened?: boolean
    onPopoverChange?: (v: boolean) => void
}

export const ShareButton = ({ popoverOpened, onPopoverChange }: ShareButtonProps) => {
    // const [popoverOpen, setPopoverOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const rootRef = useRef<HTMLDivElement>(null)
    const updateAppStatusAction = useAtomAsyncAction(updateAppStatusAtom)
    const applicationPrivateTemplate = useApplicationPrivateTemplate()
    const appEnvId = useCurrentEnvId()
    const appId = useCurrentAppID()
    const dataSourceList = useDataSourceList(appId, appEnvId)
    const [diffData, setDiffData] = useState<EnvDiffs | undefined>(undefined)
    const mergeAppAction = useAtomAsyncAction(mergeAppVersionAtom)
    const setAppPublishState = useSetAtom(AppPublishStateAtom)
    const setAppInfo = useSetAtom(appAtom)
    const { hasUpdate: hasUpdateContent, mutateHasUpdate } = useApplicationContext()

    const {
        data: env,
    } = useSWR(
        ['getEnv'],
        () => {
            return srv.getEnv(appEnvId)
        },
        {
            revalidateOnFocus: false
        }
    )

    const handleOffline = useCallback(() => {
        updateAppStatusAction.run(APPLICATION_VERSION_STATUS.OFFLINE)
        Toast.success('取消发布成功')
    }, [updateAppStatusAction])

    const handleMerge = useCallback(async () => {
        const updateTime = await mergeAppAction.run()
        if (!updateTime) {
            return
        }

        setAppInfo(draft => {
            draft.updatedTime = updateTime
        })

        Toast.success('更新发布成功')
        // 手动改一下状态
        mutateHasUpdate?.(false)
        // 打开烟花弹窗
        setAppPublishState(draft => {
            draft.isSuccess = true
        })
    }, [mergeAppAction, mutateHasUpdate, setAppInfo, setAppPublishState])

    const getIsDiffs = useCallback(async () => {
        const envDiffs = await srv.getEnvDiffs(appEnvId, APPLICATION_ENV_PROD)
        setDiffData(envDiffs)
        const isAdditions = envDiffs?.additions && envDiffs.additions.length > 0
        const isDeletions = envDiffs?.deletions && envDiffs.deletions.length > 0
        const isErrors = envDiffs?.errors && envDiffs.errors.length > 0
        const isUpdates = envDiffs?.updates && envDiffs.updates.length > 0

        return isAdditions || isDeletions || isErrors || isUpdates
    }, [appEnvId])

    const [diffLoading, setDiffLoading] = useState(false)

    const errContent = useMemo(() => {
        if (!dataSourceList) {
            return
        }
        // const env = find(env => env.envId === appEnvId,envList)
        const isExitSync = dataSourceList.some(item => item.sync)
        const isExitPoolUser = !!env?.link?.envId
        if (isExitSync && isExitPoolUser) {
            return <ExistSyncTableAndPoolUserError isPublish />
        }
        if (isExitSync) {
            return <ExistSyncTableError isPublish />
        }
        if (isExitPoolUser) {
            return <ExistPoolUserError isPublish />
        }
    }, [dataSourceList, env?.link])

    const handleCanPrivateTemplate = useCallback(() => {
        if (!applicationPrivateTemplate?.canPrivateTemplate || !dataSourceList || !errContent) {
            return true
        }
        Modal.confirm({
            title: '更新发布失败',
            content: errContent,
            okStatus: 'primary',
            okText: '知道了',
            position: 'fixed',
            target: rootRef.current

        })
        return false
    }, [applicationPrivateTemplate?.canPrivateTemplate, dataSourceList, errContent])

    const handleCheck = useCallback(async () => {
        if (!hasUpdateContent) {
            Toast.info('暂无需要更新的内容', { icon: 'Smile', iconColor: 'var(--color-main)' })
            return
        }
        const isCanPublic = handleCanPrivateTemplate()
        if(!isCanPublic){
            return
        }
        setDiffLoading(true)
        const isDiff = await getIsDiffs()
        setDiffLoading(false)

        if (isDiff) {
            setOpen(true)
        } else {
            handleMerge()
        }
    }, [getIsDiffs, handleCanPrivateTemplate, handleMerge, hasUpdateContent])

    const updateTime = useAtomData(
        appAtom,
        useCallback(s => s.updatedTime, [])
    )

    const confirmOpenedRef = useRef(false)

    return (
        <>
            <Popover
                opened={popoverOpened}
                onChange={v => !confirmOpenedRef.current && onPopoverChange?.(v)}
                width={390}
                position="bottom-end"
                withinPortal
            >
                <Tooltip title="分享">
                    <Popover.Target>
                        <Button type="primary" icon={<IconFont type="SquareShareLine" size={16} />}>
                            分享
                            {hasUpdateContent && (
                                <Box
                                    style={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--color-yellow-500)'
                                    }}
                                />
                            )}
                        </Button>
                    </Popover.Target>
                </Tooltip>
                <Popover.Dropdown
                    ref={rootRef}
                    styles={{
                        dropdown: {
                            borderRadius: 12,
                            padding: '24px !important'
                        }
                    }}
                >
                    <PublishedContent
                        showShareContent
                        header={
                            <>
                                <Flex alignItems="center" justifyContent="space-between" mb={16}>
                                    <Flex direction="column" gap={8}>
                                        <Text weight={500} lineHeight="24px">
                                            {hasUpdateContent ? '将内容更新到线上' : '已发布所有内容'}
                                        </Text>
                                        <Text size={14} color="var(--color-gray-500)">
                                            上次发布：{updateTime && timeDistance(updateTime)}
                                        </Text>
                                    </Flex>
                                    <IconFont
                                        type="CloudCheck"
                                        size={40}
                                        color={hasUpdateContent ? 'var(--color-yellow-500)' : 'var(--color-green-500)'}
                                    />
                                </Flex>

                                <UrlPreview isProdHost onLink={() => onPopoverChange?.(false)} />
                            </>
                        }
                        footer={
                            <Flex gap={8} mt={16}>
                                <Button
                                    block
                                    size="lg"
                                    loading={updateAppStatusAction.loading}
                                    disabled={mergeAppAction.loading}
                                    onClick={async () => {
                                        confirmOpenedRef.current = true
                                        const isConfirm = await Modal.confirm({
                                            title: '您确定要取消发布应用吗？',
                                            content: '取消发布后，用户将无法访问应用，重新发布后可恢复。',
                                            okStatus: 'primary',
                                            okText: '确定'
                                        })

                                        confirmOpenedRef.current = false
                                        if (isConfirm) {
                                            handleOffline()
                                        }
                                    }}
                                >
                                    {updateAppStatusAction.loading ? '取消发布中' : '取消发布'}
                                </Button>
                                <Button
                                    block
                                    type="primary"
                                    icon={<IconFont type="ArrowsClockwise" size={16} color="var(--color-white)" />}
                                    size="lg"
                                    loading={diffLoading || mergeAppAction.loading}
                                    disabled={!hasUpdateContent || updateAppStatusAction.loading}
                                    onClick={handleCheck}
                                >
                                    {hasUpdateContent ? '更新发布' : '无更新内容'}
                                    {hasUpdateContent && (
                                        <Box
                                            style={{
                                                width: 10,
                                                height: 10,
                                                borderRadius: '50%',
                                                backgroundColor: 'var(--color-yellow-500)'
                                            }}
                                        />
                                    )}
                                </Button>
                            </Flex>
                        }
                        onClose={() => onPopoverChange?.(false)}
                    />
                </Popover.Dropdown>
            </Popover>
            {open && <MergeConfirm data={diffData} onOpen={setOpen} />}
        </>
    )
}
