import type { GroupProps } from '@byecode/ui'
import { Divider, Flex, Group, IconFont, Input, SegmentedControl } from '@byecode/ui'
import type { DesignProtocol, PageAbstract, PageShareConfig } from '@lighthouse/core'
import { DataSourceType, PageType } from '@lighthouse/core'
import { ListItem4ByecodeUi, useAtomAction, useSharedConfigDisabledWithVersion } from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { updateLocalPageAtom, updateRemotePageAtom } from '@/atoms/page/action'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { SelectDataSource } from '../../../../../packages/shared/src/components/SelectDataSource'
import { DesignSetting } from '../DesignSetting'
import { SeoSearchEngine } from './SeoSearchEngine'
import { PageShare } from './Share'
import { PageVisibility } from './Visibility'

interface PageSettingProps {
    pageContent: PageAbstract
    mode?: GroupProps['mode']
}

const PageSetting: React.FC<PageSettingProps> = ({ pageContent, mode }) => {
    const { id, type, shareConfig, design, name, dsId } = pageContent
    const [activeTab, setActiveTab] = useState('basic')

    const { run: updateLocalPage } = useAtomAction(updateLocalPageAtom)
    const { run: updateRemotePage } = useAtomAction(updateRemotePageAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const disabledWithVersion = useSharedConfigDisabledWithVersion()

    const debounceUpdateRemotePage = useMemo(
        () =>
            debounce(300, async (...args: Parameters<typeof updateRemotePage>) => {
                await updateRemotePage(...args)
            }),
        [updateRemotePage]
    )

    const updatePage = useCallback(
        (...value: Parameters<typeof updateRemotePage>) => {
            updateLocalPage(...value)
            debounceUpdateRemotePage(...value)
        },
        [debounceUpdateRemotePage, updateLocalPage]
    )

    // 获取数据源
    const dataSourceList = useDataSourceList(appId, envId)

    const selectDtaSourceList = useMemo(() => {
        return dataSourceList.filter(cur => {
            if (cur.type === DataSourceType.aggregateDataSource) {
                return false
            }
            return !(cur.type === DataSourceType.userDataSource && type === PageType.creator)
        })
    }, [dataSourceList, type])

    // const disabled = useMemo(() => {
    //     const option = find(item => item.value === dsId, options)
    //     return Boolean((type === PageType.creator || type === PageType.document) && option)
    // }, [dsId, options, type])

    const methods = useForm({
        defaultValues: clone({ shareConfig, design, name, dsId })
    })

    useEffect(() => {
        const subscribe = methods.watch(values => {
            const { shareConfig, design, ...rest } = values
            const validShareConfig = shareConfig as PageShareConfig
            const validDesign = design as DesignProtocol
            updatePage(clone({ ...rest, id, shareConfig: validShareConfig, design: validDesign }))
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [updatePage, id, methods])

    return (
        <FormProvider {...methods}>
            <Flex grow styles={{ root: { padding: '0 16px' } }} mt={12}>
                <SegmentedControl
                    disabled={false}
                    fullWidth
                    size="lg"
                    value={activeTab}
                    onChange={setActiveTab}
                    data={[
                        { label: '基础', icon: <IconFont fill="var(--color-gray-400)" type="SlidersHorizontal" />, value: 'basic' },
                        { label: '设计', icon: <IconFont fill="var(--color-gray-400)" type="Palette" />, value: 'design' }
                    ]}
                />
            </Flex>
            {activeTab === 'basic' && (
                <>
                    <Group label="基础">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>数据表</div>
                            <Controller
                                control={methods.control}
                                name="dsId"
                                render={({ field }) => (
                                    <SelectDataSource
                                        withinPortal
                                        disabled={disabledWithVersion}
                                        position="bottom-end"
                                        dataSourceList={selectDtaSourceList}
                                        {...field}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>标题</div>
                            <Input
                                maxLength={30}
                                {...methods.register('name', {
                                    onChange: v => {
                                        if (!v) {
                                            return '未命名页面'
                                        }
                                    }
                                })}
                                styles={{
                                    wrapper: { width: 180 }
                                }}
                            />
                        </ListItem4ByecodeUi>
                    </Group>

                    <Divider color="var(--color-gray-200)" />

                    <PageShare />

                    <Divider color="var(--color-gray-200)" />

                    <PageVisibility pageId={id} mode={mode} addMemberWithinPortal />

                    <Divider color="var(--color-gray-200)" />

                    <SeoSearchEngine pageId={id} />
                </>
            )}

            {activeTab === 'design' && <DesignSetting host="page" containerId={id} />}
        </FormProvider>
    )
}
export default PageSetting
