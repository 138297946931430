import { Input, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

import { UploadButton } from '../UploadManage'

export const DeleteButton = styled.div`
    position: absolute;
    right: -6px;
    top: -6px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #fff;
    background: #000;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
`

export const Container = styled.div.withConfig<{ disabled?: boolean }>({ shouldForwardProp: p => p !== 'disabled' })`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: space-between;
    gap: 8px;
    /* width: 267px; */
    height: 80px;
    background: #ffffff;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover ${DeleteButton} {
                opacity: 1;
            }
        `}
`

export const ImageWrapper = styled.div<{ imgUrl?: string }>`
    align-self: center;
    position: relative;
    width: 64px;
    height: 64px;
    vertical-align: middle;
    border-radius: 2px;
    background: var(--color-gray-100) center center / contain no-repeat;
    background-image: ${({ imgUrl }) => `url('${imgUrl}')`};
    overflow: hidden;
`

export const CardUploadButton = styled(UploadButton)`
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const FileSize = styled.div`
    background-color: rgba(56, 56, 56, 0.4);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 17px;
    font-size: 12px;
    text-indent: 0.5em;
    text-align: left;
    color: var(--color-white);
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: 12px;
    overflow: hidden;
`
export const Content = styled.div`
    flex: 1;
    overflow: hidden;
    padding: 8px 8px 8px 4px;
`

export const TextField = styled.div`
    height: 28px;
    display: flex;
    align-items: center;
    gap: 12px;
    overflow: hidden;
    font-size: 14px;
`

export const TitleInput = styled(Input)`
    /* flex: 1; */
`

export const LinkButton = styled.span`
    font-size: 12px;
    color: var(--color-main);
    cursor: pointer;

    > iconpark-icon {
        margin-left: 4px;
        font-size: 14px;
        vertical-align: middle;
    }
`
export const Handle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--color-gray-50);
    border-right: 1px solid var(--color-gray-200);
    border-radius: 5px 0 0 5px;
    width: 20px;
    height: 100%;

    ${pointer}

    :hover {
        background-color: var(--color-gray-100);
    }
`
