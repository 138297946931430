import { useAtomData } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'

import { dataDrawerStateAtom } from '@/atoms/application/state'
import { pageAtomFamily, selectedBlockAtomFamily } from '@/atoms/page/state'
import { useRootPageContext } from '@/contexts/PageContext'
import { usePageCurrentDsAndRecord } from '@/hooks/usePageCurrentDsAndRecord'

import { PageDataDrawer } from './PageDataDrawer'
import { PageFooterToolBar } from './PageFooterToolBar'

export const PageFooter: React.FC = () => {
    const rootPageId = useRootPageContext()
    const visible = useAtomData(dataDrawerStateAtom)
    // const [pageId, stackId] = useAtomData(
    //     lastPageOfStackAtom,
    //     useCallback(s => {
    //         return [s?.pageId || '', s?.stackId || '']
    //     }, [])
    // )
    const { pageId, stackId, curr, prev, isRecordPage } = usePageCurrentDsAndRecord()
    const page = useAtomData(pageAtomFamily(pageId))
    const selectedBlock = useAtomData(selectedBlockAtomFamily({ rootPageId, pageId, stackId }))

    const pageDsId = useMemo(() => {
        return isRecordPage ? curr.datasource?.id : page?.dsId
    }, [curr.datasource?.id, isRecordPage, page?.dsId])

    return (
        <>
            {visible &&
                createPortal(
                    <PageDataDrawer
                        blockData={selectedBlock}
                        pageId={pageId}
                        pageDsId={pageDsId}
                        pageRecordId={curr.recordId}
                        parentRecordId={prev.recordId}
                        isRecordPage={isRecordPage}
                    />,
                    document.body
                )}
            <PageFooterToolBar />
        </>
    )
}
