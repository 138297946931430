import { type RecordLikeProtocol, type SchemaProtocol, type TableColumns } from '@lighthouse/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import SimpleTableRecord from './SimpleTableRecord'

interface SimpleTableBodyProps {
    schema: SchemaProtocol['schema']
    tableProps: TableColumns
    scrollRef: React.RefObject<HTMLDivElement>
    records: RecordLikeProtocol[]
}

const SCxTableContainer = styled.div``

const SimpleTableBody: React.FC<SimpleTableBodyProps> = ({ schema, tableProps, scrollRef, records }) => {
    const [scrollDom, setScrollDom] = useState(scrollRef.current)

    const visibleTableProps = useMemo(() => tableProps.filter(item => item.visible && schema[item.id]), [schema, tableProps])
    const rowVirtualizer = useVirtualizer({
        count: records?.length ?? 0,
        scrollPaddingStart: 37,
        getScrollElement: () => scrollDom,
        estimateSize: () => 36,
        getItemKey: (index: number) => records[index].id,
        overscan: 30
    })

    const count = records.length

    const maxWidth = useMemo(() => {
        return visibleTableProps.reduce((prev, cur) => {
            return prev + (cur.width || 160)
        }, 60)
    }, [visibleTableProps])

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: visibleTableProps.length,
        getScrollElement: () => scrollDom,
        paddingStart: 60,
        estimateSize: index => visibleTableProps[index].width || 160,
        overscan: 14
    })

    useEffect(() => {
        if (!scrollDom) {
            setScrollDom(scrollRef.current)
        }
    }, [scrollDom, scrollRef])

    useUpdateEffect(() => {
        startTransition(() => {
            columnVirtualizer.measure()
        })
    }, [visibleTableProps])

    return (
        <SCxTableContainer
            style={{
                width: `${maxWidth}px`,
                height: `${rowVirtualizer.getTotalSize()}px`,
                position: 'relative'
            }}
        >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const { index } = virtualRow
                const record = records[index]
                const { id } = record
                return (
                    <SimpleTableRecord
                        key={id}
                        isLast={count - 1 === index}
                        index={index}
                        schema={schema}
                        record={record}
                        tableProps={visibleTableProps}
                        virtualRow={virtualRow}
                        columnVirtualizer={columnVirtualizer}
                    />
                )
            })}
        </SCxTableContainer>
    )
}

export default SimpleTableBody
