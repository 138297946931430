import { backgroundTransitionOnClick, flex, IconFont, Input, pointer, Select } from '@byecode/ui'
import { IconPicker } from '@lighthouse/shared'
import styled from 'styled-components'

export const Container = styled.div`
    ${flex}
    align-items: center;
    height: 36px;
    > :not(:first-child) {
        margin-left: 8px;
    }
`

export const Icon = styled(IconFont)`
    font-size: var(--font-size-base);
    ${pointer}
`

export const TitleInput = styled(Input)`
    width: 102px !important;
    height: 36px;
    line-height: 36px;
    overflow: hidden;

    > input {
        width: 100%;
        height: 100%;
        line-height: 1;
        box-sizing: border-box;
        margin: 0;
        padding: 2px 8px;
        background-color: var(--color-white);
    }
`
export const LinkTypeSelect = styled(Select)``

export const ToLinkSelect = styled.div``

export const Handle = styled.div`
    padding: 4px;
    ${Icon} {
        cursor: grab;
    }
`

export const LinkUrl = styled(Input)``
export const ExtraItem = styled.div`
    width: 100%;
    padding: 9px 12px;
    /* border-radius: 4px; */
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    ${pointer}
    ${backgroundTransitionOnClick}
`

export const ExtraContainer = styled.div`
    width: 100%;
`
export const BtnIconPicker = styled(IconPicker)`
    font-size: var(--font-size-base);
    padding: 9px;
    border: 1px solid var(--color-gray-200);
    border-radius: 7px;
    box-sizing: border-box;
    background-color: var(--color-white);
`
