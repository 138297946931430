import { Button, IconFont } from '@byecode/ui'
import React, { useEffect, useState } from 'react'

import { Unit } from '../Mics'

export const Loading: React.FC = () => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }, [loading])

    return (
        <Unit title="加载中">
            <Button type="primary" loading>
                加载中
            </Button>
            <Button danger loading={loading} onClick={() => setLoading(true)}>
                点我加载
            </Button>
            <Button type="primary" loading={loading} onClick={() => setLoading(true)}>
                点我加载
            </Button>
            <Button icon={<IconFont type="BlockTable" />} disabled loading />
        </Unit>
    )
}
