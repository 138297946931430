import { Group, IconFont, SegmentedControl } from '@byecode/ui'
import type { Field, SubFormBlockConfig } from '@lighthouse/core'
import {
    type FieldInputConfigProtocol,
    type FieldInputType,
    type TableColumn,
    type ViewField,
    DataSourceType,
    FilterMode,
    VariableType
} from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    fieldSettingMap,
    findParentCustomViewBlock,
    findParentFormBlock,
    getAppointField,
    getDefaultValueOptions,
    getFieldBlockWithDsId,
    getSystemOption,
    getUserDatasourceOptions,
    getViewColumns,
    getViewOptions,
    isRichTextValue,
    ListItemPaddingByecodeUi,
    PURE_TEXT_INPUT,
    pureTextFieldTypes,
    RichTextEditor,
    SelectMode,
    silentInputTypes,
    transformNode2FlowLayoutNode,
    useAtomData,
    USER_DATASOURCE,
    VariableSelect,
    VariableSourceType,
    ViewFieldDisplayConfigure,
    ViewFieldFilterConfigure,
    viewNotFilterSettingFieldTypes,
    ViewSortConfigure
} from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { InputControl } from '../../InputControl'
import { ListItemMenu } from '../../ListItemMenu'
import { SwitchControl } from '../../SwitchControl'
import { CreateRecordV2 } from '../../UserOperate'
import { DIRECTION_OPTIONS, SHOW_MODE_OPTIONS, VIEW_DATASOURCE_OPTIONS } from '../constants'
import type { BaseFieldInputSetting } from '../types'

interface StyleProps extends BaseFieldInputSetting {}

const SCxRelationSelectContainer = styled.div`
    padding: 0 8px;
`
const VARIABLE_FIELD_TYPES = new Set<FieldInputType>([
    'date',
    'email',
    'person',
    'phoneNumber',
    'url',
    'text',
    'relativeSelect',
    'number',
    'checkbox',
    'slider',
    'cascade'
])

export const Style: React.FunctionComponent<StyleProps> = ({ blockId = '', mode, pointer, prefix }) => {
    const btnRef = useRef<HTMLDivElement>(null)
    const isUsedInForm = mode === 'form'
    const isUsedInSubForm = mode === 'subForm'
    const isNotParentSubForm = mode !== 'subForm'

    const { watch, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [inputType, fieldPointer = '', showTitle, relativePointer = '', relativeShowMode] = watch([
        `${prefix}inputType`,
        `${prefix}fieldPointer`,
        `${prefix}showTitle`,
        `${prefix}relativeSelect.relativePointer`,
        `${prefix}relativeSelect.showMode`
    ])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, pointer)
    const relatedDataSource = useDataSource(appId, envId, relativePointer)

    const { schema: relatedSchema, viewOptions: relatedViewOptions } = relatedDataSource ?? { schema: {} }

    const { tableProps: relatedTableProps = [] } = relatedViewOptions ?? {}

    const columns = getViewColumns({
        tableProps: relatedTableProps,
        schema: relatedSchema
    })

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    const { field: canCreateRecordField } = useController({ control, name: `${prefix}relativeSelect.canCreateRecord` })
    const { field: creatingConfigField } = useController({ control, name: `${prefix}relativeSelect.creatingConfig` })

    const [saveField, showRelativeSetting] = useMemo(() => {
        const field = dataSource?.schema?.[fieldPointer]
        const isRelativeSelect = field && field?.type !== 'select' && inputType === 'relativeSelect'

        return [dataSource?.schema?.[fieldPointer], isRelativeSelect]
    }, [dataSource?.schema, fieldPointer, inputType])

    const [stackId, pageId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || '', s?.rootPageId || ''], [])
    )

    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const validateFieldType = useCallback(
        (field: Field) => {
            if (inputType === 'relativeSelect') {
                return saveField?.type === 'select' ? field.type === 'select' : pureTextFieldTypes.has(field.type)
            }
            return fieldSettingMap[inputType].includes(field.type)
        },
        [inputType, saveField?.type]
    )

    const userOption = useMemo(() => {
        if (!saveField) {
            return
        }
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        if (!userDataSource) {
            return
        }

        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType
        })
    }, [saveField, dataSourceList, validateFieldType])

    // const defaultValueDataSourceList = useMemo(() => ([]), [])

    const pageType = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.type ?? '', [])
    )
    const isShowDefaultValue = pageType !== 'edit' && (isUsedInForm || isUsedInSubForm) && saveField

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    const options = useMemo(() => {
        if (!saveField) {
            return
        }
        if (inputType === 'relativeSelect') {
            return getDefaultValueOptions({
                sources: [
                    { dataSource: curr.datasource, page: curr.page, sourceType: VariableSourceType.page },
                    {
                        page: prev.page,
                        dataSource: prev.datasource,
                        sourceType: VariableSourceType.parentPage
                    }
                ],
                inputType: saveField?.type,
                validateFieldType: fieldType => {
                    return saveField.type === 'select' ? fieldType === 'select' : pureTextFieldTypes.has(fieldType)
                }
            })
        }

        return getDefaultValueOptions({
            sources: [
                { dataSource: curr.datasource, page: curr.page, sourceType: VariableSourceType.page },
                {
                    page: prev.page,
                    dataSource: prev.datasource,
                    sourceType: VariableSourceType.parentPage
                }
            ],
            inputType: saveField?.type,
            validateFieldType: fieldType => {
                if (fieldType === 'notes') {
                    return inputType === fieldType
                }
                return fieldSettingMap[inputType].includes(fieldType)
            }
        })
    }, [saveField, inputType, curr, prev])

    const systemOption = useMemo(() => {
        if (!saveField || inputType !== 'date') {
            return
        }
        return getSystemOption(['TODAY', 'TOMORROW', 'YESTERDAY', 'NOW'])
    }, [saveField, inputType])

    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const parentForm = useMemo(() => findParentFormBlock(blockId, blocks)(nodes), [blockId, blocks, nodes])

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()
    console.log('🚀 ~ file: index.tsx ~ line 245 ~ fieldBlocksWithDsId', fieldBlocksWithDsId)

    const selectRecordFieldBlocksWithDsId = useMemo(
        () =>
            fieldBlocksWithDsId.filter(fieldBlock => {
                const {
                    config: { initialValue },
                    parentFormContainerId,
                    id
                } = fieldBlock
                if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                    return false
                }
                return id !== blockId && parentFormContainerId === parentForm?.id
            }),
        [blockId, fieldBlocksWithDsId, parentForm?.id]
    )

    const selectDataSourceVariableEnable = useMemo(() => {
        if (!isUsedInForm) {
            return
        }
        return fieldBlocksWithDsId.every(fieldBlock => {
            const {
                config: { initialValue }
            } = fieldBlock
            if (initialValue && initialValue.type === VariableType.SELECT_DATASOURCE) {
                return !initialValue?.selectDataSourceVariable?.filter.expression?.conditions?.some(condition => {
                    return condition?.paramList?.[0]?.inputVariable?.blockId === blockId
                })
            }
            return true
        })
    }, [blockId, fieldBlocksWithDsId, isUsedInForm])

    return (
        <>
            <Group label="基础">
                <Controller
                    name={`${prefix}showTitle`}
                    render={({ field: { value, onChange } }) => (
                        <SwitchControl
                            checked={value}
                            label="显示标题"
                            onChange={e => {
                                onChange?.(e.currentTarget.checked)
                            }}
                        />
                    )}
                />
                {showTitle && <Controller name={`${prefix}title`} render={({ field }) => <InputControl label="标题" {...field} />} />}
                {!silentInputTypes.has(inputType) && (
                    <Controller name={`${prefix}placeholder`}  render={({ field }) => <InputControl label="占位符" {...field} />} />
                )}

                {isShowDefaultValue && VARIABLE_FIELD_TYPES.has(inputType) && (
                    <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                        <Text>默认值</Text>
                        <Controller
                            name={`${prefix}initialValue`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <VariableSelect
                                    field={saveField}
                                    options={options}
                                    value={value}
                                    systemOption={systemOption}
                                    userOption={userOption}
                                    dataSourceList={dataSourceList}
                                    selectDataSourceProps={{
                                        enable: selectDataSourceVariableEnable,
                                        validateFieldType,
                                        fieldBlocksWithDsId: selectRecordFieldBlocksWithDsId
                                    }}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                )}
                {isShowDefaultValue && inputType === 'notes' && (
                    <Group
                        mode="none"
                        styles={{
                            wrapper: {
                                padding: '0!important'
                            },
                            root: {
                                padding: '0!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                        label="默认值"
                    >
                        <Controller
                            name={`${prefix}initialValue`}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                                const initialValue = value && value?.type === VariableType.VALUE ? value?.valueVariable?.value : ''
                                const richTextValue = isRichTextValue(initialValue) ? initialValue : null
                                return (
                                    <RichTextEditor
                                        styles={{
                                            editorContainer: {
                                                border: '1px solid var(--color-gray-200)',
                                                borderRadius: 8,
                                                marginTop: 4
                                            },
                                            editorContent: {
                                                backgroundColor: 'var(--color-gray-50)'
                                            }
                                        }}
                                        minHeight={200}
                                        config={{
                                            image: false,
                                            heading: true,
                                            variable: false
                                        }}
                                        value={richTextValue}
                                        // autofocus="end"
                                        onChange={val => onChange({ type: VariableType.VALUE, valueVariable: { value: val } })}
                                    />
                                )
                            }}
                        />
                    </Group>
                )}
                {inputType === 'relativeSelect' && (
                    <>
                     {isNotParentSubForm &&   <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示方式</Text>
                            <div style={{ width: 180 }}>
                                <Controller
                                    name={`${prefix}relativeSelect.showMode`}
                                    control={control}
                                    render={({ field }) => (
                                        <SegmentedControl
                                            data={SHOW_MODE_OPTIONS}
                                            fullWidth
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </div>
                        </ListItemPaddingByecodeUi>}
                        {relativeShowMode !== 'input' && (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>排列方式</Text>
                                <div style={{ width: 180 }}>
                                    <Controller
                                        name={`${prefix}relativeSelect.direction`}
                                        control={control}
                                        render={({ field }) => (
                                            <SegmentedControl
                                                data={DIRECTION_OPTIONS}
                                                fullWidth
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </div>
                            </ListItemPaddingByecodeUi>
                        )}
                    </>
                )}
            </Group>
            {showRelativeSetting && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    {relativeShowMode === 'input' && (
                        <Group label="选择窗">
                            <Controller
                              name={`${prefix}relativeSelect.showType`}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SelectMode
                                            options={VIEW_DATASOURCE_OPTIONS}
                                            itemWidth="100%"
                                            styles={{
                                                image: {
                                                    padding: '20px 0px 0px 0px',
                                                    borderRadius: '8px',
                                                    width: 158,
                                                    height: 88,
                                                    outline: 'var(--color-gray-200) solid 1px',
                                                    background: 'var(--color-white)'
                                                },
                                                root: {
                                                    width: '100%',
                                                    gap: '8px',
                                                    marginTop: 8
                                                }
                                            }}
                                            value={value}
                                            onChange={val => onChange(val)}
                                        />
                                    )
                                }}
                            />
                        </Group>
                    )}
                    {relatedDataSource && (
                        <SCxRelationSelectContainer>
                            {relativeShowMode === 'input' && (
                                <Controller
                                    name={`${prefix}relativeSelect.viewFieldSettings`}
                                    render={({ field: { onChange, value } }) => {
                                        const currentColumns = getViewColumns({
                                            tableProps: relatedTableProps,
                                            value,
                                            schema: relatedSchema
                                        })
                                        // const currentPrimaryField = currentColumns?.[0]?.fieldId ?? ''
                                        const visibleNum = currentColumns.filter(item => item.visible).length
                                        const isAllVisible = visibleNum === currentColumns.length
                                        return (
                                            <ListItemMenu
                                                label="字段设置"
                                                compact
                                                value={isAllVisible ? '全部显示' : `显示${visibleNum}个字段`}
                                                icon={<IconFont type="Setting" color="var(--color-gray-400)" size={16} />}
                                                ref={btnRef}
                                                popupContent={
                                                    <ViewFieldDisplayConfigure
                                                        columns={currentColumns}
                                                        onChange={val => {
                                                            const newValue = reduce<TableColumn, ViewField[]>(
                                                                (preVal, curVal) => {
                                                                    const columnItem = find(
                                                                        item => item.fieldId === curVal.id,
                                                                        currentColumns
                                                                    )
                                                                    if (!columnItem) {
                                                                        return preVal
                                                                    }
                                                                    return [...preVal, { ...columnItem, visible: curVal.visible }]
                                                                },
                                                                [],
                                                                val
                                                            )
                                                            onChange(newValue)
                                                        }}
                                                    />
                                                }
                                            />
                                        )
                                    }}
                                />
                            )}

                            <Controller
                                name={`${prefix}relativeSelect.filter`}
                                render={({ field: { onChange, value } }) => {
                                    const filter = value
                                    const filtersLength = filter?.expression?.conditions?.length || 0
                                    return (
                                        <ListItemMenu
                                            width="auto"
                                            label="筛选选项"
                                            compact
                                            value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                                            icon={<IconFont type="Filter" color="var(--color-gray-400)" size={16} />}
                                            popupContent={
                                                <ViewFieldFilterConfigure
                                                    dataSource={relatedDataSource}
                                                    dataSourceList={dataSourceList}
                                                    // sourceType={variableSourceType}
                                                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                                                    // sourceDataSource={defaultValueDataSourceList}
                                                    sources={sources}
                                                    columns={columns}
                                                    paramsMode={FilterMode.CUSTOM}
                                                    primaryField={primaryField}
                                                    fieldMode={FilterMode.NORMAL}
                                                    filter={filter}
                                                    noSettingFields={viewNotFilterSettingFieldTypes}
                                                    onFilter={onChange}
                                                />
                                            }
                                        />
                                    )
                                }}
                            />

                            <Controller
                                name={`${prefix}relativeSelect.disabledFilter`}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    const filtersLength = value?.expression?.conditions?.length || 0
                                    return (
                                        <ListItemMenu
                                            width="auto"
                                            label="禁用选项"
                                            compact
                                            value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                                            icon={<IconFont type="Forbidden" color="var(--color-gray-400)" size={16} />}
                                            popupContent={
                                                <ViewFieldFilterConfigure
                                                    dataSource={relatedDataSource}
                                                    dataSourceList={dataSourceList}
                                                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                                                    sources={sources}
                                                    columns={columns}
                                                    paramsMode={FilterMode.CUSTOM}
                                                    primaryField={primaryField}
                                                    fieldMode={FilterMode.NORMAL}
                                                    filter={value}
                                                    noSettingFields={viewNotFilterSettingFieldTypes}
                                                    onFilter={onChange}
                                                />
                                            }
                                        />
                                    )
                                }}
                            />

                            <Controller
                                name={`${prefix}relativeSelect.sorts`}
                                render={({ field: { onChange, value } }) => (
                                    <ListItemMenu
                                        width="auto"
                                        label="排序数据"
                                        compact
                                        value={Boolean(value?.length) && `${String(value?.length)} 条排序`}
                                        icon={<IconFont type="ArrowsDownUp" color="var(--color-gray-400)" size={16} />}
                                        popupContent={
                                            <ViewSortConfigure
                                                noSettingFields={viewNotFilterSettingFieldTypes}
                                                columns={columns}
                                                primaryField={primaryField}
                                                sorters={value}
                                                onChangeSorter={onChange}
                                                isShowDsName={relatedDataSource?.type === DataSourceType.joinDataSource}
                                            />
                                        }
                                    />
                                )}
                            />
                            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />

                            {relativeShowMode === 'input' && (
                                <CreateRecordV2
                                    value={{ canCreateRecord: canCreateRecordField.value, creatingConfig: creatingConfigField.value }}
                                    dataSource={relatedDataSource}
                                    onChange={val => {
                                        const { canCreateRecord, creatingConfig } = val
                                        canCreateRecordField.onChange(canCreateRecord)
                                        creatingConfigField.onChange(creatingConfig)
                                    }}
                                />
                            )}
                        </SCxRelationSelectContainer>
                    )}
                </>
            )}
        </>
    )
}
