import { Box, Button, Flex, IconFont, Modal, singleTextEllipsis } from '@byecode/ui'
import produce from 'immer'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import type { AnalysisConfigure } from '@/services'
import * as srv from '@/services'
import { useGetAnalyzeConfigure } from '@/shared/reusable'

import { Card } from '../components'
import { PLATFORM_OPTIONS } from '../constants'
import { CreateModal } from './CreateModal'

const Empty = styled.div`
    height: 356px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
    line-height: 22px;
`
const EmptyTitle = styled.div`
    font-weight: 600;
`
const EmptySubtitle = styled.div`
    color: var(--color-gray-400);
`

const Item = styled.div`
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & + & {
        border-top: 1px solid var(--color-gray-200);
    }
`

const IconBox = styled(Box)`
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: var(--color-gray-100);
`

const Title = styled(Box)`
    font-size: 14px;
`

const Subtitle = styled(Box<'div'>)`
    color: var(--color-gray-500);
    font-size: 12px;
    line-height: 20px;
    ${singleTextEllipsis()}
`

const DangerButton = styled(Button)`
    color: var(--color-red-500) !important;
    border: 1px solid var(--color-red-100) !important;
    background-color: var(--color-red-100) !important;
    &:hover {
        background-color: var(--color-red-200) !important;
    }
`
const GrayButton = styled(Button)`
    color: var(--color-gray-500) !important;
    border: 1px solid var(--color-gray-100) !important;
    background-color: var(--color-gray-100) !important;
    &:hover {
        background-color: var(--color-gray-200) !important;
    }
`

export const AnalyzeSettings = () => {
    const { data, mutate, isLoading } = useGetAnalyzeConfigure()
    const disabledWithVersion = useIsDisabledWithVersion()

    const [open, setOpen] = useState(false)
    const [editData, setEditData] = useState<AnalysisConfigure>()

    const onOk = useCallback(
        async (values: Omit<AnalysisConfigure, 'id'>) => {
            if (editData) {
                // 更新
                const res = await srv.UpdateAnalysisConfigure({ ...values, id: editData.id })
                if (res) {
                    mutate(
                        d =>
                            produce(d, draft => {
                                const current = draft?.find(item => item.id === editData.id)
                                if (current) {
                                    current.code = values.code
                                    current.title = values.title
                                    current.type = values.type
                                }
                            }),
                        {
                            revalidate: false
                        }
                    )
                    setEditData(undefined)
                    setOpen(false)
                }
            } else {
                // 新建
                const res = await srv.createAnalysisConfigure(values)
                if (res) {
                    mutate(d => [...(d || []), { ...values, id: res }], {
                        revalidate: false
                    })
                    setOpen(false)
                }
            }
        },
        [editData, mutate]
    )

    const onDelete = useCallback(
        async (id: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除？',
                content: '删除后不可恢复'
            })
            if (!isConfirm) {
                return
            }
            const res = await srv.deleteAnalysisConfigure(id)
            if (res) {
                mutate(d => d?.filter(item => item.id !== id))
            }
        },
        [mutate]
    )

    return (
        <Card
            loading={isLoading}
            title="自有统计"
            extra={
                disabledWithVersion ? undefined : (
                    <Button icon={<IconFont type="Add" />} onClick={() => setOpen(true)}>
                        添加
                    </Button>
                )
            }
        >
            {!data || data.length === 0 ? (
                <Empty>
                    <IconFont type="DefaultNoStatistics" size={144} />
                    <EmptyTitle>未绑定自有统计</EmptyTitle>
                    <EmptySubtitle>点击右上角「添加」按钮以添加自有统计</EmptySubtitle>
                </Empty>
            ) : (
                data.map(item => {
                    const currentOption = PLATFORM_OPTIONS.find(option => option.value === item.type)
                    return (
                        <Item key={item.id}>
                            <Flex style={{ flexBasis: '50%', overflow: 'hidden' }} alignItems="center" gap="12px">
                                <IconBox>
                                    <IconFont size={24} fill="#E36037" type={currentOption?.icon || ''} />
                                </IconBox>
                                <Box style={{ overflow: 'hidden' }}>
                                    <Title>{item.title}</Title>
                                    <Subtitle>{currentOption?.label}</Subtitle>
                                </Box>
                            </Flex>
                            <Flex alignItems="center" gap="8px">
                                <DangerButton icon={<IconFont type="Trash" />} onClick={() => onDelete(item.id)}>
                                    删除
                                </DangerButton>
                                <GrayButton
                                    icon={<IconFont type="edit" />}
                                    onClick={() => {
                                        setEditData(item)
                                        setOpen(true)
                                    }}
                                >
                                    编辑
                                </GrayButton>
                            </Flex>
                        </Item>
                    )
                })
            )}

            <CreateModal
                open={open}
                onClose={() => {
                    setOpen(false)
                    if (editData) {
                        setEditData(undefined)
                    }
                }}
                value={editData}
                onOk={onOk}
            />
        </Card>
    )
}
