import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const SCxContainer = styled.div`
    width: 100%;
    overflow: hidden;
`

export const ValueContainer = styled.div`
    width: 100%;
    height: 32px;
    padding: 0 4px 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    background-color: var(--color-white);
    cursor: pointer;

    &.active {
        border-color: var(--color-main);
    }

    &:focus {
        border-color: var(--color-main);
    }

    &:focus-within {
        border-color: var(--color-main);
    }
`

export const Icon = styled(IconFont)`
    color: var(--color-gray-400);
`

export const PlaceHolder = styled.div`
    color: rgb(173, 181, 189);
    font-size: 14px;
`

export const ValueContent = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const ValueTag = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

export const ValueTagText = styled.div`
    color: var(--color-gray-900);
    font-size: 14px;
    white-space: nowrap;
`

export const ErrorTag = styled.div`
    color: var(--color-red-500);
`

export const List = styled.div`
    padding: 8px 0;
    width: 100%;
`

export const ListItem = styled.div<{ active?: boolean }>`
    height: 36px;
    cursor: pointer;
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    background-color: ${({ active }) => (active ? 'var(--color-gray-100)' : 'none')};

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ListItemContent = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    overflow: hidden;
`

export const ListItemText = styled.div`
    font-size: 14px;
    color: var(--color-gray-900);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
`

export const ListItemExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    width: fit-content;
`
