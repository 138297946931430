import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const InviteWrapper = styled.div`
    width: 100%;
    min-height: 372px;
    text-align: center;
`

export const InviteTitle = styled.div`
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    color: var(--color-black);
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
`

export const InviteImg = styled.img`
    width: 176px;
`

export const InviteContent = styled.div`
    padding-top: 24px;
`

export const InviteIcon = styled(IconFont)`
    font-size: 15px;
`

export const InvitePhone = styled.div``
