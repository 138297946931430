import { tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);

`
export const BlockContainer = styled.div``

export const BlockTitle = styled.div``

export const Text = styled.div`
    color: var(--color-black);
`

export const LineText = styled.div`
    color: var(--color-gray-400);
`
export const LeftFill = styled.div``

export const RightFill = styled.div``

export const ShowNavbarContain = styled.div``
