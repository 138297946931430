export type Brand = 'BYE_CODE' | 'I_CLICK'

export enum LoginType {
    MOBILE_AND_CODE = 1,
    EMAIL_AND_CODE = 2,
    WECHAT_OFFICIAL = 3,
    PC_WECHAT = 4,
    GOOGLE = 5,
    ACCOUNT_PASSWORD = 6
}

export type SMSLoginProtocol = {
    authType: LoginType.MOBILE_AND_CODE
    mobile: string
    smsCode: string
    mark?: string
    invitationCode?: string
    sourceParty?: Brand
}

export type PasswordLoginProtocol = {
    authType: LoginType.ACCOUNT_PASSWORD
    mobile: string
    password: string
    sourceParty?: Brand
}

export type WechatLoginProtocol = {
    authType: LoginType.WECHAT_OFFICIAL
    mobile?: string
    smsCode?: string
    mark: string
    invitationCode?: string
    sourceParty?: Brand
}

export type LoginProtocol = SMSLoginProtocol | PasswordLoginProtocol | WechatLoginProtocol

export type SmsCodeProtocol = {
    mobile: string
}
