import styled from 'styled-components'

export const Container = styled.form`
    width: 100%;
    height: 100%;
    font-size: var(--font-size-normal);
    /* border-left: 1px solid var(--color-gray-200); */
`
export const BlockContainer = styled.div``

export const BlockTitle = styled.div``

export const Text = styled.div`
    color: var(--color-black);
`

export const LineText = styled.div`
    color: var(--color-gray-400);
`
export const LeftFill = styled.div``

export const RightFill = styled.div``

export const ShowNavbarContain = styled.div``

export const Label = styled.div`
    width: 100%;
    font-style: normal;
    font-size: 14px;
`
export const Row = styled.div`
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
    align-items: center;
    margin-top: 12px;
`

export const Card = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    display: flex;
    flex-direction: column;
    gap: 12px;
`
export const CardText = styled.div<{ active?: boolean; color?: string }>`
    font-size: 12px;
    text-align: center;
    color: ${({ active }) => (active ? 'var(--color-main)' : 'var(--color-gray-400)')};
`
