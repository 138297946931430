import type { Option } from '@byecode/ui'
import { APPLICATION_ENV_PROD, ENV_ICON, ErrorMessage } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'

import { useApplicationList } from '@/hooks/useApplication'
import { useEnvList } from '@/shared/reusable'

import * as SC from './styles'

export interface LinkApplicationProps {
    appId: string
    onLinkApplication?: (appId: string, envId: string) => void
    onClose?: () => void
}

export const LinkApplication: React.FC<LinkApplicationProps> = ({ appId, onLinkApplication, onClose }) => {
    const applicationList = useApplicationList()

    const {
        control,
        formState: { errors },
        watch,
        handleSubmit
    } = useForm<{ appId: string; envId: string }>({
        mode: 'onSubmit',
        defaultValues: {
            appId: '',
            envId: ''
        }
    })

    const linkAppId = watch('appId')
    const linkEnvId = watch('envId')
    const { data: linkEnvList } = useEnvList(linkAppId)
    const options: Option[] = useMemo(() => {
        return applicationList.reduce<Option[]>((prev, cur) => {
            if (appId === cur.id) {
                return prev
            }
            prev.push({
                label: cur.name,
                value: cur.id
            })
            return prev
        }, [])
    }, [appId, applicationList])

    const envOptions = useMemo(() => {
        if (!linkEnvList) {
            return []
        }
        return linkEnvList.map(item => ({
            label: item.envName,
            value: item.envId,
            icon: ENV_ICON
        }))
    }, [linkEnvList])

    const handleLinkApplication: SubmitHandler<{ appId: string; envId: string }> = useCallback(
        values => {
            onLinkApplication?.(values.appId, values.envId)
            onClose?.()
        },
        [onClose, onLinkApplication]
    )

    return (
        <form onSubmit={handleSubmit(handleLinkApplication)}>
            <SC.Header>
                <SC.HeaderTitle />
                <SC.HeaderClose>
                    <SC.Close fill="#667085" size={20} type="Close" onClick={onClose} />
                </SC.HeaderClose>
            </SC.Header>
            <SC.Content>
                <SC.IconGroup>
                    <SC.Icon size={30} fill="var(--color-theme-6)" type="UserTable" />
                    <SC.ArrowIcon size={16} fill="var(--color-theme-6)" type="TransferData" />
                    <SC.Icon size={30} fill="var(--color-theme-6)" type="UserTable" />
                </SC.IconGroup>
                <SC.Title>使用其他应用的用户</SC.Title>
                <SC.FormItem>
                    <SC.Label>选择应用</SC.Label>
                    <ErrorMessage name="appId" errors={errors}>
                        <Controller
                            name="appId"
                            rules={{
                                required: '请选择应用'
                            }}
                            control={control}
                            render={({ field }) => <SC.ApplicationSelect options={options} {...field} />}
                        />
                    </ErrorMessage>
                </SC.FormItem>
                {linkAppId && (
                    <SC.FormItem>
                        <SC.Label>选择环境</SC.Label>
                        <ErrorMessage name="envId" errors={errors}>
                            <Controller
                                name="envId"
                                rules={{
                                    required: '请选择环境'
                                }}
                                control={control}
                                render={({ field }) => <SC.ApplicationSelect options={envOptions} {...field} />}
                            />
                        </ErrorMessage>
                    </SC.FormItem>
                )}
            </SC.Content>
            <SC.Footer>
                <SC.ConfirmBtn size="lg" type="primary" htmlType="submit" disabled={!linkAppId || !linkEnvId}>
                    确认
                </SC.ConfirmBtn>
            </SC.Footer>
        </form>
    )
}
