import { Group, Text } from '@byecode/ui'
import type {SubFormBlockConfig } from '@lighthouse/core';
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import {
    datasourceFieldTypes,
    FieldInputNameMap,
    fieldSettingMap,
    formSettingMap,
    getRealField,
    ListItemPaddingByecodeUi,
    relativeSelectShowFieldList,
    SelectDataSource
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList, useDsListAndFieldOptions } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { FieldSelect } from '../../../../FieldSelect'
import { READONLY_FIELDS } from '../constants'
import { getSaveFieldOptions } from '../helps'
import type { DataMode, FieldOptions } from '../types'

interface RelativeSelectConfigProps {
    mode: DataMode
    fieldOptions?: FieldOptions
    prefix: '' | `columns.${number}.config.`
}

const RelativeSelectConfig: React.FunctionComponent<RelativeSelectConfigProps> = ({ mode, fieldOptions, prefix }) => {
    const { watch, setValue, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const disabledWithVersion = useIsDisabledWithVersion()
    const [inputType, relativePointer = ''] = watch([`${prefix}inputType`, `${prefix}relativeSelect.relativePointer`])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const { dataSourceOptions: relativeDataSourceOptions, fieldOptions: relativeFieldOptions } = useDsListAndFieldOptions(
        appId,
        envId,
        relativePointer
    )

    const relativeDataSource = useDataSource(appId, envId, relativePointer)

    const [saveFieldOptions, disableSaveFiledList] = useMemo(
        () => (inputType ? getSaveFieldOptions(inputType, fieldOptions ?? [], mode) : [[], []]),
        [mode, fieldOptions, inputType]
    )

    const [usedRelativeInputOptions, disableUsedRelativeInputFiledList] = useMemo(
        () => [
            relativeFieldOptions.filter(item => {
                const settingMap = mode === 'field' ? fieldSettingMap : formSettingMap
                return settingMap['text'].includes(item.type) || READONLY_FIELDS.includes(item.type) || item.type === 'number'
            }),
            disableSaveFiledList.filter(item => !READONLY_FIELDS.includes(item))
        ],
        [relativeFieldOptions, disableSaveFiledList, mode]
    )

    const [showRelativeInputOptions, disableShowRelativeInputFiledList] = useMemo(
        () => [
            relativeFieldOptions.filter(item => {
                if (!relativeDataSource?.schema?.[item.value]) {
                    return false
                }
                const realType = getRealField(relativeDataSource?.schema?.[item.value])?.type
                if (!realType) {
                    return false
                }
                // const realType = relativeDataSource?.schema?.[item.value]?.type
                return relativeSelectShowFieldList.includes(realType)
            }),
            datasourceFieldTypes.filter(item => !relativeSelectShowFieldList.includes(item))
        ],
        [relativeDataSource?.schema, relativeFieldOptions]
    )

    return (
        <Group label="选项内容">
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                <Text>选项来源</Text>
                <Controller
                    name={`${prefix}relativeSelect.relativePointer`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <SelectDataSource
                            placeholder="请选择数据表"
                            size="md"
                            value={value}
                            disabled={disabledWithVersion}
                            onChange={onChange}
                            dataSourceList={dataSourceList}
                        />
                    )}
                />
            </ListItemPaddingByecodeUi>
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                <Text>选项值</Text>
                <Controller
                    name={`${prefix}relativeSelect.relativeFieldPointer`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FieldSelect
                            dsId={relativePointer}
                            disabled={usedRelativeInputOptions.length === 0 || disabledWithVersion}
                            options={usedRelativeInputOptions}
                            value={value}
                            onChange={onChange}
                            disableTitle={`${FieldInputNameMap[inputType]}输入框不支持以下字段：`}
                            disableList={disableUsedRelativeInputFiledList}
                        />
                    )}
                />
            </ListItemPaddingByecodeUi>
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                <Text>显示为</Text>
                <Controller
                    name={`${prefix}relativeSelect.relativeShowFieldPointer`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <FieldSelect
                            dsId={relativePointer}
                            disabled={showRelativeInputOptions.length === 0 || disabledWithVersion}
                            options={showRelativeInputOptions}
                            value={value}
                            onChange={onChange}
                            disableTitle="不支持以下字段："
                            disableList={disableShowRelativeInputFiledList}
                            disableDesc="只支持选择附件字段"
                        />
                    )}
                />
            </ListItemPaddingByecodeUi>
        </Group>
    )
}

export default RelativeSelectConfig
