import { Button, Input } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`

export const InputBox = styled.div`
    flex: 1;
`

export const SpaceInfo = styled.div`
    width: 100%;
    flex: 1;
`

export const SpaceForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 18px;
`

export const HideSubmit = styled.input`
    display: none;
`

export const FormGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
`

export const FormLabel = styled.div`
    width: 123px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    line-height: 36px;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FormContent = styled.div`
    flex: 1;
    max-width: 70%;
    min-width: 120px;
    height: 36px;
    line-height: 36px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
    font-weight: 400;
`
export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const OkBtn = styled(Button)``

export const FormInput = styled(Input)`
    flex: 1;
    max-width: 70%;
    min-width: 120px;

    /* input {
        height: 36px;
        line-height: 36px;
    } */
`
