import styled, { css } from 'styled-components'

export const Container = styled.div`
    width: 100%;
`

export const Content = styled.div`
    padding: 0 16px 10px 16px;
`

export const FormItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    margin-bottom: 8px;
`

export const FormItemLabelWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FormItemLabel = styled.span<{ required?: boolean }>`
    position: relative;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                position: absolute;
                top: 0;
                right: -12px;
                color: var(--color-red-500);
            }
        `}
`

export const FormItemContent = styled.div`
    width: 100%;
`
