import React from 'react'
import { type HandleProps, Handle } from 'reactflow'
import styled from 'styled-components'

export interface HandlerProps extends HandleProps {
    state?: 'active' | 'inactive'
}

const StyledHandle = styled(Handle)`
    background-color: var(--color-gray-300);
    border: none;
    width: 8px;
    height: 8px;
    opacity: 0;

    &[data-state='active'] {
        background-color: var(--color-green-500);
    }

    &[data-visible='true'] {
        opacity: 1;
    }
`

export const Handler: React.FC<HandlerProps> = props => {
    const { state, type, ...restProps } = props
    return <StyledHandle data-state={state} data-visible={type === 'source'} type={type} {...restProps} />
}
