import { ErrorFallback, FallbackBlock } from '@lighthouse/block'
import type { BlockAbstract } from '@lighthouse/core'
import { type FlowLayoutNode } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import AppNavBlockController from './AppNavBlockController'
import BreadcrumbBlockController from './BreadcrumbBlockController'
import ButtonBlockController from './ButtonBlockController'
import CardBlockController from './CardBlockController'
import ChartBlockController from './ChartBlockController'
import CollapseBlockController from './CollapseBlockController'
import ContainerController from './ContainerController'
import DividerBlockController from './DividerBlockController'
import FieldBlockController from './FieldBlockController'
import FieldGroupBlockController from './FieldGroupBlockController'
import FileBlockController from './FileBlockController'
import FilterBlockController from './FilterBlockController'
import FormContainerController from './FormContainerController'
import IframeBlockController from './IframeBlockController'
import ImageBlockController from './ImageBlockController'
import QrBarcodeBlockController from './QrBarcodeBlockController'
import SubFormBlockController from './SubFormBlockController'
import TabsController from './TabsController'
import TextBlockController from './TextBlockController'
import VideoBlockController from './VideoBlockController'
import ViewBlockController from './ViewBlockController'

interface BlockRenderControllerProps {
    readonly?: boolean
    blockData: BlockAbstract
    node: FlowLayoutNode
    onBlockChange?: (values: BlockAbstract, origin: BlockAbstract) => Promise<void> | void
    children?: React.ReactNode
}

const BlockRenderController = (props: BlockRenderControllerProps) => {
    const {
        blockData: { type },
        children
    } = props

    const BlockComp = useMemo(() => {
        const blocks = {
            appNav: AppNavBlockController,
            image: ImageBlockController,
            chart: ChartBlockController,
            field: FieldBlockController,
            buttonGroup: ButtonBlockController,
            view: ViewBlockController,
            card: CardBlockController,
            divider: DividerBlockController,
            video: VideoBlockController,
            fieldGroup: FieldGroupBlockController,
            breadcrumb: BreadcrumbBlockController,
            iframe: IframeBlockController,
            collapse: CollapseBlockController,
            file: FileBlockController,
            container: ContainerController,
            formContainer: FormContainerController,
            tabs: TabsController,
            text: TextBlockController,
            filter: FilterBlockController,
            qrBarcode: QrBarcodeBlockController,
            subForm: SubFormBlockController
        }
        return (
            (blocks[type] as React.FC<{
                blockData: BlockAbstract
                onBlockChange?: (values: BlockAbstract, origin: BlockAbstract) => Promise<void> | void
            }>) || FallbackBlock
        )
    }, [type])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BlockComp {...props}>{children}</BlockComp>
        </ErrorBoundary>
    )
}

export default BlockRenderController
