import { Button, Input, Popover, SelectDropdown } from '@byecode/ui'
import { Text } from '@mantine/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import type { AppAccountType } from '@/services/types'
import { useOtherPlatformList } from '@/shared/reusable'

import { OTHER_PLATFORMS_OPTIONS, WECHAT_TYPES } from '../constant'
import * as CM from '../styles'

interface OtherPlatformsProps {
    searchWord: string
    onChange: (value: string) => void
    onAddCount: (type: string) => void
}

const SCxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
`
const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`
const SCxRightFill = styled.div``
export const OtherPlatformsHeader: React.FunctionComponent<OtherPlatformsProps> = ({ searchWord, onChange, onAddCount }) => {
    const { data } = useOtherPlatformList()

    const currentOptions = useMemo(() => {
        return OTHER_PLATFORMS_OPTIONS.map(item => {
            if (WECHAT_TYPES.includes(item.value)) {
                const isExit = (data ?? []).some(v => v.type === item.value)
                return {
                    ...item,
                    extra: <Text size={12} color="var(--color-gray-400)" style={{ flexShrink: 0 }}>{`${Number(isExit)}/1`}</Text>,
                    disable: isExit,

                    styles: {
                        body: {
                            color: isExit ? 'var(--color-gray-400)' : undefined
                        }
                    }
                }
            }
            return item
        }).map(v => ({ ...v, disableTooltip: true }))
    }, [data])

    return (
        <SCxContainer>
            <SCxLeftFill>
                <Text size={20} color="var(--color-black)">
                    集成管理
                </Text>
                <Input
                    styles={{
                        wrapper: {
                            width: 240,
                            background: 'var(--color-white)'
                        }
                    }}
                    size="lg"
                    prefix={<CM.Icon type="SearchLine" color="var(--color-gray-400)" />}
                    value={searchWord}
                    onChange={e => onChange(e.currentTarget.value)}
                    placeholder="搜索应用账户、应用类型"
                />
            </SCxLeftFill>
            <SCxRightFill>
                <Popover position="bottom-end" width={240}>
                    <Popover.Target>
                        <Button size="lg" icon={<CM.Icon type="Add" color="var(--color-black)" />}>
                            <Text color="var(--color-black)">添加应用</Text>
                        </Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <SelectDropdown searchable options={currentOptions} onSelect={onAddCount} />
                    </Popover.Dropdown>
                </Popover>
            </SCxRightFill>
        </SCxContainer>
    )
}
