import type { DataSourceAbstract, ViewType } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { TabEnum } from '../constants'
import { CalendarStyleSettings } from './CalendarStyleSettings'
import GalleryBlockSettings from './GalleryBlockSettings'
import { TableBlockStyleSettings } from './TableBlockStyleSettings'

export interface DesignProps {
    currentTab: string
    viewType: ViewType
    dataSource: DataSourceAbstract
}

export const Design: React.FC<DesignProps> = ({ currentTab, viewType, dataSource }) => {
    const content = useMemo(() => {
        switch (viewType) {
            case 'gallery': {
                return <GalleryBlockSettings />
            }

            case 'calendar': {
                return <CalendarStyleSettings dataSource={dataSource} />
            }
            default: {
                return <TableBlockStyleSettings viewType={viewType} dataSource={dataSource} />
            }
        }
    }, [dataSource, viewType])

    return (
        <div key={TabEnum.style} hidden={currentTab !== TabEnum.style}>
            {content}
        </div>
    )
}
