import { useAtomAction } from '@lighthouse/shared'
import React, { useEffect } from 'react'

import { setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { UserGroupPanel } from '@/components/UserSetting'

export const UserGroup: React.FC = () => {
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    useEffect(() => {
        setCurrentDataSourceId({ dsId: 'userGroup' })
    }, [setCurrentDataSourceId])

    return <UserGroupPanel />
}
