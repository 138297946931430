import { Button, IconFont } from '@byecode/ui'
import styled from 'styled-components'

import { FieldSelect } from '../../../FieldSelect'

export const Container = styled.div`
    /* padding: 8px 0; */
`

export const WhiteBgSelect: typeof FieldSelect = styled(FieldSelect)``

export const AddIcon = styled(IconFont)`
    color: var(--color-white);
    margin-right: 4px;
`

export const SettingItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 8px;

    /* :hover{
        background-color: var(--color-gray-100);
    } */
`

export const SettingItemContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`

export const Dragger = styled(IconFont)`
    margin-right: 6px;
    font-size: 16px;
    color: var(--color-gray-500);
    cursor: grab;
`

export const Setting = styled.div`
    width: 32px;
    height: 32px;
    margin-left: 6px;
    border-radius: 6px;
    background: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const List = styled.div``

export const ListItem = styled.div`
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ListItemText = styled.div`
    color: var(--color-gray-900);
    font-size: 14px;
    margin-left: 8px;
`

export const AddButton = styled(Button)`
    color: var(--color-gray-900);
    margin: 4px 0;
`

