import type { AggregatorNode } from '@lighthouse/shared'
import { AggregatorNodeType } from '@lighthouse/shared'
import React, { useMemo, useRef } from 'react'
import type { Edge, Viewport } from 'reactflow'
import ReactFlow, { Background, useEdgesState, useNodesState, useOnSelectionChange, useReactFlow } from 'reactflow'
import styled, { createGlobalStyle } from 'styled-components'

import { usePannable } from '@/components/AIAssistant/GraphCanvas/hooks'
import { DataAggregatorProvider } from '@/contexts/DataAggregatorContext'

import { createNode, getEdges, getNodes, removeNode } from '../utils'
import { Controls } from './AggregatorControls'
import { CommonEdge } from './CommonEdge'
import { Marker } from './Marker'
import { AddPlaceholderNode } from './Nodes/AddPlaceholderNode'
import { DataInputNode } from './Nodes/DataInputNode'
import { FieldSettingNode } from './Nodes/FieldSettingNode'
import { FilterNode } from './Nodes/FilterNode'
import { GroupStatisticsNode } from './Nodes/GroupStatisticsNode'
import { JoinNode } from './Nodes/JoinNode'

interface AggregatorEditorProps {
    nodes?: AggregatorNode[]
    edges?: Edge[]
    selectedNode: AggregatorNode | null
    onNodeSelected?: (node: AggregatorNode | null) => void
    onSelectChange?: (selected: boolean) => void
    onUpdate?: (nodes: AggregatorNode[], edges: Edge[]) => void
}

const SCxAggregatorEditorWrapper = styled.div`
    width: 100%;
`

const nodeTypes = {
    [AggregatorNodeType.DATA_INPUT]: DataInputNode,
    [AggregatorNodeType.JOIN]: JoinNode,
    [AggregatorNodeType.FIELD_SETTING]: FieldSettingNode,
    [AggregatorNodeType.FILTER]: FilterNode,
    [AggregatorNodeType.GROUP_STATISTICS]: GroupStatisticsNode,
    [AggregatorNodeType.ADD_PLACEHOLDER]: AddPlaceholderNode
}

const edgeTypes = {
    COMMON: CommonEdge
}

const DEFAULT_VIEWPORT: Viewport = {
    zoom: 1,
    x: 20,
    y: 20
}

const FlowEditorGlobalStyle = createGlobalStyle`
    .react-flow {
        .react-flow__pane {
            cursor: default;
        }
        .react-flow__node {
            cursor: pointer;
        }
        &.react-flow__pane_pan_start {
            .react-flow__pane {
                cursor: grab !important;
            }
        }
        .react-flow__pane.dragging {
            cursor: grabbing;
        }
    }
`

export const AggregatorEditor: React.FC<AggregatorEditorProps> = ({
    nodes: aggregatorNode,
    edges: aggregatorEdges,
    selectedNode,
    onNodeSelected,
    onSelectChange,
    onUpdate
}) => {
    const aggregatorRef = useRef<HTMLDivElement>(null)
    const { setCenter } = useReactFlow()
    const initialNodes = useMemo(() => getNodes(aggregatorNode ?? [], aggregatorEdges ?? []), [aggregatorEdges, aggregatorNode])
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
    const [edges, setEdges, onEdgesChange] = useEdgesState(getEdges(initialNodes as AggregatorNode[], aggregatorEdges ?? []))
    usePannable(aggregatorRef)
    /**
     * 选中节点时，触发 onNodeSelected
     */
    useOnSelectionChange({
        onChange: selected => {
            const { nodes } = selected
            if (nodes.length === 0) {
                onNodeSelected?.(null)
            }
            const singleNode = nodes[0] as AggregatorNode
            if (singleNode && singleNode.type !== AggregatorNodeType.ADD_PLACEHOLDER) {
                onNodeSelected?.(singleNode)
            } else {
                onNodeSelected?.(null)
            }
        }
    })

    const handleNodeAdd = (type: AggregatorNodeType) => {
        const { nodes: newNodes, edges: newEdges, addedNode } = createNode(type, nodes as AggregatorNode[], edges)
        addedNode && onNodeSelected?.(addedNode)
        setNodes(newNodes)
        setEdges(newEdges)

        onUpdate?.(newNodes, newEdges)
    }

    const handleNodeRemove = (nodeId: string) => {
        const { nodes: newNodes, edges: newEdges } = removeNode(nodeId, nodes as AggregatorNode[], edges)
        // onNodeSelected?.(null)

        if (selectedNode) {
            onNodeSelected?.(null)
        }
        // const node = find(item => item.id === selectedNode.id,nodes as AggregatorNode[])
        // if(node){
        //     const removeNodeIds = getAllNextNodeIds(node, nodes as AggregatorNode[], edges)
        //     if(removeNodeIds.has(selectedNode.id)){
        //         onNodeSelected?.(null)
        //     }
        // }

        setNodes(newNodes)
        setEdges(newEdges)

        onUpdate?.(newNodes, newEdges)
    }

    const wrapperStyle = { height: 'calc(100% - 55px)' }

    return (
        <DataAggregatorProvider onAddNode={handleNodeAdd} onRemoveNode={handleNodeRemove}>
            <SCxAggregatorEditorWrapper style={wrapperStyle}>
                <FlowEditorGlobalStyle />
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    ref={aggregatorRef}
                    panOnDrag={false}
                    deleteKeyCode={null}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    zoomOnScroll={false}
                    zoomOnDoubleClick={false}
                    defaultViewport={DEFAULT_VIEWPORT}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onClickCapture={() => onSelectChange?.(false)}
                    onNodeClick={(ev, node) => {
                        ev.stopPropagation()
                        // // 过滤掉点击创建节点的情况
                        if (node.type === AggregatorNodeType.ADD_PLACEHOLDER) {
                            onNodeSelected?.(null)
                        } else {
                            onNodeSelected?.(node as AggregatorNode)
                        }
                    }}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    proOptions={{ hideAttribution: true }}
                >
                    <Controls />
                    <Marker />
                    <Background />
                </ReactFlow>
            </SCxAggregatorEditorWrapper>
        </DataAggregatorProvider>
    )
}
