import { getBrandInfo } from '@lighthouse/shared'
import type { ModalProps } from '@mantine/core'
import { Image, Modal, Text } from '@mantine/core'
import * as React from 'react'
import styled from 'styled-components'

import { modalStyles } from './constants'

interface ContactSalesModalProps extends ModalProps {}

export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 60px 0;
    border-radius: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`

export const SCxTitle = styled.div`
    padding: 0 56px;
    color: var(--color-black);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
`

export const SCxDescribe = styled.div`
    display: flex;
    padding: 0 56px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--color-gray-500);
    padding-bottom: 16px;
`

export const ContactSalesModal: React.FunctionComponent<ContactSalesModalProps> = props => {
    return (
        <Modal centered padding={0} styles={modalStyles} zIndex={201} withinPortal radius={20} size={1024} {...props}>
            <SCxContainer>
                <SCxTitle>联系销售购买</SCxTitle>
                <SCxDescribe>
                    添加销售人员微信二维码，请备注
                    <Text weight={600} color="var(--color-theme-7)">
                        {' '}
                        “购买咨询”
                    </Text>
                    ，人工服务时段（周一至周日 9:00~20:00）
                </SCxDescribe>
                <Image width={267} src={getBrandInfo().space.saleImage} />
            </SCxContainer>
        </Modal>
    )
}
