import { Button, IconFont } from '@byecode/ui'
import type { SpaceUser } from '@lighthouse/core'
import { UserAvatar } from '@lighthouse/shared'
import React from 'react'
import styled, { css } from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    text-align: center;
`

const SCxTitle = styled.div`
    width: 100%;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
`

const SCxText = styled.div<{ bold?: boolean }>`
    width: 100%;
    text-align: center;
    font-weight: ${({ bold }) => (bold ? 600 : 400)};
    font-size: 14px;
    color: var(--color-gray-500);
    line-height: 20px;
`

const SCxUserName = styled.div`
    margin-left: 8px;
    font-size: 14px;
    color: var(--color-gray-500);
`

const SCxList = styled.div`
    width: 100%;
    margin-top: 16px;
`

const SCxOption = styled.div`
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxUser = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const SCxOkButton = styled(Button)<{ styleType: 'error' | 'primary' | 'warning' }>`
    width: 100%;
    margin-top: 24px;
    background-color: #fff2f0;
    color: #d88987;
    border-color: #d88987;
    height: 36px;
    border-radius: 8px;

    ${({ styleType }) => {
        switch (styleType) {
            case 'error': {
                return css`
                    background-color: #fff2f0;
                    color: #d88987;
                    border-color: #d88987;

                    &:hover {
                        background-color: #fadedc;
                    }
                `
            }
            case 'warning': {
                return css`
                    background-color: #4a78d4;
                    color: var(--color-white);
                    border-color: #4a78d4;

                    &:hover {
                        background-color: #649bfa;
                    }
                `
            }
            default: {
                return css`
                    background-color: #4a78d4;
                    color: #fff;
                    border-color: #4a78d4;

                    &:hover {
                        background-color: #649bfa;
                    }
                `
            }
        }
    }}
`

const CancelButton = styled(Button)<{ themeColor?: string }>`
    width: 100%;
    height: 36px;
    margin-top: 12px;
    border-radius: 8px;

    &:hover {
        color: ${({ themeColor }) => themeColor || 'var(--color-main)'};
        background: #f5f5f5;
    }
`

interface TransferProps {
    data: SpaceUser[]
    onConfirm?: (id: string) => void
    onClose?: () => void
}

export const Transfer: React.FC<TransferProps> = ({ data, onConfirm, onClose }) => {
    const [selectedId, setSelectedId] = React.useState(data[0].userId)
    return (
        <SCxContainer onClick={e => e.stopPropagation()}>
            <SCxTitle>确定离开空间？</SCxTitle>
            <SCxText>你是空间中的最后 1 位管理员，离开空间前需要将</SCxText>
            <SCxText>管理员权限转交给其他人，请选择1位成员：</SCxText>
            <SCxList>
                {data.map(item => (
                    <SCxOption key={item.userId} onClick={() => setSelectedId(item.userId)}>
                        <SCxUser>
                            <UserAvatar id={item.userId} avatar={item.avatar} name={item.username || ''} size="middle" />
                            <SCxUserName>{item.username}</SCxUserName>
                        </SCxUser>
                        {selectedId === item.userId && <IconFont type="Tick" />}
                    </SCxOption>
                ))}
            </SCxList>
            <SCxOkButton
                styleType="error"
                onClick={() => {
                    onConfirm?.(selectedId)
                }}
            >
                移交并离开空间
            </SCxOkButton>

            <CancelButton onClick={onClose}>取消</CancelButton>
        </SCxContainer>
    )
}
