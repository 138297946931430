import { pointer, Toast } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { getColorById, logoMaxUploadSize, UploadClickDropZone, UserAvatar } from '@lighthouse/shared'
import { useAbortAll, useItemFinishListener, useItemStartListener } from '@rpldy/uploady'
import * as React from 'react'
import styled from 'styled-components'

interface AvatarUploadProps {
    value: AppUser
    readonly?: boolean
    style?: React.CSSProperties

    onChange?: (url: string) => void
}

const ImageContain = styled.div`
    ${pointer}
    padding: 0px;
    display: flex;
    align-items: center;
`

const AvatarUpload: React.FC<AvatarUploadProps> = ({ value: user, style, readonly, onChange }) => {
    const abortAll = useAbortAll()
    useItemStartListener(item => {
        const currentSize = item.file.size
        if (currentSize > logoMaxUploadSize) {
            Toast.warning('当前图标超出2M, 请重新选择')
            abortAll()
        }
    })

    useItemFinishListener(item => {
        const { id, uploadResponse } = item
        if (uploadResponse.data.content) {
            onChange?.(uploadResponse.data.content.url)
        }
    })

    return (
        <UploadClickDropZone disabled={readonly}>
            <ImageContain style={style}>
                <UserAvatar
                    id={user.userId}
                    size={24}
                    background={getColorById(user.uniqueUserId)}
                    avatar={user.avatar}
                    borderColor=""
                    color="var(--color-white)"
                    displayHover
                    name={user.username || '未命名用户'}
                />
            </ImageContain>
        </UploadClickDropZone>
    )
}

export default AvatarUpload
