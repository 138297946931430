import { CardBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { CardBlockAbstract } from '@lighthouse/core'
import { type RichTextEditorProps, useApplicationContext } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import React, { memo, useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

interface CardBlockControllerProps {
    blockData: CardBlockAbstract
}

const CardBlockController: React.FC<CardBlockControllerProps> = ({ blockData }) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { personOptions } = useApplicationContext()
    const renderLabel = useVariableValueRender(parentRecordId, recordId)

    const { handleActionTrigger } = useActionTrigger()

    const { record } = useCustomViewBlockContext()
    const config: RichTextEditorProps['config'] = useMemo(
        () => ({ variable: { renderLabel: v => renderLabel(v.attrs.value, { personOptions, viewRecord: record }) } }),
        [personOptions, record, renderLabel]
    )

    return <CardBlock blockData={blockData} config={config} onClick={handleActionTrigger} />
}

export default memo(CardBlockController, equal)
