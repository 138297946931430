import { Empty, Flex, Switch, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationSettingAdvertising, ApplicationWebsiteSetting } from '@lighthouse/core'
import { ListItemPaddingByecodeUi, spaceVersionEnum, useAtomAction } from '@lighthouse/shared'
import { Image } from '@mantine/core'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { useApplicationSetting, useCurrentAppID } from '@/hooks/useApplication'
import { useSpaceQuota } from '@/shared/reusable'

import SettingBlock from '../SettingBlock'
import { SettingCard } from '../SettingCard'
import * as CM from '../styles'

interface SettingAdvertisingProps {}
const SCxContainer = styled.form`
    width: 100%;
`
const SettingAdvertising: React.FunctionComponent<SettingAdvertisingProps> = props => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { data: spaceGrade } = useSpaceQuota()

    const { run: updateAppSetting } = useAtomAction(updateAppAtom)
    const methods = useForm<ApplicationSettingAdvertising>({
        mode: 'onChange',
        defaultValues: appSetting?.advertising
    })
    const { watch, control, register } = methods

    const isHideLogo = watch('isHideLogo')
    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingAdvertising) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        advertising: value
                    }
                })
            }),
        [updateAppSetting]
    )

    const isShowGradeTag = useMemo(() => (spaceGrade?.currentVersionCode ?? 0) < spaceVersionEnum.BASIC, [spaceGrade?.currentVersionCode])

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingAdvertising)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <SCxContainer>
            <SettingCard title="广告位显示设置">
                <Flex gap={8} style={{ marginTop: 16 }}>
                    <Controller
                        control={control}
                        name="isHideLogo"
                        render={({ field }) => (
                            <Switch
                                checked={field.value ?? false}
                                onChange={e => {
                                    if (isShowGradeTag) {
                                        openSpaceGradeConfirm(
                                            'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pgxun5gglgckxerv?singleDoc#',
                                            'BASIC'
                                        )
                                        return
                                    }
                                    field.onChange(e.target.checked)
                                }}
                            />
                        )}
                    />

                    <Text size={14} lineHeight="22px" weight={600} color="var(--color-black)">
                        {isHideLogo ? '关闭' : '未关闭'}
                    </Text>
                    {isShowGradeTag && <SpaceGradeTag value={spaceVersionEnum.BASIC} />}
                </Flex>
                <Text size={14} lineHeight="22px" color="var(--color-gray-400)" style={{ marginTop: 12 }}>
                    关闭后应用页面右下角的广告位将隐藏更新发布后生效
                </Text>
                <Flex>
                    <Empty
                        styles={{
                            root: {
                                marginTop: 16
                            },
                            wrapper: {
                                flexDirection: 'row',
                                justifyContent: 'flex-start'
                            }
                        }}
                        icon={
                            <Image
                                width={174}
                                height={174}
                                radius={8}
                                src={
                                    isHideLogo
                                        ? getAssetUrl('common', 'advert_empty_close.svg')
                                        : getAssetUrl('common', 'advert_empty_open.svg')
                                }
                            />
                        }
                    />
                </Flex>
            </SettingCard>
        </SCxContainer>
    )
}

export default SettingAdvertising
