import { Divider } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

export const SCxTitle = styled.div`
    font-weight: 400;
    font-size: 20px;
    color: var(--color-gray-900);
`

export const SCxDivider = styled(Divider)`
    margin: 18px 0 32px 0;
`

interface HeaderProps {
    title: string
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <>
            <SCxTitle>{title}</SCxTitle>
            <SCxDivider color="var(--color-gray-200)" />
        </>
    )
}
