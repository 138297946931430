import { Divider, Toast } from '@byecode/ui'
import { ErrorMessage, passwordRegex, useAtomData, VerificationCodeBtn } from '@lighthouse/shared'
import { ResultAsync } from 'neverthrow'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { userDataAtom } from '@/atoms/auth/state'
import * as srv from '@/services'
import { encrypt } from '@/utils'

import * as SC from './styles'

interface UpdatePasswordForm {
    smsCode: string
    password: string
    confirmPassword: string
}

interface ResetPasswordProps {
    children?: React.ReactNode
    onClose: () => void
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({ onClose }) => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm<UpdatePasswordForm>({ reValidateMode: 'onChange', shouldFocusError: false })
    const userData = useAtomData(userDataAtom)
    const { mobile } = userData
    const submitEl = useRef<HTMLInputElement>(null)

    const compareToFirstPassword = useCallback(
        (value?: string) => {
            if (value && value !== getValues('password')) {
                return '密码不一致'
            }
            return undefined
        },
        [getValues]
    )
    /**
     * 获取验证码
     */
    const handleGetCodeHandle = useCallback(
        async (resolve: () => void, reject: () => void) => {
            const res = await ResultAsync.fromPromise(srv.sendAuthCode({ mobile }), e => e)
            if (res.isOk()) {
                resolve()
                return
            }
            reject()
        },
        [mobile]
    )

    // 表单提交
    const formSubmit = useCallback(
        async (data: UpdatePasswordForm) => {
            const { smsCode, password } = data
            const enctyptPassword = encrypt(password)

            const isUpdate = await srv.updatePassword({
                mobile,
                smsCode,
                password: enctyptPassword || ''
            })
            if (isUpdate) {
                Toast.success('修改成功')
                onClose()
            }
        },
        [mobile, onClose]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(formSubmit)()
        },
        [handleSubmit, formSubmit]
    )

    const phone = useMemo(() => {
        return `${mobile.slice(0, 3)}****${mobile.slice(-4)}`
    }, [mobile])

    return (
        <SC.ModalContainer>
            <SC.Describe>点击「获取验证」，我们将向手机 {phone} 发送验证码短信</SC.Describe>
            <SC.Form onSubmit={handleFormSubmit}>
                <SC.VerificationCodeInputBox>
                    <ErrorMessage style={{ flex: 1 }} name="smsCode" errors={errors}>
                        <SC.VerificationCodeInput
                            placeholder="验证码"
                            // bordered={false}
                            maxLength={6}
                            {...register('smsCode', { required: '请输入验证码' })}
                        />
                    </ErrorMessage>
                    <VerificationCodeBtn loading={false} style={{ marginLeft: 8 }} onGetCodeHandle={handleGetCodeHandle} />
                </SC.VerificationCodeInputBox>
                <Divider my={2} />
                <ErrorMessage name="password" errors={errors}>
                    <SC.PasswordInput
                        placeholder="输入新密码"
                        type="password"
                        {...register('password', {
                            required: '请输入新密码',
                            minLength: {
                                value: 8,
                                message: '密码最小长度为8' // JS only: <p>error message</p> TS only support string
                            },
                            maxLength: {
                                value: 20,
                                message: '密码最大长度为20' // JS only: <p>error message</p> TS only support string
                            },
                            pattern: {
                                value: passwordRegex,
                                message: '请输入8-20位,且是字母、数字/符号的组合' // JS only: <p>error message</p> TS only support string
                            }
                        })}
                    />
                </ErrorMessage>
                <ErrorMessage name="confirmPassword" errors={errors}>
                    <SC.PasswordInput
                        placeholder="再次输入新密码"
                        type="password"
                        {...register('confirmPassword', {
                            required: '请再次输入新密码',
                            validate: compareToFirstPassword
                        })}
                    />
                </ErrorMessage>
                <SC.HideSubmit ref={submitEl} type="submit" />
                <SC.Footer>
                    <SC.Ok block size="lg" htmlType="submit" loading={false} type="primary">
                        确认修改
                    </SC.Ok>
                </SC.Footer>
            </SC.Form>
        </SC.ModalContainer>
    )
}
