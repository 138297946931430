import { Text, UnstableTabPanel, UnstableTabs } from '@byecode/ui'
import { ActivityType } from '@lighthouse/core'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useImmer } from 'use-immer'

import { activityListAtom } from '@/atoms/workSpace/state'

import { AccountManager } from '../AccountManager'
import { OrderManger } from '../AccountManager/OrderManger'
import { UserForm } from '../UserForm'
import * as SC from './styles'
import type { AccountSettingProps, AccountSettingState } from './types'

export const AccountSetting: React.FC<AccountSettingProps> = ({ userInfo, onUpdateUser, onInviteUser, onClose }) => {
    const [state, setState] = useImmer<AccountSettingState>({
        tabIndex: 'userInfo'
    })

    const activityList = useAtomValue(activityListAtom)
    // const { data: activityList } = useSWR('getActivityList', () => run(), {
    //     revalidateOnFocus: false
    // })

    const inviteActivity = useMemo(() => find(act => act.type === ActivityType.INVITATION_USER, activityList || []), [activityList])

    const tabChange = useCallback(
        (key: string) => {
            if (key === 'invite') {
                onInviteUser?.()
                return
            }
            {
                setState(draft => {
                    draft.tabIndex = key
                })
            }
        },
        [onInviteUser, setState]
    )

    useHotkeys('Escape', (event, handler) => {
        event.preventDefault()
        if (handler.key === 'Escape') {
            onClose?.()
        }
    })

    return (
        <SC.Container>
            <UnstableTabs
                // title={username}
                onChange={tabChange}
                activeKey={state.tabIndex}
                tabPosition="left"
                styles={{
                    root: {
                        height: '100%'
                    },
                    tab: {
                        fontSize: 'var(--font-size-normal)'
                    },
                    tabs: {
                        height: '100%',
                        borderRight: '1px solid var(--color-gray-200)',
                        color: 'var(--color-gray-600)'
                    },
                    tabIcon: {
                        color: 'var(--color-gray-400)'
                    }
                }}
                activeStyles={{ color: 'var(--color-black)', background: 'var(--color-gray-100)', fontWeight: 400 }}
            >
                <UnstableTabPanel icon="User" tab="个人资料" key="userInfo">
                    <UserForm userInfo={userInfo} onUpdateUser={onUpdateUser} />
                </UnstableTabPanel>
                <UnstableTabPanel icon="Setting" tab="账户设置" key="security">
                    <AccountManager />
                </UnstableTabPanel>
                <UnstableTabPanel icon="PageBroadContent" tab="订单列表" key="order">
                    <OrderManger />
                </UnstableTabPanel>
                {inviteActivity?.isValid && (
                    <UnstableTabPanel
                        icon="AddUser"
                        tab="邀请注册"
                        key="invite"
                        extra={
                            <Text color="var(--color-main)" size={12} weight={400}>
                                邀请有礼
                            </Text>
                        }
                    />
                )}
            </UnstableTabs>
        </SC.Container>
    )
}
