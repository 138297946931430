import { Checkbox } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Checkbox size="xs" />
            <Checkbox size="sm" />
            <Checkbox radius="50%" />
            <Checkbox />
            <Checkbox size="lg" />
        </Unit>
    )
}
