import type { DataSourceAbstract } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import type { AggregateResultField } from '@lighthouse/shared'
import {
    type AggregatorDataInputNode,
    type AggregatorFieldSettingNode,
    type AggregatorFilterNode,
    type AggregatorGroupStatisticsNode,
    type AggregatorJoinNode,
    type AggregatorNode,
    AggregatorNodeType,
    getFieldIdInFilter
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import { find, isEmpty } from 'rambda'

type ValidatorsParams<T extends AggregatorNode = AggregatorNode> = {
    node: T
    prevFieldList?: AggregateResultField[]
    dataSourceList?: DataSourceAbstract[]
}

/**
 * validate data input node
 */
const dataInputValidator = {
    type: AggregatorNodeType.DATA_INPUT,
    validators: [
        // 用于校验是否已经指定数据源且是否有该数据源
        (params: ValidatorsParams<AggregatorDataInputNode>) => {
            const { node, dataSourceList = [] } = params
            return !!find(item => item.id === node.data.config.dsId, dataSourceList)
        },
        // 用于校验名称是否存在
        (params: ValidatorsParams<AggregatorDataInputNode>) => {
            const { node } = params
            return !!node.data.config.name
        },
        // 用户校验是否选择了字段
        (params: ValidatorsParams<AggregatorDataInputNode>) => {
            const { node, dataSourceList = [] } = params
            const dataSource = find(item => item.id === node.data.config.dsId, dataSourceList)
            if (!dataSource) {
                return false
            }
            const { schema } = dataSource
            const { fieldIds } = node.data.config
            return fieldIds && fieldIds.length > 0 && !fieldIds.some(id => !schema[id])
        }
    ]
}

const joinValidator = {
    type: AggregatorNodeType.JOIN,
    validators: [
        // 用于校验名称是否存在
        (params: ValidatorsParams<AggregatorJoinNode>) => {
            const { node } = params
            return !!node.data.config.name
        },
        // 用于校验连接方式是否存在
        (params: ValidatorsParams<AggregatorJoinNode>) => {
            const { node } = params
            return !!node.data.config.joinType
        },
        (params: ValidatorsParams<AggregatorJoinNode>) => {
            const { node, prevFieldList } = params
            const rules = node.data.config?.rules
            if (!rules || isEmpty(rules) || !prevFieldList || isEmpty(prevFieldList)) {
                return false
            }
            const prevFieldIds = new Set(prevFieldList.map(item => item.id))
            let isPass = true
            for (const rule of rules) {
                if (!rule.left || !rule.left.upstreamVariable?.fieldId) {
                    isPass = false
                    break
                }
                if (!prevFieldIds.has(rule.left.upstreamVariable.fieldId)) {
                    isPass = false
                    break
                }
                if (!rule.operator) {
                    isPass = false
                    break
                }
                if (rule.operator === 'isEmpty' || rule.operator === 'isNotEmpty') {
                    continue
                }
                if (!rule.right) {
                    isPass = false
                    break
                }
                if (rule.right.type === VariableType.VALUE && !rule.right.valueVariable?.value) {
                    isPass = false
                    break
                }
                if (
                    rule.right.type === VariableType.UPSTREAM &&
                    (!rule.right.upstreamVariable?.fieldId || !prevFieldIds.has(rule.right.upstreamVariable.fieldId))
                ) {
                    isPass = false
                    break
                }
            }
            return isPass
        }
    ]
}

const fieldSettingValidator = {
    type: AggregatorNodeType.FIELD_SETTING,
    validators: [
        // 用于校验名称是否存在
        (params: ValidatorsParams<AggregatorFieldSettingNode>) => {
            const { node } = params
            return !!node.data.config.name
        },
        // 上游是否有字段输出
        (params: ValidatorsParams<AggregatorFieldSettingNode>) => {
            const { prevFieldList } = params
            return prevFieldList && prevFieldList.length > 0
        }
    ]
}

const filterValidator = {
    type: AggregatorNodeType.FILTER,
    validators: [
        // 用于校验名称是否存在
        (params: ValidatorsParams<AggregatorFilterNode>) => {
            const { node } = params
            return !!node.data.config.name
        },
        // 用于是否存在筛选器
        (params: ValidatorsParams<AggregatorFilterNode>) => {
            const { node } = params
            return !!node.data.config.expression
        },
        // 用于筛选器中字段是否存在
        (params: ValidatorsParams<AggregatorFilterNode>) => {
            const { node, prevFieldList } = params
            if (!prevFieldList) {
                return true
            }
            const fieldIds = getFieldIdInFilter(node.data.config)
            if (isEmpty([...fieldIds])) {
                return true
            }
            const prevFieldIds = new Set(prevFieldList.map(item => item.id))
            let prev = true
            for (const fieldId of fieldIds) {
                if (!prevFieldIds.has(fieldId)) {
                    prev = false
                    break
                }
            }
            return prev
        }
    ]
}

const groupStatisticsValidator = {
    type: AggregatorNodeType.GROUP_STATISTICS,
    validators: [
        // 用于校验名称是否存在
        (params: ValidatorsParams<AggregatorGroupStatisticsNode>) => {
            const { node } = params
            return !!node.data.config.name
        },
        (params: ValidatorsParams<AggregatorGroupStatisticsNode>) => {
            const { node, prevFieldList } = params
            if (!prevFieldList || isEmpty(prevFieldList)) {
                return false
            }
            const groupByFields = node.data.config?.groupByFields
            const statisticsFields = node.data.config?.statisticsFields
            const isEmptyGroupByFields = !groupByFields || isEmpty(groupByFields)
            const isEmptyStatisticsFields = !statisticsFields || isEmpty(statisticsFields)
            if (isEmptyGroupByFields || isEmptyStatisticsFields) {
                return false
            }
            const prevFieldIds = new Set(prevFieldList.map(item => item.id))
            let isPass = true
            if (!isEmptyGroupByFields) {
                for (const groupByField of groupByFields) {
                    if (!prevFieldIds.has(groupByField.groupByFieldId)) {
                        isPass = false
                        return isPass
                    }
                }
            }
            if (!isEmptyStatisticsFields) {
                for (const statisticsField of statisticsFields) {
                    if (!prevFieldIds.has(statisticsField.statisticsFieldId)) {
                        isPass = false
                        return isPass
                    }
                }
            }
            return isPass
        }
    ]
}

/**
 * validate aggregator node config
 */
const configValidators = [dataInputValidator, joinValidator, fieldSettingValidator, filterValidator, groupStatisticsValidator]

export const validateAggregatorNodeConfig = (param: ValidatorsParams) => {
    const { node } = param
    const { type } = node

    const thisNodeValidator = configValidators.find(validator => validator.type === type)

    if (!thisNodeValidator) {
        return false
    }

    const { validators: nodeValidators } = thisNodeValidator

    for (const nodeValidator of nodeValidators) {
        // @ts-expect-error nodeValidator is a function
        if (!nodeValidator(param)) {
            return false
        }
    }

    return true
}

/**
 * validate aggregator data
 */
const dataValidators = []

export const validateAggregatorData = (data: AnyObject) => {
    return true
}
