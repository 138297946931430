import type { Option } from '@byecode/ui'
import { IconFont, Input, pointer, Select } from '@byecode/ui'
import type { AggregateResultField } from '@lighthouse/shared'
import { FieldTypeTag, getFieldIcon } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { statisticsCalcTypeOptions } from '../../constants'

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const SCxRemove = styled.div`
    ${pointer}
    padding: 2px;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

const SelectStyle = {
    flex: 1,
    flexShrink: 0,
    overflow: 'hidden'
}

export interface StatisticsFieldItemProps {
    prefixName: string
    fieldList: AggregateResultField[]
    onRemove?: () => void
}

export const StatisticsFieldItem: React.FC<StatisticsFieldItemProps> = ({ prefixName, fieldList, onRemove }) => {
    const { control } = useFormContext()

    const statisticsFieldId = useWatch({
        control,
        name: `${prefixName}.statisticsFieldId`
    })

    const statisticsField = useMemo(() => find(item => item.id === statisticsFieldId, fieldList), [statisticsFieldId, fieldList])

    const options = useMemo(() => {
        return fieldList.reduce<Option[]>((prev, cur) => {
            prev.push({
                label: cur.name,
                value: cur.id,
                icon: getFieldIcon(cur.id, cur.type, cur.innerType),
                extra: <FieldTypeTag type={cur.type} innerType={cur.innerType} />
            })
            return prev
        }, [])
    }, [fieldList])

    const calcTypeOptions = useMemo(() => {
        if (!statisticsField) {
            return []
        }
        return statisticsCalcTypeOptions.filter(item => item.innerTypeList.includes(statisticsField.innerType))
    }, [statisticsField])

    return (
        <SCxContainer>
            <Controller
                control={control}
                name={`${prefixName}.statisticsFieldId`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: SelectStyle
                        }}
                        searchable
                        value={field.value}
                        options={options}
                        onChange={field.onChange}
                    />
                )}
            />

            <Controller
                control={control}
                name={`${prefixName}.calcType`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: {
                                width: 64,
                                flexShrink: 0
                            }
                        }}
                        placeholder="统计方式"
                        disabled={!statisticsFieldId}
                        value={field.value}
                        options={calcTypeOptions}
                        onChange={val => {
                            field.onChange(val)
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name={`${prefixName}.name`}
                render={({ field }) => <Input placeholder="重命名" value={field.value} onChange={field.onChange} />}
            />

            <SCxRemove onClick={onRemove}>
                <IconFont type="Close" size={16} />
            </SCxRemove>
        </SCxContainer>
    )
}
