import { Button } from '@byecode/ui'
import { Divider } from '@mantine/core'
import styled from 'styled-components'

export const Container = styled.div``

export const List = styled.div``

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
`

export const ListItemWrapper = styled.div`
    display: flex;
    flex-flow: nowrap row;
    flex: 1;
`

export const ListItemIcon = styled.div`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--color-gray-200);
    margin-right: 12px;
`

export const ListItemContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const ListItemTitle = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

export const ListitemDescribe = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
`

export const ListItemExtra = styled.div``

export const BindButton = styled(Button)`
    width: 86px;
`
