import { Input, TextArea } from '@byecode/ui'
import { Text } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

interface NameProps {
    value?: string
    label: string
    onChange?: (value: string) => void
}

const SCxContainer = styled.div`
    margin-top: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Name = React.forwardRef<HTMLDivElement, NameProps>(({ value = '注册', label, onChange }, ref) => {
    return (
        <SCxContainer ref={ref}>
            <Text>{label}</Text>
            <Input
                styles={{
                    wrapper: {
                        width: 180
                    }
                }}
                value={value}
                onChange={e => onChange?.(e.target.value)}
            />
        </SCxContainer>
    )
})

export default React.memo(Name)
