import type { Option } from '@byecode/ui'
import { flex, IconFont, Select, singleTextEllipsis } from '@byecode/ui'
import type { Field, FilterBlockAbstract, SchemaProtocol } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import { getFieldIcon, ListItem4ByecodeUi, useAtomAction } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { blockHighlightAtom } from '@/atoms/page/state'

const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

const SCxControlContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    padding: 4px 8px;
    overflow: hidden;
    border: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-shrink: 0;
`
const SCxHeader = styled.div`
    ${flex}
    padding: 10px 14px;
    justify-content: space-between;
`

const SCxLeftFill = styled.div`
    /* width: 126px; */
    ${flex}
    align-items: center;
    overflow: hidden;
    flex: 1;
`

const SCxHandle = styled.div`
    /* padding: 4px; */
    ${flex}
    align-items: center;
`

const SCxContent = styled.div`
    margin: 0 8px;
`

export const SCxTitle = styled.span`
    padding-left: 4px;
    line-height: 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    font-size: var(--font-size-normal);
    flex: 1;
    ${singleTextEllipsis}
`

interface FilterControlItemProps {
    id: string
    fieldId: string
    schema: SchemaProtocol['schema']
    prefixName: string
    filterBlockOptions: Option[]
    filterBlocks?: FilterBlockAbstract[]
    disabled?: boolean
    onDelete: (id: string) => void
}

export const FilterControlItem: React.FC<FilterControlItemProps> = ({
    id,
    fieldId,
    schema,
    prefixName,
    filterBlockOptions,
    filterBlocks,
    disabled,
    onDelete
}) => {
    const { control } = useFormContext()
    const field: Field | undefined = schema[fieldId]
    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const paramList = useWatch({ control, name: `${prefixName}.paramList` })

    const filterBlockId = useMemo(() => {
        return paramList?.[0]?.filterVariable?.blockId
    }, [paramList])

    const itemOptions = useMemo(() => {
        const filterBlock = find(filterBlock => filterBlock.id === filterBlockId, filterBlocks || [])
        if (!filterBlock) {
            return []
        }
        return filterBlock.config.filterItems.map(item => ({
            label: item.title,
            value: item.id
        }))
    }, [filterBlockId, filterBlocks])

    return (
        <SCxControlContainer>
            <SCxHeader>
                <SCxLeftFill>
                    <IconFont color="var(--color-gray-400)" type={getFieldIcon(field?.id, field?.type, field?.innerType)} />
                    <SCxTitle> {field?.name}</SCxTitle>
                </SCxLeftFill>
                <SCxRightFill>
                    <IconFont size={16} color="var(--color-gray-500)" type="Trash" onClick={() => onDelete?.(id)} />
                </SCxRightFill>
            </SCxHeader>
            <Controller
                control={control}
                name={`${prefixName}.paramList`}
                render={({ field }) => {
                    const blockId = field.value?.[0]?.filterVariable?.blockId
                    const itemId = field.value?.[0]?.filterVariable?.itemId
                    return (
                        <SCxContent>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center" gap={8}>
                                <Select
                                    style={{ flex: 1 }}
                                    size="lg"
                                    disabled={disabled}
                                    value={blockId}
                                    onChange={val => {
                                        setBlockHighlight([])
                                        if (val !== blockId) {
                                            field.onChange([
                                                {
                                                    type: VariableType.FILTER,
                                                    filterVariable: {
                                                        blockId: val,
                                                        itemId: ''
                                                    }
                                                }
                                            ])
                                        }
                                    }}
                                    options={filterBlockOptions}
                                />
                                <Select
                                    style={{ flex: 1 }}
                                    size="lg"
                                    value={itemId}
                                    disabled={!filterBlockId || disabled}
                                    onChange={val => {
                                        if (val !== itemId) {
                                            field.onChange([
                                                {
                                                    type: VariableType.FILTER,
                                                    filterVariable: {
                                                        blockId,
                                                        itemId: val
                                                    }
                                                }
                                            ])
                                        }
                                    }}
                                    options={itemOptions}
                                />
                            </ListItem4ByecodeUi>
                        </SCxContent>
                    )
                }}
            />
        </SCxControlContainer>
    )
}
