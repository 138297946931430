import type { BlockAbstract, ContainerBlockAbstract, ImageBlockAbstract, TextBlockAbstract } from '@lighthouse/core'
import { type FlowLayoutNode, TEXT_FONT_NORMAL } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import { DEFAULT_CONTAINER_DESIGN } from '@/components/BlockSettings/constants'

import { blockPicture, blockText } from './constant'
import { getBlockInitConfig } from './help'
import { COL_CONTAINER, ROW_CONTAINER } from './shared'
import { COMMON_BLOCK_CONFIG } from './sharedStyle'

export function getDefaultCombinationBlocksOrNode(): [BlockAbstract[], FlowLayoutNode] | undefined {
    const containerBlock = getBlockInitConfig(COL_CONTAINER) as ContainerBlockAbstract
    if (!containerBlock) {
        return
    }
    const combinationContainer: ContainerBlockAbstract = {
        ...containerBlock,
        config: {
            ...COMMON_BLOCK_CONFIG,
            design: {
                direction: 'horizontal',
                alignY: 'center',
                gap: 0.5,
                padding: [1, 1, 1, 1],
                radius: [0, 0, 0, 0],
                size: 'md',
                veins: {}
            },
            viewList: [
                {
                    id: nanoid(),
                    name: '面板 01'
                }
            ]
        }
    }
    const textContainer: ContainerBlockAbstract = {
        ...containerBlock,
        id: nanoid(),
        config: {
            ...COMMON_BLOCK_CONFIG,
            design: {
                direction: 'vertical',
                alignX: 'center',
                gap: 0.5,
                padding: [0, 0, 0, 0],
                radius: [0, 0, 0, 0],
                size: 'md',
                veins: {}
            },
            viewList: [
                {
                    id: nanoid(),
                    name: '面板 01'
                }
            ]
        }
    }
    const image: ImageBlockAbstract = {
        ...(getBlockInitConfig(blockPicture) as ImageBlockAbstract),
        config: {
            ...COMMON_BLOCK_CONFIG,
            sourceType: 'default',
            isPreview: true,
            swipeSpeed: 2,
            variant: 'default',
            showTitle: true,
            align: 'center',
            fitType: 'cover',
            shape: 'square',
            radius: '16',
            ratio: '7',
            imageClickEvent: 'custom',
            sources: [
                {
                    link: '',
                    size: 441919,
                    title: '',
                    name: '',
                    type: 'image',
                    url: 'https://byecode-libs.oss-cn-shanghai.aliyuncs.com/libs/backend/touxiang.png'
                }
            ],
            action: {
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            }
        }
    }
    // const button = {
    //     ...getBlockInitConfig(blockButton),
    //     config: {
    //         size: 'md',
    //         shape: 'rectangle',
    //         radius: '8',
    //         align: 'left',
    //         list: buttonList
    //     }
    // } as ButtonBlockAbstract
    const stress: TextBlockAbstract = {
        ...getBlockInitConfig(blockText),
        config: {
            ...COMMON_BLOCK_CONFIG,
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    text: '强调文本',
                                    type: 'text',
                                    marks: [
                                        {
                                            type: 'bold'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            color: {
                color: '--color-app-main',
                gradient: {
                    angle: 90,
                    height: 50,
                    mode: 'linear',
                    stops: [
                        {
                            color: '#2D01FF',
                            percentage: 0
                        },
                        {
                            color: '#F00000',
                            percentage: 100
                        }
                    ],
                    width: 50
                },
                type: 'color'
            },
            font: {
                fontFamily: TEXT_FONT_NORMAL,
                fontSize: 1,
                letterSpacing: 0,
                lineHeight: 1
            }
        }
    } as TextBlockAbstract
    const subTitle = {
        ...getBlockInitConfig(blockText),
        config: {
            ...COMMON_BLOCK_CONFIG,
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'text',
                                    text: '副标题'
                                }
                            ]
                        }
                    ]
                }
            },
            color: {
                color: '#999999',
                gradient: {
                    angle: 90,
                    height: 50,
                    mode: 'linear',
                    stops: [
                        {
                            color: '#2D01FF',
                            percentage: 0
                        },
                        {
                            color: '#F00000',
                            percentage: 100
                        }
                    ],
                    width: 50
                },
                type: 'color'
            },
            font: {
                advance: '',
                fontFamily: TEXT_FONT_NORMAL,
                lineHeight: 1.5
            }
        }
    } as TextBlockAbstract
    const title = {
        ...getBlockInitConfig(blockText),
        config: {
            ...COMMON_BLOCK_CONFIG,
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    text: '标题',
                                    type: 'text',
                                    marks: [
                                        {
                                            type: 'bold'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            font: {
                advance: '',
                fontFamily: TEXT_FONT_NORMAL,
                fontSize: 2,
                letterSpacing: 0,
                lineHeight: 1
            }
        }
    } as TextBlockAbstract
    const combination = [title, subTitle, stress, textContainer, image, combinationContainer] as BlockAbstract[]
    if (combination.some(i => !i)) {
        return
    }
    const node: FlowLayoutNode = {
        type: 'container',
        id: combinationContainer.id,
        data: { ...DEFAULT_CONTAINER_DESIGN },
        width: 12,
        children: [
            {
                type: 'block',
                id: image.id,
                width: 2
            },
            {
                type: 'container',
                id: textContainer.id,
                data: { ...DEFAULT_CONTAINER_DESIGN },
                width: 10,
                children: [
                    {
                        type: 'block',
                        id: stress.id,
                        width: 12
                    },
                    {
                        type: 'block',
                        id: title.id,
                        width: 12
                    },
                    {
                        type: 'block',
                        id: subTitle.id,
                        width: 12
                    }
                ]
            }
            // {
            //     type: 'block',
            //     id: button.id,
            //     width: 2,
            //     minWidth: 2
            // }
        ]
    }
    return [combination, node]
}

export const getDefaultHighlightBlocksOrNode = function (): [BlockAbstract[], FlowLayoutNode] | undefined {
    const textBlock = {
        ...getBlockInitConfig(blockText),
        config: {
            ...COMMON_BLOCK_CONFIG,
            color: {
                color: '#0d19fc',
                gradient: {
                    angle: 90,
                    height: 50,
                    mode: 'linear',
                    stops: [
                        {
                            color: '#2D01FF',
                            percentage: 0
                        },
                        {
                            color: '#F00000',
                            percentage: 100
                        }
                    ],
                    width: 50
                },
                type: 'color'
            },
            content: {
                value: {
                    content: [
                        {
                            content: [
                                {
                                    text: '高亮文本',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            },
            font: {
                advance: '',
                fontFamily: TEXT_FONT_NORMAL,
                fontSize: 1,
                letterSpacing: 0,
                lineHeight: 1.5
            }
        }
    } as TextBlockAbstract

    const textContainer = getBlockInitConfig(ROW_CONTAINER) as ContainerBlockAbstract
    const textContainerBlock: ContainerBlockAbstract = {
        ...textContainer,
        title: '高亮文本',
        config: {
            ...COMMON_BLOCK_CONFIG,
            design: {
                alignY: 'flex-start',
                background: {
                    color: '#0d19fc1f',
                    gradient: {
                        angle: 90,
                        height: 50,
                        mode: 'linear',
                        stops: [
                            {
                                color: '#2D01FF',
                                percentage: 0
                            },
                            {
                                color: '#F00000',
                                percentage: 100
                            }
                        ],
                        width: 50
                    },
                    type: 'color'
                },
                direction: 'vertical',
                gap: 0.7,
                padding: [1.5, 1.5, 1.5, 1.5],
                radius: [8, 8, 8, 8],
                size: 'md',
                veins: {
                    color: '#08324e1a',
                    size: 50,
                    strokeWidth: 2,
                    value: 'Angled Dots (Circle)'
                }
            },
            viewList: [
                {
                    id: nanoid(),
                    name: '面板 01'
                }
            ]
        }
    }
    const node: FlowLayoutNode = {
        children: [
            {
                type: 'block',
                id: textBlock.id,
                width: 12
            }
        ],
        data: { ...DEFAULT_CONTAINER_DESIGN },
        id: textContainerBlock.id,
        type: 'container',
        width: 12
    }

    return [[textContainerBlock, textBlock], node]
}
