import { pointer } from '@byecode/ui'
import { ScrollArea } from '@mantine/core'
import styled from 'styled-components'

export const IconWrapper = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
`

export const ItemLeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
`

export const ItemRightFill = styled.div``

export const DropDownItem = styled.div`
    position: relative;
    height: 36px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :hover {
        background-color: var(--color-theme-3);
        border-radius: 4px;
    }
`

export const ItemWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    ${pointer}
`
