import { Flex, IconFont, Menu, Modal, Popover, Text, tinyButtons } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingTheme, TextAdvanceOption } from '@lighthouse/core'
import { PopoverDropdownWithClose, TEXT_TEMPLATE_LIST, useAtomAction, useAtomData } from '@lighthouse/shared'
import { generateNameByAutoIncrement, mergeRefs, nanoid, stopPropagation } from '@lighthouse/tools'
import classNames from 'classnames'
import produce from 'immer'
import type { CSSProperties } from 'react'
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { updateAppVersionConfigAtom } from '@/atoms/application/action'
import { applicationSettingAtom } from '@/atoms/application/state'
import { TextAdvanceConfigure } from '@/components/TextAdvanceConfigure'
import { useCurrentAppID } from '@/hooks/useApplication'

import * as SC from '../styles'

const StyledScroller = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 600px;
    overflow: hidden auto;
    ${tinyButtons}
`

const StyledTextItem = styled.div<{ isDragging: boolean; isSorting: boolean }>`
    height: 36px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    padding: 8px;

    ${({ isDragging, isSorting }) => {
        if (isSorting) {
            if (isDragging) {
                return css`
                    background-color: var(--color-gray-100);
                `
            }
            return
        }

        return css`
            &:hover,
            &.active {
                background-color: var(--color-gray-100);
            }
        `
    }}
`

const StyledInput = styled.input`
    appearance: none;
    width: 100%;
    height: 16px;
    line-height: 16px;
    flex: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
`

type ActionMethods = {
    rename: () => void
}

interface TextItemProps extends React.ComponentPropsWithoutRef<'div'> {
    data: TextAdvanceOption
    onUpdate: (value: TextAdvanceOption) => void

    actionRef?: React.MutableRefObject<ActionMethods | undefined>
}

const TextItem = forwardRef<HTMLDivElement, TextItemProps>(({ data, actionRef, className, onUpdate, ...rest }, ref) => {
    const { setNodeRef, listeners, isDragging, isSorting, transform, transition } = useSortable({
        id: data.id
    })

    const [isEditMode, setIsEditMode] = useState(false)

    const onEditorBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget
        if (value === data.name) {
            return
        }
        onUpdate({ ...data, name: value })

        setIsEditMode(false)
    }

    const onEditorKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const { value } = e.currentTarget
            e.currentTarget.blur()

            if (value === data.name) {
                return
            }
            onUpdate({ ...data, name: value })
        }
    }

    useImperativeHandle(
        actionRef,
        () => {
            return {
                rename: () => setIsEditMode(true)
            }
        },
        []
    )

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <StyledTextItem
            isDragging={isDragging}
            isSorting={isSorting}
            ref={mergeRefs([ref, setNodeRef])}
            role="button"
            {...rest}
            className={classNames(className, { active: isEditMode })}
            onMouseDown={e => {
                rest.onMouseDown?.(e)
                listeners?.onMouseDown(e)
            }}
            onDoubleClick={() => setIsEditMode(true)}
            style={style}
        >
            <IconFont type="Text-e33om5n2" size={16} />
            {isEditMode ? (
                <StyledInput
                    autoFocus
                    defaultValue={data.name}
                    onBlur={onEditorBlur}
                    onKeyDown={onEditorKeyDown}
                    onFocus={e => e.currentTarget.select()}
                />
            ) : (
                <Text size={14} lineHeight="16px" styles={{ root: { flex: 1, height: 16 } }}>
                    {data.name}
                </Text>
            )}
        </StyledTextItem>
    )
})

interface WithMenuItemProps extends Omit<TextItemProps, 'actionRef'> {
    onDelete: (id: string) => void
}

const WithMenuItem = forwardRef<HTMLDivElement, WithMenuItemProps>(({ data, onDelete, className, ...rest }, ref) => {
    const actionRef = useRef<ActionMethods>()

    const [open, setOpen] = useState(false)

    return (
        <Menu
            opened={open}
            onChange={setOpen}
            trigger="contextMenu"
            closeOnItemClick
            returnFocus={false}
            position="right-start"
            width={166}
        >
            <Menu.Target ref={ref}>
                <TextItem data={data} actionRef={actionRef} className={classNames(className, { active: open })} {...rest} />
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    icon={<IconFont type="NotePencil" size={16} color="var(--color-gray-400)" />}
                    onClick={() => {
                        actionRef.current?.rename()
                    }}
                >
                    重命名
                </Menu.Item>
                <Menu.Item icon={<IconFont type="Trash" size={16} color="var(--color-gray-400)" />} onClick={() => onDelete(data.id)}>
                    删除
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
})

interface WithPopoverItemProps extends WithMenuItemProps {}
const WithPopoverItem = forwardRef<HTMLDivElement, WithPopoverItemProps>((props, ref) => {
    const { className, ...rest } = props

    const [open, setOpen] = useState(false)

    return (
        <Popover
            opened={open}
            onChange={setOpen}
            enableClickToggle={false}
            withinPortal
            width={300}
            returnFocus={false}
            offsetOptions={12}
            position="right-start"
        >
            <Popover.Target ref={ref}>
                <WithMenuItem className={classNames(className, { active: open })} {...rest} onContextMenu={() => setOpen(false)} />
            </Popover.Target>
            <PopoverDropdownWithClose title={props.data.name}>
                <TextAdvanceConfigure value={props.data} onChange={v => props.onUpdate({ ...v, id: props.data.id })} />
            </PopoverDropdownWithClose>
        </Popover>
    )
})

const modifiers = [restrictToVerticalAxis, restrictToParentElement]

export const TextCategory = () => {
    const theme = useAtomData(
        applicationSettingAtom,
        useCallback(s => s.theme, [])
    )

    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)

    const onUpdate = useCallback(
        (values: ApplicationSettingTheme) => {
            updateAppVersionConfig({
                config: {
                    theme: values
                }
            })
        },
        [updateAppVersionConfig]
    )

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        })
    )

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = theme.textPresetList.findIndex(item => item.id === (over.id as string))
            const activeIndex = theme.textPresetList.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                const newList = arrayMove(theme.textPresetList, activeIndex, overIndex)
                onUpdate({ ...theme, textPresetList: newList })
            }
        }

        setActiveId(null)
    }

    // 创建文本预设
    const onCreate = useCallback(
        (values: Omit<TextAdvanceOption, 'id'>) => {
            const newValue = {
                ...values,
                id: nanoid(),
                name: generateNameByAutoIncrement(
                    values.name,
                    theme.textPresetList.map(item => item.name)
                )
            }
            onUpdate({ ...theme, textPresetList: [...theme.textPresetList, newValue] })
        },
        [onUpdate, theme]
    )

    // 删除文本预设
    const onDelete = useCallback(
        async (id: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: '删除后，所有使用该预设的文本样式将会清除'
            })
            if (!isConfirm) {
                return
            }

            onUpdate({ ...theme, textPresetList: theme.textPresetList.filter(item => item.id !== id) })
        },
        [onUpdate, theme]
    )

    return (
        <SC.CategoryGroup
            label="文本"
            renderRightIcon={() => (
                <Menu width={175} withinPortal position="right-start" closeOnItemClick offsetOptions={16}>
                    <Menu.Target>
                        <SC.CreatorButton onClick={stopPropagation}>
                            <IconFont type="Add2" size={16} color="var(--color-main)" />
                        </SC.CreatorButton>
                    </Menu.Target>
                    <Menu.Dropdown onClick={stopPropagation}>
                        {TEXT_TEMPLATE_LIST.map(item => (
                            <Menu.Item key={item.name} onClick={() => onCreate(item)}>
                                {item.name}
                            </Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu>
            )}
        >
            <StyledScroller>
                <DndContext
                    sensors={sensors}
                    modifiers={modifiers}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={theme.textPresetList} strategy={verticalListSortingStrategy}>
                        {theme.textPresetList.map((item, index) => (
                            <WithPopoverItem
                                key={item.id}
                                data={item}
                                onDelete={onDelete}
                                onUpdate={v =>
                                    onUpdate(
                                        produce(theme, draft => {
                                            draft.textPresetList[index] = v
                                        })
                                    )
                                }
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </StyledScroller>
        </SC.CategoryGroup>
    )
}
