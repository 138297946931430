import { Flex, IconFont, Text } from '@byecode/ui'
import type { SmsPlatForm } from '@lighthouse/core'
import { SMS_TYPE } from '@lighthouse/core'
import { smsPlatformMap, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { setAppLayoutModeAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'

import { ModuleType } from '../ApplicationSetting/type'

interface AppSmsCardProps {}
const SCxContainer = styled.div`
    display: flex;
    width: 100%;
    height: 38px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    /* border: 1px solid var(--color-gray-200); */
    background: var(--color-gray-100);
`

export const AppSmsCard: React.FunctionComponent<AppSmsCardProps> = props => {
    const navigate = useNavigate()
    const { run: setMode } = useAtomAction(setAppLayoutModeAtom)
    const sms = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.sms, [])
    )

    const text = useMemo(() => {
        if (sms?.smsType === SMS_TYPE.SYSTEM) {
            return 'ByeCode'
        }
        if (!sms?.smsPlatform) {
            return null
        }
        return smsPlatformMap[sms.smsPlatform]
    }, [sms?.smsPlatform, sms?.smsType])

    return (
        <SCxContainer>
            <Flex gap={4}>
                <IconFont size={16} color="var(--color-gray-400)" type="ChatRoundLine" />
                <Text>验证码短信</Text>
            </Flex>
            <Flex
                justifyContent="flex-end"
                gap={4}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setMode({ type: 'setting', currentTab: ModuleType.sms })
                    navigate({ pathname: './settings' })
                }}
            >
                <Text color="var(--color-gray-400)">{text ? `由 ${text} 发送` : '未配置完成'} </Text>
                <IconFont size={16} color="var(--color-gray-400)" type="ArrowRightSmall" />
            </Flex>
        </SCxContainer>
    )
}
