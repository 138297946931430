import React from 'react'

import { UserCenterItem } from './UserCenterItem'

const userCenterOptions = [
    {
        value: 'user',
        label: '用户',
        icon: 'PropertyPerson'
    },
    {
        value: 'department',
        label: '部门',
        icon: 'Departments'
    },
    {
        value: 'userGroup',
        label: '角色',
        icon: 'SpaceTeam'
    }
]

interface UserCenterProps {
    activeId?: string
    onSelect: (val: string) => void
}

export const UserCenter: React.FC<UserCenterProps> = ({ activeId, onSelect }) => {
    return (
        <>
            {userCenterOptions.map(option => (
                <UserCenterItem
                    key={option.value}
                    activeId={activeId}
                    id={option.value}
                    name={option.label}
                    icon={option.icon}
                    onSelect={onSelect}
                />
            ))}
        </>
    )
}
