import { Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { AggregateResultField, AggregatorFieldSettingConfig } from '@lighthouse/shared'
import type { TabsValue } from '@mantine/core'
import { Tabs } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'

import * as SC from '../styles'
import { TabStyles } from '../styles'
import { TablePreview } from '../TablePreview'
import { AggregatorFieldSetting } from './AggregatorFieldSetting'

const noDataDependenceImg = getAssetUrl('empty', 'no_data_dependence.png')
const noDataImperfectImg = getAssetUrl('empty', 'no_data_imperfect.png')

interface FieldSettingProps {
    dsId: string
    nodeId: string
    fieldList: AggregateResultField[]
}

export const FieldSetting: React.FC<FieldSettingProps> = ({ dsId, nodeId, fieldList }) => {
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const { control } = useFormContext<AggregatorFieldSettingConfig>()

    const [tab, setTab] = useState('config')

    const handleTabChange = useCallback((value: TabsValue) => {
        if (value) {
            setTab(value)
        }
    }, [])

    const isEmptyFieldList = useMemo(() => {
        return fieldList.length === 0
    }, [fieldList.length])

    const configureContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 315 }} src={noDataDependenceImg} alt="未找到数据" />}
                    description="请先完成依赖节点的配置"
                />
            )
        }
        return (
            <Controller
                name="fieldList"
                control={control}
                render={({ field }) => (
                    <AggregatorFieldSetting dsId={dsId} nodeId={nodeId} fieldList={field.value} onChange={field.onChange} />
                )}
            />
        )
    }, [control, dsId, isEmptyFieldList, nodeId])

    const previewContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 202 }} src={noDataImperfectImg} alt="未找到数据" />}
                    description="预览数据前，请先完成节点配置"
                />
            )
        }
        return <TablePreview appId={appId} envId={envId} dsId={dsId} nodeId={nodeId} active={tab === 'preview'} />
    }, [appId, dsId, envId, isEmptyFieldList, nodeId, tab])

    return (
        <Tabs color="var(--color-main)" value={tab} onTabChange={handleTabChange} styles={TabStyles}>
            <Tabs.List>
                <Tabs.Tab value="config">
                    <SC.TabText isActive={tab === 'config'}>节点配置</SC.TabText>
                </Tabs.Tab>
                <Tabs.Tab value="preview" color="blue">
                    <SC.TabText isActive={tab === 'preview'}>
                        数据预览
                        <SC.TabTooltip>
                            <Tooltip title="预览前20条数据">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </SC.TabTooltip>
                    </SC.TabText>
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="config" pt="xs">
                <SC.PanelContent style={isEmptyFieldList ? {} : { paddingTop: 24 }}>{configureContent}</SC.PanelContent>
            </Tabs.Panel>

            <Tabs.Panel value="preview" pt="xs">
                <SC.PreviewContent>{previewContent}</SC.PreviewContent>
            </Tabs.Panel>
        </Tabs>
    )
}
