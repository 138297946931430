import { Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import * as SC from './styles'
import type { WxRobotFormState, WxRobotMode } from './type'

interface WxRobotProps {
    id?: string
    mode: WxRobotMode
    onClose?: () => void
}

const initData: WxRobotFormState = {
    id: '',
    name: '',
    webhook: ''
}
const WxRobot: React.FunctionComponent<WxRobotProps> = ({ id, mode, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()
    const [loading, setLoading] = useState(false)

    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform ? { ...otherPlatform?.config, name: otherPlatform.name, id } : initData
    }, [id, otherPlatforms])

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: WxRobotFormState) => {
            const { webhook, name } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'WE_COM_ROBOT',
                name,
                params: {
                    webhook
                }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」。`,
            okStatus: 'error'
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: WxRobotFormState) => {
            const { webhook, name, id } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'WE_COM_ROBOT',
                name,
                id,
                params: {
                    webhook
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} type="BrandLogoqiyeweixin" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}企业微信群机器人账号</SC.Title>
                <SC.Item>
                    <SC.Text>
                        企业微信群机器人名称
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>

                    <SC.Description>方案启动后，填入的文本内容，将被企微群机器人发出，请检查核对。</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        企业微信群机器人Webhook地址
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="webhook" errors={errors}>
                        <Input {...register('webhook', { required: '不能为空' })} />
                    </ErrorMessage>
                    <SC.Description>
                        ① 添加新的群机器人：选择一个企业微信内部员工群，右上角…添加群机器人-添加一个新的机器人，添加成功后，复制webhook地址
                        <br />
                        <br />
                        ② 添加已有的群机器人：选择一个企业微信内部员工群，查看已有群机器人-查看资料-复制webhook地址
                        <br />
                        <br />
                        ③增加多个账号，即可发多个群
                        <br />
                        <br />
                        ④更改/删除账号配置，所有引用此账号的流程和节点都会被影响
                    </SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default WxRobot
