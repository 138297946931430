import { Divider, IconFont, Popover, Switch, Toast, Tooltip } from '@byecode/ui'
import type { FilterFormType, Sorter } from '@lighthouse/core'
import { type DataSourceAbstract, DataSourceType, SelectedMode } from '@lighthouse/core'
import type { ApplicationPreviewEnum, DsExportParams } from '@lighthouse/shared'
import { type AppendParams, type ImportParams, ImportModal } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'

import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import * as SC from './styles'

interface DataSourceAppenderProps {
    appId: string
    envId: string
    dataSource: DataSourceAbstract
    searchVal: string
    name: string
    enableApprovalState?: boolean
    // previewType: ApplicationPreviewEnum
    filter?: FilterFormType
    sorts?: Sorter[]
    onCreate?: (params: ImportParams) => Promise<boolean>
    onAppend?: (params: AppendParams) => Promise<void>
    onEnableApprovalState?: (enabled: boolean) => Promise<void>
}

const DataSourceAppender: React.FC<DataSourceAppenderProps> = ({
    appId,
    envId,
    dataSource,
    searchVal,
    name,
    enableApprovalState = false,
    filter,
    sorts,
    onAppend,
    onCreate,
    onEnableApprovalState
}) => {
    const { type, id } = dataSource
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const dataSourceList = useDataSourceList(appId, envId)

    const handleImport = useCallback(() => {
        setPopoverOpen(false)
        setOpen(true)
    }, [])

    const handleExport = useCallback(async () => {
        Toast.warning('正在导出, 请勿重复点击')
        const params: DsExportParams = {
            envId,
            dsId: id,
            search: searchVal,
            mode: SelectedMode.ALL,
            fileType: 'xlsx',
            filter,
            sorts
        }
        const res = await srv.dsExport(params)
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${name}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [envId, filter, id, name, searchVal, sorts])

    const disableImport = useMemo(() => type === DataSourceType.userDataSource || type === DataSourceType.aggregateDataSource, [type])
    // const disableApprove = useMemo(
    //     () =>
    //         type === DataSourceType.userDataSource || type === DataSourceType.joinDataSource || type === DataSourceType.aggregateDataSource,
    //     [type]
    // )
    const disableApprove = useMemo(() => true, [])
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <Popover width="auto" withinPortal opened={popoverOpen} onChange={setPopoverOpen}>
            <Popover.Target>
                <SC.ExtraButton type="text" icon={<IconFont type="DotsThree" />} />
            </Popover.Target>
            <Popover.Dropdown>
                <SC.ActionList>
                    {!disableImport && (
                        <SC.ActionItem onClick={handleImport}>
                            <IconFont type="UploadSimple" style={{ marginRight: 4, color: 'var(--color-gray-400)' }} />
                            导入数据
                        </SC.ActionItem>
                    )}
                    <SC.ActionItem onClick={handleExport}>
                        <IconFont type="Import" style={{ marginRight: 4, color: 'var(--color-gray-400)' }} />
                        导出数据
                    </SC.ActionItem>
                    {!disableApprove && (
                        <>
                            <Divider style={{ borderColor: 'var(--color-gray-100)', margin: '4px 0' }} />
                            <SC.ActionItem>
                                <IconFont type="UserRole" style={{ marginRight: 4, color: 'var(--color-gray-400)' }} />
                                <SC.ActionItemText>
                                    启用审批系统字段
                                    <Tooltip
                                        title="在数据表上呈现 审批状态、发起人、发起时间 的审批系统字段
启用后才能在页面中选用这些字段"
                                    >
                                        <IconFont style={{ marginLeft: 6 }} type="Question" color="var(--color-gray-400)" />
                                    </Tooltip>
                                </SC.ActionItemText>
                                <Switch
                                    checked={enableApprovalState}
                                    size="sm"
                                    style={{ justifySelf: 'flex-end' }}
                                    onChange={ev => onEnableApprovalState?.(ev.target.checked)}
                                />
                            </SC.ActionItem>
                        </>
                    )}
                </SC.ActionList>
            </Popover.Dropdown>
            <ImportModal
                appId={appId}
                envId={envId}
                dataSource={dataSource}
                defaultImportType="append"
                open={open}
                dataSourceList={dataSourceList}
                onImport={onCreate}
                onAppend={onAppend}
                onClose={handleClose}
            />
        </Popover>
    )
}

export default DataSourceAppender
