import { Button, Loading, Modal, ModalConfirm, Toast } from '@byecode/ui'
import type { BaseFlowNode, DrawMode, FlowEdge, FlowNode, FlowNodeButtonClickPayload, FlowPageMode, Workflow } from '@lighthouse/shared'
import { FindUseLocationType, useAtomAction, useAtomData } from '@lighthouse/shared'
import { Pagination } from '@mantine/core'
import { lightFormat } from 'date-fns'
import { clone, find, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'
import { ReactFlowProvider, useReactFlow } from 'reactflow'
import styled from 'styled-components'
import type { KeyedMutator } from 'swr'
import useSWR from 'swr'
import { useImmer } from 'use-immer'

import { setFindUseAtom } from '@/atoms/application/action'
import { findUseAtom } from '@/atoms/application/state'
import { getDataSourceListMetaAtom } from '@/atoms/dataSource/action'
import { fetchWorkflowAtom } from '@/atoms/workflow/action'
import { FlowProvider } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'
import type { RunStatus } from '@/services/types'
import { useFlowLogsWithPagination } from '@/shared/reusable'

import { StatusTable } from '../FlowList/components/StatusTable'
import { FlowEditor } from './components/FlowEditor'
import { PageHeader } from './components/PageHeader'
import { FlowSetting } from './FlowSetting'
import { addNewConditionNodeWithEdges } from './utils/addNode'

interface FlowDetailState {
    opened: boolean
    mode: DrawMode
    node: FlowNode | null
    edge: FlowNodeButtonClickPayload | null
}

const SCxFlowDetailWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff;
`

const SCxFlowDetailContent = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
`

const SCxFlowSettingWrapper = styled.div`
    position: absolute;
    top: 55px;
    right: 0px;
    height: 100%;
    background-color: var(--color-white);
    z-index: 1;
`

const SCxFlowRunLogWrapper = styled.div`
    margin: 24px;
    height: calc(100% - 160px);
`

export interface FlowDetailProps {
    id: string
    showBack?: boolean
    showLogTab?: boolean
    showClose?: boolean
    closeAfterDelete?: boolean
    onClose?: () => void
}

export interface FlowDetailContentProps {
    appId?: string
    id: string
    showBack?: boolean
    showClose?: boolean
    showLogTab?: boolean
    workflow: Workflow
    tab?: FlowPageMode
    status?: RunStatus
    mutate: KeyedMutator<Workflow>
    onTabChange?: (tab: FlowPageMode) => void
    onNameChange?: (name: string) => void
    onBack?: () => void
    onDelete?: (id: string) => void
    onClose?: () => void
    onLogStatusFilterChange?: (status: RunStatus) => void
}

export const FlowDetailContent: React.FC<FlowDetailContentProps> = ({
    id,
    workflow,
    showBack,
    showClose,
    showLogTab,
    mutate,
    tab,
    onTabChange,
    onNameChange,
    onBack,
    onDelete,
    onClose
}) => {
    const { setNodes, setEdges, getNodes, getEdges } = useReactFlow()
    const findUseData = useAtomData(findUseAtom)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)

    const [isChanged, setIsChanged] = useState(false)
    const [isSaveAndLeaveLoading, setIsSaveAndLeaveLoading] = useState(false)

    const handleFlowEnabledChange = useCallback(
        async (enabled: boolean) => {
            if (enabled) {
                try {
                    const { content, ...restWorkflow } = workflow
                    const res = await srv.updateAndEnableWorkflow({ ...restWorkflow, ...content })

                    if (res) {
                        Toast.success('启用成功')
                        setIsChanged(false)
                        mutate()
                    }
                } catch {
                    Toast.error('无法启用工作流，请检查配置后重试')
                }
                return
            }
            try {
                const res = await srv.enableFlow(id, enabled)

                if (res.success) {
                    Toast.success(`${enabled ? '启用' : '禁用'}成功`)
                    setIsChanged(false)
                    mutate()
                }
            } catch {
                Toast.error(enabled ? '无法启用工作流，请检查配置后重试' : '禁用失败，请稍后重试')
            }
        },
        [id, mutate, workflow]
    )

    const handleNodesOrEdgesChange = useCallback(
        (nodes: FlowNode[], edges: FlowEdge[], refresh = true) => {
            // await srv.updateFlow({ id, nodes, edges })
            // if (refresh) {
            //     mutate()
            //     return
            // }
            // 更新工作流状态
            setIsChanged(true)
            workflow && mutate({ ...workflow, content: { nodes, edges }, changed: true }, { revalidate: false })
        },
        [mutate, workflow]
    )

    const handleChangeNodeConfig = useCallback(
        (nodeId: string, data: BaseFlowNode['data']) => {
            const nodes = getNodes() as FlowNode[]
            const edges = getEdges() as FlowEdge[]
            if (!nodes || !edges) {
                return
            }
            setIsChanged(true)
            if (workflow && workflow.content) {
                const newNodes = nodes.map(item => {
                    if (item.id === nodeId) {
                        return {
                            ...item,
                            data
                        }
                    }
                    return item
                })
                setNodes(newNodes)
                // await srv.updateFlow({ id, nodes: newNodes, edges })
                // 当工作流启动后,第一次修改节点配置,需要重新拉取工作流状态
                /* workflow.enabled && !workflow.changed &&  */
                mutate({ ...workflow, content: { ...workflow.content, nodes: newNodes }, changed: true }, { revalidate: false })
            }
        },
        [getEdges, getNodes, mutate, setNodes, workflow]
    )

    const descriptions = useMemo(() => {
        if (!workflow) {
            return []
        }
        const { updatedTime, updatedBy, createdTime, createdBy } = workflow
        return [
            { id: '1', label: '最后编辑', value: lightFormat(updatedTime, 'yyyy/MM/dd HH:mm') },
            { id: '2', label: '编辑人', value: updatedBy },
            { id: '3', label: '创建时间', value: lightFormat(createdTime, 'yyyy/MM/dd HH:mm') },
            { id: '4', label: '创建人', value: createdBy }
        ]
    }, [workflow])

    const [state, setState] = useImmer<FlowDetailState>({
        opened: true,
        mode: 'nodeCreator',
        node: null,
        edge: null
    })
    const { opened, mode, node: currentNode, edge: currentEdge } = state

    const isSettingShown = currentNode || currentEdge

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.opened = false
        })
    }, [setState])

    const handleNodeClick = useCallback(
        (node: FlowNode) => {
            setState(draft => {
                draft.mode = 'nodeConfigurator'
                draft.opened = true
                draft.node = node
            })
        },
        [setState]
    )

    useEffect(() => {
        if (!findUseData) {
            return
        }
        const { location } = findUseData
        if (location.type === FindUseLocationType.WORKFLOW && location.workflowId === workflow.id) {
            const node = find(item => item.id === location.nodeId, workflow.content?.nodes || [])
            if (node) {
                handleNodeClick(node)
            }
            setFindUse(undefined)
        }
    }, [findUseData, handleNodeClick, setFindUse, workflow.content?.nodes, workflow.id])

    // Block navigating elsewhere when data has been entered into the input
    useBeforeUnload(!showClose && isChanged, '你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？')
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !showClose && isChanged && currentLocation.pathname !== nextLocation.pathname
    )

    // const handleDropDraft = useCallback(async () => {
    //     try {
    //         const res = await srv.dropFlowDraft(id)
    //         if (res.success) {
    //             Toast.success('已放弃更改')
    //             mutate()
    //             return
    //         }
    //     } catch {
    //         Toast.error('操作失败，请稍后重试')
    //     }
    // }, [id, mutate])

    // const handleSaveDraftAsRelease = useCallback(async () => {
    //     try {
    //         const res = await srv.saveFlowDraftAsRelease(id)
    //         mutate()
    //         if (res.success) {
    //             Toast.success('更新成功')
    //             return
    //         }
    //     } catch {
    //         Toast.error('更新失败，请稍后重试')
    //     }
    // }, [id, mutate])
    const handleUpdateAndRelease = useCallback(async () => {
        if (workflow.enabled) {
            try {
                const updated = await srv.updateAndEnableWorkflow({ id, ...workflow.content })
                if (updated) {
                    Toast.success('更新成功')
                    setIsChanged(false)
                    return true
                }
            } catch {
                Toast.error('更新失败，请稍后重试')
            }
            return false
        }

        const updated = await srv.updateFlow({ id, ...workflow.content })
        if (updated) {
            Toast.success('更新成功')
            setIsChanged(false)
        }
        return updated
    }, [id, workflow])

    const handleSaveAndLeave = useCallback(async () => {
        setIsSaveAndLeaveLoading(true)
        const isSuccess = await handleUpdateAndRelease()
        setIsSaveAndLeaveLoading(false)
        isSuccess ? blocker?.proceed?.() : blocker?.reset?.()
    }, [blocker, handleUpdateAndRelease])

    const handleSelectChange = useCallback(
        (selected: boolean) => {
            if (!selected) {
                setState(draft => {
                    draft.node = null
                    draft.edge = null
                })
            }
        },
        [setState]
    )

    const handleEdgeButtonClick = useCallback(
        (params: FlowNodeButtonClickPayload) => {
            const {
                edge: { source, target },
                operator
            } = params

            // 如果当前操作为添加条件，则直接添加，不需要弹出右侧节点配置面板
            if (operator === 'addCondition') {
                const nodes = getNodes() as FlowNode[]
                const edges = getEdges() as FlowEdge[]

                const { nodes: newNodes, edges: newEdges } = addNewConditionNodeWithEdges(nodes, edges, source, target)

                handleNodesOrEdgesChange(newNodes, newEdges)

                setNodes(newNodes)
                setEdges(newEdges)
                return
            }

            setState(draft => {
                draft.mode = 'nodeCreator'
                draft.opened = true
                draft.edge = clone(params)
            })
        },
        [getEdges, getNodes, handleNodesOrEdgesChange, setEdges, setNodes, setState]
    )

    return workflow ? (
        <FlowProvider
            id={workflow.id}
            type={workflow.type}
            errorMap={workflow.errorMap}
            onChange={handleNodesOrEdgesChange}
            onEdgeButtonClick={handleEdgeButtonClick}
        >
            <SCxFlowDetailContent>
                <PageHeader
                    isSettingShown={!!isSettingShown}
                    showBack={showBack}
                    showClose={showClose}
                    showLogTab={showLogTab}
                    name={workflow.name}
                    tab={tab}
                    descriptions={descriptions}
                    flowEnabled={workflow.enabled}
                    flowUpdated={isChanged}
                    onNameChange={onNameChange}
                    onBack={onBack}
                    onClose={onClose}
                    onDelete={() => {
                        setIsChanged(false)
                        onDelete?.(id)
                    }}
                    onFlowEnableChange={handleFlowEnabledChange}
                    onUpdateAndRelease={handleUpdateAndRelease}
                    onTabChange={onTabChange}
                />
                <FlowEditor
                    type={workflow.type}
                    nodes={workflow.content?.nodes || []}
                    edges={workflow.content?.edges || []}
                    onChange={handleNodesOrEdgesChange}
                    onSelectChange={handleSelectChange}
                    onNodeClick={handleNodeClick}
                />
                {isSettingShown && (
                    <SCxFlowSettingWrapper>
                        <FlowSetting
                            type={workflow.type}
                            opened={opened}
                            mode={mode}
                            edges={workflow.content?.edges || []}
                            node={currentNode}
                            edge={currentEdge}
                            onClose={handleClose}
                            onChangeNodeConfig={handleChangeNodeConfig}
                            onNodesOrEdgesChange={(nodes, edges, node) => {
                                const newNotSelectedEdges = edges.map(e => ({ ...e, selected: false }))
                                handleNodesOrEdgesChange(nodes, newNotSelectedEdges, false)
                                if (node) {
                                    setState(draft => {
                                        draft.mode = 'nodeConfigurator'
                                        draft.opened = true
                                        draft.node = node
                                        draft.edge = null
                                    })
                                }
                            }}
                        />
                    </SCxFlowSettingWrapper>
                )}
            </SCxFlowDetailContent>
            {blocker.state === 'blocked' && (
                <ModalConfirm
                    title="工作流设计有修改，是否保存？"
                    content="你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？"
                    footerExtra={
                        <Button type="text" size="sm" onClick={blocker.reset}>
                            返回设计
                        </Button>
                    }
                    onReject={blocker.proceed}
                    onResolve={handleSaveAndLeave}
                    okStatus="primary"
                    okText="保存并继续"
                    okProps={{ loading: isSaveAndLeaveLoading }}
                    cancelText="不保存"
                />
            )}
        </FlowProvider>
    ) : (
        <Loading outlined />
    )
}

export const FlowRunLog: React.FC<FlowDetailContentProps> = ({
    appId,
    id,
    workflow,
    showBack,
    showClose,
    showLogTab,
    tab,
    status,
    onTabChange,
    onNameChange,
    onBack,
    onDelete,
    onLogStatusFilterChange
}) => {
    const { workflows, totalPage, setCurrentPage } = useFlowLogsWithPagination({ workflowId: id, state: status })

    const descriptions = useMemo(() => {
        if (!workflow) {
            return []
        }
        const { updatedTime, updatedBy, createdTime, createdBy } = workflow
        return [
            { id: '1', label: '最后编辑', value: lightFormat(updatedTime, 'yyyy/MM/dd HH:mm') },
            { id: '2', label: '编辑人', value: updatedBy },
            { id: '3', label: '创建时间', value: lightFormat(createdTime, 'yyyy/MM/dd HH:mm') },
            { id: '4', label: '创建人', value: createdBy }
        ]
    }, [workflow])

    return workflow ? (
        <SCxFlowDetailContent>
            <PageHeader
                showBack={showBack}
                showClose={showClose}
                showLogTab={showLogTab}
                name={workflow.name}
                tab={tab}
                descriptions={descriptions}
                status={status}
                onNameChange={onNameChange}
                onBack={onBack}
                onDelete={() => onDelete?.(id)}
                onTabChange={onTabChange}
                onLogStatusFilterChange={onLogStatusFilterChange}
            />
            <SCxFlowRunLogWrapper>
                <StatusTable showNameField data={workflows} />
                <Pagination style={{ marginTop: 16 }} size="md" total={totalPage} onChange={page => setCurrentPage(page)} />
            </SCxFlowRunLogWrapper>
        </SCxFlowDetailContent>
    ) : null
}

export const FlowDetailContainer: React.FC<{
    id: string
    showBack: boolean
    showLogTab: boolean
    showClose: boolean
    closeAfterDelete?: boolean
    onClose?: () => void
}> = ({ id, showBack, showClose, showLogTab, closeAfterDelete, onClose }) => {
    const navigate = useNavigate()
    const [tab, setTab] = useState<FlowPageMode>('flow')
    const [status, setStatus] = useState<RunStatus>('all')
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { run: fetchWorkflow } = useAtomAction(fetchWorkflowAtom)
    const { run: getDataSourceListMeta } = useAtomAction(getDataSourceListMetaAtom)
    const {
        data: workflow,
        isLoading,
        mutate
    } = useSWR(
        ['fetchFlow', id],
        async ([, id]) => {
            const flow = await fetchWorkflow(id)
            const nodes = flow.content?.nodes || []
            const dataSourceIdentities = nodes.reduce<string[]>((prev, cur) => {
                const nodeConfig = cur.data.config
                if (!nodeConfig || !('dataSourceId' in nodeConfig)) {
                    return prev
                }
                const index = findIndex(dsId => dsId === nodeConfig.dataSourceId, prev)
                if (nodeConfig.dataSourceId && index < 0) {
                    prev.push(nodeConfig.dataSourceId)
                }
                return prev
            }, [])
            if (dataSourceIdentities.length > 0) {
                getDataSourceListMeta({ dsIds: dataSourceIdentities, envId })
            }
            return flow
        },
        { revalidateOnMount: true, revalidateOnFocus: false }
    )

    const isShownLogTab = useMemo(() => {
        // return workflow?.type !== 'action' && showLogTab
        return showLogTab
    }, [showLogTab])

    const handleNameChange = useCallback(
        async (name: string) => {
            workflow && mutate({ ...workflow, name }, { revalidate: false })
            await srv.updateFlow({ id, name })
            !workflow && mutate()
        },
        [id, mutate, workflow]
    )

    const handleBack = useCallback(() => {
        navigate({ pathname: '../flow' })
    }, [navigate])

    const handleDelete = useCallback(async () => {
        const isDeleted = await Modal.confirm({
            title: '删除工作流',
            content: `确认删除“${workflow?.name ?? '未命名'}”工作流？删除后不可恢复，请谨慎操作`
        })

        if (!isDeleted) {
            return
        }

        await srv.deleteFlow(id)

        Toast.success('删除成功')

        if (closeAfterDelete) {
            onClose?.()
            return
        }
        navigate({ pathname: '../flow' })
    }, [closeAfterDelete, id, navigate, onClose, workflow?.name])

    useEffect(() => {
        return () => {
            mutate(undefined, { revalidate: false })
        }
    }, [mutate])

    return useMemo(() => {
        if (!workflow || isLoading) {
            return <Loading />
        }

        return tab === 'runLog' ? (
            <FlowRunLog
                appId={appId}
                id={id}
                showBack={showBack}
                showClose={showClose}
                showLogTab={isShownLogTab}
                workflow={workflow}
                tab={tab}
                status={status}
                onTabChange={setTab}
                onBack={handleBack}
                onDelete={handleDelete}
                onNameChange={handleNameChange}
                mutate={mutate}
                onLogStatusFilterChange={setStatus}
            />
        ) : (
            <FlowDetailContent
                id={id}
                showBack={showBack}
                showClose={showClose}
                showLogTab={isShownLogTab}
                workflow={workflow}
                tab={tab}
                onTabChange={setTab}
                onBack={handleBack}
                onDelete={handleDelete}
                onClose={onClose}
                onNameChange={handleNameChange}
                mutate={mutate}
            />
        )
    }, [
        appId,
        handleBack,
        handleDelete,
        handleNameChange,
        id,
        isLoading,
        isShownLogTab,
        mutate,
        onClose,
        showBack,
        showClose,
        status,
        tab,
        workflow
    ])
}

export const FlowDetail: React.FC<FlowDetailProps> = ({
    id,
    showBack = true,
    showClose = false,
    showLogTab = true,
    closeAfterDelete = true,
    onClose
}) => {
    return (
        <ReactFlowProvider>
            <SCxFlowDetailWrapper>
                <FlowDetailContainer
                    id={id}
                    showBack={showBack}
                    showLogTab={showLogTab}
                    showClose={showClose}
                    closeAfterDelete={closeAfterDelete}
                    onClose={onClose}
                />
            </SCxFlowDetailWrapper>
        </ReactFlowProvider>
    )
}
