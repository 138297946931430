import { Box, Flex, Group, IconFont, singleTextEllipsis } from '@byecode/ui'
import { useDndMonitor } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { CardBlockAbstract, PageAbstract } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import { generateText, getIsFindUseInRichText, ListItem4ByecodeUi, RichTextEditor, useFindUseObjectContext } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import type { UploadyProps } from '@rpldy/shared-ui'
import type { FC } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { ActionAdder } from '@/components/ActionAdder'

import { ColorControl } from '../Common/ColorControl'
import { IconControlV2 } from '../Common/IconControl'

const SCxCollapse = styled(Group)<{ highlighting?: boolean }>`
    padding: 0;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
        `}

    & + & {
        margin-top: 12px;
    }
    .byecode-group-wrapper {
        background-color: var(--color-gray-100);
    }
    .byecode-group-collapse {
        background-color: #fff;
    }
`

const SCxTitle = styled.span`
    font-weight: normal;
    ${singleTextEllipsis()}
`

const StyledRichTextEditor = styled(RichTextEditor)`
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
    .ProseMirror {
        min-height: 120px;
    }
`

const SCxContent = styled.div`
    padding: 12px 0;
`

interface SortableConfigureProps {
    id: string
    index: number
    disabled?: boolean
    config: RichTextEditorProps['config']
    allPages: PageAbstract[]
    uploadParams: UploadyProps
    dsId?: string
    onRemove: (index: number | number[]) => void
}

export const SortableConfigure: FC<SortableConfigureProps> = ({ index, id, disabled, config, allPages, uploadParams, dsId, onRemove }) => {
    const [highlighting, setHighlighting] = useState(false)
    const { control } = useFormContext<CardBlockAbstract['config']>()
    const { setNodeRef, listeners, isDragging, attributes, setActivatorNodeRef, transform, transition } = useSortable({
        id,
        disabled
    })
    const findUseObject = useFindUseObjectContext()
    const { heading, iconDecoration, action } = useWatch({
        control,
        name: `list.${index}`
    })

    const [open, setOpen] = useState(index === 0)

    const prevOpenRef = useRef(open)

    const onCollapseChange = useCallback((f: boolean) => {
        setOpen(f)
        prevOpenRef.current = f
    }, [])

    useDndMonitor({
        onDragStart: e => {
            if (e.active.id === id && prevOpenRef.current) {
                setOpen(false)
            }
        },
        onDragEnd: e => {
            if (e.active.id === id && prevOpenRef.current) {
                setOpen(true)
            }
        }
    })

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        position: isDragging ? 'relative' : undefined,
        zIndex: isDragging ? 1 : undefined
    }

    return (
        <SCxCollapse
            ref={setNodeRef}
            style={style}
            highlighting={highlighting}
            collapseProps={{
                style: {
                    padding: '0 8px'
                }
            }}
            opened={open}
            onCollapseChange={onCollapseChange}
            label={
                <Box style={{ display: 'flex', gap: 6 }}>
                    <IconFont fill="var(--color-gray-400)" type="DotsSix" ref={setActivatorNodeRef} {...attributes} {...listeners} />
                    <SCxTitle>{typeof heading === 'string' ? heading : generateText(heading, config)}</SCxTitle>
                </Box>
            }
            extra={
                disabled ? null : (
                    <IconFont type="Trash" onClick={() => onRemove(index)} size={16} style={{ color: 'var(--color-gray-500)' }} />
                )
            }
        >
            <SCxContent>
                <div style={{ padding: '0 8px' }}>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <div>图标</div>
                        <Flex alignItems="center" gap="8px">
                            <Controller
                                control={control}
                                name={`list.${index}.iconDecoration`}
                                render={({ field }) => (
                                    <IconControlV2
                                        size={32}
                                        disabled={disabled}
                                        value={field.value}
                                        uploadParams={uploadParams}
                                        onChange={v => {
                                            field.onChange(v ?? { type: 'icon', value: '' })
                                        }}
                                    />
                                )}
                            />
                            {iconDecoration?.type === 'icon' && (
                                <Controller
                                    control={control}
                                    name={`list.${index}.iconColor`}
                                    render={({ field }) => <ColorControl disabled={disabled} {...field} />}
                                />
                            )}
                        </Flex>
                    </ListItem4ByecodeUi>

                    {/* 未打开时卸载富文本，节省cpu计算 */}
                    {open && (
                        <>
                            <Flex py={12} gap="12px" direction="column">
                                <div>标题</div>
                                <Controller
                                    control={control}
                                    name={`list.${index}.heading`}
                                    render={({ field }) => {
                                        const isHighLight = getIsFindUseInRichText({
                                            doc: field.value,
                                            findUseObject,
                                            currentDsId: dsId
                                        })
                                        setHighlighting(isHighLight)
                                        return (
                                            <StyledRichTextEditor
                                                style={
                                                    isHighLight
                                                        ? {
                                                              borderColor: 'var(--color-main)',
                                                              boxShadow: '0 0 4px 0 var(--color-main)',
                                                              overflow: 'hidden'
                                                          }
                                                        : undefined
                                                }
                                                disabled={disabled}
                                                config={config}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                            </Flex>
                            <Flex py={12} gap="12px" direction="column">
                                <div style={{ alignSelf: 'flex-start' }}>描述</div>
                                <Controller
                                    control={control}
                                    name={`list.${index}.introduction`}
                                    render={({ field }) => {
                                        const isHighLight = getIsFindUseInRichText({
                                            doc: field.value,
                                            findUseObject,
                                            currentDsId: dsId
                                        })
                                        setHighlighting(isHighLight)
                                        return (
                                            <StyledRichTextEditor
                                                style={
                                                    isHighLight
                                                        ? {
                                                              borderColor: 'var(--color-main)',
                                                              boxShadow: '0 0 4px 0 var(--color-main)',
                                                              overflow: 'hidden'
                                                          }
                                                        : undefined
                                                }
                                                disabled={disabled}
                                                config={config}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                            </Flex>
                        </>
                    )}

                    <Divider color="var(--color-gray-200)" my={12} />
                </div>
                <ActionAdder
                    index={`${index}`}
                    title={typeof heading === 'string' ? heading : generateText(heading, config) || ''}
                    allPages={allPages}
                    events={{
                        params: [],
                        handleEvent: 'copy',
                        triggerEvent: 'click'
                    }}
                    action={action}
                    prefixName=""
                />
            </SCxContent>
        </SCxCollapse>
    )
}
