import { Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Arrow: React.FC = () => {
    return (
        <Unit title="箭头">
            <Tooltip withArrow open title="我是tooltip">
                tooltip
            </Tooltip>
        </Unit>
    )
}
