import { Anchor, Select } from '@byecode/ui'
import type { FlowNode, NodeTypes, VariableSource } from '@lighthouse/shared'
import {
    CollapseBox,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    InnerTypeMapByFieldType,
    NodeFieldPreview,
    nodeTypeOptions,
    pureTextFieldTypes,
    useAtomData,
    USER_DATASOURCE,
    VariableSourceType,
    workflowGuidanceDocumentMap
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { equals, find, uniq } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import type { WxRobotMode } from '@/components/OtherPlatFormModal'
import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { useGetWxMessageInfo } from '@/shared/reusable'

import * as CM from '../../styles'
import { ContentSetting } from './ContentSetting'
import { MessageTargetPerson } from './MessageTargetPerson'
import * as SC from './styles'

const templateDocument = 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/sh28sm0z7ypsyps7#Naumw'
interface WxTemplateActionConfigureProps {
    prefixName?: string
    allParentNodes?: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const WxTemplateActionConfigure: React.FC<WxTemplateActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control, setValue, getValues } = useFormContext()
    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { type } = useFlow()

    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'
    const { data } = useGetWxMessageInfo(appId)

    const dataSource = useMemo(() => dataSourceList.find(ds => ds.id === dsId), [dataSourceList, dsId])

    const enablePageLinkOption = type !== 'approval' && type !== 'automation'

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const formOption = useMemo(
        () =>
            isForm && !isInFlow
                ? getFormDatasourceOptions({
                      dataSource,
                      validateFieldType: validatedField => validatedField.type !== 'notes'
                  })
                : undefined,
        [dataSource, isForm, isInFlow]
    )

    const userDataSource = useMemo(() => find(item => item.id === USER_DATASOURCE, dataSourceList), [dataSourceList])

    const templateUserOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => field.type !== 'notes'
        })
    }, [userDataSource])

    const { customViewOption } = useVariableCustomViewOption(validatedField => validatedField.type !== 'notes')

    const viewOption = useMemo(() => {
        if (isInFlow) {
            return
        }
        return (
            customViewOption ||
            getViewOptions({
                dataSource,
                viewType,
                validateFieldType: validatedField => validatedField.type !== 'notes'
            })
        )
    }, [customViewOption, dataSource, viewType, isInFlow])

    const textUserOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => pureTextFieldTypes.has(field.type)
        })
    }, [userDataSource])

    const wechatMessageTemplatesOptions = useMemo(() => {
        return (
            data?.map(item => ({
                value: item.templateId ?? '',
                label: item.title ?? '',
                content: item.content ?? ''
            })) ?? []
        )
    }, [data])

    const options = useMemo(() => {
        return getDefaultValueOptions({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    parentNodes: allParentNodes && getAllCanBeUpstreamNodes(allParentNodes),
                    dataSourceList
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateFieldType: type => type !== 'notes'
        })
    }, [isInFlow, allParentNodes, dataSourceList, isNotAutomation, prev.datasource, prev.page, curr.datasource, curr.page])

    const handleTemplateChange = useCallback(
        (value: string, onChange: (value: string) => void) => {
            const currentOption = find(option => option.value === value, wechatMessageTemplatesOptions)?.content ?? ''
            const variablesMatches = currentOption.match(/{{([\w.]+)}}/g) ?? []
            const variables = variablesMatches.map(item => {
                return item.replace(/{{(\w+)\.\w+}}/g, '$1')
            })
            const uniqVariables = uniq(variables)

            const formValues = getValues()
            const values = (formValues[prefixName]?.values ?? []) as { variableName: string; value: string }[]
            const valuesVariableNames = values.map(v => v.variableName)

            onChange(value)

            if (equals(valuesVariableNames, uniqVariables)) {
                return
            }
            const newValues = uniqVariables.map(field => {
                const value = find(v => v.variableName === field, values)?.value ?? {}
                return { variableName: field, value }
            })
            setValue(`${prefixName}.values`, newValues)
        },
        [wechatMessageTemplatesOptions, getValues, prefixName, setValue]
    )

    return (
        <>
            <CM.Container>
                <CollapseBox label="微信服务号">
                    <SC.Content>
                        <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
                    </SC.Content>
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="动作配置">
                    <SC.Content>
                        <CM.FormItem>
                            <CM.FormItemLabelWrapper>
                                <CM.FormItemLabel>类型</CM.FormItemLabel>
                            </CM.FormItemLabelWrapper>
                            <CM.FormItemContent>
                                {actionTypeSwitcher || (
                                    <Controller
                                        name="nodeType"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                disabled
                                                value={field.value}
                                                options={nodeTypeOptions}
                                                onChange={val => field.onChange?.(val as NodeTypes)}
                                            />
                                        )}
                                    />
                                )}
                            </CM.FormItemContent>
                        </CM.FormItem>
                    </SC.Content>
                </CollapseBox>

                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="发送给" required>
                    <SC.DescriptionWrapper>
                        <SC.Description>
                            请选择{' '}
                            <Link to={`/${appId}/dataSource/USER_DATASOURCE`} target="_blank">
                                用户表
                            </Link>{' '}
                            中包含“微信网页授权_OpenID”的人员或人员变量，除此之外人员要在微信关注公众号才能接收到消息。
                        </SC.Description>
                    </SC.DescriptionWrapper>
                    <SC.Content>
                        <Controller
                            control={control}
                            name={`${prefixName}.personList`}
                            render={({ field }) => (
                                <MessageTargetPerson
                                    dataSourceList={dataSourceList}
                                    parentNodes={allParentNodes}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </SC.Content>
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="消息模版" required>
                    <SC.Content>
                        <Controller
                            control={control}
                            name={`${prefixName}.templateId`}
                            render={({ field }) => (
                                <SC.SelectContainer>
                                    <Select
                                        placeholder="请选择"
                                        options={wechatMessageTemplatesOptions}
                                        value={field.value}
                                        onChange={value => handleTemplateChange(value, field.onChange)}
                                    />
                                </SC.SelectContainer>
                            )}
                        />
                        <SC.Description marginTop>
                            模板消息需在公众号中配置{' '}
                            <Anchor to={workflowGuidanceDocumentMap.WECHAT_TEMPLATE_MSG_ACTION.url} target="_blank">
                                详见
                            </Anchor>
                        </SC.Description>
                    </SC.Content>
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="模板参数" required>
                    <SC.Content>
                        <SC.Description>
                            微信针对不同类型的模板参数有相应要求，如果不符合会造成消息发送失败，具体要求{' '}
                            <Anchor to={templateDocument} target="_blank">
                                详见
                            </Anchor>
                        </SC.Description>
                        <Controller
                            control={control}
                            name={`${prefixName}.values`}
                            render={({ field }) => (
                                <ContentSetting
                                    value={field.value}
                                    options={options}
                                    userOption={templateUserOption}
                                    enablePageLink={enablePageLinkOption}
                                    viewOption={viewOption}
                                    formOption={formOption}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </SC.Content>
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="详情链接" required>
                    <SC.Content>
                        <Controller
                            control={control}
                            name={`${prefixName}.url`}
                            render={({ field }) => (
                                <NodeFieldPreview
                                    placeholder="请输入"
                                    value={field.value}
                                    onChange={field.onChange}
                                    autoHeight
                                    enablePageLink={enablePageLinkOption}
                                    options={options}
                                    viewOption={viewOption}
                                    formOption={formOption}
                                    userOption={textUserOption}
                                />
                            )}
                        />
                        {/* <SC.Description>模板消息需在公众号中配置 详见</SC.Description> */}
                    </SC.Content>
                </CollapseBox>
            </CM.Container>
        </>
    )
}
