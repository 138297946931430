import { IconFont, Popover } from '@byecode/ui'
import type { RoleOption } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import * as SC from './styles'

interface RoleSelectProps {
    children?: React.ReactNode
    id: string
    value: string
    option: RoleOption[]
    disable?: boolean
    onUpdateSelect?: (id: string, value: string) => void
}

export const RoleSelect: React.FC<RoleSelectProps> = ({ id, value, option, disable = false, onUpdateSelect }) => {
    const [open, setOpen] = useState(false)

    const text = useMemo(() => {
        if (!value) {
            return ''
        }

        return find(item => item.value === value, option)?.label
    }, [option, value])

    const handleOpenChange = useCallback(
        (v: boolean) => {
            if (disable) {
                return
            }
            setOpen(v)
        },
        [disable]
    )

    const handleSelect = useCallback(
        (value: string) => {
            setOpen(false)
            onUpdateSelect?.(id, value)
        },
        [id, onUpdateSelect]
    )

    return (
        <SC.Container>
            <Popover opened={open} onChange={handleOpenChange} width="target">
                <Popover.Target>
                    <SC.Select disable={disable}>
                        <SC.SelectText>{text}</SC.SelectText>
                        {!disable && (
                            <SC.Arrow>
                                <IconFont size={16} fill="var(--color-gray-400)" type="ArrowDownSmall" />
                            </SC.Arrow>
                        )}
                    </SC.Select>
                </Popover.Target>
                <Popover.Dropdown>
                    {option.map(item => {
                        return (
                            <SC.Option
                                key={item.value}
                                disable={item.disable}
                                onClick={() => {
                                    if (!item.disable) {
                                        handleSelect(item.value)
                                    }
                                }}
                            >
                                {/* <SC.OptionTip>{value === item.value && <SC.OptionIcon size={16} type="Tick" />}</SC.OptionTip> */}
                                <SC.OptionContent>
                                    <SC.OptionLabel color={item.color}>{item.label}</SC.OptionLabel>
                                </SC.OptionContent>
                            </SC.Option>
                        )
                    })}
                </Popover.Dropdown>
            </Popover>
        </SC.Container>
    )
}
