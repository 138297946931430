import { Empty, IconFont, Input, Menu, singleTextEllipsis } from '@byecode/ui'
import type { AppUserDepartment } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { getFlatDepartments } from '../help'
import * as CM from '../styles'
import { DepartmentSelectItem } from './DepartmentItem'
import * as SC from './styles'

interface DepartmentSelectProps {
    options?: AppUserDepartment[]
    value?: string[]
    onChange?: (value: string[]) => void
}

const SCxDropDownHeader = styled.div`
    padding: 4px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const DepartmentSelect: React.FunctionComponent<DepartmentSelectProps> = ({ options: data = [], value = [], onChange }) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')
    const [options, setOptions] = useState(data)

    const allList = useMemo(() => getFlatDepartments(options), [options])

    const tagList = useMemo(() => {
        return reduce<string, AppUserDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.departmentId === departmentId, allList)
                return option ? [...preVal, option] : preVal
            },
            [],
            value
        )
    }, [allList, value])

    const handleChange = useCallback(
        (ids: string[]) => {
            onChange?.(ids)
            setOpened(false)
        },
        [onChange]
    )

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const search = ev.target.value
            const searchList = search === '' ? data : allList.filter(item => item.departmentName?.includes(search))
            setSearch(ev.target.value)
            setOptions(searchList)
        },
        [allList, data]
    )

    return (
        <Menu opened={opened} onChange={setOpened} withinPortal>
            <Menu.Target>
                <SC.Trigger style={{ padding: 8 }}>
                    <SC.TagWrapper>
                        {tagList.map(tag => (
                            <SCxTag key={tag.departmentId} isOmit>
                                {tag.departmentName}
                            </SCxTag>
                        ))}
                        {tagList.length === 0 && <Text color="var(--color-gray-400)">请选择部门</Text>}
                    </SC.TagWrapper>
                    <SC.IconWrapper>
                        {tagList.length > 0 ? (
                            <IconFont type="CloseCircle" onClick={() => onChange?.([])} size={16} color="var(--color-gray-400)" />
                        ) : (
                            <IconFont type="ArrowDownSmall" size={16} color="var(--color-gray-400)" />
                        )}
                    </SC.IconWrapper>
                </SC.Trigger>
            </Menu.Target>
            <Menu.Dropdown>
                <SC.DropDown>
                    <SCxDropDownHeader>
                        <Input
                            styles={{
                                wrapper: {
                                    flex: 1
                                }
                            }}
                            prefix={<CM.Icon type="Search" />}
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="部门名称"
                        />
                    </SCxDropDownHeader>
                    <SC.DropDownList>
                        {options.map(option =>
                            value.includes(option.departmentId) ? null : (
                                <DepartmentSelectItem
                                    key={option.departmentId}
                                    data={option}
                                    value={value}
                                    level={0}
                                    onChange={handleChange}
                                />
                            )
                        )}
                        {options.length === 0 && <Empty icon="SpaceTeam" styles={{ root: { height: 100 } }} description="没有部门" />}
                    </SC.DropDownList>
                </SC.DropDown>
            </Menu.Dropdown>
        </Menu>
    )
}
