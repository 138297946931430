import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface TagPreviewProps {
    icon?: string
    label?: string | React.ReactNode
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SCxTag = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    gap: 4px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    background: var(--color-gray-200);
    border: 1px solid var(--color-gray-200);
    ${pointer}
`

const SCxIcon = styled(IconFont)<{ size?: number; bgcolor?: string; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: ${({ bgcolor }) => bgcolor ?? 'transparent'};
    ${pointer}
    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`

const SCxText = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    /* line-height: ${({ size }) => size ?? 14 + 2}px; */
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

const SCxLeftFill = styled.div`
    display: inline-flex;
    align-items: center;
`

export const TagPreview: React.FunctionComponent<TagPreviewProps> = ({ onClick, icon, label }) => {
    return (
        <SCxTag>
            <SCxLeftFill>
                {icon && <SCxIcon type={icon} />}
                {typeof label === 'string' ? (
                    <SCxText isOmit size={12} style={{ paddingLeft: 4 }}>
                        {label}
                    </SCxText>
                ) : (
                    label
                )}
            </SCxLeftFill>
            <SCxIcon type="Close" size={12} color="var(--color-gray-600)" onClick={onClick} />
        </SCxTag>
    )
}
