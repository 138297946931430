import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal, Modal } from '@byecode/ui'
import React from 'react'

import AliyunAccount from '../AliyunAccount'
import type { AliyunAccountFormState, AliyunAccountMode } from '../type'

interface AliyunAccountModalProps extends BaseModalProps {
    mode: AliyunAccountMode
    id?: string
}

export const AliyunCodeAccountModal: React.FunctionComponent<AliyunAccountModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <AliyunAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
