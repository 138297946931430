import { backgroundTransitionOnClick, Button, Flex, Group, IconFont, Menu, Toast } from '@byecode/ui'
import { ListItem4ByecodeUi, spaceVersionEnum, useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import copyToClipboard from 'copy-to-clipboard'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { usePageURI } from '@/hooks/usePageURI'
import * as srv from '@/services'
import type { SearchEngineType } from '@/services/types'
import { useSpaceQuota } from '@/shared/reusable'

import { SearchEngineModal, SearchEngineQueue } from '../SearchEngine'
import { openSpaceGradeConfirm, SpaceGradeTag } from '../SpaceGrade'

interface SeoSearchEngineProps {
    pageId: string
}

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    border-left: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    ${backgroundTransitionOnClick}
`
const SCxButtonContainer = styled.div`
    height: 32px;
    width: 164px;
    display: flex;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
        background-color: var(--color-gray-100);
    }
`
const SCxButton = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1;
    > * {
        line-height: 32px;
    }
    ${backgroundTransitionOnClick}
`

export const SeoSearchEngine: React.FunctionComponent<SeoSearchEngineProps> = ({ pageId }) => {
    const { shareUrl } = usePageURI()
    const { data } = useSpaceQuota()
    const [loading, setLoading] = useState(false)
    const [opened, { open, close }] = useDisclosure(false)
    const [openedSearchEngine, { open: openSearchEngine, close: closeSearchEngine }] = useDisclosure(false)

    const appSetting = useAtomData(websiteApplicationSettingAtom)

    const isConfigSearchEngine = useMemo(() => {
        if (!appSetting) {
            return false
        }
        const { seo } = appSetting
        if (!seo) {
            return false
        }
        return Object.entries(seo).some(([searchEngine, config]) => config.isOpened && config.secret && config.verificationInfo)
    }, [appSetting])

    const handleSubmitPage = useCallback(
        (type: SearchEngineType) => {
            const isCopied = copyToClipboard(shareUrl)
            if (isCopied) {
                Toast.success('复制成功')
            }
            switch (type) {
                case 'baidu': {
                    return Toast.success('已复制当前页面网址，请在百度搜索平台提交链接', {
                        linkText: '点击前往',
                        onLinkClick: () => window.open('https://ziyuan.baidu.com/linksubmit/url', '_blank')
                    })
                }

                case 'google': {
                    return Toast.success('已复制当前页面网址，请在谷歌搜索平台提交链接', {
                        linkText: '点击前往',
                        onLinkClick: () => window.open('https://search.google.com/search-console/welcome?hl=zh-cn', '_blank')
                    })
                }
                case 'bing': {
                    return Toast.success('已复制当前页面网址，请在必应搜索平台提交链接', {
                        linkText: '点击前往',
                        onLinkClick: () => window.open('https://www.bing.com/webmasters?tid=68ae62f1-b16d-4246-83d3-e4e833bdabd5', '_blank')
                    })
                }
                default: {
                    break
                }
            }
        },
        [shareUrl]
    )

    const handleAutoSubmitPage = useCallback(async () => {
        if ((data?.currentVersionCode ?? 0) < spaceVersionEnum.BASIC) {
            return openSpaceGradeConfirm(
                'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/dpcc7xurgbwmw6q7#xL1vv',
                'BASIC'
            )
        }
        if (!isConfigSearchEngine) {
            Toast.warning('使用自动提交前，需先启用搜索引擎')
            openSearchEngine()
            return
        }
        if (loading) {
            return
        }
        setLoading(true)
        const isSuccess = await srv.autoSubmission({ id: pageId, url: shareUrl })
        isSuccess && Toast.success('已添加到提交队列')
    }, [data?.currentVersionCode, isConfigSearchEngine, loading, openSearchEngine, pageId, shareUrl])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                setLoading(false)
            }
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [loading])

    const handleQueue = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()
            open()
        },
        [open]
    )

    return (
        <Group label="提交页面给搜索引擎">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>手动提交</div>
                <Menu closeOnItemClick>
                    <Menu.Target>
                        <Button disabled={false} style={{ width: 164 }}>
                            <Text style={{ marginRight: 4 }}>提交到</Text>
                            <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
                        </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            icon={<IconFont size={16} type="Baidu" color="var(--color-theme-7)" />}
                            onClick={() => handleSubmitPage('baidu')}
                        >
                            百度
                        </Menu.Item>
                        <Menu.Item
                            icon={<IconFont size={16} type="Google" color="var(--color-theme-7)" />}
                            onClick={() => handleSubmitPage('google')}
                        >
                            谷歌
                        </Menu.Item>
                        <Menu.Item
                            icon={<IconFont size={16} type="Bing" color="var(--color-theme-7)" />}
                            onClick={() => handleSubmitPage('bing')}
                        >
                            必应
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap={8}>
                    <div>自动提交</div>
                    {(data?.currentVersionCode ?? 0) < spaceVersionEnum.BASIC && <SpaceGradeTag value={spaceVersionEnum.BASIC} />}
                </Flex>
                <SCxButtonContainer className={loading ? 'disabled' : undefined}>
                    <SCxButton
                        style={{ width: 164, padding: 0, background: loading ? ' var(--color-gray-100)' : '#fff' }}
                        onClick={handleAutoSubmitPage}
                    >
                        {loading && <IconFont color="var(--color-green-500)" type="Tick" />}
                        <Text align="center">{loading ? '已添加到队列' : '添加到提交队列'}</Text>
                    </SCxButton>
                    <SCxIconWrapper onClick={handleQueue}>
                        <IconFont color="var(--color-gray-400)" size={16} type="SubmitList" />
                    </SCxIconWrapper>
                </SCxButtonContainer>
            </ListItem4ByecodeUi>
            <SearchEngineQueue open={opened} onClose={close} />
            <SearchEngineModal open={openedSearchEngine} onClose={closeSearchEngine} />
        </Group>
    )
}
