import { Button, IconFont, Input } from '@byecode/ui'
import styled from 'styled-components'

export const List = styled.div`
    width: 100%;
    margin-top: 18px;
`

export const ItemButton = styled(Button)`
    font-size: var(--font-size-normal);
`

// modal style

export const ModalContainer = styled.div``

export const Describe = styled.div`
    font-size: 14px;
    line-height: 22px;
    color: var(--color-gray-400);
    margin-bottom: 24px;
`

export const CloseIcon = styled(IconFont)`
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
`

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const PhoneInput = styled(Input)`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        margin-bottom: 4px;
    }
`

export const PasswordInput = styled(Input)`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        margin-bottom: 4px;
    }
`

export const HideSubmit = styled.input`
    display: none;
`

export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background: var(--color-gray-200);
`

export const VerificationCode = styled.a`
    color: var(--color-black);
    text-decoration: none;
`

export const VerificationCodeInputBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const VerificationCodeInput = styled(Input)`
    flex: 1;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        margin-bottom: 4px;
    }
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`

export const Ok = styled(Button)`
    background-color: var(--color-main);
    color: var(--color-white);
    height: 40px;
    font-size: 16px;
`
