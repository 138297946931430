import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'

export const INITIAL_DATASOURCE: DataSourceAbstract = {
    id: '',
    appName: '',
    appId: '',
    envId: '',
    name: '',
    type: DataSourceType.dataSource,
    sync: false,
    schema: {},
    createdBy: '',
    viewOptions: {
        tableProps: [],
        pagination: {}
    },
    records: []
}
