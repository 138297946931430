import { Button, Flex, Input, Select } from '@byecode/ui'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import * as SC from './styles'
import { Upload } from './Upload'

const FUNCTIONAL_MODULE_OPTIONS = [
    { label: '空间', value: '空间' },
    { label: '应用', value: '应用' },
    { label: '页面', value: '页面' },
    { label: '组件', value: '组件' },
    { label: '数据表', value: '数据表' },
    { label: '工作流', value: '工作流' },
    { label: '其他', value: '其他' }
]
const MAX_LENGTH = 150

interface VikaAttachment {
    id: string
    name: string
    size: number
    mimeType: string
    token: string
    width?: number
    height?: number
    url: string
}
type FeedbackFormValue = {
    用户?: string
    功能模块?: string
    您的建议?: string
    截图?: VikaAttachment[]
    如何称呼您?: string
}

export interface FeedbackFormProps {
    loading?: boolean
    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
    onSubmit?: (values: FeedbackFormValue) => void
}
export const FeedbackForm = (props: FeedbackFormProps) => {
    const { loading, onCancel, onSubmit } = props
    const { watch, control, register, handleSubmit, formState } = useForm<FeedbackFormValue>()

    const count = watch('您的建议')?.length ?? 0

    const onFinish = (values: FeedbackFormValue) => {
        onSubmit?.(values)
    }

    return (
        <SC.Form>
            <SC.Title>用户反馈</SC.Title>
            <SC.Description>
                感谢您对我们的支持和信任，您的反馈对我们来说非常重要，它帮助我们改进的产品和服务，我们将为努力您提供更好的体验！
            </SC.Description>

            <SC.Field>
                <SC.FieldLabel required>功能模块</SC.FieldLabel>
                <Controller
                    control={control}
                    name="功能模块"
                    rules={{ required: '请选择要反馈的功能模块' }}
                    render={({ field }) => <Select {...field} options={FUNCTIONAL_MODULE_OPTIONS} />}
                />
                {formState.errors['功能模块'] && <SC.FieldError>{formState.errors['功能模块'].message}</SC.FieldError>}
            </SC.Field>

            <SC.Field>
                <SC.FieldLabel required>您的建议</SC.FieldLabel>
                <SC.Textarea
                    rows={5}
                    {...register('您的建议', {
                        maxLength: { value: MAX_LENGTH, message: `最大字数不能超过${MAX_LENGTH}` },
                        required: '请输入您的建议'
                    })}
                    placeholder="请输入"
                />
                <div style={{ color: 'var(--color-gray-500)', fontSize: 12, marginTop: 6 }}>
                    {count}/{MAX_LENGTH}
                </div>
                {formState.errors['您的建议'] && <SC.FieldError>{formState.errors['您的建议'].message}</SC.FieldError>}
            </SC.Field>

            <SC.Field>
                <SC.FieldLabel>截图</SC.FieldLabel>
                <Controller
                    control={control}
                    name="截图"
                    render={({ field }) => (
                        <Upload
                            onChange={v => {
                                const formatter = v.map(item => item.uploadResponse.data.data)
                                field.onChange(formatter)
                            }}
                        />
                    )}
                />
            </SC.Field>

            <SC.Field>
                <SC.FieldLabel required>如何称呼您</SC.FieldLabel>
                <Input {...register('如何称呼您', { required: '请输入您的称呼' })} placeholder="请输入" />
                {formState.errors['如何称呼您'] && <SC.FieldError>{formState.errors['如何称呼您'].message}</SC.FieldError>}
            </SC.Field>

            <Flex justifyContent="flex-end" gap="12px" mt={88} py={20}>
                <Button size="lg" loading={loading} onClick={onCancel}>
                    取消
                </Button>
                <Button size="lg" type="primary" loading={loading} onClick={handleSubmit(onFinish)}>
                    提交意见
                </Button>
            </Flex>
        </SC.Form>
    )
}
