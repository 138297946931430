import { initBaiduTracker, Tracker } from '@lighthouse/shared'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export function initSentry() {
    const { VITE_SENTRY_ENVIRONMENT } = import.meta.env

    // const sentryEnabled = !!VITE_SENTRY_ENVIRONMENT && VITE_SENTRY_ENVIRONMENT !== 'dev'
    const enabled = VITE_SENTRY_ENVIRONMENT === 'prod'
    const sentryMaxRate = VITE_SENTRY_ENVIRONMENT === 'prod' ? 0.1 : 0.5

    // 初始化用户行为埋点 // 名杠说不需要了
    // const tracker = new Tracker()
    // tracker.init({ requestUrl: '/lighthouse/api/auth/v1/tracking' })

    // 初始化百度统计
    enabled && initBaiduTracker('077c09c5ed9bb83550dbff456531d8a4')

    Sentry.init({
        dsn: 'https://97c4b8eab7054e6f911ad6d130c7826f@op.byecode.com/3',
        integrations: [
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],
        enabled,
        environment: VITE_SENTRY_ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sentryMaxRate
    })
}
