import React from 'react'
import styled from 'styled-components'

import { useHomePageShare } from '@/shared/reusable'

import { Card } from '../Card'

interface PublishSharedPreviewProps {}

const SCxContainer = styled.div`
    padding: 16px;
    width: 360px;
`

const SCxTitle = styled.div`
    color: var(--color-black);

    /* 16/SC-Medium */
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
`

const SCxDescribe = styled.div`
    color: var(--color-gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: 8px;
    margin-bottom: 14px;
`

const SCxPreviewText = styled.div`
    color: var(--color-black);
    /* 14/SC-Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`

export const PublishSharedPreview: React.FunctionComponent<PublishSharedPreviewProps> = props => {
    const { data } = useHomePageShare()
    return (
        <SCxContainer>
            {/* <SCxTitle>分享预览</SCxTitle>
            <SCxDescribe> 更好传播 更好展示 更多转化！</SCxDescribe> */}
            <SCxPreviewText> 微信消息预览</SCxPreviewText>
            <Card data={data} theme="default" type="wxMessage" style={{ marginBottom: 10 }} />
            <SCxPreviewText> 朋友圈预览</SCxPreviewText>
            <Card data={data} theme="default" type="wxFriendCircle" />
        </SCxContainer>
    )
}
