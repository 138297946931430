import { Toast } from '@byecode/ui'
import type { BlockAbstract, DataSourceAbstract, Field, FieldBlockAbstract, PageNode } from '@lighthouse/core'
import { BlockType, DIRECTION, PageType, RecordOpenType } from '@lighthouse/core'
import {
    type Coordinates,
    type FlowLayoutNode,
    type OverDescriptor,
    detectOrderForPointerOnBlock,
    FieldTypeToInputTypeMap,
    findNodeById,
    findNodeParentById,
    findParentFormBlock,
    getCanEditFieldIds,
    transformNode2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { current, original } from 'immer'
import { mergeDeepRight, reduce } from 'rambda'
import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'throttle-debounce'
import type { MakeADTMember } from 'ts-adt/MakeADT'

import { createBlockAtom } from '@/atoms/page/action'
import { outsideDraggingNode, pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { generateFieldBlock } from '@/constants/Block/generate/field'
import { getNodeInitConfig } from '@/constants/Block/help'
import { COMMON_BLOCK_CONFIG } from '@/constants/Block/sharedStyle'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { BLOCK_NODE_CREATE_ACTION, NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { getCreateBlock } from '../PageContent/help'
import { useNodeToolbarActions } from './useNodeToolbarActions'
import { findPageNodeById, getRealParentNodeId, insertNodeByOverId, insertNodeByParentId, removeNodeById } from './utils'

/** 栅格事件 */
export const useNodeDragMonitors = () => {
    const stackId = useCurrentStackIdContext()
    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSources = useDataSourceList(appId, envId)
    const [pageDsId, pageType] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.dsId, s?.type], [])
    )

    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )

    const addingBlocksData = useAtomData(
        outsideDraggingNode,
        useCallback(s => s?.data, [])
    )

    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const { run: createBlock } = useAtomAction(createBlockAtom)

    const debounceUpdate = useMemo(
        () =>
            debounce(300, (payload: { id: string; nodes: PageNode[] }) => {
                srv.updateNodes(payload)
            }),
        []
    )

    const [activeId, setActiveId] = useState<string>()

    const onDragStart = useCallback((node: FlowLayoutNode) => {
        setActiveId(node.id)
    }, [])

    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )

    const onDragEnd = useCallback(
        (coordinates: Coordinates, active: FlowLayoutNode, over: OverDescriptor | null) => {
            if (!activeId || !over) {
                return
            }

            let newBlocks: BlockAbstract[] = []

            // 如果是从外部添加的，走创建
            if (addingBlocksData) {
                const dataSource = dataSources.find(item => item.id === pageDsId)

                // 预设block
                newBlocks = addingBlocksData.map(block => getCreateBlock(block, dataSource))
                // 如果创建的是表单容器, 需要填充字段block
                if (addingBlocksData[0].type === BlockType.formContainer && dataSource) {
                    const fieldBlocks = padBlocksByFormContainer(dataSource, dataSources)
                    newBlocks.push(...fieldBlocks)

                    // 节点填充children
                    if (active.type === 'container') {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        active.children = fieldBlocks.map(item => getNodeInitConfig(item)!)
                    }
                }
            }

            // 更新nodes
            let finalNodes: PageNode[] = []
            let originNodes: PageNode[] | undefined

            setPageNodes(draft => {
                let pageNodes = draft[pageId]
                originNodes = original(pageNodes)
                if (!pageNodes) {
                    pageNodes = []
                }
                const pageNode = addingBlocksData ? wrapperAddedPageNode(active, newBlocks) : findPageNodeById(activeId)(pageNodes)
                if (!pageNode) {
                    return
                }
                /** 子表单容器拖拽到非表单容器下 */
                const activeBlock = blocks.find(block => block.id === activeId) || addingBlocksData?.[0]
                const overBlock = blocks.find(block => block.id === over.id)
                const isNotWithParentForm =
                    activeBlock?.type === 'subForm' &&
                    !(overBlock?.type === 'formContainer' || findParentFormBlock(over.id, blocks)(pageNodes))
                if (isNotWithParentForm) {
                    return Toast.warning('请拖拽到表单容器')
                }
                const isEditPageCreateSubForm = activeBlock?.type === 'subForm' && pageType === PageType.edit
                if (isEditPageCreateSubForm) {
                    return Toast.warning('不允许拖拽至数据编辑页')
                }
                const flowNodes = transformNode2FlowLayoutNode(pageNodes, blocks, blockRuntimeState)

                switch (over.target) {
                    case 'root': {
                        removeNodeById(activeId)(pageNodes)

                        pageNodes.push(pageNode)
                        break
                    }

                    case 'node-mask': {
                        const overParentNode = findNodeParentById(over.actualId ?? over.id)(flowNodes)

                        // 水平轴，检查在左侧还是右侧
                        const isOnBeginArea =
                            over.rect &&
                            detectOrderForPointerOnBlock(overParentNode && overParentNode.data.direction, coordinates, over.rect)

                        removeNodeById(activeId)(pageNodes)
                        const checkSize =
                            !!overParentNode &&
                            (overParentNode.type === 'container' || overParentNode.type === 'custom') &&
                            overParentNode.data.direction === DIRECTION.horizontal
                        insertNodeByOverId([pageNode], over.actualId ?? over.id, isOnBeginArea, checkSize)(pageNodes)

                        break
                    }

                    case 'padding-top':
                    case 'padding-right':
                    case 'padding-bottom':
                    case 'padding-left': {
                        const isInsertBegin = over.target === 'padding-top' || over.target === 'padding-left'

                        if (over.id === 'root') {
                            removeNodeById(activeId)(pageNodes)

                            if (isInsertBegin) {
                                pageNodes.unshift(pageNode)
                            } else {
                                pageNodes.push(pageNode)
                            }
                            break
                        }

                        const parentId = getRealParentNodeId(over.actualId ?? over.id, blocks, blockRuntimeState)

                        if (!parentId) {
                            break
                        }

                        removeNodeById(activeId)(pageNodes)

                        // 落入时调整尺寸
                        const overNode = findNodeById(over.actualId ?? over.id)(flowNodes)
                        const checkSize =
                            !!overNode &&
                            (overNode.type === 'container' || overNode.type === 'custom') &&
                            overNode.data.direction === DIRECTION.horizontal

                        insertNodeByParentId([pageNode], parentId, isInsertBegin, checkSize)(pageNodes)

                        break
                    }

                    case 'gap': {
                        removeNodeById(activeId)(pageNodes)

                        // 落入时调整尺寸
                        const overParentNode = findNodeParentById(over.id)(flowNodes)
                        const checkSize =
                            !!overParentNode &&
                            (overParentNode.type === 'container' || overParentNode.type === 'custom') &&
                            overParentNode.data.direction === DIRECTION.horizontal
                        insertNodeByOverId([pageNode], over.id, false, checkSize)(pageNodes)
                        break
                    }

                    case 'placeholder-begin': {
                        const parentId = getRealParentNodeId(over.actualId ?? over.id, blocks, blockRuntimeState)

                        if (!parentId) {
                            break
                        }

                        removeNodeById(activeId)(pageNodes)

                        // 落入时调整尺寸
                        const overNode = findNodeById(over.actualId ?? over.id)(flowNodes)
                        const checkSize =
                            !!overNode &&
                            (overNode.type === 'container' || overNode.type === 'custom') &&
                            overNode.data.direction === DIRECTION.horizontal

                        insertNodeByParentId([pageNode], parentId, true, checkSize)(pageNodes)
                        break
                    }
                    case 'placeholder-after': {
                        const parentId = getRealParentNodeId(over.actualId ?? over.id, blocks, blockRuntimeState)

                        if (!parentId) {
                            break
                        }

                        removeNodeById(activeId)(pageNodes)

                        // 落入时调整尺寸
                        const overNode = findNodeById(over.actualId ?? over.id)(flowNodes)
                        const checkSize =
                            !!overNode &&
                            (overNode.type === 'container' || overNode.type === 'custom') &&
                            overNode.data.direction === DIRECTION.horizontal

                        insertNodeByParentId([pageNode], parentId, false, checkSize)(pageNodes)

                        break
                    }
                    default: {
                        break
                    }
                }
                finalNodes = current(pageNodes)
            })

            debounceUpdate({ id: pageId, nodes: finalNodes })

            if (addingBlocksData) {
                pageUndoRedoController.add({
                    action: BLOCK_NODE_CREATE_ACTION,
                    payload: {
                        blocks: newBlocks,
                        nodes: { prev: originNodes, next: finalNodes }
                    }
                })
                createBlock({ blocks: newBlocks, rootPageId, pageId, stackId })
            } else {
                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: { prev: originNodes, next: finalNodes }
                })
            }
        },
        [
            activeId,
            addingBlocksData,
            blockRuntimeState,
            blocks,
            createBlock,
            dataSources,
            debounceUpdate,
            pageDsId,
            pageId,
            rootPageId,
            setPageNodes,
            stackId
        ]
    )

    const onDragCancel = useCallback(() => {
        setActiveId(undefined)
    }, [])

    const actions = useNodeToolbarActions({ stackId, pageId, rootPageId })

    return {
        onDragStart,
        onDragEnd,
        onDragCancel,
        ...actions
    }
}

/** 新增的空白容器，回填时需要设置view */
function wrapperAddedPageNode(node: FlowLayoutNode, blocks: BlockAbstract[]): PageNode {
    function addView(node: FlowLayoutNode): PageNode {
        const { type, ...rest } = node
        const block = blocks.find(block => block.id === node.id)
        if (!block) {
            return rest
        }
        if (type === 'container' && block.type === BlockType.container) {
            const { type, data, children = [], ...rest } = node
            const viewId = block.config.viewList[0].id
            return {
                ...rest,
                children: [
                    {
                        id: viewId,
                        width: 0,
                        children: children.map(node => addView(node))
                    }
                ]
            }
        }

        return rest
    }

    return addView(node)
}

/**
 * 需要额外创建至多5个上层数据源对应的非系统表单字段
 * textGeneration、selectGenerationByText、lookup、formula除外
 * 置入创建的表单容器内
 * @param dataSource
 */
function padBlocksByFormContainer(dataSource: DataSourceAbstract, dataSourceList: DataSourceAbstract[]) {
    type AutoPadField = MakeADTMember<
        'type',
        Field,
        Exclude<
            Field['type'],
            'aggregation' | 'userGroup' | 'userDepartment' | 'id' | 'formula' | 'textGeneration' | 'selectGenerationByText' | 'lookup'
        >
    >
    // 过滤系统字段
    const noSystemFields = getCanEditFieldIds(dataSource, dataSourceList)
    // 筛选前五个字段并生成block
    const fieldBlocks = reduce<string, FieldBlockAbstract[]>(
        (pre, cur) => {
            const field = dataSource?.schema?.[cur]
            const inputType = FieldTypeToInputTypeMap[field.type]
            if (!inputType || !field) {
                return pre
            }
            const config = generateFieldBlock(inputType)
            config.fieldPointer = field.id
            config.canDownload = false
            config.canEdit = true
            config.direction = 'column'
            config.showTitle = true
            config.size = 'middle'
            config.required = false
            config.noRepeat = false
            Object.assign(config, COMMON_BLOCK_CONFIG)

            switch (config.inputType) {
                case 'date': {
                    config.placeholder = '请选择'
                    break
                }

                case 'number': {
                    config.placeholder = '请输入'
                    config.number = mergeDeepRight(config.number || {}, {
                        mode: 'number',
                        accuracy: 0,
                        prefix: '',
                        suffix: ''
                    })
                    break
                }

                case 'phoneNumber':
                case 'text':
                case 'url':
                case 'email':
                case 'file':
                case 'notes': {
                    config.placeholder = '请输入'
                    break
                }

                case 'person': {
                    config.placeholder = '请选择'
                    config.person = {
                        canMultipleChoice: false,
                        filter: {}
                    }
                    break
                }

                case 'relativeSelect': {
                    config.placeholder = '请选择'
                    config.relativeSelect = mergeDeepRight(config.relativeSelect || {}, {
                        canMultipleChoice: false,
                        canCreateRecord: false,
                        direction: DIRECTION.vertical,
                        filter: {},
                        showType: 'table',
                        sorts: [],
                        viewFieldSettings: [],
                        creatingConfig: {
                            openType: RecordOpenType.modal,
                            label: '添加',
                            page: ''
                        }
                    } satisfies typeof config.relativeSelect)
                    break
                }

                default: {
                    break
                }
            }

            return [
                ...pre,
                {
                    id: `field-${nanoid(12)}`,
                    type: 'field',
                    title: field.name,
                    config
                }
            ]
        },
        [],
        noSystemFields
    ).slice(0, 5)

    return [...fieldBlocks]
}
