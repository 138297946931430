import { USER_DATASOURCE } from '@lighthouse/shared'
import React from 'react'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { DataSetting } from './DataSetting'

interface PersonFilterProps {
    prefixName: string
}

export const PersonFilter: React.FC<PersonFilterProps> = ({ prefixName }) => {
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    // const pointer = useWatch({ control, name: 'pointer' })
    const userDataSource = useDataSource(currentAppId, envId, USER_DATASOURCE)

    return <>{userDataSource && <DataSetting prefixName={prefixName} dataSource={userDataSource} />}</>
}
