import React from 'react'
import styled from 'styled-components'

import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { useSpaceQuota } from '@/shared/reusable'

import { dosageList } from '../constants'
import { SpaceQuotaSlider } from '../Slider'
import { SliderLabel } from '../Slider/SliderLabel'

interface SpaceQuotaDosageProps {}

const SCxContainer = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
`

export const SCxTitle = styled.div`
    display: flex;
    align-items: center;
    height: 20px;
    gap: 8px;
    font-weight: 600;
`

export const SCxContent = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const SpaceQuotaDosage: React.FunctionComponent<SpaceQuotaDosageProps> = () => {
    const { data } = useSpaceQuota()
    return (
        <SCxContainer>
            <SCxTitle>用量</SCxTitle>
            <SCxContent>
                {dosageList.map(({ icon, label, toolTipLabel, id, warningColor, renderQuotaLabel, renderUsageLabel, extra }) => {
                    const { usage, quota } = data?.[`${id}`] ?? { usage: 0, quota: 0 }
                    // -1 不限制 0不支持使用
                    if (quota <= 0) {
                        return null
                    }
                    return (
                        <SpaceQuotaSlider
                            key={id}
                            data={{
                                icon,
                                label: (
                                    <SliderLabel
                                        label={label}
                                        toolTipLabel={toolTipLabel}
                                        usageLabel={renderUsageLabel(usage)}
                                        primaryColor={usage < quota ? 'var(--color-gray-600)' : 'var(--color-red-500)'}
                                        quotaLabel={renderQuotaLabel(usage, quota)}
                                        extra={extra}
                                    />
                                ),
                                usage,
                                quota,
                                primaryColor: 'var(--color-gray-500)'
                            }}
                        />
                    )
                })}
            </SCxContent>
        </SCxContainer>
    )
}
