import { IconFont, Popover } from '@byecode/ui'
import { getBlockIcon } from '@lighthouse/block'
import type { BlockAbstract } from '@lighthouse/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

type ComponentOption = {
    label: string
    value: string
    children?: ComponentOption[]
    block: BlockAbstract
    level: number
}

export interface ComponentSelectorProps {
    value: string
    onChange: (value?: string) => void
    onHover?: (value?: string) => void
    options: ComponentOption[]
}

export interface ComponentItemProps {
    value?: string
    option: ComponentOption
    onChange?: (value?: string) => void
    onHover?: (value?: string) => void
}

const SCxComponentSelectorWrapper = styled.div``

const SCxComponentSelectorPreviewer = styled.div`
    display: flex;
    height: 32px;
    align-items: center;
    padding: 0 6px 0 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxPlaceholder = styled.span`
    flex: 1;
    color: var(--color-gray-400);
`

const SCxComponentSelectorPreviewerLabel = styled.span`
    flex: 1;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-gray-800);
    font-size: var(---font-size-sm);
`

const SCxComponentSelectorItem = styled.div`
    position: relative;
    user-select: none;
`

const SCxComponentSelectorItemContent = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    cursor: pointer;
    padding: 0 16px;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxComponentSelectorItemLabel = styled.span`
    flex: 1;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-gray-800);
    font-size: var(---font-size-sm);
`

const SCxComponentSelectorItemIconWrapper = styled.div`
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
`

const SCxComponentSelectorItemGroup = styled.div`
    padding: 0 0 0 20px;
`

const ComponentItem: React.FC<ComponentItemProps> = ({ value, option, onChange, onHover }) => {
    const [open, setOpen] = useState(true)
    const ref = useRef<HTMLDivElement>(null)
    const { children, label, value: optionValue, block, level } = option
    const hasChildren = children && children.length > 0
    const isChecked = value === optionValue

    useEffect(() => {
        const mouseEnter = () => onHover?.(optionValue)
        const mouseLeave = () => onHover?.(undefined)
        const dom = ref.current
        if (dom) {
            dom.addEventListener('mouseenter', mouseEnter)
            dom.addEventListener('mouseleave', mouseLeave)
        }

        return () => {
            if (dom) {
                dom.removeEventListener('mouseenter', mouseEnter)
                dom.removeEventListener('mouseleave', mouseLeave)
            }
        }
    }, [onHover, optionValue])

    const itemContentStyle = useMemo(() => {
        return { paddingLeft: (level || 0) * 24 + 8 }
    }, [level])

    return (
        <SCxComponentSelectorItem>
            <SCxComponentSelectorItemContent style={itemContentStyle} ref={ref} onClick={() => onChange?.(optionValue)}>
                {hasChildren ? (
                    <SCxComponentSelectorItemIconWrapper
                        onClick={ev => {
                            ev.stopPropagation()
                            setOpen(o => !o)
                        }}
                    >
                        <IconFont type={open ? 'CaretDown' : 'CaretUp'} size={16} color="var(--color-gray-400)" />
                    </SCxComponentSelectorItemIconWrapper>
                ) : (
                    <SCxComponentSelectorItemIconWrapper />
                )}
                <IconFont type={getBlockIcon(block)} size={16} color="var(--color-gray-400)" style={{ marginRight: 6 }} />
                <SCxComponentSelectorItemLabel>{label}</SCxComponentSelectorItemLabel>
                {isChecked && <IconFont type="Tick" color="var(--color-main)" />}
            </SCxComponentSelectorItemContent>
        </SCxComponentSelectorItem>
    )
}

export const ComponentSelector: React.FC<ComponentSelectorProps> = ({ value, onChange, options, onHover }) => {
    const [opened, setOpened] = useState(false)
    const currentOption = useMemo(() => {
        const recursive = (options: ComponentOption[]): ComponentOption | undefined => {
            for (const option of options) {
                if (option.value === value) {
                    return option
                }

                if (option.children && option.children.length > 0) {
                    const result = recursive(option.children)

                    if (result) {
                        return result
                    }
                }
            }
        }
        return recursive(options)
    }, [options, value])

    const flattenOptions = useMemo(() => {
        // 通过层序遍历，将所有节点展开
        const stack = options.map(option => ({ ...option, level: 0 }))
        const result: ComponentOption[] = []
        while (stack.length) {
            const node = stack.shift() as ComponentOption
            result.push(node)
            if (node.children) {
                stack.unshift(...node.children.map(child => ({ ...child, level: node.level + 1 })))
            }
        }
        return result
    }, [options])

    const handleChange = (value?: string) => {
        onChange(value)
        setOpened(false)
    }
    return (
        <SCxComponentSelectorWrapper>
            <Popover opened={opened} width={240} position="bottom-end" onChange={setOpened}>
                <Popover.Target>
                    <SCxComponentSelectorPreviewer>
                        {currentOption && (
                            <IconFont
                                type={getBlockIcon(currentOption.block)}
                                size={16}
                                style={{ marginRight: 6 }}
                                color="var(--color-gray-400)"
                            />
                        )}
                        {currentOption ? (
                            <SCxComponentSelectorPreviewerLabel>{currentOption?.label}</SCxComponentSelectorPreviewerLabel>
                        ) : (
                            <SCxPlaceholder>请选择组件</SCxPlaceholder>
                        )}
                        <IconFont type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'} size={16} color="var(--color-gray-400)" />
                    </SCxComponentSelectorPreviewer>
                </Popover.Target>
                <Popover.Dropdown>
                    {flattenOptions.map(option => (
                        <ComponentItem option={option} key={option.value} value={value} onChange={handleChange} onHover={onHover} />
                    ))}
                </Popover.Dropdown>
            </Popover>
        </SCxComponentSelectorWrapper>
    )
}
