import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

export const blockPicture: BlockTreeItem = {
    name: '图片',
    icon: 'BlockImage',
    describeImage: BlockThumbImageMap['image'],
    color: '#FFB74A',
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    type: BlockType.image
}

export const blockVideo: BlockTreeItem = {
    name: '视频',
    icon: 'BlockImage',
    describeImage: BlockThumbImageMap['video'],
    backGroundColor: 'rgba(246, 61, 104, 0.10)',
    color: '#F63D68',
    type: BlockType.video
}

export const blockFile: BlockTreeItem = {
    name: '文件预览',
    icon: '',
    color: '#12AF94',
    backGroundColor: 'rgba(18, 175, 148, 0.10)',
    describeImage: BlockThumbImageMap['file'],
    type: BlockType.file
}
