import { useAtomAction } from '@lighthouse/shared'
import { useCallback } from 'react'

import { setLanguageAtom } from '@/atoms/application/action'
import { openPageStackAtom } from '@/atoms/page/action'
import { stackFactory } from '@/atoms/page/utils'

import { useCurrentAppID } from './useApplication'
import { usePageList } from './usePage'

export const useToggleLang = () => {
    const pageList = usePageList()
    const appId = useCurrentAppID()
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: setLanguage } = useAtomAction(setLanguageAtom)

    // 切换语言
    const handleToggleLang = useCallback(
        (v: string) => {
            setLanguage(v)
            const homePageId = pageList.find(page => page.isHome && page.language === v)?.id
            homePageId && openPageStack(stackFactory({ appId, pageId: homePageId }))
        },
        [appId, openPageStack, pageList, setLanguage]
    )

    return { handleToggleLang }
}
