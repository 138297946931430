import { IconFont } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import { Modal } from '@byecode/ui/components/Modal'
import { useAtomAction } from '@lighthouse/shared'
import a11yChecker from 'a11y-checker'
// import { useAccount } from '@/atoms'
import Carousel from 'nuka-carousel'
import React, { useCallback, useEffect, useState } from 'react'
import type { CallBackProps, Step, TooltipRenderProps } from 'react-joyride'
import Joyride, { STATUS } from 'react-joyride'
import { useMount, useSetState } from 'react-use'

import { updateUserAtom } from '@/atoms/auth/action'
import { isSkipWorkSpaceGuideAtom, spaceGuideStepAtom } from '@/atoms/workSpace/state'
import { useSpaceContext } from '@/contexts/SpaceContext'
import { useUser } from '@/hooks/useUser'
import * as srv from '@/services'

import { guideStepList, titleStyle } from './constant'
import { emitter } from './events'
import { GuideModal } from './Modal'
import * as SC from './styles'

interface SpaceGuideProps {}

interface State {
    run: boolean
    steps: Step[]
    open: boolean
    isLastStep: boolean
    showSkipBtn?: boolean
}

type TooltipProps = TooltipRenderProps

const steps: Step[] = Object.entries(guideStepList).map(([_, { id, placement, images, title, describe }]) => ({
    placement,
    disableBeacon: true,
    // spotlightPadding: 0,
    offset: 10,
    content: (
        <SC.Content>
            {images.length > 1 ? (
                <Carousel
                    autoplay
                    wrapAround
                    tabbed
                    autoplayInterval={2000}
                    defaultControlsConfig={{
                        nextButtonText: <IconFont size={16} type="ArrowRightSmall" color="#fff" />,
                        prevButtonText: <IconFont size={16} type="ArrowLeftSmall" color="#fff" />,
                        pagingDotsClassName: 'dot',
                        pagingDotsContainerClassName: 'dotContainer'
                    }}
                >
                    {images.map(url => (
                        <SC.PreviewImg key={url} src={url} />
                    ))}
                </Carousel>
            ) : (
                <SC.PreviewImg src={images[0]} />
            )}

            <SC.Text size={16} style={titleStyle}>
                {title}
            </SC.Text>
            <SC.Text size={14} style={{ lineHeight: '22px', padding: '0 4px' }}>
                {describe}
            </SC.Text>
        </SC.Content>
    ),
    styles: {
        options: {}
    },
    target: `#${id}`
}))

const Tooltip: React.FunctionComponent<TooltipProps> = ({ backProps, continuous, index, isLastStep, primaryProps, step, tooltipProps }) => {
    // useEffect(() => {
    //     if (isLastStep) {
    //         return
    //     }
    //     const dom = typeof step.target === 'string' ? document.querySelector(step.target) : step.target
    //     dom?.classList.add('active')
    //     return () => {
    //         dom?.classList.remove('active')
    //     }
    // }, [isLastStep, step.target])

    return (
        // <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SC.TooltipContainer {...tooltipProps}>
            {step.content}
            <SC.Footer>
                <SC.Text size={14} style={{ lineHeight: '22px' }}>
                    {index + 1}/{steps.length}
                </SC.Text>
                <SC.FooterRightFill>
                    {/* {!!index && (
                        <SC.Button backgroundColor="var(--color-main)" color="rgba(255,255,255,0.6)" {...backProps}>
                            上一步
                        </SC.Button>
                    )} */}
                    <SC.Button color="var(--color-main)" backgroundColor="#fff" {...primaryProps} title="">
                        {index === steps.length - 1 ? '完成' : '下一步'}
                    </SC.Button>
                </SC.FooterRightFill>
            </SC.Footer>
        </SC.TooltipContainer>
        // </ErrorBoundary>
    )
}

const SpaceGuide: React.FunctionComponent<SpaceGuideProps> = () => {
    const userData = useUser()
    const { run: updateUser } = useAtomAction(updateUserAtom)
    const { setPromotionOpen  } = useSpaceContext()
    // const [isRender] = useDomRender(`#${guideStepList.dataSource.id}`)
    const [isRender, setIsRender] = useState(false)
    const { run: setIsSkipWorkSpaceGuide } = useAtomAction(isSkipWorkSpaceGuideAtom)
    const { run: setSpaceGuideStep } = useAtomAction(spaceGuideStepAtom)

    const [state, setState] = useSetState<State>({
        run: false,
        steps: [],
        open: false,
        isLastStep: false,
        showSkipBtn: false
    })

    useEffect(() => {
        const timer = setInterval(() => {
            const dom = document.querySelector<HTMLDivElement>(`#${guideStepList.accountSetting.id}`)
            setIsRender(Boolean(dom))
            dom && clearInterval(timer)
        }, 1000)

        // 防止一直未拿到dom，强制清空定时器
        setTimeout(() => {
            clearInterval(timer)
        }, 5000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    const handleSkip = useCallback(async () => {
        setIsSkipWorkSpaceGuide(true)
        await srv.becomeRegularUser()
        updateUser({ ...userData, isNewUser: false })
        setState({ open: false, run: false, showSkipBtn: false })
    }, [setIsSkipWorkSpaceGuide, setState, updateUser, userData])

    const handleJoyrideCallback = useCallback(
        async (data: CallBackProps) => {
            const { status, lifecycle, index } = data
            setSpaceGuideStep(status)
            const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
            if (index === steps.length - 1) {
                setState({ isLastStep: true, showSkipBtn: false })
            }
            if (finishedStatuses.includes(status) && lifecycle === 'complete') {
                await srv.becomeRegularUser()
                updateUser({ ...userData, isNewUser: false })
                setState({ open: false, run: false, showSkipBtn: false })
                emitter.emit('createApp')
            }
        },
        [setSpaceGuideStep, setState, updateUser, userData]
    )

    useEffect(() => {
        if (userData.isNewUser && isRender && userData.profileComplete) {
            setState({
                run: true,
                open: false,
                steps,
                showSkipBtn: true
            })
            a11yChecker()
        }
    }, [isRender, setState, userData.isNewUser, userData.profileComplete])

    return (
        <SC.Container>
            <Joyride
                callback={handleJoyrideCallback}
                {...state}
                disableCloseOnEsc
                // disableOverlayClose
                // disableScrolling
                // disableScrollParentFix
                styles={{
                    options: {
                        zIndex: 10000,
                        arrowColor: 'var(--color-main)'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    tooltip: {
                        marginTop: -20,
                        background: 'red'
                    },
                    tooltipContainer: {
                        background: 'red'
                    },
                    spotlight: {
                        // borderRadius: 8
                    }
                }}
                floaterProps={{
                    offset: 2,
                    disableAnimation: true,
                    styles: {
                        floaterOpening: {
                            // marginTop: -16
                        },
                        arrow: {
                            spread: 12,
                            length: 6
                        }
                    }
                }}
                tooltipComponent={Tooltip}
            />
            {/* <GuideModal open={state.open} onStartGuide={handleStartGuide} onSkip={handleSkip} onClose={() => setState({ open: false })} /> */}
            {state.showSkipBtn && (
                <Button size="lg" style={{ width: 100, position: 'fixed', right: 24, bottom: 24, zIndex: 10101 }} onClick={handleSkip}>
                    跳过
                </Button>
            )}
        </SC.Container>
    )
}

export default SpaceGuide
