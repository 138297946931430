import type { Placement } from 'react-joyride'

export interface GuideStep {
    id: string
    images: string[]
    describe: string
    title: string
    placement: 'center' | 'auto' | Placement
}

export enum GuideStepName {
    spaceList = 'spaceList',
    spacePerson = 'spacePerson',
    spaceQuota = 'spaceQuota',
    spaceSetting = 'spaceSetting',
    accountSetting = 'accountSetting'
    // createApp = 'createApp'
}
