import { IconFont } from '@byecode/ui'
import type { FlowNodeButtonClickPayload } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import type { EdgeProps } from 'reactflow'
import { EdgeLabelRenderer, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { getConditionSourceStepPath } from '../../utils/getStepPath'

interface ConditionSourceEdgeProps extends EdgeProps {}

const foreignObjectSize = 18
const foldForeignObjectSize = 100

const SCxEdge = styled.path`
    position: absolute;
    fill: none;
    stroke: #dfdfdf;
`

const SCxEdgeAddButtonWrapper = styled.div`
    background: transparent;
    width: ${foreignObjectSize}px;
    height: ${foreignObjectSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`

const SCxEdgeAddConditionButtonWrapper = styled.div`
    background: transparent;
    width: ${foreignObjectSize}px;
    height: ${foreignObjectSize}px;
    position: absolute;
    top: 0;
    left: 40px;
    z-index: -1;
    /* opacity: 0; */
    transform: translate(0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`

const SCxEdgeButton = styled.div`
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border-radius: 4px;
    color: var(--color-white);
    background: var(--color-gray-400);
    transition: all 0.2s ease-in-out;
    &.flow-node-selected {
        background: var(--color-main);
        transform: scale(2);
    }

    &:hover {
        background: var(--color-main);
        transform: scale(2);
    }
`

const SCxAddIcon = styled(IconFont)``

// const SCxEdgeFoldButton = styled.div`
//     width: 26px;
//     height: 26px;
//     color: #383838;
//     background: #3f65ed;
//     border-radius: 4px;
//     cursor: pointer;
//     font-size: 16px;
//     transform: rotate(-45deg);

//     &:hover {
//         box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
//     }
// `

const SCxActionWrapper = styled.div`
    position: absolute;
    pointer-events: all;
    top: 0;
    /* &:hover {
    ${SCxEdgeAddConditionButtonWrapper} {
      opacity: 1;
      transform: translate(46px, 0);
      transition: opacity, transform 0.2s ease;
    }
  } */
`

const ConditionSourceEdge: React.FC<ConditionSourceEdgeProps> = ({
    id,
    selected,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style = {},
    markerEnd,
    data
}) => {
    const [operator, setOperator] = useState<FlowNodeButtonClickPayload['operator'] | null>()
    const { readonly, onEdgeButtonClick } = useFlow()
    const disabledBtn = useIsDisabledWithVersion() || readonly
    const { getEdge } = useReactFlow()
    const { path, roundX, roundY } = getConditionSourceStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        roundDistance: 40
    })

    const edgeStyles = useMemo<React.CSSProperties>(() => {
        const isSuccess = data?.status?.status === 'SUCCESS'
        const defaultStyle = style ?? {}
        return readonly
            ? {
                  ...defaultStyle,
                  pointerEvents: 'none',
                  stroke: isSuccess ? 'var(--color-green-500)' : 'var(--color-gray-400)',
                  strokeWidth: isSuccess ? 2 : 1,
                  strokeDasharray: isSuccess ? 0 : 4,
                  zIndex: isSuccess ? 1 : 0
              }
            : { ...defaultStyle, stroke: 'var(--color-gray-200)' }
    }, [data?.status?.status, readonly, style])

    const handleAddNewNodeBeforeCondition = useCallback(() => {
        setOperator('addBeforeCondition')
        const edge = getEdge(id)
        edge && onEdgeButtonClick?.({ edge, operator: 'addBeforeCondition' })
    }, [getEdge, id, onEdgeButtonClick])

    const handleAddNewConditionNode = useCallback(() => {
        setOperator(null)
        const edge = getEdge(id)
        edge && onEdgeButtonClick?.({ edge, operator: 'addCondition' })
    }, [getEdge, id, onEdgeButtonClick])

    return (
        <>
            <SCxEdge id={id} style={edgeStyles} d={path} markerEnd={markerEnd} />
            {!disabledBtn && (
                <>
                    <EdgeLabelRenderer>
                        <SCxActionWrapper
                            style={{
                                width: foreignObjectSize,
                                height: foreignObjectSize,
                                transform: `translate(-50%, -50%) translate(${sourceX}px,${sourceY + 40}px)`
                            }}
                        >
                            <SCxEdgeAddButtonWrapper>
                                <SCxEdgeButton
                                    onClick={handleAddNewNodeBeforeCondition}
                                    className={cls({ 'flow-node-selected': selected && operator === 'addBeforeCondition' })}
                                >
                                    <SCxAddIcon size={8} type="Add" />
                                </SCxEdgeButton>
                            </SCxEdgeAddButtonWrapper>
                        </SCxActionWrapper>
                    </EdgeLabelRenderer>
                    <EdgeLabelRenderer>
                        <SCxActionWrapper
                            style={{
                                width: foldForeignObjectSize,
                                height: foreignObjectSize,
                                transform: `translate(${roundX - foreignObjectSize / 2}px,${roundY - foreignObjectSize / 2}px)`
                            }}
                        >
                            {/* <SCxEdgeFoldButtonWrapper>
                        <SCxEdgeFoldButton></SCxEdgeFoldButton>
                    </SCxEdgeFoldButtonWrapper> */}
                            <SCxEdgeAddConditionButtonWrapper>
                                <SCxEdgeButton onClick={handleAddNewConditionNode}>
                                    <SCxAddIcon size={8} type="Add" />
                                </SCxEdgeButton>
                            </SCxEdgeAddConditionButtonWrapper>
                        </SCxActionWrapper>
                    </EdgeLabelRenderer>
                </>
            )}
        </>
    )
}

export default ConditionSourceEdge
