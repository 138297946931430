import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const StyledIconWrapper = styled.div<{ active?: boolean; activeColor?: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${p => (p.active ? p.activeColor ?? 'var(--color-main-tint)' : 'var(--color-gray-100)')};
    border-radius: 8px;
    width: 32px;
    height: 32px;
`

export const StyledInnerBox = styled.div`
    padding: 14px 12px;
    margin: 8px 10px 0 10px;

    background-color: var(--color-gray-50);
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
`

export const StyledConfiguration = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--color-gray-500);

    ${pointer}
`

export const StyledConfigurationIcon = styled(IconFont)`
    margin-right: 4px;
`

export const StyledItemTitle = styled.div`
    margin-bottom: 4px;
    font-weight: var(--font-weight-bold);
`

export const StyledActionLabel = styled.div`
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-sm);
    margin-top: 2;
`

// AllowExportSetting start
export const ExportListCardWrapper = styled.div`
    margin: 12px 0;
`

export const ExportListCard = styled.div`
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    padding: 12px 0 0 0;
    background: var(--ffffff, #fff);
    overflow: hidden;
    margin-bottom: 12px;
`

export const ExportListCardInfo = styled.div`
    padding: 0 16px;
    margin-bottom: 16px;
`

export const ExportListCardTitle = styled.div`
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-little-bold);
    margin-bottom: 12px;
`

export const ExportListCardDesc = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
`

export const ExportListCardActionWrapper = styled.div`
    padding: 8px 12px;
    background-color: var(--color-gray-100);
`
// AllowExportSetting end
export const StyledOptionButton = styled.button`
    all: unset;
    padding: 4px;
    border-radius: 5px;
    color: var(--color-gray-400);
    &:hover {
        color: var(--color-gray-900);
        background-color: var(--color-gray-200);
    }
`

export const ImportTriggerWorkflowTipWrapper = styled.div`
    padding: 12px;
    background-color: var(--color-gray-100);
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
    line-height: 22px;
    border-radius: 6px;
`
