import type { PageNode } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import type { ResizeEventsOptions } from '@lighthouse/shared'
import {
    findNodeById,
    findNodeParentById,
    FLOW_LAYOUT_NODE_HEIGHT,
    FLOW_LAYOUT_NODE_ROWS,
    transformNode2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import { current } from 'immer'
import { clamp } from 'rambda'
import { useCallback, useMemo, useRef } from 'react'
import { debounce } from 'throttle-debounce'

import { pageBlocksAtom, pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { findPageNodeById, findParentPageNodeById, getContainerViewNodes } from './utils'

export const useNodeResizeMonitors = () => {
    const stackId = useCurrentStackIdContext()
    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()

    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )

    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )

    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const debounceUpdate = useMemo(
        () =>
            debounce(300, (payload: { id: string; nodes: PageNode[] }) => {
                srv.updateNodes(payload)
            }),
        []
    )

    const resizingInitialCoordinatesRef = useRef(0)
    const pageNodesRef = useRef<{ prev: PageNode[]; next: PageNode[] }>({ prev: [], next: [] })

    const onResizeStart = useCallback(
        (event: ResizeEventsOptions) => {
            resizingInitialCoordinatesRef.current = event.direction === DIRECTION.horizontal ? event.coordinates.x : event.coordinates.y
            pageNodesRef.current.prev = nodes
        },
        [nodes]
    )

    const onResizing = useCallback(
        (event: ResizeEventsOptions) => {
            const { direction, coordinates, nodeId, nodeUnitWidth } = event
            const initial = resizingInitialCoordinatesRef.current
            const isResizingHorizontal = direction === DIRECTION.horizontal
            const diff = isResizingHorizontal
                ? Math.round((coordinates.x - initial) / nodeUnitWidth)
                : Math.round((coordinates.y - initial) / FLOW_LAYOUT_NODE_HEIGHT)

            setPageNodes(draft => {
                const pageNodes = draft[pageId] || []
                const flowNodes = transformNode2FlowLayoutNode(pageNodes, blocks, blockRuntimeState)

                const flowNode = findNodeById(nodeId)(flowNodes)
                const draftNode = findPageNodeById(nodeId)(pageNodes)
                if (!flowNode || !draftNode) {
                    return
                }
                const parent = findNodeParentById(nodeId)(flowNodes)
                const layoutChildren = parent ? parent.children || [] : flowNodes
                const currentLayoutIndex = layoutChildren.findIndex(item => item.id === flowNode.id)

                const restrictDiff = isResizingHorizontal
                    ? clamp(
                          (flowNode.minWidth ?? 1) - flowNode.width,
                          Math.min(
                              flowNode.maxWidth ?? FLOW_LAYOUT_NODE_ROWS,
                              parent?.data.direction === DIRECTION.horizontal
                                  ? FLOW_LAYOUT_NODE_ROWS -
                                        layoutChildren.reduce((total, current, index) => {
                                            if (index < currentLayoutIndex) {
                                                return total + current.width
                                            }
                                            if (index === currentLayoutIndex) {
                                                return total
                                            }
                                            return total + (current.minWidth ?? 1)
                                        }, 0)
                                  : FLOW_LAYOUT_NODE_ROWS
                          ) - flowNode.width,
                          diff
                      )
                    : flowNode.height
                    ? clamp(
                          (flowNode.minHeight ?? 1) - flowNode.height,
                          flowNode.maxHeight ? flowNode.maxHeight - flowNode.height : Infinity,
                          diff
                      )
                    : 0

                if (restrictDiff === 0) {
                    return
                }

                if (isResizingHorizontal) {
                    draftNode.width += restrictDiff
                } else {
                    if (draftNode.height) {
                        draftNode.height += restrictDiff
                    }
                }

                // 如果超出尺寸，依次往后减小
                if (parent?.data.direction === DIRECTION.horizontal) {
                    const parentBlock = parent && blocks.find(item => item.id === parent.id)
                    const parentPageNode = findParentPageNodeById(nodeId)(pageNodes, blocks)

                    const pageNodeChildren = parentPageNode
                        ? parentBlock
                            ? parentBlock.type === BlockType.container
                                ? getContainerViewNodes(parentPageNode, blockRuntimeState)
                                : parentPageNode.children || []
                            : []
                        : pageNodes
                    const { length } = pageNodeChildren

                    let nextIndex = currentLayoutIndex + 1
                    while (pageNodeChildren.reduce((total, current) => total + current.width, 0) > FLOW_LAYOUT_NODE_ROWS) {
                        const next = pageNodeChildren[nextIndex]
                        if (next.width > (next.minWidth ?? 1)) {
                            pageNodeChildren[nextIndex].width--
                            continue
                        }

                        if (nextIndex === length - 1) {
                            break
                        }
                        nextIndex++
                    }
                }

                resizingInitialCoordinatesRef.current += isResizingHorizontal
                    ? nodeUnitWidth * restrictDiff
                    : FLOW_LAYOUT_NODE_HEIGHT * restrictDiff
                debounceUpdate({ id: pageId, nodes: current(pageNodes) })
                pageNodesRef.current.next = current(pageNodes)
            })
        },
        [blockRuntimeState, blocks, debounceUpdate, pageId, setPageNodes]
    )

    const onResizeEnd = useCallback(() => {
        resizingInitialCoordinatesRef.current = 0
        if (equal(pageNodesRef.current.next, pageNodesRef.current.prev)) {
            return
        }
        pageUndoRedoController.add({
            action: NODE_UPDATE_ACTION,
            payload: { prev: pageNodesRef.current.prev, next: pageNodesRef.current.next }
        })
    }, [])

    const onFillSize = useCallback(
        (nodeId: string) => {
            pageNodesRef.current.prev = nodes
            setPageNodes(draft => {
                const pageNodes = draft[pageId] || []
                const flowNodes = transformNode2FlowLayoutNode(pageNodes, blocks, blockRuntimeState)

                const flowNode = findNodeById(nodeId)(flowNodes)
                const draftNode = findPageNodeById(nodeId)(pageNodes)
                if (!flowNode || !draftNode) {
                    return
                }
                const parent = findNodeParentById(nodeId)(flowNodes)
                const parentIsHorizontal = !!parent && parent.data.direction === DIRECTION.horizontal

                const layoutChildren = parent ? parent.children || [] : flowNodes
                const restWidth = parentIsHorizontal
                    ? FLOW_LAYOUT_NODE_ROWS - layoutChildren.reduce((total, current) => total + current.width, 0)
                    : FLOW_LAYOUT_NODE_ROWS - flowNode.width
                if (restWidth <= 0) {
                    return
                }

                draftNode.width = Math.min(draftNode.width + restWidth, draftNode.maxWidth ?? FLOW_LAYOUT_NODE_ROWS)
                pageNodesRef.current.next = current(pageNodes)
            })

            if (equal(pageNodesRef.current.next, pageNodesRef.current.prev)) {
                return
            }
            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: { prev: pageNodesRef.current.prev, next: pageNodesRef.current.next }
            })
        },
        [blockRuntimeState, blocks, nodes, pageId, setPageNodes]
    )

    return {
        onResizeStart,
        onResizing,
        onResizeEnd,
        onFillSize
    }
}
