import { IconFont, Popover, Toast, Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { DataSourceType } from '@lighthouse/core'
import {
    type AppendParams,
    type DataSourceAction,
    type ImportParams,
    type MenuItem,
    type SynchronizeParams,
    ImportModal
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useState } from 'react'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'
import { useSpaceQuota } from '@/shared/reusable'

import { DataSourceSynchronizeModal } from '../DataSourceSynchronize'
import * as SC from './styles'

interface DataSourceAdderProps {
    onCreate?: (type: DataSourceType) => Promise<boolean>
    onImport?: (params: ImportParams) => Promise<boolean>
    onAppend?: (params: AppendParams) => void
    onAsyncSynchronize?: (params: SynchronizeParams) => void
    onCreateAggregationDataSource?: () => void
}

const SyncDataSourceDescribe: React.FC = () => {
    const imgUrl = getAssetUrl('common', 'Illustration_SyncData.svg')
    return (
        <SC.DescribeContent>
            <SC.DescribeImg alt="Illustration_SyncData" src={imgUrl} />

            <SC.DescribeText>
                <p>你可以选择从其他应用中同步数据表，同步和被同步的表，会数据保持同步。</p>
                <p>注意：同步过来的表会保留原表的公式和引用字段，但不能继续创建公式和引用字段，并且不能被其他表公式和引用字段所使用。</p>
            </SC.DescribeText>
        </SC.DescribeContent>
    )
}

const DataSourceAdder: React.FC<DataSourceAdderProps> = ({
    onCreate,
    onImport,
    onAppend,
    onAsyncSynchronize,
    onCreateAggregationDataSource
}) => {
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const [importOpen, setImportOpen] = useState(false)
    // const [joinOpen, setJoinOpen] = useState(false)
    const [synchronizeOpen, setSynchronizeOpen] = useState(false)
    const [open, setOpen] = useState(false)
    // const addPopupState = usePopupState({ variant: 'popover', popupId: 'addPopupState' })
    const { data: spaceGrade } = useSpaceQuota()

    const menuItems: MenuItem<DataSourceAction>[] = [
        {
            section: 'datasource',
            items: [
                {
                    name: '新建数据表',
                    icon: 'NewTable',
                    hotkey: '',
                    action: 'CREATE'
                    // extra: (
                    //     <SC.Tag color="#12B76A" background="#D1FADF">
                    //         新
                    //     </SC.Tag>
                    // )
                },
                // {
                //     name: '新建旧数据表',
                //     icon: 'BlockTable',
                //     hotkey: '',
                //     action: 'CREATE_OLD',
                //     extra: (
                //         <SC.Tag color="#667085" background="#E4E7EC">
                //             旧
                //         </SC.Tag>
                //     )
                // },
                {
                    name: '聚合表',
                    icon: 'AggregateTable',
                    hotkey: '',
                    action: 'CREATE_AGGREGATE'
                }
                // {
                //     name: '新建连接表',
                //     icon: 'DataSource',
                //     hotkey: '',
                //     action: 'CREATE_JOIN',
                //     extra: (spaceGrade?.currentVersionCode ?? 0) < 3 ? <SpaceGradeTag value="ENTERPRISE" /> : null
                //     //  huge
                // }
            ]
        },
        {
            section: 'import',
            name: '导入',
            items: [
                {
                    name: '导入 EXCEL',
                    icon: 'UploadSimple',
                    hotkey: '',
                    action: 'IMPORT'
                }
            ]
        },
        {
            section: 'sync',
            name: '同步',
            items: [
                {
                    name: '从其他应用同步',
                    icon: 'SyncTable',
                    hotkey: '',
                    action: 'SYNCHRONIZE',
                    extra: (
                        <Tooltip
                            title={<SyncDataSourceDescribe />}
                            placement="right"
                            styles={{ tooltip: { background: 'var(--color-white)', padding: 0 } }}
                        >
                            <SC.ListItemExtra>
                                <IconFont type="QuestionMark" size={10} color="var(--color-white)" />
                            </SC.ListItemExtra>
                        </Tooltip>
                    )
                }
            ]
        }
    ]

    const handlers = useCallback(
        (dsId: string): Record<DataSourceAction, (sender?: React.MouseEvent<HTMLDivElement>) => void> => {
            return {
                // CREATE_BIG() {
                //     setOpen(false)
                //     if ((spaceGrade?.currentVersionCode ?? 0) < 3) {
                //         openSpaceGradeConfirm(
                //             'https://docs.byecode.com/docs/app/datasheet/datasheet.html#%E5%A4%A7%E6%95%B0%E6%8D%AE%E8%A1%A8',
                //             'ENTERPRISE'
                //         )
                //         return
                //     }
                //     onCreate?.(DataSourceType.bigDataSource)
                // },
                CREATE() {
                    setOpen(false)
                    onCreate?.(DataSourceType.dataSource)
                },
                IMPORT() {
                    setImportOpen(true)
                    setOpen(false)
                },
                SYNCHRONIZE() {
                    setSynchronizeOpen(true)
                    setOpen(false)
                },
                CREATE_AGGREGATE() {
                    setOpen(false)
                    onCreateAggregationDataSource?.()
                }
            }
        },
        [onCreate, onCreateAggregationDataSource]
    )

    const handleImport = useCallback(
        async (importParams: ImportParams) => {
            const isImported = await onImport?.(importParams)
            if (isImported) {
                Toast.success('导入数据成功！')
            }
            setImportOpen(false)
        },
        [onImport]
    )

    const handleAppend = useCallback(
        (params: AppendParams) => {
            setImportOpen(false)
            onAppend?.(params)
        },
        [onAppend]
    )

    const handleSynchronize = useCallback(
        (params: SynchronizeParams) => {
            setSynchronizeOpen(false)
            onAsyncSynchronize?.(params)
        },
        [onAsyncSynchronize]
    )

    const handleImportClose = useCallback(() => {
        setImportOpen(false)
    }, [])

    const handleSynchronizeClose = useCallback(() => {
        setSynchronizeOpen(false)
    }, [])

    return (
        <Popover opened={open} onChange={setOpen} position="top-start" width={240} trigger="hover">
            <Popover.Target>
                <SC.AdderButton
                    className={cls({ active: open })}
                    type="Add"
                    // {...bindHover(addPopupState)}
                    size={16}
                    // className={cls({ active: addPopupState.isOpen })}
                />
            </Popover.Target>
            <Popover.Dropdown
            // {...bindPopover(addPopupState)}
            // anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'left'
            // }}
            // transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left'
            // }}
            >
                <SC.ListContain>
                    {menuItems.map(({ section, items, name: groupName }, index) => (
                        <SC.ListItem key={section}>
                            <SC.ListGroup>
                                {/* {groupName && <SC.GroupName>{groupName}</SC.GroupName>} */}
                                {items.map(({ name, icon, hotkey, action, extra }) => (
                                    <SC.MenuItem key={`${name}${action}`} onClick={sender => handlers('')[action](sender)}>
                                        <SC.MenuItemContent>
                                            <SC.MenuItemContentInfo>
                                                <IconFont size={20} type={icon} color="var(--color-gray-400)" />
                                                <SC.Name>{name}</SC.Name>
                                            </SC.MenuItemContentInfo>
                                            {extra || ''}
                                        </SC.MenuItemContent>
                                    </SC.MenuItem>
                                ))}
                            </SC.ListGroup>
                            {index + 1 !== menuItems.length && <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />}
                        </SC.ListItem>
                    ))}
                </SC.ListContain>
            </Popover.Dropdown>
            <ImportModal
                appId={appId}
                envId={envId}
                dataSourceList={dataSourceList}
                open={importOpen}
                onImport={handleImport}
                onAppend={handleAppend}
                onClose={handleImportClose}
            />
            {synchronizeOpen && (
                <DataSourceSynchronizeModal open={synchronizeOpen} onClose={handleSynchronizeClose} onSynchronize={handleSynchronize} />
            )}
        </Popover>
    )
}

export default DataSourceAdder
