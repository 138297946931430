import { Button, Empty, IconFont, Image, Popover } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useMemo } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'

import { OrderListItem } from './OrderListItem'

const SCxBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`

const SCxHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const SCxList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    overflow-y: auto;
`

const SCxImg = styled.img`
    width: 120px;
`

const customerImage = getAssetUrl('space', 'customer_wechat.png')
const noOrder = getAssetUrl('space', 'no_order.png')

interface OrderMangerProps {}

export const OrderManger: React.FC<OrderMangerProps> = () => {
    const spaceId = useCurrentSpaceID()
    const { value } = useAsyncRetry(() => srv.getOrders(spaceId), [spaceId])

    const content = useMemo(() => {
        if (!value || value?.length === 0) {
            return <Empty description="暂无订单" icon={<SCxImg src={noOrder} alt="noOrder" />} />
        }
        return (
            <SCxContainer>
                <SCxHeader>
                    <Popover width="auto" trigger="hover" position="bottom-start" withinPortal>
                        <Popover.Target>
                            <Button
                                type="text"
                                style={{
                                    color: 'var(--color-main)',
                                    marginBottom: '16px'
                                }}
                                icon={<IconFont type="Service" size={16} fill="var(--color-main)" />}
                            >
                                联系客服
                            </Button>
                        </Popover.Target>
                        <Popover.Dropdown style={{ padding: 0 }}>
                            <Image width={200} height={200} style={{ borderRadius: '12px' }} src={customerImage} />
                        </Popover.Dropdown>
                    </Popover>
                </SCxHeader>
                <SCxList>
                    {value?.map(order => (
                        <OrderListItem key={order.outTradeNo} data={order} />
                    ))}
                    {/* <OrderListItem />
                <OrderListItem />
                <OrderListItem />
                <OrderListItem />
                <OrderListItem />
                <OrderListItem /> */}
                </SCxList>
            </SCxContainer>
        )
    }, [value])

    return <SCxBody>{content}</SCxBody>
}
