import type { NotificationMessageData } from '@lighthouse/shared'
import { useMessage } from '@lighthouse/shared'
import React, { useEffect } from 'react'

import { useSetAiFieldStatus } from '@/hooks/useSetAiFieldStatus'

interface ApplicationMessageProps {
    appId: string
}

export const ApplicationMessage: React.FC<ApplicationMessageProps> = ({ appId }) => {
    const { lastMessage, getWebSocket } = useMessage<NotificationMessageData>(appId, true, 'dummyUniqueUserId')
    useSetAiFieldStatus(lastMessage)

    useEffect(() => {
        return () => {
            getWebSocket()?.close()
        }
    }, [getWebSocket])

    return <></>
}
