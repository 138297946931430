import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import produce from 'immer'
import { useCallback } from 'react'

import { updateAppVersionConfigAtom } from '@/atoms/application/action'
import { applicationSettingAtom } from '@/atoms/application/state'

/** 样式选择器 需要的方法 */
export const useColorSystemAction = () => {
    const { run: updateAppVersion } = useAtomAction(updateAppVersionConfigAtom)
    const theme = useAtomData(
        applicationSettingAtom,
        useCallback(s => s.theme, [])
    )

    const onUpdateColor = useCallback(
        (value: ApplicationSettingThemeColor) => {
            updateAppVersion({
                config: {
                    theme: {
                        ...theme,
                        palettes: produce(theme.palettes, draft => {
                            const index = draft.findIndex(item => item.id === value.id)
                            if (index === -1) {
                                return
                            }
                            draft[index] = value
                        })
                    }
                }
            })
        },
        [theme, updateAppVersion]
    )

    const onCreateColor = useCallback(
        (value: ApplicationSettingThemeColor) => {
            updateAppVersion({
                config: {
                    theme: {
                        ...theme,
                        palettes: [...theme.palettes, value]
                    }
                }
            })
        },
        [theme, updateAppVersion]
    )

    return {
        onUpdateColor,
        onCreateColor
    }
}
