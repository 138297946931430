import { Button } from '@byecode/ui'
import type {
    Conditions,
    DataSourceAbstract,
    Field,
    FieldBlockWithDsId,
    FieldType,
    FilterBlockAbstract,
    FilterGroupType,
    VariableADTField,
    ViewField,
    ViewType
} from '@lighthouse/core'
import { FilterMode, VariableType } from '@lighthouse/core'
import cls from 'classnames'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'

import type { VariableSource } from '../../types'
import { getDefaultOperator, getRealField } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '..'
import { MorePopover } from '../Condition'
import FilterCondition from './FilterCondition'
import * as SC from './styles'
import { WhereSelect } from './WhereSelect'

export interface FilterGroupProps {
    dataSource?: DataSourceAbstract
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList: DataSourceAbstract[]
    sources?: VariableSource[]
    columns: ViewField[]
    primaryField?: string
    children?: React.ReactNode
    level?: number
    prefixName: string
    noSettingFields?: Set<FieldType>
    fieldMode?: FilterMode
    paramsMode?: FilterMode
    formDsId?: string
    enableCurrentUser?: boolean
    viewDsId?: string
    viewType?: ViewType
    filterBlocks?: FilterBlockAbstract[]
    enableFilterVariable?: boolean
    disabled?: boolean
    onDuplicate?: () => void
    onDelete?: () => void
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

export const FilterGroup: React.FC<FilterGroupProps> = ({
    dataSource,
    fieldBlocksWithDsId,
    dataSourceList,
    sources,
    columns,
    primaryField,
    prefixName,
    level = 0,
    noSettingFields,
    fieldMode = FilterMode.NORMAL,
    paramsMode,
    formDsId,
    enableCurrentUser,
    viewDsId,
    viewType,
    filterBlocks,
    enableFilterVariable,
    disabled,
    onDuplicate,
    onDelete,
    onMouseEnter,
    onMouseLeave
}) => {
    const { control, getValues } = useFormContext()
    const { fields, append, insert, remove } = useFieldArray({
        control,
        name: `${prefixName}.conditions`
    })
    const where = useWatch({ control, name: `${prefixName}.where` })
    const conditions: Conditions = useWatch({ control, name: `${prefixName}.conditions` })
    const isTopLevel = level > 0
    const isRealFilterGroup = !!where && isTopLevel
    const isMoreThanOneCondition = fields.length > 1

    const isNoneConditions = !conditions || conditions.length === 0

    const initIdVariable: VariableADTField = useMemo(
        () =>
            fieldMode === FilterMode.NORMAL
                ? {
                      type: VariableType.FIELD_ID,
                      fieldIdVariable: { fieldId: primaryField }
                  }
                : {
                      type: VariableType.VALUE
                  },
        [fieldMode, primaryField]
    )

    const handleAdd = useCallback(() => {
        const viewField = find(item => item.fieldId === primaryField, columns)
        if (!viewField) {
            append({ idVariable: initIdVariable, operator: '', paramList: [DEFAULT_FILTER_VALUE_VARIABLE] })
            return
        }
        const field = { ...viewField, id: viewField.fieldId, name: viewField.title } as Field
        const realField = getRealField(field)
        const operator = getDefaultOperator(realField)

        append({ idVariable: initIdVariable, operator, paramList: [DEFAULT_FILTER_VALUE_VARIABLE] })
    }, [append, columns, initIdVariable, primaryField])

    const handleAddGroup = useCallback(() => {
        const viewField = find(item => item.fieldId === primaryField, columns)
        if (!viewField) {
            append({
                where: 'OR',
                conditions: [{ idVariable: initIdVariable, operator: '', paramList: [DEFAULT_FILTER_VALUE_VARIABLE] }]
            })
            return
        }
        const field = { ...viewField, id: viewField.fieldId, name: viewField.title } as Field
        const realField = getRealField(field)
        const operator = getDefaultOperator(realField)

        append({
            where: 'OR',
            conditions: [{ idVariable: initIdVariable, operator, paramList: [DEFAULT_FILTER_VALUE_VARIABLE] }]
        })
    }, [append, columns, initIdVariable, primaryField])

    const handleDuplicateGroup = useCallback(
        (index: number) => {
            const duplicatedData = getValues(`${prefixName}.conditions[${index}]`)
            insert(index, duplicatedData)
        },
        [getValues, insert, prefixName]
    )

    const handleDeleteGroup = useCallback(
        (index: number) => {
            remove(index)
        },
        [remove]
    )

    const filterList = useMemo(
        () =>
            fields.map((field, index) => (
                <SC.ListItem key={field.id}>
                    {(field as FilterGroupType)?.where ? (
                        <FilterGroup
                            sources={sources}
                            prefixName={`${prefixName}.conditions[${index}]`}
                            dataSource={dataSource}
                            fieldBlocksWithDsId={fieldBlocksWithDsId}
                            dataSourceList={dataSourceList}
                            columns={columns}
                            primaryField={primaryField}
                            fieldMode={fieldMode}
                            paramsMode={paramsMode}
                            level={level + 1}
                            enableCurrentUser={enableCurrentUser}
                            viewDsId={viewDsId}
                            viewType={viewType}
                            filterBlocks={filterBlocks}
                            enableFilterVariable={enableFilterVariable}
                            disabled={disabled}
                            onDuplicate={() => {
                                handleDuplicateGroup(index)
                            }}
                            onDelete={() => {
                                handleDeleteGroup(index)
                            }}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        />
                    ) : (
                        <FilterCondition
                            sources={sources}
                            dataSource={dataSource}
                            fieldBlocksWithDsId={fieldBlocksWithDsId}
                            dataSourceList={dataSourceList}
                            columns={columns}
                            prefixName={`${prefixName}.conditions[${index}]`}
                            noSettingFields={noSettingFields}
                            fieldMode={fieldMode}
                            paramsMode={paramsMode}
                            enableCurrentUser={enableCurrentUser}
                            formDsId={formDsId}
                            viewDsId={viewDsId}
                            viewType={viewType}
                            filterBlocks={filterBlocks}
                            enableFilterVariable={enableFilterVariable}
                            disabled={disabled}
                            onDuplicate={() => {
                                handleDuplicateGroup(index)
                            }}
                            onDelete={() => {
                                handleDeleteGroup(index)
                            }}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                        />
                    )}
                </SC.ListItem>
            )),
        [
            columns,
            dataSource,
            dataSourceList,
            disabled,
            enableCurrentUser,
            enableFilterVariable,
            fieldBlocksWithDsId,
            fieldMode,
            fields,
            filterBlocks,
            formDsId,
            handleDeleteGroup,
            handleDuplicateGroup,
            level,
            noSettingFields,
            onMouseEnter,
            onMouseLeave,
            paramsMode,
            prefixName,
            primaryField,
            sources,
            viewDsId,
            viewType
        ]
    )

    const filterListContent = useMemo(() => {
        return (
            <SC.FilterGroup>
                {isMoreThanOneCondition && (
                    <Controller
                        name={`${prefixName}.where`}
                        control={control}
                        render={({ field: ctlField }) => <WhereSelect value={ctlField.value} onChange={ctlField.onChange} />}
                    />
                )}
                <SC.FilterGroupItemWrapper>{filterList}</SC.FilterGroupItemWrapper>
            </SC.FilterGroup>
        )
    }, [control, filterList, isMoreThanOneCondition, prefixName])

    // const filterEmpty = useMemo(() => {
    //     return <SC.Empty>添加筛选条件，过滤多余数据</SC.Empty>
    // }, [])

    return (
        <SC.FilterGroupWrapper>
            <SC.FilterGroupContentWrapper className={cls({ 'real-filter-group': isRealFilterGroup })}>
                {!isNoneConditions && filterListContent}
                <SC.PropertyFooter>
                    <Button icon={<SC.PropertyAdderIcon fill="var(--color-gray-500)" type="Add" />} onClick={handleAdd}>
                        添加条件
                    </Button>
                    <Button icon={<SC.PropertyAdderIcon fill="var(--color-gray-500)" type="Group" />} onClick={handleAddGroup}>
                        添加条件组
                    </Button>
                    {/* <SC.PropertyAdder onClick={handleAdd}>
                        <SC.PropertyAdderIcon fill="var(--color-main)" type="Add" />
                        添加条件
                    </SC.PropertyAdder>
                    <SC.PropertyAdder onClick={handleAddGroup}>
                        <SC.PropertyAdderIcon fill="var(--color-main)" type="Group" />
                        添加条件组
                    </SC.PropertyAdder> */}
                </SC.PropertyFooter>
            </SC.FilterGroupContentWrapper>
            {isTopLevel && <MorePopover disabled={disabled} onDuplicate={onDuplicate} onDelete={onDelete} />}
        </SC.FilterGroupWrapper>
    )
}
