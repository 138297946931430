import type { NavigationShowMode } from '@lighthouse/core'
import { SelectMode } from '@lighthouse/shared'
import * as React from 'react'
import styled from 'styled-components'

import SettingBlock from '../../SettingBlock'
import { navbarModeList } from '../constant'

const SCxContainer = styled(SettingBlock)`
    border-style: none !important;
`
interface NavBarModeProps {
    disabled?: boolean
    value: NavigationShowMode
    onChange: (value: NavigationShowMode) => void
}

const NavBarMode = React.forwardRef<HTMLDivElement, NavBarModeProps>(({ disabled, value, onChange }, ref) => {
    return (
        <SCxContainer label="导航栏模式" disableCollapse>
            <SelectMode
                ref={ref}
                disabled={disabled}
                options={navbarModeList}
                itemWidth="50%"
                styles={{
                    root: {
                        justifyContent: 'flex-start',
                        gap: 16,
                        marginTop: 8
                    }
                }}
                value={value}
                onChange={val => onChange(val as NavigationShowMode)}
            />
        </SCxContainer>
    )
})

export default NavBarMode
