import type { CascadeValue, FieldFindUse } from '@lighthouse/shared'
import { CascadeSelect, FieldTypeTag, FindUseLocationType, getFieldIcon, getTableIcon, useAtomAction } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { setFindUseAtom } from '@/atoms/application/action'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { FindUseLink } from '../FindUseLink'

const SCxContainer = styled.div`
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
`

interface FindUseFieldProps {
    config: FieldFindUse
    onChange?: (val: FieldFindUse) => void
}

export const FindUseField: React.FC<FindUseFieldProps> = ({ config, onChange }) => {
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const navigate = useNavigate()
    const dataSourceList = useDataSourceList(currentAppId, envId)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)

    const options = useMemo(() => {
        return dataSourceList.map(ds => ({
            label: ds.name,
            value: ds.id,
            icon: getTableIcon(ds),
            children: Object.entries(ds.schema).map(([id, field]) => ({
                label: field.name,
                value: id,
                icon: getFieldIcon(field.id, field.type, field.innerType),
                extra: <FieldTypeTag type={field.type} innerType={field.innerType} />
            }))
        }))
    }, [dataSourceList])

    const {
        type,
        field: { fieldId, dsId }
    } = config

    const handleChange = useCallback(
        (val: CascadeValue) => {
            const [dsId, fieldId] = val
            onChange?.({
                type,
                field: {
                    dsId,
                    fieldId
                }
            })
        },
        [onChange, type]
    )

    const handleLink = useCallback(() => {
        setFindUse({
            config,
            location: {
                type: FindUseLocationType.FIELD,
                dsId,
                fieldId,
                dsName: '',
                fieldType: 'text',
                fieldName: ''
            }
        })
        navigate({ pathname: `/${currentAppId}/dataSource/${dsId}` })
    }, [config, currentAppId, dsId, fieldId, navigate, setFindUse])

    return (
        <SCxContainer>
            <CascadeSelect value={[dsId, fieldId]} options={options} onChange={handleChange} />
            <FindUseLink onClick={handleLink} />
        </SCxContainer>
    )
}
