import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal, Modal } from '@byecode/ui'
import React from 'react'

import StripeAccount from '../StripeAccount'
import type { StripeAccountMode } from '../type'

interface StripeAccountModalProps extends BaseModalProps {
    mode: StripeAccountMode
    id?: string
}

export const StripeAccountModal: React.FunctionComponent<StripeAccountModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <StripeAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
