import { Input, ModalConfirm, ModalInfo } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import * as srv from '@/services'

const SCxInputWrapper = styled.div`
    margin-top: 12px;
`

const SCxContentWrapper = styled.div``

interface DeleteEnvProps {
    env: Environment
    onConfirm?: () => void
    onClose?: () => void
}

export const DeleteEnv: React.FC<DeleteEnvProps> = ({ env, onConfirm, onClose }) => {
    const [open, setOpen] = useState(false)
    const [infoOpen, setInfoOpen ] = useState(false)
    const [value, setValue] = useState('')
    const desc = `删除数据环境后，可能导致使用数据的地方出现异常，且无法恢复，请谨慎操作。输入数据环境名称“${env.envName}”，解锁删除。`

    const { data, mutate, isLoading } = useSWR(['get_apps_by_env', env.envId], () => srv.getAppsByEnv(env.envId), {
        revalidateOnFocus: false
    })

    useEffect(() => {
        if (!isLoading && data && data.length === 0) {
            setOpen(true)
        }
        if(!isLoading && data && data.length > 0){
            setInfoOpen(true)
        }
    }, [data, isLoading])

    const disableConfirm = useMemo(() => value !== env.envName, [env.envName, value])

    const handleResolve = useCallback(() => {
        if (disableConfirm) {
            return
        }
        onConfirm?.()
    }, [disableConfirm, onConfirm])

    const handleClose = useCallback(() => {
        onClose?.()
    }, [onClose])

    const content = useMemo(() => {
        if (!data) {
            return null
        }
        return (
            <SCxContentWrapper>
                <p>{`你不能删除一个已经连接了版本的数据环境，“${env.envName}”已经连接了以下版本：`}</p>
                {data.map(item => (
                    <p key={item.id}>
                        #{item.serial} {item.name}
                    </p>
                ))}
            </SCxContentWrapper>
        )
    }, [data, env.envName])

    if (!data || isLoading) {
        return null
    }

    return (
        <>
            {infoOpen && <ModalInfo title="不能删除该数据环境" content={content} okStatus='primary' onResolve={handleClose} />}
            {open && (
                <ModalConfirm
                    title="确认删除数据环境？"
                    content={desc}
                    onResolve={handleResolve}
                    onReject={handleClose}
                    okDisable={disableConfirm}
                    extra={
                        <SCxInputWrapper>
                            <Input value={value} onChange={e => setValue(e.target.value)} placeholder={env.envName} />
                        </SCxInputWrapper>
                    }
                />
            )}
        </>
    )
}
