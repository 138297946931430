import { IconFont } from '@byecode/ui/components/IconFont'
import { Popover } from '@byecode/ui/components/Popover'
import { Slider } from '@byecode/ui/components/Slider'
import { Text } from '@byecode/ui/components/Text'
import type { ApplicationSettingThemeColor, Shadow } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import React from 'react'

import { FilledInput } from '../FilledInput'
import { FillPickerPopoverDropdown } from '../FillPicker'
import { ListItem4ByecodeUi } from '../ListItemMenu'

interface ShadowControllerProps {
    value?: Shadow
    onChange?: (value: Shadow) => void

    onUpdateColor: (value: ApplicationSettingThemeColor) => void
    onCreateColor: (value: ApplicationSettingThemeColor) => void
}

/** 阴影控制器 */
export const ShadowController = ({ value, onChange, onUpdateColor, onCreateColor }: ShadowControllerProps) => {
    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>颜色</Text>
                <Popover width={300} withinPortal>
                    <Popover.Target>
                        <FilledInput
                            value={{ type: BACKGROUND_TYPE.color, color: value?.color }}
                            onClear={() => onChange?.({ ...value, color: '' })}
                            style={{ width: 180 }}
                        />
                    </Popover.Target>
                    {/* <PopoverDropdownWithClose title="填充">
                        <FillPicker
                            value={value?.color ? { type: BACKGROUND_TYPE.color, color: value.color } : undefined}
                            onChange={v => {
                                onChange?.({ ...value, color: v.color })
                            }}
                        />
                    </PopoverDropdownWithClose> */}
                    <FillPickerPopoverDropdown
                        title="填充"
                        value={value?.color ? { type: BACKGROUND_TYPE.color, color: value.color } : undefined}
                        onChange={v => {
                            onChange?.({ ...value, color: v.color })
                        }}
                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                        onCreateColor={onCreateColor}
                        onUpdateColor={onUpdateColor}
                    />
                </Popover>
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>X</Text>
                <Slider
                    style={{ width: 180 }}
                    icon={<IconFont type="X" />}
                    min={-10}
                    max={10}
                    value={value?.x}
                    onChange={v => onChange?.({ ...value, x: v })}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>Y</Text>
                <Slider
                    style={{ width: 180 }}
                    icon={<IconFont type="Y" />}
                    min={-10}
                    max={10}
                    value={value?.y}
                    onChange={v => onChange?.({ ...value, y: v })}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>模糊</Text>
                <Slider
                    style={{ width: 180 }}
                    icon={<IconFont type="Blur" />}
                    min={0}
                    max={10}
                    value={value?.blur}
                    onChange={v => onChange?.({ ...value, blur: v })}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>扩散</Text>
                <Slider
                    style={{ width: 180 }}
                    icon={<IconFont type="Spread" />}
                    min={0}
                    max={10}
                    value={value?.diffusion}
                    onChange={v => onChange?.({ ...value, diffusion: v })}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
