import { ChartBlockThumbImageMap } from '@lighthouse/block'
import { BlockType, ChartType } from '@lighthouse/core'

import type { BlockTree, BlockTreeItem } from '../type'
import { BlockKind } from '../type'

const blockChartBar: BlockTreeItem = {
    name: '柱状图',
    icon: 'ChartBar',
    describeImage: ChartBlockThumbImageMap['bar'],
    color: '#F04438',
    backGroundColor: 'rgba(240, 68, 56, 0.10)',
    type: BlockType.chart,
    subType: ChartType.bar
}

const blockChartPie: BlockTreeItem = {
    name: '饼状图',
    icon: 'ChartPie',
    describeImage: ChartBlockThumbImageMap['pie'],
    color: '#12B76A',
    backGroundColor: 'rgba(85, 200, 27, 0.10)',
    type: BlockType.chart,
    subType: ChartType.pie
}
const blockChartLine: BlockTreeItem = {
    name: '折线图',
    icon: 'ChartLine',
    color: '#FFB74A',
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    describeImage: ChartBlockThumbImageMap['line'],
    type: BlockType.chart,
    subType: ChartType.line
}

const blockChartNumCard: BlockTreeItem = {
    name: '指标卡',
    icon: 'ChartNumCard',
    color: '#54C5EB',
    backGroundColor: 'rgba(84, 197, 235, 0.10)',
    describeImage: ChartBlockThumbImageMap['indicator'],
    type: BlockType.chart,
    subType: ChartType.indicator
}

const blockChartComposite: BlockTreeItem = {
    name: '复合图表',
    icon: 'ChartCompound',
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    describeImage: ChartBlockThumbImageMap['composite'],
    type: BlockType.chart,
    subType: ChartType.composite
}
const blockChartFunnel: BlockTreeItem = {
    name: '漏斗图',
    icon: 'ChartFunnel',
    color: '#FF4A55',
    backGroundColor: 'rgba(240, 98, 52, 0.10)',
    describeImage: ChartBlockThumbImageMap['funnel'],
    type: BlockType.chart,
    subType: ChartType.funnel
}

export const blockChartStriation: BlockTreeItem = {
    name: '条形图',
    icon: 'ChartStriation',
    color: '#FFB74A',
    backGroundColor: 'rgba(255, 183, 74, 0.10)',
    describeImage: ChartBlockThumbImageMap['striation'],
    type: BlockType.chart,
    subType: ChartType.striation
}

export const chart: BlockTree = {
    id: BlockKind.chart,
    name: '图表',
    icon: 'Chart',
    color: '',
    items: []
}

export const allChart: BlockTree = {
    ...chart,
    items: [blockChartComposite, blockChartBar, blockChartFunnel, blockChartStriation, blockChartNumCard, blockChartPie, blockChartLine]
}
