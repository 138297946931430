import { Divider, Modal, SegmentedControl, Switch, Text } from '@byecode/ui'
import { type ApplicationPrivateTemplate } from '@lighthouse/core'
import { APPLICATION_ENV_PROD, ListItem4ByecodeUi, useAtomAction } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import type { ChangeEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import useSWR from 'swr'

import { updateAppPrivateTemplateAtom } from '@/atoms/application/action'
import { applicationPrivateTemplateAtom } from '@/atoms/application/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'
import { useDataSourceList } from '@/shared/reusable'

import {
    ExistPoolUserError,
    ExistSyncTableAndPoolUserError,
    ExistSyncTableError,
    PrivateTemplatePolicy,
    PrivateTemplatePolicyCheck
} from '../PrivateTemplateNotice'
import { SharedHeader } from '../Share/Header'
import { StyledUrl } from '../StyledUrl'
import { PreviewImage } from './PreviewImage'

const SCxContainer = styled.div`
    width: 100%;
`

const SCxContent = styled.div``

const SCxPrivateLinkSwitchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

const SCxPrivateLinkSwitchInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const SCxPrivateLinkConfig = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const previewTabs = [
    { label: 'H5端', value: 'mobile' },
    { label: '电脑端', value: 'desktop' },
    { label: '可两端切换', value: 'all' }
]

const initTabs = [
    { label: 'H5端', value: 'mobile' },
    { label: '电脑端', value: 'desktop' }
]

interface SharePrivateLinkProps {
    onBack?: () => void
    onClose?: () => void
}

export const SharePrivateLink: React.FC<SharePrivateLinkProps> = ({ onBack, onClose }) => {
    const rootRef = useRef<HTMLDivElement>(null)
    const { host } = window.location
    const appId = useCurrentAppID()
    const envId = APPLICATION_ENV_PROD
    const shareUrl = `https://${host}/template/${appId}`

    const {
        data: env
    } = useSWR(
        ['getEnv'],
        () => {
            return srv.getEnv(envId)
        },
        {
            revalidateOnFocus: false
        }
    )
    const { data: dataSourceList } = useDataSourceList(appId, envId)

    const applicationPrivateTemplate = useAtomValue(applicationPrivateTemplateAtom)

    const { run: updateAppPrivateTemplate } = useAtomAction(updateAppPrivateTemplateAtom)

    const { control, watch } = useForm<ApplicationPrivateTemplate>({
        mode: 'onChange',
        defaultValues: {
            ...applicationPrivateTemplate
        }
    })

    const canPrivateTemplate = useWatch({ control, name: 'canPrivateTemplate' })

    useEffect(() => {
        const { unsubscribe } = watch((formValue, info) => {
            updateAppPrivateTemplate({
                ...formValue,
                canPrivateTemplate: !!formValue.canPrivateTemplate
            })
        })
        return unsubscribe
    }, [watch, updateAppPrivateTemplate])

    const device = useWatch({ control, name: 'device' })
    const initDevice = useWatch({ control, name: 'initDevice' })

    const errContent = useMemo(() => {
        if (!dataSourceList) {
            return
        }
        const isExitSync = dataSourceList.some(item => item.sync)
        const isExitPoolUser = !!env?.link?.envId
        if (isExitSync && isExitPoolUser) {
            return <ExistSyncTableAndPoolUserError />
        }
        if (isExitSync) {
            return <ExistSyncTableError />
        }
        if (isExitPoolUser) {
            return <ExistPoolUserError />
        }
    }, [dataSourceList, env?.link])

    const handleCanPrivateTemplate = useCallback(
        async (ev: ChangeEvent<HTMLInputElement>, changeEvent: (val: boolean) => void) => {
            const val = ev.target.checked
            if (!val) {
                const isStop = await Modal.confirm({
                    title: '确定要停用吗？',
                    content: '停用后用户将无法访问当前模版链接',
                    okText: '停用',
                    position: 'fixed',
                    target: rootRef.current
                })
                if (isStop) {
                    changeEvent(val)
                }
                return
            }
            if (dataSourceList) {
                if (errContent) {
                    Modal.confirm({
                        title: '启用失败',
                        content: errContent,
                        okStatus: 'primary',
                        okText: '知道了',
                        position: 'fixed',
                        target: rootRef.current
                    })
                    return
                }
                const isStart = await Modal.confirm({
                    title: '《启用私人模板规范》',
                    content: <PrivateTemplatePolicy />,
                    okStatus: 'primary',
                    okText: '确认开启',
                    position: 'fixed',
                    target: rootRef.current,
                    isCheckBoxConfirm: true,
                    checkBoxLabel: <PrivateTemplatePolicyCheck />
                })
                if (isStart) {
                    changeEvent(val)
                }
            }
        },
        [dataSourceList, errContent]
    )

    return (
        <SCxContainer ref={rootRef}>
            <SharedHeader onBack={() => onBack?.()} onClose={onClose} title="分享私人模板" />
            <SCxContent>
                <SCxPrivateLinkSwitchWrapper>
                    <SCxPrivateLinkSwitchInfo>
                        <Text color="var(--color-black)" size={14}>
                            {canPrivateTemplate ? '已启动' : '未启动'}
                        </Text>
                        <Text color="var(--color-gray-400)" size={14}>
                            用户可以通过链接复制应用
                        </Text>
                    </SCxPrivateLinkSwitchInfo>
                    <Controller
                        control={control}
                        name="canPrivateTemplate"
                        render={({ field }) => (
                            <Switch checked={field.value || false} onChange={ev => handleCanPrivateTemplate(ev, field.onChange)} />
                        )}
                    />
                </SCxPrivateLinkSwitchWrapper>
                <PreviewImage mode={device} initMode={initDevice} />
                {canPrivateTemplate && (
                    <>
                        <Divider my={12} />
                        <SCxPrivateLinkConfig>
                            <StyledUrl shareUrl={shareUrl} />
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>展示为</Text>
                                <Controller
                                    control={control}
                                    name="device"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            fullWidth
                                            style={{ width: 257 }}
                                            size="md"
                                            value={field.value}
                                            defaultValue="all"
                                            onChange={field.onChange}
                                            data={previewTabs}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                            {device === 'all' && (
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>默认</Text>
                                    <Controller
                                        control={control}
                                        name="initDevice"
                                        render={({ field }) => (
                                            <SegmentedControl
                                                fullWidth
                                                style={{ width: 172 }}
                                                size="md"
                                                value={field.value}
                                                defaultValue="desktop"
                                                onChange={field.onChange}
                                                data={initTabs}
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            )}
                        </SCxPrivateLinkConfig>
                    </>
                )}
            </SCxContent>
        </SCxContainer>
    )
}
