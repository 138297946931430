import { pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Trigger = styled.div`
    position: relative;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    gap: 6px;
    background-color: var(--color-gray-100);
`

export const DropDown = styled.div``

export const IconWrapper = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
`

export const TagWrapper = styled.div<{ isOmit?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    overflow: hidden;
    flex: 1;
`
export const TagContainer = styled.div`
    position: relative;
    max-width: 100%;
    overflow: hidden;
`

export const DropDownList = styled.div`
    max-height: 500px;
    overflow-y: auto;
    ${tinyButtons}
`

export const ItemLeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
`

export const ItemRightFill = styled.div``

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    ${pointer}
`

export const DropDownItem = styled.div`
    position: relative;
    padding: 8px;
    height: 36px;
    display: flex;
    justify-content: space-between;

    &.menu {
        padding: 10px 12px;
        line-height: 16px;
        border-radius: 6px;
        :hover {
            background-color: var(--color-theme-3);
        }
    }

    &.active {
        background-color: var(--color-theme-3);

        ${ItemLeftFill} :last-child {
            font-weight: 600;
            color: var(--color-theme-7);
        }
    }

    :hover {
        > :last-child {
            visibility: visible;
        }
    }
`

export const DropDownHeaderLeft = styled.div`
    display: flex;
`
