import { Empty, Group, Input } from '@byecode/ui'
import type { FlowNode, RichTextEditorProps, SendEmailActionNode, SenderAccountType } from '@lighthouse/shared'
import { getAllCanBeUpstreamNodes, getDefaultValueOptions, NodeFieldPreview, pureTextFieldTypes, RichTextEditor, VariableSourceType } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'

import { FieldSelect } from '@/components/FieldSelect'
import type { WeComAccountMode } from '@/components/OtherPlatFormModal'
import { AddWeComAccount, WeComModal } from '@/components/OtherPlatFormModal'
import { AddNetEaseFreeAccount, NetEaseFreeAccountModal } from '@/components/OtherPlatFormModal/NetEaseFreeAccount'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import type { AllOtherPlatform } from '@/services/types'
import { useOtherPlatformList } from '@/shared/reusable'

import { FormItem } from '../../Common/FormItem'
import { GroupRequired } from '../../Common/GroupRequired'
import { SEND_WAY_OPTIONS } from '../../constant'
import * as CM from '../../styles'
import { SelectEmail } from './NodeFieldVariable'
import * as SC from './styles'

interface SendEmailActionConfigureProps {
    allParentNodes: FlowNode[]
}

interface State {
    mode: WeComAccountMode
    opened: boolean
    id?: string
}

const emailTypeIconMap: Record<
    SenderAccountType,
    {
        icon: string
        color: string
        name: string
    }
> = {
    NET_EASE_126_MAIL: {
        icon: 'NetEasyFree',
        color: '#1E7E3E',
        name: '网易免费邮'
    },
    WE_COM_MAIL: {
        icon: 'BrandLogoWeCom',
        color: '#0c8ce9',
        name: '腾讯企业邮'
    }
}

export const SendEmailActionConfigure: React.FC<SendEmailActionConfigureProps> = ({ allParentNodes }) => {
    const { control, watch, setValue } = useFormContext<Pick<SendEmailActionNode, 'config'>>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const disabledWithVersion = useIsDisabledWithVersion()
    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })

    const { type } = useFlow()
    const [senderAccountId, senderAccountType] = watch(['config.senderAccountId', 'config.senderAccountType'])

    const { data: otherPlatforms } = useOtherPlatformList()

    const accountList: AllOtherPlatform = useMemo(
        () =>
            ({
                NET_EASE_126_MAIL: otherPlatforms?.filter(item => item.type === 'NET_EASE_126_MAIL'),
                WE_COM_MAIL: otherPlatforms?.filter(item => item.type === 'WE_COM_MAIL')
            }[senderAccountType] ?? []),
        [otherPlatforms, senderAccountType]
    )

    const emailOptions = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: getAllCanBeUpstreamNodes(allParentNodes) || []
                    }
                ],
                validateFieldType: type => type === 'email' || type === 'formula'
            }),
        [allParentNodes, dataSourceList]
    )

    const titleOptions = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: getAllCanBeUpstreamNodes(allParentNodes) || []
                    }
                ],
                validateFieldType: type => pureTextFieldTypes.has(type)
            }),
        [allParentNodes, dataSourceList]
    )

    const richTextConfig: RichTextEditorProps['config'] = useMemo(() => {
        return {
            variable: {
                options: titleOptions,
                enablePageLink: type !== 'approval' && type !== 'automation'
            },
            heading: false,
            taskList: false,
            bulletList: false,
            orderedList: false,
            codeBlock: false,
            image: false,
            quote: false,
            line: false
        }
    }, [titleOptions, type])

    const account = useMemo(() => {
        const account = find(item => item.id === senderAccountId, accountList)
        return account?.type === 'WE_COM_MAIL' || account?.type === 'NET_EASE_126_MAIL' ? account : undefined
    }, [accountList, senderAccountId])

    const handleUpdate = useCallback(
        (value: string) => {
            const data = find(item => item.id === value, accountList)
            setState({ opened: true, mode: 'update', id: data?.id })
        },
        [accountList, setState]
    )

    const handleAdd = useCallback(() => {
        setState({ opened: true, mode: 'add', id: undefined })
    }, [setState])

    const accountOptions = useMemo(() => {
        return accountList.map((item, index) => ({
            label: item.name ?? '未命名账户名称',
            value: item.id,
            icon: <SC.Icon size={16} type={emailTypeIconMap[senderAccountType].icon} color={emailTypeIconMap[senderAccountType].color} />,
            extra: (
                <SC.RightFill
                    onMouseDown={ev => {
                        ev.stopPropagation()
                        handleUpdate(item.id)
                    }}
                >
                    <CM.Icon color="var(--color-gray-400)" type="PencilSimple" />
                </SC.RightFill>
            )
        }))
    }, [accountList, senderAccountType, handleUpdate])

    const modalEle = useMemo(() => {
        if (senderAccountType === 'WE_COM_MAIL') {
            return <WeComModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
        }
        return <NetEaseFreeAccountModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
    }, [id, mode, opened, senderAccountType, setState])

    return (
        <>
            <CM.Container>
                <Group
                    label="发件人"
                    styles={{
                        root: {
                            padding: '0!important'
                        }
                    }}
                >
                    <FormItem label="发送方式" required>
                        <Controller
                            control={control}
                            name="config.senderAccountType"
                            render={({ field }) => (
                                <FieldSelect width="200" options={SEND_WAY_OPTIONS} value={field.value} onChange={field.onChange} />
                            )}
                        />
                    </FormItem>
                    {senderAccountType && (
                        <>
                            <FormItem label="选择账号">
                                <Controller
                                    control={control}
                                    name="config.senderAccountId"
                                    render={({ field }) => (
                                        <SC.SelectContainer>
                                            <SC.ManTinSelect
                                                hiddenEmpty
                                                placeholder="请选择"
                                                options={accountOptions}
                                                prefix={
                                                    find(item => item.value === field.value, accountOptions) &&
                                                    field.value && (
                                                        <SC.Icon
                                                            size={32}
                                                            type={emailTypeIconMap[senderAccountType].icon}
                                                            color={emailTypeIconMap[senderAccountType].color}
                                                        />
                                                    )
                                                }
                                                {...field}
                                                styles={{
                                                    root: {
                                                        width: 180
                                                    },
                                                    item: {
                                                        '&:hover>div>*:last-child': {
                                                            display: 'flex'
                                                        }
                                                    }
                                                }}
                                                dropdownProps={{
                                                    extra: (
                                                        <>
                                                            {accountOptions.length === 0 && (
                                                                <Empty
                                                                    icon={
                                                                        <SC.Icon
                                                                            type={emailTypeIconMap[senderAccountType].icon}
                                                                            size={32}
                                                                            color="var(--color-gray-400)"
                                                                        />
                                                                    }
                                                                    styles={{
                                                                        root: {
                                                                            minHeight: '75px'
                                                                        }
                                                                    }}
                                                                    description={`请添加${emailTypeIconMap[senderAccountType].name}账号`}
                                                                />
                                                            )}
                                                            <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                                                            {
                                                                {
                                                                    WE_COM_MAIL: <AddWeComAccount onClick={() => handleAdd()} />,
                                                                    NET_EASE_126_MAIL: <AddNetEaseFreeAccount onClick={() => handleAdd()} />
                                                                }[senderAccountType]
                                                            }
                                                        </>
                                                    )
                                                }}
                                            />
                                        </SC.SelectContainer>
                                    )}
                                />
                            </FormItem>
                            <>
                                {senderAccountId && (
                                    <>
                                        <FormItem label="发件人名称">
                                            <Input readOnly size="sm" disabled defaultValue={account?.config.senderName} />
                                        </FormItem>
                                        <FormItem label="回复地址">
                                            <Input readOnly disabled size="sm" width={200} defaultValue={account?.config.emailAddress} />
                                        </FormItem>
                                    </>
                                )}
                            </>
                        </>
                    )}
                </Group>
                <>
                    {senderAccountId && (
                        <>
                            <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                            <GroupRequired
                                required
                                label="收件人"
                                mode="switch"
                                tooltipLabel="添加收件人的方式：从数据表中选择插入变量"
                                styles={{
                                    root: {
                                        padding: 0
                                    }
                                }}
                            >
                                <SelectEmail options={emailOptions} name="config.recipients" />
                            </GroupRequired>
                            <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                            <GroupRequired
                                label="抄送人"
                                mode="switch"
                                tooltipLabel="打开后每个收件人都可以看到所有收件人和抄送人"
                                styles={{
                                    root: {
                                        padding: 0
                                    }
                                }}
                            >
                                <SelectEmail options={emailOptions} name="config.cc" />
                            </GroupRequired>
                            <Divider color="var(--color-gray-200)" style={{ margin: '8px 0' }} />
                            <GroupRequired
                                required
                                label="邮件内容"
                                styles={{
                                    root: {
                                        padding: 0
                                    }
                                }}
                            >
                                <FormItem label="标题">
                                    <Controller
                                        control={control}
                                        name="config.title"
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                /*
                                                 *@describe 此处placeholder过长时会引发删除变量时tiptapEditor的dom移位，进而引发不能聚焦
                                                 */
                                                <NodeFieldPreview
                                                    disabled={disabledWithVersion}
                                                    placeholder="输入文字或添加变量"
                                                    value={value}
                                                    enablePageLink={type !== 'approval' && type !== 'automation'}
                                                    onChange={onChange}
                                                    options={titleOptions}
                                                    autoHeight
                                                />
                                            )
                                        }}
                                    />
                                </FormItem>
                                <SC.Item>
                                    <SC.Text>正文</SC.Text>
                                    <SC.RightFill style={{ display: 'flex' }}>
                                        <Controller
                                            control={control}
                                            name="config.content"
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <RichTextEditor
                                                        config={richTextConfig}
                                                        value={value}
                                                        styles={{
                                                            editorContainer: {
                                                                width: '100%',
                                                                minHeight: 200,
                                                                borderRadius: 6,
                                                                marginTop: 12,
                                                                border: '1px solid var(--color-gray-200)'
                                                            }
                                                        }}
                                                        disableToolbar={false}
                                                        onChange={val => {
                                                            onChange(val)
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </SC.RightFill>
                                </SC.Item>
                            </GroupRequired>
                        </>
                    )}
                </>
            </CM.Container>
            {modalEle}
        </>
    )
}
