import { DatePicker, Input, MobileDatePickerView } from '@byecode/ui'
import type { DateValue, Field, VariableADTvalue } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import { Popover } from '@mantine/core'
import { isValid, lightFormat } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { defaultDateFormat } from '../../../../constants'
import { isDateValue } from '../../../../utils'
import { Placeholder } from '../../../PlaceHolder'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../../constant'
import * as SC from './styles'

interface DateInputProps {
    data: DateValue
    field: Field
    placeholder?: string
    disabled?: boolean
    onChange: (value: VariableADTvalue) => void
    onChangeOpen: () => void
}

const DateInput: React.FunctionComponent<DateInputProps> = ({ placeholder = "请选择", data, field, disabled, onChangeOpen, onChange }) => {
    const [opened, setOpened] = useState(false)
    const dateField = useMemo(
        () =>
            field.type === 'date'
                ? field.date
                : {
                      format: defaultDateFormat
                  },
        [field]
    )

    const valueDate = useMemo(() => {
        const currentValue = data ? new Date(data) : undefined
        if (!currentValue) {
            return
        }
        return isValid(currentValue) ? currentValue : undefined
    }, [data])

    return (
        <SC.ItemContainer onClick={() => !disabled && onChangeOpen?.()}>
            <SC.ItemWrapper>
                {valueDate ? (
                    lightFormat(valueDate, dateField.format ?? defaultDateFormat)
                ) : (
                    <Placeholder value={disabled ? '-' : placeholder} style={{ color: 'var(--color-gray-400)' }} />
                )}
            </SC.ItemWrapper>
            <SC.IconWrapper>
                {valueDate && (
                    <SC.Icon
                        type="CloseCircle"
                        size={16}
                        onClick={e => {
                            e.stopPropagation()
                           !disabled && onChange(DEFAULT_FILTER_VALUE_VARIABLE)
                        }}
                        isAction
                    />
                )}
                <SC.Icon type="ArrowDownSmall" size={16} />
            </SC.IconWrapper>
        </SC.ItemContainer>
        // <DatePicker
        //     format={dateField?.format}
        //     showTime={showTime}
        //     placeholder={placeholder}
        //     suffix={
        //         <SC.IconWrapper>
        //             {valueDate && (
        //                 <SC.Icon
        //                     type="CloseCircle"
        //                     size={16}
        //                     onClick={e => {
        //                         e.stopPropagation()
        //                         onChange(DEFAULT_FILTER_VALUE_VARIABLE)
        //                     }}
        //                     isAction
        //                 />
        //             )}
        //             <SC.Icon
        //                 type="DotsThreeVertical"
        //                 size={16}
        //                 onClick={() => {
        //                     setOpened(false)
        //                     onChangeOpen?.()
        //                 }}
        //             />
        //         </SC.IconWrapper>
        //     }
        //     value={valueDate}
        //     onChange={handleChange}
        // />
    )
}

export default DateInput
