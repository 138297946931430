import type { ModalProps } from '@byecode/ui'
import { Button, Flex, IconFont, Input, Modal, Popover, Text } from '@byecode/ui'
import type { PageAbstract } from '@lighthouse/core'
import { domainReg2, TitleInContentModal } from '@lighthouse/shared'
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { usePageURI } from '@/hooks/usePageURI'

const Footer = styled.div`
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding: 16px 20px;
    background-color: var(--color-gray-50);
`

const ActionButton = styled(Button)`
    width: 104px;
    height: 40px;
`

const StyledModal = styled(TitleInContentModal)`
    .byecode-Modal-title {
        padding: 0;
        margin: 0 0 20px 0px;
    }
    .byecode-Modal-body {
        padding: 48px 40px 55px;
    }
`

const StyledInput = styled(Input)`
    padding: 0 8px;
    .byecode-Input-prefix {
        width: auto;
        position: unset;
        font-size: 14px;
    }
    .byecode-Input-input {
        padding: 0;
    }
`

const DEFAULT_TIP = '使用英文字母（a-z）、数字（0-9）和连字符（-）'

interface Props extends ModalProps {
    pages: PageAbstract[]
    page: PageAbstract
    onClear?: () => void
    onConfirm?: (route: string) => Promise<boolean>
}

export const CustomRouteModal = (props: Props) => {
    const { pages, page, onClose, onClear, onConfirm } = props

    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isDirty, setIsDirty] = useState(false)

    const [value, setValue] = useState(page.route)
    const hasChangedRef = useRef(false)
    const [opened, setOpened] = useState(false)
    useLayoutEffect(() => {
        setOpened(true)
    }, [])

    const errors = useMemo(() => {
        const isExist = pages.some(item => !!value && item.id !== page.id && item.route === value)
        if (isExist) {
            return [{ error: true, message: '该域名已被占用' }]
        }

        return [
            {
                error: !value || !domainReg2.test(value),
                message: DEFAULT_TIP
            },
            {
                error: !value || value.length > 63,
                message: '最大长度限制为63'
            }
        ]
    }, [page.id, pages, value])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        hasChangedRef.current = true
        setValue(v)
    }, [])

    const { host } = usePageURI()

    const [loading, setLoading] = useState(false)

    const hasError = errors.some(item => item.error)

    return (
        <StyledModal
            {...props}
            width={676}
            title="自定义页面路径"
            footer={
                <Footer>
                    {page.route && (
                        <ActionButton
                            style={{ marginRight: 'auto' }}
                            icon={<IconFont type="Trash" />}
                            onClick={async () => {
                                const isConfirm = await Modal.confirm({
                                    title: '确认删除',
                                    content: '删除后的当前页面不可使用自定义后缀。'
                                })
                                if (!isConfirm) {
                                    return
                                }

                                onClear?.()
                                onClose?.()
                            }}
                        >
                            删除
                        </ActionButton>
                    )}
                    <ActionButton size="lg" onClick={onClose}>
                        取消
                    </ActionButton>
                    <ActionButton
                        size="lg"
                        type="primary"
                        loading={loading}
                        disabled={hasError || !hasChangedRef.current}
                        onClick={async () => {
                            if (value && onConfirm) {
                                setLoading(true)
                                const res = await onConfirm(value)
                                setLoading(false)
                                if (res) {
                                    onClose?.()
                                }
                            }
                        }}
                    >
                        确定
                    </ActionButton>
                </Footer>
            }
        >
            <Text color="var(--color-gray-500)" size={12} mb={20}>
                页面当前后缀：{page.route || `P/${page.id}`}，您可以自定义页面后缀。
            </Text>

            <Text mb={8}>URL</Text>
            <div style={{ position: 'relative' }}>
                <StyledInput
                    size="lg"
                    ref={inputRef}
                    placeholder=""
                    prefix={`${host}/`}
                    value={value}
                    onChange={handleChange}
                    onFocus={() => {
                        setOpened(true)
                        setIsDirty(true)
                    }}
                    onBlur={() => {
                        if (!hasError) {
                            setOpened(false)
                        }
                    }}
                />

                {hasError ? null : (
                    <IconFont
                        type="TickCircle"
                        size={16}
                        style={{ position: 'absolute', top: '50%', right: -24, transform: 'translateY(-50%)' }}
                    />
                )}
            </div>

            <Popover
                opened={opened && isDirty}
                trapFocus={false}
                returnFocus={false}
                position="right"
                withArrow
                arrowSize={6}
                offsetOptions={16}
                width="auto"
            >
                <Popover.AnchorEl anchorEl={inputRef.current} />
                <Popover.Dropdown style={{ padding: 8 }}>
                    {errors.map(item => (
                        <Flex gap={4} alignItems="center" key={item.message}>
                            <IconFont
                                type={item.error ? 'CloseCircle' : 'Tick'}
                                color={item.error ? 'var(--color-red-500)' : 'var(--color-gray-500)'}
                                size={16}
                            />
                            <Text size={12} color={item.error ? 'var(--color-red-500)' : 'var(--color-gray-500)'} lineHeight="20px">
                                {item.message}
                            </Text>
                        </Flex>
                    ))}
                </Popover.Dropdown>
            </Popover>
        </StyledModal>
    )
}
