import { useDndContext } from '@dnd-kit/core'
import React from 'react'
import styled from 'styled-components'

const StyledGuideLine = styled.div`
    position: absolute;
    width: calc(100% - 8px);
    left: 8px;
    top: 0;
    height: 4px;
    z-index: 9;
    background-color: var(--color-main);
    &:before {
        position: absolute;
        left: -8px;
        top: -4px;
        display: block;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #2389ff;
        background-color: #ffffff;
    }
`
interface GuideLineProps {
    children?: React.ReactNode
    rect: {
        top: number
    }
}

export const RowGuideLine: React.FC<GuideLineProps> = ({ rect }) => {
    const { top } = rect
    const { over } = useDndContext()
    return over && <StyledGuideLine style={{ top }} />
}
