import React from 'react'

import { FlowList } from '@/containers/FlowList'
import { useCurrentAppID } from '@/hooks/useApplication'

const FlowPage: React.FC = () => {
    const appId = useCurrentAppID()

    return <FlowList appId={appId} />
}

export default FlowPage
