import type { BreadcrumbItemProps } from '@byecode/ui'
import { Breadcrumbs, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { SchemaProtocol, ViewField } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import { InnerDrawer } from '@/components/BlockSettings/InnerDrawer'

import { ViewFieldConfigRenderer } from '../ViewFieldConfigRenderer'

const SCxText = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: ${({ size }) => size ?? 14 + 2}px;
    margin-left: 4px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

interface DetailSettingProps {
    prefixName: string
    config: ViewField
    schema: SchemaProtocol['schema']
    columns: ViewField[]
    onBack?: () => void
    onChange?: (val: ViewField) => void
}

export const DetailSetting: React.FunctionComponent<DetailSettingProps> = ({ prefixName, config, schema, columns, onBack, onChange }) => {
    const breadcrumbs: BreadcrumbItemProps[] = [
        {
            label: <SCxText>{config.title}</SCxText>,
            icon: <IconFont type="ArrowLeft" />,
            style: { display: 'flex', alignItems: 'center' },
            onClick: onBack
        }
    ]

    return (
        <InnerDrawer>
            <Breadcrumbs items={breadcrumbs} />
            <Divider color="var(--color-gray-200)" style={{ marginBottom: 12 }} />
            <ViewFieldConfigRenderer prefixName={prefixName} viewField={config} schema={schema} columns={columns} onChange={onChange} />
        </InnerDrawer>
    )
}
