import { Button, Group, IconFont } from '@byecode/ui'
import type { ChartViewOptions, SchemaProtocol } from '@lighthouse/core'
import { areaChartType, BasicHeader, disableAddMainAxisChartType } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { IndicatorSetting } from '../IndicatorSetting'

const ChartConfigurator = styled.div``

interface IndicatorConfiguratorProps {
    dsId: string
    schema: SchemaProtocol['schema']
}

const SCxText = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
    font-weight: 400;
`

const SCxIcon = styled(IconFont)`
    cursor: pointer;
`

export const IndicatorConfigurator: React.FC<IndicatorConfiguratorProps> = ({ dsId, schema }) => {
    const { control, setValue } = useFormContext<ChartViewOptions>()
    const chartType = useWatch({ control, name: 'chartType' })
    const showSecondaryAxis = useWatch({ control, name: 'showSecondaryAxis' })
    const dimensions = useWatch({ control, name: 'dimensions' })
    const aggregate = useWatch({ control, name: 'aggregate' })
    const mainAxis = useWatch({ control, name: 'mainAxis' })
    const isAreaChart = areaChartType.has(chartType)
    const isDisableAddMainAxisChartType = disableAddMainAxisChartType.has(chartType)
    // const isChartIndicator = chartType === 'indicator'
    const enableSecondaryAxis = chartType === 'composite' || chartType === 'bar' || chartType === 'line'
    const enableChartTypeSelector = chartType === 'composite' || chartType === 'bar' || chartType === 'line'
    const enableMainAxis = useMemo(() => {
        if (isDisableAddMainAxisChartType) {
            return dimensions.length === 0 || (dimensions.length === 1 && mainAxis.length === 0)
        }
        // if (isChartIndicator) {
        //     return mainAxis.length === 0
        // }
        return true
    }, [dimensions.length, isDisableAddMainAxisChartType, mainAxis.length])

    const handleAddSecondaryAxis = useCallback(() => {
        setValue('showSecondaryAxis', true)
    }, [setValue])

    const handleDeleteSecondaryAxis = useCallback(() => {
        setValue('showSecondaryAxis', false)
        setValue('secondaryAxis', [])
    }, [setValue])

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group label="指标-Y轴">
                {!isAreaChart && (
                    <BasicHeader margin justifyContent="space-between" alignItems="center">
                        <SCxText>主轴</SCxText>
                        <Button size="sm" onClick={handleAddSecondaryAxis}>
                            添加次轴
                        </Button>
                    </BasicHeader>
                )}
                <ChartConfigurator>
                    <Controller
                        control={control}
                        name="mainAxis"
                        render={({ field }) => (
                            <IndicatorSetting
                                dsId={dsId}
                                value={field.value}
                                onChange={field.onChange}
                                enableChartTypeSelector={enableChartTypeSelector}
                                disableAdd={!enableMainAxis}
                                aggregate={aggregate}
                                schema={schema}
                            />
                        )}
                    />
                </ChartConfigurator>
                {showSecondaryAxis && enableSecondaryAxis && (
                    <ChartConfigurator>
                        <BasicHeader margin justifyContent="space-between" alignItems="center">
                            <Text>次轴</Text>
                            <SCxIcon size={16} type="Close" onClick={handleDeleteSecondaryAxis} />
                        </BasicHeader>
                        <Controller
                            control={control}
                            name="secondaryAxis"
                            render={({ field }) => (
                                <IndicatorSetting
                                    dsId={dsId}
                                    onChange={field.onChange}
                                    enableChartTypeSelector={enableChartTypeSelector}
                                    value={field.value}
                                    aggregate={aggregate}
                                    schema={schema}
                                />
                            )}
                        />
                    </ChartConfigurator>
                )}
            </Group>
        </>
    )
}
