import { Button, ClickAwayListener, IconFont, Input, pointer, Popover, Toast } from '@byecode/ui'
import React, { useCallback, useRef, useState } from 'react'
import { useToggle } from 'react-use'
import styled from 'styled-components'

type TitleMode = 'input' | 'preview'
export interface DataAggregatorHeaderProps {
    title?: string
    updated?: boolean
    onCancel?: () => void
    onSave?: () => Promise<boolean>
    onDelete?: () => void
    onChangeName?: (val: string) => void
}

const SCxDataAggregatorHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-normal);
`

const SCxDataAggregatorLeft = styled.div`
    display: flex;
    align-items: center;
`
const SCxDataAggregatorRight = styled.div``

const SCxForm = styled.form``

const SCxPageTitlePreviewer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
`

const SCxPageTitleInput = styled(Input)`
    width: 200px;
    resize: none;
    overflow: hidden;
`

const SCxPageNavigatorAction = styled(IconFont)`
    margin-left: 12px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    color: var(--color-gray-500);

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: var(--font-size-normal);
    height: 36px;

    ${pointer}
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxListItemIcon = styled(IconFont)`
    margin-right: 8px;
`

const SCxListItemLabel = styled.div``

export const DataAggregatorHeader: React.FC<DataAggregatorHeaderProps> = ({
    title = '',
    updated,
    onCancel,
    onSave,
    onDelete,
    onChangeName
}) => {
    const [titleMode, setTitleMode] = useState<TitleMode>('preview')
    const [open, setOpen] = useState<boolean>(false)
    const [dataSourceName, setDataSourceName] = useState(title)
    const titleRef = useRef<HTMLInputElement>(null)
    const [loading, toggle] = useToggle(false)
    // const [cancelLoading, toggleCancel] = useToggle(false)
    const handleSave = async () => {
        toggle(true)
        const res = await onSave?.()
        toggle(false)
        if (res) {
            Toast.success('保存成功')
        } else {
            Toast.error('无法保存聚合表，请检查配置后重试')
        }
    }

    const handleOk = useCallback(() => {
        onChangeName?.(dataSourceName)
        setTitleMode('preview')
    }, [dataSourceName, onChangeName])

    const popoverCommonContent = (
        <Popover opened={open} onChange={setOpen} width={220} position="bottom-start">
            <Popover.Target>
                <SCxPageNavigatorAction type="DotsThree" />
            </Popover.Target>
            <Popover.Dropdown>
                <SCxListItem
                    onClick={() => {
                        setTitleMode('input')
                        setOpen(false)

                        requestAnimationFrame(() => {
                            titleRef.current?.select()
                        })
                    }}
                >
                    <SCxListItemIcon type="NotePencil" />
                    <SCxListItemLabel>重命名</SCxListItemLabel>
                </SCxListItem>
                <SCxListItem onClick={onDelete}>
                    <SCxListItemIcon type="Trash" />
                    <SCxListItemLabel>删除</SCxListItemLabel>
                </SCxListItem>
            </Popover.Dropdown>
        </Popover>
    )

    return (
        <SCxDataAggregatorHeader>
            <SCxDataAggregatorLeft>
                <Button
                    type="text"
                    icon={<IconFont type="ArrowLeftSmall" size={20} fill="var(--color-gray-300)" />}
                    size="sm"
                    onClick={onCancel}
                />
                {titleMode === 'preview' ? (
                    <SCxPageTitlePreviewer>{dataSourceName}</SCxPageTitlePreviewer>
                ) : (
                    <SCxForm onSubmit={handleOk}>
                        <ClickAwayListener onClickAway={handleOk}>
                            <SCxPageTitleInput
                                ref={titleRef}
                                value={dataSourceName}
                                onChange={ev => {
                                    setDataSourceName(ev.target.value)
                                }}
                            />
                        </ClickAwayListener>
                    </SCxForm>
                )}

                {popoverCommonContent}
            </SCxDataAggregatorLeft>
            <SCxDataAggregatorRight>
                <Button type="primary" disabled={!updated} loading={loading} onClick={handleSave}>
                    保存
                </Button>
            </SCxDataAggregatorRight>
        </SCxDataAggregatorHeader>
    )
}
