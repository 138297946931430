import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxAddVariableButton = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-main);
    border-radius: 3px;
`

const SCxIcon = styled(IconFont)``

export const AddVariableButton = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
    return (
        <SCxAddVariableButton {...props} ref={ref}>
            <SCxIcon type="Add" fill="var(--color-white)" size={16} />
        </SCxAddVariableButton>
    )
})
