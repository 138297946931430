import { Anchor,Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { NetEaseFreeAccountFormState, NetEaseFreeAccountMode } from './type'

interface NetEaseFreeAccountProps {
    id?: string
    mode: NetEaseFreeAccountMode
    onClose?: () => void
}

const initData: NetEaseFreeAccountFormState = {
    id: '',
    name: '',
    senderName: '',
    emailAddress: '',
    password: ''
}
const NetEaseFreeAccount: React.FunctionComponent<NetEaseFreeAccountProps> = ({ id, mode, onClose }) => {
    const { update: otherPlatformUpdate, data: otherPlatforms } = useOtherPlatformList()

    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform
            ? { ...otherPlatform?.config, name: otherPlatform.name, id, password: mode === 'add' ? '' : PLACEHOLDER_PASSWORD }
            : initData
    }, [id, mode, otherPlatforms])

    const [hide, setHide] = useState(true)
    const [loading, setLoading] = useState(false)

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm<NetEaseFreeAccountFormState>({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: NetEaseFreeAccountFormState) => {
            setLoading(true)
            const { name, senderName, emailAddress, password } = params
            const res = await srv.addIntegration({
                type: 'NET_EASE_126_MAIL',
                name,
                params: { password, senderName, emailAddress }
            })
            setLoading(false)
            if (res) {
                Toast.success(`账号${getValues('name')}已添加至账户。`)
                otherPlatformUpdate()
                onClose?.()
                return
            }
            Toast.error('请检查输入邮箱地址或密码是否正确。')
        },
        [getValues, onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        if (!id) {
            return
        }
        const title = getValues('name')
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WE_COM_MAIL']}-${title}」。`
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WE_COM_MAIL']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: NetEaseFreeAccountFormState) => {
            const { id, name, senderName, emailAddress, password } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                name,
                id,
                type: 'NET_EASE_126_MAIL',
                params: { senderName, emailAddress, password: password === PLACEHOLDER_PASSWORD ? undefined : password }
            })

            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.LogoContainer>
                    <SC.Icon size={58} type="NetEasyFree" color="#1E7E3E" />
                    <SC.Text color="var(--color-black)" size={32} style={{ fontWeight: 500 }}>
                        网易免费邮
                    </SC.Text>
                </SC.LogoContainer>
                <SC.Title style={{ marginTop: 7 }}>{mode === 'add' ? '请填写' : '编辑'}授权信息</SC.Title>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>账户名称</SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>

                    <SC.Description>用于在账户列表中展现名称，可自定义</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        发件人名称
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="senderName" errors={errors}>
                        <Input {...register('senderName', { required: '不能为空' })} />
                    </ErrorMessage>

                    <SC.Description>收件人看到的名称，请检查核对</SC.Description>
                </SC.Item>

                <SC.Item>
                    <SC.Text>
                        发件人名邮箱地址
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="emailAddress" errors={errors}>
                        <Input {...register('emailAddress', { required: '不能为空' })} />
                    </ErrorMessage>

                    <SC.Description>
                        如address@exmail.qq.com，请先在网易免费邮箱开启
                        <Anchor
                            style={{ color: 'var(--color-main)' }}
                            target="_blank"
                            to="https://help.mail.163.com/faqDetail.do?code=d7a5dc8471cd0c0e8b4b8f4f8e49998b374173cfe9171305fa1ce630d7f67ac25ef2e192b234ae4d"
                        >
                            SMTP
                        </Anchor>
                        服务
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        邮箱授权码
                        {mode === 'add' && (
                            <SC.Text color="red" style={{ paddingLeft: 4 }}>
                                *
                            </SC.Text>
                        )}
                    </SC.Text>
                    <ErrorMessage name="password" errors={errors}>
                        <Controller
                            name="password"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type={hide ? 'password' : 'text'}
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                        suffix={
                                            mode === 'add' && <SC.Icon onClick={() => setHide(!hide)} type={hide ? 'Hide' : 'NoHide'} />
                                        }
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: 'var(--color-gray-500)', borderColor: 'var(--color-gray-200)' }}
                            icon={<SC.Icon type="Trash" color="var(--color-gray-500)" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        {mode === 'add' ? '添加' : '保存'}
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default NetEaseFreeAccount
