import { type AppUser, type PageAbstract, PageOpenType } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { find, map, reduce } from 'rambda'
import { useMemo } from 'react'

import { commonRoleIds } from '@/atoms/application/constants'
import { appRoleAtom } from '@/atoms/application/state'
import { getDepartmentChildIds } from '@/components/UserSetting/help'
import { useDataSourceDepartments, useDataSourceUsers } from '@/shared/reusable'

import { usePageList } from './usePage'

export const useUserPageList = function () {
    const roleId = useAtomData(appRoleAtom)
    const pageList = usePageList()
    const { data: users = [] } = useDataSourceUsers()
    const { data: departments = [] } = useDataSourceDepartments()

    const currentUser = useMemo(() => find(user => user.userId === roleId, users), [roleId, users])

    const userPageList = useMemo(() => {
        const currentUserGroupId = currentUser?.groupId
        const currentUserDepartmentIds = currentUser?.departmentIds ?? []
        const noNeedPageList = pageList.filter(item => item.open === PageOpenType.all)
        if (roleId === 'visitor') {
            return noNeedPageList
        }
        if (roleId === 'developer') {
            return pageList
        }
        return reduce<PageAbstract, PageAbstract[]>(
            (preVal, curVal) => {
                if (curVal.open === PageOpenType.part) {
                    const groupIds = map(item => item.groupId, curVal.groups ?? [])
                    const isUserExitGroup = currentUserGroupId && groupIds.includes(currentUserGroupId)

                    const isUserExitDepartment = (curVal.departments ?? []).some(({ departmentId }) =>
                        currentUserDepartmentIds.includes(departmentId)
                    )

                    if (isUserExitGroup || isUserExitDepartment) {
                        return [...preVal, curVal]
                    }
                    return preVal
                }
                return [...preVal, curVal]
                // if (curVal.open === PageOpenType.part) {
                //     const groupIds = map(item => item.groupId, curVal.groups ?? [])
                //     const isUserExitGroup = currentUserGroupId && groupIds.includes(currentUserGroupId)
                //     const allPageDepartmentIds = curVal.departments.flatMap(department =>
                //         getDepartmentChildIds(department.departmentId, departments, [])
                //     )
                //     const allUserDepartmentIds = currentUser?.departmentIds?.flatMap(departmentId =>
                //         getDepartmentChildIds(departmentId, departments, [])
                //     )
                //     const isUserExitDepartment = allPageDepartmentIds.some(id => allUserDepartmentIds?.includes(id))

                //     if (isUserExitGroup || isUserExitDepartment) {
                //         return [...preVal, curVal]
                //     }
                //     return preVal
                // }
                // return [...preVal, curVal]
            },
            [],
            pageList
        )
    }, [currentUser?.departmentIds, currentUser?.groupId, pageList, roleId])

    const allRolePageList = useMemo(() => {
        const currentUserGroupId = currentUser?.groupId
        if (roleId === 'visitor') {
            return pageList.map(page => ({ ...page, isAccess: page.open === PageOpenType.all }))
        }
        if (roleId === 'developer') {
            return pageList.map(page => ({ ...page, isAccess: true }))
        }
        return pageList.map(page => {
            if (page.open === PageOpenType.part) {
                const groupIds = map(item => item.groupId, page.groups ?? [])
                const isUserExitGroup = currentUserGroupId && groupIds.includes(currentUserGroupId)
                const allPageDepartmentIds = page.departments.flatMap(department =>
                    getDepartmentChildIds(department.departmentId, departments, [department.departmentId])
                )
                const allUserDepartmentIds = currentUser?.departmentIds?.flatMap(departmentId =>
                    getDepartmentChildIds(departmentId, departments, [departmentId])
                )
                const isUserExitDepartment = allPageDepartmentIds.some(id => allUserDepartmentIds?.includes(id))

                return { ...page, isAccess: Boolean(isUserExitDepartment || isUserExitGroup) }
            }
            return { ...page, isAccess: true }
        })
    }, [currentUser?.departmentIds, currentUser?.groupId, departments, pageList, roleId])

    const user: AppUser | undefined = useMemo(() => {
        if (commonRoleIds.includes(roleId)) {
            return
        }
        return currentUser
    }, [currentUser, roleId])

    return useMemo(() => ({ visitPageList: userPageList, user, roleId, allRolePageList }), [allRolePageList, roleId, user, userPageList])
}
