export enum UserGroupType {
    custom = 1
    // system
}

export interface UserGroup {
    groupId: string
    groupName: string
    type: UserGroupType
}

export interface UserGroupProtocols {
    groups: UserGroup[]
}

export interface UserDepartment {
    departmentId: string
    departmentName: string
}

export interface UserDepartmentProtocols {
    departments: UserDepartment[]
}
