import { type ActionFlowNode, type OpenPageActionConfig, commonPages } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleOpenPage = async (node: ActionFlowNode<OpenPageActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { actionEvents, clickTriggerNodeParams } = excParams

    const { record, currentAppId } = clickTriggerNodeParams

    const { openPageId, pageOpenType } = config as OpenPageActionConfig
    const { openPage } = actionEvents

    if (!openPageId || !actionEvents) {
        return excParams
    }
    const pageId = openPageId.replace('/page/', '')
    if (commonPages.includes(pageId)) {
        return excParams
    }

    openPage({ pageId, openType: pageOpenType, appId: record?.appId, dsId: record?.dsId, recordId: record?.id })

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: true
    })
}
