import type { IconicProtocol, WorkSpaceAbstract } from '@lighthouse/core'
import { atom } from 'jotai'
import { findIndex } from 'rambda'

import * as srv from '@/services'

import { applyDraftPayload } from '../utils/applyDraftPayload'
import { activityListAtom, currentWorkSpaceIdAtom, workSpaceAtom, workSpaceListAtom } from './state'
import type { QuitWorkSpaceAtomPayload } from './types'

/**
 * 更新空间信息
 */
export const updateWorkSpaceAtom = atom(null, async (_, set, payload: WorkSpaceAbstract) => {
    const isSuccess = await srv.updateWorkSpace(payload.id, payload)
    if (isSuccess) {
        set(workSpaceAtom, draft => {
            if (draft) {
                applyDraftPayload(draft, payload)
            }
        })
    }
    return isSuccess
})

export const generateApiTokenAtom = atom(null, async (_, set, spaceId: string) => {
    const apiToken = await srv.generateApiToken(spaceId)
    if (apiToken) {
        set(workSpaceAtom, draft => {
            draft.apiKey = apiToken
        })
    }
    return apiToken
})

export const fetchWorkSpaceListAtom = atom(null, async (get, set) => {
    const data = await srv.getWorkSpaceList()
    set(workSpaceListAtom, data)
    return data
})


export const createWorkSpaceAtom = atom(null, async (_, set, payload: IconicProtocol) => {
    const { id } = await srv.createWorkSpace(payload)
    if (!id) {
        throw new Error('创建空间失败')
    }

    return id
})

export const removeWorkSpaceAtom = atom(null, async (_, set, id: string) => {
    const isDelete = await srv.deleteWorkSpace(id)
    set(workSpaceListAtom, draft => {
        const index = findIndex(item => item.id === id, draft)
        draft.splice(index, 1)
    })
    return isDelete
})

export const quitWorkSpaceAtom = atom(null, async (_, set, payload: QuitWorkSpaceAtomPayload) => {
    const { userId, spaceId } = payload
    const res = await srv.removeUsersOfSpace(spaceId, userId)
    set(workSpaceListAtom, draft => {
        const index = findIndex(item => item.id === spaceId, draft)
        draft.splice(index, 1)
    })
    return res
})

export const setWorkSpaceIdAtom = atom(null, (_, set, id: string) => {
    set(currentWorkSpaceIdAtom, id)
})

export const fetchWorkSpaceAtom = atom(null, async (_, set, id: string) => {
    const data = await srv.getWorkSpace(id)
    set(workSpaceAtom, data)
    return data
})


export const fetchActivityListAtom = atom(null, async (get, set) => {
    const data = await srv.getActivityList()
    set(activityListAtom, data)
    return data
})


