import { ApplicationType } from '@lighthouse/core'
import { getUrlNameByOpenType, useAtomData } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { CURRENT_PAGE, HOME_PAGE } from '@/atoms/application/constants'
import { appAtom, sharePageAtom } from '@/atoms/application/state'
import { defaultPageListAtom, pageStackBreadcrumbAtom } from '@/atoms/page/state'

import { useDefaultPageList } from './usePage'

type Option = {
    isCurrentPage?: boolean
    isProdHost?: boolean
}

export function usePageURI(option?: Option) {
    const { isCurrentPage = true, isProdHost } = option ?? {}
    const stackList = useAtomData(pageStackBreadcrumbAtom)
    const pageList = useDefaultPageList()
    const sharePageId = useAtomData(sharePageAtom)
    const sharePage = useMemo(() => pageList.find(item => item.id === sharePageId), [pageList, sharePageId])
    const homePage = useAtomData(
        defaultPageListAtom,
        useCallback(list => list.find(p => p.isHome), [])
    )

    const host = useAtomData(
        appAtom,
        useCallback(
            s => {
                if (s.type !== ApplicationType.website) {
                    return ''
                }

                return isProdHost
                    ? s.config.domain.isUsedCustomDomain && s.config.domain.customDomain
                        ? s.config.domain.customDomain
                        : `${s.config.domain.domainPrefix}.${s.mainDomain}`
                    : s.version.url
            },
            [isProdHost]
        )
    )

    const shareUrl = useMemo(() => {
        if (sharePageId === CURRENT_PAGE || isCurrentPage) {
            const stackUrl = stackList.reduce((prev, curr, index) => {
                if (index === 0) {
                    const page = pageList.find(item => item.id === curr.pageId)
                    if (!page) {
                        return prev
                    }

                    return page.route ? `/${page.route}` : `/P/${page.id}`
                }
                const urlParams = {
                    [getUrlNameByOpenType(curr.stackDisplayType)]: curr.pageId,
                    a: curr.appId,
                    d: curr.dsId,
                    r: curr.recordId,
                    i: JSON.stringify(curr.initialRecordValue)
                }
                return (
                    prev +
                    Object.entries(urlParams)
                        .map(([k, v]) => (v ? `/${k}/${v}` : ''))
                        .join('')
                )
            }, '')
            return `${host}${stackUrl}`
        }
        if (sharePageId === HOME_PAGE && homePage) {
            return homePage.route ? `${host}/${homePage.route}` : `${host}/P/${homePage.id}`
        }
        return sharePage?.route ? `${host}/${sharePage.route}` : `${host}/P/${sharePageId}`
    }, [homePage, host, pageList, isCurrentPage, sharePage?.route, sharePageId, stackList])

    return useMemo(() => ({ shareUrl: `https://${shareUrl}`, host }), [host, shareUrl])
}
