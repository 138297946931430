import { backgroundTransitionOnClick, flex, IconFont, Input, pointer, Select } from '@byecode/ui'
import styled from 'styled-components'

export const BlockSettingsInput: typeof Input = styled(Input)<{ width?: string }>`
    flex: 1;
    width: ${({ width }) => width ?? '100%'};
    flex: none;
    .mantine-Input-input {
        min-height: 32px;
        height: 32px;
        line-height: 30px;
    }
`

export const BlockSettingsSelect: typeof Select = styled(Select)``

export const Text = styled.div<{ color?: string }>`
    width: 56px;
    text-align: left;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: var(--font-size-normal);
    box-sizing: border-box;
`

export const Button = styled.div<{ bgColor: string; justifyContent?: string }>`
    width: 100%;
    ${flex}
    height: 32px;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
    border-radius: 6px;
    background-color: ${({ bgColor }) => bgColor};
    overflow: hidden;

    ${Text} {
        padding-left: 4px;
    }
`

export const Icon = styled(IconFont)<{ bgColor?: string }>`
    width: 32px;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor};
    font-size: var(--font-size-base);
`

export const List = styled.div``
export const Item = styled.div<{ disabled?: boolean }>`
    position: relative;
    width: 202px;
    height: 36px;
    padding: 0 16px;
    ${flex}
    align-items: center;
    ${pointer}
    &[disabled]:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        cursor: not-allowed;
        z-index: 2;
    }

    ${backgroundTransitionOnClick}
`
export const ItemIcon = styled(IconFont)`
    margin-right: 16px;
    font-size: var(--font-size-base);
`
export const ItemText = styled.div`
    height: 24px;
    line-height: 24px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-normal);
`
export const LightText = styled.div<{ top?: boolean }>`
    margin-top: ${({ top }) => (top ? 10 : 0)}px;
    padding: 0 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const Container = styled.div`
    padding: 8px 0;
    background-color: var(--color-white);
    > *:not(:first-child) {
        margin-top: 12px;
    }
`

export const BlockSettingsBox = styled.div`
    padding: 8px 4px;
`

export const RightFill = styled.div`
    width: 130px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    > * {
        margin-left: 4px;
    }
`
