export enum ModuleType {
    theme,
    navbarTitle,
    tabBar,
    login,
    leftPanel,
    webTag,
    seo,
    domain,
    advertising,
    lang,
    webApp,
    sms
}

export type ApplicationSettingTabs = {
    label: string
    value: ModuleType
    icon: string
    iconColor: string
}[]

export enum PreviewPage {
    webRegister,
    webApp,
    wxAppletRegister,
    wxAppletApp
}

export const ModuleTypeMap = new Map<ModuleType, string>([
    [ModuleType.theme, 'theme'],
    [ModuleType.tabBar, 'tabBar'],
    [ModuleType.login, 'login'],
    [ModuleType.leftPanel, 'leftPanel'],
    [ModuleType.navbarTitle, 'navbarTitle'],
    [ModuleType.webTag, 'webTag'],
    [ModuleType.seo, 'seo'],
    [ModuleType.domain, 'domain'],
    [ModuleType.advertising, 'advertising'],
    [ModuleType.lang, 'lang'],
    [ModuleType.webApp, 'webApp'],
    [ModuleType.sms, 'sms']
])
