import { enableAllPlugins, setAutoFreeze, setUseProxies } from 'immer'
// @ts-expect-error missing types
import * as hasOwn from 'object.hasown'

setUseProxies(true)
setAutoFreeze(true)
enableAllPlugins()

if (!Object.hasOwn) {
    hasOwn.shim()
}

// 解决 react-floater  global is not defined
if (typeof window.global === 'undefined') {
    window.global = window
}
