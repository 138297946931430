import { IconFont, Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { DataSourceAbstract } from '@lighthouse/core'
import { type AggregatorNode, AggregatorNodeType, aggregatorNodeTypeDescription, geyAggregatorNodeIcon } from '@lighthouse/shared'
import type { TabsValue } from '@mantine/core'
import { Tabs } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'

import { NodeTypeColorMap } from '../../AggregatorEditor/constants'
import { aggregatorNodeNameMap } from '../../constants'
import { otherNodeResultGenerator } from '../../utils'
import * as SC from '../styles'
import { TabStyles } from '../styles'
import { TablePreview } from '../TablePreview'
import { AggregatorJoinConfig } from './AggregatorJoinConfig'

const noDataDependenceImg = getAssetUrl('empty', 'no_data_dependence.png')
const noDataImperfectImg = getAssetUrl('empty', 'no_data_imperfect.png')
const noDataTableImg = getAssetUrl('empty', 'no_data_table.png')

interface JoinProps {
    dsId: string
    nodeId: string
    nodeList: AggregatorNode[]
    dataSourceList: DataSourceAbstract[]
}

export const SCxJoinDescription = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-gray-900);
`

export const SCxJoinDescriptionItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

export const SCxVertical = styled.div`
    width: 1px;
    height: 11px;
    background: var(--color-gray-200);
`

export const SCxJoinTag = styled.div`
    height: 22px;
    display: flex;
    align-items: center;
    background: var(--color-gray-100);
    border-radius: 4px;
    padding: 0 6px 0 2px;
`

export const SCxJoinNode = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const SCxJoinNodeTitle = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`

export const SCxText = styled.div<{ color?: string }>`
    font-size: var(--font-size-sm);
    line-height: 1.5;
    color: ${({ color }) => color || 'var(--color-gray-900)'};
`

export const SCxJoinNodeDescription = styled.div`
    color: var(--color-gray-400);
    line-height: 1.5;
`

export const Join: React.FC<JoinProps> = ({ dsId, nodeId, nodeList, dataSourceList }) => {
    const [tab, setTab] = useState('config')
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const handleTabChange = useCallback((value: TabsValue) => {
        if (value) {
            setTab(value)
        }
    }, [])

    const [leftNode, rightNode] = nodeList

    const isEmptyDataSource = useMemo(() => {
        return nodeList.some(node => {
            if (node.type === AggregatorNodeType.DATA_INPUT) {
                return !node.data.config.dsId
            }
            return false
        })
    }, [nodeList])

    const isEmptyFieldList = useMemo(() => {
        const fieldList = otherNodeResultGenerator(nodeList)
        return fieldList.length === 0
    }, [nodeList])

    const configureContent = useMemo(() => {
        if (isEmptyDataSource) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 144, height: 144 }} src={noDataTableImg} alt="未找到数据" />}
                    description="请先选择要聚合的表格"
                />
            )
        }
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 315 }} src={noDataDependenceImg} alt="未找到数据" />}
                    description="请先完成依赖节点的配置"
                />
            )
        }
        return <AggregatorJoinConfig nodeList={nodeList} />
    }, [isEmptyDataSource, isEmptyFieldList, nodeList])

    const previewContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 202 }} src={noDataImperfectImg} alt="未找到数据" />}
                    description="预览数据前，请先完成节点配置"
                />
            )
        }
        return <TablePreview appId={appId} envId={envId} dsId={dsId} nodeId={nodeId} active={tab === 'preview'} />
    }, [appId, dsId, envId, isEmptyFieldList, nodeId, tab])

    const getNodeDescription = useCallback(
        (node?: AggregatorNode) => {
            if (!node || !node?.type) {
                return ''
            }
            if (node.type === AggregatorNodeType.DATA_INPUT) {
                const { dsId } = node.data.config
                const ds = find(item => item.id === dsId, dataSourceList)
                return ds?.name
            }
            return aggregatorNodeTypeDescription[node.type]
        },
        [dataSourceList]
    )

    return (
        <Tabs color="var(--color-main)" value={tab} onTabChange={handleTabChange} styles={TabStyles}>
            <Tabs.List>
                <Tabs.Tab value="config">
                    <SC.TabText isActive={tab === 'config'}>节点配置</SC.TabText>
                </Tabs.Tab>
                <Tabs.Tab value="preview" color="blue">
                    <SC.TabText isActive={tab === 'preview'}>
                        数据预览
                        <SC.TabTooltip>
                            <Tooltip title="预览前20条数据">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </SC.TabTooltip>
                    </SC.TabText>
                </Tabs.Tab>
                <SCxJoinDescription>
                    <SCxJoinDescriptionItem>
                        <SCxJoinTag>
                            <IconFont size={16} type="Left" fill="var(--color-gray-500)" />
                            <SCxText color="var(--color-gray-500)">左表</SCxText>
                        </SCxJoinTag>
                        <SCxJoinNode>
                            <SCxJoinNodeTitle>
                                <IconFont
                                    type={geyAggregatorNodeIcon(leftNode)}
                                    fill={NodeTypeColorMap[leftNode.type || AggregatorNodeType.DATA_INPUT]}
                                />
                                <SCxText>{aggregatorNodeNameMap[leftNode.type || AggregatorNodeType.DATA_INPUT]}</SCxText>
                            </SCxJoinNodeTitle>
                            <SCxJoinNodeDescription>{getNodeDescription(leftNode)}</SCxJoinNodeDescription>
                        </SCxJoinNode>
                    </SCxJoinDescriptionItem>
                    <SCxJoinDescriptionItem>
                        <SCxVertical />
                    </SCxJoinDescriptionItem>
                    <SCxJoinDescriptionItem>
                        <SCxJoinTag>
                            <IconFont size={16} type="Right" fill="var(--color-gray-500)" />
                            <SCxText color="var(--color-gray-500)">右表</SCxText>
                        </SCxJoinTag>
                        <SCxJoinNode>
                            <SCxJoinNodeTitle>
                                <IconFont
                                    type={geyAggregatorNodeIcon(rightNode)}
                                    fill={NodeTypeColorMap[rightNode.type || AggregatorNodeType.DATA_INPUT]}
                                />
                                <SCxText>{aggregatorNodeNameMap[rightNode.type || AggregatorNodeType.DATA_INPUT]}</SCxText>
                            </SCxJoinNodeTitle>
                            <SCxJoinNodeDescription>{getNodeDescription(rightNode)}</SCxJoinNodeDescription>
                        </SCxJoinNode>
                    </SCxJoinDescriptionItem>
                </SCxJoinDescription>
            </Tabs.List>

            <Tabs.Panel value="config" pt="xs">
                <SC.PanelContent>{configureContent}</SC.PanelContent>
            </Tabs.Panel>

            <Tabs.Panel value="preview" pt="xs">
                <SC.PreviewContent>{previewContent}</SC.PreviewContent>
            </Tabs.Panel>
        </Tabs>
    )
}
