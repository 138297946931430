import { Radio } from '@byecode/ui'
import React, { useCallback } from 'react'

import { Unit } from '../Mics'

export const Disabled: React.FC = () => {
    const [value, setValue] = React.useState('xs')
    const handleChange = useCallback((val: string) => {
        setValue(val)
    }, [])

    return (
        <Unit title="禁用">
            <Radio disabled size="xs" checked={value === 'xs'} onChange={() => handleChange('xs')} />
            <Radio size="sm" checked={value === 'sm'} onChange={() => handleChange('sm')} />
            <Radio disabled checked={value === 'md'} onChange={() => handleChange('md')} />
            <Radio size="lg" checked={value === 'lg'} onChange={() => handleChange('lg')} />
        </Unit>
    )
}
