import { Toast } from '@byecode/ui'
import type { WorkSpaceAbstract } from '@lighthouse/core'
import { ErrorMessage, getAuthorize } from '@lighthouse/shared'
import { useUpdateEffect } from '@react-hookz/web'
import Uploady from '@rpldy/uploady'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { uploadInSpaceParams } from '@/utils/auth'

import { Header } from '../Header'
import * as SC from './styles'
import type { SpaceDataProps, SpaceForm } from './types'
import { UploadBtn } from './UploadButton'

export const SpaceData: React.FC<SpaceDataProps> = ({ workspace, onUpdate }) => {
    const submitEl = useRef<HTMLInputElement>(null)
    const [data, setData] = useState<WorkSpaceAbstract>(workspace)
    const { id: spaceId, createdTime, members, icon, roleId = '' } = data
    const spaceInformationManager = useMemo(() => getAuthorize(workspace.roleId || '', 'spaceInformationManager'), [workspace.roleId])

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors }
    } = useForm<SpaceForm>({
        reValidateMode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            ...workspace
        }
    })

    useUpdateEffect(() => {
        reset(workspace)
    }, [workspace])

    const handleUpdateSubmit = useCallback(
        async (formData: SpaceForm) => {
            const param = {
                ...data,
                name: formData.name
            }
            const isSuccess = await onUpdate(param)
            if (isSuccess) {
                Toast.success('修改成功')
            }
        },
        [data, onUpdate]
    )

    // 上传空间头像
    const handleUpdateImg = useCallback(
        (img: string) => {
            setData({
                ...data,
                icon: img
            })
        },
        [data]
    )

    const readySubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        return submitEl.current?.click()
    }

    return (
        <SC.Container>
            <SC.SpaceInfo>
                <Header title="资料" />
                <SC.SpaceForm
                    onSubmit={(ev: React.FormEvent) => {
                        ev.preventDefault()
                        handleSubmit(handleUpdateSubmit)()
                    }}
                >
                    <SC.FormGroup>
                        <SC.FormLabel>头像</SC.FormLabel>
                        <Uploady accept="image/*" {...uploadInSpaceParams({ spaceId })}>
                            <UploadBtn
                                name={getValues('name')}
                                disabled={!spaceInformationManager}
                                img={icon}
                                radius="8px"
                                onUpdateImg={handleUpdateImg}
                            />
                        </Uploady>
                    </SC.FormGroup>
                    <SC.FormGroup>
                        <SC.FormLabel>空间名</SC.FormLabel>
                        <ErrorMessage name="name" errors={errors} style={{ flex: 1 }}>
                            <SC.FormInput
                                disabled={!spaceInformationManager}
                                maxLength={20}
                                placeholder="请输入空间名"
                                {...register('name', {
                                    required: '请输入空间名'
                                })}
                            />
                        </ErrorMessage>
                    </SC.FormGroup>
                    <SC.HideSubmit ref={submitEl} type="submit" />
                    <SC.FormGroup>
                        <SC.FormLabel>空间创建于</SC.FormLabel>
                        <SC.FormContent>{createdTime}</SC.FormContent>
                    </SC.FormGroup>
                    <SC.FormGroup>
                        <SC.FormLabel>空间人数</SC.FormLabel>
                        <SC.FormContent>{members}人</SC.FormContent>
                    </SC.FormGroup>
                    <SC.FormGroup>
                        <SC.FormLabel>空间拥有者</SC.FormLabel>
                        <SC.FormContent>{workspace.createdBy}</SC.FormContent>
                    </SC.FormGroup>
                    <SC.Footer>
                        {spaceInformationManager && (
                            <SC.OkBtn size="md" type="primary" radius={7} onClick={readySubmit}>
                                保存设置
                            </SC.OkBtn>
                        )}
                    </SC.Footer>
                </SC.SpaceForm>
            </SC.SpaceInfo>
        </SC.Container>
    )
}
