import { Flex, IconFont, Select, Text } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { SMS_PLATFORM } from '@lighthouse/core'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import type { AliyunAccountMode } from '@/components/OtherPlatFormModal/AliyunAccount'
import { AliyunCodeAccountModal } from '@/components/OtherPlatFormModal/AliyunAccount'
import { SmsPlatformOptions } from '@/constants/common'
import { useGetAliyunMessageInfo, useOtherPlatformList } from '@/shared/reusable'

const StyledAddButton = styled.button`
    all: unset;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color-main);
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-50);
    }
`

const StyledPreviewBox = styled.div`
    margin-top: 4px;
    padding: 12px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 22px;
    font-style: 14px;
    background-color: var(--color-gray-50);
`

export const SelfSms:React.FC<{ prefix: string }> = ({ prefix }) => {
    const { control, watch } = useFormContext()

    const [smsPlatform, smsAccount, smsTemplate] = watch([`${prefix}.smsPlatform`, `${prefix}.smsAccount`, `${prefix}.smsTemplate`])
    const isAliyun = smsPlatform === SMS_PLATFORM.ALIYUN

    const { data: otherPlatforms } = useOtherPlatformList()

    const aliyunPlatformAccounts = useMemo(() => {
        const aliyunPlatforms = otherPlatforms?.filter(item => item.type === 'ALIYUN_SMS') ?? []

        return aliyunPlatforms.map(item => ({
            label: item.name,
            value: item.id,
            extra: (
                <IconFont
                    type="edit"
                    fill="var(--color-gray-400)"
                    size={16}
                    onMouseDown={e => {
                        e.stopPropagation()
                        setAliyunModalState({ open: true, mode: 'update', editId: item.id })
                    }}
                />
            )
        }))
    }, [otherPlatforms])

    const { data: aliyunInfo } = useGetAliyunMessageInfo(smsAccount)
    const aliyunMessageSignaturesOptions = useMemo(() => {
        return aliyunInfo?.signatures?.map(item => ({
            value: item.name,
            label: item.name
        }))
    }, [aliyunInfo?.signatures])

    const aliyunMessageTemplatesOptions = useMemo(() => {
        return (
            aliyunInfo?.templates.map(item => ({
                value: item.id,
                label: item.name,
                content: item.content
            })) ?? []
        )
    }, [aliyunInfo?.templates])

    const [aliyunModalState, setAliyunModalState] = useState({
        editId: '',
        open: false,
        mode: 'add' as AliyunAccountMode
    })

    return (
        <>
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>
                    短信平台{' '}
                    <Text inline color="var(--color-red-500)">
                        *
                    </Text>
                </Text>
                <Controller
                    control={control}
                    name={`${prefix}.smsPlatform`}
                    render={({ field }) => <Select {...field} style={{ width: 180 }} options={SmsPlatformOptions} />}
                />
            </ListItemPaddingByecodeUi>

            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>
                    选择账号{' '}
                    <Text inline color="var(--color-red-500)">
                        *
                    </Text>
                </Text>
                <Controller
                    control={control}
                    name={`${prefix}.smsAccount`}
                    render={({ field }) => (
                        <Select
                            {...field}
                            styles={{
                                root: { width: 180 },
                                dropdownExtra: {
                                    paddingTop: 8,
                                    borderTop: '1px solid var(--color-gray-200)'
                                }
                            }}
                            options={isAliyun ? aliyunPlatformAccounts : undefined}
                            position='bottom-end'
                            dropdownProps={
                                isAliyun
                                    ? {
                                          emptyComponent: (
                                              <Flex pt={14.5} pb={22.5} gap={8} direction="column" alignItems="center">
                                                  <IconFont type="aliyun" color="var(--color-gray-300)" size={32} />
                                                  <Text size={14} color="var(--color-gray-500)">
                                                      您还没添加{' '}
                                                      <span style={{ fontWeight: 600 }}>
                                                          阿里云短信
                                                      </span>
                                                      {' '}的账号
                                                  </Text>
                                              </Flex>
                                          ),
                                          extra: (
                                              <StyledAddButton
                                                  type="button"
                                                  onClick={() => {
                                                      setAliyunModalState({ open: true, mode: 'add', editId: '' })
                                                  }}
                                              >
                                                  <IconFont type="Add" />
                                                  <Text>添加账号</Text>
                                              </StyledAddButton>
                                          )
                                      }
                                    : undefined
                            }
                        />
                    )}
                />
            </ListItemPaddingByecodeUi>

            {smsAccount && (
                <>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>
                            短信签名{' '}
                            <Text inline color="var(--color-red-500)">
                                *
                            </Text>
                        </Text>
                        <Controller
                            control={control}
                            name={`${prefix}.smsSign`}
                            render={({ field }) => <Select {...field} style={{ width: 180 }} options={aliyunMessageSignaturesOptions} />}
                        />
                    </ListItemPaddingByecodeUi>

                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>
                            短信模板{' '}
                            <Text inline color="var(--color-red-500)">
                                *
                            </Text>
                        </Text>
                        <Controller
                            control={control}
                            name={`${prefix}.smsTemplate`}
                            render={({ field }) => <Select {...field} style={{ width: 180 }} options={aliyunMessageTemplatesOptions} />}
                        />
                    </ListItemPaddingByecodeUi>

                    {smsTemplate && (
                        <StyledPreviewBox>
                            <Text color="var(--color-gray-500)">短信模板内容预览：</Text>
                            <Text whiteSpace={undefined} style={{ overflow: 'initial' }}>
                                {aliyunMessageTemplatesOptions.find(item => item.value === smsTemplate)?.content}
                            </Text>
                        </StyledPreviewBox>
                    )}
                </>
            )}

            <AliyunCodeAccountModal
                id={aliyunModalState.editId}
                open={aliyunModalState.open}
                onClose={() => {
                    setAliyunModalState(s => ({ ...s, open: false }))
                }}
                mode={aliyunModalState.mode}
            />
        </>
    )
}
