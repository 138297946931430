import { type CustomInputValueRenderProps, Flex, IconFont } from '@byecode/ui'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxFieldContainer = styled.div`
    min-width: 100px;
    height: 32px;
    border: 1px solid transparent;
    padding: 0 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    &:focus {
        color: var(--color-gray-800);
        border-color: var(--color-main);
    }
`

const SCxRepeatText = styled.span`
    line-height: 32px;
`

export const RenderLabel: React.FC<CustomInputValueRenderProps> = ({ selectOptions, options }) => {
    const label = useMemo(() => {
        if (selectOptions.length === 0 || !options || options.length === 0) {
            return ''
        }
        if (options.length === selectOptions.length) {
            return '全选'
        }
        return `${selectOptions.length}个`
    }, [selectOptions.length, options])

    return (
        <SCxFieldContainer>
            <Flex alignItems="center" justifyContent="space-between" gap="6px" style={{ height: '100%' }}>
                <SCxRepeatText>{label}</SCxRepeatText>
                <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
            </Flex>
        </SCxFieldContainer>
    )
}
