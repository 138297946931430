import type { ActionFlowNode } from '@lighthouse/shared'
import { waitFor } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleDeleteRecord = async (node: ActionFlowNode, excParams: AnyObject) => {
    const { actionEvents, triggerNodeId } = excParams
    let isDeleted = false
    if (actionEvents) {
        const { deleteRecord, closeCurrentPageLayer } = actionEvents
        const record = excParams?.[triggerNodeId]?.record
        const params = record ? { dsId: record.dsId, recordId: record.id } : undefined
        isDeleted = await deleteRecord(params)

        if (isDeleted) {
            closeCurrentPageLayer()
        }
        await waitFor(300)
    }

    return {
        success: isDeleted
    }
}
