import { IconFont } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

const SCxContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
`

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-500);
    margin-bottom: 4px;
`

const ModeSegmentedItem = styled.button<{ active?: boolean }>`
    flex: 1;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    border-radius: 8px;

    &:not([disabled]):hover {
        color: var(--color-main);
        background: var(--color-gray-100);
        font-weight: 600;
        ${SCxIcon} {
            color: var(--color-main);
        }
    }

    ${({ active }) =>
        active &&
        css`
            color: var(--color-main);
            background: var(--color-gray-100);
            font-weight: 600;

            ${SCxIcon} {
                color: var(--color-main);
            }
        `}
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

interface ModeSegmentedProps {
    className?: string
    style?: React.CSSProperties
    value?: string
    options: { label: string; value: string; icon?: string }[]
    disabled?: boolean
    onChange?: (val: string) => void
}

export const ModeSegmented: React.FC<ModeSegmentedProps> = ({ className, style, options, value, disabled, onChange }) => {
    return (
        <SCxContainer className={className} style={style}>
            {options.map(item => (
                <ModeSegmentedItem
                    disabled={disabled}
                    key={item.value}
                    active={value === item.value}
                    onClick={() => onChange?.(item.value)}
                >
                    {item.icon && <SCxIcon type={item.icon} size={24} />}
                    {item.label}
                </ModeSegmentedItem>
            ))}
        </SCxContainer>
    )
}
