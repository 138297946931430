import { backgroundTransitionOnClick, IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`
export const Text = styled.div<{ size?: number; color?: string; isOmit?: boolean }>`
    display: inline-block;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: 24px;
`

export const Description = styled.div`
    margin-top: 6px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
`

export const Item = styled.div`
    width: 100%;
    margin-top: 28px;

    > *:nth-child(2) {
        margin-top: 8px;
    }
`

export const Container = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);
`

export const Content = styled.div`
    width: 100%;
    padding: 16px 40px;
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
    margin-bottom: 24px;
    margin-top: 20px;
`

export const Footer = styled.div`
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    align-items: center;
    background: var(--color-gray-50);
    border-radius: 0px 0px inherit inherit;
`
export const RightFill = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    order: 2;
`
export const LeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    order: 1;
`

export const Tip = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 20px;
    padding: 12px;
    height: 46px;
    border-radius: 6px;
    border: 1px solid var(--color-theme-7);
    background: var(--color-theme-2);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`
