import { IconFont, Tag } from '@byecode/ui'
import type { DataSourceType } from '@lighthouse/core'
import type { DataSourceModifyWay } from '@lighthouse/shared'
import { getTableIcon } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { mergeStyle, mergeText } from '../constant'
import { MergeGroup } from './MergeGroup'

const SCxLabel = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
`

const SCxIcon = styled(IconFont)``

const SCxTitle = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-white);
`

interface DataSourceGroupProps {
    dsType: DataSourceType
    dsName: string
    sync: boolean
    type: DataSourceModifyWay
    children?: React.ReactNode
}

export const DataSourceGroup: React.FC<DataSourceGroupProps> = ({ dsName, dsType, type, sync, children }) => {
    const tableIcon = getTableIcon({ type: dsType, sync })
    const color = mergeStyle[type] || 'green'

    return (
        <MergeGroup
            defaultOpen={false}
            enableFixed
            iconColor="var(--color-white)"
            headerBackground={`var(--color-${color}-500)`}
            label={
                <SCxLabel>
                    <SCxIcon type={tableIcon} fill="#ffffffc0" />
                    <SCxTitle>{dsName}</SCxTitle>
                </SCxLabel>
            }
            extra={
                <Tag
                    color={`var(--color-${color}-500)`}
                    radius={4}
                    background={`var(--color-${color}-100)`}
                    style={{
                        padding: '2px 6px',
                        fontSize: 12
                    }}
                >
                    {mergeText[type]}
                </Tag>
            }
        >
            {children}
        </MergeGroup>
    )
}
