import type { ImageOptions } from '@lighthouse/core'

/**
 * 生成图片block的配置
 *
 * @returns {ImageOptions}
 */
export const generateImageBlock = (): ImageOptions => {
    return {
        sources: [],
        variant: 'default',
        shape: 'rectangle',
        ratio: '0',
        radius: '8',
        fitType: 'cover',
        align: 'center',
        imageClickEvent: 'none',
        sourceType: 'default',
        isPreview: true,
        showTitle: true,
        autoLoop: false,
        swipeSpeed: 2,
        action: {
            type: 'none',
            trigger: 'click',
            data: {
                none: {}
            }
        }
    }
}
