import type { BaseFlowNode, FlowEdge, FlowNode, WorkflowType } from '@lighthouse/shared'
import { getAllParentNodes, LinkDocument, nodeTypeOptions } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import isEqual from 'fast-deep-equal'
import React, { useMemo, useRef } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { NodeHeader } from '../components/NodeHeader'
import { NodeSetting } from '../components/NodeSetting'

interface NodeConfigureProps {
    flowType: WorkflowType
    node: FlowNode
    nodes?: FlowNode[]
    edges?: FlowEdge[]
    onClose: () => void
    onChangeNodeConfig: (data: BaseFlowNode['data']) => void
}

export const NodeConfigurator: React.FC<NodeConfigureProps> = ({ node, nodes, edges, onClose, onChangeNodeConfig }) => {
    const nodeIdRef = useRef(node.id)
    const methods = useForm<BaseFlowNode['data']>({
        mode: 'onChange',
        defaultValues: node.data
    })

    const allParentNodes = useRef<FlowNode[]>(nodes && edges ? getAllParentNodes(node, nodes, edges) : [])
    // const allParentNodes = useMemo(() => (nodes && edges ? getAllParentNodes(node, nodes, edges) : []), [edges, node, nodes])

    const {
        control,
        watch,
        setValue,
        reset,
        handleSubmit,
        formState: { errors }
    } = methods

    const nodeType = watch('nodeType')

    const defaultValue = useMemo(() => {
        return nodeTypeOptions.find(item => item.value === nodeType)?.label || ''
    }, [nodeType])

    const formData = useWatch({ control })

    const debouncedFormData = useDebounce(formData, 500)

    useUpdateEffect(() => {
        reset(node.data)
    }, [node.id])

    useUpdateEffect(() => {
        if (nodeIdRef.current !== node.id) {
            nodeIdRef.current = node.id
            return
        }
        if (debouncedFormData && !isEqual(debouncedFormData, node)) {
            onChangeNodeConfig({
                ...(debouncedFormData as BaseFlowNode['data']),
                name: debouncedFormData.name || defaultValue
            })
        }
    }, [debouncedFormData])

    // useUpdateEffect(() => {
    //     setValue('config', )
    // }, [nodeType])

    return (
        <FormProvider {...methods}>
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <NodeHeader
                        defaultValue={defaultValue}
                        rightSection={<LinkDocument type={nodeType} />}
                        value={field.value}
                        onChange={field.onChange}
                        onClose={onClose}
                    />
                )}
            />
            <Divider color="var(--color-gray-200)" style={{ margin: '0 0 12px 0' }} />
            <NodeSetting nodeType={nodeType} allParentNodes={allParentNodes.current} />
            {/* <Controller name="config" control={control} render={({ field }) => } /> */}
        </FormProvider>
    )
}
