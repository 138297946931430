import { IconFont } from '@byecode/ui'
import { Textarea } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { PromptProgress } from './PromptProgress'

interface PromptContentProps {
    onConfirm?: (value: string) => void
}

const SCxPromptWrapper = styled.div``

const SCxPromptHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const SCxPromptHeaderIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: var(--color-gray-100);
    margin-right: 8px;
    border-radius: 8px;
`

const SCxPromptHeaderTitle = styled.h2`
    font-size: 20px;
    font-weight: 500;
`

const SCxPromptTip = styled.div`
    font-size: 12px;
    color: var(--color-gray-500);
    margin-bottom: 20px;
`

const SCxPromptChatInput = styled(Textarea)`
    width: 100%;
    margin-bottom: 12px;
`

const SCxPromptButton = styled.button`
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: 8px;
    padding: 8px;
    font-size: var(--font-size-sm);
    color: var(--color-white);
    background: linear-gradient(121.79deg, #5551ff 13.42%, #a74adf 91.28%);
`

const SCxPromptMessage = styled.div`
    background-color: var(--color-gray-100);
    border-radius: 8px;
    padding: 8px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    margin-bottom: 20px;
`

export const PromptContent: React.FC<PromptContentProps> = ({ onConfirm }) => {
    const [step, setStep] = useState<'prepare' | 'pending'>('prepare')
    const [prompt, setPrompt] = useState('')

    const handleCreateApp = useCallback(() => {
        prompt && setStep('pending')
        onConfirm?.(prompt)
    }, [onConfirm, prompt])

    const ref = useHotkeys<HTMLTextAreaElement>(
        'enter',
        ev => {
            ev.preventDefault()
            const textarea = ev.target as HTMLTextAreaElement
            const { value } = textarea
            if (value) {
                handleCreateApp()
            }
        },
        { enableOnTags: ['TEXTAREA'], enabled: !!prompt }
    )

    const content =
        step === 'prepare' ? (
            <>
                <SCxPromptTip>Byecode 人工智能可以根据您的描述在 1-2 分钟内创建一个定制的应用，之后您还可以编辑它。</SCxPromptTip>
                <SCxPromptChatInput
                    ref={ref}
                    value={prompt}
                    placeholder="某某行业的客户管理 或 我需要管理我的客户信息以及客户订单信息，我的流程是 1 2 3......"
                    styles={{
                        input: {
                            height: 80,
                            borderRadius: 12,
                            padding: '4px 8px',

                            '::placeholder': {
                                fontSize: 12
                            }
                        }
                    }}
                    onChange={e => setPrompt(e.currentTarget.value)}
                />
                <SCxPromptButton onClick={handleCreateApp}>
                    <IconFont type="Magic" style={{ marginRight: 4, color: 'var(--color-white)' }} />
                    生成应用
                </SCxPromptButton>
            </>
        ) : (
            <>
                <SCxPromptTip>生成过程中需要等待 1-2 分钟，请勿关闭窗口</SCxPromptTip>
                <SCxPromptMessage>{prompt}</SCxPromptMessage>
                <PromptProgress />
            </>
        )

    return (
        <SCxPromptWrapper>
            <SCxPromptHeader>
                <SCxPromptHeaderIconWrapper>
                    <IconFont size={24} type="Magic" />
                </SCxPromptHeaderIconWrapper>
                <SCxPromptHeaderTitle>AI 创建应用</SCxPromptHeaderTitle>
            </SCxPromptHeader>
            {content}
        </SCxPromptWrapper>
    )
}
