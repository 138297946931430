import type { FlowNode } from '@lighthouse/shared'

import { actionValidators } from './validators/action'
import { conditionValidators } from './validators/condition'
import { triggerValidators } from './validators/trigger'

const validators = [
    // trigger
    ...triggerValidators,
    // action
    ...actionValidators,
    ...conditionValidators
]

export const validateNode = (node: FlowNode) => {
    const {
        data: { nodeType }
    } = node

    const thisNodeValidator = validators.find(validator => validator.nodeType === nodeType)

    if (!thisNodeValidator) {
        return true
    }

    const { validators: nodeValidators } = thisNodeValidator

    for (const nodeValidator of nodeValidators) {
        // @ts-expect-error expect nodeValidator to be a function
        if (!nodeValidator(node)) {
            return false
        }
    }

    return true
}
