import { Enum } from '@lighthouse/tools'

import { StatisticalPlatForm } from '@/services/analyze'

export const CONDITION_TYPE = Enum('pv', 'uv', 'ip', 'visits', 'avgPv', 'avgDepth', 'avgDuration', 'bounceRate')
export type ConditionType = Enum<typeof CONDITION_TYPE>

export const CONDITION_OPTIONS: { label: string; value: ConditionType }[] = [
    {
        label: '浏览次数 (PV)',
        value: CONDITION_TYPE.pv
    },
    {
        label: '独立访客 (UV)',
        value: CONDITION_TYPE.uv
    },
    {
        label: 'IP',
        value: CONDITION_TYPE.ip
    },
    {
        label: '访问次数',
        value: CONDITION_TYPE.visits
    },
    {
        label: '人均浏览页数',
        value: CONDITION_TYPE.avgPv
    },
    {
        label: '平均访问深度',
        value: CONDITION_TYPE.avgDepth
    },
    {
        label: '平均访问时长',
        value: CONDITION_TYPE.avgDuration
    },
    {
        label: '跳出率',
        value: CONDITION_TYPE.bounceRate
    }
]

export const PLATFORM_OPTIONS = [
    {
        label: '友盟统计',
        icon: 'Youmeng',
        value: StatisticalPlatForm.UMENG
    },
    {
        label: '百度统计',
        icon: 'Baidu',
        value: StatisticalPlatForm.BAIDU
    },
    {
        label: 'iParllay',
        icon: 'BrandLogoIClick',
        value: StatisticalPlatForm.IPARLLAY
    },
    {
        label: '谷歌分析',
        icon: 'GoogleAnalytics',
        value: StatisticalPlatForm.CHROME
    }
]
