import { hideScrollBar } from '@byecode/ui'
import { getBlockName } from '@lighthouse/block'
import type { VariableADTvalue } from '@lighthouse/core'
import { BlockType, RecordOpenType } from '@lighthouse/core'
import type { SensorContextValue } from '@lighthouse/shared'
import {
    combineBackgroundStyle,
    FlowLayout,
    getBackgroundStyle,
    getFieldBlockWithDsId,
    getVeinsStyle,
    PAGE_SCROLL_PARENT_CONTENT,
    PageContainerProvider,
    SortableMonitorProvider,
    transformNode2FlowLayoutNode,
    useAppContainerContext,
    useAtomAction,
    useAtomData,
    useFillPickerContext,
    useManualSetMediaQueryRemBase
} from '@lighthouse/shared'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { dataDrawerStateAtom } from '@/atoms/application/state'
import {
    blockHighlightAtom,
    containerDesignStateAtom,
    lastPageOfStackAtom,
    outsideDraggingNode,
    pageAtomFamily,
    pageBlocksAtom,
    pageNodesAtom,
    pageStackAtom,
    pageStackAtomFamily,
    selectedBlockAtomFamily
} from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { useSelectedBlockEffect } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { BlockCard } from '@/containers/page/LeftPanel/KindBlock/BlockCardList'
import { ModuleCard } from '@/containers/page/LeftPanel/KindBlock/ModuleList'
import { PageFieldBlocksProvider, useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import type { FlowLayoutEvents } from '@/utils/flowLayoutEventBus'
import { flowLayoutEmitter } from '@/utils/flowLayoutEventBus'
import { usePageUndoRedo } from '@/utils/undoRedo/page'

import { PageSuspendPagination } from '../PageSuspendPagination'
import { BlockRender } from './BlockRender'
import { nodeDetection } from './nodeDetection'
import { useBoxSelectionNodes } from './useBoxSelectionNodes'
import { useClickAwayOutside } from './useClickAwayOutside'
import { useNodeDragMonitors } from './useNodeDragMonitor'
import { useNodeResizeMonitors } from './useNodeResizeMonitors'

const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const ScrollerArea = styled.div`
    /* background-color: var(--color-white); */
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    ${hideScrollBar}
`

interface PageContentProps {
    loading?: boolean
}

export const PageContent: React.FC<PageContentProps> = () => {
    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const isRootPage = rootPageId === pageId
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const endPoint = useManualSetMediaQueryRemBase()
    const { run: onVisibleDataDrawer } = useAtomAction(dataDrawerStateAtom)

    const { scale, disabledPageScroll } = useAppContainerContext()

    const pageDesign = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.design, [])
    )

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )
    const isPageOpenedAsPage = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.stackDisplayType === RecordOpenType.page, [])
    )
    const flowNodes = useMemo(
        () => transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState),
        [blockRuntimeState, pageBlocks, pageNodes]
    )

    /** 当前页面是栈顶 */
    const isCurrentStackTop = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId === pageId && s?.stackId === stackId, [pageId, stackId])
    )
    const selectedNodeId = useAtomData(
        selectedBlockAtomFamily({ rootPageId, pageId, stackId }),
        useCallback(s => s?.id, [])
    )

    useLayoutEffect(() => {
        if (selectedNodeId) {
            const nodeEl = document.querySelector(`div[data-node-id="${selectedNodeId}"`)
            ;(nodeEl as HTMLDivElement)?.focus()
        }
    }, [selectedNodeId])

    const ref = useRef<HTMLDivElement>(null)

    const disabledWithVersion = useIsDisabledWithVersion()

    useClickAwayOutside({ ref, enabled: isCurrentStackTop })

    usePageUndoRedo(
        {
            enabled: isCurrentStackTop && !disabledWithVersion,
            filter(event) {
                return !(event.target instanceof HTMLInputElement && (!event.target.type || event.target.type === 'text'))
            },
            filterPreventDefault: false,
            enableOnTags: ['INPUT']
        },
        [stackId]
    )

    useSelectedBlockEffect()

    /** ********************************* 栅格的交互事件 start ******************************** */
    const dndSensorRef = useRef<SensorContextValue>(null)

    useEffect(() => {
        function beginAdding({ node, coordinate, rect }: FlowLayoutEvents['beginAdding']) {
            if (!isCurrentStackTop) {
                return
            }
            dndSensorRef.current?.onStart(coordinate, node, rect)
        }
        // 栅格监听从外部添加node
        flowLayoutEmitter.on('beginAdding', beginAdding)

        return () => flowLayoutEmitter.off('beginAdding', beginAdding)
    }, [isCurrentStackTop])

    const { onSwap, ...dragMonitors } = useNodeDragMonitors()
    const resizeMonitors = useNodeResizeMonitors()
    const addingNode = useAtomData(outsideDraggingNode)

    const onSwapNode = useCallback(
        (from: string, to: string) => {
            if (!isCurrentStackTop) {
                return
            }
            onSwap(from, to)
        },
        [isCurrentStackTop, onSwap]
    )
    /** ********************************* 栅格的交互事件 end ******************************** */

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId, page: currPage }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    const containerDesignState = useAtomData(
        containerDesignStateAtom,
        useCallback(
            s => {
                const currentStackState = s[stackId]
                if (!currentStackState) {
                    return
                }
                return {
                    ...currentStackState,
                    root: currentStackState[pageId]
                }
            },
            [pageId, stackId]
        )
    )

    const blockHighlight = useAtomData(blockHighlightAtom)
    const highlight = useMemo(() => {
        return {
            ...containerDesignState,
            ...Object.fromEntries(blockHighlight.map(id => [id, { self: true }]))
        }
    }, [containerDesignState, blockHighlight])

    // 选中节点
    const onSelectedIdChange = useCallback(
        (id: string | undefined) => {
            if (!isCurrentStackTop) {
                return
            }
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.selectedNode = id
                    stack.state.asideType = id ? AsideType.BLOCK : AsideType.PAGE
                }
            })
        },
        [isCurrentStackTop, rootPageId, setPageStack, stackId]
    )

    /** ****************************** 多(框)选节点 start ****************************** */
    const { boxSelectionIds, onBoxSelectionIdsChange } = useBoxSelectionNodes({
        enabled: isCurrentStackTop && !disabledWithVersion,
        ref,
        flowNodes,
        blocks: pageBlocks,
        pageNodes
    })
    /** ****************************** 多(框)选节点 end ****************************** */

    /** *************** block render迁移至上层，获取所有字段block start *************** */
    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks, nodes: flowNodes, pageDsId: currPage?.dsId }),
        [currPage?.dsId, flowNodes, pageBlocks]
    )
    /** *************** block render迁移至上层，获取所有字段block end *************** */

    const background = pageDesign?.background

    const { palettes } = useFillPickerContext()

    const backgroundStyle: React.CSSProperties = useMemo(() => {
        const styles = {
            ...combineBackgroundStyle([
                getBackgroundStyle(background, parseBackgroundVariableImage, palettes),
                getVeinsStyle(pageDesign?.veins, palettes)
            ])
        }

        return {
            ...styles,
            backgroundColor: background && (styles.backgroundColor || '#fff')
        }
    }, [background, pageDesign?.veins, palettes, parseBackgroundVariableImage])

    const layerLayoutStyle = useMemo(() => {
        return {
            ...backgroundStyle,
            backgroundAttachment: 'fixed'
        }
    }, [backgroundStyle])

    return (
        <PageContainer>
            <PageFieldBlocksProvider value={fieldBlocksWithDsId}>
                <PageContainerProvider endpoint={endPoint}>
                    <ScrollerArea id={PAGE_SCROLL_PARENT_CONTENT}>
                        <SortableMonitorProvider
                            key={pageId}
                            {...dragMonitors}
                            onSwapNode={onSwapNode}
                            dndSensorRef={dndSensorRef}
                            detection={params => nodeDetection({ ...params, nodes: flowNodes, blocks: pageBlocks, addingNode })}
                            selectedId={selectedNodeId}
                            onSelectedIdChange={onSelectedIdChange}
                            boxSelectionIds={boxSelectionIds}
                            onBoxSelectionIdsChange={onBoxSelectionIdsChange}
                        >
                            <FlowLayout
                                ref={ref}
                                data={flowNodes}
                                size={pageDesign?.size}
                                direction={pageDesign?.direction}
                                gap={pageDesign?.gap}
                                padding={pageDesign?.padding}
                                alignX={pageDesign?.alignX}
                                alignY={pageDesign?.alignY}
                                veins={pageDesign?.veins}
                                background={isRootPage ? undefined : pageDesign?.background}
                                style={layerLayoutStyle}
                                parseBackgroundVariableImage={parseBackgroundVariableImage}
                                highlight={highlight}
                                scale={scale}
                                dragOverlay={
                                    addingNode &&
                                    (addingNode.type === BlockType.container || addingNode.type === BlockType.formContainer ? (
                                        <ModuleCard data={addingNode} />
                                    ) : (
                                        <BlockCard data={addingNode} />
                                    ))
                                }
                                nodeRender={BlockRender}
                                labelRender={id => {
                                    const block = pageBlocks.find(item => item.id === id)
                                    if (!block) {
                                        return null
                                    }
                                    return block.title || getBlockName(block)
                                }}
                                onDataDrawerVisible={onVisibleDataDrawer}
                                {...resizeMonitors}
                            />
                        </SortableMonitorProvider>

                        {/* {stackDisplayType === 'page' && <DomainFiling data={domainSetting} isMobile={isMobile}  />} */}
                    </ScrollerArea>
                    {/* 悬浮视图工具栏 */}
                    <PageSuspendPagination />
                </PageContainerProvider>
            </PageFieldBlocksProvider>
        </PageContainer>
    )
}
