import type { Editor } from '@tiptap/core'
import React from 'react'

type NodeFieldEditorProviderProps = React.PropsWithChildren<{
    editor: Editor | null
}>

export const NodeFieldEditorContext = React.createContext<Editor | null>(null)

export const NodeFieldEditorProvider: React.FC<NodeFieldEditorProviderProps> = ({ editor, children }) => {
    return <NodeFieldEditorContext.Provider value={editor}>{children}</NodeFieldEditorContext.Provider>
}

export const useNodeFieldEditor = () => {
    const editor = React.useContext(NodeFieldEditorContext)

    if (!editor) {
        throw new Error('useEditor must be used within an EditorProvider')
    }

    return editor
}
