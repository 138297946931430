import { FilterBlockMode, FilterSelectWay, FilterWay, NumberRangeMode } from '@lighthouse/core'

export const TABS = [
    { value: FilterBlockMode.normal, label: '普通模式', icon: 'PropertySingleSelect' },
    { value: FilterBlockMode.tile, label: '平铺模式', icon: 'StackSimple' }
]

export const selectWayOptions = [
    { label: '单选', value: FilterSelectWay.singleSelect },
    { label: '多选', value: FilterSelectWay.multiSelect }
]

export const filterWayOptions = [
    { label: '文本筛选', value: FilterWay.textFilter },
    { label: '数值筛选', value: FilterWay.numberFilter },
    { label: '日期筛选', value: FilterWay.dateFilter },
    { label: '人员筛选', value: FilterWay.personFilter },
    { label: '选项筛选', value: FilterWay.selectFilter },
    { label: '布尔筛选', value: FilterWay.boolFilter },
    { label: '级联筛选', value: FilterWay.cascadeFilter }
]

export const rangeModeOptions = [
    {
        label: '可滑动选择',
        value: NumberRangeMode.slider
    },
    {
        label: '可自定义输入',
        value: NumberRangeMode.custom
    },
    {
        label: '不允许',
        value: NumberRangeMode.disable
    }
]
