import type { SendMsgDingTalkRobotRootNode, VariableOptions } from '@lighthouse/shared'
import { NodeFieldPreview, optionFilterFieldType, pureTextFieldTypes } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { PersonConfig } from './PersonConfig'
import * as SC from './styles'

interface TextMsgConfigProps {
    options: VariableOptions
}

export const TextMsgConfig: React.FunctionComponent<TextMsgConfigProps> = ({ options }) => {
    const { control } = useFormContext<Pick<SendMsgDingTalkRobotRootNode, 'config'>>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const disabledWithVersion = useIsDisabledWithVersion()

    const richTextOptions = useMemo(
        () =>
            optionFilterFieldType(
                dataSourceList,
                options,
                field => pureTextFieldTypes.has(field.type) || (field.type === 'formula' && field.innerType === 'TEXT')
            ),
        [dataSourceList, options]
    )

    return (
        <>
            <SC.Item>
                <SC.Text>
                    文本内容
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                {/* <Input {...register('config.text.editorContent')} /> */}
                <Controller
                    control={control}
                    name="config.text.editorContent"
                    render={({ field }) => (
                        <NodeFieldPreview disabled={disabledWithVersion} {...field} autoHeight options={richTextOptions} />
                    )}
                />
                <SC.Description>方案启动后，填入的文本内容，将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
            <PersonConfig />
        </>
    )
}
