import type { Option } from '@byecode/ui'
import { IconFont, pointer, Select } from '@byecode/ui'
import { type AggregateResultField, FieldTypeTag, getFieldIcon } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

interface GroupByFieldItemProps {
    prefixName: string
    fieldList: AggregateResultField[]
    onRemove: () => void
}

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const SCxRemove = styled.div`
    ${pointer}
    padding: 2px;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

export const GroupByFieldItem: React.FC<GroupByFieldItemProps> = ({ prefixName, fieldList, onRemove }) => {
    const { control } = useFormContext()

    const options = useMemo(() => {
        return fieldList.reduce<Option[]>((prev, cur) => {
            if (cur.innerType === 'DATE') {
                return prev
            }
            prev.push({
                label: cur.name,
                value: cur.id,
                icon: getFieldIcon(cur.id, cur.type, cur.innerType),
                extra: <FieldTypeTag type={cur.type} innerType={cur.innerType} />
            })
            return prev
        }, [])
    }, [fieldList])

    return (
        <SCxContainer>
            <Controller
                control={control}
                name={`${prefixName}.groupByFieldId`}
                render={({ field }) => (
                    <Select
                        styles={{ root: { width: '100%' } }}
                        searchable
                        value={field.value}
                        options={options}
                        onChange={field.onChange}
                    />
                )}
            />
            <SCxRemove onClick={onRemove}>
                <IconFont type="Close" size={16} />
            </SCxRemove>
        </SCxContainer>
    )
}
