import { useAtomAction, useAtomAsyncAction } from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'

import { createBlockAtom, removeBlockAtom, updateBlockAtom } from '@/atoms/page/action'
import { pageNodesAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'

import type { PageUndoRedoPayload } from './controller'
import {
    BLOCK_NODE_CREATE_ACTION,
    BLOCK_NODE_REMOVE_ACTION,
    BLOCK_UPDATE_ACTION,
    NODE_UPDATE_ACTION,
    pageUndoRedoController
} from './controller'

type Params = {
    rootPageId: string
    pageId: string
    stackId: string
}

/**
 * 页面block及node操作的撤销重做具体处理逻辑
 *
 * @returns {*}
 */
export const usePageUndoRedoHelper = ({ rootPageId, pageId, stackId }: Params) => {
    const appId = useCurrentAppID()
    const { run: createBlock } = useAtomAsyncAction(createBlockAtom)
    const { run: removeBlock } = useAtomAsyncAction(removeBlockAtom)
    const { run: updateBlock } = useAtomAsyncAction(updateBlockAtom)
    const { run: setPageNodes } = useAtomAction(pageNodesAtom)

    const undoHandler = useAtomCallback<void, [PageUndoRedoPayload]>((_, __, data) => {
        switch (data.action) {
            case BLOCK_NODE_CREATE_ACTION: {
                setPageNodes(draft => void (draft[pageId] = data.payload.nodes.prev))
                srv.updateNodes({ id: pageId, nodes: data.payload.nodes.prev || [] })

                removeBlock({
                    blockIds: data.payload.blocks.map(item => item.id),
                    rootPageId,
                    stackId,
                    pageId
                })
                break
            }

            case BLOCK_NODE_REMOVE_ACTION: {
                setPageNodes(draft => void (draft[pageId] = data.payload.nodes.prev))
                srv.updateNodes({ id: pageId, nodes: data.payload.nodes.prev || [] })

                createBlock({
                    blocks: data.payload.blocks,
                    rootPageId,
                    pageId,
                    stackId
                })
                break
            }

            case BLOCK_UPDATE_ACTION: {
                updateBlock({
                    appId,
                    block: data.payload.prev,
                    origin: data.payload.next,
                    pageId,
                    stackId,
                    rootPageId
                })
                break
            }

            case NODE_UPDATE_ACTION: {
                const newNodes = data.payload.prev || []
                setPageNodes(draft => void (draft[pageId] = newNodes))
                srv.updateNodes({ id: pageId, nodes: newNodes })
                break
            }

            default: {
                break
            }
        }
    })

    const redoHandler = useAtomCallback<void, [PageUndoRedoPayload]>((_, __, data) => {
        switch (data.action) {
            case BLOCK_NODE_CREATE_ACTION: {
                setPageNodes(draft => void (draft[pageId] = data.payload.nodes.next))
                srv.updateNodes({ id: pageId, nodes: data.payload.nodes.next || [] })

                createBlock({
                    blocks: data.payload.blocks,
                    rootPageId,
                    pageId,
                    stackId
                })

                break
            }

            case BLOCK_NODE_REMOVE_ACTION: {
                setPageNodes(draft => void (draft[pageId] = data.payload.nodes.next))
                srv.updateNodes({ id: pageId, nodes: data.payload.nodes.next || [] })

                removeBlock({
                    blockIds: data.payload.blocks.map(item => item.id),
                    rootPageId,
                    stackId,
                    pageId
                })
                break
            }

            case BLOCK_UPDATE_ACTION: {
                updateBlock({
                    appId,
                    block: data.payload.next,
                    origin: data.payload.prev,
                    pageId,
                    stackId,
                    rootPageId
                })
                break
            }

            case NODE_UPDATE_ACTION: {
                const newNodes = data.payload.next || []
                setPageNodes(draft => void (draft[pageId] = newNodes))
                srv.updateNodes({ id: pageId, nodes: newNodes })
                break
            }

            default: {
                break
            }
        }
    })
    
    const undo = useCallback(() => {
        const res = pageUndoRedoController.undo()
        if (res) {
            undoHandler(res)
            // Toast.success('撤回成功')
        }
    }, [undoHandler])

    const redo = useCallback(() => {
        const res = pageUndoRedoController.redo()
        if (res) {
            redoHandler(res)
            // Toast.success('重做成功')
        }
    }, [redoHandler])

    return { undo, redo }
}
