import { Modal } from '@byecode/ui'

export const promptSwitchDataSource = async () => {
    // eslint-disable-next-line no-return-await
    return await Modal.confirm({
        title: '切换数据表',
        content: `当前节点数据的配置将被清空，依赖该节点的下游节点需要重新配置引用数据，该操作不可恢复。`,
        okText: '切换'
    })
}
