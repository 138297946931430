import { IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
`

const SCxItem = styled.div<{ background: string; active: boolean }>`
    position: relative;
    flex: 1;
    height: 242px;
    border-radius: 20px;
    background: ${({ background }) => background};
    padding-left: 36px;
    cursor: pointer;
    opacity: ${({ active }) => (active ? 1 : 0.8)};

    &:hover {
        opacity: 1;
    }
`

const SCxItemTitle = styled.div`
    margin-top: 74px;
    font-size: 30px;
    color: var(--color-white);
`

const SCxItemDescription = styled.div`
    margin-top: 18px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
`

const SCxImage = styled.img`
    position: absolute;
    width: 124.38px;
    right: 0;
    bottom: 0;
`

const SCxIconWrapper = styled.div`
    position: absolute;
    top: -18px;
    right: -18px;
    z-index: 1;
    &:after {
        top: 7px;
        right: 7px;
        position: absolute;
        z-index: -1;
        content: ' ';
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
    }
`

const SCxIcon = styled(IconFont)``

interface CheckSelectProps {
    value: string
    onChange: (val: string) => void
}

const coolPng = getAssetUrl('common', 'cool.png')

const happyPng = getAssetUrl('common', 'happy.png')

export const CheckSelect: React.FC<CheckSelectProps> = ({ value, onChange }) => {
    return (
        <SCxContainer>
            <SCxItem
                background="linear-gradient(to right,#5551FF,#5652E5 )"
                active={value === 'PERSONAL'}
                onClick={() => onChange('PERSONAL')}
            >
                <SCxItemTitle>个人使用</SCxItemTitle>
                <SCxItemDescription>for person</SCxItemDescription>
                <SCxImage src={coolPng} />
                {value === 'PERSONAL' && (
                    <SCxIconWrapper>
                        <SCxIcon type="TickCircle" size={44} fill="var(--color-green-500)" />
                    </SCxIconWrapper>
                )}
            </SCxItem>
            <SCxItem
                background="linear-gradient(to right,#A851FF,#AE6DEE )"
                active={value === 'COMPANY'}
                onClick={() => onChange('COMPANY')}
            >
                <SCxItemTitle>团队/企业使用</SCxItemTitle>
                <SCxItemDescription>for team</SCxItemDescription>
                <SCxImage src={happyPng} />
                {value === 'COMPANY' && (
                    <SCxIconWrapper>
                        <SCxIcon type="TickCircle" size={44} fill="var(--color-green-500)" />
                    </SCxIconWrapper>
                )}
            </SCxItem>
        </SCxContainer>
    )
}
