import type { Draft } from 'immer'

export type Mutator<T> = (state: Draft<T>) => void

export type Selector<T, Result> = (state: T) => Result

export function passThrough<T>(val: T): T {
    return val
}

export type UnionToTuple<Union> = Union extends infer A | infer B ? [A, B] : never

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never

// 提取所有联合类型中包含的配置键
export type ExtractConfigKeys<T> = T extends { [key: string]: infer U } ? keyof U : never

// 交叉类型，将所有联合类型中的属性组合
export type CombineConfigs<T> = {
    [K in ExtractConfigKeys<T>]: T extends { [key: string]: { [P in K]: infer V } } ? V : never
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? K : never }[keyof T]

export type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>

// eslint-disable-next-line @typescript-eslint/ban-types
export type NonFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? never : K }[keyof T]

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>

export type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>
      }
    : T

export interface UseUncontrolledInput<T> {
    /** 受控状态值 */
    value?: T

    /** 非受控状态默认值 */
    defaultValue?: T

    /** 如果没有给 value 或 defaultValue，那么就用 finalValue 作为默认值 */
    finalValue?: T

    /** 受控状态下的 onChange */
    onChange?(value: T): void
}

export type FileUnit = 'B' | 'kB' | 'MB' | 'GB' | 'TB'

export const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v
