import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight } from 'rambda'

import type { WorkSpaceAbstract } from '../abstracts'

export function WorkSpaceStruct(initial: Partial<WorkSpaceAbstract> = {}): WorkSpaceAbstract {
    return mergeDeepRight<WorkSpaceAbstract>(
        {
            id: nanoid(32),
            name: '',
            icon: 'default',
            members: 1,
            createdTime: Number(Date.now()),
            createdBy: '',
            applications: [],
            roleId: ''
        },
        initial
    )
}
