import { Button, IconFont } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import type { AggregatorNode, AggregatorNodeConfig } from '@lighthouse/shared'
import { getAggregatorUpstreamOption } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import type { Node } from 'reactflow'
import styled from 'styled-components'

import { MatchCondition } from './MatchCondition'

const SCxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const SCxButtonWrapper = styled.div`
    margin: 4px 0;
`

interface JoinMatchContentProps {
    prefixName: string
    dataSourceList: DataSourceAbstract[]
    nodeList: AggregatorNode[]
}

export const JoinMatchContent: React.FC<JoinMatchContentProps> = ({ prefixName, dataSourceList, nodeList }) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: prefixName
    })

    const leftOptions = useMemo(
        () => getAggregatorUpstreamOption({ aggregateNodeList: nodeList, dataSourceList }),
        [dataSourceList, nodeList]
    )

    const handleAdd = useCallback(() => {
        append({
            left: null,
            operator: '=',
            right: null
        })
    }, [append])

    const handleRemove = useCallback(
        (i: number) => {
            remove(i)
        },
        [remove]
    )
    return (
        <SCxWrapper>
            {fields.map((_, i) => (
                <MatchCondition
                    key={i}
                    nodeList={nodeList}
                    dataSourceList={dataSourceList}
                    leftOptions={leftOptions}
                    prefixName={`${prefixName}.${i}`}
                    onRemove={() => handleRemove(i)}
                />
            ))}
            <SCxButtonWrapper>
                <Button radius={100} onClick={handleAdd} icon={<IconFont type="Add" fill="var(--color-gray-500)" size={16} />}>
                    添加
                </Button>
            </SCxButtonWrapper>
        </SCxWrapper>
    )
}
