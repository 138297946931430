import { singleTextEllipsis } from '@byecode/ui'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Popover, usePopoverContext } from '@byecode/ui/components/Popover'
import type { PopoverDropdownProps } from '@byecode/ui/components/Popover/PopoverDropdown'
import React from 'react'
import styled from 'styled-components'

const StyledDropdown = styled(Popover.Dropdown)`
    padding: 8px 16px;
`

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    ${singleTextEllipsis}
`

const ActionButton = styled.button`
    all: unset;
    color: var(--color-gray-400);
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    transition: backgroundColor 0.2s;
    &:hover {
        color: var(--color-gray-500);
        background-color: var(--color-gray-200);
    }
`

export interface PopoverDropdownWithCloseProps extends Omit<PopoverDropdownProps, 'title'> {
    title?: React.ReactNode
    onGoBack?: () => void
}

export const PopoverDropdownWithClose = React.forwardRef<HTMLElement, PopoverDropdownWithCloseProps>((props, ref) => {
    const { title, onGoBack, children, ...rest } = props

    const ctx = usePopoverContext()

    return (
        <StyledDropdown ref={ref} {...rest}>
            <Flex mb={4} py={8} gap="8px" alignItems="center" justifyContent="space-between">
                {onGoBack && (
                    <ActionButton onClick={onGoBack}>
                        <IconFont type="ArrowLeftSmall" />
                    </ActionButton>
                )}
                <Title>{title}</Title>
                <ActionButton onClick={() => ctx.onClose?.()}>
                    <IconFont type="Close" />
                </ActionButton>
            </Flex>
            {children}
        </StyledDropdown>
    )
})
