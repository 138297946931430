import { flex, tinyButtons } from '@byecode/ui'
import { Button } from '@mantine/core'
import { omit } from 'rambda'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useUser } from '@/hooks/useUser'

import TemplateBreadcrumbs from '../TemplateBreadcrumbs'
import TemplateDetail from '../TemplateDetail'
import TemplateTitle from '../TemplateTitle'
import type { Route, Template } from '../types'

interface TemplatePreviewProps {
    data: Template
    routes: Route[]
    loading: boolean

    onBack: () => void
    onCreate: (value: Template) => void
}

const SCXContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px 100px;

    overflow-y: auto;
    ${tinyButtons}
`

const SCXHeader = styled.div``

const SCXTemplateTitle = styled.div`
    margin-top: 32px;
    ${flex}
    align-items: center;
    justify-content: space-between;
`

const SCXWrapper = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    margin: 0 auto;
    max-width: 800px;
`
const TemplatePreview: React.FunctionComponent<TemplatePreviewProps> = ({ data, routes, loading, onBack, onCreate }) => {
    const userData = useUser()
    const navigate = useNavigate()

    return (
        <SCXContainer>
            <SCXWrapper>
                <SCXHeader>
                    <TemplateBreadcrumbs onBack={onBack} routes={routes} />
                    <SCXTemplateTitle>
                        <TemplateTitle data={omit(['labels'], data)} titleSize={20} describeSize={12} iconSize={27} logoSize={51} />
                        <Button
                            styles={{
                                label: {
                                    fontWeight: 400
                                }
                            }}
                            loading={loading}
                            onClick={() => (userData.userId ? onCreate(data) : navigate({ pathname: '/account/login' }))}
                        >
                            立即体验
                        </Button>
                    </SCXTemplateTitle>
                </SCXHeader>
                <TemplateDetail data={data} />
            </SCXWrapper>
        </SCXContainer>
    )
}

export default TemplatePreview
