import type { ColorPickerProps } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Input, Text } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import ColorPicker from '@/components/ColorPicker'

interface ColorPickerControlProps extends ColorPickerProps {
    label: string
    width?: number
    style?: React.CSSProperties
}
const ColorContainer = styled.div`
    display: flex;
    width: 100px;
    height: 32px;
    padding: 4px;
    align-items: center;
    background-color: #f2f4f7;
    border-radius: 4px;
`

export const SettingsInput: typeof Input = styled(Input)<{ width?: number }>`
    flex: 1;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    flex: none;
    .mantine-Input-input {
        min-height: 20px;
        height: 20px;
        line-height: 20px;
    }
`

export const ColorPickerControl: React.FunctionComponent<ColorPickerControlProps> = ({
    value,
    label,
    width = 20,
    style,
    onChange,
    ...rest
}) => {
    return (
        <ListItem4ByecodeUi compact alignItems="center" justifyContent="space-between">
            <Text>{label}</Text>
            <ColorContainer>
                <ColorPicker
                    style={{
                        padding: 0,
                        ...style
                    }}
                    {...rest}
                    value={value}
                    width={width}
                    onChange={onChange}
                />
                <SettingsInput
                    styles={{
                        input: {
                            padding: 0,
                            paddingLeft: 4,
                            pointerEvents: 'none',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent'
                        }
                    }}
                    width={66}
                    value={value}
                    readOnly
                    placeholder="无颜色"
                />
            </ColorContainer>
        </ListItem4ByecodeUi>
    )
}
