import { pointer } from '@byecode/ui'
import { useItemFinishListener, useUploadyContext } from '@rpldy/shared-ui'
import UploadDropZone from '@rpldy/upload-drop-zone'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import { RoundAvatar } from '@/components/RoundAvatar'
import { SpaceAvatar } from '@/containers/WorkSpace/SpaceAvatar'

interface UploadButtonProps {
    children?: React.ReactNode
    onUpdateImg: (imgSrc: string) => void
    name?: string
    img?: string
    enableTooltip?: boolean
    disabled?: boolean
    radius?: string
}

const SpaceBackground = styled.div`
    ${pointer}
`

const SpaceItemAbbrText = styled.div`
    width: 34px;
    height: 36px;
    line-height: 33px;
    text-align: center;
    background: var(--color-wakaba-light);
    border: 1px solid var(--color-black);
    border-radius: 10px;
    color: var(--color-black);
    font-size: 16px;

    ${pointer}
`

export const UploadBtn: React.FC<UploadButtonProps> = ({ img, enableTooltip = false, onUpdateImg, name, disabled = false, radius }) => {
    const uploady = useUploadyContext()

    useItemFinishListener(item => {
        const { uploadResponse } = item

        onUpdateImg(uploadResponse.data.content.url)
    })

    const handleUpload = useCallback(() => {
        if (disabled) {
            return
        }
        uploady.showFileUpload()
    }, [disabled, uploady])

    return (
        <UploadDropZone>
            <SpaceBackground onClick={handleUpload}>
                <RoundAvatar
                    enableTooltip={enableTooltip}
                    tooltipLabel={`空间：${name}`}
                    background="var(--color-gray-50)"
                    activeBgColor="var(--color-white)"
                    activeColor="var(--color-black)"
                    color="var(--color-black)"
                    radius={radius}
                    style={{
                        borderColor: 'var(--color-gray-200)'
                    }}
                    icon={img}
                    name={name}
                />
            </SpaceBackground>
        </UploadDropZone>
    )
}
