import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ color?: string }>`
    font-size: var(--font-size-lg);
    ${pointer};
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
`
export const RoundIcon = styled(IconFont)`
    padding: 4px;
    color: var(--color-gray-400);
    background-color: var(--color-gray-200);
    border-radius: 100%;
    ${pointer};
`
