import { Group } from '@byecode/ui'
import type { IframeBlockAbstract } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { VisibilityFilter } from '../Common/VisibilityFilter'
import { IframeInput } from './IframeInput'

interface IframeSettingProps {}

export const IframeSetting: React.FC<IframeSettingProps> = () => {
    const { control } = useFormContext<IframeBlockAbstract['config']>()
    return (
        <>
            <Group label="网页链接">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Controller control={control} name="source" render={({ field }) => <IframeInput {...field} placeholder="请输入" />} />
                </ListItem4ByecodeUi>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}
