import { DatePicker, MobileDatePicker } from '@byecode/ui'
import { Card, Collapse, Grid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { addYears } from 'date-fns'
import React, { useState } from 'react'

import { Unit } from '../Mics'

export const DatePickerComponentsDemo: React.FC = () => {
    const [opened, { toggle }] = useDisclosure(true)

    const [value, setValue] = useState<Date>()
    return (
        <Card withBorder>
            <Title order={2} onClick={toggle}>
                日期选择器
            </Title>
            <Collapse in={opened}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Unit title="基础">
                            <DatePicker showTime={false} />
                        </Unit>
                        <Unit title="受控">
                            <DatePicker value={value} onChange={setValue} />
                        </Unit>
                        <Unit title="移动端">
                            <MobileDatePicker value={value} onChange={setValue} maxDate={addYears(new Date(), 1)} />
                        </Unit>
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    )
}
