import { Button, IconFont, Modal, Toast } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import copy from 'copy-to-clipboard'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { generateApiTokenAtom } from '@/atoms/workSpace/action'

import { Header } from '../Header'

const SCxContainer = styled.div``

const SCxFormGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
`

const SCxFormLabel = styled.div`
    width: 177px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    line-height: 36px;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxToken = styled.div`
    width: 250px;
    height: 36px;
    line-height: 36px;
    border-radius: 8px;
    background: var(--color-gray-100);
    font-size: var(--font-size-normal);
    padding: 0 8px;
`

const SCxValue = styled.div`
    color: var(--color-gray-400);
`

const SCxButton = styled(Button)`
    margin-left: 8px;
`

interface DevelopProps {
    spaceId: string
    apiToken?: string
}

export const Develop: React.FC<DevelopProps> = ({ spaceId, apiToken }) => {
    const apiTokenRef = useRef(apiToken)
    const { run: generateApiToken, loading } = useAtomAction(generateApiTokenAtom)
    const rebuildApiToken = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确定重新生成？',
            content: '令牌重新生成后，原令牌将失效，功能不可用，可能引发未知错误。请在操作前确保相关服务已更新验证。',
            checkBoxLabel: '我已知晓上述风险',
            isCheckBoxConfirm: true,
            okStatus: 'primary',
            okText: '重新生成'
        })
        if (isConfirm) {
            generateApiToken(spaceId)
        }
    }, [generateApiToken, spaceId])

    useEffect(() => {
        if (!apiToken) {
            return
        }
        if (!apiTokenRef.current) {
            apiTokenRef.current = apiToken
            Toast.success('已生成 API KEY')
            return
        }

        if (apiTokenRef.current !== apiToken) {
            apiTokenRef.current = apiToken
            Toast.success('已重新生成 API KEY')
        }
    }, [apiToken])

    const handleCopy = useCallback(() => {
        if (apiToken) {
            copy(apiToken)
            Toast.success('已复制 API KEY')
        }
    }, [apiToken])

    const handle = useMemo(() => {
        if (apiToken) {
            return (
                <>
                    <SCxButton
                        loading={loading}
                        type="default"
                        size="lg"
                        icon={<IconFont type="ArrowCounterClockwise" size="16" />}
                        onClick={rebuildApiToken}
                    >
                        重新生成
                    </SCxButton>
                    <SCxButton
                        loading={loading}
                        type="default"
                        size="lg"
                        icon={<IconFont fill="var(--color-gray-400" type="Duplicate" size="16" />}
                        onClick={handleCopy}
                    >
                        复制
                    </SCxButton>
                </>
            )
        }
        return (
            <SCxButton
                loading={loading}
                type="primary"
                size="lg"
                icon={<IconFont type="Add" size="16" />}
                onClick={() => generateApiToken(spaceId)}
            >
                生成KEY
            </SCxButton>
        )
    }, [apiToken, generateApiToken, handleCopy, loading, rebuildApiToken, spaceId])

    return (
        <SCxContainer>
            <Header title="开发者" />
            <SCxFormGroup>
                <SCxFormLabel>API KEY</SCxFormLabel>
                <SCxToken>
                    <SCxValue>
                        {apiToken ? `${apiToken.slice(0, 4)}***************${apiToken.slice(-4)}` : '点击右侧按钮即可生成Token'}
                    </SCxValue>
                </SCxToken>
                {handle}
            </SCxFormGroup>
        </SCxContainer>
    )
}
