import { Flex, Group, IconFont, Input, SegmentedControl, Text, Tooltip } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ColorControl } from '../../ColorControl'
import { InputControl } from '../../InputControl'
import { SwitchControl } from '../../SwitchControl'
import type { BaseFieldInputSetting } from '../types'

interface SliderConfigProps extends Pick<BaseFieldInputSetting, 'prefix'> {}

const STYLE_CONTROL = [
    { label: '跟随主题', value: 'theme' },
    { label: '自定义', value: 'custom' }
]

const SliderConfig: React.FunctionComponent<SliderConfigProps> = ({ prefix }) => {
    const { watch, setValue, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [colorMode, sliderMin, sliderMax] = watch([`${prefix}slider.colorMode`, `${prefix}slider.min`, `${prefix}slider.max`])

    useEffect(() => {
        if (sliderMax <= sliderMin) {
            setValue(`${prefix}slider.step`, 1)
        }
    }, [prefix, setValue, sliderMax, sliderMin])

    return (
        <Group label="基础配置">
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>样式</Text>
                <div style={{ width: 180 }}>
                    <Controller
                        name={`${prefix}slider.colorMode`}
                        control={control}
                        render={({ field }) => (
                            <SegmentedControl fullWidth data={STYLE_CONTROL} value={field.value} onChange={field.onChange} />
                        )}
                    />
                </div>
            </ListItemPaddingByecodeUi>
            {colorMode === 'custom' && (
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <div>图标</div>
                    <Controller name={`${prefix}slider.color`} render={({ field }) => <ColorControl {...field} />} />
                </ListItemPaddingByecodeUi>
            )}
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <div>区间</div>
                <Flex style={{ width: 180 }} alignItems="center" gap={4}>
                    <Controller
                        name={`${prefix}slider.min`}
                        render={({ field }) => (
                            <Input
                                value={field.value}
                                min={0}
                                type="number"
                                onChange={e => field.onChange(Number(e.target.value))}
                                onBlur={e => {
                                    const newValue = e.target.value
                                    if (Number(newValue) < 0) {
                                        field.onChange(0)
                                    }
                                    if (Number(newValue) >= Number(sliderMax)) {
                                        setValue(`${prefix}slider.max`, Number(newValue) + 1)
                                    }
                                }}
                            />
                        )}
                    />
                    <Text> - </Text>
                    <Controller
                        name={`${prefix}slider.max`}
                        render={({ field }) => (
                            <Input
                                value={field.value}
                                min={0}
                                type="number"
                                onChange={e => field.onChange(Number(e.target.value))}
                                onBlur={e => {
                                    const newValue = e.target.value
                                    if (Number(newValue) <= Number(sliderMin)) {
                                        field.onChange(Number(sliderMin) + 1)
                                    }
                                }}
                            />
                        )}
                    />
                </Flex>
            </ListItemPaddingByecodeUi>
            <Controller
                name={`${prefix}slider.isInput`}
                render={({ field }) => (
                    <SwitchControl label="输入框" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                )}
            />
            <Controller
                name={`${prefix}slider.step`}
                render={({ field }) => (
                    <InputControl
                        label="步长"
                        tipNode={
                            <Tooltip title="设置值需要能够被区间最大值-最小值整除">
                                <IconFont size={16} color="var(--color-gray-400)" type="Question" style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        }
                        type="number"
                        min={1}
                        value={field.value}
                        onChange={v => field.onChange(Number(v))}
                        onBlur={e => {
                            const newValue = e.currentTarget.value
                            if (Number(newValue) > Number(sliderMax) - Number(sliderMin)) {
                                field.onChange(1)
                            }
                            if (Number(newValue) === 0) {
                                field.onChange(1)
                            }
                        }}
                    />
                )}
            />
        </Group>
    )
}

export default SliderConfig
