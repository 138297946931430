import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`
export const Text = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    font-family: 'PingFang SC';
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: ${({ size }) => size ?? 14 + 2}px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const Description = styled.div`
    margin-top: 6px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
    min-height: 38px;
`

export const Item = styled.div`
    width: 100%;
    margin-top: 12px;

    .byecode-Input-wrapper {
        margin-top: 12px;
    }
`

export const Container = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);
`

export const Content = styled.div`
    width: 100%;
    padding: 16px 40px;
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
    margin-bottom: 24px;
`

export const Footer = styled.div`
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    align-items: center;
    background: var(--color-gray-50);
    border-radius: 0px 0px inherit inherit;
`
export const RightFill = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    order: 2;
`
export const LeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    order: 1;
`
