import type { AiFieldStatus, DataSourceAbstract, FieldADTValue, RecordLikeProtocol, TableColumns } from '@lighthouse/core'
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import TableCell from '../TableCell'
import { TableChecker } from './TableChecker'
import type { TablePopoverState, TableTooltipState } from './TableContent'

interface TableRecordProps {
    dataSourceInfo: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    id: string
    index: number
    selectedRecords?: string[]
    tableProps: TableColumns
    virtualRow: VirtualItem
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    primaryDataSourceFieldIds?: Set<string>
    disableEdit?: boolean
    disableSelect?: boolean
    onRecordSelect?: (recordIds: string[]) => void
    onOpenEditor?: (data: TablePopoverState) => void
    onOpenTooltip?: (data: TableTooltipState) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
}

const SCxTableRecord = styled.div`
    display: flex;
    width: max-content;
    min-width: 100%;
    border-top: 1px solid var(--color-gray-200);
    background: var(--color-white);

    :hover {
        background-color: var(--color-gray-100);
    }

    & :hover .table-check {
        background-color: var(--color-gray-100);
    }
`

const SCxTableRow = styled.div`
    position: relative;
    width: 100%;
`

const TableRecord: React.FC<TableRecordProps> = ({
    dataSourceInfo,
    dataSourceList,
    id,
    index,
    selectedRecords = [],
    tableProps,
    virtualRow,
    columnVirtualizer,
    recordPoolAtom,
    aiFieldStatusListAtom,
    primaryDataSourceFieldIds,
    disableEdit,
    disableSelect,
    onRecordSelect,
    onOpenEditor,
    onOpenTooltip,
    onCellChange,
    onAiGeneration
}) => {
    const { id: dsId, schema } = dataSourceInfo

    const handleCheck = useCallback(
        (checked: boolean, id: string) => {
            if (checked) {
                onRecordSelect?.([...selectedRecords, id])
            } else {
                onRecordSelect?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [onRecordSelect, selectedRecords]
    )

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (!onAiGeneration) {
                return Promise.resolve(false)
            }
            return onAiGeneration(id, fieldId)
        },
        [id, onAiGeneration]
    )

    return (
        <SCxTableRecord
            key={id}
            data-key={`${dsId}&${id}`}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
            }}
        >
            <SCxTableRow>
                <TableChecker
                    index={index}
                    checked={selectedRecords.includes(id)}
                    className="table-check"
                    disableSelect={disableSelect}
                    onChange={checked => handleCheck(checked, id)}
                />
                {columnVirtualizer.getVirtualItems().map(virtualColumn => {
                    const item = tableProps[virtualColumn.index]
                    const { id: cId, visible, width } = item
                    const field = schema?.[cId]
                    return (
                        field &&
                        visible && (
                            <TableCell
                                key={`${id}-${cId}`}
                                dataSource={dataSourceInfo}
                                dataSourceList={dataSourceList}
                                width={virtualColumn.size}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: `${virtualColumn.size}px`,
                                    height: `${virtualRow.size}px`,
                                    transform: `translateX(${virtualColumn.start}px)`
                                }}
                                recordId={id}
                                recordPoolAtom={recordPoolAtom}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                field={{ ...field, id: cId }}
                                primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                                disableEdit={disableEdit}
                                onOpenEditor={onOpenEditor}
                                onOpenTooltip={onOpenTooltip}
                                onCellChange={onCellChange}
                                onAiGeneration={handleAiGeneration}
                            />
                        )
                    )
                })}
            </SCxTableRow>
        </SCxTableRecord>
    )
}

export default TableRecord
