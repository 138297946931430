import { IconFont, Loading } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const SCxPromptProgressWrapper = styled.div``

const SCxPromptProgressItem = styled.div`
    height: 72px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    opacity: 0.1;
    transition: all 0.3s ease-in;
`

const SCxPromptProgressItemIconWrapper = styled.div`
    display: flex;
    width: 98px;
    height: 72px;
    border-radius: 8px;
    background-color: var(--color-gray-100);
    margin-right: 20px;
`

const SCxPromptProgressItemIcon = styled.img`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
`

const SCxPromptProgressItemText = styled.span`
    flex: 1;
`

const SCxPromptProgressItemStatusWrapper = styled.div``

const SCxTickIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--color-white);
    background-color: var(--color-gray-500);
`

const SCxPendingWrapper = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-gray-500);
`

const promptProgressSteps = [
    {
        uid: 1,
        image: getAssetUrl('common', 'DataModule.svg'),
        text: '生成数据模型'
    },
    {
        uid: 2,
        image: getAssetUrl('common', 'ExampleData.svg'),
        text: '生成 5-10 条示例数据'
    },
    {
        uid: 3,
        image: getAssetUrl('common', 'Layout.svg'),
        text: '绘制应用页面'
    },
    {
        uid: 4,
        image: getAssetUrl('common', 'InstructionManual.svg'),
        text: '撰写应用指南'
    }
]

export const PromptProgress: React.FC = () => {
    const [currentStep, setCurrentStep] = React.useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentStep(currentStep => {
                if (currentStep === promptProgressSteps.length - 1) {
                    clearInterval(timer)
                    return currentStep
                }
                return currentStep + 1
            })
        }, 30000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <SCxPromptProgressWrapper>
            {promptProgressSteps.map(({ uid, image, text }, index) => {
                const isPassed = index < currentStep
                const isCurrent = index === currentStep
                return (
                    <SCxPromptProgressItem key={uid} style={{ opacity: index > currentStep ? 0.5 : 1 }}>
                        <SCxPromptProgressItemIconWrapper>
                            <SCxPromptProgressItemIcon src={image} />
                        </SCxPromptProgressItemIconWrapper>
                        <SCxPromptProgressItemText>{text}</SCxPromptProgressItemText>
                        <SCxPromptProgressItemStatusWrapper>
                            {isPassed && (
                                <SCxTickIconWrapper>
                                    <IconFont type="Tick" />
                                </SCxTickIconWrapper>
                            )}
                            {isCurrent && (
                                <SCxPendingWrapper>
                                    <span style={{ marginRight: 8 }}>进行中</span>
                                    <Loading outlined />
                                </SCxPendingWrapper>
                            )}
                        </SCxPromptProgressItemStatusWrapper>
                    </SCxPromptProgressItem>
                )
            })}
        </SCxPromptProgressWrapper>
    )
}
