import { IconFont, SegmentedControl } from '@byecode/ui'
import type { ViewOptions } from '@lighthouse/core'
import { CenteredWrapper } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { dataSourceAtom } from '../atoms'
import { TabEnum, VIEW_TABS } from '../constants'
import { Data } from './Data'
import { Design } from './Design'
import { Operate } from './Operate'
import { ViewTypeSwitcher } from './ViewTypeSwitcher'

const SCxPaddingBox = styled.div`
    padding: 0 16px;
`
interface ViewSettingProps {
    id: string
}

function NoPointer() {
    return (
        <CenteredWrapper style={{ padding: 36 }}>
            <IconFont type="BlockTable" style={{ fontSize: 24, marginBottom: 8 }} fill="var(--color-gray-400)" />
            <Text color="var(--color-gray-400)">请先添加数据源</Text>
        </CenteredWrapper>
    )
}
/** 视图block设置 */
const ViewSetting: React.FC<ViewSettingProps> = ({ id }) => {
    const dataSource = useAtomValue(dataSourceAtom)
    const { watch } = useFormContext<ViewOptions>()
    const viewType = watch('viewType')

    const [currentTab, setCurrentTab] = useState<string>(TabEnum.data)

    const tabs = useMemo(() => {
        if (!dataSource) {
            return VIEW_TABS.map(item => ({
                ...item,
                disabled: item.value !== 'data'
            }))
        }
        return VIEW_TABS
    }, [dataSource])

    const emptyContent = useMemo(() => {
        if (!dataSource && (currentTab === TabEnum.style || currentTab === TabEnum.operation)) {
            return <NoPointer />
        }
        return null
    }, [currentTab, dataSource])

    return (
        <>
            <ViewTypeSwitcher />
            <Divider my={12} color="var(--color-gray-200)" />
            <SCxPaddingBox>
                <SegmentedControl
                    disabled={false}
                    key={viewType}
                    data={tabs}
                    size="lg"
                    fullWidth
                    value={currentTab}
                    onChange={setCurrentTab}
                />
            </SCxPaddingBox>

            {/* 数据 */}
            <Data viewType={viewType} currentTab={currentTab} dataSource={dataSource} />
            {/* 用户操作 */}
            {dataSource && currentTab === TabEnum.operation && (
                <Operate id={id} viewType={viewType} currentTab={currentTab} dataSource={dataSource} />
            )}

            {dataSource && <Design currentTab={currentTab} viewType={viewType} dataSource={dataSource} />}

            {emptyContent}
        </>
    )
}

export default ViewSetting
