import { useAtomData } from '@lighthouse/shared'
import { useContext } from 'react'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdderContext } from '@/contexts/ActionAdderContext'

import { usePage } from './usePage'
import { useVariableCustomViewOption } from './useVariableCustomViewOption'

export const useActionAdderDepParams = () => {
    const { customViewOption } = useVariableCustomViewOption()
    const actionAdderCtxValue = useContext(ActionAdderContext)
    const currentPageDeps = useAtomData(lastPageOfStackAtom)
    const currentPage = usePage(currentPageDeps?.pageId ?? '')

    // 自定义视图
    if (customViewOption) {
        return { dsId: customViewOption.dsId }
    }

    if (actionAdderCtxValue) {
        return actionAdderCtxValue
    }

    return { dsId: currentPage?.dsId ?? '' }
}
