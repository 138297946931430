import type { WorkSpaceAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import type { Selector } from '@lighthouse/tools'
import type { Atom } from 'jotai'
import { atom, useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { currentWorkSpaceIdAtom, workSpaceAtom, workSpaceListAtom } from '@/atoms/workSpace/state'

export const useCurrentSpaceID = () => {
    return useAtomValue(currentWorkSpaceIdAtom)
}

export const useWorkSpaceList = () => {
    return useAtomValue(workSpaceListAtom)
}
// export const useWorkSpace = <P extends Partial<WorkSpaceAbstract>>(selector?: Selector<WorkSpaceAbstract, P>) => {
//     return useAtomValue(atom(workSpaceAtom, (get) => selector ? selector(get(workSpaceAtom)) : get(workSpaceAtom) ))
// }
export const useWorkSpaceSelector = <P extends Partial<WorkSpaceAbstract | null>>(selector: Selector<WorkSpaceAbstract | null, P>) => {
    return useAtomData<WorkSpaceAbstract | null, P>(workSpaceAtom, selector)
}

export const useWorkSpace = () => {
    return useAtomValue(workSpaceAtom)
}

// export const useWorkSpace = <P extends Partial<WorkSpaceAbstract>>(selector?: Selector<WorkSpaceAbstract, P>) => {
//     return useAtomData<WorkSpaceAbstract, P>(workSpaceAtom)
// }
