export class UndoRedoController<T> {
    private _queue: T[] = []

    private _undoStack: T[] = []

    private _redoStack: T[] = []

    public add(payload: T) {
        this._queue.push(payload)
        this._undoStack.push(payload)
        this._redoStack = []
    }

    public undo() {
        const data = this._undoStack.pop()
        if (data) {
            this._queue.pop()
            this._redoStack.push(data)
            return data
        }
    }

    public redo() {
        const data = this._redoStack.pop()
        if (data) {
            this.queue.push(data)
            this._undoStack.push(data)
            return data
        }
    }

    public clear() {
        this._queue = []
        this._undoStack = []
        this._redoStack = []
    }

    get queue() {
        return this._queue
    }

    get redoStack() {
        return this._redoStack
    }

    get undoStack() {
        return this._undoStack
    }
}
