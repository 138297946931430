import { Input, Select } from '@byecode/ui'
import { NumberRangeMode } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { range } from 'rambda'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { ListItemMenu } from '../Common/ListItemMenu'
import { NumberOptionSetting } from '../Common/NumberOptionSetting'
import { rangeModeOptions } from './constants'

const SCxNumberInput = styled(Input)`
    width: 180px;

    & input[type='number']::-webkit-outer-spin-button,
    & input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type='number'] {
        -moz-appearance: textfield;
    }
`

const SCxRange = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 180px;
`

interface NumberFilterProps {
    prefixName: string
}

export const NumberFilter: React.FC<NumberFilterProps> = ({ prefixName }) => {
    const { control, setValue } = useFormContext()

    const numberRangeMode = useWatch({ control, name: `${prefixName}.numberRangeMode` })

    return (
        <>
            <Controller
                control={control}
                name={`${prefixName}.numberOptions`}
                render={({ field: { value, onChange } }) => {
                    return <NumberOptionSetting value={value} onChange={onChange} />
                }}
            />
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>自定义数值范围</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.numberRangeMode`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                style={{ width: 180 }}
                                value={value}
                                onChange={v => {
                                    onChange(v)
                                    if (v === NumberRangeMode.slider) {
                                        setValue(`${prefixName}.numberRange`, [1, 100])
                                        setValue(`${prefixName}.step`, 1)
                                    }
                                }}
                                options={rangeModeOptions}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>

            {numberRangeMode === NumberRangeMode.slider && (
                <>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>
                            数值范围 <span style={{ color: 'red', verticalAlign: 'text-top' }}>*</span>
                        </Text>
                        <Controller
                            control={control}
                            name={`${prefixName}.numberRange`}
                            render={({ field: { value, onChange } }) => {
                                const [start, end] = value ?? [undefined, undefined]
                                return (
                                    <SCxRange>
                                        <SCxNumberInput
                                            type="number"
                                            size="lg"
                                            // max={max}
                                            placeholder="最小值"
                                            value={start === undefined ? '' : start}
                                            onChange={ev => {
                                                const val = ev.target.value
                                                onChange([val ? Number(val) : undefined, end])
                                            }}
                                            onBlur={e => {
                                                const newValue = e.target.value
                                                if (Number(newValue) >= Number(end)) {
                                                    onChange([start, Number(newValue) + 1])
                                                }
                                            }}
                                        />
                                        <span>-</span>
                                        <SCxNumberInput
                                            type="number"
                                            size="lg"
                                            // min={min}
                                            placeholder="最大值"
                                            value={end === undefined ? '' : end}
                                            onChange={ev => {
                                                const val = ev.target.value
                                                onChange([start, val ? Number(val) : undefined])
                                            }}
                                            onBlur={e => {
                                                const newValue = e.target.value
                                                if (Number(newValue) <= Number(start)) {
                                                    onChange(Number(start) + 1)
                                                    onChange([start, Number(start) + 1])
                                                }
                                            }}
                                        />
                                    </SCxRange>
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                        <Text>步长</Text>
                        <Controller
                            control={control}
                            name={`${prefixName}.step`}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <SCxNumberInput
                                        type="number"
                                        size="lg"
                                        value={value === null ? '' : value}
                                        onChange={ev => {
                                            const val = ev.target.value
                                            onChange(val ? Number(val) : null)
                                        }}
                                        onBlur={e => {
                                            const newValue = e.target.value
                                            if (Number(newValue) <= 0) {
                                                onChange(1)
                                            }
                                        }}
                                    />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                </>
            )}
        </>
    )
}
