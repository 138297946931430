import { IconFont } from '@byecode/ui'
import type { EnvDsDiffError } from '@lighthouse/shared'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const options = [
    {
        label: '表所在应用',
        value: 'CRM管理系统'
    },
    {
        label: '表所在应用',
        value: 'CRM管理系统'
    },
    {
        label: '表所在应用',
        value: 'CRM管理系统'
    },
    {
        label: '表所在应用',
        value: 'CRM管理系统'
    },
    {
        label: '表所在应用',
        value: 'CRM管理系统'
    }
]

const SCxList = styled.div``

const SCxListItem = styled.div`
    height: 36px;
    padding-left: 52px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-color: var(--color-gray-200);
    border-style: solid;
    border-width: 0 1px 1px 1px;

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`

const SCxLabel = styled.div`
    width: 123px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

const SCxValue = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const SCxLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
`

interface MergeErrorListProps {
    data: EnvDsDiffError
}

export const MergeErrorList: React.FC<MergeErrorListProps> = ({ data }) => {
    return (
        <SCxList>
            <SCxListItem>
                <SCxLabel>表所在应用</SCxLabel>
                <SCxValue>
                    {data.originAppName}
                    <SCxLink to={`/${data.originAppId}`} target="_blank">
                        <IconFont type="BlockLinkToPage" />
                    </SCxLink>
                </SCxValue>
            </SCxListItem>
            <SCxListItem>
                <SCxLabel>表所在环境</SCxLabel>
                <SCxValue>{data.originEnvName}</SCxValue>
            </SCxListItem>
            <SCxListItem>
                <SCxLabel>被同步的表</SCxLabel>
                <SCxValue>{data.originDsName}</SCxValue>
            </SCxListItem>
            <SCxListItem>
                <SCxLabel>异常原因</SCxLabel>
                <SCxValue>{data.cause}</SCxValue>
            </SCxListItem>
            <SCxListItem>
                <SCxLabel>解决办法</SCxLabel>
                <SCxValue>{data.solution}</SCxValue>
            </SCxListItem>
        </SCxList>
    )
}
