import { Button, Group, Scanner, scannerEmitter, ScannerProvider, Toast } from '@byecode/ui'
import { Card, Grid, Title } from '@mantine/core'
import React, { useState } from 'react'

export const ScannerComponentsDemo: React.FC = () => {
    return (
        <ScannerProvider>
            <Card withBorder>
                <Group label={<Title order={2}>二维码或条形码的识别器</Title>} renderRightIcon={() => null}>
                    <Grid gutter={20}>
                        <Grid.Col span={6}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    scannerEmitter.emit('openScanner', {
                                        success: v => {
                                            Toast.success(v)
                                        },
                                        error: err =>{
                                            Toast.error(err)
                                        }
                                    })
                                }}
                            >
                                打开扫码识别器
                            </Button>
                        </Grid.Col>
                    </Grid>
                </Group>
            </Card>
        </ScannerProvider>
    )
}
