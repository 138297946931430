import { Select } from '@byecode/ui'
import type { NodeTypes } from '@lighthouse/shared'
import { CollapseBox, nodeTypeOptions } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as SC from '../styles'

export const DeleteRecordActionConfigure: React.FC = () => {
    const { control } = useFormContext()

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="nodeType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabled
                                        value={field.value}
                                        options={nodeTypeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
