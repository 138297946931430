import { IconFont, itemBackgroundTransitionOnClick, pointer } from "@byecode/ui";
import styled from "styled-components";

export const UserCenterIcon = styled(IconFont)``

export const Prefix = styled.div<{ width: number }>`
    width: ${({ width }) => width && `${width}px`};
`

export const CenterItem = styled.div`
    flex: 1;
    padding: 6px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
`


export const ListItem = styled.div`
    /* width: 200px; */
    margin: 0 8px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    border-radius: 6px;
    color: var(--color-gray-500);
    ${pointer}
    ${itemBackgroundTransitionOnClick}

    &:hover ${UserCenterIcon} {
        color: var(--color-gray-500);
    }
    /* 'var(--color-main)' : 'var(--color-gray-400)' */


    &.operate {
        color: var(--color-black);
        background-color: var(--color-gray-100);
    }

    &.active {
        color: var(--color-main);
        background-color: var(--color-main-tint);

        ${UserCenterIcon} {
            color: var(--color-main);
        }
    }
`

export const ListGroupTitle = styled.div<{ color?: string }>`
    flex: 1;
    line-height: 24px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
