import { Tooltip } from '@byecode/ui/components/Tooltip'
import type { ChartType } from '@lighthouse/core'
import { Image } from '@mantine/core'
import React from 'react'
import styled, { css } from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { chartTypeOptions } from '../../constants'

const SCxChartTypeSwitchers = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-row-gap: 16px;
    justify-content: space-between;
    padding: 8px 0;
`

const SCxChartTypeSwitcherItem = styled.div``

const SCxBlock = styled.button<Pick<React.CSSProperties, 'width' | 'height' | 'background'>>`
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 16px;
    cursor: pointer;
    background-color: ${({ background }) => background || 'var(--color-gray-50)'};
    img {
        -webkit-user-drag: none;
    }

    &:not([disabled]):hover {
        background-color: ${({ background }) => !background && 'var(--color-gray-100)'};
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const SCxText = styled.div<{ color?: string }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    bottom: 8px;
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-weight: 500;
    font-size: 12px;
`

export interface ChartTypeSwitcherProps {
    value: ChartType
    onChange?: (val: ChartType) => void
}

export interface ChartTypeSwitcherItemProps {
    image: string
    label?: string
    checked?: boolean
    disabled?: boolean
    color: string
    background: string
    onCheck?: () => void
}

const ChartTypeSwitcherItem: React.FC<ChartTypeSwitcherItemProps> = ({ image, label, checked, color, background, disabled, onCheck }) => {
    const hoverColor = checked ? color : 'var(--color-gray-500)'
    const activeBackground = checked ? background : undefined
    return (
        <Tooltip title={label} disabled={disabled}>
            <SCxBlock disabled={disabled} width={104} height={110} background={activeBackground} onClick={onCheck}>
                {/* <SCxStyleWrapper backgroundColor={hoverBackground} /> */}
                <Image fit="cover" style={{ zIndex: 2, position: 'relative' }} radius={16} src={image} />
                <SCxText color={hoverColor}>{label}</SCxText>
            </SCxBlock>
        </Tooltip>
    )
}

export const ChartTypeSwitcher: React.FC<ChartTypeSwitcherProps> = ({ value, onChange }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    // const popoverState = usePopupState({
    //     variant: 'popover',
    //     popupId: 'chartTypeSwitcherPopover'
    // })

    // const currentChartInfo = useMemo(() => {
    //     return find(({ value: val }) => value === val, chartTypeOptions)
    // }, [value])

    // const handleClick = useCallback(
    //     (val: string) => {
    //         onChange?.(val as ChartType)
    //         popoverState.close()
    //     },
    //     [onChange, popoverState]
    // )

    return (
        <SCxChartTypeSwitchers>
            {chartTypeOptions.map(option => (
                <SCxChartTypeSwitcherItem key={option.value}>
                    <ChartTypeSwitcherItem
                        disabled={disabledWithVersion}
                        checked={option.value === value}
                        {...option}
                        onCheck={() => onChange?.(option.value)}
                    />
                </SCxChartTypeSwitcherItem>
            ))}
        </SCxChartTypeSwitchers>
    )
}
