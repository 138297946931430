import type { DragMoveEvent } from '@dnd-kit/core'

/** 插入位置，表示前后 */
export enum PosEnum {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER'
}

export const judgeInsertPosition = (ev: DragMoveEvent) => {
    const { collisions, over } = ev
    const customSortItemData = over?.data.current

    // 默认均处理为前方插入
    if (!over || !collisions) {
        return PosEnum.BEFORE
    }

    if (collisions.length === 1 && customSortItemData?.['isTail']) {
        return PosEnum.AFTER
    }

    // 拖拽到 了最后，插入到后方
    return PosEnum.BEFORE
}
