import { CheckBoxGroup, Flex, Group, IconFont, Text, Tooltip } from '@byecode/ui'
import type {SubFormBlockConfig } from '@lighthouse/core';
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import {
    ListItemPaddingByecodeUi,
    useAtomData} from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'

import { SwitchControl } from '../../SwitchControl'
import { imageSourceOptions, scannerOptions } from '../constants'
import type { BaseFieldInputSetting } from '../types'

interface TextConfigProps extends Pick<BaseFieldInputSetting, 'prefix'>{}

export const TextConfig: React.FunctionComponent<TextConfigProps> = ({ prefix }) => {
    const { watch, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [scannerList] = watch([`${prefix}text.scannerList`])

    const isUsedCustomDomain = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.domain.isUsedCustomDomain && s?.domain.customDomain, [])
    )

    return (
        <Group label="移动端设置">
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Flex gap={4} alignItems="center">
                    <Text>扫码输入</Text>
                    <Tooltip
                        title={
                            <Text lineHeight="20px" whiteSpace="pre-wrap" style={{ width: 209 }}>
                                通过启用设备摄像头实现扫码输入。仅在微信浏览器中扫码区分条形码、二维码，其他平台扫码不做区分。
                            </Text>
                        }
                    >
                        <IconFont type="Question" size={16} color="var(--color-gray-400)" />
                    </Tooltip>
                </Flex>
                <Flex justifyContent="flex-end">
                    <Controller
                        name={`${prefix}text.scannerList`}
                        control={control}
                        render={({ field }) => (
                            <CheckBoxGroup
                                style={{
                                    gap: 16
                                }}
                                direction="horizontal"
                                options={scannerOptions}
                                size="xs"
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </Flex>
            </ListItemPaddingByecodeUi>
            {(scannerList ?? []).length > 0 && (
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>约束</Text>
                    <Flex justifyContent="flex-end">
                        <Controller
                            name={`${prefix}text.disableInputs`}
                            control={control}
                            render={({ field }) => (
                                <CheckBoxGroup
                                    style={{
                                        gap: 16
                                    }}
                                    direction="horizontal"
                                    options={imageSourceOptions}
                                    size="xs"
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </Flex>
                </ListItemPaddingByecodeUi>
            )}
            <Flex direction="column" gap={4} style={{ padding: '4px 0' }}>
                <Controller
                    name={`${prefix}text.enableWxScanner`}
                    render={({ field }) => (
                        <SwitchControl label="启用微信扫一扫" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                />
                {isUsedCustomDomain && (
                    <>
                        <Text lineHeight="20px" whiteSpace="pre-wrap" color="var(--color-gray-400)" size={12}>
                            根据微信公众平台的要求，当应用使用自有域名，启用微信扫一扫需要集成公众号，否则无法使用微信扫一扫。
                        </Text>
                        <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
                    </>
                )}
            </Flex>
        </Group>
    )
}
