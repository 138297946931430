import React from 'react'
import type { RouteObject } from 'react-router-dom';
import { redirect } from 'react-router-dom'

import { PrivateTemplate } from '@/views/Byecode/PrivateTemplate'

import NotFoundTemplate from '../views/404/template'

export const templateRoute: RouteObject = {
    path: '/template',
    children: [
        {
            index: true,
            loader: () => redirect('404')
        },
        {
            path: '404',
            element: <NotFoundTemplate />
        },
        {
            path: ':applicationId',
            element: <PrivateTemplate />
        }
    ]
}
