import { Box } from '@byecode/ui/components/Box'
import { Loading } from '@byecode/ui/components/Loading'
import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

const Container = styled(Box)`
    padding: 28px 32px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    border-radius: 12px;
`

const Header = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

const Title = styled(Box)`
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
`

interface CardProps extends Omit<React.ComponentProps<typeof Box<'div'>>, 'title'> {
    title?: React.ReactNode
    extra?: React.ReactNode
    loading?: boolean
}

export const Card: FC<CardProps> = props => {
    const { title, extra, loading, children, ...rest } = props

    return (
        <Container {...rest}>
            {(title || extra) && (
                <Header>
                    <Title>{title}</Title>
                    {extra}
                </Header>
            )}
            {loading ? <Loading /> : children}
        </Container>
    )
}
