import { IconFont, Input } from '@byecode/ui'
import React, { useState } from 'react'

import { Unit } from '../Mics'

export const Controlled: React.FC = () => {
    const [value, setValue] = useState('')

    return (
        <Unit title="受控与非受控">
            <Input
                placeholder="受控"
                value={value}
                onChange={ev => {
                    const { value } = ev.target
                    setTimeout(() => {
                        setValue(value)
                    }, 2000)
                }}
            />
            <Input placeholder="非受控" icon={<IconFont type="Add" />} />
        </Unit>
    )
}
