import { Button, IconFont } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type ViewFieldProtocol } from '@lighthouse/core'
import { arrayMove } from '@lighthouse/tools'
import { clone, find, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useSharedConfigDisabledWithVersion } from '../../contexts'
import { getFieldIcon } from '../../utils'

export interface QuickFilterListProps {
    items: string[]
    columns: ViewFieldProtocol[]
    onDelete?: (id?: string) => void
    onMove?: (data: string[]) => void
}

export interface QuickFilterItemProps {
    id: string
    columns: ViewFieldProtocol[]
    onDelete?: (event: React.MouseEvent) => void
}

const QuickFilterListWrapper = styled.div``

const QuickFilterItemWrapper = styled.div`
    padding: 0 8px;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    color: var(--color-gray-900);
    border-radius: 8px;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const ItemInfoWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`

const FieldName = styled.span`
    font-size: 14px;
`

const QuickFilterItem: React.FC<QuickFilterItemProps> = ({ id, columns, onDelete }) => {
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id, disabled: disabledWithVersion })
    const wrapperStyles = { transform: CSS.Transform.toString(transform), transition }
    const infoWrapperStyles = { cursor: isDragging ? 'grabbing' : 'grab' }

    const fieldData = find(item => item.fieldId === id, columns)
    if (!fieldData) {
        return null
    }
    const { type, title, innerType } = fieldData

    return (
        <QuickFilterItemWrapper ref={setNodeRef} style={wrapperStyles}>
            <ItemInfoWrapper style={infoWrapperStyles} {...listeners} {...attributes}>
                <IconFont style={{ marginRight: 8 }} type="Move" />
                <IconFont style={{ marginRight: 6 }} type={getFieldIcon(id, type, innerType) || ''} />
                <FieldName>{title}</FieldName>
            </ItemInfoWrapper>
            <Button disabled={disabledWithVersion} type="text" onClick={onDelete} icon={<IconFont type="Trash" />} />
        </QuickFilterItemWrapper>
    )
}

const QuickFilterList: React.FC<QuickFilterListProps> = ({ items, columns, onDelete, onMove }) => {
    const handleDragEnd = useCallback(
        (ev: DragEndEvent) => {
            const { active, over } = ev
            if (!over || active.id === over.id) {
                return
            }
            const newFields = clone(items)
            const activeIndex = findIndex(fieldId => fieldId === active.id, newFields)
            const overIndex = findIndex(fieldId => fieldId === over.id, newFields)
            const newFieldItems = arrayMove(newFields, activeIndex, overIndex)
            onMove?.(newFieldItems)
        },
        [items, onMove]
    )

    const fields = useMemo(() => items.filter(fieldId => find(col => col.fieldId === fieldId, columns)), [columns, items])

    return (
        <QuickFilterListWrapper>
            <DndContext modifiers={[restrictToParentElement]} onDragEnd={handleDragEnd}>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {fields.map((fieldId, index) => (
                        <QuickFilterItem columns={columns} key={fieldId} id={fieldId} onDelete={() => onDelete?.(fieldId)} />
                    ))}
                </SortableContext>
            </DndContext>
        </QuickFilterListWrapper>
    )
}

export default QuickFilterList
