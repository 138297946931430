import { Input } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Size: React.FC = () => {
    return (
        <Unit title="尺寸">
            <Input placeholder="请输入" size="xs" />
            <Input placeholder="请输入" size="sm" />
            <Input placeholder="请输入" size="md" />
            <Input placeholder="请输入" size="lg" />
        </Unit>
    )
}
