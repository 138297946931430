import { backgroundTransitionOnClick, Button, IconFont, Modal } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    height: 71px;
    margin-top: 16px;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    padding: 0 16px;
    background-color: var(--color-white);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

export const Left = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const IconGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 16px;
`

export const Icon = styled(IconFont)``

export const ArrowIcon = styled(IconFont)`
    margin: 0 6px;
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const Title = styled.div`
    font-weight: 600;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    white-space: nowrap;
`

export const Description = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    white-space: nowrap;
`

export const Right = styled.div``

export const PoolBtn = styled(Button)`
    border-color: var(--color-theme-6);
    background-color: var(--color-theme-2);
    color: var(--color-theme-6);
    white-space: nowrap;

    ${backgroundTransitionOnClick}
`

export const PoolModal = styled(Modal)``
