import type { PageAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'

import { pageAtomFamily } from '@/atoms/page/state'

const getGroups = (page: PageAbstract | null) => page?.groups ?? []

const getDepartments = (page: PageAbstract | null) => page?.departments ?? []

export const usePageGroups = (pageId: string) => useAtomData(pageAtomFamily(pageId), getGroups)

export const usePageDepartments = (pageId: string) => useAtomData(pageAtomFamily(pageId), getDepartments)
