import { ApplicationType } from '@lighthouse/core'
import { getBrandInfo, useAtomAction, useAtomData } from '@lighthouse/shared'
import { pick } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { setAppLayoutModeAtom } from '@/atoms/application/action'
import { appAtom } from '@/atoms/application/state'
import { useAppLayoutMode } from '@/hooks/useApplication'

import { AppWebsiteTabs, AppWxAppletTabs } from '../constant'
import SettingTabs from '../SettingTabs'
import { ModuleType } from '../type'

interface SettingDrawerProps {}

export const Container = styled.div`
    flex: 0 0 560px;
    height: 100%;
`
export const Title = styled.div`
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-black);
`

const SettingDrawer: React.FC<SettingDrawerProps> = () => {
    const { type: appType, id: appId = '' } =
        useAtomData(
            appAtom,
            useCallback(state => state && pick(['type', 'id'], state), [])
        ) ?? {}

    // const [currentTab, setCurrentTab] = useState(ModuleType.theme)
    const mode = useAppLayoutMode()

    const { run: setMode } = useAtomAction(setAppLayoutModeAtom)

    const options = useMemo(() => {
        if (appType === ApplicationType.wxApplet) {
            return AppWxAppletTabs
        }

        return AppWebsiteTabs.filter(({ value }) => {
            const isConfigDomain = getBrandInfo().app.isFilings
            const isConfigAdverting = getBrandInfo().app.isAdverting
            if (value === ModuleType.domain) {
                return isConfigDomain
            }
            if (value === ModuleType.advertising) {
                return isConfigAdverting
            }
            return true
        })
    }, [appType])

    return (
        <Container>
            <SettingTabs
                key={appId}
                appId={appId}
                currentTab={mode.currentTab ?? options[0].value}
                options={options}
                onChange={val => {
                    setMode({ type: 'setting', currentTab: val })
                }}
            />
        </Container>
    )
}

export default SettingDrawer
