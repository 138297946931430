import type { PopoverProps } from '@byecode/ui'
import { Button, Popover } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const pos: PopoverProps['position'][] = [
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
]

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Popover>
                <Popover.Target>
                    <Button>点我</Button>
                </Popover.Target>
                <Popover.Dropdown>来了来了</Popover.Dropdown>
            </Popover>
            <Popover trigger="hover">
                <Popover.Target>
                    <Button>移入</Button>
                </Popover.Target>
                <Popover.Dropdown>来了来了</Popover.Dropdown>
            </Popover>
            <Popover withinPortal>
                <Popover.Target>
                    <Button>portal</Button>
                </Popover.Target>
                <Popover.Dropdown>withinPortal</Popover.Dropdown>
            </Popover>
            <Popover withArrow>
                <Popover.Target>
                    <Button>arrow</Button>
                </Popover.Target>
                <Popover.Dropdown>withArrow</Popover.Dropdown>
            </Popover>

            {pos.map(item => (
                <Popover position={item} width={200} key={item}>
                    <Popover.Target>
                        <Button>{item}</Button>
                    </Popover.Target>
                    <Popover.Dropdown>{item}</Popover.Dropdown>
                </Popover>
            ))}
        </Unit>
    )
}
