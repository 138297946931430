import { Button, IconFont } from '@byecode/ui'
import type { AggregateField, Field, SchemaProtocol } from '@lighthouse/core'
import type { AggregateResultField } from '@lighthouse/shared'
import { ErrorMessage, FieldIcon, FieldTypeColorMap, FormulaConfigView, getFieldName, TableFieldInput } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useDataSourceEnvId } from '@/hooks/useDataSource'
import * as srv from '@/services'

const SCxEditorContainer = styled.div`
    /* padding: 6px 0; */
    overflow: visible;
`

const SCxEditorHeader = styled.div`
    position: sticky;
    top: 0;
    background: var(--color-white);
    z-index: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
`

/**
 * set view styles
 */
const SCxSetView = styled.div`
    overflow: hidden;
`

const SCxTypeSwitcher = styled.div<{ disable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 32px;
    font-size: var(--font-size-normal);
    margin: 12px 16px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
    flex: 1;

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxTypeDesc = styled.div`
    display: inline-flex;
    align-items: center;
`

const SCxTypeName = styled.span`
    margin-left: 6px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
`

const SCxActionWrapper = styled.div`
    margin: 12px 16px;
    display: flex;
    justify-content: flex-end;
`

interface AggregatorFieldConfigureProps {
    dsId: string
    nodeId: string
    field: AggregateResultField
    fieldList: AggregateResultField[]
    onChange?: (val: AggregateResultField) => void
    onCancel: () => void
}

export const AggregatorFieldConfigure: React.FC<AggregatorFieldConfigureProps> = ({
    dsId,
    nodeId,
    field,
    fieldList,
    onChange,
    onCancel
}) => {
    const envId = useDataSourceEnvId()
    const [fieldConfig, setFieldConfig] = useImmer<AggregateResultField>(field)
    const [errors, setErrors] = useState({
        title: ''
    })

    const isFormula = useMemo(
        () => field.type === 'aggregation' && field.aggregation?.isFormula,
        [field.aggregation?.isFormula, field.type]
    )
    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            if (!ev.target.value.trim()) {
                setErrors({
                    title: '请输入字段名称'
                })
            }
            //
            setFieldConfig(draft => {
                draft.name = ev.target.value
            })
        },
        [setFieldConfig]
    )

    const handleOk = useCallback(
        async (data: AggregateField) => {
            const innerType = await srv.getInnerTypeInAggregation({
                envId,
                dsId,
                nodeId,
                expression: data.aggregation?.formula?.expression || ''
            })
            const fieldConfig = {
                ...data,
                innerType,
                visible: field.visible
            }
            // setFieldConfig(fieldConfig)
            onChange?.(fieldConfig)
            return true
        },
        [dsId, envId, field.visible, nodeId, onChange]
    )

    const handleDummyOk = useCallback(() => {
        onChange?.(fieldConfig)
    }, [fieldConfig, onChange])

    const extra = useMemo(() => {
        if (!isFormula) {
            return null
        }
        const schema = fieldList.reduce<SchemaProtocol['schema']>((prev, cur) => {
            prev[cur.id] = cur as Field
            return prev
        }, {})
        const fieldIds = fieldList.map(item => item.id)
        return <FormulaConfigView config={fieldConfig} schema={schema} fieldIds={fieldIds} onOk={handleOk} onCancel={onCancel} />
    }, [fieldConfig, fieldList, handleOk, isFormula, onCancel])

    return (
        <SCxEditorContainer>
            <SCxEditorHeader>
                <ErrorMessage name="title" enablePortal={false} errors={errors}>
                    <TableFieldInput autoSelect placeholder="请输入" value={fieldConfig.name} onChange={handleChange} />
                </ErrorMessage>
            </SCxEditorHeader>
            <SCxSetView>
                <SCxTypeSwitcher disable>
                    <SCxTypeDesc>
                        <FieldIcon
                            id=""
                            type={field.type}
                            innerType={field.innerType}
                            isFormula={field?.aggregation?.isFormula}
                            fill={FieldTypeColorMap[field.type]}
                        />
                        <SCxTypeName>{getFieldName(field.type, field.innerType, field?.aggregation?.isFormula)}</SCxTypeName>
                    </SCxTypeDesc>
                    <IconFont fill="var(--color-gray-500)" type="ArrowDownSmall" />
                </SCxTypeSwitcher>
                {extra}
            </SCxSetView>
            {!isFormula && (
                <SCxActionWrapper>
                    <Button style={{ marginRight: 8 }} onClick={onCancel}>
                        取消
                    </Button>
                    <Button type="primary" onClick={handleDummyOk}>
                        确定
                    </Button>
                </SCxActionWrapper>
            )}
        </SCxEditorContainer>
    )
}
