import { IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean; backgroundColor?: string }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
    color: var(--color-gray-500);
    ${pointer}
    ${({ isAction }) =>
        isAction &&
        css`
            :hover {
                color: var(--color-black);
            }
        `}
`
