import type { ApplicationType, IconicProtocol } from '@lighthouse/core'
import type { ModalProps } from '@mantine/core'
import { Modal } from '@mantine/core'
import React from 'react'

import { PromptContent } from './PromptContent'

export interface AppAICreatorState extends IconicProtocol {
    type: ApplicationType
    isBlank: boolean
}

export interface AppAICreatorProps extends ModalProps {
    onConfirm: (value: string) => void
}

export const AppAICreator: React.FC<AppAICreatorProps> = ({ opened, onConfirm, onClose, ...rest }) => {
    return (
        <Modal
            closeOnClickOutside
            closeOnEscape
            opened={opened}
            centered
            radius={12}
            size={500}
            padding={0}
            onClose={onClose}
            styles={{
                modal: {
                    overflow: 'hidden'
                },
                header: {
                    width: '100%',
                    marginBottom: 0
                },
                body: {
                    padding: '0 24px 24px 24px'
                },
                close: {
                    margin: '12px 12px 0 0'
                }
            }}
        >
            <PromptContent onConfirm={onConfirm} />
        </Modal>
    )
}
