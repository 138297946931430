import { IconFont, Input, pointer, TextArea } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const PageHeaderWrapper = styled.div`
    position: relative;
`

export const PageNavigatorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 12px;
    background-color: #ffffff;
    border-bottom: 1px solid var(--color-gray-200);
`

export const PageNavigatorBackIcon = styled(IconFont)`
    margin-right: 4px;
    cursor: pointer;
`

export const PageNavigatorContent = styled.div`
    display: flex;
    width: calc(100% - 80px);
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
`

export const PageNavigatorLeft = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
`

export const PageNavigatorActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 400px;
`

export const PageTitlePreviewer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
    padding: 4px 10px;
    border-radius: 4px;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const PageTitleInput = styled(Input)`
    width: 400px;
    resize: none;
    overflow: hidden;
`

export const PageNavigatorAction = styled(IconFont)`
    margin-left: 12px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    color: var(--color-gray-500);

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const PageNotificationWrapper = styled.div`
    position: absolute;
    bottom: -50px;
    z-index: 1;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    background-color: var(--color-main);
    color: #ffffff;
    font-size: var(--font-size-normal);
`

export const PageNotificationContentWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`

export const PageNotificationContent = styled.div``

export const PageNotificationActionWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const PageNotificationDiscardAction = styled.div`
    margin-right: 16px;
    cursor: pointer;
`

export const PageNotificationApplyAction = styled.div`
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    padding: 0 6px;
    background-color: #ffffff;
    color: var(--color-main);
    cursor: pointer;
`

/**  setting popover  start */

const itemCommonStyle = css`
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: var(--font-size-normal);
`

export const SettingPopoverWrapper = styled.div`
    width: 220px;
`

export const TitleInput = styled(Input)`
    margin: 0 12px 12px 12px;
`

export const DescriptionInput = styled(TextArea)`
    width: 256px;
    margin: 0 12px;
`

export const ListItem = styled.div`
    ${itemCommonStyle}
    height: 36px;

    ${pointer}
    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ListItemIcon = styled(IconFont)`
    margin-right: 8px;
`

export const ListItemLabel = styled.div``

export const ListDivider = styled.div`
    height: 1px;
    background-color: var(--color-gray-100);
    margin: 12px 0;
`

export const ListDescriptionWrapper = styled.div`
    ${itemCommonStyle}
    height: 28px;
    justify-content: space-between;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const ListDescriptionItemLabel = styled.div``

export const ListDescriptionItemValue = styled.div``

export const StatusSelectWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const StatusSelectLabel = styled.div`
    white-space: nowrap;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

/**  setting popover  end   */
