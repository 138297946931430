import { Modal } from '@byecode/ui'
import type { SpaceUser } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { logoutAtom, updateUserAtom } from '@/atoms/auth/action'
import { useUser } from '@/hooks/useUser'

import { AccountSetting } from './components/AccountSetting'

interface ProfileProps {
    open: boolean
    onInviteUser?: () => void
    onClose?: () => void
}

export const Profile: React.FC<ProfileProps> = ({ open, onInviteUser, onClose }) => {
    // const [open, setOpen] = useState(false)
    const userData = useUser()
    const { run: updateUser } = useAtomAction(updateUserAtom)

    // // 退出登录
    // const handleLogout = useCallback(async () => {
    //     const isConfirm = await Modal.confirm({
    //         title: '确认退出',
    //         content: '确认退出登录',
    //         okStatus: 'error',
    //         okText: '退出'
    //     })
    //     if (isConfirm) {
    //         await logout()
    //         navigate(
    //             { pathname: '/account/login' },
    //             {
    //                 replace: true
    //             }
    //         )
    //     }
    // }, [navigate, logout])

    const handleUpdateUser = useCallback(
        (data: SpaceUser) => {
            updateUser(data)
        },
        [updateUser]
    )

    // const renderAvatar = useMemo(() => {
    //     if (avatar) {
    //         return <SC.Avatar background={avatar} />
    //     }
    //     return <SC.UserBoxText>{username.slice(0, 1)}</SC.UserBoxText>
    // }, [avatar, username])

    return (
        <Modal
            title="账号设置"
            open={open}
            width="auto"
            disableMobile
            styles={{
                desktop: {
                    modal: { height: '600px', margin: '0 32px', width: '100%', maxWidth: '1000px' },
                    body: { height: '100%', width: '100%', padding: 0 }
                }
            }}
            onClose={onClose}
        >
            <AccountSetting userInfo={userData} onUpdateUser={handleUpdateUser} onInviteUser={onInviteUser} onClose={onClose} />
        </Modal>
    )
}
