import { flex } from '@byecode/ui'
import * as React from 'react'
import styled from 'styled-components'

interface TollTopProps {
    leftFill?: React.ReactNode
    rightFill?: React.ReactNode
}

const Container = styled.div`
    width: 100%;
    height: 32px;
    ${flex}
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

const TollTop: React.FC<TollTopProps> = ({ leftFill, rightFill }) => {
    return (
        <Container>
            {leftFill}
            {rightFill}
        </Container>
    )
}

export default TollTop
