import { Group4ByecodeUi, SelectDataSource } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { DataSetting } from './DataSetting'

interface DefaultDataSourceProps {}

const SCxDataSourceWrapper = styled.div`
    margin: 4px 0;
`

/** 视图字段 */
export const DefaultDataSource: React.FC<DefaultDataSourceProps> = () => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { control, watch } = useFormContext()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)
    const pointer = watch('pointer')
    const dataSource = useMemo(() => find(item => item.id === pointer, dataSourceList), [dataSourceList, pointer])

    return (
        <Group4ByecodeUi label="数据设置">
            <Controller
                name="pointer"
                control={control}
                render={({ field }) => {
                    return (
                        <SCxDataSourceWrapper>
                            <SelectDataSource
                                placeholder="请选择数据表"
                                value={field.value}
                                onChange={field.onChange}
                                searchable
                                width="100%"
                                dataSourceList={dataSourceList}
                            />
                        </SCxDataSourceWrapper>
                    )
                }}
            />
            {dataSource && <DataSetting dataSource={dataSource} />}
        </Group4ByecodeUi>
    )
}
