import { DatePicker, Select } from '@byecode/ui'
import type { NodeTypes, ScheduledTriggerRule } from '@lighthouse/shared'
import { CollapseBox, nodeTypeOptions } from '@lighthouse/shared'
import { isValid } from 'date-fns'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import * as SC from '../../styles'
import { CommonRule } from './CommonRule'

interface ScheduledTriggerConfigureProps {}

export const ScheduledTriggerConfigure: React.FC<ScheduledTriggerConfigureProps> = () => {
    const { control } = useFormContext()

    const scheduleRule: ScheduledTriggerRule =
        useWatch({
            control,
            name: 'config.scheduleRule'
        }) ?? 'ONCE'

    const isTriggerOnce = scheduleRule === 'ONCE'

    return (
        <>
            <SC.Container>
                <CollapseBox label="触发器配置">
                    <SC.Content>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel>类型</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>开始时间</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.scheduleStartTime"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        const dateValue = field.value && isValid(new Date(field.value)) ? new Date(field.value) : undefined
                                        return (
                                            <DatePicker
                                                clearable
                                                value={dateValue}
                                                showTime
                                                onChange={field.onChange}
                                                onClear={() => field.onChange('')}
                                            />
                                        )
                                    }}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                        {!isTriggerOnce && (
                            <SC.FormItem>
                                <SC.FormItemLabelWrapper>
                                    <SC.FormItemLabel>结束时间</SC.FormItemLabel>
                                </SC.FormItemLabelWrapper>
                                <SC.FormItemContent>
                                    <Controller
                                        name="config.scheduleEndTime"
                                        control={control}
                                        render={({ field }) => {
                                            const dateValue =
                                                field.value && isValid(new Date(field.value)) ? new Date(field.value) : undefined
                                            return (
                                                <DatePicker
                                                    clearable
                                                    value={dateValue}
                                                    showTime
                                                    onChange={field.onChange}
                                                    onClear={() => field.onChange('')}
                                                />
                                            )
                                        }}
                                    />
                                </SC.FormItemContent>
                            </SC.FormItem>
                        )}
                    </SC.Content>
                </CollapseBox>
                <CommonRule />
            </SC.Container>
        </>
    )
}
