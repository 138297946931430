import { flex, Input, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const PopoverContainer = styled.form`
    /* width: 473px; */
    /* padding: 24px 34px; */
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    padding: 0 24px;
    height: 28px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-black);
`

export const InputItem = styled(Input)``

export const Item = styled.div`
    ${flex}
    &>*:not(:first-child) {
        margin-left: 12px;
    }
`

export const Desc = styled.div`
    ${flex}
    align-items: center;
    font-size: var(--font-size-sm);
    line-height: 16px;

    & > *:not(:first-child) {
        margin-left: 5px;
    }
`

export const Footer = styled.div`
    padding: 16px 20px;
    background-color: var(--color-gray-50);
    ${flex}
    justify-content: flex-end;
    > * {
        margin-left: 12px;
    }
`

export const Body = styled.div`
    /* 此处需要上下预留padding 否则errorMessage的UI异常 */
    padding: 30px 24px;
    max-height: 400px;
    overflow-y: auto;
    ${tinyButtons}

    & > *:not(:first-child) {
        margin-top: 16px;
    }
`
