import { Group, Input, SegmentedControl } from '@byecode/ui'
import type { Field, ImageOptions, PageAbstract } from '@lighthouse/core'
import {
    APPLICATION_VERSION_PROD,
    FindUseLocationType,
    getDefaultValueOptions,
    getPageDataOptions,
    getUserDatasourceOptions,
    getViewOptions,
    ListItemPaddingByecodeUi,
    RATIO_OPTIONS,
    useAtomData,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Divider, Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { currentAppVersionIdAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { FieldSelect as Select } from '../../FieldSelect'
import { SwitchControl } from '../Common/SwitchControl'
import { VisibilityFilter } from '../Common/VisibilityFilter'
import { ALIGN_CONTROL, BLOCK_IMAGE_SOURCES, IMAGE_CLICK_OPTIONS, IMAGE_FIT_OPTIONS, SHAPE_OPTIONS } from '../constants'
import ImageUploadList from './ImageUploadList'

export const maxFileNum = 10

const SCxStyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
`
const fileField: Field = { type: 'file', id: 'file', dsId: '', name: '图片', innerType: 'ARRAY' }
export const BlockImageSettings: React.FC<{ allPages: PageAbstract[], blockId: string }> = ({ blockId, allPages = [] }) => {
    const disabledWithVersion = useIsDisabledWithVersion()

    const { control, register, watch, setValue } = useFormContext<ImageOptions>()
    const [shape, ratio, variant, sourceType, action, imageClickEvent, autoLoop] = watch([
        'shape',
        'ratio',
        'variant',
        'sourceType',
        'action',
        'imageClickEvent',
        'autoLoop'
    ])
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        if (!userDataSource) {
            return
        }
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => field.type === 'file'
        })
    }, [dataSourceList])

    const { customViewOption } = useVariableCustomViewOption(field => field.type === 'file')

    const options = useMemo(() => {
        const filter = (field: Field) => field.type === 'file'
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filter
            },
            curr.datasource && {
                variableSourceType: curr.variableSourceType,
                datasource: curr.datasource,
                validateFieldType: filter
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [curr.datasource, curr.variableSourceType, prev.datasource, prev.variableSourceType])

    // 原图比例仅限于矩形使用且非swiper， 1:1 只有圆形和正方形可使用
    const newRatioOptions = useMemo(() => {
        if (variant === 'swiper') {
            return RATIO_OPTIONS
        }
        return shape === 'rectangle' ? RATIO_OPTIONS.filter(item => !item.disable) : RATIO_OPTIONS
    }, [shape, variant])

    return (
        <>
            <Group label="图片">
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>图片来源</Text>
                    <Controller
                        name="sourceType"
                        control={control}
                        render={({ field }) => (
                            <Select
                                style={{
                                    width: 180
                                }}
                                searchable={false}
                                options={BLOCK_IMAGE_SOURCES}
                                value={field.value}
                                onChange={val => {
                                    if (val === 'field') {
                                        setValue('imageClickEvent', 'preview')
                                    }
                                    field.onChange(val)
                                }}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                {sourceType === 'default' ? (
                    <ImageUploadList blockId={blockId} disabled={disabledWithVersion} disableJump={imageClickEvent !== 'jump'} allPages={allPages} />
                ) : (
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>选择变量</Text>
                        <Controller
                            control={control}
                            name="imageSource"
                            render={({ field }) => (
                                <VariableSelect
                                    value={field.value}
                                    options={options}
                                    viewOption={customViewOption}
                                    userOption={userOption}
                                    field={fileField}
                                    placeholder="粘贴图片链接"
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                )}
                {sourceType === 'default' && (
                    <Controller
                        name="showTitle"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl
                                label="图片标题"
                                checked={field.value}
                                onChange={e => {
                                    field.onChange?.(e.currentTarget.checked)
                                }}
                            />
                        )}
                    />
                )}
                <Controller
                    name="variant"
                    control={control}
                    render={({ field }) => (
                        <SwitchControl
                            label="开启轮播"
                            checked={field.value === 'swiper'}
                            onChange={e => {
                                if (e.currentTarget.checked && shape === 'rectangle' && ratio === '0') {
                                    setValue('ratio', '4')
                                }
                                field.onChange?.(e.currentTarget.checked ? 'swiper' : 'default')
                            }}
                        />
                    )}
                />
                {variant === 'swiper' && (
                    <>
                        <Controller
                            name="autoLoop"
                            control={control}
                            render={({ field }) => (
                                <SwitchControl
                                    label="自动播放"
                                    checked={field.value}
                                    onChange={e => field.onChange?.(e.currentTarget.checked)}
                                />
                            )}
                        />
                        {autoLoop && (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>停顿时间</Text>
                                <Input
                                    {...register('swipeSpeed')}
                                    style={{
                                        width: 178
                                    }}
                                    type="number"
                                    min={0}
                                    suffix={<Text>s</Text>}
                                />
                            </ListItemPaddingByecodeUi>
                        )}
                    </>
                )}
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group
                label="动作"
                styles={{
                    collapse: {
                        padding: '0 8px !important'
                    }
                }}
            >
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center" style={{ margin: '0 8px' }}>
                    <Text>点击后</Text>
                    <div style={{ width: 180 }}>
                        <Controller
                            name="imageClickEvent"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    options={IMAGE_CLICK_OPTIONS.filter(item => !(sourceType === 'field' && item.value === 'jump'))}
                                    styles={{
                                        root: {
                                            width: 180
                                        }
                                    }}
                                    searchable={false}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </div>
                </ListItemPaddingByecodeUi>
                {imageClickEvent === 'custom' && (
                    <>
                        <Text style={{ lineHeight: '40px', padding: '0 8px' }}>自定义动作</Text>
                        <ActionAdder
                            title=""
                            showLabel={false}
                            prefixName=""
                            events={{
                                params: [],
                                handleEvent: 'none',
                                triggerEvent: 'click'
                            }}
                            allPages={allPages}
                            action={
                                action ?? {
                                    type: 'none',
                                    params: {},
                                    trigger: 'click'
                                }
                            }
                        />
                    </>
                )}
            </Group>

            <Divider color="var(--color-gray-200)" />
            <Group label="设计">
                <SCxStyleContainer>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>对齐</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                name="align"
                                control={control}
                                render={({ field }) => (
                                    <SegmentedControl
                                        fullWidth
                                        data={ALIGN_CONTROL}
                                        value={field.value}
                                        onChange={v => field.onChange(v)}
                                    />
                                )}
                            />
                        </div>
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图片适应</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                name="fitType"
                                control={control}
                                render={({ field }) => (
                                    <SegmentedControl
                                        data={IMAGE_FIT_OPTIONS}
                                        fullWidth
                                        value={field.value}
                                        onChange={v => field.onChange(v)}
                                    />
                                )}
                            />
                        </div>
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>形状</Text>
                        <Controller
                            name="shape"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    style={{
                                        width: 180
                                    }}
                                    searchable={false}
                                    options={SHAPE_OPTIONS}
                                    value={field.value}
                                    onChange={value => {
                                        const equalWidthAndHeight = RATIO_OPTIONS.find(item => item.value === '7')
                                        field.onChange(value)
                                        if (variant === 'swiper') {
                                            setValue('ratio', value === 'rectangle' ? '4' : equalWidthAndHeight?.value)
                                            return
                                        }
                                        setValue('ratio', value === 'rectangle' ? '0' : equalWidthAndHeight?.value)
                                    }}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>比例</Text>
                        <Controller
                            name="ratio"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    style={{
                                        width: 180
                                    }}
                                    searchable={false}
                                    disabled={disabledWithVersion || shape !== 'rectangle'}
                                    options={newRatioOptions}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )}
                        />
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>圆角</Text>
                        <Input
                            {...register('radius')}
                            style={{
                                width: 178
                            }}
                            type="number"
                            disabled={disabledWithVersion || shape === 'round'}
                            min={0}
                            placeholder="请输入圆角"
                        />
                    </ListItemPaddingByecodeUi>
                </SCxStyleContainer>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}
