import { IconFont } from '@lighthouse/bui'
import React from 'react'
import ReactDOM from 'react-dom'
import { useNetworkState } from 'react-use'
import styled from 'styled-components'

const SCxNetworkErrorNotificationWrapper = styled.div`
    position: absolute;
    top: 66px;
    right: 14px;
    z-index: 9999;
    padding: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 0.5px solid var(--color-gray-200);
    background: var(--color-white);

    /* 阴影样式 01 */
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
`

const SCxIconWrapper = styled.div`
    display: flex;
    width: 36px;
    height: 36px;
    padding: 2px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-red-50);
`

const SCxNoNetworkTitle = styled.div`
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    margin-bottom: 4px;
`

const SCxNoNetworkDesc = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-700);
`

export const NetworkErrorNotification: React.FC = () => {
    const { online } = useNetworkState()

    if (online) {
        return null
    }

    return ReactDOM.createPortal(
        <SCxNetworkErrorNotificationWrapper>
            <SCxIconWrapper>
                <IconFont type="NoWifi" color="var(--color-red-500)" size={20} />
            </SCxIconWrapper>
            <div>
                <SCxNoNetworkTitle>无法连接网络</SCxNoNetworkTitle>
                <SCxNoNetworkDesc>为避免数据丢失，请您刷新页面再进行后续操作</SCxNoNetworkDesc>
            </div>
        </SCxNetworkErrorNotificationWrapper>,
        document.body
    )
}
