import { getAuthorize, useAtomAction } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { updateWorkSpaceAtom } from '@/atoms/workSpace/action'
import { useWorkSpace, useWorkSpaceList } from '@/hooks/useWorkSpace'

import AccountIntegration from './AccountIntegration'
import { Box } from './Box'
import { Develop } from './Develop'
import { SpaceUpdateOrder } from './Order'
import Setting from './Setting'
import { SpaceData } from './SpaceData'

const SCxContainer = styled.div`
    padding: 40px 36px 24px 36px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-gray-25);
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const SpaceSetting: React.FC = () => {
    const workspace = useWorkSpace()
    const { roleId = '', id, apiKey } = workspace
    const spaceList = useWorkSpaceList()
    const { run: updateWorkSpace } = useAtomAction(updateWorkSpaceAtom)
    const isDeletePermission = useMemo(() => getAuthorize(roleId, 'spaceDelete'), [roleId])
    const isDevelopPermission = useMemo(() => getAuthorize(roleId, 'spaceDevelop'), [roleId])
    return (
        <SCxContainer>
            <Box>
                <SpaceData workspace={workspace} onUpdate={updateWorkSpace} />
            </Box>
            {isDeletePermission && (
                <Box>
                    <Setting workspace={workspace} spaceNum={spaceList.length} />
                </Box>
            )}
            {isDevelopPermission && (
                <Box>
                    <Develop spaceId={id} apiToken={apiKey} />
                </Box>
            )}
            {/* <Box>
                <SpaceUpdateOrder spaceId={id} />
            </Box> */}
            <Box>
                <AccountIntegration workspace={workspace} />
            </Box>
        </SCxContainer>
    )
}
