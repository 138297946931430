import { IconFont, Tag } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxUpdateFieldGroup = styled.div`
    display: flex;
    gap: 8px;
    padding-left: 54px;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
`

const SCxTagWrapper = styled.div`
    width: 100px;
`

const SCxUpdateFieldContent = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

const SCxUpdateValue = styled.div`
    color: var(--color-gray-700);
`

interface UpdateItemContentProps {
    title: string
    theme: string
    from: string
    to: string
}

export const UpdateItemContent: React.FC<UpdateItemContentProps> = ({ title, theme, from, to }) => {
    const color = `var(--color-${theme}-500)`
    const background = `var(--color-${theme}-100)`
    return (
        <SCxUpdateFieldGroup>
            <SCxTagWrapper>
                <Tag color={color} background={background} radius={4} style={{ fontSize: 12, height: '20px', lineHeight: '20px', padding: '0 4px', width: 'fit-content' }}>
                    {title}
                </Tag>
            </SCxTagWrapper>
            <SCxUpdateFieldContent>
                <SCxUpdateValue>{from}</SCxUpdateValue>
                <IconFont type="ArrowRight" fill="var(--color-gray-400)" />
                <SCxUpdateValue>{to}</SCxUpdateValue>
            </SCxUpdateFieldContent>
        </SCxUpdateFieldGroup>
    )
}
