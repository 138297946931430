import { flex } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 8px 0;
    font-size: var(--font-size-normal);
`
export const Content = styled.div`
    ${flex}
`
export const Header = styled.div`
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
`

export const Footer = styled.div`
    margin-top: 20px;
`

export const RadioGroup = styled.div`
    margin-bottom: 24px;
    ${flex}
    align-items: center;
    > div {
        width: 50%;
    }
`

export const UploadImgContain = styled.div`
    ${flex}
    justify-content: space-around;
    align-items: center;
`

export const UploadImgDesc = styled.div`
    width: 100%;
    padding-left: 8px;
    color: var(--color-gray-500);
    line-height: 24px;
    font-weight: 400;
    font-size: var(--font-size-normal);
    box-sizing: border-box;
`

export const LogoName = styled.div`
    margin-top: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: var(--font-size-normal);
    font-size: var(--font-size-normal);
    line-height: 16px;
    text-align: center;
    color: var(--color-black);
`
export const LeftFill = styled.div`
    /* border: 1px solid red; */
`

export const RightFill = styled.div`
    padding-left: 8px;
`
