import { IconFont, Tooltip } from '@byecode/ui'
import type { SpaceVersion } from '@lighthouse/core'
import { DEFAULT_SPACE_VERSION, defaultZhDateFormat, getBrand, spaceGradeNameMap } from '@lighthouse/shared'
import { Flex, Group, Image, Text } from '@mantine/core'
import { lightFormat } from 'date-fns'
import React, { useMemo } from 'react'
import { useCss } from 'react-use'
import styled from 'styled-components'

import { openSaleSpaceGrade, openSpaceGrade } from '@/components/SpaceGrade'
import { spaceThemeMap } from '@/constants/common'

interface SpaceQuotaInfoProps {
    data: {
        name?: string
        version?: SpaceVersion
        expiredAt?: number
    }
}

const SCxContainer = styled.div<{ tipLabel: string; tagColor: string }>`
    position: relative;
    width: 100%;
    height: 144px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    overflow: hidden;

    ::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 52px;
        height: 22px;
        line-height: 22px;
        font-size: var(--font-size-sm);
        text-align: center;
        content: '${({ tipLabel }) => tipLabel}';
        background-color: ${({ tagColor }) => tagColor};
        z-index: 0;
        border-radius: 11px 0 12px 0;
    }
`
const SCxTagImage = styled(Image)`
    position: absolute;
    right: -30px;
    bottom: -15px;
`
export const SpaceQuotaInfo: React.FunctionComponent<SpaceQuotaInfoProps> = ({ data }) => {
    const theme = spaceThemeMap[data?.version ?? DEFAULT_SPACE_VERSION]
    const { borderColor, background, tagColor, color } = theme
    const spaceTheme = useCss({
        borderColor,
        color,
        background
    })

    const dateEle = useMemo(() => {
        if (!data.expiredAt) {
            return
        }
        if (data.expiredAt === -1) {
            return '永久'
        }
        const currentDate = Date.now()
        if (data.expiredAt < currentDate) {
            const expiredDate = Math.round((currentDate - data.expiredAt) / 1000 / 3600 / 24)
            return (
                <Text size={14} color={color}>
                    {lightFormat(data.expiredAt, defaultZhDateFormat)} （已过期 {expiredDate} 天）
                </Text>
            )
        }
        return (
            <Text size={14} color={color}>
                {lightFormat(data.expiredAt, defaultZhDateFormat)} （剩余 {Math.round((data.expiredAt - currentDate) / 1000 / 3600 / 24)}{' '}
                天）
            </Text>
        )
    }, [color, data.expiredAt])

    return (
        <SCxContainer className={spaceTheme} tagColor={tagColor} tipLabel={spaceGradeNameMap[data?.version ?? DEFAULT_SPACE_VERSION]}>
            <Group>
                <Text size={14} color="var(--color-gray-500)">
                    空间名称
                </Text>
                <Text size={14} color={color}>
                    {data.name}
                </Text>
            </Group>
            <Group>
                <Text size={14} color="var(--color-gray-500)">
                    当前版本
                </Text>
                <Flex gap={8}>
                    <Text size={14} color={color}>
                        {spaceGradeNameMap[data?.version ?? DEFAULT_SPACE_VERSION]}
                    </Text>
                    {data.version !== 'ULTIMATE' && (
                        <Tooltip title="升级版本">
                            <IconFont
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    const brand = getBrand()
                                    brand === 'I_CLICK' ? openSaleSpaceGrade() : openSpaceGrade('version_upgrade')
                                }}
                                type="UploadSimple"
                                size={16}
                                color={color}
                            />
                        </Tooltip>
                    )}
                </Flex>
            </Group>
            <Group>
                <Text size={14} color="var(--color-gray-500)">
                    到期时间
                </Text>
                {dateEle}
            </Group>
            {/* {image && <SCxTagImage width={120} height={120} src={image} />} */}
        </SCxContainer>
    )
}
