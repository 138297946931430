import { IconFont } from '@byecode/ui/components/IconFont'
import type { FormContainerBlockConfig } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Flex, Text } from '@mantine/core'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { CollectorSetting } from './CollectorSetting'

interface WeChatCollectorProps {}

export const WeChatCollector: React.FunctionComponent<WeChatCollectorProps> = () => {
    const { control, watch, register, setValue } = useFormContext<FormContainerBlockConfig>()

    const [openSetting, setOpenSetting] = useState(false)
    const [isUsed] = watch(['weChatCollector.isUsed'])

    return (
        <ListItem4ByecodeUi justifyContent="space-between" style={{ cursor: 'pointer' }} onClick={() => setOpenSetting(true)}>
            <Flex align="center" gap={8}>
                <IconFont size={16} color={isUsed ? '#12B76A' : 'var(--color-gray-400)'} type="WeChatLogo" />
                <Text>采集微信用户信息</Text>
            </Flex>
            <Flex align="center" gap={8}>
                <Text size={14} color="var(--color-gray-400)">
                    {isUsed ? '开启' : '关闭'}
                </Text>
                <IconFont size={16} color="var(--color-gray-400)" type="ArrowRightSmall" />
            </Flex>
            {openSetting && (
                <CollectorSetting
                    onGoBack={e => {
                        e.stopPropagation()
                        setOpenSetting(false)
                    }}
                />
            )}
        </ListItem4ByecodeUi>
    )
}
