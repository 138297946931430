import type {
    FilterFormType
} from '@lighthouse/core';
import {
    type DataSourceAbstract,
    type Field,
    type FieldType,
    type FilterableProtocol,
    type HighLightConditions,
    type Sorter,
    type TableColumns,
    DataSourceType
} from '@lighthouse/core'
import type { InsertDirection } from '@lighthouse/shared'
import { ApplicationPreviewEnum, Display, Filter, FlexSearch, getAppointField, getViewColumns, Sort } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & > div {
        margin-left: 8px;
    }
`

export interface DataSourceSettingProps {
    dataSourceInfo: DataSourceAbstract
    enableShowHighLight?: boolean
    searchVal?: string
    // allDataSources?: ApplicationDataSourceDatum
    // allRecords?: ApplicationRecordDatum
    noSettingFields?: Set<FieldType>
    dataSourceList: DataSourceAbstract[]
    previewType: ApplicationPreviewEnum
    filter?: FilterFormType
    sorts?: Sorter[]
    onChangeSorter?: (data?: Sorter[]) => void
    onChangeDisplay?: (data?: TableColumns) => Promise<boolean>
    onChangeFilter?: (data?: FilterableProtocol['filter']) => void
    onChangeSearch?: (val: string) => void
    onChangeHighLightRules?: (data?: HighLightConditions) => void
    onCreateField?: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onUpdateField?: (fieldId: string, data: Field) => Promise<boolean>
    onDeleteField?: (field: Field) => Promise<boolean>
}

export const DataSourceSetting: React.FC<DataSourceSettingProps> = ({
    dataSourceInfo,
    enableShowHighLight = false,
    searchVal,
    // allDataSources,
    // allRecords,
    noSettingFields,
    dataSourceList,
    previewType,
    filter,
    sorts,
    onChangeSorter,
    onChangeDisplay,
    onChangeFilter,
    onChangeSearch,
    onChangeHighLightRules,
    onCreateField,
    onUpdateField,
    onDeleteField
}) => {
    const { id, schema, viewOptions, type } = dataSourceInfo
    const { tableProps } = viewOptions
    const isAggregateTable = useMemo(() => type === DataSourceType.aggregateDataSource, [type])
    const columns = getViewColumns({
        tableProps,
        schema
    })

    const primaryField = useMemo(() => {
        if (!dataSourceInfo) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSourceInfo, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSourceInfo])

    return (
        <SCxDiv>
            <FlexSearch key={id} value={searchVal} isMobile={previewType === ApplicationPreviewEnum.mobile} onChange={onChangeSearch} />
            <Filter
                dataSource={dataSourceInfo}
                dataSourceList={dataSourceList}
                columns={columns}
                primaryField={primaryField}
                isMobile={previewType === ApplicationPreviewEnum.mobile}
                data={filter}
                noSettingFields={noSettingFields}
                onChangeFilter={onChangeFilter}
            />
            <Sort
                noSettingFields={noSettingFields}
                columns={columns}
                isMobile={previewType === ApplicationPreviewEnum.mobile}
                data={sorts}
                isShowDsName={type === DataSourceType.joinDataSource}
                onChangeSorter={onChangeSorter}
            />
            <Display
                columns={columns}
                dataSource={dataSourceInfo}
                primaryField={primaryField}
                configurable={!isAggregateTable}
                dataSourceList={dataSourceList}
                isMobile={previewType === ApplicationPreviewEnum.mobile}
                onChangeDisplay={onChangeDisplay}
                onCreateField={onCreateField}
                onUpdateField={onUpdateField}
                onDeleteField={onDeleteField}
            />
            {/* {enableShowHighLight && (
                <HighLight
                    schema={schema}
                    primaryField={primaryField}
                    data={highLightRules}
                    onChangeHighLightRules={onChangeHighLightRules}
                    onFetchRelationDataSource={onFetchRelationDataSource}
                />
            )} */}
        </SCxDiv>
    )
}
