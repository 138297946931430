import { getAssetUrl } from '@lighthouse/assets'
import { AccountBox, useAtomAction } from '@lighthouse/shared'
import { BreakPointSize, useBreakpoint } from '@lighthouse/tools'
import { Image } from '@mantine/core'
import type { AnyObject } from 'immer/dist/internal'
import React, { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { consummateInfoAtom } from '@/atoms/auth/action'
import { encrypt } from '@/utils'

import * as SC from '../styles'
import { BetaRequestForm } from './BetaRequestForm'
import { StepIndicator } from './BetaRequestForm/StepIndicator'

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
`

const SCxContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const SCxHeader = styled.div`
    width: 100%;
    height: 15vh;
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

export const PerfectInfo: React.FC = () => {
    const { ref, breakPoint } = useBreakpoint()
    // ref.current = document.querySelector('#root')
    const isMobile = breakPoint !== BreakPointSize.lg
    // const location = useLocation()
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(0)
    const { run: consummateInfo, loading } = useAtomAction(consummateInfoAtom)
    const [searchParams] = useSearchParams()

    const redirect = searchParams.get('redirect')
    // const urlSearch = useMemo(() => location.search, [location])
    // 完成
    const finish = useCallback(
        async (data: AnyObject) => {
            const { username, password, spaceName, spaceIcon } = data
            const enctyptPassword = encrypt(password)
            const params = {
                username,
                password: enctyptPassword || '',
                spaceName,
                spaceIcon
            }
            const isPerfect = await consummateInfo(params)
            if (isPerfect) {
                navigate(
                    { pathname: redirect || '/' },
                    {
                        replace: true
                    }
                )
            }
        },
        [consummateInfo, navigate, redirect]
    )

    const handleChangeStep = useCallback((num: number) => {
        setCurrentStep(num)
    }, [])

    return (
        <SCxContainer ref={ref}>
            <SCxHeader>
                <Image key="logo" sx={{ userSelect: 'none' }} src={getAssetUrl('common', 'logo.png')} width={100} />
            </SCxHeader>
            <SCxContent>
                <StepIndicator currentStep={currentStep} isMobile={isMobile} />
                <AccountBox height="auto">
                    <SC.FormContainer>
                        <BetaRequestForm step={currentStep} onChangeStep={handleChangeStep} onSubmit={finish} />
                    </SC.FormContainer>
                </AccountBox>
            </SCxContent>
        </SCxContainer>
    )
}
