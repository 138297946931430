import { Group, Select, Text } from '@byecode/ui'
import type {SubFormBlockConfig } from '@lighthouse/core';
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import { COUNTRY_LIST, ListItemPaddingByecodeUi } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { CountrySelect } from '@/components/CountrySelect'

import type { BaseFieldInputSetting } from '../types'

interface PhoneNumberConfigProps extends Pick<BaseFieldInputSetting, 'prefix'>{}

export const PhoneNumberConfig: React.FunctionComponent<PhoneNumberConfigProps> = ({ prefix }) => {
    const { watch, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [openSendSmsCode, selectAreaCode] = watch([`${prefix}phoneNumber.isOpened`, `${prefix}phoneNumber.selectAreaCode`])

    const countryOptions = useMemo(() => {
        return selectAreaCode?.map(short => {
            const country = COUNTRY_LIST.find(item => item.short === short)
            return {
                label: country?.en,
                value: short,
                icon: <img src={country?.flag} alt="" width={16} />,
                extra: <Text color="var(--color-gray-400)">+{country?.tel}</Text>
            }
        })
    }, [selectAreaCode])

    return (
        <Controller
            name={`${prefix}phoneNumber.isUsedAreaCode`}
            control={control}
            render={({ field }) => (
                <Group
                    label="区号"
                    mode="switch"
                    switchProps={{ disabled: openSendSmsCode }}
                    opened={field.value ?? false}
                    onCollapseChange={field.onChange}
                >
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>可选的国家/地区</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                name={`${prefix}phoneNumber.selectAreaCode`}
                                control={control}
                                render={({ field }) => <CountrySelect value={field.value} onChange={field.onChange} />}
                            />
                        </div>
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>默认的国家/地区</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                name={`${prefix}phoneNumber.areaCode`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        options={countryOptions}
                                        dropdownWidth={300}
                                        searchable
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </div>
                    </ListItemPaddingByecodeUi>
                </Group>
            )}
        />
    )
}
