import { backgroundTransitionOnClick, flex, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    font-family: 'PingFang SC';
    font-style: normal;
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    margin-bottom: 16px;
    font-weight: 550;
    font-size: var(--h3-font-size);
    line-height: 28px;
`

export const BtnGroup = styled.div`
    width: 100%;
    ${flex}
    justify-content: flex-end;
    align-items: center;
    margin-top: 28px;
    > * {
        margin-left: 12px;
    }
`

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`
export const Text = styled.div<{ size?: number; color?: string; isOmit?: boolean }>`
    font-family: 'PingFang SC';
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: 16px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`
