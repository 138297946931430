import type { FindUseADTObject } from '@lighthouse/shared'
import { FindUseType } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FindUseDataSource } from './FindUseDataSource'
import { FindUseField } from './FindUseField'

const SCxContainer = styled.div`
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-200);
    height: 105px;
`

const SCxHeader = styled.div`
    padding: 0 16px;
    color: var(--color-gray-500);
    font-size: 14px;
    height: 32px;
    line-height: 32px;
`

const SCxContent = styled.div`
    padding: 0 16px;
`

interface FindUseObjectProps {
    config?: FindUseADTObject
    onChange?: (val: FindUseADTObject) => void
}

export const FindUseObject: React.FC<FindUseObjectProps> = ({ config, onChange }) => {
    const content = useMemo(() => {
        if (!config) {
            return null
        }
        switch (config.type) {
            case FindUseType.FIELD: {
                return <FindUseField config={config} onChange={onChange} />
            }
            case FindUseType.DATASHEET: {
                return <FindUseDataSource config={config} onChange={onChange} />
            }
            default: {
                return null
            }
        }
    }, [config, onChange])

    const objectName = config?.type === FindUseType.DATASHEET ? '数据表' : '字段'

    return (
        <SCxContainer>
            <SCxHeader>查找对象: {objectName}</SCxHeader>
            <SCxContent>{content}</SCxContent>
        </SCxContainer>
    )
}
