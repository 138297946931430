import type { FieldInputValue } from '@lighthouse/core';
import { type RecordOpenType, SelectedMode } from '@lighthouse/core';
import { getMainTableRecordId, useAtomAction } from '@lighthouse/shared';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useMemo } from 'react';

import { deleteRecordAtom } from '@/atoms/dataSource/action';
import { recordPoolAtom } from '@/atoms/dataSource/state';
import { openPageStackAtom, rollbackPageStackHistoryAtom } from '@/atoms/page/action';
import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state';
import { stackFactory } from '@/atoms/page/utils';
import { useCurrentPageContext, useRootPageContext } from '@/contexts/PageContext';
import * as srv from '@/services';

import { useCurrentEnvId } from './useApplication';
import { usePageStackActions } from './usePageStackActions';


export const useActionRuntimeParams = () => {
    const { refreshPage } = useCurrentPageContext()
    const envId = useCurrentEnvId()
    const { run: popStack } = useAtomAction(rollbackPageStackHistoryAtom)
    const { peekCurrentPageStack, closeCurrentPageLayer } = usePageStackActions()
    const rootPageId = useRootPageContext()

    const updateRecord = useAtomCallback(useCallback(async (get, set, manualDeps: {
        appId: string, envId: string, dsId: string, recordId: string, fields: {
            fieldId: string
            value: FieldInputValue
        }[]
    }) => {
        const { appId, envId, dsId, recordId, fields } = manualDeps
        const MainTableRecordId = getMainTableRecordId(recordId)
        const record = await srv.updateRecordWithFields({
            appId,
            envId,
            dsId,
            recordId,
            fields
        })

        if (record) {
            set(recordPoolAtom, draft => {
                // const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
                const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                    if (cur.appId === appId && cur.envId === envId && cur.dsId === dsId && getMainTableRecordId(cur.id) === MainTableRecordId) {
                        prev.push(index)
                    }
                    return prev
                }, [])
                if (recordsIndex.length === 0) {
                    return
                }
                recordsIndex.forEach(i => {
                    fields.forEach(f => {
                        draft[i].content[f.fieldId] = { value: f.value }
                    })
                })
            })
        }
        return record
    }, []))

    const deleteRecord = useAtomCallback(
        useCallback(
            (get, set, manualDeps?: { dsId: string; recordId: string }) => {
                const pageDeps = get(lastPageOfStackAtom)
                const deps = manualDeps ?? pageDeps

                if (!deps) {
                    return
                }

                const { dsId, recordId } = deps

                if (!dsId || !envId || !recordId) {
                    return
                }

                // const isDeleted = await
                // await srv.deleteRecord({ appId, dsId, recordIds: [recordId], mode: SelectedMode.CURRENT_PAGE })
                // if (isDeleted) {

                // }

                return set(deleteRecordAtom, {
                    envId,
                    dsId,
                    recordIds: [recordId],
                    mode: SelectedMode.CURRENT_PAGE
                })
            },
            [envId]
        )
    )

    const openPageAtomCallback = useAtomCallback(
        useCallback(
            (
                get,
                set,
                {
                    pageId,
                    openType,
                    appId,
                    dsId,
                    viewId,
                    recordId,
                    onSaveCallback
                }: {
                    pageId: string
                    openType: RecordOpenType
                    appId: string
                    dsId?: string
                    viewId?: string
                    recordId?: string
                    onSaveCallback?: () => void
                }
            ) => {
                const page = get(pageAtomFamily(pageId))
                // 2024-03-25 灿白说改成全部作为子页面的交互形式
                // const stackRootPageId = page?.type === 'default' && openType === RecordOpenType.page ? page.id : rootPageId ?? ''
                const stackRootPageId = rootPageId ?? ''
                // TODO: 临时解决连接表问题
                const rId = getMainTableRecordId(recordId)
                // openModal({ id: pageId, name: pageName }, { openType, appId, dsId, recordId, onSaveCallback })
                set(
                    openPageStackAtom,
                    stackFactory({
                        pageId,
                        rootPageId: stackRootPageId,
                        stackDisplayType: openType,
                        onSave: onSaveCallback,
                        appId,
                        dsId,
                        viewId,
                        recordId: rId
                    })
                )
            },
            [rootPageId]
        )
    )

    const getCurrentPageDepsAtomCallback = useAtomCallback(
        useCallback(get => {
            return get(lastPageOfStackAtom)
        }, [])
    )

    const actionEvents = useMemo(() => {
        return {
            openPage: openPageAtomCallback,
            peekCurrentPageStack,
            closePage: () => {
                popStack()
            },
            closeCurrentPageLayer,
            refreshPage,
            deleteRecord,
            updateRecord,
        }
    }, [openPageAtomCallback, peekCurrentPageStack, closeCurrentPageLayer, refreshPage, deleteRecord, updateRecord, popStack])

    return {
        actionEvents,
        getCurrentPageDeps: getCurrentPageDepsAtomCallback
    }
}
