import { type PageType, type SpaceVersion,MAX_CONTAINER_WIDTH_SIZE, SMS_PLATFORM } from '@lighthouse/core'

import type { CreatePageMode } from '@/atoms/page/types'

export const spaceThemeMap: Record<SpaceVersion, { background: string; color: string; tagColor: string; borderColor: string }> = {
    FREE: {
        background: 'linear-gradient(153deg, #FEFEFF 17.46%, #ECECEC 188.42%)',
        borderColor: 'var(--color-gray-200)',
        tagColor: 'var(--color-gray-100)',
        color: 'var(--color-black)'
    },
    BASIC: {
        background: 'linear-gradient(130deg, #FAFAFF 11.91%, #E8E8FF 89.07%)',
        borderColor: 'var(--color-theme-4)',
        tagColor: 'var(--color-theme-3)',
        color: 'var(--color-theme-10)'
    },
    STANDARD: {
        background: 'linear-gradient(130deg, #FAFAFF 11.91%, #E8E8FF 89.07%)',
        borderColor: 'var(--color-theme-4)',
        tagColor: 'var(--color-theme-3)',
        color: 'var(--color-theme-10)'
    },
    ADVANCED: {
        background: 'linear-gradient(129.63deg, rgba(171, 120, 255, 0.08) 11.91%, rgba(60, 43, 255, 0.16) 89.07%)',
        borderColor: 'var(--color-purple-300)',
        tagColor: 'var(--color-purple-100)',
        color: 'var(--color-purple-600)'
    },
    PROFESSIONAL: {
        background: 'linear-gradient(122.88deg, #F2F7FF 9.76%, #D9E8FF 73.42%)',
        tagColor: 'rgba(209, 233, 255, 1)',
        color: 'rgba(24, 73, 169, 1)',
        borderColor: 'rgba(178, 221, 255, 1)'
    },
    ENTERPRISE: {
        background: 'linear-gradient(132.53deg, #FFF5F8 1.28%, #FFDEE6 86.21%)',
        tagColor: 'rgba(255, 228, 232, 1)',
        color: 'rgba(161, 16, 67, 1)',
        borderColor: 'rgba(255, 204, 214, 1)'
    },
    ULTIMATE: {
        background: 'linear-gradient(154deg, #FDF0E6 4.33%, #FBE6CC 128.04%)',
        tagColor: '#FEF0C7',
        color: '#93370D',
        borderColor: '#FEF0C7'
    },
    I_CLICK_FREE: {
        background: 'linear-gradient(153deg, #FEFEFF 17.46%, #ECECEC 188.42%)',
        borderColor: 'var(--color-gray-200)',
        tagColor: 'var(--color-gray-100)',
        color: 'var(--color-black)'
    },
    I_CLICK_BASIC: {
        background: 'linear-gradient(130deg, #FAFAFF 11.91%, #E8E8FF 89.07%)',
        borderColor: 'var(--color-theme-4)',
        tagColor: 'var(--color-theme-3)',
        color: 'var(--color-theme-10)'
    },
    I_CLICK_STANDARD: {
        background: 'linear-gradient(130deg, #FAFAFF 11.91%, #E8E8FF 89.07%)',
        borderColor: 'var(--color-theme-4)',
        tagColor: 'var(--color-theme-3)',
        color: 'var(--color-theme-10)'
    },
    I_CLICK_ADVANCED: {
        background: 'linear-gradient(129.63deg, rgba(171, 120, 255, 0.08) 11.91%, rgba(60, 43, 255, 0.16) 89.07%);',
        borderColor: 'var(--color-purple-300)',
        tagColor: 'var(--color-purple-100)',
        color: 'var(--color-purple-600)'
    },
    I_CLICK_PROFESSIONAL: {
        background: 'linear-gradient(122.88deg, #F2F7FF 9.76%, #D9E8FF 73.42%)',
        tagColor: '##D1E9FF',
        color: '#1849A9',
        borderColor: '##B2DDFF'
    },
    I_CLICK_ENTERPRISE: {
        background: 'linear-gradient(132.53deg, #FFF5F8 1.28%, #FFDEE6 86.21%)',
        tagColor: 'rgba(255, 228, 232, 1)',
        color: 'rgba(161, 16, 67, 1)',
        borderColor: 'rgba(255, 204, 214, 1)'
    },
    I_CLICK_ULTIMATE: {
        background: 'linear-gradient(154deg, #FDF0E6 4.33%, #FBE6CC 128.04%)',
        tagColor: '#FEF0C7',
        color: '#93370D',
        borderColor: '#FEF0C7'
    }
}

export const pageTypeToCreateModeMap: Record<PageType, CreatePageMode | undefined> = {
    creator: 'CAN_CREATE_RECORD',
    default: undefined,
    document: 'CAN_OPEN_RECORD',
    edit: 'CAN_EDIT_RECORD'
}

export const createModeToPageTypeMap: Record<CreatePageMode, PageType> = {
    CAN_CREATE_RECORD: 'creator',
    CAN_OPEN_RECORD: 'document',
    CAN_EDIT_RECORD: 'edit'
}

export const MAX_WIDTH_OPTIONS = [
    {
        label: '840px',
        value: MAX_CONTAINER_WIDTH_SIZE.sm
    },
    {
        label: '960px',
        value: MAX_CONTAINER_WIDTH_SIZE.md
    },
    {
        label: '1200px',
        value: MAX_CONTAINER_WIDTH_SIZE.lg
    },
    {
        label: '1440px',
        value: MAX_CONTAINER_WIDTH_SIZE.xl
    },
    {
        label: '不限制',
        value: MAX_CONTAINER_WIDTH_SIZE.unlimited
    }
]


export const SmsPlatformOptions = [
    {
        label: '阿里云短信服务',
        value: SMS_PLATFORM.ALIYUN
    }
]
