import styled from 'styled-components'

export const StyledIconWrapper = styled.div<{ active?: boolean; activeColor?: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${p => (p.active ? p.activeColor ?? 'var(--color-main-tint)' : 'var(--color-gray-100)')};
    border-radius: 8px;
    width: 32px;
    height: 32px;
`

export const StyledItemTitle = styled.div`
    margin-bottom: 4px;
    font-weight: var(--font-weight-bold);
`

export const StyledActionLabel = styled.div`
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-sm);
    margin-top: 2;

`
