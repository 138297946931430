import { IconFont, Text } from '@byecode/ui'
import type { PageAbstract } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { appLayoutModeAtom } from '@/atoms/application/state'
import { ModuleType } from '@/components/ApplicationSetting/type'

interface SystemPageProps {
    data: PageAbstract
}

const SCxContainer = styled.div`
    display: flex;
    gap: 4px;
    height: 36px;
    align-items: center;
    padding: 8px 12px 8px 28px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
        background: var(--color-gray-100);
    }
    &.active {
        background: var(--color-gray-100);
    }
`

export const SystemPage: React.FunctionComponent<SystemPageProps> = ({ data }) => {
    const appLayoutMode = useAtomData(appLayoutModeAtom)
    const { run: setAppLayoutMode } = useAtomAction(appLayoutModeAtom)
    return (
        <SCxContainer
            className={appLayoutMode.type === 'setting' && appLayoutMode.currentTab === ModuleType.login ? 'active' : undefined}
            onClick={() => {
                setAppLayoutMode({
                    type: 'setting',
                    currentTab: ModuleType.login
                })
            }}
        >
            <IconFont type="SignIn" size={16} color="var(--color-gray-400)" />
            <Text lineHeight="16px" color="var(--color-black)" size={14}>
                {data.name}
            </Text>
        </SCxContainer>
    )
}
