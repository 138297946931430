import type {
    DataSourceAbstract,
    FieldBlockWithDsId,
    FieldType,
    FilterBlockAbstract,
    VariableADTField,
    ViewField,
    ViewType
} from '@lighthouse/core'
import { FilterMode } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import type { VariableFormUpstreamSource, VariableUpstreamSource } from '../../types'
import { type VariableSource, VariableSourceType } from '../../types'
import { getIdVariableData, getRealField } from '../../utils'
import { Fields, MorePopover, Operator, Params } from '../Condition'
import { FieldVariable } from '../Condition/FieldVariable'
import { ParamsVariable } from '../Condition/ParamsVariable'

export interface FilterConditionProps {
    children?: React.ReactNode
    prefixName: string
    columns: ViewField[]
    dataSource?: DataSourceAbstract
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    formDsId?: string
    sources?: VariableSource[]
    dataSourceList: DataSourceAbstract[]
    noSettingFields?: Set<FieldType>
    fieldMode?: FilterMode
    paramsMode?: FilterMode
    enableCurrentUser?: boolean
    viewDsId?: string
    viewType?: ViewType
    filterBlocks?: FilterBlockAbstract[]
    enableFilterVariable?: boolean
    disabled?: boolean
    onDuplicate?: () => void
    onDelete?: () => void
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

const SCxFlex = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 12px;
`

const FilterCondition: React.FC<FilterConditionProps> = ({
    dataSource,
    fieldBlocksWithDsId,
    sources,
    dataSourceList,
    prefixName,
    columns,
    noSettingFields,
    fieldMode = FilterMode.NORMAL,
    paramsMode = FilterMode.NORMAL,
    enableCurrentUser,
    formDsId,
    viewDsId,
    viewType,
    filterBlocks,
    enableFilterVariable,
    disabled,
    onDuplicate,
    onDelete,
    onMouseEnter,
    onMouseLeave
}) => {
    const { control } = useFormContext()
    const idVariable: VariableADTField = useWatch({
        control,
        name: `${prefixName}.idVariable`
    })

    const field = useMemo(() => {
        const viewDataSource = dataSourceList.find(item => item.id === viewDsId)
        const sourceFieldFlowNode = sources?.find(
            item => item.sourceType === VariableSourceType.parentNode || item.sourceType === VariableSourceType.form
        ) as VariableUpstreamSource | VariableFormUpstreamSource
        return getIdVariableData({
            idVariable,
            dataSourceList,
            sourceFlowNode: sourceFieldFlowNode?.parentNodes,
            dataSource,
            fieldBlocksWithDsId,
            viewDataSource
        })
    }, [dataSource, dataSourceList, fieldBlocksWithDsId, idVariable, sources, viewDsId])

    const realField = useMemo(() => {
        if (!field) {
            return
        }
        const _field = getRealField(field)
        if (!_field) {
            return
        }
        return _field
    }, [field])

    const fieldContent = useMemo(() => {
        if (fieldMode === FilterMode.CUSTOM) {
            return (
                <FieldVariable
                    sources={sources}
                    viewDsId={viewDsId}
                    viewType={viewType}
                    dataSourceList={dataSourceList}
                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                    prefixName={prefixName}
                    disabled={disabled}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    // enableFieldCurrentUser={enableFieldCurrentUser}
                />
            )
        }
        return (
            <Fields columns={columns} dataSource={dataSource} noSettingFields={noSettingFields} prefixName={prefixName} disabled={disabled} />
        )
    }, [columns, dataSource, dataSourceList, disabled, fieldBlocksWithDsId, fieldMode, noSettingFields, onMouseEnter, onMouseLeave, prefixName, sources, viewDsId, viewType])

    const paramContent = useMemo(() => {
        if (realField?.type === 'file') {
            return null
        }
        if (paramsMode === FilterMode.CUSTOM && realField?.type !== 'checkbox') {
            return (
                <ParamsVariable
                    width="100%"
                    field={realField}
                    sources={sources}
                    viewDsId={viewDsId}
                    viewType={viewType}
                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                    dataSourceList={dataSourceList}
                    prefixName={prefixName}
                    formDsId={formDsId}
                    disabled={disabled}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    filterBlocks={filterBlocks}
                />
            )
        }
        return <Params width="100%" field={realField} prefixName={prefixName} enableCurrentUser={enableCurrentUser} disabled={disabled} />
    }, [
        realField,
        paramsMode,
        prefixName,
        enableCurrentUser,
        disabled,
        sources,
        viewDsId,
        viewType,
        fieldBlocksWithDsId,
        dataSourceList,
        formDsId,
        onMouseEnter,
        onMouseLeave,
        filterBlocks
    ])

    return (
        <SCxFlex style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <SCxFlex style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                {fieldContent}
                <Operator
                    field={field}
                    prefixName={prefixName}
                    clearable={false}
                    disabled={disabled}
                    enableFilterVariable={enableFilterVariable}
                />
                {paramContent}
            </SCxFlex>
            <MorePopover disabled={disabled} onDuplicate={onDuplicate} onDelete={onDelete} />
        </SCxFlex>
    )
}

export default FilterCondition
