import { Divider } from '@byecode/ui'
import React from 'react'

import { ColorCategory } from './ColorCategory'
import * as SC from './styles'
import { TextCategory } from './TextCategory'

interface SettingThemeProps {
    // color?: string
    // onChange?: (value: string) => void
}

const SettingTheme: React.FC<SettingThemeProps> = () => {
    return (
        <SC.Root disableCollapse label="样式">
            <ColorCategory />
            <Divider my={8} />
            <TextCategory />
        </SC.Root>
    )
}

export default SettingTheme
