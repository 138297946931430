import { Group, IconFont } from '@byecode/ui'
import type { ChartViewOptions, SchemaProtocol } from '@lighthouse/core'
import { areaChartType } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { DimensionSetting } from '../DimensionSetting'

export const ChartConfigurator = styled.div``

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

interface DimensionConfiguratorProps {
    dsId: string
    schema: SchemaProtocol['schema']
}

export const DimensionConfigurator: React.FC<DimensionConfiguratorProps> = ({ dsId, schema }) => {
    const { control } = useFormContext<ChartViewOptions>()
    const chartType = useWatch({ control, name: 'chartType' })

    const isAreaChart = areaChartType.has(chartType)
    const dimensions = useWatch({ control, name: 'dimensions' })
    const mainAxis = useWatch({ control, name: 'mainAxis' })
    // const enableHierarchy = useWatch({ control, name: 'enableHierarchy' })
    const enableDimensions = (isAreaChart && dimensions.length === 0 && mainAxis.length < 2) || !isAreaChart
    // const showHierarchy = (!isPieChart && dimensions.length > 0) || (isPieChart && dimensions.length === 1 )
    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group label="维度">
                <ChartConfigurator>
                    <Controller
                        control={control}
                        name="dimensions"
                        render={({ field }) => (
                            <DimensionSetting
                                dsId={dsId}
                                value={field.value}
                                onChange={field.onChange}
                                disableAdd={!enableDimensions}
                                // limitIds={hierarchies?.map(({ fieldId }) => fieldId)}
                                type={chartType}
                                schema={schema}
                            />
                        )}
                    />
                </ChartConfigurator>
                {/* {showHierarchy && (
                    <ListItem4ByecodeUi compact enablePadding justifyContent="space-between" alignItems="center">
                        <Flex gap="6px" alignItems="center">
                            <Text>启用层级结构</Text>
                            <Tooltip withinPortal label="使用层级结构可展开查看更多维度">
                                <SCxIcon size={16} type="Question" />
                            </Tooltip>
                        </Flex>
                        <Controller
                            control={control}
                            name="enableHierarchy"
                            render={({ field }) => <Switch checked={field.value} onChange={field.onChange} />}
                        />
                    </ListItem4ByecodeUi>
                )}
                {enableHierarchy && showHierarchy && (
                    <ChartConfigurator>
                        <Controller
                            control={control}
                            name="hierarchies"
                            render={({ field }) => (
                                <DimensionSetting
                                    disableAdd={dimensions.length === 0}
                                    limitIds={dimensions.map(({ fieldId }) => fieldId)}
                                    value={field.value}
                                    onChange={field.onChange}
                                    type={chartType}
                                    schema={schema}
                                />
                            )}
                        />
                    </ChartConfigurator>
                )} */}
            </Group>
        </>
    )
    // <Divider color="var(--color-gray-200)" />
    // <Group label="指标">
    //     <ChartConfigurator>
    //         <Controller
    //             control={control}
    //             name="chartOption.values"
    //             render={({ field }) => (
    //                 <ValuesConfigurator
    //                     {...field}
    //                     dimensions={getValues('chartOption.dimensions')}
    //                     aggregate={getValues('chartOption.aggregate')}
    //                     type={chartType}
    //                     schema={schema}
    //                 />
    //             )}
    //         />
    //     </ChartConfigurator>
    // </Group>
}
