import type { InputProps } from '@byecode/ui'
import { Input } from '@byecode/ui'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import { Flex } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

interface InputControlProps extends Omit<InputProps, 'onChange'> {
    label: string
    value?: string | number
    placeholder?: string
    enablePadding?: boolean
    tipNode?: React.ReactNode
    onChange?: (value: string) => void
}

export const InputControl = React.forwardRef<HTMLDivElement, InputControlProps>(
    ({ label, enablePadding = false, tipNode, onChange, ...rest }, ref) => {
        return (
            <ListItemPaddingByecodeUi ref={ref} enablePadding={enablePadding} alignItems="center" justifyContent="space-between">
                <Flex align="center" gap={6}>
                    {label}
                    {tipNode}
                </Flex>
                <Input style={{ width: 180 }} {...rest} onChange={e => onChange?.(e.target.value)} />
            </ListItemPaddingByecodeUi>
        )
    }
)
