import { Toast } from '@byecode/ui'
import type { SubFormRecord } from '@lighthouse/block'
import { FormContainerBlock, getFormOfFieldCodeValidator, getFormOfSubFormCodeValidator, PageModuleForm } from '@lighthouse/block'
import type { ButtonAction, FieldInputValue, FormContainerBlockAbstract, InputValueItem, PageType } from '@lighthouse/core'
import type { FlowLayoutContainerNode, FlowLayoutNode } from '@lighthouse/shared'
import { ApplicationPreviewEnum, pageStackPubSub, transformFilterNormalFilter, useAtomAction, useAtomData } from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'

import { setRecordAtom } from '@/atoms/dataSource/action'
import { pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useFieldBlockDefaultValueRender } from '@/hooks/useFieldBlockDefaultValueRender'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { usePageStackActions } from '@/hooks/usePageStackActions'
import { useUserRecord } from '@/hooks/useUserRecord'
import * as srv from '@/services'

interface FormContainerControllerProps {
    blockData: FormContainerBlockAbstract
    children?: React.ReactNode
    node: FlowLayoutNode
}

const FormContainerController = (props: FormContainerControllerProps) => {
    const { blockData, node, children } = props
    const { id: blockId, config } = blockData
    const { action, isUseFormRecord, rules = [], pointer } = config

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, pointer)
    const preview = usePreviewType()
    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { record: userRecord } = useUserRecord()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const fieldBlockValueMap = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.formState, [])
    )
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )

    const { handleActionTrigger } = useActionTrigger()
    const { peekCurrentPageStack, closeCurrentPageLayer } = usePageStackActions()
    const { run: updateRecord } = useAtomAction(setRecordAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const [pageType, pageDsId = ''] = useAtomData(
        pageAtomFamily(pageId),
        React.useCallback(s => [s?.type, s?.dsId], [])
    )

    const reqRules = useMemo(
        () =>
            rules.map(rule => {
                const { filter, checkFilter } = rule
                return {
                    ...rule,
                    filter: transformFilterNormalFilter(filter, fieldBlockValueMap),
                    checkFilter: transformFilterNormalFilter(checkFilter, fieldBlockValueMap)
                }
            }),
        [fieldBlockValueMap, rules]
    )

    const onVariableValueRender = useFieldBlockDefaultValueRender(pointer)
    // 表单提交-start
    const handleSubmit = React.useCallback(
        async (params: {
            blockId: string
            pointer: string
            value: InputValueItem[]
            currentRecordId?: string
            parentRecordId?: string
            action?: ButtonAction
            subFormRecord?: Record<string, SubFormRecord[]>
        }) => {
            const { blockId, pointer, value, currentRecordId, action, parentRecordId, subFormRecord } = params
            const fields = value.map(({ fieldId, value, isCheckRepeat }) => ({ fieldId, value, isCheckRepeat }))
            const subFormRecords = Object.entries(subFormRecord ?? {}).reduce<SubFormRecord[]>((pre, cur) => [...pre, ...cur[1]], [])
            const res = await srv.submitForm({
                appId,
                envId,
                pageId,
                blockId,
                dsId: pointer,
                fields,
                parentRecordId,
                currentRecordId,
                validator: {
                    rules: reqRules,
                    codeValidator: {
                        ...getFormOfFieldCodeValidator(value, blocks),
                        ...getFormOfSubFormCodeValidator(subFormRecords, blocks)
                    }
                },
                subFormRecord: Object.entries(subFormRecord ?? {}).map(([id, records]) => ({
                    subFormId: id,
                    subFormContent: records.reduce<{ columnId: string; value: FieldInputValue }[][]>(
                        (prev, { id, content }) => [
                            ...prev,
                            Object.entries(content).map(([id, item]) => ({ columnId: id, value: item.value }))
                        ],
                        []
                    )
                }))
            })

            const { record, repeatFieldIds, failureMessage } = res

            if (failureMessage) {
                Toast.error(failureMessage)
                return res
            }

            // 有不可重复字段
            if (!record) {
                return res
            }

            // 获取动作执行前的当前页面栈
            const prevPageStack = peekCurrentPageStack()

            // 表单提交完后执行动作
            // 2023-11-28 11:15:43 灿白说去掉提交成功的提示，表单提交后动作提示
            if (record && action) {
                await handleActionTrigger(action, { formRecord: record })
            }

            // 获取动作执行后的当前页面栈
            // 2023-11-07 13:57:04 灿白说去掉提交成功的提示，表单提交后动作提示
            const currentPageStack = peekCurrentPageStack()
            // 只有页面类型为表单或编辑页时，且打开类型为弹窗或者抽屉时，关闭弹窗
            // 如果后续动作存在打开页面的操作，则不用手动关闭页面
            // if (
            //     !/open(.*)Page/.test(action?.type ?? '') &&
            //     (pageType === 'creator' || pageType === 'edit') &&
            //     (currentPageStack?.stackDisplayType === 'modal' || currentPageStack?.stackDisplayType === 'drawer')
            // ) {
            //     closeCurrentPageLayer()
            // }
            // 如果在动作执行过程中，当前页面栈没有发生变化，则关闭当前页面，否则不关闭，因为动作执行过程打开了新页面
            if (
                (pageType === 'creator' || pageType === 'edit') &&
                (currentPageStack?.stackDisplayType === 'modal' || currentPageStack?.stackDisplayType === 'drawer') &&
                prevPageStack?.stackId === currentPageStack?.stackId
            ) {
                closeCurrentPageLayer()
            }

            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)

                if (stack) {
                    if (!stack.blockRuntimeState.formContainer) {
                        stack.blockRuntimeState.formContainer = {}
                    }
                    stack.blockRuntimeState.formContainer = {}
                }
            })

            // 更新操作也需要，因为有关联或者 filter 的场景
            pageStackPubSub.emit(`${pointer}-ADD`, record)

            if (pageType === 'edit') {
                const { dsId, id: recordId } = record
                updateRecord({
                    envId,
                    dsId,
                    recordId,
                    record
                })
                return res
            }

            return res
        },
        [
            appId,
            blocks,
            closeCurrentPageLayer,
            envId,
            handleActionTrigger,
            pageId,
            pageType,
            peekCurrentPageStack,
            reqRules,
            rootPageId,
            setPageStack,
            stackId,
            updateRecord
        ]
    )
    // 表单提交-end

    // 页面卸载时还原状态
    useEffect(() => {
        return () => {
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    if (!stack.blockRuntimeState.formContainer) {
                        stack.blockRuntimeState.formContainer = {}
                    }
                    stack.blockRuntimeState.formContainer[blockId] = {
                        changed: false
                    }
                }
            })
        }
    }, [blockId, rootPageId, setPageStack, stackId])

    return (
        <PageModuleForm
            node={node as FlowLayoutContainerNode}
            nodes={nodes}
            block={blockData}
            blocks={blocks}
            pageType={pageType as PageType}
            userRecord={userRecord}
            dataSource={dataSource}
            isMobile={preview === ApplicationPreviewEnum.mobile}
            onVariableValueRender={onVariableValueRender}
            onSubmit={(val, pointer, subFormRecordMap) =>
                handleSubmit?.({
                    blockId,
                    pointer,
                    value: val,
                    currentRecordId: curr.recordId,
                    parentRecordId: prev.recordId,
                    action,
                    subFormRecord: subFormRecordMap
                })
            }
        >
            <FormContainerBlock {...props} />
        </PageModuleForm>
    )
}

export default FormContainerController
