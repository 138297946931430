import { Card, Collapse, Grid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React from 'react'

import { Basic } from './Basic'
import { Quick } from './Quick'
import { Utils } from './Utils'
import { Variant } from './Variant'
import { WithOtherPop } from './WithOtherPop'

export const ModalComponentDemo = () => {
    const [opened, { toggle }] = useDisclosure(true)

    return (
        <Card withBorder>
            <Title order={2} onClick={toggle}>
                Modal
            </Title>
            <Collapse in={opened}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Basic />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Utils />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Quick />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Variant />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <WithOtherPop />
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    )
}
