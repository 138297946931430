import * as React from 'react'
import styled from 'styled-components'

import type { PageSubmissionStateEnum } from '@/services/types'

import { searchEngineClassNameMap, searchEngineStatusMap } from '../constants'

interface QueueStatusTagProps {
    status: PageSubmissionStateEnum
}

const SCxContainer = styled.div`
    display: flex;
    width: 54px;
    height: 24px;
    padding: 12px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    &.success {
        color: #12b76a;
        background-color: #e9faf0;
    }
    &.fail {
        color: var(--color-red-500);
        background-color: var(--color-theme-50);
    }
    &.queue {
        color: var(--color-theme-7);
        background-color: var(--color-theme-1);
    }
`
export const QueueStatusTag: React.FunctionComponent<QueueStatusTagProps> = ({ status }) => {
    return <SCxContainer className={searchEngineClassNameMap.get(status)}>{searchEngineStatusMap.get(status)}</SCxContainer>
}
