import type { GroupProps } from '@byecode/ui'
import { flex, Group, Tooltip } from '@byecode/ui'
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { Text } from '@mantine/core'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface SettingBlockProps {
    label?: React.ReactNode
    disableCollapse?: boolean
    children?: React.ReactNode
    isMargin?: boolean
    tooltipLabel?: string
    mode?: GroupProps['mode']
    opened?: boolean
    onChangeCollapse?: (v: boolean) => void
    style?: React.CSSProperties
    className?: string
}

const Header = styled.div`
    ${flex}
    height: 40px;
    line-height: 40px;
    justify-content: space-between;
    cursor: pointer;
`
const SCxContainer = styled.div`
    width: 100%;
    /* padding: 12px 16px; */
    border-top: 1px solid var(--color-gray-200);
`

const SCxMargin = styled.div<{ isMargin?: boolean }>`
    margin: ${({ isMargin }) => (isMargin ? '8px 0' : 0)};
`

const Title = styled.div`
    color: var(--color-black);
    font-weight: 600;
    font-size: var(--font-size-normal);
    display: flex;
    align-items: center;
    gap: 8px;
`

const SettingBlock = React.forwardRef<HTMLDivElement, SettingBlockProps>(
    (
        {
            label = '',
            disableCollapse = false,
            isMargin,
            mode = 'default',
            tooltipLabel,
            className,
            style,
            children,
            opened,
            onChangeCollapse
        },
        ref
    ) => {
        const [open, setOpen] = useUncontrolled({ value: opened, onChange: onChangeCollapse })

        return (
            <SCxContainer ref={ref} className={className}>
                <Group
                    collapsible={!disableCollapse}
                    mode={disableCollapse ? 'none' : mode}
                    switchProps={{ disabled: false }}
                    label={
                        <Header style={style}>
                            <Title>
                                <Text>{label}</Text>
                                {tooltipLabel && (
                                    <Tooltip
                                        title={
                                            <Text
                                                weight={400}
                                                size={12}
                                                style={{ maxWidth: 140, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                                            >
                                                {tooltipLabel}
                                            </Text>
                                        }
                                    >
                                        <CM.Icon color="var(--color-gray-400)" type="Question" size={16} />
                                    </Tooltip>
                                )}
                            </Title>
                        </Header>
                    }
                    opened={open}
                    onCollapseChange={setOpen}
                >
                    <SCxMargin isMargin={isMargin}>{children}</SCxMargin>
                </Group>

                {/* </SCWrapper> */}
            </SCxContainer>
        )
    }
)

export default SettingBlock
