import type { AggregateField, FilterFormType, UpstreamVariable, ValueVariable } from '@lighthouse/core'
import type { Node } from 'reactflow'

export enum AggregatorNodeType {
    'DATA_INPUT' = 'DATA_INPUT',
    'JOIN' = 'JOIN',
    'FIELD_SETTING' = 'FIELD_SETTING',
    'FILTER' = 'FILTER',
    'GROUP_STATISTICS' = 'GROUP_STATISTICS',
    'ADD_PLACEHOLDER' = 'ADD_PLACEHOLDER' // 前端使用
}

export type StatisticsCalcType = 'SUM' | 'AVG' | 'MAX' | 'MIN' | 'COUNT' | 'TO_ARRAY'

export type StatisticsField = {
    fieldId: string
    statisticsFieldId: string
    calcType?: StatisticsCalcType
    name?: string
}

export type groupByField = {
    fieldId: string
    groupByFieldId: string
}

export type AggregateResultField = AggregateField & {
    visible: boolean
}

export enum AggregatorJoinType {
    'LEFT_JOIN' = 'LEFT_JOIN',
    'RIGHT_JOIN' = 'RIGHT_JOIN',
    'INNER_JOIN' = 'INNER_JOIN'
}

export type AggregatorJoinLeft = UpstreamVariable

export type AggregatorJoinRight = UpstreamVariable | ValueVariable

export type AggregatorJoinCondition = {
    left: AggregatorJoinLeft
    operator: string
    right: AggregatorJoinRight
}

export type AggregatorNodeResult = {
    fieldList?: AggregateResultField[]
}
export interface AggregatorNodeConfigProtocol {
    name: string
    // result: AggregatorNodeResult
}

export type AggregatorDataInputConfig = AggregatorNodeConfigProtocol & {
    dsId: string
    fieldIds?: string[]
}

export type AggregatorJoinConfig = AggregatorNodeConfigProtocol & {
    joinType: AggregatorJoinType
    rules: AggregatorJoinCondition[]
}

export type AggregatorFieldSettingConfig = AggregatorNodeConfigProtocol & {
    fieldList: AggregateResultField[]
}

export type AggregatorFilterConfig = AggregatorNodeConfigProtocol & FilterFormType

export type AggregatorGroupStatisticsConfig = AggregatorNodeConfigProtocol & {
    groupByFields: groupByField[]
    statisticsFields: StatisticsField[]
}

export type AggregatorNodeConfig =
    | AggregatorDataInputConfig
    | AggregatorJoinConfig
    | AggregatorFieldSettingConfig
    | AggregatorFilterConfig
    | AggregatorGroupStatisticsConfig

export type AggregatorDataInputNodeData<T extends AggregatorNodeConfig> = {
    config: T
    result?: AggregatorNodeResult
}

export type AggregatorDataInputNode = Node<AggregatorDataInputNodeData<AggregatorDataInputConfig>, AggregatorNodeType.DATA_INPUT>
export type AggregatorJoinNode = Node<AggregatorDataInputNodeData<AggregatorJoinConfig>, AggregatorNodeType.JOIN>
export type AggregatorFieldSettingNode = Node<AggregatorDataInputNodeData<AggregatorFieldSettingConfig>, AggregatorNodeType.FIELD_SETTING>
export type AggregatorFilterNode = Node<AggregatorDataInputNodeData<AggregatorFilterConfig>, AggregatorNodeType.FILTER>
export type AggregatorGroupStatisticsNode = Node<
    AggregatorDataInputNodeData<AggregatorGroupStatisticsConfig>,
    AggregatorNodeType.GROUP_STATISTICS
>
export type AggregatorAddPlaceholderNode = Node<AggregatorDataInputNodeData<AggregatorNodeConfig>, AggregatorNodeType.ADD_PLACEHOLDER>

export type AggregatorNode =
    | AggregatorDataInputNode
    | AggregatorJoinNode
    | AggregatorFieldSettingNode
    | AggregatorFilterNode
    | AggregatorGroupStatisticsNode
    | AggregatorAddPlaceholderNode
