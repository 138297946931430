import type { AppUser } from '@lighthouse/core'
import { ApplicationPreviewEnum, DEVELOPER, VISITOR } from '@lighthouse/shared'
import { Enum } from '@lighthouse/tools'

import { ModuleType } from '@/components/ApplicationSetting/type'

// export enum ApplicationPreview {
//     pc = 'pc',
//     h5 = 'h5'
// }

export const APPLICATION_LAYOUT_MODE_TYPE = Enum('analyze', 'setting', 'preview', 'dataSource', 'users', 'flow', 'otherPlatforms', 'system')
export type ApplicationLayoutModeType = Enum<typeof APPLICATION_LAYOUT_MODE_TYPE>
// 应用当前所处展示模式
export type ApplicationLayoutMode = {
    type: ApplicationLayoutModeType
    currentTab?: ModuleType
}

/** 应用临时状态，与服务器无交互 */
export const APPLICATION_SERVERLESS_STATE = {
    previewType: ApplicationPreviewEnum.desktop
}

/** 应用布局状态，与服务器无交互 */
export const APPLICATION_LAYOUT_MODE: ApplicationLayoutMode = {
    type: 'preview',
    currentTab: ModuleType.webTag
}

export type ApplicationModePageHistory = {
    setting: {
        currentTab: ModuleType
    }
    dataSource: {
        subPath: string[]
    }
    users: {
        currentTab: 'user' | 'userGroup'
    }
    flow: {
        flowIds: string[]
    }
    preview: {
        pages: {
            pageId?: string
            viewId?: string
            recordId?: string
            path: string
        }[]
    }
    otherPlatforms: {
        //
    }
}

export const APPLICATION_MODE_PAGE_HISTORY: ApplicationModePageHistory = {
    setting: {
        currentTab: ModuleType.login
    },
    dataSource: {
        subPath: []
    },
    users: {
        currentTab: 'user'
    },
    flow: {
        flowIds: []
    },
    preview: {
        pages: []
    },
    otherPlatforms: {
        //
    }
}

export const developerRole: AppUser = {
    userId: DEVELOPER,
    uniqueUserId: DEVELOPER,
    username: '应用管理者',
    mobile: ''
}

export const visitorRole: AppUser = {
    userId: VISITOR,
    uniqueUserId: VISITOR,
    username: '游客',
    mobile: ''
}

export const commonRoleIds = [developerRole.userId, visitorRole.userId]

export const CURRENT_PAGE = 'CURRENT_PAGE'

export const HOME_PAGE = 'HOME_PAGE'

export const RELATIVE_PAGE = [HOME_PAGE, CURRENT_PAGE]
