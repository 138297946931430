import { Group } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { getFieldIcon, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { filter } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { FieldSelect } from '../../../FieldSelect'
import { calcTypeOptions, notIndicatorFieldInnerType, notIndicatorFieldType } from '../../constants'

export const SCxWhiteBgSelect: typeof FieldSelect = styled(FieldSelect)``

const SCxRight = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: nowrap row;
    align-items: center;
    justify-content: flex-end;
`

interface SingleIndicatorConfiguratorProps {
    dataSource: DataSourceAbstract
}

export const SingleIndicatorConfigurator: React.FC<SingleIndicatorConfiguratorProps> = ({ dataSource }) => {
    const { control } = useFormContext()
    const fieldId = useWatch({ control, name: 'fieldId' })
    // const { control, reset, getValues } = useFormContext()

    const fieldList = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const { schema } = dataSource
        return Object.keys(schema)
            .filter(id => !notIndicatorFieldType.has(schema[id].type) && !notIndicatorFieldInnerType.has(schema[id]?.innerType || 'NULL'))
            .map(id => {
                const { name, type, innerType } = schema[id]
                return { value: id, label: name, icon: getFieldIcon(id, type, innerType), type, innerType }
            })
    }, [dataSource])

    const options: typeof calcTypeOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const { schema } = dataSource
        const currentField = schema[fieldId]
        if (!currentField) {
            return []
        }
        const isNumberNormalType = currentField.innerType === 'NUMBER'

        return filter(({ value }) => (isNumberNormalType ? true : ['count', 'countUnique'].includes(value)), calcTypeOptions)
    }, [dataSource, fieldId])

    return (
        <Group label="指标卡">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>字段</Text>
                <SCxRight>
                    <Controller
                        name="fieldId"
                        control={control}
                        render={({ field }) => (
                            <SCxWhiteBgSelect
                                styles={{
                                    root: {
                                        width: 120,
                                        marginRight: 6
                                    }
                                }}
                                size="md"
                                searchable={false}
                                {...field}
                                options={fieldList}
                            />
                        )}
                    />
                    <Controller
                        name="calcType"
                        control={control}
                        render={({ field }) => (
                            <SCxWhiteBgSelect
                                styles={{
                                    root: {
                                        width: 79
                                    }
                                }}
                                size="md"
                                searchable={false}
                                {...field}
                                options={options}
                            />
                        )}
                    />
                </SCxRight>
            </ListItem4ByecodeUi>
        </Group>
    )
}
