import type { SpaceVersion } from '@lighthouse/core'
import mitt from 'mitt'

type Event = {
    openVersionConfirm: { url: string; version: SpaceVersion }
    openSaleSpaceGrade: undefined
    closeSaleSpaceGrade: undefined
    openSpaceGrade: 'version_upgrade' | 'sms_purchase' | undefined
    closeSpaceGrade: undefined
    closeVersionConfirm: undefined
}

export const emitter = mitt<Event>()

export const openSpaceGrade = (defaultTab?: 'version_upgrade' | 'sms_purchase') => {
    emitter.emit('openSpaceGrade', defaultTab)
}

export const closeSpaceGrade = () => {
    emitter.emit('closeSpaceGrade')
}

export const openSaleSpaceGrade = () => {
    emitter.emit('openSaleSpaceGrade')
}

export const closeSaleSpaceGrade = () => {
    emitter.emit('closeSaleSpaceGrade')
}

export const openSpaceGradeConfirm = (url: string, version: SpaceVersion) => {
    emitter.emit('openVersionConfirm', { url, version })
}

export const closeSpaceGradeConfirm = () => {
    emitter.emit('closeVersionConfirm')
}

// export const openSpaceGradeConfirm = (url: string, version: SpaceVersion) => {
//     return LModal.confirm({
//         icon: 'PublishDouble',
//         text: `需升级到「${spaceGradeNameMap[version]}」才能使用该功能`,
//         iconSize: 62,
//         type: 'primary',
//         okText: '升级版本',
//         partText: '查看功能介绍',
//         cancelText: ''
//     }).then(e => {
//         if (e === 'resolve') {
//
//         }
//         if (e === 'part') {
//             url &&  window.open(url, '_blank')
//         }
//     })
// }
