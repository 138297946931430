import type { MenuDropdownProps, MenuProps, PopoverProps } from '@byecode/ui'
import { Flex, IconFont, Menu, singleTextEllipsis } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import type { ReactNode } from 'react'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledDropdown = styled(Menu.Dropdown)`
    font-size: 14px;
`

const StyledText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    ${singleTextEllipsis()}
`

export interface ListItemMenuProps extends Partial<MenuProps> {
    label: ReactNode
    icon?: ReactNode
    value?: ReactNode
    width?: PopoverProps['width']
    popupContent: ReactNode
    dropdownProps?: Partial<MenuDropdownProps>
    enablePadding?: boolean
    enableHover?: boolean
    compact?: boolean
    highlighting?: boolean
    style?: React.CSSProperties
}

/** 可弹出额外内容的配置项单元 */
export const ListItemMenu = forwardRef<HTMLDivElement, ListItemMenuProps>(
    (
        {
            label,
            icon,
            value,
            width = 'target',
            popupContent,
            compact = false,
            highlighting,
            enablePadding = true,
            enableHover = true,
            dropdownProps,
            style,
            ...props
        },
        ref
    ) => {
        return (
            <Menu width={width} trapFocus={false} closeOnItemClick={false} withinPortal position="right-start" {...props}>
                <Menu.Target>
                    <ListItem4ByecodeUi
                        justifyContent="space-between"
                        compact={compact}
                        enablePadding={enablePadding}
                        enableHover={enableHover}
                        alignItems="center"
                        highlighting={highlighting}
                        ref={ref}
                        style={style}
                    >
                        <Flex style={{ overflow: 'hidden' }} alignItems="center">
                            <Flex style={{ color: 'var(--color-gray-400)' }}>{icon}</Flex>
                            <StyledText style={{ marginLeft: icon ? 8 : 0 }}>{label}</StyledText>
                        </Flex>
                        <Flex alignItems="center" style={{ overflow: 'hidden', cursor: 'pointer' }}>
                            <StyledText color="var(--color-gray-400)">{value || '未设置'}</StyledText>
                            <IconFont color="var(--color-gray-400)" size={16} type="ArrowRightSmall" />
                        </Flex>
                    </ListItem4ByecodeUi>
                </Menu.Target>
                <StyledDropdown {...dropdownProps}>{popupContent}</StyledDropdown>
            </Menu>
        )
    }
)
