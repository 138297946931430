import { Button, Input } from '@byecode/ui'
import { type AppUserDepartment, ErrorMessage, PersonSelect, useAtomAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import type { ModalProps } from '@mantine/core'
import { Modal } from '@mantine/core'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { fetchDataSourceDepartmentAtom, fetchDataSourceUserAtom } from '@/atoms/dataSource/action'
import { dataSourceUserListAtom } from '@/atoms/dataSource/state'
import * as srv from '@/services'
import { useDataSourceDepartments } from '@/shared/reusable'

import { DepartmentSelect } from '../DepartmentSelect'
import * as SC from './styles'

export type FormState = Omit<AppUserDepartment, 'departmentChild'>

interface DepartmentModalProps extends ModalProps {
    envId: string
    data?: FormState
    mode: 'edit' | 'add'
}

const DepartmentModal: React.FunctionComponent<DepartmentModalProps> = ({ envId, data, mode, onClose, ...rest }) => {
    const { data: departmentList } = useDataSourceDepartments()
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    // const { update: usersUpdate } = useDataSourceUsers()
    const personOptions = useAtomData(dataSourceUserListAtom)
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<FormState>({
        mode: 'onSubmit',
        defaultValues: data
    })

    useUpdateEffect(() => {
        reset(data)
    }, [data])

    const handleAdd = useCallback(
        async (params: FormState) => {
            setLoading(true)
            const res = await srv.createDepartment(envId, { ...params, departmentId: nanoid() })
            setLoading(false)
            if (res) {
                fetchDataSourceDepartment()
                onClose()
                params.departmentManager && fetchDataSourceUser()
            }
        },
        [fetchDataSourceDepartment, envId, onClose, fetchDataSourceUser]
    )
    const handleUpdate = useCallback(
        async (params: FormState) => {
            const res = await srv.updateDepartment(envId, params)
            if (res) {
                fetchDataSourceDepartment()
                onClose()
                fetchDataSourceUser()
            }
        },
        [fetchDataSourceDepartment, envId, onClose, fetchDataSourceUser]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <Modal
            {...rest}
            onClose={() => {
                setLoading(false)
                onClose?.()
            }}
            padding={0}
            centered
            styles={{
                header: {
                    height: 44,
                    margin: 0
                },
                close: {
                    marginTop: 12,
                    marginRight: 12
                }
            }}
        >
            <SC.Container onSubmit={handleInviteSubmit}>
                <SC.Content>
                    <SC.Title>{mode === 'add' ? '新建' : '编辑'}部门</SC.Title>
                    <SC.Item>
                        <SC.Text>
                            部门名称
                            <SC.Text color="red" style={{ paddingLeft: 4 }}>
                                *
                            </SC.Text>
                        </SC.Text>
                        <ErrorMessage name="departmentName" errors={errors}>
                            <Input placeholder="请输入" size="lg" {...register('departmentName', { required: '不能为空' })} />
                        </ErrorMessage>
                    </SC.Item>
                    <SC.Item>
                        <SC.Text>上级部门</SC.Text>
                        <ErrorMessage name="departParentId" errors={errors}>
                            <Controller
                                control={control}
                                name="departParentId"
                                render={({ field }) => (
                                    <DepartmentSelect
                                        value={field.value ? [field.value] : []}
                                        onChange={val => field.onChange(val[0])}
                                        options={departmentList}
                                    />
                                )}
                            />
                        </ErrorMessage>
                    </SC.Item>
                    <SC.Item>
                        <SC.Text>部门主管</SC.Text>
                        <ErrorMessage name="departmentManager" errors={errors}>
                            <Controller
                                control={control}
                                name="departmentManager"
                                render={({ field }) => (
                                    <PersonSelect
                                        enableCheckBox={false}
                                        width="376px"
                                        value={field.value ? [field.value] : []}
                                        styles={{
                                            container: {
                                                height: 36,
                                                backgroundColor: 'var(--color-gray-100)',
                                                borderStyle: 'none',
                                                borderRadius: 7
                                            }
                                        }}
                                        isMultiple={false}
                                        placeholder="请选择部门主管"
                                        onChange={val => field.onChange(val[0])}
                                        options={personOptions}
                                    />
                                )}
                            />
                        </ErrorMessage>
                    </SC.Item>
                </SC.Content>
                <SC.Footer>
                    <Button
                        size="lg"
                        style={{ width: 104 }}
                        onClick={() => {
                            setLoading(false)
                            onClose?.()
                        }}
                    >
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.Footer>
            </SC.Container>
        </Modal>
    )
}

export default DepartmentModal
