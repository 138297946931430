import { styled } from '@byecode/ui/theme/stitches.config'

export const StyledDropZone = styled('div', {
    width: '100%',
    height: '100%',
    flex: 1,
    minHeight: '141px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    fontSize: '14px',
    color: 'var(--color-gray-500)',
    border: '1px solid $colorGray200',
    backgroundColor: '$colorGray100',
    cursor: 'pointer'
})

export const Indicator = styled('div', {
    display: 'none',
    height: '100px',
    backgroundColor: 'yellow'
})

export const EmptyContainer = styled('div', {
    width: '100%',
    [`&.drag-over ${Indicator}`]: {
        display: 'block'
    }
})

export const Container = styled('div', {
    width: '100%',
    padding: '12px 0 8px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
})

export const Content = styled('div', {
    display: 'flex',
    flex: 1,
    gap: '12px',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$colorGray50',
    borderRadius: 'inherit'
})

export const VideoInfo = styled('div', {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    gap: '6px'
})
export const Title = styled('div', {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '158px'
})

export const ButtonGroup = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px'
})

export const Text = styled('span', {})

export const Img = styled('img', {
    objectFit: 'contain'
})

export const StressText = styled('span', {
    color: 'var(--color-purple-500)',
    fontWeight: 600
})
