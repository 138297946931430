import { IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
`
export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? 16}px;
    ${pointer}
`

export const Text = styled.span`
    flex-shrink: 0;
    line-height: 16px;
    /* text-align: center; */
    ${singleTextEllipsis}
    font-size: var(--font-size-sm);
`
export const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Header = styled.div`
    width: 100%;
    height: 52px;
    padding: 16px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color: var(--color-white);
    background: #649bfa;
`

export const Name = styled.span`
    margin-left: 7.5px;
    /* font-size: var(--font-size-base); */
`

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--color-gray-100);
`
