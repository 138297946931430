import type { FlexProps, SegmentedControlProps } from '@byecode/ui'
import { SegmentedControl } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

interface FormSegmentedControlProps extends SegmentedControlProps {
    label?: React.ReactNode
    compact?: boolean
    color?: string
    wrapperProps?: FlexProps & { enablePadding?: boolean }
}

const StyledSegment = styled(SegmentedControl)`
    /* flex: 0 0 182px; */
`

/** SegmentedControl表单项组件 */
const FormSegmentedControl = forwardRef<HTMLDivElement, FormSegmentedControlProps>(
    ({ label, wrapperProps, compact, color, ...rest }, ref) => {
        return (
            <ListItem4ByecodeUi justifyContent="space-between" compact={compact} alignItems="center" {...wrapperProps}>
                {label && <Text color={color}>{label}</Text>}
                <div style={{ width: 180 }}>
                    <StyledSegment ref={ref} fullWidth {...rest} />
                </div>
            </ListItem4ByecodeUi>
        )
    }
)

export default FormSegmentedControl
