import type { DataSourceAction, DataSourceItemAction, MenuItem } from '@lighthouse/shared'

export const menuOperationItems: MenuItem<DataSourceItemAction>[] = [
    {
        section: '操作',
        items: [
            {
                name: '重命名',
                icon: 'PencilSimple',
                hotkey: '',
                action: 'RENAME'
            },
            {
                name: '查找使用',
                icon: 'Search',
                hotkey: '',
                action: 'FINDUSE'
            },
            {
                name: '复制',
                icon: 'Duplicate',
                hotkey: '',
                action: 'DUPLICATE'
            },
            {
                name: '删除',
                icon: 'Trash',
                hotkey: '',
                action: 'DELETE'
            }
        ]
    }
]
