import type { Option } from '@byecode/ui'
import { Button, Modal, Select } from '@byecode/ui'
import { DataSourceType } from '@lighthouse/core'
import type { SynchronizeParams } from '@lighthouse/shared'
import { ErrorMessage, getTableIcon, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { appListAtom } from '@/atoms/application/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId,useDataSourceList as useCurrentDataSourceList } from '@/hooks/useDataSource'
import { useDataSourceList, useEnvList } from '@/shared/reusable'

// import type { FieldOption } from '../FieldSelect'
// import { FieldSelect } from '../FieldSelect'

const SCxContainer = styled.form`
    outline: none;
`

const SCxContent = styled.div`
    padding: 0 20px;
`

const SCxItem = styled.div`
    margin-bottom: 16px;
`

const SCxLabel = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
    margin-bottom: 10px;
`

const SCxSelect = styled(Select)`
    height: 36px;
`

const SCxFooter = styled.div`
    width: 100%;
    padding: 12px 20px;
    background: var(--color-gray-50);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const SCxSubmit = styled(Button)`
    margin-left: 10px;
    height: 36px;
    width: 80px;
`

const SCxCancel = styled(Button)`
    height: 36px;
    width: 80px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

interface DataSourceSynchronizeModalProps {
    open: boolean
    onSynchronize: (params: SynchronizeParams) => void
    onClose: () => void
}

export const DataSourceSynchronizeModal: React.FC<DataSourceSynchronizeModalProps> = ({ open, onSynchronize, onClose }) => {
    const {
        formState: { errors },
        control,
        setValue,
        watch,
        handleSubmit
    } = useForm<SynchronizeParams>({
        mode: 'onSubmit',
        shouldFocusError: false
    })
    const currentAppId = useCurrentAppID()
    const currentEnvId = useDataSourceEnvId()
    const currentDataSourceList = useCurrentDataSourceList(currentAppId, currentEnvId)
    const appList = useAtomData(appListAtom)
    const appId = watch('appId')
    const envId = watch('envId')
    const { data: envList } = useEnvList(appId)
    // const dataSourceList = useDataSourceList(appId)
    const { data: dataSourceList } = useDataSourceList(appId, envId)

    const appOptions = useMemo(
        () =>
            appList.reduce<Option[]>((acc, app) => {
                if (app.id === currentAppId) {
                    return acc
                }
                acc.push({
                    label: app.name,
                    value: app.id,
                    icon: app.icon
                })
                return acc
            }, []),
        [appList, currentAppId]
    )

    const envOptions = useMemo(() => {
        if (!envList) {
            return []
        }
        return envList.map(item => ({
            label: item.envName,
            value: item.envId,
            icon: 'DataSource'
        }))
    }, [envList])

    const dataSourceOptions = useMemo(() => {
        if (!appId) {
            return []
        }
        if (!dataSourceList) {
            return []
        }
        return dataSourceList.reduce<Option[]>((acc, dataSource) => {
            if (dataSource.type === DataSourceType.userDataSource || dataSource.sync || dataSource.type === DataSourceType.joinDataSource) {
                return acc
            }
            const asyncDataSource = find(
                item => item.syncAppId === appId && item.syncEnvId === dataSource.envId && item?.syncDsId === dataSource.id,
                currentDataSourceList
            )
            if (asyncDataSource) {
                return acc
            }
            acc.push({
                label: dataSource.name,
                value: dataSource.id,
                icon: getTableIcon(dataSource)
            })
            return acc
        }, [])
    }, [appId, currentDataSourceList, dataSourceList])

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(onSynchronize)()
        },
        [handleSubmit, onSynchronize]
    )

    return (
        <Modal
            width={720}
            open={open}
            styles={{
                desktop: {
                    body: {
                        padding: '24px 0 0 0'
                    }
                }
            }}
            title="数据同步设置"
            onClose={onClose}
        >
            <SCxContainer onSubmit={handleFormSubmit}>
                <SCxContent>
                    <SCxItem>
                        <SCxLabel>选择应用</SCxLabel>
                        <Controller
                            control={control}
                            name="appId"
                            rules={{ required: '请选择应用' }}
                            render={({ field }) => (
                                <ErrorMessage name="appId" errors={errors} zIndex="unset">
                                    <SCxSelect
                                        searchable
                                        options={appOptions}
                                        value={field.value}
                                        onChange={id => {
                                            field.onChange(id)
                                            setValue('dsId', '')
                                        }}
                                    />
                                </ErrorMessage>
                            )}
                        />
                    </SCxItem>
                    {appId && (
                        <SCxItem>
                            <SCxLabel>选择环境</SCxLabel>
                            <Controller
                                control={control}
                                name="envId"
                                rules={{ required: '请选择应用' }}
                                render={({ field }) => (
                                    <ErrorMessage name="envId" errors={errors} zIndex="unset">
                                        <SCxSelect
                                            searchable
                                            options={envOptions}
                                            value={field.value}
                                            onChange={id => {
                                                field.onChange(id)
                                                setValue('dsId', '')
                                            }}
                                        />
                                    </ErrorMessage>
                                )}
                            />
                        </SCxItem>
                    )}
                    {appId && envId && (
                        <SCxItem>
                            <SCxLabel>选择数据表</SCxLabel>
                            <Controller
                                control={control}
                                name="dsId"
                                rules={{ required: '请选择数据表' }}
                                render={({ field }) => (
                                    <ErrorMessage name="dsId" errors={errors} zIndex="unset">
                                        <SCxSelect searchable options={dataSourceOptions} value={field.value} onChange={field.onChange} />
                                    </ErrorMessage>
                                )}
                            />
                        </SCxItem>
                    )}
                </SCxContent>
                <SCxFooter>
                    <SCxSubmit htmlType="submit" type="primary">
                        同步
                    </SCxSubmit>
                    <SCxCancel onClick={onClose}>取消</SCxCancel>
                </SCxFooter>
            </SCxContainer>
        </Modal>
    )
}
