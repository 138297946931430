import { Flex, Group, Select } from '@byecode/ui'
import type { ChartViewOptions } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { chartThemeColorMap, chartThemeColorOptions } from '../../constants'
import { ColorList } from './ColorList'

const SCxPatchesList = styled(Flex)``

const SCxPatches = styled.div<{ background?: string }>`
    width: 12px;
    height: 12px;
    background: ${props => props.background};
`

export const ThemeColorConfigurator: React.FC = () => {
    const { control, setValue, watch } = useFormContext<ChartViewOptions>()
    const themeColor = watch('theme')
    const themeColorOptions = useMemo(() => {
        const multiColors = chartThemeColorOptions.filter(item => item.value.includes('multi'))
        const singleColors = chartThemeColorOptions.filter(item => item.value.includes('single'))

        return [
            {
                label: '多色',
                value: 'multi',
                children: multiColors.map(item => ({
                    label: item.label,
                    value: item.value,
                    extra: (
                        <SCxPatchesList justifyContent="flex-start" alignItems="center">
                            {chartThemeColorMap[item.value].map(color => (
                                <SCxPatches key={color} background={color} />
                            ))}
                        </SCxPatchesList>
                    )
                }))
            },
            {
                label: '单色',
                value: 'single',
                children: singleColors.map(item => ({
                    label: item.label,
                    value: item.value,
                    extra: (
                        <SCxPatchesList justifyContent="flex-start" alignItems="center">
                            {chartThemeColorMap[item.value].map(color => (
                                <SCxPatches key={color} background={color} />
                            ))}
                        </SCxPatchesList>
                    )
                }))
            }
        ]
    }, [])

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group label="主题颜色">
                <Controller
                    control={control}
                    name="theme"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                options={themeColorOptions}
                                value={value}
                                onChange={(val: string) => {
                                    onChange(val)
                                    if (chartThemeColorMap[val]) {
                                        setValue('colors', chartThemeColorMap[val])
                                    }
                                }}
                            />
                        )
                    }}
                />
                <Controller
                    control={control}
                    name="colors"
                    render={({ field: { value, onChange } }) => {
                        return <ColorList value={value} onChange={onChange} defaultColors={chartThemeColorMap?.[themeColor || '']} />
                    }}
                />
            </Group>
        </>
    )
}
