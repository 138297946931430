import type { FlowEdge, FlowNode } from '@lighthouse/shared'
import { NODE_HORIZONTAL_GAP, NODE_VERTICAL_GAP, NODE_WIDTH } from '@lighthouse/shared'

interface NodesTopToBottomTree {
    node: string
    level: number
    levelIndex: number
    levelCount: number
    data?: FlowEdge
}

export const getRootNodeType = (nodes: FlowNode[]) => {
    return nodes.find(node => node.type === 'TRIGGER')
}

export const getNodesTopToBottomTree = (edges: FlowEdge[]) => {
    const edgedNodes = new Set<string>()
    const nodesWithNoIncomingEdges = new Set<string>()
    const nodesWithNoOutgoingEdges = new Set<string>()

    for (const edge of edges) {
        edgedNodes.add(edge.source)
        edgedNodes.add(edge.target)

        if (nodesWithNoIncomingEdges.has(edge.target)) {
            nodesWithNoIncomingEdges.delete(edge.target)
        } else {
            nodesWithNoOutgoingEdges.add(edge.target)
        }

        if (nodesWithNoOutgoingEdges.has(edge.source)) {
            nodesWithNoOutgoingEdges.delete(edge.source)
        } else {
            nodesWithNoIncomingEdges.add(edge.source)
        }
    }

    const root = [...nodesWithNoIncomingEdges][0]
    const leaves = [...nodesWithNoOutgoingEdges]

    const tree = new Map<string, { children: string[]; data: FlowEdge }>()
    for (const node of edgedNodes) {
        tree.set(node, {
            children: [],
            data: edges.find(e => e.source === node) as FlowEdge
        })
    }

    for (const edge of edges) {
        tree.get(edge.source)?.children?.push(edge.target)
    }

    const nodesTopToBottom: NodesTopToBottomTree[] = []
    const queue = [
        {
            level: 0,
            levelIndex: 0,
            node: root,
            levelCount: 1,
            data: tree.get(root)?.data
        }
    ]
    while (queue.length > 0) {
        const node = queue.shift()
        const treeNode = node && tree.get(node.node)
        if (!node || !treeNode) {
            continue
        }
        nodesTopToBottom.push(node)
        const { children, data } = treeNode

        for (let index = 0; index < children.length; index++) {
            const child = children[index]
            queue.push({
                level: node.level + 1,
                levelIndex: index,
                levelCount: children.length,
                node: child,
                data
            })
        }
    }

    return nodesTopToBottom
}

export const getNodesWithPosition = (nodes: FlowNode[], edges: FlowEdge[]) => {
    const nodesTopToBottom = getNodesTopToBottomTree(edges)

    const nodesTopToBottomWithPosition: FlowNode[] = []

    for (const node of nodesTopToBottom) {
        const { node: nodeId, level, data: edgeData } = node

        // 如果是条件内部边，则取条件源节点的层级信息
        if (edgeData?.type === 'conditionInner') {
            const sourceNode = nodesTopToBottom.find(n => n.node === edgeData.source)
            if (sourceNode) {
                node.levelIndex = sourceNode.levelIndex
                node.levelCount = sourceNode.levelCount
            }
        }

        const { levelIndex } = node
        const { levelCount } = node

        const nodeData = nodes.find(n => n.id === nodeId)

        if (!nodeData) {
            continue
        }

        const levelTotalWidth = NODE_WIDTH * levelCount + NODE_HORIZONTAL_GAP * (levelCount - 1)

        const x = levelIndex * NODE_WIDTH + (levelIndex - 1) * NODE_HORIZONTAL_GAP - levelTotalWidth / 2 + NODE_WIDTH

        const y = NODE_VERTICAL_GAP * level

        nodesTopToBottomWithPosition.push({
            ...nodeData,
            position: { x, y }
        })
    }

    const visited = new Set<string>()

    const distinctByNodeNodesTopToBottomWithPosition = []

    for (let index = nodesTopToBottomWithPosition.length - 1; index >= 0; index--) {
        const node = nodesTopToBottomWithPosition[index]
        if (visited.has(node.id)) {
            continue
        }
        visited.add(node.id)
        distinctByNodeNodesTopToBottomWithPosition.unshift(node)
    }

    return distinctByNodeNodesTopToBottomWithPosition
}
