import type { AppUserDepartment } from "@lighthouse/shared"

import type { AppUserDepartmentPayload } from "@/services/types"

export function getNewDepartment(departmentList: AppUserDepartmentPayload[], parentId?: string): AppUserDepartment[] {
    return departmentList.map(department => {
        const { children = [], name, departmentId, managerUserId } = department
        return {
            departmentId,
            departmentName: name,
            departmentManager: managerUserId,
            departParentId: parentId,
            departmentChild: getNewDepartment(children, departmentId)
        }
    })
}
