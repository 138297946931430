import React from 'react'
import type { RouteObject } from 'react-router'

import { AnalyzeLayout, EventAnalyze, PageAnalyze, TrafficAnalyze } from '@/views/Byecode/Workbench/Analyze'
import { SettingsAnalyze } from '@/views/Byecode/Workbench/Analyze/settings'

export const analyzeRoute: RouteObject = {
    path: 'analyze',
    element: <AnalyzeLayout />,
    children: [
        {
            path: 'traffic',
            element: <TrafficAnalyze />
        },
        {
            path: 'page',
            element: <PageAnalyze />
        },
        {
            path: 'event',
            element: <EventAnalyze />
        },
        {
            path: 'settings',
            element: <SettingsAnalyze />
        }
    ]
}
