import { Button, IconFont } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import { isEmpty } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { updateUserAtom } from '@/atoms/auth/action'
import { useUser } from '@/hooks/useUser'
import * as srv from '@/services'

import { FormItemRender } from './FormItemRender'
import { formSchema, formStepCompanyMeta, formStepPersonMeta } from './formSchema'
import { StepIndicator } from './StepIndicator'
import * as SC from './styles'

interface BetaRequestFormProps {}

const buttonStyles: React.CSSProperties = {
    width: 200,
    height: 48,
    justifyContent: 'center'
}

export const BetaRequestForm: React.FC<BetaRequestFormProps> = () => {
    const [opened, setOpened] = useState(true)
    const { run: updateUser } = useAtomAction(updateUserAtom)

    const [currentStep, setCurrentStep] = useState(0)
    // 获取当前用户信息，用于保存手机号
    const userData = useUser()

    const form = useForm<Record<string, string | string[]>>()

    const useType = form.watch('useType')

    const currentStepForm = useMemo(() => {
        if (useType === 'COMPANY') {
            return formStepCompanyMeta[currentStep]
        }
        return formStepPersonMeta[currentStep]
    }, [currentStep, useType])

    const currentFormFields = useMemo(() => {
        return currentStepForm.fields?.reduce<string[]>((prev, cur) => {
            if (formSchema[cur]?.name) {
                prev.push(formSchema[cur].name)
            }
            return prev
        }, [])
    }, [currentStepForm.fields])
    const isButtonDisabled = form.watch(currentFormFields || []).some(field => !field || isEmpty(field))

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isSaved, setIsSaved] = useState(false)

    const stepCount = formStepCompanyMeta.length
    const indicatedStepCount = stepCount
    const currentIndicatorStep = currentStep

    const handlePrev = useCallback(() => {
        if (currentStep <= 0) {
            return
        }
        setCurrentStep(s => s - 1)
    }, [currentStep])

    const handleCollectInfo = useCallback(() => {
        const finalValues = form.getValues()
        updateUser({ ...userData, profileComplete: true })
        return srv.userInfoCollect(finalValues)
    }, [form, updateUser, userData])

    const handleSubmit = useCallback(async () => {
        if (isSubmitting) {
            return
        }
        // const finalValues = form.getValues()
        setIsSubmitting(true)
        const isCollect = await handleCollectInfo()
        if (isCollect) {
            updateUser({ ...userData, profileComplete: true })
        }
        setIsSubmitting(false)
        setOpened(false)
    }, [isSubmitting, handleCollectInfo, updateUser, userData])

    const handleSkip = useCallback(async () => {
        setOpened(false)
        await handleCollectInfo()
    }, [handleCollectInfo])

    const handleNext = useCallback(async () => {
        if (currentStep === formStepCompanyMeta.length - 1) {
            handleSubmit()
            return
        }

        // trigger validate
        const isValid = await form.trigger(currentStepForm.fields)

        if (isValid) {
            setCurrentStep(s => s + 1)
        }
    }, [currentStep, currentStepForm.fields, form, handleSubmit])

    const handleClose = useCallback(() => {
        setOpened(false)
    }, [])

    return (
        <Modal
            styles={{
                modal: {
                    width: 822,
                    overflow: 'hidden'
                }
            }}
            radius={20}
            centered
            padding={0}
            closeOnEscape={false}
            closeOnClickOutside={false}
            withCloseButton={false}
            opened={opened}
            onClose={handleClose}
        >
            <FormProvider {...form}>
                <SC.BetaRequestForm>
                    <SC.BetaRequestStepTitleWrapper>
                        <SC.Header>
                            {currentStep > 0 && (
                                <SC.Back onClick={handlePrev}>
                                    <IconFont type="ArrowLeftSmall" fill="var(--color-gray-400)" size={20} />
                                </SC.Back>
                            )}
                            <StepIndicator stepCount={indicatedStepCount} currentStep={currentIndicatorStep} />
                        </SC.Header>
                        <SC.BetaRequestStepTitleWrapper>
                            <SC.BetaRequestStepTitle>{currentStepForm.stepTitle}</SC.BetaRequestStepTitle>
                            <SC.BetaRequestStepDescription>{currentStepForm.stepDescription}</SC.BetaRequestStepDescription>
                        </SC.BetaRequestStepTitleWrapper>
                        {currentStepForm.fields?.map(fieldName => {
                            const field = formSchema[fieldName]

                            return <FormItemRender key={fieldName} name={field.name} field={field} />
                        })}
                        {currentStepForm.stepList && currentStepForm.stepList.length > 0 && (
                            <SC.BetaRequestStepUl>
                                {currentStepForm.stepList.map(item => (
                                    <SC.BetaRequestStepLi key={item}>
                                        <SC.BetaRequestStepLiDot />
                                        {item}
                                    </SC.BetaRequestStepLi>
                                ))}
                            </SC.BetaRequestStepUl>
                        )}
                        {currentStepForm.stepImage && <SC.BetaRequestStepImage background={currentStepForm.stepImage} />}
                    </SC.BetaRequestStepTitleWrapper>
                    <SC.BetaRequestFormFooter>
                        {currentStep > 1 && <SC.BetaRequestFormSkip onClick={handleSkip}>跳过</SC.BetaRequestFormSkip>}
                        <Button
                            style={buttonStyles}
                            disabled={isButtonDisabled}
                            type="primary"
                            size="lg"
                            onClick={handleNext}
                            loading={isSubmitting}
                        >
                            {currentStep < stepCount - 1 ? '下一步' : '开始搭建'}
                        </Button>
                    </SC.BetaRequestFormFooter>
                </SC.BetaRequestForm>
            </FormProvider>
        </Modal>
    )
}
