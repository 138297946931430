import { Flex, IconFont, Switch } from '@byecode/ui'
import type { ApplicationSettingSearchEngine } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { useState } from 'react'
import styled from 'styled-components'

import { TagIcon } from '@/components/TagIcon'
import type { PageSubmissionSearchEngineEnum, SearchEngineType } from '@/services/types'

import { searchEngineIconMap, searchEngineNameMap } from '../constants'
import { SearchEngineConfig } from '../SearchEngineConfig'

interface SearchEngineTagProps {
    type: PageSubmissionSearchEngineEnum
    value: ApplicationSettingSearchEngine
    style?: React.CSSProperties
    onChange: (v: ApplicationSettingSearchEngine) => void
}

const SCxItem = styled.div`
    width: 100%;
    height: 72px;
    padding: 16px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-gray-200);
`

const SCxItemLeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    * {
        line-height: 16px;
    }
`

const SCxItemRightFill = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const SCxIcon = styled(IconFont)<{ size?: number; color?: string; bgColor?: string; cursor?: string }>`
    font-size: ${({ size }) => size ?? 16}px;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
`

const defaultValue = {
    isOpened: false,
    verificationInfo: '',
    secret: '',
    usage: 0,
    quota: 0
}
export const SearchEngineTag: React.FunctionComponent<SearchEngineTagProps> = ({ type, value = defaultValue, style, onChange }) => {
    const [opened, { close, open }] = useDisclosure(false)

    const isPerfectInfo = value?.secret && value?.verificationInfo

    return (
        <SCxItem style={style}>
            <SCxItemLeftFill>
                <SCxIcon type={searchEngineIconMap.get(type) ?? ''} size={32} color="var(--color-theme-7)" />
                <Flex justifyContent="space-between" direction="column" style={{ height: '100%' }}>
                    <Text size={14}>{searchEngineNameMap.get(type)}搜索</Text>
                    {isPerfectInfo ? (
                        <Text size={12} color="var(--color-gray-400)">
                            配额： {value.usage} / {value.quota}
                        </Text>
                    ) : (
                        <Text size={12} color="var(--color-gray-400)">
                            配置未完善
                        </Text>
                    )}
                </Flex>
            </SCxItemLeftFill>
            <SCxItemRightFill>
                <Text color="var(--color-gray-400)">{value.isOpened ? '已开启' : '未开启'}</Text>
                <Switch
                    checked={value.isOpened}
                    disabled={!isPerfectInfo}
                    onChange={e => onChange({ ...value, isOpened: e.target.checked })}
                />
                <TagIcon iconSize={16} icon="edit" size={24} iconColor="var(--color-gray-400)" onClick={open} />
            </SCxItemRightFill>
            <SearchEngineConfig
                opened={opened}
                defaultData={value}
                type={type}
                onChange={v => {
                    onChange({ ...value, ...v })
                    close()
                }}
                onClose={close}
            />
        </SCxItem>
    )
}
