import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`
export const Text = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    font-weight: 500;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: ${({ size }) => size ?? 14 + 2}px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`
