import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal, Modal } from '@byecode/ui'
import React from 'react'

import IClickAccount from '../IClickAccount'
import type { IClickAccountFormState, IClickAccountMode } from '../type'

interface IClickAccountModalProps extends BaseModalProps {
    mode: IClickAccountMode
    id?: string
}

export const IClickAccountModal: React.FunctionComponent<IClickAccountModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <IClickAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
