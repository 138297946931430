import { Button, IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { useSpaceContext } from '@/contexts/SpaceContext'

const SCxContainer = styled.div`
    padding: 8px;
    width: 100%;
    height: 48px;
    background: #dff9ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    &:hover {
        background: #c5eef7;
    }
`

const SCxImageWrapper = styled.div`
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

const SCxContent = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    overflow: hidden;
    flex-shrink: 0;
`

const SCxImage = styled.img`
    width: 20px;
    height: 20px;
`

const SCxText = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const SCxButton = styled(Button)`
    white-space: nowrap;
    color: var(--color-black);
`

const SCxIcon = styled(IconFont)`
    transform: rotate(45deg);
`

const SCxClose = styled(IconFont)`
    cursor: pointer;
`

const promoImage = getAssetUrl('space', 'promo.svg')

export const PromotionNotice: React.FC = () => {
    const [close, setClose] = useState(false)
    const { setPromotionOpen } = useSpaceContext()

    const handleJoin = useCallback(() => {
        setPromotionOpen?.(true)
    }, [setPromotionOpen])
    // const { data: latestActivity } = useSwr('getLatestActivity', () => srv.getLatestActivity(), {
    //     revalidateOnFocus: false,
    // })

    // const handleLink = useCallback((url: string) => {
    //     window.open(url)
    // }, [])

    // if(close || !latestActivity){
    //     return null
    // }
    if (close) {
        return null
    }

    return (
        <SCxContainer>
            <SCxContent>
                <SCxImageWrapper>
                    <SCxImage src={promoImage} />
                </SCxImageWrapper>
                <SCxText>邀请朋友注册，获取升级空间折扣，低至7.9折！</SCxText>
                <SCxButton type="text" suffix={<SCxIcon type="ArrowUp" fill="var(--color-black)" size={16} />} onClick={handleJoin}>
                    立即了解
                </SCxButton>
            </SCxContent>
            <SCxClose type="Close" size={16} fill="var(--color-gray-400)" onClick={() => setClose(true)} />
        </SCxContainer>
    )
}
