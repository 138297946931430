import { IconFont, Modal } from '@byecode/ui'
import type { AggregateResultField, AggregatorNode } from '@lighthouse/shared'
import {
    type AggregatorDataInputNodeData,
    type AggregatorGroupStatisticsConfig,
    aggregateNodeFieldList,
    AggregatorNodeType
} from '@lighthouse/shared'
import isEqual from 'fast-deep-equal'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import type { NodeProps } from 'reactflow'
import { Position, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useDataAggregator } from '@/hooks/useDataAggregator'

import { groupStatisticsResultGenerator, otherNodeResultGenerator, peekNextNodes, peekPrevNodes, pickInfoFromNodeProps } from '../../utils'
import { validateAggregatorNodeConfig } from '../../validators'
import { CloseButton } from '../CloseButton'
import { defaultNodeTypeColor, NodeTypeColorMap } from '../constants'
import { Handler } from '../Handler'

export type GroupStatisticsNodeProps = NodeProps<AggregatorDataInputNodeData<AggregatorGroupStatisticsConfig>>

const SCxNodeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 48px;
    border: 1px solid var(--color-gray-200);
    border-radius: 100px;
    background-color: var(--color-white);

    &[data-state='inactive'] {
        border-color: transparent;
        box-shadow: 0 0 0 1px var(--color-red-500);

        &[data-selected='true'] {
            box-shadow: 0 0 0 2px var(--color-red-500), 0px 0px 0px 4px #ff000033;
        }
    }

    &[data-selected='true'] {
        border-color: transparent;
        box-shadow: 0 0 0 2px var(--color-purple-500), 0px 0px 0px 4px #5551ff33;
    }
`

export const GroupStatisticsNode: React.FC<GroupStatisticsNodeProps> = props => {
    const { id, selected, data } = props
    const { config } = data
    const methods = useDataAggregator()
    const { getNodes, getEdges } = useReactFlow()
    const prevNodes = useMemo(() => {
        const nodes = getNodes() as AggregatorNode[]
        const edges = getEdges()
        const node = find(item => item.id === id, nodes)
        if (!node) {
            return []
        }
        return peekPrevNodes(node, nodes, edges).nodes
    }, [getEdges, getNodes, id])
    const [prevFieldList, setPrevFieldList] = useState<AggregateResultField[]>(otherNodeResultGenerator(prevNodes))
    const parentNodeIds = useMemo(() => prevNodes.map(item => item.id), [prevNodes])

    useUpdateEffect(() => {
        if (prevFieldList.length === 0) {
            const list = otherNodeResultGenerator(prevNodes)
            setPrevFieldList(list)
        }
    }, [prevNodes])

    useEffect(() => {
        const clear = aggregateNodeFieldList.subscribe(parentNodeIds, (nodeId: string, fieldList: AggregateResultField[]) => {
            // 处理逻辑
            if (!isEqual(prevFieldList, fieldList)) {
                const newFieldList: AggregateResultField[] = groupStatisticsResultGenerator(config, fieldList)
                setPrevFieldList(fieldList)
                aggregateNodeFieldList.emit(id, newFieldList)
            }
        })
        return () => clear()
    }, [config, id, parentNodeIds, prevFieldList])

    const nodeConfigState = useMemo(() => {
        const validatePass = validateAggregatorNodeConfig({ node: pickInfoFromNodeProps(props), prevFieldList })
        return validatePass ? 'active' : 'inactive'
    }, [prevFieldList, props])

    const iconColor = useMemo(() => {
        if (prevFieldList.length === 0 || nodeConfigState === 'inactive') {
            return defaultNodeTypeColor
        }
        return NodeTypeColorMap[AggregatorNodeType.JOIN]
    }, [nodeConfigState, prevFieldList.length])

    const handleRemove = useCallback(async () => {
        const nodes = getNodes() as AggregatorNode[]
        const edges = getEdges()
        const node = find(item => item.id === id, nodes)
        if (!node) {
            methods?.onRemoveNode(id)
            return
        }
        const { nodes: nextNodes } = peekNextNodes(node, nodes, edges)
        if (nextNodes.length === 0 || nextNodes[0].type === AggregatorNodeType.ADD_PLACEHOLDER) {
            methods?.onRemoveNode(id)
            return
        }
        const isConfirm = await Modal.confirm({
            title: '确认删除',
            content: '删除后，该节点之后的其他节点也将一并删除，且不可恢复，请谨慎操作！',
            okStatus: 'error',
            okText: <div>确定</div>
        })
        if (isConfirm) {
            methods?.onRemoveNode(id)
        }
    }, [getEdges, getNodes, id, methods])

    return (
        <SCxNodeWrapper data-selected={selected} data-state={nodeConfigState}>
            <IconFont type="Charts" size={24} color={iconColor} />
            <Handler type="target" position={Position.Left} id={id} />
            <Handler type="source" position={Position.Right} id={id} />
            <CloseButton style={{ display: selected ? 'flex' : 'none' }} onClick={handleRemove} />
        </SCxNodeWrapper>
    )
}
