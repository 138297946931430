import { FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    getFieldBlockWithDsId,
    getIsFindUseInFilter,
    transformNode2FlowLayoutNode,
    useAtomAction,
    useAtomData,
    useFindUseObjectContext,
    VariableSourceType,
    ViewFieldFilterConfigure
} from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
    blockHighlightAtom,
    lastPageOfStackAtom,
    pageAtomFamily,
    pageBlocksAtom,
    pageNodesAtom,
    pageStackAtomFamily
} from '@/atoms/page/state'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import type { ListItemMenuProps } from '../ListItemMenu'
import { ListItemMenu } from '../ListItemMenu'

interface Props extends Partial<ListItemMenuProps> {
    name?: string
    currentDsId?: string
}

export const ConditionFilter: FC<Props> = ({ name = 'visibilityFilter', currentDsId, ...rest }) => {
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [stackId, pageId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || '', s?.rootPageId || ''], [])
    )
    const disabledWithVersion = useIsDisabledWithVersion()
    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()
    const { customViewDataSource } = useVariableCustomDataSource()
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const handleMouseEnter = useCallback(
        (id: string) => {
            setBlockHighlight([id])
            document.querySelector(`div[data-node-id="${id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        },
        [setBlockHighlight]
    )

    const handleMouseLeave = useCallback(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                const filter = value
                const filtersLength = filter?.expression?.conditions?.length || 0
                const isHighLight = getIsFindUseInFilter({
                    findUseObject,
                    data: filter,
                    currentDsId
                })
                return (
                    <ListItemMenu
                        width="auto"
                        compact
                        label="条件"
                        highlighting={isHighLight}
                        positionDependencies={[filter]}
                        value={filtersLength ? `已设 ${String(filtersLength)} 个条件` : '未设置'}
                        popupContent={
                            <ViewFieldFilterConfigure
                                dataSourceList={dataSourceList}
                                fieldBlocksWithDsId={fieldBlocksWithDsId}
                                sources={sources}
                                paramsMode={FilterMode.CUSTOM}
                                fieldMode={FilterMode.CUSTOM}
                                enableCurrentUser
                                viewDsId={customViewDataSource?.id}
                                viewType="custom"
                                columns={[]}
                                filter={filter}
                                disabled={disabledWithVersion}
                                onFilter={onChange}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                        }
                        {...rest}
                    />
                )
            }}
        />
    )
}
