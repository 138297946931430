import { IconFont, pointer } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div<{ isPointer?: boolean }>`
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    gap: 4px;

    ${({ isPointer }) => isPointer && pointer}
`

const SCxIcon = styled(IconFont)``

const SCxText = styled.div`
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

interface EnvTagProps {
    iconColor?: string
    icon: string
    name?: string
    isPointer?: boolean
    extra?: React.ReactNode
    onClick?: () => void
}

export const EnvTag = React.forwardRef<HTMLDivElement, EnvTagProps>(({ icon, iconColor, name, isPointer, extra, onClick }, ref) => {
    return (
        <SCxContainer ref={ref} onClick={onClick} isPointer={isPointer}>
            <SCxIcon fill={iconColor} type={icon} />
            <SCxText>{name}</SCxText>
            {extra}
        </SCxContainer>
    )
})
