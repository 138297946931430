import { Button } from '@byecode/ui/components/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Loading } from '@byecode/ui/components/Loading'
import type { WechatPayOrderInfoProtocol } from '@lighthouse/core'
import { WechatPayStatus } from '@lighthouse/core'
import { getBrandInfo } from '@lighthouse/shared'
import { Anchor, Flex, Text } from '@mantine/core'
import QRCode from 'qrcode.react'
import { multiply, subtract } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import * as srv from '@/services'

export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`

export const SCxTitle = styled.div`
    padding: 0 56px;
    color: var(--color-black);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
`

export const SCxDescribe = styled.div`
    display: flex;
    padding: 0 56px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--color-gray-500);
    padding-bottom: 16px;
`

const SCxLogo = styled.div``

const SCxOrderInfo = styled.div`
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    background: var(--color-gray-100);
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

interface WeChatPayProps {
    getPayInfo?: () => Promise<WechatPayOrderInfoProtocol>
    onPaySuccess?: () => void
    onPayFailed?: (status: WechatPayStatus) => void
}

export const WeChatPay: React.FunctionComponent<WeChatPayProps> = ({ getPayInfo, onPaySuccess, onPayFailed }) => {
    const [isCheck, setIsCheck] = useState(false)

    // 检查过期时间
    const timeRef = useRef(Date.now())
    const [isExpired, setIsExpired] = useState(false)

    const { value, loading, retry } = useAsyncRetry(async () => {
        setIsCheck(false)
        if (!getPayInfo) {
            return
        }
        const res = await getPayInfo()
        setIsCheck(true)
        timeRef.current = Date.now()
        return res
    }, [getPayInfo])

    const isPromo = useMemo(() => value?.discount && value?.discount < 1, [value?.discount])

    // 检查非jsapi微信支付，是否支付成功
    const checkWechatPayStatus = useCallback(async () => {
        if (!value?.outTradeNo) {
            return null
        }

        const code = await srv.getWeChatPayStatus(value.outTradeNo)
        if (code === WechatPayStatus.NOTPAY) {
            return
        }

        if (code === WechatPayStatus.SUCCESS) {
            onPaySuccess?.()
            return
        }

        onPayFailed?.(code)
    }, [onPayFailed, onPaySuccess, value?.outTradeNo])

    // 轮询检查非jsapi微信支付
    useEffect(() => {
        if (!isCheck) {
            return
        }

        const timer = setInterval(() => {
            checkWechatPayStatus()
            if (Date.now() - timeRef.current >= 5 * 60 * 1000) {
                setIsExpired(true)
                clearInterval(timer)
            }
        }, 3000)

        return () => {
            clearInterval(timer)
        }
    }, [checkWechatPayStatus, isCheck])

    return (
        <SCxContainer>
            <SCxLogo>
                <IconFont type="WechatPay" size={60} color="var(--color-green-500)" />
                <Text color="var(--color-green-500)" weight={600}>
                    微信支付
                </Text>
            </SCxLogo>
            <Text>请使用微信扫一扫进行支付</Text>

            {loading ? (
                <div style={{ height: 156 }}>
                    <Loading />
                </div>
            ) : (
                <div style={{ position: 'relative' }}>
                    {isExpired && (
                        <div
                            style={{
                                position: 'absolute',
                                inset: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(0,0,0,0.5)'
                            }}
                        >
                            <Button
                                type="text"
                                style={{ color: '#fff' }}
                                onClick={() => {
                                    setIsExpired(false)
                                    timeRef.current = Date.now()
                                    retry()
                                }}
                            >
                                已过期，点击刷新
                            </Button>
                        </div>
                    )}
                    <QRCode size={156} level="H" value={value?.nativeResponse.codeUrl ?? ''} renderAs="svg" />
                </div>
            )}

            <Flex direction="column" align="center">
                <Text>使用微信扫码支付</Text>
                <Text>
                    支付即视为你同意
                    <Anchor
                        // size="sm"
                        href={getBrandInfo().account.privacyAgreement}
                        color="var(--color-blue-500)"
                        weight={600}
                        target="_blank"
                    >
                        《ByeCode会员服务协议》
                    </Anchor>
                </Text>
            </Flex>

            <SCxOrderInfo>
                <Flex justify="space-between">
                    <Text size={16} color="var(--color-gray-500)">
                        商品信息
                    </Text>
                    <Text>{value?.prodDesc}</Text>
                </Flex>
                <Flex justify="space-between">
                    <Text size={16} color="var(--color-gray-500)">
                        实付金额
                    </Text>
                    <Text>￥{value?.realPrice}</Text>
                </Flex>
                {isPromo && value?.discount && (
                    <>
                        <Flex justify="space-between">
                            <Text size={16} color="var(--color-gray-500)">
                                原价
                            </Text>
                            <Text color="var(--color-gray-500)">￥{value?.originalPrice}</Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Text size={16} color="var(--color-gray-400)">
                                优惠
                            </Text>
                            <Text color="var(--color-main)">
                                {multiply(value.discount, 100).toFixed(0)}折 -￥{subtract(value?.originalPrice ?? 0, value?.realPrice ?? 0).toFixed(2)}
                            </Text>
                        </Flex>
                    </>
                )}
            </SCxOrderInfo>
        </SCxContainer>
    )
}
