import type { ActionFlowNode, FieldPermission, FlowNode, InitiateApproveTriggerConfig } from '@lighthouse/shared'
import { SegmentedControl } from '@mantine/core'
import React, { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import * as SC from '../../styles'
import { ApproveSetting } from './ApproveSetting'
import { FieldSetting } from './FieldSetting'

export interface ApproveActionConfigureProps {
    allParentNodes: FlowNode[]
}

export const ApproveActionConfigure: React.FC<ApproveActionConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { control, setValue: setFormValue } = useFormContext()
    const [value, setValue] = React.useState('approve')
    const approveTriggerNode = allParentNodes.find(node => node.type === 'TRIGGER' && node.data.nodeType === 'INITIATE_APPROVE_TRIGGER') as
        | ActionFlowNode<InitiateApproveTriggerConfig>
        | undefined
    const [dataSourceId, pageId, formModuleId] = useMemo(() => {
        if (!approveTriggerNode) {
            return ['', '', '']
        }
        const nodeConfig = approveTriggerNode.data.config
        return [nodeConfig?.dataSourceId || '', nodeConfig?.pageId || '', nodeConfig?.formModuleId || '']
    }, [approveTriggerNode])

    const dataSource = useDataSource(appId, envId, dataSourceId)

    const fieldSchema = dataSource?.schema

    const fieldPermissionList: FieldPermission[] = useMemo(() => {
        if (!fieldSchema) {
            return []
        }
        return Object.values(fieldSchema).map(item => {
            return {
                fieldId: item.id,
                mode: 'READ'
            }
        })
    }, [fieldSchema])

    const fieldPermissions = useWatch({ control, name: 'config.fieldPermissions' })

    useEffect(() => {
        if (!fieldPermissions && fieldPermissionList.length > 0) {
            setFormValue('config.fieldPermissions', fieldPermissionList)
        }
    }, [fieldPermissionList, fieldPermissions, setFormValue, value])

    const content = useMemo(() => {
        if (!dataSource || !fieldSchema) {
            return null
        }
        if (value === 'approve') {
            return <ApproveSetting />
        }

        return <FieldSetting schema={fieldSchema} />
    }, [dataSource, fieldSchema, value])

    return (
        <SC.Container>
            <SC.Wrapper>
                <SegmentedControl
                    value={value}
                    fullWidth
                    onChange={setValue}
                    data={[
                        { label: '审批设置', value: 'approve' },
                        { label: '字段权限', value: 'field' }
                    ]}
                />
            </SC.Wrapper>
            {content}
        </SC.Container>
    )
}
