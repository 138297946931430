import type { ConditionConfig } from '@lighthouse/shared'

import type { ConditionValidator, NodeValidator } from '../types'

/**
 * condition validate
 */
const conditionValidator: NodeValidator<ConditionValidator<ConditionConfig>, 'CONDITION'> = {
    nodeType: 'CONDITION',
    validators: [
        node => {
            return !!node.data.config?.filter && !!node.data.config?.filter.expression?.conditions?.length
        }
    ]
}

export const conditionValidators = [conditionValidator]
