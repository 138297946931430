import { getAssetUrl } from '@lighthouse/assets'
import type { CSSProperties } from 'react'

import type { GuideStep } from './types'
import { GuideStepName } from './types'

const guideStepImg21 = getAssetUrl('guide', 'guide_step2_1.jpg')
const guideStepImg22 = getAssetUrl('guide', 'guide_step2_2.jpg')
const guideStepImg3 = getAssetUrl('guide', 'guide_step3.jpg')
const guideStepImg41 = getAssetUrl('guide', 'guide_step4_1.jpg')
const guideStepImg42 = getAssetUrl('guide', 'guide_step4_2.jpg')
const guideStepImg43 = getAssetUrl('guide', 'guide_step4_3.jpg')
const guideStepImg5 = getAssetUrl('guide', 'guide_step5.jpg')
const guideStepImg6 = getAssetUrl('guide', 'guide_step6.jpg')

export const titleStyle: CSSProperties = { fontWeight: 550, padding: '0 4px', lineHeight: '24px' }

export const guideStepList: Record<GuideStepName, GuideStep> = {
    spaceList: {
        id: 'guideStep1',
        images: [guideStepImg21, guideStepImg22],
        title: '空间列表',
        placement: 'right',
        describe: '在这里你可以创建一个新的空间并切换你所在的空间，或者升级你的版本'
    },
    spacePerson: {
        id: 'guideStep2',
        images: [guideStepImg3],
        title: '成员管理',
        placement: 'right',
        describe: '在这里你可以邀请你的伙伴一起加入到你的空间，并设置他们的角色'
    },
    spaceQuota: {
        id: 'guideStep3',
        images: [guideStepImg41, guideStepImg42, guideStepImg43],
        title: '资源配额',
        placement: 'right',
        describe: '在这里你可以查看你的空间资源已用数量以及空间版本等级，如果现有资源不够用，可以考虑升级版本或购买扩容包'
    },
    spaceSetting: {
        id: 'guideStep4',
        images: [guideStepImg5],
        title: '空间设置',
        placement: 'right',
        describe: '你可以在这里更改你的空间名称与头像'
    },
    accountSetting: {
        id: 'guideStep5',
        images: [guideStepImg6],
        title: '账户设置',
        placement: 'auto',
        describe: '在这里你可以完善你的个人信息，并更改或设置你的账号密码'
    }
    // createApp: {
    //     id: 'guideStep6',
    //     images: [guideStepImg7],
    //     title: '创建应用',
    //     placement: 'auto',
    //     describe: '现在开始搭建你的第一个应用吧！👏'
    // }
}

export const guideSteps = new Map<GuideStepName, string>([
    [GuideStepName.spaceList, guideStepList[GuideStepName.spaceList].id],
    [GuideStepName.spacePerson, guideStepList[GuideStepName.spacePerson].id],
    [GuideStepName.spaceQuota, guideStepList[GuideStepName.spaceQuota].id],
    [GuideStepName.spaceSetting, guideStepList[GuideStepName.spaceSetting].id],
    [GuideStepName.accountSetting, guideStepList[GuideStepName.accountSetting].id]
    // [GuideStepName.createApp, guideStepList[GuideStepName.createApp].id]
])
