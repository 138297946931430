import { IconFont, Menu } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { APPLICATION_ENV_PROD, ENV_ICON, getEnvColor } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { EnvTag } from '../EnvTag'

const SCxIcon = styled(IconFont)``

interface EnvTagSelectProps {
    id: string
    envList: Environment[]
    onlyCurrentEnv?: boolean
    onChange?: (id: string) => void
}

export const EnvTagSelect: React.FC<EnvTagSelectProps> = ({ id, envList, onlyCurrentEnv, onChange }) => {
    const [open, setOpen] = useState(false)
    const env = useMemo(() => find(item => item.envId === id, envList), [envList, id])
    const list = useMemo(() => (onlyCurrentEnv && env ? [env] : envList), [envList, env, onlyCurrentEnv])

    const handleSelect = useCallback(
        (id: string) => {
            if (id === env?.envId) {
                setOpen(false)
                return
            }
            onChange?.(id)
            setOpen(false)
        },
        [env?.envId, onChange]
    )

    return (
        <Menu width={204} position="bottom-start" withinPortal opened={open} onChange={setOpen}>
            <Menu.Target>
                <EnvTag
                    iconColor={getEnvColor(env).color}
                    icon={ENV_ICON}
                    name={env?.envName}
                    isPointer
                    extra={<SCxIcon fill="var(--color-gray-400)" type="ArrowDownSmallGray" />}
                />
            </Menu.Target>
            <Menu.Dropdown>
                {list.map(item => {
                    return (
                        <Menu.Item
                            key={item.envId}
                            icon={<IconFont fill={getEnvColor(item).color} type={ENV_ICON} />}
                            onClick={() => handleSelect?.(item.envId)}
                        >
                            {item.envName}
                        </Menu.Item>
                    )
                })}
            </Menu.Dropdown>
        </Menu>
    )
}
