import { Anchor, Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { AliyunAccountFormState, AliyunAccountMode } from './type'

interface AliyunAccountProps {
    id?: string
    mode: AliyunAccountMode
    onClose?: () => void
}

const initData: AliyunAccountFormState = {
    id: '',
    name: '',
    secret: '',
    accessKey: ''
}
const AliyunAccount: React.FunctionComponent<AliyunAccountProps> = ({ id, mode, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()
    const [loading, setLoading] = useState(false)
    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform
            ? { ...otherPlatform?.config, name: otherPlatform.name, id, secret: PLACEHOLDER_PASSWORD, accessKey: PLACEHOLDER_PASSWORD }
            : initData
    }, [id, otherPlatforms])

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: id ? account : initData
    })

    const handleAdd = useCallback(
        async (params: AliyunAccountFormState) => {
            setLoading(true)
            const { name, secret, accessKey } = params
            const res = await srv.addIntegration({
                type: 'ALIYUN_SMS',
                name,
                params: {
                    secret,
                    accessKey
                }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」。`,
            okStatus: 'error'
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, otherPlatformUpdate, onClose])

    const handleUpdate = useCallback(
        async (params: AliyunAccountFormState) => {
            const { name, secret, accessKey, id } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'ALIYUN_SMS',
                name,
                id,
                params: {
                    secret: secret === PLACEHOLDER_PASSWORD ? undefined : secret,
                    accessKey: accessKey === PLACEHOLDER_PASSWORD ? undefined : accessKey
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} color="var(--color-orange-500)" type="aliyun" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}阿里云短信账号</SC.Title>
                <SC.Item>
                    <SC.Text>
                        账号名称
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>

                    <SC.Description>用于在账户列表中展现名称，可自定义</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        Access Key
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="accessKey" errors={errors}>
                        <Controller
                            name="accessKey"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        请登录
                        <Anchor target="_blank" style={{ color: 'var(--color-main)' }} to="https://homenew.console.aliyun.com/home/dashboard">
                            阿里云控制台用户页面
                        </Anchor>
                        -顶部导航企业-选择人员权限管理-选择已有用户或者创建一个新用户，并选择添加权限-添加短信管理全部权限到此用户-在此用户下创建一个AccessKey-获取AccessKey
                        ID复制到Access Key内容中
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        Access Secret
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="secret" errors={errors}>
                        <Controller
                            name="secret"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        请登录
                        <Anchor target="_blank" style={{ color: 'var(--color-main)' }} to="https://homenew.console.aliyun.com/home/dashboard">
                            阿里云控制台用户页面
                        </Anchor>
                        -顶部导航企业-选择人员权限管理-选择已有用户或者创建一个新用户，并选择添加权限-添加短信管理全部权限到此用户-在此用户下创建一个AccessKey-获取secret复制到Access
                        Secret内容中
                    </SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default AliyunAccount
