import type { UserProtocol } from '../protocols'

/**
 * 用户状态 0未激活 1正常 2待审核
 * @return {*}
 */
export enum AppUserStatus {
    unActivated,
    activated,
    checkPending
}

// 应用端用户
export interface AppUser extends UserProtocol {
    // 是否注销
    isDepart?: boolean
    uniqueUserId: string
    /** 应用端需要完善信息 */
    checkPerfect?: boolean
    /** 是否审核中 */
    isReviewed?: boolean
    /** 用户状态 0未激活 1正常 2待审核 */
    state?: AppUserStatus
    createdTime?: number
    groupId?: string
    groupName?: string
    lastLoginTime?: number
    departmentIds?: string[]
    // 是否需要绑定手机号
    bindMobile?: boolean
    // wechatId?: string
    wechatOpenId?: string
    wechatPcOpenid?: string
    wechatUnionId?: string
}

// 管理端用户
export interface SpaceUser extends UserProtocol {
    contactNumber?: string
    description?: string
    birthday?: string
    roleId: string
    sex?: number
    addSpaceTime: number
    /** 是否是新人用户 （用于管理端空间引导） */
    isNewUser?: boolean
    /** 管理端用户新手引导是否完成 */
    appGuideCompleted?: boolean
    /** 管理端用户信息是否收集完成 */
    profileComplete?: boolean
    /** 是否绑定微信 */
    isBindWechat?: boolean
    /** 优惠活动邀请码 */
    invitationCode: string
}
