import { useUploadyContext } from '@rpldy/shared-ui'
import type { UploadDropZoneProps } from '@rpldy/upload-drop-zone'
import UploadDropZone from '@rpldy/upload-drop-zone'
import React, { useCallback } from 'react'

interface UploadClickDropZoneProps extends Omit<UploadDropZoneProps, 'children'> {
    disabled?: boolean
    disabledOpacity?: number
    children?: React.ReactNode
}

export const UploadClickDropZone: React.FC<UploadClickDropZoneProps> = ({ disabled, children, disabledOpacity = 0.6, ...rest }) => {
    const uploady = useUploadyContext()

    const handleUpload = useCallback(() => {
        uploady.showFileUpload()
    }, [uploady])

    return (
        <UploadDropZone
            onDragOverClassName="drag-over"
            extraProps={{
                style: { cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? disabledOpacity : 1 },
                onClick: disabled ? undefined : handleUpload
            }}
            {...rest}
        >
            {children as JSX.Element}
        </UploadDropZone>
    )
}
