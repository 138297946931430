import type { SpaceUser } from '@lighthouse/core'

export const INITIAL_AUTH_DATA = {
    userId: '',
    uniqueUserId: '',
    accessToken: '',
    refreshToken: ''
}

export const INITIAL_USER_DATA: SpaceUser = {
    userId: '',
    username: '',
    mobile: '',
    sex: 0,
    email: '',
    avatar: '',
    birthday: '',
    contactNumber: '',
    description: '',
    roleId: '',
    addSpaceTime: 0,
    invitationCode: ''
}
