import type { DragEndEvent, DragMoveEvent, DragStartEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { PageAbstract } from '@lighthouse/core'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import type { CreatePagePayload, MovePageAtomPayload } from '@/atoms/page/types'
import { RowGuideLine } from '@/components/StyledGuideLine'

import PageTreeListItem from '../Item'

interface DragListProps {
    list: PageAbstract[]
    level: number
    focusPage?: string
    activeId?: string
    style?: React.CSSProperties
    onUpdatePage?: (param: Pick<PageAbstract, 'name' | 'id' | 'open' | 'isHome'>) => Promise<boolean>
    onSetHomePage?: (param: Pick<PageAbstract, 'id' | 'isHome'>) => Promise<boolean>
    onRemovePage?: (id: string) => void
    onToLink?: (page: PageAbstract) => void
    onChangeEdit: (id: string) => void
    onCopyPage: (id: string, lang: string) => Promise<string>
    onMovePage: (params: MovePageAtomPayload) => void
    onCustomRoute?: (id: string) => void
}

const SCxContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
`

export const DragList: React.FunctionComponent<DragListProps> = ({ list, level, focusPage, onMovePage, ...rest }) => {
    const [guideRect, setGuideRect] = useState({ top: 12, width: 0, parentLeft: 0, initialScrollLeft: 0 })
    const ref = useRef<HTMLDivElement>(null)
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const handleDragStart = useCallback((event: DragStartEvent) => {
        // const { active } = event
    }, [])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (!over) {
                return
            }
            if (active.id !== over.id) {
                onMovePage({ id: active.id.toString(), oldId: active.id.toString(), newId: over.id.toString() })
            }
        },
        [onMovePage]
    )

    const handDragMove = useCallback(
        (ev: DragMoveEvent) => {
            const { over, active, collisions } = ev
            const tableTop = ref.current?.getBoundingClientRect().top ?? 0
            const activeCollision = find(item => item.id === active.id, collisions ?? [])
            const activeHeight: number = activeCollision?.data?.droppableContainer.rect.current.height
            const activeIndex = findIndex(item => item.id === active.id, list)
            const overIndex = findIndex(item => item.id === (over?.id ?? ''), list)
            setGuideRect(rect => {
                const baseTop = over?.rect.top ?? 0
                const baseOffset = activeIndex < overIndex ? (over?.rect.height ?? 0) - activeHeight : 0
                // 12 为pageTreeListWrapper的内上边距
                const top = baseTop - tableTop + baseOffset + 12
                return { ...rect, top }
            })
        },
        [list]
    )

    return (
        <SCxContainer ref={ref}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragMove={handDragMove}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <SortableContext items={list.map(d => d.id)} strategy={verticalListSortingStrategy}>
                    {list.map(page => (
                        <PageTreeListItem
                            key={page.id}
                            data={page}
                            level={level}
                            isEdit={focusPage === page.id}
                            onMovePage={onMovePage}
                            {...rest}
                        />
                    ))}
                </SortableContext>
                <RowGuideLine rect={guideRect} />
            </DndContext>
        </SCxContainer>
    )
}
