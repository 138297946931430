import { Button, IconFont, Popover } from '@byecode/ui'
import { type AggregateResultField } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { AggregatorFieldConfigure } from './AggregatorFieldConfigure'

const SCxButtonContent = styled(Button)``

const SCxIcon = styled(IconFont)`
    margin-right: 6px;
`

const SCxContainer = styled.div`
    margin: 4px 0;
    height: 32px;
`

interface AddFieldButtonProps {
    dsId: string
    nodeId: string
    fieldList: AggregateResultField[]
    onAdd?: (field: AggregateResultField) => void
}

export const AddFieldButton: React.FC<AddFieldButtonProps> = ({ dsId, nodeId, fieldList, onAdd }) => {
    const [open, setOpen] = useState(false)

    const handleCancel = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleChange = useCallback(
        (value: AggregateResultField) => {
            onAdd?.(value)
            handleCancel()
        },
        [handleCancel, onAdd]
    )

    const getInitField: () => AggregateResultField = useCallback(
        () => ({
            id: `f${nanoid(10)}`,
            dsId,
            name: '计算',
            type: 'aggregation',
            innerType: 'NULL',
            visible: true,
            aggregation: {
                isFormula: true,
                formula: {
                    expression: ''
                }
            }
        }),
        []
    )

    return (
        <SCxContainer>
            <Popover opened={open} width={250} position="top-start" onChange={setOpen}>
                <Popover.Target>
                    <SCxButtonContent
                        radius={100}
                        onClick={handleOpen}
                        icon={<SCxIcon type="Add" fill="var(--color-gray-500)" size={16} />}
                    >
                        创建计算字段
                    </SCxButtonContent>
                </Popover.Target>
                <Popover.Dropdown>
                    <AggregatorFieldConfigure
                        dsId={dsId}
                        nodeId={nodeId}
                        field={getInitField()}
                        fieldList={fieldList}
                        onChange={handleChange}
                        onCancel={handleCancel}
                    />
                </Popover.Dropdown>
            </Popover>
        </SCxContainer>
    )
}
