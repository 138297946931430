import type { TabConfig, TabsBlockConfig, TabStyleConfig } from '@lighthouse/core'
import { ALIGNMENT, BACKGROUND_TYPE, /* SIZE_FIT, */ TABS_TYPE } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

export const LINE_COLOR_DEFAULT: Omit<TabStyleConfig, 'id'> = {
    color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    background: { type: BACKGROUND_TYPE.color, color: '' },
    borderColor: { type: BACKGROUND_TYPE.color, color: '' },
    iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    hover: {
        color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
        background: { type: BACKGROUND_TYPE.color, color: '#F9FAFB' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' }
    },
    active: {
        color: { type: BACKGROUND_TYPE.color, color: '#101828' },
        background: { type: BACKGROUND_TYPE.color, color: '' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#101828' }
    }
}

export const CARD_COLOR_DEFAULT: Omit<TabStyleConfig, 'id'> = {
    color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    background: { type: BACKGROUND_TYPE.color, color: '#F9FAFB' },
    borderColor: { type: BACKGROUND_TYPE.color, color: '#e4e7ec' },
    iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    hover: {
        color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
        background: { type: BACKGROUND_TYPE.color, color: '#ECEFF4' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '#e4e7ec' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' }
    },
    active: {
        color: { type: BACKGROUND_TYPE.color, color: '#101828' },
        background: { type: BACKGROUND_TYPE.color, color: '#FFFFFF' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '#e4e7ec' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#101828' }
    }
}

export const ROUND_COLOR_DEFAULT: Omit<TabStyleConfig, 'id'> = {
    color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    background: { type: BACKGROUND_TYPE.color, color: '' },
    borderColor: { type: BACKGROUND_TYPE.color, color: '' },
    iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    hover: {
        color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
        background: { type: BACKGROUND_TYPE.color, color: '' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' }
    },
    active: {
        color: { type: BACKGROUND_TYPE.color, color: '#101828' },
        background: { type: BACKGROUND_TYPE.color, color: '#ECEFF4' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#101828' }
    }
}

export const SEGMENTED_COLOR_DEFAULT: Omit<TabStyleConfig, 'id'> = {
    color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    background: { type: BACKGROUND_TYPE.color, color: '' },
    borderColor: { type: BACKGROUND_TYPE.color, color: '' },
    iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
    hover: {
        color: { type: BACKGROUND_TYPE.color, color: '#98A2B3' },
        background: { type: BACKGROUND_TYPE.color, color: '' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#98A2B3' }
    },
    active: {
        color: { type: BACKGROUND_TYPE.color, color: '#101828' },
        background: { type: BACKGROUND_TYPE.color, color: '#FFFFFF' },
        borderColor: { type: BACKGROUND_TYPE.color, color: '' },
        iconColor: { type: BACKGROUND_TYPE.color, color: '#101828' }
    }
}

export const TAB_TYPE_COLOR_MAP = {
    [TABS_TYPE.CARD_SLOT]: CARD_COLOR_DEFAULT,
    [TABS_TYPE.LINE]: LINE_COLOR_DEFAULT,
    [TABS_TYPE.ROUND]: ROUND_COLOR_DEFAULT,
    [TABS_TYPE.SEGMENTED]: SEGMENTED_COLOR_DEFAULT
}

/**
 * 生成标签block的配置
 *
 * @returns {TabsBlockConfig}
 */
export const generateTabsBlock = (): TabsBlockConfig => {
    const length = 3
    function getListByVariantColorConfig(): TabConfig[] {
        return Array.from({ length }, (_, i) => i + 1).map(item => ({
            name: `标签 0${item}`,
            id: nanoid(),
            associatedView: '',
            icon: '',
            iconPosition: ALIGNMENT.left,
            // sizeFit: SIZE_FIT.contain
        }))
    }

    const baseList = getListByVariantColorConfig()

    return {
        variant: TABS_TYPE.LINE,
        associatedContainer: '',
        baseList,
        variantMap: {
            [TABS_TYPE.LINE]: {
                align: ALIGNMENT.center,
                styleList: baseList.map(item => ({ id: item.id, ...LINE_COLOR_DEFAULT })),
                design: {}
            },
            [TABS_TYPE.CARD_SLOT]: {
                align: ALIGNMENT.center,
                styleList: baseList.map(item => ({ id: item.id, ...CARD_COLOR_DEFAULT })),
                design: {}
            },
            [TABS_TYPE.ROUND]: {
                align: ALIGNMENT.center,
                styleList: baseList.map(item => ({ id: item.id, ...ROUND_COLOR_DEFAULT })),
                design: {}
            },
            [TABS_TYPE.SEGMENTED]: {
                align: ALIGNMENT.center,
                styleList: baseList.map(item => ({ id: item.id, ...SEGMENTED_COLOR_DEFAULT })),
                design: {
                    background: { type: 'color', color: '#f2f4f7' },
                    radius: [18, 18, 18, 18]
                }
            }
        },

        lineColor: { type: BACKGROUND_TYPE.color, color: '#101828' },

        font: {
            advance: '',
            align: ALIGNMENT.left,
            fontSize: 1,
            lineHeight: 1,
            letterSpacing: 0
        }
    }
}
