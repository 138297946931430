import { IconFont, Loading, Modal, Toast } from '@byecode/ui'
import type { SpaceUser } from '@lighthouse/core'
import type { RoleOption } from '@lighthouse/shared'
import { getAuthorize, spaceRoleOptions, useAtomAction, UserAvatar } from '@lighthouse/shared'
import { Modal as MantineModal } from '@mantine/core'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'

import { quitWorkSpaceAtom, removeWorkSpaceAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'
// import { EnhancedTableHead } from '@/components/EnhancedTableHead'
import { ModalTitle } from '@/components/ModalTitle'
import { useUser } from '@/hooks/useUser'
import { useWorkSpaceList } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import { useSpaceUserList } from '@/shared/reusable'

import { HandleSelect } from './HandleSelect'
import { Invite } from './Invite'
import { RoleSelect } from './RoleSelect'
import * as SC from './styles'
import { Transfer } from './Transfer'
import type { HeadCell, MemberProps, MemberState } from './types'

export const Member: React.FC<MemberProps> = ({ workspace }) => {
    const userData = useUser()
    const workspaceList = useWorkSpaceList()
    const navigate = useNavigate()
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const { run: removeWorkSpace } = useAtomAction(removeWorkSpaceAtom)
    const { run: quitWorkSpace } = useAtomAction(quitWorkSpaceAtom)
    const [open, setOpen] = useState<boolean>(false)

    const { roleId = '', id: spaceId, name } = workspace
    // const modalIdRef = useRef<string | undefined>()
    // const isTransfer = useMemo(() => getAuthorize(roleId, 'spaceTransfer'), [roleId])
    const isMemberPermission = useMemo(() => getAuthorize(roleId, 'spaceMemberPermission'), [roleId])
    const isMemberManager = useMemo(() => getAuthorize(roleId, 'spaceMemberManager'), [roleId])
    const [state, setState] = useImmer<MemberState>({
        isShowInvite: false,
        userList: [],
        keyword: '',
        isAbleRemoveUser: true,
        selected: []
    })

    const { data, isLoading, update } = useSpaceUserList(spaceId)

    const headCells: HeadCell[] = [
        {
            id: 'username',
            label: '名称'
        },
        {
            id: 'role',
            label: '角色',
            width: 200
        },
        {
            id: 'date',
            label: '加入日期',
            width: 200
        },
        {
            id: 'handle',
            label: '',
            width: 60
        }
    ]

    const toggleInvite = useCallback(
        (bool: boolean) => {
            setState(draft => {
                draft.isShowInvite = bool
            })
        },
        [setState]
    )

    const getMemberHandleOptions = useCallback(
        (userId: string) => {
            return [
                {
                    value: 'remove',
                    label: '移除',
                    hidden: !isMemberPermission || userId === userData.userId
                },
                {
                    value: 'quit',
                    label: '退出空间',
                    hidden: userId !== userData.userId
                }
            ].filter(item => !item.hidden)
        },
        [isMemberPermission, userData.userId]
    )

    const getMemberRoleOptions = useCallback(
        (data: SpaceUser, roles: RoleOption[]) => {
            let handleList = [...roles]

            if (!isMemberPermission || userData.userId === data.userId) {
                handleList = roles.map(item => {
                    return {
                        ...item,
                        disable: true
                    }
                })
            }

            // if (isMemberPermission && data.userId !== userData.userId) {
            //     const remove: RoleOption = {
            //         label: '移出空间',
            //         value: 'remove',
            //         describe: '将成员从空间移出',
            //         color: '#D88987'
            //     }
            //     handleList.push(remove)
            // }

            // if (data.userId === userData.userId) {
            //     const remove: RoleOption = {
            //         label: '离开空间',
            //         value: 'quit',
            //         describe: '离开该空间',
            //         color: '#D88987'
            //     }
            //     handleList.push(remove)
            // }

            // if (isTransfer && spaceNum > 1) {
            //     const transfer: RoleOptions = {
            //         label: '转让空间给',
            //         value: 'transfer',
            //         desc: '将管理员权限转让给他人',
            //         color: '#D88987'
            //     }
            //     handleList.push(transfer)
            // }
            return handleList
        },
        [isMemberPermission, userData.userId]
    )

    const navigateFirstSpace = useCallback(() => {
        const extraSpace = workspaceList.find(item => {
            return item.id !== spaceId
        })
        setWorkSpaceId(extraSpace?.id || '')
        if (extraSpace) {
            navigate({ pathname: '/workbench/applications' }, { replace: true })
            return
        }
        navigate(
            { pathname: '/' },
            {
                replace: true
            }
        )
    }, [navigate, setWorkSpaceId, spaceId, workspaceList])

    const handleQuitSpace = useCallback(async () => {
        const res = await quitWorkSpace({ spaceId, userId: userData.userId })
        if (res) {
            navigateFirstSpace()
        }
    }, [navigateFirstSpace, quitWorkSpace, spaceId, userData.userId])

    const handleRemoveSpace = useCallback(async () => {
        const isDelete = await removeWorkSpace(spaceId)
        if (isDelete) {
            navigateFirstSpace()
        }
    }, [navigateFirstSpace, removeWorkSpace, spaceId])

    // 更新成员角色
    const handleUpdateSelect = useCallback(
        async (id: string, value: string) => {
            await srv.transferSpace(spaceId, id, value)
            await update()
        },
        [spaceId, update]
    )

    const handleTransfer = useCallback(
        async (id: string) => {
            const res = await srv.transferSpace(spaceId, id, 'SPACE_ADMIN')
            if (res) {
                Toast.success('转交成功')
                handleQuitSpace()
            }
        },
        [handleQuitSpace, spaceId]
    )

    // 离开空间
    const handleQuit = useCallback(async () => {
        if (!data) {
            return
        }

        if (data.length === 1) {
            const isConfirm = await Modal.confirm({
                title: `确认离开「${name}」？`,
                content: '你是空间中的最后 1 个人，如果你离开空间的话，将自动删除空间',
                okText: '离开并删除空间'
            })
            if (isConfirm) {
                handleRemoveSpace()
            }
            return
        }

        const adminList = data.filter(item => item.roleId === 'SPACE_ADMIN')
        if (adminList.length === 1 && adminList[0].userId === userData.userId) {
            setOpen(true)
            return
        }

        // 空间人数大于1则正常离开
        const isConfirm = await Modal.confirm({
            title: `确认离开「${name}」？`
        })
        if (isConfirm) {
            handleQuitSpace()
        }
    }, [data, handleQuitSpace, handleRemoveSpace, name, userData.userId])

    // 移除成员
    const handleRemove = useCallback(
        async (id: string, name: string) => {
            const res = await srv.removeUsersOfSpace(spaceId, id)
            if (res) {
                Toast.success('移除成功')
                update()
            }
        },
        [spaceId, update]
    )

    const getSelectEnable = useCallback(
        (data: SpaceUser) => roleId === 'SPACE_ADMIN' && data.userId !== userData.userId,
        [roleId, userData.userId]
    )

    const handleSearchUser = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.keyword = event.target.value
            })
        },
        [setState]
    )

    const extraSelfList = useMemo(() => data?.filter(item => item.userId !== userData.userId) || [], [data, userData.userId])
    const userList = useMemo(() => data?.filter(item => item.username?.includes(state.keyword)) || [], [data, state.keyword])

    const headerExtra = useMemo(
        () =>
            isMemberManager && (
                <SC.InviteBtn
                    type="primary"
                    size="md"
                    icon={<IconFont size={16} type="Add" fill="#fff" />}
                    onClick={() => toggleInvite(true)}
                >
                    邀请成员
                </SC.InviteBtn>
            ),
        [isMemberManager, toggleInvite]
    )

    const titleAfter = useMemo(
        () => <SC.SearchBar size="md" prefix={<IconFont type="Search" />} placeholder="搜索成员..." onChange={handleSearchUser} />,
        [handleSearchUser]
    )

    return (
        <SC.Container>
            <ModalTitle title="空间成员" titleAfter={titleAfter} extra={headerExtra} />
            {isLoading ? (
                <Loading />
            ) : (
                <SC.MemberTable>
                    <SC.MemberHead>
                        {headCells.map(item => (
                            <SC.MemberTableCell key={item.id} width={item.width}>
                                {item.label}
                            </SC.MemberTableCell>
                        ))}
                    </SC.MemberHead>
                    {userList.map(row => (
                        <SC.MemberRow key={row.userId}>
                            <SC.MemberTableCell>
                                <SC.UserIconBox>
                                    <UserAvatar id={row.userId} avatar={row.avatar} name={row.username || ''} size="middle" />
                                    <SC.UserItemInfo>
                                        <SC.UserText>{row.username}</SC.UserText>
                                        <SC.UserMobile>{row.mobile}</SC.UserMobile>
                                    </SC.UserItemInfo>
                                </SC.UserIconBox>
                            </SC.MemberTableCell>
                            <SC.MemberTableCell width={200}>
                                <RoleSelect
                                    id={row.userId}
                                    value={row.roleId}
                                    disable={!getSelectEnable(row)}
                                    option={getMemberRoleOptions(row, spaceRoleOptions)}
                                    onUpdateSelect={handleUpdateSelect}
                                />
                            </SC.MemberTableCell>
                            <SC.MemberTableCell width={200}>
                                <SC.UserDate>{lightFormat(row.addSpaceTime, 'yyyy.MM.dd')}</SC.UserDate>
                            </SC.MemberTableCell>
                            <SC.MemberTableCell width={60}>
                                <HandleSelect
                                    id={row.userId}
                                    name={row.username}
                                    onRemove={handleRemove}
                                    option={getMemberHandleOptions(row.userId)}
                                    onQuit={handleQuit}
                                />
                            </SC.MemberTableCell>
                        </SC.MemberRow>
                    ))}
                </SC.MemberTable>
            )}
            <MantineModal
                withCloseButton={false}
                opened={open}
                centered
                radius={8}
                closeOnEscape
                onClose={() => setOpen(false)}
                zIndex={1210}
                styles={{ root: { background: 'transparent' } }}
            >
                <Transfer
                    data={extraSelfList}
                    onClose={() => {
                        setOpen(false)
                    }}
                    onConfirm={handleTransfer}
                />
            </MantineModal>
            <MantineModal
                centered
                title="邀请成员"
                size={473}
                closeOnEscape
                radius={8}
                padding={34}
                opened={state.isShowInvite}
                onClose={() => toggleInvite(false)}
                zIndex={1210}
            >
                <>
                    <Invite
                        spaceId={spaceId}
                        roleOption={spaceRoleOptions}
                        onClose={() => {
                            toggleInvite(false)
                        }}
                    />
                </>
            </MantineModal>
        </SC.Container>
    )
}
