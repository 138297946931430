import { ColorPicker, Popover, Switch } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    const [color, setColor] = React.useState('#00b388')
    return (
        <Unit title="基础">
            <Popover withinPortal>
                <Popover.Target>
                    <div style={{ width: 32, height: 32, backgroundColor: color }} />
                </Popover.Target>
                <Popover.Dropdown unstyled>
                    <ColorPicker value={color} onChange={setColor} />
                </Popover.Dropdown>
            </Popover>
        </Unit>
    )
}
