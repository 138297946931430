import type { Option } from '@byecode/ui'
import { Select } from '@byecode/ui'
import { DataSourceType } from '@lighthouse/core'
import type { FlowNode } from '@lighthouse/shared'
import { canAsUpstreamNodeTypes, FlowSelectRecordType, fromUpstreamOptions, getNodeInfo } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import * as SC from '../../styles'

interface SelectRecordProps {
    prefixName?: string
    allParentNodes: FlowNode[]
}

export const SelectRecord: React.FC<SelectRecordProps> = ({ prefixName = 'config', allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { type } = useFlow()
    const isInActionFlow = !!type

    const { control, setValue } = useFormContext()

    const selectType: string = useWatch({
        control,
        name: `${prefixName}.selectType`
    })

    const selectTypeOptions = useMemo(() => {
        if (isInActionFlow) {
            return fromUpstreamOptions.filter(item => item.value === FlowSelectRecordType.UPSTREAM)
        }
        return fromUpstreamOptions.filter(item => item.value === FlowSelectRecordType.CURRENT_RECORD)
    }, [isInActionFlow])

    // TODO: @柯霖 该方法已封装到nodeSetting目录下的help中，以提供多个节点公用
    const nodeOptions = useMemo(() => {
        return allParentNodes.reduce<Option[]>((prev, cur) => {
            if (!canAsUpstreamNodeTypes.includes(cur.data.nodeType)) {
                return prev
            }
            if (cur.data.config && 'dataSourceId' in cur.data.config) {
                const { dataSourceId } = cur.data.config
                const dataSource = find(item => item.id === dataSourceId, dataSourceList)
                if (!dataSource || dataSource.type === DataSourceType.aggregateDataSource) {
                    return prev
                }
            }
            prev.push({
                label: cur.data.name,
                value: cur.id,
                icon: getNodeInfo(cur.data.nodeType).icon
            })
            return prev
        }, [])
    }, [allParentNodes, dataSourceList])

    const renderSetRecordContent = useMemo(() => {
        if (selectType === FlowSelectRecordType.CURRENT_RECORD) {
            return null
        }
        return (
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel>选择上游节点</SC.FormItemLabel>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <Controller
                        name={`${prefixName}.nodeId`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Select
                                value={field.value}
                                options={nodeOptions}
                                onChange={val => {
                                    if (field.value === val) {
                                        return
                                    }
                                    field.onChange(val)
                                    setValue(`${prefixName}.fields`, [])
                                }}
                            />
                        )}
                    />
                </SC.FormItemContent>
            </SC.FormItem>
        )
    }, [control, nodeOptions, prefixName, selectType, setValue])

    return (
        <>
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel>要更新的记录</SC.FormItemLabel>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <Controller
                        name={`${prefixName}.selectType`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <Select {...field} disabled options={selectTypeOptions} />}
                    />
                </SC.FormItemContent>
            </SC.FormItem>

            {renderSetRecordContent}
        </>
    )
}
