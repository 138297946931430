import type { FieldType, SchemaProtocol, TableColumns } from '@lighthouse/core'
import type { FieldPermission } from '@lighthouse/shared'
import { APPROVAL_IGNORE_FIELD, getIsAppointField, notWriteFieldType, SYSTEM_FIELD } from '@lighthouse/shared'
import { Checkbox, Divider } from '@mantine/core'
import { find } from 'rambda'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import * as SC from '../../styles'

const SCxItem = styled.div`
    height: 32px;
    margin-bottom: 8px;
    display: grid;
    gap: 10px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 32px;
    align-items: center;
`

const SCxLabel = styled.div<{ bold?: boolean }>`
    color: var(---color-gray-900);
    font-size: var(--font-size-normal);
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

const SCxContent = styled.div`
    display: grid;
    gap: 4px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 32px;
    align-items: center;
`

const SCXCheckBox = styled(Checkbox)`
    display: flex;
    align-items: center;

    .mantine-Checkbox-label {
        font-size: 14px;
    }
`

export interface FieldAuthorityItemProps {
    id: string
    label: string
    type: FieldType
    value: FieldPermission['mode']
    onChange: (id: string, val: FieldPermission['mode']) => void
}

export interface FieldAuthorityProps {
    schema: SchemaProtocol['schema']
    value?: FieldPermission[]
    onChange: (val: FieldPermission[]) => void
}

export interface FieldSettingProps {
    schema: SchemaProtocol['schema']
    fieldProps?: TableColumns
}

export const FieldAuthorityItem: React.FC<FieldAuthorityItemProps> = ({ id, label, type, value, onChange }) => {
    const notWriteField = notWriteFieldType.has(type) || getIsAppointField(id, SYSTEM_FIELD)

    const handleReadChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                onChange(id, 'READ')
                return
            }
            onChange(id, 'NONE')
        },
        [id, onChange]
    )

    const handleWriteChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (notWriteField) {
                return
            }
            if (e.target.checked) {
                onChange(id, 'WRITE')
                return
            }
            onChange(id, 'READ')
        },
        [id, notWriteField, onChange]
    )

    const readCheck = value === 'READ' || value === 'WRITE'
    const writeCheck = value === 'WRITE'

    return (
        <SCxItem>
            <SCxLabel>{label}</SCxLabel>
            <SCxContent>
                <SCXCheckBox size="xs" checked={readCheck} onChange={handleReadChange} />
                <SCXCheckBox size="xs" checked={writeCheck} disabled={notWriteField} onChange={handleWriteChange} />
            </SCxContent>
        </SCxItem>
    )
}

export const FieldAuthority: React.FC<FieldAuthorityProps> = ({ schema, value, onChange }) => {
    const fieldPermissionList: FieldPermission[] = useMemo(() => {
        return Object.values(schema)
            .filter(s => !APPROVAL_IGNORE_FIELD.has(s.type))
            .map(item => {
                const fieldPermission = find(authority => authority.fieldId === item.id, value || [])
                if (fieldPermission) {
                    return fieldPermission
                }
                return {
                    fieldId: item.id,
                    mode: 'READ'
                }
            })
    }, [schema, value])

    const readList = fieldPermissionList.filter(item => item.mode === 'READ' || item.mode === 'WRITE')
    const normalFieldList = fieldPermissionList.filter(
        item => !getIsAppointField(item.fieldId, SYSTEM_FIELD) && !notWriteFieldType.has(schema[item.fieldId].type)
    )
    const writeList = fieldPermissionList.filter(item => item.mode === 'WRITE')

    const handleItemChange = useCallback(
        (id: string, val: FieldPermission['mode']) => {
            const newFieldPermissionList = fieldPermissionList.map(item => {
                if (item.fieldId === id) {
                    return {
                        ...item,
                        mode: val
                    }
                }
                return item
            })
            onChange(newFieldPermissionList)
        },
        [fieldPermissionList, onChange]
    )

    const handleAllReadChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked || readList.length !== fieldPermissionList.length) {
                const newFieldPermissionList = fieldPermissionList.map(item => {
                    const mode = item.mode === 'NONE' ? 'READ' : item.mode
                    return {
                        ...item,
                        mode
                    }
                })
                onChange(newFieldPermissionList)
                return
            }
            const newFieldPermissionList = fieldPermissionList.map(item => {
                const fieldPermission: FieldPermission = {
                    ...item,
                    mode: 'NONE'
                }
                return fieldPermission
            })
            onChange(newFieldPermissionList)
        },
        [fieldPermissionList, onChange, readList.length]
    )

    const handleAllWriteChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked || writeList.length !== normalFieldList.length) {
                const newFieldPermissionList: FieldPermission[] = fieldPermissionList.map(item => {
                    return {
                        ...item,
                        mode:
                            getIsAppointField(item.fieldId, SYSTEM_FIELD) || notWriteFieldType.has(schema[item.fieldId].type)
                                ? item.mode
                                : 'WRITE'
                    }
                })
                onChange(newFieldPermissionList)
                return
            }
            const newFieldPermissionList = fieldPermissionList.map(item => {
                const fieldPermission: FieldPermission = {
                    ...item,
                    mode: item.mode === 'WRITE' ? 'READ' : 'NONE'
                }
                return fieldPermission
            })
            onChange(newFieldPermissionList)
        },
        [fieldPermissionList, normalFieldList.length, onChange, schema, writeList.length]
    )

    return (
        <SC.Container>
            <SC.Wrapper>
                <SCxItem>
                    <SCxLabel bold>字段</SCxLabel>
                    <SCxContent>
                        <SCXCheckBox
                            size="xs"
                            checked={readList.length > 0}
                            indeterminate={readList.length !== fieldPermissionList.length && readList.length > 0}
                            onChange={handleAllReadChange}
                            label="可读"
                        />
                        <SCXCheckBox
                            size="xs"
                            checked={writeList.length > 0}
                            indeterminate={writeList.length !== normalFieldList.length && writeList.length > 0}
                            onChange={handleAllWriteChange}
                            label="编辑"
                        />
                    </SCxContent>
                </SCxItem>
                <Divider color="var(--color-gray-200)" style={{ marginBottom: 8 }} />
                {fieldPermissionList.map(item => (
                    <FieldAuthorityItem
                        key={item.fieldId}
                        id={item.fieldId}
                        label={schema[item.fieldId].name}
                        type={schema[item.fieldId].type}
                        value={item.mode}
                        onChange={handleItemChange}
                    />
                ))}
            </SC.Wrapper>
        </SC.Container>
    )
}

export const FieldSetting: React.FC<FieldSettingProps> = ({ schema }) => {
    const { control } = useFormContext()
    return (
        <Controller
            name="config.fieldPermissions"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
                return <FieldAuthority schema={schema} value={field.value} onChange={field.onChange} />
            }}
        />
    )
}
