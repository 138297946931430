import { Checkbox, Empty, tinyButtons } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { ScrollArea } from '@mantine/core'
import * as React from 'react'
import { useCss } from 'react-use'
import styled from 'styled-components'

import * as CM from '../styles'
import type { UserTitle } from '../type'
import UserItem from '../UserItem'

const SCxTableContainer = styled(ScrollArea)`
    position: relative;
    width: 100%;
    flex: 1;
    max-width: 100%;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--color-gray-200);
    border-bottom-style: none;
    overflow: auto;
    ${tinyButtons}
`

const SCxTableHeader = styled.div`
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-100);
    z-index: 1;
`

const SCxEmptyWrapper = styled.div`
    position: sticky;
    left: 50%;
    margin-left: -50%;
    width: fit-content;
`

export const SCxTableContent = styled.div`
    width: 100%;
    height: calc(100% - 47px);
    overflow: unset auto;
    ${tinyButtons}
`

interface UserListProps {
    searchUsers: AppUser[]
    titleList: UserTitle[]
    selectUsers: AppUser[]

    onAudit?: (value: boolean, userId: string) => void
    onSelectUsers?: (userIds: AppUser[]) => void
    onUpdateUser?: (params: AppUser) => void
}

const UserList: React.FC<UserListProps> = ({ titleList, searchUsers, selectUsers, onSelectUsers, onUpdateUser, onAudit }) => {
    const stickyCls = useCss({
        position: 'sticky',
        left: 0,
        backgroundColor: 'var(--color-gray-100)'
    })
    return (
        <SCxTableContainer>
            <SCxTableHeader>
                {titleList.map(({ width, xs, ...title }) => (
                    <CM.WidthItem key={title.id} {...title} width={width} className={title.id === 'checkbox' ? stickyCls : undefined}>
                        {title.id === 'checkbox' ? (
                            <Checkbox
                                size="xs"
                                checked={selectUsers.length === searchUsers.length && selectUsers.length > 0}
                                onChange={e => onSelectUsers?.(e.target.checked ? searchUsers : [])}
                            />
                        ) : (
                            <CM.TableColumnName>{title.name}</CM.TableColumnName>
                        )}
                    </CM.WidthItem>
                ))}
            </SCxTableHeader>
            <SCxTableContent>
                {searchUsers.length === 0 && (
                    <SCxEmptyWrapper>
                        <Empty icon="User" styles={{ root: { height: 300 } }} description="没有搜索到用户" />
                    </SCxEmptyWrapper>
                )}
                {searchUsers.map(user => {
                    const selectUserIds = selectUsers.map(user => user.userId)
                    return (
                        <UserItem
                            key={user.userId}
                            user={user}
                            titleList={titleList}
                            checked={selectUserIds.includes(user.userId) && selectUserIds.length > 0}
                            onSelectUsers={userId =>
                                onSelectUsers?.(userId ? [...selectUsers, user] : selectUsers.filter(draft => draft.userId !== user.userId))
                            }
                            onAudit={onAudit}
                            onUpdateUser={onUpdateUser}
                            disableGroups={user.state === 2}
                        />
                    )
                })}
            </SCxTableContent>
        </SCxTableContainer>
    )
}

export default React.memo(UserList)
