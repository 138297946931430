import type { ReactNode } from 'react'
import React from 'react'

import * as SC from './styles'

export interface ModalTitleProps {
    title: string
    titleAfter?: ReactNode
    size?: number
    extra?: ReactNode
    style?: React.CSSProperties
}

export const ModalTitle: React.FC<ModalTitleProps> = ({ title, titleAfter, size = 24, extra, style }) => {
    return (
        <SC.Container style={style}>
            <SC.Main>
                <SC.Title size={size}>{title}</SC.Title>
                {titleAfter}
            </SC.Main>
            {!!extra && <SC.Extra>{extra}</SC.Extra>}
        </SC.Container>
    )
}
