import { IconFont, pointer } from '@byecode/ui'
import { Text } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

const SCxAddVariableButton = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-main);
    border-radius: 3px;
`
const SCxContainer = styled.div<{ width?: number }>`
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
    ${pointer}
`

const SCxIcon = styled(IconFont)``

export const AddVariableButton = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
    return (
        <SCxContainer {...props} ref={ref}>
            <SCxAddVariableButton>
                <SCxIcon type="Add" fill="var(--color-white)" size={16} />
            </SCxAddVariableButton>
            <Text size={14}>添加变量</Text>
        </SCxContainer>
    )
})
