import { Button, SegmentedControl } from '@byecode/ui'
import React, { useMemo, useState } from 'react'

import { Unit } from '../Mics'

const data = [
    {
        label: '男',
        value: 'man'
    },
    {
        label: '女',
        value: 'woman'
    },
    {
        label: '未知',
        value: 'unknown'
    }
]

export const Controlled: React.FC = () => {
    const [value, setValue] = useState('man')
    const label = useMemo(() => {
        return data.find(item => item.value === value)?.label
    }, [value])

    return (
        <Unit title="受控">
            <SegmentedControl data={data} value={value} onChange={setValue} />
            当前选中：{label}
            <Button type="primary" onClick={() => setValue('unknown')}>
                设置为未知
            </Button>
        </Unit>
    )
}
