import { pointer } from '@byecode/ui'
import type { AvatarSize } from '@lighthouse/shared'
import { avatarSize } from '@lighthouse/shared'
import { useItemFinishListener, useUploadyContext } from '@rpldy/shared-ui'
import UploadDropZone from '@rpldy/upload-drop-zone'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import { RoundAvatar } from '../RoundAvatar'

interface UploadSpaceAvatarProps {
    children?: React.ReactNode
    onUpdateImg: (imgSrc: string) => void
    border?: boolean
    size?: AvatarSize
    name?: string
    img?: string
    type?: 'img' | 'icon'
    fontSize?: number
    background?: string
    disable?: boolean
}

const Avatar = styled.div<{ size: number; border?: boolean; fontSize?: number }>`
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ border }) =>
        border &&
        css`
            border: 1px solid var(--color-gray-200);
            border-radius: 6px;
        `}

    ${pointer}
`

export const UploadSpaceAvatar: React.FC<UploadSpaceAvatarProps> = ({
    img,
    type = 'img',
    onUpdateImg,
    name,
    disable = false,
    fontSize,
    background,
    size = 'xLarge'
}) => {
    const uploady = useUploadyContext()
    const spaceSize = useMemo(() => avatarSize[size], [size])
    useItemFinishListener(item => {
        const { uploadResponse } = item

        onUpdateImg(uploadResponse.data.content.url)
    })

    const handleUpload = useCallback(() => {
        if (disable) {
            return
        }
        uploady.showFileUpload()
    }, [disable, uploady])

    return (
        <UploadDropZone>
            <Avatar size={spaceSize} fontSize={fontSize} onClick={handleUpload}>
                <RoundAvatar radius="8px" icon={img} name={name || ''} type={type} size={size} background={background} />
            </Avatar>
        </UploadDropZone>
    )
}
