import { backgroundTransitionOnClick, IconFont, pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Item = styled.div`
    height: 36px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    ${pointer}
    ${backgroundTransitionOnClick}
`

export const Icon = styled(IconFont)<{ COLOR?: string }>`
    font-size: 16px;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
`

export const DropDown = styled.div``

export const DropDownContainer = styled.div`
    min-width: 180px;
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    max-width: 300px;
`

export const DropDownScrollArea = styled.div`
    flex: 1;
    overflow-y: auto;
    ${tinyButtons}
`
