import type { DataSourceAbstract, VariableADTvalue } from '@lighthouse/core'
import type { VariableOptions } from '@lighthouse/shared'
import { VariablePicker } from '@lighthouse/shared'
import { usePopupState } from 'material-ui-popup-state/hooks'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { AddVariableButton } from './AddVariableButton'

const SCxContainer = styled.div<{ width?: number }>`
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
`

interface NodeFieldPreviewProps {
    options: VariableOptions
    onSelect?: (value: VariableADTvalue) => void
}

export const NodeFieldVariable: React.FC<NodeFieldPreviewProps> = ({ options, onSelect }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'popupState' })
    const editorRef = useRef(null)

    useEffect(() => {
        if (editorRef.current) {
            popupState.setAnchorEl(editorRef.current)
        }
    }, [popupState])

    return (
        <SCxContainer>
            <VariablePicker options={options} triggerWrapper={AddVariableButton} onSelect={onSelect} />
        </SCxContainer>
    )
}
