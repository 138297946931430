import { backgroundTransitionOnClick, Popover } from '@byecode/ui'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

export type WhereValueType = 'OR' | 'AND'

export interface WhereSelectProps {
    children?: React.ReactNode

    value: WhereValueType
    onChange?: (val: WhereValueType) => void
}

const WhereSelectorWrapper = styled.div`
    margin-right: 12px;
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    justify-content: center;
`

export const FilterGroupGuideLineRect = styled.div`
    position: absolute;
    width: 50%;
    height: calc(100% - 30px);
    right: 0;
    top: 15px;
    border: 1px solid;
    border-right-color: transparent !important;
`

const WhereSelector = styled.div`
    z-index: 1;
    display: flex;
    width: 28px;
    height: 22px;
    border-radius: 10px;
    color: #ffffff;
    font-size: var(--font-size-normal);
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const WhereList = styled.div`
    padding: 8px 0;
`

const WhereItem = styled.div`
    padding: 6px 14px;
    ${backgroundTransitionOnClick}
`

const WhereValue = styled.div`
    display: flex;
    width: 28px;
    height: 16px;
    border-radius: 10px;
    color: #ffffff;
    font-size: var(--font-size-normal);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
`

export const WhereSelect: React.FC<WhereSelectProps> = ({ value, onChange }) => {
    const [open, setOpen] = useState(false)

    const values = useMemo<WhereValueType[]>(() => ['AND', 'OR'], [])

    const valueMap = {
        OR: { color: 'var(--color-teal)', text: '或' },
        AND: { color: 'var(--color-main)', text: '且' }
    }

    const handleChange = (val: 'OR' | 'AND') => {
        onChange?.(val)
        setOpen(false)
    }

    return (
        <WhereSelectorWrapper>
            <FilterGroupGuideLineRect style={{ borderColor: valueMap[value]?.color }} />
            <Popover opened={open} onChange={setOpen} width="auto">
                <Popover.Target>
                    <WhereSelector style={{ backgroundColor: valueMap[value]?.color }}>{valueMap[value].text}</WhereSelector>
                </Popover.Target>
                <Popover.Dropdown>
                    <WhereList>
                        {values.map(val => {
                            const { color, text } = valueMap[val]
                            return (
                                <WhereItem
                                    key={val}
                                    onClick={() => {
                                        handleChange(val)
                                    }}
                                >
                                    <WhereValue style={{ backgroundColor: color }}>{text}</WhereValue>
                                </WhereItem>
                            )
                        })}
                    </WhereList>
                </Popover.Dropdown>
            </Popover>
        </WhereSelectorWrapper>
    )
}
