import type { Environment } from "@lighthouse/core";

import { APPLICATION_ENV_PROD, ENV_DEV_COLOR, ENV_DEV_COLOR_LIGHT, ENV_ICON, ENV_PROD_COLOR, ENV_PROD_COLOR_LIGHT, ENV_PROD_ICON } from "../constants";

export const getEnvColor = (data?: Environment) => {
    if (data?.envId === APPLICATION_ENV_PROD) {
        return {
            color: ENV_PROD_COLOR,
            tinyColor: ENV_PROD_COLOR_LIGHT
        }
    }
    return {
        color: ENV_DEV_COLOR,
        tinyColor: ENV_DEV_COLOR_LIGHT
    }
}


export const getEnvIcon = (envId: string | undefined) => {
    const isProd = envId === APPLICATION_ENV_PROD
    return isProd ? ENV_PROD_ICON : ENV_ICON
}
