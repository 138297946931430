import { IconFont, Tag, Text } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { getEnvColor } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

const SCxTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
`

const SCxTag = styled(Tag)`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 1px 4px;
    border-radius: 4px;
`

interface EnvTagProps {
    data?: Environment
}

export const EnvTag: React.FC<EnvTagProps> = ({ data }) => {
    return (
        <SCxTag style={{ backgroundColor: getEnvColor(data).tinyColor }}>
            <IconFont size={16} type="DataSource" fill={getEnvColor(data).color} />
            <Text color={getEnvColor(data).color} size={14} style={{ maxWidth: 90 }}>
                {data?.envName}
            </Text>
        </SCxTag>
    )
}
