export function urlToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
        // 从URL获取数据
        fetch(url, {
            cache: 'no-cache'
        })
            .then(response => response.blob())
            .then(blob => {
                // 转换Blob数据为Base64
                const reader = new FileReader()
                reader.onloadend = e => {
                    const base64Data = e.target?.result?.toString()
                    if (base64Data) {
                        resolve(base64Data)
                    } else {
                        reject(new Error('Failed to convert URL to Base64.'))
                    }
                }
                // eslint-disable-next-line unicorn/prefer-add-event-listener
                reader.onerror = error => {
                    reject(error)
                }
                reader.readAsDataURL(blob)
            })
            .catch(error => reject(error))
    })
}
