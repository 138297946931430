import type { CascadeOption } from '@byecode/ui'
import { CascadeSelect, Group, Text } from '@byecode/ui'
import { Card, Grid, Title } from '@mantine/core'
import React from 'react'

import { Unit } from '../Mics'

const options: CascadeOption[] = [
    {
        value: 'fruit',
        label: '水果1111111113123123123',
        children: [
            {
                value: 'apple',
                label: '苹果'
            },
            {
                value: 'banana',
                label: '香蕉'
            }
        ]
    },
    {
        value: 'vegetable',
        label: '蔬菜',
        children: [
            {
                value: 'tomato',
                label: '西红柿'
            },
            {
                value: 'potato',
                label: '土豆'
            }
        ]
    },
    {
        value: 'test',
        label: 'test'
    },
    {
        value: 'test1',
        label: 'test1'
    },
    {
        value: 'test2',
        label: 'test2'
    },
    {
        value: 'test3',
        label: 'test3'
    },
    {
        value: 'test4',
        label: 'test4'
    },
    {
        value: 'test5',
        label: 'test5'
    },
    {
        value: 'test6',
        label: 'test6'
    },
    {
        value: 'test7',
        label: 'test7'
    }
]

export const CascadeComponentsDemo: React.FC = () => {
    return (
        <Card withBorder>
            <Group label={<Title order={2}>级联选择</Title>} renderRightIcon={() => null}>
                <Grid gutter={20}>
                    <Grid.Col span={2}>
                        <Unit title="普通">
                            <CascadeSelect withinPortal options={options} />
                        </Unit>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Unit title="最后一级">
                            <CascadeSelect withinPortal lastLevel={false} options={options} />
                        </Unit>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Unit title="多选">
                            <CascadeSelect withinPortal multiple options={options} />
                        </Unit>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Unit title="多选可搜索">
                            <CascadeSelect withinPortal multiple searchable options={options} />
                        </Unit>
                    </Grid.Col>
                </Grid>
            </Group>
        </Card>
    )
}
