import { backgroundTransitionOnClick, Checkbox, flex, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 12px 0;
    font-size: var(--font-size-normal);
`
export const Content = styled.div``

export const Footer = styled.div`
    margin-top: 4px;
`
export const RadioGroup = styled.div`
    ${flex}
    flex-direction: column;
    align-items: flex-start;
`
export const UploadImgContain = styled.div`
    padding: 8px 0;
    ${flex}
    gap: 4px;
    justify-content: space-around;
    align-items: center;
`
export const UploadImgDesc = styled.div`
    width: 100%;
    padding-left: 8px;
    color: var(--color-gray-500);
    line-height: 24px;
    font-weight: 400;
    font-size: 12px;
    box-sizing: border-box;
`
export const LogoName = styled.div`
    margin-top: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: var(--font-size-normal);
    font-size: var(--font-size-normal);
    line-height: 16px;
    text-align: center;
    color: var(--color-black);
`

export const LogoCheckBox = styled.div`
    width: calc(100% + 16px);
    height: 40px;
    margin: 0 -8px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 8px;
    ${pointer}
    ${backgroundTransitionOnClick}
`
