import { flex, Input, singleTextEllipsis } from '@byecode/ui'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'

import * as CM from '../styles'

const RenameInput: typeof Input = styled(Input)``

export const RenameInputContain = styled.div<{ readonly?: boolean }>`
    width: 100%;
    height: 100%;
    ${flex}
    align-items: center;
    padding-right: 8px;

    ${({ readonly }) =>
        readonly
            ? css`
                  cursor: not-allowed;
              `
            : css`
                  :hover {
                      ${CM.Icon} {
                          display: flex;
                      }
                  }
              `}

    ${CM.Icon} {
        display: none;
        margin-left: 6px;
        padding: 2px;
        /* background-color: #fff; */
        border-radius: 4px;
        z-index: 2;
    }
`
export const PreViewName = styled.div<{ weight?: number }>`
    font-weight: ${({ weight }) => weight};
    ${singleTextEllipsis}
`

interface EditInputProps {
    value: string
    readonly?: boolean
    weight?: number
    reg?: RegExp
    onBlur?: (value: string) => void
    onChange?: (value: string) => void
    onReset?: (value: string) => string | undefined
}

const EditInput: React.FC<EditInputProps> = ({ value, readonly = false, weight = 400, reg, onChange, onBlur, onReset }) => {
    const [newValue, setNewValue] = useState(value)
    const inputRef = useRef<HTMLInputElement>(null)
    const [isEdit, setIsEdit] = useState(false)

    useUpdateEffect(() => {
        setNewValue(value)
    }, [value])

    const handleReset = useCallback(
        (newValue: string) => {
            const defaultValue = onReset?.(newValue)
            if (defaultValue) {
                setNewValue(defaultValue)
                return false
            }
            return true
        },
        [onReset]
    )

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
                setIsEdit(false)
                if (handleReset(e.currentTarget.value)) {
                    const isChange = reg ? reg.test(e.currentTarget.value) : true
                    isChange ? onChange?.(e.currentTarget.value) : setNewValue(value)
                }
            }
        },
        [handleReset, onChange, reg, value]
    )
    useEffect(() => {
        if (inputRef.current && isEdit) {
            inputRef.current.select()
        }
    }, [isEdit])

    return (
        <>
            <RenameInputContain readonly={readonly}>
                {isEdit ? (
                    <RenameInput
                        ref={inputRef}
                        value={newValue}
                        styles={{
                            input: {
                                padding: '0 6px',
                                height: 32,
                                lineHeight: '16px',
                                minHeight: 32
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setNewValue(e.target.value)
                        }}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                            setIsEdit(false)
                            if (handleReset(e.currentTarget.value)) {
                                ;(reg ? reg.test(e.currentTarget.value) : true) ? onBlur?.(e.currentTarget.value) : setNewValue(value)
                            }
                        }}
                    />
                ) : (
                    <>
                        <PreViewName weight={weight}>{newValue}</PreViewName>
                        <CM.Icon
                            color="var(--color-gray-400)"
                            type="PencilSimple"
                            onClick={() => {
                                if (readonly) {
                                    return
                                }
                                setIsEdit(true)
                                if (inputRef.current) {
                                    inputRef.current.focus()
                                }
                            }}
                        />
                    </>
                )}
            </RenameInputContain>
        </>
    )
}

export default EditInput
