import React, { createContext, useContext } from 'react'

import type { FindUseADTObject } from '../types'

export type FindUseObjectContextProps = {
    value?: FindUseADTObject
    children: React.ReactNode
}

export const FindUseObjectContext = createContext<FindUseADTObject | undefined>(undefined)

export const FindUseObjectContextProvider: React.FC<FindUseObjectContextProps> = ({ value, children }) => {
    return <FindUseObjectContext.Provider value={value}>{children}</FindUseObjectContext.Provider>
}

export const useFindUseObjectContext = () => useContext(FindUseObjectContext)
