import { Box, Group, SegmentedControl, Slider, Text } from '@byecode/ui'
import { type Field, type QrBarcodeBlockAbstract, QrBarcodeEnum } from '@lighthouse/core'
import {
    BasicListItem,
    getIsFindUseInRichText,
    getPageDataOptions,
    getUserDatasourceOptions,
    ListItem4ByecodeUi,
    NodeFieldPreview,
    useAtomData,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { userDataSourceAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { VisibilityFilter } from '../Common/VisibilityFilter'
import { SETTINGS_TAB_ENUM, SETTINGS_TABS } from '../constants'
import { CodeTypeSelector } from './CodeTypeSelector'

const SCxContainer = styled.div`
    height: 100%;
`

export const FormItemContent = styled.div`
    flex-shrink: 0;
    width: 180px;
`

interface QrBarcodeSettingProps {}

const filterField = (field: Field) => field.type !== 'notes'

export const QrBarcodeSetting: React.FC<QrBarcodeSettingProps> = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const [currentTab, setCurrentTab] = useState<string>(SETTINGS_TAB_ENUM.normal)
    const { control } = useFormContext<QrBarcodeBlockAbstract['config']>()
    const findUseObject = useFindUseObjectContext()
    const userDataSource = useAtomData(userDataSourceAtom)
    const { customViewOption } = useVariableCustomViewOption(filterField)
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )

    const codeType = useWatch({ control, name: 'codeType' })

    const {
        prev,
        curr: { recordId, variableSourceType, datasource }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])

    const userOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filterField
        })
    }, [userDataSource])

    const contentLabel = useMemo(() => (codeType === QrBarcodeEnum.BARCODE ? '条形码内容' : '二维码内容'), [codeType])

    return (
        <Box style={{ height: '100%' }}>
            <BasicListItem grow>
                <SegmentedControl disabled={false} data={SETTINGS_TABS} fullWidth size="lg" value={currentTab} onChange={setCurrentTab} />
            </BasicListItem>
            <SCxContainer key={SETTINGS_TAB_ENUM.normal} hidden={currentTab !== SETTINGS_TAB_ENUM.normal}>
                <Group label="类型">
                    <ListItem4ByecodeUi grow>
                        <Controller name="codeType" control={control} render={({ field }) => <CodeTypeSelector {...field} />} />
                    </ListItem4ByecodeUi>
                </Group>
                <Divider color="var(--color-gray-200)" />
                <Group label="内容">
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>{contentLabel}</Text>
                        <FormItemContent>
                            <Controller
                                control={control}
                                name="content"
                                render={({ field }) => {
                                    const isHighLight = getIsFindUseInRichText({
                                        doc: field.value,
                                        findUseObject,
                                        currentDsId: datasource?.id
                                    })
                                    return (
                                        <NodeFieldPreview
                                            disabled={disabledWithVersion}
                                            highlighting={isHighLight}
                                            style={{ width: '100%' }}
                                            autoHeight
                                            placeholder="请输入"
                                            options={options}
                                            userOption={userOption}
                                            viewOption={customViewOption}
                                            enablePageLink
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </FormItemContent>
                    </ListItem4ByecodeUi>
                    {codeType === QrBarcodeEnum.BARCODE && (
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>柱状条宽度</Text>
                            <FormItemContent>
                                <Controller
                                    control={control}
                                    name="barWidth"
                                    render={({ field }) => (
                                        <Slider
                                            min={1}
                                            max={5}
                                            step={1}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </FormItemContent>
                        </ListItem4ByecodeUi>
                    )}
                </Group>
            </SCxContainer>
            <SCxContainer key={SETTINGS_TAB_ENUM.visibility} hidden={currentTab !== SETTINGS_TAB_ENUM.visibility}>
                <VisibilityFilter />
            </SCxContainer>
        </Box>
    )
}
