import { type BlockAbstract, type ChartBlockAbstract, type DataSourceAbstract } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

import { getIndicatorOption } from '@/constants/Block/sharedStyle'

export const getInitIndicatorChartConfigurator = (dataSource: DataSourceAbstract) => {
    const { schema, id: dsId } = dataSource
    const textField = Object.values(schema).find(f => f.type === 'text' || (f.type === 'aggregation' && f.innerType === 'TEXT'))
    const textFieldId = textField?.id || 'ID'
    const numberField = Object.values(schema).find(f => f.type === 'number' || (f.type === 'aggregation' && f.innerType === 'NUMBER'))
    const numberFieldId = numberField?.id || textFieldId
    const calcType = numberField ? 'max' : 'count'

    return {
        pointer: dsId,
        fieldId: numberFieldId,
        calcType
    }
}

export const getInitChartBlockConfigurator = function (
    block: ChartBlockAbstract,
    dataSource: DataSourceAbstract,
    index?: number
): ChartBlockAbstract {
    const { schema, appId, id: dsId } = dataSource
    const textField = Object.values(schema).find(f => f.type === 'text' || (f.type === 'aggregation' && f.innerType === 'TEXT'))
    const textFieldId = textField?.id || 'ID'
    const numberField = Object.values(schema).find(f => f.type === 'number' || (f.type === 'aggregation' && f.innerType === 'NUMBER'))
    const numberFieldId = numberField?.id
    const calcType = numberField ? 'max' : 'count'
    switch (block.config.chartType) {
        case 'bar':
        case 'striation': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId ? [
                        {
                            id: nanoid(),
                            fieldId: numberFieldId,
                            calcType,
                            chartType: 'bar'
                        }
                    ] : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'line': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId ? [
                        {
                            id: nanoid(),
                            fieldId: numberFieldId,
                            calcType,
                            chartType: 'line',
                            showArea: false,
                            showSymbol: true,
                            lineType: 'smooth'
                        }
                    ] : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'funnel':
        case 'pie': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId ? [
                        {
                            id: nanoid(),
                            fieldId: numberFieldId,
                            calcType
                        }
                    ] : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'composite': {
            const secondNumberField = Object.values(schema).find(f => f.type === 'number' && f.id !== numberFieldId)
            const secondCalcType = secondNumberField ? 'sum' : calcType
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId ? [
                        {
                            id: nanoid(),
                            fieldId: numberFieldId,
                            calcType,
                            chartType: 'bar'
                        }
                    ] : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    },
                    showSecondaryAxis: !!secondNumberField,
                    secondaryAxis: secondNumberField
                        ? [
                            {
                                id: nanoid(),
                                fieldId: secondNumberField?.id,
                                calcType: secondCalcType,
                                chartType: 'line',
                                showArea: false,
                                showSymbol: true,
                                lineType: 'straight'
                            }
                        ] : []
                }
            }
        }
        case 'indicator': {
            return {
                ...block,
                config: {
                    ...block.config,
                    ...getIndicatorOption(),
                    appId,
                    pointer: dsId,
                    fieldId: numberFieldId,
                    calcType
                }
            }
        }
        default: {
            return block
        }
    }
    // const dimension = dataSource.schema
}

export const getCreateBlock = function (block: BlockAbstract, dataSource?: DataSourceAbstract): BlockAbstract {
    // switch (block.type) {
    //     case 'chart': {
    //         if (dataSource) {
    //             return getInitChartBlockConfigurator(block, dataSource)
    //         }
    //         return { ...block, config: { ...block.config, po∏∏inter: '' } } as BlockAbstract
    //     }
    //     case 'view': {
    //         const viewFieldSettings = dataSource
    //             ? getViewColumns({
    //                   tableProps: dataSource?.viewOptions?.tableProps,
    //                   schema: dataSource?.schema
    //               })
    //             : undefined
    //         return {
    //             ...block,
    //             title: dataSource?.name,
    //             config: { ...block.config, pointer: dataSource?.id ?? '', viewFieldSettings }
    //         } as BlockAbstract
    //     }
    //     default: {
    //         return block
    //     }
    // }
    return block
}
