import type { AppUserDepartment } from '@lighthouse/shared'
import { find } from 'rambda'

export function getDepartmentParentIds(id: string, list: AppUserDepartment[], arr: string[]): string[] {
    const department = find(item => item.departmentId === id, list)
    return department?.departParentId ? getDepartmentParentIds(department.departParentId, list, [...arr, department.departParentId]) : arr
}

export function getDepartmentChildIds(id: string, list: AppUserDepartment[], arr: string[]): string[] {
    const department = find(item => item.departmentId === id, list)
    if (!department) {
        return arr
    }
    for (const departmentChild of department.departmentChild ?? []) {
        arr = getDepartmentChildIds(departmentChild.departmentId, list, [...arr, departmentChild.departmentId])
    }
    return arr
}

export function getFlatDepartmentChild(department: AppUserDepartment, arr: AppUserDepartment[]) {
    const { departmentId, departmentChild = [] } = department
    let newArr = arr
    for (const element of departmentChild) {
        newArr = getFlatDepartmentChild(element, [...newArr, { ...element, departParentId: departmentId, departmentChild: [] }])
    }
    return newArr
}

export function getFlatDepartments(list: AppUserDepartment[]) {
    let newArr: AppUserDepartment[] = []
    for (const element of list) {
        newArr = getFlatDepartmentChild(element, [...newArr, { ...element, departmentChild: [] }])
    }
    return newArr
}
