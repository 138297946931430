import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const VerificationCodeTextButton = styled(Button)`
    height: 40px;
    padding: 0 6px;
    text-align: center;
    font-weight: 400;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border: 1px solid #dfdfdd;
    background-color: var(--color-white);
    border-radius: 8px;
    outline: none;

    cursor: pointer;
    white-space: nowrap;

    :hover {
        background-color: transparent;
    }
`
