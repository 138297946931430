import React, { useCallback, useMemo } from 'react'
import { useImmer } from 'use-immer'

import type { Mode } from '../types'
import { Bind } from './Bind'
import type { AuthState } from './types'
import { Wechat } from './Wechat'

interface AuthProps {
    mode: Mode
    inviteCode: string | null
    onChangeMode: (mode: Mode) => void
}

export const Auth: React.FC<AuthProps> = ({ mode, inviteCode, onChangeMode }) => {
    const [state, setState] = useImmer<AuthState>({
        mark: ''
    })

    const { mark } = state

    const handleChangeState = useCallback(
        (mark: string) => {
            setState(draft => {
                draft.mark = mark
            })
        },
        [setState]
    )

    return useMemo(() => {
        if (mode === 'bind' && mark) {
            return <Bind mark={mark} inviteCode={inviteCode} onChangeMode={onChangeMode} />
        }
        return <Wechat onChangeMode={onChangeMode} onChangeState={handleChangeState} />
    }, [mode, mark, onChangeMode, handleChangeState, inviteCode])
}
