import { Divider } from '@byecode/ui'
import type { ApplicationSettingSms, ApplicationWebsiteSetting } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { useApplicationSetting } from '@/hooks/useApplication'

import { SettingCard } from '../SettingCard'
import * as CM from '../styles'
import { SmsConfig } from './SmsConfig'

interface SettingSEOProps {}

const Contain = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);
`

const SettingSEO: React.FC<SettingSEOProps> = () => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)

    const methods = useForm<ApplicationSettingSms>({
        mode: 'onChange',
        defaultValues: appSetting?.sms
    })
    const { watch, control, reset } = methods

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingSms) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        sms: value
                    }
                })
            }),
        [updateAppSetting]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingSms)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <Contain>
            <FormProvider {...methods}>
                <SettingCard title="短信发送方">
                    <div style={{ width: 430,marginTop: 16 }}>
                        <SmsConfig />
                    </div>
                </SettingCard>
            </FormProvider>
        </Contain>
    )
}

export default SettingSEO
