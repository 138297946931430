import React from 'react'

import { AccountSecurity } from './AccountSecurity'
import { PlatformLogin } from './PlatformLogin'
import * as SC from './styles'

export const AccountManager: React.FC = () => {
    return (
        <SC.Container>
            <AccountSecurity />
            <PlatformLogin />
        </SC.Container>
    )
}
