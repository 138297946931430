import { tinyButtons } from '@byecode/ui'
import type { NavigationLogoType } from '@lighthouse/core'
import { type ApplicationPublishInfo, useAtomAction, useAtomData } from '@lighthouse/shared'
import { clsx, Flex, Image, Text } from '@mantine/core'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { sharePosterAtom } from '@/atoms/application/state'
import type { RoundAvatarProps } from '@/components/RoundAvatar'
import { RoundAvatar } from '@/components/RoundAvatar'
import { TagIcon } from '@/components/TagIcon'
import { useApplication } from '@/hooks/useApplication'
import { usePageURI } from '@/hooks/usePageURI'

import { POSTER_LIST } from '../../constants'
import * as CM from '../../styles'
import { urlToBase64 } from '../help'
import { SharedContainer } from '../Share'
import type { Mode } from '../types'

interface SharePosterProps {
    onBack?: () => void
    onClose?: () => void
}

const SCxPosterItem = styled.div`
    width: 230px;
    flex-shrink: 0;
`

const SCxPosterItemWrapper = styled.div`
    /* width: 232px;
    height: 392px; */
    width: max-content;
    border: 6px solid transparent;
    border-radius: 29px;
    padding: 12px;

    &.active {
        border: 6px solid var(--color-theme-7);
        > * {
            transform: scale(0.95, 0.975);
        }
    }
    font-size: var(--font-size-normal);
    background-color: var(--color-white);
`
const SCxPosterItemContent = styled.div`
    position: relative;
    width: 464px;
    height: 784px;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
        border-radius: 8px;
        outline: 5px solid var(--color-gray-200);
    }
`
const SCxItemDescribe = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 167px;
    display: flex;
    justify-content: center;
`

const SCxReplenish = styled.div`
    position: absolute;
    inset: 0;
    font-size: 8px;
`

const SCxLogo = styled.div`
    position: absolute;
    display: flex;
    padding: 0 83px;
    width: 100%;
    justify-content: center;
    top: 214px;
`
const SCxContent = styled.div`
    width: 100%;
    height: 392px;
    margin: -8px 0 12px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    overflow: auto hidden;
    ${tinyButtons}
`
const SCxSuffix = styled.div`
    /* justify-self: flex-end; */
    margin-left: auto;
`

const SCxRoundAvatar = styled(RoundAvatar)`
    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        border-radius: unset;
    }
`

export const Item = React.forwardRef<
    HTMLDivElement,
    {
        imgUrl: string
        name: string
        subName?: string
        shareUrl: string
        avatarProps: Pick<RoundAvatarProps, 'icon' | 'type' | 'background'>
    }
>(({ imgUrl, avatarProps, name, subName, shareUrl }, ref) => {
    return (
        <SCxPosterItemContent ref={ref}>
            <Image src={imgUrl} width={464} height={784} />
            <SCxReplenish>
                <SCxLogo>
                    <SCxRoundAvatar
                        iconSize={100}
                        size={300}
                        radius="20px"
                        iconColor="var(--color-white)"
                        style={{ border: '12px solid #fff', overflow: 'hidden' }}
                        {...avatarProps}
                    />
                </SCxLogo>
                <SCxItemDescribe>
                    <Flex style={{ width: 300 }} justify="space-between">
                        <Flex direction="column" gap={8} justify="flex-start" style={{ margin: '11px 0' }}>
                            <Text size={20} weight={600} lineClamp={1} style={{ lineHeight: '24px' }}>
                                {name}
                            </Text>
                            <Text size={14} style={{ lineHeight: '22px' }}>
                                {subName}
                            </Text>
                        </Flex>
                        <Flex direction="column" gap={8}>
                            <QRCode
                                size={56}
                                value={shareUrl}
                                renderAs="canvas"
                                style={{ padding: 4, borderRadius: 4, background: 'rgba(255, 255, 255, 0.56)' }}
                            />
                            <Text align="center" color="rgba(0, 0, 0, 0.34)" size={12} style={{ lineHeight: '20px' }}>
                                扫码查看
                            </Text>
                        </Flex>
                    </Flex>
                </SCxItemDescribe>
            </SCxReplenish>
        </SCxPosterItemContent>
    )
})

export const SharePoster: React.FunctionComponent<SharePosterProps> = ({ onClose, onBack }) => {
    const downItemRef = useRef<HTMLDivElement | null>(null)

    const sharePoster = useAtomData(sharePosterAtom)

    const { run: setSharePoster } = useAtomAction(sharePosterAtom)

    const { shareUrl } = usePageURI({ isCurrentPage: false })
    const app = useApplication()

    const res = useAsyncRetry(() => {
        const { logo } = app.config.navbar
        if (logo.logoType === 1) {
            return urlToBase64(logo.imgUrl.square)
        }
        return Promise.resolve('')
    }, [app.config.navbar])

    const [loading, setLoading] = useState(false)

    const logoParams = useMemo(() => {
        const { logo } = app.config.navbar
        const propsMap: Record<NavigationLogoType, Pick<RoundAvatarProps, 'icon' | 'type' | 'background' | 'objectFit' | 'style'>> = {
            0: { type: 'icon', icon: app.icon, background: 'var(--color-app-main)' },
            1: {
                type: 'img',
                icon: res.value,
                background: 'var(--color-white)',
                objectFit: 'unset',
                style: { border: '12px solid #fff', overflow: 'hidden', width: '100%' }
            }
        }
        return propsMap[logo.logoType]
    }, [app.config.navbar, app.icon, res.value])

    const handleDownload = useCallback(
        async (e: React.MouseEvent<HTMLDivElement>) => {
            if (loading) {
                return
            }
            const activePoster = downItemRef.current
            if (activePoster) {
                try {
                    const canvas = await html2canvas(activePoster, {
                        scale: 2,
                        useCORS: true
                    })
                    const dataURL = canvas.toDataURL('image/png')
                    const downloadLink = document.createElement('a')
                    downloadLink.href = dataURL
                    downloadLink.download = 'poster.png'
                    document.body.append(downloadLink)
                    downloadLink.click()
                    downloadLink.remove()
                } catch (error) {
                    console.error('Error converting DOM to image:', error)
                }
            }
        },
        [loading]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
                setLoading(false)
            }
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [loading])

    return (
        <SharedContainer title="分享海报" subTitle="预览" isUpdateSubName onClose={onClose} onBack={() => onBack?.()}>
            <SCxContent>
                {POSTER_LIST.map((item, i) => (
                    <SCxPosterItem key={item.id}>
                        <SCxPosterItemWrapper
                            className={clsx({ active: sharePoster === item.id })}
                            style={{ transform: 'scale(0.45) translateX(-300px)' }}
                            onClick={() => {
                                setSharePoster(item.id)
                            }}
                        >
                            <Item imgUrl={item.url} shareUrl={shareUrl} name={app.name} subName={app.subName} avatarProps={logoParams} />
                        </SCxPosterItemWrapper>
                    </SCxPosterItem>
                ))}
                <SCxPosterItem style={{ position: 'absolute', zIndex: -2, opacity: 0 }}>
                    <SCxPosterItemWrapper>
                        <Item
                            imgUrl={find(item => item.id === sharePoster, POSTER_LIST)?.url ?? ''}
                            ref={downItemRef}
                            shareUrl={shareUrl}
                            name={app.name}
                            subName={app.subName}
                            avatarProps={logoParams}
                        />
                    </SCxPosterItemWrapper>
                </SCxPosterItem>
            </SCxContent>
            <CM.ToolContainer className="hoverStyle" onClick={handleDownload}>
                <TagIcon
                    icon="DownloadMinimalisticIconLibrary"
                    size={36}
                    iconSize={20}
                    radius={8}
                    iconColor="var(--color-black)"
                    background="rgba(38, 65, 90, 0.06)"
                    style={{ flexShrink: 0 }}
                />
                <Text size={14} lineClamp={1}>
                    下载
                </Text>
                <SCxSuffix>{loading ? <CM.Icon type="Tick" color="#12B76A" /> : <Text>.PNG</Text>}</SCxSuffix>
            </CM.ToolContainer>
        </SharedContainer>
    )
}
