import type { RadioGroupProps } from '@byecode/ui'
import { IconFont, Radio } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
// import BarcodeSvg from '@lighthouse/assets/public/static/blocks/barcode.svg?react'
// import QrcodeSvg from '@lighthouse/assets/public/static/blocks/qrcode.svg?react'
import { QrBarcodeEnum } from '@lighthouse/core'
import React from 'react'
import styled, { css } from 'styled-components'

// import AngledDotsPlusSignRaw from '@lighthouse/assets/public/static/veins/Angled Dots (Plus Sign).svg?raw'
// import LineTabsSvg from './assets/line.svg?react'
// import RoundTabsSvg from './assets/round.svg?react'

const TabsRadioGroup = styled(Radio.Group)`
    justify-content: flex-start;
    gap: 16px;
`

const TabsRadio = styled(Radio).withConfig<{ isChecked: boolean }>({ shouldForwardProp: p => p !== 'isChecked' })`
    flex-direction: column;
    padding: 4px 0px;
    &:hover .byecode-radio-inner {
        color: var(--color-main);
    }

    .byecode-radio-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: ${({ isChecked }) => `1px solid ${isChecked ? 'var(--color-main)' : 'transparent'}`};
        &:checked ~ .byecode-radio-inner {
            color: var(--color-main);
        }
    }
    .byecode-radio-icon {
        display: none;
    }
    .byecode-radio-inner {
        padding: 0;
        border-radius: 4px;
        padding: 7px 17px;
        background-color: unset;
    }

    .byecode-radio-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        ${({ isChecked }) =>
            isChecked &&
            css`
                color: var(--color-main);
                font-weight: 500;
            `}
    }
`

export const CodeTypeSelector = React.forwardRef<HTMLDivElement, RadioGroupProps>((props, ref) => {
    return (
        <TabsRadioGroup ref={ref} {...props}>
            <TabsRadio isChecked={props.value === QrBarcodeEnum.QR_CODE} label="二维码" value={QrBarcodeEnum.QR_CODE}>
                <IconFont type="QRCode" size={36} />
            </TabsRadio>
            <TabsRadio isChecked={props.value === QrBarcodeEnum.BARCODE} label="条形码" value={QrBarcodeEnum.BARCODE}>
                <IconFont type="Barcode" size={36} />
            </TabsRadio>
        </TabsRadioGroup>
    )
})
