import type { Option } from '@byecode/ui'
import { Modal, Select, Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { AggregatorNode } from '@lighthouse/shared'
import { type AggregatorDataInputConfig, AggregatorNodeType, disableJoinDataSourceType } from '@lighthouse/shared'
import type { TabsValue } from '@mantine/core'
import { Tabs } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useStoreApi } from 'reactflow'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import { peekNextNodes } from '../../utils'
import * as SC from '../styles'
import { TabStyles } from '../styles'
import { TablePreview } from '../TablePreview'
import { SelectField } from './SelectField'

interface DataInputProps {
    dsId: string
    nodeId: string
}

export const DataInput: React.FC<DataInputProps> = ({ dsId: currentDsId, nodeId }) => {
    const store = useStoreApi()
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const [tab, setTab] = useState('config')

    const { control, setValue } = useFormContext<AggregatorDataInputConfig>()
    const dsId = useWatch({ control, name: 'dsId' })
    const fieldIds = useWatch({ control, name: 'fieldIds' })
    const dsOptions = useMemo(
        () =>
            dataSourceList.reduce<Option[]>((prev, cur) => {
                if (!disableJoinDataSourceType.has(cur.type)) {
                    prev.push({
                        label: cur.name,
                        value: cur.id
                    })
                }
                return prev
            }, []),
        [dataSourceList]
    )

    const dataSource = useMemo(() => find(item => item.id === dsId, dataSourceList), [dataSourceList, dsId])

    const handleTabChange = useCallback((value: TabsValue) => {
        if (value) {
            setTab(value)
        }
    }, [])

    const handleChange = useCallback(
        async (val: string) => {
            if (val === dsId) {
                return
            }
            if (!dsId) {
                setValue('dsId', val)
                setValue('fieldIds', [])
                return
            }
            const dataSource = find(item => item.id === dsId, dataSourceList)
            if (!dataSource) {
                setValue('dsId', val)
                setValue('fieldIds', [])
                return
            }
            const state = store.getState()
            const nodes = state.getNodes() as AggregatorNode[]
            const { edges } = state
            const node = find(item => item.id === nodeId, nodes)
            if (!node) {
                return
            }
            const { nodes: nextNodes } = peekNextNodes(node, nodes, edges)
            if (nextNodes.length === 0 || nextNodes[0].type === AggregatorNodeType.ADD_PLACEHOLDER) {
                setValue('dsId', val)
                setValue('fieldIds', [])
                return
            }
            const isConfirm = await Modal.confirm({
                title: '确认修改',
                content: '此操作会同步更改后续节点的配置，无法还原，请谨慎操作！',
                okStatus: 'error',
                okText: <div>确定</div>
            })
            if (isConfirm) {
                setValue('dsId', val)
                setValue('fieldIds', [])
            }
        },
        [dataSourceList, dsId, nodeId, setValue, store]
    )

    const isEmptyFieldList = useMemo(() => {
        return !fieldIds || fieldIds.length === 0
    }, [fieldIds])

    const previewContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 202 }} src={getAssetUrl('empty', 'no_data_imperfect.png')} alt="未找到数据" />}
                    description="预览数据前，请先完成节点配置"
                />
            )
        }
        return <TablePreview appId={appId} envId={envId} dsId={currentDsId} nodeId={nodeId} active={tab === 'preview'} />
    }, [appId, currentDsId, envId, isEmptyFieldList, nodeId, tab])

    return (
        <Tabs color="var(--color-main)" value={tab} onTabChange={handleTabChange} styles={TabStyles}>
            <Tabs.List>
                <Tabs.Tab value="config">
                    <SC.TabText isActive={tab === 'config'}>节点配置</SC.TabText>
                </Tabs.Tab>
                <Tabs.Tab value="preview" color="blue">
                    <SC.TabText isActive={tab === 'preview'}>
                        数据预览
                        <SC.TabTooltip>
                            <Tooltip title="预览前20条数据">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </SC.TabTooltip>
                    </SC.TabText>
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="config" pt="xs">
                <SC.PanelContent>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="dsId"
                                control={control}
                                render={({ field }) => <Select options={dsOptions} value={field.value} onChange={handleChange} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {dataSource && (
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel>选择字段</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="fieldIds"
                                    control={control}
                                    render={({ field }) => <SelectField dataSource={dataSource} {...field} />}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    )}
                </SC.PanelContent>
            </Tabs.Panel>

            <Tabs.Panel value="preview" pt="xs">
                <SC.PreviewContent>{previewContent}</SC.PreviewContent>
            </Tabs.Panel>
        </Tabs>
    )
}
