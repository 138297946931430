import { Breadcrumbs, Flex, Group, IconFont, Menu, Modal, Select, Tooltip } from '@byecode/ui'
import type { ViewOptions } from '@lighthouse/core'
import { PageType, RecordOpenType } from '@lighthouse/core'
import { ListItem4ByecodeUi, SelectPage, useAtomAction, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { nanoid, stopPropagation } from '@lighthouse/tools'
import { Divider } from '@mantine/core'
import { useAtomCallback } from 'jotai/utils'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'

import { breadcrumbsHistoryAtom } from '@/atoms/application/state'
import { clonePageAtom, createPageAtom, openPageStackAtom, removePageAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageListAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { useRootPageContext } from '@/contexts/PageContext'
import { useAppLanguageList, useCurrentAppID, useLanguage } from '@/hooks/useApplication'
import { useBreadcrumbsHistoryBackEffect } from '@/hooks/useBreadcrumbs'
import { usePageList } from '@/hooks/usePage'

import { InnerDrawer } from '../../InnerDrawer'
import FormSegmentedControl from '../FormSegmentedControl'
import { listCommonStyles, OpenTypeData } from './constants'
import { StyledIconWrapper, StyledOptionButton } from './styles'

interface RecordViewableProps {
    id: string
}

export const RecordViewable: FC<RecordViewableProps> = props => {
    const { getValues } = useFormContext<ViewOptions>()

    const [canViewRecord, page] = getValues(['canViewRecord', 'viewingConfig.page'])

    const [open, toggle] = useToggle(false)

    useBreadcrumbsHistoryBackEffect(page || '', () => {
        toggle(true)
    })

    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <StyledIconWrapper active={canViewRecord}>
                        <IconFont type="Open" size={18} fill={canViewRecord ? 'var(--color-main)' : 'var(--color-gray-500)'} />
                    </StyledIconWrapper>
                    <div>展开记录</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canViewRecord ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && <ViewRecordConfigure {...props} onGoBack={() => toggle(false)} />}
        </>
    )
}

interface RecordConfigureProps {
    id: string
    onGoBack: () => void
}

const ViewRecordConfigure = ({ id: viewId, onGoBack }: RecordConfigureProps) => {
    const appId = useCurrentAppID()

    const { control, setValue, getValues } = useFormContext<ViewOptions>()

    const [dsId, canViewRecord, selectedPage, openType] = getValues([
        'pointer',
        'canViewRecord',
        'viewingConfig.page',
        'viewingConfig.openType'
    ])

    const pageList = usePageList()
    const language = useLanguage()
    const languageList = useAppLanguageList()

    // 校验选择的页面是否有效
    useEffect(() => {
        if (!canViewRecord) {
            return
        }
        if (selectedPage) {
            const isExist = pageList.some(p => p.id === selectedPage && p.type === PageType.document)
            // 已过期的配置
            if (!isExist) {
                setPageDefaultValue()
            }
        } else {
            setPageDefaultValue()
        }

        function setPageDefaultValue() {
            const firstBelongToCurrentDsPage = pageList.find(p => p.appId === appId && p.dsId === dsId && p.type === PageType.document)
            setValue('viewingConfig.page', firstBelongToCurrentDsPage?.id ?? '')
        }
    }, [appId, canViewRecord, dsId, pageList, selectedPage, setValue])

    const { run: setHistory } = useAtomAction(breadcrumbsHistoryAtom)

    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const { pageId = '', state } = lastPageMeta ?? {}
    const { selectedNode } = state ?? {}

    const { run: createPage, loading: createLoading } = useAtomAsyncAction(createPageAtom)
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: removePage, loading: deleteLoading } = useAtomAsyncAction(removePageAtom)
    const { run: copyPage } = useAtomAsyncAction(clonePageAtom)

    const rootPageId = useRootPageContext()

    const handleRecordAdd = useCallback(
        (pageId: string) => {
            openPageStack(
                stackFactory({
                    pageId,
                    rootPageId,
                    stackDisplayType: openType,
                    appId,
                    dsId,
                    viewId
                })
            )
        },
        [appId, dsId, openType, openPageStack, rootPageId, viewId]
    )

    /** 创建页面 */
    const onCreatePage = useAtomCallback(async get => {
        if (!viewId || createLoading) {
            return
        }
        const pageID = await createPage({ dsId, createMode: 'CAN_OPEN_RECORD', viewId })
        setValue('viewingConfig.page', pageID)

        if (openType !== RecordOpenType.page) {
            setHistory(s => {
                s.push({
                    from: { page: pageId, block: selectedNode },
                    to: { page: pageID, block: undefined },
                    restored: false
                })
            })
        }

        handleRecordAdd(pageID)
    })

    /** 复制页面 */
    const onCopyPage = useCallback(
        async (id: string) => {
            const pageId = await copyPage({ id, lang: language })
            handleRecordAdd(pageId)
        },
        [copyPage, handleRecordAdd, language]
    )

    /** 删除页面 */
    const onDeletePage = useCallback(
        async (id: string) => {
            if (deleteLoading) {
                return
            }
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: '删除后,可能导致使用该表单的地方出现异常',
                okStatus: 'error'
            })
            if (isConfirm) {
                const res = await removePage(id)
                if (res && id === selectedPage) {
                    setValue('viewingConfig.page', '')
                }
            }
        },
        [deleteLoading, removePage, selectedPage, setValue]
    )

    /** 编辑详情页 */
    const onEditPage = useCallback(
        (id: string) => {
            if (openType !== RecordOpenType.page) {
                setHistory(s => {
                    s.push({
                        from: { page: pageId, block: selectedNode },
                        to: { page: id, block: undefined },
                        restored: false
                    })
                })
            }
            handleRecordAdd(id)
        },
        [handleRecordAdd, openType, pageId, selectedNode, setHistory]
    )

    const viewsPageListOptions = useMemo(() => {
        return pageList
            .filter(item => item.appId === appId && item.dsId === dsId && item.type === PageType.document)
            .map(item => ({
                label: item.name,
                value: item.id,
                lang: item.language,
                icon: <IconFont type="DataDetailPage" />,
                extra: (
                    <Flex gap="4px" onMouseDown={stopPropagation}>
                        <Tooltip title="编辑" placement="top">
                            <StyledOptionButton onClick={() => onEditPage(item.id)}>
                                <IconFont type="PencilSimple" />
                            </StyledOptionButton>
                        </Tooltip>
                        <Menu width={200}>
                            <Tooltip title="更多操作" placement="top">
                                <Menu.Target>
                                    <StyledOptionButton>
                                        <IconFont type="DotsThree" />
                                    </StyledOptionButton>
                                </Menu.Target>
                            </Tooltip>

                            <Menu.Dropdown>
                                <Menu.Item
                                    icon={<IconFont fill="var(--color-gray-400)" type="Duplicate" />}
                                    onClick={() => onCopyPage(item.id)}
                                >
                                    复制
                                </Menu.Item>
                                <Menu.Item
                                    icon={<IconFont fill="var(--color-gray-400)" type="Trash" />}
                                    onClick={() => {
                                        onDeletePage(item.id)
                                    }}
                                >
                                    删除
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                )
            }))
    }, [appId, dsId, onCopyPage, onDeletePage, onEditPage, pageList])

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '展开记录', onClick: onGoBack }]} />

            <Divider color="var(--color-gray-200)" />

            <Controller
                control={control}
                name="canViewRecord"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="展开记录"
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)

                            const selectedPage = getValues('viewingConfig.page')
                            if (selectedPage && pageList.some(p => p.id === selectedPage && p.type === PageType.document)) {
                                return
                            }

                            const firstBelongToCurrentDsPage = pageList.find(
                                p => p.appId === appId && p.dsId === dsId && p.type === PageType.document
                            )
                            setValue('viewingConfig.page', firstBelongToCurrentDsPage?.id ?? '')
                        }}
                        // switchProps={{
                        //     disabled: !dataSource || dataSource.type === 2
                        // }}
                    >
                        <ListItem4ByecodeUi gap="8px" justifyContent="space-between" alignItems="center">
                            <div>选择一个详情页</div>
                            <Controller
                                control={control}
                                name="viewingConfig.page"
                                render={({ field }) => (
                                    <SelectPage
                                        {...field}
                                        searchable
                                        style={{ width: 180 }}
                                        prefix={
                                            <div style={{ boxShadow: 'var(--box-shadow)' }}>
                                                <IconFont type="DataDetailPage" />
                                            </div>
                                        }
                                        languageList={languageList}
                                        options={viewsPageListOptions}
                                        dropdownProps={{
                                            extra: (
                                                <>
                                                    <Divider my={8} color="var(--color-gray-200)" />
                                                    <ListItem4ByecodeUi
                                                        style={{
                                                            color: 'var(--color-main)',
                                                            minHeight: 36,
                                                            padding: '0 16px',
                                                            borderRadius: 'unset'
                                                        }}
                                                        gap="8px"
                                                        alignItems="center"
                                                        enableHover
                                                        onClick={onCreatePage}
                                                    >
                                                        <IconFont type="Add" />
                                                        新的记录页面
                                                    </ListItem4ByecodeUi>
                                                </>
                                            ),
                                            styles: {
                                                item: {
                                                    height: 32,
                                                    padding: '0 16px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    overflow: 'hidden',
                                                    '>div': {
                                                        width: '100%'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                            {/* {selectedPage && (
                                <Button
                                    style={{ height: 36, whiteSpace: 'nowrap' }}
                                    icon={<IconFont type="edit" />}
                                    onClick={() => {
                                        const hasExist = viewsPageListOptions.some(item => item.value === selectedPage)
                                        if (hasExist) {
                                            onEditPage(selectedPage)
                                        }
                                    }}
                                >
                                    编辑详情页
                                </Button>
                            )} */}
                        </ListItem4ByecodeUi>

                        <Controller
                            control={control}
                            name="viewingConfig.openType"
                            render={({ field }) => <FormSegmentedControl label="打开方式" data={OpenTypeData} {...field} />}
                        />
                    </Group>
                )}
            />
        </InnerDrawer>
    )
}
