import type { FieldInputType, FieldType } from '@lighthouse/core'
import {
    datasourceFieldTypes,
    editableUserFieldId,
    fieldSettingMap,
    formSettingMap,
    getIsAppointField,
    SYSTEM_FIELD
} from '@lighthouse/shared'
import { find } from 'rambda'

import type { DataMode, FieldOptions } from './types'

export const getSaveFieldOptions = function (
    inputType: FieldInputType,
    fieldOptions: FieldOptions,
    mode: DataMode
): [FieldOptions, FieldType[]] {
    const inputTypeMap = mode === 'field' ? fieldSettingMap[inputType] : formSettingMap[inputType]
    return [
        fieldOptions.filter(item => {
            if (mode === 'field') {
                if (getIsAppointField(item.value, new Set(['ID']))) {
                    return false
                }

                return inputTypeMap.includes(item.type)
            }
            const isCanUsedField = inputTypeMap.includes(item.type)
            if (getIsAppointField(item.value, SYSTEM_FIELD)) {
                return editableUserFieldId.has(item.value) && isCanUsedField
            }
            return isCanUsedField
        }),
        datasourceFieldTypes.filter(item => !inputTypeMap.includes(item))
    ]
}

export const isDisabledFieldInput = function (fieldPointer: string, fieldOptions: FieldOptions) {
    const fieldItem = find(item => item.value === fieldPointer, fieldOptions)
    if (getIsAppointField(fieldItem?.value ?? '', SYSTEM_FIELD)) {
        return !editableUserFieldId.has(fieldPointer)
    }
    return fieldItem?.type === 'formula'
}
