import { Flex, IconFont, Toast, Tooltip } from '@byecode/ui'
import { autoUpdate, flip, limitShift, offset, shift, useFloating } from '@floating-ui/react'
import { mergeRefs } from '@lighthouse/tools'
import { showNotification } from '@mantine/notifications'
import React from 'react'
import { useToggle } from 'react-use'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'

import { useUser } from '@/hooks/useUser'
import { VIKA_API_PREFIX, VIKA_API_TOKEN } from '@/utils/auth'

import { FeedbackForm } from './Form'

const FloatButton = styled.button`
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    z-index: 999;
    right: 20px;
    bottom: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 8px;
    color: var(--color-white);
    background-color: var(--color-black);
`

const OptionItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 16px;
    height: 36px;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-gray-900);
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const CloseIcon = styled(IconFont)`
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--color-gray-100);
    &:hover {
        background-color: var(--color-gray-200);
    }
`

const PopoverContainer = styled.div`
    z-index: 200;
    background: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.1);
`

export const Feedback = () => {
    const [optionOpen, toggleOptionOpen] = useToggle(false)
    const [formOpen, toggleFormOpen] = useToggle(false)

    const user = useUser()

    const { trigger, isMutating } = useSWRMutation('/api/feedback', async (_, { arg }) => {
        const res = await fetch(`${VIKA_API_PREFIX}/records`, {
            body: JSON.stringify({
                records: [
                    {
                        fields: { ...arg, 用户: user.username }
                    }
                ]
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${VIKA_API_TOKEN}`
            }
        }).then(res => res.json())

        if (res && res.success) {
            showNotification({
                styles: {
                    icon: {
                        width: 'auto',
                        height: 'auto',
                        backgroundColor: 'transparent !important'
                    }
                },
                icon: (
                    <div style={{ backgroundColor: 'var(--color-green-50)', padding: 8, borderRadius: 8 }}>
                        <IconFont type="Tick" size={20} color="var(--color-green-500)" style={{ verticalAlign: 'middle' }} />
                    </div>
                ),
                title: '提交成功',
                message: '再次感谢您的支持与反馈！'
            })
            toggleFormOpen(false)
        } else {
            Toast.error('提交失败')
        }
    })

    const optionFloating = useFloating({
        strategy: 'fixed',
        placement: 'top-end',

        middleware: [flip({ fallbackPlacements: ['left-start'] }), offset(8), shift({ limiter: limitShift() })],
        whileElementsMounted: autoUpdate
    })

    const formFloating = useFloating({
        strategy: 'fixed',
        placement: 'top-end',
        middleware: [flip({ fallbackPlacements: ['left-start'] }), offset(8), shift({ limiter: limitShift() })],
        whileElementsMounted: autoUpdate
    })

    return (
        <>
            <Tooltip
                title="帮助与资源"
                placement="left"
                ref={mergeRefs([optionFloating.refs.setReference, formFloating.refs.setReference])}
            >
                <FloatButton
                    onClick={() => {
                        if (formOpen) {
                            toggleFormOpen()
                        } else {
                            toggleOptionOpen()
                        }
                    }}
                >
                    <IconFont type="QuestionMark" size={28} />
                </FloatButton>
            </Tooltip>

            {optionOpen && (
                <PopoverContainer style={{ width: 180, ...optionFloating.floatingStyles }} ref={optionFloating.refs.setFloating}>
                    <OptionItem
                        onClick={() => {
                            toggleFormOpen()
                            toggleOptionOpen()
                        }}
                    >
                        <IconFont type="ChatRoundLine" size={16} fill="var(--color-gray-500)" />
                        <text>反馈意见</text>
                    </OptionItem>
                    <OptionItem
                        onClick={() => {
                            toggleOptionOpen()
                        }}
                        as="a"
                        href="https://www.byecode.com/P/31d787f432af4e52a0481f3186472a6e/a/e6406c8c749745eda2d2bc35330d0487"
                        target="_blank"
                    >
                        <IconFont type="HandShake" size={16} fill="var(--color-gray-500)" />
                        <text>企业咨询</text>
                    </OptionItem>
                    <OptionItem
                        onClick={() => {
                            toggleOptionOpen()
                        }}
                        as="a"
                        href="https://www.byecode.com/P/3beb6bb00cb940979be5ede0a219741d/a/e6406c8c749745eda2d2bc35330d0487"
                        target="_blank"
                    >
                        <IconFont type="Hearts" size={16} fill="var(--color-gray-500)" />
                        <text>渠道合作</text>
                    </OptionItem>
                    <OptionItem
                        onClick={() => {
                            toggleOptionOpen()
                        }}
                        as="a"
                        href="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich?#"
                        target="_blank"
                    >
                        <IconFont type="QuestionCircle" size={16} fill="var(--color-gray-500)" />
                        <text>帮助中心</text>
                    </OptionItem>
                    <OptionItem
                        onClick={() => {
                            toggleOptionOpen()
                        }}
                        as="a"
                        href="https://www.bilibili.com/video/BV1g9gsegEjN/?spm_id_from=333.999.0.0&vd_source=9e54b013d46831e69a759a906ba5cdff"
                        target="_blank"
                        // onClick={() => {
                        //     setAppGuideStep('video')
                        //     toggleOptionOpen()
                        // }}
                        // target="_blank"
                    >
                        <IconFont type="BlockVideo" size={16} fill="var(--color-gray-500)" />
                        <text>新手视频</text>
                    </OptionItem>
                </PopoverContainer>
            )}

            {formOpen && (
                <PopoverContainer style={{ width: 496, ...formFloating.floatingStyles }} ref={formFloating.refs.setFloating}>
                    <Flex justifyContent="flex-end" p={12}>
                        <CloseIcon type="Close" onClick={() => toggleFormOpen(false)} />
                    </Flex>

                    <FeedbackForm loading={isMutating} onCancel={() => toggleFormOpen(false)} onSubmit={trigger} />
                </PopoverContainer>
            )}
        </>
    )
}
