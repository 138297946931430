import { Box, Group, Title } from '@mantine/core'
import React from 'react'

export interface UnitProps {
    title?: string
    children?: React.ReactNode
}

export const Unit: React.FC<UnitProps> = ({ title = '基础', children }) => {
    return (
        <Box>
            <Title order={3} sx={{ margin: '8px 0' }}>
                {title}
            </Title>
            <Group>{children}</Group>
        </Box>
    )
}
