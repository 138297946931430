import { Flex, Select } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const Colors: React.FC<{ colors?: string[] }> = ({ colors }) => (
    <Flex>
        {colors?.map(color => (
            <Flex key={color} style={{ width: 16, height: 16, backgroundColor: color }} />
        ))}
    </Flex>
)

export const Extra: React.FC = () => {
    return (
        <Unit title="额外">
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man', extra: <Colors colors={['#5B1647', '#93073E', '#C90035', '#FF5627']} /> },
                    { label: '女', value: 'woman', extra: <Colors colors={['#071a52', '#086972', '#17b978', '#a7ff83']} /> }
                ]}
            />
        </Unit>
    )
}
