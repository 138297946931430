import { BlockThumbImageMap, BlockTypeIconMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

export const tabsBlock: BlockTreeItem = {
    name: '标签栏',
    icon: BlockTypeIconMap.tabs,
    describeImage: BlockThumbImageMap['tabs'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.tabs
}
