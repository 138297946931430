import { Group, Input, SegmentedControl, Switch } from '@byecode/ui'
import type { DataSourceAbstract, ViewType } from '@lighthouse/core'
import { emphasizeFieldTypes, getFieldIcon, getViewColumns, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { FieldSelect } from '@/components/FieldSelect'

export interface TableBlockStyleSettingsProps {
    viewType: ViewType
    dataSource?: DataSourceAbstract
}

const SCxSquare = styled.div`
    width: 13px;
    height: 11px;
    background-color: var(--color-gray-400);
    border-radius: 2px;
`

const SCxRound = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: var(--color-gray-400);
`

const tableDesignStyleOptions = [
    { label: '经典', value: 'classic' },
    { label: '简约', value: 'simple' },
    { label: '斑马纹', value: 'stripe' }
]
const listDesignStyleOptions = [
    { label: '经典', value: 'classic' },
    { label: '实用', value: 'stripe' },
    { label: '简约', value: 'simple' }
]

const listDesignTypeOptions = [
    { label: '默认', value: 'default' },
    { label: '卡片', value: 'card' },
    { label: '色块', value: 'colorBlock' }
]

const avatarTypeOptions = [
    { label: <SCxSquare />, value: 'square' },
    { label: <SCxRound />, value: 'round' }
]

export const TableBlockStyleSettings: React.FC<TableBlockStyleSettingsProps> = ({ viewType, dataSource }) => {
    const { control, watch, register, getValues, setValue } = useFormContext()
    const showTitle = watch('showTitle')
    // const designStyle = watch('designStyle')
    // const isShowListAvatar = watch('isShowListAvatar')
    const isHiddenTable = viewType === 'table'
    const isDesignStyle = viewType === 'table' || viewType === 'list'
    const isDesignType = viewType === 'list'
    const isEmphasizeText = viewType === 'list'
    const { schema = {}, viewOptions: { tableProps = [] } = {} } = dataSource ?? {}

    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps,
                schema
            }),
        [schema, tableProps]
    )

    // 附件字段
    const fileFieldOptions = useMemo(() => {
        return columns
            .filter(item => item.type === 'file')
            .map(item => ({
                label: item.title,
                value: item.fieldId,
                icon: getFieldIcon(item.fieldId, item.type, item.innerType),
                type: item.type,
                innerType: item.innerType
            }))
    }, [columns])

    /**
     * 开启头像时，自动选择附件字段
     * @date 7/4/2023 - 6:06:42 PM
     * @param {boolean} bool 是否开启封面
     * @type {*}
     */
    const onToggleListAvatar = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const avatarField = getValues('listAvatar')
            if (avatarField) {
                return
            }

            const fileField = fileFieldOptions[0]
            if (!fileField) {
                return
            }

            setValue('listAvatar', fileField.value)
        },
        [getValues, setValue, fileFieldOptions]
    )

    // 文本字段
    const textFieldOptions = useMemo(() => {
        return columns
            .filter(item => {
                if (!item.innerType) {
                    return false
                }
                // const type = innerTypeToFieldType[item.innerType]
                // if (!type) {
                //     return false
                // }
                return emphasizeFieldTypes.has(item.type)
            })
            .map(item => ({
                label: item.title,
                value: item.fieldId,
                icon: getFieldIcon(item.fieldId, item.type, item.innerType),
                type: item.type,
                innerType: item.innerType
            }))
    }, [columns])

    return (
        <>
            <Group label="基础">
                {isDesignStyle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>风格</Text>
                        <Controller
                            control={control}
                            name="designStyle"
                            render={({ field: { value, onChange } }) => {
                                const options = viewType === 'table' ? tableDesignStyleOptions : listDesignStyleOptions
                                return (
                                    <SegmentedControl
                                        fullWidth
                                        style={{ width: 180 }}
                                        size="md"
                                        value={value}
                                        onChange={onChange}
                                        data={options}
                                    />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}

                {isDesignType && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>条目类型</Text>
                        <Controller
                            control={control}
                            name="designType"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <SegmentedControl
                                        fullWidth
                                        style={{ width: 180 }}
                                        size="md"
                                        defaultValue="default"
                                        value={value}
                                        onChange={onChange}
                                        data={listDesignTypeOptions}
                                    />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}

                {isHiddenTable && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>隐藏表头</Text>
                        <Controller
                            control={control}
                            name="hiddenHeader"
                            render={({ field: { value, onChange, ...restField } }) => (
                                <Switch checked={value} onChange={e => onChange(e.target.checked)} {...restField} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                )}
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示视图标题</Text>
                    <Controller
                        control={control}
                        name="showTitle"
                        render={({ field: { value, onChange, ...restField } }) => (
                            <Switch checked={value} onChange={e => onChange(e.target.checked)} {...restField} />
                        )}
                    />
                </ListItem4ByecodeUi>
                {showTitle ? (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>视图标题</Text>
                        <Input style={{ width: 180 }} {...register('title')} />
                    </ListItem4ByecodeUi>
                ) : null}
            </Group>

            {isDesignStyle && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Controller
                        control={control}
                        name="isShowListAvatar"
                        render={({ field: { value, onChange } }) => (
                            <Group
                                mode="switch"
                                label="头像"
                                onCollapseChange={val => {
                                    onChange(val)
                                    onToggleListAvatar(val)
                                }}
                                opened={!!value}
                            >
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>选择字段</Text>
                                    <Controller
                                        control={control}
                                        name="listAvatar"
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <FieldSelect
                                                    styles={{ root: { width: 180 } }}
                                                    value={value}
                                                    dsId={dataSource?.id}
                                                    onChange={onChange}
                                                    options={fileFieldOptions}
                                                />
                                            )
                                        }}
                                    />
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>头像类型</Text>
                                    <Controller
                                        control={control}
                                        name="avatarType"
                                        render={({ field: { value, onChange } }) => (
                                            <SegmentedControl
                                                fullWidth
                                                style={{ width: 180 }}
                                                value={value}
                                                onChange={onChange}
                                                data={avatarTypeOptions}
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            </Group>
                        )}
                    />
                    {isEmphasizeText && (
                        <>
                            <Divider color="var(--color-gray-200)" />
                            <Controller
                                control={control}
                                name="isShowEmphasizeText"
                                render={({ field: { value, onChange } }) => (
                                    <Group label="强调文本" onCollapseChange={onChange} opened={!!value} mode="switch">
                                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                            <Text>选择字段</Text>
                                            <Controller
                                                control={control}
                                                name="emphasizeTextField"
                                                render={({ field: { value, onChange } }) => (
                                                    <FieldSelect
                                                        styles={{ root: { width: 180 } }}
                                                        value={value}
                                                        onChange={onChange}
                                                        options={textFieldOptions}
                                                    />
                                                )}
                                            />
                                        </ListItem4ByecodeUi>
                                    </Group>
                                )}
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}
