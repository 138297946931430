import type { Option } from '@byecode/ui'
import { Divider, Empty, Select } from '@byecode/ui'
import type { VariableSource } from '@lighthouse/shared'
import {
    type FlowNode,
    BasicListItem,
    CollapseBox,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    NodeFieldPreview,
    pureTextFieldTypes,
    useAtomData,
    USER_DATASOURCE,
    VariableSourceType
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { AddIClickAccount, IClickAccountModal } from '@/components/OtherPlatFormModal'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { useOtherPlatformList } from '@/shared/reusable'

import { GroupRequired } from '../Common/GroupRequired'
import * as CM from '../styles'

interface IclickContactActionConfigureProps {
    allParentNodes?: FlowNode[]
    prefixName?: string
    actionTypeSwitcher?: React.ReactNode
}

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 8px 0;
`

const SCxRightFill = styled.div`
    width: 100%;
    margin-top: 12px;

    & div[data-node-view-wrapper] {
        top: 0 !important;
    }
`
const SCxSelectContainer = styled.div`
    width: 180px;

    .selectInput {
        color: var(--color-orange-500);
        iconpark-icon {
            color: var(--color-orange-500);
        }
    }
`

const SCxItemRightFill = styled.div`
    display: none;
    align-items: center;
    justify-content: flex-end;
`

const SCxText = styled.div`
    line-height: 22px;
    font-size: var(--font-size-normal);
`

interface State {
    mode: 'add' | 'update'
    opened: boolean
    id?: string
}

export const IclickContactActionConfigure: React.FunctionComponent<IclickContactActionConfigureProps> = ({
    actionTypeSwitcher,
    prefixName = 'config',
    allParentNodes
}) => {
    const { control, watch } = useFormContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: otherPlatform, update } = useOtherPlatformList()
    const { isForm, dsId, viewType } = useActionAdderDepParams()
    const disabledWithVersion = useIsDisabledWithVersion()
    const configIClickId = watch(`${prefixName}.iClickId`)

    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })
    const dataSourceList = useDataSourceList(appId, envId)
    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => pureTextFieldTypes.has(field.type)
        })
    }, [dataSourceList])

    const dataSource = useDataSource(appId, envId, dsId)

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const { type } = useFlow()
    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const formOption = useMemo(
        () =>
            isForm && !isInFlow
                ? getFormDatasourceOptions({ dataSource, validateFieldType: field => pureTextFieldTypes.has(field.type) })
                : undefined,
        [dataSource, isInFlow, isForm]
    )

    const handleUpdate = useCallback(
        (value: string) => {
            setState({ opened: true, mode: 'update', id: value })
        },
        [setState]
    )

    const handleAdd = useCallback(() => {
        setState({ opened: true, mode: 'add', id: undefined })
    }, [setState])

    const options = useMemo(() => {
        // if (!dataSource) {
        //     return []
        // }

        return getDefaultValueOptions({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    dataSourceList,
                    parentNodes: allParentNodes && getAllCanBeUpstreamNodes(allParentNodes)
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateFieldType: type => pureTextFieldTypes.has(type)
        })
    }, [allParentNodes, curr.datasource, curr.page, dataSourceList, isInFlow, isNotAutomation, prev.datasource, prev.page])

    const { customViewOption } = useVariableCustomViewOption(field => pureTextFieldTypes.has(field.type))

    const viewOption = useMemo(() => {
        if (isInFlow) {
            return
        }
        return customViewOption || getViewOptions({ dataSource, viewType, validateFieldType: field => pureTextFieldTypes.has(field.type) })
    }, [customViewOption, viewType, isInFlow, dataSource])

    const iClickOptions: Option[] = useMemo(
        () =>
            (otherPlatform?.filter(item => item.type === 'ICLICK') ?? []).map((item, index) => ({
                label: item.name,
                value: item.id,
                icon: 'BrandLogoIClick',
                extra: (
                    <SCxItemRightFill
                        onMouseDown={ev => {
                            ev.stopPropagation()
                            handleUpdate(item.id)
                        }}
                        className="itemEdit"
                    >
                        <CM.Icon color="var(--color-gray-400)" type="PencilSimple" />
                    </SCxItemRightFill>
                )
            })),
        [handleUpdate, otherPlatform]
    )

    return (
        <>
            <CollapseBox label="动作配置" required>
                {actionTypeSwitcher && (
                    <BasicListItem disablePadding style={{ justifyContent: 'space-between', padding: '0 16px' }}>
                        <SCxText>类型</SCxText>
                        {actionTypeSwitcher}
                    </BasicListItem>
                )}
                <BasicListItem style={{ justifyContent: 'space-between' }}>
                    <SCxText>选择账号</SCxText>
                    <Controller
                        control={control}
                        name={`${prefixName}.iClickId`}
                        render={({ field }) => (
                            <SCxSelectContainer>
                                <Select
                                    hiddenEmpty
                                    placeholder="请选择"
                                    className="selectInput"
                                    styles={{
                                        item: {
                                            '&:hover .itemEdit': {
                                                display: 'flex'
                                            }
                                        },
                                        icon: {
                                            color: 'var(--color-orange-500)'
                                        },
                                        root: {
                                            color: 'var(--color-orange-500)'
                                        }
                                    }}
                                    options={iClickOptions}
                                    {...field}
                                    dropdownProps={{
                                        extra: (
                                            <>
                                                {iClickOptions.length === 0 && (
                                                    <Empty
                                                        icon="BrandLogoIClick"
                                                        styles={{
                                                            root: {
                                                                minHeight: '75px'
                                                            }
                                                        }}
                                                        description="您还没添加 iClick 账号"
                                                    />
                                                )}
                                                <Divider color="var(--color-gray-200)" />
                                                <AddIClickAccount onClick={() => handleAdd()} />
                                            </>
                                        )
                                    }}
                                />
                            </SCxSelectContainer>
                        )}
                    />
                </BasicListItem>
            </CollapseBox>
            {configIClickId && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <GroupRequired
                        required
                        label="设置联系人内容"
                        styles={{
                            root: {
                                padding: 0,
                                marginBottom: 20
                            }
                        }}
                    >
                        <SCxItem>
                            <SCxText>姓名</SCxText>
                            <SCxRightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.name`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <NodeFieldPreview
                                                disabled={disabledWithVersion}
                                                placeholder="请输入"
                                                value={value}
                                                onChange={onChange}
                                                autoHeight
                                                userOption={userOption}
                                                options={options}
                                                formOption={formOption}
                                                viewOption={viewOption}
                                            />
                                        )
                                    }}
                                />
                            </SCxRightFill>
                        </SCxItem>
                        <SCxItem>
                            <SCxText>手机号</SCxText>
                            <SCxRightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.mobile`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <NodeFieldPreview
                                                placeholder="请输入"
                                                value={value}
                                                onChange={onChange}
                                                autoHeight
                                                userOption={userOption}
                                                options={options}
                                                formOption={formOption}
                                                viewOption={viewOption}
                                            />
                                        )
                                    }}
                                />
                            </SCxRightFill>
                        </SCxItem>
                        <SCxItem>
                            <SCxText>邮箱</SCxText>
                            <SCxRightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}.email`}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <NodeFieldPreview
                                                placeholder="请选择"
                                                value={value}
                                                onChange={onChange}
                                                autoHeight
                                                userOption={userOption}
                                                options={options}
                                                formOption={formOption}
                                                viewOption={viewOption}
                                            />
                                        )
                                    }}
                                />
                            </SCxRightFill>
                        </SCxItem>
                    </GroupRequired>
                </>
            )}

            <IClickAccountModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
        </>
    )
}
