import { IconFont, itemBackgroundTransitionOnClick } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const FlowListWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    /* padding: 32px; */
    background-color: var(--color-gray-50);
`

export const FlowListTabWrapper = styled.div`
    padding: 16px;
    background-color: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
`

export const FlowAdderWrapper = styled.div``

export const FlowAdderButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    border-radius: 6px;
    padding: 8px 8px 8px 16px;
    font-size: var(--font-size-normal);
    color: var(--color-white);
    background-color: var(--color-main);
    cursor: pointer;
`

export const FlowAdderButtonText = styled.div`
    margin: 0 4px;
`

export const FlowAdderDropdown = styled.div``

export const FlowAdderDropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    line-height: 1;
    cursor: pointer;
    font-size: var(--font-size-normal);

    ${itemBackgroundTransitionOnClick}
`

export const FlowAdderDropdownItemIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin-right: 8px;
`

export const FlowAdderDropdownItemIcon = styled(IconFont)`
    color: var(--color-white);
    border-radius: 6px;
`

export const FlowListTabIcon = styled(IconFont)`
    margin-right: 8px;
    font-size: var(--font-size-base);
`

export const FlowListTab = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    width: 220px;
    border-radius: 6px;
    padding: 10px 8px 10px 16px;
    margin-bottom: 12px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }

    ${({ active }) =>
        active &&
        css`
            font-weight: var(--font-weight-bold);
            background-color: var(--color-gray-100);
            color: var(--color-gray-700);
        `}
`

export const FlowListRightContent = styled.div`
    flex: 1;
    padding: 0 24px;
    overflow-y: auto;
`
