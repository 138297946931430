import { flex, pointer } from '@byecode/ui'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { blankTemplateAIId, blankTemplateExcelId, blankTemplateWebsiteId } from '../constant'
import * as CM from '../styles'

interface TemplateImageProps {
    value: string
    isBlankTemplate?: boolean
    active?: boolean
    backgroundColor?: string
    id?: string
    ratio?: number
    style?: React.CSSProperties
}

const SCXWrapper = styled.div<{ active?: boolean; ratio: number }>`
    position: relative;
    width: 100%;
    border: 2px solid ${({ active }) => (active ? 'var(--color-main)' : 'transparent')};
    border-radius: 10px;

    &::after {
        content: '';
        display: block;
        padding-top: ${({ ratio }) => ratio * 100}%;
    }
`

const SCXImageContent = styled.div<{ active?: boolean; backgroundColor: string }>`
    position: absolute;
    inset: 0;
    /* padding: 7.5% 6% 0 6%; */
    /* padding: 16px 16px 0 16px; */
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
    /* border: 2px solid ${({ active }) => (active ? 'var(--color-black)' : 'transparent')}; */
    background: ${({ backgroundColor }) => `${backgroundColor}`};
    box-sizing: content-box;
    ${pointer}
`

const SCXDescribeImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
`

const SCXDescribeImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: var(--color-white);
    border-radius: 4px 4px 0px 0px;
`

const SCXBlankDescribeWrapper = styled.div<{ device?: 'website' | 'mobile' }>`
    ${flex}
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: ${({ device }) => (device === 'mobile' ? '33.8%' : '100%')};
    height: 100%;
    background: var(--color-white);
    border-radius: 6px 6px 0px 0px;
    box-shadow: var(--box-shadow);
`

const isInitialBlack = (id?: string) => {
    return id && [blankTemplateWebsiteId, blankTemplateAIId, blankTemplateExcelId].includes(id)
}

const TemplateImage: React.FunctionComponent<TemplateImageProps> = ({
    isBlankTemplate,
    value,
    id,
    active,
    style,
    ratio = 9 / 16,
    backgroundColor = 'var(--color-gray-100)'
}) => {
    const isTemplateItem = useMemo(() => {
        return isBlankTemplate && !isInitialBlack(id)
    }, [id, isBlankTemplate])

    return (
        <SCXWrapper active={active} ratio={ratio} style={style}>
            <SCXImageContent backgroundColor={backgroundColor}>
                {isTemplateItem ? (
                    <SCXBlankDescribeWrapper>
                        <CM.Icon type="Add" size={24} color="var(--color-gray-400)" />
                    </SCXBlankDescribeWrapper>
                ) : (
                    <SCXDescribeImageWrapper>
                        <SCXDescribeImage src={value} />
                    </SCXDescribeImageWrapper>
                )}
            </SCXImageContent>
        </SCXWrapper>
    )
}

export default React.memo(TemplateImage)
