import { Button, Flex, IconFont, Input } from '@byecode/ui'
import type { MarkLine } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { FieldSelect } from '../../../FieldSelect'
import { chartMarkLineOptions } from '../../constants'
import { Patches } from './Patches'

interface MarkLineConfiguratorProps {
    label: string
    value: MarkLine[]
    onChange: (val: MarkLine[]) => void
}

const ChartConfigurator = styled.div``

const ValueConfiguratorItem = styled(Flex)`
    margin-bottom: 12px;
`

const BasicHeader = styled(Flex)<{ margin?: boolean }>`
    font-weight: 600;
    height: 32px;
    margin-bottom: ${({ margin }) => (margin ? '8px' : '0')};
    line-height: 32px;
    color: var(--color-gray-900);
    font-size: var(--font-size-normal);
    font-weight: 400;
`

const SCxText = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
    font-weight: 400;
`

const SCxSelect: typeof FieldSelect = styled(FieldSelect)``

const SCxInput = styled(Input)`
    flex: 1;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const SCxTrash = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray-100);
    height: 32px;
    width: 32px;
    border-radius: 6px;
`

const SCxAddButton = styled(Button)`
    margin: 4px 0;
`

export const MarkLineConfigurator: React.FC<MarkLineConfiguratorProps> = ({ label, value = [], onChange }) => {
    const handleAdd = useCallback(() => {
        onChange?.([...value, { id: nanoid(10), type: 'fixed', value: undefined, color: '#F04438' }])
    }, [onChange, value])

    const handleDelete = useCallback(
        (index: number) => {
            const newValue = value.filter((_, i) => i !== index)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleChange = useCallback(
        (val: Partial<MarkLine>, index: number) => {
            const newValue = value.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        ...val
                    }
                }
                return item
            })
            onChange?.(newValue)
        },
        [onChange, value]
    )

    return (
        <ChartConfigurator>
            <BasicHeader margin>
                <SCxText>{label}</SCxText>
            </BasicHeader>

            {value.map(({ id, type, value, color }, index) => (
                <ValueConfiguratorItem key={id} justifyContent="space-between" alignItems="center" gap="6px">
                    <SCxSelect
                        searchable={false}
                        style={{
                            width: 79
                        }}
                        value={type}
                        onChange={val => handleChange({ type: val }, index)}
                        options={chartMarkLineOptions}
                    />
                    <SCxInput
                        type="number"
                        placeholder="请输入"
                        value={value}
                        onChange={e => {
                            handleChange({ value: e.target.valueAsNumber }, index)
                        }}
                    />

                    <Patches value={color} onChange={val => handleChange({ color: val }, index)} />
                    <SCxTrash onClick={() => handleDelete(index)}>
                        <IconFont type="Trash" size={16} fill="var(--color-gray-900)" />
                    </SCxTrash>
                </ValueConfiguratorItem>
            ))}
            <SCxAddButton radius={100} onClick={handleAdd} icon={<IconFont type="Add" size={16} fill="var(--color-gray-500)" />}>
                添加
            </SCxAddButton>
        </ChartConfigurator>
    )
}
