import * as React from 'react'

import * as SC from '../styles'

interface FormItemProps {
    label: string
    required?: boolean
    children?: React.ReactNode
}

export const FormItem: React.FunctionComponent<FormItemProps> = ({ label, required, children }) => {
    return (
        <SC.FormItem>
            <SC.FormItemLabelWrapper>
                <SC.FormItemLabel>{label}</SC.FormItemLabel>
                {required && <span style={{ color: 'var(--color-red-500)' }}>*</span>}
            </SC.FormItemLabelWrapper>
            <SC.FormItemContent>{children}</SC.FormItemContent>
        </SC.FormItem>
    )
}
