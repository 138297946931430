import { IconFont, Modal } from '@byecode/ui'
import React, { useCallback } from 'react'

import { Header } from '../Header'
import * as SC from './styles'
import type { AccountIntegrationProps } from './types'

const integrationOption = [
    {
        title: '微信公众号',
        describe: '仅限绑定官方认证的服务号；绑定公众号后，应用将可以通过指定的域名获取该公众号下微信用户的授权及OpenID',
        key: 'wechat',
        icon: 'WeChatLogo',
        iconColor: '#07C160'
    }
]

export const AccountIntegration: React.FC<AccountIntegrationProps> = ({ workspace }) => {
    const { id } = workspace

    // const handleBind = useCallback(() => {
    //     window.location.href = ''
    // }, [])

    const handleUnbind = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '取消绑定公众号',
            content: `取消绑定后，本应用与公众号所有相关信息将失效（包含但不限于微信登录）请您谨慎操作。`,
            okStatus: 'error'
        })
    }, [])

    const handleToggleBind = useCallback(() => {
        handleUnbind()
    }, [handleUnbind])

    return (
        <SC.Container>
            <Header title="账号集成" />
            <SC.List>
                {integrationOption.map(item => (
                    <SC.ListItem key={item.key}>
                        <SC.ListItemWrapper>
                            <SC.ListItemIcon>
                                <IconFont type={item.icon} size={20} fill={item.iconColor} />
                            </SC.ListItemIcon>
                            <SC.ListItemContent>
                                <SC.ListItemTitle>{item.title}</SC.ListItemTitle>
                                <SC.ListitemDescribe>{item.describe}</SC.ListitemDescribe>
                            </SC.ListItemContent>
                        </SC.ListItemWrapper>
                        <SC.ListItemExtra>
                            <SC.BindButton size="md" onClick={handleToggleBind}>
                                去绑定
                            </SC.BindButton>
                        </SC.ListItemExtra>
                    </SC.ListItem>
                ))}
            </SC.List>
        </SC.Container>
    )
}

export default AccountIntegration
