import type { BlockType, DataSourceType, FieldType, PageType } from '@lighthouse/core'

import type { NodeTypes } from './flow'

export enum FindUseType {
    'FIELD' = 'FIELD',
    'DATASHEET' = 'DATASHEET'
}

export type FieldFindUseConfig = {
    // type: fieldId
    field: {
        dsId: string
        fieldId: string
    }
}

export type DataSheetFindUseConfig = {
    dataSheet: {
        dsId: string
    }
}

export type FieldFindUse = Record<'type', FindUseType.FIELD> & FieldFindUseConfig

export type DataSheetFindUse = Record<'type', FindUseType.DATASHEET> & DataSheetFindUseConfig

export type FindUseADTObject = FieldFindUse | DataSheetFindUse

// location 使用位置

export enum FindUseLocationType {
    'DATASHEET' = 'DATASHEET',
    'FIELD' = 'FIELD',
    'PAGE' = 'PAGE',
    'WORKFLOW' = 'WORKFLOW',
    'BLOCK' = 'BLOCK'
}

export type DataSheetLocationConfigure = Record<'type', FindUseLocationType.DATASHEET> & {
    // versionId: string
    dsId: string
    dsName: string
    dsType: DataSourceType
    nodeId: string
    nodeName: string
}

export type FieldLocationConfigure = Record<'type', FindUseLocationType.FIELD> & {
    // versionId: string
    dsId: string
    dsName: string
    fieldId: string
    fieldType: FieldType
    fieldName: string
}

export type PageLocationConfigure = Record<'type', FindUseLocationType.PAGE> & {
    // versionId: string
    dsId: string
    dsName: string
    pageId: string
    pageName: string
    pageType: PageType
}

export type WorkflowLocationConfigure = Record<'type', FindUseLocationType.WORKFLOW> & {
    // versionId: string
    workflowId: string
    nodeId: string
    workflowName: string
    nodeName: string
    workflowType: string
    nodeType: NodeTypes
}

export type BlockLocationConfigure = Record<'type', FindUseLocationType.BLOCK> & {
    // versionId: string
    pageId: string
    blockId: string
    blockType: BlockType
    pageName: string
    blockName: string
    dsId?: string
}

export type FindUseLocationConfig =
    | DataSheetLocationConfigure
    | FieldLocationConfigure
    | PageLocationConfigure
    | WorkflowLocationConfigure
    | BlockLocationConfigure

export type DataSheetLocation = { datasheet?: FindUseLocationConfig[] }

export type FieldLocation = { field?: FindUseLocationConfig[] }

export type PageLocation = { page?: FindUseLocationConfig[] }

export type WorkflowLocation = { workflow?: FindUseLocationConfig[] }

export type BlockLocation = { block?: FindUseLocationConfig[] }

export type FindUseLocation = DataSheetLocation & FieldLocation & PageLocation & WorkflowLocation & BlockLocation

export type FindUseData = { config: FindUseADTObject; location: FindUseLocationConfig }
