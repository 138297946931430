import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px 24px;
    overflow: hidden;
`

export const Body = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    gap: 30px;
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    overflow: hidden;
`
export const ListContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`

export const LeftFill = styled.div`
    width: 280px;
    height: 100%;
`
export const RightFill = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`


export const ToolsContainer = styled.div`
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`
export const TableFooter = styled.div`
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--color-white);
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--color-gray-200);
`


// pool

export const TabExtra = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Icon = styled(IconFont)`
    margin-right: 6px;
`

export const Bold = styled.span<{ pointer?: boolean }>`
    font-weight: 600;
    margin: 0 2px;
    ${props => props.pointer && pointer()}
`

export const CancelPool = styled.span`
    color: var(--color-main);
    margin: 0 2px;
    ${pointer}
`

export const AppName = styled.div`
    color: var(--color-white);
    text-align: center;
    font-size: var(--font-size-normal);
`
