import { Button } from '@byecode/ui/components/Button/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Text } from '@byecode/ui/components/Text'
import type { WechatPayStatus } from '@lighthouse/core'
import { wechatPayStatusMessageMap } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import type { Step } from '../types'

interface WeChatOrderFailProps {
    errorStatus?: WechatPayStatus
    onNextStep: (step: Step) => void
}
export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`

export const WeChatOrderFail: React.FunctionComponent<WeChatOrderFailProps> = ({ errorStatus, onNextStep }) => {
    return (
        <SCxContainer>
            <IconFont type="ErrorCircle" size={60} color="var(--color-red-500)" />
            {errorStatus && <Text>支付失败：{wechatPayStatusMessageMap[errorStatus]}</Text>}
            <Button style={{ height: 40, width: 280 }} onClick={() => onNextStep({ value: 'customerService' })}>
                联系客服
            </Button>
            <Button style={{ height: 40, width: 280 }} type="primary" onClick={() => onNextStep({ value: 'order' })}>
                重新支付
            </Button>
        </SCxContainer>
    )
}
