import { BlockThumbImageMap, BlockTypeIconMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

// export const blockNotice: BlockTreeItem = {
//     id: '501',
//     name: '公告',
//     icon: 'MediaSpeakerLow',
//     describeImage: BlockThumbImageMap['notice'],
//     type: 'block',
//     width: 12,
//     minWidth: 2,
//     minHeight: 1,
//     allowResizeHeight: false,
//     data: {
//         id: '501',
//         type: 'notice',
//         title: '公告',
//         config: { type: 'info', items: [] }
//     }
// }

// export const blockAiTable: BlockTreeItem = {
//     id: '502',
//     name: '智能表格',
//     icon: 'AI02',
//     describeImage: BlockThumbImageMap['aiTable'],
//     type: 'block',
//     width: 12,
//     minWidth: 2,
//     minHeight: 1,
//     allowResizeHeight: false,
//     data: {
//         id: '502',
//         type: 'aiTable',
//         title: '智能表格',
//         config: { prompt: '', showTitle: true },
//         content: { data: [] }
//     }
// }

export const appNavBlock: BlockTreeItem = {
    name: '应用导航',
    icon: BlockTypeIconMap.appNav,
    describeImage: BlockThumbImageMap['appNav'],
    color: '#2E90FA',
    backGroundColor: 'rgba(46, 144, 250, 0.10)',
    type: BlockType.appNav
}
