import { Tooltip } from '@byecode/ui'
import type { IconDecoration, IconType } from '@lighthouse/shared'
import { IconPicker } from '@lighthouse/shared'
import type { UploadyProps } from '@rpldy/shared-ui'
import React, { forwardRef } from 'react'


type IconControlProps = {
    value?: string
    clearable?: boolean
    style?: React.CSSProperties
    size?: number
    disabled?: boolean
    allowIconTypes?: string[]
    onChange?: (v: string) => void
}

export const IconControl = forwardRef<HTMLInputElement, IconControlProps>(
    ({ value = '', clearable = true, size, disabled, style, onChange }, ref) => {
        return (
            <Tooltip disabled={disabled} title={value ? '更换图标' : '添加图标'}>
                <IconPicker
                    size={size}
                    disabled={disabled}
                    clearable={clearable}
                    ref={ref}
                    value={{ type: 'icon', value }}
                    onChange={val => onChange?.(val?.value ?? '')}
                />
            </Tooltip>
        )
    }
)

interface IconControlV2Props {
    value?: IconDecoration
    clearable?: boolean
    size?: number
    disabled?: boolean
    allowIconTypes?: IconType[]
    uploadParams: UploadyProps
    onChange?: (v?: IconDecoration) => void
}

export const IconControlV2 = forwardRef<HTMLInputElement, IconControlV2Props>(({ clearable = true, ...rest }, ref) => {
    return (
        <Tooltip disabled={rest.disabled} title={rest.value ? '更换图标' : '添加图标'}>
            <IconPicker clearable={clearable} disableTagToggle={false} ref={ref} {...rest} />
        </Tooltip>
    )
})
