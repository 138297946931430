import type { SwitchProps } from '@byecode/ui'
import { Switch } from '@byecode/ui'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import type { ReactNode } from 'react'
import React, { forwardRef } from 'react'

interface SwitchControlProps extends SwitchProps {
    label?: ReactNode
    enablePadding?: boolean
}

export const SwitchControl = forwardRef<HTMLInputElement, SwitchControlProps>(({ label, enablePadding = false, ...rest }, ref) => {
    return (
        <ListItemPaddingByecodeUi enablePadding={enablePadding} justifyContent="space-between" alignItems="center">
            {label && <Text>{label}</Text>}
            <Switch {...rest} ref={ref} />
        </ListItemPaddingByecodeUi>
    )
})
