import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@mantine/core'
import cls from 'classnames'
import React from 'react'
import styled from 'styled-components'

import type { BlockTree, BlockTreeItem } from '@/constants/Block/type'

import * as CM from '../style'

interface KindBlockItemProps {
    data: BlockTree
    activeId: string
    onOpen: (v: string) => void
}

const SCxLeftFill = styled(Flex)``

const SCxItem = styled(Flex)`
    display: flex;
    height: 48px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    :hover {
        border-radius: 12px;
        background-color: rgba(38, 65, 90, 0.06);
    }

    &.active {
        border-radius: 12px;
        background-color: rgba(38, 65, 90, 0.06);
    }
`

const KindBlockItem: React.FunctionComponent<KindBlockItemProps> = ({ data, activeId, onOpen }) => {
    const { id, items, name, icon, color, iconColor } = data

    return (
        <SCxItem data-kindId={id} className={cls({ active: activeId === id })} onMouseEnter={() => onOpen(id)}>
            <SCxLeftFill gap={12} align="center">
                <Button
                    type="tag"
                    radius={8}
                    style={{ background: color, boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.10)' }}
                    icon={<CM.Icon color={iconColor} type={icon} size={20} />}
                />
                <CM.Text size={12} style={{ fontWeight: 500 }} color="var(--color-gray-500)" className={cls({ active: activeId === id })}>
                    {name}
                </CM.Text>
            </SCxLeftFill>
            <CM.Icon type="ArrowRightSmall" />
        </SCxItem>
    )
}

export default KindBlockItem
