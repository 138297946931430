import { getAssetUrl } from '@lighthouse/assets'
import { NoFound } from '@lighthouse/shared'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
`

export default function NotFoundTitle() {
    const navigate = useNavigate()

    return (
        <SCxContainer>
            <NoFound image={getAssetUrl('empty', 'no_app.svg')} desc="没有应用" onBtnClick={() => navigate('/workbench/applications')} />
        </SCxContainer>
    )
}
