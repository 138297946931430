import { Button, Checkbox, Group, IconFont, Input, SegmentedControl, Select, Slider, Switch, Text, Tooltip } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import { DIRECTION, HEIGHT_RULE } from '@lighthouse/core'
import { ListItem4ByecodeUi, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { containerDesignStateAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import { MAX_WIDTH_OPTIONS } from '@/constants/common'

import type { DesignSettingProps } from './types'

export const DIRECTION_OPTIONS = [
    {
        label: '纵向',
        icon: <IconFont type="VerticallLayout" />,
        value: DIRECTION.vertical
    },
    {
        label: '横向',
        icon: <IconFont type="HorizontalLayout" />,
        value: DIRECTION.horizontal
    }
]

export const HEIGHT_RULES_OPTIONS = [
    {
        label: '适应',
        value: HEIGHT_RULE.AUTO,
        icon: <IconFont type="ArrowPackUp" color="var(--color-gray-400)" />
    },
    {
        label: '充满',
        value: HEIGHT_RULE.FILL,
        icon: <IconFont type="ArrowOpen" color="var(--color-gray-400)" />
    },
    {
        label: '固定',
        value: HEIGHT_RULE.FIXED,
        icon: <IconFont type="VerticalPadding" color="var(--color-gray-400)" />
    }
]

export const HORIZONTAL_FLEX_LAYOUT_OPTIONS = [
    {
        value: 'flex-start',
        icon: (
            <Tooltip title="居上">
                <IconFont size={16} type="AlignTop" />
            </Tooltip>
        )
    },
    {
        value: 'center',
        icon: (
            <Tooltip title="居中">
                <IconFont size={16} type="AlignCenterVertical" />
            </Tooltip>
        )
    },
    {
        value: 'flex-end',
        icon: (
            <Tooltip title="居下">
                <IconFont size={16} type="AlignDown" />
            </Tooltip>
        )
    }
]
export const VERTICAL_FLEX_LAYOUT_OPTIONS = [
    {
        value: 'flex-start',
        icon: (
            <Tooltip title="居左">
                <IconFont size={16} type="AlignLeft" />
            </Tooltip>
        )
    },
    {
        value: 'center',
        icon: (
            <Tooltip title="居中">
                <IconFont size={16} type="AlignCenter" />
            </Tooltip>
        )
    },
    {
        value: 'flex-end',
        icon: (
            <Tooltip title="居右">
                <IconFont size={16} type="AlignRight" />
            </Tooltip>
        )
    }
]

export const LayoutSetting = ({ containerId, host, enableHeightConfigure }: DesignSettingProps) => {
    const { getValues, control } = useFormContext<ContainerBlockConfig & { height?: number }>()

    // const plRef = useRef<HTMLInputElement>(null)
    // const ptRef = useRef<HTMLInputElement>(null)
    // const prRef = useRef<HTMLInputElement>(null)
    // const pbRef = useRef<HTMLInputElement>(null)

    // useMouseEventDistance<HTMLInputElement>(
    //     x => {
    //         const newValue = Math.max(0, Math.min(transform2Integer(getValues('design.padding.each.0')) + x, 1000))
    //         setValue('design.padding.each.0', newValue)
    //         setDraftPadding(draft => void (draft[0] = newValue))
    //     },
    //     { ref: plRef }
    // )
    // useMouseEventDistance<HTMLInputElement>(
    //     x => {
    //         const newValue = Math.max(0, Math.min(transform2Integer(getValues('design.padding.each.1')) + x, 1000))
    //         setValue('design.padding.each.1', newValue)
    //         setDraftPadding(draft => void (draft[1] = newValue))
    //     },
    //     { ref: ptRef }
    // )
    // useMouseEventDistance<HTMLInputElement>(
    //     x => {
    //         const newValue = Math.max(0, Math.min(transform2Integer(getValues('design.padding.each.2')) + x, 1000))
    //         setValue('design.padding.each.2', newValue)
    //         setDraftPadding(draft => void (draft[2] = newValue))
    //     },
    //     { ref: prRef }
    // )
    // useMouseEventDistance<HTMLInputElement>(
    //     x => {
    //         const newValue = Math.max(0, Math.min(transform2Integer(getValues('design.padding.each.3')) + x, 1000))
    //         setValue('design.padding.each.3', newValue)
    //         setDraftPadding(draft => void (draft[3] = newValue))
    //     },
    //     { ref: pbRef }
    // )

    const { stackId = '' } = useAtomData(lastPageOfStackAtom) ?? {}
    const { run: setContainerDesignState } = useAtomAction(containerDesignStateAtom)
    const onMouseEnterPaddingX = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], paddingX: true } })
        })
    }, [containerId, setContainerDesignState, stackId])
    const onMouseLeavePaddingX = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], paddingX: false } })
        })
    }, [containerId, setContainerDesignState, stackId])
    const onMouseEnterPaddingY = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], paddingY: true } })
        })
    }, [containerId, setContainerDesignState, stackId])
    const onMouseLeavePaddingY = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], paddingY: false } })
        })
    }, [containerId, setContainerDesignState, stackId])

    const onMouseEnterGap = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], gap: true } })
        })
    }, [containerId, setContainerDesignState, stackId])
    const onMouseLeaveGap = useCallback(() => {
        setContainerDesignState(draft => {
            Reflect.set(draft, stackId, { [containerId]: { ...draft[stackId], gap: false } })
        })
    }, [containerId, setContainerDesignState, stackId])

    // const [draftPadding, setDraftPadding] = useImmer<(string | number | undefined)[]>(clone(getValues('design.padding.each')) || [])

    // const getPaddingInputProps = useCallback(
    //     (index: 0 | 1 | 2 | 3) => {
    //         const setString2Number = (value: string) => {
    //             const number = Number(value)
    //             if (Number.isNaN(number)) {
    //                 setDraftPadding(draft => void (draft[index] = getValues(`design.padding.each.${index}`)))
    //                 return
    //             }

    //             const inRangeNumber = Math.max(0, Math.min(number, 1000))

    //             setDraftPadding(draft => void (draft[index] = inRangeNumber))
    //             setValue(`design.padding.each.${index}`, inRangeNumber)
    //         }

    //         return {
    //             value: draftPadding[index],
    //             onChange: e => setDraftPadding(draft => void (draft[index] = e.target.value)),
    //             onBlur: e => {
    //                 const { value } = e.currentTarget
    //                 setString2Number(value)
    //             },
    //             onKeyDown: e => {
    //                 if (e.key === 'Enter') {
    //                     const { value } = e.currentTarget
    //                     setString2Number(value)
    //                 }
    //             }
    //         } satisfies React.ComponentPropsWithRef<'input'>
    //     },
    //     [draftPadding, getValues, setDraftPadding, setValue]
    // )

    const direction = getValues('design.direction')

    return (
        <Group label="布局">
            {host === 'container' && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>方向</Text>
                    <Controller
                        control={control}
                        name="design.direction"
                        render={({ field }) => <SegmentedControl {...field} fullWidth data={DIRECTION_OPTIONS} style={{ width: 180 }} />}
                    />
                </ListItem4ByecodeUi>
            )}

            {direction === DIRECTION.horizontal && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>自适应</Text>

                    <Controller
                        control={control}
                        name="design.mobileAdaptive"
                        render={({ field }) => (
                            <Checkbox
                                size="xs"
                                label="在手机端垂直布局"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}

            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>最大宽度</Text>
                <Controller
                    control={control}
                    name="design.size"
                    render={({ field }) => <Select {...field} options={MAX_WIDTH_OPTIONS} styles={{ root: { width: 180 } }} />}
                />
            </ListItem4ByecodeUi>

            {enableHeightConfigure && (
                <>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>高度规则</Text>
                        <Controller
                            control={control}
                            name="design.heightRule"
                            render={({ field }) => (
                                <SegmentedControl {...field} fullWidth data={HEIGHT_RULES_OPTIONS} style={{ width: 180 }} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                    {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>高度</Text>
                <Controller
                    control={control}
                    name="height"
                    render={({ field }) => (
                        <Input
                            value={field.value}
                            onChange={e => {
                                const { value } = e.target
                                const number = Math.ceil(Number(value))
                                if (Number.isNaN(number) || number < 0) {
                                    return
                                }
                                field.onChange(number)
                            }}
                            style={{ width: 180 }}
                        />
                    )}
                />
            </ListItem4ByecodeUi> */}
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>裁剪超出内容</Text>
                        <Controller
                            control={control}
                            name="design.crop"
                            render={({ field }) => <Switch checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                        />
                    </ListItem4ByecodeUi>
                </>
            )}

            <ListItem4ByecodeUi
                justifyContent="space-between"
                alignItems="center"
                onMouseEnter={onMouseEnterGap}
                onMouseLeave={onMouseLeaveGap}
            >
                <Text>间距</Text>
                <Controller
                    control={control}
                    name="design.gap"
                    render={({ field }) => (
                        <Slider
                            min={0}
                            max={12}
                            step={0.125}
                            {...field}
                            style={{ width: 180 }}
                            inputProps={{
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            {/* {!direction || direction === DIRECTION.vertical ? (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>对齐</Text>
                    <Controller
                        control={control}
                        name="design.alignX"
                        render={({ field }) => (
                            <SegmentedControl {...field} fullWidth data={VERTICAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                        )}
                    />
                </ListItem4ByecodeUi>
            ) : (
                <> */}
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>纵向对齐</Text>
                        <Controller
                            control={control}
                            name="design.alignY"
                            render={({ field }) => (
                                <SegmentedControl {...field} fullWidth data={HORIZONTAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>横向对齐</Text>
                        <Controller
                            control={control}
                            name="design.alignX"
                            render={({ field }) => (
                                <SegmentedControl {...field} fullWidth data={VERTICAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                            )}
                        />
                    </ListItem4ByecodeUi>
                {/* </>
            )} */}

            <ListItem4ByecodeUi
                justifyContent="space-between"
                alignItems="center"
                onMouseEnter={onMouseEnterPaddingX}
                onMouseLeave={onMouseLeavePaddingX}
            >
                <Text>横向边距</Text>
                <Controller
                    control={control}
                    name="design.padding"
                    render={({ field }) => (
                        <Slider
                            value={field.value?.[0]}
                            onChange={v => {
                                const [, top = 0, , bottom = 0] = field.value || []
                                field.onChange([v, top, v, bottom])
                            }}
                            icon={<IconFont type="MaxWidth" />}
                            min={0}
                            max={12}
                            step={0.125}
                            style={{ width: 180 }}
                            inputProps={{
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                        />
                    )}
                />
                {/* <Flex styles={{ root: { width: 180 } }} grow gap="8px">
                        <Input
                            ref={plRef}
                            {...getPaddingInputProps(0)}
                            key="pl"
                            placeholder=""
                            style={{ cursor: 'ew-resize' }}
                            prefix={<IconFont type="PaddingLeft" />}
                        />
                        <Input
                            ref={ptRef}
                            {...getPaddingInputProps(1)}
                            key="pt"
                            placeholder=""
                            style={{ cursor: 'ew-resize' }}
                            prefix={<IconFont type="PaddingTop" />}
                        />
                    </Flex> */}
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi
                justifyContent="space-between"
                alignItems="center"
                onMouseEnter={onMouseEnterPaddingY}
                onMouseLeave={onMouseLeavePaddingY}
            >
                <Text>纵向边距</Text>
                <Controller
                    control={control}
                    name="design.padding"
                    render={({ field }) => (
                        <Slider
                            value={field.value?.[1]}
                            onChange={v => {
                                const [left = 0, , right = 0] = field.value || []
                                field.onChange([left, v, right, v])
                            }}
                            icon={<IconFont type="MaxWidth" />}
                            min={0}
                            max={12}
                            step={0.125}
                            style={{ width: 180 }}
                            inputProps={{
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                        />
                    )}
                />
                {/* <Flex styles={{ root: { width: 180 } }} gap="8px">
                        <Input
                            ref={prRef}
                            {...getPaddingInputProps(2)}
                            key="pr"
                            placeholder=""
                            style={{ cursor: 'ew-resize' }}
                            prefix={<IconFont type="PaddingRight" />}
                        />
                        <Input
                            ref={pbRef}
                            {...getPaddingInputProps(3)}
                            key="pb"
                            placeholder=""
                            style={{ cursor: 'ew-resize' }}
                            prefix={<IconFont type="PaddingBottom" />}
                        />
                    </Flex> */}
            </ListItem4ByecodeUi>
        </Group>
    )
}
