/* eslint-disable promise/prefer-await-to-callbacks  */

import { nanoid } from "@lighthouse/tools"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Noop = (params?: any) => void

type SubscribeReturnType = {
    subscribeId: string
    unSubscribe: (id: string) => void
}

type Listener = {
    id: string
    subscribeId: string
    callback: Noop
}

type Operation = 'UPDATE'

/**
 * 数据查看器的事件订阅
 * @date 2024/7/02 - 11:48:00
 *
 * @class PageStackTask
 * @typedef {PageStackTask}
 */
class DataDrawerPubSub {
    #listeners: Listener[] = []

    subscribe<O extends `${string}-${Operation}`>(id: O, callback: Noop): SubscribeReturnType {
        const subscribeId = nanoid()
        this.#listeners.push({ id, subscribeId, callback })

        return {
            subscribeId,
            unSubscribe: (id: string) => {
                this.#listeners = this.#listeners.filter(item => item.subscribeId !== id)
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emit<O extends `${string}-${Operation}`>(id: O, params?: any): void {
        this.#listeners.forEach(listener => {
            if (listener.id === id) {
                listener.callback(params)
            }
        })
    }

    clear(): void {
        this.#listeners = []
    }
}

export const dataDrawerPubSub = new DataDrawerPubSub()
