import { backgroundTransitionOnClick, Divider, IconFont, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import cls from 'classnames'
import { findIndex, reduce } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export type GroupOption = { label: string; value: string; icon?: string | React.ReactNode; group: string }

interface GroupListProps {
    maxHeight?: number
    value?: string
    options?: GroupOption[]
    width?: string | number
    onItem?: (value: GroupOption) => void
}

const SCxContainer = styled.div<{ maxHeight: number; width?: number | string }>`
    max-height: ${({ maxHeight }) => maxHeight}px;
    /* padding: 8px 0; */
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width ?? 'auto')};
    overflow-y: auto;
    ${tinyButtons}
`

const SCxWrapper = styled.div`
    width: 100%;
`

const SCxGroup = styled.div`
    width: 100%;
`

const SCxItem = styled.div`
    padding: 0 16px;
    display: flex;
    width: 100%;
    height: 36px;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    ${backgroundTransitionOnClick}
    ${pointer}

    &.active {
    }
`

const SCxLeftFill = styled.div`
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    width: 100%;
    overflow: hidden;
`

const SCxRightFill = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row nowrap;
    max-width: 50%;
    min-width: 32px;
`

const SCxText = styled.div`
    font-weight: 400;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    line-height: 16px;
    ${singleTextEllipsis}
`

export const GroupList: React.FunctionComponent<GroupListProps> = ({ options = [], value, width, maxHeight = 320, onItem }) => {
    const newOption = useMemo(
        () =>
            reduce<GroupOption, { list: GroupOption[]; group: string }[]>(
                (preVal, curVal) => {
                    const { group } = curVal
                    const groupIndex = findIndex(item => item.group === group, preVal)
                    if (groupIndex === -1) {
                        preVal.push({ list: [curVal], group })
                        return preVal
                    }
                    preVal[groupIndex].list.push(curVal)
                    return preVal
                },
                [],
                options
            ),
        [options]
    )
    return (
        <SCxContainer maxHeight={maxHeight} width={width}>
            <SCxWrapper>
                {newOption.map(({ list, group }, index) => {
                    const notLast = newOption.length - 1 !== index
                    return (
                        <React.Fragment key={group}>
                            <SCxGroup>
                                {list.map(item => {
                                    const IconEle = typeof item.icon === 'string' ? <IconFont size={16} type={item.icon} /> : item.icon
                                    const isActive = value === item.value
                                    return (
                                        <SCxItem key={item.value} className={cls({ active: isActive })} onClick={() => onItem?.(item)}>
                                            <SCxLeftFill>
                                                {item.icon && IconEle}
                                                <SCxText>{item.label}</SCxText>
                                            </SCxLeftFill>
                                            <SCxRightFill>{isActive && <IconFont size={16} type="Tick" />}</SCxRightFill>
                                        </SCxItem>
                                    )
                                })}
                            </SCxGroup>
                            {notLast && <Divider style={{ margin: '8px 0' }} color="var(--color-gray-200)" />}
                        </React.Fragment>
                    )
                })}
            </SCxWrapper>
        </SCxContainer>
    )
}
