import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight } from 'rambda'

import { APPLICATION_VERSION_STATUS, ApplicationType } from '../abstracts'
import type { ApplicationAbstract } from '../abstracts/Application'

export function ApplicationStruct(initial: Partial<ApplicationAbstract> = {}): ApplicationAbstract {
    return mergeDeepRight<ApplicationAbstract>(
        {
            id: '',
            name: '未命名应用',
            type: ApplicationType.website,
            icon: 'default',
            // publishStat: 0,
            // type: 0,
            // // blocks: [],
            // pageList: [],
            // dataSourceList: [],
            // prefersColorScheme: 'light',
            // primaryColor: 'default',
            // backgroundColor: 'default',
            mainDomain: 'byecode.site',
            version: {
                id: '',
                name: '',
                status: APPLICATION_VERSION_STATUS.OFFLINE,
                envId: '',
                url: '',
                preview: false
            }
        } satisfies Partial<ApplicationAbstract>,
        initial
    )
}

function generateLinksByNames(names: string[]) {
    return names.map(name => ({
        id: nanoid(),
        event: 'openLink',
        name,
        page: '',
        url: ''
    }))
}
function generateLinksByLength(l: number) {
    return Array.from({ length: l }).map((_, i) => ({
        id: nanoid(),
        event: 'openLink',
        name: `链接${i + 1}`,
        page: '',
        url: ''
    }))
}
function generateGroups(l: number) {
    return Array.from({ length: l }).map((_, i) => ({
        id: nanoid(),
        name: `分组${i + 1}`,
        textLinks: i === 0 ? generateLinksByNames(['关于我们', '加入我们', '帮助中心', '用户协议', '隐私政策']) : generateLinksByLength(3)
    }))
}
