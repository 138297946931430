import { Button, IconFont, Input, Popover } from '@byecode/ui'
import { useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'

import { TagIcon } from '../TagIcon'

interface KeywordsAdderProps {
    value?: string[]
    style?: React.CSSProperties
    disabled?: boolean
    onChange?: (value: string[]) => void
}

const SCxKeywordsAdderWrapper = styled.div`
    min-height: 32px;
`

const SCxKeywordsPreviewer = styled.div`
    display: flex;
    gap: 8px 12px;
    flex-wrap: wrap;
`

const SCxKeywordsTag = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border: 1px solid var(--color-gray-200);
    font-size: var(--font-size-normal);
    color: var(--color-gray-900);
    border-radius: 100px;

    iconpark-icon {
        color: var(--color-gray-400);
    }

    /* &:hover {
        background-color: var(--color-gray-100);
        border-color: var(--color-gray-100);
        iconpark-icon {
            color: var(--color-black);
        }
    }
    &.active {
        background-color: var(--color-gray-100);
        border-color: var(--color-gray-100);
        iconpark-icon {
            color: var(--color-black);
        }
    } */
`

const SCxKeywordsTagCloseIcon = styled(IconFont)`
    margin-left: 6px;
    color: var(--color-gray-600);
    font-size: var(--font-size-sm);
    cursor: pointer;
`

const SCxKeywordsAdderInput = styled(Input)`
    flex: 1;
    margin-bottom: 12px;
`

export const KeywordsAdder: React.FC<KeywordsAdderProps> = ({ value, style, disabled, onChange }) => {
    const oldKeywords = useRef(value)
    let inputRef = useRef<HTMLInputElement>(null)
    const [opened, setOpened] = useState(false)

    const [keywords, setKeywords] = useState<string[]>(value ?? [])

    const handleAddKeyword = useCallback(() => {
        const keyword = inputRef.current?.value?.trim()
        if (!keyword || !inputRef.current) {
            return
        }

        const newKeywords = [...keywords, keyword]
        setKeywords(newKeywords)
        onChange?.(newKeywords)
        setOpened(false)
        inputRef.current.value = ''
    }, [keywords, onChange])

    const handleRemoveKeyword = useCallback(
        (index: number) => {
            const newKeywords = [...keywords]
            newKeywords.splice(index, 1)
            setKeywords(newKeywords)
            onChange?.(newKeywords)
        },
        [keywords, onChange]
    )

    inputRef = useHotkeys<HTMLInputElement>('Enter', handleAddKeyword, { enableOnTags: ['INPUT'] })

    useEffect(() => {
        oldKeywords.current = keywords
    }, [keywords])

    return (
        <SCxKeywordsAdderWrapper style={style}>
            <SCxKeywordsPreviewer>
                {keywords.map((keyword, index) => (
                    <SCxKeywordsTag key={keyword} disabled={disabled}>
                        <Text lineClamp={1}>{keyword}</Text>
                        <SCxKeywordsTagCloseIcon type="Close" onClick={() => handleRemoveKeyword(index)} />
                    </SCxKeywordsTag>
                ))}
                <Popover width={200} opened={opened} onChange={setOpened}>
                    <Popover.Target>
                        <SCxKeywordsTag disabled={disabled} style={{ height: 28, width: 32 }} className={opened ? 'active' : undefined}>
                            <IconFont type="Add" />
                        </SCxKeywordsTag>
                    </Popover.Target>
                    <Popover.Dropdown compact>
                        <div style={{ flex: 1, padding: 16 }}>
                            <SCxKeywordsAdderInput maxLength={10} ref={inputRef} placeholder="请输入关键词" />
                            <Button type="primary" block onClick={handleAddKeyword}>
                                <span style={{ marginRight: 4 }}>确定</span>
                                <IconFont size={16} type="ArrowElbowDownLeft" />
                            </Button>
                        </div>
                    </Popover.Dropdown>
                </Popover>
            </SCxKeywordsPreviewer>
        </SCxKeywordsAdderWrapper>
    )
}
