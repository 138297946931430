import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const AdderButton = styled(IconFont)`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);

    ${pointer}

    &.active {
        background-color: var(--color-gray-200);
        color: var(--color-gray-900);
    }

    &:hover {
        background-color: var(--color-gray-200);
        color: var(--color-gray-900);
    }
`

export const GroupName = styled.div`
    color: var(--color-gray-500);
    font-size: 14px;
    padding: 8px 16px;
`

export const List = styled.div`
    position: relative;
    width: 100%;
    height: calc(100 - 48px);
`

export const MenuItem = styled.div`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const MenuItemContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`


export const MenuItemContentInfo = styled.div`
    display: flex;
    align-items: center;
`

export const ListGroup = styled.div`
    width: 100%;
`

export const ListContain = styled.div`
    width: 100%;
`
export const Name = styled.span`
    margin-left: 8px;
    line-height: 16px;
    /* font-size: var(--font-size-base); */
`

export const ListItem = styled.div``

export const ListItemExtra = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--color-gray-400);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;

    &:hover {
        background: var(--color-black);
    }
`

export const DescribeContent = styled.div`
    width: 315px;
    padding: 12px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
`

export const DescribeImg = styled.img`
    width: 100%;
`

export const DescribeText = styled.div`
    margin-top: 8px;
    width: 100%;
    color: var(--color-black);
    word-break: break-word;
    white-space: pre-line;
    font-size: 14px;
    color: var(--color-gray-500);
    line-height: 24px;
`

export const Tag = styled.div<{ color: string; background: string }>`
    color: ${({ color }) => color};
    background: ${({ background }) => background};
    padding: 0 5px;
    margin-left: 4px;
    border-radius: 10px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
`
