import React from 'react'
import styled from 'styled-components'

import type { BlockOption } from '@/constants/Block/type'

import KindBlockItem from './Item'

interface KindBlockProps {
    option: BlockOption
    activeId: string
    onOpen: (v: string) => void
}

const SCxContainer = styled.div`
    margin-top: 12px;
    padding: 0 8px;
`

const SCxTitle = styled.div`
    color: var(--color-gray-400);
    text-align: left;
    padding: 0 12px;

    /* 12/SC-Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
`

export const KindBlock: React.FunctionComponent<KindBlockProps> = ({ option, activeId, onOpen }) => {
    return (
        <SCxContainer>
            <SCxTitle>{option.title}</SCxTitle>
            {option.children.map((item, index) => (
                <KindBlockItem data={item} key={item.id} activeId={activeId} onOpen={onOpen} />
            ))}
        </SCxContainer>
    )
}
