import type { Option } from '@byecode/ui'
import { Divider, IconFont, SegmentedControl, Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { DataSourceType, RecordOpenType } from '@lighthouse/core'
import type { NodeTypes } from '@lighthouse/shared'
import { CollapseBox, getTableIcon, nodeTypeOptions, OpenTypeData, SelectDataSource } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useFormPageList } from '@/hooks/usePage'

import * as SC from '../styles'

export interface OpenFormPageActionConfigureProps {
    prefixName?: string
    actionTypeSwitcher?: React.ReactNode
}

export const OpenFormPageActionConfigure: React.FC<OpenFormPageActionConfigureProps> = ({ prefixName = 'config', actionTypeSwitcher }) => {
    const { control } = useFormContext()
    const appId = useCurrentAppID()
    const formPageList = useFormPageList()
    const envId = useCurrentEnvId()
    const dataSourceId: string = useWatch({
        control,
        name: `${prefixName}.dataSourceId`
    })

    const dataSourceList = useNotAggregateDataSourceList(appId, envId)

    const formPageOptions = useMemo(() => {
        return formPageList
            .filter(({ dsId }) => dsId === dataSourceId)
            .map(item => ({
                label: item.name,
                value: item.id,
                icon: 'PageForm'
            }))
    }, [dataSourceId, formPageList])

    const dsOptions = useMemo(
        () =>
            dataSourceList.reduce<DataSourceAbstract[]>((listDs, item) => {
                const tableIcon = getTableIcon(item)
                if (item.type !== DataSourceType.userDataSource) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [dataSourceList]
    )

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.dataSourceId`}
                                control={control}
                                render={({ field }) => (
                                    <SelectDataSource
                                        value={field.value}
                                        dataSourceList={dsOptions}
                                        onChange={val => field.onChange?.(val)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="表单页">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>数据表单</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.formPageId`}
                                control={control}
                                render={({ field }) => (
                                    <Select value={field.value} options={formPageOptions} onChange={val => field.onChange?.(val)} />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>打开方式</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.pageOpenType`}
                                control={control}
                                defaultValue={RecordOpenType.modal}
                                render={({ field }) => <SegmentedControl fullWidth {...field} data={OpenTypeData} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
