import { IconFont, SegmentedControl, Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const iconData = [
    {
        label: (
            <div>
                <IconFont type="Add" size={16} />
            </div>
        ),
        value: 'man'
    },
    {
        label: (
            <div>
                <IconFont type="User" size={16} />
            </div>
        ),
        value: 'woman'
    },
    {
        label: (
            <Tooltip title="未知" placement="bottom">
                <IconFont type="User" size={16} />
            </Tooltip>
        ),
        value: 'unknown'
    }
]

export const Disabled: React.FC = () => {
    return (
        <Unit title="禁用">
            <SegmentedControl disabled data={['man', 'woman', 'unknown']} />
            <SegmentedControl
                disabled
                data={[
                    {
                        label: '男',
                        value: 'man'
                    },
                    {
                        label: '女',
                        value: 'woman'
                    },
                    {
                        label: '未知',
                        value: 'unknown'
                    }
                ]}
            />
            <SegmentedControl disabled data={iconData} />
            <SegmentedControl
                data={[
                    { label: '篮球', value: 'basketball' },
                    { label: '排球', value: 'volleyball' },
                    { label: '禁用的', value: 'disabled', disabled: true },
                    { label: '足球', value: 'football' }
                ]}
            />
        </Unit>
    )
}
