import { flex, Input, Select, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

import { Icon } from '../../styles'

export const Contain = styled.div`
    position: relative;
    overflow: hidden;
    background-color: var(--color-gray-50);
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
`

export const Header = styled.div`
    ${flex}
    padding: 16px 12px;
    justify-content: space-between;
`
export const Fill = styled.div`
    ${flex}
    justify-content: space-between;
    align-items: center;
`

export const Content = styled.div`
    border-top: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    /* padding: 0 12px 12px 12px; */
    & > * {
        padding-top: 12px;
    }
`

export const Title = styled.span`
    padding-left: 4px;
    line-height: 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 400;
    ${singleTextEllipsis}
`

export const LeftFill = styled.div`
    /* width: 126px; */
    flex: 1;
    /* max-width: 100%; */
    overflow: hidden;
    ${flex}
    align-items: center;
`
export const RightFill = styled.div`
    /* width: 240px; */
    flex: 0;
    ${flex}
    justify-content: flex-end;
    ${Icon} {
        margin-left: 12px;
    }
`
export const FieldSelect = styled(Select)`
    height: 38px;
    line-height: 38px;
`

export const TextInput = styled(Input)`
    width: 100%;
    > input {
        height: 32px;
        line-height: 32px;
    }
`
export const Handle = styled.div`
    /* padding: 4px; */
    ${flex}
    align-items: center;
    ${Icon} {
        cursor: grab;
    }
`
