import { Flex, Text } from '@byecode/ui'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div``

const SCxParagraph = styled.div``

const SCxLabel = styled(Text)`
    color: var(--color-gray-700);
    font-size: var(--font-size-normal);
    font-weight: 500;
`

const SCxValue = styled(Text)`
    color: var(--color-gray-600);
    font-size: var(--font-size-normal);
    text-overflow: unset;
    white-space: break-spaces;
`

const SCxSpace = styled.div`
    width: 100%;
    height: 20px;
`

const SCxUl = styled.ul`
    padding: 0 24px;
`

const SCxLink = styled.a`
    text-decoration: none;
    color: var(--color-main);
    font-size: var(--font-size-normal);
    font-weight: 400;
    &:active {
        color: var(--color-main);
    }
`

const syncTableErrorText = '正式数据的数据不能从其他应用同步'
const poolUserErrorText = '正式数据不能使用其他应用的用户'

const syncTableResolveText = '前往「数据源」，切换到正式数据然后删除所有同步表或关闭私人模板链接'
const poolUserResolveText = '前往「数据源」，切换到正式数据，进入「用户」然后点击右上角的「取消使用」按钮或关闭私人模板链接'

const publishSyncTableErrorText = '正式数据的数据不能从其他应用同步，因为您已启用私人模板链接'
const publishPoolUserErrorText = '正式数据不能使用其他应用的用户，因为您已启用私人模板链接'

const publishSyncTableResolveText = '前往「数据源」，切换到正式数据然后删除所有同步表或关闭私人模板链接'
const publishPoolUserResolveText = '前往「用户」，然后点击右上角的「取消使用」按钮或关闭私人模板链接'

interface TableErrorProps {
    isPublish?: boolean
}

export const ExistSyncTableError: React.FC<TableErrorProps> = ({ isPublish }) => {
    const reason = useMemo(() => (isPublish ? publishSyncTableErrorText : syncTableErrorText), [isPublish])
    const resolve = useMemo(() => (isPublish ? publishSyncTableResolveText : syncTableResolveText), [isPublish])
    return (
        <SCxContainer>
            <SCxParagraph>
                <SCxLabel>失败原因:</SCxLabel>
                <SCxValue>{reason}；</SCxValue>
            </SCxParagraph>
            <SCxParagraph>
                <SCxLabel>解决办法:</SCxLabel>
                <SCxValue>{resolve}。</SCxValue>
            </SCxParagraph>
        </SCxContainer>
    )
}

export const ExistPoolUserError: React.FC<TableErrorProps> = ({ isPublish }) => {
    const reason = useMemo(() => (isPublish ? publishPoolUserErrorText : poolUserErrorText), [isPublish])
    const resolve = useMemo(() => (isPublish ? publishPoolUserResolveText : poolUserResolveText), [isPublish])
    return (
        <SCxContainer>
            <SCxParagraph>
                <SCxLabel>失败原因:</SCxLabel>
                <SCxValue>{reason}；</SCxValue>
            </SCxParagraph>
            <SCxParagraph>
                <SCxLabel>解决办法:</SCxLabel>
                <SCxValue>{resolve}。</SCxValue>
            </SCxParagraph>
        </SCxContainer>
    )
}

export const ExistSyncTableAndPoolUserError: React.FC<TableErrorProps> = ({ isPublish }) => {
    const resolve1 = useMemo(() => (isPublish ? publishSyncTableResolveText : syncTableResolveText), [isPublish])
    const resolve2 = useMemo(() => (isPublish ? publishPoolUserResolveText : poolUserResolveText), [isPublish])
    return (
        <SCxContainer>
            <SCxParagraph>
                <SCxLabel>失败原因:</SCxLabel>
            </SCxParagraph>
            {
               isPublish && <SCxParagraph>
                <SCxValue>您已启用私人模板链接。</SCxValue>
            </SCxParagraph>
            }
            <SCxParagraph>
                <SCxValue>1.{syncTableErrorText}；</SCxValue>
            </SCxParagraph>
            <SCxParagraph>
                <SCxValue>2.{poolUserErrorText}。</SCxValue>
            </SCxParagraph>
            <SCxSpace />
            <SCxParagraph>
                <SCxLabel>解决办法:</SCxLabel>
            </SCxParagraph>
            <SCxParagraph>
                <SCxValue>1.{resolve1}；</SCxValue>
            </SCxParagraph>
            <SCxParagraph>
                <SCxValue>2.{resolve2}。</SCxValue>
            </SCxParagraph>
        </SCxContainer>
    )
}

export const PrivateTemplatePolicyCheck: React.FC = () => {
    return (
        <Flex alignItems="center">
            <Text inline color="var(--color-gray-500)" size={14} weight={400}>
                已阅读并同意
            </Text>
            <SCxLink href="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/bexs2e808a5p2ihb?singleDoc#" target="_blank">
                《启用私人模板规范》
            </SCxLink>
        </Flex>
    )
}

export const PrivateTemplatePolicy: React.FC = () => {
    return (
        <SCxContainer>
            <SCxValue>请检查模板中应避免包含以下几类内容</SCxValue>
            <SCxUl>
                <li>
                    <SCxValue>他人的隐私数据，例如手机号、姓名、住址、身份证号等</SCxValue>
                </li>
                <li>
                    <SCxValue>违反国家法律法规的内容</SCxValue>
                </li>
                <li>
                    <SCxValue>扰乱社会秩序的内容</SCxValue>
                </li>
                <li>
                    <SCxValue>涉及知识产权的内容</SCxValue>
                </li>
                <li>
                    <SCxValue>其他不良信息</SCxValue>
                </li>
            </SCxUl>
            <SCxSpace />
            <SCxValue>模板中的以下设置及内容会自动清空：</SCxValue>
            <SCxUl>
                <li>
                    <SCxValue>集成中的所有账号信息</SCxValue>
                </li>
                <li>
                    <SCxValue>用户中的所有用户信息</SCxValue>
                </li>
            </SCxUl>
        </SCxContainer>
    )
}
