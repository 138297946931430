import { getAssetUrl } from '@lighthouse/assets'

const poster1 = getAssetUrl('poster', 'poster1.jpg')
const poster2 = getAssetUrl('poster', 'poster2.jpg')
const poster3 = getAssetUrl('poster', 'poster3.jpg')
const poster4 = getAssetUrl('poster', 'poster4.jpg')
const poster5 = getAssetUrl('poster', 'poster5.jpg')
const poster6 = getAssetUrl('poster', 'poster6.jpg')
const poster7 = getAssetUrl('poster', 'poster7.jpg')
const poster8 = getAssetUrl('poster', 'poster8.jpg')

export const POSTER_LIST = [
    {
        url: poster1,
        id: 'poster1'
    },
    {
        url: poster2,
        id: 'poster2'
    },
    {
        url: poster3,
        id: 'poster3'
    },
    {
        url: poster4,
        id: 'poster4'
    },
    {
        url: poster5,
        id: 'poster5'
    },
    {
        url: poster6,
        id: 'poster6'
    },
    {
        url: poster7,
        id: 'poster7'
    },
    {
        url: poster8,
        id: 'poster8'
    }
]
