import { backgroundTransitionOnClick } from '@byecode/ui'
import { Group } from '@mantine/core'
import styled from 'styled-components'

export const BasicListItem = styled(Group).withConfig<{ clickable?: boolean; disablePadding?: boolean }>({
    shouldForwardProp: p => !['clickable', 'disablePadding'].includes(p)
})`
    min-height: 36px;
    font-size: 14px;
    padding: ${p => !p.disablePadding && '2px 12px'};
    border-radius: 4px;
    gap: 0;
    flex-wrap: nowrap;
    ${p => p.clickable && backgroundTransitionOnClick()}
`
