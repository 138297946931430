import { backgroundTransitionOnClick, Button, Message, pointer, Popover, Tooltip } from '@byecode/ui'
import { Flex, Text } from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import * as srv from '@/services'
import type { UpdateIntegrationPayload } from '@/services/types'
import { useOtherPlatformList } from '@/shared/reusable'

import { NONE_TEST_TYPES, otherPlatformColorMap, otherPlatformIconMap, otherPlatformNameMap } from '../constant'
import EditInput from '../EditInput'
import * as CM from '../styles'
import type { AppAccountItem, AppCountAction } from '../types'

interface OtherPlatformsProps {
    data: AppAccountItem
    onChangeAccount?: (id: string, action: AppCountAction) => void
}
export const SCxContainer = styled.div`
    margin: 0;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--color-gray-50);
    }
`

export const SCxItem = styled.div`
    padding-left: 16px;
    height: 57px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    flex: 1;
    font-size: 14px;
`

const SCxOperationItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 57px;
    padding: 0 16px;
    min-width: 258px;
    gap: 8px;
    flex: 0;
`
const SCxDropDownItem = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    ${backgroundTransitionOnClick}
    ${pointer}
`

export const OtherPlatformsItem: React.FunctionComponent<OtherPlatformsProps> = ({ data, onChangeAccount }) => {
    const [loading, setLoading] = useState(false)
    const [isSuccess, seIsSuccess] = useState(false)
    const { update } = useOtherPlatformList()

    const handleConnectTest = useCallback(async () => {
        setLoading(true)
        const isSuccess = await srv.getConnectionTest(data?.type, data.id)
        setLoading(false)
        seIsSuccess(isSuccess)
        if (!isSuccess) {
            Message.warning({
                title: '连接失败',
                description: '请点击【编辑账户】。'
            })
        }
    }, [data.id, data?.type])

    const handleUpdateName = useCallback(
        async (value: string) => {
            await srv.updateIntegration({
                type: data.type,
                name: value,
                id: data.id
            } as UpdateIntegrationPayload)
            update()
        },
        [data.id, data.type, update]
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isSuccess) {
                seIsSuccess(false)
            }
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [isSuccess])

    return (
        <SCxContainer>
            <SCxItem>
                <CM.Icon size={40} color={otherPlatformColorMap[data.type]} isHidePointer type={otherPlatformIconMap[data.type]} />
                <EditInput value={data.title || '未命名用户'} onBlur={(value: string) => handleUpdateName?.(value)} />
            </SCxItem>
            <SCxItem>
                <Text size={14} style={{ cursor: 'default' }}>
                    {otherPlatformNameMap[data.type]}
                </Text>
            </SCxItem>
            {/* <SCxItem>
                <Text size={14}>{data.createdTime}</Text>
            </SCxItem> */}
            <SCxOperationItem>
                {!NONE_TEST_TYPES.includes(data.type) && (
                    <Button
                        onClick={isSuccess ? undefined : handleConnectTest}
                        loading={loading}
                        size="sm"
                        style={{ width: 94, cursor: isSuccess ? 'not-allowed' : 'pointer' }}
                        icon={
                            <CM.Icon
                                color={isSuccess ? 'var(--color-green-400)' : 'var(--color-gray-400)'}
                                type={isSuccess ? 'TickCircle' : 'Binding'}
                            />
                        }
                    >
                        <Text color="var(--color-black)">{loading ? '正在测试' : isSuccess ? '连接成功' : '连接测试'}</Text>
                    </Button>
                )}
                <Button
                    onClick={() => {
                        onChangeAccount?.(data.id, 'EDIT')
                        seIsSuccess(false)
                    }}
                    color="var(--color-black)"
                    type="text"
                    size="sm"
                    style={{ width: 94 }}
                    icon={<CM.Icon color="var(--color-gray-400)" type="edit" />}
                >
                    <Text color="var(--color-black)">编辑账户</Text>
                </Button>

                <Popover position="bottom-end" width={204}>
                    <Popover.Target>
                        <div>
                            <Tooltip title="更多操作">
                                <CM.Icon type="DotsThree" isAction style={{ transform: 'rotate(90deg)' }} />
                            </Tooltip>
                        </div>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <SCxDropDownItem onClick={() => onChangeAccount?.(data.id, 'DELETE')}>删除账户</SCxDropDownItem>
                    </Popover.Dropdown>
                </Popover>
            </SCxOperationItem>
        </SCxContainer>
    )
}
