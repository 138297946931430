import type { FindUseADTObject } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { useFindUseContext } from '@/contexts/PageContext'

import { FindUseHeader } from './FindUseHeader'
import { FindUseLocation } from './FindUseLocation'
import { FindUseObject } from './FindUseObject'

const SCxContainer = styled.div`
    width: 300px;
    height: 100%;
    background: var(--color-gray-50);
    border-left: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: column;
`

export const FindUse: React.FC = () => {
    const { config } = useFindUseContext()

    const [state, setState] = useState(config)

    const handleChange = useCallback((val: FindUseADTObject) => {
        setState(val)
    }, [])

    useUpdateEffect(() => {
        setState(config)
    }, [config])

    return (
        <SCxContainer>
            <FindUseHeader />
            <FindUseObject config={state} onChange={handleChange} />
            {state && <FindUseLocation config={state} />}
        </SCxContainer>
    )
}
