import type {
    DataSourceAbstract,
    FieldBlockWithDsId,
    FieldType,
    FilterableProtocol,
    FilterBlockAbstract,
    FilterFormType,
    FilterMode,
    ViewField,
    ViewType
} from '@lighthouse/core'
import isEqual from 'fast-deep-equal'
import { clone, isEmpty } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import type { VariableSource } from '../../types'
import { FilterGroup } from './FilterGroup'
import * as SC from './styles'

interface FilterContentProps {
    children?: React.ReactNode
    filter?: FilterableProtocol['filter']
    dataSource?: DataSourceAbstract
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList: DataSourceAbstract[]
    sources?: VariableSource[]
    columns: ViewField[]
    primaryField?: string
    noSettingFields?: Set<FieldType>
    fieldMode?: FilterMode
    paramsMode?: FilterMode
    enableCurrentUser?: boolean
    formDsId?: string
    viewDsId?: string
    viewType?: ViewType
    filterBlocks?: FilterBlockAbstract[]
    enableFilterVariable?: boolean
    disabled?: boolean
    onFilter?: (val: FilterableProtocol['filter']) => void
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

export const FilterContent: React.FC<FilterContentProps> = ({
    filter,
    dataSource,
    fieldBlocksWithDsId,
    dataSourceList,
    sources,
    columns,
    primaryField,
    noSettingFields,
    fieldMode,
    paramsMode,
    enableCurrentUser,
    formDsId,
    viewDsId,
    viewType,
    filterBlocks,
    enableFilterVariable,
    disabled,
    onMouseEnter,
    onMouseLeave,
    onFilter
}) => {
    const filtersValue: FilterFormType = useMemo(() => {
        if (isEmpty(filter) || !filter) {
            return {
                expression: {
                    where: 'AND',
                    conditions: []
                }
            }
        }
        if (filter.expression && !filter.expression?.where) {
            return {
                expression: {
                    where: 'AND',
                    conditions: filter.expression?.conditions || []
                }
            }
        }
        return filter
    }, [filter])
    const methods = useForm<FilterFormType>({
        mode: 'onChange',
        defaultValues: filtersValue
    })

    const { control } = methods

    const handleFilter = useCallback(
        (data: FilterableProtocol['filter']) => {
            if (data) {
                onFilter?.(clone(data))
            }
        },
        [onFilter]
    )

    const formData = useWatch({ control })

    // const debouncedFormData = useDebounce(formData, 500)

    useUpdateEffect(() => {
        if (formData && !isEqual(formData, filter)) {
            handleFilter(formData || {})
        }
    }, [formData])

    return (
        <SC.PropertySettingWrapper>
            <FormProvider {...methods}>
                <SC.FilterForm>
                    <SC.PropertySettingTitle>筛选条件</SC.PropertySettingTitle>
                    <FilterGroup
                        columns={columns}
                        dataSource={dataSource}
                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                        sources={sources}
                        dataSourceList={dataSourceList}
                        primaryField={primaryField}
                        prefixName="expression"
                        fieldMode={fieldMode}
                        paramsMode={paramsMode}
                        enableCurrentUser={enableCurrentUser}
                        noSettingFields={noSettingFields}
                        formDsId={formDsId}
                        viewDsId={viewDsId}
                        viewType={viewType}
                        disabled={disabled}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        filterBlocks={filterBlocks}
                        enableFilterVariable={enableFilterVariable}
                    />
                </SC.FilterForm>
            </FormProvider>
        </SC.PropertySettingWrapper>
    )
}
