import { IconFont, Tooltip } from '@byecode/ui'
import type { AppUser, DataSourceAbstract, Device, FieldBlockWithDsId, FilterFormType } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, CustomViewVisibleData, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { deviceLabelMap, filterToString } from '@lighthouse/shared'
import { isEmpty } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxTooltipContainer = styled.div`
    padding: 0 4px;
    color: var(--color-white);
    background: var(--color-gray-900);
`

const SCxVisibleContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    height: 24px;
    border-radius: 6px;
    background-color: #1b1855;
`

const SCxVertical = styled.div`
    height: 100%;
    width: 1px;
    background-color: #403c85;
`

const SCxVisibleWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
`

const SCxText = styled.p`
    white-space: pre;
`

// const SCxSpace = styled.div`
//     height: 22px;
// `



export interface VisibleDomProps {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    filter?: FilterFormType
    device?: Device[]
    personOptions: AppUser[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const VisibleDom: React.FC<VisibleDomProps> = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    filter,
    device,
    personOptions,
    fieldBlocksWithDsId
}) => {
    const title = useMemo(
        () =>
            filter
                ? filterToString({ customViewData, currentPage, prevPage, dataSourceList, filter, personOptions, fieldBlocksWithDsId })
                : undefined,
        [currentPage, customViewData, dataSourceList, fieldBlocksWithDsId, filter, personOptions, prevPage]
    )

    const filterLabel = useMemo(
        () => (
            <SCxTooltipContainer>
                <SCxText>符合以下条件时显示该组件：</SCxText>
                {title && title.map((str, index) => <SCxText key={index}>{str}</SCxText>)}
            </SCxTooltipContainer>
        ),
        [title]
    )

    const isDeviceVisible = useMemo(() => {
        return device?.length === 1
    }, [device])

    const isVisible = useMemo(() => {
        return !device || device?.length === 0
    }, [device])

    const isFilterVisible = useMemo(() => {
        return title && !isEmpty(title)
    }, [title])

    const deviceOption = useMemo(() => {
        if (!device || device.length === 0) {
            return { label: '所有设备不可见', icon: 'NoEye' }
        }
        if(device.length === 2){
            return undefined
        }
        return deviceLabelMap[device[0]]
    }, [device])

    return (
        <SCxVisibleContainer>
            {isFilterVisible && !isVisible && (
                <Tooltip title={filterLabel}>
                    <SCxVisibleWrapper>
                        <IconFont fill="white" size={16} type="EyeOutline" />
                    </SCxVisibleWrapper>
                </Tooltip>
            )}
            {isDeviceVisible && isFilterVisible && <SCxVertical />}
            {deviceOption && (
                <Tooltip title={deviceOption.label}>
                    <SCxVisibleWrapper>
                        <IconFont fill="white" size={16} type={deviceOption.icon} />
                    </SCxVisibleWrapper>
                </Tooltip>
            )}
        </SCxVisibleContainer>
    )
}
