import { IconFont, Input, tinyButtons } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 240px;
    border-right: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const ErItemWrapper = styled.div<{ active: boolean }>`
    height: 36px;
    display: flex;
    align-items: center;
    margin: 16px 8px;
    padding: 7px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }

    ${({ active }) => {
        return (
            active &&
            css`
                background-color: var(--color-gray-100);
            `
        )
    }}
`

export const ErItemIcon = styled(IconFont)`
    margin-right: 8px;
`

export const ErItemText = styled.div`
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-gray-600);
`

export const Header = styled.div`
    width: 100%;
    height: 36px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const SearchWrapper = styled.div`
    padding: 12px 12px 8px 12px;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`

export const ListWrapper = styled.div`
    flex: 1;
    overflow-y: auto;
`

export const Space = styled.div`
    width: 100%;
    height: 188px;
`

export const Search = styled(Input)``

export const SearchIcon = styled(IconFont)``

export const GroupName = styled.div`
    color: var(--color-gray-400);
    font-size: 12px;
`

export const List = styled.div`
    position: relative;
    width: 100%;

    ${tinyButtons}
`
