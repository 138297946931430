import type { SelectProps } from '@byecode/ui'
import { Flex,IconFont, Menu } from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared'
import { find, map } from 'rambda'
import * as React from 'react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useDataSourceUserGroups } from '@/shared/reusable'

type UserGroupSelectProps = SelectProps

const SCxClear = styled(TagIcon)`
cursor: pointer;
    &:hover {
        color: var(--color-black);
    }
`

const UserGroupSelect: React.FC<UserGroupSelectProps> = ({ value, onChange, ...rest }) => {
    const userGroups = useDataSourceUserGroups()
    const [opened, setOpened] = useState(false)

    const userGroupOptions = useMemo(
        () =>
            map(
                item => ({
                    label: item.groupName,
                    value: item.groupId
                    // icon: 'SpaceTeam'
                }),
                userGroups
            ),
        [userGroups]
    )

    const currentItem = useMemo(() => find(item => item.value === value, userGroupOptions), [userGroupOptions, value])

    return (
        <Menu opened={opened} onChange={setOpened} trigger="click" withinPortal position="bottom-start" closeOnItemClick width={160}>
            <Menu.Target>
                <Flex justifyContent="space-between" gap={6}>
                    {currentItem?.label ?? '未选择'}
                    <Flex gap={4} alignItems='center'>
                        {currentItem && (
                            <SCxClear
                                icon="CloseCircle"
                                color="var(--color-gray-400)"
                                hoverColor="var(--color-black)"
                                size={16}
                                iconSize={16}
                                onClick={e => {
                                    e.stopPropagation()
                                    onChange?.('')
                                }}
                            />
                        )}
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />
                    </Flex>
                </Flex>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Dropdown>
                    {userGroupOptions.map(item => (
                        <Menu.Item
                            key={item.value}
                            onClick={() => {
                                onChange?.(item.value)
                            }}
                        >
                            {item.label}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu.Dropdown>
        </Menu>
        // <GroupSelect
        //     ref={selectRef}
        //     {...rest}
        //     options={userGroupOptions}
        //     style={{ borderStyle: 'none', backgroundColor: 'inherit', width: 'auto' }}
        //     downIcon="ArrowDownSmall"
        //     placeholder="未选择"
        // />
    )
}

export default UserGroupSelect
