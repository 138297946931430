import { Input } from '@byecode/ui'
import type { SendMsgDingTalkRobotRootNode, VariableOptions } from '@lighthouse/shared'
import { FieldClassMap, NodeFieldPreview, optionFilterFieldType } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import * as SC from './styles'

interface LinkMsgConfigProps {
    options: VariableOptions
}

export const LinkMsgConfig: React.FunctionComponent<LinkMsgConfigProps> = ({ options }) => {
    const { control, register, watch, setValue } = useFormContext<Pick<SendMsgDingTalkRobotRootNode, 'config'>>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const textOptions = useMemo(
        () => optionFilterFieldType(dataSourceList, options, field => FieldClassMap[field.type].has('text')),
        [dataSourceList, options]
    )

    return (
        <>
            <SC.Item>
                <SC.Text>
                    消息标题
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                {/* <AddFieldButton schema={schema} fieldIds={fieldIds} onItemClick={handleItemClick} /> */}
                <Controller
                    control={control}
                    name="config.link.editorTitle"
                    render={({ field }) => <NodeFieldPreview {...field} autoHeight options={textOptions} />}
                />
                {/* <Input {...register('config.link.title')} /> */}
                <SC.Description>方案启动后，填入的文本内容，将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
            <SC.Item>
                <SC.Text>
                    消息内容
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                <Controller
                    control={control}
                    name="config.link.editorText"
                    render={({ field }) => <NodeFieldPreview {...field} autoHeight options={textOptions} />}
                />
                <SC.Description>方案启动后，填入的文本内容，将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
            <SC.Item>
                <SC.Text>
                    消息缩略图
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                <Input placeholder="请输入" {...register('config.link.picUrl')} />
                {/* <Controller
                    control={control}
                    name="config.link.editorPicUrl"
                    render={({ field }) => <NodeFieldPreview {...field} variable={fileVariable} />}
                /> */}
                <SC.Description>可以添加变量或填入文本，内容将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
            <SC.Item>
                <SC.Text>
                    跳转链接
                    <SC.Text color="red" style={{ paddingLeft: 4 }}>
                        *
                    </SC.Text>
                </SC.Text>
                <Controller
                    control={control}
                    name="config.link.editorMessageUrl"
                    render={({ field }) => <NodeFieldPreview {...field} autoHeight options={textOptions} />}
                />
                <SC.Description>可以添加变量或填入文本，内容将被钉钉群机器人发出，请检查核对。</SC.Description>
            </SC.Item>
        </>
    )
}
