import { ColorPicker, Input, Popover } from '@byecode/ui'
import type { ApplicationSettingWebTag, ApplicationWebsiteSetting } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { useApplicationSetting } from '@/hooks/useApplication'

import SettingBlock from '../SettingBlock'
import { SettingCard } from '../SettingCard'
import { SettingItem } from '../SettingItem'
import SettingWebApp from '../SettingWebApp'
import * as CM from '../styles'
import WebTagLogo from './WebTagLogo'

interface SettingWebTagProps {}

const SCxContain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const SCxTarget = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin: 4px 0;
    border-radius: 6px;
    background: var(--color-gray-100);
`

const SCxPatches = styled.div<{ background?: string }>`
    width: 26px;
    height: 26px;
    background: ${props => props.background};
    border-radius: 3px;
`

const SettingWebTag: React.FC<SettingWebTagProps> = () => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)

    const methods = useForm<ApplicationSettingWebTag>({
        mode: 'onChange',
        defaultValues: appSetting?.webTag
    })
    const { watch, control, register } = methods
    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingWebTag) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        webTag: value
                    }
                })
            }),
        [updateAppSetting]
    )

    // useEffect(() => {
    //     reset(appSetting.webTag)
    // }, [appSetting, reset])

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingWebTag)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <SCxContain>
            <SettingCard title="应用信息">
                <SettingItem label="应用图标">
                    <Controller control={control} name="logo" render={({ field }) => <WebTagLogo {...field} />} />
                </SettingItem>
                <SettingItem label="应用标题">
                    <div style={{ margin: '4px 0' }}>
                        <Input {...register('title')} disabled={false} placeholder="请输入关键字" />
                    </div>
                </SettingItem>
                <SettingItem label="背景颜色">
                    <Controller
                        name="backgroundColor"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Popover width={262} position="bottom-start">
                                    <Popover.Target>
                                        <SCxTarget>
                                            <SCxPatches background={field.value} />
                                        </SCxTarget>
                                    </Popover.Target>
                                    <Popover.Dropdown unstyled>
                                        <ColorPicker value={field.value} onChange={field.onChange} />
                                    </Popover.Dropdown>
                                </Popover>
                            )
                        }}
                    />
                </SettingItem>
            </SettingCard>
            <SettingWebApp />
        </SCxContain>
    )
}

export default SettingWebTag
