import { Box, Flex, IconFont } from '@byecode/ui'
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import cls from 'classnames'
import { add, multiply } from 'rambda'
import React, { forwardRef, useCallback, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'

const collapsedStyle = {
    display: 'none',
    height: 0,
    overflow: 'hidden'
}

const SCxContainer = styled.div<{ paddingLeft?: number; border?: boolean }>`
    border-radius: ${({ border }) => (border ? 0 : '6px')};
    overflow: hidden;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}px` : 0)};
`

const SCxHeader = styled(Flex)<{ border?: boolean }>`
    padding: 0 8px;
    height: 36px;
    ${({ border }) =>
        border &&
        css`
            border-color: var(--color-gray-200);
            border-style: solid;
            border-width: 0 1px 1px 1px;
        `};

    &.merge-header {
        position: relative;
        z-index: 1;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
`

const SCxLabel = styled.div`
    flex: 1;
`

export interface MergeGroupProps {
    defaultOpen?: boolean
    opened?: boolean
    iconColor?: string
    onCollapseChange?: (v: boolean) => void
    /** 分组名称 */
    headerBackground?: string
    label: React.ReactNode
    extra?: React.ReactNode
    children?: React.ReactNode
    level?: number
    enableBorder?: boolean
    enableFixed?: boolean
}

export const MergeGroup = forwardRef<HTMLDivElement, MergeGroupProps>((props, ref) => {
    const {
        defaultOpen = true,
        opened,
        iconColor,
        onCollapseChange,
        headerBackground,
        label,
        extra,
        children,
        level = 0,
        enableBorder,
        enableFixed
    } = props

    const el = useRef<HTMLDivElement | null>(null)
    const [open, setOpen] = useUncontrolled({ value: opened, onChange: onCollapseChange, defaultValue: defaultOpen })

    const [internalCollapseStyle, setInternalCollapseStyle] = useState<React.CSSProperties>(open ? {} : collapsedStyle)

    const handleClick = useCallback(() => {
        setOpen(!open)
    }, [open, setOpen])

    useUpdateEffect(() => {
        if (open) {
            requestAnimationFrame(() => {
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, willChange: 'height', display: 'block', overflow: 'hidden' }))
                })
                requestAnimationFrame(() => {
                    const height = el.current ? el.current.scrollHeight : 'auto'
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height }))
                    })
                })
            })
        } else {
            requestAnimationFrame(() => {
                const height = el.current ? el.current.scrollHeight : 'auto'
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height, willChange: 'height' }))
                })
                requestAnimationFrame(() => {
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, height: 0, overflow: 'hidden' }))
                    })
                })
            })
        }
    }, [open])

    const transitionEndHandle = useCallback(
        (e: React.TransitionEvent<HTMLDivElement>) => {
            if (e.target !== el.current || e.propertyName !== 'height') {
                return
            }
            if (open) {
                setInternalCollapseStyle({})
            } else {
                setInternalCollapseStyle(collapsedStyle)
            }
        },
        [open]
    )

    return (
        <SCxContainer ref={ref} border={enableBorder} className={cls({ 'merge-group': enableFixed })}>
            <SCxHeader
                className={cls({ 'merge-header': enableFixed })}
                alignItems="center"
                gap={8}
                border={enableBorder}
                onClick={handleClick}
                style={{ background: headerBackground, paddingLeft: add(multiply(22, level), 8) }}
            >
                <Flex>
                    <IconFont
                        type="ArrowDownSmall"
                        size={16}
                        fill={iconColor}
                        style={{ transform: open ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
                    />
                </Flex>

                <SCxLabel>{label}</SCxLabel>

                <Flex>{extra}</Flex>
            </SCxHeader>
            <Box ref={el} onTransitionEnd={transitionEndHandle} style={internalCollapseStyle}>
                {children}
            </Box>
        </SCxContainer>
    )
})
