import { Button, Modal, ModalConfirm } from '@byecode/ui'
import type { AggregateProtocol, DataViewProtocol } from '@lighthouse/core'
import { type AggregatorNode, AggregatorNodeType, useAtomAction } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import { filter } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useBlocker, useNavigate } from 'react-router'
import { useBeforeUnload } from 'react-use'
import type { Edge } from 'reactflow'
import styled from 'styled-components'

import { deleteDataSourceAtom, getDataSourceMetaAtom, setCurrentDataSourceIdAtom, updateDataSourceAtom } from '@/atoms/dataSource/action'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSource, useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import { AggregatorDetail } from './AggregatorDetail'
import { DataAggregatorHeader } from './AggregatorHeader'

export interface DataAggregatorProps {
    dataSourceId: string
}

const SCxDataAggregatorWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const DataAggregator: React.FC<DataAggregatorProps> = ({ dataSourceId }) => {
    const navigate = useNavigate()
    const currentAppId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(currentAppId, envId)
    const dataSource = useDataSource(currentAppId, envId, dataSourceId)
    const { run: updateDataSource } = useAtomAction(updateDataSourceAtom)
    const { run: getDataSourceMeta } = useAtomAction(getDataSourceMetaAtom)
    const { run: deleteDataSource } = useAtomAction(deleteDataSourceAtom)
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    const [isChanged, setIsChanged] = useState(false)
    const [isSaveAndLeaveLoading, setIsSaveAndLeaveLoading] = useState(false)
    const handleBack = useCallback(() => {
        navigate(`/${currentAppId}/dataSource/${dataSourceId}`)
    }, [currentAppId, dataSourceId, navigate])

    const { analyticConfig, draftAnalyticConfig } = useMemo(() => {
        if (!dataSource) {
            return { analyticConfig: undefined, draftAnalyticConfig: undefined }
        }
        const {
            viewOptions: { analyticConfig, draftAnalyticConfig }
        } = dataSource
        return { analyticConfig, draftAnalyticConfig }
    }, [dataSource])

    const analyticConfigRef = useRef<AggregateProtocol['analyticConfig']>(draftAnalyticConfig)

    const handleCancel = useCallback(() => {
        if (!analyticConfig) {
            handleBack()
            return
        }

        if (isDeepEqual(analyticConfigRef.current, analyticConfig)) {
            handleBack()
            return
        }

        updateDataSource({
            envId,
            dsId: dataSourceId,
            params: {
                viewOptions: {
                    ...dataSource?.viewOptions,
                    draftAnalyticConfig: analyticConfig
                } as DataViewProtocol['viewOptions']
            }
        })
        handleBack()
    }, [analyticConfig, dataSource?.viewOptions, dataSourceId, envId, handleBack, updateDataSource])

    const handleSave = useCallback(async () => {
        if (isDeepEqual(analyticConfigRef.current, analyticConfig)) {
            return true
        }
        const notValidNode = document.querySelectorAll('[data-state="inactive"]')
        if (!analyticConfigRef.current || notValidNode.length > 0) {
            return false
        }
        const { nodes, edges } = analyticConfigRef.current
        const lastNode: AggregatorNode = nodes.find(node => !edges.some(edge => edge.source === node.id))
        const tableProps = lastNode
            ? lastNode.data.result?.fieldList?.map(aggregationField => ({
                  id: aggregationField.id,
                  visible: aggregationField.visible,
                  width: 160
              }))
            : []
        const isUpdate = await updateDataSource({
            envId,
            dsId: dataSourceId,
            params: {
                viewOptions: {
                    ...dataSource?.viewOptions,
                    analyticConfig: analyticConfigRef.current,
                    tableProps
                } as DataViewProtocol['viewOptions']
            }
        })
        if (isUpdate) {
            setIsChanged(false)
            getDataSourceMeta({
                envId,
                dsId: dataSourceId
            })
        }
        return isUpdate
    }, [analyticConfig, dataSource?.viewOptions, dataSourceId, envId, getDataSourceMeta, updateDataSource])

    const handleConfigureChange = useCallback(
        async (nodes: AggregatorNode[], edges: Edge[]) => {
            setIsChanged(true)
            const omitAdderNodes = filter(node => node.type !== AggregatorNodeType.ADD_PLACEHOLDER, nodes)
            const omitAdderEdges = filter(edge => edge.target !== 'add-placeholder', edges)
            const isSuccess = await updateDataSource({
                envId,
                dsId: dataSourceId,
                params: {
                    viewOptions: {
                        ...dataSource?.viewOptions,
                        draftAnalyticConfig: {
                            edges: omitAdderEdges,
                            nodes: omitAdderNodes
                        }
                    } as DataViewProtocol['viewOptions']
                }
            })
            if (isSuccess) {
                analyticConfigRef.current = {
                    edges: omitAdderEdges,
                    nodes: omitAdderNodes
                }
            }
        },
        [dataSource?.viewOptions, dataSourceId, envId, updateDataSource]
    )

    const handleSelectDataSource = useCallback(
        (dsId: string) => {
            if (dsId) {
                setCurrentDataSourceId({ dsId })
                navigate({ pathname: `/${currentAppId}/dataSource/${dsId}` })
                return
            }
            navigate({ pathname: `/${currentAppId}/dataSource` })
        },
        [currentAppId, navigate, setCurrentDataSourceId]
    )

    const handleChangeName = useCallback(
        (val: string) => {
            updateDataSource({
                envId,
                dsId: dataSourceId,
                params: {
                    name: val
                }
            })
        },
        [dataSourceId, envId, updateDataSource]
    )

    const handleDelete = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认删除',
            content: `确认删除数据源「${dataSource?.name ?? '未命名数据源'}」？`,
            okStatus: 'error'
        })
        if (isConfirm) {
            const isDelete = await deleteDataSource({
                envId,
                dsId: dataSourceId
            })
            if (isDelete) {
                const newList = dataSourceList.filter(item => item.id !== dataSourceId)
                if (newList.length > 0) {
                    const ds = newList[0]
                    handleSelectDataSource?.(ds.id)
                    return isDelete
                }
                handleSelectDataSource?.('')
            }
        }
    }, [dataSource?.name, dataSourceId, dataSourceList, deleteDataSource, envId, handleSelectDataSource])

    // Block navigating elsewhere when data has been entered into the input
    useBeforeUnload(isChanged, '你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？')
    const blocker = useBlocker(({ currentLocation, nextLocation }) => isChanged && currentLocation.pathname !== nextLocation.pathname)

    const handleSaveAndLeave = useCallback(async () => {
        setIsSaveAndLeaveLoading(true)
        await handleSave()
        setIsSaveAndLeaveLoading(false)
        blocker?.proceed?.()
    }, [blocker, handleSave])

    return (
        <SCxDataAggregatorWrapper>
            {dataSource && (
                <>
                    <DataAggregatorHeader
                        title={dataSource.name}
                        updated={isChanged}
                        onCancel={handleCancel}
                        onSave={handleSave}
                        onDelete={handleDelete}
                        onChangeName={handleChangeName}
                    />
                    <AggregatorDetail dataSource={dataSource} onChange={handleConfigureChange} />
                </>
            )}
            {blocker.state === 'blocked' && (
                <ModalConfirm
                    title="聚合表配置有修改，是否保存？"
                    content="你修改了聚合表配置但没有保存，是否需要保存聚合表配置并继续？"
                    footerExtra={
                        <Button type="text" size="sm" onClick={blocker.reset}>
                            返回设计
                        </Button>
                    }
                    onReject={blocker.proceed}
                    onResolve={handleSaveAndLeave}
                    okStatus="primary"
                    okText="保存并继续"
                    okProps={{ loading: isSaveAndLeaveLoading }}
                    cancelText="不保存"
                />
            )}
        </SCxDataAggregatorWrapper>
    )
}
