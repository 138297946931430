import React, { useMemo } from 'react'
import { useWindowSize } from 'react-use'

import { ApplicationPreviewEnum } from '../../types'
import { getApplicationPreviewType } from '../../utils'
import { MobileAccountLayout } from './mobile'
import type { AccountLayoutProps } from './types'
import { WebAccountLayout } from './WebAccountLayout'

export * from './types'

export const AccountLayout: React.FunctionComponent<AccountLayoutProps> = props => {
    const preview = useMemo(() => getApplicationPreviewType(), [])
    return preview === ApplicationPreviewEnum.desktop ? <WebAccountLayout {...props} /> : <MobileAccountLayout {...props} />
}
