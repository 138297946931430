import React from 'react'
import styled from 'styled-components'

import { PerfectInfo } from '@/containers/Account/PerfectInfo'

const SCxContainer = styled.div``

const PerfectUserInfo: React.FC = () => {
    return <PerfectInfo />
}

export default PerfectUserInfo
