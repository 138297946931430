import { flex, pointer, Toast } from '@byecode/ui'
import { logoMaxUploadSize, UploadClickDropZone } from '@lighthouse/shared'
import { useAbortAll, useItemFinishListener, useItemStartListener } from '@rpldy/uploady'
import cls from 'classnames'
import * as React from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface SettingLogoProps {
    value?: string
    style?: React.CSSProperties
    icon?: string
    defaultIcon?: React.ReactNode

    onChange?: (url: string) => void
}

const ImageContain = styled.div`
    ${pointer}
    /* padding: 4px; */
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
`
const ImageWrapper = styled.div`
    position: relative;
    ${flex}
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: var(--color-gray-100);

    &.active::before {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: '更换图片';
        width: 100%;
        height: 19px;
        letter-spacing: 1px;
        background: rgba(56, 56, 56, 0.4);
        border-radius: 0px 0px 4px 4px;
        z-index: 9;
        text-align: center;
        font-size: var(--font-size-sm);
        color: var(--color-white);
    }
`

const CloseBtn = styled.div`
    position: absolute;
    top: -7px;
    right: -7px;
    width: 15px;
    height: 15px;
    color: var(--color-white);
    border-radius: 100%;
    z-index: 2;
    text-align: center;
    color: var(--color-white);
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: contain;
`

const SettingLogo: React.FC<SettingLogoProps> = ({ value, style, defaultIcon, icon = 'Add', onChange }) => {
    const abortAll = useAbortAll()
    useItemStartListener(item => {
        const currentSize = item.file.size
        if (currentSize > logoMaxUploadSize) {
            Toast.warning('当前LOGO超出2M, 请重新选择')
            abortAll()
        }
    })

    useItemFinishListener(item => {
        const { id, uploadResponse } = item
        if (uploadResponse.data.content) {
            onChange?.(uploadResponse.data.content.url)
        }
    })
    const handleDelete = React.useCallback(
        (ev: React.MouseEvent) => {
            ev.stopPropagation()
            onChange?.('')
        },
        [onChange]
    )

    return (
        // <RegisterBlock label="Logo">
        <UploadClickDropZone>
            <ImageContain style={style}>
                <ImageWrapper className={cls({ active: !!value })}>
                    {value && (
                        <CloseBtn onClick={handleDelete}>
                            <CM.Icon color="var(--color-black)" type="CloseCircle" />
                        </CloseBtn>
                    )}
                    {value ? <Image src={value} /> : defaultIcon || <CM.Icon type={icon} color="var( --color-gray-400)" />}
                </ImageWrapper>
            </ImageContain>
        </UploadClickDropZone>
        // </RegisterBlock>
    )
}

export default SettingLogo
