import { Breadcrumbs, Button, IconFont, Input, Modal, Popover, SelectDropdown } from '@byecode/ui'
import { getBlockName } from '@lighthouse/block'
import type { BlockAbstract, BlockType } from '@lighthouse/core'
import { PageType } from '@lighthouse/core'
import { LinkDocument, transformNode2FlowLayoutNode, useAtomAction, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { getNextIndexByDeleteList } from '@lighthouse/tools'
import { Flex, Menu } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { removePageAtom, updateBlockAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'

import { getNodePath } from '../PageContentV2/utils'

export const PageBreadcrumbs: React.FC = () => {
    const pageMeta = useAtomData(lastPageOfStackAtom)
    const { pageId = '', rootPageId = '', stackId = '', blockRuntimeState, state } = pageMeta ?? {}
    const { asideType, selectedNode } = state ?? {}

    const pageContent = useAtomData(pageAtomFamily(pageId))
    const disabledWithVersion = useIsDisabledWithVersion()

    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )
    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s[pageId] ?? [], [pageId])
    )

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const pageName = pageContent?.name || '无标题'

    const appId = useCurrentAppID()
    const pageList = usePageList()
    const { run: removePage } = useAtomAction(removePageAtom)
    const { run: updateBlock } = useAtomAsyncAction(updateBlockAtom)

    const [documentType, subNodeType] = useMemo(() => {
        if (selectedNode) {
            const block = pageBlocks.find(item => item.id === selectedNode)
            if (block?.type === 'view') {
                return [block?.type, block.config.viewType]
            }
            if (block?.type === 'chart') {
                return [block?.type, block.config.chartType]
            }
            if (block?.type === 'field') {
                return [block?.type, block.config.inputType]
            }
            return [block?.type]
        }
        if (asideType) {
            return ['navbar' as unknown as BlockType]
        }
        return []
    }, [asideType, pageBlocks, selectedNode])

    /** 删除页面 */
    const onDeletePage = useCallback(() => {
        Modal.confirm({
            title: '确认删除页面',
            content: `确认删除页面「${pageName ?? '无标题'} 」？`,
            okStatus: 'error',
            okText: '删除'
        })?.then(async s => {
            if (s) {
                if (!pageList) {
                    return
                }
                await removePage(pageId)

                setPageStack(draft => {
                    draft.splice(0)
                    const rootPageList = pageList.filter(item => item.type === PageType.default)
                    const index = rootPageList.findIndex(item => item.id === pageId)
                    const nextIndex = getNextIndexByDeleteList(index, rootPageList)
                    const nextPageId = rootPageList[nextIndex].id
                    if (nextPageId) {
                        draft.push(
                            stackFactory({
                                appId,
                                pageId: nextPageId
                            })
                        )
                    }
                })
            }
        })
    }, [appId, pageId, pageList, pageName, removePage, setPageStack])

    const hasExtraMenu = useMemo(() => {
        return asideType === AsideType.PAGE && rootPageId !== pageId
    }, [asideType, pageId, rootPageId])

    const [editBlock, setEditBlock] = useState<BlockAbstract | null>(null)

    useUpdateEffect(() => {
        setEditBlock(null)
    }, [selectedNode])

    const breadcrumbs = useMemo(() => {
        switch (asideType) {
            case AsideType.BLOCK: {
                if (!selectedNode) {
                    break
                }

                if (editBlock) {
                    const label = editBlock.title || getBlockName(editBlock)
                    return [
                        {
                            label: (
                                <Input
                                    disabled={disabledWithVersion}
                                    size="sm"
                                    defaultValue={label}
                                    onBlur={e => {
                                        const v = e.currentTarget.value
                                        if (v && v !== label) {
                                            updateBlock({
                                                pageId,
                                                rootPageId,
                                                stackId,
                                                appId,
                                                origin: editBlock,
                                                block: { ...editBlock, title: v }
                                            })
                                        }
                                        setEditBlock(null)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            const v = e.currentTarget.value
                                            if (v && v !== label) {
                                                updateBlock({
                                                    pageId,
                                                    rootPageId,
                                                    stackId,
                                                    appId,
                                                    origin: editBlock,
                                                    block: { ...editBlock, title: v }
                                                })
                                            }
                                            setEditBlock(null)
                                        }
                                    }}
                                    autoFocus
                                    autoSelect
                                />
                            )
                        }
                    ]
                }

                const nodes = transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState)
                const paths = getNodePath(selectedNode)(nodes)

                const needFold = paths.length > 3

                if (needFold) {
                    const foldOptions = paths.slice(1, -1).map(path => {
                        const block = pageBlocks.find(item => item.id === path.id)
                        if (!block) {
                            return {
                                label: '',
                                value: ''
                            }
                        }

                        return {
                            label: block.title || getBlockName(block),
                            value: block.id
                        }
                    })

                    const firstBlock = pageBlocks.find(item => item.id === paths[0].id)
                    const lastBlock = pageBlocks.find(item => item.id === paths[paths.length - 1].id)

                    return [
                        {
                            label: firstBlock ? (
                                <span
                                    onClick={() => {
                                        setPageStack(draft => {
                                            const stack = equalPageStack({ rootPageId, stackId })(draft)
                                            if (stack) {
                                                stack.state.selectedNode = firstBlock.id
                                            }
                                        })
                                    }}
                                >
                                    {firstBlock.title || getBlockName(firstBlock)}
                                </span>
                            ) : (
                                ''
                            )
                        },
                        {
                            label: (
                                <Popover width={180} withinPortal>
                                    <Popover.Target>
                                        <span>...</span>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <SelectDropdown
                                            options={foldOptions}
                                            onSelect={id => {
                                                setPageStack(draft => {
                                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                    if (stack) {
                                                        stack.state.selectedNode = id
                                                    }
                                                })
                                            }}
                                        />
                                    </Popover.Dropdown>
                                </Popover>
                            )
                        },
                        {
                            label: lastBlock ? (
                                <div
                                    onClick={() => {
                                        setEditBlock(lastBlock)
                                    }}
                                >
                                    <span>{lastBlock.title || getBlockName(lastBlock)}</span>
                                    <IconFont type="Edit" size={12} color="var(--color-gray-400)" />
                                </div>
                            ) : (
                                ''
                            )
                        }
                    ]
                }

                return paths.map((path, index, arr) => {
                    const block = pageBlocks.find(item => item.id === path.id)

                    if (!block) {
                        return { label: '' }
                    }

                    const label = block.title || getBlockName(block)
                    const isLast = index === arr.length - 1

                    if (isLast) {
                        return {
                            label: (
                                <div
                                    onClick={() => {
                                        setEditBlock(block)
                                    }}
                                >
                                    <span>{label}</span>
                                    <IconFont type="Edit" size={12} color="var(--color-gray-400)" />
                                </div>
                            )
                        }
                    }

                    return {
                        label: (
                            <span
                                onClick={() => {
                                    setPageStack(draft => {
                                        const stack = equalPageStack({ rootPageId, stackId })(draft)
                                        if (stack) {
                                            stack.state.selectedNode = block.id
                                        }
                                    })
                                }}
                            >
                                {label}
                            </span>
                        )
                    }
                })
            }

            case AsideType.NAVBAR: {
                return [{ label: '导航栏' }]
            }

            case AsideType.PAGE: {
                return [{ label: pageName }]
            }

            default: {
                break
            }
        }

        return []
    }, [
        appId,
        asideType,
        blockRuntimeState,
        disabledWithVersion,
        editBlock,
        pageBlocks,
        pageId,
        pageName,
        pageNodes,
        rootPageId,
        selectedNode,
        setPageStack,
        stackId,
        updateBlock
    ])

    return (
        <Breadcrumbs
            styles={{
                root: {
                    flex: 1
                },
                body: {
                    margin: '-8px 0'
                },
                item: {
                    cursor: 'pointer'
                }
            }}
            separator=" / "
            items={breadcrumbs}
            extra={[
                <Flex key="breadcrumbs_extra" align="center" gap={4}>
                    {hasExtraMenu ? (
                        <Menu key="menu" shadow="sm">
                            <Menu.Target>
                                <Button
                                    size="xs"
                                    type="text"
                                    style={{ width: 20, height: 20 }}
                                    icon={<IconFont type="DotsThree" size={16} />}
                                />
                            </Menu.Target>
                            <Menu.Dropdown>
                                {asideType === AsideType.PAGE && (
                                    <Menu.Item key="del-page" onClick={onDeletePage}>
                                        删除
                                    </Menu.Item>
                                )}
                            </Menu.Dropdown>
                        </Menu>
                    ) : null}
                    <LinkDocument type={documentType} subNodeType={subNodeType} />
                </Flex>
            ]}
        />
    )
}
