import { IconFont } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType, FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    getAppointField,
    getIsFindUseInFilter,
    getIsFindUseInSort,
    getViewColumns,
    useAtomData,
    useFindUseObjectContext,
    VariableSourceType,
    ViewFieldFilterConfigure,
    viewNotFilterSettingFieldTypes,
    ViewSortConfigure
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { ListItemMenu } from '../Common/ListItemMenu'

interface DataSettingProps {
    prefixName: string
    dataSource: DataSourceAbstract
}

export const DataSetting: React.FC<DataSettingProps> = ({ prefixName, dataSource }) => {
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const disabledWithVersion = useIsDisabledWithVersion()
    const { curr, prev, dataSourceList } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const {
        id: dsId,
        viewOptions: { tableProps },
        schema
    } = dataSource
    const columns = getViewColumns({
        tableProps,
        schema
    })

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    return (
        <>
            <Controller
                name={`${prefixName}.filter`}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const filter = value
                    const filtersLength = filter?.expression?.conditions?.length || 0
                    const isHighLight = getIsFindUseInFilter({
                        findUseObject,
                        data: filter,
                        currentDsId: dsId
                    })
                    return (
                        <ListItemMenu
                            width="auto"
                            compact
                            highlighting={isHighLight}
                            enableHover={false}
                            label="筛选数据"
                            positionDependencies={[filter]}
                            value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                            popupContent={
                                <ViewFieldFilterConfigure
                                    dataSource={dataSource}
                                    dataSourceList={dataSourceList}
                                    sources={sources}
                                    columns={columns}
                                    primaryField={primaryField}
                                    filter={filter}
                                    paramsMode={FilterMode.CUSTOM}
                                    noSettingFields={viewNotFilterSettingFieldTypes}
                                    disabled={disabledWithVersion}
                                    onFilter={onChange}
                                />
                            }
                        />
                    )
                }}
            />

            <Controller
                name={`${prefixName}.sorts`}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const isHighLight = getIsFindUseInSort({
                        findUseObject,
                        data: value,
                        currentDsId: dsId
                    })
                    return (
                        <ListItemMenu
                            width="auto"
                            compact
                            highlighting={isHighLight}
                            enableHover={false}
                            label="排序数据"
                            positionDependencies={[value]}
                            value={Boolean(value?.length) && `${String(value?.length)} 条排序`}
                            popupContent={
                                <ViewSortConfigure
                                    // noSettingFields={viewNotFilterSettingFieldTypes}
                                    columns={columns}
                                    primaryField={primaryField}
                                    sorters={value}
                                    disabled={disabledWithVersion}
                                    isShowDsName={dataSource.type === DataSourceType.joinDataSource}
                                    onChangeSorter={onChange}
                                />
                            }
                        />
                    )
                }}
            />
        </>
    )
}
