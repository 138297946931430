import {
    type DragEndEvent,
    type DragMoveEvent,
    type DragStartEvent,
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { ApplicationAbstract, DataSourceAbstract } from '@lighthouse/core'
import { judgeInsertPosition, PosEnum } from '@lighthouse/tools'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import type { MoveDataSourceAtomPayload } from '@/atoms/dataSource/types'
import { RowGuideLine } from '@/components/StyledGuideLine'

import type { TreeListItem } from '../Item'
import DataSourceItem from '../Item'
import * as SC from '../styles'

interface ListProps {
    appId: string
    envId: string
    activeId?: string
    allDataSource: DataSourceAbstract[]
    dataSourceList: TreeListItem[]
    applicationList: ApplicationAbstract[]
    level?: number
    onMoveDataSource?: (params: MoveDataSourceAtomPayload) => void
    onSelectDataSource: (dsId: string) => void
    onUpdateDataSource?: (dsId: string, value: string) => Promise<boolean>
    onDeleteDataSource?: (dsId: string, name: string) => Promise<boolean>
    onDuplicateDataSource?: (dsId: string) => void
}

export const List: React.FC<ListProps> = ({
    appId,
    envId,
    activeId,
    allDataSource,
    dataSourceList,
    applicationList,
    level = 0,
    onMoveDataSource,
    onSelectDataSource,
    onUpdateDataSource,
    onDeleteDataSource,
    onDuplicateDataSource
}) => {
    const listContainerRef = useRef<HTMLDivElement>(null)
    const [dragId, setDragId] = useState('')
    // const dragRef = useRef<string | undefined>(undefined)
    const [guideRect, setGuideRect] = useState({ top: 0, width: 0, parentLeft: 0, initialScrollLeft: 0 })
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))
    const dataSourceListIds = useMemo(() => dataSourceList.map(item => item.id), [dataSourceList])
    const allDataSourceListIds = useMemo(() => allDataSource.map(item => item.id), [allDataSource])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (!over) {
                return
            }
            if (active.id !== over.id && dragId) {
                const oldIndex = allDataSourceListIds.indexOf(String(active.id))
                const newIndex = allDataSourceListIds.indexOf(String(over.id))
                onMoveDataSource?.({
                    envId,
                    dsId: dragId,
                    oldIndex,
                    newIndex
                })
            }
        },
        [allDataSourceListIds, dragId, envId, onMoveDataSource]
    )

    const handleDragStart = useCallback((event: DragStartEvent) => {
        const { active } = event
        setDragId(String(active?.id))
    }, [])

    const handDragMove = useCallback((ev: DragMoveEvent) => {
        const { over } = ev
        const tableTop = listContainerRef.current?.getBoundingClientRect().top ?? 0
        setGuideRect(rect => {
            const insertPosition = judgeInsertPosition(ev)
            const baseTop = over?.rect.top ?? 0
            const baseOffset = insertPosition === PosEnum.BEFORE ? 0 : over?.rect.height ?? 0
            const top = baseTop + baseOffset - tableTop
            return { ...rect, top }
        })
    }, [])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragMove={handDragMove}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            modifiers={[restrictToVerticalAxis]}
        >
            <SC.List ref={listContainerRef}>
                <SortableContext items={dataSourceListIds} strategy={verticalListSortingStrategy}>
                    {dataSourceList.map((ds, index) => (
                        <DataSourceItem
                            key={`${appId}-${ds.id}`}
                            appId={appId}
                            envId={envId}
                            dataSource={ds}
                            activeId={activeId}
                            dataSourceList={allDataSource}
                            applicationList={applicationList}
                            level={level}
                            onSelectDataSource={onSelectDataSource}
                            onUpdateDataSource={onUpdateDataSource}
                            onDuplicateDataSource={onDuplicateDataSource}
                            onDeleteDataSource={onDeleteDataSource}
                            onMoveDataSource={onMoveDataSource}
                            isTail={dataSourceList.length - 1 === index - 1}
                        />
                    ))}
                    <RowGuideLine rect={guideRect} />
                </SortableContext>
            </SC.List>
        </DndContext>
    )
}
