import { Button, Flex, Popover, SelectDropdown, useDisclosure } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { findParentFormBlock, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import * as React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'

import { BlockSettingIcon } from '../styles'
import { RelativeFieldItem } from './RelativeFieldItem'

interface RelativeListConfigureProps {
    blockId: string
}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const SCxList = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`
export const RelativeListConfigure: React.FunctionComponent<RelativeListConfigureProps> = ({ blockId }) => {
    const { control, watch } = useFormContext<SubFormBlockConfig>()
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'relativeFieldList',
        keyName: 'tid'
    })

    const bindDsId = watch('pointer') ?? ''
    const pageId = useAtomData(
        lastPageOfStackAtom,
        React.useCallback(s => s?.pageId || '', [])
    )

    const blocks = useAtomData(
        pageBlocksAtom,
        React.useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        React.useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const parentForm = React.useMemo(() => findParentFormBlock(blockId, blocks)(nodes), [blockId, blocks, nodes])

    const dsId = parentForm?.config.pointer ?? ''

    return (
        <SCxContainer>
            {fields.length > 0 && (
                <SCxList>
                    {fields.map((item, index) => (
                        <Controller
                            name={`relativeFieldList.${index}`}
                            key={item.tid}
                            render={({ field }) => (
                                <RelativeFieldItem
                                    index={index}
                                    dsId={dsId}
                                    bindDsId={bindDsId}
                                    value={field.value}
                                    onChange={field.onChange}
                                    onDelete={remove}
                                />
                            )}
                        />
                    ))}
                </SCxList>
            )}
            <Button
                radius="100px"
                style={{ width: 74 }}
                icon={<BlockSettingIcon color='var(--color-gray-500)' type="Add" />}
                onClick={() => append({ id: nanoid(10), fieldPointer: '', bindFieldPointer: '' })}
            >
                添加
            </Button>
        </SCxContainer>
    )
}
