import { Button } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Disable: React.FC = () => {
    return (
        <Unit title="禁用">
            <Button type="primary" disabled>
                按钮禁用
            </Button>
            <Button disabled>按钮禁用</Button>
            <Button danger disabled>
                按钮禁用
            </Button>
            <Button type="primary" danger disabled>
                按钮禁用
            </Button>
        </Unit>
    )
}
