import { mergeDeepRight } from "rambda";

import type { EnvironmentAbstract } from "../abstracts";

export function EnvironmentStruct(initial: Partial<EnvironmentAbstract> = {}): EnvironmentAbstract {
    return mergeDeepRight<EnvironmentAbstract>(
        {
           linked: []
        } satisfies Partial<EnvironmentAbstract>,
        initial
    )
}
