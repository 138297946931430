import { Anchor, Button, IconFont, Popover } from '@byecode/ui'
import type { BlockType, ChartType, FieldInputType, ViewType } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { allGuidanceDocumentUrlMap, subnodeGuidanceDocumentMap } from '../../constants'
import type { NodeTypes } from '../../types'

export type DocumentType = BlockType | NodeTypes | 'joinDataSource' | 'navbar'

interface LinkDocumentProps {
    type?: DocumentType
    subNodeType?: ViewType | ChartType | FieldInputType
}

const SCxContainer = styled.div`
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const LinkDocument: React.FunctionComponent<LinkDocumentProps> = ({ type, subNodeType }) => {
    const info = useMemo(() => {
        if (!type) {
            return null
        }
        if (type === 'field' || type === 'chart' || type === 'view') {
            return subNodeType ? subnodeGuidanceDocumentMap[subNodeType] : null
        }
        return allGuidanceDocumentUrlMap[type]
    }, [subNodeType, type])

    if (!info) {
        return null
    }
    const { url, describe, title } = info

    return (
        <>
            {url ? (
                <Anchor target="_blank" to={url}>
                    <Button
                        disabled={false}
                        size="sm"
                        style={{ flex: 0, borderColor: 'transparent' }}
                        type="default"
                        icon={<IconFont color="var(--color-gray-400)" type="HelpManual" size={16} />}
                    />
                </Anchor>
            ) : null}
        </>
    )
}
