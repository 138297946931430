import { Button, Flex, Text } from '@byecode/ui'
import { getFileTypeByUrl, TitleInContentModal } from '@lighthouse/shared'
import { Link } from '@tiptap/extension-link'
import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import type { ControlProps } from 'nuka-carousel'
import Carousel from 'nuka-carousel'
import React, { useState } from 'react'
import { useAsync, useLocalStorage } from 'react-use'
import styled from 'styled-components'

import { useUser } from '@/hooks/useUser'
import * as srv from '@/services'

const StyledPagingDotsContainer = styled.div`
    display: flex;
    gap: 9px;
    margin-bottom: 21px;
`
const StyledPagingDot = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #d9d9d980;
`

const ModalContent = styled.div`
    padding: 24px;
`
const Progress = styled.div`
    padding: 4px 12px;
    width: 48px;
    text-align: center;
    border-radius: 100px;
    font-size: 14px;
    color: var(--color-gray-600);
    line-height: 22px;
    background-color: var(--color-gray-100);
`
const StyledButton = styled(Button)`
    height: 40px;
    padding: 9px 24px;
`

const StyledTiptap = styled.div`
    font-size: 14px;
    line-height: 22px;
    color: var(--color-gray-600);
    a {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`

const PagingDots = ({ currentSlide, pagingDotsIndices, goToSlide }: ControlProps) => {
    return (
        <StyledPagingDotsContainer>
            {pagingDotsIndices.map(item => {
                const active = currentSlide === item

                return (
                    <StyledPagingDot
                        key={item}
                        style={{ backgroundColor: active ? '#00000080' : undefined }}
                        onClick={() => !active && goToSlide(item)}
                    />
                )
            })}
        </StyledPagingDotsContainer>
    )
}

const extensions = [StarterKit, Link]

export const VersionModal = () => {
    const user = useUser()
    const [currentProgress, setCurrentProgress] = useState(0)
    const [notificationId, setNotificationId] = useLocalStorage<string>(`NEW_VERSION_NOTIFICATION_ID-${user.userId}`)
    const res = useAsync(() => {
        return srv.getVersionNotification()
    })

    if (res.loading || res.error || !res.value) {
        return null
    }

    const open = notificationId !== res.value.notificationId

    const handleClose = () => {
        setNotificationId(res.value.notificationId)
    }

    return (
        <TitleInContentModal
            open={open}
            closeOnClickOverlay={false}
            closeOnEscape={false}
            onClose={handleClose}
            withCloseIcon
            styles={{ desktop: { modal: { border: 'none !important' } } }}
        >
            <Carousel
                key={currentProgress}
                style={{ height: 347 }}
                autoplay
                autoplayInterval={5000}
                wrapAround
                pauseOnHover
                zoomScale={1}
                disableEdgeSwiping
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
                renderBottomCenterControls={PagingDots}
                defaultControlsConfig={{
                    pagingDotsStyle: {
                        background: 'red'
                    }
                }}
            >
                {res.value.items[currentProgress].files?.map(item => {
                    const fileType = getFileTypeByUrl(item)
                    if (fileType === 'video') {
                        return (
                            <video
                                key={item}
                                src={item}
                                controls
                                controlsList="nodownload"
                                style={{ width: '100%', height: 347, objectFit: 'cover' }}
                            />
                        )
                    }
                    return <img key={item} style={{ width: '100%', height: 347, objectFit: 'cover' }} src={item} alt="" />
                })}
            </Carousel>

            <ModalContent>
                <Text mb={16} weight={600} size={24} lineHeight="32px" color="var(--color-black)">
                    {res.value.items[currentProgress].title}
                </Text>
                <StyledTiptap>
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <EditorProvider extensions={extensions} content={res.value.items[currentProgress].description} children={undefined} />
                </StyledTiptap>

                <Flex justifyContent="space-between" alignItems="center" mt={24}>
                    <Progress>
                        {currentProgress + 1}/{res.value.items.length}
                    </Progress>

                    {currentProgress === res.value.items.length - 1 ? (
                        <Flex alignItems="center" gap={12}>
                            {currentProgress === 0 ? null : (
                                <StyledButton
                                    onClick={() => {
                                        setCurrentProgress(s => s - 1)
                                    }}
                                >
                                    上一个
                                </StyledButton>
                            )}
                            <StyledButton type="primary" onClick={handleClose}>
                                知道了
                            </StyledButton>
                        </Flex>
                    ) : (
                        <StyledButton
                            type="primary"
                            onClick={() => {
                                setCurrentProgress(s => s + 1)
                            }}
                        >
                            下一个
                        </StyledButton>
                    )}
                </Flex>
            </ModalContent>
        </TitleInContentModal>
    )
}
