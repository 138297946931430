import {
    type AiFieldStatus,
    type AppUser,
    type DataSourceAbstract,
    type DataSourceIdentity,
    type EnvironmentAbstract,
    type RecordIdentity,
    type RecordLikeProtocol,
    EnvironmentStruct} from '@lighthouse/core';
import type { AppUserDepartment, AppUserGroup } from '@lighthouse/shared';
import { getMainTableRecordId, USER_DATASOURCE } from '@lighthouse/shared';
import shallowEqual from 'fast-deep-equal';
import type { Draft } from 'immer';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { atomWithImmer } from 'jotai-immer';
import { find } from 'rambda';

import { appRoleAtom, currentEnvIdAtom } from '../application/state';
import { applyDraftPayload } from '../utils/applyDraftPayload';

// export const dataSourceListIdsAtom = atomWithImmer<string[]>([])
export const dataSourcePoolAtom = atomWithImmer<DataSourceAbstract[]>([])
export const recordPoolAtom = atomWithImmer<RecordLikeProtocol[]>([])
export const currentDataSourceIdAtom = atom('')
export const aiFieldStatusListAtom = atomWithImmer<AiFieldStatus[]>([])
// export const dataSourceEnvIdAtom = atomWithImmer<string>('')
export const dataSourceUserListAtom = atomWithImmer<AppUser[]>([])
export const dataSourceUserGroupListAtom = atomWithImmer<AppUserGroup[]>([])
export const dataSourceDepartmentListAtom = atomWithImmer<AppUserDepartment[]>([])
export const dataSourceEnvAtom =  atomWithImmer<EnvironmentAbstract>({...EnvironmentStruct()})

export const dataSourceAtomFamily = atomFamily((params: DataSourceIdentity) => {
    const { appId, envId, dsId } = params
    return atom(
        get => get(dataSourcePoolAtom).find(item => item.appId === appId && item.envId === envId && item.id === dsId),
        (_, set, payload: DataSourceAbstract | undefined | ((draft: Draft<DataSourceAbstract | undefined>) => void)) =>
            set(dataSourcePoolAtom, draft => {
                const dataSource = draft.find(item => item.id === dsId && item.appId === appId && item.envId === envId)
                applyDraftPayload(dataSource, payload)
            })
    )
}, shallowEqual)

export const recordAtomFamily = atomFamily((params: RecordIdentity) => {
    // TODO: 临时解决连接表问题
    const { appId, envId, dsId, recordId } = params
    return atom(
        get => get(recordPoolAtom).find(item => item.appId === appId && item.envId === envId && item.id === recordId && item.dsId === dsId),
        (_, set, payload: RecordLikeProtocol | ((draft: Draft<RecordLikeProtocol>) => void)) =>
            set(recordPoolAtom, draft => {
                const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                    if (cur.appId === appId && cur.dsId === dsId && getMainTableRecordId(cur.id) === getMainTableRecordId(recordId)) {
                        prev.push(index)
                    }
                    return prev
                }, [])
                if (recordsIndex.length === 0) {
                    return
                }
                recordsIndex.forEach(i => {
                    applyDraftPayload(draft[i], payload)
                })
            })
    )
}, shallowEqual)

// export const aiFieldStatusAtomFamily = atomFamily((params: AiFieldIdentity) => {
//     const { dataSourceId, fieldId, recordId } = params
//     return atom(
//         get =>
//             get(aiFieldStatusListAtom).find(item => item.dataSourceId === dataSourceId && item.id === recordId && item.fieldId === fieldId),
//         (_, set, payload: AiFieldStatus | ((draft: Draft<AiFieldStatus>) => void)) =>
//             set(aiFieldStatusListAtom, draft => {
//                 const record = draft.find(item => item.dataSourceId === dataSourceId && item.id === recordId && item.fieldId === fieldId)
//                 if (!record) {
//                     return
//                 }
//                 applyDraftPayload(record, payload)
//             })
//     )
// }, shallowEqual)

// export const firstRecordsAtomFamily = atomFamily((params: Omit<RecordIdentity, 'recordId'>) => {
//     const { appId, dsId } = params

//     return atom(null, async (get, set) => {
//         const res = await srv.getDs({ appId, dsId, pagination: { currentPage: 1, pageSize: 100 } })

//         const first = res?.records?.[0]

//         first &&
//             set(recordPoolAtom, draft => {
//                 draft.push(first)
//             })

//         return first
//     })
// }, shallowEqual)

export const dataSourceListAtomFamily = atomFamily((params: { appId: string, envId: string }) => {
    const { appId, envId } = params
    return atom(get => get(dataSourcePoolAtom).filter(item => item.appId === appId && item.envId === envId))
})

export const userRecordAtom = atom(get => {
    const recordList = get(recordPoolAtom)
    // const envId = get(dataSourceEnvIdAtom)
    const envId = get(currentEnvIdAtom)
    const userId = get(appRoleAtom)
    return find(record => record?.content?.['ID']?.value === userId && Boolean(userId) && record.dsId === USER_DATASOURCE && record.envId === envId, recordList ?? [])
})

export const userDataSourceAtom = atom(get => {
    const dataSourceList = get(dataSourcePoolAtom)
    // const envId = get(dataSourceEnvIdAtom)
    const envId = get(currentEnvIdAtom)
    return find(ds => ds.id === USER_DATASOURCE && ds.envId === envId, dataSourceList)
})
