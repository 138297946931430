import { hideScrollBar, pointer, SelectItem } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    /* padding: 20px 16px 16px 16px; */
    font-size: var(--font-size-normal);
`
export const Header = styled.div`
    height: 28px;
    margin: 0 -6px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
`
export const Describe = styled.div`
    height: 38px;
    line-height: 38px;
    color: var(--color-gray-500);
`

export const SelectPagePreview = styled.div`
    margin-bottom: 8px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    border-radius: 8px;
    padding: 0 8px;
`

export const PageSelectItem = styled(SelectItem)`
    padding: 10px 16px;
    line-height: 16px;
    font-size: var(--font-size-normal);
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ScrollArea = styled.div`
    width: 100%;
    overflow-y: auto;
    max-height: 300px;

    ${hideScrollBar}
`

export const ContentScrollArea = styled.div`
    overflow: hidden auto;
    ${hideScrollBar}
`
