import { Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Tooltip title="我是tooltip">tooltip</Tooltip>
        </Unit>
    )
}
