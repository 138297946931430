import { Popover } from '@byecode/ui'
import type { NodeTypes, OtherPlatformOption } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'

interface OtherPlatformItemProps {
    option: OtherPlatformOption
    onItemClick?: (type: NodeTypes) => void
}

export const OtherPlatformItem: React.FC<OtherPlatformItemProps> = ({ option, onItemClick }) => {
    const [open, setOpen] = useState(false)

    const handleClick = useCallback(
        (type: NodeTypes) => {
            setOpen(false)
            onItemClick?.(type)
        },
        [onItemClick]
    )

    return (
        <Popover key={option.value} opened={open} onChange={setOpen} withinPortal position="left-start" width="auto" trigger="hover">
            <Popover.Target>
                <SC.CreatorOtherCardWrapper>
                    <SC.CreatorOtherCardLeftFill>
                        <SC.CreatorCardIconWrapper style={{ border: 'none' }}>
                            <SC.CreatorCardIcon size={28} type={option.icon} color={option.color} />
                        </SC.CreatorCardIconWrapper>
                        <SC.CreatorCardLabel>{option.label}</SC.CreatorCardLabel>
                    </SC.CreatorOtherCardLeftFill>
                    <SC.CreatorIcon type="ArrowRightSmall" />
                </SC.CreatorOtherCardWrapper>
            </Popover.Target>
            <Popover.Dropdown compact>
                <SC.SelectContain>
                    {option.children.map(({ value, icon, label, color }) => (
                        <SC.SelectItem key={value} onClick={() => handleClick(value)}>
                            <SC.CreatorIcon type={icon ?? ''} size={20} color={color} />
                            <Text>{label}</Text>
                        </SC.SelectItem>
                    ))}
                </SC.SelectContain>
            </Popover.Dropdown>
        </Popover>
    )
}
