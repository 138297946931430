import { IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Select = styled.div<{ disable?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ disable }) => (disable ? 'var(--color-gray-6)' : 'var(--color-black)')};

    ${pointer}
`

export const SelectText = styled.div`
    font-size: 14px;
    color: var(--color-gray-900);
`

export const RoleText = styled.div`
    margin-right: 15px;
    font-size: 14px;
`

export const Arrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
`

export const Option = styled.div<{ disable?: boolean }>`
    width: 100%;
    height: 32px;
    padding: 0 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: ${({ disable }) => (disable ? 'no-drop' : 'pointer')};
    opacity: ${({ disable }) => (disable ? 0.5 : 1)};

    :hover {
        background-color: #f5f5f5;
    }
`

export const OptionTip = styled.div`
    width: 24px;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const OptionIcon = styled(IconFont)``
export const OptionContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const OptionLabel = styled.div<{ color?: string }>`
    font-size: 14px;
    ${({ color }) =>
        color
            ? css`
                  color: ${color};
              `
            : css`
                  color: var(--color-black);
              `}
`

export const OptionMark = styled.div`
    color: var(--color-gray-7);
    font-size: 12px;
`
