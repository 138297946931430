import { Tooltip } from '@byecode/ui'
import type { ApplicationAbstract, EnvironmentAbstract, EnvironmentIdentity } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import * as SC from './styles'

export interface linkExtraProps {
    dataSourceEnv: EnvironmentAbstract
    applicationList: ApplicationAbstract[]
    onUnLinkApplication?: () => void
}

export const LinkExtra: React.FC<linkExtraProps> = ({ dataSourceEnv, applicationList, onUnLinkApplication }) => {
    const [link, linked] = useMemo(() => {
        return [dataSourceEnv.link, dataSourceEnv.linked]
    }, [dataSourceEnv.link, dataSourceEnv.linked])

    const linkApplication = useMemo(() => find(item => item.id === link?.appId, applicationList), [applicationList, link?.appId])

    const toolTipContent = useCallback(
        (linked: EnvironmentIdentity[]) => {
            return (
                <>
                    {linked.map(item => {
                        const linkApplication = find(app => app.id === item.appId, applicationList)
                        return <SC.AppName key={item.appId}>{linkApplication?.name}</SC.AppName>
                    })}
                </>
            )
        },
        [applicationList]
    )

    return useMemo(() => {
        if (linkApplication) {
            return (
                <SC.TabExtra>
                    <SC.Icon type="TransferData" />
                    已使用
                    <SC.Bold>{linkApplication.name}</SC.Bold>
                    的用户
                    <SC.CancelPool onClick={onUnLinkApplication}>取消使用</SC.CancelPool>
                </SC.TabExtra>
            )
        }

        if (linked && linked.length > 0) {
            if (linked.length === 1) {
                const linkApplication = find(app => app.id === linked[0].appId, applicationList)
                return (
                    <SC.TabExtra>
                        <SC.Icon type="TransferData" />
                        该应用的用户被
                        <SC.Bold>{linkApplication?.name || ''}</SC.Bold>
                        使用
                    </SC.TabExtra>
                )
            }
            return (
                <SC.TabExtra>
                    <SC.Icon type="TransferData" />
                    该应用的用户被
                    <Tooltip placement="top" title={toolTipContent(linked)}>
                        <SC.Bold pointer>{`${linked.length}个应用`}</SC.Bold>
                    </Tooltip>
                    使用
                </SC.TabExtra>
            )
        }
        return null
    }, [linkApplication, linked, onUnLinkApplication, toolTipContent, applicationList])
}
