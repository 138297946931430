import { backgroundTransitionOnClick, flex, IconFont, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const CreatorContainer = styled.div`
    user-select: none;
`

export const CreatorTitleWrapper = styled.div`
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    color: var(--color-black);
    height: 54px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CreatorTitle = styled.div`
    width: fit-content;
    max-width: 100%;
    position: relative;
`

export const CreatorIcon = styled(IconFont)`
    ${pointer}
`

export const CreatorContentWrapper = styled.div``

export const CreatorCardGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 8px;
`

export const CreatorCardWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 150px;
    margin: 12px;
    padding: 6px;
    cursor: pointer;
    border-radius: 8px;

    ${backgroundTransitionOnClick}
`

export const CreatorCardIconWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

export const CreatorCardIcon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? '24px'};
    color: var(--color-white);
`

export const CreatorCardLabel = styled.span`
    width: 100%;
    font-size: var(--font-size-sm);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const CreatorOtherCardGroupWrapper = styled.div`
    width: 100%;
    padding: 0 6px;
`
export const CreatorOtherCardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    gap: 6px;

    height: 44px;

    background: var(--color-white);

    border-radius: 8px;
    ${pointer}

    ${backgroundTransitionOnClick}
    ${CreatorCardIconWrapper} {
        margin-bottom: 0;
    }
`

export const CreatorOtherCardLeftFill = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SelectContain = styled.div`
    padding: 8px 0px;
    max-height: 500px;
    width: 200px !important;
    overflow-y: auto;
    ${tinyButtons};
`

export const SelectItem = styled.div<{ disabled?: boolean }>`
    width: 100%;
    padding-left: 16px;
    ${flex}
    align-items: center;
    height: 38px;
    line-height: 38px;
    gap: 8px;

    ${({ disabled }) => !disabled && backgroundTransitionOnClick}

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: #fef3f2;
            pointer-events: none;
            cursor: not-allowed;
        `};

    ${singleTextEllipsis}
    ${pointer}
    font-size: var(--font-size-normal);
`
