import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}

    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`
export const Text = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    font-family: 'PingFang SC';
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: ${({ size }) => size ?? 14 + 2}px;
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

export const Description = styled.div`
    margin-top: 6px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
    min-height: 38px;
`

export const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
`

export const Container = styled.form`
    width: 100%;
    font-size: var(--font-size-normal);
`

export const Content = styled.div`
    width: 100%;
    padding: 16px 32px;
    padding-top: 0;
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    font-size: 20px;
    line-height: 28px;
    color: var(--color-black);
    margin-bottom: 8px;
`

export const Footer = styled.div`
    padding: 20px 16px;
    margin-top: 20px;
    gap: 12px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    background: var(--color-gray-50);
    border-radius: 0px 0px 4px 4px;
`
