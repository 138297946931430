import { Select } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { NodeTypes, WorkflowType } from '@lighthouse/shared'
import { CollapseBox, getIsAllowedDataSourceAsFlowOption, nodeTypeOptions, SelectDataSource, useAtomAction } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useSWR from 'swr'

import { getDataSourceMetaAtom } from '@/atoms/dataSource/action'
import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../styles'

const disabledDs = (ds: DataSourceAbstract, type?: WorkflowType) => {
    switch (type) {
        case 'action': {
            return ds.sync
        }
        case 'automation': {
            return ds.type === DataSourceType.joinDataSource || ds.sync
        }
        default: {
            return true
        }
    }
}

export const ClickTriggerConfigure: React.FC = () => {
    const { control } = useFormContext()
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { type } = useFlow()
    const { data: listDs = [] } = useSWR(`/fetch/listDs/${currentAppId}`, () => srv.listDs(currentAppId, envId), {
        revalidateOnFocus: false
    })
    const { run: getDataSourceMeta } = useAtomAction(getDataSourceMetaAtom)

    const options = useMemo(
        () =>
            listDs.reduce<DataSourceAbstract[]>((listDs, item) => {
                if (getIsAllowedDataSourceAsFlowOption(item) && !disabledDs(item, type)) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [listDs, type]
    )

    return (
        <SC.Container>
            <CollapseBox label="触发器配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="nodeType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabled
                                        value={field.value}
                                        options={nodeTypeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.dataSourceId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <SelectDataSource
                                            // disabled={!!field.value}
                                            value={field.value}
                                            searchable
                                            placeholder="请选择数据表"
                                            onChange={async dsId => {
                                                const isConfirmed = field.value === undefined ? true : await promptSwitchDataSource()
                                                if (isConfirmed) {
                                                    field.onChange(dsId)
                                                    getDataSourceMeta({ envId, dsId })
                                                }
                                            }}
                                            dataSourceList={options}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
