import { IconFont } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import type { CustomViewVisibleData } from '@lighthouse/shared'
import { deviceLabelMap, filterToString, useApplicationContext, useAtomData } from '@lighthouse/shared'
import { isEmpty } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { usePageFieldBlocksContext } from '@/contexts/PageContext'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

type visibleParams = {
    customViewData?: CustomViewVisibleData
}

export const useVisibleIcon = (block: BlockAbstract | undefined, option: visibleParams) => {
    const { customViewData } = option
    const [pageId, stackId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.stackId || '', s?.rootPageId || '', s?.blockRuntimeState] as const, [])
    )
    const { curr, prev, dataSourceList } = usePageDataSourceForVariableSelector({ pageId, stackId })
    // curr.datasource?.id
    const fieldBlocksWithDsId = usePageFieldBlocksContext()
    // getFieldBlockWithDsId
    const { personOptions } = useApplicationContext()
    const device = block?.config.visibilityDevice
    const filter = block?.config.visibilityFilter
    const [isDeviceVisible, isVisible, isFilterVisible] = useMemo(() => {
        const title = filter
            ? filterToString({
                  customViewData,
                  currentPage: curr,
                  prevPage: prev,
                  dataSourceList,
                  filter,
                  personOptions,
                  fieldBlocksWithDsId
              })
            : undefined
        // filterToString({ customViewData, currentPage, prevPage, dataSourceList, filter, personOptions, fieldBlocksWithDsId })
        return [device?.length === 1, !device || device?.length === 0, title && !isEmpty(title)]
    }, [curr, customViewData, dataSourceList, device, fieldBlocksWithDsId, filter, personOptions, prev])

    return useMemo(() => {
        if (isVisible) {
            return <IconFont size={16} fill="var(--color-gray-400" type="NoEye" />
        }
        return (
            <>
                {isDeviceVisible && <IconFont type={device?.[0] ? deviceLabelMap[device?.[0]]?.icon : ''} fill="var(--color-gray-400" />}
                {isFilterVisible && <IconFont type="Eye" fill="var(--color-gray-400" />}
            </>
        )
    }, [device, isDeviceVisible, isFilterVisible, isVisible])
}
