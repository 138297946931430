import { Popover } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import { useAsyncAction } from '@/atoms/helper'
import { createWorkSpaceAtom, fetchWorkSpaceAtom, fetchWorkSpaceListAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'
// import { useAsyncAction, useBoundStore } from '@/stores'
import { CardCreator } from '@/components/CardCreator'
import { useWorkSpace, useWorkSpaceList } from '@/hooks/useWorkSpace'

import { SpaceMenu } from '../components/SpaceMenu'
import { SpaceAvatar } from '../SpaceAvatar'

interface SpaceConfigureState {
    isSpaceSettingOpen: boolean
    isCreateSpace: boolean
}

export const SpaceConfigure: React.FC = () => {
    const workspace = useWorkSpace()
    const [open, setOpen] = useState(false)
    const { id, name, icon, members } = workspace
    const workSpaceList = useWorkSpaceList()
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const { run: createWorkSpace } = useAtomAction(createWorkSpaceAtom)
    const { run: fetchWorkSpace } = useAtomAction(fetchWorkSpaceAtom)
    const { run: fetchWorkSpaceList } = useAtomAction(fetchWorkSpaceListAtom)
    const [state, setState] = useImmer<SpaceConfigureState>({
        isSpaceSettingOpen: false,
        isCreateSpace: false
    })

    const [effect, doCreateWorkSpace] = useAsyncAction(createWorkSpace)

    useEffect(() => {
        const id: string | undefined = effect.value
        if (id) {
            setState(draft => {
                draft.isCreateSpace = false
            })
            setWorkSpaceId(id)
            fetchWorkSpaceList()
            // fetchWorkSpace(id)
        }
    }, [effect.value, fetchWorkSpace, fetchWorkSpaceList, setState, setWorkSpaceId])

    const isBrowseStart = useMemo(() => workSpaceList.some(item => item.browseStart), [workSpaceList])

    // 跳转空间
    const handleChangeSpace = useCallback(
        (spaceId: string) => {
            if (id === spaceId) {
                return
            }
            setOpen(false)
            setWorkSpaceId(spaceId)
        },
        [id, setWorkSpaceId]
    )

    const handleCreateSpace = useCallback(
        (isOpen: boolean) => {
            setOpen(false)
            setState(draft => {
                draft.isCreateSpace = isOpen
            })
        },
        [setState]
    )

    return (
        <>
            <Popover width="target" opened={open} withinPortal zIndex={200} onChange={setOpen}>
                <Popover.Target>
                    <SpaceAvatar name={name} icon={icon} dot={isBrowseStart} active={open} members={members} />
                </Popover.Target>
                <Popover.Dropdown style={{ padding: 0 }}>
                    <SpaceMenu
                        space={workspace}
                        workspaces={workSpaceList}
                        onChangeSpace={handleChangeSpace}
                        // onSpaceSet={handleSpaceSet}
                        onCreateSpace={handleCreateSpace}
                    />
                </Popover.Dropdown>
            </Popover>

            <Modal
                centered
                title="创建空间"
                opened={state.isCreateSpace}
                onClose={() => {
                    handleCreateSpace(false)
                }}
            >
                <CardCreator
                    title="创建空间"
                    value={{ name: '', icon: '' }}
                    loading={effect.loading}
                    onConfirm={value => {
                        doCreateWorkSpace(value)
                    }}
                />
            </Modal>
        </>
    )
}
