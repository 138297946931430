import { FieldBlockThumbImageMap } from '@lighthouse/block'
import { BlockType, fieldBlockInput } from '@lighthouse/core'
import { FieldInputIconTypeMap, FieldInputNameMap } from '@lighthouse/shared'

export const allField = {
    id: 'field',
    name: '数据录入',
    icon: 'Input',
    color: '',
    items: fieldBlockInput.map(inputType => {
        return {
            icon: FieldInputIconTypeMap[inputType],
            name: FieldInputNameMap[inputType],
            describeImage: FieldBlockThumbImageMap[inputType],
            color: '#667085',
            backGroundColor: '#F9FAFB',
            type: BlockType.field,
            subType: inputType
        }
    })
}
