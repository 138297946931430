import { BlockThumbImageMap, BlockTypeIconMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

export const filterBlock: BlockTreeItem = {
    name: '筛选控制器',
    icon: BlockTypeIconMap.filter,
    describeImage: BlockThumbImageMap['filter'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.filter
}
