import type { ApplicationSettingTheme, ApplicationWebsiteSetting, ApplicationWxAppletSetting } from '@lighthouse/core'

import type { ApplicationSettingTabs } from './type'
import { ModuleType } from './type'

export const AppWxAppletTabs: ApplicationSettingTabs = [
    {
        label: '导航标题',
        value: ModuleType.navbarTitle,
        icon: 'PaintBrushBroad',
        iconColor: '#7B70F4'
    },
    {
        label: '标签栏',
        value: ModuleType.tabBar,
        icon: 'PaintBrushBroad',
        iconColor: '#7B70F4'
    },
    {
        label: '注册页',
        value: ModuleType.login,
        icon: 'PaintBrushBroad',
        iconColor: '#D86A42'
    },
    {
        label: '广告位',
        value: ModuleType.advertising,
        icon: 'PaintBrushBroad',
        iconColor: '#7B70F4'
    }
]

export const AppWebsiteTabs: ApplicationSettingTabs = [
    // {
    //     label: '主题',
    //     value: ModuleType.theme,
    //     icon: 'PaintRoller',
    //     iconColor: 'linear-gradient(180deg, #F79009 0%, #FFAE44 100%)'
    // },
    {
        label: '基础设置',
        value: ModuleType.webTag,
        icon: 'LayoutBlockTabs',
        iconColor: 'linear-gradient(180deg, #12B76A 0%, #38D18A 100%)'
    },
    {
        label: '域名设置',
        value: ModuleType.domain,
        icon: 'Globe',
        iconColor: 'linear-gradient(180deg, #804AFF 40%, #926BFF 100%)'
    },
    // {
    //     label: '登录设置',
    //     value: ModuleType.login,
    //     icon: 'UserId',
    //     iconColor: 'linear-gradient(180deg, #2E90FA 0%, #6FB3FD 100%)'
    // },

    // {
    //     label: '网页 APP',
    //     value: ModuleType.webApp,
    //     icon: 'LayoutBlockTabs',
    //     iconColor: 'linear-gradient(180deg, #F79009 0%, #FFAE44 100%)'
    // },
    {
        label: 'SEO 优化',
        value: ModuleType.seo,
        icon: 'SEO',
        iconColor: 'linear-gradient(180deg, #5551FF 0%, #817EFF 100%)'
    },
    // {
    //     label: '语言版本',
    //     value: ModuleType.lang,
    //     icon: 'Globe',
    //     iconColor: 'linear-gradient(180deg, #667085 0%, #8C94A7 100%)'
    // },
    {
        label: '广告位',
        value: ModuleType.advertising,
        icon: 'ButtonBasic',
        iconColor: 'linear-gradient(180deg, #F63D68 0%, #FF92AC 100%)'
    },
    {
        label: '验证码短信',
        value: ModuleType.sms,
        icon: 'ChatRoundLine',
        iconColor: 'linear-gradient(180deg, #54C5EB 0%, #8FE3FF 100%)'
    }
]
