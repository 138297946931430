import { Flex, IconFont, Input, Popover, singleTextEllipsis, Tooltip } from '@byecode/ui'
import type { TextAdvanceOption } from '@lighthouse/core'
import { PopoverDropdownWithClose, TextController } from '@lighthouse/shared'
import { stopPropagation } from '@lighthouse/tools'
import React, { forwardRef, useRef, useState } from 'react'
import styled from 'styled-components'

const IconButton = styled.button`
    all: unset;
    box-sizing: border-box;
    opacity: 0;
    display: inline-flex;
    align-items: center;
    padding: 2px;
    border-radius: 6px;
    font-size: 16px;
    color: var(--color-gray-400);
    cursor: pointer;
    &:hover {
        background-color: rgba(38, 65, 90, 0.06);
    }
    .byecode-SelectDropdown-item:hover & {
        opacity: 1;
    }
`

const OptionActions = styled.div`
    display: flex;
    gap: 4px;
`

const TextEllipsis = styled.span`
    ${singleTextEllipsis}
`

interface Props {
    popoverReference: React.MutableRefObject<HTMLDivElement | null>
    advanceConfig: TextAdvanceOption
    handleUpdateAdvance: (value: TextAdvanceOption) => void
    handleDeleteAdvance: (id: string) => void
}

export const AdvanceOptionExtra = ({ popoverReference, advanceConfig, handleUpdateAdvance, handleDeleteAdvance }: Props) => {
    const { id, name, ...textConfig } = advanceConfig

    const [titleEditable, setTitleEditable] = useState(false)

    return (
        // <OptionActions>
        <Popover width={300} positionReference={popoverReference.current || undefined} position="left-start">
            <Popover.Target>
                <Tooltip title="编辑">
                    <IconButton
                        onMouseDown={e => {
                            stopPropagation(e)
                        }}
                    >
                        <IconFont type="Regulation" />
                    </IconButton>
                </Tooltip>
            </Popover.Target>
            <PopoverDropdownWithClose
                style={{ cursor: 'initial' }}
                title={
                    <Flex alignItems="center" gap="4px">
                        {titleEditable ? (
                            <Input
                                size="xs"
                                autoFocus
                                defaultValue={name}
                                onBlur={e => {
                                    const { value } = e.currentTarget
                                    handleUpdateAdvance({ ...advanceConfig, name: value })
                                    setTitleEditable(false)
                                }}
                                onKeyDown={e => {
                                    const { value } = e.currentTarget
                                    if (e.key === 'Enter') {
                                        handleUpdateAdvance({ ...advanceConfig, name: value })
                                        setTitleEditable(false)
                                    }
                                }}
                            />
                        ) : (
                            <TextEllipsis>{name}</TextEllipsis>
                        )}

                        <IconButton
                            style={{ display: titleEditable ? 'none' : undefined }}
                            onClick={() => {
                                setTitleEditable(true)
                            }}
                        >
                            <IconFont type="PencilSimple" />
                        </IconButton>
                    </Flex>
                }
                onMouseDown={stopPropagation}
            >
                <TextController defaultValue={textConfig} onChange={v => handleUpdateAdvance({ ...v, id, name })} />
            </PopoverDropdownWithClose>
        </Popover>
        //     <Tooltip title="删除">
        //         <IconButton
        //             onMouseDown={e => {
        //                 stopPropagation(e)
        //                 handleDeleteAdvance(id)
        //             }}
        //         >
        //             <IconFont type="Trash" />
        //         </IconButton>
        //     </Tooltip>
        // </OptionActions>
    )
}
