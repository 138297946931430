import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ImgBox = styled.div`
    position: relative;
    width: 111px;
    height: 111px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 36px;
`

// export const ImgBackground = styled.div`
//     position: absolute;
//     z-index: -1;
//     width: 111px;
//     height: 111px;
//     background-color: #EDEDFF;
//     border-radius: 50%;
// `

export const Img = styled.img`
    width: 111px;
`

export const Text = styled.div`
    color: var(--color-gray-900);
    font-size: 16px;
    text-align: center;
    font-weight: 600;
`

export const Remark = styled.div`
    width: 170px;
    text-align: center;
    margin-top: 12px;
    font-size: 14px;
    color: var(--color-gray-500);
`

export const Link = styled.div`
    width: 176px;
    text-align: center;
    margin-top: 12px;
    font-size: 14px;
    color: var(--color-gray-400);
`

export const Btn = styled(Button)`
    margin-top: 12px;
    max-width: 338px;
    width: calc(100% - 40px);
    height: 40px;
`
