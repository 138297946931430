import type { VariableOptions, VariableTree } from '@lighthouse/shared'
import { NodeFieldPreview } from '@lighthouse/shared'
import type { JSONContent } from '@tiptap/core'
import React, { useCallback } from 'react'

import * as SC from './styles'

type WechatTemplateContentValue = {
    variableName: string
    value: JSONContent | undefined
}[]

interface ContentSettingProps {
    value?: WechatTemplateContentValue
    options: VariableOptions
    viewOption?: VariableTree
    userOption?: VariableTree
    formOption?: VariableTree
    enablePageLink?: boolean
    onChange: (val: WechatTemplateContentValue) => void
}

export const ContentSetting: React.FC<ContentSettingProps> = ({
    value,
    options,
    viewOption,
    userOption,
    formOption,
    enablePageLink,
    onChange
}) => {
    const handleChange = useCallback(
        (val: JSONContent, i: number) => {
            if(!value){
                return
            }
            const newValue = value.map((item, index) => {
                if (index === i) {
                    return {
                        variableName: item.variableName,
                        value: val
                    }
                }
                return item
            })
            onChange(newValue)
        },
        [onChange, value]
    )

    return (
        <>
            {value?.map((item, index) => (
                <SC.VariableItem style={{ marginTop: 8 }} key={item.variableName}>
                    <SC.VariableTitle>{`${item.variableName}`}</SC.VariableTitle>
                    <NodeFieldPreview
                        placeholder="请输入"
                        value={item.value}
                        onChange={val => handleChange(val, index)}
                        autoHeight
                        enablePageLink={enablePageLink}
                        options={options}
                        formOption={formOption}
                        viewOption={viewOption}
                        userOption={userOption}
                    />
                </SC.VariableItem>
            ))}
        </>
    )
}
