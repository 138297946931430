import { Flex, SegmentedControl } from '@byecode/ui'
import type { SpaceVersion } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import { SmsPurchase } from './SmsPurchase'
import { SpaceUpgrade } from './SpaceUpgrade'

const TABS = [
    {
        label: '版本升级',
        value: 'version_upgrade'
    },
    {
        label: '短信增购包',
        value: 'sms_purchase'
    }
]

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 40px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    border-radius: inherit;
`

interface UserGradeProps {
    productTab?: 'version_upgrade' | 'sms_purchase'
    onChangeTab?: (v: 'version_upgrade' | 'sms_purchase') => void
    onWechatPaySpaceUpgrade: (level: SpaceVersion) => void
    onWechatPaySms: (order: 'SMS_1000' | 'SMS_5000' | 'SMS_15000') => void
}

const UserGrade: React.FunctionComponent<UserGradeProps> = ({
    productTab = 'version_upgrade',
    onChangeTab,
    onWechatPaySpaceUpgrade,
    onWechatPaySms
}) => {
    return (
        <StyledContainer>
            <Flex alignItems="center" justifyContent="center" mb={32}>
                <SegmentedControl style={{ width: 252 }} fullWidth size="lg" data={TABS} value={productTab} onChange={onChangeTab} />
            </Flex>

            {productTab === 'version_upgrade' ? (
                <SpaceUpgrade onWechatPaySpaceUpgrade={onWechatPaySpaceUpgrade} />
            ) : (
                <SmsPurchase onWechatPaySms={onWechatPaySms} />
            )}
        </StyledContainer>
    )
}

export default UserGrade
