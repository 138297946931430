import { Toast } from '@byecode/ui'
import { LoginType } from '@lighthouse/core'
import { ErrorMessage, mobileRegex, useAtomAction, VerificationCodeBtn } from '@lighthouse/shared'
import type { FormEvent } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { loginAtom } from '@/atoms/auth/action'
import * as srv from '@/services'

import * as SC from '../../styles'
import type { VerificationForm, VerificationProps } from './types'

export const Verification: React.FC<VerificationProps> = ({ onChangeState, onClearState, onChangeMode }) => {
    const {
        register,
        handleSubmit,
        trigger,
        getValues,
        clearErrors,
        formState: { errors }
    } = useForm<VerificationForm>({ mode: 'onBlur', shouldFocusError: false })
    const submitEl = useRef<HTMLInputElement>(null)
    const [loadingBtn, setLoadingBtn] = useState(false)
    // 表单提交
    const { run: login, loading } = useAtomAction(loginAtom)

    const loginSubmit = useCallback(
        async (params: VerificationForm) => {
            const { phone, verificationCode } = params
            const isLogin = await login({ authType: LoginType.MOBILE_AND_CODE, mobile: phone, smsCode: verificationCode })
            if (!isLogin) {
                return
            }
            Toast.success('身份验证通过')
            onChangeMode('resetPassword')
            onChangeState({
                mobile: phone,
                smsCode: verificationCode
            })
        },
        [login, onChangeMode, onChangeState]
    )

    // 提交行为
    const loginHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        return submitEl.current?.click()
    }

    // 跳转登录
    const handleChangeLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onClearState()
        onChangeMode('login')
    }

    /**
     * 发送验证码
     */
    const handleSendCodeHandle = useCallback(
        async (resolve: () => void, reject: () => void) => {
            const result = await trigger('phone')
            if (result) {
                setLoadingBtn(true)
                const isSend = await srv.sendAuthCode({ mobile: getValues('phone') })
                setLoadingBtn(false)
                if (isSend) {
                    resolve()
                    return
                }
                reject()
            }
        },
        [getValues, trigger]
    )

    const clearFormError = useCallback(
        (name: 'phone' | 'verificationCode') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(loginSubmit)()
        },
        [handleSubmit, loginSubmit]
    )

    return (
        <SC.FormContainer onSubmit={handleFormSubmit}>
            <SC.InputWrapper>
                <ErrorMessage name="phone" errors={errors}>
                    <SC.AccountInput
                        placeholder="你注册时使用手机号"
                        onFocus={() => {
                            clearFormError('phone')
                        }}
                        {...register('phone', {
                            required: '请输入手机号',
                            pattern: {
                                value: mobileRegex,
                                message: '请输入正确的手机号' // JS only: <p>error message</p> TS only support string
                            }
                        })}
                    />
                </ErrorMessage>
                <SC.CodeInputBox>
                    <ErrorMessage name="verificationCode" errors={errors}>
                        <SC.CodeInput
                            placeholder="验证码"
                            onFocus={() => {
                                clearFormError('verificationCode')
                            }}
                            maxLength={6}
                            {...register('verificationCode', { required: '请输入验证码' })}
                        />
                    </ErrorMessage>
                    <VerificationCodeBtn loading={loadingBtn} style={{ marginLeft: 8 }} onGetCodeHandle={handleSendCodeHandle} />
                </SC.CodeInputBox>
                <SC.HideSubmit ref={submitEl} type="submit" />
            </SC.InputWrapper>
            <SC.ButtonContainer>
                <SC.AccountButton type="primary" onClick={loginHandle} block loading={loading}>
                    下一步：重置密码
                </SC.AccountButton>
                <SC.AccountButton
                    icon={<SC.Icon type="DeviceMobileFilled" size={20} color="var(--color-main)" />}
                    block
                    onClick={handleChangeLogin}
                >
                    验证码登录/注册
                </SC.AccountButton>
            </SC.ButtonContainer>
        </SC.FormContainer>
    )
}
