import { IconFont, pointer } from '@byecode/ui'
import { Text } from '@mantine/core'
import styled, { css } from 'styled-components'

export const Icon = styled(IconFont)<{ fontSize?: string }>`
    ${({ fontSize }) =>
        fontSize &&
        css`
            font-size: ${fontSize};
        `}
`

export const UserCenter = styled.div`
    width: 100%;
    height: 48px;
    padding: 4px 8px 4px 4px;

    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${pointer}

    &:hover {
        background: var(--color-gray-100);
    }

    &.active {
        background: var(--color-gray-200);
    }
`

export const UserCenterContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`

export const UserCenterExtra = styled.div``

export const UserName: typeof Text = styled(Text)`
    margin-left: 12px;
    font-size: 16px;
    color: var(--color-gray-900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const UserBoxText = styled.div`
    width: 36px;
    height: 36px;
    line-height: 36px;
    background: var(--color-gray-100);
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    margin-left: 8px;
`

export const NavLeft = styled.div`
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const Container = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
`

export const Avatar = styled.div<{ background?: string }>`
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;

    ${({ background }) => {
        return (
            background &&
            css`
                background: url('${background}');
            `
        )
    }}

    background-position: center;
    background-size: contain;
`
