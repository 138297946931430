import { Button, Modal, Text, Toast } from '@byecode/ui'
import { type AppUserGroup, useAtomAction } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import { findIndex } from 'rambda'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { unLinkApplicationAtom } from '@/atoms/application/action'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceUserAtom,
    fetchDataSourceUserGroupAtom,
    fetchEnvAtom,
    setDataSourceUserGroupAtom
} from '@/atoms/dataSource/action'
import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import { useApplicationList } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import { createUserGroup, deleteUserGroup, updateUserGroup } from '@/services'
import { useDataSourceUserGroups } from '@/shared/reusable'

import { LinkExtra } from '../LinkExtra'
import PanelHeader from '../PanelHeader'
import * as CM from '../styles'
import TollTop from '../ToolTop'
import type { PageType } from '../type'
import UserGroupList from '../UserGroupList'

interface IUserGroupPanelProps {}

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px 24px 24px;
    background-color: var(--color-white);
    /* display: flex; */
`

const SCxToolsContainer = styled.div`
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`

interface UserGroupPanelState {
    keyword: string
    list: AppUserGroup[]
    searchList: AppUserGroup[]
    pages: PageType[]
    currentUserId: string
    updateGroupName: string
    selectGroups: string[]
}

export const UserGroupPanel: React.FC<IUserGroupPanelProps> = () => {
    const applicationList = useApplicationList()
    const [state, setState] = useImmer<UserGroupPanelState>({
        keyword: '',
        list: [],
        pages: ['userGroupList'],
        searchList: [],
        currentUserId: '',
        updateGroupName: '',
        selectGroups: []
    })
    // const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)
    const groups = useDataSourceUserGroups()
    const { run: setDataSourceUserGroup } = useAtomAction(setDataSourceUserGroupAtom)
    const { run: fetchDataSourceUserGroup } = useAtomAction(fetchDataSourceUserGroupAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: unLinkApplication } = useAtomAction(unLinkApplicationAtom)
    // const { update: updateUsers } = useDataSourceUsers()

    const debouncedTitle = useDebounce(state.keyword, 200)

    useEffect(() => {
        const search = debouncedTitle.trim()
        const searchList = groups.filter(item => item.groupName?.includes(search))
        if (groups.length === 0) {
            return setState(draft => {
                draft.list = groups
                draft.searchList = []
                draft.selectGroups = []
            })
        }

        setState(draft => {
            draft.list = groups
            draft.searchList = searchList
            draft.selectGroups = []
        })
    }, [debouncedTitle, groups, groups.length, setState])

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.keyword = ev.target.value
            })
        },
        [setState]
    )

    const handleAddGroup = useCallback(async () => {
        const isSuccess = await createUserGroup(envId, `未命名角色${groups.length + 1}`)
        if (isSuccess) {
            fetchDataSourceUserGroup()
        }
    }, [envId, fetchDataSourceUserGroup, groups.length])

    const handleChangeName = useCallback(
        (groupName: string, groupId: string) => {
            const newGroup = groups.map(item => {
                if(item.groupId === groupId){
                    return{ ...item, groupName }
                }
                return item
            })
            setDataSourceUserGroup(newGroup)
            updateUserGroup(envId, { groupName, groupId })
        },
        [envId, groups, setDataSourceUserGroup]
    )

    const handleDelete = useCallback(
        async (groupIds: string[]) => {
            const isSuccess = await deleteUserGroup(envId, groupIds)
            if (isSuccess) {
                fetchDataSourceUserGroup()
                fetchDataSourceUser()
                Toast.success('删除成功')
            }
        },
        [envId, fetchDataSourceUserGroup, fetchDataSourceUser]
    )

    const handleSelectGroups = useCallback(
        (groups: string[]) => {
            setState(draft => {
                draft.selectGroups = groups
            })
        },
        [setState]
    )

    const handleCancelLink = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认取消使用？',
            okStatus: 'error',
            okText: '确认'
        })
        if (isConfirm) {
            await unLinkApplication(envId)
            fetchEnv()
            await Promise.all([fetchDataSourceDepartment(), fetchDataSourceUserGroup()])
            fetchDataSourceUser()
        }
    }, [envId, fetchDataSourceDepartment, fetchDataSourceUser, fetchDataSourceUserGroup, fetchEnv, unLinkApplication])

    return (
        <Container>
            <TollTop
                leftFill={
                    <CM.LeftToolFill>
                        <Text color="var(--color-black)" size={16} weight={600}>
                            角色
                        </Text>
                        <LinkExtra applicationList={applicationList} dataSourceEnv={dataSourceEnv} onUnLinkApplication={handleCancelLink} />
                    </CM.LeftToolFill>
                }
                rightFill={
                    <SCxToolsContainer>
                        {state.selectGroups.length > 0 ? (
                            <Button
                                onClick={() =>
                                    handleSelectGroups(state.selectGroups.length > 0 ? [] : state.searchList.map(draft => draft.groupId))
                                }
                                // style={{ color: 'var(--color-main)' }}
                                type="default"
                                // icon={<CM.Icon type="Add" />}
                            >
                                取消选择
                            </Button>
                        ) : (
                            <PanelHeader keyword={state.keyword} onSearchChange={handleSearchChange} />
                        )}

                        {state.selectGroups.length > 0 && (
                            <Button
                                onClick={() => handleDelete(state.selectGroups)}
                                style={{ color: 'var(--color-red-500)', borderColor: 'var(--color-red-500)' }}
                                type="text"
                                icon={<CM.Icon type="Trash" color="var(--color-red-500)" />}
                            >
                                删除
                            </Button>
                        )}
                        {state.selectGroups.length === 0 && (
                            <Button onClick={handleAddGroup} type="primary" icon={<CM.Icon type="Add" color="var(--color-white)" />}>
                                创建角色
                            </Button>
                        )}
                    </SCxToolsContainer>
                }
            />
            <UserGroupList
                groups={state.searchList}
                selectGroups={state.selectGroups}
                onSelectGroup={handleSelectGroups}
                onChangeName={handleChangeName}
            />
        </Container>
    )
}
