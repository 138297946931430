import { Divider } from '@byecode/ui/components/Divider'
import React from 'react'

import { LayoutSetting } from './LayoutSetting'
import { StyleSetting } from './StyleSetting'
import type { DesignSettingProps } from './types'

/** 右侧设计配置 */
export const DesignSetting = (props: DesignSettingProps) => {
    return (
        <>
            <LayoutSetting {...props} />
            <Divider />
            <StyleSetting {...props} />
        </>
    )
}
