import { Button, Divider, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const StyledDivider = styled(Divider)``

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const Wrapper = styled.div`
    padding: 21px 51px;
`

export const Title = styled.div`
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 8px;
`

export const List = styled.div`
    width: 100%;
    margin-top: 20px;
`

export const Item = styled.div`
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid var(--color-gray-200);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

export const ItemLeft = styled.div`
    width: 86px;
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const ItemCenter = styled.div`
    padding: 0 4px;
    color: var(--color-gray-400);
`

export const ItemRight = styled.div`
    white-space: nowrap;
`

export const ItemIcon = styled(IconFont)`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
    margin-right: 14px;
`

export const ItemName = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

export const ItemButton = styled(Button)`
    font-size: var(--font-size-normal);
`
