import { IconFont } from '@byecode/ui'
import { useDebounce } from '@lighthouse/tools'
import cls from 'classnames'
import React, { useCallback, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import SettingButton from './SettingButton'
import * as SC from './styles'

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SearchIcon = styled(IconFont)`
    margin-right: 4px;
    color: var(--color-gray-500);
`

const KeywordsInput = styled.input`
    width: 0;
    outline: none;
    border: none;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);

    &.active {
        width: 100px;
        transition: width 0.2s ease;
    }

    ::placeholder {
        color: var(--color-gray-300);
    }
`

const CloseBtn = styled(IconFont)`
    cursor: pointer;
    color: var(--color-gray-400);
    opacity: 0;

    &.show {
        opacity: 1;
    }
`

export interface FlexSearchProps {
    children?: React.ReactNode
    value?: string
    isMobile?: boolean
    onChange?: (val: string) => void
}

export const FlexSearch: React.FC<FlexSearchProps> = ({ value = '', isMobile, onChange }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [editing, setEditing] = useState(false)
    const [searchVal, setSearchVal] = useState(value)

    const handleOpen = useCallback(() => {
        setEditing(true)
        inputRef.current?.focus()
    }, [])

    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchVal(ev.target.value)
    }, [])

    const handleClose = useCallback(() => {
        if (!searchVal) {
            setEditing(false)
        }
    }, [searchVal])

    const handleClear = useCallback(() => {
        setSearchVal('')
        setEditing(false)
    }, [])

    const debouncedSearchVal = useDebounce(searchVal, 500)

    useUpdateEffect(() => {
        onChange?.(debouncedSearchVal)
    }, [debouncedSearchVal])

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Escape') {
                handleClear()
            }
        },
        [handleClear]
    )

    return (
        <SearchWrapper>
            {editing ? (
                <SearchIcon type="SearchLine" />
            ) : (
                <SC.SettingBox mainColor="var(--color-main)" tintMainColor="var(--color-tintMainColor)" onClick={handleOpen}>
                    <SettingButton icon="SearchLine" />
                    {!isMobile && <SC.SettingText>搜索</SC.SettingText>}
                </SC.SettingBox>
            )}

            <KeywordsInput
                ref={inputRef}
                value={searchVal}
                className={cls({ active: editing })}
                placeholder="搜索"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleClose}
            />

            {editing && <CloseBtn className={cls({ show: searchVal })} type="Close" size={14} onClick={handleClear} />}
        </SearchWrapper>
    )
}
