import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface CloseButtonProps {
    style?: React.CSSProperties | undefined
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const SCxCloseWrapper = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: var(--color-gray-500);
    border-radius: 50%;
    cursor: pointer;
    display: none;
`

const SCxClose = styled(IconFont)`
    color: var(--color-white);
    border-radius: 50%;
`

export const CloseButton: React.FC<CloseButtonProps> = ({ style, onClick }) => {
    return (
        <SCxCloseWrapper style={style} onClick={onClick}>
            <SCxClose type="Close" size={14} />
        </SCxCloseWrapper>
    )
}
