import type { Option } from '@byecode/ui'
import { Text } from '@byecode/ui'
import { Collapse } from '@mantine/core'
import type { ReactNode } from 'react'
import React, { useMemo, useState } from 'react'

import { TooltipText } from '../TooltipText'
import * as SC from './styles'

interface SelectItemProps {
    data: Option
    level?: number
    isShowDesc?: boolean
    onSelect: (v: string) => void
}

export const SelectItem: React.FunctionComponent<SelectItemProps> = ({ data, level = 0, isShowDesc, onSelect }) => {
    const [opened, setOpened] = useState(true)

    return (
        <>
            <SC.Item onClick={e => onSelect(data.value)} style={{ paddingLeft: level > 0 ? 40 + 16 * level : 16 }}>
                {data.children && data.children.length > 0 ? (
                    <SC.Icon
                        type={opened ? 'ArrowDownSmall' : 'ArrowRightSmall'}
                        onClick={e => {
                            e.stopPropagation()
                            setOpened(!opened)
                        }}
                    />
                ) : (
                    <span style={{ paddingLeft: 16 }} />
                )}
                {isShowDesc ? (
                    data.describe
                ) : (
                    <>
                        {data.icon && typeof data.icon === 'string' ? <SC.Icon type={data.icon} /> : data.icon}
                        <TooltipText title={data.label} render={ref => <Text ref={ref}>{data.label}</Text>} />
                    </>
                )}
            </SC.Item>

            {data.children && data.children.length > 0 && (
                <Collapse in={opened}>
                    {data.children?.map(item => {
                        return <SelectItem key={item.value} level={level + 1} data={item} onSelect={onSelect} />
                    })}
                </Collapse>
            )}
        </>
    )
}
