import * as React from 'react'

import { SpaceSetting } from '@/containers/SpaceSetting'

interface SettingProps {}

const Setting: React.FC<SettingProps> = () => {
    return <SpaceSetting />
}

export default Setting
