import { IconFont, pointer, Switch } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import type { AggregateResultField } from '@lighthouse/shared'
import { FieldListItem, FieldTypeTag, getFieldIcon, List, signFieldIconColor } from '@lighthouse/shared'
import { arrayMove } from '@lighthouse/tools'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { AddFieldButton } from './AddFieldButton'
import { MoreSetting } from './MoreSetting'

const SCxPropertySettingWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
`

const SCxGroup = styled.div`
    width: 397px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const SCxGroupHeader = styled.div`
    padding: 8px 12px;
    height: 33px;
    display: flex;
    justify-content: space-between;
`

const SCxGroupContent = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const SCxGroupTitle = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

const SCxGroupButton = styled.div<{ color?: string }>`
    color: ${({ color }) => color || 'var(--color-main)'};
    font-size: var(--font-size-sm);
    ${pointer}
`

const Extra = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
`

const SCxSwitch = styled(Switch)<{ color?: string }>`
    display: flex;
    align-items: center;
    .mantine-Switch-input:checked + .mantine-Switch-track {
        background-color: ${({ color }) => color};
        border-color: ${({ color }) => color};
    }
`

const SCxVertical = styled.div`
    height: 100%;
    width: 1px;
    background: var(--color-gray-200);
    margin: 0 4px;
`

const SCxTrash = styled(IconFont)``

const IconBox = styled.div`
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    width: 32px;
    display: none;
`

const SCxEye = styled(IconFont)<{ disable?: boolean }>`
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`

const SCxBox = styled.div`
    display: flex;
    align-items: center;
`

type ListItemData = AggregateResultField & {
    label: string
    value: string
    icon: string
    visible?: boolean
}

interface AggregatorFieldSettingProps {
    dsId: string
    nodeId: string
    fieldList: AggregateResultField[]
    onChange: (val: AggregateResultField[]) => void
}

export const AggregatorFieldSetting: React.FC<AggregatorFieldSettingProps> = ({ dsId, nodeId, fieldList, onChange }) => {
    const [showSchemaList, hideSchemaList] = useMemo(() => {
        const showList: ListItemData[] = []
        const hideList: ListItemData[] = []
        fieldList.forEach(item => {
            const { id, visible, type, name, innerType, aggregation } = item
            const icon = getFieldIcon(id, type, innerType, aggregation?.isFormula)
            if (visible) {
                showList.push({
                    ...item,
                    value: id,
                    label: name,
                    icon
                })
                return
            }
            hideList.push({
                ...item,
                value: id,
                label: name,
                icon
            })
        })
        return [showList, hideList]
    }, [fieldList])

    const handleSwitchChange = useCallback(
        (checked: boolean, id: string) => {
            const col = find(({ id: colId }) => colId === id, fieldList)
            if (!col) {
                return
            }
            const filterTableProps = fieldList.filter(item => item.id !== id)
            const t = [...filterTableProps, { ...col, visible: checked }]
            onChange?.(t)
        },
        [fieldList, onChange]
    )

    const handleUpdateField = useCallback(
        (value: AggregateResultField) => {
            const newFieldList = fieldList.map(item => {
                if (item.id === value.id) {
                    return value
                }
                return item
            })
            onChange?.(newFieldList)
        },
        [fieldList, onChange]
    )

    const handleAdd = useCallback(
        (field: AggregateResultField) => {
            const newFieldList = [...fieldList, field]
            onChange(newFieldList)
        },
        [fieldList, onChange]
    )

    const toggleAllDisplay = useCallback(
        (visible = false) => {
            if (!visible) {
                const bool = true
                const firstShowColumns = fieldList.find(item => item.visible)
                if (!firstShowColumns) {
                    return
                }
                const [showTableProps, hideTableProps] = fieldList.reduce<[AggregateResultField[], AggregateResultField[]]>(
                    (list, cur) => {
                        //   return  item.visible
                        if (cur.visible && cur.id !== firstShowColumns?.id) {
                            list[0].push({
                                ...cur,
                                visible: false
                            })
                        }
                        if (!cur.visible) {
                            list[1].push({
                                ...cur,
                                visible: cur.visible
                            })
                        }
                        return list
                    },
                    [[], []]
                )
                onChange?.([firstShowColumns, ...hideTableProps, ...showTableProps])
                return
            }
            const list = [...showSchemaList, ...hideSchemaList]
            const newTableProps = list.reduce<AggregateResultField[]>((list, cur) => {
                const column = find(c => c.id === cur.value, fieldList)
                if (column) {
                    list.push({
                        ...column,
                        id: column.id,
                        visible: true
                    })
                }
                return list
            }, [])
            onChange?.(newTableProps)
        },
        [fieldList, hideSchemaList, onChange, showSchemaList]
    )

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const list = [...showSchemaList, ...hideSchemaList]
            const sourceIndex = findIndex(col => col.value === activeId, list)
            const targetIndex = findIndex(col => col.value === overId, list)
            // const tempProps = map(({ value, visible = true, width }) => ({ id: value, visible, width }), schemaList)
            const newList = arrayMove(list, sourceIndex, targetIndex)
            onChange?.(newList)
        },
        [showSchemaList, hideSchemaList, onChange]
    )

    const handleDelete = useCallback(
        (id: string) => {
            const filterTableProps = fieldList.filter(item => item.id !== id)
            onChange?.(filterTableProps)
        },
        [fieldList, onChange]
    )

    const showLength = useMemo(() => showSchemaList.length, [showSchemaList.length])

    const itemRenderer: React.FC<{ value: string; label: string; icon: string; sortable?: boolean }> = useCallback(
        item => {
            const { icon, label, value, sortable } = item
            const config = find(field => field.id === value, fieldList)
            if (!config) {
                return null
            }
            const { visible, aggregation } = config
            const isSignField = aggregation?.isFormula
            const isDisable = !!sortable && showLength === 1
            return (
                <FieldListItem
                    icon={icon}
                    iconColor={isSignField ? signFieldIconColor : ''}
                    title={label}
                    suffix={
                        <Extra width={84}>
                            {isSignField && <SCxTrash type="Trash" onClick={() => handleDelete(value)} />}
                            <SCxBox>
                                <IconBox className="field-visible">
                                    {visible ? (
                                        <SCxEye type="Hide" size={16} disable={isDisable} onClick={e => handleSwitchChange(false, value)} />
                                    ) : (
                                        <SCxEye type="Eye" size={16} onClick={e => handleSwitchChange(true, value)} />
                                    )}
                                </IconBox>
                                <FieldTypeTag className="field-tag" type={config.type} innerType={config.innerType} />
                            </SCxBox>
                            <MoreSetting
                                dsId={dsId}
                                nodeId={nodeId}
                                field={config}
                                fieldList={fieldList}
                                onUpdateField={handleUpdateField}
                            />
                            {/* <SCxSwitch
                                disabled={isDisable}
                                checked={visible}
                                color="var(--color-main)"
                                onChange={ev => {
                                    handleSwitchChange(ev.target.checked, value)
                                }}
                            /> */}
                        </Extra>
                    }
                    sortable={sortable}
                />
            )
        },
        [dsId, fieldList, handleDelete, handleSwitchChange, handleUpdateField, nodeId, showLength]
    )

    return (
        <SCxPropertySettingWrapper>
            <SCxGroup>
                <SCxGroupHeader>
                    <SCxGroupTitle>显示字段</SCxGroupTitle>
                    <SCxGroupButton
                        color="var(--color-main)"
                        onClick={() => {
                            toggleAllDisplay()
                        }}
                    >
                        全部隐藏
                    </SCxGroupButton>
                </SCxGroupHeader>
                <SCxGroupContent>
                    <List
                        sortable
                        data={showSchemaList}
                        onSortEnd={handleSortEnd}
                        style={{ maxHeight: '100%', overflow: 'auto' }}
                        itemDraggerStyle={{ height: 20 }}
                        itemStyle={{
                            alignItems: 'flex-start'
                        }}
                        itemRender={rest => itemRenderer({ ...rest, sortable: true })}
                    />
                    <AddFieldButton dsId={dsId} nodeId={nodeId} fieldList={fieldList} onAdd={handleAdd} />
                </SCxGroupContent>
            </SCxGroup>
            <SCxVertical />
            <SCxGroup>
                <SCxGroupHeader>
                    <SCxGroupTitle>未显示字段</SCxGroupTitle>
                    <SCxGroupButton
                        color="var(--color-main)"
                        onClick={() => {
                            toggleAllDisplay(true)
                        }}
                    >
                        全部显示
                    </SCxGroupButton>
                </SCxGroupHeader>
                <List
                    data={hideSchemaList}
                    style={{ flex: 1, maxHeight: 'none', overflow: 'auto' }}
                    onSortEnd={handleSortEnd}
                    itemRender={itemRenderer}
                />
            </SCxGroup>
        </SCxPropertySettingWrapper>
    )
}
