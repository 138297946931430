import { APPLICATION_VERSION_STATUS } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'

import { applicationVersionAtom } from '@/atoms/application/state'

import { PreviewButton } from './Preview'
import { PublishButton } from './Publish'
import { ShareButton } from './Share'

export const ApplicationActions = () => {
    const isPublished = useAtomData(
        applicationVersionAtom,
        useCallback(s => s.status === APPLICATION_VERSION_STATUS.ONLINE, [])
    )

    const [sharedPopoverOpened, setSharedPopoverOpened] = useState(false)

    return (
        <>
            <PreviewButton />
            {isPublished ? (
                <ShareButton popoverOpened={sharedPopoverOpened} onPopoverChange={setSharedPopoverOpened} />
            ) : (
                <PublishButton onPublishSuccess={() => setSharedPopoverOpened(true)} />
            )}
        </>
    )
}
