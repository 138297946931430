import type { Option } from '@byecode/ui'
import { Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { CollapseBox, FindUseType, getIsFindUse, getTableIcon, useFindUseObjectContext } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as SC from './styles'

interface BasicConfigureProps {
    dataSourceList: DataSourceAbstract[]
}

export const BasicConfigure: React.FC<BasicConfigureProps> = ({ dataSourceList }) => {
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const dataSourceOptions = useMemo(() => {
        return dataSourceList.reduce<Option[]>((prev, cur) => {
            if (cur.type !== 5) {
                prev.push({
                    label: cur.name,
                    value: cur.id,
                    icon: getTableIcon(cur)
                })
            }
            return prev
        }, [])
    }, [dataSourceList])

    return (
        <SC.Container>
            <CollapseBox label="关联主表">
                <SC.Content>
                    {/* <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>表名称</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input value={field.value} onChange={field.onChange} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem> */}

                    <SC.FormItem>
                        <SC.FormItemContent>
                            <Controller
                                name="primaryDsId"
                                control={control}
                                render={({ field }) => {
                                    const isHighLight = getIsFindUse({
                                        findUseObject,
                                        type: FindUseType.DATASHEET,
                                        dsId: field.value
                                    })
                                    return (
                                        <Select
                                            highlighting={isHighLight}
                                            value={field.value}
                                            disabled
                                            options={dataSourceOptions}
                                            onChange={val => field.onChange?.(val)}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
