import { Button, Flex, IconFont } from '@byecode/ui'
import { type BaseModalProps, DesktopModal } from '@byecode/ui/components/Modal'
import { useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { TagIcon } from '@/components/TagIcon'

import { SearchEngineModal } from '../SearchEngineModal'
import { SearchEngineQueueTable } from './SearchEngineQueueTable'

interface SearchEngineQueueProps extends BaseModalProps {}

const SCxFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 20px;
    border-radius: 0px 0px 12px 12px;
    background: var(--color-gray-50);
`

export const SearchEngineQueue: React.FunctionComponent<SearchEngineQueueProps> = ({ ...rest }) => {
    const [opened, { open, close }] = useDisclosure(false)
    const appSetting = useAtomData(websiteApplicationSettingAtom)
    const { seo } = appSetting ?? {}

    const isBaiduConfig = useMemo(
        () => seo?.baidu?.isOpened && seo?.baidu?.secret && seo?.baidu?.verificationInfo,
        [seo?.baidu?.isOpened, seo?.baidu?.secret, seo?.baidu?.verificationInfo]
    )
    const isBingConfig = useMemo(
        () => seo?.bing?.isOpened && seo?.bing?.secret && seo?.bing?.verificationInfo,
        [seo?.bing?.isOpened, seo?.bing?.secret, seo?.bing?.verificationInfo]
    )
    const isGoogleConfig = useMemo(
        () => seo?.google?.isOpened && seo?.google?.secret && seo?.google?.verificationInfo,
        [seo?.google?.isOpened, seo?.google?.secret, seo?.google?.verificationInfo]
    )

    return (
        <DesktopModal
            {...rest}
            width={785}
            styles={{
                header: {
                    borderColor: 'transparent!important'
                },
                root: {
                    fontSize: 14
                },
                modal: {
                    maxHeight: 'calc(100% - 48px)!important'
                    // height: '100%'
                },
                body: {
                    width: '100%',
                    padding: '0px!important',
                    overflow: 'hidden!important',
                    display: 'flex',
                    alignItems: 'stretch'
                    // height: '100%'
                }
            }}
            footer={
                <SCxFooter>
                    <Flex alignItems="center" gap="8px">
                        <Text>页面链接会排队提交给搜索引擎：</Text>
                        {isBaiduConfig && (
                            <TagIcon
                                icon="Baidu"
                                size={32}
                                radius={100}
                                iconColor="var(--color-theme-7)"
                                background="#fff"
                                style={{ border: '1px solid var(--color-gray-200)' }}
                            />
                        )}
                        {/* {isBingConfig && (
                            <TagIcon
                                icon="Bing"
                                size={32}
                                radius={100}
                                iconColor="var(--color-theme-7)"
                                background="#fff"
                                style={{ border: '1px solid var(--color-gray-200)' }}
                            />
                        )}
                         {isGoogleConfig && (
                            <TagIcon
                                icon="Google"
                                size={32}
                                radius={100}
                                iconColor="var(--color-theme-7)"
                                background="#fff"
                                style={{ border: '1px solid var(--color-gray-200)' }}
                            />
                        )} */}
                    </Flex>
                    <Button
                        style={{ height: 40 }}
                        onClick={open}
                        icon={<IconFont type="Setting" size={16} color="var(--color-gray-400)" />}
                    >
                        管理搜索引擎
                    </Button>
                </SCxFooter>
            }
        >
            <SearchEngineQueueTable />
            <SearchEngineModal open={opened} onClose={close} />
        </DesktopModal>
    )
}
