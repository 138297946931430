import { Group, Switch } from '@byecode/ui'
import type { ChartViewOptions } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { BlockSettingsInput } from '../../styles'
import { ColorList } from '../ThemeColorConfigurator/ColorList'
import { addPrefixNameToFormName } from '../utils'

interface IndicatorDesignProps {
    prefixName?: string
}

export const IndicatorDesign: React.FC<IndicatorDesignProps> = ({ prefixName }) => {
    const { control, watch } = useFormContext()
    const showHeadTitleFormName = addPrefixNameToFormName('showHeadTitle', prefixName)
    const titleFormName = addPrefixNameToFormName('title', prefixName)
    const showLabelFormName = addPrefixNameToFormName('showLabel', prefixName)
    const labelFormName = addPrefixNameToFormName('label', prefixName)
    const showUnitFormName = addPrefixNameToFormName('showUnit', prefixName)
    const unitFormName = addPrefixNameToFormName('unit', prefixName)
    const labelColorFormName = addPrefixNameToFormName('labelColor', prefixName)
    const showThousandthSplitFormName = addPrefixNameToFormName('showThousandthSplit', prefixName)

    const showHeadTitle = useWatch({ control, name: showHeadTitleFormName })
    const showLabel = useWatch({ control, name: showLabelFormName })
    const showUnit = useWatch({ control, name: showUnitFormName })
    return (
        <>
            <Group label="颜色">
                <Controller
                    control={control}
                    name={labelColorFormName}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <ColorList
                                value={value ? [value] : ['#101828']}
                                onChange={colorList => onChange(colorList[0])}
                                defaultColors={['#101828']}
                            />
                        )
                    }}
                />
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group label="标题">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示</Text>
                    <Controller
                        control={control}
                        name={showHeadTitleFormName}
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                {showHeadTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>文本</Text>
                        <Controller
                            control={control}
                            name={titleFormName}
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group label="描述">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示</Text>
                    <Controller
                        control={control}
                        name={showLabelFormName}
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                {showLabel && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>文本</Text>
                        <Controller
                            control={control}
                            name={labelFormName}
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>
            <Divider color="var(--color-gray-200)" />
            <Group label="格式">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示千分位</Text>
                    <Controller
                        control={control}
                        name={showThousandthSplitFormName}
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示单位</Text>
                    <Controller
                        control={control}
                        name={showUnitFormName}
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                {showUnit && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>自定义单位</Text>
                        <Controller
                            control={control}
                            name={unitFormName}
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>
        </>
    )
}
