import type { NodeTypes, OtherPlatformOption } from '@lighthouse/shared'
import {
    CollapseBox
} from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { OtherPlatformItem } from './OtherPlatformItem'
import * as SC from './styles'

interface OtherPlatformsNodeCreatorProps {
    groupId?: string
    title?: string
    options?: OtherPlatformOption[]
    onAddNode?: (nodeType: NodeTypes) => void
}

export const OtherPlatformsNodeCreator: React.FunctionComponent<OtherPlatformsNodeCreatorProps> = ({
    groupId,
    title,
    options = [],
    onAddNode
}) => {
    const handleItemClick = useCallback(
        (type: NodeTypes) => {
            onAddNode?.(type)
        },
        [onAddNode]
    )

    return (
        <CollapseBox key={groupId} label={title}>
            <SC.CreatorOtherCardGroupWrapper>
                {options.map(option => (
                    <OtherPlatformItem key={option.value} option={option} onItemClick={handleItemClick} />
                ))}
            </SC.CreatorOtherCardGroupWrapper>
        </CollapseBox>
    )
}
