import { Empty, Input, Select } from '@byecode/ui'
import type { FlowNode, SendMsgWxRootNode } from '@lighthouse/shared'
import {
    CollapseBox,
    FieldClassMap,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    NodeFieldPreview,
    optionFilterFieldType,
    VariableSourceType
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'

import type { WxRobotMode } from '@/components/OtherPlatFormModal'
import { AddWxRobot, WxRobotModal } from '@/components/OtherPlatFormModal'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { useOtherPlatformList } from '@/shared/reusable'

import { SEND_MSG_WX_OPTIONS } from '../../constant'
import * as CM from '../../styles'
import * as SC from './styles'

interface WxRobotActionConfigureProps {
    allParentNodes: FlowNode[]
}

interface State {
    mode: WxRobotMode
    opened: boolean
    id?: string
}

export const WxRobotActionConfigure: React.FC<WxRobotActionConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const disabledWithVersion = useIsDisabledWithVersion()
    const { control, register } = useFormContext<Pick<SendMsgWxRootNode, 'config'>>()
    const { data: otherPlatform } = useOtherPlatformList()
    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })

    const rootList = useMemo(() => (otherPlatform ?? []).filter(item => item.type === 'WE_COM_ROBOT'), [otherPlatform])

    const options = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: getAllCanBeUpstreamNodes(allParentNodes) || []
                    }
                ]
            }),
        [allParentNodes, dataSourceList]
    )

    const textOptions = useMemo(
        () =>
            optionFilterFieldType(
                dataSourceList,
                options,
                field => FieldClassMap[field.type].has('text') || (field.type === 'formula' && field.innerType === 'TEXT')
            ),
        [dataSourceList, options]
    )

    const handleUpdate = useCallback(
        (value: string) => {
            const data = find(item => item.id === value, rootList)
            setState({ opened: true, mode: 'update', id: data?.id })
        },
        [rootList, setState]
    )

    const handleAdd = useCallback(() => {
        setState({ opened: true, mode: 'add', id: undefined })
    }, [setState])

    const rootOptions = useMemo(
        () =>
            rootList.map((item, index) => ({
                label: item.name,
                value: item.id,
                icon: <SC.Icon size={16} type="BrandLogoqiyeweixin" />,
                extra: (
                    <SC.RightFill
                        onMouseDown={ev => {
                            ev.stopPropagation()
                            handleUpdate(item.id)
                        }}
                    >
                        <CM.Icon color="var(--color-gray-400)" type="PencilSimple" />
                    </SC.RightFill>
                )
            })),
        [handleUpdate, rootList]
    )

    return (
        <>
            <CM.Container>
                <CollapseBox label="选择账号" required>
                    <Controller
                        control={control}
                        name="config.id"
                        render={({ field }) => (
                            <SC.SelectContainer>
                                <Select
                                    hiddenEmpty
                                    placeholder="请选择"
                                    styles={{
                                        root: {
                                            margin: '0 16px'
                                        },
                                        item: {
                                            '&:hover>div>*:last-child': {
                                                display: 'flex'
                                            }
                                        }
                                    }}
                                    options={rootOptions}
                                    {...field}
                                    dropdownProps={{
                                        extra: (
                                            <>
                                                {rootOptions.length === 0 && (
                                                    <Empty
                                                        icon="BrandLogoqiyeweixinFilter"
                                                        styles={{
                                                            root: {
                                                                minHeight: '75px'
                                                            }
                                                        }}
                                                        description="您还没添加企业微信群机器人账号"
                                                    />
                                                )}
                                                <Divider color="var(--color-gray-200)" />
                                                <AddWxRobot onClick={() => handleAdd()} />
                                            </>
                                        )
                                    }}
                                />
                            </SC.SelectContainer>
                        )}
                    />
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="执行操作" required>
                    <Controller
                        control={control}
                        name="config.msgtype"
                        render={({ field }) => (
                            <SC.SelectContainer>
                                <Select
                                    placeholder="请选择"
                                    styles={{
                                        root: {
                                            margin: '0 16px'
                                        }
                                    }}
                                    options={SEND_MSG_WX_OPTIONS}
                                    {...field}
                                />
                            </SC.SelectContainer>
                        )}
                    />
                </CollapseBox>
                <Divider color="var(--color-gray-200)" />
                <CollapseBox label="配置参数">
                    <SC.Item>
                        <SC.Text>
                            文本内容
                            <SC.Text color="red" style={{ paddingLeft: 4 }}>
                                *
                            </SC.Text>
                        </SC.Text>
                        <SC.FormItemWrapper>
                            {/* <Input placeholder="请输入" {...register('config.text.content')} /> */}
                            <Controller
                                control={control}
                                name="config.text.editorContent"
                                render={({ field }) => (
                                    <NodeFieldPreview disabled={disabledWithVersion} {...field} autoHeight options={textOptions} />
                                )}
                            />
                        </SC.FormItemWrapper>
                        <SC.Description>方案启动后，填入的文本内容，将被企微群机器人发出，请检查核对。</SC.Description>
                    </SC.Item>
                    <SC.Item>
                        <SC.Text>提醒人员</SC.Text>
                        <SC.FormItemWrapper>
                            <Controller
                                control={control}
                                name="config.text.mentioned_mobile_list"
                                render={({ field }) => {
                                    const value = (field.value ?? [])?.join(',')
                                    return (
                                        <Input
                                            placeholder="请输入"
                                            value={value}
                                            onChange={e => field.onChange(e.target.value.split(','))}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemWrapper>
                        <SC.Description>
                            可以输入@all，提醒全部群成员，也可以输入成员ID提醒多人，并使用中/英文逗号隔开，比如@all，Wendywang、Kevinliu、Tonychen。
                            <br /> <br />
                            成员ID可以在企业微信管理后台-通讯录-成员详情中查看，“账号”即是成员ID，一般是一串英文
                        </SC.Description>
                    </SC.Item>
                </CollapseBox>
                <WxRobotModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
            </CM.Container>
        </>
    )
}
