import { IconFont, Select } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Group: React.FC = () => {
    return (
        <Unit title="分组">
            <Select
                placeholder="请选择"
                style={{
                    width: 200
                }}
                options={[
                    {
                        label: '性别',
                        value: 'gender',
                        children: [
                            { label: '男', value: 'man', icon: <IconFont type="Add" /> },
                            { label: '女', value: 'woman' }
                        ]
                    },
                    {
                        label: '爱好',
                        value: 'hobby',
                        children: [
                            { label: '打球', value: 'qiu' },
                            { label: '跑步', value: 'bu' }
                        ]
                    }
                ]}
            />
        </Unit>
    )
}
