import { Group } from '@byecode/ui'
import { Card, Grid, Title } from '@mantine/core'
import React from 'react'

import { Basic } from './Basic'
import { Block } from './Block'
import { Disable } from './Disable'
import { Icon } from './Icon'
import { Loading } from './Loading'
import { Radius } from './Radius'

export const ButtonComponentsDemo: React.FC = () => {
    return (
        <Card withBorder>
            <Group label={<Title order={2}>按钮</Title>} renderRightIcon={() => null}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Basic />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Disable />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Icon />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Loading />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Block />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Radius />
                    </Grid.Col>
                </Grid>
            </Group>
        </Card>
    )
}
