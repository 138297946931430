import type { FieldType, RichTextContentProtocol } from '@lighthouse/core'
import { clone } from 'rambda'

const fieldTypeRelationType: Record<string, string> = {
    text: 'text',
    number: 'text',
    email: 'text',
    phoneNumber: 'text',
    url: 'text',
    select: 'select',
    date: 'date',
    file: 'file',
    video: 'file',
    person: 'person',
    relation: 'select',
    // formula: 'text',
    notes: 'text',
    // userGroup: 'userGroup',
    cascade: 'cascade'
    // lookup: 'text'
}

export const getDefaultFiledValue: (type: FieldType) => RichTextContentProtocol = (type: FieldType) => {
    return {
        type: 'doc',
        content: [
            {
                type: 'paragraph',
                content: []
            }
        ]
    }
}

// export const richTextContentToFieldValue = (data: RichTextContentProtocol) => {
//     if(!data?.content){
//         return
//     }
//     // let initData = [] | '' | {} | string[] | json[]
//     data.content.map(item => {
//         if(item.type === 'paragraph'){
//             richTextContentToFieldValue(item)
//         }
//         if(item.type){

//         }
//         // fieldTypeRelationType
//         if(item.type === '')
//     })

// }

export const richTextContentConvertType: (data: RichTextContentProtocol) => RichTextContentProtocol = (data: RichTextContentProtocol) => {
    if (!data?.content) {
        return data
    }
    return data.content.map(item => {
        if (item.type === 'paragraph') {
            return richTextContentConvertType(clone(item))
        }
        if (item.type) {
            item.type = fieldTypeRelationType?.[item.type] || 'text'
        }
        return item
    })
}
