import { Button, Input } from '@byecode/ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export interface IframeInputProps {
    value?: string
    placeholder?: string
    onChange: (val: string) => void
}

const SCxWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`

const SCxInput = styled(Input)`
    flex: 1;
`

export const IframeInput: React.FC<IframeInputProps> = ({ value = '', placeholder, onChange }) => {
    const [status, setStatus] = useState(!!value)
    const inputRef = useRef<HTMLInputElement>(null)
    const [val, setVal] = useState(value)

    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        setVal(ev.target.value)
    }, [])

    // useEffect(() => {
    //     setStatus(!!value)
    // }, [value])

    const handleOk = useCallback(() => {
        if (status) {
            setStatus(false)
            return requestAnimationFrame(() => {
                inputRef.current?.focus()
            })
        }
        setStatus(true)
        onChange(val)
    }, [onChange, status, val])

    return (
        <SCxWrapper>
            <SCxInput value={val} ref={inputRef} disabled={status} onChange={handleChange} placeholder={placeholder} />
            <Button type={status ? 'default' : 'primary'} onClick={handleOk}>
                {status ? '编辑' : '确认'}
            </Button>
        </SCxWrapper>
    )
}
