import { Button, Modal, Text, Toast } from '@byecode/ui'
import { type AppUser, AppUserStatus } from '@lighthouse/core'
import { PaginationFc, useAtomAction } from '@lighthouse/shared'
import { isValid, lightFormat } from 'date-fns'
import { useAtomValue } from 'jotai'
import { find, findIndex, reduce } from 'rambda'
import * as React from 'react'
import { useCallback, useDeferredValue, useEffect, useMemo } from 'react'
import { debounce } from 'throttle-debounce'
import { useImmer } from 'use-immer'

import { unLinkApplicationAtom } from '@/atoms/application/action'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceUserAtom,
    fetchDataSourceUserGroupAtom,
    fetchEnvAtom
} from '@/atoms/dataSource/action'
import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import { useApplicationList } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import { appAuditUser, deleteAppUser, logOutAppUser, restoreAppUser, updateAppUser } from '@/services'
import { useDataSourceDepartments, useDataSourceUsers } from '@/shared/reusable'

import { ALL_DEPARTMENT, dateFormat, usersTitle } from '../constant'
import { LinkExtra } from '../LinkExtra'
import PanelHeader from '../PanelHeader'
import { PoolUser } from '../PoolUser'
import * as CM from '../styles'
import TollTop from '../ToolTop'
import UserDepartment from '../UserDepartment'
import { UserImport } from '../UserImport'
import UserInvite from '../UserInvite'
import UserList from '../UserList'
import * as SC from './styles'

interface UserPanelState {
    keyword: string
    selectUsers: AppUser[]
    searchDepartmentId: string
    currentPage: number
    userList: AppUser[]
}

interface UserPanelProps {}

const PAGE_SIZE = 30

export const UserPanel: React.FC<UserPanelProps> = () => {
    // const [link, linked] = useMemo(() => {
    //     return [application?.version?.link, application?.version.linked]
    // }, [application?.version?.link, application?.version.linked])

    // const linkApplication = useMemo(() => find(item => item.id === link?.appId, applicationList), [applicationList, link?.appId])
    const applicationList = useApplicationList()
    const envId = useDataSourceEnvId()
    const [state, setState] = useImmer<UserPanelState>({
        keyword: '',
        searchDepartmentId: ALL_DEPARTMENT,
        selectUsers: [],
        userList: [],
        currentPage: 1
    })

    // const application = useApplication()
    // const { link, linked } = version
    const debouncedTitle = useDeferredValue(state.keyword)
    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceUserGroup } = useAtomAction(fetchDataSourceUserGroupAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: unLinkApplication } = useAtomAction(unLinkApplicationAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { flatData: flatDepartments } = useDataSourceDepartments()
    const { data: usersData } = useDataSourceUsers()
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)

    const { link, linked } = dataSourceEnv

    const handleCancelLink = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认取消使用？',
            okStatus: 'error',
            okText: '确认'
        })
        if (isConfirm) {
            await unLinkApplication(envId)
            fetchEnv()
            await Promise.all([fetchDataSourceDepartment(), fetchDataSourceUserGroup()])
            fetchDataSourceUser()
        }
    }, [envId, fetchDataSourceDepartment, fetchDataSourceUser, fetchDataSourceUserGroup, fetchEnv, unLinkApplication])

    const searchUserList = useMemo(() => {
        return reduce<AppUser, AppUser[]>(
            (users, user) => {
                const newUser = {
                    ...user,
                    departmentNames:
                        user.departmentIds?.map((departmentId: string) => {
                            const department = find(item => departmentId === item.departmentId, flatDepartments)
                            return department ? department.departmentName : departmentId
                        }) ?? ''
                }
                const userStrArr: string = Object.entries(newUser)
                    .map(([key, value]) => {
                        if ((key === 'createdTime' || key === 'lastLoginTime') && typeof value === 'number') {
                            return isValid(value) ? lightFormat(value, dateFormat) : value
                        }
                        return value?.toString() ?? ''
                    })
                    .join(',')

                const searchDepartmentId = state.searchDepartmentId === ALL_DEPARTMENT ? '' : state.searchDepartmentId
                return userStrArr.search(debouncedTitle) !== -1 &&
                    (!searchDepartmentId || user?.departmentIds?.includes(searchDepartmentId))
                    ? [...users, user]
                    : users
            },
            [],
            usersData
        ).slice((state.currentPage - 1) * PAGE_SIZE, state.currentPage * PAGE_SIZE)
    }, [debouncedTitle, flatDepartments, state.currentPage, state.searchDepartmentId, usersData])

    const isShowLogOutBtn = useMemo(
        () =>
            state.selectUsers.some(
                user => (user.state === AppUserStatus.activated || user.state === AppUserStatus.unActivated) && !user.isDepart
            ),
        [state.selectUsers]
    )

    const isShowRestoreBtn = useMemo(() => state.selectUsers.some(user => user.isDepart), [state.selectUsers])

    useEffect(() => {
        setState(draft => {
            draft.userList = searchUserList
            draft.selectUsers = []
        })
    }, [searchUserList, setState])

    const DebounceUpdateUserFunc = useMemo(
        () =>
            debounce(300, async (params: AppUser) => {
                await updateAppUser(envId, { ...params })
                fetchDataSourceUser()
            }),
        [envId, fetchDataSourceUser]
    )

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.keyword = ev.target.value
                draft.currentPage = 1
            })
        },
        [setState]
    )

    const handleChangeSearchDepartment = useCallback(
        (searchDepartmentId: string) => {
            setState(draft => {
                draft.searchDepartmentId = searchDepartmentId
                draft.selectUsers = []
                draft.currentPage = 1
            })
        },
        [setState]
    )

    const handleUpdateUser = useCallback(
        (params: AppUser) => {
            setState(draft => {
                const userIndex = findIndex(draft => draft.userId === params.userId, draft.userList)
                if (userIndex !== -1) {
                    draft.userList[userIndex] = { ...draft.userList[userIndex], ...params }
                }
            })
            DebounceUpdateUserFunc({ ...params })
        },
        [DebounceUpdateUserFunc, setState]
    )

    const handleRestore = useCallback(
        async (users: AppUser[]) => {
            const logOutUserIds = reduce<AppUser, string[]>(
                (userIds, user) => (user.isDepart ? [...userIds, user.userId] : userIds),
                [],
                users
            )
            const isSuccess = await restoreAppUser(envId, logOutUserIds)
            if (isSuccess) {
                fetchDataSourceUser()
                Toast.success(`已恢复 ${logOutUserIds.length} 个用户`)
            }
        },
        [envId, fetchDataSourceUser]
    )
    const handleLogOut = useCallback(
        async (users: AppUser[]) => {
            const isCheckPendingUser = users.some(user => user.state === AppUserStatus.checkPending)
            if (isCheckPendingUser) {
                return Toast.error('您不能注销「待审核」的用户')
            }
            const logOutUserIds = reduce<AppUser, string[]>(
                (userIds, user) => (user.isDepart ? userIds : [...userIds, user.userId]),
                [],
                users
            )
            const isConfirm = await Modal.confirm({
                title: '确认注销',
                content: `确认注销这 ${logOutUserIds.length} 个用户，注销后用户将无法正常登录应用，且无法在成员选择中选择已注销的用户。`,
                okText: '注销'
            })
            if (isConfirm) {
                const isSuccess = await logOutAppUser(envId, logOutUserIds)
                if (isSuccess) {
                    fetchDataSourceUser()
                    Toast.success(`已注销 ${logOutUserIds.length} 个用户`)
                }
            }
        },
        [envId, fetchDataSourceUser]
    )

    const handleDelete = useCallback(
        async (users: AppUser[]) => {
            const isNoLogOutUser = users.some(user => !user.isDepart)
            if (isNoLogOutUser) {
                return Toast.error('只能删除「已注销」的用户')
            }
            const logOutUserIds = reduce<AppUser, string[]>(
                (userIds, user) => (user.isDepart ? [...userIds, user.userId] : userIds),
                [],
                users
            )
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                isCheckBoxConfirm: true,
                checkBoxLabel: '我已知晓上述风险',
                content: `确认删除这 ${logOutUserIds.length} 个用户，删除后用户将无法正常登录应用，删除该成员在所有成员字段中的数据，且无法恢复，请谨慎操作。`
            })
            if (isConfirm) {
                const isSuccess = await deleteAppUser(envId, logOutUserIds)
                if (isSuccess) {
                    fetchDataSourceUser()
                    Toast.success(`已删除 ${logOutUserIds.length} 个用户`)
                }
            }
        },
        [envId, fetchDataSourceUser]
    )

    const handleAudit = useCallback(
        async (audit: boolean, userId: string) => {
            await (audit ? appAuditUser(envId, userId) : deleteAppUser(envId, [userId]))
            fetchDataSourceUser()
        },
        [envId, fetchDataSourceUser]
    )

    const handleSelectUsers = useCallback(
        (users: AppUser[]) => {
            setState(draft => {
                draft.selectUsers = users
            })
        },
        [setState]
    )

    const handleChangePageNum = useCallback(
        (val: number) => {
            setState(draft => {
                draft.currentPage = val
                draft.selectUsers = []
            })
        },
        [setState]
    )

    return (
        <SC.Container>
            <TollTop
                leftFill={
                    <CM.LeftToolFill>
                        <Text color="var(--color-black)" size={16} weight={600}>
                            用户
                        </Text>
                        <LinkExtra applicationList={applicationList} dataSourceEnv={dataSourceEnv} onUnLinkApplication={handleCancelLink} />
                    </CM.LeftToolFill>
                }
                rightFill={
                    <SC.ToolsContainer>
                        {state.selectUsers.length > 0 ? (
                            <Button
                                onClick={() => handleSelectUsers(state.selectUsers.length > 0 ? [] : state.userList)}
                                type="default"
                                style={{ flexShrink: 0 }}
                            >
                                取消选择
                            </Button>
                        ) : (
                            <PanelHeader keyword={state.keyword} onSearchChange={handleSearchChange} />
                        )}
                        {isShowRestoreBtn && (
                            <Button onClick={() => handleRestore(state.selectUsers)} style={{ flexShrink: 0 }}>
                                恢复用户
                            </Button>
                        )}
                        {isShowLogOutBtn && (
                            <Button onClick={() => handleLogOut(state.selectUsers)} style={{ flexShrink: 0 }} danger type="primary">
                                注销用户
                            </Button>
                        )}
                        {isShowRestoreBtn && (
                            <Button onClick={() => handleDelete(state.selectUsers)} style={{ flexShrink: 0 }} danger type="primary">
                                删除
                            </Button>
                        )}
                        {state.selectUsers.length === 0 && <UserImport onFetchUsers={fetchDataSourceUser} />}
                        {state.selectUsers.length === 0 && <UserInvite envId={envId} />}
                    </SC.ToolsContainer>
                }
            />
            <SC.Body>
                <SC.LeftFill>
                    <UserDepartment value={state.searchDepartmentId} count={usersData.length} onChange={handleChangeSearchDepartment} />
                </SC.LeftFill>
                <SC.RightFill>
                    <UserList
                        searchUsers={state.userList}
                        titleList={usersTitle}
                        selectUsers={state.selectUsers}
                        onSelectUsers={handleSelectUsers}
                        onAudit={handleAudit}
                        onUpdateUser={handleUpdateUser}
                    />
                    <SC.TableFooter>
                        <PaginationFc
                            pagination={{ pageSize: PAGE_SIZE, currentPage: state.currentPage, rowTotal: usersData.length }}
                            onChangePageNum={handleChangePageNum}
                        />
                    </SC.TableFooter>
                    {!link?.appId && linked?.length === 0 && <PoolUser />}
                </SC.RightFill>
            </SC.Body>
        </SC.Container>
    )
}
