import type { NodeTypes } from '@lighthouse/shared'
import { CURRENT_RECORD } from '@lighthouse/shared'

export const SELECT_UPSTREAM_OPTIONS = [
    {
        label: '从上游节点中选择',
        value: 'upstream'
    }
]

export const SELECT_RECORD_OPTIONS = [
    {
        label: '当前记录',
        value: CURRENT_RECORD
    }
]
