import { Anchor, Button, Input, Modal, Toast } from '@byecode/ui'
import { CopyButton, ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import { useCurrentAppID } from '@/hooks/useApplication'
import { usePageURI } from '@/hooks/usePageURI'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { StripeAccountFormState, StripeAccountMode } from './type'

interface StripeAccountProps {
    id?: string
    mode: StripeAccountMode
    onClose?: () => void
}

const initData: StripeAccountFormState = {
    id: '',
    name: '',
    publicKey: '',
    secretKey: ''
}
const StripeAccount: React.FunctionComponent<StripeAccountProps> = ({ id, mode, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()
    const appId = useCurrentAppID()
    const { host } = usePageURI({ isProdHost: true })
    const [loading, setLoading] = useState(false)

    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform
            ? { ...otherPlatform?.config, name: otherPlatform.name, id, secretKey: PLACEHOLDER_PASSWORD, publicKey: PLACEHOLDER_PASSWORD }
            : initData
    }, [id, otherPlatforms])

    const webhooks = `https://${host}/byecode/external/stripe/pay/notify`

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: StripeAccountFormState) => {
            const { name, publicKey, secretKey } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'STRIPE',
                name,
                params: { publicKey, secretKey }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['STRIPE']}-${title}」。`,
            okStatus: 'error'
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['STRIPE']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: StripeAccountFormState) => {
            const { name, id, publicKey, secretKey } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'STRIPE',
                name,
                id,
                params: {
                    publicKey: publicKey === PLACEHOLDER_PASSWORD ? undefined : publicKey,
                    secretKey: secretKey === PLACEHOLDER_PASSWORD ? undefined : secretKey
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} type="Stripe" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}Stripe</SC.Title>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Description>开通Stripe支付账号，开通后订单将结算至账户银行卡内。</SC.Description>
                </SC.Item>
                <SC.Tip>
                    <SC.Icon color="var(--color-main)" type="WarningCircle" />
                    <SC.Text>如果您添加账号遇到问题，请查看帮助文档：</SC.Text>
                    <Anchor
                        target="_blank"
                        style={{ fontWeight: 600, color: 'var(--color-main)' }}
                        to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lyr9sxw7nbigb0ds"
                    >
                        如何添加Stripe
                    </Anchor>
                </SC.Tip>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>回调函数 Webhook</SC.Text>

                    <Input placeholder={webhooks} disabled size="lg" suffix={<CopyButton style={{ marginRight: 8 }} value={webhooks} />} />
                    <SC.Description>
                        请复制该链接粘贴到管理平台到：{' '}
                        <Anchor
                            target="_blank"
                            to="https://dashboard.stripe.com/login?redirect=%2Faccount%2Fapikeys"
                            style={{ color: 'var(--color-main)', fontWeight: 600 }}
                        >
                            Stripe管理平台
                        </Anchor>{' '}
                        {'>'} [首页] {'>'} [开发人员] {'>'} [Webhook] {'>'} [添加托管端点] {'>'} [复制当前Webhook链接至端点 URL] {'>'}{' '}
                        添加端点，否则集成将不会生效
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        账号标题
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input size="lg" {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        公钥
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="publicKey" errors={errors}>
                        <Controller
                            name="publicKey"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        size="lg"
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入 Stripe 账号的公钥"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        具有管理员权限登录{' '}
                        <Anchor
                            target="_blank"
                            to="https://dashboard.stripe.com/login?redirect=%2Faccount%2Fapikeys"
                            style={{ color: 'var(--color-main)' }}
                        >
                            Stripe管理平台
                        </Anchor>{' '}
                        [首页] {'>'} [公钥] {'>'} [点击以复制]
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        API 密钥
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="secretKey" errors={errors}>
                        <Controller
                            name="secretKey"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        size="lg"
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入API 密钥"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        具有管理员权限登录{' '}
                        <Anchor
                            target="_blank"
                            to="https://dashboard.stripe.com/login?redirect=%2Faccount%2Fapikeys"
                            style={{ color: 'var(--color-main)' }}
                        >
                            Stripe管理平台
                        </Anchor>{' '}
                        {'>'} [首页] {'>'} [API 密钥] {'>'} [显示一次] {'>'} [点击以复制]
                    </SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987', height: 40 }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104, height: 40 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104, height: 40 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default StripeAccount
