import { IconFont, Popover } from '@byecode/ui'
import { type AggregateResultField } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import { AggregatorFieldConfigure } from './AggregatorFieldConfigure'

const SCxDot = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
`

const SCxMoreIcon = styled.div`
    display: inline-flex;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    font-size: 18px;

    color: var(--color-black);
    cursor: pointer;
`

interface MoreSettingProps {
    dsId: string
    nodeId: string
    field: AggregateResultField
    fieldList: AggregateResultField[]
    onUpdateField?: (field: AggregateResultField) => void
}

export const MoreSetting: React.FC<MoreSettingProps> = ({ dsId, nodeId, field, fieldList, onUpdateField }) => {
    const [open, setOpen] = useState(false)

    const handleCancel = useCallback(() => {
        setOpen(false)
    }, [])

    const handleChange = useCallback(
        (value: AggregateResultField) => {
            handleCancel()
            onUpdateField?.(value)
        },
        [handleCancel, onUpdateField]
    )

    return (
        <SCxDot>
            <Popover opened={open} onChange={setOpen} withinPortal width={250}>
                <Popover.Target>
                    <SCxMoreIcon className="dotsThree">
                        <IconFont type="DotsThree" size={14} />
                    </SCxMoreIcon>
                </Popover.Target>
                <Popover.Dropdown compact>
                    <AggregatorFieldConfigure
                        dsId={dsId}
                        nodeId={nodeId}
                        field={field}
                        fieldList={fieldList}
                        onChange={handleChange}
                        onCancel={handleCancel}
                    />
                </Popover.Dropdown>
            </Popover>
        </SCxDot>
    )
}
