import { IconFont, Input } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Disabled: React.FC = () => {
    return (
        <Unit title="禁用">
            <Input placeholder="请输入" disabled />
            <Input placeholder="带 Icon 的，没做完？？？" disabled icon={<IconFont type="Add" />} />
            <Input placeholder="前缀" disabled prefix={<IconFont type="User" />} />
            <Input placeholder="后缀" disabled suffix={<IconFont type="PropertyNumber" />} />
        </Unit>
    )
}
