import type { FieldType, TypeInstanceMap } from '@lighthouse/core'
import type { AggregatorNodeType, StatisticsCalcType } from '@lighthouse/shared'

export const dataInputDisableFieldType = new Set<FieldType>(['file', 'video', 'notes'])

export const statisticsCalcTypeOptions: { label: string; value: StatisticsCalcType; innerTypeList: TypeInstanceMap[] }[] = [
    { label: '计数', value: 'COUNT', innerTypeList: ['TEXT', 'ARRAY', 'NUMBER', 'DATE', 'BOOL'] },
    // { label: '聚合为数组', value: 'TO_ARRAY', innerTypeList: ['TEXT', 'ARRAY', 'NUMBER', 'BOOL'] },
    { label: '求和', value: 'SUM', innerTypeList: ['NUMBER'] },
    { label: '平均', value: 'AVG', innerTypeList: ['NUMBER'] },
    { label: '最大值', value: 'MAX', innerTypeList: ['NUMBER', 'DATE'] },
    { label: '最小值', value: 'MIN', innerTypeList: ['NUMBER', 'DATE'] }
]

export const aggregatorNodeNameMap: Record<AggregatorNodeType, string> = {
    DATA_INPUT: '数据输入',
    JOIN: '横向连接',
    FIELD_SETTING: '字段设置',
    FILTER: '过滤',
    GROUP_STATISTICS: '分组统计',
    ADD_PLACEHOLDER: '添加节点'
}
