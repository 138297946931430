import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

export const blockText: BlockTreeItem = {
    name: '文本',
    icon: '',
    describeImage: BlockThumbImageMap['text'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.text
}

// export const blockTitle: BlockTreeItem = {
//     name: '大标题',
//     icon: 'BlockH3',
//     describeImage: BlockThumbImageMap['h3'],
//     color: '#667085',
//     backGroundColor: 'rgba(102, 112, 133, 0.10)',
//     type: BlockType.h3
// }

// export const blockQuote: BlockTreeItem = {
//     name: '引述标题',
//     icon: 'BlockQuote',
//     describeImage: BlockThumbImageMap['quote'],
//     color: '#F79009',
//     backGroundColor: 'rgba(250, 140, 22, 0.10)',
//     type: BlockType.quote
// }

// export const blockCallout: BlockTreeItem = {
//     name: '提示',
//     icon: 'BlockCallout',
//     describeImage: BlockThumbImageMap['prompt'],
//     color: '#667085',
//     backGroundColor: 'rgba(102, 112, 133, 0.10)',
//     type: BlockType.prompt
// }
// export const blockRichText: BlockTreeItem = {
//     name: '富文本',
//     icon: 'PropertyNote',
//     describeImage: BlockThumbImageMap['richText'],
//     color: '#2E90FA',
//     backGroundColor: 'rgba(46, 144, 250, 0.10)',
//     type: BlockType.richText
// }
