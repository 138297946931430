import { Loading } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Loading />
            <Loading outlined />
            <Loading shape="indicator" />
        </Unit>
    )
}
