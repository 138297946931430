import { IconFont, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const PersonSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    transition: border 0.2s ease;
    cursor: pointer;
    border-radius: 7px;
    background-color: var(--color-gray-100);
`

export const PersonSelectValueContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 6px 8px 0 8px;
    color: var(--color-gray-500);
`

export const PersonSelectedValue = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    flex-wrap: wrap;
    overflow: hidden;
`

export const IconAdder = styled(IconFont)`
    color: var(--color-gray-500);
`

export const PersonAdder = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-200);
    margin-bottom: 6px;

    &:hover {
        background-color: var(--color-main);

        ${IconAdder} {
            color: var(--color-white);
        }
    }
`

export const Placeholder = styled.span`
    color: var(--color-gray-300);
`

export const Tag = styled.div`
    width: 100%;
    margin: 0 2px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    color: var(--color-black);
    padding: 0 8px;
`

export const Text = styled.span`
    width: 100%;
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const PersonSelectOverlay = styled.div``

export const PersonSelectList = styled.div`
    width: 100%;
    max-height: 300px;
    overflow-y: auto;

    ${tinyButtons}
`

export const Group = styled.div``

export const GroupLabel = styled.div`
    padding: 4px 16px;
    font-size: 12px;
    color: var(--color-gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const PersonSelectItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const PersonTagItem = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    margin-bottom: 6px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const GroupAvatar = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
`

export const PersonSelectItemIcon = styled.div`
    margin-right: 6px;
`

export const PersonSelectLabel = styled.div`
    padding: 0 2px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    color: var(--color-black);
    margin-right: 6px;
`

export const NoData = styled.div`
    height: 60px;
    color: var(--color-black);
    line-height: 60px;
    text-align: center;
    font-size: 14px;
`

export const SearchWrapper = styled.div`
    width: 100%;
    padding: 4px 12px;
`

export const Search = styled.div`
    width: 100%;
    height: 32px;
    border-radius: 6px;
    background: var(--color-gray-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const SearchInput = styled.input`
    height: 32px;
    flex: 1;
    line-height: 32px;
    border: none;
    outline: none;
    font-size: var(--font-size-normal);
`

export const SearchExtra = styled.div`
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ClearIcon = styled(IconFont)``
