import JSEncrypt from 'jsencrypt'

const publicKey = `MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAfvoU419DPvEQkjmrhawoGJbLOZGgPfI5
8lHT1pB5BaFVUE2bEUy5sw4SScFS6uUImHzg+s+6rpw5FGZe1JMK1QIDAQAB`

const Encrypt = new JSEncrypt()

Encrypt.setPublicKey(publicKey)

export const encrypt = (password: string) => Encrypt.encrypt(password)
