import { Button, IconFont, Tooltip } from '@byecode/ui'
import type { ActionFlowNode, FlowEdge, FlowNode, SendMsgDingTalkRobotConfig } from '@lighthouse/shared'
import { nodeTypeIconMap, nodeTypeMap } from '@lighthouse/shared'
import cls from 'classnames'
import { lightFormat } from 'date-fns'
import { find } from 'rambda'
import React, { memo, useCallback, useMemo } from 'react'
import { Handle, Position, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { deleteNode } from '../../utils/deleteNode'
import { validateNode } from '../../utils/nodeValidator'
import { SEND_MSG_DINGTALK_OPTIONS } from '../NodeSetting/constant'

export interface ActionNodeOtherPlatformProps extends ActionFlowNode {
    isConnectable?: boolean
}

const SCxActionNodeOtherPlatformWrapper = styled.div`
    display: flex;
    width: 248px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
`

const SCxErrorInfoIconButton = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: 1;
    background-color: var(--color-white);
`

const SCxErrorInfoIcon = styled(IconFont)`
    font-size: 24px;
    color: var(--color-red-500);
`

const SCxActionIconWrapper = styled.div`
    position: relative;
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 20px;
    border-radius: 12px;
    color: #999999;
    border: 1px solid var(--color-gray-200);

    &.flow-node-selected {
        border-color: var(--color-main);
    }

    &.flow-node-success {
        border-width: 2px;
        border-color: var(--color-green-500);
        color: var(--color-green-500);
    }
    &.flow-node-failed {
        border-width: 2px;
        border-color: var(--color-red-500);
        color: var(--color-red-500);
    }
    &.flow-node-running {
        border-width: 2px;
        border-color: var(--color-blue-500);
        color: var(--color-blue-500);
    }
    &.flow-node-not-start {
        border-width: 2px;
        border-color: var(--color-gray-500);
        color: var(--color-gray-500);
        opacity: 0.5;
    }
`

export const SCxActionIcon = styled(IconFont)`
    font-size: 24px;
`

const SCxActionSettingWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    background: var(--color-gray-500);

    ${SCxActionNodeOtherPlatformWrapper}:hover & {
        opacity: 1;
    }

    &:hover {
        background: var(--color-gray-900);
    }
`

const SCxActionStatusIconWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.flow-icon-success {
        background-color: var(--color-green-500);
    }
    &.flow-icon-failed {
        background-color: var(--color-red-500);
    }
    &.flow-icon-running {
        background-color: var(--color-blue-500);
    }
    &.flow-icon-not-start {
        background-color: var(--color-gray-500);
    }
`

const SCxActionSettingButton = styled(Button)``

const SCxActionContentWrapper = styled.div`
    font-weight: 400;
    flex: 1;
    overflow: hidden;
`

const SCxActionTitle = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    color: #383838;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const SCxActionDescription = styled.div`
    font-size: 12px;
    color: #7c8492;
`

const SCxActionErrorInfo = styled.div`
    font-size: 12px;
    color: var(--color-red-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 0;
`

const ActionNodeOtherPlatform: React.FC<ActionNodeOtherPlatformProps> = props => {
    const { id, type, data, selected, isConnectable } = props
    // TODO: useNodeExternalData
    const { errorMap, readonly, onChange } = useFlow()
    const { name, nodeType, config, status } = data

    const { getNodes, getEdges, setNodes, setEdges } = useReactFlow()
    const disabledWithVersion = useIsDisabledWithVersion()
    // const nodeInfo = useMemo(() => getNodeInfo(nodeType), [nodeType])

    const description = useMemo(
        () => find(item => item.value === (config as SendMsgDingTalkRobotConfig)?.msgtype, SEND_MSG_DINGTALK_OPTIONS)?.label,
        [config]
    )

    const handleDelete = useCallback(
        (ev: React.MouseEvent<HTMLSpanElement>) => {
            ev.stopPropagation()
            ev.preventDefault()

            const nodes = getNodes() as FlowNode[]
            const edges = getEdges() as FlowEdge[]

            const { nodes: newNodes, edges: newEdges } = deleteNode({ id, type }, nodes, edges)

            setNodes(newNodes)
            setEdges(newEdges)

            onChange?.(newNodes, newEdges)
        },
        [getEdges, getNodes, id, onChange, setEdges, setNodes, type]
    )

    const errorMessage = useMemo(() => !validateNode(props) || errorMap?.[id], [errorMap, id, props])

    const logStatus = useMemo(() => {
        if (status) {
            const errorMessage = status.status === 'FAILED' ? <SCxActionErrorInfo>异常原因：{status.log}</SCxActionErrorInfo> : null
            return (
                <>
                    {status.executeTime && (
                        <SCxActionDescription>执行时间：{lightFormat(status.executeTime, 'yyyy/MM/dd HH:mm:ss')}</SCxActionDescription>
                    )}
                    {errorMessage}
                </>
            )
        }
        return null
    }, [status])

    const nodeContentStyles = useMemo<React.CSSProperties>(() => {
        if (status?.log || status?.executeTime) {
            return {
                position: 'absolute',
                left: 72
            }
        }
        return {
            position: 'initial',
            padding: '8px 12px'
        }
    }, [status])

    const iconWrapperClassNames = useMemo(() => {
        if (status) {
            return cls({
                'flow-node-selected': !readonly && selected,
                'flow-node-success': status.status === 'SUCCESS',
                'flow-node-failed': status.status === 'FAILED',
                'flow-node-running': status.status === 'RUNNING'
                // 'flow-node-not-start': status.status === 'NOT_START'
            })
        }
        return cls({
            'flow-node-selected': selected
        })
    }, [readonly, selected, status])

    const iconClassNames = useMemo(() => {
        if (status) {
            return cls({
                'flow-icon-success': status.status === 'SUCCESS',
                'flow-icon-failed': status.status === 'FAILED',
                'flow-icon-running': status.status === 'RUNNING'
                // 'flow-icon-not-start': status.status === 'NOT_START'
            })
        }
    }, [status])

    const statusIconType = useMemo(() => {
        switch (status?.status) {
            case 'SUCCESS': {
                return 'Tick'
            }
            case 'FAILED': {
                return 'Close'
            }
            case 'RUNNING': {
                return 'Repeat'
            }
            // case 'NOT_START': {
            //     return 'Clock'
            // }
            default: {
                return ''
            }
        }
    }, [status])

    return (
        <SCxActionNodeOtherPlatformWrapper>
            {errorMessage && (
                <Tooltip title="配置未完善" placement="top">
                    <SCxErrorInfoIconButton>
                        <SCxErrorInfoIcon type="WarningCircle" />
                    </SCxErrorInfoIconButton>
                </Tooltip>
            )}
            <SCxActionIconWrapper className={iconWrapperClassNames}>
                <SCxActionIcon size={36} type={nodeTypeIconMap[nodeType]} fill={nodeTypeMap[nodeType]?.color} />
                {status ? (
                    <SCxActionStatusIconWrapper className={iconClassNames}>
                        <IconFont style={{ color: 'var(--color-white)', cursor: 'default' }} size={12} type={statusIconType} />
                    </SCxActionStatusIconWrapper>
                ) : (
                    <SCxActionSettingWrapper>
                        <SCxActionSettingButton
                            type="text"
                            disabled={disabledWithVersion}
                            icon={<IconFont color="var(--color-white)" size={12} type="Close" />}
                            onClick={handleDelete}
                        />
                    </SCxActionSettingWrapper>
                )}
            </SCxActionIconWrapper>
            <SCxActionContentWrapper style={nodeContentStyles}>
                <SCxActionTitle>{name}</SCxActionTitle>
                {description && <SCxActionDescription>{description}</SCxActionDescription>}
                {logStatus}
            </SCxActionContentWrapper>
            <Handle
                type="target"
                position={Position.Top}
                id="handle"
                style={{ top: -4, bottom: 'auto', opacity: 0, left: 30 }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="handle"
                style={{ bottom: -4, top: 'auto', opacity: 0, left: 30 }}
                isConnectable={isConnectable}
            />
        </SCxActionNodeOtherPlatformWrapper>
    )
}

export default memo(ActionNodeOtherPlatform)
