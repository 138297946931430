import { useAtomAction } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'

import { linkApplicationAtom } from '@/atoms/application/action'
import { fetchDataSourceDepartmentAtom, fetchDataSourceUserAtom, fetchDataSourceUserGroupAtom, fetchEnvAtom } from '@/atoms/dataSource/action'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'

import { LinkApplication } from '../LinkApplication'
import * as SC from './styles'

export interface PoolUserProps {}

export const PoolUser: React.FC<PoolUserProps> = () => {
    const [open, setOpen] = useState(false)
    const currentAppId = useCurrentAppID()
    const currentDsEnvId = useDataSourceEnvId()
    const { run: linkApplication } = useAtomAction(linkApplicationAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: fetchDataSourceUser} = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceUserGroup} = useAtomAction(fetchDataSourceUserGroupAtom)
    const { run: fetchDataSourceDepartment} = useAtomAction(fetchDataSourceDepartmentAtom)

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleClick = useCallback(() => {
        setOpen(true)
    }, [])

    const handleLinkApplication = useCallback(
        async (appId: string, envId: string) => {
            await linkApplication({ linkAppId: appId, envId: currentDsEnvId, linkEnvId: envId })
            await Promise.all([fetchDataSourceDepartment(), fetchDataSourceUserGroup()])
            fetchDataSourceUser()
            fetchEnv()
        },
        [linkApplication, currentDsEnvId, fetchEnv, fetchDataSourceUser, fetchDataSourceUserGroup, fetchDataSourceDepartment]
    )

    return (
        <>
            <SC.Container>
                <SC.Left>
                    <SC.IconGroup>
                        <SC.Icon size={20} type="UserTable" />
                        <SC.ArrowIcon size={12} type="TransferData" />
                        <SC.Icon size={20} type="UserTable" />
                    </SC.IconGroup>
                    <SC.Info>
                        <SC.Title>使用其他应用的用户</SC.Title>
                        <SC.Description>其他应用的用户可免登录进入当前应用</SC.Description>
                    </SC.Info>
                </SC.Left>
                <SC.Right>
                    <SC.PoolBtn onClick={handleClick} icon={<SC.Icon size={14} fill="var(--color-theme-6)" type="TransferData" />}>
                        去使用
                    </SC.PoolBtn>
                </SC.Right>
            </SC.Container>
            <SC.PoolModal
                width={525}
                styles={{
                    desktop: {
                        body: {
                            padding: '24px 0 0 0'
                        }
                    }
                }}
                open={open}
                withCloseIcon={false}
                onClose={handleClose}
            >
                <LinkApplication appId={currentAppId} onClose={handleClose} onLinkApplication={handleLinkApplication} />
            </SC.PoolModal>
        </>
    )
}
