import { backgroundTransitionOnClick, flex, IconFont, pointer, wideSpreadAnimation } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    ${wideSpreadAnimation}

    @media (max-width: 1200px) {
        .byecode-text-root {
            display: none;
        }
    }
`
export const ModalContainer = styled.form`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    ${wideSpreadAnimation}
`
export const Name = styled.span`
    margin-top: 6.25px;
    line-height: 16px;
    color: var(--color-white);
    font-size: var(--font-size-sm);

    &.active {
        background-color: var(--color-gray-200);
    }
`

export const Icon = styled(IconFont)<{ size?: number; color?: string; bgColor?: string; cursor?: string }>`
    font-size: ${({ size }) => size ?? 16}px;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    cursor: ${({ cursor }) => cursor ?? 'pointer'};
`

export const SquareIcon = styled(IconFont)<{ size?: number; color?: string; bgColor?: string }>`
    padding: 6px;
    font-size: ${({ size }) => size ?? 16}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    background: ${({ bgColor }) => bgColor ?? 'var(--color-white)'};
    border-radius: 6px;
`

export const Text = styled.span<{ color?: string }>`
    font-size: var(--font-size-normal);
    color: ${({ color }) => color ?? 'var(--color-black)'};
    line-height: 16px;
    ${pointer}
`

export const Btn = styled.div`
    padding: 6px;
    ${flex}
    align-items: center;
    justify-content: center;
    height: 28px;
    border-radius: 5px;
    gap: 6px;
    ${pointer}
    ${backgroundTransitionOnClick}
    color: var(--color-gray-600);

    &.active {
        color: var(--color-black);
        background-color: var(--color-gray-200);

        ${Icon} {
            color: var(--color-black);
        }
    }
`

export const Content = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 63px);
    ${flex};
    flex: 1;
`

export const Title = styled.div`
    width: 100%;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-size-base);
    line-height: 24px;
    color: var(--color-black);
`

export const Tip = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    margin: 6px 0;
`
