import { Toast } from '@byecode/ui'
import type { FC } from 'react'
import React, { useCallback, useRef } from 'react'
import { useImmer } from 'use-immer'

import * as SC from './styles'
import type { ModalPropType, StateType } from './types'

export const DelSpaceModal: FC<ModalPropType> = ({ onDelete, onClose, spaceName, spaceLength }) => {
    const [state, setState] = useImmer<StateType>({
        value: '',
        randomStr: spaceName
    })

    const ref = useRef(null)

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.value = event.target.value
            })
        },
        [setState]
    )

    const delHandle = useCallback(() => {
        // if (spaceLength < 2) {
        //     return
        // }
        if (state.value === state.randomStr) {
            onDelete()
            return
        }
        Toast.error('空间名称输入错误')
    }, [state.value, state.randomStr, onDelete])

    return (
        <>
            <SC.ModalAlert>{`你现在的操作是删除空间 “${spaceName}”，一旦操作成功，空间下的所有内容将会删除。请输入下面内容再次确认操作。`}</SC.ModalAlert>
            <SC.ModalTips>请在下方输入框中输入内容以确认操作</SC.ModalTips>
            <SC.ModalInput onChange={handleChangeInput} value={state.value} placeholder={state.randomStr} />
            <SC.ModalFooter>
                <SC.CancelBtn onClick={onClose}>取消</SC.CancelBtn>
                <SC.DelBtn onClick={delHandle}>删除空间</SC.DelBtn>
            </SC.ModalFooter>
        </>
    )
}

export default DelSpaceModal
