import { Group, SegmentedControl, Switch } from '@byecode/ui'
import type { ChartType, ChartViewOptions } from '@lighthouse/core'
import { areaChartType, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { chartStackingOptions, legendTranspose } from '../../constants'
import { BlockSettingsInput } from '../../styles'
import { MarkLineConfigurator } from '../MarkLineConfigurator'
import { ThemeColorConfigurator } from '../ThemeColorConfigurator'

export interface CompositeDesignProps {
    chartType: ChartType
}

export const CompositeDesign: React.FC<CompositeDesignProps> = ({ chartType }) => {
    const { control, watch } = useFormContext<ChartViewOptions>()
    const showHeadTitle = watch('showHeadTitle')
    const showAxisTitle = watch('showAxisTitle')
    const showLegend = watch('showLegend')
    const showSecondaryAxis = watch('showSecondaryAxis')
    const isAreaChart = areaChartType.has(chartType)
    const isLineBarComposite = chartType === 'line' || chartType === 'bar' || chartType === 'composite' || chartType === 'striation'
    const enableShowAxisTitle = isLineBarComposite
    const enableXAxisTitle = isLineBarComposite && showAxisTitle
    const enableYMainAxisTitle = isLineBarComposite && showAxisTitle
    const enableYSecondaryAxisTitle = isLineBarComposite && showAxisTitle && showSecondaryAxis
    const enableMarkLine = isLineBarComposite
    const enableOtherDesign = isLineBarComposite
    const enableThemeColor = isLineBarComposite || isAreaChart
    return (
        <>
            <Group label="标题">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示图表标题</Text>
                    <Controller
                        control={control}
                        name="showHeadTitle"
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                {showHeadTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图表标题</Text>
                        <Controller
                            control={control}
                            name="title"
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
                {enableShowAxisTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>显示轴标题</Text>
                        <Controller
                            control={control}
                            name="showAxisTitle"
                            render={({ field: { value, onChange } }) => {
                                return <Switch checked={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
                {enableXAxisTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>X轴标题</Text>
                        <Controller
                            control={control}
                            name="xAxisTitle"
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
                {enableYMainAxisTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>Y轴-主轴标题</Text>
                        <Controller
                            control={control}
                            name="yMainAxisTitle"
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
                {enableYSecondaryAxisTitle && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>Y轴-次轴标题</Text>
                        <Controller
                            control={control}
                            name="ySecondaryAxisTitle"
                            render={({ field: { value, onChange } }) => {
                                return <BlockSettingsInput width={180} value={value} onChange={onChange} />
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>
            {enableThemeColor && <ThemeColorConfigurator />}
            {enableMarkLine && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group label="辅助线">
                        <Controller
                            control={control}
                            name="mainMarkLines"
                            render={({ field: { value, onChange } }) => {
                                return <MarkLineConfigurator label="主轴" value={value} onChange={onChange} />
                            }}
                        />
                        {showSecondaryAxis && (
                            <Controller
                                control={control}
                                name="secondaryMarkLines"
                                render={({ field: { value, onChange } }) => {
                                    return <MarkLineConfigurator label="次轴" value={value} onChange={onChange} />
                                }}
                            />
                        )}
                    </Group>
                </>
            )}
            <Divider color="var(--color-gray-200)" />
            <Group label="图例">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示图例</Text>
                    <Controller
                        control={control}
                        name="showLegend"
                        render={({ field: { value, onChange } }) => {
                            return <Switch checked={value} onChange={onChange} />
                        }}
                    />
                </ListItem4ByecodeUi>
                {showLegend && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图例方向</Text>
                        <Controller
                            control={control}
                            name="legendTranspose"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <SegmentedControl
                                        style={{ width: 157 }}
                                        fullWidth
                                        data={legendTranspose}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>

            {enableOtherDesign && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group label="其他">
                        {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示方向</Text>
                            <Controller
                                control={control}
                                name="transpose"
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SegmentedControl data={transpose} value={value ? 'vertical' : 'horizontal'} onChange={val => onChange(val === 'vertical')} />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi> */}
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>展示方式</Text>
                            <Controller
                                control={control}
                                name="stacking"
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SegmentedControl
                                            style={{ width: 157 }}
                                            fullWidth
                                            data={chartStackingOptions}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示数值标签</Text>
                            <Controller
                                control={control}
                                name="showLabel"
                                render={({ field: { value, onChange } }) => {
                                    return <Switch checked={value} onChange={onChange} />
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示网络线</Text>
                            <Controller
                                control={control}
                                name="showGridLine"
                                render={({ field: { value, onChange } }) => {
                                    return <Switch checked={value} onChange={onChange} />
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示缩略轴</Text>
                            <Controller
                                control={control}
                                name="showAbbreviationAxis"
                                render={({ field: { value, onChange } }) => {
                                    return <Switch checked={value} onChange={onChange} />
                                }}
                            />
                        </ListItem4ByecodeUi>
                    </Group>
                </>
            )}
        </>
    )
}
