import { Toast } from '@byecode/ui'
import { Popover } from '@byecode/ui/components/Popover'
import { ErrorMessage, passwordFormatRegex, passwordRegex } from '@lighthouse/shared'
import { useDisclosure } from '@mantine/hooks'
import type { FormEvent } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn, useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { RuleBox, RuleItem } from '@/components/RuleBox'
import * as srv from '@/services'
import { encrypt } from '@/utils'

import * as SC from '../../styles'
import type { ResetPasswordErrors, ResetPasswordForm, ResetPasswordProps, ResetPasswordState } from './types'

export const ResetPassword: React.FC<ResetPasswordProps> = ({ verificationData, onChangeMode }) => {
    const navigate = useNavigate()
    const [passwordRuleOpen, { open: openPasswordRule, close: closePasswordRule }] = useDisclosure(false)
    const [confirmPasswordOpen, { open: openConfirmPasswordRule, close: closeConfirmPasswordRule }] = useDisclosure(false)
    const {
        register,
        handleSubmit,
        watch,
        clearErrors,
        getValues,
        formState: { errors }
    } = useForm<ResetPasswordForm>({ mode: 'onSubmit', shouldFocusError: false })

    // 重置密码

    const [state, setState] = useImmer<ResetPasswordState>({
        confirmPasswordLengthValidation: false,
        confirmPasswordRuleValidation: false,
        newPasswordLengthValidation: false,
        newPasswordRuleValidation: false
    })

    const [{ loading, value }, doFetch] = useAsyncFn(srv.updatePassword)

    useUpdateEffect(() => {
        if (value) {
            onChangeMode('resetSuccess')
        }
    }, [value])

    // 表单监听
    useEffect(() => {
        const subscription = watch((value: Partial<ResetPasswordForm>) => {
            setState(draft => {
                draft.newPasswordLengthValidation = !!value.newPassword && value.newPassword.length >= 8 && value.newPassword.length <= 20
                draft.newPasswordRuleValidation = !!value.newPassword && passwordFormatRegex.test(value.newPassword)
                draft.confirmPasswordLengthValidation =
                    !!value.confirmPassword && value.confirmPassword.length >= 8 && value.confirmPassword.length <= 20
                draft.confirmPasswordRuleValidation = !!value.confirmPassword && passwordFormatRegex.test(value.confirmPassword)
            })
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [errors, setState, watch])

    // 完成
    const finish = useCallback(
        (data: ResetPasswordForm) => {
            const enctyptPassword = encrypt(data.newPassword)
            if (!verificationData.mobile || !verificationData.smsCode) {
                Toast.warning('信息丢失,请重新进行手机号验证')
                onChangeMode('verification')
                return
            }
            doFetch({
                mobile: verificationData.mobile,
                smsCode: verificationData.smsCode,
                password: enctyptPassword || ''
            })
        },
        [doFetch, onChangeMode, verificationData.mobile, verificationData.smsCode]
    )

    // 跳转到登录
    const jumpVerificationCode = () => {
        onChangeMode('login')
    }

    // 密码比较是否一致
    const compareToFirstPassword = (val?: string) => {
        const confirmPassword = getValues('confirmPassword')
        if (confirmPassword === val) {
            return true
        }
        return '两次密码不一致'
    }

    const clearFormError = useCallback(
        (name: 'newPassword' | 'confirmPassword') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(finish)()
        },
        [finish, handleSubmit]
    )

    return (
        <SC.FormContainer onSubmit={handleFormSubmit}>
            <SC.InputWrapper>
                <ErrorMessage name="newPassword" errors={errors}>
                    <Popover trapFocus={false} opened={passwordRuleOpen} position="top-start">
                        <Popover.Target>
                            <div>
                                <SC.AccountInput
                                    type="password"
                                    onFocus={() => {
                                        openPasswordRule()
                                        clearFormError('newPassword')
                                    }}
                                    placeholder="新的密码"
                                    {...register('newPassword', {
                                        onBlur: closePasswordRule,
                                        required: '密码不能为空',
                                        minLength: { value: 8, message: '请输入8-20位密码' },
                                        maxLength: { value: 20, message: '请输入8-20位密码' },
                                        validate: {
                                            checkPassword: compareToFirstPassword
                                        },
                                        pattern: {
                                            value: passwordFormatRegex,
                                            message: '密码需是字母、数字/符号的组合'
                                        }
                                    })}
                                />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <RuleItem
                                icon="TickCircle"
                                iconColor={state.newPasswordLengthValidation ? '' : 'var(--color-gray-400)'}
                                text="8-20位"
                            />

                            <RuleItem
                                icon="TickCircle"
                                iconColor={state.newPasswordRuleValidation ? '' : 'var(--color-gray-400)'}
                                text="字母、数字/符号的组合"
                            />
                        </Popover.Dropdown>
                    </Popover>
                </ErrorMessage>

                <ErrorMessage name="confirmPassword" errors={errors}>
                    <Popover width="auto" trapFocus={false} opened={confirmPasswordOpen} position="top-start">
                        <Popover.Target>
                            <div>
                                <SC.AccountInput
                                    placeholder="确认密码"
                                    type="password"
                                    onFocus={() => {
                                        clearFormError('confirmPassword')
                                        openConfirmPasswordRule()
                                    }}
                                    {...register('confirmPassword', {
                                        onBlur: closeConfirmPasswordRule,
                                        required: '密码不能为空',
                                        minLength: { value: 8, message: '请输入8-20位密码' },
                                        maxLength: { value: 20, message: '请输入8-20位密码' },
                                        pattern: {
                                            value: passwordFormatRegex,
                                            message: '密码需是字母、数字/符号的组合'
                                        }
                                    })}
                                />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <RuleItem
                                icon="TickCircle"
                                iconColor={state.confirmPasswordLengthValidation ? '' : 'var(--color-gray-400)'}
                                text="8-20位"
                            />
                            <RuleItem
                                icon="TickCircle"
                                iconColor={state.confirmPasswordRuleValidation ? '' : 'var(--color-gray-400)'}
                                text="字母、数字/符号的组合"
                            />
                        </Popover.Dropdown>
                    </Popover>
                </ErrorMessage>
            </SC.InputWrapper>
            <SC.ButtonContainer>
                <SC.AccountButton block type="primary" loading={loading} htmlType="submit">
                    确认
                </SC.AccountButton>
                <SC.AccountButton block loading={loading} onClick={jumpVerificationCode}>
                    返回登录
                </SC.AccountButton>
            </SC.ButtonContainer>
        </SC.FormContainer>
    )
}
