import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 68px;
`

export const Main = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const TitleAfter = styled.div``

export const Title = styled.div<{ size: number }>`
    min-width: fit-content;
    padding: 20px 0;
    font-weight: 500;
    font-size: ${({ size }) => `${size}px`};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-gray-700);
`

export const Extra = styled.div``
