import { useContextPopoverHeight, usePopoverContext } from '@byecode/ui'
import { min } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import SpaceItem from '../SpaceItem'
// import spaceState from '@/lib/space'
import * as SC from './styles'
import type { Action, MenuProps } from './types'

export const SpaceMenu: React.FC<MenuProps> = ({ space, workspaces, onCreateSpace, onChangeSpace }) => {
    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context, offset: 16 })
    const handle = useMemo(
        () => ({
            CREATE_BLOCK() {
                onCreateSpace(true)
            }
        }),
        [onCreateSpace]
    )

    const handleSettingClick = useCallback(
        (action: Action) => {
            handle[action]()
        },
        [handle]
    )

    const handleSpaceItemClick = useCallback(
        (id: string) => {
            onChangeSpace(id)
        },
        [onChangeSpace]
    )

    const list = useMemo(() => workspaces.filter(item => item.id !== space.id), [space, workspaces])

    return (
        <SC.MenuList ref={internalRef} maxHeight={min(600, maxHeight || 600)}>
            <SC.SpaceList>
                {list.map(space => (
                    <SpaceItem data={space} key={space.id} onClick={handleSpaceItemClick} />
                ))}
            </SC.SpaceList>
            <SC.ListFooter>
                <SC.StyledDivider />
                <SC.ListFooterItem
                    onClick={() => {
                        handleSettingClick('CREATE_BLOCK')
                    }}
                >
                    <SC.ItemIcon type="Add" />
                    <SC.ListItemInfo>
                        <SC.ListItemText>创建新的空间</SC.ListItemText>
                    </SC.ListItemInfo>
                </SC.ListFooterItem>
            </SC.ListFooter>
        </SC.MenuList>
    )
}
