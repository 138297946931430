import type { Embla } from '@mantine/carousel'
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel'
import type { ModalProps } from '@mantine/core'
import { Flex, Modal, Text, Transition } from '@mantine/core'
import QRCode from 'qrcode.react'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect, useMount } from 'react-use'
import styled from 'styled-components'

import { useHomePageShare } from '@/shared/reusable'

import * as CM from '../../styles'
import { Card } from '../Card'

interface PublishShareProps {
    shareUrl: string
    onClose?: () => void
    onBack: () => void
}

const SCxContainer = styled.div`
    /* width: 390px; */
    margin: -24px;
    overflow: hidden;
    border-radius: 12px;
`

const SCxSlider = styled.div`
    width: 386px;
    height: 100%;
`

const SCxTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-white);
    /* 20/SC-Medium */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
`

const SCxSliderTitle = styled.div`
    color: #88dbb5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-align: center;
    padding-top: 33px;
    padding-bottom: 12px;
`

const SCxContent = styled.div`
    height: 470px;
    padding: 20px 22px 0 22px;
    background-color: #12b76a;
`

const SCxFooter = styled.div`
    position: relative;
    height: 130px;
`

const SCxQrCodeContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: -106px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`
const SCxQrCode = styled.div`
    padding: 16px;
    border-radius: 12px;
    width: 165px;
    height: 165px;
    border: 1px solid #2b7754;
    background: var(--color-white);
    /* 阴影样式 01 */
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
`

const SCxCarousel = styled.div`
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(35, 138, 90, 0.3) 0%, rgba(35, 138, 90, 0) 100%);
`

const TRANSITION_DURATION = 200

export const PublishShare: React.FunctionComponent<PublishShareProps> = ({ shareUrl, onClose, onBack }) => {
    const [embla, setEmbla] = useState<Embla | null>(null)
    const { data } = useHomePageShare()
    useAnimationOffsetEffect(embla, TRANSITION_DURATION)
    const [mounted, setMounted] = useState(false)

    useIsomorphicLayoutEffect(() => {
        setMounted(true)
    })

    return (
        <Transition mounted={mounted} transition="fade" duration={800} timingFunction="ease">
            {styles => (
                <SCxContainer style={styles}>
                    <SCxContent>
                        <Flex justify="space-between" style={{ paddingBottom: 28 }}>
                            <CM.Icon type="ArrowLeftSmall" color="var(--color-white)" onClick={onBack} />
                            <SCxTitle>
                                <CM.Icon size={32} color="var(--color-white)" type="WeChatLogo" />
                                <Text lineClamp={1}>分享到微信</Text>
                            </SCxTitle>
                            {onClose && <CM.Icon type="Close" color="var(--color-white)" onClick={onClose} />}
                        </Flex>
                        <SCxCarousel>
                            <Carousel
                                styles={{
                                    indicators: {
                                        bottom: 100
                                    },
                                    indicator: {
                                        width: 10,
                                        height: 10
                                    }
                                }}
                                getEmblaApi={setEmbla}
                                withIndicators
                                withControls={false}
                                height={355}
                            >
                                <Carousel.Slide>
                                    <SCxSlider>
                                        <SCxSliderTitle>微信消息效果</SCxSliderTitle>
                                        {/* style={{  }} */}
                                        <Card
                                            type="wxMessage"
                                            theme="green"
                                            isShadow
                                            styles={{
                                                card: {
                                                    width: 228,
                                                    overflow: 'hidden',
                                                    flex: 'none'
                                                },
                                                root: {
                                                    paddingRight: 36
                                                }
                                            }}
                                            data={data}
                                        />
                                    </SCxSlider>
                                </Carousel.Slide>
                                <Carousel.Slide>
                                    <SCxSlider>
                                        <SCxSliderTitle>朋友圈效果</SCxSliderTitle>
                                        <Card
                                            type="wxFriendCircle"
                                            theme="green"
                                            isShadow
                                            styles={{
                                                card: {
                                                    width: 230,
                                                    overflow: 'hidden',
                                                    flex: 'none'
                                                },
                                                root: {
                                                    justifyContent: 'flex-start',
                                                    paddingLeft: 40
                                                }
                                            }}
                                            data={data}
                                        />
                                    </SCxSlider>
                                </Carousel.Slide>
                            </Carousel>
                        </SCxCarousel>
                    </SCxContent>
                    <SCxFooter>
                        <SCxQrCodeContainer>
                            <SCxQrCode>
                                <QRCode size={132} value={shareUrl} renderAs="canvas" />
                            </SCxQrCode>
                            <Text size={16}>打开微信，扫一扫</Text>
                        </SCxQrCodeContainer>
                    </SCxFooter>
                </SCxContainer>
            )}
        </Transition>
    )
}
