import { Switch } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Disabled: React.FC = () => {
    return (
        <Unit title="禁用">
            <Switch size="xs" disabled />
            <Switch size="sm" disabled />
            <Switch disabled />
            <Switch size="lg" disabled checked />
        </Unit>
    )
}
