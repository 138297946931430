import { Card, Collapse, Grid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React from 'react'

import { Basic } from './Basic'

export const MessageComponentDemo = () => {
    const [opened, { toggle }] = useDisclosure(true)

    return (
        <Card withBorder>
            <Title order={2} onClick={toggle}>
                Message
            </Title>
            <Collapse in={opened}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Basic />
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    )
}
