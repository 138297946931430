import Uploady from '@rpldy/uploady'
import React from 'react'

import { uploadManagerInAppParams } from '@/utils/auth'

import SettingLogo from '../../SettingLogo'
import * as CM from '../../styles'
import * as SC from './styles'

interface WebTagLogoProps {
    value: string

    onChange?: (value: string) => void
}

const WebTagLogo = React.forwardRef<HTMLDivElement, WebTagLogoProps>(({ value, onChange }, ref) => {
    return (
        <SC.Container ref={ref}>
            <SC.Content>
                <SC.LeftFill>
                    <Uploady accept="image/png, image/ico" {...uploadManagerInAppParams()}>
                        <SC.UploadImgContain>
                            <SettingLogo
                                value={value}
                                defaultIcon={<CM.Icon size={24} type="BlockImage" color="var(--color-gray-400)" />}
                                onChange={url => onChange?.(url)}
                                style={{ width: 70, height: 70 }}
                            />
                        </SC.UploadImgContain>
                    </Uploady>
                </SC.LeftFill>
                <SC.RightFill>
                    <SC.UploadImgDesc>
                        <li>图片大小不能超过 2mb</li>
                        <li> 仅支持.png、.ico 格式的图片</li>
                        <li>建议上传 100 * 100px 的图片</li>
                    </SC.UploadImgDesc>
                </SC.RightFill>
            </SC.Content>
        </SC.Container>
    )
})

export default WebTagLogo
