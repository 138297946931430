import { DividerBlock } from '@lighthouse/block'
import type { DividerBlockAbstract } from '@lighthouse/core'
import type { FC } from 'react'
import React from 'react'

interface DividerBlockControllerProps {
    blockData: DividerBlockAbstract
}

const DividerBlockController: FC<DividerBlockControllerProps> = props => {
    return <DividerBlock {...props} />
}

export default DividerBlockController
