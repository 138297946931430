import { Switch } from '@byecode/ui/components/Switch'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingAuthenticationField, Field, FieldType, TypeInstanceMap } from '@lighthouse/core'
import { Collapse, Flex } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'

import { FieldSelect } from '@/components/FieldSelect'

import * as CM from '../../styles'
import * as SC from './styles'

interface PerfectFieldItemProps {
    field: Field
    fieldType?: FieldType
    options?: { label: string; value: string; type?: FieldType; innerType?: TypeInstanceMap }[]
    value: ApplicationSettingAuthenticationField
    onChange: (params: ApplicationSettingAuthenticationField) => void
    onDelete?: () => void
}

const PerfectFieldBlock: React.FC<PerfectFieldItemProps> = ({ field, options, value, onChange, onDelete }) => {
    const { title, placeHolder, required } = value
    const [open, setOpen] = useState(true)

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id: field?.id
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === field?.id ? 1 : 0
        }),
        [active?.id, field?.id, transform, transition]
    )

    const handleChangeField = useCallback(
        (fieldId: string | number) => {
            onChange?.({ fieldId: fieldId.toString(), title: '', placeHolder: '' })
        },
        [onChange]
    )

    const handleChange = useCallback(
        (params: Partial<ApplicationSettingAuthenticationField>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    return (
        <SC.Contain {...attributes} style={style} ref={setNodeRef}>
            <SC.Header>
                <SC.LeftFill>
                    <SC.Handle {...listeners}>
                        <CM.Icon size={16} type="DotsSix" color="var(--color-gray-500)" style={{ cursor: 'grab' }} />
                    </SC.Handle>
                    <Flex gap={4} align="center">
                        {/* {icon && <CM.Icon size={16} color="var(--color-gray-500)" type={getFieldIcon(field?.id, icon, field.innerType)} />} */}
                        <SC.Title>{field.name}</SC.Title>
                    </Flex>
                </SC.LeftFill>
                <SC.RightFill>
                    <CM.Icon onClick={() => onDelete?.()} type="Trash" />
                    <CM.Icon onClick={() => setOpen(!open)} type={open ? 'ArrowDownSmall' : 'ArrowUpSmall'} />
                </SC.RightFill>
            </SC.Header>
            <Collapse in={open}>
                <SC.Content>
                    <SC.Fill>
                        <SC.LeftFill>字段</SC.LeftFill>
                        <SC.RightFill>
                            <FieldSelect
                                dsId={field.dsId}
                                value={field.id}
                                width="100%"
                                disabled
                                onChange={handleChangeField}
                                options={options}
                            />
                        </SC.RightFill>
                    </SC.Fill>

                    <SC.Fill>
                        <SC.LeftFill>标题</SC.LeftFill>
                        <SC.RightFill>
                            <SC.TextInput value={title} placeholder={field.name} onChange={e => handleChange({ title: e.target.value })} />
                        </SC.RightFill>
                    </SC.Fill>
                    <SC.Fill>
                        <SC.LeftFill>占位符</SC.LeftFill>
                        <SC.RightFill>
                            <SC.TextInput
                                value={placeHolder}
                                placeholder={`请输入${field.name}`}
                                onChange={e => handleChange({ placeHolder: e.target.value })}
                            />
                        </SC.RightFill>
                    </SC.Fill>
                    <SC.Fill>
                        <SC.LeftFill>必填</SC.LeftFill>
                        <SC.RightFill>
                            <Switch checked={required} onChange={e => handleChange({ required: e.target.checked })} />
                        </SC.RightFill>
                    </SC.Fill>
                </SC.Content>
            </Collapse>
        </SC.Contain>
    )
}

export default PerfectFieldBlock
