import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const BetaRequestForm = styled.div`
    /* margin: 100px auto 80px; */
    /* padding: 60px; */
    /* height: 400px; */
    /* max-width: 600px;
    min-height: 600px;
    max-height: 680px; */
`

export const BetaRequestStepTitleWrapper = styled.div``

export const BetaRequestStepIndicatorWrapper = styled.div<{ isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ isMobile }) => (isMobile ? '100px' : 0)};
    width: 100%;
    padding: 0 40px;
    max-width: ${({ isMobile }) => (isMobile ? '418px' : '728px')};
    margin-bottom: 7vh;
`

export const BetaRequestStepIndicator = styled.div<{ isFull?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: ${({ isFull }) => (isFull ? 1 : 'none')};
`

export const Line = styled.div<{ width: string }>`
    max-width: ${({ width }) => width};
    flex: 1;
    height: 1px;
    margin: 0 12px;
    background-color: var(--color-gray-300);
`

export const BetaRequestStepIndex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    line-height: 24px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    border-radius: 50%;
    margin-right: 8px;
`

export const BetaRequestStepText = styled.div`
    white-space: nowrap;
    flex-shrink: 0;
    width: fit-content;
`

export const BetaRequestStepTitle = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
`

export const BetaRequestStepDescription = styled.div`
    font-size: 14px;
    color: #999999;
    line-height: 24px;
    margin-bottom: 32px;
`

export const BetaRequestFormButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
`

export const AccountButton = styled(Button)`
    height: 40px;
    line-height: 40px;
`
