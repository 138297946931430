import { Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { Drawer } from '@mantine/core'
import copyToClipboard from 'copy-to-clipboard'
import React, { useCallback } from 'react'

import * as SC from './styles'

export const MobileSupport: React.FC = () => {
    const handleCopy = useCallback(() => {
        copyToClipboard('https://app.byecode.com') && Toast.success('已复制链接')
    }, [])

    return (
        <Drawer
            opened
            size="100%"
            styles={{
                root: {
                    overflow: 'hidden'
                },
                body: {
                    height: '100%'
                }
            }}
            onClose={() => false}
            withCloseButton={false}
            position="bottom"
        >
            <SC.Container>
                {/* <SC.Header>请使用电脑打开</SC.Header> */}
                <SC.Content>
                    <SC.ImgBox>
                        <SC.Img src={getAssetUrl('empty', 'EmptyNoDataSource.jpg')} alt="" />
                    </SC.ImgBox>
                    <SC.Text>请在电脑端浏览器打开工作台</SC.Text>
                    <SC.Remark>暂不支持手机端编辑应用 请使用电脑打开</SC.Remark>
                    <SC.Link>app.byecode.com</SC.Link>
                    <SC.Btn type="primary" onClick={handleCopy}>
                        复制链接
                    </SC.Btn>
                </SC.Content>
            </SC.Container>
        </Drawer>
    )
}
