import { IconFont, Popover } from '@byecode/ui'
import type { DataSourceAbstract, Field, ViewFieldProtocol } from '@lighthouse/core'
import React, { useCallback, useRef, useState } from 'react'

import { TableFieldEditor } from '../TableFieldEditor'
import * as SC from './styles'

export interface TableFieldAdderProps {
    dataSource: DataSourceAbstract
    columns: ViewFieldProtocol[]
    dataSourceList: DataSourceAbstract[]
    onSubmit?: (data: Field) => Promise<boolean>
}

const TableFieldAdder: React.FC<TableFieldAdderProps> = ({ dataSource, columns, dataSourceList, onSubmit }) => {
    const [open, setOpen] = useState(false)
    const btnRef = useRef<HTMLDivElement>(null)

    const handleClick = useCallback((ev: React.MouseEvent<HTMLElement>) => {
        setOpen(true)
    }, [])

    const handleCancel = useCallback(() => {
        setOpen(false)
    }, [])

    const handleOk = useCallback(
        async (data: Field) => {
            if (onSubmit) {
                const isSuccess = await onSubmit(data)
                if (isSuccess) {
                    handleCancel()
                }
            }
        },
        [handleCancel, onSubmit]
    )

    return (
        <Popover withinPortal width="auto" position="bottom-start" offsetOptions={0} opened={open} onChange={setOpen}>
            <Popover.Target>
                <SC.AddField onClick={handleClick} ref={btnRef}>
                    <IconFont type="Add" />
                </SC.AddField>
            </Popover.Target>
            <Popover.Dropdown
                style={{
                    padding: 0,
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}
            >
                <TableFieldEditor
                    fieldId=""
                    dataSourceList={dataSourceList}
                    dataSource={dataSource}
                    columns={columns}
                    onOk={handleOk}
                    onCancel={handleCancel}
                />
            </Popover.Dropdown>
        </Popover>
    )
}

export default TableFieldAdder
