import { Select } from '@byecode/ui'
import type { FlowNode, NodeTypes, VariableSource } from '@lighthouse/shared'
import {
    CollapseBox,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    nodeTypeOptions,
    pureTextFieldTypes,
    useAtomData,
    USER_DATASOURCE,
    VariableSelect,
    VariableSourceType
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { dataSourcePoolAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import * as SC from '../styles'

export interface CallActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
    isAction?: boolean
}

const filterType = (fieldType: string) => {
    return fieldType === 'phoneNumber'
}

export const CallActionConfigure: React.FC<CallActionConfigureProps> = ({
    prefixName = 'config',
    isAction,
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control } = useFormContext()
    const dataSourceList = useAtomData(dataSourcePoolAtom)
    const disabledWithVersion = useIsDisabledWithVersion()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dsId)
    // const formDataSource = useDataSource(appId, envId， dsId)

    const { type } = useFlow()
    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const userDsOption = useMemo(() => {
        const ds = find(ds => ds.id === USER_DATASOURCE, dataSourceList)
        if (!ds) {
            return
        }

        return getUserDatasourceOptions({ dataSource: ds, validateFieldType: field => filterType(field.type) })
    }, [dataSourceList])

    const options = useMemo(() => {
        // if (!dataSource) {
        //     return []
        // }

        return getDefaultValueOptions({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    dataSourceList,
                    parentNodes: getAllCanBeUpstreamNodes(allParentNodes)
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateFieldType: filterType
        })
    }, [isInFlow, dataSourceList, allParentNodes, isNotAutomation, prev.datasource, prev.page, curr.datasource, curr.page])

    const formOption = useMemo(
        () =>
            isForm && !isInFlow ? getFormDatasourceOptions({ dataSource, validateFieldType: field => filterType(field.type) }) : undefined,
        [isForm, isInFlow, dataSource]
    )

    const { customViewOption } = useVariableCustomViewOption(field => filterType(field.type))

    const viewOption = useMemo(() => {
        if (isInFlow) {
            return
        }
        return customViewOption || getViewOptions({ dataSource, viewType, validateFieldType: field => filterType(field.type) })
    }, [customViewOption, viewType, dataSource, isInFlow])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>电话号码</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.phone`}
                                control={control}
                                render={({ field }) => (
                                    <VariableSelect
                                        field={{
                                            id: '',
                                            dsId: '',
                                            type: 'phoneNumber',
                                            name: 'phoneNumber',
                                            innerType: 'TEXT'
                                        }}
                                        disabled={disabledWithVersion}
                                        options={options}
                                        userOption={userDsOption}
                                        formOption={formOption}
                                        viewOption={viewOption}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
