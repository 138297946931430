import type { VariableADTvalue } from '@lighthouse/core'
import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { getVariableText } from '../../Variable'
import { VariableComponent } from './Component'

export const VariableNode = Node.create({
    name: 'variable',
    group: 'inline',
    inline: true,
    parseHTML() {
        return [{ tag: 'variable' }]
    },
    renderText({ node }) {
        const { options: extensionOptions } = this
        const { options, userOption, viewOption, formOption } = extensionOptions
        const variableData = node.attrs.value as VariableADTvalue

        return (
            getVariableText({
                value: variableData,
                viewOption,
                formOption,
                options,
                userDsOption: userOption
            }) || ''
        )
    },
    renderHTML({ node, HTMLAttributes }) {
        return [
            'variable',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                'data-value': JSON.stringify(node.attrs.value)
            })
        ]
    },
    addAttributes() {
        return {
            value: {
                default: '',
                parseHTML: element => {
                    const value = element.getAttribute('data-value')

                    return value ? JSON.parse(value) : {}
                },
                renderHTML: attributes => ({
                    'data-value': JSON.stringify(attributes.value)
                })
            }
        }
    },
    addNodeView() {
        return ReactNodeViewRenderer(VariableComponent)
    }
})
