import { Select } from '@byecode/ui'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import { Flex, Text } from '@mantine/core'
import React from 'react'

import { registerModeList } from '../constant'

interface PerfectWayProps {
    value: string
    onChange: (value: string) => void
}

const PerfectWay = React.forwardRef<HTMLDivElement, PerfectWayProps>(({ value, onChange }, ref) => {
    return (
        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center" ref={ref}>
            <Text>完善内容</Text>

            <Flex>
                <Select value={value} style={{ width: 180 }} options={registerModeList} onChange={onChange} />
            </Flex>
        </ListItemPaddingByecodeUi>
    )
})

export default React.memo(PerfectWay)
