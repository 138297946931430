import { Divider } from '@mantine/core'
import React, { useCallback } from 'react'

import * as SC from './styles'

export type OriginType = 'bottom-left' | 'top-left' | 'bottom-right' | 'top-right'
export interface MenuItemChildrenItems<T> {
    name: string
    icon: string
    hotkey?: string
    action: T
    divider?: boolean
    extra?: React.ReactNode
}
export interface MenuItem<T extends string> {
    section?: string
    name?: string
    items: MenuItemChildrenItems<T>[]
}

export interface RadioItem {
    value: string | number
    label: string
    name: string
}

type OperationMenuProps<T extends string> = {
    menuItems?: MenuItem<T>[]
    radioList?: RadioItem[]
    radioValue?: string | number
    value?: string
    title?: string
    id?: string
    isRename?: boolean
    errorMessage?: string
    extra?: React.ReactNode
    maxLength?: number
    handlers?: (dsId: string) => Record<string, (sender?: React.MouseEvent<HTMLDivElement>) => void>
    onRadioChange?: (value: string | number) => void
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClose: () => void
}

export const OperationMenu: React.FunctionComponent<OperationMenuProps<string>> = ({
    id = '',
    menuItems = [],
    radioList = [],
    errorMessage,
    title = '',
    value = '',
    radioValue = '',
    isRename = true,
    maxLength = 64,
    extra,
    onRadioChange,
    handlers,
    onChange,
    onClose
}) => {
    const handleKeyChange = useCallback(
        (e: React.KeyboardEvent) => {
            e.stopPropagation()
            if (e.nativeEvent.keyCode === 13) {
                onClose()
            }
        },
        [onClose]
    )

    return (
        <SC.ListContain onClick={e => e.stopPropagation()}>
            <SC.Text>{title}</SC.Text>
            {isRename && (
                <SC.ErrMsg name="title" message={errorMessage}>
                    <SC.TitleInput
                        maxLength={maxLength}
                        value={value}
                        placeholder="名称"
                        autoSelect
                        onKeyPress={handleKeyChange}
                        onChange={onChange}
                    />
                </SC.ErrMsg>
            )}
            {menuItems.map(({ section, items }) => (
                <SC.ListGroup key={section}>
                    {section && <SC.ListGroupTitle>{section}</SC.ListGroupTitle>}
                    {items.map(({ name, icon, hotkey, action, divider }) =>
                        divider ? (
                            <Divider />
                        ) : (
                            <SC.MenuItem key={name} onClick={sender => handlers?.(id)?.[action]?.(sender)}>
                                <SC.Icon type={icon} color="var(--color-gray-400)" />
                                <SC.Name>{name}</SC.Name>
                            </SC.MenuItem>
                        )
                    )}
                </SC.ListGroup>
            ))}
            {radioList.length > 0 && (
                <SC.ListRadioGroup>
                    <SC.ListGroupTitle>按钮类型</SC.ListGroupTitle>
                    {radioList.map(({ value, label, name }) => (
                        <SC.ListCheckItem key={value}>
                            <SC.ListCheckBox
                                name={name}
                                checked={radioValue.toString() === value.toString()}
                                value={value.toString()}
                                onChange={e => onRadioChange?.(e.target.value)}
                            />
                            <SC.Text>{label}</SC.Text>
                        </SC.ListCheckItem>
                    ))}
                </SC.ListRadioGroup>
            )}
            {extra}
        </SC.ListContain>
    )
}
