import { Input } from '@byecode/ui'
import type { SendMsgDingTalkRobotRootNode } from '@lighthouse/shared'
import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as SC from './styles'

interface PersonConfigProps {}

export const PersonConfig: React.FunctionComponent<PersonConfigProps> = props => {
    const { control, register, watch, setValue } = useFormContext<Pick<SendMsgDingTalkRobotRootNode, 'config'>>()

    return (
        <>
            <SC.Item>
                <SC.Text>提醒人员</SC.Text>
                <Controller
                    control={control}
                    name="config.at.atMobiles"
                    render={({ field }) => {
                        const value = (field.value ?? [])?.join(',')
                        return (
                            <Input
                                value={value}
                                onChange={e => {
                                    const newValue = e.target.value
                                    const arrayValue = newValue.split(',')
                                    setValue('config.at.isAtAll', newValue === '@all')
                                    setValue(
                                        'config.at.atUserIds',
                                        arrayValue.filter(item => item === '@all')
                                    )
                                    field.onChange(arrayValue)
                                }}
                            />
                        )
                    }}
                />
                <SC.Description>
                    可以输入 @all，提醒全部群成员，也可以输入钉钉成员的手机号提醒多人，并使用中/英文逗号隔开，例如：188****9089, 156****3321
                </SC.Description>
            </SC.Item>
        </>
    )
}
