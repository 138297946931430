import { Checkbox } from '@byecode/ui'
import { UserGroupType } from '@lighthouse/core'
import type { AppUserGroup } from '@lighthouse/shared'
import * as React from 'react'

import { userGroupsTitle } from '../constant'
import * as CM from '../styles'
import UserGroupItem from '../UserGroupItem'

interface UserGroupListProps {
    disabled?: boolean
    groups?: AppUserGroup[]
    selectGroups: string[]
    onSelectGroup?: (groups: string[]) => void
    onChangeName?: (name: string, id: string) => void
}

const UserGroupList: React.FC<UserGroupListProps> = ({ disabled, groups = [], selectGroups, onSelectGroup, onChangeName }) => {
    return (
        <CM.TableContainer>
            <CM.TableHeader>
                {userGroupsTitle.map(title => (
                    <CM.Item key={title.id} {...title}>
                        {title.id === 'checkbox' ? (
                            <Checkbox
                                disabled={disabled}
                                size="xs"
                                checked={
                                    selectGroups.length === groups.filter(draft => draft.type === UserGroupType.custom).length &&
                                    groups.length > 0
                                }
                                onChange={e => onSelectGroup?.(e.target.checked ? groups.map(draft => draft.groupId) : [])}
                            />
                        ) : (
                            <CM.TableColumnName>{title.name}</CM.TableColumnName>
                        )}
                    </CM.Item>
                ))}
            </CM.TableHeader>
            <CM.TableContent>
                {groups.map(group => (
                    <UserGroupItem
                        disabled={disabled}
                        group={group}
                        checked={selectGroups.includes(group.groupId)}
                        key={group.groupId}
                        onChangeName={onChangeName}
                        onSelectGroup={groupId =>
                            onSelectGroup?.(groupId ? [...selectGroups, groupId] : selectGroups.filter(draft => draft !== group.groupId))
                        }
                    />
                ))}
            </CM.TableContent>
        </CM.TableContainer>
    )
}

export default UserGroupList
