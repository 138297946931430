import { type FieldInputConfigProtocol, type FieldInputType, type FieldOptionsProtocol,CheckboxShapeEnum } from '@lighthouse/core'
import { DATE_FORMAT_OPTIONS } from '@lighthouse/shared'

const FIELD_INPUT_STYLES: FieldOptionsProtocol = {
    canEdit: true,
    showTitle: true,
    size: 'middle',
    direction: 'column'
}

/**
 * 生成字段block的配置
 *
 * @param {FieldInputType} type
 * @returns {FieldInputConfigProtocol}
 */
export const generateFieldBlock = (type: FieldInputType): FieldInputConfigProtocol => {
    switch (type) {
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'file':
        case 'notes': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                placeholder: '请输入'
            }
        }
        case 'checkbox': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                checkbox: {
                    gapType: 'fixed',
                    gap: 0.625,
                    shape: CheckboxShapeEnum.checkbox,
                    type: 'checkbox',
                    describePosition: 'right'
                }
            }
        }
        case 'date': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                placeholder: '请选择',
                date: {
                    dateFormat: DATE_FORMAT_OPTIONS[0].value,
                    range: 'ALL'
                }
            }
        }
        case 'number': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                placeholder: '请输入',
                number: {}
            }
        }
        // case 'select': {
        //     return {
        //         inputType: type,
        //         placeholder: '请选择',
        //         ...FIELD_INPUT_STYLES,
        //         select: {
        //             canMultipleChoice: false
        //         }
        //     }
        // }
        case 'person': {
            return {
                inputType: type,
                placeholder: '请选择',
                ...FIELD_INPUT_STYLES,
                person: {
                    canMultipleChoice: false,
                    filter: {}
                }
            }
        }
        case 'relativeSelect': {
            return {
                inputType: type,
                placeholder: '请选择',
                ...FIELD_INPUT_STYLES,
                relativeSelect: {
                    showType: 'table',
                    viewFieldSettings: [],
                    canCreateRecord: false,
                    direction: 'vertical',
                    showMode: 'input',
                    creatingConfig: {
                        page: '',
                        openType: 'modal',
                        label: '添加'
                    }
                }
            }
        }
        case 'slider': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                placeholder: '请输入',
                slider: {
                    min: 0,
                    max: 100,
                    step: 1,
                    isInput: true,
                    colorMode: 'theme',
                    color: 'var(--color-main)'
                }
            }
        }
        case 'cascade': {
            return {
                inputType: type,
                ...FIELD_INPUT_STYLES,
                placeholder: '请选择',
                cascade: {
                    cascadePointer: '',
                    cascadeFieldPointer: 'ID',
                    cascadeShowFieldPointer: '',
                    parentFieldPointer: '',
                    sortFieldPointer: '',
                    isLastLevel: true,
                    showPath: true
                }
            }
        }
        default: {
            return { inputType: 'text' }
        }
    }
}
