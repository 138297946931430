import { IconFont } from '@byecode/ui'
import { BreakPointSize, useBreakpoint } from '@lighthouse/tools'
import React, { useCallback } from 'react'

import { stepOptions } from './constants'
import * as SC from './styles'

interface StepIndicatorProps {
    currentStep: number
    isMobile?: boolean
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep, isMobile }) => {
    const getStepIndex = useCallback(
        (index: number) => {
            if (currentStep > index) {
                return (
                    <SC.BetaRequestStepIndex
                        style={{
                            backgroundColor: 'var(--color-purple-200)',
                            color: 'var(--color-main)'
                        }}
                    >
                        <IconFont type="Tick" />
                    </SC.BetaRequestStepIndex>
                )
            }
            if (index === currentStep) {
                return (
                    <SC.BetaRequestStepIndex
                        style={{
                            backgroundColor: 'var(--color-main)',
                            color: 'var(--color-white)'
                        }}
                    >
                        {index + 1}
                    </SC.BetaRequestStepIndex>
                )
            }
            return (
                <SC.BetaRequestStepIndex
                    style={{
                        backgroundColor: 'var(--color-gray-200)',
                        color: 'var(--color-gray-600)'
                    }}
                >
                    {index + 1}
                </SC.BetaRequestStepIndex>
            )
        },
        [currentStep]
    )

    return (
        <SC.BetaRequestStepIndicatorWrapper isMobile={isMobile}>
            {stepOptions.map(({ value }, i) => {
                const isLast = stepOptions.length === i + 1
                return (
                    <SC.BetaRequestStepIndicator key={value} isFull={!isLast}>
                        {getStepIndex(i)}
                        {!isMobile && <SC.BetaRequestStepText>{value}</SC.BetaRequestStepText>}
                        {!isLast && <SC.Line width={isMobile ? '74px' : '116px'} />}
                    </SC.BetaRequestStepIndicator>
                )
            })}
        </SC.BetaRequestStepIndicatorWrapper>
    )
}
