import React from 'react'

import { Account } from '@/containers/Account'

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
    return <Account />
}

export default Login
