import { Enum } from '@lighthouse/tools'

import type { CreatableProtocol, EnvironmentIdentity } from '../protocols'

export const APPLICATION_VERSION_STATUS = Enum(/* 'UN_PUBLISH', 'EDITING',  */ 'ONLINE', 'OFFLINE')
/**
 * 应用版本状态
 * UN_PUBLISH：未发布
 * EDITING: 编辑中
 * ONLINE: 已上线
 * OFFLINE: 已下线
 */
export type ApplicationVersionStatus = Enum<typeof APPLICATION_VERSION_STATUS>

// /** 应用版本的配置信息 */
// export type ApplicationVersionConfig = {
//     authentication: ApplicationSettingAuthentication
//     navbar: ApplicationSettingNavbar
// }

/** 应用版本分支 */
export interface ApplicationVersionBranch extends CreatableProtocol {
    id: string
    status: ApplicationVersionStatus
    envId: string
    // config: ApplicationVersionConfig
    preview: boolean
    url: string
    name: string
    remark?: string
}

