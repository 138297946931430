import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => {
    return {
        root: css({}),
        dropDown: css({
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            width: '100%',
            minHeight: 300
        }),
        dropContent: css({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: ' 100%',
            overflow: 'hidden',
            flex: 1
        })
    }
})
