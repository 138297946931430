import { Toast, Tooltip } from '@byecode/ui'
import type { RoleOption } from '@lighthouse/shared'
import { ErrorMessage, useHandleAbortPrevious } from '@lighthouse/shared'
import { ResultAsync } from 'neverthrow'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useSWRConfig } from 'swr'

import * as srv from '@/services'

// import { useBoundStore } from '@/stores'
import * as SC from './styles'

export interface InvitePhoneProps {
    children?: React.ReactNode
    roleOption: RoleOption[]
    spaceId: string
    onClose: () => void
}
export interface InviteForm {
    spaceId: string
    userId: string
    avatar?: string
    username: string
    mobile: string
    roleId: string
}

// 表单验证错误回调
const onError = () => undefined

export const InvitePhone: React.FC<InvitePhoneProps> = ({ spaceId, roleOption, onClose }) => {
    const { mutate } = useSWRConfig()
    // const { name, members } = useBoundStore(s => pick(['name', 'members', 'updateWorkSpace'], s.workSpaceSlice))
    const {
        control,
        handleSubmit,
        clearErrors,
        getValues,
        formState: { errors }
    } = useForm<{ options: InviteForm[] }>({
        shouldFocusError: false,
        mode: 'onSubmit',
        defaultValues: {
            options: [
                {
                    spaceId,
                    userId: '',
                    username: '',
                    avatar: '',
                    mobile: '',
                    roleId: 'SPACE_MEMBER'
                }
            ]
        }
    })
    const { fields, remove, append, update } = useFieldArray({ control, name: 'options' })

    const options = useMemo(() => roleOption.map(item => ({ label: item.label, value: item.value })), [roleOption])

    const { mutation: getUserAvatar } = useHandleAbortPrevious(srv.getUserAvatar)

    const renderIcon = useCallback(
        (index: number) => {
            const field = getValues().options[index]
            if (!field) {
                return null
            }
            const { avatar, username } = field
            if (username) {
                return (
                    <Tooltip title={username} placement="top" withArrow>
                        <SC.UserItemAbbrText>{username.slice(0, 1)}</SC.UserItemAbbrText>
                    </Tooltip>
                )
            }
            if (!avatar) {
                return <SC.UserIcon type="User" />
            }
            if (avatar === 'loading') {
                return <SC.UserIcon type="Spinner" />
            }
            if (avatar === 'error') {
                return (
                    <Tooltip title="未注册账号" placement="top" withArrow>
                        <SC.UserIcon type="QuestionMark" />
                    </Tooltip>
                )
            }

            return <SC.UserImg background={avatar} />
        },
        [getValues]
    )

    const handleAdd = useCallback(() => {
        const inviteSort: InviteForm = {
            spaceId,
            mobile: '',
            avatar: '',
            username: '',
            userId: '',
            roleId: 'SPACE_MEMBER'
        }

        append(inviteSort)
    }, [append, spaceId])

    const handleDelete = useCallback(
        (index: number) => {
            remove(index)
        },
        [remove]
    )

    const inviteSubmit = useCallback(
        async (data: { options: InviteForm[] }) => {
            const users = data.options.map(item => ({
                userId: item.userId,
                roleId: item.roleId
            }))
            const res = await ResultAsync.fromPromise(srv.addUsersToSpace(spaceId, users), e => e)
            if (res.isOk()) {
                Toast.success('邀请成功')
                // updateWorkSpace({ id: spaceId, name, members: members + 1 })
                mutate(`/spaceGetUsers/get-${spaceId}`)
                onClose()
            }
        },
        [mutate, onClose, spaceId]
    )

    const handleMobileChange = useCallback(
        async (mobile: string, index: number) => {
            const value = getValues().options[index]
            if (!value) {
                return
            }
            if (mobile.length !== 11) {
                update(index, { ...value, mobile, spaceId, avatar: '', username: '', userId: '' })
                return
            }
            update(index, { ...value, mobile, spaceId, avatar: 'loading', username: '', userId: '' })
            const data = await getUserAvatar({ mobile })
            if(data.mobile !== mobile){
                return
            }
            if (data) {
                const { username = '', avatar = '', userId } = data

                update(index, { ...value, mobile, spaceId, avatar, username, userId })
                return
            }

            update(index, { ...value, mobile, spaceId, avatar: 'error', username: '', userId: '' })
        },
        [getValues, update, spaceId, getUserAvatar]
    )

    // 监测是否为真实用户
    const checkUser = (field: InviteForm) => {
        if (field.userId) {
            return true
        }
        if (field.avatar === 'error') {
            return '不存在的用户'
        }
        return '请输入正确的手机号'
    }

    const renderList = useCallback(() => {
        return (
            <>
                {fields.map((field, index) => (
                    <SC.InviteBox key={field.id}>
                        <SC.InviteItem id={field.id}>
                            <SC.InviteAvatar>
                                <SC.UserIconBox>{renderIcon(index)}</SC.UserIconBox>
                            </SC.InviteAvatar>
                            <SC.InviteForm>
                                <ErrorMessage name={`options.${index}.mobile`} isRelative={false} errors={errors} style={{ flex: 1 }}>
                                    <Controller
                                        name={`options.${index}.mobile`}
                                        control={control}
                                        rules={{
                                            validate: {
                                                checkUser: () => checkUser(field)
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SC.InvitePhone
                                                {...field}
                                                onFocus={() => {
                                                    clearErrors(`options.${index}.mobile`)
                                                }}
                                                size="lg"
                                                autoFocus
                                                onChange={ev => {
                                                    // field.onChange(ev)
                                                    handleMobileChange(ev.target.value, index)
                                                }}
                                            />
                                        )}
                                    />
                                </ErrorMessage>
                                <Controller
                                    name={`options.${index}.roleId`}
                                    control={control}
                                    render={({ field }) => (
                                        <SC.InviteRole
                                            value={field.value}
                                            styles={{
                                                root: {
                                                    width: 90
                                                }
                                            }}
                                            disabledPortal
                                            size="lg"
                                            onChange={ev => {
                                                field.onChange(ev)
                                            }}
                                            options={options}
                                        />
                                    )}
                                />
                            </SC.InviteForm>
                        </SC.InviteItem>
                        {fields.length - 1 === index && fields.length > 1 && (
                            <SC.InviteClose
                                onClick={() => {
                                    handleDelete(index)
                                }}
                            >
                                <SC.InviteIcon type="Close" />
                            </SC.InviteClose>
                        )}
                    </SC.InviteBox>
                ))}
            </>
        )
    }, [clearErrors, control, errors, fields, handleDelete, handleMobileChange, renderIcon, options])

    return (
        <SC.LinkForm
            onSubmit={(ev: React.FormEvent) => {
                ev.preventDefault()
                handleSubmit(inviteSubmit, onError)()
            }}
        >
            {renderList()}
            <SC.InviteFooter>
                <SC.InviterAdder onClick={handleAdd} icon={<SC.InviteIcon type="Add" />}>
                    添加
                </SC.InviterAdder>
                <SC.InviteBtn htmlType="submit">邀请</SC.InviteBtn>
            </SC.InviteFooter>
        </SC.LinkForm>
    )
}
