import { Button, Empty, Menu, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import type { AppUserDepartment } from '@lighthouse/shared'
import { TooltipList, TooltipText, useWidth } from '@lighthouse/shared'
import { mergeRefs, useUncontrolled } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { find, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { getFlatDepartments } from '../help'
import * as CM from '../styles'
import { DepartmentSelectItem } from './DepartmentItem'
import * as SC from './styles'

interface DepartmentMultipleSelectProps {
    options?: AppUserDepartment[]
    value?: string[]
    onChange?: (value: string[]) => void
}
const SCxDropDownHeader = styled.div`
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    background-color: var(--color-gray-50);
    border-radius: 8px 8px 0 0;
`

const SCxDropDownList = styled.div`
    margin: 8px 0;
    max-height: 500px;
    overflow-y: auto;
    ${tinyButtons}
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    line-height: 16px;
    padding: 4px 6px;
    height: 24px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    border-radius: 4px;
    background-color: var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

const Tag: React.FunctionComponent<{ data: AppUserDepartment; isLast: boolean }> = ({ data, isLast }) => {
    const { ref: widthRef } = useWidth<HTMLDivElement>(data.departmentId)
    return (
        <TooltipText
            title={data.departmentName}
            render={ref => (
                <SCxTag isOmit={isLast} ref={mergeRefs([ref, widthRef])} key={data.departmentId}>
                    {data.departmentName}
                </SCxTag>
            )}
        />
    )
}

export const DepartmentMultipleSelect: React.FunctionComponent<DepartmentMultipleSelectProps> = ({ options, value: data, onChange }) => {
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')
    const [searchOptions, setSearchOptions] = useState(options ?? [])
    const { ref, width } = useElementSize()
    const [value, handleChangeValue] = useUncontrolled<string[]>({ defaultValue: data })

    const allList = useMemo(() => getFlatDepartments(options ?? []), [options])

    const tagList = useMemo(() => {
        return reduce<string, AppUserDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.departmentId === departmentId, allList)
                return option ? [...preVal, option] : preVal
            },
            [],
            value
        )
    }, [allList, value])

    const dataTagList = useMemo(() => {
        return reduce<string, AppUserDepartment[]>(
            (preVal, departmentId) => {
                const option = find(item => item.departmentId === departmentId, allList)
                return option ? [...preVal, option] : preVal
            },
            [],
            data ?? []
        )
    }, [allList, data])

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const search = ev.target.value
            const searchList = search === '' ? options : allList.filter(item => item.departmentName?.includes(search))
            setSearch(ev.target.value)
            setSearchOptions(searchList ?? [])
        },
        [allList, options]
    )

    const handleMultiple = useCallback(
        (ids: string[]) => {
            const id = ids[0]
            const newIds = value.includes(id) ? value.filter(item => item !== id) : [...value, id]
            handleChangeValue(newIds)
        },
        [handleChangeValue, value]
    )

    const handleChange = useCallback(() => {
        onChange?.(value)
        setOpened(false)
    }, [onChange, value])

    const handleOpened = useCallback(
        (v: boolean) => {
            setOpened(v)
            if (v) {
                handleChangeValue(data ?? [])
            }
        },
        [data, handleChangeValue]
    )

    useEffect(() => {
        setSearchOptions(options ?? [])
    }, [options])

    return (
        <Menu opened={opened} position="bottom-start" width={400} onChange={handleOpened} withinPortal>
            <Menu.Target>
                <div style={{ width: '100%' }}>
                    <SC.Trigger ref={ref} style={{ backgroundColor: 'transparent' }}>
                        <SC.TagContainer>
                            <TooltipList
                                key={dataTagList.map(item => item.departmentId).join('-')}
                                data={dataTagList.map((item, index) => ({ label: item.departmentName, value: String(index) }))}
                                width={width - 22}
                                render={visibleNum => {
                                    const showTagList = dataTagList.slice(0, visibleNum)
                                    return (
                                        <SC.TagWrapper isOmit={visibleNum !== dataTagList.length} style={{ flexWrap: 'nowrap' }}>
                                            {showTagList.map((tag, index) => (
                                                <Tag isLast={showTagList.length - 1 === index} key={tag.departmentId} data={tag} />
                                            ))}
                                        </SC.TagWrapper>
                                    )
                                }}
                            />
                            {dataTagList.length === 0 && <Text color="var(--color-gray-400)">未加入部门</Text>}
                        </SC.TagContainer>
                        <SC.IconWrapper>
                            <IconFont type="ArrowDownSmall" size={16} color="var(--color-gray-500)" />
                        </SC.IconWrapper>
                    </SC.Trigger>
                </div>
            </Menu.Target>
            <Menu.Dropdown compact>
                <SC.DropDown>
                    <SCxDropDownHeader>
                        <SC.DropDownHeaderLeft>
                            <SC.TagWrapper>
                                {tagList.map(tag => (
                                    <SCxTag key={tag.departmentId}>{tag.departmentName}</SCxTag>
                                ))}
                                <Input
                                    value={search}
                                    styles={{
                                        wrapper: {
                                            borderStyle: 'none',
                                            backgroundColor: 'transparent!important',
                                            flex: 1,
                                            minWidth: 70
                                        }
                                    }}
                                    onChange={handleSearchChange}
                                    placeholder="搜索"
                                    size="xs"
                                />
                            </SC.TagWrapper>
                        </SC.DropDownHeaderLeft>
                        <Button size="sm" type="primary" style={{ flexShrink: 0 }} onClick={handleChange}>
                            确认
                        </Button>
                    </SCxDropDownHeader>
                    <SCxDropDownList>
                        {searchOptions.length === 0 && <Empty styles={{ root: { height: 100 } }} icon="SpaceTeam" description="没有部门" />}
                        {searchOptions.map(option => (
                            <DepartmentSelectItem
                                key={option.departmentId}
                                data={option}
                                level={0}
                                onChange={handleMultiple}
                                renderRightSection={childDepartment => {
                                    return value.includes(childDepartment.departmentId) ? (
                                        <CM.Icon type="Tick" color="var(--color-theme-7)" />
                                    ) : null
                                }}
                            />
                        ))}
                    </SCxDropDownList>
                </SC.DropDown>
            </Menu.Dropdown>
        </Menu>
    )
}
