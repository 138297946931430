import { Empty, Modal, Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { otherPlatformDocumentMap, spaceVersionEnum } from '@lighthouse/shared'
import { find, reduce, sortBy } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useSetState } from 'react-use'
import styled from 'styled-components'

import {
    AliyunCodeAccountModal,
    DingTalkRobotModal,
    IClickAccountModal,
    NetEaseFreeAccountModal,
    WeChatOfficialAccountModal,
    WeChatPayAccountModal,
    WeChatWebsiteAccountModal,
    WeComModal,
    WxRobotModal
} from '@/components/OtherPlatFormModal'
import { StripeAccountModal } from '@/components/OtherPlatFormModal/StripeAccount'
import { openSpaceGradeConfirm } from '@/components/SpaceGrade'
import * as srv from '@/services'
import type { AppAccountType } from '@/services/types'
import { useOtherPlatformList, useSpaceQuota } from '@/shared/reusable'

import { otherPlatformNameMap, SPACE_GRADE_TYPES } from './constant'
import { OtherPlatformsHeader } from './Header'
import { OtherPlatformsTable } from './Table'
import type { AppAccountItem, AppCountAction, State } from './types'

interface OtherPlatformsProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 24px;
    background-color: var(--color-gray-50);
`

const SCxEmptyImage = styled.img`
    width: 195px;
    height: 194px;
`

const emptyImage = getAssetUrl('empty', 'otherPlatformListEmpty.svg')

export const OtherPlatforms: React.FunctionComponent<OtherPlatformsProps> = props => {
    const { data: otherPlatformList, update } = useOtherPlatformList()
    const location = useLocation()
    const { data: quota } = useSpaceQuota()

    const [{ searchWord, activeId, opened, mode, sort, type, sortDep }, setState] = useSetState<State>({
        searchWord: '',
        activeId: '',
        opened: false,
        mode: 'add',
        sort: 'ASC',
        sortDep: 'CREATE_TIME'
    })

    useEffect(() => {
        if (location.state?.openModalType) {
            setState({ activeId: '', type: location.state?.openModalType, mode: 'add', opened: true })
        }
    }, [location.state?.openModalType, setState])

    const list: AppAccountItem[] = useMemo(
        () =>
            (otherPlatformList ?? []).map(item => {
                return {
                    title: item.name,
                    id: item.id,
                    type: item.type,
                    createdTime: item.createdTime
                }
            }),
        [otherPlatformList]
    )

    const filterList = useMemo(() => {
        const groupSortList = sortBy(
            item => {
                switch (sortDep) {
                    case 'APP_TYPE': {
                        return item.type
                    }
                    case 'TITLE': {
                        return item.title
                    }
                    default: {
                        return item.createdTime
                    }
                }
            },
            reduce<AppAccountItem, AppAccountItem[]>(
                (preVal, curVal) =>
                    (curVal.title ?? '').toLocaleLowerCase().trim().includes(searchWord.toLocaleLowerCase().trim())
                        ? [...preVal, curVal]
                        : preVal,
                [],
                list
            )
        )
        return sort === 'ASC' ? groupSortList.reverse() : groupSortList
    }, [list, searchWord, sort, sortDep])

    const modalEle = useMemo(() => {
        if (!type) {
            return
        }
        switch (type) {
            case 'DING_TALK_ROBOT': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <DingTalkRobotModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'WE_COM_MAIL': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <WeComModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'NET_EASE_126_MAIL': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <NetEaseFreeAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'WE_COM_ROBOT': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <WxRobotModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'ICLICK': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <IClickAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'ALIYUN_SMS': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <AliyunCodeAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'WECHAT_OFFICIAL_ACCOUNT': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <WeChatOfficialAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'WECHAT_WEBSITE': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <WeChatWebsiteAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'WECHAT_PAY': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <WeChatPayAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            case 'STRIPE': {
                const data = find(item => item.id === activeId, otherPlatformList ?? [])
                return (
                    <StripeAccountModal
                        id={data?.id}
                        mode={mode}
                        open={opened}
                        onClose={() => setState({ opened: false, activeId: '', type: undefined })}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [type, otherPlatformList, mode, opened, activeId, setState])

    const handleChangeEdit = useCallback(
        (id: string) => {
            const type = find(item => item.id === id, list)?.type
            setState({ activeId: id, type, mode: 'update', opened: true })
        },
        [list, setState]
    )

    const handleChangeAdd = useCallback(
        (type: string) => {
            const accountType = type as AppAccountType
            if (quota && quota?.currentVersionCode < spaceVersionEnum.ADVANCED && SPACE_GRADE_TYPES.includes(accountType)) {
                openSpaceGradeConfirm(otherPlatformDocumentMap[type]?.url ?? '', 'ADVANCED')
                return
            }
            setState({ activeId: '', type: accountType, mode: 'add', opened: true })
        },
        [quota, setState]
    )

    const handleChangeDelete = useCallback(
        async (id: string) => {
            const account = find(item => item.id === id, list)
            const { type, title } = account ?? {}
            if (!type) {
                return
            }
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除「${otherPlatformNameMap[type]}-${title}」。`,
                okStatus: 'error'
            })

            if (isConfirm) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    update()
                    Toast.success(`已删除「${otherPlatformNameMap[type]}-${title}」`)
                }
            }
        },
        [list, update]
    )

    const handleChangeAccount = useCallback(
        (id: string, action: AppCountAction) => {
            switch (action) {
                case 'EDIT': {
                    handleChangeEdit(id)
                    break
                }
                case 'DELETE': {
                    handleChangeDelete(id)
                    break
                }
                default: {
                    break
                }
            }
        },
        [handleChangeDelete, handleChangeEdit]
    )

    return (
        <SCxContainer>
            <OtherPlatformsHeader searchWord={searchWord} onChange={val => setState({ searchWord: val })} onAddCount={handleChangeAdd} />
            {list.length > 0 ? (
                <OtherPlatformsTable
                    list={filterList}
                    onChangeAccount={handleChangeAccount}
                    onChangeSort={val => setState({ sortDep: val, sort: sort === 'ASC' ? 'DESC' : 'ASC' })}
                />
            ) : (
                <Empty icon={<SCxEmptyImage src={emptyImage} />} style={{ height: '100%' }} description="暂未使用第三方应用" />
            )}
            {modalEle}
        </SCxContainer>
    )
}
