import { ErrorFallback } from '@lighthouse/block'
import { useAtomValue } from 'jotai'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { workSpaceAtom } from '@/atoms/workSpace/state'
import { Layout } from '@/containers/Layout'

import { Profile } from './Profile'
import { SpaceConfigure } from './SpaceConfigure'
import { SpaceNav } from './SpaceNav'
import * as SC from './styles'

interface SpaceDetailProps {
    children?: React.ReactNode
}

const SpaceDetail: React.FC<SpaceDetailProps> = ({ children }) => {
    const currentWorkSpace = useAtomValue(workSpaceAtom)

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Layout aside={<SpaceNav workSpace={currentWorkSpace} header={<SpaceConfigure />} footer={<Profile />} />}>
                <SC.WorkSpaceContainer>{children}</SC.WorkSpaceContainer>
            </Layout>
        </ErrorBoundary>
    )
}

export default SpaceDetail
