import { Flex } from '@byecode/ui/components/Flex'
import React from 'react'

import { CoreIndicators } from '../AnalyzeTraffic/CoreIndicators'
import type { ConditionType } from '../constants'
import { CONDITION_OPTIONS, CONDITION_TYPE } from '../constants'
import { PageVisits } from './PageVisits'

const DEFAULT_CHECKED = new Set<ConditionType>([
    CONDITION_TYPE.pv,
    CONDITION_TYPE.uv,
    CONDITION_TYPE.bounceRate,
    CONDITION_TYPE.avgDepth,
    CONDITION_TYPE.avgDuration
])

export const AnalyzePage = () => {
    return (
        <Flex direction="column" gap="20px">
            <CoreIndicators defaultChecked={CONDITION_OPTIONS.filter(item => DEFAULT_CHECKED.has(item.value)).map(item => item.value)} />
            <PageVisits />
        </Flex>
    )
}
