import type { ChartViewOptions } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { CompositeDesign } from './DesignConfiguratorRender/CompositeDesign'

export const Design: React.FC = () => {
    const { watch } = useFormContext<ChartViewOptions>()
    const chartType = watch('chartType')
    return useMemo(() => {
        switch (chartType) {
            case 'composite':
            case 'pie':
            case 'bar':
            case 'funnel':
            case 'striation':
            case 'line': {
                return <CompositeDesign chartType={chartType} />
            }
            default: {
                return null
            }
        }
    }, [chartType])
}
