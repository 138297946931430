import React from 'react'
import { useParams } from 'react-router'

import { DataAggregator } from '@/components/DataAggregator'

export const DataSourceAggregator = () => {
    const { dataSourceId = '' } = useParams()

    return <DataAggregator dataSourceId={dataSourceId} />
}
