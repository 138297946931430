import { Button } from '@byecode/ui/components/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { ModalProps } from '@byecode/ui/components/Modal'
import { Modal } from '@byecode/ui/components/Modal'
import type { WechatPayOrderInfoProtocol, WechatPayStatus } from '@lighthouse/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import type { Step } from '../types'
import { CustomerServices } from './CustomerServices'
import { WeChatOrderFail } from './OrderFail'
import { WeChatOrderSuccess } from './OrderSuccess'
import { WeChatPay } from './WeChatPay'

interface WeChatPayModalProps extends ModalProps {
    getPayInfo?: () => Promise<WechatPayOrderInfoProtocol>
    onPaySuccess?: () => void
    onPayFailed?: (status: WechatPayStatus) => void
}

export const WeChatPayModal: React.FunctionComponent<WeChatPayModalProps> = ({ getPayInfo, onPaySuccess, onPayFailed, open, ...props }) => {
    const [step, setStep] = useImmer<Step[]>([{ value: 'order' }])
    const [errorStatus, setErrorStatUs] = useState<WechatPayStatus>()

    const lastStep = useMemo(() => step[step.length - 1], [step])

    const ele = useMemo(() => {
        switch (lastStep?.value) {
            case 'orderFail': {
                return (
                    <WeChatOrderFail
                        errorStatus={errorStatus}
                        onNextStep={v =>
                            setStep(d => {
                                d.push(v)
                            })
                        }
                    />
                )
            }

            case 'orderSuccess': {
                return <WeChatOrderSuccess onClose={props.onClose} />
            }
            case 'customerService': {
                return <CustomerServices />
            }

            default: {
                return (
                    <WeChatPay
                        getPayInfo={getPayInfo}
                        onPaySuccess={() => {
                            onPaySuccess?.()
                            setStep(d => {
                                d.push({ label: '支付成功', value: 'orderSuccess' })
                            })
                        }}
                        onPayFailed={code => {
                            onPayFailed?.(code)
                            setErrorStatUs(code)
                        }}
                    />
                )
            }
        }
    }, [errorStatus, getPayInfo, lastStep?.value, onPayFailed, onPaySuccess, props.onClose, setStep])

    useEffect(() => {
        if (!open) {
            setStep([{ value: 'order' }])
        }
    }, [open, setStep])

    return (
        <Modal
            styles={{
                desktop: {
                    modal: {
                        maxWidth: '100%',
                        minWidth: 460,
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        bottom: 8
                    },
                    body: {
                        padding: '0px 80px!important'
                    }
                },
                mobile: {
                    body: {
                        padding: '0px !important'
                    }
                }
            }}
            leftSlot={
                step.length > 1 && (
                    <Button
                        type="text"
                        onClick={() =>
                            setStep(draft => {
                                draft.splice(-1)
                            })
                        }
                    >
                        <IconFont type="ArrowLeft" size={16} />
                    </Button>
                )
            }
            transition={{ desktop: { from: { x: '100%' }, to: { x: '0%' } } }}
            width={490}
            zIndex={201}
            open={open}
            {...props}
            title={lastStep?.value === 'order' ? '支付页面' : '返回'}
        >
            {ele}
        </Modal>
    )
}
