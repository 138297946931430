import { Button } from '@byecode/ui/components/Button'
import { Checkbox } from '@byecode/ui/components/Checkbox'
import { LoginType } from '@lighthouse/core'
import { ErrorMessage, getBrandInfo, mobileRegex, useAtomAction } from '@lighthouse/shared'
import { Anchor, Divider } from '@mantine/core'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { loginAtom } from '@/atoms/auth/action'
import { encrypt } from '@/utils'

import * as SC from '../styles'
import type { LoginForm, LoginProps } from './types'

export const Login: React.FC<LoginProps> = ({ onChangeMode }) => {
    const navigate = useNavigate()
    const { run: login, loading } = useAtomAction(loginAtom)
    const submitEl = useRef<HTMLInputElement>(null)

    const [searchParams] = useSearchParams()

    const redirect = searchParams.get('redirect')

    const methods = useForm<LoginForm>({ mode: 'onBlur', shouldFocusError: false })
    const {
        formState: { errors },
        control,
        register,
        clearErrors,
        handleSubmit
    } = methods

    // 跳转验证码登录
    const handleChangeRegister = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onChangeMode('register')
    }

    // 跳转微信登录
    const handleChangeWechat = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onChangeMode('wechat')
    }

    // 跳转忘记密码
    const handleChangeForget = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onChangeMode('verification')
    }

    // 提交行为
    const loginHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        return submitEl.current?.click()
    }

    // 表单提交
    const loginSubmit = useCallback(
        async (data: LoginForm) => {
            const enctyptPassword = encrypt(data.password)
            const isLogin = await login({
                authType: LoginType.ACCOUNT_PASSWORD,
                mobile: data.phone,
                password: enctyptPassword || ''
            })
            if (isLogin) {
                 navigate(redirect || '/', { replace: true })
            }
        },
        [login, navigate, redirect]
    )

    const clearFormError = useCallback(
        (name: 'phone' | 'password') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(loginSubmit)()
        },
        [handleSubmit, loginSubmit]
    )

    const clause = useMemo(
        () => (
            <SC.AgreeContent>
                点击同意
                <Anchor size="sm" href={getBrandInfo().account.privacyAgreement} target="_blank">
                    《隐私协议》
                </Anchor>
                和
                <Anchor size="sm" href={getBrandInfo().account.termsOfService} target="_blank">
                    《服务条款》
                </Anchor>
            </SC.AgreeContent>
        ),
        []
    )

    return (
        <SC.FormContainer onSubmit={handleFormSubmit}>
            <SC.InputWrapper>
                <ErrorMessage name="phone" errors={errors}>
                    <SC.AccountInput
                        placeholder="手机号"
                        onFocus={() => {
                            clearFormError('phone')
                        }}
                        // size="xl"
                        {...register('phone', {
                            required: '请输入手机号',
                            pattern: {
                                value: mobileRegex,
                                message: '请输入正确的手机号'
                            }
                        })}
                    />
                </ErrorMessage>
                <ErrorMessage name="password" errors={errors}>
                    <SC.AccountInput
                        placeholder="密码"
                        type="password"
                        onFocus={() => {
                            clearFormError('password')
                        }}
                        {...register('password', { required: '密码不能为空' })}
                    />
                </ErrorMessage>
                <SC.HideSubmit ref={submitEl} type="submit" />
            </SC.InputWrapper>
            <SC.ButtonContainer>
                <SC.AccountButton block type="primary" loading={loading} onClick={loginHandle}>
                    登录
                </SC.AccountButton>
            </SC.ButtonContainer>
            {/* <SC.Agree>
                <Controller
                    name="remember-me"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            checked={!!field.value}
                            color="var(--color-main)"
                            label="记住我"
                            size="xs"
                            onChange={event => field.onChange(event.currentTarget.checked ? 'on' : undefined)}
                        />
                    )}
                />
            </SC.Agree> */}
            <SC.Agree>
                <ErrorMessage name="agree" errors={errors}>
                    <Controller
                        name="agree"
                        control={control}
                        rules={{ required: '请先同意协议与条款' }}
                        render={({ field }) => (
                            <Checkbox
                                checked={field.value}
                                color="var(--color-main)"
                                label={clause}
                                size="xs"
                                onChange={event => field.onChange(event.currentTarget.checked)}
                            />
                        )}
                    />
                </ErrorMessage>
                <Button type="text" style={{ color: 'var(--color-main)' }} onClick={handleChangeForget}>
                    忘记密码?
                </Button>
            </SC.Agree>

            <Divider
                style={{ margin: '32px 0  16px 0', color: 'var(--color-gray-400)' }}
                my="xs"
                label="其他登录方式"
                labelPosition="center"
            />
            <SC.FooterBetween>
                <SC.AccountButton
                    icon={<SC.Icon type="WeChatLogo" size={20} color="var(--color-green-500)" />}
                    block
                    onClick={handleChangeWechat}
                >
                    微信登录
                </SC.AccountButton>
                <SC.AccountButton
                    icon={<SC.Icon type="DeviceMobileFilled" size={20} color="var(--color-main)" />}
                    block
                    onClick={handleChangeRegister}
                >
                    验证码登录/注册
                </SC.AccountButton>
            </SC.FooterBetween>
        </SC.FormContainer>
    )
}
