import isEqual from 'fast-deep-equal'
import { useCallback, useRef, useState } from 'react'

//
export const useLimitRetryAction = <T extends unknown[]>(fn: (...arg: T) => void | Promise<void>, limitCount = 3) => {
    // const
    const [requestCount, setRequestCount] = useState(1)
    const payloadRef = useRef<T | undefined>()
    const isLimit = requestCount >= limitCount
    const retry = useCallback(
        async (...arg: T) => {
            if (!isEqual(arg, payloadRef.current)) {
                payloadRef.current = arg
                setRequestCount(1)
                await fn(...arg)
                return
            }
            if (isLimit) {
                return
            }
            setRequestCount(requestCount + 1)
            await fn(...arg)
        },
        [fn, isLimit, requestCount]
    )

    return { retry, requestCount, isLimit }
}
