import { IconFont, pointer } from '@byecode/ui'
import type {
    AggregatorDataInputConfig,
    AggregatorFieldSettingConfig,
    AggregatorFilterConfig,
    AggregatorGroupStatisticsConfig,
    AggregatorJoinConfig,
    AggregatorNode,
    AggregatorNodeConfig
} from '@lighthouse/shared'
import { type AggregateResultField, aggregateNodeFieldList, AggregatorNodeType, geyAggregatorNodeIcon } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import isEqual from 'fast-deep-equal'
import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import type { Edge } from 'reactflow'
import { useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import { NodeTypeColorMap } from '../AggregatorEditor/constants'
import {
    dataInputResultGenerator,
    fieldSettingConfigGenerator,
    fieldSettingResultGenerator,
    getAggregateFlowByNodes,
    groupStatisticsResultGenerator,
    otherNodeResultGenerator,
    peekPrevNodes
} from '../utils'
import { DataInput } from './DataInput'
import { FieldSetting } from './FieldSetting'
import { Filter } from './Filter'
import { GroupStatistics } from './GroupStatistics'
import { Join } from './Join'
import { NameInput } from './NameInput'

const SCxHeader = styled.div`
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
`

const SCxIconFontWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--color-gray-100);
`

const SCxIcon = styled(IconFont)`
    ${pointer}
`

const SCxText = styled.div`
    color: var(--color-gray-900);
    font-weight: 500;
    font-size: var(--font-size-base);
`

export const SCxContent = styled.div`
    padding: 12px 16px;
`
export interface AggregatorConfigureProps {
    dsId: string
    node: AggregatorNode
    onChange: (nodes: AggregatorNode[], edges: Edge[], newNode: AggregatorNode) => void
}

export const AggregatorConfigure: React.FC<AggregatorConfigureProps> = ({ dsId, node, onChange }) => {
    const { setNodes, getNodes, getEdges } = useReactFlow()
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const { prevFieldList, prevNodes } = useMemo(() => {
        const prevNodes = peekPrevNodes(node, getNodes() as AggregatorNode[], getEdges()).nodes
        return {
            prevFieldList: otherNodeResultGenerator(prevNodes),
            prevNodes
        }
    }, [getEdges, getNodes, node])

    const initFormData = useMemo(() => {
        if (node.type === AggregatorNodeType.FILTER) {
            const filter = node.data.config
            if (!filter?.expression || !filter.expression?.where) {
                return {
                    ...filter,
                    expression: {
                        where: 'AND',
                        conditions: filter?.expression?.conditions || []
                    }
                }
            }
            return filter
        }
        return node.data.config
    }, [node.data.config, node.type])

    const methods = useForm<AggregatorNode['data']['config']>({
        mode: 'onChange',
        defaultValues: initFormData as AggregatorNodeConfig
    })

    const { control, setValue } = methods
    // const onDebounceUpdatePageModules = useMemo(() => debounce(500, onUpdatePageModuleNodes), [onUpdatePageModuleNodes])
    // const fieldList: AggregateResultField[] = []

    const formData = useWatch({ control })

    const debouncedFormData = useDebounce(formData, 500)

    useUpdateEffect(() => {
        if (debouncedFormData && !isEqual(debouncedFormData, node.data.config)) {
            const nodes = getNodes() as AggregatorNode[]
            const edges = getEdges()
            switch (node.type) {
                case AggregatorNodeType.DATA_INPUT: {
                    const config = debouncedFormData as AggregatorDataInputConfig
                    const newFieldList: AggregateResultField[] = dataInputResultGenerator(node.id, config, dataSourceList)

                    const newNode = {
                        ...node,
                        data: {
                            config,
                            result: { fieldList: newFieldList }
                        }
                    }
                    const newNodes = getAggregateFlowByNodes(newNode, nodes, edges)
                    setNodes(newNodes)
                    onChange(newNodes, edges, newNode)
                    aggregateNodeFieldList.emit(node.id, newFieldList)
                    return
                }
                case AggregatorNodeType.JOIN: {
                    const config = debouncedFormData as AggregatorJoinConfig
                    // const newFieldList: AggregateResultField[] = otherNodeResultGenerator(prevNodes)
                    const newNode = {
                        ...node,
                        data: {
                            config,
                            result: { fieldList: prevFieldList }
                        }
                    }
                    aggregateNodeFieldList.emit(node.id, prevFieldList)
                    const newNodes = getAggregateFlowByNodes(newNode, nodes, edges)
                    setNodes(newNodes)
                    onChange(newNodes, edges, newNode)
                    return
                }
                case AggregatorNodeType.FIELD_SETTING: {
                    const config = debouncedFormData as AggregatorFieldSettingConfig
                    const configFieldList = fieldSettingConfigGenerator(config, prevFieldList)
                    const newConfig = {
                        ...config,
                        fieldList: configFieldList
                    }
                    const fieldList: AggregateResultField[] = fieldSettingResultGenerator(newConfig)
                    const newNode = {
                        ...node,
                        data: {
                            config: newConfig,
                            result: { fieldList }
                        }
                    }
                    aggregateNodeFieldList.emit(node.id, fieldList)
                    const newNodes = getAggregateFlowByNodes(newNode, nodes, edges)
                    setNodes(newNodes)
                    onChange(newNodes, edges, newNode)
                    return
                }
                case AggregatorNodeType.FILTER: {
                    const config = debouncedFormData as AggregatorFilterConfig
                    // const newFieldList: AggregateResultField[] = otherNodeResultGenerator(prevNodes)
                    const newNode = {
                        ...node,
                        data: {
                            config,
                            result: { fieldList: prevFieldList }
                        }
                    }
                    aggregateNodeFieldList.emit(node.id, prevFieldList)
                    const newNodes = getAggregateFlowByNodes(newNode, nodes, edges)
                    setNodes(newNodes)
                    onChange(newNodes, edges, newNode)
                    return
                }
                case AggregatorNodeType.GROUP_STATISTICS: {
                    const config = debouncedFormData as AggregatorGroupStatisticsConfig
                    const newFieldList: AggregateResultField[] = groupStatisticsResultGenerator(config, prevFieldList)
                    const newNode = {
                        ...node,
                        data: {
                            config,
                            result: { fieldList: newFieldList }
                        }
                    }
                    aggregateNodeFieldList.emit(node.id, newFieldList)
                    const newNodes = getAggregateFlowByNodes(newNode, nodes, edges)
                    setNodes(newNodes)
                    onChange(newNodes, edges, newNode)
                    return
                }
                default: {
                    return undefined
                }
            }
        }
    }, [debouncedFormData])

    const content = useMemo(() => {
        switch (node.type) {
            case AggregatorNodeType.DATA_INPUT: {
                return <DataInput dsId={dsId} nodeId={node.id} />
            }
            case AggregatorNodeType.JOIN: {
                return <Join dsId={dsId} nodeId={node.id} nodeList={prevNodes} dataSourceList={dataSourceList} />
            }
            case AggregatorNodeType.FIELD_SETTING: {
                return <FieldSetting dsId={dsId} nodeId={node.id} fieldList={prevFieldList} />
            }
            case AggregatorNodeType.FILTER: {
                return <Filter dsId={dsId} nodeId={node.id} fieldList={prevFieldList} />
            }
            case AggregatorNodeType.GROUP_STATISTICS: {
                return <GroupStatistics dsId={dsId} nodeId={node.id} fieldList={prevFieldList} />
            }
            default: {
                return null
            }
        }
    }, [node.type, node.id, dsId, prevNodes, dataSourceList, prevFieldList])

    return (
        <FormProvider {...methods}>
            <SCxHeader>
                <SCxIconFontWrapper>
                    {node.type && <IconFont type={geyAggregatorNodeIcon(node)} fill={NodeTypeColorMap[node.type]} size={24} />}
                </SCxIconFontWrapper>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <NameInput value={field.value} onChange={field.onChange} />}
                />
            </SCxHeader>
            <SCxContent>{content}</SCxContent>
        </FormProvider>
    )
}
