import type { SelectProps } from '@byecode/ui'
import { backgroundTransitionOnClick, IconFont, pointer, Select, Tooltip } from '@byecode/ui'
import type { FieldType, TypeInstanceMap } from '@lighthouse/core'
import { FieldNameMap, FieldTypeTag, FindUseType, getIsFindUse, useFindUseObjectContext } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export interface FieldOption {
    icon?: string
    label: string
    value: string
    describe?: React.ReactNode
    iconColor?: string
    type?: FieldType
    innerType?: TypeInstanceMap
}
interface FieldSelectProps extends Omit<SelectProps, 'options' | 'full'> {
    dsId?: string
    color?: string
    fontWeight?: string
    width?: string
    options?: FieldOption[]
    disableList?: FieldType[]
    disableTitle?: string
    disableDesc?: string
    emptyComponent?: React.ReactNode
}

const SCxIcon = styled(IconFont)<{ size?: number; bgcolor?: string; isAction?: boolean }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: ${({ bgcolor }) => bgcolor ?? 'transparent'};
    ${pointer}
    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
`

const SCxTooltipLabel = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    height: auto;
    width: 220px;
    line-height: 16px;
    font-size: var(--font-size-sm);

    li {
        padding-left: 6px;
    }
`

const SCxExtra = styled.div`
    padding: 8px 16px;
    min-height: 32px;
    display: flex;
    align-items: center;
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
`

export const FieldSelect = React.forwardRef<HTMLInputElement, FieldSelectProps>(
    (
        {
            value,
            dsId,
            onChange,
            options = [],
            color = 'var(--color-black)',
            fontWeight,
            width = '180px',
            disableList,
            disableTitle,
            disableDesc = '已隐藏不支持的字段',
            ...rest
        },
        ref
    ) => {
        const findUseObject = useFindUseObjectContext()

        const isHighLight = useMemo(
            () =>
                getIsFindUse({
                    findUseObject,
                    type: FindUseType.FIELD,
                    dsId,
                    fieldId: value
                }),
            [dsId, findUseObject, value]
        )

        const prefixIcon = useMemo(() => {
            if (!value) {
                return
            }
            const item = find(item => item.value === value, options ?? [])
            return item?.icon && <SCxIcon type={item.icon} color={item?.iconColor} />
        }, [options, value])

        const iconOptions = useMemo(
            () =>
                options.map(item => ({
                    ...item,
                    extra: <FieldTypeTag type={item.type} innerType={item.innerType} />,
                    icon: item.icon && <SCxIcon type={item.icon} color={item?.iconColor} />
                })),
            [options]
        )
        // extra={<FieldTypeTag type={item.} />}
        return (
            <Select
                styles={{
                    root: {
                        width
                    }
                }}
                highlighting={isHighLight}
                searchable
                prefix={prefixIcon}
                options={iconOptions}
                ref={ref}
                placeholder="请选择"
                value={value}
                onChange={onChange}
                dropdownProps={{
                    extra:
                        (disableList ?? []).length > 0 ? (
                            <SCxExtra>
                                {disableDesc}
                                <Tooltip
                                    title={
                                        <SCxTooltipLabel>
                                            {disableTitle}
                                            {disableList?.map(title => (
                                                <li key={title}>{FieldNameMap[title]}</li>
                                            ))}
                                        </SCxTooltipLabel>
                                    }
                                >
                                    <SCxIcon type="Question" style={{ marginLeft: 4 }} />
                                </Tooltip>
                            </SCxExtra>
                        ) : null
                }}
                {...rest}
            />
        )
    }
)
