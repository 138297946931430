import type { WorkSpaceAbstract } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { guideStepList } from '@/components/SpaceGuide/constant'
import { useApplicationList } from '@/hooks/useApplication'

import * as SC from './styles'
// import { useAsyncAction, useBoundStore } from '@/stores'

type SpaceMenuItem = {
    icon: string
    name: string
    link: string
    number?: number
    id?: string
}

interface SpaceNavProps {
    workSpace: WorkSpaceAbstract
    header: React.ReactNode
    footer?: React.ReactNode
}

export const SpaceNav: React.FC<SpaceNavProps> = ({ workSpace, header, footer }) => {
    const applications = useApplicationList()

    const menuList: SpaceMenuItem[] = useMemo(() => {
        return [
            {
                icon: 'Gallery',
                name: '所有应用',
                link: 'applications',
                number: applications.length
            },
            {
                icon: 'SpaceTeam',
                name: '成员管理',
                link: 'member',
                number: workSpace?.members || 0,
                id: guideStepList.spacePerson.id
            },
            {
                icon: 'Wave',
                name: '资源配额',
                link: 'quota',
                id: guideStepList.spaceQuota.id
            },
            {
                icon: 'Setting',
                link: 'setting',
                name: '空间设置',
                id: guideStepList.spaceSetting.id
            }
        ]
    }, [applications.length, workSpace?.members])

    return (
        <SC.NavContainer>
            {header}
            <SC.Content>
                <SC.MenuList>
                    {menuList.map(item => (
                        <SC.MenuItem key={item.name} to={item.link} id={item.id}>
                            <SC.MenuItemContent>
                                <SC.MenuItemIcon size={16} type={item.icon} />
                                <SC.MenuItemText>{item.name}</SC.MenuItemText>
                            </SC.MenuItemContent>
                            {(item.number || item.number === 0) && <SC.MenuItemExtra>{item.number}</SC.MenuItemExtra>}
                        </SC.MenuItem>
                    ))}
                    {/* <SC.MenuItemExtra></SC.MenuItemExtra> */}
                    {/* <TemplateButton /> */}
                </SC.MenuList>
            </SC.Content>
            {footer}
            {/* <TemplateModal
                opened={openTemplate}
                onClose={() => {
                    setState({ openTemplate: false })
                }}
                onCreateBlankApp={handlers.handleSelectTemplate}
                onChangeFileStatus={handleChangeFileStatus}
            />
            <ImportLoading open={openLoading} hidePercent fileStatus={fileStatus} onClose={() => setState({ openLoading: false })} />

            <AppCreator
                opened={createWay === 'common' && open}
                centered
                padding={0}
                size={480}
                appType={createAppType}
                onClose={() => {
                    setState({ open: false })
                }}
                onConfirm={handleCreateApp}
            />

            <AppAICreator
                opened={createWay === 'ai' && open}
                onClose={() => {
                    setState({ open: false })
                }}
                onConfirm={value => {
                    handleCreateAppByAI(value)
                }}
            /> */}
        </SC.NavContainer>
    )
}
