import { Divider, Empty, Group, IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { TypeInstanceMap } from '@lighthouse/core'
import { type FlowNode, FieldTypeTag, LinkDocument } from '@lighthouse/shared'
import { ScrollArea } from '@mantine/core'
import cls from 'classnames'
import { lightFormat } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

interface RunLogDetailDrawerProps {
    node: FlowNode
}

const SCxRunLogDetailDrawerWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(--color-white);
`

const SCxDrawerWrapper = styled(ScrollArea)`
    width: 360px;
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid var(--color-gray-200);

    & .mantine-ScrollArea-viewport > div {
        display: block !important;
    }
`

const SCxHeader = styled.div`
    position: sticky;
    z-index: 99;
    top: 0;
    height: 54px;
    width: 100%;
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
`

const SCxTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: var(--color-black);
`

const SCxIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: var(--color-green-500);
`

const SCxRunLogNotification = styled.div`
    position: relative;
    margin: 12px;
    padding: 10px 12px;
    font-size: var(--font-size-sm);
    border-radius: 4px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &.flow-run-log-success {
        border-width: 2px;
        background-color: var(--color-green-50);
        border-color: var(--color-green-500);
        color: var(--color-green-500);

        ${SCxIconWrapper} {
            background-color: var(--color-green-500);
        }

        &::before {
            background-color: var(--color-green-500);
        }
    }
    &.flow-run-log-failed {
        border-width: 2px;
        background-color: var(--color-red-50);
        border-color: var(--color-red-500);
        color: var(--color-red-500);

        ${SCxIconWrapper} {
            background-color: var(--color-red-500);
        }

        &::before {
            background-color: var(--color-red-500);
        }
    }
    &.flow-run-log-running {
        border-width: 2px;
        background-color: var(--color-blue-50);
        border-color: var(--color-blue-500);
        color: var(--color-blue-500);

        ${SCxIconWrapper} {
            background-color: var(--color-blue-500);
        }

        &::before {
            background-color: var(--color-blue-500);
        }
    }
    &.flow-run-log-start {
        border-width: 2px;
        background-color: var(--color-gray-50);
        border-color: var(--color-gray-500);
        color: var(--color-gray-500);
        opacity: 0.5;

        ${SCxIconWrapper} {
            background-color: var(--color-gray-500);
        }

        &::before {
            background-color: var(--color-gray-500);
        }
    }
`

const SCxRunLogStatus = styled.div`
    display: flex;
    align-items: center;
`

const SCxRunLogDetail = styled.div`
    margin-top: 8px;
`

const SCxInfoCell = styled.div`
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxInfoLabel = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const SCxInfoValue = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

const SCxCardList = styled.div`
    padding-bottom: 62px;
`

const SCxCard = styled.div`
    margin: 0 0 12px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
`

const SCxItem = styled.div`
    padding: 8px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`

const SCxCardItemLabel = styled.div`
    display: flex;
    gap: 4px;
    flex-shrink: 0;
    padding: 4px 8px;
    margin-right: 8px;
    height: 28px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    background-color: var(--color-gray-100);
    border-radius: 4px;
`

const SCxCardItemValue = styled.div`
    line-height: 1.5;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

const SCxFieldTypeTag = styled(FieldTypeTag)`
    color: var(--color-gray-400) !important;
`

const statusLabelMap = {
    NOT_START: '未开始',
    RUNNING: '运行中',
    SUCCESS: '已完成',
    FAILED: '已失败'
}

const statusIconMap = {
    NOT_START: 'Clock',
    SUCCESS: 'Tick',
    FAILED: 'Close',
    RUNNING: 'Repeat'
}

export const RunLogDetailDrawer: React.FC<RunLogDetailDrawerProps> = ({ node }) => {
    const { data } = node

    const nodeInvokeLog = data?.status

    if (!nodeInvokeLog) {
        return null
    }

    const { status, executeTime, log, output } = nodeInvokeLog
    const className = cls({
        'flow-run-log-success': status === 'SUCCESS',
        'flow-run-log-failed': status === 'FAILED',
        'flow-run-log-running': status === 'RUNNING'
        // 'flow-node-not-start': status.status === 'NOT_START'
    })

    return (
        <SCxRunLogDetailDrawerWrapper>
            <SCxDrawerWrapper>
                <SCxHeader>
                    <SCxTitle>{data.name}</SCxTitle>
                    <LinkDocument type={data.nodeType} />
                </SCxHeader>
                <Divider />
                <SCxRunLogNotification className={className}>
                    <SCxRunLogStatus>
                        <SCxIconWrapper>
                            <IconFont type={statusIconMap[status]} size={12} color="var(--color-white)" />
                        </SCxIconWrapper>
                        {statusLabelMap[status]}
                    </SCxRunLogStatus>
                    {log && <SCxRunLogDetail>{log}</SCxRunLogDetail>}
                </SCxRunLogNotification>
                <Divider />
                {executeTime && (
                    <Group label="节点详情">
                        <SCxInfoCell>
                            <SCxInfoLabel>执行时间</SCxInfoLabel>
                            <SCxInfoValue>{lightFormat(executeTime, 'yyyy/MM/dd HH:mm:ss')}</SCxInfoValue>
                        </SCxInfoCell>
                    </Group>
                )}
                {!!output && (
                    <>
                        <Divider />
                        <Group label="输出">
                            <SCxCardList>
                                {output.length ? (
                                    output.map((o, i) => {
                                        return (
                                            <SCxCard key={i}>
                                                {o.map(({ label, value, type }) => {
                                                    return (
                                                        <SCxItem key={label}>
                                                            <SCxCardItemLabel>
                                                                {label} <SCxFieldTypeTag innerType={type as TypeInstanceMap} />
                                                            </SCxCardItemLabel>
                                                            <SCxCardItemValue>{value.toString()}</SCxCardItemValue>
                                                        </SCxItem>
                                                    )
                                                })}
                                            </SCxCard>
                                        )
                                    })
                                ) : (
                                    <Empty
                                        icon={<img alt="no_output" src={getAssetUrl('empty', 'no_output.svg')} />}
                                        description="无输出"
                                    />
                                )}
                            </SCxCardList>
                        </Group>
                    </>
                )}
            </SCxDrawerWrapper>
        </SCxRunLogDetailDrawerWrapper>
    )
}
