import { Radio } from '@byecode/ui'
import React, { useCallback } from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    const [value, setValue] = React.useState('xs')
    const handleChange = useCallback((val: string) => {
        setValue(val)
    }, [])

    return (
        <Unit title="基础">
            <Radio size="xs" checked={value === 'xs'} color="red" onChange={() => handleChange('xs')} />
            <Radio size="sm" checked={value === 'sm'} onChange={() => handleChange('sm')} />
            <Radio checked={value === 'md'} onChange={() => handleChange('md')} />
            <Radio size="lg" checked={value === 'lg'} onChange={() => handleChange('lg')} />
            <Radio size="lg" label="这是一个测试label" disabled checked onChange={() => handleChange('lg')} />
        </Unit>
    )
}
