import type { PopoverProps } from '@byecode/ui'
import { IconFont, Popover } from '@byecode/ui'
import type { Placement } from '@floating-ui/react'
import React from 'react'
import styled, { css } from 'styled-components'

const SCxList = styled.div``

const SCxListItem = styled.div<{ disable?: boolean }>`
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    ${({ disable }) =>
        disable
            ? css`
                  opacity: 0.5;
                  cursor: not-allowed;
              `
            : css`
                  &:hover {
                      background-color: var(--color-gray-100);
                  }
              `}
`

const SCxListItemText = styled.div`
    color: var(--color-gray-900);
    font-size: 14px;
    margin-left: 8px;
`

export type MoreSettingOption = {
    label: string
    icon: string
    hidden?: boolean
    disable?: boolean
    onItemClick?: () => void
}

interface MoreSettingProps {
    children: React.ReactElement
    width?: PopoverProps['width']
    options: MoreSettingOption[]
    position?: Placement
    withinPortal?: boolean
}

export const MoreSetting: React.FC<MoreSettingProps> = ({ width = 'target', options, position = 'bottom-end', withinPortal, children }) => {
    const [open, setOpen] = React.useState(false)
    //
    return (
        <Popover width={width} opened={open} onChange={setOpen} position={position} withinPortal={withinPortal}>
            <Popover.Target>{React.cloneElement(children, { open })}</Popover.Target>
            <Popover.Dropdown>
                <SCxList>
                    {options.map(option => {
                        if (option.hidden) {
                            return null
                        }
                        return (
                            <SCxListItem
                                key={option.label}
                                disable={option?.disable}
                                onClick={() => {
                                    if (option?.disable) {
                                        return
                                    }
                                    option.onItemClick?.()
                                    setOpen(false)
                                }}
                            >
                                <IconFont type={option.icon} size={16} fill="var(--color-gray-400)" />
                                <SCxListItemText>{option.label}</SCxListItemText>
                            </SCxListItem>
                        )
                    })}
                </SCxList>
            </Popover.Dropdown>
        </Popover>
    )
}
