import { useAtomData } from '@lighthouse/shared'
import { useAtom } from 'jotai'
import { useCallback, useLayoutEffect } from 'react'
import { useLatest } from 'react-use'

import { breadcrumbsHistoryAtom, IsBreadcrumbsHistoryBackAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'

/**
 * 面包屑历史，回退后的副作用
 * 每个历史记录暂时只能使用一次
 * @date 8/22/2023 - 2:55:11 PM
 *
 * @export
 * @param {string} toPage 去往的页面
 * @param {Function} cb
 */
export function useBreadcrumbsHistoryBackEffect(toPage: string, cb: Function) {
    const [history, setHistory] = useAtom(breadcrumbsHistoryAtom)
    const [isHistoryBack, setIsHistoryBack] = useAtom(IsBreadcrumbsHistoryBackAtom)

    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId || '', [])
    )

    const latest = useLatest(cb)

    useLayoutEffect(() => {
        const prevHistory = history.findLast(item => item.from.page === pageId && item.to.page === toPage && !item.restored)
        const isBackFromHistory = isHistoryBack && !!prevHistory
        if (isBackFromHistory) {
            const clear = latest.current()
            setIsHistoryBack(false)
            setHistory(s => {
                const h = s.findLast(item => item.from.page === pageId && !item.restored)
                if (h) {
                    h.restored = true
                }
            })

            return clear
        }
    }, [history, isHistoryBack, latest, pageId, setHistory, setIsHistoryBack, toPage])
}
