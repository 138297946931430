import { Breadcrumbs, Flex, Group, IconFont } from '@byecode/ui'
import type { SortProtocol, ViewField } from '@lighthouse/core'
import { FieldSettingConfigure, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useToggle } from 'react-use'

import { InnerDrawer } from '../../InnerDrawer'
import { listCommonStyles } from './constants'
import { StyledIconWrapper } from './styles'

interface AllowFieldConfiguratorProps {
    value: SortProtocol
    columns: ViewField[]
    onChange?: (v: SortProtocol) => void
    onBack?: () => void
}
interface AllowFieldSettingProps {
    title: string
    type: string
    value: SortProtocol
    columns: ViewField[]
    onChange?: (v: SortProtocol) => void
}

export const AllowFieldConfigurator: FC<AllowFieldConfiguratorProps> = ({ value, columns, onChange, onBack }) => {
    const { canSort, userSortSetting } = value

    const handleChange = useCallback(
        (params: Partial<SortProtocol>) => {
            onChange?.({ ...value, ...params })
        },
        [onChange, value]
    )

    return (
        <InnerDrawer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '排序', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <Group
                mode="switch"
                label="排序"
                opened={canSort}
                onCollapseChange={v => handleChange({ canSort: v })}
                collapseProps={{ style: { padding: 0 } }}
            >
                <FieldSettingConfigure
                    title="用户排序的字段"
                    columns={columns}
                    value={userSortSetting}
                    onChange={v => handleChange({ userSortSetting: v })}
                />
            </Group>
        </InnerDrawer>
    )
}

export const AllowFieldSetting: FC<AllowFieldSettingProps> = ({ title, type, value, columns, onChange, ...rest }) => {
    const { canSort } = value
    const [open, toggle] = useToggle(false)
    return (
        <>
            <ListItem4ByecodeUi
                styles={listCommonStyles}
                justifyContent="space-between"
                compact={false}
                enablePadding
                enableHover
                alignItems="center"
                onClick={() => toggle(true)}
            >
                <Flex gap="12px" alignItems="center">
                    <StyledIconWrapper active={canSort}>
                        <IconFont type={type} size={18} fill={canSort ? 'var(--color-main)' : 'var(--color-gray-500)'} />
                    </StyledIconWrapper>
                    <div>{title}</div>
                </Flex>
                <Flex gap="4px" alignItems="center" style={{ color: 'var(--color-gray-400)' }}>
                    <div>{canSort ? '已开启' : '已关闭'}</div>
                    <IconFont type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>
            {open && <AllowFieldConfigurator value={value} columns={columns} onChange={onChange} onBack={() => toggle(false)} />}
        </>
    )
}
