import { type ActionFlowNode, type UpdateRecordActionConfig, getUpdateRecordVariableValue, pageStackPubSub } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleUpdateRecord = async (node: ActionFlowNode<UpdateRecordActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const { nodeId, selectType } = config
    let appId = ''
    let envId = ''
    let dsId = ''
    let recordId = ''

    if (selectType === 'CURRENT_RECORD') {
        appId = excParams?.record?.appId
        envId = excParams?.record?.envId
        dsId = excParams?.record?.dsId
    } else {
        appId = excParams[nodeId]?.record?.appId
        envId = excParams[nodeId]?.record?.envId
        dsId = excParams[nodeId]?.record?.dsId
    }

    if (!dsId) {
        return excParams
    }

    const { recordId: upstreamRecordId, fields } = getUpdateRecordVariableValue(config, { ...excParams, sourceDataSourceId: dsId })

    if (selectType === 'CURRENT_RECORD') {
        recordId = excParams?.clickTriggerNodeParams?.record?.id
    } else {
        recordId = upstreamRecordId
    }

    const record = await excParams.actionEvents.updateRecord({
        appId,
        envId,
        dsId,
        recordId,
        fields
    })

    if (!record?.id) {
        throw new Error('更新记录异常')
    }

    // 更新行以后，需要通知对应订阅更新数据
    pageStackPubSub.emit(`${dsId}-ADD`, record)

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        record
    })
}
