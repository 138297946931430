import { SegmentedControl } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const data = [
    {
        label: '男',
        value: 'man'
    },
    {
        label: '女',
        value: 'woman'
    },
    {
        label: '未知',
        value: 'unknown'
    }
]

const data2 = [
    {
        label: '男男',
        value: 'man'
    },
    {
        label: '女女',
        value: 'woman'
    },
    {
        label: '未知水电费',
        value: 'unknown'
    }
]

export const FullWidth: React.FC = () => {
    return (
        <Unit title="满宽">
            <div style={{ width: 500 }}>
                <SegmentedControl fullWidth data={data} />
            </div>
            <div style={{ width: 100 }}>
                <SegmentedControl fullWidth data={data2} />
            </div>
        </Unit>
    )
}
