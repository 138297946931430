import { IconFont } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import { type AppUser, type DTSelectItem, type Field, VariableType } from '@lighthouse/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import type { Editor, NodeViewProps } from '@tiptap/react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import { lightFormat } from 'date-fns'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../../../constants/color'
import { useSharedConfigDisabledWithVersion } from '../../../../contexts'
import { DATE_FORMAT, getColorById } from '../../../../utils'
import type { VariableTree } from '../../../'
import { type VariableOptions } from '../../../'
import { VariableTag } from '../../../Variable/VariableTag'
import { useNodeFieldEditor } from '../../EditorProvider'
// import type { VariablePopoverSchema } from '../../../VariablePicker'
// import { getSchemaOption, VariableType } from '../../../VariablePicker'

const SCxNodeWrapper = styled.span<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);

    border-radius: 4px;
    margin: 0 3px;
    color: ${({ color }) => color || 'var(--color-black)'};
    border: 1px solid var(--color-gray-200);
`

const SCxSelectNodeWrapper = styled.span<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);
    border-radius: 100px;
    margin: 0 3px;
    color: ${({ color }) => color || 'var(--color-black)'};
    border: 1px solid var(--color-gray-200);
`

const SCxNode = styled.span`
    margin: 2px 4px;
    display: inline-flex;
    /* height: 24px; */
    /* line-height: 24px; */
    align-items: center;
    justify-content: flex-start;
`

const SCxVariableNode = styled(NodeViewContent)`
    margin: 0 4px;
    /* vertical-align: middle; */
`

const SCxText = styled.span`
    font-size: var(--font-size-sm);
    margin: 0 4px;
    white-space: nowrap;
`

const SCxIcon = styled(IconFont)`
    font-size: 14px;
    margin-left: 4px;
`

const SCxPersonAvatar = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 22px;
    margin-right: 4px;
    display: block;
`

const SCxPersonTextAvatar = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const PersonName = styled.span`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

export interface DataQuoteOptions {
    options: VariableOptions
    // nodeData?: VariableOptions
    // renderTooltip?: (node: ProseMirrorNode) => React.ReactNode
    personOptions?: AppUser[]
    userOption?: VariableTree
    formOption?: VariableTree
    viewOption?: VariableTree
    selectOptions?: DTSelectItem[]
    onNodeClick?: () => void
}

interface DataQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DataQuoteOptions }
    deleteNode: () => void
}

export interface DateQuoteOptions {
    field: Field
    onNodeClick?: () => void
}
interface DateQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DateQuoteOptions }
    deleteNode: () => void
}

const commonStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center'
    // position: 'relative',
    // top: '-1px'
}

const isCursorInCustomNode = (node: NodeViewProps['node'], state: Editor['state']) => {
    const { selection } = state

    // @ts-expect-error - ProseMirror types are not up-to-date
    return node === selection?.node
}

export const VariableComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options: extensionOptions } = extension
    const { options, userOption, viewOption, formOption } = extensionOptions
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue
    const editor = useNodeFieldEditor()
    const isSelected = editor?.state && isCursorInCustomNode(node, editor?.state)

    if (!variableData) {
        return null
    }

    if (
        [
            VariableType.PAGE,
            VariableType.VIEW,
            VariableType.USER,
            VariableType.VARIABLE,
            VariableType.UPSTREAM,
            VariableType.SYSTEM,
            VariableType.PAGE_LINK,
            VariableType.FORM
        ].includes(variableData.type)
    ) {
        return (
            <NodeViewWrapper as="span" style={{ fontWeight: 'normal' }}>
                <SCxVariableNode as="span" contentEditable={false}>
                    <VariableTag
                        isSelected={isSelected}
                        withClose={!disabledWithVersion}
                        onClose={deleteNode}
                        viewOption={viewOption}
                        formOption={formOption}
                        value={variableData}
                        options={options}
                        userDsOption={userOption}
                    />
                </SCxVariableNode>
            </NodeViewWrapper>
        )
    }
    return null
}

export const SelectComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options } = extension
    const { onNodeClick, selectOptions = [] } = options
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue

    if (!variableData) {
        return null
    }

    if (variableData.type !== VariableType.VALUE) {
        return null
    }
    const data = variableData.valueVariable?.value
    const option = find(item => item.label === data, selectOptions)
    const labelColor = option?.color
    // const [_, option] = data
    const itemBasicStyle: React.CSSProperties = {
        backgroundColor: labelColor ? `var(${LIGHT_COLORS_MAP[labelColor]})` : '',
        color: labelColor ? `var(${COLORS_MAP[labelColor]})` : '',
        borderColor: labelColor && 'rgba(0,0,0,0.1)'
    }
    return (
        <NodeViewWrapper as="span" style={commonStyle}>
            <SCxSelectNodeWrapper onClick={onNodeClick} style={itemBasicStyle}>
                <SCxNode>
                    <SCxText> {option?.label || ''}</SCxText>
                    {!disabledWithVersion && (
                        <SCxIcon
                            type="Close"
                            onClick={e => {
                                e.stopPropagation()
                                deleteNode()
                            }}
                        />
                    )}
                </SCxNode>
            </SCxSelectNodeWrapper>
        </NodeViewWrapper>
    )
}

export const CheckboxComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options } = extension
    const { onNodeClick, selectOptions = [] } = options
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue

    if (!variableData) {
        return null
    }

    if (variableData.type !== VariableType.VALUE) {
        return null
    }
    const data = variableData.valueVariable?.value

    const option = find(item => item.id === String(data), selectOptions)

    return (
        <NodeViewWrapper as="span" style={commonStyle}>
            <SCxNodeWrapper onClick={onNodeClick}>
                <SCxNode>
                    <SCxText> {option?.label || ''}</SCxText>
                    {!disabledWithVersion && (
                        <SCxIcon
                            type="Close"
                            onClick={e => {
                                e.stopPropagation()
                                deleteNode()
                            }}
                        />
                    )}
                </SCxNode>
            </SCxNodeWrapper>
        </NodeViewWrapper>
    )
}

export const PersonComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options } = extension
    const { onNodeClick, personOptions } = options
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue

    if (!variableData) {
        return null
    }

    if (variableData.type !== VariableType.VALUE) {
        return null
    }
    const data = variableData.valueVariable?.value
    const person = find(item => item.userId === data, personOptions || [])

    const avatarContent = useMemo(() => {
        if (data === '{currentUserId}') {
            return <SCxIcon size={22} type="UserCircle" />
        }
        if (!person) {
            return null
        }
        const { avatar, userId, username } = person

        const background = getColorById(userId)
        if (avatar) {
            return <SCxPersonAvatar src={avatar} />
        }
        return <SCxPersonTextAvatar background={background}>{username.charAt(0)}</SCxPersonTextAvatar>
    }, [data, person])

    const username = useMemo(() => {
        if (data === '{currentUserId}') {
            return '当前用户'
        }
        return person?.username
    }, [data, person?.username])

    if (!data) {
        return null
    }

    // const [_, option] = data
    return (
        <NodeViewWrapper as="div" style={{ ...commonStyle, verticalAlign: 'middle' }}>
            <SCxNodeWrapper onClick={onNodeClick}>
                <SCxNode>
                    {avatarContent}
                    <PersonName>{username}</PersonName>
                    {!disabledWithVersion && (
                        <SCxIcon
                            type="Close"
                            onClick={e => {
                                e.stopPropagation()
                                deleteNode()
                            }}
                        />
                    )}
                </SCxNode>
            </SCxNodeWrapper>
        </NodeViewWrapper>
    )
}

export const DateComponent: React.FC<DateQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options } = extension
    const { onNodeClick } = options
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue
    if (!variableData) {
        return null
    }
    if (variableData.type !== VariableType.VALUE) {
        return null
    }
    const timestamp = variableData.valueVariable?.value
    if (!timestamp) {
        return null
    }
    const dateString = timestamp ? lightFormat(Number(timestamp), DATE_FORMAT) : ''
    if (!dateString) {
        return null
    }
    return (
        <NodeViewWrapper as="span" style={commonStyle}>
            <SCxNodeWrapper onClick={onNodeClick}>
                <SCxNode>
                    <SCxText>{dateString}</SCxText>
                    {!disabledWithVersion && (
                        <SCxIcon
                            type="Close"
                            onClick={e => {
                                e.stopPropagation()
                                deleteNode()
                            }}
                        />
                    )}
                </SCxNode>
            </SCxNodeWrapper>
        </NodeViewWrapper>
    )
}
