import { SegmentedControl, Select, Slider } from '@byecode/ui'
import type { CustomViewOptions, PaginationProtocol } from '@lighthouse/core'
import { ARRANGEMENT, CUSTOM_VIEW_LAYOUT } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BLOCK_SIZE_OPTIONS, DIRECTION_OPTIONS, LAYOUT_CONTROL } from '../../../constants'

const CUSTOM_VIEW_RECORD_SIZE = BLOCK_SIZE_OPTIONS.filter(item => item.value !== 'xs')

const COLS_OPTIONS = Array.from({ length: 6 }).map((_, i) => ({
    label: `${i + 1} 列`,
    value: (i + 1).toString()
}))

export const View = ({ id }: { id: string }) => {
    const { control, getValues } = useFormContext<CustomViewOptions & PaginationProtocol>()

    const [layout, arrangement] = getValues(['layout', 'arrangement'])

    return (
        <Group4ByecodeUi label="视图">
            {layout === CUSTOM_VIEW_LAYOUT.grid && (
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <Text>方向</Text>
                    <Controller
                        control={control}
                        name="direction"
                        render={({ field }) => (
                            <SegmentedControl
                                style={{ width: 180 }}
                                fullWidth
                                data={DIRECTION_OPTIONS}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>排列方式</Text>
                <Controller
                    control={control}
                    name="arrangement"
                    render={({ field }) => (
                        <SegmentedControl
                            style={{ width: 180 }}
                            fullWidth
                            data={LAYOUT_CONTROL}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            {arrangement === ARRANGEMENT.fixed ? (
                <ListItem4ByecodeUi key="cols" enablePadding justifyContent="space-between" alignItems="center">
                    <Text>每行显示</Text>
                    <Controller
                        control={control}
                        name="cols"
                        shouldUnregister={false}
                        render={({ field: { value, onChange } }) => (
                            <Select
                                value={value?.toString()}
                                onChange={v => onChange(Number(v))}
                                style={{ width: 180 }}
                                searchable={false}
                                options={COLS_OPTIONS}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            ) : (
                <ListItem4ByecodeUi key="size" enablePadding justifyContent="space-between" alignItems="center">
                    <Text>尺寸</Text>
                    <Controller
                        control={control}
                        name="size"
                        shouldUnregister={false}
                        render={({ field }) => (
                            <SegmentedControl {...field} size="md" fullWidth data={CUSTOM_VIEW_RECORD_SIZE} style={{ width: 180 }} />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}

            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>间距</Text>
                <Controller
                    name="gap"
                    control={control}
                    render={({ field }) => (
                        <Slider style={{ width: 180 }} min={0} max={12} step={0.125} value={field.value} onChange={field.onChange} />
                    )}
                />
            </ListItem4ByecodeUi>
        </Group4ByecodeUi>
    )
}
