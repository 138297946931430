import { Button } from '@byecode/ui/components/Button'
import { TitleInContentModal } from '@lighthouse/shared'
import React, { useState } from 'react'

import { Unit } from '../Mics'

export const Variant: React.FC = () => {
    const [open, setOpen] = useState(false)
    return (
        <Unit title="shared特殊变体: 标题不在head中">
            <Button onClick={() => setOpen(true)}>打开</Button>
            <TitleInContentModal open={open} onClose={() => setOpen(false)} title="测试标题">
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容 测试内容
                测试内容 测试内容 测试内容
                <br />
            </TitleInContentModal>
        </Unit>
    )
}
