import { Group } from '@byecode/ui'
import { FindUseType, getIsFindUse, getTableIcon, SelectDataSource, useFindUseObjectContext } from '@lighthouse/shared'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

interface DefaultDataSourceProps {
    prefixName?: string
}

/** 图表设置数据源id */
export const DefaultDataSource: React.FC<DefaultDataSourceProps> = ({ prefixName }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { control } = useFormContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const name = prefixName ? `${prefixName}.pointer` : 'pointer'

    return (
        <Group label="数据来源">
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <SelectDataSource
                        withinPortal
                        placeholder="请选择数据表"
                        value={field.value}
                        width="100%"
                        onChange={field.onChange}
                        dataSourceList={dataSourceList}
                    />
                )}
            />
        </Group>
    )
}
