import { ColorPicker, Flex, Popover } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useState } from 'react'

import { colorList } from './help'
import ThemeColorPick from './ThemeColorPick'

export * from './help'

export type ThemeColor = {
    colorName: string
    color: string
}

export interface ThemeColorPickerProps {
    value?: ThemeColor
    size?: number
    withinPortal?: boolean
    onChange?: (val: ThemeColor) => void
}

export const ThemeColorPicker: React.FC<ThemeColorPickerProps> = ({ value, size = 35, withinPortal = true, onChange }) => {
    const [open, setOpen] = useState(false)

    const { colorName, color } = value || {}

    return (
        <>
            {colorList.map(([colorVariable, c], i) => (
                <ThemeColorPick
                    key={c}
                    size={size}
                    active={colorVariable === colorName}
                    type="color"
                    onClick={() =>
                        onChange?.({
                            colorName: colorVariable,
                            color: c
                        })
                    }
                    color={c}
                />
            ))}
            <Popover width="auto" withinPortal opened={open} onChange={setOpen}>
                <Popover.Target>
                    <Flex alignItems="center">
                        <ThemeColorPick
                            size={size}
                            type="image"
                            active={colorName === 'custom'}
                            src={
                                colorName === 'custom'
                                    ? getAssetUrl('common', 'theme_picker_active.png')
                                    : getAssetUrl('common', 'theme_picker.png')
                            }
                        />
                    </Flex>
                </Popover.Target>
                <Popover.Dropdown compact>
                    <ColorPicker
                        showSystemColors={false}
                        value={color}
                        onChange={c => {
                            onChange?.({
                                color: c,
                                colorName: 'custom'
                            })
                        }}
                    />
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
