import { backgroundTransitionOnClick, Divider, IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const UserMenuContainer = styled.div`
    width: 240px;
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
`

export const TitleName = styled.span`
    font-weight: bold;
    font-size: var(--font-size-base);
    color: var(--color-black);
`

export const TitlePhone = styled.span`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin-top: 4px;
`


export const SettingContainer = styled.div`

`

export const SettingItem = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    ${backgroundTransitionOnClick}
    ${pointer}
`


export const SettingName = styled.span`
    margin-left: 8px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    flex: 1;
`

export const ItemDivider = styled(Divider)`
    border-color: var(--color-gray-100);
`
