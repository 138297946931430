import { Flex, IconFont, Text } from '@byecode/ui'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useOtherPlatformList } from '@/shared/reusable'

interface WechatIntegratedCardProps {
    type: 'WECHAT_OFFICIAL_ACCOUNT' | 'WECHAT_WEBSITE' | 'WECHAT_PAY' | 'STRIPE'
}

const SCxContainer = styled(Flex)`
    display: flex;
    align-items: center;
    height: 38px;
    gap: 4px;
    padding: 0 8px;
    background-color: var(--color-gray-100);
    border-radius: 6px;
    font-size: var(--font-size-normal);
`
export const WechatIntegratedCard: React.FunctionComponent<WechatIntegratedCardProps> = ({ type }) => {
    const { data } = useOtherPlatformList()
    const appId = useCurrentAppID()
    const navigate = useNavigate()

    const account = useMemo(() => data?.find(item => item.type === type), [data, type])

    const label = useMemo(() => {
        switch (type) {
            case 'WECHAT_OFFICIAL_ACCOUNT': {
                return '公众号'
            }
            case 'WECHAT_WEBSITE': {
                return '微信网站应用'
            }
            case 'WECHAT_PAY': {
                return '微信支付商户号'
            }
            case 'STRIPE': {
                return 'Stripe商户账号'
            }
            default: {
                return null
            }
        }
    }, [type])

    const themeColor = type === 'STRIPE' ? 'var(--color-main)' : 'var(--color-green-500)'

    if (!account) {
        return (
            <SCxContainer
                justifyContent="space-between"
                onClick={() => {
                    navigate(
                        { pathname: `/${appId}/otherPlatforms` },
                        {
                            state: {
                                openModalType: type
                            }
                        }
                    )
                }}
            >
                <Flex gap={4}>
                    <IconFont color="var(--color-gray-400)" size={16} type="TickCircle" />
                    <Text color="var(--color-black)">
                        未集成
                        {label}
                    </Text>
                </Flex>
                <Flex justifyContent="flex-end">
                    <Text color="var(--color-gray-400)">前去集成</Text>
                    <IconFont color="var(--color-gray-400)" size={16} type="ArrowRightSmall" />
                </Flex>
            </SCxContainer>
        )
    }

    return (
        <SCxContainer>
            <IconFont color={themeColor} size={16} type={type === 'STRIPE' ? 'Stripe' : 'WeChatLogoIconLibrary'} />
            <span>{label}</span>
            <span style={{ color: themeColor }}>{account.name}</span>
        </SCxContainer>
    )
}
