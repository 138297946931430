import * as React from 'react'

import * as CM from '../styles'
import * as SC from './styles'

interface PanelHeaderProps {
    keyword?: string
    rightSide?: React.ReactNode
    leftSide?: React.ReactNode
    disableSearch?: boolean
    onSearchChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
}

const PanelHeader: React.FC<PanelHeaderProps> = ({ keyword = '', disableSearch = false, rightSide, leftSide, onSearchChange }) => {
    return (
        <>
            {/* <SC.UtilTitle>
                {leftSide} */}
            <SC.UtilHeader>
                {disableSearch || (
                    <SC.SearchBar icon={<CM.Icon type="Search" />} placeholder="搜索..." value={keyword} onChange={onSearchChange} />
                )}
                {rightSide}
            </SC.UtilHeader>
            {/* </SC.UtilTitle> */}
            {/* <Divider light /> */}
        </>
    )
}

export default PanelHeader
