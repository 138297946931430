import { pointer } from '@byecode/ui'
import * as React from 'react'
import styled, { css } from 'styled-components'

interface ThemeColorPickProps {
    color?: string
    active: boolean
    size: number
    type: 'color' | 'image'
    src?: string

    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const roundIcon = (active: boolean, size: number, color?: string) => css`
    position: relative;
    width: ${`${size}px`};
    height: ${`${size}px`};
    flex-shrink: 0;
    background-color: ${color};
    /* border-width: ${active ? '1.5px' : '3.5px'};
    border-color: var(--color-white);
    border-style: solid; */
    /* box-sizing: border-box; */
    border-radius: 100%;
    z-index: 1;
    ${pointer}

    &:before {
        ${active &&
        css`
            position: absolute;
            left: 2px;
            right: 2px;
            bottom: 2px;
            top: 2px;
            content: '';
            border: 2px solid var(--color-white);
            border-radius: 100%;
            background-color: transparent;
            z-index: 2;
        `}
    }
`

export const ColorPicker = styled.div<{ active: boolean; size: number; color?: string }>`
    ${({ color, active, size }) => roundIcon(active, size, color)}
`

export const ColorPickerImg = styled.img<{ active: boolean; color?: string; size: number }>`
    position: relative;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    flex-shrink: 0;
    /* box-sizing: border-box; */
    /* border-width: ${({ active }) => (active ? '1.5px' : '3.5px')};
    border-color: var(--color-white);
    border-style: solid; */
    border-radius: 100%;
    z-index: 1;
    ${pointer}

    &:before {
        ${({ active, color }) =>
            active &&
            css`
                position: absolute;
                left: 2px;
                right: 2px;
                bottom: 2px;
                top: 2px;
                content: '';
                border: 2px solid var(--color-white);
                border-radius: 100%;
                background-color: transparent;
                z-index: 2;
            `}
    }
`

const ThemeColorPick: React.FC<ThemeColorPickProps> = ({ type, ...rest }) => {
    if (type === 'image') {
        return <ColorPickerImg {...rest} />
    }
    return <ColorPicker {...rest} />
}

export default ThemeColorPick
