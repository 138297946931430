import { Flex, Menu, Modal, Popover, Toast, Tooltip } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type PageAbstract, PageOpenType, PageType } from '@lighthouse/core'
import { LANGUAGE_LIST, OperationMenu, PageIconTypeMap, SelectLanguage, TooltipText, useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import cls from 'classnames'
import copy from 'copy-to-clipboard'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCss, useUpdateEffect } from 'react-use'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import type { MovePageAtomPayload } from '@/atoms/page/types'
import { PageVisibility } from '@/components/PageSetting/Visibility'
import { TagIcon } from '@/components/TagIcon'
import { useCurrentAppID, useCurrentEnvId, useLanguage } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import type { PageItemAction } from '../../constant'
import { menuOperationItems } from '../../constant'
import * as CM from '../../style'
import * as SC from './styles'

interface PageTreeListItemProps {
    data: PageAbstract
    activeId?: string
    level: number
    isEdit: boolean
    style?: React.CSSProperties
    onUpdatePage?: (param: Pick<PageAbstract, 'name' | 'id' | 'open' | 'isHome'>) => Promise<boolean>
    onSetHomePage?: (param: Pick<PageAbstract, 'id' | 'isHome'>) => Promise<boolean>
    onRemovePage?: (id: string) => void
    onToLink?: (page: PageAbstract) => void
    onChangeEdit: (id: string) => void
    onCopyPage: (id: string, lang: string) => Promise<string>
    onMovePage: (params: MovePageAtomPayload) => void
    onCustomRoute?: (id: string) => void
}

const activeCss = {
    color: 'var(--color-gray-600)',
    backgroundColor: 'var(--color-gray-100)',
    borderRadius: '5px'
}

const hoverCss = {
    color: 'var(--color-gray-600)',
    borderRadius: '5px',
    backgroundColor: 'var(--color-gray-100)'
}

const PageTreeListItem: React.FC<PageTreeListItemProps> = ({
    data,
    activeId = '',
    isEdit,
    level,
    style: propStyle,
    onSetHomePage,
    onUpdatePage,
    onRemovePage,
    onCopyPage,
    onToLink,
    onMovePage,
    onChangeEdit,
    onCustomRoute
}) => {
    const {
        id = '',
        name = '',
        icon,
        open = PageOpenType.all,
        groups = [],
        isHome = false,
        type = PageType.default,
        dsId = '',
        departments = [],
        route
    } = data ?? {}
    const [pageName, setPageName] = useState(name)
    // const [openVisible, setOpenVisible] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    const [copyLanguageOpened, setCopyLanguageOpened] = useState(false)
    const language = useLanguage()
    // const popupState = usePopupState({ variant: 'popover', popupId: 'optionPopupState' })
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const disabledWithVersion = useIsDisabledWithVersion()
    const dsName = useAtomData(
        dataSourceAtomFamily({ appId, envId, dsId }),
        useCallback(s => s?.name, [])
    )
    const { attributes, listeners, setNodeRef, node, transform, transition } = useSortable({
        id,
        disabled: isEdit
    })

    const isDefaultPage = type === PageType.default

    const visitableBtnCss = useCss({
        color: activeId === id ? 'var(--color-gray-500)' : 'var(--color-gray-400)',
        '&:hover': hoverCss
    })

    const operationBtnCss = useCss(
        popoverOpen
            ? activeCss
            : {
                  color: activeId === id ? 'var(--color-gray-500)' : 'var(--color-gray-400)',
                  '&:hover': hoverCss
              }
    )

    const homeBtnCss = useCss({
        color: activeId === id ? 'var(--color-gray-500)' : 'var(--color-gray-400)'
    })

    const options = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(
            s =>
                s?.language?.list
                    ?.map(item => {
                        const LANG = LANGUAGE_LIST.find(lang => lang.lang === item.lang)
                        return {
                            value: item.lang,
                            label: item.title,
                            icon: LANG?.flag
                        }
                    })
                    .filter(item => item.value !== language) ?? [],
            [language]
        )
    )

    const currentMenuItem = useMemo(
        () =>
            isDefaultPage
                ? menuOperationItems
                : menuOperationItems.map(item => ({
                      ...item,
                      items: item.items.filter(item => !['HOME', 'CUSTOM_ROUTE'].includes(item.action))
                  })),
        [isDefaultPage]
    )

    useUpdateEffect(() => {
        setPageName(name)
    }, [name])

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            paddingLeft: 16 * level + 42,
            ...propStyle
        }),
        [level, propStyle, transform, transition]
    )

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPageName(e.target.value)
    }, [])

    const handlers = useCallback(
        (pageId: string): Record<PageItemAction, (sender?: React.MouseEvent<HTMLDivElement>) => void> => {
            return {
                async DELETE() {
                    const isConfirm = await Modal.confirm({
                        title: '确认删除页面',
                        content: `确认删除页面「${name ?? '无标题'} 」？`,
                        okText: '删除'
                    })
                    if (isConfirm) {
                        onRemovePage?.(pageId)
                        setPopoverOpen(false)
                    }
                },
                async HOME(ev) {
                    ev?.stopPropagation()
                    await onSetHomePage?.({ id, isHome: true })
                    setPopoverOpen(false)
                },
                COPY_LINK() {
                    const success = copy(window.location.href)
                    if (success) {
                        Toast.success('已复制页面链接')
                    }
                },
                async COPY(ev) {
                    ev?.stopPropagation()
                    await onCopyPage?.(id, language)
                    setPopoverOpen(false)
                },
                RENAME(ev) {
                    ev?.stopPropagation()
                    setPopoverOpen(false)
                    onChangeEdit(id)
                },
                CUSTOM_ROUTE() {
                    onCustomRoute?.(id)
                }
            }
        },
        [name, onRemovePage, onSetHomePage, id, onCopyPage, language, onChangeEdit, onCustomRoute]
    )

    const handleUpdatePage = useCallback(async () => {
        onChangeEdit(id)
        if (name === pageName) {
            return
        }
        if (!pageName.trim()) {
            setPageName(name)
            return
        }
        const isUpdate = await onUpdatePage?.({ id, name: pageName ?? '未命名', open, isHome })
        if (!isUpdate) {
            setPageName(name)
        }
    }, [onChangeEdit, name, pageName, onUpdatePage, id, open, isHome])

    const handleKeyDown = useCallback(
        (ev: React.KeyboardEvent) => {
            if (ev.key === 'Enter') {
                handleUpdatePage()
            }
        },
        [handleUpdatePage]
    )
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef && inputRef.current && isEdit) {
            inputRef.current.select()
        }
    }, [isEdit])

    const isActivated = activeId === id

    return (
        <>
            <SC.DragContainer ref={setNodeRef} {...attributes} {...listeners}>
                <SC.ListItem
                    className={cls({ active: isActivated })}
                    data-page-id={id}
                    style={style}
                    onClick={ev => data && onToLink?.(data)}
                >
                    <SC.TopBlock>
                        <SC.LeftItem>
                            <CM.Icon type={PageIconTypeMap[type]} size={16} />
                            {isEdit ? (
                                <SC.TitleInput>
                                    <input
                                        type="text"
                                        value={pageName}
                                        placeholder="请输入名称"
                                        autoFocus
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        onBlur={() => handleUpdatePage()}
                                        ref={inputRef}
                                    />
                                </SC.TitleInput>
                            ) : (
                                <TooltipText
                                    title={pageName}
                                    render={ref => (
                                        <SC.Name ref={ref} onDoubleClick={() => !disabledWithVersion && onChangeEdit(id)}>
                                            {pageName || '无标题'}
                                        </SC.Name>
                                    )}
                                />
                            )}
                        </SC.LeftItem>
                        <SC.RightItem>
                            {isHome && (
                                <Tooltip title="主页">
                                    <div>
                                        <TagIcon radius={5} iconSize={12} size={12} className={homeBtnCss} icon="Home" />
                                    </div>
                                </Tooltip>
                            )}

                            {open !== PageOpenType.all && (
                                <Tooltip
                                    title={
                                        open === PageOpenType.login ? (
                                            '登录后可见'
                                        ) : (
                                            <Flex
                                                direction="column"
                                                style={{ maxWidth: 182, lineHeight: '20px', padding: '4px 8px', whiteSpace: 'pre-wrap' }}
                                            >
                                                <Text>以下角色可见：{groups.map(({ groupName }) => groupName).join('、')}。</Text>
                                                <Text>
                                                    以下部门可见：{departments.map(({ departmentName }) => departmentName).join('、')}。
                                                </Text>
                                            </Flex>
                                        )
                                    }
                                >
                                    <div>
                                        <TagIcon radius={5} iconSize={12} size={12} icon="Lock" className={visitableBtnCss} />
                                    </div>
                                </Tooltip>
                            )}

                            {route && (
                                <Tooltip title={`点击编辑自定义后缀：${route}`}>
                                    <TagIcon
                                        radius={5}
                                        iconSize={12}
                                        size={12}
                                        icon="PropertyLink"
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            onCustomRoute?.(id)
                                        }}
                                    />
                                </Tooltip>
                            )}

                            {!disabledWithVersion && (
                                <TagIcon
                                    onClick={ev => {
                                        setPopoverOpen(true)
                                        ev.stopPropagation()
                                    }}
                                    size={20}
                                    radius={5}
                                    className={cls({ operationBtnCss, dotsThree: true, activeDots: popoverOpen })}
                                    icon="DotsThree"
                                />
                            )}
                        </SC.RightItem>
                    </SC.TopBlock>

                    {/* WARNING 注意更换popover时，需要将copy link事件里指定的focus元素替换 */}
                    <Popover
                        opened={popoverOpen}
                        returnFocus={false}
                        onChange={setPopoverOpen}
                        width="auto"
                        withinPortal
                        position="right-start"
                    >
                        <Popover.AnchorEl anchorEl={node.current} />
                        <Popover.Dropdown>
                            <OperationMenu
                                onClose={() => setPopoverOpen(false)}
                                handlers={handlers}
                                menuItems={currentMenuItem}
                                isRename={false}
                                id={id}
                                value={pageName}
                                extra={
                                    <>
                                        <SelectLanguage
                                            options={options}
                                            trigger="hover"
                                            position="right-start"
                                            opened={copyLanguageOpened}
                                            onChange={setCopyLanguageOpened}
                                            targetComponent={
                                                <SC.Item className={cls({ active: copyLanguageOpened })}>
                                                    <Flex gap="8px">
                                                        <CM.Icon type="Copy" color="var(--color-gray-400)" />
                                                        <Text size={14}> 复制到其他语言版本 </Text>
                                                    </Flex>
                                                    <Flex>
                                                        <CM.Icon type="ArrowRightSmall" color="var(--color-gray-400)" />
                                                    </Flex>
                                                </SC.Item>
                                            }
                                            onClick={async lang => {
                                                await onCopyPage(id, lang)
                                                setPopoverOpen(false)
                                            }}
                                        />
                                        <Popover
                                            opened={menuOpened}
                                            onClose={() => setMenuOpened(false)}
                                            onChange={setMenuOpened}
                                            trigger="hover"
                                            withinPortal
                                            position="left-start"
                                            width="auto"
                                        >
                                            <Popover.Target>
                                                <SC.Item className={cls({ active: menuOpened })}>
                                                    <Flex gap="8px">
                                                        <CM.Icon type="Eye" color="var(--color-gray-400)" />
                                                        <Text size={14}> 页面可见性 </Text>
                                                    </Flex>
                                                    <Flex>
                                                        <CM.Icon type="ArrowRightSmall" color="var(--color-gray-400)" />
                                                    </Flex>
                                                </SC.Item>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <PageVisibility pageId={id} mode="none" style={{ width: 280, paddingBottom: 12 }} />
                                            </Popover.Dropdown>
                                        </Popover>
                                    </>
                                }
                            />
                        </Popover.Dropdown>
                    </Popover>
                </SC.ListItem>
            </SC.DragContainer>
        </>
    )
}

export default React.memo(PageTreeListItem)
