import { Box, Flex, Modal, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { lightFormat } from 'date-fns'
import { useSetAtom } from 'jotai'
import type { AnimationItem } from 'lottie-web'
import lottie from 'lottie-web'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { AppPublishStateAtom } from '@/atoms/application/state'

import { PublishedContent } from '../../ApplicationHeader/PublishedContent'

const Progress = styled.div`
    margin: 36px 0 12px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--color-gray-200);
`
const ProgressBar = styled.div`
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(#17f7ff, #7000ff);
    transition: all 0.3s;
`

interface PublishProgressProps {
    // inProgress: boolean
    isComplete: boolean
    // onProgressEnd: () => void
    isPublish?: boolean
    onClose: () => void
}

const MIN_DURATION = 3000

export const PublishProgress = ({ /* inProgress,  */ isComplete, isPublish, /* onProgressEnd, */ onClose }: PublishProgressProps) => {
    // const [currentTime, setCurrentTime] = useState(0)
    // const timerRef = useRef<number>()

    // useEffect(() => {
    //     if (inProgress) {
    //         timerRef.current = window.setInterval(() => {
    //             setCurrentTime(s => s + 1000 / 60)
    //         }, 1000 / 60)
    //     }

    //     return () => {
    //         clearInterval(timerRef.current)
    //         setCurrentTime(0)
    //     }
    // }, [inProgress])

    // useEffect(() => {
    //     if (inProgress && currentTime >= MIN_DURATION) {
    //         clearInterval(timerRef.current)
    //         setCurrentTime(0)
    //         onProgressEnd()
    //     }
    // }, [currentTime, inProgress, onProgressEnd])

    const animationRef = useRef<HTMLDivElement>(null)
    const lottieAnimationRef = useRef<AnimationItem | null>(null)

    const setAppPublishState = useSetAtom(AppPublishStateAtom)

    useLayoutEffect(() => {
        if (isComplete) {
            const el = animationRef.current
            if (!el) {
                return
            }

            lottieAnimationRef.current = lottie.loadAnimation({
                container: el,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                path: getAssetUrl('lottie', 'celebrate.json')
            })

            lottieAnimationRef.current.addEventListener('complete', () => {
                setAppPublishState({
                    isPublish: false,
                    isSuccess: false
                })
            })
        }
    }, [isComplete, setAppPublishState])

    useEffect(() => {
        return () => {
            // clearInterval(timerRef.current)
            lottieAnimationRef.current?.destroy()
        }
    }, [setAppPublishState])

    return (
        <>
            {/* <Modal width={390} open={inProgress} closeOnClickOverlay={false} closeOnEscape={false} withCloseIcon={false}>
                <Text algin="center" size={20} weight={500} lineHeight="22px">
                    正在{isPublish ? '发布' : '更新'}应用...
                </Text>
                <Progress>
                    <ProgressBar style={{ width: `${Math.floor((currentTime / MIN_DURATION) * 100)}%` }} />
                </Progress>
                <Flex justifyContent="flex-end" mb={12}>
                    <Text weight={700} size={10}>{`${Math.floor((currentTime / MIN_DURATION) * 100)}%`}</Text>
                </Flex>
                <Text algin="center" color="var(--color-gray-400)" size={12}>
                    “优秀的设计是去除所有不必要的细节。”-明·D·德兰
                </Text>
            </Modal> */}

            {/* <Modal
                width={390}
                open={isComplete}
                onClose={onClose}
                title={
                    <Flex direction="column" gap={4}>
                        <Text size={20} weight={500} lineHeight="22px">
                            您的应用已{isPublish ? '发布' : '更新'}🎉
                        </Text>
                        <Text size={14} lineHeight="22px" color="var(--color-gray-400)">
                            最近更新：{lightFormat(new Date(), 'yyyy-MM-dd HH:mm')}
                        </Text>
                    </Flex>
                }
                styles={{
                    desktop: {
                        header: {
                            padding: 16,
                            height: 'auto',
                            alignItems: 'flex-start'
                        },
                        body: {
                            padding: 0
                        }
                    }
                }}
            >
                <Box px={12}>
                    <PublishedContent showShareContent />
                </Box>
            </Modal> */}

            {createPortal(
                <div
                    ref={animationRef}
                    style={{
                        pointerEvents: 'none',
                        position: 'fixed',
                        zIndex: 201,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        height: 800
                    }}
                />,
                document.body
            )}
        </>
    )
}
