import { Divider, IconFont, Switch } from '@byecode/ui'
import type { DataSourceAbstract, JoinField } from '@lighthouse/core'
import { FieldTypeTag, getFieldIcon } from '@lighthouse/shared'
import { compareArrays } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

interface JoinFieldsProps {
    joinedDs: DataSourceAbstract
    value?: JoinField[]
    onChange: (val: JoinField[]) => void
}

const SCxList = styled.div``

const SCxItem = styled.div``

const SCxItemContent = styled.div`
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxField = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`

const SCxExtra = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
`

const SCxIcon = styled(IconFont)``

const SCxName = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

const SCxSwitch = styled(Switch)``

export const JoinFields: React.FC<JoinFieldsProps> = ({ joinedDs, value, onChange }) => {
    const { schema } = joinedDs
    const fieldIds = useMemo(() => Object.keys(schema), [schema])
    const [fields, joinFields, checkValue] = useMemo(() => {
        const fields = Object.values(schema).map(item => ({
            fieldId: item.id,
            name: item.name
        }))
        const validFields = value?.filter(item => !!schema[item.fieldId]) || []
        const checkValue = validFields.map(item => item.fieldId)
        return [fields, validFields, checkValue]
    }, [schema, value])
    // const checkValue = useMemo(() => value?.filter(item => !!schema[item.fieldId]).map(item => item.fieldId) || [], [schema, value])
    const isCheckAll = useMemo(() => compareArrays(fieldIds, checkValue || []), [fieldIds, checkValue])

    const handleAllChange = useCallback(() => {
        if (isCheckAll) {
            onChange([])
            return
        }
        onChange(fields)
    }, [fields, isCheckAll, onChange])

    const handleChange = useCallback(
        (val: string) => {
            if (checkValue.includes(val)) {
                const newValue = joinFields.filter(item => item.fieldId !== val)
                onChange(newValue)
                return
            }
            const field = schema[val]
            if (!field) {
                return
            }
            const newValue = [...joinFields, { fieldId: val, name: field.name }]
            onChange(newValue)
        },
        [checkValue, schema, joinFields, onChange]
    )

    return (
        <SCxList>
            <SCxItemContent>
                <SCxField>
                    <SCxName>全选</SCxName>
                </SCxField>
                <SCxSwitch checked={isCheckAll} onChange={handleAllChange} />
            </SCxItemContent>
            {Object.entries(schema).map(([key, field]) => (
                <SCxItem key={key}>
                    <Divider />
                    <SCxItemContent>
                        <SCxField>
                            <SCxIcon size={16} type={getFieldIcon(key, field.type, field.innerType)} fill="var(--color-gray-400)" />
                            <SCxName>{field.name}</SCxName>
                        </SCxField>
                        <SCxExtra>
                            <FieldTypeTag type={field.type} innerType={field.innerType} />
                            <SCxSwitch checked={checkValue.includes(field.id)} onChange={() => handleChange(field.id)} />
                        </SCxExtra>
                    </SCxItemContent>
                </SCxItem>
            ))}
        </SCxList>
    )
}
