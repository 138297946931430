import 'reactflow/dist/style.css'

import type { FlowEdge, FlowNode, TriggerNodeType, WorkflowType } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import React, { useCallback, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import ReactFlow, { Background, useEdgesState, useNodesState } from 'reactflow'
import styled, { createGlobalStyle } from 'styled-components'

import { usePannable } from '@/components/AIAssistant/GraphCanvas/hooks'
import { Controls } from '@/components/FlowControls'

import { addNewTriggerNodeWithEdges } from '../../utils/addNode'
import { getNodesWithPosition } from '../../utils/getNodesPosition'
import { getWithStatusEdges } from '../../utils/getWithStatusEdges'
import CommonEdge from '../Edges/CommonEdge'
import ConditionInnerEdge from '../Edges/ConditionInnerEdge'
import ConditionSourceEdge from '../Edges/ConditionSourceEdge'
import ConditionTargetEdge from '../Edges/ConditionTargetEdge'
import ActionNode from '../Nodes/ActionNode'
import ConditionNode from '../Nodes/ConditionNode'
import EndNode from '../Nodes/EndNode'
import TriggerNode from '../Nodes/TriggerNode'
import { Placeholder } from './Placeholder'

interface FlowEditorProps {
    type: WorkflowType
    nodes: FlowNode[]
    edges: FlowEdge[]
    silent?: boolean
    styles?: { wrapper?: React.CSSProperties }
    onChange?: (nodes: FlowNode[], edges: FlowEdge[]) => void
    onSelectChange?: (selected: boolean) => void
    onNodeClick?: (node: FlowNode) => void
}

const SCxFlowEditorWrapper = styled.div`
    width: 100%;
`

const FlowEditorGlobalStyle = createGlobalStyle`
    .react-flow {
        .react-flow__pane {
            cursor: default;
        }
        &.react-flow__pane_pan_start {
            .react-flow__pane {
                cursor: grab !important;
            }
        }
        .react-flow__pane.dragging {
            cursor: grabbing;
        }
    }
`

const nodeTypes = {
    TRIGGER: TriggerNode,
    ACTION: ActionNode,
    CONDITION: ConditionNode,
    END: EndNode
} as AnyObject

const edgeTypes = {
    common: CommonEdge,
    conditionSource: ConditionSourceEdge,
    conditionInner: ConditionInnerEdge,
    conditionTarget: ConditionTargetEdge
}

export const FlowEditor: React.FC<FlowEditorProps> = ({ nodes, edges, type, silent, styles, onChange, onSelectChange, onNodeClick }) => {
    const flowRef = useRef<HTMLDivElement>(null)
    const reactFlowWrapper = useRef<HTMLDivElement>(null)
    const [flowNodes, setNodes, onNodesChange] = useNodesState(getNodesWithPosition(nodes, edges))
    const [flowEdges, setEdges, onEdgesChange] = useEdgesState(getWithStatusEdges(nodes, edges))

    const isEmpty = nodes.length === 0

    const handleInit = useCallback(
        (nodeType: TriggerNodeType) => {
            const { nodes: newNodes, edges: newEdges } = addNewTriggerNodeWithEdges(nodeType)

            setNodes(newNodes)
            setEdges(newEdges)

            onChange?.(newNodes, newEdges)
        },
        [onChange, setEdges, setNodes]
    )

    usePannable(flowRef)

    useUpdateEffect(() => {
        // if (isEmpty) {
        //     return
        // }

        setNodes(getNodesWithPosition(nodes, edges))
        setEdges(getWithStatusEdges(nodes, edges))
    }, [nodes, edges, isEmpty, setNodes, setEdges])

    const wrapperStyle = styles?.wrapper ?? silent ? { height: '100%' } : { height: 'calc(100% - 55px)' }

    const fitViewOptions = useMemo(() => {
        return silent
            ? {
                  padding: 0,
                  minZoom: 0.5,
                  maxZoom: 0.5
              }
            : {
                  padding: 300,
                  minZoom: 1,
                  maxZoom: 1
              }
    }, [silent])

    return (
        <SCxFlowEditorWrapper ref={reactFlowWrapper} style={wrapperStyle}>
            <FlowEditorGlobalStyle />
            <ReactFlow
                fitView
                ref={flowRef}
                deleteKeyCode={null}
                nodes={flowNodes}
                edges={flowEdges}
                panOnDrag={false}
                zoomOnScroll={false}
                nodesDraggable={false}
                edgesFocusable={false}
                proOptions={{ hideAttribution: true }}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitViewOptions={fitViewOptions}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onClickCapture={() => onSelectChange?.(false)}
                onNodeClick={(event, node) => {
                    event.stopPropagation()
                    if (node.type === 'END') {
                        onSelectChange?.(false)
                        return
                    }

                    onNodeClick?.(node as FlowNode)
                }}
            >
                {!silent && (
                    <>
                        {isEmpty ? (
                            <Placeholder type={type} onInit={handleInit} />
                        ) : (
                            <>
                                <Controls />
                                {/* <MiniMap pannable nodeColor={nodeColor} /> */}
                            </>
                        )}
                        <Background />
                    </>
                )}
            </ReactFlow>
        </SCxFlowEditorWrapper>
    )
}
