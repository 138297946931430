import { Badge, Tag } from '@byecode/ui'
import type { EnvDsDiffUpdate } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { mergeText } from '../constant'
import { MergeFieldList } from './MergeFieldList'
import { MergeGroup } from './MergeGroup'
import { UpdateFieldList } from './UpdateFieldList'
import { UpdateItemContent } from './UpdateItemContent'

const SCListItem = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-color: var(--color-gray-200);
    border-style: solid;
    border-width: 0 1px 1px 1px;

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`

const SCxList = styled.div``

interface MergeUpdateListProps {
    data: EnvDsDiffUpdate
}

const badgeStyle = {
    color: 'var(--color-gray-500)',
    backgroundColor: 'var(--color-gray-100)'
}
export const MergeUpdateList: React.FC<MergeUpdateListProps> = ({ data }) => {
    const addFieldContent = useMemo(() => {
        if (!data.additions || data.additions.length === 0) {
            return null
        }
        const color = `var(--color-${mergeText['add']}-500)`
        const background = `var(--color-${mergeText['add']}-100)`
        return (
            <MergeGroup
                level={1}
                enableBorder
                label={
                    <Tag color={color} background={background} radius={4} style={{ fontSize: 12, padding: '2px 6px' }}>
                        新增字段
                    </Tag>
                }
                extra={<Badge {...badgeStyle}>{data.additions.length ?? 0}</Badge>}
            >
                <MergeFieldList level={1} data={data.additions} />
            </MergeGroup>
        )
    }, [data.additions])

    const updateFieldContent = useMemo(() => {
        if (!data.updates || data.updates.length === 0) {
            return null
        }
        const color = `var(--color-${mergeText['update']}-500)`
        const background = `var(--color-${mergeText['update']}-100)`
        return (
            <MergeGroup
                level={1}
                enableBorder
                label={
                    <Tag color={color} background={background} radius={4} style={{ fontSize: 12, padding: '2px 6px' }}>
                        修改字段
                    </Tag>
                }
                extra={<Badge {...badgeStyle}>3</Badge>}
            >
                {data.updates.map((item, index) => (
                    <UpdateFieldList key={index} data={item} />
                ))}
            </MergeGroup>
        )
    }, [data.updates])

    const deleteFieldContent = useMemo(() => {
        if (!data.deletions || data.deletions.length === 0) {
            return null
        }
        const color = `var(--color-${mergeText['delete']}-500)`
        const background = `var(--color-${mergeText['delete']}-100)`
        return (
            <MergeGroup
                level={1}
                enableBorder
                label={
                    <Tag color={color} background={background} radius={4} style={{ fontSize: 12, padding: '2px 6px' }}>
                        删除字段
                    </Tag>
                }
                extra={<Badge {...badgeStyle}>{data.deletions.length ?? 0}</Badge>}
            >
                <MergeFieldList level={1} data={data.deletions} />
            </MergeGroup>
        )
    }, [data.deletions])
    return (
        <SCxList>
            {data.nameChange && (
                <SCListItem>
                    <UpdateItemContent title="数据源名称" theme={mergeText['update']} from={data.nameChange.from} to={data.nameChange.to} />
                </SCListItem>
            )}
            {addFieldContent}
            {updateFieldContent}
            {deleteFieldContent}
        </SCxList>
    )
}
