import { IconFont, Select } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Feature: React.FC = () => {
    return (
        <Unit title="特性">
            <Select
                placeholder="请选择"
                style={{
                    width: 200
                }}
                searchable
                dropdownProps={{ extra: <div style={{ fontSize: 14, padding: 16 }}>这是一段tips</div> }}
                options={[
                    { label: '男', value: 'man', describe: '这是男的describe属性' },
                    { label: '女', value: 'woman' }
                ]}
            />
            <Select
                placeholder="禁用"
                clearable
                searchable
                disabled
                style={{
                    width: 200
                }}
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
            />
            <Select
                placeholder="自定义item"
                clearable
                searchable
                style={{
                    width: 200
                }}
                optionComponent={({ label, value, extra, describe, icon, ...other }) => (
                    <div {...other}>
                        {icon && typeof icon === 'string' ? <IconFont type={icon} /> : icon}
                        <div>{label}</div>
                    </div>
                )}
                options={[
                    { label: '男', value: 'man', icon: 'Preview' },
                    { label: '女', value: 'woman', icon: 'PageRecord' }
                ]}
            />
        </Unit>
    )
}
