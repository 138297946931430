import { IconFont, Switch } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Group, Text } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

import { StyledIconWrapper } from './styles'

interface RequiredProps {
    value?: boolean
    label?: string
    onChange?: (v: boolean) => void
    disabled?: boolean
}

export const Required: FC<RequiredProps> = ({ value, label = '必填', onChange, ...rest }) => (
    <ListItem4ByecodeUi enableHover enablePadding justifyContent="space-between" alignItems="center">
        <Group spacing="xs" noWrap>
            {/* <StyledIconWrapper active={value}>
                <IconFont type="Asterisk" size={18} fill={value ? 'var(--color-main)' : undefined} />
            </StyledIconWrapper> */}
            <Text>{label}</Text>
        </Group>
        <Switch checked={value} onChange={e => onChange?.(e.currentTarget.checked)} {...rest} />
    </ListItem4ByecodeUi>
)
