import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const SelectContainer = styled.div`
    width: 180px;

    .selectInput {
        color: var(--color-orange-500);
        iconpark-icon {
            color: var(--color-orange-500);
        }
    }
`

export const ItemRightFill = styled.div`
    display: none;
    align-items: center;
    justify-content: flex-end;
`

export const Text = styled.div`
    line-height: 22px;
    font-size: var(--font-size-normal);
`

export const Icon = styled(IconFont)`
    margin-right: 6px;
`

export const Tip = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin: 8px 0;
`

export const MessageTemplatePreviewer = styled.div`
    padding: 12px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    font-size: var(--font-size-normal);
    margin-top: 18px;
`

export const MessageTemplatePreviewerHeader = styled.div`
    color: var(--color-gray-400);
    margin-bottom: 8px;
`

export const MessageTemplatePreviewerContent = styled.div``

export const VariableItem = styled.div`
    margin-bottom: 12px;
`

export const VariableTitle = styled.div`
    margin-bottom: 8px;
`
