import type { PopoverProps } from '@byecode/ui'
import { Button, IconFont, Menu } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const pos: PopoverProps['position'][] = [
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
    'right',
    'right-end',
    'right-start',
    'top',
    'top-end',
    'top-start'
]

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Menu>
                <Menu.Target>
                    <Button>点我</Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {pos.map(item => (
                        <Menu.Item key={item}>{item}</Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
            <Menu trigger="hover">
                <Menu.Target>
                    <Button>移入</Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {pos.map(item => (
                        <Menu.Item key={item}>{item}</Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
            <Menu withinPortal>
                <Menu.Target>
                    <Button>portal</Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {pos.map(item => (
                        <Menu.Item key={item}>{item}</Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>

            {pos.map(item => (
                <Menu position={item} withinPortal width={200} key={item}>
                    <Menu.Target>
                        <Button>{item}</Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {pos.map(item => (
                            <Menu.Item key={item} icon={<IconFont type="Add" />} rightSection={<IconFont type="ArrowRight" />}>
                                {item}
                            </Menu.Item>
                        ))}
                    </Menu.Dropdown>
                </Menu>
            ))}

            <Menu withinPortal trigger="hover" width={200}>
                <Menu.Target>
                    <Button>nested</Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {pos.map(item => (
                        <Menu key={item} trigger="hover" position="right">
                            <Menu.Target>
                                <Menu.Item rightSection={<IconFont type="ArrowRight" />}>{item}</Menu.Item>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {pos.map(item => (
                                    <Menu.Item key={item} closeMenuOnClick icon={<IconFont type="Add" />}>
                                        {item}
                                    </Menu.Item>
                                ))}
                            </Menu.Dropdown>
                        </Menu>
                    ))}
                </Menu.Dropdown>
            </Menu>
        </Unit>
    )
}
