import { singleTextEllipsis } from '@byecode/ui'
import { Box } from '@byecode/ui/components/Box'
import { Button } from '@byecode/ui/components/Button'
import { Checkbox } from '@byecode/ui/components/Checkbox'
import { DatePicker } from '@byecode/ui/components/DatePicker'
import { Flex } from '@byecode/ui/components/Flex'
import { Select } from '@byecode/ui/components/Select'
import { Enum } from '@lighthouse/tools'
import { subDays } from 'date-fns'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

const Subtitle = styled(Box)`
    color: var(--color-gray-400);
    display: inline-flex;
    align-items: center;
    line-height: 22px;
    font-size: 14px;
    width: 100px;
    flex: 0 0 100px;
    ${singleTextEllipsis()}
`

const DATE_TYPE = Enum('yesterday', 'last7', 'last30', 'day', 'dayRange')
type DateType = Enum<typeof DATE_TYPE>

const IS_DATE_RANGE = new Set<DateType>([DATE_TYPE.dayRange, DATE_TYPE.last30, DATE_TYPE.last7])
const IS_DATE_PICKER = new Set<DateType>([DATE_TYPE.day, DATE_TYPE.dayRange])
const DATE_COMPARE_OPTIONS = [
    {
        label: '昨日',
        value: DATE_TYPE['yesterday']
    },
    {
        label: '最近7日',
        value: DATE_TYPE['last7']
    },
    {
        label: '最近30日',
        value: DATE_TYPE['last30']
    },
    {
        label: '具体日期',
        value: DATE_TYPE['day']
    },
    {
        label: '日期范围',
        value: DATE_TYPE['dayRange']
    }
]

export function resetTime2Zero(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
}

export function resetTime2Last(date: Date) {
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
}

type Option<B extends boolean = false> = (B extends true ? { value?: string } : { value: string }) & {
    label: string
}

type CompareDates = {
    start?: Date
    end?: Date
}

interface ConditionProps<B extends boolean> {
    options: Option<B>[]

    multiple?: B

    defaultCondition?: string[]
    onConditionChange?: (selected: string[] | string) => void
    optionalCompare?: boolean

    onDateTypeChange?: (type: 'HOUR' | 'DAY') => void

    onDateChange?: (v: Required<CompareDates>) => void

    onCompareDateChange?: (v: CompareDates) => void
}
export const Condition = <B extends boolean = false>(props: ConditionProps<B>) => {
    const {
        options,
        multiple,
        defaultCondition = [],
        onConditionChange,
        optionalCompare,
        onDateTypeChange: propOnDateTypeChange,
        onDateChange,
        onCompareDateChange
    } = props

    const [indicator, setIndicator] = useState<string[]>(defaultCondition)

    const [dateType, setDateType] = useState<DateType>(DATE_TYPE.last7)

    const [compare, setCompare] = useState(false)

    const [compareDates, setCompareDates] = useState<Required<CompareDates>>(() => {
        const start = subDays(new Date(), 7)
        resetTime2Zero(start)
        const end = subDays(new Date(), 1)
        resetTime2Last(end)
        return { start, end }
    })
    const [compareToDates, setCompareToDates] = useState<CompareDates>({})

    const onDateTypeChange = useCallback(
        (v: DateType) => {
            propOnDateTypeChange?.(IS_DATE_RANGE.has(v) ? 'DAY' : 'HOUR')
            switch (v) {
                case DATE_TYPE.yesterday: {
                    const start = subDays(new Date(), 1)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setCompareDates({
                        start,
                        end
                    })

                    onDateChange?.({ start, end })
                    break
                }

                case DATE_TYPE.last7: {
                    const start = subDays(new Date(), 7)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setCompareDates({ start, end })

                    onDateChange?.({ start, end })
                    break
                }

                case DATE_TYPE.last30: {
                    const start = subDays(new Date(), 30)
                    resetTime2Zero(start)
                    const end = subDays(new Date(), 1)
                    resetTime2Last(end)
                    setCompareDates({ start, end })

                    onDateChange?.({ start, end })
                    break
                }

                case DATE_TYPE.day: {
                    const end = new Date(compareDates.start)
                    resetTime2Last(end)
                    setCompareDates({ start: compareDates.start, end })

                    onDateChange?.({ start: compareDates.start, end })
                    break
                }

                default: {
                    break
                }
            }

            setDateType(v)
        },
        [compareDates.start, onDateChange, propOnDateTypeChange]
    )

    return (
        <>
            <Flex mb={20} gap="40px" alignItems="baseline">
                <Subtitle>数据指标</Subtitle>
                <Flex gap="16px" wrap="wrap">
                    {options.map(item => (
                        <Button
                            key={item.value || 'all'}
                            type="text"
                            style={
                                (item.value && indicator.includes(item.value)) || (!item.value && indicator.length === 0)
                                    ? { color: 'var(--color-main)', backgroundColor: 'var(--color-main-tint)' }
                                    : {}
                            }
                            size="lg"
                            radius="100px"
                            onClick={() => {
                                if (multiple) {
                                    let arr: string[] = []
                                    // 点击全部
                                    if (!item.value) {
                                        if (indicator.length === 0) {
                                            return
                                        }
                                        arr = []
                                    } else if (indicator.includes(item.value)) {
                                        arr = indicator.filter(v => v !== item.value)
                                    } else {
                                        arr = [...indicator, item.value]
                                    }
                                    setIndicator(arr)
                                    onConditionChange?.(arr)
                                } else {
                                    setIndicator([item.value as string])
                                    onConditionChange?.(item.value as string)
                                }
                            }}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Flex>
            </Flex>

            <Flex mb={20} gap="40px" alignItems="baseline">
                <Subtitle>数据时间</Subtitle>
                <Flex gap="12px">
                    <Select
                        style={{ width: 116 }}
                        options={DATE_COMPARE_OPTIONS}
                        value={dateType}
                        onChange={v => onDateTypeChange(v as DateType)}
                    />
                    {IS_DATE_PICKER.has(dateType) ? (
                        <>
                            <DatePicker
                                showTime={false}
                                disableInput
                                prefix={null}
                                style={{ width: 116 }}
                                format="yyyy-MM-dd"
                                value={compareDates.start}
                                clearable={false}
                                onChange={v => {
                                    if (!v) {
                                        return
                                    }

                                    const start = new Date(v)
                                    resetTime2Zero(start)
                                    const end = dateType === DATE_TYPE.dayRange ? compareDates.end : new Date(v)
                                    resetTime2Last(end)
                                    setCompareDates({ start, end })
                                    onDateChange?.({
                                        start: v,
                                        end
                                    })
                                }}
                            />
                            {dateType === DATE_TYPE.dayRange ? (
                                <DatePicker
                                    showTime={false}
                                    disableInput
                                    prefix={null}
                                    style={{ width: 116 }}
                                    format="yyyy-MM-dd"
                                    value={compareDates.end}
                                    onChange={v => {
                                        if (!v) {
                                            return
                                        }
                                        setCompareDates(s => ({ ...s, end: v }))
                                        onDateChange?.({ ...compareDates, end: v })
                                    }}
                                />
                            ) : null}
                        </>
                    ) : null}

                    {optionalCompare && (
                        <Checkbox
                            label="对比"
                            size="xs"
                            style={{ fontSize: 14 }}
                            checked={compare}
                            onChange={e => {
                                const { checked } = e.target
                                setCompare(checked)
                                if (!checked) {
                                    setCompareToDates({})
                                    onCompareDateChange?.({})
                                }
                            }}
                        />
                    )}

                    {compare && (
                        <>
                            <DatePicker
                                showTime={false}
                                disableInput
                                prefix={null}
                                format="yyyy-MM-dd"
                                style={{ width: 116 }}
                                value={compareToDates.start}
                                onChange={v => {
                                    const start = v && new Date(v)
                                    start && resetTime2Zero(start)
                                    const end = IS_DATE_RANGE.has(dateType) ? compareToDates.end : v && new Date(v)
                                    end && resetTime2Last(end)
                                    setCompareToDates({
                                        start,
                                        end
                                    })
                                    onCompareDateChange?.({
                                        start,
                                        end
                                    })
                                }}
                            />
                            {IS_DATE_RANGE.has(dateType) ? (
                                <DatePicker
                                    showTime={false}
                                    disableInput
                                    prefix={null}
                                    format="yyyy-MM-dd"
                                    style={{ width: 116 }}
                                    value={compareToDates.end}
                                    onChange={v => {
                                        const end = v && new Date(v)
                                        end && resetTime2Last(end)
                                        setCompareToDates(s => ({ ...s, end }))
                                        onCompareDateChange?.({
                                            ...compareToDates,
                                            end
                                        })
                                    }}
                                />
                            ) : null}
                        </>
                    )}
                </Flex>
            </Flex>
        </>
    )
}
