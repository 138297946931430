import { Checkbox } from '@byecode/ui'
import type { NavigationLogo } from '@lighthouse/core'
import {} from '@mantine/core'
import Uploady from '@rpldy/uploady'
import React, { useMemo } from 'react'

import { uploadManagerInAppParams } from '@/utils/auth'

import SettingBlock from '../../SettingBlock'
import SettingLogo from '../../SettingLogo'
import * as CM from '../../styles'
import { jumpLinkOptions } from '../constant'
import NavBarItem from '../NavBarItem'
import * as SC from './styles'

interface NavBarLogoProps {
    value: NavigationLogo
    onChange?: (value: NavigationLogo) => void
}
const NavBarLogo = React.forwardRef<HTMLDivElement, NavBarLogoProps>(({ value, onChange }, ref) => {
    const renderLogo = useMemo(() => {
        if (value.logoType === 1) {
            return (
                <SC.UploadImgContain>
                    <div>
                        <Uploady accept="image/*" {...uploadManagerInAppParams()}>
                            <SettingLogo
                                value={value?.imgUrl?.square}
                                defaultIcon={<CM.Icon size={24} type="BlockImage" color="var(--color-gray-400)" />}
                                onChange={url => onChange?.({ ...value, imgUrl: { square: url } })}
                                style={{ width: 70, height: 70 }}
                            />
                        </Uploady>
                    </div>
                    <SC.UploadImgDesc>
                        <li>请上传 logo</li>
                        <li>图片大小不能超过 2mb</li>
                        <li>支持 jpg、jpeg、png、svg 格式</li>
                    </SC.UploadImgDesc>
                </SC.UploadImgContain>
            )
        }
        return <></>
    }, [onChange, value])
    return (
        <SettingBlock label="站点Logo" disableCollapse>
            <SC.Container ref={ref}>
                <SC.Content>
                    <SC.RadioGroup>
                        <SC.LogoCheckBox onClick={() => onChange?.({ ...value, logoType: 1 })}>
                            <Checkbox
                                label="自定义"
                                value="1"
                                color="var(--color-main)"
                                checked={value?.logoType === 1}
                                style={{ width: '100%' }}
                            />
                        </SC.LogoCheckBox>
                        <SC.LogoCheckBox onClick={() => onChange?.({ ...value, logoType: 0 })}>
                            <Checkbox
                                label="沿用应用图标和名称"
                                value="0"
                                color="var(--color-main)"
                                checked={value?.logoType === 0}
                                style={{ width: '100%' }}
                            />
                        </SC.LogoCheckBox>
                    </SC.RadioGroup>
                    {renderLogo}
                </SC.Content>
                <SC.Footer>
                    <NavBarItem
                        id=""
                        jumpOptions={jumpLinkOptions}
                        disableName
                        disableDrag
                        disableIcon
                        data={{ ...value, id: '' }}
                        onChange={(id, val) => onChange?.({ ...value, ...val })}
                    />
                </SC.Footer>
            </SC.Container>
        </SettingBlock>
    )
})
export default NavBarLogo
