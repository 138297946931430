import { IconFont } from '@byecode/ui/components/IconFont'
import { Popover } from '@byecode/ui/components/Popover'
import Carousel from 'nuka-carousel'
import React from 'react'

import * as SC from './styles'

interface GuideStepProps {
    images: string[]
    describe: string
    titleStyle?: React.CSSProperties
    title: string
    confirmText?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const GuideStep: React.FunctionComponent<GuideStepProps> = ({ images, describe, titleStyle, title, confirmText, onClick }) => {
    return (
        <SC.TooltipContainer>
            <SC.Content>
                {images.length > 1 ? (
                    <Carousel
                        autoplay
                        autoplayInterval={2000}
                        tabbed
                        defaultControlsConfig={{
                            nextButtonText: <IconFont size={16} type="ArrowRightSmall" color="#fff" />,
                            prevButtonText: <IconFont size={16} type="ArrowLeftSmall" color="#fff" />,
                            pagingDotsClassName: 'dot',
                            pagingDotsContainerClassName: 'dotContainer'
                        }}
                    >
                        {images.map(url => (
                            <SC.PreviewImg key={url} src={url} />
                        ))}
                    </Carousel>
                ) : (
                    <SC.PreviewImg src={images[0]} />
                )}

                <SC.Text size={16} style={titleStyle}>
                    {title}
                </SC.Text>
                <SC.Text size={14} style={{ lineHeight: '22px', padding: '0 4px' }}>
                    {describe}
                </SC.Text>
            </SC.Content>
            {confirmText && (
                <SC.Footer>
                    <SC.FooterLeftFill />
                    <SC.FooterRightFill>
                        <SC.Button color="var(--color-main)" backgroundColor="#fff" onClick={onClick}>
                            {confirmText}
                        </SC.Button>
                    </SC.FooterRightFill>
                </SC.Footer>
            )}
        </SC.TooltipContainer>
    )
}
