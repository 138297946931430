import { backgroundTransitionOnClick, IconFont, pointer } from '@byecode/ui'
import { Input } from '@mantine/core'
import styled, { css } from 'styled-components'

export const ComponentPanelContainer = styled.aside`
    height: 100%;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    /* border-right: 1px solid var(--color-gray-200); */
    background-color: var(--color-white);
`

export const Header = styled.div`
    width: 240px;
    display: flex;
    flex-direction: column;
    margin: 12px;
    margin-bottom: 0;
`

export const Icon = styled(IconFont)<{ size?: number; color?: string; isAction?: boolean; hoverColor?: string; isUsed?: boolean }>`
    font-size: ${({ size }) => size ?? 16}px;
    /* transition: all 0.3s ease-in-out; */
    ${({ isAction }) => isAction && `${backgroundTransitionOnClick}`}
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    flex-shrink: 0;

    ${pointer}
    ${({ isUsed }) =>
        isUsed &&
        css`
            color: var(--color-black);
            background-color: var(--color-gray-100);
            border-radius: 3px;
        `}
`

export const SquareIcon = styled(IconFont)`
    width: 20px;
    height: 20px;
`

export const LineGuide = styled.div``

export const PanelCon = styled.div`
    width: 100%;
    height: 100%;
`

export const Chunk = styled.div`
    width: 256px;
    height: 100%;
    z-index: 0;
    background-color: var(--color-white);
    /* margin-right: 24px; */
`

export const SearchInput: typeof Input = styled(Input)`
    margin: 0 16px 4px 16px;
`

export const Text = styled.span<{ size?: number; color?: string }>`
    text-align: center;
    font-size: ${({ size }) => size}px;
    line-height: 22px;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    ${pointer}

    &.active {
        color: var(--color-black);
    }
`
export const Title = styled.div`
    margin: 9px 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
`

export const IconWrapper = styled.div<{ size?: number; radius?: number }>`
    /* padding: 0px 8px; */
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ radius }) => `${radius ?? 6}px`};
    flex-shrink: 0;
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
    cursor: pointer;
`
