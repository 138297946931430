import { IconFont } from '@byecode/ui'
import type { EnvFieldDiffUpdateItem } from '@lighthouse/shared'
import { FieldIconTypeMap } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { updateFieldConfigTypeText } from '../constant'
import { MergeGroup } from './MergeGroup'
import { UpdateItemContent } from './UpdateItemContent'

const SCxFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const SCxFieldTitle = styled.div`
    color: var(--color-gray-700);
    font-size: var(--font-size-normal);
`

const SCxUpdateFieldGroup = styled.div`
    padding-left: 20px;
    /* height: 36px; */
    border-color: var(--color-gray-200);
    border-style: solid;
    border-width: 0 1px 1px 1px;
`

interface UpdateFieldListProps {
    data: EnvFieldDiffUpdateItem
}

export const UpdateFieldList: React.FC<UpdateFieldListProps> = ({ data }) => {
    const fieldIcon = FieldIconTypeMap[data.fieldType]

    return (
        <MergeGroup
            level={2}
            enableBorder
            label={
                <SCxFieldWrapper>
                    <IconFont type={fieldIcon} fill="var(--color-gray-400)" />
                    <SCxFieldTitle>{data.fieldName}</SCxFieldTitle>
                </SCxFieldWrapper>
            }
        >
            {data.updates.map((item, index) => (
                <SCxUpdateFieldGroup key={index}>
                    <UpdateItemContent title={updateFieldConfigTypeText[item.updateType]} theme="gray" from={item.from} to={item.to} />
                </SCxUpdateFieldGroup>
            ))}
        </MergeGroup>
    )
}
