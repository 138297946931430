import { FieldGroupBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { FieldGroupBlockAbstract } from '@lighthouse/core'
import { getViewColumns, useAtomAction, useAtomData, useFormModuleContext } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'

import { addAiFieldStatusAtom } from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom } from '@/atoms/dataSource/state'
import { pageAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { usePageCurrentDsFirstRecord } from '@/hooks/usePageCurrentDsAndRecordByBlock'
import * as srv from '@/services'

interface FieldGroupBlockControllerProps {
    blockData: FieldGroupBlockAbstract
}

const FieldGroupBlockController: React.FunctionComponent<FieldGroupBlockControllerProps> = ({ blockData }) => {
    const { config } = blockData
    const { viewFields } = config

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const rootPageId = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const { pointer: viewPointer, record: viewRecord } = useCustomViewBlockContext()
    const stackId = useCurrentStackIdContext()
    const formModule = useFormModuleContext()
    const pageDsId = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.dsId ?? '', [])
    )
    const pointer = viewPointer || formModule?.pointer || pageDsId
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const { record } = usePageCurrentDsFirstRecord({ pointer, envId, rootPageId, pageId, stackId, appId, fieldPointer: '' })
    const dataSource = useDataSource(appId, envId, pointer)

    const currentRecord = viewRecord || record
    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const id = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: pointer, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id,
                    dataSourceId: pointer,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, pointer]
    )

    const currentColumns = useMemo(
        () =>
            getViewColumns({
                tableProps: dataSource?.viewOptions.tableProps ?? [],
                value: viewFields,
                schema: dataSource?.schema ?? {}
            }).filter(item => item.type !== 'notes'),
        [dataSource?.schema, dataSource?.viewOptions.tableProps, viewFields]
    )

    return (
        <FieldGroupBlock
            dsId={pointer}
            blockData={blockData}
            dataSource={dataSource}
            columns={currentColumns}
            record={currentRecord}
            aiFieldStatusListAtom={aiFieldStatusListAtom}
            onAiGeneration={handleAiGeneration}
        />
    )
}

export default FieldGroupBlockController
