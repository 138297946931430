import * as React from 'react'

// import { Template } from '@/containers/Template'
import { ApplicationList } from '@/containers/ApplicationList'

interface ListProps {}

const List: React.FC<ListProps> = props => {
    return <ApplicationList />
}

export default List
