import type { FieldBlockWithDsId, UpstreamVariable, VariableADTvalue, ViewType } from '@lighthouse/core'
import { type DataSourceAbstract, VariableType } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { propertyFilter, USER_DATASOURCE } from '../../constants'
import { type VariableSource, VariableSourceType } from '../../types'
import { getNodeDataSourceId, getRealField } from '../../utils'
import {
    DEFAULT_FILTER_VALUE_VARIABLE,
    getDefaultValueOptionsByInnerType,
    getInputOption,
    getUserDatasourceOptions,
    getViewOptions,
    VariableSelect
} from '../'
import * as SC from './styles'

interface FieldVariableProps {
    sources?: VariableSource[]
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList: DataSourceAbstract[]
    viewDsId?: string
    viewType?: ViewType
    prefixName: string
    disabled?: boolean
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

export const FieldVariable: React.FC<FieldVariableProps> = ({
    sources,
    fieldBlocksWithDsId = [],
    dataSourceList = [],
    viewType,
    viewDsId,
    prefixName,
    disabled,
    onMouseEnter,
    onMouseLeave
}) => {
    const { control, setValue } = useFormContext()
    const options = useMemo(() => {
        return getDefaultValueOptionsByInnerType({
            sources: sources || []
        })
    }, [sources])

    const sourceFieldFlowNode = useMemo(() => {
        if (!sources) {
            return undefined
        }
        const source = sources.find(item => item.sourceType === VariableSourceType.parentNode)
        if (source?.sourceType === VariableSourceType.parentNode) {
            return source.parentNodes
        }
        return undefined
    }, [sources])

    const inputOption = useMemo(() => {
        if (fieldBlocksWithDsId.length === 0) {
            return
        }
        return getInputOption(fieldBlocksWithDsId, {
            onMouseEnter,
            onMouseLeave
        })
    }, [fieldBlocksWithDsId, onMouseEnter, onMouseLeave])

    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptions({
            dataSource: userDataSource
        })
    }, [dataSourceList])

    const viewOption = useMemo(() => {
        const viewDataSource = dataSourceList.find(item => item.id === viewDsId)
        return getViewOptions({
            dataSource: viewDataSource,
            viewType
        })
    }, [dataSourceList, viewDsId, viewType])

    const handleSetInitCondition = useCallback(
        (dsId: string, fieldId: string) => {
            const dataSource = find(item => item.id === dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            if (!field) {
                return
            }
            const realField = getRealField(field)
            const op = propertyFilter[realField?.innerType || 'NULL']

            // const op = propertyFilter[field.innerType || 'NULL']
            // return op[0].value
            setValue(`${prefixName}.operator`, op[0]?.value)
            setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
        },
        [dataSourceList, prefixName, setValue]
    )

    const handleUpstreamOperator = useCallback(
        (val: UpstreamVariable) => {
            const { nodeId, fieldId } = val.upstreamVariable || {}
            if (!nodeId || !fieldId) {
                return
            }
            const node = find(item => item.id === nodeId, sourceFieldFlowNode || [])
            if (!node) {
                return
            }
            const dataSourceId = getNodeDataSourceId(node, sourceFieldFlowNode || [])
            const dataSource = find(item => item.id === dataSourceId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            if (!field) {
                return
            }
            const realField = getRealField(field)
            const op = propertyFilter[realField?.innerType || 'NULL']
            // return op[0].value
            setValue(`${prefixName}.operator`, op[0]?.value)
            setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
        },
        [dataSourceList, prefixName, setValue, sourceFieldFlowNode]
    )

    const handleOperator = useCallback(
        (val: VariableADTvalue) => {
            if (val.type === VariableType.SYSTEM) {
                setValue(`${prefixName}.operator`, propertyFilter.ARRAY[0].value)
                setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
                return
            }

            if (val.type === VariableType.USER) {
                const { fieldId = '' } = val.userVariable || {}
                const dsId = 'USER_DATASOURCE'
                handleSetInitCondition(dsId, fieldId)
                return
            }

            if (val.type === VariableType.INPUT) {
                const { blockId = '' } = val.inputVariable || {}
                const fieldBlock = find(item => item.id === blockId, fieldBlocksWithDsId)
                const dsId = fieldBlock?.dsId
                const fieldId = fieldBlock?.config?.fieldPointer
                if (!dsId || !fieldId) {
                    return
                }
                handleSetInitCondition(dsId, fieldId)
            }

            if (val.type === VariableType.UPSTREAM) {
                handleUpstreamOperator(val)
            }

            if (val.type !== VariableType.VARIABLE) {
                return
            }

            const { dsId, fieldId } = val.fieldVariable || {}
            if (!dsId || !fieldId) {
                return
            }
            handleSetInitCondition(dsId, fieldId)
        },
        [fieldBlocksWithDsId, handleSetInitCondition, handleUpstreamOperator, prefixName, setValue]
    )

    return (
        <Controller
            name={`${prefixName}.idVariable`}
            control={control}
            render={({ field: ctlField }) => {
                // const { dsId, fieldId } = ctlField.value
                // const ds = find(item => item.id === dsId, dataSourceList)
                // const field = ds?.schema[fieldId]
                return (
                    <SC.Box key={`${prefixName}.idVariable`}>
                        <VariableSelect
                            // field={field}
                            style={{ flex: 1 }}
                            options={options}
                            value={ctlField.value}
                            disableInput
                            inputOption={inputOption}
                            viewOption={viewOption}
                            userOption={userOption}
                            disabled={disabled}
                            width="100%"
                            onChange={val => {
                                ctlField.onChange(val)
                                handleOperator(val)
                                onMouseLeave?.()
                            }}
                        />
                    </SC.Box>
                )
            }}
        />
    )
}
