import { IconFont, pointer } from '@byecode/ui'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const NavContainer = styled.div`
    width: 300px;
    padding: 15px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`

export const MenuList = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 12px;
    display: flex;
    flex-flow: column;
    align-items: center;
`

export const MenuItem = styled(NavLink)`
    width: 100%;
    height: 40px;
    margin: 2px 0;
    padding: 0 12px;
    border-radius: 8px;
    display: flex;
    color: var(--color-gray-500);
    justify-content: space-between;
    align-items: center;

    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }

    &.active {
        color: var(--color-main);
        background-color: var(--color-theme-3);
    }
`

export const MenuItemContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`

export const MenuItemIcon = styled(IconFont)`
    width: 16px;
`

export const MenuItemText = styled.div`
    flex: 1;
    white-space: nowrap;
    font-size: 14px;
    padding-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const MenuItemExtra = styled.div`
    font-size: 12px;
    width: fit-content;
    color: var(--color-gray-500);
`

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-white);
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-white);
        border-radius: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
        background-color: var(--color-main);
    }
    scrollbar-width: whin;
`
