import type { AggregatorNode, FlowEdge } from '@lighthouse/shared'
import { TitleInContentModal } from '@lighthouse/shared'
import React from 'react'
import type { Edge } from 'reactflow'
import styled from 'styled-components'

import { AggregatorConfigure } from '../AggregatorConfigure'

const SCxSpace = styled.div`
    height: 30px;
`

const SCxBody = styled.div`
    padding: 0 16px;
`

export interface AggregatorModalProps {
    open: boolean
    dsId: string
    node: AggregatorNode | null
    onChange: (nodes: AggregatorNode[], edges: Edge[], newNode: AggregatorNode) => void
    onClose?: () => void
}

export const AggregatorModal: React.FC<AggregatorModalProps> = ({ open, dsId, node, onChange, onClose }) => {
    return (
        <TitleInContentModal
            open={open}
            withOverlay={false}
            styles={{
                desktop: {
                    root: {
                        alignItems: 'flex-start',
                        height: '360px',
                        width: '100%',
                        inset: 'auto 0 20px 0!important'
                    },
                    body: {
                        position: 'relative',
                        zIndex: 2,
                        overflow: 'visible'
                    },
                    actions: {
                        zIndex: '3!important'
                    },

                    // body: {
                    //     overflow: 'visible'
                    // },
                    // close: {
                    //     zIndex: 1
                    // },
                    modal: {
                        width: 'calc(100% - 20px)!important',
                        height: '100%'
                    }
                }
            }}
            onClose={onClose}
        >
            <SCxSpace />
            <SCxBody>{node && <AggregatorConfigure key={node.id} dsId={dsId} node={node} onChange={onChange} />}</SCxBody>
        </TitleInContentModal>
    )
}
