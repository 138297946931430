import { Button } from '@byecode/ui/components/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Text } from '@byecode/ui/components/Text'
import type { spaceVersionEnum } from '@lighthouse/shared'
import { getBrandInfo, spaceGradeCodeMap, spaceGradeNameMap } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useSpaceQuota } from '@/shared/reusable'

import { openSpaceGrade } from './event'

interface VersionOverlayProps {
    spaceVersion: number
    children?: React.ReactNode
}

const SCxContainer = styled.div`
    position: relative;
    &.mask {
        &::before {
            position: absolute;
            inset: 0;
            content: '';
            background: linear-gradient(181deg, rgba(255, 255, 255, 0.42) 33.02%, rgba(255, 255, 255, 0.05) 96.17%);
            backdrop-filter: blur(1.5px);
            border-radius: 16px;
            z-index: 10;
        }
    }
`

const SCxDesc = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 10;
`

export const VersionOverlay: React.FunctionComponent<VersionOverlayProps> = ({ spaceVersion, children }) => {
    const { data } = useSpaceQuota()

    const isShowMask = (data?.currentVersionCode ?? 0) < spaceVersion

    const name = useMemo(() => {
        const INFO = getBrandInfo()
        const version = INFO.space.gradeVersions[spaceVersion]
        return spaceGradeNameMap[version]
    }, [spaceVersion])

    return (
        <SCxContainer className={cls({ mask: isShowMask })}>
            {children}
            {isShowMask && (
                <SCxDesc>
                    <Text size={20} color="var(--color-gray-600)" style={{ lineHeight: '28px' }}>
                        示例数据
                    </Text>
                    <Button
                        type="primary"
                        radius={8}
                        style={{ width: 100 }}
                        icon={<IconFont type="CrownStar" size={16} color="#fff" />}
                        onClick={() => openSpaceGrade('version_upgrade')}
                    >
                        {name}
                    </Button>
                </SCxDesc>
            )}
        </SCxContainer>
    )
}
