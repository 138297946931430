import type { QrBarcodeBlockConfig } from '@lighthouse/core';
import { QrBarcodeEnum } from '@lighthouse/core';

/**
 * 生成图片block的配置
 *
 * @returns {QrBarcodeBlockConfig}
 */
export const generateQrBarcodeBlock = (): QrBarcodeBlockConfig => {
    return {
       codeType: QrBarcodeEnum.QR_CODE,
       content: { value: undefined },
       barWidth: 1
    }
}
