import type { PopoverProps } from '@byecode/ui'
import { Popover } from '@byecode/ui'
import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

interface PopoverButtonProps extends Omit<PopoverProps, 'children'> {
    children?: ReactNode
    popupContent: ReactNode
}

const SCxContainer = styled.div`
    margin-top: 12px;
`

/** 可弹出额外内容的配置项单元 */
export const PopoverButton = React.forwardRef<HTMLDivElement, PopoverButtonProps>(({ popupContent, children, ...rest }, ref) => {
    return (
        <Popover {...rest}>
            <Popover.Target>
                <SCxContainer ref={ref}>{children}</SCxContainer>
            </Popover.Target>
            <Popover.Dropdown>{popupContent}</Popover.Dropdown>
        </Popover>
    )
})
