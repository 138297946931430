import { Empty, Group, Loading } from '@byecode/ui'
import type { FindUseLocationConfig } from '@lighthouse/shared'
import { type FindUseADTObject, FindUseLocationText } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import * as srv from '@/services'

import { FindUseLocationList } from './FindUseLocationList'

const SCxContainer = styled.div`
    padding: 12px 0;
    flex: 1;
    overflow: hidden;
`

const SCxContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const SCxHeader = styled.div`
    padding: 0 16px;
    height: 32px;
    line-height: 32px;
    color: var(--color-gray-500);
    font-size: 14px;
`

const SCxList = styled.div`
    flex: 1;
    overflow: auto;
`

const SCxSpace = styled.div`
    height: 64px;
`

interface FindUseLocationProps {
    config: FindUseADTObject
}

export const FindUseLocation: React.FC<FindUseLocationProps> = ({ config }) => {
    const { data, mutate, isLoading } = useSWR(
        ['findUses', config],
        () => {
            return srv.getFindUses(config)
        },
        {
            revalidateOnFocus: false
        }
    )

    const listData = useMemo(() => {
        return Object.entries(data || {}).reduce<[string, FindUseLocationConfig[]][]>((prev, cur) => {
            const [_, arr] = cur
            if (arr.length > 0) {
                prev.push(cur)
            }
            return prev
        }, [])
    }, [data])

    const content = useMemo(() => {
        if (isLoading) {
            return <Loading />
        }

        return (
            <SCxContent>
                <SCxHeader>使用位置</SCxHeader>
                <SCxList>
                    {listData.map(([key, item]) => {
                        if (item.length === 0) {
                            return null
                        }
                        return (
                            <Group label={FindUseLocationText[key]} key={key} styles={{ root: { padding: 0 } }}>
                                <FindUseLocationList config={config} data={item} />
                            </Group>
                        )
                    })}
                    <SCxSpace />
                </SCxList>
            </SCxContent>
        )
    }, [config, isLoading, listData])

    return <SCxContainer>{content}</SCxContainer>
}
