import type { StatusFile } from '@lighthouse/shared'
import type { ModalProps } from '@mantine/core'
import { Modal } from '@mantine/core'
import * as React from 'react'

import { Template } from '..'

interface TemplateModalProps extends ModalProps {
    isBlankTemplate?: boolean
    onCreateBlankApp?: (id: string) => void
    onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
}

const TemplateModal: React.FunctionComponent<TemplateModalProps> = ({
    isBlankTemplate,
    onCreateBlankApp,
    onChangeFileStatus,
    onClose,
    ...rest
}) => {
    return (
        <Modal
            {...rest}
            onClose={onClose}
            size={1100}
            centered
            padding={0}
            withCloseButton={false}
            styles={{
                modal: {
                    border: '1px solid #DFDFDD',
                    overflow: 'hidden',
                    borderRadius: 12,
                    margin: 0
                },
                body: {
                    boxShadow: 'var(--box-shadow)',
                    height: '90vh'
                }
            }}
        >
            <Template
                onCreateBlankApp={onCreateBlankApp}
                onClose={onClose}
                mode="modal"
                isBlankTemplate={isBlankTemplate}
                onChangeFileStatus={onChangeFileStatus}
            />
        </Modal>
    )
}

export default TemplateModal
