import { BlockType } from '@lighthouse/core'
import { Flex, ScrollArea } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

import { BlockCard } from './BlockCardList'
import { ModuleCard } from './ModuleList'

interface FormCardProps {
    items: BlockTreeItem[]
    onCreate: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow: hidden;
`
const SCxList = styled(Flex)``

const FormCard: React.FunctionComponent<FormCardProps> = ({ items, onCreate }) => {
    return (
        <SCxContainer>
            <ScrollArea scrollbarSize={0}>
                <SCxList wrap="wrap" gap={12}>
                    {items.map(item => {
                        if (item.type === BlockType.formContainer) {
                            return <ModuleCard data={item} key={item.name} onCreate={onCreate} />
                        }
                        return <BlockCard data={item} key={item.name} onCreate={onCreate} />
                    })}
                </SCxList>
            </ScrollArea>

            {/* <Button type="primary" icon={<CM.Icon type="AddDataTable" color="var(--color-white)" />}>
                前往添加数据表
            </Button> */}
        </SCxContainer>
    )
}

export default FormCard
