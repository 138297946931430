import { Toast } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { ImageOptions, ImageSwiperItem, PageAbstract } from '@lighthouse/core'
import type { UseUploadFileSParameter } from '@lighthouse/shared'
import {
    avatarMaxFileSize,
    getFileSizeToMB,
    getFileTypeByUrl,
    LinkCard,
    UploadDropZone,
    useAtomData,
    useUploadBatchFinished
} from '@lighthouse/shared'
import React, { useCallback, useId, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { uploadManagerInAppParams } from '@/utils/auth'

import { maxFileNum } from '.'

interface ImageUploadListProps {
    blockId: string
    disabled?: boolean
    disableJump?: boolean
    allPages?: PageAbstract[]
}

const StyledDropZone = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    color: var(--color-gray-500);
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
    cursor: pointer;
`

const SCxEmptyContainer = styled.div`
    width: 100%;
`

const SCxContainer = styled.div`
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const SwiperUploader: React.FC<{
    title: string
    disabled: boolean
    value: ImageSwiperItem[]
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onChange: (value: ImageSwiperItem[]) => void
}> = ({ title, disabled, uploadOptions, onChange }) => {
    const uploadDropId = useId()

    useUploadBatchFinished(uploadDropId, batch => {
        const newItems = batch.items
            .filter(item => item.state !== 'aborted')
            .map(item => {
                const {
                    file: { name, size },
                    uploadResponse: {
                        data: {
                            content: { url }
                        }
                    }
                } = item

                return { name, url, size, type: getFileTypeByUrl(name), title: '', link: '' }
            })
        onChange?.(newItems)
    })

    return (
        <UploadDropZone
            accept="image/*"
            multiple
            disabled={disabled}
            uploadOptions={{
                ...uploadOptions,
                info: { ...uploadOptions.info, id: uploadDropId }
            }}
        >
            <StyledDropZone>{title}</StyledDropZone>
        </UploadDropZone>
    )
}

const ImageUploadList: React.FunctionComponent<ImageUploadListProps> = ({ blockId, allPages = [], disableJump, disabled }) => {
    const { control } = useFormContext<ImageOptions>()
    const { fields, move, remove, append } = useFieldArray({
        control,
        name: 'sources'
    })

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const [_, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )

    // TODO: @kidrue id后续处理掉 不需要此参数
    const uploadInfo = useMemo(() => ({ id: '', label: pageName, groupId: pageId }), [pageId, pageName])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.id === active.id)
                const newIndex = fields.findIndex(item => item.id === over?.id)
                move?.(oldIndex, newIndex)
            }
        },
        [fields, move]
    )
    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <SortableContext disabled={disabled} items={fields.map(d => d.id ?? '')} strategy={verticalListSortingStrategy}>
                    {fields.map((field, index) => (
                        <Controller
                            control={control}
                            key={field.id}
                            name={`sources.${index}`}
                            render={({ field: inputField }) => {
                                return (
                                    <LinkCard
                                        value={inputField.value}
                                        onChange={inputField.onChange}
                                        id={field.id ?? ''}
                                        disabled={disabled}
                                        disableJump={disableJump}
                                        uploadOptions={{
                                            info: uploadInfo,
                                            options: {
                                                ...uploadManagerInAppParams({ pageId, blockId }),
                                                fileFilter: (file, index) => {
                                                    if (file instanceof File) {
                                                        if (file.size > avatarMaxFileSize) {
                                                            Toast.error(`不能上传大于 ${getFileSizeToMB(avatarMaxFileSize)}mb 的文件`)
                                                            return false
                                                        }
                                                        if (getFileTypeByUrl(file.name) !== 'image') {
                                                            Toast.error(` 图片格式错误，请上传 JPG/JPEG/PNG/SVG 格式的图片`)
                                                            return false
                                                        }
                                                        return index === 0
                                                    }
                                                    return true
                                                }
                                            }
                                        }}
                                        pageList={allPages}
                                        onDeleteClick={() => remove(index)}
                                    />
                                )
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <SCxEmptyContainer>
                {fields.length >= maxFileNum ? undefined : (
                    <SwiperUploader
                        title={`将文件拖到这里或点击上传 (${fields.length}/${maxFileNum})`}
                        disabled={disabled || fields.length >= maxFileNum}
                        value={fields}
                        uploadOptions={{
                            info: uploadInfo,
                            options: {
                                ...uploadManagerInAppParams({ pageId, blockId }),
                                fileFilter: (file, index) => {
                                    if (file instanceof File) {
                                        if (file.size > avatarMaxFileSize) {
                                            Toast.error(`不能上传大于 ${getFileSizeToMB(avatarMaxFileSize)}mb 的文件`)
                                            return false
                                        }
                                        if (getFileTypeByUrl(file.name) !== 'image') {
                                            Toast.error(` 图片格式错误，请上传 JPG/JPEG/PNG/SVG 格式的图片`)
                                            return false
                                        }

                                        return index + fields.length < maxFileNum
                                    }
                                    return true
                                }
                            }
                        }}
                        onChange={data => {
                            append([...data])
                        }}
                    />
                )}
            </SCxEmptyContainer>
        </SCxContainer>
    )
}

export default ImageUploadList
