import { IconFont, Loading, Message } from '@byecode/ui'
import { getBrand } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import useSWRMutation from 'swr/mutation'

import { openSaleSpaceGrade, openSpaceGrade } from '@/components/SpaceGrade'
import { useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'
import type { AppImportUser } from '@/services/types'

import { Finish } from './Finish'
import * as SC from './styles'
import { Upload } from './Upload'

interface UserImportModalProps {
    onFetchUsers: () => void
    onClose: () => void
}

export const UserImportModal: React.FC<UserImportModalProps> = ({ onFetchUsers, onClose }) => {
    const [importData, setImportData] = useState<AppImportUser>()
    const [step, setStep] = useState(0)
    const envId = useCurrentEnvId()
    const { data, isMutating, trigger, reset } = useSWRMutation('importAppUser', (_, { arg }: { arg: File }) => {
        return srv.importAppUser(arg, envId)
    })

    const recordLimitError = useMemo(
        () => (
            <>
                记录数达到上限
                <SC.UpgradeVersion
                    onClick={e => {
                        e.stopPropagation()
                        const brand = getBrand()
                        brand === 'I_CLICK' ? openSaleSpaceGrade() : openSpaceGrade('version_upgrade')
                    }}
                >
                    请升级版本
                </SC.UpgradeVersion>
            </>
        ),
        []
    )

    useUpdateEffect(() => {
        if (!data) {
            return
        }
        if (data.code === '0000000') {
            const { content } = data
            const { failCount, successCount } = content
            Message.success({
                title: '导入完成',
                description: `导入完成${successCount}人, 导入失败${failCount}人`
            })
            setImportData(content)
            setStep(1)
            onFetchUsers()
            return
        }
        Message.error({
            title: '导入失败',
            description: data.code === '0011040' ? recordLimitError : '模版格式错误'
        })
    }, [data])

    const content = useMemo(() => {
        if (isMutating) {
            return <Loading shape="indicator" description="正在导入" />
        }
        if (step) {
            return <Finish data={importData} onClose={onClose} />
        }
        return <Upload onImport={trigger} onClose={onClose} />
    }, [isMutating, step, trigger, onClose, importData])

    return (
        <SC.PopoverContainer>
            <SC.Close onClick={onClose}>
                <IconFont type="Close" size={20} fill="var(--color-gray-500)" />
            </SC.Close>
            {content}
        </SC.PopoverContainer>
    )
}
