import type { BlockAbstract, BlockType, ChartType, Direction, FieldInputType, ViewType } from '@lighthouse/core'

export enum BlockKind {
    text = 'text',
    view = 'view',
    layout = 'layout',
    button = 'button',
    chart = 'chart',
    file = 'file',
    field = 'field',
    other = 'other'
}

export type BlockTreeItem =
    | {
          type: Exclude<BlockType, 'container' | 'field' | 'view' | 'chart'> | 'combinationTitle' | 'highlight'
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'container'
          subType: Direction
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'field'
          subType: FieldInputType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'view'
          subType: ViewType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }
    | {
          type: 'chart'
          subType: ChartType
          name: string
          icon: string
          describeImage: string
          color: string
          backGroundColor: string
      }

export type DragNode = BlockTreeItem & { data: BlockAbstract[] }

export interface BlockTree {
    id: string
    name: string
    icon: string
    iconColor?: string
    color?: string
    items: BlockTreeItem[]
}

export interface BlockOption {
    id: string
    title: string
    children: BlockTree[]
}
