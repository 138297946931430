import { Button, IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxButtonContent = styled(Button)``

const SCxIcon = styled(IconFont)`
    margin-right: 6px;
`

const SCxContainer = styled.div``

interface AddButtonProps {
    onClick?: () => void
    buttonText?: string
}

export const AddButton: React.FC<AddButtonProps> = ({ buttonText = '添加', onClick }) => {
    return (
        <SCxContainer>
            <SCxButtonContent radius={100} onClick={onClick} icon={<SCxIcon type="Add" fill="var(--color-gray-500)" size={16} />}>
                {buttonText}
            </SCxButtonContent>
        </SCxContainer>
    )
}
