import { IconFont, Input } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Input placeholder="请输入" />
            <Input placeholder="带 Icon 的，没做完？？？" icon={<IconFont type="Add" />} />
            <Input placeholder="前缀" prefix={<IconFont type="User" />} />
            <Input placeholder="后缀" suffix={<IconFont type="PropertyNumber" />} />
        </Unit>
    )
}
