import { Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { DataSourceAbstract, SchemaProtocol, ViewField } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import type { AggregateResultField } from '@lighthouse/shared'
import { FilterGroup, getAppointField } from '@lighthouse/shared'
import type { TabsValue } from '@mantine/core'
import { Tabs } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'

import { INITIAL_DATASOURCE } from '@/atoms/dataSource/constants'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import * as SC from '../styles'
import { TabStyles } from '../styles'
import { TablePreview } from '../TablePreview'

const noDataDependenceImg = getAssetUrl('empty', 'no_data_dependence.png')
const noDataImperfectImg = getAssetUrl('empty', 'no_data_imperfect.png')

interface FilterProps {
    dsId: string
    nodeId: string
    fieldList: AggregateResultField[]
}

export const Filter: React.FC<FilterProps> = ({ dsId, nodeId, fieldList }) => {
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const dataSource: DataSourceAbstract = useMemo(
        () => ({
            ...INITIAL_DATASOURCE,
            id: nodeId,
            type: DataSourceType.aggregateDataSource,
            schema: Object.fromEntries(fieldList.map(cur => [cur.id, cur])) as SchemaProtocol['schema']
        }),
        [fieldList, nodeId]
    )

    const columns = fieldList.map(
        item =>
            ({
                ...item,
                title: item.name,
                fieldId: item.id
            })
    ) as ViewField[]

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    const [tab, setTab] = useState('config')

    const handleTabChange = useCallback((value: TabsValue) => {
        if (value) {
            setTab(value)
        }
    }, [])

    const isEmptyFieldList = useMemo(() => {
        return fieldList.length === 0
    }, [fieldList.length])

    const configureContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 315 }} src={noDataDependenceImg} alt="未找到数据" />}
                    description="请先完成依赖节点的配置"
                />
            )
        }
        return (
            <SC.FilterWrapper>
                <FilterGroup
                    primaryField={primaryField}
                    columns={columns}
                    dataSourceList={dataSourceList}
                    dataSource={dataSource}
                    prefixName="expression"
                />
            </SC.FilterWrapper>
        )
    }, [columns, dataSource, dataSourceList, isEmptyFieldList, primaryField])

    const previewContent = useMemo(() => {
        if (isEmptyFieldList) {
            return (
                <SC.Empty
                    icon={<img style={{ width: 202 }} src={noDataImperfectImg} alt="未找到数据" />}
                    description="预览数据前，请先完成节点配置"
                />
            )
        }
        return <TablePreview appId={appId} envId={envId} dsId={dsId} nodeId={nodeId} active={tab === 'preview'} />
    }, [appId, dsId, envId, isEmptyFieldList, nodeId, tab])

    return (
        <Tabs color="var(--color-main)" value={tab} onTabChange={handleTabChange} styles={TabStyles}>
            <Tabs.List>
                <Tabs.Tab value="config">
                    <SC.TabText isActive={tab === 'config'}>节点配置</SC.TabText>
                </Tabs.Tab>
                <Tabs.Tab value="preview" color="blue">
                    <SC.TabText isActive={tab === 'preview'}>
                        数据预览
                        <SC.TabTooltip>
                            <Tooltip title="预览前20条数据">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </SC.TabTooltip>
                    </SC.TabText>
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="config" pt="xs">
                <SC.PanelContent style={isEmptyFieldList ? {} : { paddingTop: 20 }}>{configureContent}</SC.PanelContent>
            </Tabs.Panel>

            <Tabs.Panel value="preview" pt="xs">
                <SC.PreviewContent>{previewContent}</SC.PreviewContent>
            </Tabs.Panel>
        </Tabs>
    )
}
