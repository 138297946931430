import { Button, IconFont, Input, Text } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import { ENV_DEV_COLOR, ENV_ICON, ENV_PROD_COLOR, ErrorMessage, getRandomColor, ThemeColorPicker } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { EnvTag } from '@/components/Environment/EnvTag'

const SCxContainer = styled.form`
    padding: 8px 32px 20px 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const SCxHeader = styled.div`
    margin-top: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
`

const SCxContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxLabel = styled(Text)`
    width: 100%;
    height: 24px;
    display: flex;
`

const SCxThemeColorWrapper = styled.div`
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    padding-left: 10px;
    gap: 12px;
    height: 34px;
    width: 100%;
    background-color: var(--color-gray-100);
    border-radius: 8px;
`

const SCxFooter = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const SCxSubmit = styled(Button)`
    margin-left: 10px;
    height: 36px;
    width: 80px;
`

const SCxCancel = styled(Button)`
    height: 36px;
    width: 80px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

type EnvFormState = Omit<Environment, 'envId'>

export interface EnvFormProps {
    // currentDev: Environment | undefined
    data: Environment | undefined
    envList: Environment[]
    onCreate?: (val: EnvFormState) => void
    onUpdate?: (val: Environment) => void
    onClose?: () => void
}

export const EnvForm: React.FC<EnvFormProps> = ({ data, envList, onCreate, onUpdate, onClose }) => {
    const isUpdate = !!data?.envId
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm<EnvFormState>({
        mode: 'onSubmit',
        defaultValues: isUpdate ? data : undefined
    })

    const name = watch('envName')

    const handleEnv = useCallback(
        (params: EnvFormState) => {
            if (data?.envId) {
                onUpdate?.({ envId: data.envId, ...params })
                return
            }
            onCreate?.(params)
        },
        [data?.envId, onCreate, onUpdate]
    )

    const handleOk = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(handleEnv)()
        },
        [handleEnv, handleSubmit]
    )

    return (
        <SCxContainer onSubmit={handleOk}>
            {!isUpdate && (
                <SCxHeader>
                    <EnvTag iconColor={ENV_PROD_COLOR} name="正式数据" icon={ENV_ICON} />
                    <IconFont type="ArrowRight" fill="var(--color-gray-400)" />
                    <EnvTag iconColor={ENV_DEV_COLOR} name={name || '开发数据'} icon={ENV_ICON} />
                </SCxHeader>
            )}
            <SCxContent>
                <SCxItem>
                    <SCxLabel>
                        标题
                        <span style={{ paddingLeft: 4, color: 'var(--color-red-500)' }}>*</span>
                    </SCxLabel>
                    <ErrorMessage name="envName" errors={errors}>
                        <Controller
                            name="envName"
                            control={control}
                            rules={{
                                required: '数据环境标题不能为空',
                                validate(val) {
                                    const repeatedEnv = envList.filter(({ envName, envId }, i) => {
                                        return envName === val && data?.envId !== envId
                                    })
                                    return repeatedEnv.length > 0 ? '数据环境标题不能重复' : true
                                }
                            }}
                            render={({ field: { value, onChange } }) => (
                                <Input value={value} size="lg" onChange={onChange} placeholder="请输入数据环境名称，不允许重复" />
                            )}
                        />
                    </ErrorMessage>
                </SCxItem>
                {/* <SCxItem>
                    <SCxLabel>环境颜色</SCxLabel>
                    <ErrorMessage name="theme" errors={errors}>
                        <SCxThemeColorWrapper>
                            <Controller
                                name="theme"
                                control={control}
                                rules={{
                                    required: '请选择环境颜色'
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <ThemeColorPicker size={18} withinPortal value={value} onChange={onChange} />
                                )}
                            />
                        </SCxThemeColorWrapper>
                    </ErrorMessage>
                </SCxItem> */}
                <SCxFooter>
                    <SCxSubmit htmlType="submit" type="primary">
                        {isUpdate ? '保存修改' : '创建'}
                    </SCxSubmit>
                    <SCxCancel onClick={onClose}>取消</SCxCancel>
                </SCxFooter>
            </SCxContent>
        </SCxContainer>
    )
}
