import { flex, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Contain = styled.div`
    /* width: 100%; */
    ${flex}
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    /* padding: 0 16px 4px 16px; */
`

export const BtnBox = styled.span`
    position: relative;
    display: inline-block;
    padding: 6px 2px;
    margin: 0 auto;
    text-align: center;
    background-color: var(--color-gray-100);
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    z-index: 2;
    ${pointer}
`

export const Btn = styled.span`
    padding: 5px 22px;
    border-radius: 5px;
    &.active {
        background-color: var(--color-white);
        transition: all 0.2s ease;
    }
`
export const Content = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const Header = styled.div`
    ${flex}
    align-items: center;
    justify-content: space-between;
    ${pointer}
`

export const Footer = styled.div``
