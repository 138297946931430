import { SegmentedControl } from '@byecode/ui'
import { Divider } from '@mantine/core'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { dataSourceAtom } from '../atoms'
import { CHART_TABS, ChartTabEnum } from '../constants'
import { ChartTypeSwitcher } from './ChartTypeSwitcher'
import { Data } from './Data'
import { DefaultDataSource } from './DataSource'
import { Design } from './Design'
import { IndicatorCardConfigurator } from './IndicatorCardConfigurator'

const SCxContainer = styled.div`
    padding: 12px 16px 0 16px;
`

const SCxPaddingBox = styled.div`
    padding: 0 16px;
    margin-top: 16px;
`

const SCxHeader = styled.div`
    font-weight: 600;
    padding: 6px 0;
`

interface ChartBlockSettingsProps {}

export const ChartBlockSettings: React.FC<ChartBlockSettingsProps> = () => {
    const [mode, setMode] = useState<string>(ChartTabEnum.data)
    const dataSource = useAtomValue(dataSourceAtom)
    const { control, setValue, getValues } = useFormContext()
    const chartType = useWatch({ control, name: 'chartType' })
    const setting = useMemo(() => {
        if (chartType === 'indicator') {
            return <IndicatorCardConfigurator />
        }

        if (mode === 'data') {
            return (
                <>
                    <DefaultDataSource />
                    {dataSource && <Data dataSource={dataSource} />}
                </>
            )
        }
        return <Design />
    }, [chartType, dataSource, mode])

    return (
        <>
            <SCxContainer>
                <SCxHeader>类型</SCxHeader>
                <Controller
                    control={control}
                    name="chartType"
                    render={({ field }) => {
                        return <ChartTypeSwitcher value={field.value} onChange={field.onChange} />
                    }}
                />
            </SCxContainer>

            <Divider my={12} color="var(--color-gray-200)" />
            {chartType !== 'indicator' && (
                <SCxPaddingBox>
                    <SegmentedControl data={CHART_TABS} fullWidth size="lg" value={mode} onChange={setMode} />
                </SCxPaddingBox>
            )}
            {setting}
        </>
    )
}
