import { backgroundTransitionOnClick, flex, Input, pointer, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

import { Icon } from '../../style'

export const TopBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Tag = styled.div`
    max-width: 100%;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
    border-radius: 3px;
    font-size: var(--font-size-sm);
    ${singleTextEllipsis}
`

export const DragContainer = styled.div``

export const RightItem = styled.div`
    flex-shrink: 0;
    ${flex}
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`

export const ListItem = styled.div`
    position: relative;
    width: 100%;
    padding: 8px 10px 8px 8px;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    z-index: 0;
    outline: none;
    color: var(--color-gray-700);
    ${pointer}

    &:hover ${Icon} {
        visibility: visible;
    }

    ${backgroundTransitionOnClick}

    &.active {
        background-color: var(--color-gray-100);
        color: var(--color-black);

        ${Tag} {
            color: var(--color-theme-5);
            background-color: var(--color-theme-3);
        }
    }

    .dotsThree {
        /* display: none; */
        visibility: hidden;
        order: -1;
    }

    .activeDots {
        order: 5;
        visibility: visible;
    }

    :hover {
        .dotsThree {
            order: 5;
            visibility: visible;
        }
    }
`

export const Name = styled.span`
    line-height: 20px;
    word-wrap: break-word;

    ${singleTextEllipsis}
`

export const LeftItem = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
    overflow: hidden;
`

export const TitleInput = styled.div`
    flex: 1;
    height: 20px;
    line-height: 20px;
    font-size: var(--font-size-normal);
    overflow: hidden;

    input {
        width: 100%;
    }
`

export const TipText = styled.div`
    height: 36px;
    line-height: 36px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`

export const AddGroupCon = styled.div`
    padding: 0px 16px;
`

export const Item = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: space-between;
    padding: 0 16px;
    ${pointer}
    :hover {
        background-color: var(--color-gray-100);
    }
    &.active {
        background-color: var(--color-gray-100);
    }
`

export const DsName = styled.div<{ color?: string }>`
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};

    /* 12/SC-Regular */
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    ${singleTextEllipsis}
`
