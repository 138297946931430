import type { MenuOpItem } from '@lighthouse/shared'

export const userMenuOpItems: MenuOpItem[] = [
    // {
    //     area: 'info'
    // },
    {
        area: 'setting',
        items: [
            // { name: '账号设置', iconfont: 'Setting', action: 'SETTING' },
            // { name: '退出登录', iconfont: 'SignOut', action: 'SIGNOUT' }
            { name: '账号设置', iconfont: '', action: 'SETTING' },
            { name: '退出登录', iconfont: '', action: 'SIGNOUT' }
        ]
    }
]
