import { flex, Input, singleTextEllipsis } from '@byecode/ui'
import styled from 'styled-components'

export const Contain = styled.div`
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    background-color: var(--color-white);
`

export const Header = styled.div`
    ${flex}
    height: 44px;
    padding: 8px 12px;
    justify-content: space-between;
    background-color: var(--color-gray-100);
`
export const Fill = styled.div`
    ${flex}
    justify-content: space-between;
    align-items: center;
`

export const Content = styled.div`
    border-top: 1px solid var(--color-gray-200);
    padding: 0 12px 12px 12px;
    & > * {
        margin-top: 12px;
    }
`

export const Title = styled.span`
    padding-left: 4px;
    line-height: 16px;
    color: var(--color-black);
    font-style: normal;
    font-weight: 400;
    ${singleTextEllipsis}
`

export const LeftFill = styled.div`
    min-width: 84px;
    flex-shrink: 0;
    ${flex}
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
`
export const RightFill = styled.div`
    width: 100%;
    padding-left: 12px;
    flex-shrink: 1;
    ${flex}
    justify-content: flex-end;
    gap: 16px;
`

export const TextInput: typeof Input = styled(Input)`
    width: 100%;
`
export const Handle = styled.div`
    /* padding: 4px; */
    ${flex}
    align-items: center;
`
