import { Checkbox } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { emailRegex, mobileRegex } from '@lighthouse/shared'
import Uploady from '@rpldy/uploady'
import { lightFormat } from 'date-fns'
import * as React from 'react'
import { useCallback } from 'react'
import { useCss } from 'react-use'

import { useDataSourceDepartments } from '@/shared/reusable'
import { uploadManagerInAppParams } from '@/utils/auth'

import AvatarUpload from '../AvatarUpload'
import { DepartmentMultipleSelect } from '../DepartmentSelect/DepartmentMultipleSelect'
import EditInput from '../EditInput'
import * as CM from '../styles'
import type { UserTitle, UserTitleType } from '../type'
import UserGroupSelect from '../UserGroupSelect'
import UserStateTag from '../UserStateTag'
import * as SC from './styles'

interface UserItemProps {
    user: AppUser
    titleList: UserTitle[]
    disableGroups?: boolean
    customNode?: React.ReactNode
    checked: boolean

    onAudit?: (value: boolean, userId: string) => void
    onSelectUsers?: (userIds: string) => void
    onUpdateUser?: (params: AppUser) => void
}

const UserItem: React.FC<UserItemProps> = ({
    user,
    titleList,
    checked,
    customNode,
    onSelectUsers,
    disableGroups = false,
    onUpdateUser,
    onAudit
}) => {
    const { data: departments } = useDataSourceDepartments()
    const renderColumn = useCallback(
        (type: UserTitleType) => {
            switch (type) {
                case 'name': {
                    return (
                        <EditInput
                            weight={600}
                            value={user.username || '未命名用户'}
                            // readonly={Boolean(user.username)}
                            onChange={(value: string) => onUpdateUser?.({ ...user, username: value })}
                            onBlur={(value: string) => onUpdateUser?.({ ...user, username: value })}
                        />
                    )
                }
                case 'mobile': {
                    return (
                        <EditInput
                            value={user.mobile ?? ''}
                            reg={mobileRegex}
                            // readonly={Boolean(user.mobile)}
                            onChange={(value: string) => onUpdateUser?.({ ...user, mobile: value })}
                            onBlur={(value: string) => onUpdateUser?.({ ...user, mobile: value })}
                        />
                    )
                }
                case 'avatar': {
                    return (
                        <Uploady accept="image/*" {...uploadManagerInAppParams()}>
                            <AvatarUpload
                                value={{ ...user, avatar: user?.avatar ?? '', username: user?.username || '未命名用户' }}
                                // readonly={Boolean(user.avatar)}
                                onChange={url => onUpdateUser?.({ ...user, avatar: url })}
                            />
                        </Uploady>
                    )
                }
                case 'state': {
                    return <UserStateTag state={user.state} isDepart={user.isDepart} onAudit={isAgree => onAudit?.(isAgree, user.userId)} />
                }
                case 'creatTime': {
                    return <div>{user?.createdTime && lightFormat(user?.createdTime, 'yyyy-MM-dd HH:mm:ss')}</div>
                }
                case 'lastLoginTime': {
                    return <div>{user?.lastLoginTime && lightFormat(user.lastLoginTime, 'yyyy-MM-dd HH:mm:ss')}</div>
                }
                case 'groups': {
                    return (
                        <UserGroupSelect
                            value={user.groupId}
                            style={{ borderStyle: 'none', backgroundColor: 'inherit' }}
                            placeholder="未选择"
                            disabled={disableGroups}
                            onChange={e => onUpdateUser?.({ ...user, groupId: e.toString() })}
                        />
                    )
                }
                case 'extra': {
                    return user.isDepart || customNode
                }
                case 'email': {
                    return (
                        <EditInput
                            value={user.email ?? ''}
                            reg={emailRegex}
                            // readonly={Boolean(user.email)}
                            onChange={(value: string) => onUpdateUser?.({ ...user, email: value })}
                            onBlur={(value: string) => onUpdateUser?.({ ...user, email: value })}
                        />
                    )
                }
                case 'checkbox': {
                    return <Checkbox size="xs" checked={checked} onChange={e => onSelectUsers?.(e.target.checked ? user.userId : '')} />
                }
                case 'departments': {
                    return (
                        <DepartmentMultipleSelect
                            options={departments}
                            value={user.departmentIds}
                            onChange={val => onUpdateUser?.({ ...user, departmentIds: val })}
                        />
                    )
                }
                // case 'wechatId': {
                //     return <EditInput value={user?.wechatId ?? ''} readonly />
                // }
                case 'wechatOpenid': {
                    return <EditInput value={user?.wechatOpenId ?? ''} readonly />
                }
                case 'wechatPcOpenid': {
                    return <EditInput value={user?.wechatPcOpenid ?? ''} readonly />
                }
                case 'wechatUnionId': {
                    return <EditInput value={user?.wechatUnionId ?? ''} readonly />
                }
                default: {
                    return undefined
                }
            }
        },
        [user, onUpdateUser, disableGroups, customNode, checked, onSelectUsers, onAudit, departments]
    )

    const stickyCls = useCss({
        position: 'sticky',
        left: 0,
        backgroundColor: checked ? 'transparent' : 'var(--color-white)'
    })

    return (
        <SC.RecordItem checked={checked}>
            {titleList.map(({ id, xs, width, ...rest }) => (
                <CM.WidthItem key={id} {...rest} width={width} className={id === 'checkbox' ? stickyCls : undefined}>
                    {renderColumn(id)}
                </CM.WidthItem>
            ))}
        </SC.RecordItem>
    )
}

export default UserItem
