import React, { useCallback, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { ResetPassword } from './ResetPassword'
import type { ForgetProps, ForgetState } from './types'
import { Verification } from './Verification'

export const Forget: React.FC<ForgetProps> = ({ mode, onChangeMode }) => {
    const [state, setState] = useImmer<ForgetState>({
        mobile: '',
        smsCode: ''
    })

    const handleChangeState = useCallback(
        (value: ForgetState) => {
            setState(draft => {
                draft.mobile = value.mobile
                draft.smsCode = value.smsCode
            })
        },
        [setState]
    )

    const handleClearState = useCallback(() => {
        setState(draft => {
            draft.mobile = ''
            draft.smsCode = ''
        })
    }, [setState])

    return useMemo(() => {
        if (mode === 'resetPassword') {
            return <ResetPassword verificationData={state} onChangeMode={onChangeMode} />
        }
        return <Verification onChangeState={handleChangeState} onClearState={handleClearState} onChangeMode={onChangeMode} />
    }, [handleChangeState, handleClearState, mode, onChangeMode, state])
}
