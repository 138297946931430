import { IconFont } from '@byecode/ui'
import type { FlowNodeButtonClickPayload } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import type { EdgeProps } from 'reactflow'
import { EdgeLabelRenderer, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { getConditionTargetStepPath } from '../../utils/getStepPath'

interface ConditionSourceEdgeProps extends EdgeProps {}

const foreignObjectSize = 18

const SCxEdge = styled.path`
    fill: none;
    stroke: #dfdfdf;
`

const SCxEdgeAddButtonWrapper = styled.div`
    background: transparent;
    width: ${foreignObjectSize}px;
    height: ${foreignObjectSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`

const SCxEdgeButton = styled.div`
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border-radius: 4px;
    color: var(--color-white);
    background: var(--color-gray-400);
    transition: all 0.2s ease-in-out;

    &.flow-node-selected {
        background: var(--color-main);
        transform: scale(2);
    }

    &:hover {
        background: var(--color-main);
        transform: scale(2);
    }
`

const SCxAddIcon = styled(IconFont)``

const SCxActionWrapper = styled.div`
    position: absolute;
    pointer-events: all;
`

const ConditionSourceEdge: React.FC<ConditionSourceEdgeProps> = ({
    id,
    selected,
    target,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style = {},
    markerEnd,
    data
}) => {
    const [operator, setOperator] = useState<FlowNodeButtonClickPayload['operator'] | null>()
    const { readonly, onEdgeButtonClick } = useFlow()
    const disabledBtn = useIsDisabledWithVersion() || readonly
    const { getEdge } = useReactFlow()

    const edgeStyles = useMemo<React.CSSProperties>(() => {
        const isSuccess = data?.status?.status === 'SUCCESS'
        const defaultStyle = style ?? {}
        return readonly
            ? {
                  ...defaultStyle,
                  pointerEvents: 'none',
                  stroke: isSuccess ? 'var(--color-green-500)' : 'var(--color-gray-400)',
                  strokeWidth: isSuccess ? 2 : 1,
                  strokeDasharray: isSuccess ? 0 : 4,
                  zIndex: isSuccess ? 1 : 0
              }
            : { ...defaultStyle, stroke: 'var(--color-gray-200)' }
    }, [data?.status?.status, readonly, style])

    const selectEdge = (operator: FlowNodeButtonClickPayload['operator']) => {
        setOperator(operator)
        const edge = getEdge(id)
        edge && onEdgeButtonClick?.({ edge, operator })
    }

    const handleAddNodeAfterCondition = useCallback(() => {
        selectEdge('addAfterCondition')
    }, [])

    const handleAddNextLevelNode = useCallback(() => {
        selectEdge('common')
    }, [])

    const { path, roundX, roundY, lastHalfCenterY } = getConditionTargetStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        roundDistance: 60
    })

    const isLongDistance = Math.abs(targetY - sourceY) > 200
    const conditionAdderY = isLongDistance ? sourceY + 70 - foreignObjectSize / 2 : sourceY + 36 - foreignObjectSize / 2
    const nextLevelNodeAdderY = lastHalfCenterY - foreignObjectSize / 2

    return (
        <>
            <SCxEdge id={id} style={edgeStyles} d={path} fill="none" markerEnd={markerEnd} />
            {!disabledBtn && (
                <>
                    {/* condition adder */}
                    <EdgeLabelRenderer>
                        <SCxActionWrapper
                            style={{
                                width: foreignObjectSize,
                                height: foreignObjectSize,
                                transform: `translate(${roundX - foreignObjectSize / 2}px,${conditionAdderY}px)`
                            }}
                        >
                            <SCxEdgeAddButtonWrapper>
                                <SCxEdgeButton
                                    onClick={handleAddNodeAfterCondition}
                                    className={cls({ 'flow-node-selected': selected && operator === 'addAfterCondition' })}
                                >
                                    <SCxAddIcon size={8} type="Add" />
                                </SCxEdgeButton>
                            </SCxEdgeAddButtonWrapper>
                        </SCxActionWrapper>
                    </EdgeLabelRenderer>
                    {/* next level node adder */}
                    <EdgeLabelRenderer>
                        <SCxActionWrapper
                            style={{
                                width: foreignObjectSize,
                                height: foreignObjectSize,
                                transform: `translate(${targetX - foreignObjectSize / 2}px,${nextLevelNodeAdderY}px)`
                            }}
                        >
                            <SCxEdgeAddButtonWrapper>
                                <SCxEdgeButton
                                    onClick={handleAddNextLevelNode}
                                    className={cls({ 'flow-node-selected': selected && operator === 'common' })}
                                >
                                    <SCxAddIcon size={8} type="Add" />
                                </SCxEdgeButton>
                            </SCxEdgeAddButtonWrapper>
                        </SCxActionWrapper>
                    </EdgeLabelRenderer>
                </>
            )}
        </>
    )
}

export default ConditionSourceEdge
