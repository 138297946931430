import { Flex } from '@byecode/ui/components/Flex'
import React from 'react'

import { CONDITION_OPTIONS } from '../constants'
import { VersionCard } from '../VersionCard'
import { CoreIndicators } from './CoreIndicators'
import { GeographicalDistribution } from './GeographicalDistribution'
import { TrafficSource } from './TrafficSource'
import { TrafficTrends } from './TrafficTrends'

export const AnalyzeTraffic = () => {
    return (
        <Flex direction="column" gap="20px">
            <VersionCard />
            <CoreIndicators defaultChecked={CONDITION_OPTIONS.map(item => item.value)} />
            <TrafficTrends />
            <TrafficSource />
            <GeographicalDistribution />
        </Flex>
    )
}
