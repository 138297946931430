import { NavigationShowMode } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { RoundAvatar } from '@/components/RoundAvatar'

import * as SC from './styles'

interface WebsiteProps {
    navBackgroundColor: string
    iconColor: string
    appName?: string
    appIcon: string
    appDomainPrefix?: string
    activeBandColor?: string
    iconBackground?: string
    color?: string
    layout?: NavigationShowMode
    bandColor?: string
    bandOpacity?: number
}

export const Website: React.FC<WebsiteProps> = ({
    navBackgroundColor,
    iconColor,
    appName,
    appIcon,
    appDomainPrefix,
    activeBandColor,
    iconBackground,
    color,
    layout = NavigationShowMode.horizontal,
    bandColor,
    bandOpacity
}) => {
    const navTitle = useMemo(() => {
        if (appName) {
            return <SC.Title>{appName}</SC.Title>
        }
        return <SC.CrossBand style={{ marginLeft: 8 }} width={78} height={4} borderRadius={2} background="#e8eaed" />
    }, [appName])

    const title = useMemo(() => {
        if (appName) {
            return (
                <SC.AppTitle color={color} isHorizontal={layout === NavigationShowMode.horizontal}>
                    {appName}
                </SC.AppTitle>
            )
        }
        return <SC.CrossBand width={88} height={20} borderRadius={8} background={navBackgroundColor} opacity={0.4} />
    }, [appName, color, layout, navBackgroundColor])

    const content = useMemo(() => {
        return (
            <SC.Content isHorizontal={layout === NavigationShowMode.horizontal} background={navBackgroundColor}>
                <SC.AppHeader isHorizontal={layout === NavigationShowMode.horizontal}>
                    <SC.AppInfo isHorizontal={layout === NavigationShowMode.horizontal}>
                        <RoundAvatar
                            iconSize={24}
                            size={32}
                            type="icon"
                            radius="8px"
                            bordered={false}
                            iconColor={iconColor}
                            icon={appIcon}
                            background={iconBackground}
                        />
                        {title}
                    </SC.AppInfo>
                    <SC.AppNavBar isHorizontal={layout === NavigationShowMode.horizontal}>
                        <SC.AppNav>
                            <SC.CrossBand width={16} height={16} borderRadius={5} background={activeBandColor} />
                            <SC.CrossBand width={24} height={4} borderRadius={8} background={activeBandColor} />
                        </SC.AppNav>
                        <SC.AppNav>
                            <SC.CrossBand width={16} height={16} borderRadius={5} background={bandColor} opacity={bandOpacity} />
                            <SC.CrossBand width={24} height={4} borderRadius={8} background={bandColor} opacity={bandOpacity} />
                        </SC.AppNav>
                        <SC.AppNav>
                            <SC.CrossBand width={16} height={16} borderRadius={5} background={bandColor} opacity={bandOpacity} />
                            <SC.CrossBand width={24} height={4} borderRadius={8} background={bandColor} opacity={bandOpacity} />
                        </SC.AppNav>
                        <SC.AppNav>
                            <SC.CrossBand width={16} height={16} borderRadius={5} background={bandColor} opacity={bandOpacity} />
                            <SC.CrossBand width={24} height={4} borderRadius={8} background={bandColor} opacity={bandOpacity} />
                        </SC.AppNav>
                    </SC.AppNavBar>
                </SC.AppHeader>
            </SC.Content>
        )
    }, [activeBandColor, appIcon, bandColor, bandOpacity, iconBackground, iconColor, layout, navBackgroundColor, title])

    return (
        <SC.Website>
            <SC.Header>
                <SC.HeaderContent>
                    <SC.HeaderHalf />
                    <SC.Prefix />
                    <SC.WebTab>
                        <SC.CrossBand width={14} height={14} borderRadius={2} background="#e8eaed" />
                        {navTitle}
                    </SC.WebTab>
                    <SC.Suffix />
                </SC.HeaderContent>
            </SC.Header>
            <SC.ToolBar>
                <SC.Tool>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.5 1L1 7M1 7L7.5 13M1 7H14"
                            stroke="#5F6368"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity={1}
                        />
                    </svg>
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 1L14.5 7M14.5 7L8 13M14.5 7H1.5"
                            stroke="#BABCBE"
                            strokeOpacity={1}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.4497 2.05028C12.448 2.04852 12.4463 2.04677 12.4445 2.04502C11.5171 1.11955 10.3447 0.469401 9.05829 0.175655C7.5435 -0.170233 5.95668 -0.00230565 4.54785 0.652981C3.13901 1.30827 1.98823 2.41366 1.27683 3.79501C0.565419 5.17636 0.33382 6.75514 0.618518 8.28261C0.903215 9.81008 1.68803 11.1994 2.8493 12.2317C4.01058 13.264 5.48231 13.8806 7.03262 13.9844C8.58293 14.0881 10.1237 13.6731 11.4122 12.8048C12.5064 12.0673 13.3658 11.0384 13.8976 9.84099C14.0545 9.48766 13.8515 9.09012 13.4843 8.96927C13.1171 8.84843 12.7253 9.05072 12.5597 9.40002C12.131 10.3036 11.4661 11.0801 10.6297 11.6438C9.59896 12.3385 8.36634 12.6705 7.1261 12.5875C5.88585 12.5045 4.70846 12.0112 3.77944 11.1854C2.85042 10.3595 2.22257 9.24807 1.99482 8.02609C1.76706 6.80411 1.95234 5.54109 2.52146 4.43601C3.09059 3.33093 4.01121 2.44661 5.13828 1.92239C6.26535 1.39816 7.5348 1.26381 8.74663 1.54053C9.72993 1.76505 10.6299 2.24986 11.3553 2.93838C11.3912 2.97249 11.4298 3.003 11.4702 3.02977L9.00001 5.50001H14.5V5.84178e-06L12.4497 2.05028Z"
                            fill="#5F6368"
                            strokeOpacity={1}
                        />
                    </svg>
                </SC.Tool>
                <SC.Address>
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.5 1.75C5.32843 1.75 6 2.42157 6 3.25V4.75H3V3.25C3 2.42157 3.67157 1.75 4.5 1.75ZM2 4.75V3.25C2 1.86929 3.11929 0.75 4.5 0.75C5.88071 0.75 7 1.86929 7 3.25V4.75H8C8.55229 4.75 9 5.19772 9 5.75V11.25C9 11.8023 8.55229 12.25 8 12.25H1C0.447715 12.25 0 11.8023 0 11.25V5.75C0 5.19772 0.447715 4.75 1 4.75H2Z"
                            fill="#5F6368"
                        />
                    </svg>

                    <SC.Url>{appDomainPrefix ? `${appDomainPrefix}.byecode.site` : 'sample.byecode.site'}</SC.Url>
                </SC.Address>
            </SC.ToolBar>
            {content}
        </SC.Website>
    )
}
