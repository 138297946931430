import { IconFont } from '@byecode/ui'
import type { DataSourceAbstract, JoinConfig } from '@lighthouse/core'
import { FindUseLocationType, FindUseObjectContextProvider, useLimitRetryAction } from '@lighthouse/shared'
import { Divider, ScrollArea } from '@mantine/core'
import isEqual from 'fast-deep-equal'
import { clone } from 'rambda'
import React, { useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { useFindUse } from '@/hooks/useFindUse'

import { BasicConfigure } from './BasicConfigure'
import { JoinConfigure } from './JoinConfigure'

const SCxSettingWrapper = styled(ScrollArea)`
    width: 360px;
    height: 100%;
    padding-bottom: 62px;
    overflow-y: auto;
    border-left: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

const SCxHeader = styled.div`
    position: sticky;
    z-index: 99;
    top: 0;
    height: 48px;
    width: 100%;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-200);
`

const SCxTitle = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-black);
    font-weight: 600;
`

const SCxCloseWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    transition: background 0.2s;

    &:hover {
        background-color: var(--color-gray-200);
    }
`

interface JoinDataSourceSettingProps {
    id: string
    value?: JoinConfig
    dataSourceList: DataSourceAbstract[]
    onChange: (val: JoinConfig) => void
    onClose?: () => void
}

export const JoinDataSourceSetting: React.FC<JoinDataSourceSettingProps> = ({ id, value, dataSourceList, onChange, onClose }) => {
    const findUseObject = useFindUse(FindUseLocationType.DATASHEET, { id })

    const defaultValues = useMemo(() => {
        if (value?.conditions) {
            return value
        }
        return {
            ...value,
            conditions: []
        }
    }, [value])

    const valueRef = useRef(defaultValues)

    const methods = useForm<JoinConfig>({
        mode: 'onChange',
        defaultValues
    })

    const { watch, setValue } = methods

    const formData = watch()
    const cloneFormData = clone(formData)
    const { retry } = useLimitRetryAction(onChange)

    useUpdateEffect(() => {
        if (!isEqual(cloneFormData, valueRef.current)) {
            valueRef.current = defaultValues
            setValue('conditions', defaultValues.conditions)
        }
    }, [defaultValues])

    // useUpdateEffect(() => {
    //     valueRef.current = defaultValues
    // }, [defaultValues])

    useUpdateEffect(() => {
        if (cloneFormData && !isEqual(cloneFormData, valueRef.current)) {
            valueRef.current = cloneFormData
            retry(cloneFormData)
        }
    }, [formData])

    return (
        <FindUseObjectContextProvider value={findUseObject}>
            <SCxSettingWrapper>
                <FormProvider {...methods}>
                    <SCxHeader>
                        <SCxTitle>连接表配置</SCxTitle>
                        <SCxCloseWrapper onClick={onClose}>
                            <IconFont type="Close" size={16} />
                        </SCxCloseWrapper>
                    </SCxHeader>
                    <BasicConfigure dataSourceList={dataSourceList} />
                    <Divider color="var(--color-gray-200)" />
                    <JoinConfigure dataSourceList={dataSourceList} />
                </FormProvider>
            </SCxSettingWrapper>
        </FindUseObjectContextProvider>
    )
}
