import type { BlockAbstract } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import type { Detection, FlowLayoutContainerNode, FlowLayoutCustomNode } from '@lighthouse/shared'
import {
    type FlowLayoutNode,
    detectOrderForPointerOnBlock,
    findNodeById,
    findNodeParentById,
    FLOW_LAYOUT_NODE_ROWS
} from '@lighthouse/shared'

import type { DragNode } from '@/constants/Block/type'

import {
    computedUsedMinWidth,
    findNextNodeById,
    findPrevNodeById,
    getOverNode,
    includeNotAllowedBlock,
    isActiveInOver,
    isCustomChildren
} from './utils'

type NodeDetection = (
    params: Parameters<Detection>[0] & { nodes: FlowLayoutNode[]; blocks: BlockAbstract[]; addingNode: DragNode | null }
) => ReturnType<Detection>

/** 碰撞检测 */
export const nodeDetection: NodeDetection = ({
    nodes,
    blocks,
    addingNode,
    coordinates,
    draggableNodes,
    active,
    overType,
    relativeId,
    actualRelativeId,
    virtualParentId
}) => {
    switch (overType) {
        case 'root': {
            const activeIndex = nodes.findIndex(item => item.id === active.id)
            if (activeIndex !== -1 && activeIndex === nodes.length - 1) {
                return
            }

            return {
                id: overType,
                actualId: actualRelativeId,
                virtualParentId,
                target: overType
            }
        }

        case 'padding-top':
        case 'padding-right':
        case 'padding-bottom':
        case 'padding-left': {
            if (relativeId === active.id) {
                return
            }

            let overNodeChildren: FlowLayoutNode[] = []
            if (relativeId) {
                const overNode = findNodeById(relativeId)(nodes)
                if (
                    (overNode?.type === 'custom' || isCustomChildren(relativeId, nodes)) &&
                    includeNotAllowedBlock(active, [BlockType.view, BlockType.chart], [...blocks, ...(addingNode?.data || [])])
                ) {
                    return
                }

                if (overNode && overNode.type === 'container') {
                    overNodeChildren = overNode.children ?? []

                    // 检测尺寸是否超出
                    if (overNode.data.direction === DIRECTION.horizontal) {
                        // const usedWidth = computedUsedWidth(active)(overNodeChildren)
                        // if ((active.minWidth ?? 1) > rows - usedWidth) {
                        //     return
                        // }
                        const usedMinWidth = computedUsedMinWidth(active)(overNodeChildren)
                        if ((active.minWidth ?? 1) > FLOW_LAYOUT_NODE_ROWS - usedMinWidth) {
                            return
                        }
                    }
                }
            } else {
                overNodeChildren = nodes
            }

            // 检测插入位置是否无效
            if (overNodeChildren.length > 0) {
                const activeInOverIndex = overNodeChildren.findIndex(item => item.id === active.id)
                if (overType === 'padding-left' || overType === 'padding-top') {
                    // 检查是否是第一个元素
                    if (activeInOverIndex === 0) {
                        return
                    }
                } else {
                    // 检查是否是最后一个元素
                    if (activeInOverIndex !== -1 && activeInOverIndex === overNodeChildren.length - 1) {
                        return
                    }
                }
            }

            return {
                id: relativeId || 'root',
                actualId: actualRelativeId,
                virtualParentId,
                target: overType
            }
        }

        case 'gap': {
            if (!relativeId || relativeId === active.id) {
                return
            }

            if (
                isCustomChildren(relativeId, nodes) &&
                includeNotAllowedBlock(active, [BlockType.view, BlockType.chart], [...blocks, ...(addingNode?.data || [])])
            ) {
                return
            }

            if (isActiveInOver(active.id, relativeId)(nodes)) {
                return
            }

            const overNextNode = findNextNodeById(relativeId)(nodes)
            if (overNextNode?.id === active.id) {
                return
            }

            const overParentNode = findNodeParentById(relativeId)(nodes)

            // 检测尺寸是否超出
            if (
                overParentNode &&
                overParentNode.type === 'container' &&
                overParentNode.children &&
                overParentNode.data.direction === DIRECTION.horizontal
            ) {
                // const usedWidth = computedUsedWidth(active)(overParentNode.children)
                // if ((active.minWidth ?? 1) > rows - usedWidth) {
                //     return
                // }
                const usedMinWidth = computedUsedMinWidth(active)(overParentNode.children)
                if ((active.minWidth ?? 1) > FLOW_LAYOUT_NODE_ROWS - usedMinWidth) {
                    return
                }
            }

            return {
                id: relativeId,
                actualId: actualRelativeId,
                virtualParentId,
                target: overType
            }
        }
        case 'placeholder-begin': {
            if (!relativeId || relativeId === active.id) {
                return
            }

            const overNode = findNodeById(relativeId)(nodes)
            if (
                (overNode?.type === 'custom' || isCustomChildren(relativeId, nodes)) &&
                includeNotAllowedBlock(active, [BlockType.view, BlockType.chart], [...blocks, ...(addingNode?.data || [])])
            ) {
                return
            }

            if (overNode && overNode.type === 'container' && overNode.children) {
                // 如果active是over的第一个子级，则忽略
                const activeInOverIndex = overNode.children.findIndex(item => item.id === active.id)
                if (activeInOverIndex !== -1 && activeInOverIndex === 0) {
                    return
                }

                if (overNode.data.direction === DIRECTION.horizontal) {
                    // const usedWidth = computedUsedWidth(active)(overNode.children)
                    // if ((active.minWidth ?? 1) > rows - usedWidth) {
                    //     return
                    // }
                    const usedMinWidth = computedUsedMinWidth(active)(overNode.children)
                    if ((active.minWidth ?? 1) > FLOW_LAYOUT_NODE_ROWS - usedMinWidth) {
                        return
                    }
                }
            }

            return {
                id: relativeId,
                actualId: actualRelativeId,
                virtualParentId,
                target: overType
            }
        }
        case 'placeholder-after': {
            if (!relativeId || relativeId === active.id) {
                return
            }

            const overNode = findNodeById(relativeId)(nodes)

            if (
                (overNode?.type === 'custom' || isCustomChildren(relativeId, nodes)) &&
                includeNotAllowedBlock(active, [BlockType.view, BlockType.chart], [...blocks, ...(addingNode?.data || [])])
            ) {
                return
            }

            if (overNode?.type === 'container' && overNode.children) {
                // 如果active是over的最后一个子级，则忽略
                const activeInOverIndex = overNode.children.findIndex(item => item.id === active.id)
                if (activeInOverIndex !== -1 && activeInOverIndex === overNode.children.length - 1) {
                    return
                }

                if (overNode.data.direction === DIRECTION.horizontal) {
                    // const usedWidth = computedUsedWidth(active)(overNode.children)
                    // if ((active.minWidth ?? 1) > rows - usedWidth) {
                    //     return
                    // }
                    const usedMinWidth = computedUsedMinWidth(active)(overNode.children)
                    if ((active.minWidth ?? 1) > FLOW_LAYOUT_NODE_ROWS - usedMinWidth) {
                        return
                    }
                }
            }

            return {
                id: relativeId,
                actualId: actualRelativeId,
                virtualParentId,
                target: overType
            }
        }

        default: {
            // 检测over到node的方位
            const overNode = getOverNode(active.id, coordinates, draggableNodes)(nodes)
            if (!overNode) {
                return
            }

            if (
                isCustomChildren(overNode.id, nodes) &&
                includeNotAllowedBlock(active, [BlockType.view, BlockType.chart], [...blocks, ...(addingNode?.data || [])])
            ) {
                return
            }

            if (isActiveInOver(active.id, overNode.id)(nodes)) {
                return
            }
            const overParentNode = overNode.data.virtualParentId
                ? (draggableNodes.get(overNode.data.virtualParentId)?.data as FlowLayoutContainerNode | FlowLayoutCustomNode)
                : findNodeParentById(overNode.id)(nodes)
            const isOnBeginArea = detectOrderForPointerOnBlock(overParentNode?.data.direction, coordinates, overNode.rect)
            // 排除原地不动的情况
            if (
                (isOnBeginArea && active.id === findPrevNodeById(overNode.id)(nodes)?.id) ||
                (!isOnBeginArea && active.id === findNextNodeById(overNode.id)(nodes)?.id)
            ) {
                return null
            }

            // 检测尺寸是否超出
            if (
                overParentNode &&
                overParentNode.type === 'container' &&
                overParentNode.children &&
                overParentNode.data.direction === DIRECTION.horizontal
            ) {
                // const usedWidth = computedUsedWidth(active)(overParentNode.children)
                // if ((active.minWidth ?? 1) > rows - usedWidth) {
                //     return
                // }
                const usedMinWidth = computedUsedMinWidth(active)(overParentNode.children)
                if ((active.minWidth ?? 1) > FLOW_LAYOUT_NODE_ROWS - usedMinWidth) {
                    return
                }
            }

            return {
                id: overNode.id,
                target: 'node-mask',
                rect: overNode.rect,
                virtualParentId: overNode.data.virtualParentId
            }
        }
    }
}
