export enum ActivityType {
    'INVITATION_USER' = 'INVITATION_USER'
}


export type ActivityProtocol = {
    id: string
    isValid: boolean
    startTime: number
    endTime: number
    type: ActivityType
}
