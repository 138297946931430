import type { BlockAbstract, PageNode } from '@lighthouse/core'
import type { MakeADT } from 'ts-adt/MakeADT'

import { UndoRedoController } from '../core'

export const BLOCK_NODE_CREATE_ACTION = 'BLOCK_NODE_CREATE_ACTION'
export type BlockNodeCreateAction = 'BLOCK_NODE_CREATE_ACTION'
export const BLOCK_NODE_REMOVE_ACTION = 'BLOCK_NODE_REMOVE_ACTION'
export type BlockNodeRemoveAction = 'BLOCK_NODE_REMOVE_ACTION'
export const BLOCK_UPDATE_ACTION = 'BLOCK_UPDATE_ACTION'
export type BlockUpdateAction = 'BLOCK_UPDATE_ACTION'
export const NODE_UPDATE_ACTION = 'NODE_UPDATE_ACTION'
export type NodeUpdateAction = 'NODE_UPDATE_ACTION'

export type UndoRedoAction = BlockNodeCreateAction | BlockNodeRemoveAction | BlockUpdateAction | NodeUpdateAction

export type PageUndoRedoPayload = MakeADT<
    'action',
    {
        [BLOCK_NODE_CREATE_ACTION]: {
            payload: {
                blocks: BlockAbstract[]
                nodes: { prev: undefined | PageNode[]; next: undefined | PageNode[] }
            }
        }
        [BLOCK_NODE_REMOVE_ACTION]: {
            payload: {
                blocks: BlockAbstract[]
                nodes: { prev: undefined | PageNode[]; next: undefined | PageNode[] }
            }
        }
        [BLOCK_UPDATE_ACTION]: {
            payload: { prev: BlockAbstract; next: BlockAbstract }
        }
        [NODE_UPDATE_ACTION]: {
            payload: { prev: undefined | PageNode[]; next: undefined | PageNode[] }
        }
    }
>

export const pageUndoRedoController = new UndoRedoController<PageUndoRedoPayload>()
