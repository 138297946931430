import { flex } from '@byecode/ui'
import * as React from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface TemplateHeaderProps {
    onClose?: () => void
}

const SCxContainer = styled.div`
    width: 100%;
    padding: 0 16px;
    height: 60px;
    ${flex};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-200);
    background-color: var(--color-white);

    ${CM.Text} {
        margin-left: 12px;
    }
`

const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;
`

const SCxRightFill = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const TemplateHeader: React.FunctionComponent<TemplateHeaderProps> = ({ onClose }) => {
    return (
        <SCxContainer>
            <SCxLeftFill>
                <CM.Icon type="Template" color="var(--color-theme-6)" size={24} />
                <CM.Text size={20} style={{ fontWeight: 550 }}>
                    模板库
                </CM.Text>
            </SCxLeftFill>
            <SCxRightFill>{onClose && <CM.Icon type="Close" color="var(--color-gray-400)" size={20} onClick={onClose} />}</SCxRightFill>
        </SCxContainer>
    )
}

export default TemplateHeader
