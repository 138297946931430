import type { DataSourceAbstract } from '@lighthouse/core'
import type { AggregatorNode } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { forEach } from 'rambda'
import React, { useMemo } from 'react'
import type { Edge, EdgeProps as ReactFlowEdgeProps, Node } from 'reactflow'
import { useEdges, useNodes } from 'reactflow'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import { otherNodeResultGenerator, peekPrevNodes } from '../utils'
import { validateAggregatorNodeConfig } from '../validators'
import { arrowEnd, arrowEndGradient, arrowStart, arrowStartGradient } from './Marker'

export type EdgeProps = ReactFlowEdgeProps<{ something: string }>

const getNodeValid = (node: AggregatorNode | undefined, nodes: AggregatorNode[], edges: Edge[], dataSourceList: DataSourceAbstract[]) => {
    if (!node) {
        return false
    }
    const { nodes: prevNodes } = peekPrevNodes(node, nodes, edges)
    const sourcePrevFieldList = otherNodeResultGenerator(prevNodes)
    return validateAggregatorNodeConfig({ node, prevFieldList: sourcePrevFieldList, dataSourceList })
}

const getPath = (params: EdgeProps, nodes: AggregatorNode[], edges: Edge[], gradientId: string, dataSourceList: DataSourceAbstract[]) => {
    const { sourceX, sourceY, targetX, targetY, source, target } = params

    let sourceNode: AggregatorNode | undefined
    let targetNode: Node | undefined
    forEach(node => {
        if (node.id === source) {
            sourceNode = node
        }
        if (node.id === target) {
            targetNode = node
        }
    }, nodes)

    const startGap = 50

    const isHorizontal = targetY - sourceY < 5 && targetY - sourceY > -5
    const isAddPlaceholderNode = targetNode?.type === 'ADD_PLACEHOLDER'

    let path = ''

    if (isAddPlaceholderNode) {
        path = `
            M 0 10
            H ${targetX - sourceX}
        `
    } else {
        if (isHorizontal) {
            path = `
            M 0 10
            H ${targetX - sourceX}
        `
        } else {
            path = `
            M ${sourceX} ${sourceY}
            L ${sourceX + startGap} ${sourceY}
            L ${sourceX + startGap} ${targetY}
            L ${targetX} ${targetY}
        `
        }
    }

    // const style: React.SVGProps<SVGPathElement> | undefined = {}

    const targetYGtSourceY = targetY > sourceY
    const gradientStyle = isHorizontal
        ? {}
        : targetYGtSourceY
        ? { x1: '0', y1: '0', x2: '0', y2: '100%' }
        : { x1: '0', y1: '100%', x2: '0', y2: '0' }

    // 校验节点
    const validatedAggregatorNode = getNodeValid(sourceNode, nodes, edges, dataSourceList)
    // ，如果通过校验，设置为渐变色，否则为灰
    const lineStyle = validatedAggregatorNode && !isAddPlaceholderNode ? `url(#${gradientId})` : 'var(--color-gray-300)'

    // 设置箭头样式
    const isMarkerGradient = validatedAggregatorNode && !isAddPlaceholderNode

    // 添加节点的样式，需要处理为虚线
    if (targetNode?.type === 'ADD_PLACEHOLDER') {
        return {
            isHorizontal,
            path,
            isMarkerGradient,
            gradientStyle,
            lineStyle,
            style: { strokeDasharray: '5, 5', stroke: 'var(--color-gray-300)', fill: 'none' }
        }
    }

    // 设置普通样式
    return {
        isHorizontal,
        path,
        isMarkerGradient,
        lineStyle,
        style: { fill: isHorizontal ? lineStyle : 'none', stroke: isHorizontal ? 'none' : lineStyle },
        gradientStyle
    }
}

export const CommonEdge: React.FC<EdgeProps> = props => {
    const { sourceX, sourceY } = props
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const nodes = useNodes() as AggregatorNode[]
    const edges = useEdges()
    const gradientId = useMemo(() => `${nanoid(10)}_gradient`, [])

    const { path, style, gradientStyle, isMarkerGradient, lineStyle, isHorizontal } = getPath(
        props,
        nodes,
        edges,
        gradientId,
        dataSourceList
    )
    const arrowEndId = isMarkerGradient ? arrowEndGradient : arrowEnd
    const arrowStartId = isMarkerGradient ? arrowStartGradient : arrowStart

    return (
        <g>
            {isHorizontal ? (
                <svg width="100" height="50" x={sourceX} y={sourceY - 10} xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id={gradientId} gradientUnits="userSpaceOnUse">
                            <stop offset="0%" stopColor="var(--color-green-500)" />
                            <stop offset="100%" stopColor="var(--color-purple-500)" />
                        </linearGradient>
                    </defs>
                    <path d={path} strokeWidth={2} {...style} stroke={lineStyle} markerEnd={`url(#${arrowEndId})`} />
                </svg>
            ) : (
                <g>
                    <defs>
                        <linearGradient id={gradientId} {...gradientStyle}>
                            <stop offset="0%" stopColor="var(--color-green-500)" />
                            <stop offset="100%" stopColor="var(--color-purple-500)" />
                        </linearGradient>
                    </defs>
                    <path d={path} strokeWidth={2} markerEnd={`url(#${arrowEndId})`} {...style} />
                </g>
            )}
        </g>
    )
}
