import { useAtomAction, USER_DATASOURCE } from '@lighthouse/shared'
import { at } from '@lighthouse/tools'
import { filter, map } from 'rambda'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'

import { setApplicationModePageHistoryAtom } from '@/atoms/application/action'
import type { ApplicationLayoutMode } from '@/atoms/application/constants';
import { APPLICATION_LAYOUT_MODE_TYPE, APPLICATION_MODE_PAGE_HISTORY } from '@/atoms/application/constants'
import { useAppLayoutMode, useApplicationModePageHistory, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList } from '@/hooks/usePage'
import * as srv from '@/services'

import { aggregateUrlReg, dataSourceUrlReg, flowUrlReg, pageUrlReg, recordUrlReg, viewUrlReg } from './constant'

const urlParamReg: RegExp[] = [/\/page\/[0-9a-z-]\//u]

const getPagePath = (params: { path: string; pageId?: string; viewId?: string; recordId?: string } | undefined): string | undefined => {
    if (!params) {
        return
    }
    const { pageId, viewId, recordId, path } = params
    const pathArr = path.split('/')
    if (pageId) {
        return `./page/${pageId}`
    }

    if (viewId) {
        if (recordId) {
            return `./view/${viewId}/record/${recordId}`
        }
        if (at(-1, pathArr) === 'edit') {
            return `./view/${viewId}/record/edit`
        }
        if (at(-1, pathArr) === 'form') {
            return `./view/${viewId}/form`
        }
    }
    return ''
}

export const getParseApplicationUrlParams = function (url: string) {
    const pageId = pageUrlReg.exec(url)?.[0].replace('/page/', '')
    const flowId = flowUrlReg.exec(url)?.[0].replace('/flow/', '')
    const dataSource = dataSourceUrlReg.exec(url)?.[0]

    const aggregate = aggregateUrlReg.exec(url)?.[0]
    const viewId = viewUrlReg.exec(url)?.[0].replace('/view/', '')
    const recordId = recordUrlReg.exec(url)?.[0].replace('/record/', '')
    return { pageId, flowId, dataSourceParams: dataSource || aggregate, viewId, recordId: recordId === 'edit' ? undefined : recordId }
}

export const useRecentPageHistory = function (appId: string) {
    const mode = useAppLayoutMode()
    const location = useLocation()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSourceIdsList = dataSourceList.map(item => item.id)
    const pageList = useDefaultPageList()
    const { data: workflows = [] } = useSWR(`allFlowNodes`, () => srv.listFlowNodes(), {
        revalidateOnFocus: false
    })

    const applicationModePageHistory = useApplicationModePageHistory()
    const { run: setApplicationModePageHistory } = useAtomAction(setApplicationModePageHistoryAtom)

    const { pageId, viewId, flowId, dataSourceParams, recordId } = useMemo(
        () => getParseApplicationUrlParams(location.pathname),
        [location.pathname]
    )

    const recentDs = useMemo(() => {
        const lastSubPath = at(-1, applicationModePageHistory.dataSource.subPath)
        if (lastSubPath?.includes('aggregate')) {
            return { dsPath: lastSubPath, dsId: '' }
        }
        const subPaths = applicationModePageHistory.dataSource.subPath.map(item => item.replace('/dataSource/', ''))
        const filterDsIds = filter(id => dataSourceIdsList.includes(id ?? ''), subPaths)
        const firstDsId = filter(id => id !== USER_DATASOURCE, dataSourceIdsList)
        const dsId = at(-1, filterDsIds) ?? firstDsId[0] ?? USER_DATASOURCE

        return { dsPath: `/dataSource/${dsId}`, dsId }
    }, [applicationModePageHistory.dataSource.subPath, dataSourceIdsList])

    const recentFlowId = useMemo(() => {
        const currentWorkflowIds = map(item => item.id, workflows)
        const filterWorkflowId = filter(id => currentWorkflowIds.includes(id ?? ''), applicationModePageHistory.flow.flowIds)
        return at(-1, filterWorkflowId)
    }, [applicationModePageHistory.flow.flowIds, workflows])

    // const recentPage = useMemo(() => {
    //     const currentPageIds = map(item => item.id, pageList ?? [])
    //     const filterPages = filter(
    //         item => currentPageIds.includes(item?.pageId ?? '') || Boolean(item?.viewId ?? ''),
    //         applicationModePageHistory.preview.pages
    //     )
    //     return getPagePath(at(-1, filterPages)) || `./page/${pageList?.[0]?.id}`
    // }, [applicationModePageHistory.preview.pages, pageList])

    const history = {
        // dataSourceId: recentDsId ? `./dataSource/${recentDsId}` : './dataSource',
        ...recentDs,
        // page: recentPage || './',
        flowId: recentFlowId ? `./flow/${recentFlowId}` : './flow'
    }

    useEffect(() => {
        switch (mode.type) {
            case 'dataSource': {
                if (dataSourceParams) {
                    setApplicationModePageHistory(draft => {
                        draft.dataSource.subPath = [...draft.dataSource.subPath.splice(-10), dataSourceParams]
                    })
                }
                break
            }
            case 'flow': {
                if (flowId) {
                    setApplicationModePageHistory(draft => {
                        draft.flow.flowIds = [...draft.flow.flowIds.splice(-10), flowId]
                    })
                }
                break
            }
            case 'preview': {
                if (pageId || viewId || recordId) {
                    setApplicationModePageHistory(draft => {
                        draft.preview.pages = [
                            ...draft.preview.pages.splice(-10),
                            {
                                path: location.pathname,
                                pageId,
                                viewId,
                                recordId
                            }
                        ]
                    })
                }
                break
            }
            default: {
                break
            }
        }
    }, [dataSourceParams, flowId, location, mode.type, pageId, recordId, setApplicationModePageHistory, viewId])

    useEffect(() => {
        return () => {
            setApplicationModePageHistory(APPLICATION_MODE_PAGE_HISTORY)
        }
    }, [setApplicationModePageHistory])

    return history
}


export const useMatchRouteToMode = (reservedMode: ApplicationLayoutMode['type']): ApplicationLayoutMode['type'] => {
    const { pathname } = useLocation()

    const isAnalyze = pathname.includes('analyze')
    const isDataSource = pathname.includes('dataSource')
    const isAggregate = pathname.includes('aggregate')
    const isUsers = pathname.includes('users')
    const isFlow = pathname.includes('flow')
    const isOtherPlatforms = pathname.includes('otherPlatforms')
    if (isAnalyze) {
        return APPLICATION_LAYOUT_MODE_TYPE.analyze
    }
    // 数据源和聚合表都是 dataSource
    if (isDataSource || isAggregate) {
        return 'dataSource'
    }
    if (isUsers) {
        return 'users'
    }
    if (isFlow) {
        return 'flow'
    }
    if (isOtherPlatforms) {
        return 'otherPlatforms'
    }

    return reservedMode === 'setting' ? 'setting' : 'preview'
}
