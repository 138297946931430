import { Button, IconFont } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

export interface RefreshButtonProps {
    onRefresh?: () => Promise<DataSourceAbstract | undefined>
}

const SCxButton = styled(Button)`
    font-size: var(--font-size-normal);
    white-space: nowrap;
`

export const RefreshButton: React.FC<RefreshButtonProps> = ({ onRefresh }) => {
    const [isRefreshing, setIsRefreshing] = useState(false)

    const [freshStatus, setFreshStatus] = useState<null | false | true>(null)

    const handleRefresh = async () => {
        setIsRefreshing(true)
        await onRefresh?.()

        setIsRefreshing(false)
        setFreshStatus(true)
    }

    useEffect(() => {
        if (freshStatus) {
            setTimeout(() => {
                setFreshStatus(null)
            }, 2000)
        }
    }, [freshStatus])

    const buttonStyles = useMemo(() => {
        if (freshStatus === null) {
            return {}
        }
        if (freshStatus) {
            return {
                background: '#E9FAF0',
                borderColor: '#E9FAF0',
                color: '#12B76A'
            }
        }
    }, [freshStatus])

    const buttonIcon = useMemo(() => {
        if (freshStatus === null) {
            return <IconFont type="Repeat" />
        }
        if (freshStatus) {
            return <IconFont type="Tick" />
        }
    }, [freshStatus])

    const buttonText = useMemo(() => {
        if (freshStatus) {
            return '已刷新'
        }
        if (isRefreshing) {
            return '正在刷新'
        }
        return '刷新'
    }, [freshStatus, isRefreshing])

    return (
        <SCxButton size="sm" style={buttonStyles} icon={buttonIcon} loading={isRefreshing} onClick={handleRefresh}>
            {buttonText}
        </SCxButton>
    )
}
