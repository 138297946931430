import { Box } from '@byecode/ui/components/Box'
import { spaceVersionEnum } from '@lighthouse/shared'
import chroma from 'chroma-js'
import { lightFormat, subDays } from 'date-fns'
import * as echarts from 'echarts/core'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { throttle } from 'throttle-debounce'

import { VersionOverlay } from '@/components/SpaceGrade'
import type { AnalysisCoreIndicators } from '@/services'
import * as srv from '@/services'

import type { EchartsOption } from '../chart.type'
import { Card } from '../components'
import { Condition, resetTime2Last, resetTime2Zero } from '../components/Condition'
import { CONDITION_OPTIONS } from '../constants'

const OPTIONS: { label: string; value?: string }[] = [
    {
        label: '全部'
    },
    ...CONDITION_OPTIONS
]

const CHART_OPTION: EchartsOption = {
    tooltip: {
        trigger: 'axis',
        extraCssText: 'width: 206px',
        padding: [8, 16],
        textStyle: {
            color: 'var(--color-gray-600)',
            fontSize: 12,
            lineHeight: 20
        }
    },
    grid: {
        left: 0,
        top: 40,
        right: 10,
        bottom: '10%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: true,
            axisTick: { show: false },
            axisLine: { show: false },
            data: []
        }
    ],
    yAxis: {
        type: 'value',
        splitLine: {
            lineStyle: {
                type: 'dashed',
                width: 2,
                dashOffset: 6
            }
        }
    },
    series: []
}

type GetSeriesParams = {
    colors: string[]
    options: typeof CONDITION_OPTIONS
    values: AnalysisCoreIndicators[]
    lightColor?: boolean
}
function getSeries({ colors, options, values, lightColor }: GetSeriesParams) {
    return options.map((item, index) => {
        const color = colors[index % colors.length]
        return {
            color: lightColor ? chroma(color).alpha(0.6).hex() : color,
            name: item.label,
            type: 'line',
            lineStyle: { width: 4 },
            symbol: 'circle',
            symbolSize: 8,
            stack: 'Total',
            data: values.map(v => v[item.value])
        } satisfies EchartsOption['series']
    })
}

const FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const TrafficTrends = () => {
    const chartDomRef = useRef<HTMLDivElement | null>(null)

    const [selectedCondition, setSelectedCondition] = useState<string[]>([])

    const [dateType, setDateType] = useState<'HOUR' | 'DAY'>('DAY')
    const [dates, setDates] = useState(() => {
        const start = subDays(new Date(), 7)
        resetTime2Zero(start)
        const end = subDays(new Date(), 1)
        resetTime2Last(end)
        return { start, end }
    })
    const [compareDates, setCompareDates] = useState<{ start?: Date; end?: Date }>({})

    const res = useAsyncRetry(
        () =>
            srv.fetchAnalysisTrafficTrend({
                startTime: lightFormat(dates.start, FORMAT),
                endTime: lightFormat(dates.end, FORMAT),
                type: dateType
            }),
        [dateType, dates.start, dates.end]
    )

    const compareRes = useAsyncRetry(() => {
        if (compareDates.start && compareDates.end) {
            return srv.fetchAnalysisTrafficTrend({
                startTime: lightFormat(compareDates.start, FORMAT),
                endTime: lightFormat(compareDates.end, FORMAT),
                type: dateType
            })
        }
        return Promise.resolve([])
    }, [compareDates.start, compareDates.end, dateType])

    const echartsRef = useRef<echarts.ECharts | null>(null)
    useLayoutEffect(() => {
        const dom = chartDomRef.current
        if (!dom) {
            return
        }

        const instance = echarts.init(dom)
        instance.setOption(CHART_OPTION)
        echartsRef.current = instance

        const resize = throttle(300, () => {
            instance.resize()
        })
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
            instance.dispose()
            echartsRef.current = null
        }
    }, [])

    useEffect(() => {
        const data = res.value
        if (!data) {
            return
        }

        const instance = echartsRef.current
        if (!instance) {
            return
        }

        const compareData = compareRes.value
        const hasCompareData = !!compareData && compareData.length > 0

        const conditions =
            selectedCondition.length === 0 ? CONDITION_OPTIONS : CONDITION_OPTIONS.filter(item => selectedCondition.includes(item.value))
        const colors = instance.getOption().color as string[]

        const series = [
            ...getSeries({ options: conditions, values: data, colors }),
            ...getSeries({
                options: conditions.map(item => ({ ...item, label: `${item.label}-compare` })),
                values: compareData ?? [],
                colors,
                lightColor: true
            }).slice(0, hasCompareData ? undefined : -1 * conditions.length)
        ]

        const xAxis = (
            [
                {
                    type: 'category',
                    boundaryGap: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    data: data.map(item => item.time)
                },
                {
                    type: 'category',
                    boundaryGap: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    data: compareData?.map(item => item.time)
                }
            ] satisfies EchartsOption['xAxis']
        ).slice(0, hasCompareData ? undefined : -1)

        const legend = (
            [
                {
                    bottom: 0,
                    left: 0,
                    data: conditions.map(item => item.label)
                },
                {
                    top: 0,
                    left: 0,
                    data: conditions.map(item => `${item.label}-compare`),
                    formatter: name => name.replace('-compare', '')
                }
            ] satisfies EchartsOption['legend']
        ).slice(0, hasCompareData ? undefined : -1)

        const tooltip = {
            formatter: params => {
                if (Array.isArray(params)) {
                    const title =
                        dateType === 'HOUR'
                            ? lightFormat(dates.start, 'yyyy/MM/dd')
                            : `${lightFormat(dates.start, 'yyyy/MM/dd')} 至 ${lightFormat(dates.end, 'yyyy/MM/dd')}：`
                    const titleTemplate = `<div style="font-weight: 600; display: flex; align-items: center; height: 36px;">${title}</div>
                    `
                    const contentTemplate = params
                        .filter(item => (hasCompareData ? item.componentIndex < series.length / 2 : true))
                        .map(
                            item =>
                                `<div style="display: flex; align-items: center; height: 36px;">${item.marker} ${
                                    item.seriesName
                                } <span style="margin-left: auto;">${typeof item.value === 'object' ? '' : item.value}</span></div>`
                        )
                        .join('')
                    const base = `${titleTemplate}${contentTemplate}`

                    if (compareDates.start && compareDates.end) {
                        const title =
                            dateType === 'HOUR'
                                ? lightFormat(compareDates.start, 'yyyy/MM/dd')
                                : `${lightFormat(compareDates.start, 'yyyy/MM/dd')} 至 ${lightFormat(compareDates.end, 'yyyy/MM/dd')}：`
                        const titleTemplate = `
                        <div style="font-weight: 600; display: flex; align-items: center; height: 36px;">${title}</div>`
                        const contentTemplate = params
                            .filter(item => item.componentIndex >= series.length / 2)
                            .map(
                                item =>
                                    `<div style="display: flex; align-items: center; height: 36px;">${
                                        item.marker
                                    } ${item.seriesName?.replace('-compare', '')} <span style="margin-left: auto;">${
                                        typeof item.value === 'object' ? '' : item.value
                                    }</span></div>`
                            )
                            .join('')

                        const compareTemplate = `${titleTemplate}${contentTemplate}`

                        return `${base}${compareTemplate}`
                    }

                    return base
                }
                return ''
            }
        } satisfies EchartsOption['tooltip']

        instance.setOption(
            {
                series,
                xAxis,
                legend,
                tooltip
            } satisfies EchartsOption,
            {
                replaceMerge: ['series', 'xAxis', 'legend']
            }
        )
    }, [res.value, compareRes.value, selectedCondition, dates.start, compareDates.start, compareDates.end, dateType, dates.end])

    return (
        <VersionOverlay spaceVersion={spaceVersionEnum.BASIC}>
            <Card title="流量趋势">
                <Condition
                    options={OPTIONS}
                    multiple
                    onConditionChange={v => setSelectedCondition(v as string[])}
                    optionalCompare
                    onDateTypeChange={setDateType}
                    onDateChange={setDates}
                    onCompareDateChange={setCompareDates}
                />

                <Box style={{ width: '100%', height: 420 }} ref={chartDomRef} />
            </Card>
        </VersionOverlay>
    )
}
