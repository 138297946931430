import { Button, IconFont, pointer, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { TitleInContentModal } from '@lighthouse/shared'
import copyToClipboard from 'copy-to-clipboard'
import { useAtomValue } from 'jotai'
import { add } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import { userDataAtom } from '@/atoms/auth/state'
import { openSpaceGrade } from '@/components/SpaceGrade/event'
import * as srv from '@/services'

import { Invite } from './invite'

const promotionImage = getAssetUrl('space', 'promo_ticket.png')

const inviteBackground = getAssetUrl('space', 'invite_background.svg')

const SCxBody = styled.div`
    position: relative;
    overflow: hidden;
`

const SCxClose = styled.div`
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 16px;
    color: var(--color-gray-400);
    ${pointer}

    &:hover {
        color: var(--color-gray-500);
    }
`

const SCxContainer = styled.div<{ background: string }>`
    background: ${({ background }) => `url('${background}')`};
    border-radius: 10px;
    overflow: hidden;
`

const SCxHeader = styled.div`
    position: relative;
    padding: 40px 0 23px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
`

const SCxTitle = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 6px;
`

const SCxBold = styled.div`
    font-size: 24px;
    color: var(--color-black);
    font-weight: 800;
`

const SCxColorText = styled.div`
    font-size: 24px;
    background: linear-gradient(89.66deg, #2dbae4 46.28%, #9152ff 89.49%);
    background-clip: text;
    line-height: 34px;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
`

const SCxLargeText = styled.b`
    font-family: DIN Alternate;
    font-size: 36px;
    font-weight: 700;
`

const SCxSmallText = styled.b`
    font-family: DIN Alternate;
    font-size: 20px;
    font-weight: 500;
`

const SCxDescription = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
    text-align: center;
    width: 216px;
    font-weight: 500;
    font-size: var(--font-size-normal);
    line-height: 20px;
`

const SCxPromoImage = styled.img`
    position: absolute;
    top: 81px;
    right: 19px;
    width: 62px;
`

const SCxContent = styled.div`
    padding: 12px 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-top: 2px solid var(--color-white);
    background-color: var(--color-white);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff96;
`

const SCxLink = styled.a`
    text-decoration: none;
    color: var(--color-main);
    ${pointer}
    &:active {
        color: var(--color-main);
    }
`

const SCxInviteLink = styled(Button)`
    margin: 17px 0 14px 0;
    padding: 12px 0;
    width: 204px;
    height: auto;
    font-size: var(--font-size-base);
    box-shadow: 0px 8px 16px 0px #5f52ff52;
    background: linear-gradient(91.12deg, #5551ff 4%, #7e51ff 97.52%);
`

const SCxInviteDescription = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
`

const SCxLine = styled.div`
    height: 1px;
    margin: 0 9px;
    background-color: var(--color-gray-600);
    display: flex;
    align-items: center;
    gap: 8px;
`

const SCxDot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--color-gray-600);
`

interface PromotionProps {
    open: boolean
    onChange?: (open: boolean) => void
}

export const Promotion: React.FC<PromotionProps> = ({ open, onChange }) => {
    const { host } = window.location
    const dotLength = Math.round(472 / 14)
    const dotArr = Array.from({ length: dotLength })
    const [isCopy, setIsCopy] = useState(false)
    const userData = useAtomValue(userDataAtom)
    const { invitationCode } = userData

    const { data } = useSWR(
        ['getPromoInviteUsers', pointer],
        () => {
            return srv.getPromoInviteUsers()
        },
        {
            revalidateOnFocus: false
        }
    )

    const handleClose = useCallback(() => {
        onChange?.(false)
    }, [onChange])

    const handleCopyLink = useCallback(() => {
        if (isCopy) {
            return
        }
        setIsCopy(true)
        const inviteText = `嘿！我正在使用ByeCode制作网站，无需开发即可轻松实现名片、网站、作品集制作，点击链接注册立即获得95折优惠，快来和我一起无代码搭建应用吧。链接：https://${host}/account/login?invite_code=${invitationCode} 邀请码：${invitationCode}`
        copyToClipboard(inviteText)
    }, [host, invitationCode, isCopy])

    const handleUsePromo = useCallback(() => {
        openSpaceGrade('version_upgrade')
    }, [])

    useEffect(() => {
        if (isCopy) {
            const timer = setTimeout(() => {
                setIsCopy(false)
            }, 3000)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [isCopy])

    const inviteLength = data?.users.length ?? 0

    const promoText = useMemo(() => {
        if (inviteLength === 0) {
            return (
                <Text size={14} color="var(--color-gray-500)">
                    还未邀请任何新用户，快去邀请吧！
                </Text>
            )
        }
        if (inviteLength < 2) {
            const needInviteNum = 2 - inviteLength
            return (
                <>
                    <Text size={14} color="var(--color-gray-500)">
                        再邀请【{needInviteNum}】人即可获得【9折】优惠，
                    </Text>
                    <Text size={14} color="var(--color-gray-500)">
                        你已获得【95折】优惠！<SCxLink onClick={handleUsePromo}>使用优惠券购买</SCxLink>
                    </Text>
                </>
            )
        }
        if (inviteLength < 5) {
            const needInviteNum = 5 - inviteLength
            return (
                <>
                    <Text size={14} color="var(--color-gray-500)">
                        再邀请【{needInviteNum}】人即可获得【85折】优惠，
                    </Text>
                    <Text size={14} color="var(--color-gray-500)">
                        你已获得【9折】优惠！<SCxLink onClick={handleUsePromo}>使用优惠券购买</SCxLink>
                    </Text>
                </>
            )
        }
        if (inviteLength < 10) {
            const needInviteNum = 10 - inviteLength
            return (
                <>
                    <Text size={14} color="var(--color-gray-500)">
                        再邀请【{needInviteNum}】人即可获得【79折】优惠，
                    </Text>
                    <Text size={14} color="var(--color-gray-500)">
                        你已获得【85折】优惠！<SCxLink onClick={handleUsePromo}>使用优惠券购买</SCxLink>
                    </Text>
                </>
            )
        }
        return (
            <Text size={14} color="var(--color-gray-500)" whiteSpace='nowrap'>
                已获得最高折扣{' '}
                <Text inline size={20} color="var(--color-main)">
                    7.9
                </Text>
                {' '}折，快去使用吧！
            </Text>
        )
    }, [handleUsePromo, inviteLength])

    const highestOfferNum = useMemo(() => {
        return add(data?.invitationCount ?? 0, 302)
    }, [data?.invitationCount])

    return (
        <TitleInContentModal
            open={open}
            width={472}
            zIndex={200}
            withCloseIcon={false}
            onClose={handleClose}
            styles={{
                desktop: {
                    modal: {
                        background: 'transparent',
                        border: 'none'
                    }
                }
            }}
        >
            <SCxBody>
                <SCxClose onClick={handleClose}>
                    <IconFont type="Close" />
                </SCxClose>
                <SCxContainer background={inviteBackground}>
                    <SCxHeader>
                        <SCxTitle>
                            <SCxBold>邀请新用户注册</SCxBold>
                            <SCxColorText>
                                最高可得<SCxLargeText>79</SCxLargeText>
                                <SCxSmallText>折!</SCxSmallText>
                            </SCxColorText>
                        </SCxTitle>
                        <SCxDescription>
                            <Text algin="center">被邀请注册用户可同时获得95折，</Text>
                            <Text algin="center">获得折扣适用于个人任意空间</Text>
                        </SCxDescription>
                        <SCxPromoImage src={promotionImage} alt="promo" />
                    </SCxHeader>
                    <SCxContent>
                        {promoText}
                        {/* <Text>最高可得79折!</Text> */}
                        {data?.users && data?.users.length >= 10 ? (
                            <>
                                <SCxInviteLink onClick={handleUsePromo} radius={33} type="primary">
                                    使用优惠劵购买
                                </SCxInviteLink>
                                <SCxInviteDescription style={{ cursor: 'pointer' }} onClick={handleCopyLink}>
                                    复制邀请链接
                                    <IconFont
                                        style={{ marginLeft: 4 }}
                                        size={13}
                                        type={isCopy ? 'Tick' : 'Duplicate'}
                                        fill={isCopy ? 'var(--color-main)' : 'var(--color-gray-400)'}
                                    />
                                </SCxInviteDescription>
                            </>
                        ) : (
                            <>
                                <SCxInviteLink
                                    onClick={handleCopyLink}
                                    radius={33}
                                    type="primary"
                                    icon={
                                        <IconFont
                                            style={{ marginRight: 4 }}
                                            size={20}
                                            type={isCopy ? 'Tick' : 'Duplicate'}
                                            fill="var(--color-white)"
                                        />
                                    }
                                >
                                    {isCopy ? '已复制' : '复制链接去分享'}
                                </SCxInviteLink>
                                <SCxInviteDescription>已有{highestOfferNum}个用户获得最高79折优惠！</SCxInviteDescription>
                            </>
                        )}
                    </SCxContent>
                </SCxContainer>
                <SCxLine>
                    {dotArr.map((item, index) => (
                        <SCxDot key={index} />
                    ))}
                </SCxLine>
                <Invite inviteUsers={data?.users || []} />
            </SCxBody>
        </TitleInContentModal>
    )
}
