import React from 'react';

import SettingDrawer from '@/components/ApplicationSetting/SettingDrawer';

interface  ApplicationSettingProps {
}

export const ApplicationSetting: React.FunctionComponent< ApplicationSettingProps> = (props) => {
  return <SettingDrawer />
};

