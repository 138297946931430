import React, { useMemo } from 'react'

import SettingAdvertising from '../SettingAdvertising'
// import SettingLeftPanel from '../SettingLeftPanel'
// import SettingNavbar from '../SettingNavbar'
// import SettingNavbarTitle from '../SettingNavbarTitle'
import SettingAuthentication from '../SettingAuthentication'
import SettingDomain from '../SettingDomain'
import SettingLanguage from '../SettingLanguage'
import SettingSEO from '../SettingSEO'
import SettingSms from '../SettingSms'
// import SettingTabBar from '../SettingTabBar'
import SettingTheme from '../SettingTheme'
import SettingWebApp from '../SettingWebApp'
import SettingWebTag from '../SettingWebTag'

export const PanelRender: React.FC<{ moduleName: string }> = ({ moduleName }) => {
    const RenderComponents = useMemo(() => {
        const Modules = {
            // navbar: SettingNavbar,
            // leftPanel: SettingLeftPanel,
            theme: SettingTheme,
            login: SettingAuthentication,
            // navbarTitle: SettingNavbarTitle,
            // tabBar: SettingTabBar,
            sms: SettingSms,
            lang: SettingLanguage,
            webTag: SettingWebTag,
            seo: SettingSEO,
            domain: SettingDomain,
            advertising: SettingAdvertising,
            webApp: SettingWebApp
        }

        return Modules[moduleName as keyof typeof Modules] as React.FC
    }, [moduleName])
    return <RenderComponents />
}
