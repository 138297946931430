import { Box, Button, Input, Text } from '@byecode/ui'
import type { ApplicationSettingThemeColor } from '@lighthouse/core'
import { nanoid, useUncontrolled } from '@lighthouse/tools'
import React, { useEffect, useState } from 'react'

import { ListItem4ByecodeUi } from '../../ListItemMenu'
import { FillPickerCore } from '../Core'

type ColorAdvanceValue = Omit<ApplicationSettingThemeColor, 'id'>

interface ColorLibraryEditorProps {
    value?: ColorAdvanceValue
    defaultValue?: ColorAdvanceValue
    onChange?: (value: ColorAdvanceValue) => void

    isCreateMode?: boolean
    onCreate?: (value: ApplicationSettingThemeColor) => void
}

export const ColorLibraryEditor = ({ value, defaultValue, onChange, isCreateMode, onCreate }: ColorLibraryEditorProps) => {
    const [_value, _onChange] = useUncontrolled({ value, onChange, defaultValue, finalValue: { label: '', value: '#fff' } })

    const [draftName, setDraftName] = useState(_value.label)

    useEffect(() => {
        setDraftName(value?.label ?? '')
    }, [value?.label])

    const handleNameSave = (v: string) => {
        setDraftName(v)
        if (v === _value.label) {
            return
        }
        _onChange?.({ label: v, value: _value.value })
    }

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>名称</Text>
                <Input
                    autoFocus
                    value={draftName}
                    onChange={e => setDraftName(e.target.value)}
                    onBlur={e => {
                        const { value } = e.currentTarget
                        if (!value) {
                            setDraftName(_value.label)
                            return
                        }
                        handleNameSave(value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            const { value } = e.currentTarget
                            if (!value) {
                                setDraftName(_value.label)
                                return
                            }
                            handleNameSave(value)
                        }
                    }}
                    onFocus={e => {
                        e.target.select()
                    }}
                    placeholder="请输入"
                    style={{ width: 180 }}
                />
            </ListItem4ByecodeUi>
            <FillPickerCore
                hideRecommend
                hideLibrary
                value={_value.value}
                onChange={v => {
                    _onChange?.({ label: _value.label, value: v })
                }}
            />
            {isCreateMode && (
                <Box py={8}>
                    <Button
                        type="primary"
                        block
                        size="lg"
                        onClick={() => onCreate?.({ ..._value, label: _value.label || _value.value, id: nanoid() })}
                    >
                        创建颜色
                    </Button>
                </Box>
            )}
        </>
    )
}
