import { Button, Flex, IconFont, Popover, Text, Tooltip } from '@byecode/ui'
import { useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { switchAppVersionPreviewAtom } from '@/atoms/application/action'
import { applicationVersionAtom } from '@/atoms/application/state'
import { UrlPreview } from '@/components/ApplicationPublish/PublishWebsite/UrlPreview'

import { PublishedContent } from '../../ApplicationHeader/PublishedContent'

const StyledButton = styled(Button)`
    border: none;
    background-color: var(--color-purple-100);
    &:hover {
        background-color: var(--color-purple-200) !important;
    }
`

const StyledTips = styled.div`
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    color: var(--color-yellow-500);
    background-color: var(--color-yellow-100);
`

export const PreviewButton = () => {
    const [open, setOpen] = useState(false)

    const previewEnabled = useAtomData(
        applicationVersionAtom,
        useCallback(s => s.preview, [])
    )

    const action = useAtomAsyncAction(switchAppVersionPreviewAtom)

    return (
        <Popover opened={open} onChange={setOpen} width={390} position="bottom-end" withinPortal>
            <Tooltip title="预览">
                <Popover.Target>
                    <StyledButton icon={<IconFont type="Play" size={16} color="var(--color-main)" />} />
                </Popover.Target>
            </Tooltip>

            <Popover.Dropdown style={{ padding: 24, borderRadius: 12 }}>
                <PublishedContent
                    header={
                        <Flex direction="column" gap={16}>
                            <Text weight={500} lineHeight="24px">
                                预览应用
                            </Text>
                            <StyledTips>该应用链接仅用于预览和测试，请勿用于实际业务</StyledTips>
                            <UrlPreview hideSettingsButton onLink={() => setOpen(false)} />
                        </Flex>
                    }
                    showShareContent={previewEnabled}
                    footer={
                        previewEnabled ? (
                            <Button
                                block
                                size="lg"
                                loading={action.loading}
                                onClick={() => {
                                    action.run(!previewEnabled)
                                }}
                                style={{ color: 'var(--color-gray-500)' }}
                            >
                                停用预览
                            </Button>
                        ) : (
                            <Button
                                block
                                size="lg"
                                loading={action.loading}
                                onClick={() => {
                                    action.run(!previewEnabled)
                                }}
                                type="primary"
                            >
                                启用预览
                            </Button>
                        )
                    }
                    isPreview
                    onClose={() => setOpen(false)}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
