import { Box } from '@byecode/ui/components/Box'
import type { ColumnType } from '@byecode/ui/components/Table'
import { Table } from '@byecode/ui/components/Table'
import { spaceVersionEnum } from '@lighthouse/shared'
import { lightFormat, subDays } from 'date-fns'
import * as echarts from 'echarts'
import produce from 'immer'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry, useDebounce } from 'react-use'
import { throttle } from 'throttle-debounce'

import { VersionOverlay } from '@/components/SpaceGrade'
import type { AnalysisTrafficTreeRes } from '@/services'
import * as srv from '@/services'

import type { EchartsOption } from '../chart.type'
import { Card } from '../components'
import { Condition, resetTime2Last, resetTime2Zero } from '../components/Condition'
import type { ConditionType } from '../constants'
import { CONDITION_TYPE } from '../constants'

const OPTIONS: { label: string; value: ConditionType }[] = [
    {
        label: '浏览次数',
        value: CONDITION_TYPE.pv
    },
    {
        label: '独立访客',
        value: CONDITION_TYPE.uv
    },
    {
        label: 'IP',
        value: CONDITION_TYPE.ip
    },
    {
        label: '站内总浏览次数',
        value: CONDITION_TYPE.visits
    },
    {
        label: '跳出率',
        value: CONDITION_TYPE.bounceRate
    }
]

const CHART_OPTION: EchartsOption = {
    title: {
        text: '全站总计',
        textStyle: {
            fontSize: 16,
            fontWeight: 400
        },
        left: 'center',
        top: 'center'
    },
    legend: {
        bottom: 'bottom'
    },
    series: [
        {
            name: '流量来源',
            type: 'pie',
            showEmptyCircle: true,
            radius: ['40%', '60%'],
            itemStyle: {
                borderRadius: 2
            },
            label: {
                show: false,
                fontSize: 16,
                fontWeight: 'lighter',
                color: '#475467',
                formatter: `{b} {c}次 {d}%`
            },
            emphasis: {
                label: {
                    show: true
                }
            },
            data: []
        }
    ]
}

type MergedAnalysisTrafficTreeRes = Omit<AnalysisTrafficTreeRes, 'children'> & {
    pvRate: number
    children?: MergedAnalysisTrafficTreeRes[]
}

function getPvRateSourceRes(data: AnalysisTrafficTreeRes[], sum: number): MergedAnalysisTrafficTreeRes[] {
    return data.map<MergedAnalysisTrafficTreeRes>(item => {
        return {
            ...item,
            children: item.children && getPvRateSourceRes(item.children, sum),
            pvRate: sum === 0 ? 0 : Number((item.pv / sum).toFixed(2))
        }
    })
}

const COLUMNS: ColumnType<MergedAnalysisTrafficTreeRes>[] = [
    {
        title: '来源形式',
        key: 'name'
    },
    {
        title: '来访次数',
        key: 'pv',
        align: 'right',
        sorter: (a, b) => a.pv - b.pv
    },
    {
        title: '独立访客',
        key: 'uv',
        align: 'right',
        sorter: (a, b) => a.uv - b.uv
    },
    {
        title: 'IP',
        key: 'ip',
        align: 'right',
        sorter: (a, b) => a.ip - b.ip
    },
    {
        title: '站内总浏览次数',
        key: 'visits',
        align: 'right',
        sorter: (a, b) => a.visits - b.visits
    },
    {
        title: '跳出率',
        key: 'bounceRate',
        align: 'right',
        sorter: (a, b) => a.bounceRate - b.bounceRate,
        formatter(v) {
            return `${Number(v) * 100}%`
        }
    }
]

const EXTRA_COLUMNS: ColumnType<MergedAnalysisTrafficTreeRes>[] = [
    {
        title: '来访次数占比',
        key: 'pvRate',
        align: 'right',
        sorter: (a, b) => a.pvRate - b.pvRate,
        formatter(v) {
            return `${Number(v) * 100}%`
        }
    }
]

function getTextAndSubText(data: MergedAnalysisTrafficTreeRes, key: ConditionType) {
    switch (key) {
        case CONDITION_TYPE.pv: {
            return `${data.pv}次`
        }

        case CONDITION_TYPE.uv: {
            return `${data.uv}个`
        }

        case CONDITION_TYPE.ip: {
            return `${data.ip}个`
        }

        case CONDITION_TYPE.visits: {
            return `${data.visits}次`
        }

        case CONDITION_TYPE.bounceRate: {
            return `${data.bounceRate * 100}%`
        }

        default: {
            return ''
        }
    }
}

const FORMAT = 'yyyy-MM-dd HH:mm:ss'
const INIT_CONDITION = CONDITION_TYPE.pv

export const TrafficSource = () => {
    const chartDomRef = useRef<HTMLDivElement | null>(null)
    const echartsRef = useRef<echarts.ECharts | null>(null)

    useLayoutEffect(() => {
        const dom = chartDomRef.current
        if (!dom) {
            return
        }

        const instance = echarts.init(dom)
        instance.setOption(CHART_OPTION)
        echartsRef.current = instance

        const resize = throttle(300, () => {
            instance.resize()
        })
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
            instance.dispose()
            echartsRef.current = null
        }
    }, [])

    const [selectedCondition, setSelectedCondition] = useState<ConditionType>(INIT_CONDITION)

    const [dateType, setDateType] = useState<'HOUR' | 'DAY'>('DAY')
    const [dates, setDates] = useState(() => {
        const start = subDays(new Date(), 7)
        resetTime2Zero(start)
        const end = subDays(new Date(), 1)
        resetTime2Last(end)
        return { start, end }
    })

    const res = useAsyncRetry(
        () =>
            srv
                .fetchAnalysisTrafficSource({
                    startTime: lightFormat(dates.start, FORMAT),
                    endTime: lightFormat(dates.end, FORMAT),
                    type: dateType
                })
                .then(res => getPvRateSourceRes(res, res[0].pv)),
        [dateType, dates.start, dates.end]
    )

    useEffect(() => {
        const instance = echartsRef.current
        if (!instance) {
            return
        }

        const data = res.value
        if (!data) {
            return
        }

        const root = data[0]
        const { children } = root

        const series = {
            data: children?.map(item => ({
                name: item.name,
                value: item[selectedCondition]
            }))
        } satisfies EchartsOption['series']

        instance.setOption<EchartsOption>({
            title: {
                subtext: getTextAndSubText(root, selectedCondition)
            },
            series
        })
    }, [res.value, selectedCondition])

    const [currentTab, setCurrentTab] = useState('origin')
    const [searchWords, setSearchWords] = useState<string>()
    const [debounceSearchWords, setDebounceSearchWords] = useState<string>()

    useDebounce(
        () => {
            setDebounceSearchWords(searchWords)
        },
        300,
        [searchWords]
    )

    const filterData = useMemo(() => {
        if (!res.value) {
            return
        }

        if (!debounceSearchWords) {
            return res.value
        }

        const hasWords = (r: MergedAnalysisTrafficTreeRes) =>
            r.name.toLocaleLowerCase().includes(debounceSearchWords.toLocaleLowerCase().trim())

        // 递归过滤
        const recursionSearch = (children: MergedAnalysisTrafficTreeRes) => {
            const flag = hasWords(children)
            if (flag) {
                return true
            }

            if (children.children) {
                return children.children.some(recursionSearch)
            }

            return false
        }

        return res.value.filter(recursionSearch)
    }, [res.value, debounceSearchWords])

    const columns = useMemo(
        () => (currentTab === 'origin' ? COLUMNS : produce(COLUMNS, draft => void draft.splice(2, 0, ...EXTRA_COLUMNS))),
        [currentTab]
    )

    return (
        <VersionOverlay spaceVersion={spaceVersionEnum.BASIC}>
            <Card title="流量来源">
                <Condition
                    options={OPTIONS}
                    multiple={false}
                    defaultCondition={[INIT_CONDITION]}
                    onConditionChange={v => {
                        setSelectedCondition(v as ConditionType)
                    }}
                    onDateTypeChange={setDateType}
                    onDateChange={setDates}
                />
                <Box style={{ width: '100%', height: 362 }} ref={chartDomRef} />
                <Table
                    data={filterData}
                    columns={columns}
                    rowKey="name"
                    tabs={{
                        fullWidth: true,
                        style: { width: 220 },
                        data: [
                            { label: '来源分类', value: 'origin' },
                            { label: '搜索词', value: 'word' }
                        ],
                        value: currentTab,
                        onChange: setCurrentTab
                    }}
                    search
                    onSearchValueChange={e => setSearchWords(e.target.value)}
                />
            </Card>
        </VersionOverlay>
    )
}
