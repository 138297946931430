import { Empty, Loading } from '@byecode/ui'
import { SimpleTable } from '@lighthouse/shared'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'

import * as srv from '@/services'

interface TablePreviewProps {
    appId: string
    envId: string
    dsId: string
    nodeId: string
    active: boolean
}

const SCxPlaceHolder = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TablePreview: React.FC<TablePreviewProps> = ({ appId, envId, dsId, nodeId, active }) => {
    const {
        data: tableData,
        isMutating,
        trigger
    } = useSWRMutation(`node-preview-${nodeId}`, _ => {
        return srv.getNodePreviewData({ appId, envId, dsId, nodeId })
    })

    useEffect(() => {
        if (active) {
            trigger()
        }
    }, [active, nodeId, trigger])

    const content = useMemo(() => {
        if (isMutating) {
            return <Loading outlined />
        }
        if (!tableData) {
            return <Empty title="没有数据" />
        }
        const { datasource, records } = tableData
        return <SimpleTable dataSource={datasource} records={records} />
    }, [isMutating, tableData])

    return <SCxPlaceHolder>{content}</SCxPlaceHolder>
}
