import { Anchor, Empty, Flex, Switch, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationSettingWebApp, ApplicationWebsiteSetting } from '@lighthouse/core'
import { ListItemPaddingByecodeUi, spaceVersionEnum, useAtomAction } from '@lighthouse/shared'
import { Image } from '@mantine/core'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { debounce } from 'throttle-debounce'

import { setAppLayoutModeAtom, updateAppAtom } from '@/atoms/application/action'
import { openSpaceGradeConfirm, SpaceGradeTag } from '@/components/SpaceGrade'
import { useApplicationSetting } from '@/hooks/useApplication'
import { useSpaceQuota } from '@/shared/reusable'

import SettingBlock from '../SettingBlock'
import { SettingCard } from '../SettingCard'
import * as CM from '../styles'
import { ModuleType } from '../type'

interface SettingWebAppProps {}
const SCxContainer = styled.div`
    width: 100%;
`

const SettingWebApp: React.FunctionComponent<SettingWebAppProps> = props => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { run: setMode } = useAtomAction(setAppLayoutModeAtom)
    const { data: quota } = useSpaceQuota()

    const webAppSetting = appSetting?.webApp

    const { run: updateAppSetting } = useAtomAction(updateAppAtom)
    const methods = useForm<ApplicationSettingWebApp>({
        mode: 'onChange',
        defaultValues: webAppSetting
    })
    const { watch, control } = methods

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingWebApp) => {
                const isSuccess = await updateAppSetting({
                    config: {
                        webApp: value
                    }
                })
            }),
        [updateAppSetting]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(value as ApplicationSettingWebApp)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    const handleGotoAppInfo = () => {
        setMode({
            type: 'setting',
            currentTab: ModuleType.webTag
        })
    }

    return (
        <SCxContainer>
            <Controller
                control={control}
                name="isPublishApp"
                render={({ field }) => (
                    <SettingCard
                        title={
                            <Flex alignItems='center' gap={8}>
                                <Text>下载为APP (PWA)</Text>
                                <SpaceGradeTag value={spaceVersionEnum.PROFESSIONAL} />
                            </Flex>
                        }
                        describe="此功能使您的应用可在任何移动端设备上下载，而无需经过应用商店批准。"
                        opened={field.value ?? false}
                        onChangeCollapse={v => {
                            if (quota && quota?.currentVersionCode < spaceVersionEnum.PROFESSIONAL) {
                                openSpaceGradeConfirm(
                                    'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ii5wgzhrxu7pv9hk',
                                    'PROFESSIONAL'
                                )
                                return
                            }

                            field.onChange(v)
                        }}
                    >
                        <Flex justifyContent="center">
                            <Empty
                                styles={{
                                    root: {
                                        flex: 'unset!important',
                                        marginTop: 16
                                    },
                                    content: {
                                        textAlign: 'left',
                                        lineHeight: '22px'
                                    },
                                    wrapper: {
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start'
                                    }
                                }}
                                icon={<Image width={266} height={144} radius={12} src={getAssetUrl('application', 'webApp_close.png')} />}
                            />
                        </Flex>
                    </SettingCard>
                )}
            />
        </SCxContainer>
    )
}

export default SettingWebApp
