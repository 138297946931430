import { flex, pointer } from '@byecode/ui'
import { Text } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import { TagIcon } from '@/components/TagIcon'

import * as CM from '../../styles'

interface ListItemProps {
    tagIcon: string
    tagColor: string
    tagLabel: string
    iconColor: string
    rightLabel?: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SCxContainer = styled.div`
    width: 100%;
    height: 52px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    ${pointer}
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxLeftItem = styled.div`
    ${flex};
    align-items: center;
    gap: 8px;
`
const SCxRightItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

export const ListItem: React.FunctionComponent<ListItemProps> = ({ tagColor, tagIcon, tagLabel, iconColor, rightLabel, onClick }) => {
    return (
        <SCxContainer onClick={onClick}>
            <SCxLeftItem>
                <TagIcon icon={tagIcon} size={36} iconSize={20} radius={8} iconColor={iconColor} background={tagColor} />
                <Text size={14}>{tagLabel}</Text>
            </SCxLeftItem>
            <SCxRightItem>
                {rightLabel}
                <CM.Icon type="ArrowRightSmall" />
            </SCxRightItem>
        </SCxContainer>
    )
}
