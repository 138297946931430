import styled from 'styled-components'

export const FormContainer = styled.div`
    min-width: 484px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const IntroduceContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex: 2;
    max-width: 1300px;
    background: linear-gradient(to right, #e1e7f2 0%, #dfe5ef 26.11%, #d4dbe9 45.79%, #c3cadf 63.22%, #d0d8e9 82.76%, #ced8ec 94.59%);
`

export const IntroduceWrapper = styled.div`
    position: absolute;
    inset: 0;
    padding: 55px 0 88px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: clip;
`

export const IntroduceTitleHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

export const IntroduceFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 25px;
`

export const LogoImg = styled.img``

export const IntroduceBackgroundImg = styled.img`
    padding-right: 40px;
`

export const Filing = styled.div`
    position: absolute;
    bottom: 16px;
    display: flex;
    align-items: center;
    line-height: 20px;
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: red; */

    @media (width <= 500px) {
        flex-direction: column-reverse;
        ${IntroduceFooter} {
            display: none;
        }

        ${IntroduceWrapper} {
            align-items: flex-end;
        }
    }
`

export const GradientText = styled.div`
    background: linear-gradient(272deg, #2621ff 0.69%, #a2a0ff 94.32%);
    background-clip: text;
    line-height: 32px;
    font-size: 22px;
    letter-spacing: 6px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
