import { MultiSelect } from '@byecode/ui'
import { DataSourceType, FilterWay } from '@lighthouse/core'
import { ListItem4ByecodeUi, SelectDataSource } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FieldSelect } from '@/components/FieldSelect'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDsListAndFieldOptions } from '@/hooks/useDataSource'

import { getFieldOptions } from './help'
import { RenderLabel } from './MultiSelectRenderLabel'

interface SelectFilterProps {
    prefixName: string
}

export const SelectFilter: React.FC<SelectFilterProps> = ({ prefixName }) => {
    const { control, setValue } = useFormContext()
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pointer = useWatch({ control, name: `${prefixName}.pointer` })
    const fieldPointer = useWatch({ control, name: `${prefixName}.fieldPointer` })
    const { dataSourceList, fieldOptions, dataSource } = useDsListAndFieldOptions(currentAppId, envId, pointer)

    const selectFieldOptions = getFieldOptions(fieldOptions, dataSource, FilterWay.selectFilter)

    const excludeDataSourceList = useMemo(
        () => dataSourceList.filter(ds => ds.type !== DataSourceType.aggregateDataSource),
        [dataSourceList]
    )

    const selectOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        const { schema } = dataSource
        const selectField = schema?.[fieldPointer]
        if (!selectField || selectField.type !== 'select') {
            return []
        }
        return selectField.select.options.map(item => ({
            label: item.label,
            value: item.label
        }))
    }, [dataSource, fieldPointer])

    return (
        <>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项来源</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.pointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <SelectDataSource
                                withinPortal
                                size="lg"
                                value={value}
                                onChange={onChange}
                                dataSourceList={excludeDataSourceList}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>选项</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.fieldPointer`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <FieldSelect
                                dsId={pointer}
                                style={{
                                    width: 180
                                }}
                                options={selectFieldOptions}
                                value={value}
                                onChange={val => {
                                    onChange(val)
                                    const ds = find(item => item.id === pointer, dataSourceList)
                                    if (!ds) {
                                        return
                                    }
                                    const { schema } = ds
                                    const selectField = schema?.[val]
                                    if (!selectField || selectField.type !== 'select') {
                                        return
                                    }
                                    const customOptions = selectField.select.options.map(item => item.label)
                                    setValue(`${prefixName}.customOptions`, customOptions)
                                }}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>自定义可选项</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.customOptions`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <MultiSelect
                                dropdownWidth={220}
                                position="bottom-start"
                                styles={{ root: { width: 180 } }}
                                placeholder="请选择"
                                options={selectOptions}
                                value={value}
                                customInputValueRender={RenderLabel}
                                onChange={val => onChange(val)}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
