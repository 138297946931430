import type { Option, SegmentedControlItem } from '@byecode/ui'
import { Divider, Group, IconFont, Popover, SegmentedControl, Select, Text } from '@byecode/ui'
import { Flex } from '@byecode/ui/components/Flex'
import type { BlockAbstract, ContainerBlockAbstract, TabsBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE, BlockType, TABS_SEGMENTED_RADIUS, TABS_TYPE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import FormSegmentedControl from '../Common/FormSegmentedControl'
import { VisibilityFilter } from '../Common/VisibilityFilter'
import type { SettingsTabEnum } from '../constants'
import { POSITION_ALIGN_CONTROL, SETTINGS_TAB_ENUM, SETTINGS_TABS } from '../constants'
import { FontConfigureFormFragment } from '../TextV2/FontConfigureFormFragment'
import SegmentedRadius0Svg from './assets/radius0.svg?react'
import SegmentedRadius4Svg from './assets/radius4.svg?react'
import SegmentedRadius8Svg from './assets/radius8.svg?react'
import SegmentedRadius12Svg from './assets/radius12.svg?react'
import TabBarSettings from './TabBarSettings'
import { TabsTypeSelector } from './TabsTypeSelector'

/** 标签容器圆角 */
const CONTAINER_TABS_RADIUS_OPTIONS: SegmentedControlItem[] = [
    { label: <SegmentedRadius0Svg />, value: TABS_SEGMENTED_RADIUS.xs },
    { label: <SegmentedRadius4Svg />, value: TABS_SEGMENTED_RADIUS.sm },
    { label: <SegmentedRadius8Svg />, value: TABS_SEGMENTED_RADIUS.md },
    { label: <SegmentedRadius12Svg />, value: TABS_SEGMENTED_RADIUS.xl }
]

function isContainerBlock(block: BlockAbstract): block is ContainerBlockAbstract {
    return block.type === BlockType.container
}

interface TabsSettingsProps {
    blockId: string
}

export const TabsSettings: React.FC<TabsSettingsProps> = ({ blockId }) => {
    const { control, watch } = useFormContext<TabsBlockConfig>()
    const [variant, associatedContainer] = watch(['variant', 'associatedContainer'])
    const isLineType = variant === TABS_TYPE.LINE
    const isSegmentedType = variant === TABS_TYPE.SEGMENTED

    const [currentTab, setCurrentTab] = useState<SettingsTabEnum>(SETTINGS_TAB_ENUM.normal)

    const { pageId } = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => ({ pageId: s?.pageId || '' }), [])
    )

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)

    useEffect(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    const containerBlockOptions = useAtomData(
        pageBlocksAtom,
        useCallback(
            dict => {
                const blocks = (!!pageId && dict[pageId]) || []
                return blocks.filter(isContainerBlock).map<Option>(item => ({
                    label: item.title,
                    value: item.id,
                    icon: <IconFont type="LayerTagContainerBlock" size={16} />,
                    onMouseEnter: () => {
                        setBlockHighlight(draft => {
                            draft.push(item.id)
                        })
                        document.querySelector(`div[data-node-id="${item.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    },
                    onMouseLeave: () => {
                        setBlockHighlight(draft => draft.filter(id => id !== item.id))
                        document.querySelector(`div[data-node-id="${item.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }))
            },
            [pageId, setBlockHighlight]
        )
    )

    const viewList = useAtomData(
        pageBlocksAtom,
        useCallback(
            dict => {
                const blocks = (!!pageId && dict[pageId]) || []
                const containerBlock = blocks.find(item => item.id === associatedContainer) as ContainerBlockAbstract
                if (!containerBlock) {
                    return
                }
                return containerBlock.config.viewList
            },
            [associatedContainer, pageId]
        )
    )

    const colorSystemMethods = useColorSystemAction()

    return (
        <>
            <Flex grow mt={12} px={16}>
                <SegmentedControl
                    data={SETTINGS_TABS}
                    fullWidth
                    size="lg"
                    value={currentTab}
                    onChange={v => setCurrentTab(v as SettingsTabEnum)}
                />
            </Flex>

            {currentTab === SETTINGS_TAB_ENUM.normal && (
                <>
                    <Group label="标签类型">
                        <ListItem4ByecodeUi grow>
                            <Controller name="variant" control={control} render={({ field }) => <TabsTypeSelector {...field} />} />
                        </ListItem4ByecodeUi>
                    </Group>

                    {/* {isSegmentedType && (
                        <>
                            <Divider />
                            <Group label="选项样式">
                                <Controller
                                    control={control}
                                    name="radius"
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            {...field}
                                            style={{ flex: '0 0 180px' }}
                                            label="圆角"
                                            data={CONTAINER_TABS_RADIUS_OPTIONS}
                                        />
                                    )}
                                />
                            </Group>
                        </>
                    )} */}

                    <Divider />

                    <Group label="绑定容器">
                        <Controller
                            control={control}
                            name="associatedContainer"
                            render={({ field }) => <Select {...field} options={containerBlockOptions} />}
                        />
                    </Group>

                    <Divider />

                    <Fragment key={variant}>
                        <Group label="标签">
                            <TabBarSettings variant={variant} isSegmentedType={isSegmentedType} viewList={viewList} />
                        </Group>

                        <Divider />

                        <Group label="布局">
                            <Controller
                                control={control}
                                name={`variantMap.${variant}.align`}
                                render={({ field }) => (
                                    <FormSegmentedControl
                                        {...field}
                                        style={{ flex: '0 0 180px' }}
                                        label="对齐"
                                        data={POSITION_ALIGN_CONTROL}
                                    />
                                )}
                            />
                        </Group>

                        <Divider />

                        <StyleSetting
                            containerId={blockId}
                            key={variant}
                            host="container"
                            prefixPath={`variantMap.${variant}`}
                            beforeSlot={
                                isLineType ? (
                                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                        <Text>线条</Text>
                                        <Controller
                                            control={control}
                                            name="lineColor"
                                            render={({ field }) => (
                                                <Popover width={300} withinPortal>
                                                    <Popover.Target>
                                                        <FilledInput value={field.value} onChange={field.onChange} style={{ width: 180 }} />
                                                    </Popover.Target>
                                                    {/* <PopoverDropdownWithClose title="填充">
                                                    <FillPicker
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                                    />
                                                </PopoverDropdownWithClose> */}
                                                    <FillPickerPopoverDropdown
                                                        title="填充"
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                                        {...colorSystemMethods}
                                                    />
                                                </Popover>
                                            )}
                                        />
                                    </ListItem4ByecodeUi>
                                ) : null
                            }
                        />
                    </Fragment>

                    <Divider />

                    <FontConfigureFormFragment label="文本" hideEllipsis />
                </>
            )}

            {currentTab === SETTINGS_TAB_ENUM.visibility && <VisibilityFilter />}
        </>
    )
}
