import { pointer } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface AddWeChatPayAccountProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SCXContainer = styled.div`
    width: 100%;
    height: 36px;
    margin-top: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    ${pointer}
`

export const AddWeChatPayAccount: React.FunctionComponent<AddWeChatPayAccountProps> = ({ onClick }) => {
    return (
        <SCXContainer onClick={onClick}>
            <CM.Icon color="var(--color-main)" type="Add" />
            <CM.Text color="var(--color-main)"> 添加账号</CM.Text>
        </SCXContainer>
    )
}
