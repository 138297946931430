import { Button } from '@byecode/ui'
import { Grid } from '@mantine/core'
import React from 'react'

import { Unit } from '../Mics'

export const Block: React.FC = () => {
    return (
        <Unit title="块按钮">
            <Grid gutter={20} style={{ width: 300 }}>
                <Grid.Col span={12}>
                    <Button type="primary" block>
                        块按钮
                    </Button>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button danger block>
                        块按钮
                    </Button>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button block>块按钮</Button>
                </Grid.Col>
            </Grid>
        </Unit>
    )
}
