import React from 'react'
import { useParams } from 'react-router-dom'

import { FlowDetail } from '@/containers/FlowDetail'

const FlowDetailPage: React.FC = () => {
    const { flowId = '' } = useParams()

    return <FlowDetail id={flowId} closeAfterDelete={false} />
}

export default FlowDetailPage
