import { flex } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    background-color: var(--color-main);
`
export const TooltipContainer = styled.div`
    padding: 8px 8px 12px 8px;
    width: 280px;
    ${flex}
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    background-color: var(--color-main);
    border-radius: 8px;
`

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;

    @media (any-hover: hover) {
        &:hover {
            .slider-control-centerright,
            .slider-control-centerleft {
                opacity: 1;
            }
        }
    }

    .slider-control-centerright,
    .slider-control-centerleft {
        background: rgba(102, 102, 102, 0.6);
        font-size: 32px;
        border: none;
        margin: 0 4px;
        border-radius: 100%;
        box-shadow: none;
        width: 20px;
        height: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;

        button {
            background: transparent !important;
            display: flex;
            align-items: center;

            iconpark-icon {
                /* transform: scaleY(0.75); */
                transform-origin: center center;
            }
        }
    }

    .dotContainer {
        padding: 4px !important;
        background: rgba(0, 0, 0, 0.3);
        gap: 4px;
        border-radius: 38px;
        top: -4px !important;

        .active {
            &::before {
                background: var(--color-white) !important;
            }
        }

        .dot {
            position: relative;
            width: 8px;
            height: 8px;

            &::before {
                position: absolute;
                inset: 0;
                content: '';
                background: rgba(255, 255, 255, 0.5);
                border-radius: 100%;
            }

            svg {
                display: none;
            }
        }
    }
`
export const Footer = styled.div`
    width: 100%;
    padding: 0 4px;
    ${flex}
    justify-content: space-between;
    align-items: center;
`

export const FooterLeftFill = styled.div``

export const FooterRightFill = styled.div`
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`

export const PreviewImg = styled.img`
    display: block;
    width: 100%;
    /* height: 148px; */
    border-radius: 5px;
    background-color: var(--color-gray-100);
`

export const Text = styled.div<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? 14}px;
    color: ${({ color }) => color ?? '#fff'};
    line-height: 1.5;
    white-space: pre-line;
`

export const Item = styled.div<{ color: string }>`
    width: 300px;
    height: 200px;
    background-color: ${({ color }) => color};
`

export const Button = styled.button<{ color?: string; backgroundColor?: string }>`
    padding: 6px 8px;
    height: 32px;
    color: ${({ color }) => color ?? '#fff'};
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
    border-radius: 6px;
    font-size: var(--font-size-normal);
`
