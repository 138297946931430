import type { FlowEdge, FlowNode, FlowNodeButtonClickPayload, WorkflowType } from '@lighthouse/shared'
import React from 'react'

interface FlowContextValue {
    id?: string
    type?: WorkflowType
    errorMap?: Record<string, boolean>
    readonly?: boolean
    onChange?: (nodes: FlowNode[], edges: FlowEdge[]) => void
    onEdgeButtonClick?: (params: FlowNodeButtonClickPayload) => void
}

interface FlowProviderProps extends FlowContextValue {
    children: React.ReactNode
}

export const FlowContext = React.createContext<FlowContextValue>({})

export const FlowProvider: React.FC<FlowProviderProps> = ({ readonly, children, type, onChange, onEdgeButtonClick }) => {
    const contextValue = React.useMemo(
        () => ({ readonly, type, onChange, onEdgeButtonClick }),
        [onChange, onEdgeButtonClick, readonly, type]
    )

    return <FlowContext.Provider value={contextValue}>{children}</FlowContext.Provider>
}

export const useFlow = () => React.useContext(FlowContext)
