import type { Option } from '@byecode/ui'
import { Checkbox, IconFont, Input, Popover } from '@byecode/ui'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

interface CheckboxSelectProps {
    options?: Option[]
    value?: string[]
    onChange?: (value: string[]) => void
}

const SCxCheckboxSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

const SCxInput = styled(Input)`
    flex: 1;
    color: var(--color-gray-400);
`

const SCxListWrapper = styled.div`
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
`

const SCxListOptionWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 12px;
    cursor: pointer;
`

const SCxListOptionLabel = styled.p`
    margin-left: 8px;
`

export const CheckboxSelect: React.FC<CheckboxSelectProps> = ({ value, options, onChange }) => {
    const [open, setOpen] = useState(false)

    const optionMap = options?.reduce((acc, { value: itemValue, label }) => {
        acc[itemValue] = typeof label === 'string' ? label : ''
        return acc
    }, {} as Record<string, string>)

    const handleChange = useCallback(
        (itemValue: string) => {
            const newValue = value?.includes(itemValue) ? value?.filter(val => val !== itemValue) : [...(value || []), itemValue]
            onChange?.(newValue)
        },
        [onChange, value]
    )

    return (
        <SCxCheckboxSelectWrapper>
            <Popover opened={open} onChange={setOpen}>
                <Popover.Target>
                    <SCxInput suffix={<IconFont type="ArrowDownSmall" />} value={value?.map(v => optionMap?.[v])?.join(',')} />
                </Popover.Target>
                <Popover.Dropdown>
                    <SCxListWrapper>
                        {options?.map(({ value: itemValue, label }) => (
                            <SCxListOptionWrapper key={itemValue} onClick={() => handleChange(itemValue)}>
                                <Checkbox size="sm" checked={value?.includes(itemValue)} />
                                <SCxListOptionLabel>{label}</SCxListOptionLabel>
                            </SCxListOptionWrapper>
                        ))}
                    </SCxListWrapper>
                </Popover.Dropdown>
            </Popover>
        </SCxCheckboxSelectWrapper>
    )
}
