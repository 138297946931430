import { IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Wrapper = styled.div<{ width?: string }>`
    width: ${({ width }) => width || '100%'};
    min-height: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    overflow: hidden;
    cursor: pointer;

    &:not(.variableDisabled) {
        &:hover {
            background-color: var(--color-gray-200);
        }
    }
    &.variableDisabled {
        cursor: not-allowed;
        background-color: var(--color-gray-100);
    }
`

export const Container = styled.div`
    position: relative;
    max-width: 100%;
`

export const TagContainer = styled.div`
    flex: 1;
    padding: 4px 8px;
    display: flex;
    align-items: stretch;

    & > * {
        min-height: 24px;
    }
`

export const TagWrapper = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean; color?: string }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    ${pointer}

    &:hover {
        color: ${({ isAction }) => isAction && 'var(--color-black)'};
    }
`
