import { Button, Divider, Flex, IconFont, Tooltip } from '@byecode/ui'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { APPLICATION_LAYOUT_MODE_TYPE } from '@/atoms/application/constants'
import { setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { workflowListAtom } from '@/atoms/workflow/state'
import { useAppLayoutMode, useCurrentAppID } from '@/hooks/useApplication'

import { useRecentPageHistory } from '../help'
import { ApplicationActions } from './Actions'
import { ApplicationInfo } from './ApplicationInfo'
import * as SC from './styles'

const TABS = [
    {
        label: '数据',
        icon: <IconFont type="NewTable" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.dataSource,
        path: './dataSource'
    },
    {
        label: '设计',
        icon: <IconFont type="RulerCrossPen" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.preview,
        path: './page'
    },
    {
        label: '流程',
        icon: <IconFont type="panel" size={20} />,
        value: APPLICATION_LAYOUT_MODE_TYPE.flow,
        path: './flow'
    }
]

const NAV_BUTTONS = [
    {
        label: '分析',
        icon: 'Charts',
        value: './analyze/traffic'
    },
    {
        label: '集成',
        icon: 'Gallery',
        value: './otherPlatforms'
    },
    {
        label: '设置',
        icon: 'Setting',
        value: './settings'
    }
]

interface ApplicationHeaderProps {
    appId: string
    onClose?: () => void
    onOpenSetting?: () => void
}

const ApplicationHeader: React.FC<ApplicationHeaderProps> = () => {
    const mode = useAppLayoutMode()
    const workflowList = useAtomData(workflowListAtom)
    const navigate = useNavigate()

    const appId = useCurrentAppID()
    const { flowId, dsId, dsPath } = useRecentPageHistory(appId)

    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)

    const onTabChange = useCallback(
        (tab: string) => {
            if (mode.type === tab) {
                return
            }

            switch (tab) {
                case APPLICATION_LAYOUT_MODE_TYPE.preview: {
                    navigate({ pathname: `./page` })
                    break
                }

                case APPLICATION_LAYOUT_MODE_TYPE.flow: {
                    const flow = workflowList.find(item => item.id === flowId)
                    // 为了解决线上版本没有编辑版本工作流的问题
                    if (flow) {
                        navigate({ pathname: `./flow/${flowId}` })
                        return
                    }
                    navigate({ pathname: `./flow` })
                    break
                }

                case APPLICATION_LAYOUT_MODE_TYPE.dataSource: {
                    setCurrentDataSourceId({ dsId })
                    navigate({ pathname: `.${dsPath}` })
                    break
                }

                default: {
                    break
                }
            }
        },
        [dsId, dsPath, flowId, mode.type, navigate, setCurrentDataSourceId, workflowList]
    )

    return (
        <SC.Container>
            <SC.Header>
                <Flex alignItems="center">
                    <Tooltip title="回到工作台">
                        <Link to="/workbench">
                            <SC.WorkBench type="text" icon={<IconFont type="Home" fill="var(--color-gray-400)" size={20} />} />
                        </Link>
                    </Tooltip>
                    <Divider direction="vertical" styles={{ root: { height: 16, margin: '0 14px 0 6px' } }} />

                    {/* <ApplicationTabs /> */}
                    <ApplicationInfo />
                </Flex>

                {/* <ApplicationMode appId={application?.id ?? ''} /> */}

                <SC.PositionCenter>
                    <SC.StyledSegmentControl size="lg" data={TABS} value={mode.type} onChange={onTabChange} />
                    {/* <ApplicationInfo /> */}
                </SC.PositionCenter>

                <Flex alignItems="center" gap={8}>
                    {NAV_BUTTONS.map(item => (
                        <Tooltip key={item.value} title={item.label}>
                            <NavLink to={item.value}>
                                <SC.NavButton type="text" icon={<IconFont type={item.icon} size={20} />} />
                            </NavLink>
                        </Tooltip>
                    ))}

                    <ApplicationActions />

                    {/* <Menu width={180} trapFocus>
                        <Menu.Target>
                            <UserAvatar avatar={userData.avatar} name={userData.username} size="middle" />
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => setIsOpenAccount(true)}>账号设置</Menu.Item>
                            <Menu.Item onClick={handleLogout}>退出登录</Menu.Item>
                        </Menu.Dropdown>
                    </Menu> */}
                    {/* <SC.RightActionButton
                        onClick={() => {
                            if (mode.type === APPLICATION_LAYOUT_MODE_TYPE.analyze) {
                                return
                            }
                            setMode({ type: APPLICATION_LAYOUT_MODE_TYPE.analyze })
                            navigate({ pathname: './analyze/traffic' })
                        }}
                        className={classNames({ active: mode.type === APPLICATION_LAYOUT_MODE_TYPE.analyze })}
                    >
                        <IconFont type="Charts" />
                        <Text>分析</Text>
                    </SC.RightActionButton>
                    <OtherPlatformsButton />
                    <UserSetting />
                    <ApplicationSetting />

                    <ApplicationActions />
                    <ApplicationPublish /> */}
                </Flex>
            </SC.Header>
        </SC.Container>
    )
}

export default ApplicationHeader
