import type { NumberOption } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { ListItemMenu } from '../ListItemMenu'
import { SettingOptions } from './SettingOptions'

interface NumberOptionSettingProps {
    value?: NumberOption[]
    onChange?: (val: NumberOption[]) => void
}

function getValueIsNumber(val: number | undefined) {
    if (typeof val === 'number') {
        return true
    }
    return !!val
}

export const NumberOptionSetting: React.FC<NumberOptionSettingProps> = ({ value, onChange }) => {
    const initValue = useMemo(() => value || [], [value])

    const validateValue = useMemo(() => {
        if (!value) {
            return []
        }
        return value.filter(item => item.label && item.value && (getValueIsNumber(item.value?.[0]) || getValueIsNumber(item.value?.[1])))
    }, [value])

    const text = useMemo(() => {
        if (validateValue.length === 0) {
            return '未设置'
        }
        return `已设${validateValue.length}个`
    }, [validateValue.length])

    return (
        <ListItemMenu
            width={433}
            compact
            enableHover={false}
            label="预设数值范围"
            value={text}
            popupContent={
                <SettingOptions value={initValue} onChange={onChange} />
                // <NumberOptionSetting value={value} onChange={onChange} />
            }
        />
    )
}
