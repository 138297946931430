import { AppNavBlock } from '@lighthouse/block'
import type { AppNavAbstract } from '@lighthouse/core'
import type { FC } from 'react'
import React, { useCallback } from 'react'

import { useApplicationList } from '@/hooks/useApplication'

interface AppNavBlockControllerProps {
    blockData: AppNavAbstract
}

const AppNavBlockController: FC<AppNavBlockControllerProps> = ({ blockData }) => {
    const appList = useApplicationList()

    return <AppNavBlock blockData={blockData} appList={appList} />
}

export default AppNavBlockController
