import { useEffect, useState } from 'react'

/**
 * 函数防抖让 value 延时改变提高性能
 * @export
 * @param {*} value
 * @param {number} delay
 * @return {*}
 */
export function useDebounce<T>(value: T, delay: number) {
    const [debounceValue, setDebounceValue] = useState(value)
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebounceValue(value)
        }, delay)
        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])
    return debounceValue
}
