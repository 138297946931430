import { Divider, Slider } from '@byecode/ui'
import { type FilterBlockAbstract, FilterBlockMode } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi, ModeSegmented } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { VisibilityFilter } from '../Common/VisibilityFilter'
import { TABS } from './constants'
import { FilterList } from './FilterList'

const SCxHeader = styled.div`
    padding: 12px 16px;
`

const SCxPopupTitle = styled.div`
    margin-bottom: 8px;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);
`

interface FilterSettingProps {
    blockId: string
}

export const FilterSetting: React.FC<FilterSettingProps> = ({ blockId }) => {
    const { control } = useFormContext<FilterBlockAbstract['config']>()
    const mode = useWatch({ control, name: 'mode' })
    const disabledWithVersion = useIsDisabledWithVersion()

    return (
        <>
            <SCxHeader>
                <SCxPopupTitle>筛选模式</SCxPopupTitle>
                <Controller
                    control={control}
                    name="mode"
                    render={({ field: { value, onChange } }) => (
                        <ModeSegmented disabled={disabledWithVersion} options={TABS} value={value} onChange={onChange} />
                    )}
                />
            </SCxHeader>
            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi label="筛选项">
                <FilterList blockId={blockId} />
            </Group4ByecodeUi>

            {mode === FilterBlockMode.tile && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group4ByecodeUi label="设计">
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <div>标题宽度</div>
                            <Controller
                                control={control}
                                name="labelWidth"
                                render={({ field }) => (
                                    <Slider
                                        min={1}
                                        max={400}
                                        step={1}
                                        value={field.value ?? 80}
                                        onChange={field.onChange}
                                        style={{ width: 180 }}
                                        inputProps={{
                                            onFocus: e => {
                                                e.target.select()
                                            }
                                        }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group4ByecodeUi>
                </>
            )}
            {/* FilterWay */}
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}
