import { Tooltip } from '@byecode/ui'
import { BlockThumbImageMap } from '@lighthouse/block'
import { Image } from '@mantine/core'
import type { AnyArray } from 'immer/dist/internal'
import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

export interface ViewTypeSwitcherProps {}

export interface ViewTypeSwitcherGridProps {
    options: AnyArray
    value?: string
    disabled?: boolean
    onChange?: (value: string) => void
}

export interface ViewTypeSwitcherItemProps {
    image: string
    label?: string
    color: string
    background: string
    checked?: boolean
    disabled?: boolean
    onCheck?: () => void
}

const SCxContainer = styled.div`
    padding: 12px 16px 0 16px;
`

const SCxHeader = styled.div`
    font-weight: 600;
    padding: 6px 0;
`

const SCxViewTypeSwitchers = styled.div`
    display: grid;
    grid-template-columns: 104px 104px 104px;
    grid-row-gap: 8px;
    justify-content: space-between;
    padding: 8px 0;
`

const SCxViewTypeSwitcherItem = styled.div``

// const SCxStyleWrapper = styled.div<Pick<React.CSSProperties, 'backgroundColor'>>`
//     position: absolute;
//     inset: 0;
//     z-index: 1;
//     background-color: ${({ backgroundColor }) => backgroundColor};
//     border-radius: 16px;
// `

const SCxBlock = styled.button<Pick<React.CSSProperties, 'width' | 'height' | 'background'>>`
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 16px;
    cursor: pointer;
    background-color: ${({ background }) => background || 'var(--color-gray-50)'};
    img {
        -webkit-user-drag: none;
    }

    &:not(:disabled):hover {
        background-color: ${({ background }) => !background && 'var(--color-gray-100)'};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const SCxText = styled.div<{ color?: string }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    bottom: 8px;
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-weight: 500;
    font-size: 12px;
`

const OPTIONS = [
    { label: '表格', value: 'table', image: BlockThumbImageMap.table, color: '#12AF94', background: 'rgba(18, 175, 148, 0.10)' },
    {
        label: '高级表格',
        value: 'advancedTable',
        image: BlockThumbImageMap.advancedTable,
        color: '#2E90FA',
        background: 'rgba(46, 144, 250, 0.10)'
    },
    { label: '列表', value: 'list', image: BlockThumbImageMap.list, color: '#FFB74A', background: 'rgba(255, 183, 74, 0.10)' },
    { label: '画廊', value: 'gallery', image: BlockThumbImageMap.gallery, color: '#F63D68', background: 'rgba(246, 61, 104, 0.10)' },
    { label: '看板', value: 'kanban', image: BlockThumbImageMap.kanban, color: '#5551FF', background: 'rgba(85, 81, 255, 0.10)' },
    { label: '日历', value: 'calendar', image: BlockThumbImageMap.calendar, color: '#00A389', background: 'rgba(0, 163, 137, 0.10)' }
]

// const RELATED_VIEW_OPTIONS = [
//     { label: '关联表格', value: 'relatedTable', icon: 'BlockTable' },
//     // { label: '关联高级表格', value: 'relatedAdvancedTable', icon: 'BlockTable' },
//     { label: '关联列表', value: 'relatedList', icon: 'BlockList' },
//     { label: '关联画廊', value: 'relatedGallery', icon: 'BlockSimpleTable' }
// ]

const ViewTypeSwitcherItem: React.FC<ViewTypeSwitcherItemProps> = ({ image, label, color, background, checked, disabled, onCheck }) => {
    const hoverColor = checked ? color : 'var(--color-gray-500)'
    const activeBackground = checked ? background : undefined
    return (
        <Tooltip title={label}>
            <SCxBlock disabled={disabled} width={104} height={110} background={activeBackground} onClick={onCheck}>
                {/* <SCxStyleWrapper backgroundColor={hoverBackground} /> */}
                <Image fit="cover" style={{ zIndex: 2, position: 'relative' }} radius={16} src={image} />
                <SCxText color={hoverColor}>{label}</SCxText>
            </SCxBlock>
        </Tooltip>
    )
}

export const ViewTypeSwitcherGrid: React.FC<ViewTypeSwitcherGridProps> = ({ value, options, disabled, onChange }) => {
    return (
        <SCxViewTypeSwitchers>
            {options.map(option => (
                <SCxViewTypeSwitcherItem key={option.value}>
                    <ViewTypeSwitcherItem
                        disabled={disabled}
                        checked={option.value === value}
                        {...option}
                        onCheck={() => onChange?.(option.value)}
                    />
                </SCxViewTypeSwitcherItem>
            ))}
        </SCxViewTypeSwitchers>
    )
}

export const ViewTypeSwitcher: React.FC<ViewTypeSwitcherProps> = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const viewTypeOptions = OPTIONS
    return (
        <SCxContainer>
            <SCxHeader>类型</SCxHeader>
            <Controller
                name="viewType"
                render={({ field }) => (
                    <ViewTypeSwitcherGrid
                        disabled={disabledWithVersion}
                        options={viewTypeOptions}
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </SCxContainer>
    )
}
