import { useAtomAction } from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'

import { closeAllPageLayerAtom, closeCurrentPageLayerStackAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom } from '@/atoms/page/state'

/**
 * 页面栈的操作
 * @returns
 */
export const usePageStackActions = () => {
    const { run: closeCurrentPageLayerStack } = useAtomAction(closeCurrentPageLayerStackAtom)
    const { run: closeAllPageLayersAction } = useAtomAction(closeAllPageLayerAtom)

    /**
     * 关闭所有页面弹窗
     */
    const closeAllPageLayers = useCallback(() => {
        closeAllPageLayersAction()
    }, [closeAllPageLayersAction])

    /**
     * 关闭当前页面
     */
    const closeCurrentPageLayer = useCallback(() => {
        closeCurrentPageLayerStack()
    }, [closeCurrentPageLayerStack])

    const peekCurrentPageStack = useAtomCallback(
        useCallback(get => {
            return get(lastPageOfStackAtom)
        }, [])
    )

    return { closeAllPageLayers, closeCurrentPageLayer, peekCurrentPageStack }
}
