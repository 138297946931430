import React from 'react'
import styled from 'styled-components'

interface SettingItemProps {
    label?: string
    className?: string
    children?: React.ReactNode
}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`
const SCxHeader = styled.div`
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
`

const SCxTitle = styled.div`
    width: 100%;
    color: var(--color-gray-900);
    font-size: 14px;
    line-height: 22px; /* 157.143% */
    font-weight: bold;
`

const SCxContent = styled.div`
    width: 100%;
`

export const SettingItem = React.forwardRef<HTMLDivElement, SettingItemProps>(({ label, className, children }, ref) => {
    return (
        <SCxContainer ref={ref} className={className}>
            <SCxHeader>
                <SCxTitle>{label}</SCxTitle>
            </SCxHeader>
            <SCxContent>{children}</SCxContent>
        </SCxContainer>
    )
})
