import type { ContainerBlockConfig, Direction } from '@lighthouse/core'
import { DEFAULT_ACTION } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import { DEFAULT_CONTAINER_DESIGN } from '@/components/BlockSettings/constants'

/**
 * 生成容器block的配置
 *
 * @returns {ContainerBlockConfig}
 */
export const generateContainerBlock = (direction: Direction): ContainerBlockConfig => {
    return {
        viewList: [
            {
                id: nanoid(),
                name: '面板 01'
            }
        ],
        action: DEFAULT_ACTION,
        design: {
            ...DEFAULT_CONTAINER_DESIGN,
            direction
        }
    }
}
