import { type MenuItem, type RadioItem } from '@lighthouse/shared'

import { DATA_MANAGER, DEFAULT, PAGE_COMPONENT } from '@/constants/Block/shared'

export const BLOCK_LIST = [DEFAULT, DATA_MANAGER, PAGE_COMPONENT]

export type PageItemAction = 'DELETE' | 'RENAME' | 'HOME' | 'COPY' | 'COPY_LINK' | 'CUSTOM_ROUTE'

export const menuOperationItems: MenuItem<PageItemAction>[] = [
    {
        section: '',
        items: [
            {
                name: '设为主页',
                icon: 'Home',
                hotkey: '',
                action: 'HOME'
            },
            {
                name: '自定义页面路径',
                icon: 'PropertyLink',
                hotkey: '',
                action: 'CUSTOM_ROUTE'
            },
            {
                name: '重命名',
                icon: 'PencilSimple',
                hotkey: '',
                action: 'RENAME'
            },
            // {
            //     name: '复制页面链接',
            //     icon: 'LinkSimple',
            //     hotkey: '',
            //     action: 'COPY_LINK'
            // },
            {
                name: '复制',
                icon: 'Duplicate',
                hotkey: '',
                action: 'COPY'
            },
            {
                name: '删除',
                icon: 'Trash',
                hotkey: '',
                action: 'DELETE'
            }
        ]
    }
]

export const menuOperationRadioList: RadioItem[] = [
    {
        value: 1,
        label: '公开',
        name: 'visible'
    },
    {
        value: 0,
        label: '登录后可见',
        name: 'visible'
    }
]

export const TABS: {
    label: string
    value: string
}[] = [
    {
        label: '组件',
        value: 'component'
    },
    {
        label: '页面结构',
        value: 'module'
    }
]

export const PAGE_FILTER_OPTIONS = [
    {
        label: '所有页面',
        value: 'all',
        group: 'system'
    },
    {
        label: '公开页面',
        value: 'public',
        group: 'system'
    },
    {
        label: '登陆后可见页面',
        value: 'login',
        group: 'system'
    }
]
