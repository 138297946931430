import { Empty as BEmpty, IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

type Position = 'relative' | 'absolute'

export const TabStyles = {
    tabsList: {
        position: 'relative' as Position,
        gap: '32px',
        borderColor: 'var(--color-gray-200)'
    },
    tab: {
        padding: '9px 0',
        '&:hover': {
            background: 'transparent'
        }
    },
    panel: {
        paddingTop: '0px!important'
    }
}

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const FormItem = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    font-size: var(--font-size-normal);
    padding: 4px 0;
`

export const FormItemLabelWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 82px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FormItemLabel = styled.span`
    position: relative;
`

export const FormItemContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    flex: 1;
`

export const ConfigureContent = styled.div`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 304px;
`

export const ListItem = styled.div``

export const Paragraph = styled.div``

export const Icon = styled(IconFont)`
    color: var(--color-gray-400);
    ${pointer}
    &:hover {
        color: var(--color-gray-900);
    }
`

export const PanelContent = styled.div`
    width: 100%;
    height: 230px;
    overflow-y: auto;
`

export const FilterWrapper = styled.div`
    width: 673px;
`

export const PreviewContent = styled.div`
    padding: 12px 0;
    height: 240px;
`

export const TabText = styled.div<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? 'var(--color-main)' : 'var(--color-gray-400)')};
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
    display: flex;
    align-items: center;
    position: relative;
`

export const TabTooltip = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Empty = styled(BEmpty)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
