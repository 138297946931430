import { flex, Input, SegmentedControl, Select } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div``

export const Header = styled.div`
    height: 32px;
    padding: 4px 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
`

export const FieldIcon = styled.div<{ color?: string }>`
    width: 32px;
    height: 32px;
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-right: 6px;
`

export const Label = styled.div<{ color?: string; bold?: boolean }>`
    width: 140px;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    text-align: left;
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const Text = styled.span<{ color?: string; bold?: boolean }>`
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    color: ${({ color }) => color ?? 'var(--color-gray-900)'};
    font-size: var(--font-size-normal);
`

export const RightFill = styled.div`
    position: relative;
    flex: 1;
    ${flex}
    justify-content: flex-end;
    align-items: center;
`

export const ViewSettingsSelect = styled(Select)``

export const ViewSettingsInput = styled(Input)``

export const Segmented = styled(SegmentedControl)`
    width: 180px;
`
