import styled, { css } from 'styled-components'

export const Form = styled.form`
    padding: 0 24px;
`
export const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: var(--color-black);
    margin-bottom: 8px;
`
export const Description = styled.div`
    font-size: 12px;
    line-height: 24px;
    color: var(--color-gray-500);
    margin-bottom: 32px;
`

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

export const FieldLabel = styled.label<{ required?: boolean }>`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding: 4px 0;
    color: var(--color-gray-700);
    font-size: 14px;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                color: var(--color-red-500);
                margin-left: 4px;
            }
        `}
`
export const FieldError = styled.div`
    margin-top: 6px;
    font-size: 14px;
    color: var(--color-red-500);
`
export const Textarea = styled.textarea`
    background-color: var(--color-gray-100);
    border: 1.5px solid transparent;
    font-size: 14px;
    padding: 8px;
    border-radius: 8px;
    color: var(--color-black);
    resize: none;
    &:disabled {
        cursor: not-allowed;
    }
    &::placeholder {
        color: var(--color-gray-300);
    }

    &:placeholder {
        font-size: 14px;
        color: var(--color-gray-400);
    }

    &:focus,
    &:focus-within {
        background-color: var(--color-white);
        border-color: var(--color-main);
    }
`
