import { IconFont } from '@byecode/ui'
import type { AggregatorNode } from '@lighthouse/shared'
import { type AggregatorDataInputConfig, type AggregatorDataInputNodeData, AggregatorNodeType } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import type { NodeProps } from 'reactflow'
import { Position, useEdges, useNodes } from 'reactflow'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'

import { peekPrevNodes, pickInfoFromNodeProps } from '../../utils'
import { validateAggregatorNodeConfig } from '../../validators'
import { defaultNodeTypeColor, NodeTypeColorMap } from '../constants'
import { Handler } from '../Handler'

export type DataInputNodeProps = NodeProps<AggregatorDataInputNodeData<AggregatorDataInputConfig>>

const SCxNodeWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    height: 66px;
    padding: 12px;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    background-color: var(--color-white);

    &[data-state='inactive'] {
        border-color: transparent;
        box-shadow: 0 0 0 1px var(--color-red-500);

        &[data-selected='true'] {
            box-shadow: 0 0 0 2px var(--color-red-500), 0px 0px 0px 4px #ff000033;
        }
    }

    &[data-selected='true'] {
        border-color: transparent;
        box-shadow: 0 0 0 2px var(--color-purple-500), 0px 0px 0px 4px #5551ff33;
    }
`

const SCxNodeIconWrapper = styled.div<{ background: string }>`
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ background }) => background};
`

const SCxNodeInfoWrapper = styled.div``

const SCxNodeTitle = styled.div`
    font-size: var(--font-size-normal);
    margin-bottom: 4px;
`
const SCxNodeInfo = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
`

export const DataInputNode: React.FC<DataInputNodeProps> = props => {
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const currentNode = pickInfoFromNodeProps(props)
    // // const store = useStoreApi()
    // const nodes = useNodes() as AggregatorNode[]
    // const edges = useEdges()
    const { id, selected, data } = props
    const { config } = data

    const dsName = useMemo(() => {
        const dataSource = find(item => item.id === config.dsId, dataSourceList)
        if (!dataSource) {
            return ''
        }
        return dataSource.name
    }, [config.dsId, dataSourceList])

    const nodeConfigState = useMemo(() => {
        const validatePass = validateAggregatorNodeConfig({ node: currentNode, dataSourceList })

        return validatePass ? 'active' : 'inactive'
    }, [currentNode, dataSourceList])

    const iconColor = useMemo(() => {
        if (nodeConfigState === 'inactive') {
            return defaultNodeTypeColor
        }
        return NodeTypeColorMap[AggregatorNodeType.DATA_INPUT]
    }, [nodeConfigState])

    return (
        <SCxNodeWrapper data-selected={selected} data-state={nodeConfigState}>
            <SCxNodeIconWrapper background={nodeConfigState === 'inactive' ? 'var(--color-gray-100)' : '#E9FAF0'}>
                <IconFont type="BlockTable" size={24} color={iconColor} />
            </SCxNodeIconWrapper>
            <SCxNodeInfoWrapper>
                <SCxNodeTitle>{config.name}</SCxNodeTitle>
                <SCxNodeInfo>{dsName}</SCxNodeInfo>
            </SCxNodeInfoWrapper>
            <Handler type="source" position={Position.Right} id={id} state={nodeConfigState} />
        </SCxNodeWrapper>
    )
}
