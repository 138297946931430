import { Toast } from '@byecode/ui/components/Toast'
import { FieldBlock, fieldInputEmitter } from '@lighthouse/block'
import type { FieldADTValue, FieldCellValue, InputValueItem, ViewRecordOperateProtocol } from '@lighthouse/core'
import { type BlockAbstract, type FieldBlockAbstract, type FieldInputADTValue, PageType, RecordOpenType } from '@lighthouse/core'
import type { UseUploadFileSParameter } from '@lighthouse/shared'
import {
    aliyunVideoProtocolList,
    fileMaxUploadSize,
    fileSuffixRegex,
    getEmptyFieldInputValue,
    getFieldInputError,
    getFieldInputRules,
    getFileSizeToMB,
    getPrimaryDataSourceEnableFieldIds,
    useAtomAction,
    useAtomData,
    useFormModuleContext
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { openPageStackAtom } from '@/atoms/page/action'
import { pageAtomFamily, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId, useLanguage, usePreviewType } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { usePageCurrentDsAndRecordByBlock } from '@/hooks/usePageCurrentDsAndRecordByBlock'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadInDataSourceManagerParams, uploadVideoInDataSourceManagerParams } from '@/utils/auth'

interface FieldBlockControllerProps {
    blockData: FieldBlockAbstract
    onBlockChange?: (values: AnyObject, origin: BlockAbstract) => Promise<void> | void
}

const FieldBlockController: React.FC<FieldBlockControllerProps> = ({ blockData: fieldBlockData }) => {
    const { config, id: blockId, title } = fieldBlockData
    const { inputType, fieldPointer = '', canEdit, required } = config
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const formModule = useFormModuleContext()
    const { pageId } = useCurrentPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const previewType = usePreviewType()
    const rootPageId = useRootPageContext()
    const stackId = useCurrentStackIdContext()
    const currentPage = useAtomData(pageAtomFamily(pageId))
    const pointer = formModule?.pointer || (currentPage?.dsId ?? '')
    const dataSource = useDataSource(appId, envId, pointer)
    const language = useLanguage()
    const { handleRenderTitle } = useRichTextToTitle()

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const joinFieldCanEdit = useMemo(() => {
        if (currentPage?.type === PageType.edit || currentPage?.type === PageType.creator) {
            return primaryDataSourceFieldIds?.has(fieldPointer)
        }
        return true
    }, [currentPage?.type, fieldPointer, primaryDataSourceFieldIds])

    const blockData = useMemo(
        () => ({ ...fieldBlockData, config: { ...config, canEdit: canEdit && joinFieldCanEdit } }),
        [fieldBlockData, config, canEdit, joinFieldCanEdit]
    )

    const { currentPageRecord, currentBlockRecord, fieldContent, handleValueChange, initRecord } = usePageCurrentDsAndRecordByBlock({
        blockData,
        pointer,
        disabled: Boolean(formModule?.type === 'form')
    })

    const initCellValue = useMemo(
        () => fieldContent ?? getEmptyFieldInputValue(inputType, dataSource?.schema?.[fieldPointer]?.type),
        [dataSource?.schema, fieldContent, fieldPointer, inputType]
    )

    const [currentValue, setCurrentValue] = useState<FieldCellValue>(initCellValue)
    const [isValidFieldInput, setIsValidFieldInput] = useState(false)

    const { onChange: formOnChange, defaultValue, isValid = true, errors } = formModule ?? {}
    const { initInputList, inputList = [] } = defaultValue ?? {}
    const formCellValue = useMemo(() => find(item => item.id === blockId, inputList), [blockId, inputList])
    const field = useMemo(() => dataSource?.schema[fieldPointer], [dataSource?.schema, fieldPointer])

    const fieldError = useMemo(
        () =>
            field && isValidFieldInput
                ? getFieldInputError({
                      value: { value: currentValue, type: inputType } as FieldInputADTValue,
                      rule: getFieldInputRules({ config, title }),
                      fieldType: field.type
                  })
                : undefined,
        [config, currentValue, field, inputType, isValidFieldInput, title]
    )
    const { value, record, initValue, error } = useMemo(() => {
        const initCellContent = find(item => item.id === blockId, initInputList ?? [])
        return formModule?.type === 'form'
            ? {
                  value: formCellValue?.value,
                  initValue: initCellContent?.value,
                  error: errors?.[initCellContent?.id ?? '']
              }
            : {
                  value: initCellValue,
                  initValue: initCellContent?.value,
                  record: currentBlockRecord,
                  error: fieldError
              }
    }, [initInputList, formModule?.type, formCellValue?.value, errors, initCellValue, currentBlockRecord, fieldError, blockId])

    useUpdateEffect(() => {
        setCurrentValue(initCellValue)
    }, [initCellValue])

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )
    const fieldBlockValueMap = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.formState, [])
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                openPageStack(stackFactory({ appId, pageId: page, rootPageId, stackDisplayType: openType }))
            }
        },
        [appId, openPageStack, rootPageId]
    )

    const handleSaveChange = useCallback(
        (v: FieldInputADTValue) => {
            formOnChange?.(blockId, v)
            setCurrentValue(v.value)
            setIsValidFieldInput(true)
            if (formModule.type === 'form') {
                setPageStack(draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        if (!stack.blockRuntimeState.formContainer) {
                            stack.blockRuntimeState.formContainer = {}
                        }
                        stack.blockRuntimeState.formContainer[blockId] = {
                            changed: true
                        }
                    }
                })
            }
        },
        [formOnChange, blockId, formModule.type, setPageStack, rootPageId, stackId]
    )

    const handleChangeSmsCode = useCallback(
        (v: string) => {
            if (formModule.type === 'form') {
                formModule?.onCodeChange?.(blockId, v)
            }
        },
        [blockId, formModule]
    )

    const handleChange = useCallback(
        (v: FieldInputADTValue) => {
            setCurrentValue(v.value)
            const field = dataSource?.schema[fieldPointer]
            if (formModule.type === 'field') {
                const errorNum = Object.keys(fieldError ?? {}).length
                errorNum === 0 && handleValueChange({ value: v.value, ...field } as FieldADTValue)
            }
        },
        [dataSource?.schema, fieldError, fieldPointer, formModule.type, handleValueChange]
    )

    /** 获取关联数据源数据 start */
    const relativeDataSourceConfig = useMemo(
        () => [
            {
                config,
                value: formModule?.type === 'form' ? initValue : initRecord?.content?.[fieldPointer]?.value
            }
        ],
        [config, formModule?.type, initValue, initRecord?.content, fieldPointer]
    )

    const { onFetchDataSource, onLoadMoreData, relativeDataSource, onFetchCascadeOptions, onFetchPersonOptions } = useFieldBlockMethods(
        relativeDataSourceConfig,
        fieldBlockValueMap
    )
    /** 获取关联数据源数据end */

    useEffect(() => {
        if (formModule.type === 'form' && formCellValue) {
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    if (!stack?.formState) {
                        stack.formState = {}
                    }
                    stack.formState[blockId] = { ...clone(formCellValue), source: 'form', dsId: dataSource?.id }
                }
            })
            return
        }
        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                if (!stack?.formState) {
                    stack.formState = {}
                }

                stack.formState[blockId] = {
                    id: blockId,
                    fieldId: fieldPointer,
                    dsId: dataSource?.id,
                    type: inputType,
                    value: currentValue ?? '',
                    fieldType: field?.type,
                    source: 'field'
                } as InputValueItem
            }
        })
    }, [
        blockId,
        currentValue,
        dataSource?.id,
        field?.type,
        fieldPointer,
        formCellValue,
        formModule.type,
        inputType,
        rootPageId,
        setPageStack,
        stackId
    ])

    useEffect(() => {
        return () => {
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack?.formState) {
                    Reflect.deleteProperty(stack.formState, blockId)
                }
            })
        }
    }, [blockId, rootPageId, setPageStack, stackId])

    const uploadyOptions = React.useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadInDataSourceManagerParams({ envId, dsId: pointer, fieldId: fieldPointer, recordId: currentBlockRecord?.id }),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }

                        return true
                    }
                    return true
                }
            }
        }),
        [currentBlockRecord?.id, envId, fieldPointer, pageId, pageName, pointer]
    )

    const videoUploadyOption = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadVideoInDataSourceManagerParams({ envId, dsId: pointer, fieldId: fieldPointer, recordId: currentBlockRecord?.id }),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }
                        if (field?.type === 'video') {
                            const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                            if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                                Toast.error('视频格式错误，请上传 MP4 格式的视频')
                                return false
                            }
                        }

                        return true
                    }
                    return true
                }
            }
        }),
        [currentBlockRecord?.id, envId, field?.type, fieldPointer, pageId, pageName, pointer]
    )

    return (
        <FieldBlock
            uploadyOptions={uploadyOptions}
            videoUploadyOption={videoUploadyOption}
            blockData={blockData}
            value={value}
            previewType={previewType}
            dataSource={dataSource}
            isValid={isValid}
            blockType={formModule?.type === 'form' ? 'form' : 'field'}
            record={record}
            relativeDataSource={relativeDataSource}
            dataSourceList={dataSourceList}
            error={error}
            language={language}
            onChange={handleChange}
            onSaveChange={handleSaveChange}
            richTextUploadOptions={uploadInDataSourceManagerParams({
                envId,
                dsId: pointer,
                fieldId: fieldPointer,
                recordId: currentBlockRecord?.id
            })}
            onFetchDataSource={onFetchDataSource}
            onLoadMoreData={onLoadMoreData}
            onOpenPage={handleOpenPage}
            onFetchCascadeOptions={onFetchCascadeOptions}
            onChangeSmsCode={handleChangeSmsCode}
            onFetchSmsCode={formModule?.type === 'form' ? mobile => srv.getVerifyCode({ mobile, blockId, pageId }) : undefined}
            onFetchPersonOptions={onFetchPersonOptions}
            onRenderTitle={handleRenderTitle}
        />
    )
}

export default FieldBlockController
