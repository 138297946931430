import { Button, IconFont, Tooltip } from '@byecode/ui'
import type {
    ActionFlowNode,
    ApproveActionConfig,
    CarbonCopyActionConfig,
    FindSingleRecordActionConfig,
    FlowEdge,
    FlowNode,
    InitiateApproveActionConfig,
    NotificationActionConfig,
    OpenLinkActionConfig,
    OpenPageActionConfig,
    OpenRecordEditPageActionConfig,
    OpenRecordPageActionConfig,
    WeChatPayActionConfig
} from '@lighthouse/shared'
import {
    copyTypeOptions,
    FlowSelectSourceType,
    nodeTypeIconMap,
    nodeTypeMap,
    otherPlatformNodeTypeList,
    useAtomData
} from '@lighthouse/shared'
import cls from 'classnames'
import { lightFormat } from 'date-fns'
import { find } from 'rambda'
import React, { memo, useCallback, useMemo } from 'react'
import { Handle, Position, useNodes, useReactFlow } from 'reactflow'
import styled from 'styled-components'
import useSWR from 'swr'

import { pageListAtom } from '@/atoms/page/state'
import { systemOperatorOptions } from '@/constants/flow'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import * as srv from '@/services'

import { deleteNode } from '../../utils/deleteNode'
import { validateNode } from '../../utils/nodeValidator'
import ActionNodeOtherPlatform from './ActionNodeOtherPlatform'

export interface ActionNodeProps extends ActionFlowNode {
    isConnectable?: boolean
}

const SCxActionIconWrapper = styled.div`
    position: relative;
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 20px;
    border-radius: 12px;
    color: #999999;
    border: 1px solid var(--color-gray-200);
    cursor: pointer;

    &.flow-node-selected {
        border-color: var(--color-main);
    }

    &.flow-node-success {
        border-width: 2px;
        border-color: var(--color-green-500);
        color: var(--color-green-500);
    }
    &.flow-node-failed {
        border-width: 2px;
        border-color: var(--color-red-500);
        color: var(--color-red-500);
    }
    &.flow-node-running {
        border-width: 2px;
        border-color: var(--color-blue-500);
        color: var(--color-blue-500);
    }
    &.flow-node-not-start {
        border-width: 2px;
        border-color: var(--color-gray-500);
        color: var(--color-gray-500);
        opacity: 0.5;
    }
`

const SCxErrorInfoIconButton = styled.div`
    width: 18px;
    height: 18px;
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: 1;
    background-color: var(--color-white);
`

const SCxErrorInfoIcon = styled(IconFont)`
    font-size: 24px;
    color: var(--color-red-500);
`

const SCxActionNodeWrapper = styled.div`
    display: flex;
    width: 268px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 8px;
`

export const SCxActionIcon = styled(IconFont)`
    font-size: 24px;
    color: var(--color-green-500);
`

const SCxActionSettingWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    background: var(--color-gray-500);

    ${SCxActionNodeWrapper}:hover & {
        opacity: 1;
    }

    &:hover {
        background: var(--color-gray-900);
    }
`

const SCxActionStatusIconWrapper = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.flow-icon-success {
        background-color: var(--color-green-500);
    }
    &.flow-icon-failed {
        background-color: var(--color-red-500);
    }
    &.flow-icon-running {
        background-color: var(--color-blue-500);
    }
    &.flow-icon-not-start {
        background-color: var(--color-gray-500);
    }
`

const SCxActionSettingButton = styled(Button)``

const SCxActionContentWrapper = styled.div`
    position: absolute;
    left: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    font-weight: 400;
`

const SCxActionTitle = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-size: 14px;
    color: #383838;
`

const SCxActionDescription = styled.div`
    font-size: 12px;
    color: #7c8492;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`

const SCxActionErrorInfo = styled.div`
    font-size: 12px;
    color: var(--color-red-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`

const ActionCommonNode: React.FC<ActionNodeProps> = props => {
    const {
        isConnectable,
        id,
        selected,
        data: { name, nodeType, config, status },
        type
    } = props
    // TODO: useNodeExternalData
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const disabledWithVersion = useIsDisabledWithVersion()
    const { data: workflows = [] } = useSWR(`allFlowNodes`, () => srv.listFlowNodes(), {
        revalidateOnFocus: false
    })
    const { data } = useSWR(`/fetch/getUsersAndGroups/${appId}`, () => srv.getUsersAndGroups(), {
        revalidateOnFocus: false
    })
    const { getNodes, getEdges, setNodes, setEdges } = useReactFlow()
    const nodes = useNodes<FlowNode['data']>()
    const { errorMap, readonly, onChange } = useFlow()
    const dsId = config && 'dataSourceId' in config ? config.dataSourceId || '' : ''
    const dataSource = useDataSource(appId, envId, dsId)
    const allPages = useAtomData(pageListAtom)
    const dataSourceName = dataSource?.name || '请选择数据表'

    const getPersonName = useCallback(
        (id: string) => {
            const { users, groups } = data || { users: [], groups: [] }
            const user = find(item => item.uniqueUserId === id, users)
            if (user) {
                return user.isDepart ? `${user.username}(已注销)` : user.username
            }
            const group = find(item => item.groupId === id, groups)
            if (group) {
                return group.groupName
            }
            const systemOperator = find(item => item.value === id, systemOperatorOptions)
            if (systemOperator) {
                return systemOperator.label
            }
            return ''
        },
        [data]
    )

    const nodeDepsName = useMemo(() => {
        switch (nodeType) {
            case 'APPROVE_ACTION': {
                const nodeConfig = config as ApproveActionConfig
                const isAuto = nodeConfig?.type === 'AUTO'
                if (isAuto) {
                    return '自动通过'
                }
                const persons = nodeConfig?.approvers?.map(approver => getPersonName(approver.identifierId)) || []
                return persons.join(',')
            }
            case 'CARBON_COPY_ACTION': {
                const nodeConfig = config as CarbonCopyActionConfig
                const persons = nodeConfig?.users?.map(user => getPersonName(user.identifierId)) || []
                return persons.join(',')
            }
            case 'OPEN_PAGE': {
                const pageId = (config as OpenPageActionConfig)?.openPageId
                const page = find(item => item.id === pageId, allPages)
                return page?.name || '请选择页面'
            }
            case 'OPEN_RECORD_PAGE': {
                const recordPageId = (config as OpenRecordPageActionConfig)?.recordPageId
                const page = find(item => item.id === recordPageId, allPages)
                return page?.name || '请选择页面'
            }
            case 'OPEN_RECORD_EDIT_PAGE': {
                const recordEditPageId = (config as OpenRecordEditPageActionConfig)?.recordEditPageId
                const page = find(item => item.id === recordEditPageId, allPages)
                return page?.name || '请选择页面'
            }
            case 'OPEN_LINK': {
                return !(config as OpenLinkActionConfig)?.link && '请输入链接'
            }
            case 'NOTIFICATION': {
                const nodeConfig = config as NotificationActionConfig
                // 没办法，这里拿不到行，所以显示不了
                return null
                // return nodeConfig.notificationContent || '请输入通知内容'
            }
            case 'COPY': {
                return copyTypeOptions.find(item => item.value === 'CUSTOM')?.label
            }
            case 'FIND_RECORD_ACTION':
            case 'FIND_SINGLE_RECORD_ACTION': {
                const findRecordConfig = config as FindSingleRecordActionConfig
                if (!findRecordConfig) {
                    return ''
                }
                if (findRecordConfig.selectType === FlowSelectSourceType.DATASOURCE) {
                    return dataSourceName
                }

                const node = nodes.find(item => item.id === findRecordConfig.nodeId)
                if (node) {
                    return node.data.name
                }
                return ''
            }

            case 'INITIATE_APPROVE_ACTION': {
                const initiateApproveConfig = config as InitiateApproveActionConfig
                return find(({ id }) => id === initiateApproveConfig?.approveFlowId, workflows)?.name
            }
            case 'UPDATE_RECORD_ACTION': {
                const updateRecordConfig = config as FindSingleRecordActionConfig
                if (!updateRecordConfig) {
                    return ''
                }

                const node = nodes.find(item => item.id === updateRecordConfig.nodeId)
                if (node) {
                    return node.data.name
                }
                return ''
            }
            case 'OPEN_FORM_PAGE':
            case 'CREATE_SINGLE_RECORD_ACTION': {
                return dataSourceName
            }
            case 'WECHAT_PAY': {
                const wechatConfig = config as WeChatPayActionConfig
                if (!wechatConfig.operationType) {
                    return ''
                }
                return wechatConfig.operationType === 'PAY' ? '发起支付' : '申请退款'
            }

            default: {
                return ''
            }
        }
    }, [allPages, config, dataSourceName, getPersonName, nodeType, nodes, workflows])

    const handleDelete = useCallback(
        (ev: React.MouseEvent<HTMLButtonElement>) => {
            ev.stopPropagation()
            ev.preventDefault()

            const nodes = getNodes() as FlowNode[]
            const edges = getEdges() as FlowEdge[]

            const { nodes: newNodes, edges: newEdges } = deleteNode({ id, type }, nodes, edges)

            setNodes(newNodes)
            setEdges(newEdges)

            onChange?.(newNodes, newEdges)
        },
        [getEdges, getNodes, id, onChange, setEdges, setNodes, type]
    )

    // 这个是前端校验，编辑时使用
    const errorMessage = useMemo(() => !validateNode(props) || errorMap?.[id], [errorMap, id, props])

    const logStatus = useMemo(() => {
        if (status) {
            const errorMessage = status.status === 'FAILED' ? <SCxActionErrorInfo>异常原因：{status.log}</SCxActionErrorInfo> : null
            return (
                <>
                    {status.executeTime && (
                        <SCxActionDescription>执行时间：{lightFormat(status.executeTime, 'yyyy/MM/dd HH:mm:ss')}</SCxActionDescription>
                    )}
                    {errorMessage}
                </>
            )
        }
        return null
    }, [status])

    const nodeContentStyles = useMemo<React.CSSProperties>(() => {
        if (status) {
            return {
                position: 'absolute',
                left: 72
            }
        }
        return {
            position: 'initial',
            padding: '8px 12px'
        }
    }, [status])

    const iconWrapperClassNames = useMemo(() => {
        if (status) {
            return cls({
                'flow-node-selected': !readonly && selected,
                'flow-node-success': status.status === 'SUCCESS',
                'flow-node-failed': status.status === 'FAILED',
                'flow-node-running': status.status === 'RUNNING'
                // 'flow-node-not-start': status.status === 'NOT_START'
            })
        }
        return cls({
            'flow-node-selected': selected
        })
    }, [readonly, selected, status])

    const iconClassNames = useMemo(() => {
        if (status) {
            return cls({
                'flow-icon-success': status.status === 'SUCCESS',
                'flow-icon-failed': status.status === 'FAILED',
                'flow-icon-running': status.status === 'RUNNING'
                // 'flow-icon-not-start': status.status === 'NOT_START'
            })
        }
    }, [status])

    const statusIconType = useMemo(() => {
        switch (status?.status) {
            case 'SUCCESS': {
                return 'Tick'
            }
            case 'FAILED': {
                return 'Close'
            }
            case 'RUNNING': {
                return 'Repeat'
            }
            // case 'NOT_START': {
            //     return 'Clock'
            // }
            default: {
                return ''
            }
        }
    }, [status])

    return (
        <SCxActionNodeWrapper>
            {errorMessage && (
                <Tooltip title="配置未完善" placement="top">
                    <SCxErrorInfoIconButton>
                        <SCxErrorInfoIcon type="WarningCircle" />
                    </SCxErrorInfoIconButton>
                </Tooltip>
            )}
            <SCxActionIconWrapper className={iconWrapperClassNames}>
                <SCxActionIcon size={36} type={nodeTypeIconMap[nodeType] ?? ''} fill={nodeTypeMap[nodeType]?.color} />
                {status ? (
                    <SCxActionStatusIconWrapper className={iconClassNames}>
                        <IconFont style={{ color: 'var(--color-white)', cursor: 'default' }} size={12} type={statusIconType} />
                    </SCxActionStatusIconWrapper>
                ) : (
                    <SCxActionSettingWrapper>
                        <SCxActionSettingButton
                            type="text"
                            disabled={disabledWithVersion}
                            icon={<IconFont color="var(--color-white)" size={12} type="Close" />}
                            onClick={handleDelete}
                        />
                    </SCxActionSettingWrapper>
                )}
            </SCxActionIconWrapper>

            <SCxActionContentWrapper style={nodeContentStyles}>
                <SCxActionTitle>{name}</SCxActionTitle>
                <Tooltip title={nodeDepsName} placement="top-start">
                    <SCxActionDescription>{nodeDepsName}</SCxActionDescription>
                </Tooltip>
                {logStatus}
            </SCxActionContentWrapper>
            <Handle
                type="target"
                position={Position.Top}
                id="handle"
                style={{ top: -4, bottom: 'auto', opacity: 0, left: 30, width: 1, height: 1, minWidth: 1, minHeight: 1 }}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Bottom}
                id="handle"
                style={{ bottom: -4, top: 'auto', opacity: 0, left: 30, width: 1, height: 1, minWidth: 1, minHeight: 1 }}
                isConnectable={isConnectable}
            />
        </SCxActionNodeWrapper>
    )
}

const ActionNode: React.FunctionComponent<ActionNodeProps> = props => {
    const {
        data: { nodeType }
    } = props
    if (otherPlatformNodeTypeList.includes(nodeType)) {
        return <ActionNodeOtherPlatform {...props} />
    }
    return <ActionCommonNode {...props} />
}

export default memo(ActionNode)
