import { flex, pointer } from '@byecode/ui'
import { Grid } from '@mantine/core'
import styled from 'styled-components'

import { Icon } from '../styles'

export const LeftItem = styled.div`
    width: calc(100% - 16px);
    padding: 9px 0;
    display: flex;
    align-items: center;
    & > * {
        margin-right: 5px;
    }
`

export const RightItem = styled.div`
    padding: 9px 0;
    ${flex}
    align-items: center;
`

export const LineGuide = styled.div``

export const ListItem = styled.div`
    position: relative;
    width: 100%;
    padding: 0px 16px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    z-index: 2;
    outline: none;
    border-radius: 8px;
    ${pointer}

    ${RightItem} ${Icon} {
        /* display: none; */
        visibility: hidden;
    }

    &:hover {
        background-color: var(--color-gray-100);
    }

    &:hover ${RightItem} ${Icon} {
        visibility: visible;
    }

    &.active {
        background-color: var(--color-gray-100);

        ${Icon} {
            visibility: visible;
        }
    }

    &.isDrag {
        opacity: 0.7;
    }
`

export const RecordItem = styled(Grid)<{ checked: boolean }>`
    margin: 0;
    /* border: 1px solid transparent; */
    /* :hover {
        background-color: ${({ checked }) => (checked ? 'var(--color-main-tint)' : 'var(--color-gray-100)')};
    } */
    background-color: ${({ checked }) => checked && 'var(--color-main-tint)'};
`
