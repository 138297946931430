import type { GroupProps } from '@byecode/ui'
import { Checkbox, Divider, Flex, Group, IconFont } from '@byecode/ui'
import type { UserDepartment, UserGroup } from '@lighthouse/core'
import { PageOpenType } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { Box, Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { addPageGroupsAtom, removePageGroupsAtom, updatePageAtom } from '@/atoms/page/action'
import { pageAtomFamily } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDepartments, usePageGroups } from '@/hooks/usePageGroups'
import { useDataSourceDepartments, useDataSourceUserGroups } from '@/shared/reusable'

import type { TagData } from '../UserSetting/AddMember'
import { AddMember } from '../UserSetting/AddMember'
import { GroupItem } from './GroupItem'

interface PageVisibilityProps {
    pageId: string
    mode: GroupProps['mode']
    addMemberWithinPortal?: boolean
    style?: React.CSSProperties
}

const SCxItem = styled.div`
    /* padding: 0 16px; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`
export const PageVisibility: FC<PageVisibilityProps> = ({ pageId, style, addMemberWithinPortal, mode = 'default' }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const allGroups = useDataSourceUserGroups()
    const { flatData: allDepartments = [] } = useDataSourceDepartments()
    const groups = usePageGroups(pageId)
    const departments = usePageDepartments(pageId)

    const pageContent = useAtomData(pageAtomFamily(pageId))
    const { open = PageOpenType.all } = pageContent ?? {}

    const { run: removePageGroups } = useAtomAction(removePageGroupsAtom)
    const { run: addPageGroups } = useAtomAction(addPageGroupsAtom)
    const { run: updatePage } = useAtomAction(updatePageAtom)

    const value = useMemo(() => {
        const groupTag: TagData[] = groups.map(({ groupId, groupName }) => ({ id: groupId, name: groupName, type: 'group' }))
        const departmentTag: TagData[] = departments.map(({ departmentId, departmentName }) => ({
            id: departmentId,
            name: departmentName,
            type: 'department'
        }))
        return [...groupTag, ...departmentTag]
    }, [departments, groups])

    // 切换可见性设置
    const onVisibilityChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!pageContent) {
                return null
            }
            await updatePage({ id: pageId, open: Number(e.target.value) as PageOpenType })
        },
        [pageContent, updatePage, pageId]
    )
    // 添加用组件可见
    const onAddGroup = useCallback(
        async (data: TagData[]) => {
            if (data.length === 0) {
                return
            }
            const addParams = reduce<TagData, { groups: UserGroup[]; departments: UserDepartment[] }>(
                (preVal, curVal) => {
                    const group = find(p => p.groupId === curVal.id, allGroups)
                    const department = find(p => p.departmentId === curVal.id, allDepartments)

                    if (curVal.type === 'department' && department) {
                        preVal.departments = [...preVal.departments, department]
                    }
                    if (curVal.type === 'group' && group) {
                        preVal.groups = [...preVal.groups, group]
                    }
                    return preVal
                },
                { groups: [], departments: [] },
                data
            )
            await addPageGroups({ pageId, ...addParams })
        },
        [addPageGroups, allDepartments, allGroups, pageId]
    )

    return (
        <Group
            label="页面可见性"
            mode={mode}
            styles={{
                root: {
                    padding: mode === 'none' ? 0 : undefined
                }
            }}
            style={style}
        >
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="ActiveNode-Website" />
                    <Text>所有人可见</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.all}
                    checked={open === PageOpenType.all}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="Lock" />
                    <Text>登录后可见</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.login}
                    checked={open === PageOpenType.login}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>
            <SCxItem>
                <Flex alignItems="center" gap="8px">
                    <IconFont color="var(--color-gray-400)" size={16} type="User" />
                    <Text>指定角色或部门</Text>
                </Flex>
                <Checkbox
                    radius="100%"
                    value={PageOpenType.part}
                    checked={open === PageOpenType.part}
                    size="xs"
                    onChange={onVisibilityChange}
                />
            </SCxItem>

            {open === PageOpenType.part && (
                <>
                    <Divider style={{ margin: '12px 0' }} />
                    <Box>
                        <Text color="var(--color-gray-400)" size={12} mb={8}>
                            以下角色或部门的用户登录后可见
                        </Text>
                        {groups.map(item => (
                            <GroupItem
                                key={item.groupId}
                                name={item.groupName}
                                icon="SpaceTeam"
                                disabled={disabledWithVersion}
                                onRemove={() => removePageGroups({ pageId, id: item.groupId, type: 'group' })}
                            />
                        ))}
                        {departments.map(item => (
                            <GroupItem
                                key={item.departmentId}
                                name={item.departmentName}
                                icon="Departments"
                                disabled={disabledWithVersion}
                                onRemove={() => removePageGroups({ pageId, id: item.departmentId, type: 'department' })}
                            />
                        ))}
                        <AddMember
                            disabled={disabledWithVersion}
                            color="var(--color-main)"
                            withinPortal={addMemberWithinPortal}
                            name="添加"
                            value={value}
                            onAddUsers={onAddGroup}
                        />
                    </Box>
                </>
            )}
        </Group>
    )
}
