import { Button, Modal, Text, Toast } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { unLinkApplicationAtom } from '@/atoms/application/action'
import {
    fetchDataSourceDepartmentAtom,
    fetchDataSourceUserAtom,
    fetchDataSourceUserGroupAtom,
    fetchEnvAtom
} from '@/atoms/dataSource/action'
import { dataSourceEnvAtom } from '@/atoms/dataSource/state'
import { useApplicationList } from '@/hooks/useApplication'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import { deleteUserDepartment } from '@/services'
import { useDataSourceDepartments } from '@/shared/reusable'

import { DEFAULT_VALUES } from '../constant'
import type { FormState } from '../DepartmentModal'
import DepartmentModal from '../DepartmentModal'
import { LinkExtra } from '../LinkExtra'
import PanelHeader from '../PanelHeader'
import * as CM from '../styles'
import TollTop from '../ToolTop'
import UserDepartmentList from '../UserDepartmentList'

interface DepartmentPanelProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px 24px 24px;
    background-color: var(--color-white);
    /* display: flex; */
`

const SCxToolsContainer = styled.div`
    /* height: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`

interface UserDepartmentPanelState {
    keyword: string
    opened: boolean
    selectDepartments: string[]
    mode: 'add' | 'edit'
    defaultValues?: FormState
}

export const DepartmentPanel: React.FunctionComponent<DepartmentPanelProps> = props => {
    const envId = useDataSourceEnvId()
    const dataSourceEnv = useAtomValue(dataSourceEnvAtom)
    const applicationList = useApplicationList()
    const [state, setState] = useImmer<UserDepartmentPanelState>({
        keyword: '',
        opened: false,
        selectDepartments: [],
        mode: 'add'
    })

    const { keyword, selectDepartments, mode, opened, defaultValues } = state

    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceUserGroup } = useAtomAction(fetchDataSourceUserGroupAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)
    const { run: fetchEnv } = useAtomAction(fetchEnvAtom)
    const { run: unLinkApplication } = useAtomAction(unLinkApplicationAtom)
    const { data: departments, flatData: flatDepartments } = useDataSourceDepartments()

    const handleSearchChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.keyword = ev.target.value
            })
        },
        [setState]
    )

    const list = useMemo(() => {
        return keyword.trim() === '' ? departments : flatDepartments.filter(item => item.departmentName?.includes(keyword))
    }, [departments, flatDepartments, keyword])

    const handleOpenCreatModal = useCallback(
        (id?: string) => {
            setState(draft => {
                draft.opened = true
                draft.mode = 'add'
                draft.defaultValues = id
                    ? {
                          ...DEFAULT_VALUES,
                          departParentId: id,
                          departmentId: nanoid()
                      }
                    : { ...DEFAULT_VALUES, departmentId: nanoid() }
            })
        },
        [setState]
    )

    const handleOpenEditModal = useCallback(
        (id: string) => {
            setState(draft => {
                draft.opened = true
                draft.mode = 'edit'
                draft.defaultValues = find(item => item.departmentId === id, flatDepartments)
            })
        },
        [flatDepartments, setState]
    )

    const handleDelete = useCallback(
        (departmentIds: string[]) => {
            Modal.confirm({
                title: '确认删除',
                content: `部门删除后不可恢复，是否继续？？`,
                okStatus: 'error'
            })?.then(async s => {
                if (s) {
                    const isSuccess = await deleteUserDepartment(envId, departmentIds)
                    if (isSuccess) {
                        fetchDataSourceDepartment()
                        fetchDataSourceUser()
                        Toast.success('删除成功')
                    }
                }
            })
        },
        [envId, fetchDataSourceDepartment, fetchDataSourceUser]
    )

    const handleSelectDepartments = useCallback(
        (departmentIds: string[]) => {
            setState(draft => {
                draft.selectDepartments = departmentIds
            })
        },
        [setState]
    )

    const handleCancelLink = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认取消使用？',
            okStatus: 'error',
            okText: '确认'
        })
        if (isConfirm) {
            await unLinkApplication(envId)
            fetchEnv()
            await Promise.all([fetchDataSourceDepartment(), fetchDataSourceUserGroup()])
            fetchDataSourceUser()
        }
    }, [envId, fetchDataSourceDepartment, fetchDataSourceUser, fetchDataSourceUserGroup, fetchEnv, unLinkApplication])

    return (
        <SCxContainer>
            <TollTop
                leftFill={
                    <CM.LeftToolFill>
                        <Text weight={600} color="var(--color-black)" size={16}>
                            部门
                        </Text>
                        <LinkExtra applicationList={applicationList} dataSourceEnv={dataSourceEnv} onUnLinkApplication={handleCancelLink} />
                    </CM.LeftToolFill>
                }
                rightFill={
                    <SCxToolsContainer>
                        {selectDepartments.length > 0 ? (
                            <Button
                                onClick={() => {
                                    handleSelectDepartments(
                                        selectDepartments.length > 0 ? [] : flatDepartments.map(draft => draft.departmentId)
                                    )
                                }}
                                // style={{ color: 'var(--color-main)' }}
                                type="default"
                                // icon={<CM.Icon type="Add" />}
                            >
                                取消选择
                            </Button>
                        ) : (
                            <PanelHeader keyword={keyword} onSearchChange={handleSearchChange} />
                        )}

                        {selectDepartments.length > 0 && (
                            <Button
                                onClick={() => handleDelete(selectDepartments)}
                                style={{ color: 'var(--color-red-500)', borderColor: 'var(--color-red-500)' }}
                                type="text"
                                icon={<CM.Icon type="Trash" color="var(--color-red-500)" />}
                            >
                                删除
                            </Button>
                        )}
                        {selectDepartments.length === 0 && (
                            <Button
                                onClick={() =>
                                    setState(draft => {
                                        draft.opened = true
                                        draft.mode = 'add'
                                        draft.defaultValues = {
                                            departmentId: nanoid(),
                                            ...DEFAULT_VALUES
                                        }
                                    })
                                }
                                type="primary"
                                icon={<CM.Icon type="Add" color="var(--color-white)" />}
                            >
                                创建部门
                            </Button>
                        )}
                    </SCxToolsContainer>
                }
            />
            <UserDepartmentList
                departments={list}
                selectDepartmentIds={selectDepartments}
                flatDepartments={flatDepartments}
                onAddDepartment={handleOpenCreatModal}
                onDeleteDepartment={handleDelete}
                onEditDepartment={handleOpenEditModal}
                onSelectDepartment={handleSelectDepartments}
            />
            <DepartmentModal
                envId={envId}
                mode={mode}
                opened={opened}
                data={defaultValues}
                onClose={() =>
                    setState(draft => {
                        draft.opened = false
                        Reflect.deleteProperty(draft, 'defaultValues')
                    })
                }
            />
        </SCxContainer>
    )
}
