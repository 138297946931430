import React from 'react'
import styled from 'styled-components'

interface ShadowSvgProps {}

const SCxContainer = styled.div`
    position: absolute;
    left: -100px;
    bottom: -196px;
`

export const ShadowSvg: React.FunctionComponent<ShadowSvgProps> = props => {
    return (
        <SCxContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="527" height="535" viewBox="0 0 527 535" fill="none">
                <g filter="url(#filter0_f_52821_50164)">
                    <path
                        d="M449.921 437.468C446.62 561.655 274.935 655.07 267.231 732C224.31 721.743 123.06 682.546 61.4291 607.814C-15.6091 514.399 -156.479 437.468 -125.664 340.757C-94.8483 244.045 -62.9325 23.1461 -59.6309 89.086C-56.3292 155.026 96.6465 189.095 224.31 196.788C351.973 204.481 453.223 313.282 449.921 437.468Z"
                        fill="url(#paint0_angular_52821_50164)"
                        fillOpacity="0.15"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_52821_50164"
                        x="-207"
                        y="0"
                        width="734"
                        height="809"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="38.5" result="effect1_foregroundBlur_52821_50164" />
                    </filter>
                    <radialGradient
                        id="paint0_angular_52821_50164"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(160 404.5) rotate(165.036) scale(263.227 297.284)"
                    >
                        <stop offset="0.0886185" stopColor="#FF10E7" stopOpacity="0.15" />
                        <stop offset="0.19417" stopColor="#469DEE" stopOpacity="0.7" />
                        <stop offset="0.473958" stopColor="#0FD4FF" stopOpacity="0.35" />
                        <stop offset="0.914323" stopColor="#2E1EFF" stopOpacity="0.858138" />
                    </radialGradient>
                </defs>
            </svg>
        </SCxContainer>
    )
}
