import type { ApplicationWebsiteSetting } from '@lighthouse/core'
import {
    APPLICATION_VERSION_PROD,
    ApplicationProvider,
    AuthenticationProvider,
    CollapseManager,
    DomainProvider,
    LanguageProvider,
    PAGE_CONTAINER_HOST,
    useAtomData
} from '@lighthouse/shared'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'

import { AppPublishStateAtom, currentAppVersionIdAtom } from '@/atoms/application/state'
import { ErrorFallback } from '@/components/ErrorFallback'
import { useAppLanguageList, useApplicationSetting, useLanguage } from '@/hooks/useApplication'
import { checkVersionUpdated, getVideoPlayAuth } from '@/services'
import { useDataSourceUsers } from '@/shared/reusable'

import ApplicationHeader from './ApplicationHeader'
import { PublishProgress } from './ApplicationHeader/Actions/PublishProgress'
import { ApplicationMessage } from './ApplicationMessage'
import * as SC from './styles'

interface ApplicationContainerProps {
    id: string
    children?: React.ReactNode
}

/** 轮询应用是否有更新 */
const useSubscriptApplicationUpdate = () => {
    return useSWR('query-app-has-update', () => checkVersionUpdated(), { refreshInterval: 3000 })
}

export const ApplicationContainer: React.FC<ApplicationContainerProps> = ({ id, children }) => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { data: users } = useDataSourceUsers()
    const language = useLanguage()
    const languageList = useAppLanguageList()
    const { pathname } = useLocation()
    const isDataSource = pathname.includes('dataSource') || pathname.includes('aggregate')

    const hasUpdateSwrRes = useSubscriptApplicationUpdate()

    const applicationValue = useMemo(
        () => ({
            personOptions: users,
            isRealityMobile: false,
            isBuilder: true,
            pageTarget: PAGE_CONTAINER_HOST,
            fetchVideoPlayAuth: getVideoPlayAuth,
            hasUpdate: hasUpdateSwrRes.data,
            mutateHasUpdate: hasUpdateSwrRes.mutate
        }),
        [hasUpdateSwrRes.data, hasUpdateSwrRes.mutate, users]
    )

    const languageValue = useMemo(
        () => ({
            language,
            languageList
        }),
        [language, languageList]
    )

    useEffect(() => {
        const list = Object.keys(appSetting.navbar.linkList.list ?? {})
        list.forEach(id => {
            const isExit = CollapseManager.getItemCollapse(id)
            isExit === null ? CollapseManager.setItemCollapse(id, false) : CollapseManager.clearItemCollapse(id)
        })
    }, [appSetting.navbar.linkList.list])

    const appVersionId = useAtomData(currentAppVersionIdAtom)
    const [appPublishState, setAppPublishState] = useAtom(AppPublishStateAtom)

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ApplicationMessage appId={id} />
            <ApplicationProvider value={applicationValue}>
                <LanguageProvider value={languageValue}>
                    <DomainProvider value={appSetting.domain}>
                        <AuthenticationProvider value={appSetting.authentication}>
                            <SC.PageContainer id="appContainer">
                                <ApplicationHeader appId={id} />
                                <SC.PageContent>{children}</SC.PageContent>
                                {/* {!isDataSource && <ApplicationFooter />} */}
                            </SC.PageContainer>
                        </AuthenticationProvider>
                    </DomainProvider>
                </LanguageProvider>

                {/* 应用发布/更新的弹窗 */}
                <PublishProgress
                    key={~~appPublishState.isSuccess}
                    isPublish={appPublishState.isPublish}
                    // inProgress={appPublishState.isProgress}
                    isComplete={appPublishState.isSuccess}
                    // onProgressEnd={() =>
                    //     setAppPublishState(draft => {
                    //         draft.isProgress = false
                    //         draft.isSuccess = true
                    //     })
                    // }
                    onClose={() => {
                        setAppPublishState({
                            isPublish: false,
                            // isProgress: false,
                            isSuccess: false
                        })
                    }}
                />
            </ApplicationProvider>
        </ErrorBoundary>
    )
}
