import { Button } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import { Unit } from '../Mics'

export const SCxButton = styled(Button)``

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Button>取消</Button>
            <Button type="primary">确定</Button>
            <Button type="text">文字按钮</Button>
            <Button type="primary" size="xs">
                xs
            </Button>
            <Button size="sm">sm</Button>
            <Button size="md">md</Button>
            <Button size="lg">lg</Button>
            <Button type="primary" danger>
                危险按钮
            </Button>
            <Button danger>危险按钮</Button>
        </Unit>
    )
}
