import { getAssetUrl } from '@lighthouse/assets'
import React from 'react'
import styled from 'styled-components'

const SCxAssetsDemo = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    padding-bottom: 100px;
    display: block;
`

const emptyImg = getAssetUrl('empty', 'no_datasource.svg')
const empty404 = getAssetUrl('empty', '404.svg')

export const AssetsDemo: React.FC = () => {
    return (
        <SCxAssetsDemo>
            <h1>静态资源演示</h1>
            <img src={emptyImg} alt="empty" />
            <img src={empty404} alt="empty" />
        </SCxAssetsDemo>
    )
}
