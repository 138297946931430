import { useAtomData, USER_DATASOURCE } from '@lighthouse/shared'

import { userRecordAtom } from '@/atoms/dataSource/state'

import { useApplication, useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSource } from './useDataSource'

export const useUserRecord = function () {

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const datasource = useDataSource(appId, envId, USER_DATASOURCE)
    const currentRecord = useAtomData(userRecordAtom)

    return { datasource, record: currentRecord }
}
