import type { Device } from "@lighthouse/core";

export const deviceLabelMap: Record<Device, { icon: string; label: string }> = {
    mobile: {
        icon: 'DeviceMobile',
        label: '仅手机端可见'
    },
    desktop: {
        icon: 'Desktop',
        label: '仅电脑端可见'
    }
}
