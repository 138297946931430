import { Text } from '@mantine/core'
import styled from 'styled-components'

export const Aside = styled.aside<{ height?: string; justifyContent?: React.CSSProperties['justifyContent'] }>`
    width: 100%;
    height: ${({ height }) => height || '100%'};
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
    align-items: center;
    overflow: hidden;
    padding: 40px;
`

export const Image = styled.img`
    width: 163px;
`

export const Box = styled.div<{ width: number }>`
    position: relative;
    max-width: ${({ width }) => `${width}px`};
    width: 100%;
    /* height: 379px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    /* box-shadow: 0 4px 12px rgba(132, 146, 167, 0.12);
    border-radius: 12px; */
`
