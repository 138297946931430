import type { Field } from '@lighthouse/core'
import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { FieldIcon } from '../FieldIcon'
import type { ResizeOffset } from '../Resize'
import { Resize } from '../Resize'

export interface SimpleTableFieldProps {
    field: Field
    width?: number
    onResizerChange?: (id: string, width: number) => void
}

const SCxHeaderFieldItemWrapper = styled.div<{ width?: number }>`
    flex-shrink: 0;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    border-right: 1px solid var(--color-gray-200);
`

const SCxDataSource = styled.div`
    line-height: 20px;
    color: var(--color-gray-400);
    white-space: nowrap;
    font-size: var(--font-size-sm);
`

const SCxField = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
`

const SCxHeaderFieldItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 34px 8px 8px;
`

const SCxHeaderLabel = styled.p`
    width: 100%;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    font-weight: 500;
`

const FieldResizerWrapper = styled.div`
    position: absolute;
    height: 100%;
    z-index: 1;
    right: 0;
    top: 0;
`
// const nonEditableFields = new Set<FieldType>(['id', 'createdBy', 'updatedBy', 'createdTime', 'updatedTime'])

export const SimpleTableField: React.FC<SimpleTableFieldProps> = ({ field, width = 160, onResizerChange }) => {
    // const { id, name, type } = field

    const initWidthRef = useRef(width)

    const isFormula = useMemo(() => {
        if (field.type !== 'aggregation') {
            return false
        }
        return field.aggregation?.isFormula
    }, [field])

    const handleResize = useCallback(
        (offset: ResizeOffset, resizing: boolean) => {
            const { x } = offset
            const result = Math.max(initWidthRef.current + x, 100)
            onResizerChange?.(field.id, result)
            if (!resizing) {
                initWidthRef.current = result
            }
        },
        [field.id, onResizerChange]
    )

    return (
        <SCxHeaderFieldItemWrapper width={width}>
            <SCxHeaderFieldItem>
                <SCxField>
                    <FieldIcon id={field.id} type={field.type} innerType={field.innerType} isFormula={isFormula} />
                    <SCxHeaderLabel>{field.name}</SCxHeaderLabel>
                </SCxField>
                <FieldResizerWrapper>
                    <Resize onChange={handleResize} />
                </FieldResizerWrapper>
            </SCxHeaderFieldItem>
        </SCxHeaderFieldItemWrapper>
    )
}
