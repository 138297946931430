import type { RecordLikeProtocol, SchemaProtocol, TableColumns } from '@lighthouse/core'
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import React from 'react'
import styled from 'styled-components'

import SimpleTableCell from './SimpleTableCell'
import { SimpleTableChecker } from './SimpleTableChecker'

interface SimpleTableRecordProps {
    index: number
    tableProps: TableColumns
    schema: SchemaProtocol['schema']
    record: RecordLikeProtocol
    virtualRow: VirtualItem
    columnVirtualizer: Virtualizer<HTMLDivElement, Element>
    isLast: boolean
}

const SCxSimpleTableRecord = styled.div`
    display: flex;
    width: max-content;
    min-width: 100%;
    border-top: 1px solid var(--color-gray-200);
    background: var(--color-white);

    :hover {
        background-color: var(--color-gray-100);
    }

    & :hover .table-check {
        background-color: var(--color-gray-100);
    }
`

const SCxTableRow = styled.div`
    position: relative;
    width: 100%;
`

const SimpleTableRecord: React.FC<SimpleTableRecordProps> = ({
    record,
    schema,
    index,
    tableProps,
    virtualRow,
    columnVirtualizer,
    isLast
}) => {
    const { id } = record
    return (
        <SCxSimpleTableRecord
            key={id}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
            }}
        >
            <SCxTableRow>
                <SimpleTableChecker index={index} className="table-check" />
                {columnVirtualizer.getVirtualItems().map(virtualColumn => {
                    const item = tableProps[virtualColumn.index]
                    const { id: cId, visible } = item
                    const field = schema?.[cId]
                    return (
                        visible && (
                            <SimpleTableCell
                                record={record}
                                width={virtualColumn.size}
                                isLast={isLast}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: `${virtualColumn.size}px`,
                                    height: `${virtualRow.size}px`,
                                    transform: `translateX(${virtualColumn.start}px)`
                                }}
                                field={{ ...field, id: cId }}
                            />
                        )
                    )
                })}
            </SCxTableRow>
        </SCxSimpleTableRecord>
    )
}

export default SimpleTableRecord
