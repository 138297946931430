import { Divider, Modal, Select, tinyButtons } from '@byecode/ui'
import type { DragEndEvent, DragMoveEvent, DragStartEvent, Modifier } from '@dnd-kit/core'
import { closestCenter, DndContext, DragOverlay, MeasuringStrategy, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { getBlockIcon, getBlockName } from '@lighthouse/block'
import type {
    AppUser,
    BlockAbstract,
    BlockRuntimeState,
    DataSourceAbstract,
    FieldBlockWithDsId,
    PageNode,
    ViewBlockAbstract
} from '@lighthouse/core'
import { BlockType, DIRECTION, PageType } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, CustomViewVisibleData, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import {
    getFieldBlockWithDsId,
    scroll2FlowNode,
    transformNode2FlowLayoutNode,
    useApplicationContext,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import produce, { original } from 'immer'
import { clone, find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { openPageStackAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useNodeToolbarActions } from '@/containers/PageDetail/PageContentV2/useNodeToolbarActions'
import {
    findPageNodeById,
    findParentPageNodeById,
    getContainerViewNodes,
    resizeParentChildrenWidth
} from '@/containers/PageDetail/PageContentV2/utils'
import { PageFieldBlocksProvider, usePageFieldBlocksContext } from '@/contexts/PageContext'
import { useApplication, useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { Item, SortableItem } from './SortableItem'
import type { FlattedNode } from './types'

const Root = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ListWrapper = styled.div`
    flex: 1;
    padding: 4px 12px 32px;
    overflow: auto;
`

const List = styled.ul`
    list-style: none;
    display: block;
    ${tinyButtons}
    overflow: hidden;
    border-radius: 5px;
    background-color: var(--color-gray-100);
`

const PAGE_ICON_MAP: Record<PageType, string> = {
    [PageType.creator]: 'LayerFormPage',
    [PageType.default]: 'PageBroadContent',
    [PageType.document]: 'LayerDetailPage',
    [PageType.edit]: 'LayerEditPage'
}

function getCustomViewData(block: ViewBlockAbstract, dataSourceList: DataSourceAbstract[]) {
    const dataSource = find(ds => ds.id === block.config.pointer, dataSourceList)
    if (!dataSource) {
        return
    }
    return {
        name: block.title,
        dataSource
    }
}

type FlattenParams = {
    blockRuntimeState?: BlockRuntimeState
    blocks: BlockAbstract[]
    indent: number
    parentId?: string
    collapsedIds: string[]
    dataSourceList: DataSourceAbstract[]
    customViewData?: CustomViewVisibleData
}
function flattenPageNode(pageNodes: PageNode[], option: FlattenParams): FlattedNode[] {
    const { blockRuntimeState, blocks, indent, parentId, collapsedIds, dataSourceList, customViewData } = option
    return pageNodes.reduce<FlattedNode[]>((total, current) => {
        const { children, ...rest } = current
        const block = blocks.find(item => item.id === rest.id)

        if (!block) {
            return total
        }

        const collapsed = collapsedIds.includes(current.id)

        const isContainerBlock = block.type === BlockType.container
        const isFormContainerBlock = block.type === BlockType.formContainer
        const isCustomViewBlock = block.type === BlockType.view && block.config.viewType === 'custom'
        const collapsible = isContainerBlock || isFormContainerBlock || isCustomViewBlock

        const currentCustomViewData: CustomViewVisibleData | undefined = isCustomViewBlock
            ? getCustomViewData(block, dataSourceList)
            : undefined

        return [
            ...total,
            {
                name: block.title || getBlockName(block),
                icon: getBlockIcon(block),
                indent,
                parentId,
                collapsible,
                collapsed,
                block,
                children,
                customViewData,
                description: isFormContainerBlock ? dataSourceList.find(item => item.id === block.config.pointer)?.name : undefined,
                ...rest
            },
            ...(collapsed
                ? []
                : flattenPageNode(isContainerBlock ? getContainerViewNodes(current, blockRuntimeState) : children || [], {
                      blockRuntimeState,
                      blocks,
                      indent: indent + 1,
                      parentId: rest.id,
                      collapsedIds,
                      dataSourceList,
                      customViewData: currentCustomViewData || customViewData
                  }))
        ]
    }, [])
}

function buildPageNodeTree(items: FlattedNode[], pageNodes: PageNode[], blockRuntimeState?: BlockRuntimeState): PageNode[] {
    const root = { id: 'root', children: [] as PageNode[] | undefined }
    const tempNodesRecord = { [root.id]: root }
    const tempNodesList = items.map(item => ({ ...item, children: getInitChildren(item) }))

    for (const item of tempNodesList) {
        // omit attr
        const { indent, parentId, block, collapsed, collapsible, description, ...rest } = item
        const _parentId = parentId ?? root.id
        // take nodes records
        const parent = tempNodesRecord[_parentId] ?? tempNodesList.find(i => i.id === _parentId)

        // record this node children in memory
        tempNodesRecord[item.id] = rest
        const currentView = blockRuntimeState?.container?.[_parentId]?.currentView
        if (currentView) {
            parent.children?.find(v => v.id === currentView)?.children?.push(rest)
        } else {
            parent.children?.push(rest)
        }
    }

    return root.children || []

    function getInitChildren(flattedNode: FlattedNode) {
        const pageNode = findPageNodeById(flattedNode.id)(pageNodes)
        if (!pageNode) {
            return
        }

        if (flattedNode.block?.type === BlockType.container) {
            const currentView = blockRuntimeState?.container?.[flattedNode.id]?.currentView
            if (!currentView) {
                return
            }
            const news = clone(pageNode.children || [])
            news.find(item => item.id === currentView)?.children?.splice(0)
            return news
        }

        return flattedNode.block?.type === BlockType.formContainer ||
            (flattedNode.block?.type === 'view' && flattedNode.block.config.viewType === 'custom')
            ? []
            : undefined
    }
}

function getParentId(indent: number, overIndex: number, items: FlattedNode[], prevItem?: FlattedNode) {
    if (indent === 1 || !prevItem) {
        return
    }
    if (indent === prevItem.indent) {
        return prevItem.parentId
    }
    if (indent > prevItem.indent) {
        return prevItem.id
    }
    return items
        .slice(0, overIndex)
        .reverse()
        .find(item => item.indent === indent)?.parentId
}

/** 检查是否包含禁止的block */
function checkIncludeNotAllowedBlock(node: PageNode, blocks: BlockAbstract[]) {
    const relativeBlock = blocks.find(item => item.id === node.id)
    if (relativeBlock && (relativeBlock.type === BlockType.view || relativeBlock.type === BlockType.chart)) {
        return true
    }

    if (node.children) {
        for (const child of node.children) {
            const res = checkIncludeNotAllowedBlock(child, blocks)
            if (res) {
                return true
            }
        }
    }

    return false
}

/** 检查是否是自定义视图后代 */
function checkIsCustomViewChildren(flattedPageNodes: FlattedNode[], parentId: string | undefined): boolean {
    if (!parentId) {
        return false
    }
    const parent = flattedPageNodes.find(item => item.id === parentId)
    if (!parent) {
        return false
    }

    if (parent.block && parent.block.type === BlockType.view && parent.block.config.viewType === 'custom') {
        return true
    }

    if (parent.parentId) {
        return checkIsCustomViewChildren(flattedPageNodes, parent.parentId)
    }

    return false
}

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always
    }
}
const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25
    }
}

export const PageLayers = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const allPageList = usePageList()
    const [pageId, stackId, rootPageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.stackId || '', s?.rootPageId || '', s?.blockRuntimeState] as const, [])
    )

    const [pageType, pageName, pageDsId] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.type || PageType.default, s?.name || '', s?.dsId || ''] as const, [])
    )

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const options = useMemo(() => {
        return allPageList.map(item => ({
            label: item.name,
            value: item.id
        }))
    }, [allPageList])

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => (pageId ? s[pageId] || [] : []), [pageId])
    )

    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => (pageId ? s[pageId] || [] : []), [pageId])
    )

    const flowNodes = useMemo(
        () => transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState),
        [blockRuntimeState, pageBlocks, pageNodes]
    )

    const { asideType, selectedNode } =
        useAtomData(
            pageStackAtomFamily({ stackId, rootPageId }),
            useCallback(s => s?.state, [])
        ) ?? {}
    const { run: setPageNodes } = useAtomAction(pageNodesAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const [collapsedIds, setCollapsedIds] = useState<string[]>([])
    const [activeId, setActiveId] = useState<string>()

    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks, nodes: flowNodes, pageDsId }),
        [flowNodes, pageBlocks, pageDsId]
    )

    const flattedPageNodes = useMemo(() => {
        const collapsed = collapsedIds.includes(pageId)
        return collapsed
            ? []
            : flattenPageNode(pageNodes, {
                  blockRuntimeState,
                  blocks: pageBlocks,
                  indent: 1,
                  collapsedIds: activeId ? [...collapsedIds, activeId] : collapsedIds,
                  dataSourceList
              })
    }, [activeId, blockRuntimeState, collapsedIds, dataSourceList, pageBlocks, pageId, pageNodes])

    const items = useMemo(() => flattedPageNodes.map(item => item.id), [flattedPageNodes])

    const activeFlattedNode = useMemo(() => flattedPageNodes.find(item => item.id === activeId), [activeId, flattedPageNodes])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 4 }
        })
    )
    const [overInfo, setOverInfo] = useState<{ indent: number; parentId?: string } | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.asideType = AsideType.BLOCK
                    stack.state.selectedNode = e.active.id as string
                }
            })
        }
    }

    const onDragMove = ({ delta, active, over }: DragMoveEvent) => {
        if (over) {
            const activeIndex = items.indexOf(active.id as string)
            const overIndex = items.indexOf(over.id as string)
            const activeItem = flattedPageNodes[activeIndex]
            const newItems = arrayMove(flattedPageNodes, activeIndex, overIndex)
            const prevItem = newItems[overIndex - 1]
            const nextItem = newItems[overIndex + 1]
            const currentIndent = activeItem.indent + Math.round(delta.x / 20)

            const max = prevItem ? (prevItem.collapsible ? prevItem.indent + 1 : prevItem.indent) : 1
            const min = nextItem ? nextItem.indent : 1
            const indent = Math.max(min, Math.min(max, currentIndent))
            const parentId = getParentId(indent, overIndex, newItems, prevItem)

            if (activeIndex === overIndex && indent === activeItem.indent) {
                setOverInfo(null)

                return
            }

            if (checkIncludeNotAllowedBlock(activeItem, pageBlocks) && checkIsCustomViewChildren(flattedPageNodes, parentId)) {
                setOverInfo(null)
                return
            }
            setOverInfo({ indent, parentId })
        } else {
            setOverInfo(null)
        }
    }

    const onDragEnd = ({ over, active }: DragEndEvent) => {
        reset()

        if (over && overInfo) {
            const { indent, parentId } = overInfo
            const flattenNodes = flattenPageNode(pageNodes, {
                blockRuntimeState,
                blocks: pageBlocks,
                indent: 1,
                collapsedIds: [],
                dataSourceList
            })
            const activeIndex = flattenNodes.findIndex(item => item.id === active.id)
            const overIndex = flattenNodes.findIndex(item => item.id === over.id)

            const sortedNodes = arrayMove(
                produce(flattenNodes, draft => {
                    draft[activeIndex].parentId = parentId
                    draft[activeIndex].indent = indent
                }),
                activeIndex,
                overIndex
            )
            setPageNodes(draft => {
                const nodes = draft[pageId]
                if (!nodes) {
                    return
                }
                const newNodes = buildPageNodeTree(sortedNodes, nodes, blockRuntimeState)
                const overParent = findParentPageNodeById(active.id as string)(newNodes, pageBlocks)
                if (overParent) {
                    const overParentBlock = pageBlocks.find(item => item.id === overParent.id)
                    if (
                        overParentBlock &&
                        (overParentBlock.type === BlockType.container || overParentBlock.type === BlockType.formContainer) &&
                        overParentBlock.config.design.direction === DIRECTION.horizontal
                    ) {
                        const view = overParent.children?.find(
                            item => item.id === blockRuntimeState?.container?.[overParent.id].currentView
                        )
                        if (view && view.children) {
                            resizeParentChildrenWidth(view.children)
                        }
                    }
                }
                draft[pageId] = newNodes
                srv.updateNodes({ id: pageId, nodes: newNodes })

                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: { prev: original(nodes), next: newNodes }
                })

                scroll2FlowNode(active.id as string)
            })
        }
    }

    const onDragCancel = () => {
        reset()
    }

    const reset = () => {
        setActiveId(undefined)
        setOverInfo(null)
    }

    const actions = useNodeToolbarActions({ pageId, rootPageId, stackId })

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    // 是否是选中的节点后代
    const isSelectedChildren = (id: string) => {
        if (asideType !== AsideType.BLOCK || !selectedNode) {
            return false
        }

        const selectedBlock = pageBlocks.find(item => item.id === selectedNode)
        if (!selectedBlock) {
            return false
        }
        const selectedPageNode = findPageNodeById(selectedNode)(pageNodes)
        if (!selectedPageNode) {
            return false
        }

        if (selectedBlock.type !== BlockType.container && selectedBlock.type !== BlockType.formContainer) {
            return false
        }

        const children =
            selectedBlock.type === BlockType.container
                ? selectedPageNode.children?.find(item => item.id === blockRuntimeState?.container?.[selectedPageNode.id].currentView)
                      ?.children
                : selectedPageNode.children

        if (!children) {
            return false
        }

        const isLeaf = (children: PageNode[]): boolean => {
            for (const node of children) {
                if (node.id === id) {
                    return true
                }

                if (node.children && isLeaf(node.children)) {
                    return true
                }
            }

            return false
        }

        return isLeaf(children)
    }

    return (
        <PageFieldBlocksProvider value={fieldBlocksWithDsId}>
            <Root>
                <Select m={12} options={options} value={pageId} onChange={v => openPageStack(stackFactory({ appId, pageId: v }))} />

                <Divider my={8} />

                <ListWrapper>
                    <List data-ignore-click-away>
                        <Item
                            data={{
                                indent: 0,
                                width: 0,
                                name: pageName,
                                icon: PAGE_ICON_MAP[pageType],
                                id: pageId,
                                description: dataSourceList.find(item => item.id === pageDsId)?.name || '',
                                collapsed: collapsedIds.includes(pageId),
                                collapsible: true
                            }}
                            disabledVisibleIcon
                            onCollapseChange={v => {
                                setCollapsedIds(s => {
                                    if (v) {
                                        return [...s, pageId]
                                    }
                                    return s.filter(id => id !== pageId)
                                })
                            }}
                            isSelected={asideType === AsideType.PAGE}
                            onClick={() => {
                                setPageStack(draft => {
                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                    if (stack) {
                                        stack.state.asideType = AsideType.PAGE
                                        stack.state.selectedNode = undefined
                                    }
                                })
                            }}
                        />
                        <DndContext
                            sensors={sensors}
                            measuring={measuring}
                            collisionDetection={closestCenter}
                            onDragStart={onDragStart}
                            onDragMove={onDragMove}
                            onDragEnd={onDragEnd}
                            onDragCancel={onDragCancel}
                        >
                            <SortableContext disabled={disabledWithVersion} items={items} strategy={verticalListSortingStrategy}>
                                {flattedPageNodes.map(item => (
                                    <SortableItem
                                        key={item.id}
                                        disabled={disabledWithVersion}
                                        isSelected={asideType === AsideType.BLOCK && item.id === selectedNode}
                                        isSelectedChildren={isSelectedChildren(item.id)}
                                        data={{ ...item, indent: activeId === item.id && overInfo ? overInfo.indent : item.indent }}
                                        onNameChange={v =>
                                            item.block &&
                                            onUpdateBlock(
                                                produce(item.block, draft => void (draft.title = v)),
                                                item.block
                                            )
                                        }
                                        onCollapseChange={v => {
                                            setCollapsedIds(s => {
                                                if (v) {
                                                    return [...s, item.id]
                                                }
                                                return s.filter(id => id !== item.id)
                                            })
                                        }}
                                        onDeleteItem={async id => {
                                            if (item.collapsible) {
                                                const isConfirm = await Modal.confirm({
                                                    title: '确认删除',
                                                    content: '删除后，该节点之后的其他节点也将一并删除，且不可恢复，请谨慎操作！'
                                                })
                                                if (!isConfirm) {
                                                    return
                                                }
                                            }
                                            actions.onRemove(id)
                                        }}
                                        onDuplicationItem={actions.onDuplicate}
                                        onClick={() => {
                                            setPageStack(draft => {
                                                const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                if (stack) {
                                                    stack.state.asideType = AsideType.BLOCK
                                                    stack.state.selectedNode = item.id
                                                }
                                                scroll2FlowNode(item.id)
                                            })
                                        }}
                                    />
                                ))}

                                {createPortal(
                                    <DragOverlay modifiers={[adjustTranslate]} style={{ opacity: 0.8 }}>
                                        {activeFlattedNode && <Item data={activeFlattedNode} isOverlay />}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </DndContext>
                    </List>
                </ListWrapper>
            </Root>
        </PageFieldBlocksProvider>
    )
}
