import { getFieldBlockWithDsId, transformNode2FlowLayoutNode, useAtomData } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom, pageStackAtomFamily } from '@/atoms/page/state'

export const useFieldBlocksWithDsId = function () {
    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const pageId = lastPageMeta?.pageId ?? ''
    const rootPageId = lastPageMeta?.rootPageId ?? ''
    const stackId = lastPageMeta?.stackId ?? ''

    const [pageDsId = ''] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.dsId], [])
    )

    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId], [pageId])
    )

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => (pageId ? s[pageId] : []), [pageId])
    )

    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )

    const flowNodes = useMemo(
        () => transformNode2FlowLayoutNode(pageNodes ?? [], pageBlocks ?? [], blockRuntimeState),
        [blockRuntimeState, pageBlocks, pageNodes]
    )

    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks || [], pageDsId, nodes: flowNodes ?? [] }),
        [flowNodes, pageBlocks, pageDsId]
    )
    return { fieldBlocksWithDsId }
}
