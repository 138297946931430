import { IconFont, Popover } from '@byecode/ui'
import type { RoleOption } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'

interface HandleSelectProps {
    children?: React.ReactNode
    id: string
    name: string
    option: RoleOption[]
    disable?: boolean
    onRemove?: (id: string, name: string) => void
    onQuit?: () => void
}

export const HandleSelect: React.FC<HandleSelectProps> = ({ id, name, option, disable = false, onRemove, onQuit }) => {
    const [open, setOpen] = useState(false)

    const handleOpenChange = useCallback(
        (v: boolean) => {
            if (disable) {
                return
            }
            setOpen(v)
        },
        [disable]
    )

    const handleSelect = useCallback(
        (value: string) => {
            setOpen(false)
            if (value === 'remove') {
                onRemove?.(id, name)
                return
            }

            if (value === 'quit') {
                onQuit?.()
            }
        },
        [id, name, onQuit, onRemove]
    )

    return (
        <SC.Container>
            {option.length > 0 && (
                <Popover opened={open} onChange={handleOpenChange} width={204} position="bottom-end">
                    <Popover.Target>
                        <SC.MemberHandle>
                            <IconFont type="DotsThreeVertical" fill="var(--color-gray-500)" />
                        </SC.MemberHandle>
                    </Popover.Target>
                    <Popover.Dropdown>
                        {option.map(item => {
                            return (
                                <SC.Option
                                    key={item.value}
                                    disable={item.disable}
                                    onClick={() => {
                                        if (!item.disable) {
                                            handleSelect(item.value)
                                        }
                                    }}
                                >
                                    <SC.OptionContent>
                                        <SC.OptionLabel color={item.color}>{item.label}</SC.OptionLabel>
                                    </SC.OptionContent>
                                </SC.Option>
                            )
                        })}
                    </Popover.Dropdown>
                </Popover>
            )}
        </SC.Container>
    )
}
