import { IconFont, SegmentedControl } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Icon: React.FC = () => {
    return (
        <Unit title="禁用">
            <SegmentedControl
                data={[
                    { label: '篮球', value: 'basketball', icon: <IconFont type="Add" size={16} /> },
                    { label: '排球', value: 'volleyball' },
                    { label: '足球', value: 'football' }
                ]}
            />
        </Unit>
    )
}
