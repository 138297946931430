import * as React from 'react'

interface LogoProps {
    width?: number
    height?: number
}

export const Logo: React.FunctionComponent<LogoProps> = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 102 26" fill="none">
            <path
                d="M56.432 7.68288C55.4268 6.38679 54.1851 5.73874 52.7068 5.73874C52.0564 5.73874 51.4503 5.86366 50.8886 6.11351C50.3416 6.36336 49.8686 6.70691 49.4694 7.14414C49.0703 7.56577 48.7525 8.07327 48.516 8.66667C48.2942 9.26006 48.1833 9.9003 48.1833 10.5874C48.1833 11.2901 48.2942 11.9381 48.516 12.5315C48.7525 13.1249 49.0703 13.6402 49.4694 14.0775C49.8834 14.5147 50.3638 14.8583 50.9107 15.1081C51.4577 15.358 52.049 15.4829 52.6847 15.4829C54.0742 15.4829 55.3234 14.8583 56.432 13.609L56.432 19.0432L55.9886 19.2072C55.3234 19.4571 54.7025 19.6366 54.126 19.7459C53.5494 19.8709 52.9803 19.9333 52.4186 19.9333C51.2655 19.9333 50.1568 19.7069 49.0925 19.2541C48.0429 18.7856 47.1116 18.1375 46.2986 17.3099C45.5003 16.4667 44.8573 15.4751 44.3694 14.3351C43.8816 13.1796 43.6377 11.9225 43.6377 10.564C43.6377 9.20541 43.8742 7.96396 44.3473 6.83964C44.8351 5.6997 45.4781 4.72372 46.2764 3.91171C47.0894 3.08409 48.0281 2.44384 49.0925 1.99099C50.1568 1.52252 51.2729 1.28829 52.4407 1.28829C53.106 1.28829 53.7564 1.36637 54.392 1.52252C55.0425 1.66306 55.7225 1.88949 56.432 2.2018L56.432 7.68288Z"
                fill="white"
            />
            <path
                d="M62.5962 13.3748C62.5962 13.7808 62.6627 14.1556 62.7958 14.4991C62.9436 14.827 63.1284 15.1159 63.3501 15.3658C63.5867 15.6156 63.8601 15.8108 64.1706 15.9514C64.4958 16.0919 64.8358 16.1622 65.1906 16.1622C65.5454 16.1622 65.878 16.0919 66.1884 15.9514C66.5136 15.8108 66.7871 15.6156 67.0088 15.3658C67.2454 15.1159 67.4301 14.827 67.5632 14.4991C67.711 14.1556 67.7849 13.7886 67.7849 13.3982C67.7849 13.0234 67.711 12.6721 67.5632 12.3441C67.4301 12.0006 67.2454 11.7039 67.0088 11.4541C66.7871 11.2042 66.5136 11.009 66.1884 10.8685C65.878 10.7279 65.5454 10.6577 65.1906 10.6577C64.8358 10.6577 64.4958 10.7279 64.1706 10.8685C63.8601 11.009 63.5867 11.2042 63.3501 11.4541C63.1284 11.7039 62.9436 11.9928 62.7958 12.3207C62.6627 12.6486 62.5962 13 62.5962 13.3748ZM58.361 13.3279C58.361 12.4378 58.531 11.6102 58.871 10.845C59.211 10.0643 59.684 9.39279 60.2901 8.83063C60.8962 8.25285 61.6132 7.8 62.441 7.47207C63.2836 7.14414 64.2001 6.98018 65.1906 6.98018C66.1662 6.98018 67.068 7.14414 67.8958 7.47207C68.7384 7.78438 69.4627 8.22943 70.0688 8.80721C70.6897 9.36937 71.1701 10.0486 71.5101 10.845C71.8501 11.6258 72.0201 12.4925 72.0201 13.445C72.0201 14.3976 71.8427 15.2721 71.488 16.0685C71.148 16.8493 70.6749 17.5285 70.0688 18.1063C69.4627 18.6685 68.731 19.1057 67.8736 19.418C67.031 19.7303 66.1145 19.8865 65.124 19.8865C64.1484 19.8865 63.2467 19.7303 62.4188 19.418C61.591 19.1057 60.874 18.6607 60.268 18.0829C59.6767 17.5051 59.211 16.818 58.871 16.0216C58.531 15.2096 58.361 14.3117 58.361 13.3279Z"
                fill="white"
            />
            <path
                d="M78.0558 13.3982C78.0558 13.7886 78.1223 14.1556 78.2553 14.4991C78.3884 14.827 78.5658 15.1159 78.7875 15.3658C79.024 15.6156 79.2975 15.8108 79.6079 15.9514C79.9332 16.0919 80.2805 16.1622 80.6501 16.1622C81.0049 16.1622 81.3375 16.0919 81.6479 15.9514C81.9732 15.8108 82.2466 15.6156 82.4684 15.3658C82.7049 15.1159 82.8897 14.827 83.0227 14.4991C83.1706 14.1712 83.2445 13.8198 83.2445 13.445C83.2445 13.0703 83.1706 12.7189 83.0227 12.391C82.8897 12.0474 82.7049 11.7508 82.4684 11.5009C82.2466 11.2511 81.9732 11.0559 81.6479 10.9153C81.3375 10.7748 81.0049 10.7045 80.6501 10.7045C80.2953 10.7045 79.9553 10.7748 79.6301 10.9153C79.3197 11.0559 79.0462 11.2511 78.8097 11.5009C78.5879 11.7508 78.4032 12.0396 78.2553 12.3676C78.1223 12.6799 78.0558 13.0234 78.0558 13.3982ZM83.1114 2.16407e-07L87.1471 2.38532e-07L87.1471 19.4414L83.1114 19.4414L83.1114 18.1063C82.254 19.2462 81.0936 19.8162 79.6301 19.8162C78.8023 19.8162 78.041 19.6523 77.3462 19.3243C76.6514 18.9964 76.0453 18.5435 75.5279 17.9658C75.0105 17.388 74.604 16.7087 74.3084 15.9279C74.0275 15.1471 73.8871 14.3039 73.8871 13.3982C73.8871 12.5393 74.0201 11.7273 74.2862 10.9622C74.5671 10.1814 74.9588 9.5021 75.4614 8.92433C75.964 8.34655 76.5627 7.89369 77.2575 7.56577C77.9671 7.22222 78.7432 7.05045 79.5858 7.05045C81.0049 7.05045 82.1801 7.57357 83.1114 8.61982L83.1114 2.16407e-07Z"
                fill="white"
            />
            <path
                d="M98.1935 11.6883C98.0604 11.0949 97.787 10.6186 97.3731 10.2595C96.9591 9.9003 96.4565 9.72072 95.8652 9.72072C95.2444 9.72072 94.7344 9.89249 94.3352 10.236C93.9509 10.5796 93.707 11.0637 93.6035 11.6883L98.1935 11.6883ZM93.4926 14.1477C93.4926 15.9748 94.3057 16.8883 95.9317 16.8883C96.8039 16.8883 97.4617 16.5135 97.9052 15.764L101.786 15.764C101.002 18.5123 99.0435 19.8865 95.9096 19.8865C94.9487 19.8865 94.0691 19.7381 93.2709 19.4414C92.4726 19.1291 91.7852 18.6919 91.2087 18.1297C90.647 17.5676 90.2109 16.8961 89.9004 16.1153C89.59 15.3345 89.4348 14.4601 89.4348 13.4919C89.4348 12.4925 89.5826 11.5946 89.8783 10.7982C90.1739 9.98619 90.5952 9.2991 91.1422 8.73694C91.6891 8.17478 92.347 7.74535 93.1157 7.44865C93.8991 7.13634 94.7787 6.98018 95.7544 6.98018C96.7152 6.98018 97.58 7.13634 98.3487 7.44865C99.1174 7.74535 99.7678 8.18258 100.3 8.76036C100.832 9.33814 101.239 10.0486 101.52 10.8919C101.8 11.7195 101.941 12.6565 101.941 13.7027L101.941 14.1477L93.4926 14.1477Z"
                fill="white"
            />
            <path
                d="M4.34609 15.8812L5.29957 15.8812C6.39348 15.8812 7.17696 15.7328 7.65 15.4361C8.12304 15.1394 8.35957 14.6632 8.35957 14.0073C8.35957 13.3514 8.12304 12.8752 7.65 12.5785C7.17696 12.2818 6.39348 12.1334 5.29957 12.1334L4.34609 12.1334L4.34609 15.8812ZM4.34609 8.71361L5.14435 8.71361C6.50435 8.71361 7.18435 8.14364 7.18435 7.0037C7.18435 5.86376 6.50435 5.29379 5.14435 5.29379L4.34609 5.29379L4.34609 8.71361ZM0 1.78027L6.47478 1.78027C8.01217 1.78027 9.18 2.17066 9.97826 2.95144C10.7765 3.73222 11.1757 4.85655 11.1757 6.32442C11.1757 7.21451 11.0204 7.95625 10.71 8.54964C10.4143 9.12742 9.95609 9.61931 9.33522 10.0253C9.95609 10.1502 10.4809 10.3454 10.9096 10.6109C11.353 10.8608 11.7078 11.1731 11.9739 11.5478C12.2548 11.9226 12.4543 12.3442 12.5726 12.8127C12.6909 13.2812 12.75 13.7809 12.75 14.3118C12.75 15.1394 12.6096 15.8734 12.3287 16.5136C12.0626 17.1538 11.6783 17.6926 11.1757 18.1298C10.6878 18.5671 10.0891 18.895 9.37957 19.1136C8.67 19.3322 7.87174 19.4415 6.98478 19.4415L-8.677e-08 19.4415L0 1.78027Z"
                fill="#344054"
            />
            <path
                d="M19.1002 17.8019L13.6011 7.42532L18.2576 7.42532L21.2954 13.5857L24.2446 7.42532L28.8567 7.42532L19.7211 25.4379L15.2198 25.4379L19.1002 17.8019Z"
                fill="#344054"
            />
            <path
                d="M37.9726 11.6884C37.8396 11.095 37.5661 10.6187 37.1522 10.2596C36.7383 9.90039 36.2357 9.72081 35.6444 9.72081C35.0235 9.72081 34.5135 9.89258 34.1144 10.2361C33.73 10.5797 33.4861 11.0638 33.3826 11.6884L37.9726 11.6884ZM33.2718 14.1478C33.2718 15.9749 34.0848 16.8884 35.7109 16.8884C36.5831 16.8884 37.2409 16.5136 37.6844 15.7641L41.5648 15.7641C40.7813 18.5124 38.8227 19.8866 35.6887 19.8866C34.7279 19.8866 33.8483 19.7382 33.05 19.4415C32.2518 19.1292 31.5644 18.692 30.9879 18.1298C30.4261 17.5677 29.99 16.8962 29.6796 16.1154C29.3692 15.3346 29.214 14.4602 29.214 13.492C29.214 12.4926 29.3618 11.5947 29.6574 10.7983C29.9531 9.98628 30.3744 9.29919 30.9213 8.73703C31.4683 8.17487 32.1261 7.74544 32.8948 7.44874C33.6783 7.13643 34.5579 6.98027 35.5335 6.98027C36.4944 6.98027 37.3592 7.13643 38.1279 7.44874C38.8966 7.74544 39.547 8.18268 40.0792 8.76045C40.6113 9.33823 41.0179 10.0487 41.2987 10.892C41.5796 11.7196 41.72 12.6565 41.72 13.7028L41.72 14.1478L33.2718 14.1478Z"
                fill="#344054"
            />
        </svg>
    )
}
