import type { TypeInstanceMap } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type DataSourceProtocol,
    type FilterFormType,
    type RecordLikeProtocol,
    type Sorter,
    DataSourceType
} from '@lighthouse/core'
import type { ViewExportParams } from '@lighthouse/shared'
import {
    type AppendParams,
    type DsExportParams,
    type ExportParams,
    type ImportParams,
    type RelativeOption,
    getMainTableRecordId
} from '@lighthouse/shared'
import type { AxiosResponse } from 'axios'
import { multiply, subtract } from 'rambda'

import type { DeleteViewRecordAtomPayload } from '@/atoms/dataSource/types'
import type { HttpResp } from '@/http'
import http from '@/http'

import { formContentType, JsonContentType } from './constant'
import type {
    CreateDataSourcePayload,
    CreateDataSourceResult,
    CreateFieldPayload,
    CreateFieldResult,
    CreateJoinDsPayload,
    CreateRecordPayload,
    CreateSyncDsPayload,
    DataSourceAndRecordsRes,
    DataSourceAndRecordsResult,
    DeleteDataSourcePayload,
    DeleteFieldPayload,
    DeleteRecordPayload,
    DuplicateAndInitDsPayload,
    EnableApprovalStatePayload,
    EnvironmentIdentity,
    GetDataSourcePayload,
    getDsCountPayload,
    GetDsListMetaPayload,
    GetDsMetaPayload,
    GetNodePreviewDataPayload,
    GetRecordByFilterPayload,
    MoveDsPayload,
    RecordRes,
    UpdateCellPayload,
    UpdateDataSourcePayload,
    UpdateFieldPayload,
    UpdateRecordPayload
} from './types'

/**
 * 获取数据源信息
 * @return {*}
 */
export async function getDsMeta(payload: GetDsMetaPayload): Promise<DataSourceAbstract> {
    const res = await http.get<unknown, HttpResp<DataSourceAbstract>>('/api/v1/ds/getDsSchema', {
        params: payload
    })
    return res.content
}

export async function getDsListMeta(payload: GetDsListMetaPayload): Promise<DataSourceAbstract[]> {
    const res = await http.post<unknown, HttpResp<DataSourceAbstract[]>>(
        '/api/v1/ds/getDsSchemas',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 创建并且初始化数据源
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function createAndInitDs(payload: CreateDataSourcePayload): Promise<CreateDataSourceResult> {
    const res = await http.post<unknown, HttpResp<CreateDataSourceResult>>(
        '/api/v1/ds/createDs',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

export async function duplicateAndInitDs(payload: DuplicateAndInitDsPayload): Promise<CreateDataSourceResult> {
    const res = await http.put<unknown, HttpResp<CreateDataSourceResult>>('/api/v1/ds/copyDs', payload)
    return res.content
}

/**
 * 创建并且初始化数据源
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function createSyncDs(payload: CreateSyncDsPayload): Promise<CreateDataSourceResult> {
    const res = await http.post<unknown, HttpResp<CreateDataSourceResult>>(
        '/api/v1/ds/createSyncDs',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 创建并且初始化数据源
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function createJoinDs(payload: CreateJoinDsPayload): Promise<CreateDataSourceResult> {
    const res = await http.post<unknown, HttpResp<CreateDataSourceResult>>(
        '/api/v1/ds/createJoinedDs',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/**
 * 创建聚合表
 * @param dsId
 * @returns
 */
export async function createAnalyticsDs(envId: string) {
    const res = await http.post<unknown, HttpResp<CreateDataSourceResult>>(
        '/api/v1/ds/createAnalyticsDs', {
        envId
    },
        { headers: JsonContentType }
    )

    return res.content
}

/**
 * 直接导入 Excel 数据
 * @param {File} file
 * @return {*}  {Promise<boolean>}
 */

export async function importDataSource(params: ImportParams & EnvironmentIdentity): Promise<Pick<DataSourceProtocol, 'id' | 'name'>[]> {
    const { file, sheetName, envId } = params
    const formData = new FormData()
    formData.append('envId', envId)
    formData.append('file', file)
    formData.append('sheetName', sheetName)
    const res = await http.post<unknown, HttpResp<Pick<DataSourceProtocol, 'id' | 'name'>[]>>('/api/v1/ds/import', formData, {
        headers: formContentType
    })
    return res.content
}

/**
 * 直接导出 Excel 数据
 * @param {File} file
 * @return {*}  {Promise<boolean>}
 */
export async function exportDataSource(params: ExportParams): Promise<void> {
    const { envId, dsId, fileType, name } = params
    const formData = new FormData()
    formData.append('envId', envId)
    formData.append('dsId', dsId)
    formData.append('fileType', fileType)
    const res = await http.post<unknown, HttpResp<string>>('/api/v1/ds/export', formData, {
        headers: formContentType
    })
    const fileUrl = res.content
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = fileUrl
    // 保存下来的文件名
    a.download = `${name}.txt`
    document.body.append(a)
    a.click()
    // 移除元素
    a.remove()
}

/**
 * 通过追加方式导入 Excel 数据
 * @return {*}  {Promise<boolean>}
 */

export async function appendDataToDataSource(params: AppendParams): Promise<boolean> {
    const formData = new FormData()
    const requestParams = Object.entries(params)
    for (const [requestParamKey, requestParamValue] of requestParams) {
        const serializedValue = requestParamKey === 'sheetDto' ? JSON.stringify(requestParamValue) : (requestParamValue as File | string)
        formData.append(requestParamKey, serializedValue)
    }
    const res = await http.post<unknown, HttpResp<Pick<DataSourceProtocol, 'id' | 'name'>>>('/api/v1/ds/importInAppendMode', formData, {
        headers: formContentType
    })
    return res.success
}

/**
 * 删除数据源
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function deleteDataSource(payload: DeleteDataSourcePayload): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp>('/api/v1/ds/deleteDs', {
        params: payload
    })
    return res.success
}

/**
 * 获取数据源数据
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function getDs(params: GetDataSourcePayload): Promise<DataSourceAndRecordsResult | undefined> {
    const { appId, envId, dsId, pagination, search, filter, sorts, currentRecordId, parentRecordId, disabledFilter, recordIds, abort } = params
    const { currentPage = 1, pageSize = 10 } = pagination
    const res = await http.post<unknown, HttpResp<DataSourceAndRecordsRes> | undefined>(
        '/api/v1/ds/getDs',
        { envId, dsId, currentPage, pageSize, search, filter, disabledFilter, sorts, currentRecordId, parentRecordId, recordIds },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    if (!res || !res?.content) {
        return
    }
    const { datasource, records } = res.content
    const { type } = datasource
    const dsIncludesAppIdAndEnvId = {
        ...datasource,
        appId,
        envId,
    }
    if (type === DataSourceType.aggregateDataSource) {
        const startIndex = multiply(subtract(currentPage, 1), pageSize)
        const newRecord = records.map((item, index) => ({
            ...item,
            appId,
            envId,
            dsId,
            id: `${dsId}__${startIndex + index}`
        }))
        return {
            datasource: dsIncludesAppIdAndEnvId,
            records: newRecord
        }
    }
    if (type === DataSourceType.joinDataSource) {
        const startIndex = multiply(subtract(currentPage, 1), pageSize)
        const newRecord = records.map((item, index) => ({
            ...item,
            appId,
            envId,
            dsId,
            id: `${item.id}__${startIndex + index}`
        }))
        return {
            datasource: dsIncludesAppIdAndEnvId,
            records: newRecord
        }
    }
    return {
        datasource: dsIncludesAppIdAndEnvId,
        records: records.map(record => ({
            ...record,
            appId,
            dsId,
            envId
        })) as RecordLikeProtocol[]
    }
}

/**
 * 获取数据源行数
 * @param {string} dataSourceId
 * @return {*}  {Promise<boolean>}
 */
export async function getDsCount(params: getDsCountPayload): Promise<number | undefined> {
    const { envId, dsId, search, filter, linkFilter, currentRecordId, parentRecordId, recordIds, abort } = params
    const res = await http.post<unknown, HttpResp<number> | undefined>(
        '/api/v1/ds/dsCount',
        { envId, dsId, search, filter, currentRecordId, parentRecordId, recordIds, linkFilter },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    return res?.content
}

/**
 * 移动数据源
 * @param {string} id
 * @param {string} before
 * @param {string} after
 * @return {*}  {Promise<boolean>}
 */
export async function moveDs(payload: MoveDsPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>(
        'api/v1/ds/moveDs',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 导出数据源
 * @param params
 * @returns
 */
export async function dsExport(params: DsExportParams) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>('api/v1/ds/export', params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 更新单元格
 * @param {UpdateCellPayload} payload
 * @return {*}  {Promise<boolean>}
 */

export async function updateCell(payload: UpdateCellPayload): Promise<boolean> {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        id: getMainTableRecordId(payload.id)
    }
    const res = await http.post<unknown, HttpResp>('/api/v1/ds/updateCell', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 创建字段
 * @param {CreateFieldPayload} payload
 * @return {*}  {Promise<boolean>}
 */

export async function createField(payload: CreateFieldPayload): Promise<CreateFieldResult> {
    const res = await http.post<unknown, HttpResp<CreateFieldResult>>('/api/v1/ds/createField', payload, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 更新字段
 * @param {UpdateFieldPayload} payload
 * @return {*}  {Promise<boolean>}
 */

export async function updateField(payload: UpdateFieldPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('/api/v1/ds/updateField', payload, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 删除字段
 * @param {string} id
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function deleteField(payload: DeleteFieldPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>(
        '/api/v1/ds/deleteField',
        payload,
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 更新一条记录的多个字段
 * @param {UpdateRecordPayload} params
 * @returns
 */
export async function updateRecord(payload: UpdateRecordPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, ...rest } = payload
    const params = {
        ...rest,
        dsId,
        recordId: getMainTableRecordId(payload.recordId)
    }
    const res = await http.post<unknown, HttpResp<RecordRes>>('api/v1/ds/updateRecord', params, { headers: JsonContentType })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * 创建行（记录）
 * @param {string} id
 * @param {string} dsId
 * @return {*}  {Promise<boolean>}
 */

export async function createRecord(payload: CreateRecordPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, beforeId, ...rest } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>(
        '/api/v1/ds/createRecord',
        payload,
        {
            headers: JsonContentType
        }
    )
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * 删除行（记录）
 * @param {string} dsId
 * @param {string[]} recordIds
 * @return {*}  {Promise<boolean>}
 */

export async function deleteRecord(params: DeleteRecordPayload): Promise<boolean> {
    const { envId, dsId, recordIds, mode = 'CURRENT_PAGE', search, filter } = params
    // TODO: 临时解决连接表问题
    const rIds = [...new Set(recordIds.map(id => getMainTableRecordId(id)))]
    const res = await http.post<unknown, HttpResp>(
        '/api/v1/ds/deleteRecord',
        { id: dsId, envId, recordIds: rIds, mode, search, filter },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 视图批量删除行
 * @param params
 * @returns
 */
export async function deleteViewRecord(payload: DeleteViewRecordAtomPayload): Promise<boolean> {
    // const rIds = recordIds.map(id => getMainTableRecordId(id))
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        recordIds: [...new Set(payload.recordIds.map(id => getMainTableRecordId(id)))]
    }
    const res = await http.post<unknown, HttpResp>('/api/v1/view/deleteRecords', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 更新数据源
 * @param {updateDataSourcePayload} payload
 * @return {*}  {Promise<boolean>}
 */

export async function updateDataSource(payload: UpdateDataSourcePayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('/api/v1/ds/updateDs', payload, {
        headers: JsonContentType
    })
    return res.success
}


// export async function enableApprovalState(payload: EnableApprovalStatePayload) {
//     const res = await http.get<unknown, HttpResp<boolean>>('api/v1/ds/enableApprovalState', {
//         params: payload
//     })
//     return res.content
// }

/**
 * 创建视图打印模板
 * @param dsId
 * @returns
 */
export async function uploadPrintTemplate(params: { dsId: string; name: string, versionId: string }, file: File) {
    const fileFormData = new FormData()
    fileFormData.append('file', file)
    const res = await http.post<unknown, HttpResp<{ templateId: string; name: string; dsId: string; url: string }>>(
        `api/v1/oss/uploadExcelTemplate?dsId=${params.dsId}&name=${params.name}&versionId=${params.versionId}`,
        fileFormData,
        {
            headers: formContentType
        }
    )
    return res.content
}


/**
 * 按模板导出/打印视图
 * @param params
 * @returns
 */
export async function exportView(params: ViewExportParams) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>('api/v1/view/exportView', params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 生成ai字段内容
 * @param params
 * @returns
 */
export async function aiGenerate(payload: { dsId: string; recordId: string; fieldId: string }) {
    // TODO: 临时解决连接表问题
    const params = {
        ...payload,
        recordId: getMainTableRecordId(payload.recordId)
    }
    const res = await http.get<unknown, HttpResp<string>>('api/v1/ds/aiGenerate', {
        params
    })
    return res.success
}

/**
 * 生成选项
 * @param params
 * @returns
 */
export async function getRelativeOptions(params: {
    dsId: string
    envId: string
    fieldId: string
    filter?: FilterFormType
    disabledFilter?: FilterFormType
    sorts?: Sorter[]
    showFieldId: string
    currentRecordId?: string
    parentRecordId?: string
}) {
    const res = await http.post<unknown, HttpResp<RelativeOption[]>>('api/v1/ds/getSelectByField', params, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 获取聚合表在某个节点下的数据
 * @return {*}
 */
export async function getNodePreviewData(payload: GetNodePreviewDataPayload): Promise<DataSourceAndRecordsResult> {
    const { appId, envId, dsId, ...rest } = payload
    const params = {
        envId,
        dsId,
        ...rest
    }
    const res = await http.get<unknown, HttpResp<DataSourceAndRecordsRes>>('/api/v1/ds/previewData', { params })
    const { datasource, records } = res.content
    return {
        datasource: {
            appId,
            envId,
            ...datasource
        },
        records: records.map(record => ({
            appId,
            envId,
            dsId,
            ...record
        }))
    }
}

/**
 * 获取聚合表在某个节点下公式字段内部类型
 * @return {*}
 */
export async function getInnerTypeInAggregation(params: { envId: string, dsId: string; nodeId: string; expression: string }): Promise<TypeInstanceMap> {
    const res = await http.get<unknown, HttpResp<TypeInstanceMap>>('/api/v1/ds/getInnerTypeInAggregation', { params })
    return res.content
}

// /**
//  * 获取级联输入框数据
//  * @return {*}
//  */
// function getNewOption(option: CascadeOptionResult): CascadeOption {
//     return { label: option.label, value: option.label, children: option.children.map(child => getNewOption(child)) }
// }
// export async function getCascade(params: { blockId: string; currentRecordId: string; parentRecordId: string }): Promise<CascadeOption[]> {
//     const res = await http.get<unknown, HttpResp<CascadeOptionResult[]>>('/api/v1/ds/getCascade', { params })
//     return res.content.map(option => getNewOption(option))
// }
/**
* 获取输入框默认值
* @param params
* @returns
*/
export async function getRecordByFilter(payload: GetRecordByFilterPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>('api/v1/ds/getRecordByFilter', payload, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}
