import type { BaseModalProps } from '@byecode/ui';
import { DesktopModal } from '@byecode/ui'
import React from 'react'

import NetEaseFreeAccount from '../NetEaseFreeAccount'
import type { NetEaseFreeAccountMode } from '../type'

interface NetEaseFreeAccountModalProps extends BaseModalProps {
    mode: NetEaseFreeAccountMode
    id?: string
}

export const NetEaseFreeAccountModal: React.FunctionComponent<NetEaseFreeAccountModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <NetEaseFreeAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
