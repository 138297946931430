import { Select } from '@byecode/ui'
import type { NodeTypes, ScheduledTriggerRule } from '@lighthouse/shared'
import { CollapseBox } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import * as SC from '../../styles'
import { CommonRuleSettingUnit } from './CommonRuleSettingUnit'

interface CommonRuleProps {}

const rulesOptions: { label: string; value: ScheduledTriggerRule }[] = [
    { label: '只触发一次', value: 'ONCE' },
    { label: '按分钟', value: 'MINUTE' },
    { label: '按小时', value: 'HOUR' },
    { label: '按天', value: 'DAY' },
    { label: '按工作日', value: 'WEEKDAY' },
    { label: '每周', value: 'WEEK' },
    { label: '每月', value: 'MONTH' }
]

export const CommonRule: React.FC<CommonRuleProps> = () => {
    const { control } = useFormContext()
    const scheduleRule: ScheduledTriggerRule = useWatch({
        control,
        name: 'config.scheduleRule'
    })

    return (
        <CollapseBox label="重复规则">
            <SC.Content>
                <SC.FormItem>
                    <SC.FormItemLabelWrapper>
                        <SC.FormItemLabel>规则</SC.FormItemLabel>
                    </SC.FormItemLabelWrapper>
                    <SC.FormItemContent>
                        <Controller
                            name="config.scheduleRule"
                            defaultValue="ONCE"
                            control={control}
                            render={({ field }) => (
                                <Select value={field.value} options={rulesOptions} onChange={val => field.onChange?.(val as NodeTypes)} />
                            )}
                        />
                    </SC.FormItemContent>
                </SC.FormItem>
                <CommonRuleSettingUnit key={scheduleRule} />
            </SC.Content>
        </CollapseBox>
    )
}
