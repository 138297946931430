import { Button, IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)`
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
`

export const Text = styled.div``

export const FreeRegister = styled.a`
    color: var(--color-main);
    font-weight: 600;
    cursor: pointer;
`

export const FormContainer = styled.form`
    width: 100%;
    padding-top: 12px;
`

export const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const ButtonContainer = styled.div`
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    gap: 16px;
`

export const AccountInput = styled(Input)`
    height: 40px;
`

export const CodeInput = styled(Input)`
    height: 40px;
`

export const CodeInputBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const HideSubmit = styled.input`
    display: none;
`

export const AccountButton = styled(Button)<{ backgroundColor?: string; hoverBackgroundColor?: string; color?: string }>`
    position: relative;
    height: 40px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    &:hover {
        color: ${({ color }) => color}!important;
        background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor}!important;
    }
`

export const FooterBetween = styled.footer`
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    font-size: var(--font-size-normal);

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`

export const Agree = styled.div`
    margin-top: 24px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const AgreeContent = styled.div`
    font-size: 12px;
    color: var(--color-gray-500);
    > * {
        font-size: 12px;
        color: var(--color-black);
    }
`

export const InputGroup = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 24px;
`

export const FooterCenter = styled.footer`
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--font-size-normal);

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`

export const QrCodeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const QRCode = styled.img`
    width: 163px;
    height: 163px;
`

export const QrCodeRefresh = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 36px 0;
    color: var(--color-gray-500);
    ${pointer}
`

export const RefreshIcon = styled(IconFont)`
    margin-left: 8px;
`
