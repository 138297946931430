import { Button } from '@byecode/ui/components/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Switch } from '@byecode/ui/components/Switch'
import { Tooltip } from '@byecode/ui/components/Tooltip'
import type { FormContainerBlockConfig } from '@lighthouse/core'
import { FindUseType, getIsFindUse, getIsFindUseInFilter, getIsFindUseInSort, useFindUseObjectContext } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Flex, Text } from '@mantine/core'
import { clone } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import type { FieldArrayWithId } from 'react-hook-form'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { TagIcon } from '../TagIcon'
import type { RuleSettingProps } from './RuleSetting'
import { RuleSetting } from './RuleSetting'

interface ValidationRuleAdderProps {}

const SCxContainer = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);
`

const SCxItem = styled.div<{ highlighting?: boolean }>`
    height: 44px;
    padding: 0 8px 0 12px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background: #fff;
    cursor: pointer;

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
        `}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 0;
    gap: 4px;
`

const SCxList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 4px 0 8px 0;
`

export const ValidationRuleAdder: React.FunctionComponent<ValidationRuleAdderProps> = () => {
    const { control } = useFormContext<FormContainerBlockConfig>()
    const findUseObject = useFindUseObjectContext()
    const [index, setIndex] = useState<number>()
    const { fields, insert, remove, update, append } = useFieldArray({
        name: 'rules',
        control,
        keyName: 'itemId'
    })
    const prefix = useMemo(() => (typeof index === 'number' ? `rules.${index}` : undefined), [index])

    const getRuleHighLight = useCallback(
        (data: FieldArrayWithId<FormContainerBlockConfig, 'rules', 'itemId'>) => {
            const { pointer, filter, sort, checkFilter } = data
            const pointerHighLight = getIsFindUse({
                findUseObject,
                type: FindUseType.DATASHEET,
                dsId: pointer
            })
            const filterHighLight = getIsFindUseInFilter({
                data: filter,
                findUseObject,
                currentDsId: pointer
            })
            const sortHighLight = getIsFindUseInSort({
                findUseObject,
                data: sort,
                currentDsId: pointer
            })

            const checkFilterHighLight = getIsFindUseInFilter({
                findUseObject,
                data: checkFilter,
                currentDsId: pointer
            })
            return pointerHighLight || filterHighLight || sortHighLight || checkFilterHighLight
        },
        [findUseObject]
    )

    return (
        <SCxContainer>
            {fields.length > 0 && (
                <SCxList>
                    {fields.map((item, index) => {
                        const isEmptyCheckFilter = item.checkFilter ? item.checkFilter?.expression?.conditions?.length === 0 : true
                        const isEmptyConfig = !item.failureMessage || isEmptyCheckFilter || !item.pointer
                        const isHighLight = getRuleHighLight(item)

                        return (
                            <SCxItem key={item.id} highlighting={isHighLight} onClick={() => setIndex(index)}>
                                <Flex gap={8} style={{ flex: 1, overflow: 'hidden', width: 200 }} align="center">
                                    <Switch
                                        checked={item.isUsedValidationRule}
                                        onChange={e => update(index, { ...item, isUsedValidationRule: e.target.checked })}
                                        onClick={e => e.stopPropagation()}
                                    />
                                    <Text lineClamp={1} style={{ whiteSpace: 'nowrap' }}>
                                        {item.title}
                                    </Text>
                                    {isEmptyConfig && (
                                        <Tooltip title="规则配置未完善，此规则不生效">
                                            <IconFont
                                                style={{ cursor: 'pointer' }}
                                                type="WarningCircle"
                                                color="var(--color-red-500)"
                                                size={16}
                                            />
                                        </Tooltip>
                                    )}
                                </Flex>
                                <SCxIconWrapper>
                                    <TagIcon
                                        size={16}
                                        icon="Copy"
                                        iconColor="var(--color-gray-400)"
                                        enableHover
                                        hoverColor="var(--color-black)"
                                        onClick={e => {
                                            e.stopPropagation()
                                            insert(index + 1, clone({ ...item, id: nanoid() }))
                                        }}
                                    />
                                    <TagIcon
                                        size={16}
                                        icon="Trash"
                                        hoverColor="var(--color-red-500)"
                                        iconColor="var(--color-gray-400)"
                                        enableHover
                                        onClick={e => {
                                            e.stopPropagation()
                                            remove(index)
                                        }}
                                    />
                                    <TagIcon size={16} icon="ArrowRightSmall" iconColor="var(--color-gray-400)" />
                                </SCxIconWrapper>
                            </SCxItem>
                        )
                    })}
                </SCxList>
            )}
            <Button
                onClick={() => {
                    append({
                        id: nanoid(),
                        title: '未命名规则',
                        failureMessage: '校验不通过',
                        isUsedValidationRule: true
                    })
                    setIndex(fields.length)
                }}
                radius={100}
                icon={<IconFont size={16} type="Add" color="var(--color-gray-500)" />}
                style={{ margin: '4px 0' }}
            >
                添加
            </Button>
            {prefix && (
                <RuleSetting
                    prefix={prefix as RuleSettingProps['prefix']}
                    onGoBack={() => setIndex(undefined)}
                    onChange={v => typeof index === 'number' && update(index, v)}
                />
            )}
        </SCxContainer>
    )
}
