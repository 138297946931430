import 'react-toastify/dist/ReactToastify.css'

import zhCN from 'date-fns/locale/zh-CN/index'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import React from 'react'
import { RouterProvider } from 'react-router-dom'

import { router } from './router'

setDefaultOptions({ locale: zhCN })

export const App: React.FC = () => {
    return <RouterProvider router={router} />
}

// const a = Object.entries(example)
// navigator.clipboard
//     .readText()
//     .then(clipText => {
//         const arrText = clipText.split('\r\n')
//         console.log(Object.fromEntries(a.map(([key, value], index) => [key, arrText[index]])))
//     })
//     .catch(e => {
//         console.error(e)
//     })
