import { IconFont, Input, pointer } from '@byecode/ui'
import type { ChangeEvent } from 'react'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    position: sticky;
    z-index: 99;
    top: 0;
    height: 54px;
    width: 100%;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
`

const SCxTitleBox = styled.div`
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    padding-right: 28px;
    color: var(--color-black);
`

const SCxTitle = styled.div`
    width: fit-content;
    max-width: 100%;
    position: relative;
`

const SCxInput = styled(Input)``

const SCxText = styled.div`
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const SCxEdit = styled(IconFont)`
    position: absolute;
    right: -20px;
    top: 4px;
    ${pointer}
`

// const SCxClose = styled.div`
//     width: 16px;
//     display: flex;
//     align-items: center;
// `

// const SCxIcon = styled(IconFont)`
//     ${pointer}
// `

interface NodeHeaderProps {
    value: string
    defaultValue: string
    disable?: boolean
    rightSection?: React.ReactNode
    onChange?: (value: string) => void
    onClose?: () => void
}

type Mode = 'edit' | 'preview'

export const NodeHeader: React.FC<NodeHeaderProps> = ({ value, defaultValue, disable = false, rightSection, onChange, onClose }) => {
    const [mode, setMode] = useState<Mode>('preview')
    const handleChangeMode = useCallback(
        (val: Mode) => {
            if (disable) {
                return
            }
            setMode(val)
        },
        [disable]
    )

    const handleBlur = useCallback(() => {
        setMode('preview')
    }, [])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value
            onChange?.(val)
        },
        [onChange]
    )

    return (
        <SCxContainer>
            <SCxTitleBox>
                {mode === 'preview' ? (
                    <SCxTitle>
                        <SCxText>{value || defaultValue}</SCxText>
                        {!disable && <SCxEdit fill="var(--color-gray-400)" type="PencilSimple" onClick={() => handleChangeMode('edit')} />}
                    </SCxTitle>
                ) : (
                    <SCxInput size="sm" value={value} autoSelect onBlur={handleBlur} onChange={handleChange} />
                )}
            </SCxTitleBox>
            {rightSection}
            {/* <SCxClose>
                <SCxIcon type="Close" onClick={onClose} />
            </SCxClose> */}
        </SCxContainer>
    )
}
