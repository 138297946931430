import { pointer } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface AddIClickAccountProps {
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const SCXContainer = styled.div`
    width: 100%;
    padding: 16px 10px 8px 10px;
    height: 32px;
    display: flex;
    align-items: center;
    ${pointer}

    ${CM.Icon} {
        margin-right: 8px;
    }
`

export const AddIClickAccount: React.FunctionComponent<AddIClickAccountProps> = ({ onClick }) => {
    return (
        <SCXContainer onClick={onClick}>
            <CM.Icon color="var(--color-main)" type="Add" />
            <CM.Text color="var(--color-main)"> 添加账号</CM.Text>
        </SCXContainer>
    )
}
