import { useAtomAction } from '@lighthouse/shared'
import React, { useEffect } from 'react'

import { setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { DepartmentPanel } from '@/components/UserSetting'

export const Department: React.FC = () => {
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    useEffect(() => {
        setCurrentDataSourceId({ dsId: 'department' })
    }, [setCurrentDataSourceId])

    return <DepartmentPanel />
}
