import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import PerfectField from '../PerfectField'
import * as SC from './styles'

interface PerfectFieldDetailProps {
    id?: string
    prefix: 'perfect.commonSetting' | `perfect.groupSetting.${number}`
    PerfectWayColumn?: 'row' | 'column'
}

const PerfectFieldDetail: React.FunctionComponent<PerfectFieldDetailProps> = ({ id, prefix, PerfectWayColumn = 'column' }) => {
    const { control, watch } = useFormContext<ApplicationSettingAuthentication>()

    return (
        <SC.Container>
            <PerfectField prefix={prefix} />
        </SC.Container>
    )
}

export default PerfectFieldDetail
