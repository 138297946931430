export function transform2Integer(v?: string | number) {
    if (!v) {
        return 0
    }

    try {
        const format = Number.parseInt(v.toString(), 10)
        if (Number.isNaN(format)) {
            return 0
        }
        return format
    } catch {
        return 0
    }
}
