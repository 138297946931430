import type { AnyObject } from 'immer/dist/internal'
import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useUser } from '@/hooks/useUser'

import { FormItemRender } from './FormItemRender'
import { formStepMeta, userSchema } from './formSchema'
import * as SC from './styles'
// import { Success } from './Success'

// const vikDataSheetId = 'dsttFJD9YS0M6oaRyE'
// const vikApiUrl = `https://api.vika.cn/fusion/v1/datasheets/${vikDataSheetId}/records`
// const vikToken = 'Bearer uskmB6oRi3LBj4zEvizfOAo'

interface BetaRequestFormProps {
    step: number
    onChangeStep: (num: number) => void
    onSubmit?: (values: Record<string, AnyObject>) => void
}

export const BetaRequestForm: React.FC<BetaRequestFormProps> = ({ step, onChangeStep, onSubmit }) => {
    // const city = 'null'

    // const [currentStep, setCurrentStep] = useState(0)
    // 获取当前用户信息，用于保存手机号
    const userData = useUser()
    const currentStepForm = formStepMeta[step]
    const form = useForm()

    const requireField = currentStepForm.fields.filter(item => userSchema[item].config.required)
    const isButtonDisabled = form.watch(requireField).some(field => !field)

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isSaved, setIsSaved] = useState(false)

    const stepCount = formStepMeta.length

    // const handlePrev = useCallback(() => {
    //     if (currentStep <= 0) {
    //         return
    //     }
    //     setCurrentStep(s => s - 1)
    // }, [currentStep])

    const handleSubmit = useCallback(() => {
        if (isSubmitting) {
            return
        }
        form.handleSubmit(values => {
            const replacedNameValues = Object.fromEntries(
                Object.entries(values).map(([fieldKey, fieldValue]) => [userSchema[fieldKey].name, fieldValue])
            )

            onSubmit?.(replacedNameValues)

            // setIsSubmitting(true)
            // const res = await fetch(vikApiUrl, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: vikToken
            //     },
            //     body: JSON.stringify({ records: [{ fields: finalValues }] })
            // })
            // setIsSubmitting(false)

            // if (res.ok) {
            //     onSubmit?.(values)
            //     // setIsSaved(true)
            // }
        })()
    }, [form, isSubmitting, onSubmit])

    const handleNext = useCallback(async () => {
        if (step === formStepMeta.length - 2) {
            handleSubmit()
            return
        }
        // trigger validate
        const isValid = await form.trigger(currentStepForm.fields)

        if (isValid) {
            onChangeStep(step + 1)
        }
    }, [currentStepForm.fields, form, handleSubmit, onChangeStep, step])

    return (
        <FormProvider {...form}>
            <SC.BetaRequestForm>
                {/* {isSaved ? (
                    <Success name={form.getValues().name} email={form.getValues().email} />
                ) : (
                    <> */}
                <SC.BetaRequestStepTitleWrapper>
                    {/* <StepIndicator stepCount={indicatedStepCount} currentStep={currentIndicatorStep} /> */}
                    <SC.BetaRequestStepTitleWrapper>
                        <SC.BetaRequestStepTitle>{currentStepForm.stepTitle}</SC.BetaRequestStepTitle>
                        <SC.BetaRequestStepDescription>{currentStepForm.stepDescription}</SC.BetaRequestStepDescription>
                    </SC.BetaRequestStepTitleWrapper>
                    {currentStepForm.fields.map(fieldName => {
                        const field = userSchema[fieldName]

                        return <FormItemRender key={fieldName} name={fieldName} field={field} />
                    })}
                </SC.BetaRequestStepTitleWrapper>
                <SC.BetaRequestFormButtonGroup>
                    <SC.AccountButton disabled={isButtonDisabled} type="primary" block onClick={handleNext} loading={isSubmitting}>
                        {step < stepCount - 2 ? '下一步' : '创建并进入空间'}
                    </SC.AccountButton>
                </SC.BetaRequestFormButtonGroup>
                {/* </>
                )} */}
            </SC.BetaRequestForm>
        </FormProvider>
    )
}
