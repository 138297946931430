import { IconFont } from '@byecode/ui'
import cls from 'classnames'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FindUseLink } from './FindUseLink'

const SCxContainer = styled.div`
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
`

const SCxInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const SCxTitle = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const SCxDescription = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

const SCxIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxIconNode = styled.div<{ isTriggerNode?: boolean }>`
    height: 32px;
    width: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.trigger {
        background-color: var(--color-green-500);
        color: var(--color-white);
    }

    &:not(.trigger) {
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-200);
    }
`

const SCxIcon = styled(IconFont)``

interface FindUseLocationItemProps {
    title: string
    description?: string
    icon: string
    isWorkFlow?: boolean
    isTriggerNode?: boolean
    onLink?: () => void
}

export const FindUseLocationItem: React.FC<FindUseLocationItemProps> = ({
    title,
    description,
    icon,
    isWorkFlow,
    isTriggerNode,
    onLink
}) => {
    const iconContent = useMemo(() => {
        if (isWorkFlow) {
            return (
                <SCxIconNode className={cls({ trigger: isTriggerNode })} isTriggerNode={isTriggerNode}>
                    <SCxIcon size={18} type={icon} />
                </SCxIconNode>
            )
        }
        return <SCxIcon type={icon} />
    }, [icon, isTriggerNode, isWorkFlow])

    return (
        <SCxContainer>
            <SCxContent>
                <SCxIconWrapper>{iconContent}</SCxIconWrapper>
                <SCxInfo>
                    <SCxTitle>{title}</SCxTitle>
                    {description && <SCxDescription>{description}</SCxDescription>}
                </SCxInfo>
            </SCxContent>

            <FindUseLink radius={14} onClick={onLink} />
        </SCxContainer>
    )
}
