import { Button, Toast } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { emailRegex, ErrorMessage, mobileRegex, useAtomAction } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Modal } from '@mantine/core'
import { clone } from 'rambda'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import { fetchDataSourceUserAtom } from '@/atoms/dataSource/action'
import { addAppUsers } from '@/services'

import { inviteUser } from '../constant'
import * as CM from '../styles'
import * as SC from './styles'

interface UserInviteProps {
    envId: string
    onRefreshUserList?: () => void
}
export type InviteUser = Pick<AppUser, 'username' | 'mobile' | 'userId' | 'email'>
export type InviteUsers = {
    inviteUsers: InviteUser[]
}

const UserInvite: React.FunctionComponent<UserInviteProps> = ({ envId, onRefreshUserList }) => {
    const [open, setOpen] = useState(false)

    const {
        register,
        control,
        handleSubmit,
        reset,
        setError,
        formState: { errors }
    } = useForm<InviteUsers>({
        mode: 'onSubmit',
        defaultValues: {
            inviteUsers: clone(inviteUser)
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'inviteUsers'
    })

    const { run: fetchDataSourceUser} = useAtomAction(fetchDataSourceUserAtom)
    // const { update } = useDataSourceUsers()

    const handleInvite = useCallback(
        async (params: InviteUsers) => {
            const res = await addAppUsers(envId, params.inviteUsers)
            const isSuccess = res.addCompleted
            if (isSuccess) {
                reset({
                    inviteUsers: clone(inviteUser)
                })
                setOpen(false)
                Toast.success('邀请成功')
                fetchDataSourceUser()
                onRefreshUserList?.()
                return
            }
            if (res.phones) {
                params.inviteUsers.forEach((item, index) => {
                    if (res.phones?.includes(item.mobile)) {
                        setError(`inviteUsers.${index}.mobile`, { message: '手机号重复' })
                    }
                })
                Toast.error('存在手机号重复的用户')
            }
        },
        [envId, onRefreshUserList, reset, setError, fetchDataSourceUser]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(handleInvite)()
        },
        [handleInvite, handleSubmit]
    )

    return (
        <SC.Container>
            <Button
                onClick={() => {
                    setOpen(true)
                }}
                style={{ whiteSpace: 'nowrap' }}
                type="primary"
                icon={<CM.Icon type="Add" color="#fff" />}
            >
                添加用户
            </Button>
            <Modal
                opened={open}
                centered
                styles={{
                    modal: {
                        width: 545,
                        padding: '0!important',
                        overflow: 'hidden',
                        borderRadius: 12
                    },
                    close: {
                        right: 16,
                        top: 8,
                        '&:hover': {
                            background: 'var(--color-gray-100)'
                        }
                    }
                }}
                onClose={() => {
                    setOpen(false)
                    reset({
                        inviteUsers: clone(inviteUser)
                    })
                }}
            >
                <SC.PopoverContainer onSubmit={handleInviteSubmit}>
                    <SC.Title>添加用户</SC.Title>
                    <SC.Body>
                        {fields.map(({ mobile, username, userId }, index) => (
                            <SC.Item key={userId}>
                                <ErrorMessage name={`inviteUsers.${index}.username`} errors={errors}>
                                    <SC.InputItem
                                        placeholder="用户姓名"
                                        {...register(`inviteUsers.${index}.username`, { required: '请输入用户名' })}
                                    />
                                </ErrorMessage>
                                <ErrorMessage name={`inviteUsers.${index}.mobile`} errors={errors}>
                                    <SC.InputItem
                                        placeholder="手机号"
                                        {...register(`inviteUsers.${index}.mobile`, {
                                            required: '请输入手机号',
                                            pattern: {
                                                value: mobileRegex,
                                                message: '请填写正确手机号'
                                            }
                                        })}
                                    />
                                </ErrorMessage>
                                <ErrorMessage name={`inviteUsers.${index}.email`} errors={errors}>
                                    <SC.InputItem
                                        placeholder="邮箱（非必填）"
                                        {...register(`inviteUsers.${index}.email`, {
                                            required: false,
                                            pattern: {
                                                value: emailRegex,
                                                message: '请填写正确的邮箱'
                                            }
                                        })}
                                    />
                                </ErrorMessage>
                                <CM.Icon type="ReduceCircle" size={20} color="var(--color-red-500)" onClick={() => remove(index)} />
                            </SC.Item>
                        ))}
                        <Button
                            type="default"
                            icon={<CM.Icon type="Add" />}
                            style={{ width: 118 }}
                            onClick={() => append({ userId: nanoid(16), username: '', mobile: '' })}
                        />
                    </SC.Body>
                    <SC.Footer>
                        <Button
                            size="lg"
                            onClick={() => {
                                setOpen(false)
                                reset({
                                    inviteUsers: []
                                })
                            }}
                        >
                            取消
                        </Button>
                        <Button size="lg" type="primary" htmlType="submit">
                            确认邀请
                        </Button>
                    </SC.Footer>
                </SC.PopoverContainer>
            </Modal>
        </SC.Container>
    )
}

export default UserInvite
