import React from 'react'
import styled from 'styled-components'

interface BoxProps {
    children?: React.ReactNode
}

const SCxBox = styled.div`
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

export const Box: React.FC<BoxProps> = ({ children }) => {
    return <SCxBox>{children}</SCxBox>
}
