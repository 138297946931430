import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const FlowCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 261px; */
    height: 271px;
    flex-wrap: wrap;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    border-radius: 12px;
    padding: 24px;
    /* margin-right: 20px; */
    margin-bottom: 20px;
    justify-content: space-between;
    transition: box-shadow 0.2s ease;
    cursor: pointer;

    @media screen and (min-width: 1680px) {
        & {
            width: 19%;
            margin-right: 1.25%;
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 1280px) and (max-width: 1680px) {
        & {
            width: 24%;
            margin-right: 1.25%;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 980px) and (max-width: 1280px) {
        & {
            width: 32%;
            margin-right: 2%;
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 736px) and (max-width: 980px) {
        & {
            width: 49%;
            margin-right: 2%;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

    @media screen and (max-width: 736px) {
        & {
            width: 100%;
        }
    }

    &:hover {
        box-shadow: 0px 4px 12px rgba(56, 56, 56, 0.12);
        transition: box-shadow 0.2s ease;
    }
`

export const FlowCardContent = styled.div`
    width: 100%;
`

export const FlowCardHeader = styled.div`
    position: relative;
    width: 100%;
`

export const FlowCardIconsWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    /* width: 212px; */
    flex: 1;
`

export const FlowCardTriggerIcon = styled(IconFont)`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: var(--color-gray-400);
    flex-shrink: 0;
`

export const FlowCardArrowIcon = styled(IconFont)`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: var(--color-gray-400);
    flex-shrink: 0;
`

export const FlowCardExtraLinearBackground = styled.div`
    width: 14px;
    height: 28px;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to right, transparent, #fff);
`

export const FlowCardExtraIconWrapper = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    flex-shrink: 0;
`

export const FlowCardActionWrapper = styled.div`
    background-color: var(--color-white);
    position: absolute;
    /* z-index: 10; */
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 0;
    transition: all 0.2s ease;
    display: flex;
    flex-flow: row nowrap;

    ${FlowCardWrapper}:hover & {
        opacity: 1;
    }
`

export const FlowCardAction = styled(IconFont)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid var(--color-gray-200);
    cursor: pointer;
    line-height: 32px;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const FlowCardType = styled.p`
    font-size: var(--font-size-sm);
    margin: 12px 0;
`

export const FlowCardTitle = styled.p`
    max-height: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-size-base);
    font-weight: 600;
    color: var(--color-gray-900);
    margin: 12px 0;
`

export const FlowCardDescription = styled.p`
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FlowCardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FlowCardFooterLeft = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FlowCardStatusWrapper = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
`

export const FlowCardStatus = styled(IconFont)``

export const FlowCardUpdatedAt = styled.div`
    font-size: 12px;
    color: var(--color-gray-300);
`
