import { DEVELOPER, useAtomAction } from '@lighthouse/shared'
import React, { useLayoutEffect } from 'react'

import { setAppRoleAtom } from '@/atoms/application/action'
import DataSource from '@/containers/DataSource'

const DataSourcePage: React.FC = () => {
    const { run: setAppRole } = useAtomAction(setAppRoleAtom)

    useLayoutEffect(() => {
        setAppRole(DEVELOPER)
    }, [setAppRole])

    return <DataSource />
}

export default DataSourcePage
