import { Modal } from '@byecode/ui'
import React, { useState } from 'react'

import { Item } from '../Item'
import { ResetPassword } from './ResetPassword'
import * as SC from './styles'

export const AccountSecurity: React.FC = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <SC.List>
                <Item
                    title="登录密码"
                    icon="Key"
                    description="已设置，可通过账号密码登录"
                    extra={
                        <SC.ItemButton
                            onClick={() => {
                                setOpen(true)
                            }}
                        >
                            更改密码
                        </SC.ItemButton>
                    }
                />
            </SC.List>
            <Modal
                open={open}
                title="修改密码"
                width={462}
                styles={{
                    desktop: {
                        body: {
                            padding: '32px 62px 48px 62px'
                        }
                    }
                }}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <ResetPassword onClose={() => setOpen(false)} />
            </Modal>
        </>
    )
}
