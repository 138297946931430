import { useEffect } from 'react'
import type { ReactFlowState } from 'reactflow'
import { useStore } from 'reactflow'

export const usePannable = (flowRef: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
        if (!flowRef.current) {
            return
        }

        const keydownHandler = (e: KeyboardEvent) => {
            if (e.key === ' ') {
                flowRef.current?.classList.add('react-flow__pane_pan_start')
            }
        }

        const keyupHandler = (e: KeyboardEvent) => {
            if (e.key === ' ') {
                flowRef.current?.classList.remove('react-flow__pane_pan_start')
            }
        }

        document.addEventListener('keydown', keydownHandler, false)
        document.addEventListener('keyup', keyupHandler, false)

        return () => {
            document.removeEventListener('keydown', keydownHandler, false)
            document.removeEventListener('keyup', keyupHandler, false)
        }
    }, [flowRef])
}

interface UseZoomLevel {
    toPercent?: boolean
}

const zoomLevelSelector = (state: ReactFlowState) => state.transform[2]

export const useFlowZoomLevel = (params?: UseZoomLevel) => {
    const zoomLevelRatio = useStore(zoomLevelSelector)

    return params?.toPercent ? `${(zoomLevelRatio * 100).toFixed(0)}%` : zoomLevelRatio
}
