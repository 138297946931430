import { IconFont } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useFindUseContext } from '@/contexts/PageContext'

const SCxContainer = styled.div`
    height: 54px;
    width: 100%;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-200);
`

const SCxTitleBox = styled.div`
    flex: 1;
    overflow: hidden;
    padding-right: 28px;
    color: var(--color-black);
`

const SCxText = styled.div`
    max-width: 220px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const SCxIcon = styled(IconFont)`
    cursor: pointer;
`

interface FindUseHeaderProps {}

export const FindUseHeader: React.FC<FindUseHeaderProps> = () => {
    const { onChange: findUseChange } = useFindUseContext()

    const handleClose = useCallback(() => {
        findUseChange?.({ open: false })
    }, [findUseChange])

    return (
        <SCxContainer>
            <SCxTitleBox>
                <SCxText>查找使用</SCxText>
            </SCxTitleBox>
            <SCxIcon type="Close" size={20} onClick={handleClose} />
        </SCxContainer>
    )
}
