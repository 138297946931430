import { Empty } from '@byecode/ui'
import { ScrollArea } from '@mantine/core'
import * as React from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

import { BlockCard } from './BlockCardList'
import { ModuleCard } from './ModuleList'

interface SearchCardListProps {
    options: BlockTreeItem[]
    onCreate: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

const SCxContainer = styled.div`
    margin-top: 12px;
    width: 264px;
    height: 100%;
    padding: 0 16px;
    gap: 12px;
    max-height: 100%;
    overflow: hidden;
`

const SCxList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`
const SearchCardList: React.FunctionComponent<SearchCardListProps> = ({ options, onCreate }) => {
    return (
        <SCxContainer>
            <ScrollArea scrollbarSize={0}>
                <SCxList>
                    {options.map(item => {
                        switch (item.type) {
                            case 'formContainer':
                            case 'container': {
                                return <ModuleCard data={item} onCreate={onCreate} />
                            }
                            default: {
                                return <BlockCard data={item} onCreate={onCreate} />
                            }
                        }
                    })}
                    {options.length === 0 && (
                        <Empty
                            styles={{
                                root: {
                                    padding: '42px'
                                }
                            }}
                            icon="SearchLine"
                            description="没有搜索到结果"
                        />
                    )}
                </SCxList>
            </ScrollArea>
        </SCxContainer>
    )
}

export default SearchCardList
