import { FilterMode } from '@lighthouse/core'
import type { FlowNode } from '@lighthouse/shared'
import { CollapseBox, singleRecordNodeType } from '@lighthouse/shared'
import React, { useMemo } from 'react'

import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'

import { TriggerFilter } from '../../TriggerFilter'
import * as CM from '../styles'

interface ConditionConfigureProps {
    allParentNodes: FlowNode[]
}

export const ConditionConfigure: React.FC<ConditionConfigureProps> = ({ allParentNodes }) => {
    const allSingleParentNodes = useMemo(
        () => allParentNodes.filter(item => singleRecordNodeType.has(item.data.nodeType)),
        [allParentNodes]
    )

    const { dsId: formDsId, isForm, viewType } = useActionAdderDepParams()
    return (
        <CollapseBox label="设置条件" required>
            <CM.Tip style={{ padding: '0 16px' }}>满足条件的数据才能进入该分支；满足条件分支下的节点都会执行（并行分支）</CM.Tip>
            <TriggerFilter
                sourceFieldFlowNode={allSingleParentNodes}
                prefix="config"
                formDsId={isForm ? formDsId : ''}
                viewType={viewType}
                paramsMode={FilterMode.CUSTOM}
                fieldMode={FilterMode.CUSTOM}
            />
        </CollapseBox>
    )
}
