import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal, Modal } from '@byecode/ui'
import React from 'react'

import type { WeChatPayAccountMode } from '../type'
import WeChatPayAccount from '../WeChatPayAccount'

interface WeChatPayAccountModalProps extends BaseModalProps {
    mode: WeChatPayAccountMode
    id?: string
}

export const WeChatPayAccountModal: React.FunctionComponent<WeChatPayAccountModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <WeChatPayAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
