import { IconFont, Input, pointer, Select } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const BackPrevious = styled.div`
    padding: 0 16px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    ${pointer}
`

export const backIcon = styled(IconFont)`
    font-size: 16px;
    transform: rotate(-90deg);
    margin-right: 6px;
`

export const backText = styled.div`
    font-size: 14px;
    color: var(--color-black);
`

export const FilterForm = styled.form`
    padding: 0 16px;
`

export const FilterGroupWrapper = styled.div`
    display: flex;
    flex: 1;
    min-width: 390px;
`

export const FilterGroupContentWrapper = styled.div`
    flex: 1;
    &.real-filter-group {
        /* background-color: var(--color-gray-50); */
        /* border: 1px solid var(--color-gray-200); */
        background-color: rgba(200, 200, 200, 0.1);
        border: 1px solid rgba(180, 180, 180, 0.1);
        border-radius: 5px;
        padding: 12px 0 12px 12px;
    }
`

export const FilterGroup = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

export const FilterGroupGuideLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    margin-left: 12px;
    align-self: stretch;
`

export const FilterGroupGuideLineRect = styled.div`
    position: absolute;
    width: 50%;
    height: calc(100% - 30px);
    right: 0;
    top: 15px;
    border: 1px solid var(--color-main);
    border-right-color: transparent;
`

export const WhereSelectorWrapper = styled.div`
    position: relative;
    z-index: 1;
`

export const SelectContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--color-gray-500);
`

export const FilterName = styled(Input)`
    width: 100%;
`

export const FilterRadio = styled.div`
    width: 100px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

export const FilterLeftRadio = styled.div<{ check?: boolean }>`
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #649bfa;
    border-radius: 7px 0px 0px 7px;
    ${({ check }) =>
        check
            ? css`
                  background: var(--color-main-tint);
                  border-color: var(--color-main);
              `
            : css`
                  background: transparent;
                  color: '#DFDFDD';
                  border-right-color: transparent;
              `}
    ${pointer}
`

export const FilterRightRadio = styled.div<{ check?: boolean; left?: number }>`
    position: relative;
    left: ${({ left }) => (left ? `${left}px` : '0px')};
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: var(--color-main-tint);
    border-radius: 0px 7px 7px 0px;
    border: 1px solid var(--color-main);
    ${({ check }) =>
        check
            ? css`
                  background: var(--color-main-tint);
                  border-color: var(--color-main);
              `
            : css`
                  background: transparent;
                  border-color: '#DFDFDD';
                  border-left-color: transparent;
              `}
    ${pointer}
`

export const RadioIcon = styled(IconFont)<{ check?: boolean }>`
    font-size: 16px;
    color: ${({ check }) => (check ? '#649BFA' : '#DFDFDD')};
`

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    font-size: var(--font-size-normal);
    margin: 12px 16px;
`

export const SelectedValue = styled.span`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FilterGroupItemWrapper = styled.div`
    flex: 1;
`

export const PropertySettingWrapper = styled.div`
    min-width: 390px;
    min-height: 78px;
`

export const HorizontalDivider = styled.div<{ offset?: number }>`
    margin: ${({ offset }) => (offset ? `${offset}px 0` : '16px 0')};
    height: 1px;
    background-color: var(--color-gray-100);
`

export const PropertySettingTitle = styled.p`
    margin-bottom: 8px;
    font-weight: 600;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

export const FilterSelect = styled(Select)``

export const PropertyFooter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
`

export const PropertyAdderIcon = styled(IconFont)`
    color: var(--color-main);
    margin-right: 6px;
`

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
`

export const IconGroup = styled.div`
    color: var(--color-gray-500);
    display: flex;
    align-items: center;
`

export const ActionIcon = styled(IconFont)`
    cursor: pointer;
`

export const Empty = styled.div`
    padding: 8px 16px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`

export const FilterInput = styled(Input)`
    width: 118px;
    margin-right: 8px;
`

export const DeleteFilter = styled.div`
    width: 100%;
    height: 26px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 0 16px;
`

export const Delete = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    ${pointer}
`

export const DeleteIcon = styled(IconFont)`
    color: #d88987;
    font-size: 16px;
    margin-right: 8px;
`

export const DeleteText = styled.div`
    color: #d88987;
    font-size: 14px;
`
