import type { ModalProps } from '@byecode/ui'
import { Modal } from '@byecode/ui'
import type { ReactNode } from 'react';
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
    .byecode-Modal-body {
        padding: 0;
    }

    .byecode-Modal-header {
        border-bottom: none;
        height: 0;
        padding: 0;
        position: relative;
    }

    .byecode-Modal-actions {
        position: absolute;
        z-index: 1;
        right: 20px;
        top: 20px;
    }
`

const ContentTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin: 32px 0 20px 0;
    padding: 0 32px;
`

const ContentDescription = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    padding: 0 32px;
`

export interface TitleInContentModalProps extends ModalProps {
    description?: string | ReactNode
}

/**
 *将bui中的modal desktop形态的title移到内容区的变体
 */
export const TitleInContentModal = forwardRef<HTMLDivElement, TitleInContentModalProps>((props, ref) => {
    const { title, styles = {}, description, children, ...rest } = props
    return (
        <StyledModal {...rest} styles={styles} ref={ref}>
            {title && <ContentTitle className="byecode-Modal-title">{title}</ContentTitle>}
            {description && <ContentDescription>{description}</ContentDescription>}
            {children}
        </StyledModal>
    )
})
