import { Divider, Select } from '@byecode/ui'
import type { DataSourceRelayConfig, FlowNode } from '@lighthouse/shared'
import { CollapseBox, generatedDataNodeTypes, nodeTypeIconMap } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSwr from 'swr'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../styles'
import { ApprovalFlowSelector } from './ApprovalFlowSelector'

interface FindSingleRecordActionConfigureProps {
    allParentNodes: FlowNode[]
}

export const InitiateApproveActionConfigure: React.FC<FindSingleRecordActionConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: listDs = [] } = useSwr(`/fetch/listDs/${appId}`, () => srv.listDs(appId, envId), {
        revalidateOnFocus: false,
    })
    const { control } = useFormContext()

    const approveObjectIdOptions = useMemo(() => {
        const options = []
        for (const node of allParentNodes) {
            if (node.data) {
                const { nodeType } = node.data
                if (generatedDataNodeTypes.has(nodeType)) {
                    options.push({
                        label: node.data.name,
                        value: node.id,
                        icon: nodeTypeIconMap[nodeType],
                        node
                    })
                }
            }
        }
        return options
    }, [allParentNodes])

    const approveObjectId: string = useWatch({
        control,
        name: 'config.approveObjectId'
    })

    const approveObjectDataSourceName = useMemo(() => {
        const approveObject = approveObjectIdOptions.find(item => item.value === approveObjectId)?.node.data
        const dsId = (approveObject?.config as DataSourceRelayConfig)?.dataSourceId

        return find(item => item.id === dsId, listDs)?.name
    }, [approveObjectId, approveObjectIdOptions, listDs])

    return (
        <>
            <SC.Container>
                <CollapseBox label="审批对象" required>
                    <SC.Content>
                        <SC.Tip>即要审批哪条记录，需从上游节点中选一个已经获取到了单条记录的节点</SC.Tip>
                        <Controller
                            name="config.approveObjectId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <Select
                                        {...field}
                                        style={{ margin: '4px 0' }}
                                        searchable
                                        placeholder="请选择审批对象"
                                        value={field.value}
                                        options={approveObjectIdOptions}
                                    />
                                )
                            }}
                        />
                        {approveObjectId && <SC.Tip style={{ marginTop: 8 }}>数据表：{approveObjectDataSourceName}</SC.Tip>}
                    </SC.Content>
                </CollapseBox>
                <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                <CollapseBox label="选择审批流程" required>
                    <SC.Content>
                        <SC.Tip>需选择一个和审批对象相同数据表的审批流程</SC.Tip>
                        <Controller
                            name="config.approveFlowId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return <ApprovalFlowSelector {...field} />
                            }}
                        />
                    </SC.Content>
                </CollapseBox>
            </SC.Container>
        </>
    )
}
