import { type FieldValue, SelectedMode } from '@lighthouse/core'
import { getCascadeOption } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { atom } from 'jotai'

import * as srv from '@/services'

import { currentAppIdAtom, currentEnvIdAtom } from '../application/state'
import { addDataSourceAtom, addRecordAtom, deleteRecordAtom, setRecordAtom } from '../dataSource/action'
import { dataSourceAtomFamily } from '../dataSource/state'
import type { CreateRecordAtomPayload, CreateViewRecordInDataDrawerAtomPayload } from '../dataSource/types'
import { blockUpdateListAtom, pageBlocksAtom } from '../page/state'
import { isViewBlock } from '../utils/block'
import { filterBlockOptionsAtom, viewRecordListAtomFamily, viewRecordPoolAtom } from './state'
import type {
    AddViewRecordAtomPayload,
    FetchChartBlockPayload,
    FetchViewRecordsAtomPayload,
    HandleFetchFilterCascadeOptionsPayload,
    HandleFetchFilterOptionsPayload,
    updateViewSearchAtomPayload
} from './types'

/** 添加视图记录数据 */
export const addViewRecordAtom = atom(null, (_, set, payload: AddViewRecordAtomPayload) => {
    const { viewId, records } = payload
    const recordAtom = viewRecordListAtomFamily(viewId)

    set(recordAtom, records)
})

/** 加载视图记录数据 */
export const loadViewRecordAtom = atom(null, (_, set, payload: AddViewRecordAtomPayload) => {
    const { viewId, records } = payload
    set(viewRecordPoolAtom, draft => {
        records.forEach(record => {
            draft[viewId].push(record)
        })
    })
})

export const fetchViewCountAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { viewId, search, params, currentRecordId, parentRecordId, pageId, abort } = payload
    const quickFilters = params?.quickFilters || []
    const linkFilter = params?.linkFilter
    const rowTotal = await srv.getViewCount({ viewId, quickFilters, search, currentRecordId, linkFilter, parentRecordId, abort })
    if (rowTotal === undefined) {
        return
    }
    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = currentBlocks.find(item => item.id === viewId)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }
        draftBlock.config.pagination.rowTotal = rowTotal
    })
})

/** 获取视图数据 */
export const fetchViewRecordsAtom = atom(null, async (get, set, payload: FetchViewRecordsAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    set(blockUpdateListAtom, [])
    const { viewId, pagination, search, params, currentRecordId, parentRecordId, pageId, disableFetchCount, abort } = payload
    const viewData = await srv.getViewByBlock({ appId, envId, viewId, pagination, params, search, currentRecordId, parentRecordId, abort })
    if (!viewData) {
        return
    }
    const { datasource, records } = viewData

    if (!disableFetchCount) {
        set(fetchViewCountAtom, payload)
    }

    set(addDataSourceAtom, { dataSource: datasource })
    set(addRecordAtom, { records })

    set(addViewRecordAtom, { viewId, records })
    // set(setBlockRecordIdsAtom, { viewId, recordIds })
    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = currentBlocks.find(item => item.id === viewId)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }

        // draftBlock.config = {
        //     ...draftBlock.config,
        //     ...config.view
        // }
        draftBlock.config.search = search
        draftBlock.config.pagination.currentPage = pagination.currentPage
    })
    return viewData
})

/** 加载视图数据 */
export const loadViewRecordsAtom = atom(null, async (get, set, payload: FetchViewRecordsAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const { viewId, pagination, search, params, currentRecordId, parentRecordId, pageId, abort } = payload
    const viewData = await srv.getViewByBlock({ appId, envId, viewId, pagination, params, search, currentRecordId, parentRecordId, abort })
    if (!viewData) {
        return []
    }
    const { records } = viewData
    set(addRecordAtom, { records })
    set(loadViewRecordAtom, { viewId, records })
    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = currentBlocks.find(item => item.id === viewId)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }

        draftBlock.config.search = search
        draftBlock.config.pagination.currentPage = pagination.currentPage
    })
    return records
})

export const updateViewSearchAtom = atom(null, (_, set, payload: updateViewSearchAtomPayload) => {
    const { pageId, viewId, search } = payload
    set(pageBlocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = currentBlocks.find(item => item.id === viewId)
        if (!draftBlock) {
            return
        }

        if (!isViewBlock(draftBlock)) {
            return
        }

        draftBlock.config.search = search
    })
})

/** 删除记录数据 */
// export const deleteViewRecordAtom = atom(null, async (get, set, payload: DeleteViewRecordAtomPayload) => {
//     const { appId, dsId, viewId, recordIds } = payload
//     // const dataSourceAtom = dataSourceAtomFamily({ appId, dsId })
//     const isDelete = await srv.deleteRecord(appId, dsId, recordIds)
//     const recordAtom = viewRecordListAtomFamily({ viewId })
//     set(recordAtom, draft => {
//         recordIds.forEach(id => {
//             const index = findIndex(item => item.appId === appId && item.id === id, draft)
//             draft.splice(index, 1)
//         })
//     })
//     set(setRecordsAtom, {
//         records: draft => {
//             recordIds.forEach(id => {
//                 const index = findIndex(item => item.appId === appId && item.id === id, draft)
//                 draft.splice(index, 1)
//             })
//         }
//     })
//     return isDelete
// })

// /** 更新单元格 */
// export const updateViewCellAtom = atom(null, (_, set, payload: UpdateViewCellAtomPayload) => {
//     const { recordId, appId, dsId, fieldId, value, viewRecordsAtom } = payload
//     set(viewRecordsAtom, draft => {
//         const record = find(item => item.id === recordId, draft)
//         if (record) {
//             record.content[fieldId] = value
//         }
//     })
//     return srv.updateCell({
//         appId,
//         dsId,
//         id: recordId,
//         fieldId,
//         content: value
//     })
// })

/** 获取图表数据 */
export const fetchChartBlockAtom = atom(null, async (_, set, payload: FetchChartBlockPayload) => {
    set(blockUpdateListAtom, [])
    const data = await srv.getDsGraphView(payload)
    return data.chart
})

// 获取文本 成员筛选器 options
export const handleFetchFilterOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterOptionsPayload) => {
    const data = await srv.getRelativeOptions(payload)
    set(filterBlockOptionsAtom, draft => {
        draft[`${payload.blockId}-${payload.filterItemId}`] = data
    })
    return data
})

// 获取级联筛选器 options
export const handleFetchFilterCascadeOptionsAtom = atom(null, async (get, set, payload: HandleFetchFilterCascadeOptionsPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const { dsId, sortFieldPointer = '', fieldPointer, parentFieldPointer, blockId, filterItemId, pageSize } = payload
    const res = await srv.getDs({
        appId,
        envId,
        dsId,
        pagination: { currentPage: 1, pageSize },
        sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
    })
    // const a = res?.records?.filter(record => record?.content?.['ID']?.value === '002')
    const usedRecords = res?.records?.filter(record => record?.content?.[fieldPointer]?.value)

    const options =
        usedRecords
            ?.filter(record => !record?.content?.[parentFieldPointer]?.value)
            .map(record =>
                getCascadeOption({ parentFieldPointer, fieldPointer: 'ID', showFieldPointer: fieldPointer, record, records: usedRecords })
            )
            .filter(item => item.value) ?? []
    set(filterBlockOptionsAtom, draft => {
        draft[`${blockId}-${filterItemId}`] = options
    })
    return options
})

/** 数据查看器创建视图数据 */
export const createViewRecordInDataDrawerAtom = atom(null, async (get, set, payload: CreateViewRecordInDataDrawerAtomPayload) => {
    const { viewId, recordId, appId, envId, dsId, content, beforeId } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const newRecordId = recordId || nanoid(32)
    const dataSource = get(dataSourceAtom)
    if (!dataSource) {
        return
    }
    const { schema } = dataSource
    // createRecordAction(newRecordId, appId, dsId, beforeId, set)

    // const record = await srv.createRecord(appId, newRecordId, dsId, beforeId)
    const rContent: Record<string, FieldValue> = Object.fromEntries(
        Object.entries(schema).map(([fieldId, config]) => {
            return [
                fieldId,
                {
                    value: ''
                }
            ]
        })
    )
    const newRecord = { content: rContent, appId, envId, dsId, id: newRecordId }
    set(addRecordAtom, {
        records: [newRecord]
    })
    const recordAtom = viewRecordListAtomFamily(viewId)

    set(recordAtom, draft => {
        draft.unshift(newRecord)
    })
    const record = await srv.createRecord({ appId, envId, id: newRecordId, dsId, content, beforeId })
    if (!record) {
        set(deleteRecordAtom, {
            envId,
            dsId,
            mode: SelectedMode.CURRENT_PAGE,
            recordIds: [newRecordId]
        })
        set(recordAtom, draft => {
            return draft.filter(record => record.id !== newRecordId)
        })
        return
    }
    if (record) {
        set(setRecordAtom, {
            envId,
            recordId: newRecordId,
            dsId,
            record
        })
    }
    return newRecordId
})
