import { backgroundAndColorOnClick, flex, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
`

export const Close = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 4px;
    border-radius: 5px;
    ${pointer}
    ${backgroundAndColorOnClick}
`

export const PopoverContainer = styled.div`
    padding: 32px 24px;
    position: relative;
    /* width: 473px; */
    /* padding: 24px 34px; */
    font-size: var(--font-size-normal);
`

export const Title = styled.div`
    height: 28px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-black);
    margin-bottom: 20px;
`

export const ErrorText = styled.span`
    color: var(--color-red-500);
`

export const Box = styled.div``

export const SecondTitle = styled.div`
    font-weight: 500;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    margin-bottom: 12px;
`

export const Description = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
    margin-bottom: 8px;
`

export const Card = styled.div`
    padding: 20px 160px;
    background: var(--color-gray-50);
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    margin-bottom: 20px;
`

export const ResultText = styled.div`
    color: var(--color-gray-500);
    font-size: var(--font-size-sm);
    line-height: 20px;
    white-space: nowrap;
`

export const UpgradeVersion = styled.span`
    color: var(--color-theme-7);
    font-weight: 500;
`

export const UploaderAreaDescription = styled.div`
    text-align: center;
`

export const Footer = styled.div`
    ${flex}
    justify-content: flex-end;
    gap: 12px;
`
