import { IconFont, Loading } from '@byecode/ui'
import { useTransition } from '@react-spring/web'
import type { BatchItem } from '@rpldy/uploady'
import Uploady, {
    FILE_STATES,
    useAbortItem,
    useItemErrorListener,
    useItemFinishListener,
    useItemProgressListener,
    useItemStartListener,
    useUploadyContext
} from '@rpldy/uploady'
import produce from 'immer'
import { clone } from 'rambda'
import React from 'react'
import { useLatest } from 'react-use'
import { useImmer } from 'use-immer'

import { uploadVikaParams } from '@/utils/auth'

import * as SC from './styles'

interface UploadProps {
    value?: BatchItem[]
    onChange?: (v: BatchItem[]) => void
}

const UploadList = ({ onChange }: UploadProps) => {
    const latestProps = useLatest({ onChange })
    const [files, setFiles] = useImmer<BatchItem[]>([])

    const abort = useAbortItem()

    useItemStartListener(item => {
        return setFiles(draft => {
            draft.push(clone(item))
        })
    })

    // useItemProgressListener(item => {
    //     setFiles(draft => {
    //         const index = draft.findIndex(i => i.id === item.id)
    //         draft.splice(index, 1, item)
    //     })
    // })

    useItemErrorListener(item => {
        setFiles(draft => {
            const index = draft.findIndex(f => f.id === item.id)
            draft.splice(index, 1, clone(item))
        })
    })

    useItemFinishListener(item => {
        const newFiles = produce(files, draft => {
            const index = draft.findIndex(f => f.id === item.id)
            item.url = item.uploadResponse.data.data.url
            draft.splice(index, 1, clone(item))
        })
        setFiles(newFiles)
        const validItems = newFiles.filter(f => f.state === FILE_STATES.FINISHED)
        latestProps.current.onChange?.(validItems)
    })

    const transition = useTransition(files, {
        keys: item => item.id,
        expires: true,
        from: { opacity: 0, height: 0, transform: 'translateX(20px)' },
        enter: { opacity: 1, height: 'auto', transform: 'translateX(0)' }
    })

    return (
        <SC.FileList>
            {transition((style, file, _, index) => {
                const isLoading = [FILE_STATES.PENDING, FILE_STATES.UPLOADING].includes(file.state)
                return (
                    <div>
                        <SC.FileListItem style={style} error={file.state === FILE_STATES.ERROR}>
                            <IconFont type="FileImage" size={24} />
                            <SC.FileListItemName>{file.file.name}</SC.FileListItemName>
                            <SC.FileListItemActions>
                                <IconFont
                                    type="Trash"
                                    size={16}
                                    onClick={() => {
                                        setFiles(draft => {
                                            if (isLoading) {
                                                abort(file.id)
                                            }
                                            draft.splice(index, 1)
                                        })
                                    }}
                                />
                            </SC.FileListItemActions>

                            {isLoading ? (
                                <SC.FileListItemLoadingMask>
                                    <Loading outlined />
                                </SC.FileListItemLoadingMask>
                            ) : null}
                        </SC.FileListItem>

                        {file.state === FILE_STATES.ERROR && (
                            <SC.FileListItemError>
                                {file.uploadResponse ? file.uploadResponse.data.message : '上传失败'}
                            </SC.FileListItemError>
                        )}
                    </div>
                )
            })}
        </SC.FileList>
    )
}

const UploadAction = () => {
    const uploady = useUploadyContext()

    return (
        <SC.UploadButton onClick={() => uploady.showFileUpload()}>
            <IconFont type="Add" size={24} style={{ verticalAlign: 'middle' }} />
        </SC.UploadButton>
    )
}

export const Upload = (props: UploadProps) => {
    return (
        <Uploady accept="image/*" sendWithFormData {...uploadVikaParams()}>
            <UploadAction />
            <UploadList {...props} />
        </Uploady>
    )
}
