import { IconFont } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Content = styled.div`
    padding-bottom: 8px;
`

export const Text = styled.span<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? 14}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
`

export const Icon = styled(IconFont)<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? '16'}px;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    cursor: pointer;
`

export const SelectContainer = styled.div`
    width: 100%;
`

export const RightFill = styled.div`
    display: none;
    align-items: center;
    justify-content: flex-end;
`

export const FieldList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
    /* padding: 0 16px; */
`

export const FieldTitle = styled.div<{ required?: boolean }>`
    height: 22px;
    line-height: 22px;
    font-size: 14px;

    position: relative;
    ${({ required }) =>
        required &&
        css`
            &::after {
                content: '*';
                position: absolute;
                top: 0;
                right: -12px;
                color: var(--color-red-500);
            }
        `}
`

export const FieldItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;
`
