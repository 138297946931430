import { DataSourceType } from '@lighthouse/core'
import type { FindUseData } from '@lighthouse/shared'
import { FindUseLocationType, useAtomAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import { useEffect, useState } from 'react'

import { setFindUseAtom } from '@/atoms/application/action'
import { findUseAtom } from '@/atoms/application/state'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSourceList } from './useDataSource'

export const useFindUse = (type: FindUseLocationType, params: { id: string; nodeId?: string }) => {
    const { id, nodeId } = params
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const findUseData = useAtomData(findUseAtom)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)
    const [state, setState] = useState<FindUseData | undefined>()

    useEffect(() => {
        if (!findUseData) {
            return
        }
        const { location } = findUseData
        if (location.type !== type) {
            return
        }
        switch (location.type) {
            case FindUseLocationType.BLOCK: {
                if (location.blockId === id) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
                return
            }

            case FindUseLocationType.DATASHEET: {
                const ds = find(item => item.id === location.dsId, dataSourceList)
                if (!ds) {
                    return
                }
                const { type } = ds
                if (type === DataSourceType.joinDataSource && location.dsId === id) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
                if (type === DataSourceType.aggregateDataSource && location.dsId === id && location.nodeId === nodeId) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
                return
            }

            case FindUseLocationType.FIELD: {
                if (location.fieldId === id) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
                return
            }

            case FindUseLocationType.PAGE: {
                if (location.pageId === id) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
                return
            }

            default: {
                if (location.workflowId === id && location.nodeId === nodeId) {
                    setState(findUseData)
                    setFindUse(undefined)
                }
            }
        }
    }, [dataSourceList, findUseData, id, nodeId, setFindUse, type])

    return state?.config
}
