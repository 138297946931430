import { IconFont, Popover } from '@byecode/ui'
import type { WorkflowType } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { workflowLabelMap, workflowOptions } from '@/constants/flow'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import * as srv from '@/services'

import { addNewTriggerNodeWithEdges } from '../FlowDetail/utils/addNode'
import { AllFlow } from './AllFlow'
import { RunLog } from './RunLog'
import * as SC from './styles'

export interface FlowListProps {
    appId: string
}

export const FlowList: React.FC<FlowListProps> = ({ appId }) => {
    const navigate = useNavigate()
    const [tab, setTab] = useState<'allFlow' | 'runLog'>('allFlow')
    const [open, setOpen] = useState(false)
    const disabledWithVersion = useIsDisabledWithVersion()

    const navigateToFlowDetail = useCallback(
        (flowId: string) => {
            navigate({ pathname: `./${flowId}` })
        },
        [navigate]
    )

    const handleFlowCreate = useCallback(
        async (type: WorkflowType) => {
            let flowInitInfo
            switch (type) {
                case 'approval': {
                    flowInitInfo = addNewTriggerNodeWithEdges('INITIATE_APPROVE_TRIGGER')
                    break
                }
                case 'action': {
                    flowInitInfo = addNewTriggerNodeWithEdges('CLICK_TRIGGER')
                    break
                }
                default: {
                    break
                }
            }
            const workflowId = await srv.createFlow({
                name: `未命名${workflowLabelMap[type].label}`,
                type,
                description: `未命名${workflowLabelMap[type].label}的描述`,
                ...flowInitInfo
            })

            if (workflowId) {
                navigateToFlowDetail(workflowId)
                return true
            }

            return false
        },
        [navigateToFlowDetail]
    )

    const content = useMemo(() => {
        switch (tab) {
            case 'allFlow': {
                return <AllFlow appId={appId} onCardClick={navigateToFlowDetail} />
            }

            case 'runLog': {
                return <RunLog appId={appId} />
            }

            default: {
                return null
            }
        }
    }, [appId, navigateToFlowDetail, tab])

    return (
        <SC.FlowListWrapper>
            <SC.FlowListTabWrapper>
                {!disabledWithVersion && (
                    <>
                        <Popover opened={open} onChange={setOpen} returnFocus={false}>
                            <Popover.Target>
                                <SC.FlowAdderWrapper>
                                    <SC.FlowAdderButton>
                                        <SC.FlowAdderButtonText>添加流程</SC.FlowAdderButtonText>
                                        <IconFont type="ArrowDownSmall" />
                                    </SC.FlowAdderButton>
                                </SC.FlowAdderWrapper>
                            </Popover.Target>
                            <Popover.Dropdown>
                                {workflowOptions.map(item => (
                                    <SC.FlowAdderDropdownItem key={item.value} onClick={() => handleFlowCreate(item.value)}>
                                        <SC.FlowAdderDropdownItemIconWrapper style={{ backgroundColor: item.color }}>
                                            <SC.FlowAdderDropdownItemIcon type={item.icon} size={20} />
                                        </SC.FlowAdderDropdownItemIconWrapper>
                                        {item.label}
                                    </SC.FlowAdderDropdownItem>
                                ))}
                            </Popover.Dropdown>
                        </Popover>
                        <Divider style={{ margin: '16px 0' }} color="var(--color-gray-200)" />
                    </>
                )}
                <SC.FlowListTab active={tab === 'allFlow'} onClick={() => setTab('allFlow')}>
                    <SC.FlowListTabIcon type="Workflow" />
                    所有流程
                </SC.FlowListTab>
                <SC.FlowListTab active={tab === 'runLog'} onClick={() => setTab('runLog')}>
                    <SC.FlowListTabIcon type="PageRecordContent" />
                    运行日志
                </SC.FlowListTab>
            </SC.FlowListTabWrapper>
            <SC.FlowListRightContent>{content}</SC.FlowListRightContent>
        </SC.FlowListWrapper>
    )
}
