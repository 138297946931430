import type React from 'react';
import { createContext, useContext } from 'react';

export type SpaceContextValue = {
    open: boolean
    promotionOpen: boolean
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>
    setPromotionOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export type SpaceContextProps = SpaceContextValue & {
    children: React.ReactNode
}

export const SpaceContext = createContext<SpaceContextValue>({
    open: false,
    promotionOpen: false
})


export const useSpaceContext = () => useContext(SpaceContext)

SpaceContext.displayName = 'SpaceContext'
