import { RichTextEditor } from '@lighthouse/shared'
import { Carousel } from '@mantine/carousel'
import { format } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import * as CM from '../styles'
import TemplateImage from '../TemplateImage'
import { TempleLabels } from '../TempleLabels'
import type { Template } from '../types'

interface TemplateDetailProps {
    data?: Template
}
const SCXContainer = styled.div`
    width: 100%;
    margin-top: 32px;
    padding-bottom: 100px;
`

const SCXDescribe = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-top: 12px;
    color: var(--color-black);
`

const SCXFooter = styled.div`
    width: 100%;
    margin-top: 32px;

    > *:nth-child(odd):not(:first-child) {
        margin-left: 26px;
    }
`

const TemplateDetail: React.FunctionComponent<TemplateDetailProps> = ({ data }) => {
    const [slideIndex, setSlideIndex] = useState(0)

    const { describeImageList = [], describeText, createdBy, iconBackgroundColor, createdTime = '', labels } = data ?? {}

    return (
        <SCXContainer>
            {describeImageList.length > 0 && (
                <>
                    <TemplateImage
                        style={{ marginBottom: 16 }}
                        ratio={9 / 16}
                        backgroundColor={`${iconBackgroundColor}20`}
                        value={describeImageList[slideIndex]}
                    />
                    <Carousel
                        // height={92}
                        slideSize={300}
                        slideGap={15}
                        loop
                        initialSlide={slideIndex}
                        controlsOffset="md"
                        align="start"
                        controlSize={50}
                        onSlideChange={val => setSlideIndex(val)}
                    >
                        {describeImageList.map((item, index) => (
                            <Carousel.Slide gap="lg" key={item} onClick={() => setSlideIndex(index)}>
                                <TemplateImage
                                    ratio={9 / 16}
                                    backgroundColor={`${iconBackgroundColor}20`}
                                    value={item}
                                    active={slideIndex === index}
                                />
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                </>
            )}
            <TempleLabels labels={labels} style={{ marginTop: '32px', fontSize: 16 }} />
            <SCXDescribe>
                <RichTextEditor disableToolbar readonly value={describeText} />
            </SCXDescribe>
            <SCXFooter>
                <CM.Text size={14} color="var(--color-gray-400)">
                    作者
                </CM.Text>
                <CM.Text size={14} style={{ paddingLeft: 16 }}>
                    {createdBy}
                </CM.Text>
                <CM.Text size={14} color="var(--color-gray-400)">
                    发布时间
                </CM.Text>
                <CM.Text size={14} style={{ paddingLeft: 16 }}>
                    {format(Number(createdTime), 'yyyy-MM-dd')}
                </CM.Text>
            </SCXFooter>
        </SCXContainer>
    )
}

export default TemplateDetail
