import type { FilterOption, RecordLikeProtocol, ViewRecordListIdentity } from '@lighthouse/core'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import shallowEqual from 'shallowequal'

import { currentEnvIdAtom } from '../application/state'
import { recordPoolAtom } from '../dataSource/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import type { ViewRecordPool } from './types'

// id 为 blockId-filterItemId
export const filterBlockOptionsAtom = atomWithImmer<Record<string, FilterOption[]>>({})

export const viewRecordPoolAtom = atomWithImmer<ViewRecordPool>({})

// key 为  appId-pageId-blockId  拼接而成
// export const filterBlockValueAtom = atomWithStorage<Record<string, FilterBlockValue>>('filterBlock', {})

export const viewRecordListAtomFamily = atomFamily((viewId: ViewRecordListIdentity) => {
    return atom(
        get => {
            const envId = get(currentEnvIdAtom)
            const ViewRecordPool = get(viewRecordPoolAtom)
            const dsRecords = get(recordPoolAtom)
            const viewRecords = ViewRecordPool?.[viewId]
            if (!viewRecords) {
                return undefined
            }
            return viewRecords.reduce<RecordLikeProtocol[]>((records, viewRecord) => {
                const record = find(
                    item => viewRecord.appId === item.appId && viewRecord.dsId === item.dsId && viewRecord.id === item.id && item.envId === envId,
                    dsRecords
                )
                if (record) {
                    const viewRecordData = record.content
                    records.push({
                        id: viewRecord.id,
                        appId: viewRecord.appId,
                        envId,
                        dsId: viewRecord.dsId,
                        content: viewRecordData
                    })
                }
                return records
            }, [])
        },
        (_, set, payload: RecordLikeProtocol[] | ((draft: Draft<RecordLikeProtocol[]>) => void)) =>
            set(viewRecordPoolAtom, draft => {
                if (typeof payload === 'function') {
                    applyDraftPayload(draft[viewId], payload)
                    return
                }
                // applyDraftPayload(draft[viewId], payload)
                draft[viewId] = payload
            })
    )
}, shallowEqual)

export const filterBlockOptionsAtomFamily = atomFamily(() => {
    return atom(
        get => {
            return get(filterBlockOptionsAtom)
        },
        (
            _,
            set,
            payload:
                | Record<string, FilterOption[]>
                | ((draft: Draft<Record<string, FilterOption[]>>) => Draft<Record<string, FilterOption[]>>)
        ) =>
            set(filterBlockOptionsAtom, draft => {
                if (typeof payload === 'function') {
                    payload(draft)
                    return
                }
                draft = payload
            })
    )
}, shallowEqual)
