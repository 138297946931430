import { ColorPicker, Popover } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const StyledPreview = styled.div<{ previewColor?: string; size: number }>`
    padding: 2px;
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    &::before {
        content: '';
        display: block;
        width: ${({ size }) => size - 4}px;
        height: ${({ size }) => size - 4}px;
        border-radius: 6px;
        background-color: ${p => p.previewColor};
    }
`

type ColorControlProps = {
    disabled?: boolean
    value?: string
    size?: number
    onChange?: (v: string) => void
}

export const ColorControl = ({ disabled, value, size = 28, onChange }: ColorControlProps) => {
    return (
        <Popover disabled={disabled} position="bottom-end" withinPortal withArrow width={243}>
            <Popover.Target>
                <StyledPreview size={size} previewColor={value} />
            </Popover.Target>
            <Popover.Dropdown unstyled>
                <ColorPicker value={value} onChange={onChange} />
            </Popover.Dropdown>
        </Popover>
    )
}
