import { flex, IconFont, Input, pointer } from '@byecode/ui'
import { Radio } from '@mantine/core'
import styled from 'styled-components'

import { ErrorMessage } from '../ErrorMessage'

export const Icon = styled(IconFont)`
    font-size: var(--font-size-base);
`

export const ListContain = styled.div`
    width: 100%;
    /* padding: 8px 0; */
    outline: none;
`
export const MenuItem = styled.div`
    width: 100%;
    min-width: 200px;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const Name = styled.span`
    margin-left: 7.5px;
    line-height: 16px;
    color: var(--color-gray-900);
    /* font-size: var(--font-size-base); */
`

export const ListGroupTitle = styled.div`
    padding: 4px 12px 1.5px 16px;
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
`
export const TitleInput = styled(Input)`
    width: calc(100% - 32px);
    margin: 8px 16px 8px 16px;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: var(--font-size-normal);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    border: 1px solid var(--color-main);

    input {
        ::placeholder {
            color: var(--color-gray-300);
        }
    }
`

export const ListGroup = styled.div`
    width: 100%;
    /* padding: 8px 0; */
`
export const ListRadioGroup = styled.div`
    ${flex};
    width: 100%;
    flex-wrap: wrap;
    font-size: var(--font-size-normal);
`
export const ListCheckItem = styled.div`
    width: 100%;
    ${flex};
    padding: 9px 16px;
`
export const ListCheckBox = styled(Radio)`
    font-size: var(--font-size-normal);
    border-radius: 100%;
`
export const ErrMsg = styled(ErrorMessage)`
    width: 100%;
`
export const Text = styled.div`
    margin: 0 16px;
    font-size: var(--font-size-normal);
`
