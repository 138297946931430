import { Button, IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const MemberHandle = styled(Button)<{ active?: boolean }>`
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    background: ${({ active }) => (active ? 'var(--color-gray-200)' : 'transparent')};

    ${pointer}
`

export const Option = styled.div<{ disable?: boolean }>`
    width: 100%;
    height: 32px;
    padding: 0 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: ${({ disable }) => (disable ? 'no-drop' : 'pointer')};
    opacity: ${({ disable }) => (disable ? 0.5 : 1)};

    :hover {
        background-color: #f5f5f5;
    }
`

export const OptionIcon = styled(IconFont)``
export const OptionContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const OptionLabel = styled.div`
    font-size: 14px;
`
