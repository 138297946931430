import { IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const TabExtra = styled.div`
    border-radius: 100px;
    background-color: var(--color-gray-100);
    padding: 0 8px;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Icon = styled(IconFont)`
    margin-right: 6px;
`

export const Bold = styled.span<{ pointer?: boolean }>`
    font-weight: 600;
    margin: 0 2px;
    ${props => props.pointer && pointer()}
`

export const CancelPool = styled.span`
    color: var(--color-main);
    margin: 0 2px;
    ${pointer}
`

export const AppName = styled.div`
    color: var(--color-white);
    text-align: center;
    font-size: var(--font-size-normal);
`
