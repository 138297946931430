import type { BlockVisibilityProtocol, IndicatorOption } from '@lighthouse/core'
import { ApplicationPreviewEnum } from '@lighthouse/shared'

import { chartThemeColorOptions } from '@/components/BlockSettings/constants'

export const DEFAULT_THEME_COLORS = chartThemeColorOptions[0]

export const getIndicatorOption: (params?: Partial<IndicatorOption>) => IndicatorOption = params => ({
    appId: '',
    pointer: '',
    showHeadTitle: true,
    title: '指标卡',
    showLabel: false,
    showThousandthSplit: false,
    showUnit: false,
    linkFilterController: {
        expression: {
            where: 'AND',
            conditions: []
        }
    },
    ...params
})

export const COMMON_BLOCK_CONFIG: BlockVisibilityProtocol = {
    visibilityDevice: [ApplicationPreviewEnum.desktop, ApplicationPreviewEnum.mobile]
}
