import { IconFont, SegmentedControl, Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const iconData = [
    {
        label: (
            <div>
                <IconFont type="Add" size={16} />
            </div>
        ),
        value: 'man'
    },
    {
        label: (
            <div>
                <IconFont type="User" size={16} />
            </div>
        ),
        value: 'woman'
    },
    {
        label: (
            <Tooltip title="未知" placement="bottom">
                <IconFont type="User" size={16} />
            </Tooltip>
        ),
        value: 'unknown'
    }
]

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <SegmentedControl data={['man', 'woman', 'unknown']} />
            <SegmentedControl
                data={[
                    {
                        label: '男',
                        value: 'man'
                    },
                    {
                        label: '女',
                        value: 'woman'
                    },
                    {
                        label: '未知',
                        value: 'unknown'
                    }
                ]}
            />
            <SegmentedControl data={iconData} />
        </Unit>
    )
}
