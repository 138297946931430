import type { AggregateResultField, AggregatorGroupStatisticsConfig } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import * as SC from '../styles'
import { AddButton } from './AddButton'
import { GroupByFieldItem } from './GroupByFieldItem'

interface GroupByFieldConfigProps {
    fieldList: AggregateResultField[]
}

export const GroupByFieldConfig: React.FC<GroupByFieldConfigProps> = ({ fieldList }) => {
    // const [ids, setIds] = useImmer<string[]>(value)
    const { control } = useFormContext<AggregatorGroupStatisticsConfig>()
    const { fields, append, remove, move } = useFieldArray({
        control,
        keyName: 'fieldId',
        name: 'groupByFields'
    })

    const handleClick = useCallback(() => {
        const id = `f${nanoid(10)}`
        append({
            fieldId: id,
            groupByFieldId: ''
        })
    }, [append])

    const handleRemove = useCallback(
        (i: number) => {
            remove(i)
        },
        [remove]
    )

    const content = useMemo(() => {
        if (fields.length === 0) {
            return null
        }
        return (
            <SC.ConfigureContent>
                {fields.map((item, index) => (
                    <GroupByFieldItem
                        key={item.fieldId}
                        fieldList={fieldList}
                        prefixName={`groupByFields.${index}`}
                        onRemove={() => handleRemove(index)}
                    />
                ))}
            </SC.ConfigureContent>
        )
    }, [fieldList, fields, handleRemove])

    return (
        <SC.FormItem>
            <SC.FormItemLabelWrapper>
                <SC.FormItemLabel>分组字段</SC.FormItemLabel>
            </SC.FormItemLabelWrapper>
            <SC.FormItemContent>
                {content}
                <AddButton onClick={handleClick} />
            </SC.FormItemContent>
        </SC.FormItem>
    )
}
