import { getAssetUrl } from '@lighthouse/assets'
import { CONTAINER_ICON_MAP } from '@lighthouse/block'
import { BlockType, DIRECTION } from '@lighthouse/core'
import { insert } from '@lighthouse/tools'

import {
    allChart,
    allField,
    allView,
    appNavBlock,
    blockBreadcrumb,
    blockButton,
    blockCard,
    blockCollapse,
    blockDriver,
    blockIframe,
    blockQrBarcode,
    blockTabList,
    blockText,
    tabsBlock
} from './constant'
import { blockFile, blockPicture, blockVideo } from './constant/file'
import { filterBlock } from './constant/filter'
import type { BlockOption, BlockTreeItem } from './type'

export const ROW_CONTAINER = {
    type: BlockType.container,
    subType: DIRECTION.vertical,
    name: '行容器',
    icon: CONTAINER_ICON_MAP.vertical,
    describeImage: getAssetUrl('blocks', 'RowContainer.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
} satisfies BlockTreeItem

export const COL_CONTAINER = {
    type: BlockType.container,
    subType: DIRECTION.horizontal,
    name: '列容器',
    icon: CONTAINER_ICON_MAP.horizontal,
    describeImage: getAssetUrl('blocks', 'ColumnContainer.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
} satisfies BlockTreeItem

// export const CONTAINER: BlockTreeItem = {
//     type: BlockType.container,
//     name: '容器',
//     icon: '',
//     describeImage: getAssetUrl('blocks', 'empty_module.svg'),
//     backGroundColor: 'var(--color-gray-50)',
//     color: '#667085'
// }
export const DEFAULT: BlockOption = {
    id: 'default',
    title: '预设',
    children: [
        {
            id: 'components',
            name: '预设组件',
            icon: 'Feed',
            color: 'var(--color-white)',
            iconColor: 'var(--color-black)',
            items: [
                {
                    type: 'combinationTitle',
                    name: '简约标题',
                    icon: 'Widget',
                    describeImage: getAssetUrl('blocks', 'preset_title.svg'),
                    backGroundColor: 'var(--color-gray-50)',
                    color: '#667085'
                },
                {
                    type: 'highlight',
                    name: '高亮文本',
                    icon: 'Widget',
                    describeImage: getAssetUrl('blocks', 'preset_highlight.svg'),
                    backGroundColor: 'var(--color-gray-50)',
                    color: '#667085'
                }
            ]
        }
    ]
}

export const DATA_MANAGER: BlockOption = {
    id: 'DATA_MANAGER',
    title: '数据',
    children: [
        {
            id: 'dataView',
            name: '视图',
            icon: 'Feed',
            color: 'var(--color-black)',
            iconColor: 'var(--color-white)',
            items: allView.items
        },
        {
            id: 'dataEnter',
            name: '表单',
            icon: 'Database',
            color: 'var(--color-black)',
            iconColor: 'var(--color-white)',
            items: [
                {
                    type: BlockType.formContainer,
                    name: '表单容器',
                    icon: '',
                    describeImage: getAssetUrl('blocks', 'form_module.svg'),
                    backGroundColor: 'var(--color-gray-50)',
                    color: '#667085'
                },
                ...insert<BlockTreeItem>(
                    1,
                    {
                        type: BlockType.subForm,
                        name: '子表单',
                        icon: '',
                        describeImage: getAssetUrl('blocks', 'subForm.svg'),
                        backGroundColor: 'var(--color-gray-50)',
                        color: '#667085'
                    },
                    allField.items
                )
            ]
        }
    ]
}

export const PAGE_COMPONENT: BlockOption = {
    id: 'PAGE_COMPONENT',
    title: '元素',
    children: [
        {
            id: 'base',
            name: '基础',
            icon: 'Widget7',
            color: 'linear-gradient(180deg, #7D7AFC 0%, #433FFB 100%)',
            iconColor: 'var(--color-white)',
            items: [ROW_CONTAINER, COL_CONTAINER, blockText, blockBreadcrumb, blockDriver]
        },
        {
            id: 'operation',
            name: '操作',
            icon: 'Cursor-bik7bbf4',
            iconColor: 'var(--color-white)',
            color: '#F79009',
            items: [blockButton, appNavBlock, tabsBlock, filterBlock]
        },
        // {
        //     id: 'text',
        //     name: '文本',
        //     icon: 'Text2',
        //     color: '#5551FF',
        //     iconColor: 'var(--color-white)',
        //     items: [, blockText, , ]
        // },
        {
            id: 'media',
            name: '媒体',
            icon: 'PlayCircle',
            color: '#FF4A55',
            iconColor: 'var(--color-white)',
            items: [blockPicture, blockVideo]
        },
        {
            id: 'chart',
            name: '图表',
            icon: 'Chart2',
            color: '#54C5EB',
            iconColor: 'var(--color-white)',
            items: allChart.items
        },
        {
            id: 'contentDisplay',
            name: '内容展现',
            icon: 'FileText',
            color: '#58CFB3',
            iconColor: 'var(--color-white)',
            items: [blockFile, blockCollapse, blockTabList, blockCard, blockIframe, blockQrBarcode]
        }
    ]
}
