import { Group, Slider, Switch, Text } from '@byecode/ui'
import type { DividerBlockAbstract } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { VisibilityFilter } from '../Common/VisibilityFilter'

interface DividerBlockSettingProps {}

export const DividerBlockSetting: React.FC<DividerBlockSettingProps> = () => {
    const { control } = useFormContext<DividerBlockAbstract['config']>()

    return (
        <>
            <Group label="样式">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示线条</Text>
                    <Controller
                        control={control}
                        name="visible"
                        render={({ field }) => <Switch checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                    />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>高度</Text>
                    <Controller
                        control={control}
                        name="height"
                        render={({ field }) => <Slider {...field} min={0.125} max={10} step={0.125} style={{ width: 180 }} />}
                    />
                </ListItem4ByecodeUi>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}
