import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div``

export const TabPanelDes = styled.div`
    margin: 32px 0 16px 0;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
    line-height: 20px;
`

export const TabPanelBtn = styled(Button)``
