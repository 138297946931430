import cls from 'classnames'
import React from 'react'

import * as SC from './styles'

interface UserCenterItemProps {
    activeId?: string
    id: string
    icon: string
    name: string
    onSelect: (val: string) => void
}

export const UserCenterItem: React.FC<UserCenterItemProps> = ({ activeId, id, icon, name, onSelect }) => {
    return (
        <SC.ListItem className={cls({ active: activeId === id })}>
              <SC.Prefix width={16} />
            <SC.CenterItem onClick={() => onSelect(id)}>
                <SC.UserCenterIcon type={icon} style={{ marginRight: 7, marginTop: 4 }} size={16} />
                <SC.ListGroupTitle>{name}</SC.ListGroupTitle>
            </SC.CenterItem>
        </SC.ListItem>
    )
}
