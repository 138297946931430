import { backgroundAndColorOnClick, IconFont, pointer } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div<{ height?: number }>`
    position: relative;
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    color: var(--color-gray-500);

    :after {
        content: '';
        position: absolute;
        width: calc(100%);
        bottom: -1px;
        border-bottom: 1px solid var(--color-gray-200);
    }

    ${pointer}
    ${backgroundAndColorOnClick}

    ${props =>
        !props?.height &&
        css`
            flex: 1;
        `};
`

export const Content = styled.div`
    position: sticky;
    width: max-content;
    height: 100%;
    left: 22px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

export const Text = styled.div`
    margin-left: 6px;
    font-size: 14px;
`

export interface RecordAdderIconProps {
    height?: number
    onAdd?: () => void
}

export const RecordAdderIcon: React.FC<RecordAdderIconProps> = ({ height, onAdd }) => {
    return (
        <Container onClick={onAdd} height={height}>
            <Content>
                <IconFont type="Add" size={16} />
                <Text>添加一行</Text>
            </Content>
        </Container>
    )
}
