import { Button, DatePicker, Input, Select } from '@byecode/ui'
import { Textarea } from '@mantine/core'
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    padding: 18px 20px 0 20px;
`

export const InputBox = styled.div`
    flex: 1;
`

export const Content = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Avatar = styled.div`
    width: 48px;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    background-color: #90a3cb;
    text-align: center;
    border-radius: 50%;
    font-size: 24px;
    color: var(--color-white);
    font-weight: 500;
    margin-bottom: 30px;
`

export const UserForm = styled.form`
    width: 100%;
    margin-top: 28px;
`

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
    gap: 8px;
`

export const FormLabel = styled.div`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    line-height: 36px;
    height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

// export const FormTextArea = styled(Textarea)`
//     width: 70%;
//     min-width: 120px;
//     min-height: 24px;
//     border-radius: 6px;
//     font-size: 14px;
//     color: var(--color-black);
//     border: 1px solid var(--color-gray-200);
//     background: var(--color-gray-50);

//     textarea {
//         border-radius: 6px;
//         background-color: var(--color-gray-50);
//         border: none;
//     }

//     textarea:focus {
//         background-color: var(--color-white);
//     }
// `

export const FormSelect = styled(Select)`
    height: 42px;
    line-height: 42px;
`

export const FormInput = styled(Input)`
    width: 100%;
`
