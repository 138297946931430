import { useCustomViewBlockContext } from '@lighthouse/block'
import type { NodeRenderProps } from '@lighthouse/shared'
import { useApplicationContext, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import { pageBlocksAtom } from '@/atoms/page/state'
import BlockRenderController from '@/blockControllers'
import { VisibleDom } from '@/components/VisibleDom'
import { useCurrentPageContext, useCurrentStackIdContext, usePageFieldBlocksContext } from '@/contexts/PageContext'
import { useBlockActions } from '@/hooks/useBlock'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

export const BlockRender = ({ node, children }: NodeRenderProps) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const block = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId]?.find(item => item.id === node.id), [node.id, pageId])
    )
    const { curr, prev, dataSourceList } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const { personOptions } = useApplicationContext()

    const { onUpdateBlock } = useBlockActions(pageId, stackId)
    const { name, pointer } = useCustomViewBlockContext()

    const customViewData = useMemo(() => {
        if (!pointer) {
            return
        }
        const dataSource = find(item => item.id === pointer, dataSourceList)
        if (!dataSource) {
            return
        }
        return {
            name,
            dataSource
        }
    }, [dataSourceList, name, pointer])

    const fieldBlocksWithDsId = usePageFieldBlocksContext()

    if (!block) {
        return children as JSX.Element
    }

    return (
        <>
            <BlockRenderController blockData={block} node={node} onBlockChange={onUpdateBlock}>
                {children}
            </BlockRenderController>
            <VisibleDom
                customViewData={customViewData}
                prevPage={prev}
                currentPage={curr}
                dataSourceList={dataSourceList}
                filter={block.config.visibilityFilter}
                device={block.config.visibilityDevice}
                personOptions={personOptions}
                fieldBlocksWithDsId={fieldBlocksWithDsId}
            />
        </>
    )
}
