import { Divider } from '@byecode/ui/components/Divider'
import { Flex } from '@byecode/ui/components/Flex'
import { Group } from '@byecode/ui/components/Group'
import { Select } from '@byecode/ui/components/Select'
import { Tooltip } from '@byecode/ui/components/Tooltip'
import { type FlowNode, getFieldOptions, getPrimaryDsSystemFieldId, getUpstreamRealDsId, pureTextFieldTypes } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'

import { SettingRecordContent } from '../../../SettingRecordContent'
import * as SC from './styles'

interface RefoundProps {
    prefixName?: string
    allParentNodes?: FlowNode[]
}

const EmptyArray: FlowNode[] = []

const Refound: React.FunctionComponent<RefoundProps> = props => {
    const { allParentNodes = EmptyArray, prefixName } = props
    const { control, watch } = useFormContext()

    const { type } = useFlow()
    const isActionFlow = Boolean(type)
    const { dsId } = useActionAdderDepParams()
    const nodeId = watch(`${prefixName}.refundConfig.nodeId`)

    const dataSourceId = useMemo(() => {
        return isActionFlow ? getUpstreamRealDsId(nodeId, allParentNodes) : dsId
    }, [allParentNodes, dsId, isActionFlow, nodeId])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dataSourceId ?? '')
    const dataSourceList = useDataSourceList(appId, envId)
    const lookTextFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(dataSource, field => pureTextFieldTypes.has(field.type) || (field.type === 'formula' && field.innerType === 'TEXT'))
    }, [dataSource])

    const lookNumberFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(dataSource, field => field.type === 'number' || (field.type === 'formula' && field.innerType === 'NUMBER'))
    }, [dataSource])

    if (!dataSource) {
        return null
    }
    return (
        <>
            <Group label="退款订单信息">
                <SC.FieldList>
                    <SC.FieldItem>
                        <Flex gap={16}>
                            <SC.FieldTitle required>退款单号</SC.FieldTitle>
                            <Tooltip
                                title={
                                    <div style={{ width: 348, lineHeight: '20px' }}>
                                        <p>格式只能是数字、大小写字母_-*；只能选「自动编号」字段。</p>
                                        <p style={{ marginTop: 20 }}>
                                            注意：请将作为退款单号的「自动编号」中的场景设置为「订单」， 这样能保证订单号的安全性。
                                        </p>
                                    </div>
                                }
                            >
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </Flex>
                        <Select
                            value={getPrimaryDsSystemFieldId('ID', { dataSource, dataSourceList })}
                            options={lookTextFieldOptions}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            disabled
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <Flex gap={16}>
                            <SC.FieldTitle required>订单号</SC.FieldTitle>
                            <Tooltip title="格式只能是数字、大小写字母_-*。">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </Flex>
                        <Controller
                            control={control}
                            name={`${prefixName}.refundConfig.outTradeNoFieldId`}
                            render={({ field }) => (
                                <Select
                                    options={lookTextFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <Flex gap={16}>
                            <SC.FieldTitle required>退款金额</SC.FieldTitle>
                            <Tooltip title="金额单位为元；货币类型为 CNY：人民币；不能大于原订单金额">
                                <SC.Icon type="Question" />
                            </Tooltip>
                        </Flex>
                        <Controller
                            control={control}
                            name={`${prefixName}.refundConfig.refundAmountFieldId`}
                            render={({ field }) => (
                                <Select
                                    options={lookNumberFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从退款订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <SC.FieldTitle>退款原因</SC.FieldTitle>
                        <Controller
                            control={control}
                            name={`${prefixName}.refundConfig.reasonFieldId`}
                            render={({ field }) => (
                                <Select
                                    options={lookTextFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从退款订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                </SC.FieldList>
            </Group>
            <Divider />
            <Group label="退款成功后更新订单">
                <SettingRecordContent
                    prefixName={`${prefixName}.refundConfig`}
                    fieldsName="succeedFieldSettings"
                    allParentNodes={allParentNodes}
                    dataSourceId={dataSource.id}
                />
            </Group>
            <Divider />
            <Group label="退款失败后更新订单">
                <SettingRecordContent
                    prefixName={`${prefixName}.refundConfig`}
                    fieldsName="failedFieldSettings"
                    allParentNodes={allParentNodes}
                    dataSourceId={dataSource.id}
                />
            </Group>
        </>
    )
}

export default Refound
