import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import React from 'react'
import styled, { css } from 'styled-components'

interface TagIconProps extends React.InputHTMLAttributes<HTMLDivElement> {
    icon?: string
    iconSize?: number
    iconColor?: string
    size?: number
    label?: string | React.ReactNode
    hoverColor?: string
    className?: string
    radius?: number
    color?: string
    enableHover?: boolean
    background?: string
    style?: React.CSSProperties
}

const SCxIcon = styled(IconFont)<{ color?: string }>`
    color: ${({ color }) => color};
`

const SCxIconWrapper = styled.div<{
    size?: number
    radius?: number
    background?: string
    enableHover?: boolean
    hoverColor?: string
    iconColor?: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => size ?? 16}px;
    height: ${({ size }) => size ?? 16}px;
    border-radius: ${({ radius }) => radius}px;
    color: ${({ color }) => color};
    background: ${({ background }) => background};
    cursor: pointer;

    ${SCxIcon} {
        color: ${({ iconColor }) => iconColor};
    }

    ${({ enableHover, hoverColor }) =>
        enableHover &&
        css`
            &:hover {
                ${SCxIcon} {
                    color: ${hoverColor}!important;
                }
                ${backgroundTransitionOnClick}
            }
        `}
`

export const TagIcon = React.forwardRef<HTMLDivElement, TagIconProps>(
    ({ iconSize = 16, radius, label, icon, size, color, iconColor, background, className, enableHover, hoverColor, ...rest }, ref) => {
        return (
            <SCxIconWrapper
                ref={ref}
                className={className}
                size={size}
                radius={radius}
                enableHover={enableHover}
                color={color}
                background={background}
                hoverColor={hoverColor}
                iconColor={iconColor}
                {...rest}
            >
                {label}
                {icon && <SCxIcon size={iconSize} type={icon} />}
            </SCxIconWrapper>
        )
    }
)
