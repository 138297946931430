import { Loading, Spin } from '@byecode/ui'
import type { ApplicationType } from '@lighthouse/core'
import { TitleInContentModal } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useWindowSize } from 'react-use'

import type { CreateApplicationPayload } from '@/services/types'

import type { CreateApplicationFormValue } from './AppSet'
import AppSet from './AppSet'
// import { useCreateAppInfo } from './helps'

export type AppCreatorProps = {
    open: boolean
    appType: ApplicationType
    onConfirm: (value: Omit<CreateApplicationPayload, 'spaceId'>) => Promise<string>
    onClose: () => void
}

export const AppCreator: React.FC<AppCreatorProps> = ({ open, appType, onConfirm, onClose }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // 此处用来获得创建应用初始配置
    const { width: windowWidth } = useWindowSize()
    // const [appInfo, setAppInfo, initAppInfo] = useCreateAppInfo(appType)

    const handleConfirm = useCallback(
        async (value: Omit<CreateApplicationPayload, 'spaceId'>) => {
            setLoading(true)
            const appId = await onConfirm(value)
            setLoading(false)
            if (appId) {
                navigate({ pathname: `/${appId}` })
            }
        },
        [navigate, onConfirm]
    )

    const width = useMemo(() => {
        if (windowWidth >= 1280) {
            return 1216
        }
        if (windowWidth >= 900 && windowWidth < 1280) {
            return 844
        }
        if (windowWidth > 768 && windowWidth < 900) {
            return 728
        }
        if (windowWidth >= 768) {
            return 536
        }
    }, [windowWidth])

    return (
        <TitleInContentModal
            withCloseIcon={false}
            disableMobile
            open={open}
            width={width}
            onClose={onClose}
            styles={{
                desktop: {
                    modal: {
                        height: '80%',
                        minHeight: '400px'
                    },
                    header: {
                        border: 'none'
                    },
                    body: {
                        padding: 0
                    }
                }
            }}
        >
            <Spin loading={loading} description="正在创建应用，请勿关闭弹窗" styles={{ root: { height: '100%' } }}>
                <AppSet appType={appType} onConfirm={handleConfirm} onClose={onClose} />
            </Spin>
        </TitleInContentModal>
    )
}
