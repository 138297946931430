import type { SpaceVersion } from '@lighthouse/core'
import React from 'react'

import { List } from './List'
import { Table } from './Table'

interface Props {
    onWechatPaySpaceUpgrade: (level: SpaceVersion) => void
}

export const SpaceUpgrade = ({ onWechatPaySpaceUpgrade }: Props) => {
    return (
        <>
            <List onWechatPaySpaceUpgrade={onWechatPaySpaceUpgrade} />
            <Table onWechatPaySpaceUpgrade={onWechatPaySpaceUpgrade} />
        </>
    )
}
