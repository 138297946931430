import { Button, Modal, Select } from '@byecode/ui'
import React, { useState } from 'react'

import { Unit } from '../Mics'

export const WithOtherPop: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    return (
        <Unit title="嵌套其他弹出层的esc">
            <Button onClick={() => setOpen(true)}>打开弹窗</Button>

            <Modal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                我是弹窗1
                <Button
                    onClick={() => {
                        setOpen2(true)
                    }}
                >
                    打开2
                </Button>
                <Select options={[{ label: 'test', value: 'test' }]} />
                <Modal
                    open={open2}
                    onClose={() => {
                        setOpen2(false)
                    }}
                >
                    我是内部弹窗2
                </Modal>
            </Modal>
        </Unit>
    )
}
