import { PageSubmissionSearchEngineEnum, PageSubmissionStateEnum } from '@/services/types'

export const searchEngineNameMap = new Map([
    [PageSubmissionSearchEngineEnum.BAIDU, '百度'],
    [PageSubmissionSearchEngineEnum.CHROME, '谷歌'],
    [PageSubmissionSearchEngineEnum.BING, '必应']
])

export const searchEngineIconMap = new Map([
    [PageSubmissionSearchEngineEnum.BAIDU, 'Baidu'],
    [PageSubmissionSearchEngineEnum.CHROME, 'Google'],
    [PageSubmissionSearchEngineEnum.BING, 'Bing']
])
export const searchEngineStatusMap = new Map([
    [PageSubmissionStateEnum.FAIL, '失败'],
    [PageSubmissionStateEnum.QUEUE, '排队中'],
    [PageSubmissionStateEnum.SUCCESS, '成功']
])

export const searchEngineClassNameMap = new Map([
    [PageSubmissionStateEnum.FAIL, 'fail'],
    [PageSubmissionStateEnum.QUEUE, 'queue'],
    [PageSubmissionStateEnum.SUCCESS, 'success']
])

export const QUEUE_STATUS_OPTIONS = [
    {
        label: '全部',
        value: 'ALL'
    },
    {
        label: '排队中',
        value: 'QUEUE'
    },
    {
        label: '成功',
        value: 'SUCCESS'
    }
]
