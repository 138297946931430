import { IconFont, Loading } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Modal } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { removeWorkSpaceAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'
import { useWorkSpaceList } from '@/hooks/useWorkSpace'

import { Header } from '../Header'
// import { useBoundStore } from '@/stores'
import { DelSpaceModal } from './DelSpaceModal'
import * as SC from './styles'
import type { SpaceSettingProps } from './types'

export const Setting: React.FC<SpaceSettingProps> = ({ workspace, spaceNum }) => {
    const workspaceList = useWorkSpaceList()
    const { run: removeWorkSpace } = useAtomAction(removeWorkSpaceAtom)
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)
    const { id } = workspace

    const spaceName = useMemo(() => {
        return workspace.name || ''
    }, [workspace])

    const handleDelete = useCallback(async () => {
        setOpen(false)
        const modalId = nanoid()
        // openPageOverlay({
        //     id: modalId,
        //     type: 'modal',
        //     props: {
        //         size: 360,
        //         centered: true,
        //         radius: 8,
        //         styles: {
        //             root: {
        //                 boxShadow: '0px 2px 8px 0px #00000026',
        //                 zIndex: 1300
        //             },
        //             header: { display: 'none' }
        //         },
        //         closeOnClickOutside: false,
        //         closeOnEscape: false,
        //         children: <Loading shape="indicator" />
        //     }
        // })
        const isDelete = await removeWorkSpace(id)
        // closePageOverlay(modalId)
        if (isDelete) {
            const extraSpace = workspaceList.find(item => item.id !== id)
            setWorkSpaceId(extraSpace?.id || '')
            if (extraSpace) {
                navigate({ pathname: '/workbench/applications' }, { replace: true })
                return
            }
            navigate({ pathname: '/' }, { replace: true })
        }
    }, [id, navigate, removeWorkSpace, setWorkSpaceId, workspaceList])

    const showDelSpace = useCallback(() => {
        // if (spaceNum < 2) {
        //     return
        // }
        setOpen(true)
    }, [])

    return (
        <SC.Container>
            <Header title="设置" />
            <SC.TabPanelBtn size="md" radius={7} icon={<IconFont type="Trash" size={16} />} onClick={showDelSpace}>
                删除空间
            </SC.TabPanelBtn>
            <SC.TabPanelDes>
                一旦你删除了企业，空间内所有项目、成员、库，以及库中的所有页面都将会被永久删除。这是一个不可恢复的操作，请谨慎对待！
            </SC.TabPanelDes>
            <Modal withCloseButton={false} opened={open} centered onClose={() => setOpen(false)} zIndex={1600}>
                <DelSpaceModal spaceLength={spaceNum} spaceName={spaceName} onClose={() => setOpen(false)} onDelete={handleDelete} />
            </Modal>
        </SC.Container>
    )
}

export default Setting
