export const pageUrlReg = /\/page\/[0-9a-zA-Z]*/u

export const flowUrlReg = /\/flow\/[0-9a-zA-Z]*/u

export const dataSourceUrlReg = /\/dataSource\/[0-9a-zA-Z_]*/u

// eslint-disable-next-line unicorn/no-unsafe-regex
export const aggregateUrlReg = /\/aggregate(\/([0-9a-zA-Z_])*)?/u

export const viewUrlReg = /\/view\/[0-9a-zA-Z]*-[0-9a-zA-Z]*/u

export const recordUrlReg = /\/record\/[0-9a-zA-Z]*/u

// export const APP_NAVBAR_MODE_LIST: SegmentedControlItem = [
//     {
//         value: 'dataSource',
//         label: (
//             <Tooltip label="数据编辑" withArrow withinPortal arrowOffset={2} position="bottom">
//                 <IconFont type="BlockTable" size={size} fill={rest?.style?.color || 'var(--color-gray-300)'} />
//                 <Text size={size}>数据源</Text>
//             </Tooltip>
//         )
//     }
// ]
