import { backgroundAndColorOnClick, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const AddFieldWrapper = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    border: 1px solid rgba(55, 53, 47, 0.09);
    border-left: none;
    border-right: none;
`

export const AddField = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    width: 100%;
    align-items: center;
    min-width: 50px;
    padding-left: 16px;
    cursor: pointer;
    background: var(--color-gray-50);
    color: var(--color-gray-400);

    &.borderBottom:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--color-gray-200);
    }

    ${pointer}
    ${backgroundAndColorOnClick}
`
