import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import SettingBlock from '../../SettingBlock'
import PerfectFieldCollapse from '../PerfectFieldCollapse'

interface PerfectGroupSettingProps {}

const PerfectGroupSetting: React.FunctionComponent<PerfectGroupSettingProps> = props => {
    const { control } = useFormContext<ApplicationSettingAuthentication>()

    const { fields: groupSetting, update } = useFieldArray({
        control,
        name: 'perfect.groupSetting'
    })

    return (
        <>
            {groupSetting.map(({ groupId }, index) => (
                <PerfectFieldCollapse prefix={`perfect.groupSetting.${index}`} key={groupId} groupId={groupId ?? ''} />
            ))}
        </>
    )
}

export default PerfectGroupSetting
