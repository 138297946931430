import type { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import type { ApplicationSettingNavbarButton, ApplicationSettingNavbarLinkList } from '@lighthouse/core'
import { find, flatten, max } from 'rambda'

import type { ApplicationNavbarHierarchyButton, ApplicationNavbarHierarchyLevelButton } from './types'

export const getAllChild = (list: string[], id: string, dataList: ApplicationSettingNavbarLinkList) => {
    const { child = [] } = dataList.list[id]
    let arrList = [...list, id]
    for (const nodeId of child) {
        arrList = getAllChild(arrList, nodeId, dataList)
    }
    return arrList
}

export const getNodeLevel = (id: string, list: Record<string, ApplicationSettingNavbarButton>, level = 0) => {
    const { parentId = '' } = list[id]
    let currentLevel = level
    if (parentId) {
        currentLevel = getNodeLevel(parentId, list, level + 1)
    }
    return currentLevel
}

export const getFlapChidList = (
    childList: string[],
    dataList: Record<string, ApplicationSettingNavbarButton>,
    curList: ApplicationNavbarHierarchyLevelButton[]
) => {
    let arrList = curList
    for (const nodeId of childList) {
        const node = dataList[nodeId]
        arrList = getFlapChidList(node.child ?? [], dataList, [...arrList, { ...node, level: getNodeLevel(node.id, dataList) }])
    }
    return arrList
}

export const getFlapList = (dataList: ApplicationSettingNavbarLinkList): ApplicationNavbarHierarchyLevelButton[] => {
    let arrList: ApplicationNavbarHierarchyLevelButton[] = []
    for (const nodeId of dataList.child) {
        const node = dataList.list[nodeId]
        const childNodeList = getFlapChidList(node.child ?? [], dataList.list, [{ ...node, level: 0 }])
        arrList = [...arrList, ...childNodeList]
    }
    return arrList
}

export const getParentNodeIdList = (
    list: ApplicationNavbarHierarchyLevelButton[],
    id: string,
    nodes?: ApplicationNavbarHierarchyLevelButton[]
): ApplicationNavbarHierarchyLevelButton[] => {
    const node = find(item => item.id === id, list)
    const { parentId } = node ?? {}
    const parentNode = find(item => item.parentId === parentId, list)
    let currentNodes = node ? [...(nodes ?? []), node] : nodes ?? []
    if (parentNode && parentId) {
        currentNodes = getParentNodeIdList(list, parentId, currentNodes)
    }
    return currentNodes
}

// export const getNodeLevelList = (id: string, list: ApplicationSettingNavbarLinkList, list: number[]) => {
//     const { child = [] } = dataList.list[id]
//     let arrList = [...list, getNodeLevel(id, list, 0)]
//     for (const nodeId of child) {
//         arrList = getNodeLevelList(nodeId, dataList, arrList)
//     }
//     return arrList
// }

// export const isTailNode =

export const getNodeChildList = (id: string, dataList: ApplicationSettingNavbarLinkList, list: string[]) => {
    const { child = [] } = dataList.list?.[id] ?? {}
    let arrList = [...child]
    for (const nodeId of child) {
        arrList = getNodeChildList(nodeId, dataList, arrList)
    }
    return arrList
}

export const getMaxNodeListLevel = (list: number[]) => {
    let maxNum = 0
    for (const num of list) {
        maxNum = max(maxNum, num)
    }
    return maxNum
}

// 返回一个具有层级关系的 ApplicationNavbarHierarchyButton
export const getNodeHierarchyList = (data: ApplicationSettingNavbarButton, dataList: ApplicationSettingNavbarLinkList, level: number) => {
    const { child = [] } = data
    const newData: ApplicationNavbarHierarchyButton = { ...data, child: [] }
    for (const nodeId of child) {
        const newChild = getNodeHierarchyList(dataList.list[nodeId], dataList, level + 1)
        newData.child.push(newChild)
    }
    return newData
}

// 返回一个具有层级关系的 ApplicationNavbarHierarchyButton数组
export const getAllNodeHierarchyList = (dataList: ApplicationSettingNavbarLinkList): ApplicationNavbarHierarchyButton[] => {
    const { child = [], list: linkMapList } = dataList
    const newData: ApplicationNavbarHierarchyButton[] = []
    for (const nodeId of child) {
        newData.push(getNodeHierarchyList(linkMapList[nodeId], dataList, 0))
    }
    return newData
}

// 返回一个具有层级关系的 ApplicationNavbarHierarchyButton & { level: number}
export const getNodeHierarchyLevelList = (data: ApplicationNavbarHierarchyLevelButton, dataList: ApplicationSettingNavbarLinkList) => {
    const { child = [], level } = data
    let newData: ApplicationNavbarHierarchyLevelButton[] = [{ ...data, level }]
    for (const nodeId of child) {
        const newChild = getNodeHierarchyLevelList({ ...dataList.list[nodeId], level: level + 1 }, dataList)
        newData = [...newData, ...newChild]
    }
    return newData
}

// 返回一个具有层级关系的 ApplicationNavbarHierarchyButton & { level: number}数组
export const getAllNodeHierarchyLevelList = (dataList: ApplicationSettingNavbarLinkList): ApplicationNavbarHierarchyLevelButton[] => {
    const { child = [], list: linkMapList } = dataList
    let newData: ApplicationNavbarHierarchyLevelButton[] = []
    for (const nodeId of child) {
        newData = [...newData, ...getNodeHierarchyLevelList({ ...linkMapList[nodeId], level: 0 }, dataList)]
    }
    return newData
}

export function removeChildrenOf(items: ApplicationNavbarHierarchyLevelButton[], ids: UniqueIdentifier[]) {
    const excludeParentIds = [...ids]

    return items.filter(item => {
        if (item.parentId && excludeParentIds.includes(item.parentId)) {
            if ((item?.child ?? []).length > 0) {
                excludeParentIds.push(item.id)
            }
            return false
        }

        return true
    })
}

function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth)
}

function getMaxDepth({ previousItem }: { previousItem: ApplicationNavbarHierarchyLevelButton }) {
    if (previousItem) {
        return previousItem.level + 1
    }

    return 0
}

function getMinDepth({ nextItem }: { nextItem: ApplicationNavbarHierarchyLevelButton }) {
    if (nextItem) {
        return nextItem.level
    }

    return 0
}

export function getProjection(
    items: ApplicationNavbarHierarchyLevelButton[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    dragOffset: number,
    indentationWidth: number
) {
    const overItemIndex = items.findIndex(({ id }) => id === overId)
    const activeItemIndex = items.findIndex(({ id }) => id === activeId)
    const activeItem = items[activeItemIndex]
    const newItems = arrayMove(items, activeItemIndex, overItemIndex)
    const previousItem = newItems[overItemIndex - 1]
    const nextItem = newItems[overItemIndex + 1]
    const dragDepth = getDragDepth(dragOffset, indentationWidth)
    const projectedDepth = activeItem.level + dragDepth
    const maxDepth = getMaxDepth({
        previousItem
    })
    const minDepth = getMinDepth({ nextItem })
    let depth = projectedDepth

    if (projectedDepth >= maxDepth) {
        depth = maxDepth
    }
    if (projectedDepth < minDepth) {
        depth = minDepth
    }

    return { depth, maxDepth, minDepth, parentId: getParentId() }

    function getParentId() {
        if (depth === 0 || !previousItem) {
            return null
        }

        if (depth === previousItem.level) {
            return previousItem.parentId
        }

        if (depth > previousItem.level) {
            return previousItem.id
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find(item => item.level === depth)?.parentId

        return newParent ?? null
    }
}
