import { Button, Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Group: React.FC = () => {
    return (
        <Unit title="基础">
            <Tooltip title="我是tooltip" open styles={{ tooltip: { color: 'red' } }}>
                <Button>tooltip</Button>
            </Tooltip>
        </Unit>
    )
}
