import React, { useMemo } from 'react'

import * as SC from './styles'

interface StepIndicatorProps {
    stepCount: number
    currentStep: number
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({ stepCount, currentStep }) => {
    const indicators = useMemo(() => {
        const ins = Array.from({ length: stepCount }).fill(1)

        return ins.map((i, index) => index)
    }, [stepCount])

    return (
        <SC.BetaRequestStepIndicatorWrapper>
            {indicators.map(i => (
                <SC.BetaRequestStepIndicator
                    key={i}
                    style={
                        i === currentStep
                            ? {
                                  backgroundColor: 'var(--color-main)'
                              }
                            : {
                                  backgroundColor: 'var(--color-gray-200)'
                              }
                    }
                />
            ))}
        </SC.BetaRequestStepIndicatorWrapper>
    )
}
