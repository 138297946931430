import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const DescriptionWrapper = styled.div`
    padding: 0 16px 8px 16px;
`

export const Description = styled.div<{ marginTop?: boolean}>`
    margin-top: ${({ marginTop }) => marginTop ? '6px' : 0};
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
`

export const Content = styled.div`
    padding: 0 16px 12px 16px;
`



export const Text = styled.span<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? 14}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
`

export const FormItemWrapper = styled.div`
    margin-top: 8px;
`

export const Item = styled.div`
    width: 100%;
    padding: 12px 16px 0 16px;

    .mantine-Input-wrapper {
        margin-top: 12px;
    }
`

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? '16'}px;
`

export const SelectItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`
export const SelectContainer = styled.div`
    width: 100%;
`
// export const LeftFill = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
// `
// export const RightFill = styled.div`
//     display: none;
//     align-items: center;
//     justify-content: flex-end;
// `

export const VariableItem = styled.div`
    margin-bottom: 12px;
`

export const VariableTitle = styled.div`
    margin-bottom: 8px;
    font-weight: 400;
    color: var(--color-black);
`
