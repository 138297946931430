import { Group, IconFont, Radio, singleTextEllipsis, Switch, Tooltip } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { AppNavAbstract, BaseApplicationAbstract } from '@lighthouse/core'
import { FindUseLocationType, ListItem4ByecodeUi, useAtomData } from '@lighthouse/shared'
import { arrayMove } from '@lighthouse/tools'
import { ActionIcon, Box, Divider } from '@mantine/core'
import { clone } from 'rambda'
import type { CSSProperties, FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import useSWR from 'swr'

import { appAtom } from '@/atoms/application/state'
import { useApplicationList } from '@/hooks/useApplication'
import { useFindUse } from '@/hooks/useFindUse'
import * as srv from '@/services'

import { VisibilityFilter } from '../Common/VisibilityFilter'

const SingleEllipseText = styled.div`
    ${singleTextEllipsis()}
`

interface AppNavSettingProps {}

export const AppNavSetting: React.FC<AppNavSettingProps> = () => {
    const { control } = useFormContext<AppNavAbstract['config']>()
    const { field } = useController({ name: 'list', control, defaultValue: [] })
    const appList = useApplicationList()
    const fieldValue = field.value.filter(id => appList.some(item => item.id === id))

    const localAppList = useMemo(() => {
        const data = clone(appList)
        data.sort((a, b) => {
            const curr = fieldValue.indexOf(a.id)
            const next = fieldValue.indexOf(b.id)
            if (curr === -1 || next === -1) {
                return 0
            }
            return curr - next
        })
        return data
    }, [appList, fieldValue])

    const appIDList = useMemo(() => localAppList.map(item => item.id), [localAppList])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = localAppList.findIndex(item => item.id === (over.id as string))
            const activeIndex = localAppList.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                const newList = arrayMove(localAppList, activeIndex, overIndex)
                // mutate(newList)
                const newFieldValue = newList.filter(item => fieldValue.includes(item.id)).map(item => item.id)
                field.onChange(newFieldValue)
            }
        }
    }

    const allChecked = useMemo(() => appIDList.every(i => fieldValue.includes(i)), [appIDList, fieldValue])

    return (
        <>
            <Group label="显示应用">
                <Radio.Group
                    name="apps"
                    value={allChecked ? 'all' : 'specified'}
                    defaultValue={allChecked ? 'all' : 'specified'}
                    style={{ flexDirection: 'column', gap: 0 }}
                    onChange={v => {
                        if (v === 'all') {
                            field.onChange(appIDList)
                        } else {
                            field.onChange([])
                        }
                    }}
                >
                    <Radio size="sm" label="所有应用" value="all" />
                    <Radio size="sm" label="指定应用" value="specified" />
                </Radio.Group>
                <Divider my={8} />
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={appIDList} strategy={verticalListSortingStrategy}>
                        {localAppList.map(item => (
                            <SortableItem
                                key={item.id}
                                data={item}
                                disabled={allChecked}
                                checked={fieldValue.includes(item.id)}
                                onChange={v => {
                                    if (v) {
                                        const newList = new Set([...fieldValue, item.id])
                                        const sortList = appIDList.filter(k => newList.has(k))
                                        field.onChange(sortList)
                                    } else {
                                        field.onChange(fieldValue.filter(k => k !== item.id))
                                    }
                                }}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </Group>
            <Divider color="var(--color-gray-200)" />
            <VisibilityFilter />
        </>
    )
}

const AppItem = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 6px;
`
const StyledSwitch = styled(Switch)`
    input:disabled + .mantine-Switch-track {
        background-color: var(--color-theme-4);
        border-color: var(--color-theme-4);
    }
`
interface SortableItemProps {
    disabled: boolean
    checked: boolean
    onChange: (checked: boolean) => void
    data: BaseApplicationAbstract
}
const SortableItem: FC<SortableItemProps> = ({ data, disabled, checked, onChange }) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({
        id: data.id
    })

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center" style={style} ref={setNodeRef}>
            <AppItem>
                <ActionIcon ref={setActivatorNodeRef} {...listeners} {...attributes} sx={{ width: 'auto', minWidth: 'auto' }}>
                    <IconFont type="DotsSix" />
                </ActionIcon>
                <Box display="flex" p={6} bg={data.primaryColor} sx={{ borderRadius: 5 }}>
                    <IconFont size={16} color="#fff" type={data.icon} />
                </Box>
                <Tooltip title={data.name}>
                    <SingleEllipseText>{data.name}</SingleEllipseText>
                </Tooltip>
            </AppItem>
            <StyledSwitch disabled={disabled} checked={checked} onChange={e => onChange(e.target.checked)} />
        </ListItem4ByecodeUi>
    )
}
