import { IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ highlighting?: boolean }>`
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
        `}
`

export const MatchWrapper = styled.div`
    padding: 12px;
`

export const MatchHeader = styled.div`
    background-color: var(--color-gray-50);
    padding: 2px 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const MatchTitle = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    display: flex;
    align-items: center;
    gap: 4px;
`

export const MatchHandle = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`

export const MatchLabel = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
`

export const MatchContent = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: var(--font-size-normal);
`

export const MatchItem = styled.div`
    line-height: 22px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

export const Text = styled.span``

export const ErrorText = styled.span`
    color: var(--color-red-500);
`

export const BoldText = styled.span`
    font-weight: 600;
`

export const Operator = styled.span`
    margin: 0 6px;
`

export const OperatorIcon = styled(IconFont)`
    ${pointer}
    color: var(--color-gray-400);

    &:hover {
        color: var(--color-gray-900);
    }
`
