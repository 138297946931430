import { ClickAwayListener, IconFont, Input, pointer } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

const SCxText = styled.div`
    color: var(--color-gray-900);
    font-weight: 500;
    font-size: var(--font-size-base);
`

const SCxIcon = styled(IconFont)`
    ${pointer}
`

interface NameInputProps {
    value: string
    onChange: (val: string) => void
}

export const NameInput: React.FC<NameInputProps> = ({ value, onChange }) => {
    const [state, setState] = useImmer({
        isEdit: false,
        value
    })

    const { isEdit } = state

    const handleOpenEdit = useCallback(() => {
        setState(draft => {
            draft.isEdit = true
        })
    }, [setState])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            setState(draft => {
                draft.value = ev.target.value
            })
        },
        [setState]
    )

    const handleOk = useCallback(() => {
        onChange(state.value)
        setState(draft => {
            draft.isEdit = false
        })
    }, [onChange, setState, state.value])

    return isEdit ? (
        <form onSubmit={handleOk}>
            <ClickAwayListener onClickAway={handleOk}>
                <Input autoFocus onChange={handleChange} value={state.value} />
            </ClickAwayListener>
        </form>
    ) : (
        <>
            <SCxText>{state.value}</SCxText>
            <SCxIcon type="edit" fill="var(--color-gray-400)" size={16} onClick={handleOpenEdit} />
        </>
    )
}
