import { CheckBoxGroup, singleTextEllipsis } from '@byecode/ui'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { ConditionFilter } from './ConditionFilter'

export interface VisibilityFilterProps {
    title?: string
    currentDsId?: string
    hideDeviceFilter?: boolean
}

const StyledText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    ${singleTextEllipsis()}
`

const deviceOptions = [
    {
        label: '电脑端',
        value: 'desktop'
    },
    {
        label: '手机端',
        value: 'mobile'
    }
]

export const VisibilityFilter: React.FC<VisibilityFilterProps> = ({ title = '显示', currentDsId, hideDeviceFilter }) => {
    const { control } = useFormContext()

    return (
        <Group4ByecodeUi label={title}>
            <ConditionFilter currentDsId={currentDsId} />

            {!hideDeviceFilter && (
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <StyledText>设备</StyledText>
                    <Controller
                        control={control}
                        name="visibilityDevice"
                        render={({ field }) => (
                            <CheckBoxGroup
                                style={{
                                    gap: 16
                                }}
                                direction="horizontal"
                                options={deviceOptions}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
        </Group4ByecodeUi>
    )
}
