import { Button } from '@byecode/ui'
import { ApplicationStruct } from '@lighthouse/core'
import { AccountBox, useAtomAction } from '@lighthouse/shared'
import { Select } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { setApplicationListAtom } from '@/atoms/application/action'
import { fetchWorkSpaceListAtom } from '@/atoms/workSpace/action'
import TemplateTitle from '@/containers/Template/TemplateTitle'
import type { Template } from '@/containers/Template/types'
import { useUser } from '@/hooks/useUser'
import { useWorkSpaceList } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import { useTemplateList } from '@/shared/reusable'

import { ImportLoading } from '../ImportLoading'
import { RoundAvatar } from '../RoundAvatar'
import * as SC from './styles'

interface TemplateAppCreatorProps {}

const TemplateAppCreator: React.FunctionComponent<TemplateAppCreatorProps> = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { search: pathSearch } = useLocation()

    const search = useMemo(
        () => ({
            spaceId: searchParams.get('spaceId'),
            templateId: searchParams.get('templateId'),
            // redirect: searchParams.get('redirect')
        }),
        [searchParams]
    )

    const workSpaceList = useWorkSpaceList()
    const { run: fetchWorkSpaceList } = useAtomAction(fetchWorkSpaceListAtom)
    const { run: setApplicationList } = useAtomAction(setApplicationListAtom)
    const { data: templateKindList } = useTemplateList()

    const [workSpaceId, setWorkSpaceId] = useState<string>('')
    const [templateId, setTemplateId] = useState<string>()
    const [loading, setLoading] = useState(false)

    const user = useUser()
    const isLogin = Boolean(user.userId)

    const selectWorkSpaceList = useMemo(() => workSpaceList.map(({ id, name }) => ({ label: name, value: id })), [workSpaceList])

    const [workSpaceName, workSpaceIcon] = useMemo(() => {
        const workSpace = find(({ id }) => id === workSpaceId, workSpaceList)
        return [workSpace?.name ?? '', workSpace?.icon ?? '']
    }, [workSpaceId, workSpaceList])

    const template = useMemo(() => {
        const templateList: Template[] = templateKindList?.reduce<Template[]>((preVal, curVal) => [...preVal, ...curVal.list], []) ?? []
        return find(item => item.id === templateId, templateList)
    }, [templateId, templateKindList])

    useEffect(() => {
        if (isLogin) {
            workSpaceList.length === 0 && fetchWorkSpaceList()
        }
    }, [fetchWorkSpaceList, isLogin, navigate, pathSearch, search, workSpaceList.length])

    useEffect(() => {
        if (search.spaceId) {
            setWorkSpaceId(search.spaceId)
            return
        }
        setWorkSpaceId(workSpaceList?.[0]?.id)
    }, [search.spaceId, workSpaceList])

    useEffect(() => {
        // if (search.templateId || search.redirect) {
        //     search.templateId ? setTemplateId(search.templateId) : setTemplateId(search.redirect?.replace('templateId-', '') ?? '')
        // }
        if (search.templateId) {
            setTemplateId(search.templateId)
        }
    }, [search, workSpaceList])

    const handleCreateTemplateApp = useCallback(async () => {
        if (!workSpaceId || !templateId) {
            return
        }
        setLoading(true)
        const appId = await srv.createAppTemplate({ templateId, spaceId: workSpaceId })
        setLoading(false)
        if (appId) {
            setApplicationList(draft => {
                draft.push({ ...ApplicationStruct(), id: appId })
            })
            navigate({ pathname: `/${appId}` })
        }
    }, [navigate, setApplicationList, templateId, workSpaceId])

    return (
        <AccountBox title="">
            <SC.Container>
                <SC.Title>复制应用</SC.Title>
                <TemplateTitle
                    style={{
                        backgroundColor: 'var(--color-gray-50)',
                        border: '1px solid var(--color-gray-200)',
                        borderRadius: 8,
                        padding: 12,
                        height: 'auto'
                    }}
                    logoSize={40}
                    iconSize={28}
                    data={template}
                />
                <SC.Text style={{ margin: '28px 0 16px 0' }}>选择复制到的空间</SC.Text>
                <Select
                    icon={
                        <RoundAvatar
                            enableHover={!workSpaceIcon}
                            icon={workSpaceIcon}
                            radius="8px"
                            style={{ overflow: 'hidden' }}
                            size="middle"
                            name={workSpaceName}
                        />
                    }
                    value={workSpaceId}
                    data={selectWorkSpaceList}
                    styles={{
                        input: {
                            minHeight: 40,
                            borderRadius: 8
                        }
                    }}
                    onChange={val => setWorkSpaceId(val ?? '')}
                    rightSection={<SC.Icon type="ArrowDownSmall" />}
                />
                <SC.BtnGroup>
                    <Button type="default" onClick={() => navigate(-1)}>
                        取消
                    </Button>
                    <Button type="primary" loading={loading} onClick={handleCreateTemplateApp}>
                        复制
                    </Button>
                </SC.BtnGroup>
                <ImportLoading open={loading} hidePercent fileStatus={null} onClose={() => setLoading(false)} />
            </SC.Container>
        </AccountBox>
    )
}

export default TemplateAppCreator
