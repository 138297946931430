import { flex, IconFont, pointer } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const PageContainer = styled.div`
    position: relative;
    width: 100%;
    /* ${flex} */
    /* flex: 1; */
    height: 100vh;
    background: #f2f2f3;
    display: flex;
    flex-direction: column;
`

export const PageContent = styled.div`
    position: relative;
    /* height: calc(100% - 96px); */
    flex: 1;
    width: 100%;
    display: flex;
    overflow: hidden;
`

export const PageContentContainer = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    ${pointer}
`
export const CenterGroup = styled.div`
    ${flex};
    flex: 1;
    justify-content: center;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 8px;
    }

    /* position: relative;
    ::before {
        position: absolute;
        z-index: 100;
        left: 180px;
        bottom: 17px;
        width: 500px;
        height: 1px;
        content:  '';
        background-color: red;
    } */
`
