import { getBrandInfo } from '@lighthouse/shared'
import { Image, Text } from '@mantine/core'
import * as React from 'react'
import styled from 'styled-components'

interface CustomerServicesProps {}

export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 20px 0;
    border-radius: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`

export const SCxTitle = styled.div`
    color: var(--color-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
`

export const SCxDescribe = styled.div`
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--color-gray-500);
    /* padding-bottom: 16px; */
`

export const CustomerServices: React.FunctionComponent<CustomerServicesProps> = props => {
    return (
        <SCxContainer>
            <SCxTitle>添加客服微信二维码</SCxTitle>
            <SCxDescribe>人工服务时段（周一至周日 9:00~20:00）</SCxDescribe>
            <Image width={267} src={getBrandInfo().space.saleImage} />
        </SCxContainer>
    )
}
