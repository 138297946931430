import { Toast } from '@lighthouse/bui'
import type { Environment } from '@lighthouse/core'
import { APPLICATION_ENV_PROD, useAtomAction } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import useSWR from 'swr'
import { useImmer } from 'use-immer'

import { setDataSourceEnvIdAtom } from '@/atoms/dataSource/action'
import * as srv from '@/services'

import type { MergeModalState } from '../../Environment'
import { MergeModal } from '../../Environment'

interface MergeWrapperProps {
    open: boolean
    currentEnv: Environment
    envList: Environment[]
    onClose?: () => void
}

export const MergeWrapper: React.FC<MergeWrapperProps> = ({ open, currentEnv, envList, onClose }) => {
    // const { run: setDataSourceEnvId } = useAtomAction(setDataSourceEnvIdAtom)
    const [state, setState] = useImmer<MergeModalState>({
        fromEnvId: currentEnv?.envId || '',
        toEnvId: APPLICATION_ENV_PROD
    })
    const { fromEnvId, toEnvId } = state

    const { data, mutate, isLoading } = useSWR(
        ['getEnvDiffs', fromEnvId, toEnvId],
        () => {
            if (!fromEnvId || !toEnvId || fromEnvId === toEnvId) {
                return Promise.resolve(undefined)
            }
            return srv.getEnvDiffs(fromEnvId, toEnvId)
        },
        {
            revalidateOnFocus: false,
        }
    )

    const handleMerge = useCallback(async () => {
        const isMerge = await srv.mergeEnv(fromEnvId, toEnvId)
        if (isMerge) {
            Toast.success('合并成功')
            // setDataSourceEnvId(toEnvId)
            onClose?.()
        }
        return isMerge
    }, [fromEnvId, onClose, toEnvId])

    if (!open) {
        return null
    }
    return (
        <MergeModal
            open={open}
            loading={isLoading}
            data={data}
            envList={envList}
            envState={state}
            onChangeEnvId={setState}
            onMerge={handleMerge}
            onClose={onClose}
        />
    )
}
