import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal } from '@byecode/ui'
import React from 'react'

import DingTalkRobot from '../DingTalkRobot'
import type { DingTalkRobotMode } from '../type'

interface DingTalkRobotModalProps extends BaseModalProps {
    mode: DingTalkRobotMode
    id?: string
}

export const DingTalkRobotModal: React.FunctionComponent<DingTalkRobotModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <DingTalkRobot mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
