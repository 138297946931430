import { Toast } from '@byecode/ui'
import type { ErrorCode, StatusFile } from '@lighthouse/shared'
import { errorCodeMap, UploadClickDropZone } from '@lighthouse/shared'
import { useAbortAll, useItemErrorListener, useItemFinishListener, useItemProgressListener, useItemStartListener } from '@rpldy/uploady'
import React from 'react'

import TemplateItem from '../TemplateItem'
import type { Template } from '../types'

interface TemplateUploadyItemProps {
    item: Template
    onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
}

const maxUploadSize = 1024 * 1024 * 10

export const TemplateUploadyItem: React.FC<TemplateUploadyItemProps> = ({ item, onChangeFileStatus }) => {
    const abortAll = useAbortAll()

    useItemStartListener(item => {
        const { file } = item
        if (file.size > maxUploadSize) {
            Toast.warning('当前选择文件超出10M, 请重新选择')
            abortAll()
        }
        const fileState: StatusFile = {
            status: 'uploading',
            percent: 0
        }
        onChangeFileStatus?.(fileState)
    })

    useItemProgressListener(item => {
        const { completed } = item
        onChangeFileStatus?.({
            status: 'uploading',
            percent: completed
        })
    })

    useItemErrorListener(item => {
        onChangeFileStatus?.({
            status: 'error',
            percent: 100
        })
    })

    useItemFinishListener(item => {
        const { id, uploadResponse } = item
        if (uploadResponse.data.code === '0000000') {
            const appId = uploadResponse.data.content as string
            onChangeFileStatus?.(
                {
                    status: 'success',
                    percent: 100
                },
                appId
            )
            Toast.success('处理成功')
            return
        }

        onChangeFileStatus?.({
            status: 'error',
            percent: 100
        })
    })
    return (
        <UploadClickDropZone>
            <TemplateItem data={item} />
        </UploadClickDropZone>
    )
}
