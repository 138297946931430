import { Popover } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingJumpType, ApplicationSettingNameType, NavigationBaseItem } from '@lighthouse/core'
import { ApplicationSettingJumpType as AppJumpType } from '@lighthouse/core'
import { OperationMenu } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { useDefaultPageList } from '@/hooks/usePage'

import { initPageList, jumpButton } from '../constant'
import * as SC from './styles'

export interface JumpLinkOption {
    label: string
    value: ApplicationSettingJumpType
    placeholder: string
}
interface NavBarItemProps {
    data: NavigationBaseItem
    id: string
    jumpOptions?: JumpLinkOption[]
    // theme?: ApplicationSettingThemeNavbar
    disableDrag?: boolean
    disableName?: boolean
    disableIcon?: boolean

    onDelete?: (id: string) => void
    onChange?: (id: string, data: NavigationBaseItem) => void
}

const NavBarItem: React.FC<NavBarItemProps> = ({
    data,
    id,
    jumpOptions = [],
    disableDrag = false,
    disableName = false,
    disableIcon = false,
    // theme,
    onDelete,
    onChange
}) => {
    const [open, setOpen] = useState(false)

    const pageList = useDefaultPageList()

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id,
        // data,
        disabled: disableDrag
    })

    const style = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition
        }),
        [transform, transition]
    )

    const linkPlaceholder = useMemo(
        () => find(item => item.value === data.jumpType, jumpOptions)?.placeholder,
        [data.jumpType, jumpOptions]
    )

    const pageOptions = useMemo(() => {
        return [
            ...initPageList,
            ...(pageList ?? []).map(item => {
                return {
                    label: item.name || '无标题',
                    value: `/page/${item.id}`
                }
            })
        ]
    }, [pageList])

    const linkUrl = useMemo(() => {
        switch (data.jumpType) {
            case AppJumpType.linkUrl: {
                return (
                    <SC.LinkUrl
                        placeholder={linkPlaceholder}
                        value={data.value}
                        size="sm"
                        onChange={e => onChange?.(id, { ...data, value: e.target.value })}
                    />
                )
            }
            case AppJumpType.page: {
                return (
                    <SC.LinkTypeSelect
                        options={pageOptions}
                        value={data.value}
                        placeholder={linkPlaceholder}
                        size="sm"
                        // downIcon="ArrowDownSmall"
                        onChange={e => onChange?.(id, { ...data, value: e.toString() })}
                    />
                )
            }
            default: {
                return ''
            }
        }
    }, [data, id, linkPlaceholder, onChange, pageOptions])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(id, { ...data, name: e.target.value })
        },
        [data, id, onChange]
    )

    return (
        <SC.Container style={style} {...attributes}>
            {disableDrag || (
                <SC.Handle ref={setNodeRef} {...listeners}>
                    <SC.Icon type="DotsSix" color="var(--color-gray-400)" />
                </SC.Handle>
            )}
            {disableIcon || (
                <SC.BtnIconPicker
                    value={{ type: 'icon', value: data.icon }}
                    onChange={icon => onChange?.(id, { ...data, icon: icon?.value })}
                />
            )}
            {disableName || (
                <SC.TitleInput value={data.name} placeholder="标题" onChange={e => onChange?.(id, { ...data, name: e.target.value })} />
            )}
            <SC.LinkTypeSelect
                options={jumpOptions.map(({ label, value }) => ({ label, value: value.toString() }))}
                value={data.jumpType.toString()}
                size="sm"
                onChange={e => onChange?.(id, { ...data, jumpType: Number(e) as ApplicationSettingJumpType, value: '' })}
            />

            {linkUrl}
            {!!data.type && (
                <Popover opened={open} onChange={setOpen} withinPortal width="auto">
                    <Popover.Target>
                        <SC.Icon type="Pattern" color="var(  --color-gray-400)" />
                    </Popover.Target>
                    <Popover.Dropdown>
                        <OperationMenu
                            // title="按钮名称"
                            radioList={jumpButton}
                            isRename={false}
                            value={data.name}
                            radioValue={data.type?.toString()}
                            onRadioChange={e => onChange?.(id, { ...data, type: Number(e) as ApplicationSettingNameType })}
                            onChange={handleChange}
                            onClose={() => setOpen(false)}
                        />
                    </Popover.Dropdown>
                </Popover>
            )}
            {disableDrag || <SC.Icon type="Close" color="var(  --color-gray-400)" onClick={() => onDelete?.(data.id ?? '')} />}
        </SC.Container>
    )
}

export default NavBarItem
