import { tinyButtons } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import { OtherPlatformsItem } from '../Item'
import * as CM from '../styles'
import type { AppAccountItem, AppCountAction, SortType } from '../types'

interface OtherPlatformsProps {
    list: AppAccountItem[]
    onChangeAccount?: (id: string, action: AppCountAction) => void
    onChangeSort: (value: SortType) => void
}

const SCxTableContainer = styled.div`
    margin-top: 16px;
    position: relative;
    width: 100%;
    height: auto;
    max-height: calc(100% - 72px);
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const SCxTableHeader = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
`

const SCxTableContent = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden auto;
    background-color: var(--color-white);
    ${tinyButtons}

    &>:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);
    }
`

const SCxTableColumnName = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    font-weight: 500;
    padding-right: 6px;
`

const SCxItem = styled.div`
    display: flex;
    align-items: center;
    height: 57px;
    padding: 0 16px;
    flex: 1;
`

const SCxOperationItem = styled.div`
    display: flex;
    align-items: center;
    height: 57px;
    padding: 0 16px;
    min-width: 258px;
    flex: 0;
`

export const OtherPlatformsTable: React.FunctionComponent<OtherPlatformsProps> = ({ list, onChangeAccount, onChangeSort }) => {
    return (
        <SCxTableContainer>
            <SCxTableHeader>
                <SCxItem onClick={() => onChangeSort('TITLE')}>
                    <SCxTableColumnName>应用账户</SCxTableColumnName>
                    <CM.Icon color="var(--color-gray-400)" size={12} type="CareBoth" />
                </SCxItem>
                <SCxItem onClick={() => onChangeSort('APP_TYPE')}>
                    <SCxTableColumnName>应用类型</SCxTableColumnName>
                    <CM.Icon color="var(--color-gray-400)" size={12} type="CareBoth" />
                </SCxItem>
                <SCxOperationItem>
                    <SCxTableColumnName>操作</SCxTableColumnName>
                </SCxOperationItem>
            </SCxTableHeader>
            <SCxTableContent>
                {list.map(item => (
                    <OtherPlatformsItem onChangeAccount={onChangeAccount} key={item.id} data={item} />
                ))}
            </SCxTableContent>
        </SCxTableContainer>
    )
}
