import type { SchemaProtocol, TableColumns } from '@lighthouse/core'
import { clone } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { SimpleTableChecker } from './SimpleTableChecker'
import { SimpleTableField } from './SimpleTableField'

export interface SimpleTableHeaderProps {
    tableProps: TableColumns
    schema: SchemaProtocol['schema']
    onChangeTableProps?: (tableProps: TableColumns) => void
}

const SCxTableHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 80;
    background-color: var(--color-gray-50);
    display: flex;
    width: max-content;
    min-width: 100%;
`

const SCxTableChecker = styled(SimpleTableChecker)`
    background-color: var(--color-gray-50);
`

export const SimpleTableHeader: React.FC<SimpleTableHeaderProps> = ({ tableProps, schema, onChangeTableProps }) => {
    const handleResizerChange = useCallback(
        (id: string, columnWidth: number) => {
            const newTableProps = clone(tableProps)
            const index = newTableProps.findIndex(col => col.id === id)
            newTableProps[index].width = columnWidth
            onChangeTableProps?.(newTableProps)
        },
        [onChangeTableProps, tableProps]
    )

    return (
        <SCxTableHeader>
            <SCxTableChecker />
            {tableProps.map(({ id, visible, width }, index) => {
                const field = schema[id]
                return (
                    field &&
                    visible && <SimpleTableField key={id} width={width} field={{ ...field, id }} onResizerChange={handleResizerChange} />
                )
            })}
        </SCxTableHeader>
    )
}
