import { Button, Divider, Empty, Input, Popover, tinyButtons } from '@byecode/ui'
import type { AppUserDepartment } from '@lighthouse/shared'
import { filter, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useDataSourceDepartments, useDataSourceUserGroups } from '@/shared/reusable'

import { getFlatDepartments } from '../help'
import * as CM from '../styles'
import { DepartmentItem } from './DepartmentItem'
import GroupItem from './GroupItem'

export interface TagData {
    type: 'department' | 'group'
    name: string
    id: string
}

export const SCxDropDownContainer = styled.div`
    padding: 12px;
    width: 100%;
    display: flex;
    max-height: 600px;
    flex-direction: column;
    gap: 8px;
`
export const SCxDropDownList = styled.div`
    flex: 1;
    width: 100%;
    overflow: hidden auto;
    ${tinyButtons}/* max-height: 100%; */
`
export const SCxDropDownFooter = styled.div`
    padding: 8px 4px 4px 4px;
    display: flex;
    gap: 12px;
`

export const SCxTitle = styled.div`
    height: 32px;
    line-height: 32px;
    color: var(--color-gray-500);

    font-size: var(--font-size-sm);
`

export const SCxDropDown = styled.div``

export type TagProps = {
    value?: TagData[]
    name?: string
    color?: string
    disabled?: boolean
    withinPortal?: boolean

    onAddUsers: (users: TagData[]) => Promise<void>
}

function getNewDepartment(
    departmentList: (AppUserDepartment & { nameList?: string[] })[],
    department?: AppUserDepartment & { nameList?: string[] }
): (AppUserDepartment & { nameList?: string[] })[] {
    const { nameList = [], departmentId: parentId } = department ?? {}
    return departmentList.map(childDepartment => {
        const { departmentChild = [], departmentName = '', departmentId = '', departmentManager } = childDepartment
        const newDepartment = {
            departmentId,
            departmentName,
            departmentManager,
            departParentId: parentId,
            nameList: [...nameList, departmentName]
        }
        return {
            ...newDepartment,
            departmentChild: getNewDepartment(departmentChild, newDepartment)
        }
    })
}

export const AddMember: React.FC<TagProps> = ({
    name = '添加成员',
    color = '#fff',
    value = [],
    disabled = false,
    withinPortal,
    onAddUsers
}) => {
    const groups = useDataSourceUserGroups()
    const { data: departments } = useDataSourceDepartments()

    const [keyword, setKeyword] = useState('')
    const [opened, setOpened] = useState(false)
    const [data, setData] = useState<TagData[]>([])

    const dropDownData = useMemo(() => [...value, ...data], [data, value])

    const [groupList, departmentList] = useMemo(() => {
        const groupList = filter(item => item.groupName.includes(keyword), groups)
        const flatDepartmentList = getFlatDepartments(getNewDepartment(departments))
        const searchDepartmentList = filter(item => item.departmentName.includes(keyword), flatDepartmentList)
        return [groupList, keyword ? searchDepartmentList : departments]
    }, [departments, groups, keyword])

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }, [])

    const handleChange = useCallback(
        (id: string, type: 'department' | 'group') => {
            setData(draft => {
                const isExit = find(item => item.id === id && item.type === type, draft)
                if (isExit) {
                    return draft.filter(item => item.id !== id && item.type !== type)
                }
                const name =
                    type === 'group'
                        ? find(item => item.groupId === id, groupList)?.groupName
                        : find(item => item.departmentId === id, departmentList)?.departmentName
                return [...draft, { id, type, name: name ?? '' }]
            })
        },
        [departmentList, groupList]
    )

    useEffect(() => {
        if (!opened) {
            setKeyword('')
            setData([])
        }
    }, [opened])

    return (
        <>
            <Popover
                width={346}
                withinPortal={withinPortal}
                position="bottom-start"
                opened={opened}
                onChange={setOpened}
                onClose={() => setOpened(false)}
            >
                <Popover.Target>
                    <Button
                        disabled={disabled}
                        style={{ marginTop: 6 }}
                        radius={100}
                        icon={<CM.Icon fill="var(--color-gray-500)" type="Add" />}
                    >
                        {name}
                    </Button>
                </Popover.Target>
                <Popover.Dropdown compact>
                    <SCxDropDownContainer>
                        <Input
                            placeholder="搜索"
                            style={{ flexShrink: 0 }}
                            prefix={<CM.Icon type="Search" />}
                            value={keyword}
                            onChange={handleSearch}
                        />
                        <SCxDropDownList>
                            <SCxTitle>角色</SCxTitle>
                            {groupList.map(item => (
                                <GroupItem
                                    data={item}
                                    disabled={value.some(v => v.type === 'group' && v.id === item.groupId)}
                                    checked={dropDownData.some(v => v.id === item.groupId && v.type === 'group')}
                                    key={item.groupId}
                                    onClick={() => handleChange(item.groupId, 'group')}
                                />
                            ))}
                            {groupList.length === 0 && <Empty icon="Nodata-8i554976" style={{ height: 86 }} description="没有数据" />}
                            <Divider style={{ margin: '12px 0' }} />
                            <SCxTitle>部门</SCxTitle>
                            {departmentList.map(item => (
                                <DepartmentItem
                                    key={item.departmentId}
                                    data={item}
                                    disableItemList={value}
                                    level={0}
                                    value={data}
                                    type={keyword ? 'breadcrumbs' : 'collapse'}
                                    onChange={handleChange}
                                />
                            ))}
                            {departmentList.length === 0 && <Empty icon="Nodata-8i554976" style={{ height: 86 }} description="没有数据" />}
                        </SCxDropDownList>
                        <SCxDropDownFooter>
                            <Button block size="lg" onClick={() => setOpened(false)}>
                                取消
                            </Button>
                            <Button
                                block
                                type="primary"
                                size="lg"
                                onClick={() => {
                                    onAddUsers(dropDownData)
                                    setOpened(false)
                                }}
                            >
                                确定
                            </Button>
                        </SCxDropDownFooter>
                    </SCxDropDownContainer>
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
