import { Flex, Switch, Text } from '@byecode/ui'
import { Collapse } from '@mantine/core'
import React, { useState } from 'react'
import styled from 'styled-components'

interface LoginBlockProps {
    label: React.ReactNode | string
    icon: React.ReactNode
    checked: boolean
    disableCollapse?: boolean
    style?: React.CSSProperties
    children?: React.ReactNode
    onChange: (v: boolean) => void
}

export const SCxLoginBlock = styled.div`
    width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
`

export const SCxLoginBlockHeader = styled.div`
    width: 100%;
    padding: 16px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const SCxLoginBlockContent = styled.div`
    width: 100%;
    padding: 8px 16px;
    border-top: 1px solid var(--color-gray-200);
`

export const LoginBlock: React.FunctionComponent<LoginBlockProps> = ({ icon, label, checked, disableCollapse, children, onChange }) => {
    return (
        <SCxLoginBlock>
            <SCxLoginBlockHeader>
                <Flex alignItems="center" gap={8}>
                    {icon}
                    {/* <TagIcon size={32} iconSize={20} icon={icon} /> */}
                    {typeof label === 'string' ? <Text>{label}</Text> : label}
                </Flex>
                <Switch checked={checked} onChange={e => onChange(e.target.checked)} />
            </SCxLoginBlockHeader>
            {disableCollapse || (
                <Collapse in={checked}>
                    <SCxLoginBlockContent>{children}</SCxLoginBlockContent>
                </Collapse>
            )}
        </SCxLoginBlock>
    )
}
