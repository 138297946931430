import { Button, IconFont, Modal } from '@byecode/ui'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'
import { UserImportModal } from './UserImportModal'

export interface UserImportProps {
    onFetchUsers: () => void
}

export const UserImport: React.FC<UserImportProps> = ({ onFetchUsers }) => {
    const [open, setOpen] = useState(false)
    const handleOpenImport = useCallback(() => {
        setOpen(true)
    }, [])

    const handleCloseImport = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <SC.Container>
            <Button onClick={handleOpenImport} icon={<IconFont type="UploadSimple" size={16} fill="var(--color-gray-400)" />}>
                导入
            </Button>
            <Modal
                open={open}
                withCloseIcon={false}
                width={745}
                styles={{
                    desktop: {
                        body: {
                            padding: '0!important',
                            overflow: 'hidden',
                            borderRadius: 12
                        }
                    }
                }}
                onClose={handleCloseImport}
            >
                <UserImportModal onFetchUsers={onFetchUsers} onClose={handleCloseImport} />
            </Modal>
        </SC.Container>
    )
}
