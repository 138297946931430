import { Group, hideScrollBar } from '@byecode/ui'
import styled from 'styled-components'

import SettingBlock from '../SettingBlock'

export const Root = styled(SettingBlock)`
    border: none;
    overflow-y: auto;
    height: 100%;
    ${hideScrollBar}
`

export const CategoryGroup = styled(Group)`
    padding: 0;

    .byecode-group-wrapper {
        padding: 0;
        height: 36px;
    }
    .byecode-group-label {
        font-size: 12px;
        font-weight: 400;
    }
    .byecode-group-collapse {
        padding: 0;
        margin: 0 -8px;
    }
`

export const CreatorButton = styled.button`
    all: unset;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 5px;
    margin-right: -4px;
    &:hover {
        background-color: rgba(38, 65, 90, 0.06);
    }
`
