import { Flex, IconFont, Text, Tooltip } from '@byecode/ui'
import type { SpaceVersion } from '@lighthouse/core'
import { spaceGradeCodeMap, spaceVersionEnum } from '@lighthouse/shared'
import React, { Fragment } from 'react'

import * as srv from '@/services'
import { useSpaceQuota } from '@/shared/reusable'

import { openSaleSpaceGrade } from '../event'
import { SPACE_LEVEL_DATA, TABLE_STRUCT } from './constants'
import * as SC from './Table.style'
import { formatSpaceFeaturesValue } from './utils'

interface Props {
    onWechatPaySpaceUpgrade: (level: SpaceVersion) => void
}

export const Table = ({ onWechatPaySpaceUpgrade }: Props) => {
    const { data: spaceQuota } = useSpaceQuota()
    const { currentVersionCode = spaceVersionEnum.FREE } = spaceQuota ?? {}

    const handleClickBuy = (level: SpaceVersion) => {
        if (level === 'ENTERPRISE') {
            srv.getUserConsult()
            openSaleSpaceGrade()
            return
        }

        onWechatPaySpaceUpgrade(level)
    }

    const buttonTitle = (versionCode: spaceVersionEnum) => {
        if (versionCode === currentVersionCode) {
            return '当前版本'
        }

        if (versionCode === spaceVersionEnum.ENTERPRISE) {
            return '联系我们'
        }

        if (versionCode < currentVersionCode) {
            return '已升级'
        }

        return '立即购买'
    }

    return (
        <div style={{ margin: '0 auto' }}>
            <SC.Title>版本对比</SC.Title>

            <Flex style={{ position: 'sticky', top: 0, background: 'var(--color-white)' }}>
                <SC.IndexCell />
                {SPACE_LEVEL_DATA.map(item => {
                    const itemVersionCode = spaceGradeCodeMap[item.version]
                    return (
                        <SC.Cell key={item.version} style={{ flexDirection: 'column', gap: 0, padding: 20 }}>
                            <Text weight={500} lineHeight="24px">
                                {item.name}
                            </Text>
                            <Flex gap={8} alignItems="center" mt={12} mb={20}>
                                {item.price === -1 ? (
                                    <SC.Price hideCurrency>咨询报价</SC.Price>
                                ) : (
                                    <>
                                        <SC.Price>{formatSpaceFeaturesValue(item.price).value}</SC.Price>
                                        <Text size={12} color="var(--color-gray-400)">
                                            /每年
                                        </Text>
                                    </>
                                )}
                            </Flex>
                            <SC.BuyButton
                                onClick={() => handleClickBuy(item.version)}
                                disabled={itemVersionCode <= currentVersionCode}
                                recommend={itemVersionCode > currentVersionCode && item.version === 'ADVANCED'}
                            >
                                {buttonTitle(itemVersionCode)}
                            </SC.BuyButton>
                        </SC.Cell>
                    )
                })}
            </Flex>

            {TABLE_STRUCT.map(item => {
                return (
                    <Fragment key={item.title}>
                        <SC.Header>{item.title}</SC.Header>
                        {item.children.map(row => {
                            return (
                                <SC.Row key={row.dataKey}>
                                    <SC.IndexCell>
                                        {row.title}
                                        {row.tooltip && (
                                            <Tooltip title={row.tooltip}>
                                                <IconFont type="QuestionCircle" fill="var(--color-gray-400)" />
                                            </Tooltip>
                                        )}
                                    </SC.IndexCell>

                                    {SPACE_LEVEL_DATA.map(cell => {
                                        const { value, isNumber } = formatSpaceFeaturesValue(cell[row.dataKey])

                                        return (
                                            <SC.Cell key={cell.version} style={{ fontFamily: isNumber ? 'DIN-Alternate-Bold' : undefined }}>
                                                {row.format ? row.format(cell, row.dataKey) : value}
                                            </SC.Cell>
                                        )
                                    })}
                                </SC.Row>
                            )
                        })}
                    </Fragment>
                )
            })}
        </div>
    )
}
