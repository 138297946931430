import { IconFont, Popover, Switch, Tooltip } from '@byecode/ui'
import { type FlowNode, type WorkflowType, getNodeInfo, nodeTypeMap } from '@lighthouse/shared'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'

import { workflowLabelMap } from '@/constants/flow'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { SettingPopoverContent } from '../SettingPopoverContent'
import * as SC from './styles'

export interface FlowCardProps {
    type: WorkflowType
    name: string
    description: string
    unConfigured?: boolean
    updatedTime: Date
    updatedBy: string
    createdTime: Date
    createdBy: string
    enabled: boolean
    nodes: FlowNode[]
    onClick: () => void
    onDelete: () => void
    onDuplicate: () => void
    onNameChange: (name: string) => void
    onDescriptionChange: (name: string) => void
    onEnabledChange: (enabled: boolean) => void
}

export const FlowCard: React.FC<FlowCardProps> = ({
    type,
    name,
    description,
    updatedTime = new Date(),
    updatedBy,
    createdTime = new Date(),
    createdBy,
    enabled,
    unConfigured,
    nodes,
    onClick,
    onDelete,
    onDuplicate,
    onNameChange,
    onDescriptionChange,
    onEnabledChange
}) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const { color } = workflowLabelMap[type]
    const [open, setOpen] = useState(false)
    const descriptions = useMemo(
        () => [
            { id: '1', label: '最后编辑', value: lightFormat(updatedTime, 'yyyy/MM/dd HH:mm') },
            { id: '2', label: '编辑人', value: updatedBy },
            { id: '3', label: '创建时间', value: lightFormat(createdTime, 'yyyy/MM/dd HH:mm') },
            { id: '4', label: '创建人', value: createdBy }
        ],
        [createdBy, createdTime, updatedBy, updatedTime]
    )

    const [triggerNode, ...extraNodes] = nodes || []
    const triggerNodeIcon = useMemo(() => {
        if (triggerNode) {
            const { nodeType } = triggerNode.data
            const nodeInfo = getNodeInfo(nodeType)
            return <SC.FlowCardTriggerIcon size={20} type={nodeInfo.icon} fill="var(--color-white)" style={{ backgroundColor: color }} />
        }

        return <SC.FlowCardTriggerIcon type="Lightning-Mini" fill="var(--color-white)" style={{ backgroundColor: color }} />
    }, [color, triggerNode])

    const extraNodeContent = useMemo(() => {
        if (extraNodes.length > 0) {
            return (
                <>
                    {extraNodes.map(node => {
                        const { nodeType } = node.data
                        const { icon = '', color } = nodeTypeMap?.[nodeType] ?? {}
                        return (
                            <SC.FlowCardExtraIconWrapper key={node.id}>
                                <IconFont type={icon} size={22} fill={color} />
                            </SC.FlowCardExtraIconWrapper>
                        )
                    })}
                </>
            )
        }
        return (
            <SC.FlowCardExtraIconWrapper style={{ backgroundColor: 'var(--color-gray-100)' }}>
                <IconFont type="ColorEmpty" size={20} fill="var(--color-gray-400)" />
            </SC.FlowCardExtraIconWrapper>
        )
    }, [extraNodes])

    const handleDelete = useCallback(() => {
        setOpen(false)
        onDelete()
    }, [onDelete])

    const handleDuplicate = useCallback(() => {
        setOpen(false)
        onDuplicate()
    }, [onDuplicate])

    return (
        <>
            <SC.FlowCardWrapper onClick={onClick}>
                <SC.FlowCardContent>
                    <SC.FlowCardHeader>
                        <SC.FlowCardIconsWrapper>
                            {triggerNodeIcon}
                            <SC.FlowCardArrowIcon type="ArrowRight" size={20} />
                            {extraNodeContent}
                        </SC.FlowCardIconsWrapper>
                        <SC.FlowCardExtraLinearBackground />
                        {!disabledWithVersion && (
                            <SC.FlowCardActionWrapper onClick={ev => ev.stopPropagation()}>
                                <Popover withinPortal width={220} opened={open} onChange={setOpen}>
                                    <Popover.Target>
                                        <SC.FlowCardAction type="DotsThree" />
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <SettingPopoverContent
                                            name={name}
                                            description={description}
                                            descriptions={descriptions}
                                            onDelete={handleDelete}
                                            onDuplicate={handleDuplicate}
                                            onNameChange={onNameChange}
                                            onDescriptionChange={onDescriptionChange}
                                        />
                                    </Popover.Dropdown>
                                </Popover>
                            </SC.FlowCardActionWrapper>
                        )}
                    </SC.FlowCardHeader>
                    <SC.FlowCardType style={{ color: workflowLabelMap[type].color }}>{workflowLabelMap[type].label}</SC.FlowCardType>
                    <SC.FlowCardTitle>{name}</SC.FlowCardTitle>
                    <Tooltip title={description}>
                        <SC.FlowCardDescription>{description}</SC.FlowCardDescription>
                    </Tooltip>
                </SC.FlowCardContent>
                <SC.FlowCardFooter>
                    <SC.FlowCardFooterLeft onClick={ev => ev.stopPropagation()}>
                        <Switch
                            disabled={disabledWithVersion}
                            size="md"
                            color={workflowLabelMap[type].color}
                            checked={enabled}
                            onChange={ev => onEnabledChange(ev.target.checked)}
                        />
                        {unConfigured && (
                            <Tooltip placement="top" title="工作流无法正常运行，请检查配置">
                                <SC.FlowCardStatusWrapper>
                                    <SC.FlowCardStatus color="#D88987" type="WarningTriangle" size={18} />
                                </SC.FlowCardStatusWrapper>
                            </Tooltip>
                        )}
                    </SC.FlowCardFooterLeft>
                    {/* <SC.FlowCardUpdatedAt>{updatedAtString}</SC.FlowCardUpdatedAt> */}
                </SC.FlowCardFooter>
            </SC.FlowCardWrapper>
        </>
    )
}
