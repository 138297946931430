import { Select } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
            />
            <Select
                placeholder="可清除"
                clearable
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
            />
            <Select
                placeholder="自动聚焦"
                searchable
                trapFocus
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
            />
            <Select
                placeholder="超出隐藏"
                clearable
                style={{
                    width: 200
                }}
                options={[
                    { label: '男男男男男男男男男男男男男男男男男男', value: 'man' },
                    { label: '女女女女女女女女女女女女女女女女女女', value: 'woman' }
                ]}
            />
        </Unit>
    )
}
