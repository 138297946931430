import { IconFont } from '@byecode/ui'
import { type EnvFieldDiff, FieldIconTypeMap } from '@lighthouse/shared'
import { add, multiply } from 'rambda'
import React from 'react'
import styled from 'styled-components'

const SCListItem = styled.div<{ paddingLeft?: number }>`
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-color: var(--color-gray-200);
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}px` : '30px')};

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`

const SCxList = styled.div``

const SCxListItemTitle = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

interface MergeFieldListProps {
    level?: number
    data: EnvFieldDiff[]
}
//
export const MergeFieldList: React.FC<MergeFieldListProps> = ({ level = 0, data }) => {
    const paddingLeft = add(multiply(22, level), 38)

    return (
        <SCxList>
            {data.map((item, index) => {
                const fieldIcon = FieldIconTypeMap[item.fieldType]
                return (
                    <SCListItem paddingLeft={paddingLeft} key={index}>
                        <IconFont type={fieldIcon} />
                        <SCxListItemTitle>{item.fieldName}</SCxListItemTitle>
                    </SCListItem>
                )
            })}
        </SCxList>
    )
}
