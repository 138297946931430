import type { RoleOption } from '../types'

export type SpaceRole = 'SPACE_ADMIN' | 'SPACE_MEMBER'

// export type LibraryRole = 'REPOSITORY_ADMIN' | 'REPOSITORY_DEFEND' | 'REPOSITORY_MEMBER'

export type Role = SpaceRole

// 空间权限
const spacePermission = {
    spaceInformationManager: '空间资料管理编辑',
    spaceDelete: '删除空间',
    // spaceMemberSearch: '搜索成员',
    spaceMemberPermission: '管理人员空间权限',
    spaceMemberManager: '添加邀请移除空间成员',
    spaceLeave: '离开空间',
    spaceTransfer: '转让空间',
    spaceLibraryManage: '空间应用管理',
    spaceDevelop: '开发者'
}

type SpacePermission = keyof typeof spacePermission

export const spaceRole: Record<string, SpacePermission[]> = {
    SPACE_ADMIN: [
        'spaceInformationManager',
        'spaceDelete',
        'spaceMemberPermission',
        'spaceMemberManager',
        'spaceLeave',
        'spaceTransfer',
        'spaceLibraryManage',
        'spaceDevelop'
    ],
    SPACE_MEMBER: ['spaceLeave']
}

export const allRole = { ...spaceRole }

export const spaceRoleOptions: RoleOption[] = [
    {
        label: '空间管理员',
        value: 'SPACE_ADMIN',
        describe: '拥有空间和所有仓库的管理权限'
    },
    {
        label: '空间成员',
        value: 'SPACE_MEMBER',
        describe: '空间成员'
    }
]

export const getAuthorize = (roleId: string, module: SpacePermission) => {
    if (!roleId) {
        return false
    }

    return allRole[roleId].includes(module)
}
