import { Tooltip } from '@byecode/ui'
import { Flex, Image } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

import * as CM from '../style'

interface ModuleListProps {
    items: BlockTreeItem[]
    onCreate: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

interface ModuleCardProps {
    data: BlockTreeItem
    onCreate?: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}
const SCxContainer = styled.div``

const SCxBlock = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
    user-drag: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    background-color: var(--color-gray-50);
    border-radius: 16px;
    height: 110px;
    img {
        -webkit-user-drag: none;
    }

    &:hover {
        background-color: var(--color-gray-100);

        img {
            transform: scale(1.02);
        }
    }
`

const SCxText = styled.div<{ color?: string }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    bottom: 8px;
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
`

const SCxList = styled(Flex)``

export const ModuleCard: React.FC<ModuleCardProps> = ({ data: item, onCreate }) => {
    return (
        <Tooltip title={item.name} disablePortal>
            <SCxBlock onMouseDown={v => onCreate?.(v, item)}>
                <Image fit="cover" src={`${item.describeImage}?version=1.25.0`} />
                <SCxText color={item.color}>{item.name}</SCxText>
            </SCxBlock>
        </Tooltip>
    )
}

export const ModuleList: React.FunctionComponent<ModuleListProps> = ({ items, onCreate }) => {
    return (
        <SCxContainer>
            {/* <SCxTitle>容器</SCxTitle> */}
            <SCxList direction="column" gap={12}>
                {items.map(item => {
                    return <ModuleCard key={item.type} data={item} onCreate={onCreate} />
                })}
            </SCxList>
        </SCxContainer>
    )
}
