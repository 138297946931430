import React from 'react'

import FlowList from '../views/Byecode/Application/[id]/Flow'
import Flow from '../views/Byecode/Application/[id]/Flow/[id]'
// import FlowLog from '../views/Byecode/Application/[id]/Flow/Log/[id]'

// export const flowLogRoute = {
//     path: 'flow/log/:flowInstanceId',
//     element: <FlowLog />
// }

export const flowRoute = {
    path: 'flow/:flowId',
    element: <Flow />
}

export const flowRootRoute = {
    path: 'flow',
    element: <FlowList />
}
