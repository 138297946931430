import { numberFontFamily } from '@byecode/ui'
import styled, { css } from 'styled-components'

export const Title = styled.div`
    margin: 100px 0 36px;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
`

export const Header = styled.div`
    position: sticky;
    top: 164px;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-white);
    background-color: var(--color-black);
`

export const Row = styled.div`
    display: flex;
    border-radius: 8px;
    &:hover {
        background-color: rgba(38, 65, 90, 0.06);
    }
`

export const Cell = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 155px;
    min-height: 60px;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    &:not(:first-of-type) {
        border-left: 1px solid var(--color-gray-200);
    }
`

export const IndexCell = styled(Cell)`
    color: var(--color-gray-700);
    font-weight: 400;
    width: 240px;
`

export const Price = styled.span<{ hideCurrency?: boolean }>`
    height: 28px;
    line-height: 28px;
    font-weight: 700;
    font-size: 24px;
    ${numberFontFamily};
    background: linear-gradient(91deg, #5551ff 4%, #7e51ff 97.52%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'clig' off, 'liga' off;

    ${p =>
        !p.hideCurrency &&
        css`
            &::before {
                content: '￥';
                font-size: 12px;
            }
        `}
`

export const BuyButton = styled.button<{ recommend?: boolean }>`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 12px;
    color: var(--color-black);
    background: var(--color-gray-100);

    &:disabled {
        cursor: not-allowed;
        color: var(--color-gray-400);
    }

    ${p =>
        p.recommend &&
        css`
            color: var(--color-white);
            background: linear-gradient(91deg, #5551ff 4%, #7e51ff 97.52%);
            box-shadow: 0px 8px 16px 0px rgba(95, 82, 255, 0.32);
        `}
`
