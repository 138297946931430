import { Button, Flex, IconFont, Message, Popover, Text, Toast } from '@byecode/ui'
import { APPLICATION_VERSION_STATUS } from '@lighthouse/core'
import { useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { formatDistanceToNow } from 'date-fns'
import { useSetAtom } from 'jotai'
import React, { useCallback, useState } from 'react'

import { updateAppStatusAtom } from '@/atoms/application/action'
import { applicationVersionAtom, AppPublishStateAtom } from '@/atoms/application/state'
import { UrlPreview } from '@/components/ApplicationPublish/PublishWebsite/UrlPreview'

import { PublishedContent } from '../PublishedContent'

interface PublishButtonProps {
    onPublishSuccess?: () => void
}

export const PublishButton = ({ onPublishSuccess }: PublishButtonProps) => {
    const action = useAtomAsyncAction(updateAppStatusAtom)

    const setAppPublishState = useSetAtom(AppPublishStateAtom)

    const handlePublish = useCallback(async () => {
        const isSuccess = await action.run(APPLICATION_VERSION_STATUS.ONLINE)
        if (!isSuccess) {
            return
        }

        onPublishSuccess?.()
        Toast.success('发布成功')

        setAppPublishState({ isPublish: true, isSuccess: true })
    }, [action, onPublishSuccess, setAppPublishState])

    const isPublished = useAtomData(
        applicationVersionAtom,
        useCallback(s => s.status === APPLICATION_VERSION_STATUS.ONLINE, [])
    )

    const [open, setOpen] = useState(false)

    return (
        <>
            <Popover opened={open} onChange={setOpen} width={390} position="bottom-end" withinPortal>
                <Popover.Target>
                    <Button type="primary" icon={<IconFont type="Publish" size={16} color="var(--color-white)" />} loading={action.loading}>
                        发布
                    </Button>
                </Popover.Target>
                <Popover.Dropdown style={{ padding: 24, borderRadius: 12 }}>
                    <PublishedContent
                        showShareContent={isPublished}
                        header={
                            <Flex direction="column" gap={16}>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <Flex direction="column" gap={8}>
                                        <Text weight={500} lineHeight="24px">
                                            {isPublished ? '已发布所有内容' : '应用未发布'}
                                        </Text>
                                        <Text size={14} color="var(--color-gray-500)">
                                            {isPublished ? '上次发布：刚刚' : '发布后用户可以访问应用'}
                                        </Text>
                                    </Flex>
                                    <IconFont
                                        type={isPublished ? 'CloudCheck' : 'CloudCross'}
                                        size={40}
                                        color={isPublished ? 'var(--color-green-500)' : 'var(--color-gray-400)'}
                                    />
                                </Flex>

                                <UrlPreview isProdHost onLink={() => setOpen(false)} />
                            </Flex>
                        }
                        footer={
                            <Button
                                block
                                size="lg"
                                type="primary"
                                icon={<IconFont type="Publish" size={16} color="var(--color-white)" />}
                                loading={action.loading}
                                onClick={handlePublish}
                            >
                                发布
                            </Button>
                        }
                    />
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
