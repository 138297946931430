import { Button, IconFont } from '@byecode/ui'
import { DataSourceType } from '@lighthouse/core'
import {
    getIsFindUseInSort,
    getViewColumns,
    useFindUseObjectContext,
    viewNotFilterSettingFieldTypes,
    ViewSortConfigure
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { PopoverButton } from '../PopoverButton'

const SCxContent = styled.div`
    padding: 0 16px 10px 16px;
`

const SCxDescription = styled.div`
    margin-bottom: 8px;
    color: var(--color-gray-500);
    line-height: 20px;
    font-size: var(--font-size-sm);
`

const SCxButton = styled(Button)``

interface TriggerSorterProps {
    dataSourceId?: string
    description?: string
    prefix: string
    style?: React.CSSProperties
}

export const TriggerSorter: React.FC<TriggerSorterProps> = ({ prefix, dataSourceId = '', description, style }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dataSourceId)
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps: dataSource?.viewOptions?.tableProps || [],
                schema: dataSource?.schema || {}
            }),
        [dataSource?.schema, dataSource?.viewOptions?.tableProps]
    )

    return (
        <SCxContent style={style}>
            {description && <SCxDescription>{description}</SCxDescription>}
            <Controller
                name={`${prefix}.sort`}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const sorters = value
                    const sortersLength = sorters?.length || 0
                    const isHighLight = getIsFindUseInSort({
                        findUseObject,
                        data: value,
                        currentDsId: dataSourceId
                    })
                    return (
                        <>
                            <PopoverButton
                                withinPortal
                                width="auto"
                                positionDependencies={[value]}
                                popupContent={
                                    <ViewSortConfigure
                                        columns={columns}
                                        primaryField="ID"
                                        sorters={value}
                                        noSettingFields={viewNotFilterSettingFieldTypes}
                                        isShowDsName={dataSource?.type === DataSourceType.joinDataSource}
                                        onChangeSorter={onChange}
                                    />
                                }
                            >
                                <SCxButton
                                    disabled={false}
                                    highlighting={isHighLight}
                                    icon={<IconFont type="Setting" size={16} color="var(--color-gray-400)" />}
                                    block
                                >
                                    {sortersLength > 0 ? `已设${sortersLength}个规则` : '设置规则'}
                                </SCxButton>
                            </PopoverButton>
                        </>
                    )
                }}
            />
        </SCxContent>
    )
}
