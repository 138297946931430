import { RecordOpenType } from '@lighthouse/core'

import { StyledIconWrapper } from './styles'

export const OpenTypeData = [
    {
        label: '弹窗',
        value: RecordOpenType.modal
    },
    {
        label: '侧边栏',
        value: RecordOpenType.drawer
    },
    {
        label: '页面',
        value: RecordOpenType.page
    }
]

export const listCommonStyles = {
    root: {
        '&:hover': {
            [`${StyledIconWrapper}`]: {
                background: 'var(--color-white)'
            }
        }
    }
}
