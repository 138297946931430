import { IconFont } from '@byecode/ui'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import * as CM from '../../styles'
import type { Mode } from '../../types'

interface ResetSuccessProps {
    onChangeMode: (value: Mode) => void
}

const SCxTitle = styled.div`
    color: var(--color-black);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
`
const SCxHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    /* 正文（普通） */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-align: center;
`

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const ResetSuccess: React.FunctionComponent<ResetSuccessProps> = ({ onChangeMode }) => {
    const [time, setTime] = useState(6)

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(draft => {
                if (draft === 0) {
                    clearInterval(timer)
                    return draft
                }
                return draft - 1
            })
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        if (time === 0) {
            onChangeMode('login')
        }
    }, [onChangeMode, time])

    return (
        <SCxContainer>
            <SCxHeader>
                <IconFont size={64} type="TickCircle" color="var(--color-green-500)" />
                <SCxTitle>密码重置成功!</SCxTitle>
            </SCxHeader>
            <CM.AccountButton type="primary" block onClick={() => onChangeMode('login')}>
                立即登录
            </CM.AccountButton>
            <SCxDescribe>{time}后自动跳转登录页</SCxDescribe>
        </SCxContainer>
    )
}
