import type { RadioGroupProps } from '@byecode/ui/components/Radio'
import { Radio } from '@byecode/ui/components/Radio'
import { TABS_TYPE } from '@lighthouse/core'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import CardSlotTabsSvg from './assets/card_slot.svg?react'
import LineTabsSvg from './assets/line.svg?react'
import RoundTabsSvg from './assets/round.svg?react'
import SegmentedTabsSvg from './assets/segmented.svg?react'

const TabsRadioGroup = styled(Radio.Group)`
    justify-content: space-between;
`

const TabsRadio = styled(Radio).withConfig<{ isChecked: boolean }>({ shouldForwardProp: p => p !== 'isChecked' })`
    flex-direction: column;
    padding: 4px 0px;
    &:hover .byecode-radio-inner {
        color: var(--color-main);
    }

    .byecode-radio-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: ${({ isChecked }) => `1px solid ${isChecked ? 'var(--color-main)' : 'transparent'}`};
        &:checked ~ .byecode-radio-inner {
            color: var(--color-main);
        }
    }
    .byecode-radio-icon {
        display: none;
    }
    .byecode-radio-inner {
        padding: 0;
        border-radius: 4px;
        background-color: unset;
    }

    .byecode-radio-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        ${({ isChecked }) =>
            isChecked &&
            css`
                color: var(--color-main);
                font-weight: 500;
            `}
    }
`

export const TabsTypeSelector = forwardRef<HTMLDivElement, RadioGroupProps>((props, ref) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    return (
        <TabsRadioGroup ref={ref} {...props}>
            <TabsRadio disabled={disabledWithVersion} isChecked={props.value === TABS_TYPE.LINE} label="线条" value={TABS_TYPE.LINE}>
                <LineTabsSvg />
            </TabsRadio>
            <TabsRadio disabled={disabledWithVersion} isChecked={props.value === TABS_TYPE.ROUND} label="圆角" value={TABS_TYPE.ROUND}>
                <RoundTabsSvg />
            </TabsRadio>
            <TabsRadio
                disabled={disabledWithVersion}
                isChecked={props.value === TABS_TYPE.CARD_SLOT}
                label="卡槽"
                value={TABS_TYPE.CARD_SLOT}
            >
                <CardSlotTabsSvg />
            </TabsRadio>
            <TabsRadio
                disabled={disabledWithVersion}
                isChecked={props.value === TABS_TYPE.SEGMENTED}
                label="胶囊"
                value={TABS_TYPE.SEGMENTED}
            >
                <SegmentedTabsSvg />
            </TabsRadio>
        </TabsRadioGroup>
    )
})
