import { Empty, Flex, IconFont, Menu, singleTextEllipsis } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { timeDistance } from '@lighthouse/shared'
import { useMounted } from '@lighthouse/tools'
import { Grid, Image, ScrollArea, Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useRef, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import * as srv from '@/services'
import type { PageSubmissionItem, PageSubmissionStateEnum } from '@/services/types'

import { QUEUE_STATUS_OPTIONS, searchEngineNameMap } from '../constants'
import { QueueStatusTag } from '../QueueStatusTag'

interface SearchEngineQueueTableProps {
    style?:React.CSSProperties
}

const SCxTitle = styled.div`
    color: var(--color-gray-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 133.333% */
`
const SCxText = styled.div<{ color?: string; align?: string }>`
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    text-align: ${({ align }) => align};
    ${singleTextEllipsis}
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 20px;
`

const SCxGrid = styled(Grid)`
    margin: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-200);
`
const SCxGridCol = styled(Grid.Col)`
    overflow: hidden;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const SCxContent = styled.div`
    padding: 0 40px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 550px;
`
/**
 *@description 子元素不能使用100%，而是用以下方式
 * @link {https://stackoverflow.com/questions/15381172/how-can-i-make-flexbox-children-100-height-of-their-parent}
 */
const SCxList = styled(ScrollArea)`
    /* height: 100%; */
    /* flex: 1; */

    display: flex;
    align-items: stretch;

    .mantine-ScrollArea-viewport {
        height: auto;
    }
`

type State = {
    list: PageSubmissionItem[]
    currentPage: number
    status: null | PageSubmissionStateEnum
    noNexData: boolean
}

const PAGE_SIZE = 100

export const SearchEngineQueueTable: React.FunctionComponent<SearchEngineQueueTableProps> = ({ style }) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState(false)
    const [state, setState] = useImmer<State>({
        list: [],
        currentPage: 1,
        status: null,
        noNexData: false
    })

    const { value: statusMap } = useAsyncRetry(async () => {
        const list = await srv.getSubmissionQueueCount()
        return Object.fromEntries(
            [...list, { value: 'ALL', count: list.reduce((pre, cur) => pre + cur.count, 0) }].map(item => [item.value, item.count])
        )
    }, [])

    const handleFetchList = useCallback(
        async (state: null | PageSubmissionStateEnum) => {
            setLoading(true)
            const list = await srv.getSubmissionQueue({ pageSize: PAGE_SIZE, currentPage: 1, state })
            setLoading(false)
            if (list) {
                setState(draft => {
                    draft.list = list
                })
            }
        },
        [setState]
    )

    const handleLoadMoreData = useCallback(
        async (page: number, state: null | PageSubmissionStateEnum) => {
            setLoading(true)
            if (loading) {
                return
            }
            const list = await srv.getSubmissionQueue({ pageSize: PAGE_SIZE, currentPage: page, state })
            setLoading(false)
            if ((list ?? [])?.length < PAGE_SIZE) {
                setState(draft => {
                    draft.noNexData = true
                })
            }
            if (list) {
                setState(draft => {
                    draft.list = [...draft.list, ...list]
                })
            }
        },
        [loading, setState]
    )

    const handleScrollPositionChange = useCallback(
        (position: { x: number; y: number }) => {
            const { y } = position
            if (!scrollRef.current) {
                return
            }
            const { clientHeight, scrollHeight } = scrollRef.current
            const isBottom = y + clientHeight + 100 > scrollHeight

            if (isBottom && !loading && !state.noNexData) {
                handleLoadMoreData(state.currentPage + 1, state.status)
            }
        },
        [handleLoadMoreData, loading, state.currentPage, state.noNexData, state.status]
    )

    const handleToggleStatus = useCallback(
        (v: string | null) => {
            setState(draft => {
                draft.status = v as PageSubmissionStateEnum | null
                draft.list = []
                draft.noNexData = false
                draft.currentPage = 1
            })
            handleFetchList(v as PageSubmissionStateEnum | null)
        },
        [handleFetchList, setState]
    )

    useMounted(() => {
        handleFetchList(null)
    })
    return (
        <SCxContent style={style}>
            <SCxTitle>页面网址提交队列</SCxTitle>
            <SCxDescribe>每天会将页面链接，按照对应搜索引擎的配额数量提交还给搜索引擎。</SCxDescribe>
            <SCxGrid grow>
                <SCxGridCol span={5} style={{ padding: '8px 0' }}>
                    <Text color="var(--color-gray-500)">页面链接</Text>
                </SCxGridCol>
                <SCxGridCol span={2}>
                    <Text color="var(--color-gray-500)">提交时间</Text>
                </SCxGridCol>
                <SCxGridCol span={2}>
                    <Text color="var(--color-gray-500)">搜索引擎</Text>
                </SCxGridCol>
                <SCxGridCol span={2}>
                    <Menu width={200} closeOnItemClick>
                        <Menu.Target>
                            <Flex justifyContent="flex-end" gap="4px" style={{ cursor: 'pointer' }}>
                                <Text align="right" color="var(--color-gray-500)">
                                    {find(item => item.value === state.status, QUEUE_STATUS_OPTIONS)?.label ?? '全部'}
                                </Text>
                                <IconFont size={16} type="ArrowDownSmall" />
                            </Flex>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {QUEUE_STATUS_OPTIONS.map(item => {
                                const isActive = (item.value === 'ALL' && !state.status) || item.value === state.status
                                return (
                                    <Menu.Item key={item.value}>
                                        <Flex
                                            justifyContent="space-between"
                                            onClick={() => handleToggleStatus(item.value === 'ALL' ? null : item.value)}
                                        >
                                            <Flex gap="8px">
                                                <Text>{item.label}</Text>
                                                <Text color="var(--color-gray-400)">{statusMap?.[item.value] ?? 0}</Text>
                                            </Flex>
                                            {isActive && <IconFont size={16} type="Tick" color="var(--color-theme-7)" />}
                                        </Flex>
                                    </Menu.Item>
                                )
                            })}
                        </Menu.Dropdown>
                    </Menu>
                </SCxGridCol>
            </SCxGrid>

            {state.list.length > 0 && (
                <SCxList onScrollPositionChange={handleScrollPositionChange} ref={scrollRef}>
                    {state.list.map(item => {
                        return (
                            <SCxGrid grow key={item.id}>
                                <SCxGridCol span={5} style={{ padding: '8px 0' }}>
                                    <Flex>
                                        <SCxText color="var(--color-black)">{item.url}</SCxText>
                                    </Flex>
                                </SCxGridCol>
                                <SCxGridCol span={2}>
                                    <SCxText>{timeDistance(item.createdTime)}</SCxText>
                                </SCxGridCol>
                                <SCxGridCol span={2}>
                                    <SCxText>{searchEngineNameMap.get(item.searchEngine)}</SCxText>
                                </SCxGridCol>
                                <SCxGridCol span={2}>
                                    <Flex justifyContent="flex-end">
                                        <QueueStatusTag status={item.state} />
                                    </Flex>
                                </SCxGridCol>
                            </SCxGrid>
                        )
                    })}
                </SCxList>
            )}
            {state.list.length === 0 && !loading && (
                <Empty
                    styles={{
                        root: {
                            flex: 1,
                            display: 'flex',
                            alginItem: 'center',
                            height: '100%'
                        }
                    }}
                    description="没有数据"
                    icon={<Image width={140} height={140} src={getAssetUrl('empty', 'empty_no_record.png')} />}
                />
            )}
        </SCxContent>
    )
}
