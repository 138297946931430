import type { BlockAbstract, LayoutNode } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import mitt from 'mitt'

type Coordinate = {
    x: number
    y: number
}

export type FlowLayoutEvents = {
    beginAdding: { node: FlowLayoutNode; coordinate: Coordinate; rect: DOMRect }
}

export const flowLayoutEmitter = mitt<FlowLayoutEvents>()

export const flowLayoutBeginAdding = (payload: FlowLayoutEvents['beginAdding']) => {
    flowLayoutEmitter.emit('beginAdding', payload)
}
