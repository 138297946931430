import type { ChildComponentProtocol } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

export type LayoutProps = ChildComponentProtocol & {
    aside: React.ReactNode
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #e5e5e5;

    display: flex;
`

const SCxAside = styled.aside`
    height: 100%;
    padding: 10px 2px 0 2px;
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
    z-index: 1;
    flex-shrink: 0;
    box-sizing: border-box;
`

export const Layout: React.FC<LayoutProps> = ({ aside, children }) => {
    return (
        <SCxContainer>
            <SCxAside>{aside}</SCxAside>
            {children}
        </SCxContainer>
    )
}
