import type { HttpResp } from '@/http'
import http from '@/http'

import { JsonContentType } from './constant'
import type { AddIntegrationPayload, AllOtherPlatform, AppAccountType, UpdateIntegrationPayload } from './types'

/**
 * 获取第三方应用集成的所有应用账户
 * @return {*}
 */
export async function getOtherPlatforms(): Promise<AllOtherPlatform> {
    const res = await http.get<unknown, HttpResp<AllOtherPlatform>>('/api/v1/integration/otherPlatforms')
    return res.content
}
/**
 * @description 添加集成账号
 * @param {string} WeComAccount
 * @return {*}  {Promise<string | undefined>}
 */
export async function addIntegration(params: AddIntegrationPayload): Promise<boolean> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/integration/createIntegration', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * @description 删除集成账号
 * @param {string} id
 * @return {*}  {Promise<string | undefined>}
 */
export async function deleteIntegration(id: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<string>>('api/v1/integration/deleteIntegration', {
        params: {
            id
        }
    })
    return res.success
}

/**
 * @description 更新集成账号
 * @param {string} WeComAccount
 * @return {*}  {Promise<string | undefined>}
 */
export async function updateIntegration(params: UpdateIntegrationPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/integration/updateIntegration', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 测试第三方应用集成的所有应用账户
 * @return {*}
 */
export async function getConnectionTest(type: AppAccountType, platformId: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<AllOtherPlatform>>('/api/v1/integration/connectionTest', {
        params: {
            type,
            id: platformId
        }
    })
    return res.success
}

/**
 * 检查微信登陆的相关配置是否配置完成
 * @return {*}
 */
export async function checkWxLoginConfig() {
    const res = await http.get<unknown, HttpResp<boolean>>('/api/v1/integration/checkFollowWechatOfficialConfig')
    return res.content
}
