import { Divider, Flex, Group, SegmentedControl, Text, Tooltip } from '@byecode/ui'
import { DataSourceType, PageType } from '@lighthouse/core'
import {
    findParentFormBlock,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { filter } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageNodesAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { VisibilityFilter } from '../Common/VisibilityFilter'
import type { SettingsTabEnum } from '../constants'
import { SETTINGS_TAB_ENUM, SETTINGS_TABS } from '../constants'
import { BlockSettingIcon } from '../styles'
import { RelativeListConfigure } from './RelativeListConfigure'
import { TableColumnConfigure } from './TableColumnConfigure'

interface SubFormProps {
    blockId: string
}

export const SubFormSetting: React.FunctionComponent<SubFormProps> = ({ blockId }) => {
    const [currentTab, setCurrentTab] = useState<SettingsTabEnum>(SETTINGS_TAB_ENUM.normal)
    const { control } = useFormContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId || '', [])
    )
    const [pageDsId, pageType] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(p => [p?.dsId, p?.type], [])
    )
    const blocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )
    const nodes = useAtomData(
        pageNodesAtom,
        useCallback(s => s?.[pageId] ?? [], [pageId])
    )

    const parentFormPointer = useMemo(() => findParentFormBlock(blockId, blocks)(nodes)?.config.pointer, [blockId, blocks, nodes])

    const subFormDataSourceList = useMemo(
        () =>
            filter(item => {
                return parentFormPointer !== item.id && item.id !== USER_DATASOURCE && item.type !== DataSourceType.aggregateDataSource
            }, dataSourceList),
        [dataSourceList, parentFormPointer]
    )

    return (
        <>
            <Flex grow styles={{ root: { padding: '0 16px' } }} mt={12}>
                <SegmentedControl
                    disabled={false}
                    data={SETTINGS_TABS}
                    fullWidth
                    size="lg"
                    value={currentTab}
                    onChange={v => setCurrentTab(v as SettingsTabEnum)}
                />
            </Flex>
            {currentTab === SETTINGS_TAB_ENUM.normal && (
                <div>
                    <Group4ByecodeUi label="基础配置">
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <div> 数据来源</div>
                            <Controller
                                name="pointer"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <SelectDataSource
                                        placeholder="请选择数据表"
                                        value={value}
                                        onChange={onChange}
                                        // 表单编辑页可以使用用户表
                                        dataSourceList={subFormDataSourceList}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group4ByecodeUi>
                    <Divider />
                    <Group
                        label={
                            <Flex alignItems="center" gap={4}>
                                <Text>关联字段</Text>
                                <Tooltip
                                    title={
                                        <p style={{ width: 400, fontSize: 12, lineHeight: '22px', padding: '4px 16px' }}>
                                            <ul>
                                                <li>
                                                    提交表单时，会将新增主表字段内容写入到绑定的子表字段中，以实现主子表单的数据关联，详见
                                                </li>
                                                <li>主表字段和子表字段均可重复选择</li>
                                                <li>可选择字段类型限制：只可选择文字类型字段</li>
                                            </ul>
                                        </p>
                                    }
                                >
                                    <BlockSettingIcon  color='var(--color-gray-400)' type="Question" />
                                </Tooltip>
                            </Flex>
                        }
                    >
                        <RelativeListConfigure blockId={blockId} />
                    </Group>
                    <Divider />
                    <Group label="表单列">
                        <TableColumnConfigure blockId={blockId} />
                    </Group>
                </div>
            )}
            {currentTab === SETTINGS_TAB_ENUM.visibility && <VisibilityFilter />}
        </>
    )
}
