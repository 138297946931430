import styled from 'styled-components'

export const Uploading = styled.div`
    padding: 24px 0;
    text-align: center;
`

export const LoadingTitle = styled.div`
    color: var(--color-black);
    font-weight: 600;
    font-size: 14px;
    margin: 12px 0;
`

export const LoadingDescribe = styled.div`
    color: var(--color-gray-400);
    font-weight: 600;
    font-size: 14px;
`
