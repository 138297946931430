import { Switch } from '@byecode/ui'
import type { FieldType, TypeInstanceMap, ViewField } from '@lighthouse/core'
import { clone, isEmpty } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useSharedConfigDisabledWithVersion } from '../../contexts'
import { getFieldIcon } from '../../utils'
import { FieldTypeTag } from '../FieldTypeTag'
import { List } from '../List'
import ListItem from './ListItem'

interface ListItemData {
    label: string
    value: string
    visible?: boolean
    icon: string
    type?: FieldType
    innerType?: TypeInstanceMap
}
interface FieldSettingProps {
    children?: React.ReactNode
    title: string
    limitFieldType?: FieldType[]
    value?: string[]
    columns: ViewField[]
    onChange?: (data: string[]) => void
}

const PropertySettingWrapper = styled.div`
    width: 100%;
    padding: 0 8px;
`

const SCxTitle = styled.div`
    color: var(--color-gray-500);
    height: 40px;
    line-height: 40px;
    font-size: var(--font-size-normal);
    padding: 0 8px;
`

const Extra = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`

export const FieldSettingConfigure: React.FC<FieldSettingProps> = ({ title, value = [], limitFieldType, columns, onChange }) => {
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const [initTableProps, initSorts] = useMemo(() => {
        const isSetting = isEmpty(value)
        return columns.reduce<[ListItemData[], string[]]>(
            (acc, column) => {
                const { fieldId, title, type, innerType } = column
                if (limitFieldType && !limitFieldType.includes(type)) {
                    return acc
                }
                const localVisible = isSetting ? true : value.includes(fieldId)
                if (localVisible) {
                    acc[1].push(fieldId)
                }
                const icon = getFieldIcon(fieldId, type, innerType)
                acc[0].push({
                    value: fieldId,
                    visible: localVisible,
                    label: title,
                    icon,
                    type,
                    innerType
                })

                return acc
            },
            [[], []]
        )
    }, [columns, limitFieldType, value])

    const disable = useMemo(() => initTableProps.filter(item => item.visible).length === 1, [initTableProps])

    const handleSwitchChange = useCallback(
        (checked: boolean, id: string) => {
            if (checked) {
                onChange?.([...initSorts, id])
                return
            }
            const index = initSorts.indexOf(id)
            if (index > -1) {
                const newValue = clone(initSorts)
                newValue.splice(index, 1)
                onChange?.(newValue)
            }
        },
        [initSorts, onChange]
    )

    const listStyle: React.CSSProperties = { maxHeight: 'none', overflow: 'visible' }

    const itemRenderer: React.FC<ListItemData> = useCallback(
        item => {
            const { icon, label, value, visible, type, innerType } = item

            return (
                <ListItem
                    icon={icon}
                    title={label}
                    suffix={
                        <Extra>
                            <FieldTypeTag type={type} innerType={innerType} />
                            <Switch
                                disabled={(disable && visible) || disabledWithVersion}
                                checked={visible}
                                onChange={ev => {
                                    handleSwitchChange(ev.target.checked, value)
                                }}
                            />
                        </Extra>
                    }
                />
            )
        },
        [disable, disabledWithVersion, handleSwitchChange]
    )

    return (
        <PropertySettingWrapper>
            <SCxTitle>{title}</SCxTitle>
            <List data={initTableProps} style={listStyle} itemRender={itemRenderer} />
        </PropertySettingWrapper>
    )
}
