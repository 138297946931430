import { Button, IconFont } from '@byecode/ui'
import React, { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import type { MatchConditionProps } from '../MatchCondition'
import { MatchCondition } from '../MatchCondition'

const SCxWrapper = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const SCxButtonWrapper = styled.div`
    margin-bottom: 8px;
`

interface MatchContentProps extends Omit<MatchConditionProps, 'prefixName'> {}

export const MatchContent: React.FC<MatchContentProps> = ({ primaryDsId, useDsIds, joinedDs, dataSourceList, index }) => {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'joinConditions'
    })

    const handleAdd = useCallback(() => {
        append({
            leftFieldId: '',
            operator: '='
        })
    }, [append])

    const handleRemove = useCallback(
        (i: number) => {
            remove(i)
        },
        [remove]
    )
    return (
        <SCxWrapper>
            {fields.map((_, i) => (
                <MatchCondition
                    index={index}
                    useDsIds={useDsIds}
                    key={i}
                    primaryDsId={primaryDsId}
                    joinedDs={joinedDs}
                    dataSourceList={dataSourceList}
                    prefixName={`joinConditions.${i}`}
                    onRemove={() => handleRemove(i)}
                />
            ))}
            <SCxButtonWrapper>
                <Button radius={100} onClick={handleAdd} icon={<IconFont type="Add" fill="var(--color-gray-500)" size={16} />}>
                    添加
                </Button>
            </SCxButtonWrapper>
        </SCxWrapper>
    )
}
