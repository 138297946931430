import type { Environment } from '@lighthouse/core'
import { TitleInContentModal } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components';

import type { EnvFormProps } from './EnvForm';
import { EnvForm } from './EnvForm'


export type EnvModalMode = 'create' | 'update'


interface EnvModalProps extends EnvFormProps {
    open: boolean
    mode: EnvModalMode
}

export const EnvModal: React.FC<EnvModalProps> = ({ open, mode, data, envList, onCreate, onUpdate, onClose }) => {

    const { title, description } = useMemo(() => {
        if (mode === 'create') {
            return { title: '新建数据环境', description: '基于「正式数据」的数据表创建新的数据环境，只会复制字段结构，不会复制记录数据。' }
        }
        return { title: '编辑数据环境', description: '' }
    }, [mode])
    return (
        <TitleInContentModal open={open} width={491} title={title} description={description} onClose={onClose}>
           <EnvForm data={data} envList={envList} onCreate={onCreate} onUpdate={onUpdate} onClose={onClose} />
        </TitleInContentModal>
    )
}
