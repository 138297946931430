import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { BackgroundProtocol, BackgroundType } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { produce } from 'immer'
import React, { useEffect, useMemo, useState } from 'react'
import { useLatest, useMount } from 'react-use'

import { FillPickerCore } from './Core'
import { GradientContainer } from './Gradient'
import type { ImageContainerProps } from './Image'
import { ImageContainer } from './Image'
import type { EditBackgroundProtocol } from './type'
import { padInitialBackground } from './utils'

const FILL_TYPE_DATA = [
    {
        label: '单色',
        value: BACKGROUND_TYPE.color
    },
    {
        label: '渐变',
        value: BACKGROUND_TYPE.gradient
    },
    {
        label: '图片',
        value: BACKGROUND_TYPE.image
    }
]

export interface FillPickerProps extends Omit<ImageContainerProps, 'value' | 'onChange'> {
    defaultValue?: BackgroundProtocol
    value?: BackgroundProtocol
    onChange?: (value: BackgroundProtocol) => void
    enabledBackgroundTypes?: BackgroundType[]
    onClickCreateColor?: () => void
    onClickEditColor?: (id: string) => void
}

export const FillPicker = ({
    defaultValue,
    value: propValue,
    onChange: propOnChange,

    onClickCreateColor,
    onClickEditColor,

    enabledBackgroundTypes = [BACKGROUND_TYPE.color],
    ...rest
}: FillPickerProps) => {
    const [value, onChange] = useUncontrolled<EditBackgroundProtocol>({
        value: propValue && padInitialBackground(propValue),
        onChange: propOnChange,
        defaultValue: defaultValue && padInitialBackground(defaultValue),
        finalValue: padInitialBackground()
    })

    useMount(() => {
        if (
            !propValue ||
            (propValue.type === BACKGROUND_TYPE.color && !propValue.color) ||
            (propValue.type === BACKGROUND_TYPE.gradient && !propValue.gradient) ||
            (propValue.type === BACKGROUND_TYPE.image && !propValue.image) ||
            !defaultValue ||
            (defaultValue.type === BACKGROUND_TYPE.color && !defaultValue.color) ||
            (defaultValue.type === BACKGROUND_TYPE.gradient && !defaultValue.gradient) ||
            (defaultValue.type === BACKGROUND_TYPE.image && !defaultValue.image)
        ) {
            propOnChange?.(padInitialBackground(propValue || defaultValue))
        }
    })

    const types = useMemo(() => FILL_TYPE_DATA.filter(item => enabledBackgroundTypes.includes(item.value)), [enabledBackgroundTypes])
    const isImage = value.type === BACKGROUND_TYPE.image

    return (
        <div>
            {types.length > 1 && (
                <SegmentedControl
                    data={types}
                    value={value.type}
                    onChange={v => {
                        onChange(padInitialBackground({ ...value, type: v as BackgroundType }))
                    }}
                    fullWidth
                    style={{ marginBottom: 8 }}
                />
            )}

            {isImage ? (
                <ImageContainer value={value} onChange={onChange} {...rest} />
            ) : (
                <CoreWithGradient
                    value={value}
                    onChange={onChange}
                    onClickCreateColor={onClickCreateColor}
                    onClickEditColor={onClickEditColor}
                />
            )}
        </div>
    )
}

interface CoreWithGradientProps {
    value: EditBackgroundProtocol
    onChange: (value: EditBackgroundProtocol) => void
    onClickCreateColor?: () => void
    onClickEditColor?: (id: string) => void
}

const CoreWithGradient = ({ value, onChange, ...rest }: CoreWithGradientProps) => {
    const latestValue = useLatest(value)
    const isGradient = value.type === BACKGROUND_TYPE.gradient

    const [selectedGradientStopKey, setSelectedGradientStopKey] = useState(value.gradient?.stops[0].key || '')

    useEffect(() => {
        if (latestValue.current.type === BACKGROUND_TYPE.gradient) {
            setSelectedGradientStopKey(latestValue.current.gradient?.stops[0].key || '')
        }
    }, [value.type, latestValue])

    const hexColorOrCssVariable =
        (isGradient ? value.gradient?.stops.find(item => item.key === selectedGradientStopKey)?.color : value.color) || '#fff'

    return (
        <>
            {isGradient && value.gradient && (
                <GradientContainer
                    value={value.gradient}
                    onChange={v => {
                        onChange({ ...value, gradient: v })
                    }}
                    selectedGradientStopKey={selectedGradientStopKey}
                    onGradientStopKeySelect={k => {
                        // 防止FillPickerCore onChange时，selectedGradientStopKey变了
                        requestAnimationFrame(() => {
                            setSelectedGradientStopKey(k)
                        })
                    }}
                />
            )}
            <FillPickerCore
                value={hexColorOrCssVariable}
                onChange={v => {
                    const newValue =
                        isGradient && value.gradient
                            ? {
                                  gradient: produce(value.gradient, draft => {
                                      const stop = draft.stops.find(item => item.key === selectedGradientStopKey)
                                      if (stop) {
                                          stop.color = v
                                      }
                                  })
                              }
                            : {
                                  color: v
                              }
                    onChange({ ...value, ...newValue })
                }}
                {...rest}
            />
        </>
    )
}
