import { backgroundTransitionOnClick, Divider, IconFont, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const StyledDivider = styled(Divider)`
    border-color: var(--color-gray-200);
    margin: 8px 0;
`

export const MenuList = styled.div<{ maxHeight?: number }>`
    width: 100%;
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'auto')};
    display: flex;
    flex-direction: column;
`

export const SpaceList = styled.div`
    flex: 1;
    overflow-y: auto;
`

export const Header = styled.div``

export const HeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 4px 0 0 16px;
    height: 44px;
`

export const HeaderName = styled.div`
    height: 17px;
    line-height: 17px;
    font-size: var(--font-size-base);
    color: var(--color-black);
    white-space: nowrap;
    margin-bottom: 2px;
`

export const HeaderPhone = styled.div`
    color: var(--color-gray-400);
    font-size: 12px;
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
`

export const ListTitle = styled.div`
    height: 16px;
    line-height: 16px;
    margin-bottom: 6px;
    padding: 8px 0 0 16px;
    font-size: 12px;
    color: var(--color-gray-400);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;
`

export const ListItem = styled.div<{ selected?: boolean }>`
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    font-size: var(--font-size-normal);
    margin-bottom: 4px;
    ${pointer}
    ${backgroundTransitionOnClick};
    background-color: ${properties => properties.selected && 'var(--color-gray-50)'};
`

export const ItemIcon = styled(IconFont)`
    color: var(--color-gray-500);
    font-size: 14px;
    padding: 0 10px 0 20px;
`

export const ListItemAbbrText = styled.div`
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    background: #f5f5f5;
    border: 1px solid var(--color-gray-200);
    border-radius: 20px;
    color: var(--color-black);
    transform: scale(0.5);
    font-size: 24px;
`

export const ListItemInfo = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const ListItemText = styled.div`
    font-weight: 400;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

export const ListItemDes = styled.div`
    color: var(--color-gray-400);
    font-size: 12px;
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
`

export const ListFooter = styled.div`
    height: fit-content;
    margin-bottom: 8px;
`

export const ListFooterItem = styled.div<{ selected?: boolean }>`
    display: flex;
    height: 36px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    font-size: var(--font-size-normal);
    ${pointer}

    background-color: ${properties => properties.selected && 'var(--color-gray-50)'};

    &:hover {
        background: var(--color-gray-100);
    }
`

export const HotKeyIcon = styled.div`
    color: var(--color-gray-400);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: end;
    align-items: center;
`
