import { ButtonHandleEvent } from '@lighthouse/core'
import { Box, Divider, Popover } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { BasicListItem } from '../ListItemMenu'
import * as SC from './styles'

interface Item {
    label: string
    value: string
    icon?: string
    disable?: boolean
    group: string
}

interface SelectProps {
    disabled?: boolean
    data: Item[]
    value: string
    label: string
    target: React.ReactNode
    disableTitle?: boolean
    onChange: (value: string) => void
}

type GroupData = Record<
    string,
    {
        name: string
        data: Item[]
    }
>

const Select: React.FC<SelectProps> = ({ disabled, data, value, label, target, disableTitle = false, onChange }) => {
    const [popoverOpened, setPopoverOpened] = useState(false)

    const groupData = useMemo(() => {
        const groupObj = data.reduce<GroupData>((preValue, curValue) => {
            const isExit = preValue[curValue.group]
            if (isExit) {
                preValue[curValue.group].data.push(curValue)
                return preValue
            }
            preValue[curValue.group] = {
                name: curValue.group,
                data: [curValue]
            }
            return preValue
        }, {})
        return Object.entries(groupObj)
    }, [data])

    const handleChange = useCallback(
        (value: string) => {
            setPopoverOpened(false)
            onChange(value)
        },
        [onChange]
    )

    return (
        <BasicListItem
            position="apart"
            noWrap
            styles={{
                root: {
                    gap: 0
                }
            }}
        >
            <SC.Text>{label}</SC.Text>
            <Popover
                disabled={disabled}
                opened={popoverOpened}
                onChange={opened => setPopoverOpened(opened)}
                withinPortal
                position="bottom"
                shadow="md"
                transition="pop"
                styles={{
                    dropdown: {
                        padding: '12px 0'
                    }
                }}
            >
                <Popover.Target>
                    <div
                        onClick={() => setPopoverOpened(!popoverOpened)}
                        onFocusCapture={() => setPopoverOpened(true)}
                        onBlurCapture={() => setPopoverOpened(false)}
                    >
                        {target}
                    </div>
                </Popover.Target>
                <Popover.Dropdown>
                    <Box>
                        {groupData.map(([key, { name, data }], index) => (
                            <React.Fragment key={key}>
                                <SC.LightText top={index !== 0}>{name}</SC.LightText>
                                <SC.List>
                                    {data.map(({ group, label, icon, disable, value }) => (
                                        <SC.Item key={`${key}-${icon}`} onClick={() => !disable && handleChange(value)} disabled={disable}>
                                            {icon && <SC.ItemIcon type={icon} />}
                                            <SC.ItemText>{label}</SC.ItemText>
                                        </SC.Item>
                                    ))}
                                </SC.List>
                                {index + 1 !== groupData.length && <Divider />}
                            </React.Fragment>
                        ))}
                    </Box>
                </Popover.Dropdown>
            </Popover>
        </BasicListItem>
    )
}

export default Select
