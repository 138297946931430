import type { Option } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import {
    type ApplicationSettingNavbar,
    type ApplicationSettingNavbarButton,
    type NavigationShowMode,
    ApplicationSettingThemeNavBarMode,
    MAX_CONTAINER_WIDTH_SIZE
} from '@lighthouse/core'
import type { RadioItem } from '@lighthouse/shared'

import type { JumpLinkOption } from './NavBarItem'

export const jumpLinkOptions: JumpLinkOption[] = [
    {
        label: '链接',
        value: 1,
        placeholder: '请输入'
    },
    {
        label: '页面',
        value: 2,
        placeholder: '请选择页面'
    }
]

export const initPageList: Option[] = [
    {
        label: '登录',
        value: '/account/login'
    },
    {
        label: '注册',
        value: '/account/register'
    }
]

export const pageOptions: Option[] = [
    {
        label: '页面1',
        value: 'testPage1'
    },
    {
        label: '页面2',
        value: 'testPage2'
    },
    {
        label: '页面3',
        value: 'testPage3'
    }
]

export const jumpButton: RadioItem[] = [
    {
        label: '主按钮',
        value: 1,
        name: 'jumpButton'
    },
    {
        label: '次按钮',
        value: 2,
        name: 'jumpButton'
    }
]

export const navbarModeList: {
    id: number
    label: string
    icon: string
    value: NavigationShowMode
}[] = [
    {
        id: 0,
        label: '水平',
        icon: getAssetUrl('application', 'navbar_mode_horizontal.svg'),
        value: 1
    },
    {
        id: 1,
        label: '垂直',
        icon: getAssetUrl('application', 'navbar_mode_vertical_wide.svg'),
        value: 2
    }
    // {
    //     id: 2,
    //     label: '垂直-较窄',
    //     icon: navbarModeVerticalNarrow,
    //     value: 3
    // }
]

export const baseItem: ApplicationSettingNavbarButton = {
    id: '',
    name: '',
    showType: 'icon-name',
    events: {
        handleEvent: 'openPage',
        triggerEvent: 'click',
        params: ['']
    },
    // 类型兼容处理，后续会去掉 events
    action: {
        type: 'none',
        trigger: 'click',
        data: {
            none: {}
        }
    },
    child: []
}

export const navbar: ApplicationSettingNavbar = {
    isShow: true,
    showMode: 1, // 1 水平 2 垂直-较 3 垂直-较窄
    linkList: {
        list: {
            a: {
                id: 'a',
                parentId: '',
                name: '链接a',
                icon: 'ss',
                showType: 'icon-name', // icon-name | 'name'
                events: {
                    triggerEvent: 'click', // 'hover' | 'click'
                    handleEvent: 'openPage', // 'openPage' | 'openLink' | 'createRecord' | 'updateRecord' | 'deleteRecord'
                    params: ['xxx']
                },

                // 类型兼容处理，后续会去掉 events
                action: {
                    type: 'none',
                    trigger: 'click',
                    data: {
                        none: {}
                    }
                },
                child: ['b']
            },
            b: {
                id: 'b',
                parentId: 'a',
                name: '链接b',
                icon: 'ss',
                showType: 'name',
                events: {
                    triggerEvent: 'click', // 'hover' | 'click'
                    handleEvent: 'openPage', // 'openPage' | 'openLink' | 'createRecord' | 'updateRecord' | 'deleteRecord'
                    params: ['']
                },

                // 类型兼容处理，后续会去掉 events
                action: {
                    type: 'none',
                    trigger: 'click',
                    data: {
                        none: {}
                    }
                },
                child: []
            }
        },
        child: ['a']
    },
    buttonList: [
        {
            id: 'kidrue',
            name: '按钮',
            icon: 'Trash',
            showType: 'icon-name',
            pattern: 1,
            events: {
                triggerEvent: 'click',
                handleEvent: 'openPage',
                params: ['']
            },
            // 类型兼容处理，后续会去掉 events
            action: {
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            }
        }
    ],
    logo: {
        logoType: 0, // 0为默认取应用设置  1为自定义
        imgUrl: {
            square: ''
        },
        jumpType: 1,
        value: 'xxx'
    },
    themeMode: ApplicationSettingThemeNavBarMode.custom,
    width: 0,
    height: 0,
    maxWidth: MAX_CONTAINER_WIDTH_SIZE.unlimited,
    backgroundPadding: 0,
    leftPadding: 0,
    topPadding: 0,
    leftAndRightPadding: 0,
    topAndDownPadding: 0,
    align: 'flex-start'
}
