import type { Options } from 'turndown'
import TurndownService from 'turndown'

export const turndownService = new TurndownService()

turndownService.addRule('strikethrough', {
    filter: ['variable'] as unknown as keyof HTMLElementTagNameMap,
    replacement: (content: string, node: Node, options: Options) => {
        const path = (node as HTMLDivElement).getAttribute('data-path')
        return `$[variable]`
    }
})
