import { Switch } from '@byecode/ui'
import type { ChartValue, FilterBlockAbstract } from '@lighthouse/core'
import { type DataSourceAbstract, FilterMode } from '@lighthouse/core'
import type { VariableSource, VariableTree } from '@lighthouse/shared'
import {
    getAppointField,
    getIsFindUseInFilter,
    getViewColumns,
    ListItem4ByecodeUi,
    useAtomData,
    useFindUseObjectContext,
    ViewFieldFilterConfigure
} from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { FilterControl } from '../../Common/FilterControl'
import { ListItemMenu } from '../../Common/ListItemMenu'
import { addPrefixNameToFormName } from '../utils'

interface SwitchList {
    label: string
    value: string
}
interface BaseDataConfiguratorProps {
    prefixName?: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    // sourcePage?: PageAbstract
    // sourceDataSource?: DataSourceAbstract[]
    // sourceType?: VariableSourceType
    sources: VariableSource[]
    switchList?: SwitchList[]
}

export const BaseDataConfigurator: React.FC<BaseDataConfiguratorProps> = ({
    prefixName,
    dataSource,
    dataSourceList,
    sources,
    switchList = []
}) => {
    const { control, watch, getValues, setValue } = useFormContext()
    const aggregate = watch('aggregate')
    const [_, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const disabledWithVersion = useIsDisabledWithVersion()
    // name="mainAxis"
    const findUseObject = useFindUseObjectContext()
    const ruleFilterFormName = addPrefixNameToFormName('ruleFilter', prefixName)
    const ruleFilter = watch(ruleFilterFormName)
    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId], [pageId])
    )
    const {
        id: dsId,
        schema,
        viewOptions: { tableProps }
    } = dataSource

    const columns = getViewColumns({
        tableProps,
        schema
    })

    const isHighLight = useMemo(
        () =>
            getIsFindUseInFilter({
                findUseObject,
                currentDsId: dsId,
                data: ruleFilter
            }),
        [dsId, findUseObject, ruleFilter]
    )

    const primaryField = useMemo(() => {
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    // 筛选器blocks
    const filterBlocks = useMemo(() => {
        if (!pageBlocks) {
            return []
        }
        return pageBlocks.filter(block => block.type === 'filter') as FilterBlockAbstract[]
    }, [pageBlocks])

    useUpdateEffect(() => {
        if (!aggregate) {
            const mainAxis: ChartValue[] | undefined = getValues('mainAxis')
            const secondaryAxis: ChartValue[] | undefined = getValues('secondaryAxis')
            if (mainAxis) {
                const newMainAxis = mainAxis.filter(item => schema?.[item.fieldId].innerType === 'NUMBER')
                setValue('mainAxis', newMainAxis)
            }
            if (secondaryAxis) {
                const newSecondaryAxis = secondaryAxis.filter(item => schema?.[item.fieldId].innerType === 'NUMBER')
                setValue('secondaryAxis', newSecondaryAxis)
            }
        }
    }, [aggregate])

    return (
        <>
            {switchList.map(({ value, label }, index) => {
                return (
                    <ListItem4ByecodeUi key={value} enablePadding justifyContent="space-between" alignItems="center">
                        <Text>{label}</Text>
                        <Controller
                            control={control}
                            name={addPrefixNameToFormName(value, prefixName)}
                            render={({ field }) => <Switch checked={field.value} onChange={field.onChange} />}
                        />
                    </ListItem4ByecodeUi>
                )
            })}
            <Controller
                name={ruleFilterFormName}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const filter = value
                    const filtersLength = filter?.expression?.conditions?.length || 0
                    return (
                        <ListItemMenu
                            width="auto"
                            highlighting={isHighLight}
                            compact
                            label="筛选数据"
                            positionDependencies={[filter]}
                            value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                            popupContent={
                                <ViewFieldFilterConfigure
                                    dataSource={dataSource}
                                    dataSourceList={dataSourceList}
                                    sources={sources}
                                    disabled={disabledWithVersion}
                                    // filterBlocks={filterBlocks}
                                    // enableFilterVariable
                                    paramsMode={FilterMode.CUSTOM}
                                    columns={columns}
                                    primaryField={primaryField}
                                    filter={filter}
                                    onFilter={onChange}
                                />
                            }
                        />
                    )
                }}
            />
            <FilterControl
                compact
                schema={schema}
                columns={columns}
                enableHover
                enablePadding
                filterBlocks={filterBlocks}
                disableIcon
                // value={field.value}
                // onChange={field.onChange}
            />
        </>
    )
}
