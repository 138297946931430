import { passwordRegex } from '@lighthouse/shared'

import type { Field } from './types'

export const userSchema: Record<string, Field> = {
    name: {
        name: 'username',
        label: '你的昵称',
        config: {
            type: 'text',
            required: true
        }
    },
    password: {
        name: 'password',
        label: '登录密码',
        config: {
            type: 'password',
            required: true,
            errorTip: '8～20位，需要有英文字母和数字组成',
            rules: {
                required: '请输入新密码',
                pattern: {
                    value: passwordRegex,
                    message: '请输入8-20位,且是字母、数字/符号的组合' // JS only: <p>error message</p> TS only support string
                },
                minLength: {
                    value: 8,
                    message: '请输入8-20位,且是字母、数字/符号的组合' // JS only: <p>error message</p> TS only support string
                },
                maxLength: {
                    value: 20,
                    message: '请输入8-20位,且是字母、数字/符号的组合' // JS only: <p>error message</p> TS only support string
                }
            }
        }
    },
    space: {
        name: 'spaceName',
        label: '空间名称',
        config: {
            type: 'text',
            required: true
        }
    },
    avatar: {
        name: 'spaceIcon',
        label: '空间头像（选填）',
        config: {
            type: 'img'
        }
    }
}

export const formStepMeta: {
    stepTitle: string
    stepDescription?: string
    fields: (keyof typeof userSchema)[]
}[] = [
    {
        stepTitle: '完善账号信息',
        stepDescription: '首次注册需完善信息',
        fields: ['name', 'password']
    },
    {
        stepTitle: '创建空间',
        stepDescription: '为自己/团队/公司创建一个空间，一个空间中可创建多个应用',
        fields: ['space', 'avatar']
    },
    {
        stepTitle: '进入空间',
        fields: []
    }
]
