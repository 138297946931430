import { Loading } from '@byecode/ui'
import type { SchemaProtocol, TableColumns, TablePropsProtocol } from '@lighthouse/core'
import { Skeleton } from '@mantine/core'
import { clone, find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export interface TablePlaceHolderProps {
    tableProps: TableColumns
    schema: SchemaProtocol['schema']
}

const SCxDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 39px;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-gray-200);
`

const SCxPlaceHolder = styled.div<{ width: number }>`
    height: 100%;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => `${width}px`};
`

export const TablePlaceHolder: React.FC<TablePlaceHolderProps> = ({ tableProps, schema }) => {
    const { initTableProps = [] } = useMemo(() => {
        const initTableProps: TablePropsProtocol['tableProps'] = clone(tableProps)
        Object.entries(schema).forEach(([id]) => {
            const tableItem = find(item => item.id === id, initTableProps)
            const fieldData = schema[id]
            if (!tableItem && fieldData) {
                initTableProps.push({ id, width: 160, visible: true })
            }
        })
        return { initTableProps }
    }, [schema, tableProps])

    return (
        <SCxDiv>
            <SCxPlaceHolder width={59}>
                <Loading outlined />
            </SCxPlaceHolder>
        </SCxDiv>
    )
}
