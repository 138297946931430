import type { ApplicationAbstract, ApplicationVersionBranch, ApplicationWebsiteSetting, RichTextContentProtocol, SmsCodeProtocol } from '@lighthouse/core'

import type { HttpResp } from '@/http'
import http from '@/http'

import type { ActivityListResult } from './types'

/**
 * 发送验证码
 * @param {SmsCodeProtocol} payload
 * @returns
 */
export async function sendAuthCode(payload: SmsCodeProtocol): Promise<boolean> {
    const res = await http.get<unknown, HttpResp<boolean>>('api/auth/v1/sms/getSmsCode', { params: payload })
    return res.success
}

/**
 * 获取验证码
 * @param payload
 * @returns
 */
export async function getSmsCode(payload: SmsCodeProtocol): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/auth/v1/sms/getSmsCode', payload)
    return res.content
}

type VersionNotification = { title?: string; description?: RichTextContentProtocol; files?: string[] }
interface VersionNotificationRes {
    notificationId: string
    items: VersionNotification[]
}
/**
 * 获取版本更新通知
 * @returns
 */
export async function getVersionNotification(): Promise<VersionNotificationRes> {
    const res = await http.get<unknown, HttpResp<VersionNotificationRes>>('api/v1/operation/getLatestUpdates')
    return res.content
}

// 获取应用数据（外部）


export async function getAppOfOutside(appId: string): Promise<ApplicationAbstract> {
    const resp = await http.get<
        unknown,
        HttpResp<
            Omit<ApplicationAbstract, 'version'> & {
                version: ApplicationVersionBranch & {
                    config: Pick<ApplicationWebsiteSetting, 'authentication' | 'navbar' | 'language' | 'theme'>
                }
            }
        >
    >('api/auth/v1/getApp', { params: {
        appId
    } })

    const { version, config, ...rest } = resp.content
    const { config: versionConfig, ...resetVersion } = version

    return {
        ...rest,
        config: { ...config, ...versionConfig },
        version: resetVersion
    } as ApplicationAbstract
}

// 是否登录（外部）


export async function hasLoggedIn(): Promise<boolean> {
    try {
        const res = await http.get<unknown, HttpResp<boolean>>('api/auth/v1/hasLoggedIn')
        return res.content
    } catch {
        return false
    }
}


/** 活动通知 */
export async function getActivityList() {
    const res = await http.get<unknown, HttpResp<ActivityListResult>>('/api/auth/v1/getActivityList')
    return res.content
}
