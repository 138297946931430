import React from 'react'

import { DataSourceAggregator } from '@/containers/DataSourceAggregator'

export const aggregateRoute = {
    path: 'aggregate',
    children: [
        {
            index: true,
            element: <DataSourceAggregator />
        },
        {
            path: ':dataSourceId',
            element: <DataSourceAggregator />
        }
    ]
}
