import { Button, IconFont, Toast } from '@byecode/ui'
import type { FieldADTValue } from '@lighthouse/core'
import { fieldConvertValue, getIsAppointField, SYSTEM_FIELD } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import copy from 'copy-to-clipboard'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useToggle } from 'react-use'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList, useRecordList } from '@/hooks/useDataSource'

interface ExportTemplateCheckerProps {
    dsId: string
}

const SCxCodeCheckerButton = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-normal);
    color: var(--color-main);
    cursor: pointer;
`

const SCxTipWrapper = styled.div`
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
`

const SCxTip = styled.div`
    position: relative;
    font-size: var(--font-size-normal);
    color: var(--color-gray-600);
    padding: 4px 10px;
`

const SCxModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 24px;
`

const SCxTitle = styled.div`
    font-size: 20px;
    font-weight: var(--font-weight-little-bold);
`

const SCxModalBody = styled.div`
    height: 100%;
    background-color: var(--color-gray-50);
    overflow: hidden;
`

const SCxModalBodyContent = styled.div`
    width: 80%;
    height: calc(100% - 96px);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px auto;
    padding: 0 20px 20px;
    border-radius: 16px;
    border: 1px solid var(--color-gray-100);
    background-color: var(--color-white);
`

const SCxHeading1 = styled.div`
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    margin: 30px 0;
`

const SCxHeading2 = styled.div`
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    margin: 16px 0;
`

const SCxFieldPreviewer = styled.div``

const SCxFieldItemHeader = styled.div`
    display: flex;
    color: var(--color-gray-600);
    font-size: var(--font-size-normal);
    margin-bottom: 18px;
`

const SCxFieldItem = styled.div`
    display: flex;
    color: var(--color-gray-900);
    font-size: var(--font-size-normal);
    padding: 8px 0;
    margin-bottom: 8px;
`

const SCxFieldItemName = styled.div`
    flex: 1;
`

const SCxFieldItemValue = styled.div`
    flex: 1;
`

const SCxFieldItemCode = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`

const ExportTemplateChecker: React.FC<ExportTemplateCheckerProps> = ({ dsId }) => {
    const [adderOpen, toggleAdder] = useToggle(false)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dsId)
    const records = useRecordList()
    const firstRecord = useMemo(() => {
        return find(record => record.dsId === dsId && record.appId === appId, records)
    }, [appId, dsId, records])

    const { systemFields, commonFields } = useMemo(() => {
        if (!dataSource) {
            return { systemFields: [], commonFields: [] }
        }
        const schemaArr = Object.values(dataSource.schema)
        const systemFields = []
        const commonFields = []

        for (const field of schemaArr) {
            const { id, name } = field
            const fieldValue = firstRecord?.content?.[id] ?? ''
            const convertedValue = fieldConvertValue({ ...field, ...fieldValue } as FieldADTValue) || '-'

            if (getIsAppointField(id, SYSTEM_FIELD)) {
                systemFields.push({
                    id,
                    name,
                    value: convertedValue
                })
            } else {
                commonFields.push({
                    id,
                    name,
                    value: convertedValue
                })
            }
        }

        return { systemFields, commonFields }
    }, [dataSource, firstRecord?.content])

    const handleCopy = useCallback((code: string) => {
        copy(code)
        Toast.success('复制成功')
    }, [])

    return (
        <>
            <SCxCodeCheckerButton onClick={toggleAdder}>查看「数据代码对照表」</SCxCodeCheckerButton>
            <Modal
                centered
                fullScreen
                opened={adderOpen}
                closeOnClickOutside={false}
                overlayOpacity={0.8}
                overlayBlur={3}
                radius={8}
                size={600}
                padding={0}
                styles={{
                    modal: { overflow: 'hidden' },
                    header: { display: 'none' },
                    body: { height: '100%' }
                }}
                onClose={toggleAdder}
            >
                <>
                    <SCxModalHeader>
                        <SCxTitle>数据代码对照表</SCxTitle>
                        <Button type="text" icon={<IconFont type="Close" />} onClick={toggleAdder} />
                    </SCxModalHeader>
                    <SCxModalBody>
                        <SCxModalBodyContent>
                            <SCxHeading1>字段</SCxHeading1>
                            <SCxTipWrapper>
                                <SCxTip>字段示例值来自数据表：「{dataSource?.name}」 中的第一行数据</SCxTip>
                            </SCxTipWrapper>
                            <SCxHeading2>系统字段</SCxHeading2>
                            <SCxFieldPreviewer>
                                <SCxFieldItemHeader>
                                    <SCxFieldItemName>字段名称</SCxFieldItemName>
                                    <SCxFieldItemValue>示例值</SCxFieldItemValue>
                                    <SCxFieldItemCode>字段代码</SCxFieldItemCode>
                                </SCxFieldItemHeader>
                                {systemFields.map(field => {
                                    const code = `#{${field.id}}`
                                    return (
                                        <SCxFieldItem key={code}>
                                            <SCxFieldItemName>{field.name}</SCxFieldItemName>
                                            <SCxFieldItemValue>{field.value}</SCxFieldItemValue>
                                            <SCxFieldItemCode>
                                                {code}
                                                <Button
                                                    type="text"
                                                    size="sm"
                                                    style={{ marginLeft: 6 }}
                                                    icon={<IconFont type="Duplicate" />}
                                                    onClick={() => handleCopy(code)}
                                                />
                                            </SCxFieldItemCode>
                                        </SCxFieldItem>
                                    )
                                })}
                            </SCxFieldPreviewer>

                            <SCxHeading2>普通字段</SCxHeading2>
                            <SCxFieldPreviewer>
                                <SCxFieldItemHeader>
                                    <SCxFieldItemName>字段名称</SCxFieldItemName>
                                    <SCxFieldItemValue>示例值</SCxFieldItemValue>
                                    <SCxFieldItemCode>字段代码</SCxFieldItemCode>
                                </SCxFieldItemHeader>
                                {commonFields.map(field => {
                                    const code = `#{${field.id}}`
                                    return (
                                        <SCxFieldItem key={code}>
                                            <SCxFieldItemName>{field.name}</SCxFieldItemName>
                                            <SCxFieldItemValue>{field.value}</SCxFieldItemValue>
                                            <SCxFieldItemCode>
                                                {code}
                                                <Button
                                                    type="text"
                                                    size="sm"
                                                    style={{ marginLeft: 6 }}
                                                    icon={<IconFont type="Duplicate" />}
                                                    onClick={() => handleCopy(code)}
                                                />
                                            </SCxFieldItemCode>
                                        </SCxFieldItem>
                                    )
                                })}
                            </SCxFieldPreviewer>
                        </SCxModalBodyContent>
                    </SCxModalBody>
                </>
            </Modal>
        </>
    )
}

export default ExportTemplateChecker
