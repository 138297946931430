import React from 'react'
import type { RouteObject } from 'react-router-dom'

import http from '@/http'
import Page from '@/views/Byecode/Application/[id]/Page'

export const pageRoute: RouteObject = {
    path: 'page'
    // element: <Page />,
    // loader: ({ params: { pageId = '' } }) => {
    //     // 添加 pageId 请求头信息
    //     http.defaults.headers.common['pageId'] = pageId
    //     return null
    // }
}
