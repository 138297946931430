import { backgroundTransitionOnClick, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 36px;
    padding: 0 12px;
    cursor: pointer;
    user-select: none;
    &.disabled {
        cursor: not-allowed;
    }


    ${backgroundTransitionOnClick}
`

export const InfoWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

export const InfoIcon = styled(IconFont)`
    font-size: var(--font-size-base);
    margin-right: 8px;
    color: var(--color-gray-500);
`

export const InfoText = styled.div`
    color: var(--color-black);
`

export const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`
