import { IconFont, SegmentedControl, Tooltip } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const iconData = [
    {
        label: (
            <div>
                <IconFont type="Add" size={16} />
            </div>
        ),
        value: 'man'
    },
    {
        label: (
            <div>
                <IconFont type="User" size={16} />
            </div>
        ),
        value: 'woman'
    },
    {
        label: (
            <Tooltip title="未知" placement="bottom">
                <IconFont type="User" size={16} />
            </Tooltip>
        ),
        value: 'unknown'
    }
]

export const Size: React.FC = () => {
    return (
        <Unit title="大小">
            <SegmentedControl size="xs" data={['man', 'woman', 'unknown']} />
            <SegmentedControl size="sm" data={['man', 'woman', 'unknown']} />
            <SegmentedControl data={['man', 'woman', 'unknown']} />
            <SegmentedControl size="lg" data={['man', 'woman', 'unknown']} />
        </Unit>
    )
}
