import { Button, Message } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Button
                type="primary"
                onClick={() =>
                    Message.success({
                        title: '提交成功',
                        description: '提交成功和史蒂夫了是多久冷风机'
                    })
                }
            >
                成功啦
            </Button>
            <Button
                onClick={() =>
                    Message.error({
                        title: '提交失败',
                        description: '是砥砺奋进上课啦大家罚款了'
                    })
                }
            >
                失败了
            </Button>
        </Unit>
    )
}
