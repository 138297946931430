import { IconFont, Input, Select } from '@byecode/ui'
import styled from 'styled-components'

import { FieldSelect } from '../FieldSelect'

export const BlockSettingContainer = styled.div`
    // 修复按钮组拖拽按钮到dom外时被隐藏
    /* overflow: hidden; */
`

export const BlockSettingHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    /* background-color: var(--color-main); */
`

export const BlockSettingHeaderTitle = styled.div`
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    /* color: var(--color-white); */
`
export const BlockSettingIcon = styled(IconFont)`
    font-size: var(--font-size-base);
`

export const BlockSettingCloseIcon = styled(IconFont)``

export const BlockSettingContentContainer = styled.div`
    font-size: 14px;
`
export const BlockSettingsBox = styled.div`
    padding: 12px 0;
`

export const CommonFormItemContainer = styled.div<{ gutter?: boolean }>`
    ${p => p.gutter && 'padding: 2px 12px'}
`

export const BlockSettingsInput: typeof Input = styled(Input)<{ width?: number }>`
    flex: 1;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    flex: none;
    .mantine-Input-input {
        min-height: 32px;
        height: 32px;
        line-height: 30px;
    }
`

export const BlockSettingsSelect: typeof Select = styled(Select)``

export const PropertyAdder = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 8px 0;
    color: ${({ color }) => color || 'var(--color-main)'};
    font-size: var(--font-size-normal);
    white-space: nowrap;
    cursor: pointer;
`
