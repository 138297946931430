import { IconFont, Popover } from '@byecode/ui'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'

export interface MorePopoverProps {
    disabled?: boolean
    onDuplicate?: () => void
    onDelete?: () => void
}

export const MorePopover: React.FC<MorePopoverProps> = ({ disabled, onDuplicate, onDelete }) => {
    const [open, setOpen] = useState(false)

    const handleDuplicate = useCallback(() => {
        if (disabled) {
            return
        }
        onDuplicate?.()
        setOpen(false)
    }, [disabled, onDuplicate])

    const handleDelete = useCallback(() => {
        if (disabled) {
            return
        }
        onDelete?.()
        setOpen(false)
    }, [disabled, onDelete])

    return (
        <SC.MorePopoverWrapper>
            <Popover width="auto" opened={open} onChange={setOpen} withinPortal>
                <Popover.Target>
                    <SC.IconWrapper>
                        <IconFont type="DotsThree" />
                    </SC.IconWrapper>
                </Popover.Target>
                <Popover.Dropdown>
                    <SC.List>
                        <SC.Item disabled={disabled} onClick={handleDuplicate}>
                            复制
                        </SC.Item>
                        <SC.Item disabled={disabled} onClick={handleDelete}>
                            删除
                        </SC.Item>
                    </SC.List>
                </Popover.Dropdown>
            </Popover>
        </SC.MorePopoverWrapper>
    )
}
