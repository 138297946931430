import { en, zhCN, zhTW } from '@lighthouse/shared'
import type { Resource } from 'i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const { VITE_SENTRY_ENVIRONMENT } = import.meta.env
// @TODO: 暂时处理
const isLocalDev = false

const resources: Resource = {
    en: {
        translation: en
    },
    'zh-CN': {
        translation: zhCN
    },
    'zh-TW': {
        translation: zhTW
    }
}

i18n.use(initReactI18next).init({
    resources,
    // 默认语言  zh/en  中文/英文
    lng: 'zh-CN',
    interpolation: {
        escapeValue: false
    },
    debug: isLocalDev
})
