import { Button, flex, IconFont } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { Field, RichTextContentProtocol } from '@lighthouse/core'
import { type PageAbstract, ActionItemPattern } from '@lighthouse/core'
import {
    generateText,
    getIsFindUseInRichText,
    getPageDataOptions,
    getUserDatasourceOptions,
    getViewOptions,
    ListItem4ByecodeUi,
    NodeFieldPreview,
    TagIcon,
    useAtomData,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Collapse, Divider, Text } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { userDataSourceAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

import { ColorPickerControl } from '../Common/ColorPickerControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import { IconControl } from '../Common/IconControl'
import * as CM from '../styles'
import { btnTypeList, BUTTON_PATTERN_CONTROL, FIT_TYPE_CONTROL, ICON_POSITION_CONTROL } from './constant'
import * as SC from './styles'

export const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

const SCxListItemBaseInfo = styled.div`
    gap: 0;
    padding: 0 16px;
`
const SCxListItemAction = styled.div`
    padding: 0 8px;
`

interface ButtonSettingProps {
    opened: boolean
    index?: number
    prefixName?: string
    listKeyName?: string
    disabled?: boolean
    allPages?: PageAbstract[]
    themeColor: string
    hideConfigs?: {
        fillWay?: boolean
    }
    isViewOption?: boolean
    onChangeOpen: (opened: boolean) => void
    onDelete: (id: string) => void
}

const filterField = (field: Field) => (field.innerType === 'NUMBER' || field.innerType === 'TEXT') && field.type !== 'notes'

export const ButtonSettingItem: React.FunctionComponent<ButtonSettingProps> = ({
    opened,
    allPages,
    index,
    prefixName,
    listKeyName = 'list',
    hideConfigs,
    isViewOption,
    disabled,
    onChangeOpen,
    onDelete
}) => {
    const { control, watch } = useFormContext()
    const { name, icon, showType, iconPosition, fillWay, id, backgroundColor, color, borderColor, events, action, pattern } = watch(
        `${prefixName}${listKeyName}.${index}`
    )

    const [pointer, viewType] = useWatch({
        control,
        name: ['pointer', 'viewType']
    })
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pointerDataSource = useDataSource(appId, envId, pointer)
    const { customViewOption } = useVariableCustomViewOption()
    const disabledWithVersion = useIsDisabledWithVersion()

    const findUseObject = useFindUseObjectContext()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const userDataSource = useAtomData(userDataSourceAtom)
    const {
        prev,
        curr: { recordId, variableSourceType, datasource }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])

    const userOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filterField
        })
    }, [userDataSource])

    const viewOption = useMemo(() => {
        if (isViewOption && pointerDataSource) {
            return getViewOptions({
                dataSource: pointerDataSource,
                viewType,
                validateFieldType: filterField
            })
        }
        return customViewOption
    }, [customViewOption, isViewOption, pointerDataSource, viewType])

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id,
        disabled: disabledWithVersion
    })

    const renderLabel = useVariableValueRender(prev.recordId, recordId)
    const handleRenderTitle = useCallback(
        (v: RichTextContentProtocol) => {
            return generateText(v, { variable: { renderLabel: v => renderLabel(v.attrs.value, {}) } })
        },
        [renderLabel]
    )

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: active?.id === id ? 1 : 0
        }),
        [active?.id, id, transform, transition]
    )

    return (
        <SC.ButtonContainer {...attributes} style={style} ref={setNodeRef}>
            <SC.Header>
                <SC.LeftFill>
                    <SC.Handle {...listeners} style={{ cursor: disabledWithVersion ? 'not-allowed' : 'grab' }}>
                        <IconFont size={16} type="DotsSix" color="var(--color-gray-500)" />
                    </SC.Handle>
                    <SC.Title> {handleRenderTitle(name)}</SC.Title>
                </SC.LeftFill>
                <SCxRightFill style={{ width: 'auto' }}>
                    <TagIcon icon="Trash" iconColor="var(--color-gray-500)" onClick={() => onDelete?.(id)} />
                    <IconFont
                        size={16}
                        color="var(--color-gray-500)"
                        type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                        onClick={() => onChangeOpen(!opened)}
                    />
                </SCxRightFill>
            </SC.Header>
            <Collapse in={opened}>
                <SC.Content>
                    <SCxListItemBaseInfo>
                        <ListItem4ByecodeUi
                            alignItems="center"
                            justifyContent="space-between"
                            compact
                            styles={{
                                root: {
                                    gap: 0
                                }
                            }}
                        >
                            <Text color="var(--color-gray-600)">标题</Text>
                            <SC.RightFill>
                                <Controller
                                    control={control}
                                    name={`${prefixName}${listKeyName}.${index}.name`}
                                    render={({ field }) => {
                                        const isHighLight = getIsFindUseInRichText({
                                            doc: field.value,
                                            findUseObject,
                                            currentDsId: datasource?.id
                                        })
                                        return (
                                            <NodeFieldPreview
                                                disabled={disabledWithVersion}
                                                highlighting={isHighLight}
                                                style={{ width: 180 }}
                                                autoHeight
                                                placeholder="输入按钮名称"
                                                options={options}
                                                userOption={userOption}
                                                viewOption={viewOption}
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                            </SC.RightFill>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi alignItems="center" compact justifyContent="space-between">
                            <Text>按钮元素</Text>
                            <SC.RightFill>
                                <Controller
                                    name={`${prefixName}${listKeyName}.${index}.showType`}
                                    control={control}
                                    defaultValue={showType}
                                    render={({ field }) => (
                                        <CM.BlockSettingsSelect
                                            // withinPortal
                                            searchable={false}
                                            // rightSection={<IconFont type="ArrowDownSmall" />}
                                            name="pointer"
                                            defaultValue="icon-name"
                                            value={field.value}
                                            options={btnTypeList}
                                            style={{
                                                width: field.value === 'name' ? 180 : 140
                                            }}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {showType !== 'name' && (
                                    <Controller
                                        name={`${prefixName}${listKeyName}.${index}.icon`}
                                        control={control}
                                        defaultValue={icon}
                                        render={({ field }) => (
                                            <IconControl value={field.value} onChange={field.onChange} clearable={false} />
                                        )}
                                    />
                                )}
                            </SC.RightFill>
                        </ListItem4ByecodeUi>

                        {showType !== 'name' && (
                            <Controller
                                name={`${prefixName}${listKeyName}.${index}.iconPosition`}
                                control={control}
                                defaultValue={iconPosition}
                                render={({ field }) => (
                                    <FormSegmentedControl
                                        label="图标位置"
                                        data={ICON_POSITION_CONTROL}
                                        value={field.value}
                                        compact
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        )}
                        {!hideConfigs?.fillWay && (
                            <Controller
                                name={`${prefixName}${listKeyName}.${index}.fillWay`}
                                control={control}
                                defaultValue={fillWay}
                                render={({ field }) => (
                                    <FormSegmentedControl
                                        label="宽度规则"
                                        data={FIT_TYPE_CONTROL}
                                        value={field.value}
                                        compact
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        )}
                        <Controller
                            name={`${prefixName}${listKeyName}.${index}.pattern`}
                            control={control}
                            defaultValue={pattern}
                            render={({ field }) => (
                                <FormSegmentedControl
                                    label="样式"
                                    compact
                                    data={BUTTON_PATTERN_CONTROL}
                                    value={field.value}
                                    size="sm"
                                    onChange={field.onChange}
                                />
                            )}
                        />

                        {pattern === ActionItemPattern.custom && (
                            <>
                                <Controller
                                    name={`${prefixName}${listKeyName}.${index}.backgroundColor`}
                                    control={control}
                                    defaultValue={backgroundColor}
                                    render={({ field }) => (
                                        <ColorPickerControl
                                            label="背景色"
                                            style={{
                                                borderColor: 'transparent'
                                            }}
                                            width={24}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${prefixName}${listKeyName}.${index}.color`}
                                    control={control}
                                    defaultValue={color}
                                    render={({ field }) => (
                                        <ColorPickerControl
                                            label="文字色"
                                            style={{
                                                borderColor: 'transparent'
                                            }}
                                            width={24}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${prefixName}${listKeyName}.${index}.borderColor`}
                                    control={control}
                                    defaultValue={borderColor}
                                    render={({ field }) => (
                                        <ColorPickerControl
                                            label="描边色"
                                            style={{
                                                borderColor: 'transparent'
                                            }}
                                            width={24}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </>
                        )}
                        <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    </SCxListItemBaseInfo>
                    <SCxListItemAction>
                        <ActionAdder
                            index={`${index}`}
                            prefixName={prefixName}
                            listKeyName={listKeyName}
                            title={handleRenderTitle(name)}
                            allPages={allPages}
                            events={events}
                            action={action}
                            enableViewVariable={isViewOption}
                        />
                    </SCxListItemAction>
                </SC.Content>
            </Collapse>
        </SC.ButtonContainer>
    )
}
