import { IconFont, Loading, Modal, Toast } from '@byecode/ui'
import { ScanStatus, useAtomAction } from '@lighthouse/shared'
import QRCode from 'qrcode.react'
import React, { useCallback, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { fetchUserAtom } from '@/atoms/auth/action'
import { useUser } from '@/hooks/useUser'
import * as srv from '@/services'

import { Item } from '../Item'
import * as SC from './styles'

export interface PlatformLoginStateType {
    isOpen: boolean
    qrcodeUrl: string
    mark: string
}

export const PlatformLogin: React.FC = () => {
    const user = useUser()
    const { run: fetchUser } = useAtomAction(fetchUserAtom)
    const [state, setState] = useImmer<PlatformLoginStateType>({
        isOpen: false,
        qrcodeUrl: '',
        mark: ''
    })

    const { isOpen, qrcodeUrl, mark } = state

    // 打开/关闭弹框
    const togglePlatformSetting = useCallback(
        (isOpen: boolean) => {
            setState(draft => {
                draft.isOpen = isOpen
            })
        },
        [setState]
    )

    const getQrcodeUrl = useCallback(async () => {
        const res = await srv.getLoginQR()
        if (res) {
            setState(draft => {
                draft.mark = res.mark
                draft.qrcodeUrl = res.url
            })
        }
    }, [setState])

    const handleBindWechat = useCallback(
        async (id: string) => {
            const isBind = await srv.bindWechat(id)
            if (isBind) {
                fetchUser()
            }
        },
        [fetchUser]
    )

    const handleUnBind = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确定解除绑定',
            content: '解绑后，使用微信无法登录系统',
            okStatus: 'error',
            okText: '解除绑定'
        })
        if (isConfirm) {
            const isUnbind = await srv.removeBindWechat()
            if (isUnbind) {
                fetchUser()
            }
        }
    }, [fetchUser])

    const handleGetLoginStatus = useCallback(
        async (id: string) => {
            const status = await srv.getLoginPoll(id)
            switch (status) {
                case ScanStatus.QR_EXPIRED: {
                    getQrcodeUrl()

                    return
                }
                case ScanStatus.LOGIN: {
                    Toast.error('绑定失败，微信已绑定其他Byecode行号')
                    return
                }

                case ScanStatus.REGISTER: {
                    Toast.success('绑定成功')
                    handleBindWechat(mark)
                    togglePlatformSetting(false)
                    return
                }

                default: {
                    return false
                }
            }
        },
        [getQrcodeUrl, handleBindWechat, mark, togglePlatformSetting]
    )

    useUpdateEffect(() => {
        if (isOpen) {
            getQrcodeUrl()
        }
    }, [isOpen])

    useUpdateEffect(() => {
        let timer: NodeJS.Timer | undefined
        if (mark && isOpen) {
            timer = setInterval(() => handleGetLoginStatus(mark), 3000)
        }
        return () => {
            if (timer) {
                return clearInterval(timer)
            }
        }
    }, [mark, isOpen])

    const wechatButton = useMemo(() => {
        if (!user.isBindWechat) {
            return (
                <SC.ItemButton
                    onClick={() => {
                        togglePlatformSetting(true)
                    }}
                >
                    前去绑定
                </SC.ItemButton>
            )
        }
        return (
            <SC.ItemButton onClick={handleUnBind} danger>
                解除绑定
            </SC.ItemButton>
        )
    }, [handleUnBind, togglePlatformSetting, user.isBindWechat])

    return (
        <>
            <SC.List>
                <Item
                    title="微信"
                    icon="WeChatLogo"
                    iconColor="var(--color-green-500)"
                    description={user.isBindWechat ? '已绑定' : '绑定后，可使用微信直接登录系统'}
                    extra={wechatButton}
                />
            </SC.List>
            <Modal
                width={346}
                open={isOpen}
                onClose={() => {
                    togglePlatformSetting(false)
                }}
                title="微信绑定"
            >
                <SC.ModalContainer>
                    <SC.IconWrapper>
                        <IconFont size={25} fill="var(--color-green-500)" type="WeChatLogo" />
                    </SC.IconWrapper>
                    <SC.ModalTitle>使用微信扫一扫</SC.ModalTitle>
                    <SC.QrcodeWrapper>{qrcodeUrl ? <SC.QRCode src={qrcodeUrl} alt="qrcode" /> : <Loading />}</SC.QrcodeWrapper>
                </SC.ModalContainer>
            </Modal>
        </>
    )
}
