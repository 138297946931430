import { getAssetUrl } from '@lighthouse/assets'

import type { Options } from './types'

export const companyTypeOptions: Options = [
    { value: '国企', label: '国企' },
    { value: '私企', label: '私企' },
    { value: '个体', label: '个体' }
]

export const jobOptions: Options = [
    { label: '👩‍🏫‍ 教师', value: '教师' },
    { label: '✍️ 设计师', value: '设计师' },
    { label: '💻 IT', value: 'IT' },
    { label: '👩‍🎓‍ 学生', value: '学生' },
    { label: '🌐 营销', value: '营销' },
    { label: '🎥 新媒体', value: '新媒体' },
    { label: '👔 管理', value: '管理' },
    { label: '🏥 医疗', value: '医疗' },
    { label: '⚖‍ 法律', value: '法律' },
    { label: '🆓‍ 自由职业', value: '自由职业' },
    { label: '👤‍ 其他', value: '其他' }
]

export const sourceOptions: Options = [
    { label: '小红书', value: '小红书' },
    { label: '公众号', value: '公众号' },
    { label: '视频号', value: '视频号' },
    { label: '官网', value: '官网' },
    { label: '朋友推荐', value: '朋友推荐' },
    { label: '行业展会', value: '行业展会' },
    { label: '线下活动', value: '线下活动' },
    { label: '百度搜索', value: '百度搜索' },
    { label: '其他', value: '其他' }
]

export const personAppsOptions: Options = [
    { value: '名片', label: '名片', icon: getAssetUrl('common', 'card.png') },
    { value: '个人官网', label: '个人官网', icon: getAssetUrl('common', 'personal_website.png') },
    { value: '落地页', label: '落地页', icon: getAssetUrl('common', 'landing.png') },
    { value: '作品集', label: '作品集', icon: getAssetUrl('common', 'works.png') },
    { value: '在线商城', label: '在线商城', icon: getAssetUrl('common', 'shop.png') },
    { value: '营销PPT', label: '营销PPT', icon: getAssetUrl('common', 'ppt.png') },
    { value: '个人简历', label: '个人简历', icon: getAssetUrl('common', 'resume.png') },
    { value: '邀请函', label: '邀请函', icon: getAssetUrl('common', 'invite.png') }
]

export const companyAppsOptions: Options = [
    { value: '官网', label: '官网', icon: getAssetUrl('common', 'official_website.png') },
    { value: '团队名片', label: '团队名片', icon: getAssetUrl('common', 'team_card.png') },
    { value: '营销落地页', label: '营销落地页', icon: getAssetUrl('common', 'market_landing.png') },
    { value: '客户门户', label: '客户门户', icon: getAssetUrl('common', 'customer_portal.png') },
    { value: 'CRM系统', label: 'CRM系统', icon: getAssetUrl('common', 'crm_system.png') },
    { value: '进销存管理', label: '进销存管理', icon: getAssetUrl('common', 'invoicing.png') },
    { value: '电商活动营销', label: '电商活动营销', icon: getAssetUrl('common', 'shop_market.png') },
    { value: '活动管理', label: '活动管理', icon: getAssetUrl('common', 'promotion.png') }
]
