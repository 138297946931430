import type { DataSourceAbstract, DataSourceBase, PageAbstract, RecordLikeProtocol } from '@lighthouse/core'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'

export const dataSourceAtom = atom<DataSourceAbstract | undefined>(undefined)

export const dataSourceListIdsAtom = atom<DataSourceBase[]>([])

export const recordsAtom = atom<RecordLikeProtocol[]>([])

export const applicationAtom = atomWithImmer<{
    pageList: PageAbstract[]
    dataSourceList: DataSourceAbstract[]
    viewList: { title: string; dsId: string; id: string }[]
}>({
    pageList: [],
    dataSourceList: [],
    viewList: []
})
