import { Button, IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface ItemProps {
    icon?: string
    iconColor?: string
    title: string
    description: string
    extra?: React.ReactNode
}

export const SCxItem = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

export const SCxItemLeft = styled.div`
    width: 86px;
    white-space: nowrap;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`

export const SCxItemIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`

export const SCxItemIcon = styled(IconFont)`
    color: var(--color-gray-500);
    font-size: var(--font-size-normal);
`

export const SCxItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
`

export const SCxItemName = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
`

export const SCxItemDescription = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

export const SCxItemRight = styled.div`
    white-space: nowrap;
`

export const Item: React.FC<ItemProps> = ({ icon, iconColor, title, description, extra }) => {
    return (
        <SCxItem>
            <SCxItemLeft>
                {icon && (
                    <SCxItemIconWrapper>
                        <SCxItemIcon size={20} type={icon} fill={iconColor} />
                    </SCxItemIconWrapper>
                )}
                <SCxItemInfo>
                    <SCxItemName>{title}</SCxItemName>
                    <SCxItemDescription>{description}</SCxItemDescription>
                </SCxItemInfo>
            </SCxItemLeft>
            <SCxItemRight>
                {extra}
            </SCxItemRight>
        </SCxItem>
    )
}
