import { IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Icon = styled(IconFont)`
    font-size: var(--font-size-base);
    ${pointer}
`
export const Text = styled.span`
    line-height: 16px;
`

export const UtilTitle = styled.div`
    width: 100%;
    padding: 8px 8px 8px 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
`

export const UtilHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > *:not(:first-child) {
        margin-left: 5px;
    }
`

export const SearchBar: typeof Input = styled(Input)`
    /* height: 30px; */
    width: 189px;
    flex-shrink: 1;
    align-items: center;

    input {
        height: 32px;
        min-height: 32px;
        /* margin: 5px 14px; */
        border-radius: 6px;
    }
`
