import { Flex, IconFont, Tooltip } from '@byecode/ui'
import type { CollapseProps } from '@mantine/core'
import { Collapse, Group, Switch, Text } from '@mantine/core'
import type { FC, ReactNode } from 'react'
import React, { useState } from 'react'

import { BasicListItem } from '../ListItemMenu'

interface CollapseBoxProps extends Omit<CollapseProps, 'in'> {
    label: ReactNode
    type?: 'switch' | 'icon'
    defaultOpen?: boolean
    required?: boolean
    isTooltip?: boolean
    tooltipTitle?: React.ReactNode
    collapseAble?: boolean
    extraActions?: React.ReactNode[]
}

export const CollapseBox: FC<CollapseBoxProps> = ({
    children,
    label,
    defaultOpen = true,
    required = false,
    isTooltip,
    tooltipTitle,
    type = 'icon',
    collapseAble = true,
    extraActions,
    ...rest
}) => {
    const [open, setOpen] = useState(defaultOpen)

    return (
        <>
            <BasicListItem sx={{ cursor: 'pointer' }} onClick={() => collapseAble && setOpen(s => !s)} position="apart">
                <Flex alignItems='center'>
                    <Text weight="bold">{label}</Text>
                    {required && (
                        <Text span color="red" style={{ paddingLeft: 4 }}>
                            *
                        </Text>
                    )}
                    {isTooltip && (
                        <Tooltip title={tooltipTitle}>
                            <IconFont type="Question" size={16} fill='var(--color-gray-400)' style={{ paddingLeft: 4 }} />
                        </Tooltip>
                    )}
                </Flex>
                <Group>
                    {extraActions}
                    {collapseAble &&
                        (type === 'icon' ? (
                            <IconFont type="ArrowUpSmall" rotate={open ? 180 : 0} />
                        ) : (
                            <Switch
                                checked={open}
                                onChange={e => {
                                    const checked = !e.currentTarget.checked
                                    setOpen(checked)
                                }}
                            />
                        ))}
                </Group>
            </BasicListItem>
            <Collapse in={open} {...rest}>
                {children}
            </Collapse>
        </>
    )
}
