import type {
    ActionFlowNode,
    ActionNodeConfig,
    ActionNodeType,
    ConditionFlowNode,
    EndFlowNode,
    FlowEdge,
    FlowNode,
    NodeTypes,
    TriggerFlowNode,
    TriggerNodeConfig,
    TriggerNodeType
} from '@lighthouse/shared'
import {
    actionDefaultConfigMap,
    actionNodeInfoMap,
    conditionNodeInfoMap,
    endNodeInfoMap,
    otherPlatformsNodeInfoMap,
    triggerNodeInfoMap
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import { getNodesWithPosition } from './getNodesPosition'

export const addNewTriggerNodeWithEdges = (nodeType: TriggerNodeType, initialConfig?: TriggerNodeConfig) => {
    const edges: FlowEdge[] = []
    const newTriggerNodeId = nanoid()
    const newEndNodeId = nanoid()
    const newEdgeId = nanoid()

    const newTriggerNode: TriggerFlowNode = {
        id: newTriggerNodeId,
        type: 'TRIGGER',
        data: {
            nodeType,
            name: triggerNodeInfoMap[nodeType].label,
            config: initialConfig
        },
        position: { x: 0, y: 0 }
    }

    const newEndNode: EndFlowNode = {
        id: newEndNodeId,
        type: 'END',
        data: {
            nodeType: 'END',
            name: endNodeInfoMap['END'].label
        },
        position: { x: 0, y: 0 }
    }

    const newEdge: FlowEdge = {
        id: newEdgeId,
        type: 'common',
        source: newTriggerNodeId,
        target: newEndNodeId
    }
    const newEdges = [...edges, newEdge] as FlowEdge[]
    const newNodes = [newTriggerNode, newEndNode]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
}

// export const addNewOtherPlatformsNodeWithEdges = (nodeType: OtherPlatformNodeType, nodes: FlowNode[], edges: FlowEdge[], target: string) => {
//     const newNodeId = nanoid()
//     const newEdgeId = nanoid()

//     const newNode: OtherPlatformsFlowNode = {
//         id: newNodeId,
//         type: 'OTHER_PLATFORMS',
//         data: {
//             nodeType,
//             name: otherPlatformsNodeInfoMap[nodeType].label
//         },
//         position: { x: 0, y: 0 }
//     }

//     for (const edge of edges) {
//         if (edge.target === target) {
//             edge.target = newNodeId
//         }
//     }

//     const newEdge: FlowEdge = {
//         id: newEdgeId,
//         type: 'common',
//         source: newNodeId,
//         target
//     }
//     const newEdges = [...edges, newEdge] as FlowEdge[]
//     const newNodes = [...nodes, newNode] as FlowNode[]

//     return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
// }

export const addNewActionNodeWithEdges = (nodeType: ActionNodeType, nodes: FlowNode[], edges: FlowEdge[], target: string) => {
    const newNodeId = nanoid()
    const newEdgeId = nanoid()

    const newNode: ActionFlowNode = {
        id: newNodeId,
        type: 'ACTION',
        data: {
            nodeType,
            name: actionNodeInfoMap[nodeType].label,
            config: actionDefaultConfigMap[nodeType] as ActionNodeConfig
        },
        selected: true,
        position: { x: 0, y: 0 }
    }

    for (const edge of edges) {
        if (edge.target === target) {
            edge.target = newNodeId
        }
    }

    const newEdge: FlowEdge = {
        id: newEdgeId,
        type: 'common',
        source: newNodeId,
        target
    }
    const newEdges = [...edges, newEdge] as FlowEdge[]
    const newNodes = [...nodes, newNode] as FlowNode[]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges, newNode, node: newNode }
}

export const initNewConditionNodeWithEdges = (nodes: FlowNode[], edges: FlowEdge[], source: string, target: string) => {
    const newNodeIds = [nanoid(), nanoid()]
    const newSourceEdgeId = nanoid()
    const newTargetEdgeIds = [nanoid(), nanoid()]

    const newConditionNodes = newNodeIds.map((id, index) => {
        return {
            id,
            type: 'CONDITION',
            data: {
                nodeType: 'CONDITION',
                name: '分支条件'
            },
            selected: index === 0,
            position: { x: 0, y: 0 }
        } as ConditionFlowNode
    })

    const [firstNodeId, secondaryNodeId] = newNodeIds

    for (const edge of edges) {
        if (edge.target === target) {
            edge.target = firstNodeId
            edge.type = 'conditionSource'
        }
    }
    const newSourceEdge: FlowEdge = {
        id: newSourceEdgeId,
        type: 'conditionSource',
        source,
        target: secondaryNodeId
    }

    const newTargetEdges = newTargetEdgeIds.map((id, index) => {
        return {
            id,
            type: 'conditionTarget',
            source: newNodeIds[index],
            target
        } as FlowEdge
    })

    const newEdges = [...edges, newSourceEdge, ...newTargetEdges]
    const newNodes = [...nodes, ...newConditionNodes]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges, newNode: undefined }
}

export const addNewConditionNodeWithEdges = (nodes: FlowNode[], edges: FlowEdge[], source: string, target: string, initial?: boolean) => {
    if (initial) {
        return initNewConditionNodeWithEdges(nodes, edges, source, target)
    }

    const newNodeId = nanoid()
    const newSourceEdgeId = nanoid()
    const newTargetEdgeId = nanoid()

    const newNode: ConditionFlowNode = {
        id: newNodeId,
        type: 'CONDITION',
        data: {
            nodeType: 'CONDITION',
            name: conditionNodeInfoMap['CONDITION'].label
        },
        selected: true,
        position: { x: 0, y: 0 }
    }

    let conditionTargetNodeId: string | undefined
    let conditionTargetId: string | undefined = target

    // find the condition target node id
    while (!conditionTargetNodeId && conditionTargetId) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func, no-loop-func
        const curTargetNodeEdge = edges.find(edge => edge.source === conditionTargetId)
        if (curTargetNodeEdge?.type === 'conditionTarget') {
            conditionTargetNodeId = nodes.find(node => node.id === curTargetNodeEdge.target)?.id
        }
        if (curTargetNodeEdge?.type === 'conditionInner') {
            conditionTargetId = curTargetNodeEdge.target
        }
    }

    if (!conditionTargetNodeId) {
        return { nodes, edges }
    }

    const newSourceEdge: FlowEdge = {
        id: newSourceEdgeId,
        type: 'conditionSource',
        source,
        target: newNodeId
    }
    const newTargetEdge: FlowEdge = {
        id: newTargetEdgeId,
        type: 'conditionTarget',
        source: newNodeId,
        target: conditionTargetNodeId
    }
    const newEdges = [...edges, newSourceEdge, newTargetEdge] as FlowEdge[]
    const newNodes = [...nodes, newNode] as FlowNode[]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges, newNode }
}

export const addNewActionNodeBeforeConditionWithEdges = (nodeType: NodeTypes, nodes: FlowNode[], edges: FlowEdge[], source: string) => {
    const newNodeId = nanoid()
    const newEdgeId = nanoid()
    const newNode: ActionFlowNode = {
        id: newNodeId,
        type: 'ACTION',
        data: {
            nodeType,
            name: actionNodeInfoMap[nodeType].label,
            config: actionDefaultConfigMap[nodeType as ActionNodeType] as ActionNodeConfig
        },
        selected: true,
        position: { x: 0, y: 0 }
    }

    for (const edge of edges) {
        if (edge.source === source) {
            edge.source = newNodeId
        }
    }

    const newEdge: FlowEdge = {
        id: newEdgeId,
        type: 'common',
        source,
        target: newNodeId
    }
    const newEdges = [...edges, newEdge] as FlowEdge[]
    const newNodes = [...nodes, newNode] as FlowNode[]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
}

export const addNewActionNodeAfterConditionWithEdges = (
    nodeType: NodeTypes,
    nodes: FlowNode[],
    edges: FlowEdge[],
    target: string,
    edgeId: string
) => {
    const newNodeId = nanoid()
    const newEdgeId = nanoid()

    const newNode: ActionFlowNode = {
        id: newNodeId,
        type: 'ACTION',
        data: {
            nodeType,
            name: actionNodeInfoMap[nodeType].label,
            config: actionDefaultConfigMap[nodeType as ActionNodeType] as ActionNodeConfig
        },
        selected: true,
        position: { x: 0, y: 0 }
    }

    for (const edge of edges) {
        if (edge.id === edgeId) {
            edge.target = newNodeId
            edge.type = 'conditionInner'
            break
        }
    }

    const newEdge: FlowEdge = {
        id: newEdgeId,
        type: 'conditionTarget',
        source: newNodeId,
        target
    }
    const newEdges = [...edges, newEdge] as FlowEdge[]
    const newNodes = [...nodes, newNode] as FlowNode[]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
}

export const addNewActionNodeInnerConditionWithEdges = (
    nodeType: NodeTypes,
    nodes: FlowNode[],
    edges: FlowEdge[],
    target: string,
    edgeId: string
) => {
    const newNodeId = nanoid()
    const newEdgeId = nanoid()

    const newNode: ActionFlowNode = {
        id: newNodeId,
        type: 'ACTION',
        data: {
            nodeType,
            name: actionNodeInfoMap[nodeType].label,
            config: actionDefaultConfigMap[nodeType as ActionNodeType] as ActionNodeConfig
        },
        selected: true,
        position: { x: 0, y: 0 }
    }

    for (const edge of edges) {
        if (edge.id === edgeId) {
            edge.target = newNodeId
            break
        }
    }

    const newEdge: FlowEdge = {
        id: newEdgeId,
        type: 'conditionInner',
        source: newNodeId,
        target
    }
    const newEdges = [...edges, newEdge] as FlowEdge[]
    const newNodes = [...nodes, newNode] as FlowNode[]

    return { nodes: getNodesWithPosition(newNodes, newEdges), edges: newEdges }
}
