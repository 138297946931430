import { Button, IconFont, Popover, SelectDropdown } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ChartValue, ChartValueCalcType, ChartViewOptions, Field, SchemaProtocol } from '@lighthouse/core'
import { FieldTypeTag, FindUseType, getFieldIcon, getIsFindUse, MoreSetting, useFindUseObjectContext } from '@lighthouse/shared'
import { arrayMove, nanoid } from '@lighthouse/tools'
import { filter, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { type FieldOption, FieldSelect } from '../../../FieldSelect'
import { calcTypeOptions, notIndicatorFieldInnerType, notIndicatorFieldType } from '../../constants'
import { ChartTypeSelector } from '../ChartTypeSelector'

const SCxContainer = styled.div``

const ValueConfiguratorItem = styled.div`
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxWhiteBgSelect: typeof FieldSelect = styled(FieldSelect)``

const SCxSetting = styled.div`
    width: 32px;
    height: 32px;
    margin-left: 6px;
    border-radius: 6px;
    background: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
`

const SCxDragger = styled(IconFont)`
    margin-right: 6px;
    font-size: 16px;
    color: var(--color-gray-500);
    cursor: grab;
`

const SCxAddButton = styled(Button)`
    margin: 4px 0;
    color: var(--color-gray-900);
`

export interface IndicatorSettingProps {
    dsId: string
    schema: SchemaProtocol['schema']
    value: ChartValue[]
    aggregate?: boolean
    disableAdd?: boolean
    enableChartTypeSelector?: boolean
    onChange?: (val: ChartValue[]) => void
}

interface IndicatorSettingItemProps {
    index: number
    dsId: string
    chartValue: ChartValue
    fieldList: FieldOption[]
    schema: SchemaProtocol['schema']
    aggregate?: boolean
    disableCopy?: boolean
    enableChartTypeSelector?: boolean
    onFieldChange?: (index: number, value: ChartValue) => void
    onCopy?: (index: number) => void
    onDelete?: (index: number) => void
}

export type ChartField = {
    value: string
    label: string
    icon: string
} & Field

const IndicatorSettingItem: React.FC<IndicatorSettingItemProps> = ({
    chartValue,
    dsId,
    index,
    fieldList,
    schema,
    aggregate,
    disableCopy,
    enableChartTypeSelector,
    onFieldChange,
    onCopy,
    onDelete
}) => {
    const findUseObject = useFindUseObjectContext()
    const { id, fieldId, calcType, chartType } = chartValue
    const { setNodeRef, activeIndex, attributes, listeners, transform, transition, isSorting } = useSortable({
        id
    })
    const isHighLight = useMemo(
        () =>
            getIsFindUse({
                findUseObject,
                type: FindUseType.FIELD,
                dsId,
                fieldId
            }),
        [dsId, fieldId, findUseObject]
    )

    const sortingStyle = {
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none'
    }

    const getCalcTypeOptions = useCallback(
        (id: string) => {
            const currentField = schema[id]
            if (!currentField) {
                return []
            }
            const isNumberNormalType = currentField.innerType === 'NUMBER'

            return filter(({ value }) => (isNumberNormalType ? true : ['count', 'countUnique'].includes(value)), calcTypeOptions)
        },
        [schema]
    )

    const handleChartConfigurator = useCallback(
        (value: ChartValue) => {
            onFieldChange?.(index, value)
        },
        [index, onFieldChange]
    )

    const handleOptions = useMemo(() => {
        const arr = [
            {
                label: '删除',
                icon: 'Trash',
                onItemClick: () => onDelete?.(index)
            }
        ]
        if (!disableCopy) {
            arr.unshift({
                label: '复制',
                icon: 'Duplicate',
                onItemClick: () => onCopy?.(index)
            })
        }
        return arr
    }, [disableCopy, index, onCopy, onDelete])

    const operatorContent = useMemo(
        () =>
            enableChartTypeSelector ? (
                <ChartTypeSelector index={index} data={chartValue} onChange={handleChartConfigurator} onDelete={onDelete} />
            ) : (
                <MoreSetting width={204} options={handleOptions}>
                    <SCxSetting>
                        <IconFont type="DotsThreeVertical" size={16} fill="var(--color-gray-400)" />
                    </SCxSetting>
                </MoreSetting>
            ),
        [chartValue, enableChartTypeSelector, handleChartConfigurator, handleOptions, index, onDelete]
    )

    return (
        <ValueConfiguratorItem
            key={id}
            style={sortingStyle}
            {...attributes}
            ref={r => {
                setNodeRef(r)
            }}
        >
            <SCxDragger type="DotsSix" {...listeners} />
            <SCxWhiteBgSelect
                highlighting={isHighLight}
                styles={{
                    root: {
                        flex: 1,
                        marginRight: 6
                    }
                }}
                size="md"
                searchable={false}
                value={fieldId}
                onChange={val => {
                    onFieldChange?.(index, { ...chartValue, fieldId: val })
                }}
                options={fieldList}
            />
            {aggregate && (
                <SCxWhiteBgSelect
                    styles={{
                        root: {
                            width: 79,
                            marginRight: 6
                        }
                    }}
                    position="bottom-end"
                    dropdownWidth={204}
                    size="md"
                    value={calcType}
                    onChange={val => {
                        onFieldChange?.(index, { ...chartValue, calcType: val as ChartValueCalcType })
                    }}
                    options={getCalcTypeOptions(fieldId)}
                />
            )}
            {operatorContent}
        </ValueConfiguratorItem>
    )
}

// 指标
export const IndicatorSetting: React.FC<IndicatorSettingProps> = ({
    dsId,
    schema,
    value,
    aggregate = false,
    disableAdd,
    enableChartTypeSelector,
    onChange
}) => {
    const { control } = useFormContext<ChartViewOptions>()
    const chartType = useWatch({ control, name: 'chartType' })
    const [open, setOpen] = useState(false)
    const [sortId, setSortId] = useState<string>('')
    const fieldList = useMemo(() => {
        return Object.keys(schema)
            .filter(id => {
                const field = schema[id]
                if (!aggregate) {
                    return field?.innerType === 'NUMBER'
                }
                return !notIndicatorFieldType.has(field.type) && !notIndicatorFieldInnerType.has(field?.innerType || 'NULL')
            })
            .map(id => {
                const { name, type, innerType } = schema[id]
                return { value: id, label: name, icon: getFieldIcon(id, type, innerType), type, innerType }
            })
    }, [aggregate, schema])

    const dropDownOptions = useMemo(
        () =>
            fieldList.map(item => ({
                value: item.value,
                label: item.label,
                icon: <IconFont key={item.value} type={item.icon} size={16} fill="var(--color-gray-900)" />,
                extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
            })),
        [fieldList]
    )
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const handleAdd = useCallback(
        (fieldId: string) => {
            const defaultChartType = chartType === 'line' ? 'line' : 'bar'
            const field = schema[fieldId]
            const initCalcType = field.type === 'number' ? 'max' : 'count'
            const addItem: ChartValue = { id: nanoid(), fieldId, calcType: initCalcType, chartType: defaultChartType }
            const newValue = [...value, addItem]
            setOpen(false)
            onChange?.(newValue)
        },
        [chartType, onChange, schema, value]
    )

    const handleCopy = useCallback(
        (index: number) => {
            const item = value[index]
            const newValue = [...value]
            newValue.splice(index, 0, item)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleDelete = useCallback(
        (index: number) => {
            const newValue = value.filter((item, i) => i !== index)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleFieldChange = useCallback(
        (index: number, newChartValue: ChartValue) => {
            const { id } = newChartValue
            const field = schema[id]
            const newParam = newChartValue
            if (field && field.innerType !== 'NUMBER') {
                newParam.calcType = 'count'
            }
            const newValue = value.map((item, i) => {
                if (i === index) {
                    return newParam
                }
                return item
            })
            onChange?.(newValue)
        },
        [onChange, schema, value]
    )

    const handleSortStart = useCallback((ev: DragStartEvent) => {
        if (!ev.active) {
            return
        }
        const id = ev.active.id as string
        setSortId(id)
    }, [])

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const sourceIndex = findIndex(({ id }) => id === activeId, value)
            const targetIndex = findIndex(({ id }) => id === overId, value)
            const newList = arrayMove(value, sourceIndex, targetIndex)
            onChange?.(newList)
        },
        [onChange, value]
    )

    const handleSortCancel = useCallback(() => {
        setSortId('')
    }, [])

    const dragItem = useMemo(() => {
        if (!sortId) {
            return
        }
        const index = findIndex(item => item.id === sortId, value)
        return (
            <IndicatorSettingItem
                dsId={dsId}
                chartValue={value[index]}
                aggregate={aggregate}
                enableChartTypeSelector={enableChartTypeSelector}
                schema={schema}
                index={index}
                fieldList={fieldList}
            />
        )
    }, [aggregate, dsId, enableChartTypeSelector, fieldList, schema, sortId, value])

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                modifiers={[restrictToParentElement]}
                onDragStart={handleSortStart}
                onDragEnd={handleSortEnd}
                onDragCancel={handleSortCancel}
            >
                <SortableContext items={value}>
                    {value.map((chartValue, index) => (
                        <IndicatorSettingItem
                            key={chartValue.id}
                            dsId={dsId}
                            aggregate={aggregate}
                            chartValue={chartValue}
                            schema={schema}
                            index={index}
                            disableCopy={disableAdd}
                            enableChartTypeSelector={enableChartTypeSelector}
                            fieldList={fieldList}
                            onCopy={handleCopy}
                            onDelete={handleDelete}
                            onFieldChange={handleFieldChange}
                        />
                    ))}
                </SortableContext>
                {createPortal(<DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{dragItem}</DragOverlay>, document.body)}
            </DndContext>
            <Popover width={249} opened={open} onChange={setOpen} position="bottom-start" withinPortal>
                <Popover.Target>
                    <SCxAddButton radius={100} disabled={disableAdd} icon={<IconFont type="Add" size={16} fill="var(--color-gray-900)" />}>
                        添加
                    </SCxAddButton>
                </Popover.Target>
                <Popover.Dropdown>
                    <SelectDropdown searchable onSelect={handleAdd} options={dropDownOptions} />
                </Popover.Dropdown>
            </Popover>
        </SCxContainer>
    )
}
