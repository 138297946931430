import { Button, Modal } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Utils: React.FC = () => {
    return (
        <Unit title="组件静态方法">
            <Button
                onClick={async () => {
                    const isConfirm = await Modal.confirm({
                        title: '确认删除？',
                        content: '区人萨蒂萨菲卡斯年方法可拉斯'
                    })
                    // eslint-disable-next-line no-alert
                    alert(isConfirm)
                }}
            >
                确认弹窗
            </Button>

            <Button
                onClick={async () => {
                    const isConfirm = await Modal.confirm({
                        title: '确认删除？',
                        content: '区人萨蒂萨菲卡斯年方法可拉斯',
                        isCheckBoxConfirm: true,
                        checkBoxLabel: '我已知晓上述风险'
                    })
                    // eslint-disable-next-line no-alert
                    alert(isConfirm)
                }}
            >
                确认弹窗
            </Button>
        </Unit>
    )
}
