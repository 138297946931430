import { pointer, singleTextEllipsis, Toast } from '@byecode/ui'
import { getBrandInfo, getUrlNameByOpenType, useAtomAction, useAtomData } from '@lighthouse/shared'
import copyToClipboard from 'copy-to-clipboard'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { rollbackPageStackHistoryAtom } from '@/atoms/page/action'
import { pageStackBreadcrumbAtom } from '@/atoms/page/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDefaultPageList } from '@/hooks/usePage'
import { usePageURI } from '@/hooks/usePageURI'

import * as CM from './styles'

interface AddressBarProps {
    style?: React.CSSProperties
    hoverColor?: string
}

const SCxContainer = styled.div`
    position: relative;
    width: 40%;
    padding: 0 76px 0 44px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-200);
    border-radius: 6px;
    overflow: hidden;
`

const SCxInput = styled.input`
    outline: none;
    pointer-events: none;
    color: var(--color-gray-500);
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: var(--font-size-sm);
    flex: 1;
    ${singleTextEllipsis}
    ::placeholder {
        color: var(--color-gray-400);
    }
`

const SCxBackIconWrapper = styled.div<{ hoverColor?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 32px;
    color: var(--color-gray-400);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    ${pointer}
    border-right: 1px solid var(--color-gray-300);

    &:hover {
        background-color: ${({ hoverColor }) => hoverColor ?? 'var(--color-gray-300)'};
    }
`

const SCxJumpIconWrapper = styled.div<{ hoverColor?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: var(--color-gray-600);
    width: 64px;
    height: 32px;
    display: flex;
    ${pointer}

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 100%;
        border-left: 1px solid var(--color-gray-300);
    }

    div:hover {
        background-color: ${({ hoverColor }) => hoverColor ?? 'var(--color-gray-300)'};
    }
`

const AddressBar: React.FunctionComponent<AddressBarProps> = ({ style, hoverColor }) => {
    const { run: popStack } = useAtomAction(rollbackPageStackHistoryAtom)

    const { shareUrl } = usePageURI()

    const handleForward = useCallback(() => {
        window.open(shareUrl)
    }, [shareUrl])

    const handleCopy = useCallback(() => {
        if (!shareUrl) {
            return
        }
        const isCopied = copyToClipboard(shareUrl)
        if (isCopied) {
            Toast.success('复制成功')
        }
    }, [shareUrl])

    return (
        <SCxContainer style={style}>
            <SCxBackIconWrapper
                hoverColor={hoverColor}
                onClick={() => {
                    popStack()
                }}
            >
                <CM.Icon type="ArrowLeftSmall" />
            </SCxBackIconWrapper>

            <SCxInput value={shareUrl} readOnly placeholder="https://发布后可显示应用域名" />
            <SCxJumpIconWrapper hoverColor={hoverColor}>
                <div>
                    <CM.Icon type="Copy" size={16} onClick={handleCopy} />
                </div>
                <div>
                    <CM.Icon type="ArrowSquareIn" size={16} onClick={handleForward} />
                </div>
            </SCxJumpIconWrapper>
        </SCxContainer>
    )
}

export default AddressBar
