import { IconFont, Switch } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Group, Text } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

import { StyledIconWrapper } from './styles'

interface CommonOperaProps {
    value?: boolean
    label: string
    icon?: string
    onChange?: (v: boolean) => void
    disabled?: boolean
}

export const CommonOpera: FC<CommonOperaProps> = ({ value, onChange, icon, label, ...rest }) => (
    <ListItem4ByecodeUi enableHover enablePadding compact justifyContent="space-between" alignItems="center">
        <Group spacing="xs" noWrap>
            {icon && (
                <StyledIconWrapper active={value}>
                    <IconFont type={icon} size={18} fill={value ? 'var(--color-main)' : undefined} />
                </StyledIconWrapper>
            )}
            <Text>{label}</Text>
        </Group>
        <Switch checked={value} onChange={e => onChange?.(e.currentTarget.checked)} {...rest} />
    </ListItem4ByecodeUi>
)
