// import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import { add, subtract } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

import * as SC from './styles'
import type { VerificationCodeBtnProps, VerificationCodeBtnState } from './types'

// let codeTimer: number

/**
 *
 *
 * @param {*} { getCodeHandle = () => {}, phoneRef }
 * @return {*}
 */

export const VerificationCodeBtn: React.FC<VerificationCodeBtnProps> = ({
    onGetCodeHandle,
    loading = false,
    style
}: VerificationCodeBtnProps) => {
    const currentTime = String(Date.now()).slice(0, 10)

    const [state, setState] = useImmer<VerificationCodeBtnState>({
        disable: false,
        prevTime: Number(getCachedItem('lastTime', 0)),
        count: 60,
        codeStep: 0,
        codeText: '获取验证码'
    })

    const { disable, prevTime, count, codeStep, codeText } = state

    useEffect(() => {
        const lastCount = add(60, subtract(Number(prevTime), Number(currentTime)))
        if (codeStep === 1) {
            setState(draft => {
                draft.count = 60
                draft.codeText = `${String(60)} s后重发`
                draft.codeStep = 2
            })
        }
        if (lastCount > 0 && count > lastCount) {
            setState(draft => {
                draft.count = lastCount
                draft.codeText = `${String(lastCount)} s后重发`
                draft.codeStep = 2
            })
            return undefined
        }
        if (codeStep === 2) {
            if (count > 0) {
                const codeTimer = setTimeout(() => {
                    setState(draft => {
                        draft.codeText = `${String(count - 1)} s后重发`
                        draft.count = count - 1
                    })
                }, 1000)
                return () => {
                    const dateValue = subtract(60, count)
                    const nowStr = String(Date.now()).slice(0, 10)
                    const now = subtract(Number(nowStr), dateValue)
                    setCachedItem<string>('lastTime', String(now))
                    clearTimeout(codeTimer)
                }
            }
            setState(draft => {
                draft.count = 60
                draft.codeText = `再次获取`
                draft.codeStep = 0
            })
        }
        return undefined
    }, [currentTime, setState, codeStep, count, prevTime])

    const startCodeDown = useCallback(() => {
        setState(draft => {
            draft.codeStep = 1
            draft.disable = false
        })
    }, [setState])

    const errorCodeDown = useCallback(() => {
        setState(draft => {
            draft.disable = false
        })
    }, [setState])

    /**
     * 获取验证码
     */
    const getPhoneCode = useCallback(() => {
        if (codeStep > 0) {
            return
        }
        setState(draft => {
            draft.disable = true
        })
        onGetCodeHandle?.(startCodeDown, errorCodeDown)
    }, [codeStep, errorCodeDown, onGetCodeHandle, setState, startCodeDown])

    return useMemo(
        () => (
            <SC.VerificationCodeTextButton disabled={codeStep > 0 || disable} loading={loading} style={style} onClick={getPhoneCode}>
                {codeText}
            </SC.VerificationCodeTextButton>
        ),
        [codeStep, codeText, disable, getPhoneCode, loading, style]
    )
}
