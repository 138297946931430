import type { TemplateList } from '@/containers/Template/types'
import type { HttpResp } from '@/http'
import http from '@/http'

http.defaults.baseURL = '/lighthouse'

/**
 * @description 获取所有模板
 * @return {*}  {Promise<TemplateList>}
 */
export async function getTemplateList(): Promise<TemplateList> {
    const res = await http.get<unknown, HttpResp<TemplateList>>('api/auth/v1/getTemplateList')
    return res.content
}

export * from './analyze'
export * from './app'
export * from './common'
export * from './ds'
export * from './env'
export * from './integration'
export * from './page'
export * from './space'
export * from './user'
export * from './workflow'
