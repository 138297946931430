import { Button, closeModal, ModalsProvider, openModal } from '@byecode/ui'
import { nanoid } from '@lighthouse/tools'
import React from 'react'

import { Unit } from '../Mics'

export const Quick: React.FC = () => {
    return (
        <Unit title="快捷方法">
            <ModalsProvider>
                <Button
                    onClick={() => {
                        const id = nanoid()
                        openModal({
                            id,
                            title: 'dddd',
                            children: (
                                <div>
                                    <Button
                                        onClick={() => {
                                            const sid = nanoid()
                                            openModal({
                                                id: sid,
                                                title: 'eeee',
                                                children: (
                                                    <div>
                                                        <Button onClick={() => closeModal(sid)}>关闭当前</Button>
                                                        <Button onClick={() => closeModal(id)}>关闭父级</Button>
                                                    </div>
                                                )
                                            })
                                        }}
                                    >
                                        打开额外的
                                    </Button>
                                    <Button onClick={() => closeModal(id)}>关闭当前</Button>
                                </div>
                            )
                        })
                    }}
                >
                    快速打开弹窗
                </Button>
            </ModalsProvider>
        </Unit>
    )
}
