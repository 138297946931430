import { IconFont, Select } from '@byecode/ui'
import type { DataSheetFindUse } from '@lighthouse/shared'
import { getTableIcon } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { FindUseLink } from '../FindUseLink'

const SCxContainer = styled.div`
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
`

interface FindUseDataSourceProps {
    config: DataSheetFindUse
    onChange?: (val: DataSheetFindUse) => void
}

export const FindUseDataSource: React.FC<FindUseDataSourceProps> = ({ config, onChange }) => {
    const navigate = useNavigate()
    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(currentAppId, envId)
    const {
        type,
        dataSheet: { dsId }
    } = config
    const options = useMemo(
        () =>
            dataSourceList.map(item => ({
                label: item.name,
                value: item.id,
                icon: <IconFont type={getTableIcon(item)} size={16} />
            })),
        [dataSourceList]
    )

    const handleLink = useCallback(() => {
        navigate({ pathname: `/${currentAppId}/dataSource/${dsId}` })
    }, [currentAppId, dsId, navigate])

    const handleChange = useCallback(
        (val: string) => {
            onChange?.({
                type,
                dataSheet: {
                    dsId: val
                }
            })
        },
        [onChange, type]
    )

    return (
        <SCxContainer>
            <Select styles={{ root: { width: '100%' } }} value={dsId} options={options} onChange={handleChange} />
            <FindUseLink onClick={handleLink} />
        </SCxContainer>
    )
}
