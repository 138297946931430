import type { AggregateResultField } from '@lighthouse/shared'
import React from 'react'

import { GroupByFieldConfig } from './GroupByFieldConfig'
import { StatisticsFieldConfig } from './StatisticsFieldConfig'

interface GroupStatisticsConfigureProps {
    fieldList: AggregateResultField[]
}

export const GroupStatisticsConfigure: React.FC<GroupStatisticsConfigureProps> = ({ fieldList }) => {
    return (
        <>
            <GroupByFieldConfig fieldList={fieldList} />
            <StatisticsFieldConfig fieldList={fieldList} />
        </>
    )
}
