import React from 'react'

import * as SC from './styles'

export interface ListItemProps {
    children?: React.ReactNode

    icon?: string
    title: string
    suffix?: React.ReactNode
    className?: string
    style?: React.CSSProperties
    onClick?: () => void
}

const ListItem: React.FC<ListItemProps> = ({ icon, title, suffix, style, className, onClick }) => {
    return (
        <SC.ItemWrapper className={className} style={style} onClick={onClick}>
            <SC.InfoWrapper>
                {icon && <SC.InfoIcon type={icon} />}
                <SC.InfoText>{title}</SC.InfoText>
            </SC.InfoWrapper>
            <SC.ActionWrapper>{suffix}</SC.ActionWrapper>
        </SC.ItemWrapper>
    )
}

export default ListItem
