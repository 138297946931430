import '@lighthouse/shared/styles/base.scss'
import '@byecode/ui/styles/variable.scss'
import '@byecode/ui/styles/theme.scss'
import '@lighthouse/shared/styles/index.scss'
import './polyfill'
import './i18'

import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { initSentry } from './sentry'

initSentry()

const container = document.querySelector('#root') as HTMLElement

const root = createRoot(container)

root.render(<App />)
