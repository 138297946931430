import type { ActionFlowNode, SendMsgDingTalkRobotConfig } from '@lighthouse/shared'
import { getDingTalkRobotVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleDingTalkRobot = async (node: ActionFlowNode<SendMsgDingTalkRobotConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const dingTalkRobotPayload = getDingTalkRobotVariableValue(config, excParams)

    await srv.activeDingTalkRobotAction({
        config: dingTalkRobotPayload
    })

    return dingTalkRobotPayload
}
