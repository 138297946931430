import type { Option } from '@byecode/ui'
import type { SpaceUser } from '@lighthouse/core'
import { emailRegex, ErrorMessage, UploadUserAvatar } from '@lighthouse/shared'
import Uploady from '@rpldy/uploady'
import { ResultAsync } from 'neverthrow'
import React, { useCallback, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import * as srv from '@/services'
import { uploadPublicParams } from '@/utils/auth'

import * as SC from './styles'
import type { UserFormProps } from './types'

const sexArr: Option[] = [
    {
        label: '男',
        value: '1'
    },
    {
        label: '女',
        value: '2'
    }
]

export const UserForm: React.FC<UserFormProps> = ({ userInfo, onUpdateUser }) => {
    const [avatar, setAvatar] = useState(userInfo.avatar)
    // 修改用户信息
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<SpaceUser>({
        reValidateMode: 'onChange',
        shouldFocusError: false,
        defaultValues: {
            ...userInfo
            // birthday: userInfo.birthday ? new Date(userInfo.birthday) : undefined
        }
    })
    const userName: string = useWatch({ control, name: 'username' }) || ''

    // 表单提交
    const updateSubmit = useCallback(
        async (data: SpaceUser) => {
            const newUser = { ...data, avatar }
            const res = await ResultAsync.fromPromise(srv.updateUser(newUser), e => e)
            if (res.isOk()) {
                onUpdateUser(newUser)
                // Toast.success('修改成功')
            }
        },
        [avatar, onUpdateUser]
    )

    const handleUpdateImg = useCallback(
        async (img: string | undefined) => {
            const oldAvatar = userInfo.avatar
            setAvatar(img)
            const newUser = { ...userInfo, avatar: img }
            const res = await ResultAsync.fromPromise(srv.updateUser(newUser), e => e)
            if (res.isOk()) {
                onUpdateUser(newUser)
                return
            }
            setAvatar(oldAvatar || '')
        },
        [onUpdateUser, userInfo]
    )

    return (
        <SC.Container>
            <SC.Content>
                <Uploady accept="image/*" {...uploadPublicParams()}>
                    <UploadUserAvatar
                        id={userInfo.userId}
                        name={userName}
                        size="xLarge"
                        fontSize={20}
                        img={avatar}
                        onUpdateImg={handleUpdateImg}
                    />
                </Uploady>
                <SC.UserForm>
                    <SC.FormGroup>
                        <SC.FormLabel>昵称</SC.FormLabel>
                        <ErrorMessage name="username" errors={errors}>
                            <SC.FormInput
                                size="lg"
                                maxLength={20}
                                placeholder="待添加"
                                {...register('username', {
                                    required: '请输入昵称'
                                })}
                                onBlur={handleSubmit(updateSubmit)}
                            />
                        </ErrorMessage>
                    </SC.FormGroup>
                    <SC.FormGroup>
                        <SC.FormLabel>邮箱</SC.FormLabel>
                        <ErrorMessage name="email" errors={errors} style={{ flex: 1 }}>
                            <SC.FormInput
                                size="lg"
                                placeholder="待添加"
                                {...register('email', {
                                    pattern: {
                                        value: emailRegex,
                                        message: '请输入正确的邮箱'
                                    }
                                })}
                                onBlur={handleSubmit(updateSubmit)}
                            />
                        </ErrorMessage>
                    </SC.FormGroup>
                </SC.UserForm>
            </SC.Content>
        </SC.Container>
    )
}

export default UserForm
