import { Flex } from '@byecode/ui/components/Flex'
import type { BaseModalProps } from '@byecode/ui/components/Modal'
import { DesktopModal } from '@byecode/ui/components/Modal'
import type { ApplicationSettingSEO, ApplicationWebsiteSetting } from '@lighthouse/core'
import { useAtomAction } from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'

import { updateAppAtom } from '@/atoms/application/action'
import { useApplicationSetting, useCurrentAppID } from '@/hooks/useApplication'
import { PageSubmissionSearchEngineEnum } from '@/services/types'

import { SearchEngineTag } from '../SearchEngineTag'

interface SearchEngineModalProps extends BaseModalProps {}

const SCxTitle = styled.div`
    color: var(--color-gray-900);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
`

const SCxDescribe = styled.div`
    color: var(--color-gray-400);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 16px;
`

const SCxContent = styled.form`
    padding: 0 40px;
`

export const SearchEngineModal: React.FunctionComponent<SearchEngineModalProps> = ({ ...rest }) => {
    const appSetting = useApplicationSetting<ApplicationWebsiteSetting>()
    const { run: updateAppSetting } = useAtomAction(updateAppAtom)
    const appId = useCurrentAppID()

    const methods = useForm<ApplicationSettingSEO>({
        mode: 'onChange',
        defaultValues: appSetting.seo
    })
    const { watch, control, reset } = methods

    const handleSubmit = React.useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingSEO) => {
                const isSuccess = await updateAppSetting({
                    id: appId,
                    config: {
                        seo: value
                    }
                })
            }),
        [appId, updateAppSetting]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(clone(value) as ApplicationSettingSEO)
        })
        return unsubscribe
    }, [handleSubmit, updateAppSetting, watch])

    return (
        <DesktopModal
            {...rest}
            styles={{
                header: {
                    borderColor: 'transparent!important'
                },
                root: {
                    fontSize: 14
                },
                body: {
                    padding: '0px!important'
                },
                modal: {
                    maxHeight: 598,
                    height: '80%'
                }
            }}
        >
            <SCxContent>
                <SCxTitle>搜索引擎管理</SCxTitle>
                <SCxDescribe>自动提交的页面网址，将会进入排队提交给开启后的搜索引擎：</SCxDescribe>
                <Flex gap="16px" style={{ margin: -4, marginTop: 20 }} wrap="wrap">
                    <Controller
                        control={control}
                        name="baidu"
                        render={({ field }) => {
                            return (
                                <SearchEngineTag
                                    value={field.value}
                                    type={PageSubmissionSearchEngineEnum.BAIDU}
                                    onChange={field.onChange}
                                    style={{ flexBasis: 'calc(50% - 16px)', margin: 4 }}
                                />
                            )
                        }}
                    />
                    {/* <Controller
                        control={control}
                        name="google"
                        render={({ field }) => {
                            return (
                                <SearchEngineTag
                                    value={field.value}
                                    type={PageSubmissionSearchEngineEnum.CHROME}
                                    onChange={field.onChange}
                                    style={{ flexBasis: 'calc(50% - 16px)', margin: 4 }}
                                />
                            )
                        }}
                    />
                    <Controller
                        control={control}
                        name="bing"
                        render={({ field }) => {
                            return (
                                <SearchEngineTag
                                    value={field.value}
                                    type={PageSubmissionSearchEngineEnum.BING}
                                    onChange={field.onChange}
                                    style={{ flexBasis: 'calc(50% - 16px)', margin: 4 }}
                                />
                            )
                        }}
                    /> */}
                </Flex>
            </SCxContent>
        </DesktopModal>
    )
}
