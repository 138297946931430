import type { DataSourceModifyWay, UpdateFieldType } from '@lighthouse/shared'

export const mergeStyle: Record<DataSourceModifyWay, string> = {
    add: 'green',
    update: 'blue',
    delete: 'red',
    error: 'peach'
}

export const mergeText: Record<DataSourceModifyWay, string> = {
    add: '新增',
    update: '更新',
    delete: '删除',
    error: '异常'
}


export const updateFieldConfigTypeText: Record<UpdateFieldType, string> = {
    FIELD_TYPE: '字段类型',
    FIELD_NAME: '字段名称',
    PRECISION: '精度',
    PREFIX: '前缀',
    SUFFIX: '后缀',
    DATE_FORMAT: '日期格式',
    ID_TYPE: '编号类型',
    ID_RULE: '编号规则',
    MULTIPLE_SELECT: '多选',
    ADD_LABEL: '增加选项',
    DELETE_LABEL: '删除选项',
    UPDATE_FORMULA: '修改公式'
}
