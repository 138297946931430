import { IconFont, noButtons } from '@byecode/ui'
import { Image } from '@mantine/core'
import styled, { css } from 'styled-components'

export const Title = styled.div`
    padding: 0 56px;
    color: var(--color-black);
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
`

export const Describe = styled.div`
    padding: 0 56px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--color-gray-500);
    margin-top: 16px;
    text-align: center;
`

export const BlockContainer = styled.div`
    margin-top: 32px;
    padding: 0 56px 60px 56px;
    max-width: 100%;
    display: flex;
    gap: 20px;
    /* flex: 1; */
    overflow: auto hidden;
    ${noButtons('8px')}
    transition: all 0.3 ease-in-out;
`

export const Block = styled.div`
    flex-shrink: 0;
    border-radius: 26px;
    width: 356px;
    height: max-content;
    /* background: linear-gradient(180deg, #f2f4f7 0%, rgba(242, 244, 247, 0.08) 100%); */
`

export const BlockWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 40px 28px 28px 28px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    /* box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1); */
    overflow: hidden;
    &[data-space-version='ADVANCED'] {
        border: 1px solid rgba(228, 231, 236, 1);
        background: linear-gradient(180deg, rgba(0, 10, 255, 0.1) 0%, rgba(85, 81, 255, 0) 34.13%);
    }
`
export const BlockContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
`

export const BlockTitle = styled.div`
    color: var(--color-gray-900);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
`

export const BlockDescribe = styled.div`
    height: 48px;
    color: var(--color-gray-600);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
`
export const BlockPrice = styled.div`
    display: flex;
    align-items: flex-end;
    height: 56px;
    gap: 8px;
    margin-bottom: 16px;
`
export const Icon = styled(IconFont)<{ isPoint?: boolean }>`
    font-size: 16px;
    cursor: ${({ isPoint }) => (isPoint ? 'pointer' : 'auto')};
`
export const VersionImage = styled(Image)`
    position: absolute;
    right: 0px;
    width: auto;
    top: 20px;
    width: auto !important;

    img {
        height: 120px !important;
        width: auto !important;
    }
`
export const InfoList = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    font-size: var(--font-size-normal);

    > * {
        width: 100%;
        line-height: 22px;
    }
`

export const Arrow = styled.div<{ horizontalAlign: 'left' | 'right' }>`
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 88px;
    height: 88px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    background: rgba(16, 24, 40, 0.65);
    cursor: pointer;
    z-index: 1;
    border-radius: 100%;
    ${({ horizontalAlign }) =>
        horizontalAlign === 'left'
            ? css`
                  left: -44px;
                  justify-content: flex-end;
              `
            : css`
                  right: -44px;
                  justify-content: flex-start;
              `}
`

export const KindBlock = styled.div`
    margin-top: 16px;
    width: 100%;
`

export const Tag = styled.div<{ backgroundColor: string }>`
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    border-radius: 12px 12px 0px 12px;
    color: #fff;
    height: 28px;
    padding: 0 8px;
    transform: rotate(4deg);
    background-color: ${({ backgroundColor }) => backgroundColor};
`
