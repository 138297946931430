import { Toast } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import { Text } from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { usePageURI } from '@/hooks/usePageURI'
import * as srv from '@/services'

import * as CM from '../../styles'
import { SharedContainer } from '../Share'
import { SharedHeader } from '../Share/Header'
import { TagInput } from './TagInput'

interface SendEmailProps {
    onClose?: () => void
    onBack: () => void
}

const SCxContainer = styled.div`
    /* padding: 20px 16px; */
    display: flex;
    flex-direction: column;
`
const SCxContent = styled.div`
    /*  */
`

export const SendEmail: React.FunctionComponent<SendEmailProps> = ({ onBack, onClose }) => {
    const [value, setValue] = useState<string[]>([])
    const [isSend, setIsSend] = useState(false)
    const [loading, setLoading] = useState(false)

    const { shareUrl } = usePageURI()

    const handleSend = useCallback(async () => {
        if (loading) {
            return
        }
        if (value.length === 0) {
            Toast.warning('请先回车确认邮箱')
            return
        }
        setIsSend(true)
        setLoading(true)
        const success = await srv.sendEmailSharing({ mails: value, buttonUrl: shareUrl })
        setLoading(false)
        if (success) {
            Toast.success('发送成功')
        }
    }, [loading, shareUrl, value])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isSend) {
                setIsSend(false)
            }
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [isSend])

    return (
        <SCxContainer>
            <SharedHeader title="发送邮件" onBack={onBack} onClose={onClose} style={{ marginBottom: 8 }} />
            <SCxContent>
                <Text weight={500} size={14} style={{ lineHeight: '38px' }}>
                    发送到邮件
                </Text>
                <TagInput value={value} onChange={setValue} />
                {isSend ? (
                    <Button
                        block
                        style={{ marginTop: 16 }}
                        icon={<CM.Icon type="TickCircle" fill="var(--color-green-500)" />}
                        onClick={handleSend}
                    >
                        已发送
                    </Button>
                ) : (
                    <Button block type="primary" style={{ marginTop: 16 }} onClick={handleSend}>
                        发送
                    </Button>
                )}
            </SCxContent>
        </SCxContainer>
    )
}
