import { singleTextEllipsis } from '@byecode/ui'
import { Flex, Image, Text } from '@mantine/core'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import type { WechatShareResult } from '@/services/types'

import * as CM from '../../styles'

interface CardProps {
    type: 'wxMessage' | 'wxFriendCircle'
    theme: 'default' | 'green'
    data?: WechatShareResult
    isShadow?: boolean
    style?: React.CSSProperties
    styles?: Partial<Record<'card' | 'root', React.CSSProperties>>
}

const SCxContainer = styled.div<{ backgroundColor: string }>`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 20px;
    margin-top: 6px;
    /* background-color: var(--color-gray-100); */
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 6px;
    overflow: hidden;
`

const SCxCard = styled.div<Pick<React.CSSProperties, 'flexDirection' | 'alignItems'> & { isShadow: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
    align-items: ${({ alignItems }) => alignItems};
    gap: 8px;
    padding: 12px;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    background-color: var(--color-white);
    overflow: hidden;
    box-shadow: ${({ isShadow }) => (isShadow ? '0px 8px 24px 0px rgba(12, 29, 21, 0.16)' : 'none')};
`

const SCxTitle = styled.div<{ color?: string }>`
    line-height: 22px;
    font-size: var(--font-size-normal);
    font-weight: 500;
    color: ${({ color }) => color};
    ${singleTextEllipsis}/* white-space: nowrap; */
    /* -webkit-line-clamp: 1; */
`

const SCxDescribe = styled.div<{ lineClamp: number; color?: string }>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
`

const SCxMinute = styled.div<{ color: string }>`
    color: var(--color-gray-500);
    /* 12/SC-Regular */
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    color: ${({ color }) => color};
`

const SCxAvatar = styled.div<{ backgroundColor: string; borderColor: string }>`
    width: 48px;
    height: 48px;
    padding: 8px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 4px;
`

const SCxColorBlock = styled.div`
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    background-color: var(--color-gray-200);
`
const themeColorMap = {
    default: {
        color: 'var(--color-gray-500)',
        titleColor: 'var(--color-black)',
        backgroundColor: 'var(--color-gray-100)',
        avatarColor: 'var(--color-gray-500)',
        avatarBorderColor: 'transparent',
        avatarBgColor: 'var(--color-gray-200)'
    },
    green: {
        color: '#83E4C0',
        titleColor: 'var(--color-white)',
        backgroundColor: 'transparent',
        avatarColor: '#5CCE99',
        avatarBorderColor: '#2b7754',
        avatarBgColor: '#238A5A'
    }
}

export const Card: React.FunctionComponent<CardProps> = ({ type, theme, style, styles, data, isShadow = false }) => {
    const themeStyle = useMemo(() => themeColorMap[theme], [theme])

    return (
        <SCxContainer style={{ ...style, ...styles?.root }} backgroundColor={themeStyle.backgroundColor}>
            {type === 'wxFriendCircle' ? (
                <>
                    <Flex style={{ flex: 1, overflow: 'hidden' }} gap={12}>
                        <SCxAvatar backgroundColor={themeStyle.avatarBgColor} borderColor={themeStyle.avatarBorderColor}>
                            <CM.Icon size={32} color={themeStyle.avatarColor} type="User" />
                        </SCxAvatar>
                        <Flex style={{ flex: 1, overflow: 'hidden' }} direction="column" gap={8}>
                            <SCxTitle color={themeStyle.titleColor}>分享</SCxTitle>
                            <SCxCard isShadow={isShadow} alignItems="flex-start" style={styles?.card}>
                                {data?.icon ? (
                                    <Image
                                        width={44}
                                        height={44}
                                        src={data?.icon}
                                        style={{ background: 'var(--color-gray-100)', flexShrink: 0 }}
                                        alt=""
                                    />
                                ) : (
                                    <SCxColorBlock />
                                )}

                                {/* <Flex justify="space-between"> */}
                                <SCxDescribe lineClamp={1} color="var(--color-black)">
                                    {data?.title ?? 'ByeCode'}
                                </SCxDescribe>
                                {/* </Flex> */}
                            </SCxCard>
                            <SCxMinute color={themeStyle.color}>1分钟前</SCxMinute>
                        </Flex>
                    </Flex>
                </>
            ) : (
                <>
                    <SCxCard flexDirection="column" isShadow={isShadow} style={styles?.card}>
                        <SCxTitle>{data?.title ?? 'ByeCode'}</SCxTitle>
                        <Flex style={{ flexShrink: 0, width: '100%' }} justify="space-between">
                            <SCxDescribe lineClamp={2}> {data?.subtitle}</SCxDescribe>
                            {data?.icon ? (
                                <Image width={44} height={44} src={data?.icon} style={{ background: 'var(--color-gray-100)' }} alt="" />
                            ) : (
                                <SCxColorBlock />
                            )}
                        </Flex>
                    </SCxCard>
                    <SCxAvatar backgroundColor={themeStyle.avatarBgColor} borderColor={themeStyle.avatarBorderColor}>
                        <CM.Icon size={32} color={themeColorMap[theme].avatarColor} type="User" />
                    </SCxAvatar>
                </>
            )}
        </SCxContainer>
    )
}
