import { Breadcrumbs, Divider, Empty, Group, IconFont, Select } from '@byecode/ui'
import type { FormContainerBlockConfig, FormWeChatCollector } from '@lighthouse/core'
import { fieldSettingMap, ListItemPaddingByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDsListAndFieldOptions } from '@/hooks/useDataSource'
import { useOtherPlatformList } from '@/shared/reusable'

import { SwitchControl } from '../BlockSettings/Common/SwitchControl'
import { FieldSelect } from '../FieldSelect'
import { AddWeChatOfficialAccount, WeChatOfficialAccountModal } from '../OtherPlatFormModal'
import { WechatIntegratedCard } from '../WechatIntegratedCard'

export interface CollectorSettingProps {
    // data: FormRule
    onGoBack: React.MouseEventHandler<HTMLDivElement>
}

const SCxContainer = styled.div`
    width: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    font-size: 14px;
`

const SCxRightFill = styled.div`
    display: none;
    align-items: center;
    justify-content: flex-end;
`

const FIELD_LIST: { label: string; value: Exclude<keyof FormWeChatCollector, keyof { isUsed: boolean }> }[] = [
    { label: 'OpenId', value: 'openId' },
    // { label: '微信号', value: 'wechatName' },
    { label: '昵称', value: 'nickName' },
    { label: '头像', value: 'avatar' }
    // { label: '性别', value: 'sex' },
    // { label: '国家', value: 'country' },
    // { label: '省份', value: 'province' },
    // { label: '城市', value: 'city' }
]
interface State {
    mode: 'add' | 'update'
    opened: boolean
    id?: string
}

export const CollectorSetting: React.FunctionComponent<CollectorSettingProps> = ({ onGoBack }) => {
    const { control, watch, setValue, getValues } = useFormContext<FormContainerBlockConfig>()
    const { data, update } = useOtherPlatformList()

    const [{ opened, mode, id }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })

    const [pointer, isUsed] = watch(['pointer', 'weChatCollector.isUsed'])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const { fieldOptions } = useDsListAndFieldOptions(appId, envId, pointer)

    const weChatPublicAccountId = data?.find(item => item.type === 'WECHAT_OFFICIAL_ACCOUNT')?.id

    useEffect(() => {
        if (isUsed) {
            FIELD_LIST.forEach(({ label, value }) => {
                const options = fieldOptions.filter(
                    option => fieldSettingMap[value === 'avatar' ? 'file' : 'text'].includes(option.type) && option.value !== 'ID'
                )
                const fieldId = options.find(option => option.label === label)?.value
                if (!getValues(`weChatCollector.${value}`) && fieldId) {
                    setValue(`weChatCollector.${value}`, fieldId)
                }
            })
        }
    }, [fieldOptions, getValues, isUsed, setValue])

    return (
        <SCxContainer>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '采集微信用户信息', onClick: onGoBack }]} />
            <Divider color="var(--color-gray-200)" />
            <Group label="标题">
                <Controller
                    name="weChatCollector.isUsed"
                    control={control}
                    render={({ field }) => (
                        <SwitchControl label="开启" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                    )}
                />
            </Group>
            {opened && (
                <Group label="标题">
                    <Controller
                        name="weChatCollector.isUsed"
                        control={control}
                        render={({ field }) => (
                            <SwitchControl label="开启" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                        )}
                    />
                </Group>
            )}
            {isUsed && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <Group label="微信公众号">
                        <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
                    </Group>
                    {weChatPublicAccountId && (
                        <>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="接收采集的字段">
                                {FIELD_LIST.map(item => (
                                    <ListItemPaddingByecodeUi key={item.value} alignItems="center" justifyContent="space-between">
                                        <Text>{item.label}</Text>
                                        <Controller
                                            control={control}
                                            name={`weChatCollector.${item.value}`}
                                            render={({ field }) => {
                                                const options = fieldOptions.filter(
                                                    option =>
                                                        fieldSettingMap[item.value === 'avatar' ? 'file' : 'text'].includes(
                                                            option.type
                                                        ) && option.value !== 'ID'
                                                )
                                                return (
                                                    <FieldSelect
                                                        dsId={pointer}
                                                        style={{ width: 180 }}
                                                        options={options}
                                                        value={field.value}
                                                        clearable
                                                        onChange={field.onChange}
                                                    />
                                                )
                                            }}
                                        />
                                    </ListItemPaddingByecodeUi>
                                ))}
                            </Group>
                        </>
                    )}
                </>
            )}
            <WeChatOfficialAccountModal open={opened} id={id} mode={mode} onClose={() => setState({ opened: false })} />
        </SCxContainer>
    )
}
