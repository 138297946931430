import type { Option } from '@byecode/ui'
import { Button, IconFont, MultiSelectDropdown, Popover } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import { FieldTypeTag, getFieldIcon } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { dataInputDisableFieldType } from '../../constants'

const SelectFieldWrapper = styled.div`
    display: flex;
    gap: 12px;
    flex-flow: wrap;
`

const SCxButtonWrapper = styled.div`
    margin: 4px 0;
`

interface SelectFieldProps {
    value?: string[]
    dataSource: DataSourceAbstract
    onChange: (val: string[]) => void
}

export const SelectField: React.FC<SelectFieldProps> = ({ value, onChange, dataSource }) => {
    const [opened, setOpened] = useState(false)

    const { schema } = dataSource
    const options = useMemo(() => {
        return Object.entries(schema).reduce<Option[]>((prev, [id, field]) => {
            if (dataInputDisableFieldType.has(field.type)) {
                return prev
            }
            prev.push({
                label: field.name,
                value: field.id,
                icon: getFieldIcon(field.id, field.type, field.innerType),
                extra: <FieldTypeTag type={field.type} innerType={field.innerType} />
            })
            return prev
        }, [])
    }, [schema])

    const handleOpen = useCallback(() => {
        setOpened(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpened(false)
    }, [])

    const handleRemove = useCallback(
        (ev: React.MouseEvent<HTMLElement>, index: number) => {
            ev.stopPropagation()
            if (value) {
                const newValue = [...value]
                newValue.splice(index, 1)
                onChange(newValue)
            }
        },
        [onChange, value]
    )

    return (
        <SelectFieldWrapper>
            {value?.map((id, index) => {
                const field = schema[id]
                if (field) {
                    return (
                        <Button
                            key={id}
                            radius={100}
                            suffix={
                                <IconFont type="Close" onClick={ev => handleRemove(ev, index)} fill="var(--color-gray-500)" size={16} />
                            }
                            icon={
                                <IconFont
                                    type={getFieldIcon(field.id, field.type, field.innerType)}
                                    fill="var(--color-gray-500)"
                                    size={16}
                                />
                            }
                        >
                            {field.name}
                        </Button>
                    )
                }
                return (
                    <Button
                        key={id}
                        radius={100}
                        suffix={<IconFont type="Close" onClick={ev => handleRemove(ev, index)} fill="var(--color-gray-500)" size={16} />}
                        style={{ color: 'var(--color-red-500)' }}
                    >
                        找不到数据
                    </Button>
                )
            })}
            <Popover opened={opened} withinPortal onChange={setOpened} width={220} position="bottom-start">
                <Popover.Target>
                    <Button
                        disabled={value?.length === options.length}
                        radius={100}
                        onClick={handleOpen}
                        icon={<IconFont type="Add" fill="var(--color-gray-500)" size={16} />}
                    >
                        添加
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <MultiSelectDropdown searchable value={value} onMultiSelect={onChange} options={options} onClose={handleClose} />
                </Popover.Dropdown>
            </Popover>
        </SelectFieldWrapper>
    )
}
