import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

export interface SimpleTableCheckerProps {
    index?: number
    checked?: boolean
    className?: string
    onChange?: (checked: boolean) => void
}

const TableRecordIndex = styled.p`
    color: var(--color-gray-400);
    display: block;
`

const SCxIcon = styled(IconFont)`
    font-size: 18px;
    color: var(--color-gray-400);
`

const SimpleTableCheckerWrapper = styled.div`
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    min-height: 36px;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    background: var(--color-white);
    border-right: 1px solid var(--color-gray-200);
    z-index: 310;
`

export const SimpleTableChecker: React.FC<SimpleTableCheckerProps> = ({ index, className }) => {
    return (
        <SimpleTableCheckerWrapper className={className}>
            {index === undefined ? <SCxIcon type="PropertyNumber" /> : <TableRecordIndex>{index + 1}</TableRecordIndex>}
        </SimpleTableCheckerWrapper>
    )
}
