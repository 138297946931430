import { Checkbox } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Label: React.FC = () => {
    return (
        <Unit title="带 Label 的">
            <Checkbox size="xs" label="男" color="#00b388" />
            <Checkbox size="sm" label="女" color="#00b388" />
            <div>
                <Checkbox label="位置" />
            </div>
        </Unit>
    )
}
