import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const SCxIconWrapper = styled.div<{ radius: number }>`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ radius }) => `${radius}px`};
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    cursor: pointer;
    color: var(--color-gray-400);

    &:hover {
        background-color: var(--color-gray-100);
        color: var(--color-black);
    }
`

const SCxIcon = styled(IconFont)`
    transform: rotate(60deg);
`

interface FindUseLinkProps {
    onClick?: () => void
    radius?: number
}

export const FindUseLink: React.FC<FindUseLinkProps> = ({ onClick, radius = 7 }) => {
    return (
        <SCxIconWrapper radius={radius} onClick={onClick}>
            <SCxIcon type="Cursor" size={16} />
        </SCxIconWrapper>
    )
}
