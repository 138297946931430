import React from 'react'
import styled from 'styled-components'

import { PageList } from '../PageList'
import ToggleLanguage from '../ToggleLanguage'

interface PageCenterProps {}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const PageCenter: React.FunctionComponent<PageCenterProps> = props => {
    return (
        <SCxContainer>
            {/* <div style={{ margin: '12px 12px 0 12px' }}>
                <SegmentedControl value={tab} fullWidth onChange={setTab} data={OPTIONS} />
            </div>
            <SCxItem key="page" hidden={tab !== 'page'}>

            </SCxItem>
            <SCxItem key="layers" hidden={tab !== 'layers'}>
                <PageLayers />
            </SCxItem> */}
            <PageList />
            <ToggleLanguage />
        </SCxContainer>
    )
}

export default PageCenter
