import { Loading } from '@byecode/ui'
import type { StatusFile } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import React from 'react'

import * as SC from './styles'

export interface ImportLoadingProps {
    open: boolean
    fileStatus: StatusFile | null
    hidePercent?: boolean
    onClose: () => void
}

export const ImportLoading: React.FC<ImportLoadingProps> = ({ open, fileStatus, hidePercent, onClose }) => {
    return (
        <Modal
            centered
            padding={0}
            radius={12}
            size={360}
            overlayColor="var(--color-black)"
            overlayOpacity={0.75}
            overlayBlur={3}
            withCloseButton={false}
            opened={open}
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={onClose}
        >
            <SC.Uploading>
                <Loading outlined size={24} />
                <SC.LoadingTitle>正在进行初始化配置...{!hidePercent && `${fileStatus?.percent || 0}%`}</SC.LoadingTitle>
                <SC.LoadingDescribe>请不要退出页面</SC.LoadingDescribe>
            </SC.Uploading>
        </Modal>
    )
}
