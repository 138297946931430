import { Select } from '@byecode/ui'
import { type DataSourceAbstract,DataSourceType } from '@lighthouse/core'
import type { NodeTypes } from '@lighthouse/shared'
import { CollapseBox, getIsAllowedDataSourceAsFlowOption, nodeTypeOptions, SelectDataSource } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSwr from 'swr'

import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../../styles'
import { AttentionField } from './AttentionField'

interface UpdateRecordTriggerConfigureProps {}

export const UpdateRecordTriggerConfigure: React.FC<UpdateRecordTriggerConfigureProps> = () => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: listDs = [] } = useSwr(`/fetch/listDs/${appId}`, () => srv.listDs(appId, envId), {
        revalidateOnFocus: false
    })
    const { control } = useFormContext()
    const options = useMemo(
        () =>
            listDs.reduce<DataSourceAbstract[]>((listDs, item) => {
                if (getIsAllowedDataSourceAsFlowOption(item) && !item.sync && item.type !== DataSourceType.joinDataSource) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [listDs]
    )

    const dataSourceId: string = useWatch({
        control,
        name: 'config.dataSourceId'
    })

    return (
        <>
            <SC.Container>
                <CollapseBox label="触发器配置">
                    <SC.Content>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel>类型</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.dataSourceId"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <SelectDataSource
                                                {...field}
                                                searchable
                                                placeholder="请选择数据表"
                                                // disabled={!!dataSourceId}
                                                value={dataSourceId}
                                                dataSourceList={options}
                                                onChange={async dsId => {
                                                    const isConfirmed = field.value === undefined ? true : await promptSwitchDataSource()
                                                    if (isConfirmed) {
                                                        field.onChange(dsId)
                                                    }
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    </SC.Content>
                </CollapseBox>
            </SC.Container>
            {dataSourceId && <AttentionField dataSourceId={dataSourceId} />}
        </>
    )
}
