import { IconFont, Switch } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { Group, Text } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

import { StyledIconWrapper } from './styles'

interface RepeatProps {
    value?: boolean
    compact?: boolean
    onChange?: (v: boolean) => void
    disabled?: boolean
}

export const Repeat: FC<RepeatProps> = ({ value, onChange, compact, ...rest }) => (
    <ListItem4ByecodeUi enableHover enablePadding compact={compact} justifyContent="space-between" alignItems="center">
        <Group spacing="xs" noWrap>
            <Text>不可重复</Text>
        </Group>
        <Switch checked={value} onChange={e => onChange?.(e.currentTarget.checked)} {...rest} />
    </ListItem4ByecodeUi>
)
