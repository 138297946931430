import { IconFont } from '@byecode/ui'
import type { PopoverProps } from '@byecode/ui/components/Popover'
import React from 'react'

import * as SC from './styles'
import type { PopoverItemProps } from './types'

/**
 *
 *
 * @param {*} { children }
 * @return {*}
 */
export const RuleBox: React.FC<PopoverProps> = ({ children, ...restProps }) => {
    return <SC.StyledPopover {...restProps}>{children}</SC.StyledPopover>
}

/**
 *
 *
 * @param {*} { icon, iconColor, text }
 * @return {*}
 */
export const RuleItem: React.FC<PopoverItemProps> = ({ icon, iconColor, text }) => {
    return (
        <SC.Item>
            {!!icon && <IconFont type={icon} color={iconColor || ''} />}
            <SC.Text icon={icon}>{text}</SC.Text>
        </SC.Item>
    )
}
