import React from 'react'
import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter, redirect } from 'react-router-dom'

import { SpaceQuota } from '@/components/SpaceQuota'
import TemplateAppCreator from '@/components/TemplateAppCreator'
import http from '@/http'
import { DefaultLayout } from '@/layout/default'
import Login from '@/views/Account/Login'
import PerfectInfo from '@/views/Account/PerfectInfo'
import { AssetsDemo } from '@/views/AssetsDemo'
import { Byecode } from '@/views/Byecode'
import Application from '@/views/Byecode/Application/[id]'
import { ApplicationSettings } from '@/views/Byecode/Application/[id]/Settings'
import Workbench from '@/views/Byecode/Workbench'
import ApplicationList from '@/views/Byecode/Workbench/List'
import SpaceMember from '@/views/Byecode/Workbench/Member'
// import { SpaceSetting } from '@/containers/SpaceSetting'
import SpaceSetting from '@/views/Byecode/Workbench/Setting'
import { WorkSpace } from '@/views/Byecode/WorkSpace'
import { ComponentsDemo } from '@/views/ComponentsDemo'

import NotFound from '../views/404'
import { aggregateRoute } from './aggregate'
import { analyzeRoute } from './analyze'
import { dataSourceRoute } from './dataSource'
import { flowRootRoute, flowRoute } from './flow'
import { otherPlatformsRoute } from './otherPlatforms'
import { pageRoute } from './page'
import { templateRoute } from './template'

// const Workbench = React.lazy(() => import('@/views/Byecode/Workbench'))
// const ComponentsDemo = React.lazy(() => import('@/views/ComponentsDemo').then(m => ({ default: m.ComponentsDemo })))
// const Login = React.lazy(() => import('@/views/Account/Login'))
// const PerfectInfo = React.lazy(() => import('@/views/Account/PerfectInfo'))
// const TemplateAppCreator = React.lazy(() => import('@/components/TemplateAppCreator'))

export const routes: RouteObject[] = [
    {
        path: '/404',
        element: <NotFound />
    },
    {
        path: '/__byecode_components',
        element: <ComponentsDemo />
    },
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            templateRoute,
            {
                path: '/__byecode_assets',
                element: <AssetsDemo />
            },
            {
                path: '/account/login',
                element: <Login />
            },
            {
                path: '/account/perfect',
                element: <PerfectInfo />
            },

            {
                path: '/',
                element: <Byecode />,
                children: [
                    {
                        path: '/create/templateApp',
                        element: <TemplateAppCreator />
                    },
                    {
                        path: '/',
                        element: <WorkSpace />
                    },
                    {
                        path: 'workbench',
                        element: <Workbench />,
                        loader: () => {
                            // if(http.defaults.headers.common['appId']){
                            //     http.defaults.headers.common['appId'] = ''
                            // }
                            return null
                        },
                        children: [
                            {
                                path: 'applications',
                                element: <ApplicationList />
                            },
                            {
                                path: 'member',
                                element: <SpaceMember />
                            },
                            {
                                path: 'setting',
                                element: <SpaceSetting />
                            },
                            {
                                path: 'quota',
                                element: <SpaceQuota />
                            }
                        ]
                    },
                    // 应用同级路由此children中追加，修改如需loader，请在views中对应组件补充逻辑
                    {
                        path: ':applicationId',
                        element: <Application />,
                        // 页面同级路由此children追加
                        children: [
                            {
                                index: true,
                                loader: () => redirect('page')
                            },
                            pageRoute,
                            flowRoute,
                            aggregateRoute,
                            dataSourceRoute,
                            // userRoute,
                            flowRootRoute,
                            otherPlatformsRoute,
                            analyzeRoute,
                            {
                                path: 'settings',
                                element: <ApplicationSettings />
                            },
                            { path: '404', element: <NotFound /> }
                        ],
                        loader: ({ params: { applicationId = '' } }) => {
                            http.defaults.headers.common['appId'] = applicationId
                            return null
                        }
                    }
                ]
            }

            // {
            //     path: '/workbench',
            //     element: <WorkSpace />,
            //     children: [
            //         {
            //             path: ':spaceId',
            //             element: <WorkSpaceDetail />,
            //             // 应用同级路由此children中追加，修改如需loader，请在views中对应组件补充逻辑
            //             children: [
            //                 appGraph,
            //                 appTemplateRoute
            //             ],
            //             loader: ({ params: { spaceId = '' } }) => {
            //                 http.defaults.headers.common['spaceId'] = spaceId
            //                 return null
            //             }
            //         }
            //     ]
            // }
        ]
    }
]

export const router = createBrowserRouter(routes)
