import { PRESET_PALETTES } from '../../constants/color'

export const colorList = Object.entries(PRESET_PALETTES)

// 获得一组用户图表主题渲染的颜色数组
export const getColors = (currentColor: string): string[] => {
    const colors = colorList

    const newColors = colors.reduce<string[]>((current, [key, value]) => {
        if (key === currentColor) {
            return current
        }
        return [...current, value]
    }, [])

    return [currentColor, ...newColors]
}

// 获取一个随机Icon
export function getRandomColor() {
    const colors = Object.entries(PRESET_PALETTES)
    const num = Math.floor(Math.random() * colors.length)
    return colors[num] ?? ['blue', PRESET_PALETTES['blue']]
}
