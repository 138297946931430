import { IconFont, Popover } from '@byecode/ui'
import { interleave } from '@lighthouse/tools'
import cls from 'classnames'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { getTreeOptionLastLevel } from '../../utils'
import * as SC from './styles'

export interface CascadeSelectValue {
    value: string
    label: string
    icon?: string
    extra?: React.ReactNode
}

export type CascadeValue = string[]

export interface CascadeSelectOption extends CascadeSelectValue {
    children?: CascadeSelectOption[]
}

interface CascadeSelectListProps {
    options: CascadeSelectOption[]
    value: string[]
    selectValue: string[]
    level?: number
    reaches: number
    parentActive?: boolean
    onChange?: (val: CascadeValue) => void
    onSelectChange?: (val: CascadeValue) => void
    onClose?: () => void
}

interface CascadeSelectProps {
    placeholder?: string
    className?: string
    style?: React.CSSProperties
    options: CascadeSelectOption[]
    defaultValue?: CascadeValue
    value?: CascadeValue
    onChange?: (val: CascadeValue) => void
}

interface CascadeSelectListState {
    selectOptionItem?: CascadeSelectOption
    selectItemEl?: HTMLDivElement
    open: boolean
}

export const CascadeSelectList: React.FC<CascadeSelectListProps> = ({
    options,
    value,
    selectValue,
    reaches,
    level = 0,
    parentActive = true,
    onChange,
    onSelectChange,
    onClose
}) => {
    // const [open, setOpen] = useState(false)
    const [state, setState] = useState<CascadeSelectListState>({
        selectOptionItem: undefined,
        selectItemEl: undefined,
        open: false
    })
    const { selectOptionItem, selectItemEl, open } = state

    const handlePopoverChange = useCallback(
        (val: boolean) => {
            setState({
                selectItemEl,
                selectOptionItem,
                open: val
            })
        },
        [selectItemEl, selectOptionItem]
    )

    const handleSelect = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>, val: CascadeSelectOption) => {
            if (reaches === level) {
                onClose?.()
                onChange?.([...selectValue, val.value])
                return
            }
            const newValue = selectValue.slice(0, level + 1)
            newValue[level] = val.value
            onSelectChange?.(newValue)
            setState({
                selectItemEl: ev.currentTarget as HTMLDivElement,
                selectOptionItem: val,
                open: true
            })
        },
        [level, onChange, onClose, onSelectChange, reaches, selectValue]
    )

    return (
        <>
            <Popover opened={open} onChange={handlePopoverChange} width="target" position="right-start">
                <SC.List>
                    {options.map(item => {
                        const active = parentActive && value.includes(item.value)
                        return (
                            <SC.ListItem key={item.value} active={active} onClick={ev => handleSelect(ev, item)}>
                                <SC.ListItemContent>
                                    {item.icon && <SC.Icon type={item.icon} />}
                                    <SC.ListItemText>{item.label}</SC.ListItemText>
                                </SC.ListItemContent>
                                <SC.ListItemExtra>
                                    {active ? <IconFont size={16} fill="var(--color-main)" type="Tick" /> : item.extra}
                                    {item.children && <SC.Icon size={16} type="ArrowRightSmall" />}
                                </SC.ListItemExtra>
                            </SC.ListItem>
                        )
                    })}
                </SC.List>
                <Popover.AnchorEl anchorEl={selectItemEl} />
                <Popover.Dropdown>
                    {selectOptionItem?.children && selectItemEl && (
                        <CascadeSelectList
                            parentActive={parentActive && value.includes(selectOptionItem.value)}
                            options={selectOptionItem.children}
                            value={value}
                            selectValue={selectValue}
                            reaches={reaches}
                            level={level + 1}
                            onChange={onChange}
                            onSelectChange={onSelectChange}
                            onClose={onClose}
                        />
                    )}
                </Popover.Dropdown>
            </Popover>
        </>
    )
}

export const CascadeSelect: React.FC<CascadeSelectProps> = ({
    className,
    style,
    placeholder = '请选择',
    value = [],
    options,
    onChange
}) => {
    const [open, setOpen] = useState(false)
    const reaches = getTreeOptionLastLevel(options)
    // const [state, setState] = useImmer<CascadeSelectState>({
    //     value: data,
    // })
    const [data, setData] = useState(value)
    const [selectValue, setSelectValue] = useState(value)

    useUpdateEffect(() => {
        setData(value)
        setSelectValue(value)
    }, [value])

    const handleChange = useCallback(
        (val: CascadeValue) => {
            setData(val)
            onChange?.(val)
        },
        [onChange]
    )

    const handleSelectChange = useCallback((val: string[]) => {
        setSelectValue(val)
    }, [])

    const getTagNode = useCallback((ids: string[], opts: CascadeSelectOption[], nodes: React.ReactNode[] = []): React.ReactNode[] => {
        if (ids.length === 0) {
            return nodes
        }
        const id = ids[0]
        const opt = find(item => item.value === id, opts)
        if (!opt) {
            nodes.push(<SC.ErrorTag>未找到数据</SC.ErrorTag>)
            return nodes
        }
        if (opt) {
            nodes.push(
                <SC.ValueTag>
                    {opt?.icon && <SC.Icon size={16} type={opt.icon} />}
                    <SC.ValueTagText>{opt.label}</SC.ValueTagText>
                </SC.ValueTag>
            )
        }
        if (opt.children) {
            const newIds = ids.slice(1)
            return getTagNode(newIds, opt.children, nodes)
        }
        return nodes
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const tagContent = useMemo(() => {
        return interleave(getTagNode(data, options), <SC.Icon size={16} type="ArrowRightSmall" />)
    }, [data, getTagNode, options])

    return (
        <SC.SCxContainer className={className} style={style}>
            <Popover opened={open} withinPortal onChange={setOpen} width="target">
                <Popover.Target>
                    <SC.ValueContainer tabIndex={0} className={cls({ active: open })}>
                        {data && data.length > 0 ? (
                            <>
                                <SC.ValueContent>{tagContent}</SC.ValueContent>
                                <SC.Icon size={16} type="ArrowDownSmall" />
                            </>
                        ) : (
                            <>
                                <SC.PlaceHolder>{placeholder}</SC.PlaceHolder>
                                <SC.Icon size={16} type="ArrowDownSmall" />
                            </>
                        )}
                    </SC.ValueContainer>
                </Popover.Target>
                <Popover.Dropdown>
                    <CascadeSelectList
                        options={options}
                        value={data}
                        selectValue={selectValue}
                        reaches={reaches ?? 0}
                        onSelectChange={handleSelectChange}
                        // anchorEl={anchorElRef.current}
                        onChange={handleChange}
                        onClose={handleClose}
                    />
                </Popover.Dropdown>
            </Popover>
        </SC.SCxContainer>
    )
}
