import { IconFont } from '@byecode/ui'
import { getNodeInfo } from '@lighthouse/shared'
import React, { memo, useMemo } from 'react'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'

export interface EndNodeProps {
    isConnectable?: boolean
}

const SCxEndNodeWrapper = styled.div`
    width: 248px;
    position: relative;
    align-items: center;
    cursor: default;
`

const SCxEndNodeContent = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
`

const SCxActionIconWrapper = styled.div`
    display: flex;
    width: 56px;
    height: 56px;
    margin-left: 4px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-100);
    color: var(--color-gray-300);
    border-radius: 50%;
    border: 1px solid #dfdfdf;
`

const SCxActionIcon = styled(IconFont)``

const SCxActionContentWrapper = styled.div`
    padding: 8px 4px;
    font-weight: 400;
`

const SCxActionDescription = styled.div`
    font-size: 14px;
    color: var(--color-gray-600);
`

const EndNode: React.FC<EndNodeProps> = ({ isConnectable }) => {
    const nodeInfo = useMemo(() => getNodeInfo('END'), [])
    return (
        <SCxEndNodeWrapper>
            <Handle
                type="target"
                position={Position.Top}
                id="handle"
                style={{ top: -4, bottom: 'auto', opacity: 0, left: 30 }}
                isConnectable={isConnectable}
            />
            <SCxEndNodeContent>
                <SCxActionIconWrapper>
                    <SCxActionIcon size={30} type={nodeInfo.icon} /* fill={nodeInfo.color}  */ />
                </SCxActionIconWrapper>
                <SCxActionContentWrapper>
                    <SCxActionDescription>流程结束</SCxActionDescription>
                </SCxActionContentWrapper>
            </SCxEndNodeContent>
        </SCxEndNodeWrapper>
    )
}

export default memo(EndNode)
