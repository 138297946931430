import { Select } from '@byecode/ui'
import { Pagination } from '@mantine/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { RunStatus } from '@/services/types'
import { useFlowLogsWithPagination } from '@/shared/reusable'

import { StatusTable } from './components/StatusTable'

export interface FlowListProps {
    appId: string
}

const SCxRunLogWrapper = styled.div`
    height: calc(100% - 100px);
`

const SCxAllFlowHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 12px;
    /* margin-bottom: 18px; */
`

const SCxAllFlowTitle = styled.h2`
    font-size: 20px;
`

const SCxStatusSelectWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SCxStatusSelectLabel = styled.div`
    white-space: nowrap;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
`

const SCxStatusTableWrapper = styled.div`
    height: calc(100% - 56px);
`

const statusOptions = [
    { label: '全部', value: 'all' },
    { label: '运行中', value: 'RUNNING' },
    { label: '已完成', value: 'SUCCESS' },
    { label: '已失败', value: 'FAILED' }
]

export const RunLog: React.FC<FlowListProps> = ({ appId }) => {
    const [status, setStatus] = useState<RunStatus>('all')
    const { workflows, totalPage, setCurrentPage } = useFlowLogsWithPagination({ state: status })

    const handleStatusChange = (value: string) => {
        setStatus(value as RunStatus)
        setCurrentPage(1)
    }

    return (
        <SCxRunLogWrapper>
            <SCxAllFlowHeader>
                <SCxAllFlowTitle>运行日志</SCxAllFlowTitle>
                <SCxStatusSelectWrapper>
                    <SCxStatusSelectLabel>状态：</SCxStatusSelectLabel>
                    <Select value={status} style={{ width: 140 }} options={statusOptions} onChange={handleStatusChange} />
                </SCxStatusSelectWrapper>
            </SCxAllFlowHeader>
            <SCxStatusTableWrapper>
                <StatusTable showNameField data={workflows} />
                <Pagination style={{ marginTop: 16 }} size="md" total={totalPage} onChange={page => setCurrentPage(page)} />
            </SCxStatusTableWrapper>
        </SCxRunLogWrapper>
    )
}
