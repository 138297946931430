import { Button, IconFont } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Icon: React.FC = () => {
    return (
        <Unit title="图标按钮">
            <Button type="primary" disabled icon={<IconFont type="Add" size={16} />} />
            <Button type="text" icon={<IconFont type="Add" size={16} />} />
            <Button type="text" icon={<IconFont type="Setting" size={16} />}>
                设置一下
            </Button>
            <Button type="text" size="sm" icon={<IconFont type="Add" size={16} />}>
                添加
            </Button>
            <Button disabled icon={<IconFont type="User" />} />
            <Button danger icon={<IconFont type="Trash" />}>
                按钮禁用
            </Button>
            <Button type="primary" size="xs" danger icon={<IconFont type="Add" />} htmlType="submit">
                点我提示
            </Button>
        </Unit>
    )
}
