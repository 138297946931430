import { Button, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import React, { useCallback } from 'react'
import styled from 'styled-components'

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

export default function NotFoundTemplate() {
    // const navigate = useNavigate()

    const handleBtnClick = useCallback(() => {
        window.location.href = 'https://www.byecode.com'
    }, [])

    return (
        <SCxContainer>
            <Image width={134} src={getAssetUrl('common', 'logo_dark.png')} />
            <Text size={24} weight={500} color="var(--color-black)">
                找不到应用模板
            </Text>
            <Text size={16} color="var(--color-gray-500)">
                应用或模板链接已被删除
            </Text>
            <Button type="primary" size="lg" onClick={handleBtnClick}>
                返回主页
            </Button>
            {/* <NoFound
                title="找不到应用模板"
                desc="应用或模板链接已被删除"
                image={getAssetUrl('common', 'logo_dark.png')}
                btnText="返回主页"
                imgWidth={134}
                styles={{
                    title: {
                        FontSize: '24px',
                        fontWeight: 500
                    },
                }}
                onBtnClick={handleBtnClick}
            /> */}
        </SCxContainer>
    )
}
