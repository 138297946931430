import { getAssetUrl } from '@lighthouse/assets'
import { nanoid } from '@lighthouse/tools'

import type { Template, TemplateList } from './types'

export const template1: Template = {
    id: nanoid(),
    name: '任务协作',
    icon: 'Cube',
    describeText: null,
    createdBy: 'kidrue',
    createdTime: '',
    primaryImage: 'https://github.com/kidrue/picture/blob/main/menhera.png?raw=true',
    describe: '和你的团队基于任务的协作协作协作协作',
    describeImageList: ['https://github.com/kidrue/picture/blob/main/menhera.png?raw=true']
}

export const template2: Template = {
    id: nanoid(),
    name: '项目管理',
    icon: 'Cube',
    describe: '管理你的项目活动',
    describeText: null,
    primaryImage: 'https://github.com/kidrue/picture/blob/main/menhera.png?raw=true',
    createdBy: 'kidrue',
    createdTime: '',
    describeImageList: ['https://github.com/kidrue/picture/blob/main/menhera.png?raw=true']
}
export const blankTemplateWebsiteId = 'blankWebsite'

export const blankTemplateWebsite: Template = {
    id: blankTemplateWebsiteId,
    name: '从 0 到 1 构建',
    icon: 'Desktop',
    describe: '同时适配电脑端和移动端',
    describeText: null,
    primaryImage: getAssetUrl('common', 'BlankCreate.svg'),
    createdBy: '',
    createdTime: '',
    describeImageList: [],
    isBlankTemplate: true,
    iconColor: 'var(--color-black)',
    iconBackgroundColor: 'var(--color-gray-100)'
}
// export const blankTemplateWxAppletId = 'blankWxApplet'

// export const blankTemplateWxApplet: Template = {
//     id: blankTemplateWxAppletId,
//     name: '创建小程序',
//     icon: 'DeviceMobile',
//     describe: '微信、阿里系小程序',
//     describeText: '',
//     primaryImage: '',
//     createdBy: '',
//     createdTime: '',
//     describeImageList: [],
//     isBlankTemplate: true,
//     isNoDispark: true,
//     iconColor: 'var(--color-black)',
//     iconBackgroundColor: 'var(--color-gray-100)'
// }
export const blankTemplateExcelId = 'excel'

export const blankTemplateExcel: Template = {
    id: blankTemplateExcelId,
    name: 'Excel 生成',
    icon: 'ArrowLineDown',
    describe: '支持 xls、xlsx、csv 格式',
    describeText: null,
    primaryImage: getAssetUrl('common', 'ExcelCreate.svg'),
    createdBy: '',
    createdTime: '',
    describeImageList: [],
    isBlankTemplate: true,
    iconColor: 'var(--color-black)',
    iconBackgroundColor: 'var(--color-gray-100)'
}

export const blankTemplateAIId = 'ai'

export const blankTemplateAI: Template = {
    id: blankTemplateAIId,
    name: 'AI 生成',
    icon: 'Magic',
    describe: '描述需求让 AI 帮你生成应用',
    describeText: null,
    primaryImage: getAssetUrl('common', 'AICreat.svg'),
    createdBy: '',
    createdTime: '',
    describeImageList: [],
    isBlankTemplate: true,
    iconColor: 'var(--color-black)',
    iconBackgroundColor: 'var(--color-gray-100)'
}

export const kindList: TemplateList = [
    { categoryId: '1', name: '项目', list: [template1, template2] },
    { categoryId: '2', name: '研发', list: [template2] },
    { categoryId: '3', name: '电商', list: [template1, template1, template1, template1, template1, template1, template1] },
    { categoryId: '4', name: '产品', list: [template2] },
    { categoryId: '5', name: '销售', list: [template1, template1, template1, template1, template1, template1, template1] },
    { categoryId: '6', name: '行政', list: [template2] },
    { categoryId: '7', name: '个人', list: [template1, template1, template1, template1, template1, template1, template1] },
    { categoryId: '8', name: 'HR', list: [template2] },
    { categoryId: '9', name: '其他', list: [template2] }
]

// export const blankTemplate: TemplateList = [
//     { categoryId: 'recommend', name: '推荐', list: [blankTemplateWebsite, blankTemplateWxApplet] }
// ]
export const blankTemplate: TemplateList = [{ categoryId: 'create', name: '创建', list: [blankTemplateWebsite, blankTemplateExcel] }]
