import { Divider, Popover, Tooltip } from '@byecode/ui'
import type { GroupOption } from '@lighthouse/shared'
import { GroupList } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Search } from '../Search'
import * as CM from '../style'

export type FilterParams = {
    searchWord: string
    groupId: string
}
interface PageListHeaderProps {
    options: GroupOption[]
    value: FilterParams
    onBack?: () => void
    onChange?: (value: FilterParams) => void
}

const SCxIconTarget = styled.div`
    display: flex;
    align-items: center;
    &:hover {
        ${CM.Icon} {
            color: var(--color-black);
        }
    }

    &.active {
        ${CM.Icon} {
            color: var(--color-main);
        }
    }
`

const PageListHeader: React.FunctionComponent<PageListHeaderProps> = ({ options, value, onChange, onBack }) => {
    const { searchWord, groupId } = value
    const [opened, setOpened] = useState(false)

    const handleChange = useCallback(
        (item: GroupOption) => {
            onChange?.({ searchWord, groupId: item.value })
            setOpened(false)
        },
        [onChange, searchWord]
    )

    return (
        <CM.Header>
            <Search
                value={searchWord}
                suffix={
                    <Popover position="bottom-start" opened={opened} width={200} withinPortal onChange={setOpened}>
                        <Popover.Target>
                            <div>
                                <Tooltip title="筛选" withArrow offset={2} placement="top">
                                    <CM.IconWrapper size={32}>
                                        <SCxIconTarget className={cls({ active: groupId !== 'all' })}>
                                            <CM.Icon type="Filter" size={16} isAction />
                                        </SCxIconTarget>
                                    </CM.IconWrapper>
                                </Tooltip>
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown style={{ width: 200 }}>
                            <GroupList value={groupId} options={options} maxHeight={600} onItem={handleChange} />
                        </Popover.Dropdown>
                    </Popover>
                }
                onChange={val => onChange?.({ groupId, searchWord: val })}
                onBack={onBack}
            />
            <Divider style={{ margin: '8px 0 8px 0' }} />
        </CM.Header>
    )
}

export default PageListHeader
