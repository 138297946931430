import { flex, SegmentedControl } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import {} from '@mantine/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import type { FieldOption } from '../../FieldSelect'
import { FieldSelect } from '../../FieldSelect'
import * as CM from '../styles'
import * as SC from './styles'
import type { ButtonConfig } from './type'

type ConfigItemProps = Omit<ButtonConfig, 'name'> & {
    data: FieldOption[]
    value: string
    onChange: (value: string) => void
}

export const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
`

export const ConfigItem: React.FC<ConfigItemProps> = React.memo(({ value, placeHolder, label, type, data, onChange }) => {
    const ele = useMemo(() => {
        switch (type) {
            case 'input': {
                return (
                    <CM.BlockSettingsInput
                        // width="100%"
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        placeholder={placeHolder}
                    />
                )
            }
            case 'select': {
                return (
                    <FieldSelect
                        styles={{ root: { flex: 1 } }}
                        value={value}
                        options={data}
                        onChange={val => {
                            onChange(val ?? '')
                        }}
                        placeholder={data.length === 0 ? `暂无可选${label}` : placeHolder}
                    />
                )
            }
            case 'segmented': {
                return <SegmentedControl fullWidth data={data} value={value} onChange={v => onChange(v)} />
            }

            default: {
                return null
            }
        }
    }, [data, label, onChange, placeHolder, type, value])

    return (
        <ListItem4ByecodeUi compact alignItems="center" justifyContent="space-between">
            <SC.Text style={{ marginRight: 12 }}>{label}</SC.Text>
            <SCxRightFill>{ele}</SCxRightFill>
        </ListItem4ByecodeUi>
    )
})
