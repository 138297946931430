import { Button, Flex, IconFont } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { FILE_EXCEL_ACCEPT, UploadArea } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'

export interface UploadProps {
    onClose?: () => void
    onImport?: (file: File) => void
}

export const Upload: React.FC<UploadProps> = ({ onClose, onImport }) => {
    const [file, setFile] = useState<File | null>(null)

    const handleDownload = useCallback(() => {
        const a = document.createElement('a')
        a.href = getAssetUrl('common', 'user_template.xlsx')
        a.download = '用户导入模板（ByeCode）.xlsx'
        a.click()
        a.remove()
    }, [])

    const handleFileClear = useCallback(() => {
        setFile(null)
    }, [])

    const handleFileLoaded = useCallback((file: File) => {
        setFile(file)
    }, [])

    const handleImport = useCallback(() => {
        if (file) {
            onImport?.(file)
        }
    }, [file, onImport])

    return (
        <>
            <SC.Title>导入用户</SC.Title>
            <Flex gap="20px" direction="column" style={{ marginBottom: 20 }}>
                <SC.Box>
                    <SC.SecondTitle>1.下载导入模版</SC.SecondTitle>
                    <SC.Description>根据提示信息完善表格内容</SC.Description>
                    <Button
                        size="lg"
                        onClick={handleDownload}
                        icon={<IconFont type="ImportExcel" size={20} fill="var(--color-gray-400)" />}
                    >
                        下载空的模版表格
                    </Button>
                </SC.Box>
                <SC.Box>
                    <SC.SecondTitle>2.上传完善后的模板</SC.SecondTitle>
                </SC.Box>
                <UploadArea
                    file={file}
                    sizeLimit={5}
                    accept={FILE_EXCEL_ACCEPT}
                    description={
                        <>
                            <SC.UploaderAreaDescription>下载模板并完善信息后，可直接将文件拖拽到此处进行上传</SC.UploaderAreaDescription>
                            <SC.UploaderAreaDescription>支持格式：xls、.xlsx</SC.UploaderAreaDescription>
                        </>
                    }
                    onFileClear={handleFileClear}
                    onFileLoaded={handleFileLoaded}
                />
            </Flex>

            <SC.Footer>
                <Button size="lg" onClick={onClose}>
                    取消
                </Button>
                <Button size="lg" type="primary" onClick={handleImport}>
                    确认导入
                </Button>
            </SC.Footer>
        </>
    )
}
