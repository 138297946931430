import type { BaseModalProps } from '@byecode/ui/components/Modal'
import type { SpaceVersion } from '@lighthouse/core'
import type { ModalProps } from '@mantine/core'

import type { SpaceVersionStaticInfo } from './types'

export const spaceVersionThemeMap: Record<SpaceVersion, { background: string; image: string }> = {
    FREE: {
        background: 'linear-gradient(180deg, #F2F4F7 0%, rgba(242, 244, 247, 0.08) 100%)',
        image: ''
    },
    BASIC: {
        background: 'linear-gradient(180deg, #E9F5FF 0%, rgba(233, 245, 255, 0.08) 100%)',
        image: ''
    },
    STANDARD: {
        background: 'linear-gradient(180deg, #FFF2F5 0%, rgba(255, 242, 245, 0.08) 100%)',

        image: ''
    },
    ADVANCED: {
        background: '',
        image: ''
    },
    PROFESSIONAL: {
        background: '',
        image: ''
    },
    ENTERPRISE: {
        background: '',
        image: ''
    },
    ULTIMATE: {
        background: '',
        image: ''
    },
    I_CLICK_FREE: {
        background: 'linear-gradient(180deg, #F2F4F7 0%, rgba(242, 244, 247, 0.08) 100%)',
        image: ''
    },
    I_CLICK_BASIC: {
        background: 'linear-gradient(180deg, #E9F5FF 0%, rgba(233, 245, 255, 0.08) 100%)',
        image: ''
    },
    I_CLICK_STANDARD: {
        background: 'linear-gradient(180deg, #FFF2F5 0%, rgba(255, 242, 245, 0.08) 100%)',

        image: ''
    },
    I_CLICK_ADVANCED: {
        background: '',
        image: ''
    },
    I_CLICK_PROFESSIONAL: {
        background: '',
        image: ''
    },
    I_CLICK_ENTERPRISE: {
        background: '',
        image: ''
    },
    I_CLICK_ULTIMATE: {
        background: '',
        image: ''
    }
}

export const spaceVersionOrderInfoMap: Record<SpaceVersion, string> = {
    FREE: '',
    BASIC: '空间升级_基础版_年付',
    STANDARD: '空间升级_标准版_年付',
    ADVANCED: '空间升级_高级版_年付',
    PROFESSIONAL: '空间升级_专业版_年付',
    ENTERPRISE: '',
    ULTIMATE: '',
    I_CLICK_FREE: '',
    I_CLICK_BASIC: '空间升级_基础版_年付',
    I_CLICK_STANDARD: '空间升级_标准版_年付',
    I_CLICK_ADVANCED: '空间升级_高级版_年付',
    I_CLICK_PROFESSIONAL: '空间升级_专业版_年付',
    I_CLICK_ENTERPRISE: '',
    I_CLICK_ULTIMATE: ''
}

export const versionModalStyles: BaseModalProps['styles'] = {
    header: {
        borderColor: 'transparent'
    },
    modal: {
        margin: '0 60px',
        maxWidth: 1692,
        height: '90%',
        width: 'calc(100% - 120px)',
        overflow: 'hidden'
    },
    body: {
        padding: 0
    }
}

export const modalStyles: ModalProps['styles'] = {
    close: {
        top: 30,
        right: 26,
        zIndex: 10,
        cursor: 'pointer',
        '>*': {
            width: 32,
            height: 32
        }
    },
    header: {
        height: 0,
        marginBottom: 0
    }
}

export const ALL_SPACE_VERSION: SpaceVersionStaticInfo[] = [
    {
        describe: '适用于个人构建项目 & 探索 ByeCode',
        publishedAppNumLimit: 1,
        accessUserLimit: 500,
        internalUserLimit: 0,
        externalUserLimit: 10,
        accessFlow: 1024,
        recordsLimit: 500,
        storageLimit: 1024,
        pageLimit: 3,
        version: 'FREE',
        price: 0,
        smsLimit: 0,
        coreFunction: ['组件库', '自定义用户角色', '工作流']
    },
    {
        describe: '适用于名片 & 微网站',
        price: 360,
        publishedAppNumLimit: 1,
        accessUserLimit: 10000,
        internalUserLimit: 0,
        externalUserLimit: 20,
        accessFlow: 10240,
        recordsLimit: 2000,
        storageLimit: 1024 * 5,
        pageLimit: 10,
        version: 'BASIC',
        coreVersion: 'FREE',
        smsLimit: 50,
        coreFunction: ['SEO', '应用访问统计分析', '绑定自有域名']
    },
    {
        describe: '适用于网站 & 轻量业务应用',
        price: 1500,
        publishedAppNumLimit: 2,
        accessUserLimit: 200000,
        internalUserLimit: 0,
        externalUserLimit: 100,
        accessFlow: 1024 * 100,
        recordsLimit: 10000,
        storageLimit: 1024 * 100,
        pageLimit: 30,
        version: 'STANDARD',
        coreVersion: 'BASIC',
        smsLimit: 200,
        coreFunction: ['多语言版本', '渠道统计', '隐藏底部制作信息']
    },
    {
        describe: '适用于网站门户、小程序 & 业务管理应用',
        price: 6800,
        publishedAppNumLimit: 2,
        accessUserLimit: -1,
        internalUserLimit: 10,
        externalUserLimit: 2000,
        accessFlow: 1024 * 1000,
        recordsLimit: 500000,
        storageLimit: 1024 * 500,
        pageLimit: -1,
        version: 'ADVANCED',
        coreVersion: 'STANDARD',
        smsLimit: 1000,
        coreFunction: ['微信登录', '支付能力', '附件存储增购']
    },
    {
        describe: '适用于业务复杂的小程序、私域中心 & 管理系统',
        price: 12800,
        publishedAppNumLimit: 5,
        accessUserLimit: -1,
        internalUserLimit: 30,
        externalUserLimit: -1,
        accessFlow: 2000  * 1024,
        recordsLimit: 1000000,
        storageLimit: 1000 * 1024,
        pageLimit: -1,
        version: 'PROFESSIONAL',
        coreVersion: 'ADVANCED',
        smsLimit: 2000,
        coreFunction: ['移动端 & Web PWA app', '数据批量处理能力', '专属客户成功专家']
    }
]
