import { Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { DingTalkRobotFormState, DingTalkRobotMode } from './type'

interface DingTalkRobotProps {
    id?: string
    mode: DingTalkRobotMode
    onClose?: () => void
}

const initData: DingTalkRobotFormState = {
    id: '',
    webhook: '',
    secret: '',
    name: ''
}
const DingTalkRobot: React.FunctionComponent<DingTalkRobotProps> = ({ id, mode, onClose }) => {
    const appId = useCurrentAppID()
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()
    const [loading, setLoading] = useState(false)
    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform
            ? { ...otherPlatform?.config, name: otherPlatform.name, id, secret: mode === 'add' ? '' : PLACEHOLDER_PASSWORD }
            : initData
    }, [id, mode, otherPlatforms])

    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors }
    } = useForm<DingTalkRobotFormState>({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: DingTalkRobotFormState) => {
            const { name, webhook, secret } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'DING_TALK_ROBOT',
                name,
                params: {
                    webhook,
                    secret
                }
            })
            setLoading(false)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(async () => {
        if (!id) {
            return
        }
        const title = getValues('name')
        const isConfirm = await Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['DING_TALK_ROBOT']}-${title}」。`
        })

        if (isConfirm) {
            const isSuccess = await srv.deleteIntegration(id)
            if (isSuccess) {
                otherPlatformUpdate()
                Toast.success(`已删除「${otherPlatformNameMap['DING_TALK_ROBOT']}-${title}」`)
                onClose?.()
            }
        }
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: DingTalkRobotFormState) => {
            const { id, name, webhook, secret } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'DING_TALK_ROBOT',
                name,
                id,
                params: {
                    webhook,
                    secret: secret === PLACEHOLDER_PASSWORD ? undefined : secret
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} type="BrandLogoDingding" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}：群机器人 账号</SC.Title>
                <SC.Item>
                    <SC.Text>
                        钉钉机器人名称
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} placeholder="请输入" />
                    </ErrorMessage>

                    <SC.Description>方案启动后，填入的文本内容，将被钉钉群机器人发出，请检查核对。</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        钉钉机器人Webhook地址{' '}
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="webhook" errors={errors}>
                        <Input autoComplete="new-password" {...register('webhook', { required: '不能为空' })} placeholder="请输入" />
                    </ErrorMessage>

                    <SC.Description>
                        ① 添加新的群机器人：选择一个钉钉内部员工群，右上角…添加群机器人-添加一个新的机器人，添加成功后，复制webhook地址
                        <br />
                        <br />
                        ② 添加已有的群机器人：选择一个钉钉内部员工群，查看已有群机器人-查看资料-复制webhook地址
                        <br />
                        <br />
                        ③增加多个账号，即可发多个群
                        <br />
                        <br />
                        ④更改/删除账号配置，所有引用此账号的流程和节点都会被影响
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>加签密钥</SC.Text>
                    <Controller
                        control={control}
                        name="secret"
                        render={({ field }) => (
                            <Input
                                autoComplete="new-password"
                                type='password'
                                onFocus={() => {
                                    if (field?.value === PLACEHOLDER_PASSWORD) {
                                        field.onChange('')
                                    }
                                }}
                                onBlur={() => {
                                    if (!(field?.value ?? '').trim()) {
                                        field.onChange(PLACEHOLDER_PASSWORD)
                                    }
                                }}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="请输入"
                            />
                        )}
                    />

                    <SC.Description>用户在创建钉钉自定义机器人时的加密设置</SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default DingTalkRobot
