import type { UserProtocol } from '@lighthouse/core'
import React, { useCallback } from 'react'

import * as SC from './styles'

export type Action = 'SETTING' | 'SIGNOUT'
export interface MenuOpItem {
    area: string
    items?: { name: string; iconfont: string; action: Action }[]
}

export type UserMenuProps = {
    options: MenuOpItem[]
    userInfo: UserProtocol

    onOpenAccountSetting?: () => void
    onLogout?: () => void
    onClose: () => void
}

export const UserMenu: React.FC<UserMenuProps> = ({ userInfo, options = [], onOpenAccountSetting, onLogout, onClose }) => {
    const handlers = useCallback(() => {
        return {
            SETTING() {
                onOpenAccountSetting?.()
                onClose()
            },
            SIGNOUT() {
                onLogout?.()
                onClose()
            }
        }
    }, [onClose, onLogout, onOpenAccountSetting])

    const renderItem = useCallback(
        (item: MenuOpItem) => {
            switch (item.area) {
                case 'info': {
                    return (
                        <SC.Title>
                            <SC.TitleName>{userInfo.username ?? '未命名'}</SC.TitleName>
                            <SC.TitlePhone>{userInfo.mobile}</SC.TitlePhone>
                        </SC.Title>
                    )
                }
                case 'setting': {
                    return (
                        <SC.SettingContainer>
                            {item.items?.map(({ name, iconfont, action }) => (
                                <SC.SettingItem
                                    key={name}
                                    onClick={() => {
                                        handlers()[action]()
                                    }}
                                >
                                    <SC.SettingName>{name}</SC.SettingName>
                                    {/* {renderSuffix(name)} */}
                                </SC.SettingItem>
                            ))}
                        </SC.SettingContainer>
                    )
                }

                default: {
                    return null
                }
            }
        },
        [userInfo.username, userInfo.mobile, handlers]
    )

    return (
        <SC.UserMenuContainer>
            {options.map(item => (
                <React.Fragment key={item.area}>
                    {renderItem(item)}
                    {['info', 'currentSpace'].includes(item.area) && <SC.ItemDivider />}
                </React.Fragment>
            ))}
        </SC.UserMenuContainer>
    )
}
