import { IconFont } from '@byecode/ui'
import React, { useCallback, useMemo } from 'react'
import type { EdgeProps } from 'reactflow'
import { EdgeLabelRenderer, getStraightPath, useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

interface ConditionInnerEdgeProps extends EdgeProps {}

const foreignObjectSize = 18

const SCxEdge = styled.path`
    fill: none;
    stroke: #dfdfdf;
`

const SCxActionWrapper = styled.div`
    position: absolute;
    pointer-events: all;
    top: 0;
`

const SCxEdgeButtonWrapper = styled.div`
    background: transparent;
    width: ${foreignObjectSize}px;
    height: ${foreignObjectSize}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
`

const SCxEdgeButton = styled.div`
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border-radius: 4px;
    color: var(--color-white);
    background: var(--color-gray-400);
    transition: all 0.2s ease-in-out;

    &.flow-node-selected {
        background: var(--color-main);
        transform: scale(2);
    }

    &:hover {
        background: var(--color-main);
        transform: scale(2);
    }
`

const SCxAddIcon = styled(IconFont)``

const ConditionInnerEdge: React.FC<ConditionInnerEdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, style = {}, markerEnd, data }) => {
    const { readonly, onEdgeButtonClick } = useFlow()
    const disabledBtn = useIsDisabledWithVersion() || readonly
    const { getEdge } = useReactFlow()

    const edgeStyles = useMemo<React.CSSProperties>(() => {
        const isSuccess = data?.status?.status === 'SUCCESS'
        const defaultStyle = style ?? {}
        return readonly
            ? {
                  ...defaultStyle,
                  pointerEvents: 'none',
                  stroke: isSuccess ? 'var(--color-green-500)' : 'var(--color-gray-400)',
                  strokeWidth: isSuccess ? 2 : 1,
                  strokeDasharray: isSuccess ? 0 : 4
              }
            : { ...defaultStyle, stroke: 'var(--color-gray-200)' }
    }, [data?.status?.status, readonly, style])

    const handleAddCondition = useCallback(() => {
        const edge = getEdge(id)
        edge && onEdgeButtonClick?.({ edge, operator: 'addInCondition' })
    }, [getEdge, id, onEdgeButtonClick])

    const [edgePath, labelX, labelY] = getStraightPath({
        sourceX,
        sourceY,
        targetX,
        targetY
    })

    return (
        <>
            <SCxEdge id={id} style={edgeStyles} d={edgePath} fill="none" markerEnd={markerEnd} />
            {!disabledBtn && (
                <EdgeLabelRenderer>
                    <SCxActionWrapper
                        style={{
                            width: foreignObjectSize,
                            height: foreignObjectSize,
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
                        }}
                    >
                        <SCxEdgeButtonWrapper>
                            <SCxEdgeButton onClick={handleAddCondition}>
                                <SCxAddIcon size={8} type="Add" />
                            </SCxEdgeButton>
                        </SCxEdgeButtonWrapper>
                    </SCxActionWrapper>
                </EdgeLabelRenderer>
            )}
        </>
    )
}

export default ConditionInnerEdge
