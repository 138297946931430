import type { SchemaProtocol, ViewField } from '@lighthouse/core'
import React from 'react'

import { BaseViewFieldConfig } from './BaseViewFieldConfig'

export interface ViewFieldConfigRendererProps {
    prefixName: string
    viewField: ViewField
    schema: SchemaProtocol['schema']
    columns: ViewField[]
    onChange?: (val: ViewField) => void
}

export const ViewFieldConfigRenderer: React.FC<ViewFieldConfigRendererProps> = ({ prefixName, viewField, schema, columns, onChange }) => {
    return <BaseViewFieldConfig value={viewField} schema={schema} onChange={val => onChange?.(val as ViewField)} />
    // return useMemo(() => {
    //     const { type } = viewField
    //     switch (type) {
    //         case 'text': {
    //             return <TextViewFieldConfig value={viewField} schema={schema} onChange={val => onChange?.(val as ViewField)} />
    //         }
    //         case 'number': {
    //             return <NumberViewFieldConfig value={viewField} schema={schema} onChange={val => onChange?.(val as ViewField)} />
    //         }
    //         case 'date': {
    //             return <DateViewFieldConfig value={viewField} schema={schema} onChange={val => onChange?.(val as ViewField)} />
    //         }
    //         default: {
    //             return <BaseViewFieldConfig value={viewField} schema={schema} onChange={val => onChange?.(val as ViewField)} />
    //         }
    //     }
    // }, [onChange, schema, viewField])
}
