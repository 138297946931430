import type { ByecodeSize } from '@byecode/ui'
import { Button, Input, Switch } from '@byecode/ui'
import { Space } from '@mantine/core'
import React, { useCallback, useState } from 'react'

import { Unit } from '../Mics'

export const Controlled: React.FC = () => {
    const [checked, setChecked] = useState(false)
    const [size, setSize] = useState<ByecodeSize>('md')

    const checkedLabel = checked ? '开' : '关'

    const toggle = useCallback((size: ByecodeSize) => {
        setChecked(prev => !prev)
        setSize(size)
    }, [])

    return (
        <Unit title="尺寸">
            <Button type="primary" size="xs" onClick={() => toggle('xs')}>
                xs：{checkedLabel}
            </Button>
            <Button type="primary" size="sm" onClick={() => toggle('sm')}>
                sm：{checkedLabel}
            </Button>
            <Button type="primary" size="md" onClick={() => toggle('md')}>
                md：{checkedLabel}
            </Button>
            <Button type="primary" size="lg" onClick={() => toggle('lg')}>
                lg：{checkedLabel}
            </Button>
            <div style={{ width: '100%' }}>
                123
                <Switch
                    size={size}
                    checked={checked}
                    onChange={ev => {
                        setChecked(ev.target.checked)
                    }}
                />
                312
            </div>
        </Unit>
    )
}
