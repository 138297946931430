import type { Draft } from 'immer'

type DraftHelper<T extends Draft<object | null | undefined>> = Partial<T> | ((draft: T) => void) | undefined

/**
 * 应用draft的变更
 * @param draft 副本
 * @param payload draft变更的数据
 */
export const applyDraftPayload = <T extends Draft<object | null | undefined>, P extends DraftHelper<T>>(draft: T, payload: P) => {
    if (!draft || !payload) {
        return
    }

    if (typeof payload === 'function') {
        payload(draft)
    } else {
        Object.entries(payload).forEach(([k, v]) => {
            Reflect.set(draft, k, v)
        })
    }
}
