import React from 'react'

export const arrowStart = 'byecode-arrowStart'
export const arrowStartGradient = 'byecode-arrowStartGradient'

export const arrowEnd = 'byecode-arrowEnd'
export const arrowEndGradient = 'byecode-arrowEndGradient'

export const Marker = () => {
    return (
        <svg>
            <defs>
                <marker id={arrowStart} viewBox="0 0 10 10" refX="0" refY="5" markerWidth="6" markerHeight="6">
                    <circle cx="5" cy="5" r="5" fill="var(--color-gray-300)" />
                </marker>
                <marker id={arrowStartGradient} viewBox="0 0 10 10" refX="0" refY="5" markerWidth="6" markerHeight="6">
                    <circle cx="5" cy="5" r="5" fill="var(--color-purple-500)" />
                </marker>
                <marker id={arrowEnd} viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6">
                    <path d="M 0 0 L 8 4 L 0 9 z" fill="var(--color-gray-300)" />
                </marker>
                <marker id={arrowEndGradient} viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6">
                    <path d="M 0 0 L 8 4 L 0 9 z" fill="var(--color-purple-500)" />
                </marker>
            </defs>
        </svg>
    )
}
