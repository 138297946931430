import { SegmentedControl } from '@byecode/ui'
import type { FilterBlockAbstract } from '@lighthouse/core'
import { type ChartViewOptions, type DataSourceAbstract } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import { getFilterOptions, getViewColumns, Group4ByecodeUi, ListItem4ByecodeUi, useAtomData, VariableSourceType } from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { chartAxisOptions, chartSortOptions, dataSettingSwitchList } from '../../constants'
import { BaseDataConfigurator } from '../BaseDataConfigurator'

interface DataConfiguratorProps {
    dataSource: DataSourceAbstract
    enableSortConfigurator?: boolean
}

export const DataConfigurator: React.FC<DataConfiguratorProps> = ({ dataSource, enableSortConfigurator }) => {
    const { control } = useFormContext<ChartViewOptions>()
    const appId = useCurrentAppID()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => s[pageId], [pageId])
    )

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    // 筛选器blocks
    const filterBlocks = useMemo(() => {
        if (!pageBlocks) {
            return []
        }
        return pageBlocks.filter(block => block.type === 'filter') as FilterBlockAbstract[]
    }, [pageBlocks])

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi label="数据设置">
                <BaseDataConfigurator
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                    sources={sources}
                    switchList={dataSettingSwitchList}
                />

                {enableSortConfigurator && (
                    <>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>排序依据</Text>
                            <Controller
                                control={control}
                                name="xyAxis"
                                render={({ field }) => (
                                    <SegmentedControl
                                        style={{ width: 157 }}
                                        fullWidth
                                        data={chartAxisOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>排序规则</Text>
                            <Controller
                                control={control}
                                name="sortMode"
                                render={({ field }) => (
                                    <SegmentedControl
                                        style={{ width: 157 }}
                                        fullWidth
                                        data={chartSortOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </>
                )}
            </Group4ByecodeUi>
        </>
    )
}
