import { IconFont } from '@byecode/ui'
import React, { memo } from 'react'
import type { ReactFlowState } from 'reactflow'
import { useReactFlow, useStore } from 'reactflow'
import styled from 'styled-components'

export type OperateType = 'move' | 'drag' | 'table'

export interface ControlProps {
    style?: React.CSSProperties
}

interface UseZoomLevel {
    toPercent?: boolean
}

const zoomLevelSelector = (state: ReactFlowState) => state.transform[2]

export const useFlowZoomLevel = (params?: UseZoomLevel) => {
    const zoomLevelRatio = useStore(zoomLevelSelector)

    return params?.toPercent ? `${(zoomLevelRatio * 100).toFixed(0)}%` : zoomLevelRatio
}

const SCxControlWrapper = styled.div`
    position: absolute;
    align-items: center;
    justify-content: space-between;
    bottom: 16px;
    left: 16px;
    z-index: 999;
    display: flex;
`

const SCxControlContent = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.1);
    overflow: hidden;
`

const SCxControlItem = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:last-child) {
        border-right: 1px solid var(--color-gray-200);
    }

    &:hover {
        background-color: var(--color-gray-100);
    }

    &.bar-item__active {
        color: var(--color-white);
        background-color: var(--color-main);
    }
`

const SCxControlExtraItem = styled(SCxControlItem)`
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.1);
`

const SCxControlZoomLevel = styled.div`
    display: inline-flex;
    width: 52px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--color-gray-200);
    font-size: var(--font-size-sm);
    user-select: none;
`

const SCxFlowTip = styled.div`
    margin-left: 22px;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`

export const Controls: React.FC<ControlProps> = memo(({ style }) => {
    const { zoomIn, zoomOut, zoomTo } = useReactFlow()
    const zoomLevel = useFlowZoomLevel({ toPercent: true })

    return (
        <SCxControlWrapper style={style}>
            <SCxControlContent>
                <SCxControlItem onClick={() => zoomIn({ duration: 500 })}>
                    <IconFont type="Add" size={14} />
                </SCxControlItem>
                <SCxControlZoomLevel>{zoomLevel}</SCxControlZoomLevel>
                <SCxControlItem onClick={() => zoomOut({ duration: 500 })}>
                    <IconFont type="Reduce" size={14} />
                </SCxControlItem>
            </SCxControlContent>
            <SCxControlExtraItem onClick={() => zoomTo(1, { duration: 500 })}>
                <IconFont type="CornersOut" size={14} />
            </SCxControlExtraItem>
            <SCxFlowTip>按住 空格 可拖拽画布</SCxFlowTip>
        </SCxControlWrapper>
    )
})
