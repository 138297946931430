import { Toast } from '@byecode/ui';
import type { ErrorCode } from '@lighthouse/shared';
import { errorCodeMap, limitExceededCodeList } from '@lighthouse/shared';
import type { IsSuccessfulCall } from '@rpldy/shared';
import type { UploadyProps } from '@rpldy/shared-ui';

import { openSpaceGrade } from '@/components/SpaceGrade';
import http from '@/http';

/** 维格表 -> 用户反馈表接口地址 */
const VIKA_API_BASE = 'https://api.vika.cn'
const VIKA_DATA_SHEET_FEEDBACK = 'dsttKm4eFMrnNdHSrz'
export const VIKA_API_PREFIX = `${VIKA_API_BASE}/fusion/v1/datasheets/${VIKA_DATA_SHEET_FEEDBACK}` as const
export const VIKA_API_TOKEN = 'uskmB6oRi3LBj4zEvizfOAo'

export const isSuccessfulCallToToast: IsSuccessfulCall = xhr => {
    if (xhr.status === 200) {
        const res = JSON.parse(xhr.response)
        if (res.code === '0030000') {
            return false
        }
        if (limitExceededCodeList.includes(res.code)) {
            Toast.error(`${errorCodeMap[res.code as ErrorCode]}，`, {
                linkText: '请升级版本',
                onLinkClick: () => openSpaceGrade('version_upgrade')
            })
            return false
        }
    }
    return true
}


export const uploadAuth = (): Pick<UploadyProps, 'params' | 'headers'> => {
    return {
        headers: http.defaults.headers.common
    }
}

// export const uploadParams = (params?: Record<string, unknown>): UploadyProps => {
//     return {
//         destination: { url: '/lighthouse/api/v1/oss/uploadInApp', ...uploadAuth(), params },
//         isSuccessfulCall: isSuccessfulCallToToast
//     }
// }

type UploadManagerInFlowParams = {
    workflowId: string
}

/** 上传管理器使用 */
export const uploadManagerInFlowParams = (params?: UploadManagerInFlowParams): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadInWorkflow', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}


type UploadManagerInAppParams = {
    pageId?: string
    blockId?: string
}

/** 上传管理器使用 */
export const uploadManagerInAppParams = (params?: UploadManagerInAppParams): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadInApp', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

type UploadInDataSourceParams = {
    dsId: string
    envId: string
    fieldId: string
    recordId?: string
}

/** 上传管理器使用 */
export const uploadInDataSourceManagerParams = (params?: UploadInDataSourceParams): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadInDataSource', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

type UploadVodInDataSourceParams = {
    dsId: string
    envId: string
    fieldId: string
    recordId?: string
}

/** 上传视频管理器使用 在数据源中 */
export const uploadVideoInDataSourceManagerParams = (params?: UploadVodInDataSourceParams): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadVod', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}


type UploadVodInPageParams = {
    pageId: string
    blockId: string
}

/** 上传视频管理器使用 在页面中 */
export const uploadVideoInPageManagerParams = (params?: UploadVodInPageParams): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadVod', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

export const uploadVikaParams = (params?: Record<string, unknown>): UploadyProps => {
    return {
        isSuccessfulCall: xhr => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const res = JSON.parse(xhr.responseText)
                return res.success
            }
            return false
        },
        destination: {
            url: `${VIKA_API_PREFIX}/attachments`,
            headers: {
                Authorization: `Bearer ${VIKA_API_TOKEN}`
            }
        }
    }
}

export const uploadInSpaceParams = (params: { spaceId: string }): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadInSpace', ...uploadAuth(), params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

export const uploadInitApplication = (params?: Record<string, unknown>): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/app/createAppByExcel', method: 'PUT', headers: http.defaults.headers.common, params },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

export const uploadPublicParams = (params?: Record<string, unknown>): UploadyProps => {
    return {
        destination: { url: '/lighthouse/api/v1/oss/uploadPublicResource', ...uploadAuth(), params }
    }
}

// /** 上传富文本内图片 */
// export const uploadRichTextOss = (params?: Record<string, unknown>): UploadyProps => {
//     return {
//         destination: {
//             url: '/lighthouse/api/v1/oss/uploadInDataSource',
//             ...uploadAuth(),
//             params
//         }
//     }
// }
