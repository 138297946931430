import { Button } from '@byecode/ui/components/Button/Button'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Text } from '@byecode/ui/components/Text'
import React from 'react'
import styled from 'styled-components'

import { closeSpaceGrade } from '../event'

interface WeChatOrderSuccessProps {
    onClose?: () => void
}
export const SCxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`

export const WeChatOrderSuccess: React.FunctionComponent<WeChatOrderSuccessProps> = ({ onClose }) => {
    return (
        <SCxContainer>
            <IconFont type="TickCircle" size={60} color="var(--color-green-500)" />
            <Text>恭喜！支付成功</Text>
            {/* <Button
                style={{ height: 40, width: 280 }}
                onClick={() => {
                    navigate('/workbench/setting')
                    onClose?.()
                    closeSpaceGrade()
                }}
            >
                查看订单
            </Button> */}
            <Button
                style={{ height: 40, width: 280 }}
                type="primary"
                onClick={() => {
                    onClose?.()
                    closeSpaceGrade()
                }}
            >
                完成
            </Button>
        </SCxContainer>
    )
}
