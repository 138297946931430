import { flex, Tooltip } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button/Button'
import { AppUserStatus } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import * as CM from '../styles'

interface IUserStateTagProps {
    state?: AppUserStatus
    isDepart?: boolean
    onAudit?: (value: boolean) => void
}

const CustomContain = styled.div`
    ${flex}
    width: auto;
    padding-left: 16px;
    height: 100%;
    align-items: center;
    gap: 6px;
`
const TagButton = styled.div`
    display: flex;
    align-items: center;
`
const TagIcon = styled.div<{ color: string }>`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    border: 1px solid ${({ color }) => color};
    border-radius: 100%;
    background-color: ${({ color }) => color};
`

const UserStateTag: React.FunctionComponent<IUserStateTagProps> = ({ state = false, isDepart, onAudit }) => {
    // const style: CSSProperties = useMemo(
    //     () => ({
    //         backgroundColor: state ? ' var(--color-main)' : 'var(--color-drygrass)'
    //         // color: state ? 'var(--color-main)' : 'var(--color-drygrass)'
    //     }),
    //     [state]
    // )
    const [stateText, color] = useMemo(() => {
        if (isDepart) {
            return ['已注销', 'var(--color-red-500)']
        }
        switch (state) {
            case 0: {
                return ['未激活', 'var(--color-gray-300)']
            }
            case 1: {
                return ['正常', 'var(--color-main)']
            }
            case 2: {
                return ['待审核', 'var(--color-gray-300)']
            }
            default: {
                return ['', '']
            }
        }
    }, [isDepart, state])

    return (
        <TagButton>
            <TagIcon color={color} /> {stateText}
            {state === AppUserStatus.checkPending && (
                <CustomContain>
                    <Tooltip title="不同意">
                        <Button
                            size="xs"
                            radius="100%"
                            type="primary"
                            danger
                            style={{ width: 24 }}
                            onClick={() => onAudit?.(false)}
                            icon={<CM.Icon color="var(--color-white)" type="Close" />}
                        />
                    </Tooltip>
                    <Tooltip title="同意">
                        <Button
                            size="xs"
                            radius="100%"
                            type="primary"
                            style={{ width: 24 }}
                            onClick={() => onAudit?.(true)}
                            icon={<CM.Icon color="var(--color-white)" type="Tick" />}
                        />
                    </Tooltip>
                </CustomContain>
            )}
        </TagButton>
    )
}

export default UserStateTag
