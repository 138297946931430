import type { PageAbstract } from '@lighthouse/core'
import { Input, List, Tabs } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface LinkSelectProps {
    pageList: PageAbstract[]
    value: string
    onChange: (v: string) => void
    onConformClick: () => void
}

const Container = styled.div`
    width: 100%;
    min-height: 180px;
`

export const LinkSelect: React.FC<LinkSelectProps> = ({ pageList, value, onChange, onConformClick }) => {
    const [tabValue, setTabValue] = useState(value?.startsWith('http') ? 'href' : 'page')
    const inputRef = React.useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (tabValue === 'href') {
            const input = inputRef.current
            if (input) {
                input.value = value?.startsWith('http') ? value : ''
            }
        }
    }, [tabValue, value])

    return (
        <Container>
            <Tabs
                value={tabValue}
                onTabChange={v => {
                    if (v === 'page' && !value.startsWith('http')) {
                        onChange('')
                    }
                    setTabValue(v ?? 'page')
                }}
            >
                <Tabs.List>
                    <Tabs.Tab value="page">页面</Tabs.Tab>
                    <Tabs.Tab value="href">自定义链接</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="page" pt="xs" sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <List sx={{ listStyle: 'none', fontSize: '14px' }}>
                        {pageList.map(item => (
                            <List.Item
                                key={item.id}
                                sx={{
                                    width: '100%',
                                    height: '26px',
                                    padding: '2px 8px',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    ':hover': { background: 'rgba(232, 232, 232, 0.51)' }
                                }}
                                onClick={() => {
                                    onChange(item.id)
                                    onConformClick()
                                }}
                            >
                                {item.name || '无标题'}
                            </List.Item>
                        ))}
                    </List>
                </Tabs.Panel>
                <Tabs.Panel value="href" pt="xs">
                    <Input
                        ref={inputRef}
                        value={value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
                    />
                </Tabs.Panel>
            </Tabs>
        </Container>
    )
}
