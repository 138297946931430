import { IconPicker } from '@lighthouse/shared'
import { Card } from '@mantine/core'
import React from 'react'

import { uploadManagerInAppParams } from '@/utils/auth'

import { Unit } from '../Mics'

interface IconPickerComponentsDemoProps {}
const icons = [
    'CloudIconLibrary',
    'ShopIconLibrary',
    'ReorderIconLibrary',
    'ChartSquareIconLibrary',
    'CityIconLibrary',
    'ShieldIconLibrary',
    'PlateIconLibrary',
    'BoxMinimalisticIconLibrary',
    'DatabaseIconLibrary',
    'MonitorSmartphoneIconLibrary'
]
export const IconPickerComponentsDemo: React.FunctionComponent<IconPickerComponentsDemoProps> = props => {
    return (
        <Card withBorder>
            <Unit title="图标选择器">
                {icons.map(name => (
                    <IconPicker defaultValue={{ type: 'icon', value: name }} clearable key={name} uploadParams={uploadManagerInAppParams()} />
                ))}
                <IconPicker defaultValue={{ type: 'icon', value: '' }} clearable uploadParams={uploadManagerInAppParams()} />
                <IconPicker defaultValue={{ type: 'icon', value: 'MinimalisticIconLibrary' }} clearable uploadParams={uploadManagerInAppParams()} />
                <IconPicker defaultValue={{ type: 'icon', value: 'PlateIconLibrary' }} uploadParams={uploadManagerInAppParams()} />
                <IconPicker defaultValue={{ type: 'icon', value: 'SecurityIconLibrary' }} disabled uploadParams={uploadManagerInAppParams()} />
            </Unit>
        </Card>
    )
}
