import { Card, Collapse, Grid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React from 'react'

import { Basic } from './Basic'
import { Extra } from './Extra'
import { Feature } from './Feature'
import { Group } from './Group'
import { Sizes } from './Sizes'

export const SelectComponentsDemo: React.FC = () => {
    const [opened, { toggle }] = useDisclosure(true)
    return (
        <Card withBorder>
            <Title order={2} onClick={toggle}>
                选择框
            </Title>
            <Collapse in={opened}>
                <Grid gutter={20}>
                    <Grid.Col span={6}>
                        <Basic />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Group />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Feature />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Sizes />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Extra />
                    </Grid.Col>
                </Grid>
            </Collapse>
        </Card>
    )
}
