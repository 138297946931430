import type { TextBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { generateJson, TEXT_FONT_NORMAL } from '@lighthouse/shared'

/**
 * 生成文本block的配置
 *
 * @returns {TextBlockConfig}
 */
export const generateTextBlock = (): TextBlockConfig => {
    return {
        content: { value: generateJson('这是一段文本') },
        color: { type: BACKGROUND_TYPE.color, color: '#101828' },
        font: {
            advance: '',
            fontFamily: TEXT_FONT_NORMAL,
            fontSize: 1,
            lineHeight: 1.5,
            letterSpacing: 0
        }
    }
}
