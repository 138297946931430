import React from 'react'
import styled from 'styled-components'

import { PageLayers } from '../PageLayers'

interface LayersCenterProps {}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const LayersCenter: React.FunctionComponent<LayersCenterProps> = props => {
    return (
        <SCxContainer>
            <PageLayers />
        </SCxContainer>
    )
}

export default LayersCenter
