import type { DataSourceAbstract, TypeInstanceMap } from '@lighthouse/core'
import type {
    AggregateResultField,
    AggregatorDataInputConfig,
    AggregatorDataInputNode,
    AggregatorFieldSettingConfig,
    AggregatorGroupStatisticsConfig,
    StatisticsCalcType
} from '@lighthouse/shared'
import {
    type AggregatorAddPlaceholderNode,
    type AggregatorNode,
    aggregatorJoinNodeIconMap,
    AggregatorJoinType,
    AggregatorNodeType,
    aggregatorNodeTypeIconMap
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { clone, filter, find, findIndex, forEach, map } from 'rambda'
import type { NodeProps } from 'reactflow'
import { type Edge, type Node, getConnectedEdges, MarkerType } from 'reactflow'

import { NodeRectMap } from './AggregatorEditor/constants'
import { aggregatorNodeNameMap } from './constants'

const getAdderPlaceholderY = (lastNode: Node) => {
    switch (lastNode.type) {
        case AggregatorNodeType.DATA_INPUT: {
            return lastNode.position.y + 13
        }
        case AggregatorNodeType.JOIN:
        case AggregatorNodeType.FIELD_SETTING:
        case AggregatorNodeType.FILTER: {
            return lastNode.position.y + 4
        }
        default: {
            return lastNode.position.y + 4
        }
    }
}

export const getNodes = (nodes: AggregatorNode[], edges: Edge[]) => {
    const dummyNode: AggregatorDataInputNode = {
        id: `n${nanoid(10)}`,
        type: AggregatorNodeType.DATA_INPUT,
        data: {
            config: {
                name: '数据输入',
                dsId: ''
            },
            result: {
                fieldList: []
            }
        },
        position: { x: 0, y: 0 }
    }
    const initialNodes = !nodes || nodes.length === 0 ? [dummyNode] : nodes
    const lastNode = initialNodes.find(node => !edges.some(edge => edge.source === node.id))

    if (!lastNode) {
        return initialNodes
    }

    const { position, type } = lastNode
    const px = position.x + ((type ?? AggregatorNodeType.DATA_INPUT) === AggregatorNodeType.DATA_INPUT ? 300 : 160)

    return [
        ...initialNodes,
        {
            id: 'add-placeholder',
            type: AggregatorNodeType.ADD_PLACEHOLDER,
            data: {},
            position: { x: px, y: getAdderPlaceholderY(lastNode) }
        }
    ]
}

export const getEdges = (nodes: AggregatorNode[], edges: Edge[]) => {
    const lastNode = nodes.find(node => !edges.some(edge => edge.source === node.id))

    const addPlaceholderEdge: Edge = {
        id: 'add-placeholder-edge',
        type: 'COMMON',
        source: lastNode?.id || '',
        target: 'add-placeholder',
        // animated: true,
        style: { stroke: '#fff' },
        markerEnd: {
            type: MarkerType.ArrowClosed
        }
    }

    return [...edges, addPlaceholderEdge]
}

export const getInitData = (nodeType: AggregatorNodeType, prevNodes: AggregatorNode[]) => {
    if (nodeType === AggregatorNodeType.FIELD_SETTING) {
        const fieldList = otherNodeResultGenerator(prevNodes)
        return {
            config: {
                name: aggregatorNodeNameMap[nodeType],
                fieldList
            },
            result: {
                fieldList
            }
        }
    }
    if (nodeType === AggregatorNodeType.FILTER) {
        const fieldList = otherNodeResultGenerator(prevNodes)
        return {
            config: {
                name: aggregatorNodeNameMap[nodeType]
            },
            result: {
                fieldList
            }
        }
    }
    if (nodeType === AggregatorNodeType.JOIN) {
        return {
            config: {
                name: aggregatorNodeNameMap[nodeType],
                joinType: AggregatorJoinType.LEFT_JOIN
            }
        }
    }

    return {
        config: {
            name: aggregatorNodeNameMap[nodeType]
        }
    }
}

/**
 * 创建新的节点，生成新的节点及所有节点
 * @param nodeType
 * @param nodes
 * @param edges
 * @returns
 */
export const generateAddedNodes = (nodeType: AggregatorNodeType, nodes: AggregatorNode[], edges: Edge[]) => {
    const newNodes = nodes.map(item => {
        if (item.selected) {
            return {
                ...item,
                selected: false
            }
        }
        return item
    })
    // const newNodes = [...nodes]
    const newNodeId = `n${nanoid(10)}`

    const adderNode = find(node => node.id === 'add-placeholder', newNodes) as AggregatorNode
    const { nodes: prevNodes } = peekPrevNodes(adderNode, nodes, edges)
    const connectedEdge = getConnectedEdges([adderNode], edges)[0]

    const lastNode = find(node => node.id === connectedEdge.source, nodes) as Node

    let maxY = lastNode.position.y
    // 找到最靠下的节点
    forEach(node => {
        if (node.position.y > maxY) {
            maxY = node.position.y
        }
    }, newNodes)
    // const lastNodeRect = NodeRectMap.get(lastNode.type as NodeTypes)
    const maxYWithHeight = maxY

    newNodes.splice(newNodes.indexOf(adderNode), 1)
    const addedNodeRect = NodeRectMap.get(nodeType)

    // 只有初始情况，或者遇到新加连接节点，才将后续节点位置定位到中心
    const isNotOutFromCenter = nodes.length === 2 || nodeType !== AggregatorNodeType.JOIN
    const tailNodeY = nodeType === AggregatorNodeType.JOIN ? (maxYWithHeight + 100 - 66) / 2 + 33 : (maxYWithHeight - 66) / 2
    // 计算尾部节点的位置
    const tailNodePosition = {
        x: adderNode.position.x,
        y: isNotOutFromCenter ? adderNode.position.y : tailNodeY
    }

    const addedNode = {
        ...addedNodeRect,
        id: newNodeId,
        selected: true,
        type: nodeType,
        data: getInitData(nodeType, prevNodes),
        position: { x: tailNodePosition.x, y: tailNodePosition.y - 4 }
    } as AggregatorNode
    const addedExtraNodes: AggregatorNode[] = []
    const newAdderNode: AggregatorAddPlaceholderNode = {
        ...adderNode,
        id: 'add-placeholder',
        type: AggregatorNodeType.ADD_PLACEHOLDER,
        data: {
            config: {
                name: '添加节点'
            },
            result: {
                fieldList: []
            }
        },
        position: { x: tailNodePosition.x + 160, y: tailNodePosition.y }
    }
    const addedNodes = [addedNode]
    // 如果是连接节点，需要多创建一个数据输入节点
    if (nodeType === AggregatorNodeType.JOIN) {
        const extraAddedNodeRect = NodeRectMap.get(AggregatorNodeType.DATA_INPUT)

        const secondaryDataInputNode: AggregatorDataInputNode = {
            ...extraAddedNodeRect,
            id: `n${nanoid(10)}`,
            type: AggregatorNodeType.DATA_INPUT,
            selected: false,
            data: {
                config: {
                    name: aggregatorNodeNameMap['DATA_INPUT'],
                    dsId: ''
                },
                result: {
                    fieldList: []
                }
            },
            width: 200,
            position: {
                x: lastNode.position.x + (lastNode.width ?? 0) - 200,
                y: maxY + 100
            }
        }

        addedExtraNodes.unshift(secondaryDataInputNode)
    }

    newNodes.push(...addedNodes, ...addedExtraNodes, newAdderNode)

    return { addedNode, addedExtraNodes, newNodes }
}

/**
 * 通过新生成的节点，生成新的边
 * @param node
 * @param nodes
 * @param edges
 * @returns
 */
export const generateAddedEdges = ({
    addedNode,
    nodes,
    edges,
    addedExtraNodes
}: {
    addedNode: Node
    nodes: Node[]
    edges: Edge[]
    addedExtraNodes: Node[]
}) => {
    const newEdges = [...edges]

    const adderNode = find(node => node.id === 'add-placeholder', nodes) as Node
    const connectedEdge = getConnectedEdges([adderNode], edges)[0]

    const lastNode = find(node => node.id === connectedEdge.source, nodes) as Node

    // 将原连接 add-placeholder 的边删除
    newEdges.splice(newEdges.indexOf(connectedEdge), 1)
    const addedEdges = [
        {
            id: `${nanoid(10)}`,
            type: 'COMMON',
            source: lastNode.id,
            target: addedNode.id
        }
    ]
    // 如果是连接节点，需要多创建一个数据输入节点
    forEach(node => {
        addedEdges.push(
            // {
            //     id: `${nanoid(10)}`,
            //     type: 'COMMON',
            //     source: lastNode.id,
            //     target: node.id
            // },
            {
                id: `${nanoid(10)}`,
                type: 'COMMON',
                source: node.id,
                target: addedNode.id
            }
        )
    }, addedExtraNodes)

    addedEdges.push({
        id: `${nanoid(10)}`,
        type: 'COMMON',
        source: addedNode.id,
        target: 'add-placeholder'
    })
    // 添加新的连接边
    newEdges.push(...addedEdges)

    return newEdges
}

/**
 * 创建新的节点
 * @param nodeType
 * @param nodes
 * @param edges
 * @returns
 */
export const createNode = (nodeType: AggregatorNodeType, nodes: AggregatorNode[], edges: Edge[]) => {
    const { addedNode, addedExtraNodes, newNodes } = generateAddedNodes(nodeType, nodes, edges)
    const newEdges = generateAddedEdges({ addedNode, nodes, edges, addedExtraNodes })
    return { nodes: newNodes, edges: newEdges, addedNode }
}

/**
 * 删除节点
 * @param nodeId
 * @param nodes
 * @param edges
 * @returns
 */
export const removeNode = (nodeId: string, nodes: AggregatorNode[], edges: Edge[]) => {
    const newNodes = [...nodes]
    const newEdges = [...edges]

    const node = find(node => node.id === nodeId, nodes)

    if (!node) {
        return { nodes, edges }
    }

    // remove all next edges and nodes recursively
    // use peekNextNodes
    const needRemoveNodes: string[] = [nodeId]
    const needRemoveEdges: string[] = []
    let nextNode = node

    while (nextNode) {
        // 如果是连接节点，需要删除其对应的右边数据输入节点
        const { nodes: prevNodes } = peekPrevNodes(nextNode, nodes, edges)
        const { id: nextNodeId } = nextNode
        if (nextNode.type === AggregatorNodeType.JOIN) {
            const [, secondaryDataInputNode] = prevNodes
            needRemoveNodes.push(secondaryDataInputNode.id)
            forEach(edge => {
                if (edge.source === secondaryDataInputNode.id || edge.source === nextNodeId || edge.target === nextNodeId) {
                    needRemoveEdges.push(edge.id)
                }
            }, newEdges)
        } else {
            forEach(edge => {
                if (edge.source === nextNodeId || edge.target === nextNodeId) {
                    needRemoveEdges.push(edge.id)
                }
            }, newEdges)
        }

        const { nodes: nextNodes, edges: nextEdges } = peekNextNodes(nextNode, nodes, edges)
        needRemoveNodes.push(nextNodeId)
        needRemoveEdges.push(...nextEdges.map(edge => edge.id))
        // if (

        nextNode = nextNodes[0]
    }

    // remove all collected nodes
    const remainNodes = filter(node => !needRemoveNodes.includes(node.id), newNodes)
    // remove all connected edges
    const remainEdges = filter(edge => !needRemoveEdges.includes(edge.id), newEdges)
    const remainY = remainNodes.length === 1 ? 13 : 4
    const {
        nodes: [removedNodePrevNode]
    } = peekPrevNodes(node, remainNodes, edges)

    // add add-placeholder edge and node
    const adderNode: AggregatorAddPlaceholderNode = {
        id: 'add-placeholder',
        type: AggregatorNodeType.ADD_PLACEHOLDER,
        data: {
            config: {
                name: '添加节点'
            },
            result: {
                fieldList: []
            }
        },
        position: { x: node.position.x, y: removedNodePrevNode.position.y + remainY }
    }
    const adderEdge = {
        id: 'add-placeholder-edge',
        type: 'COMMON',
        source: removedNodePrevNode.id,
        target: 'add-placeholder',
        style: { stroke: '#fff' },
        markerEnd: {
            type: MarkerType.ArrowClosed
        }
    }

    return { nodes: [...remainNodes, adderNode], edges: [...remainEdges, adderEdge] }
}

/**
 * 获取节点的基础 node 信息
 * @param node
 * @returns
 */
export const pickInfoFromNodeProps = (node: NodeProps) => {
    const { id, type, xPos, yPos, data } = node
    return { id, type, position: { x: xPos, y: yPos }, data } as AggregatorNode
}

/**
 * 获取节点前的一级节点
 * @param node
 * @param nodes
 * @param edges
 * @returns
 */
export const peekPrevNodes = (node: AggregatorNode, nodes: AggregatorNode[], edges: Edge[]) => {
    const connectedEdges = getConnectedEdges([node], edges)

    const prevEdges = filter(edge => edge.target === node.id, connectedEdges)
    const prevNodeIds = map(e => e.source, prevEdges)

    return { nodes: filter(n => prevNodeIds.includes(n.id), nodes), edges: prevEdges }
}

/**
 * 获取节点后的一级节点
 * @param node
 * @param nodes
 * @param edges
 * @returns
 */
export const peekNextNodes = (node: AggregatorNode, nodes: AggregatorNode[], edges: Edge[]) => {
    const connectedEdges = getConnectedEdges([node], edges)

    const nextEdges = filter(edge => edge.source === node.id, connectedEdges)
    const nextNodeIds = map(e => e.target, nextEdges)

    return { nodes: filter(n => nextNodeIds.includes(n.id), nodes), edges: nextEdges }
}

/**
 * 节点 结果生成器
 * @param node
 * @param nodes
 * @param edges
 * @returns
 */
export const otherNodeResultGenerator = (prevNodes: AggregatorNode[]): AggregateResultField[] => {
    return prevNodes.flatMap(item => item.data.result?.fieldList || [])
}

/**
 * 数据输入节点 结果生成器
 * @param config
 * @param dataSourceList
 * @returns
 */
export const dataInputResultGenerator = (
    nodeId: string,
    config: AggregatorDataInputConfig,
    dataSourceList: DataSourceAbstract[]
): AggregateResultField[] => {
    const { dsId, fieldIds = [] } = config
    const dataSource = find(item => item.id === dsId, dataSourceList)
    if (!dataSource) {
        return []
    }
    const { schema } = dataSource
    return fieldIds.reduce<AggregateResultField[]>((prev, id, index) => {
        const field = schema[id]
        if (!field) {
            return prev
        }
        prev.push({
            ...field,
            type: 'aggregation',
            id: `f${nodeId}${field.id}`,
            visible: true
        })
        return prev
    }, [])
}

/**
 * 字段设置节点 结果生成器
 * @param config
 * @param fieldList
 * @returns
 */
export const fieldSettingResultGenerator = (config: AggregatorFieldSettingConfig) => {
    const { fieldList: fieldSettingList } = config
    return fieldSettingList.map(item => {
        if (item?.aggregation?.isFormula) {
            return {
                ...item,
                aggregation: {
                    ...item.aggregation,
                    isFormula: false
                }
            }
        }
        return item
    })
}

/**
 * 字段设置节点 配置生成器
 * @param config
 * @param fieldList
 * @returns
 */
export const fieldSettingConfigGenerator = (config: AggregatorFieldSettingConfig, fieldList: AggregateResultField[]) => {
    const { fieldList: fieldSettingList } = config
    const validFieldList = fieldSettingList.reduce<AggregateResultField[]>((prev, cur) => {
        const field = find(item => item.id === cur.id, fieldList)
        if (field) {
            prev.push({
                ...cur,
                innerType: field.innerType
            })
        }
        if (cur?.aggregation?.isFormula) {
            prev.push(cur)
        }
        return prev
    }, [])
    return fieldList.reduce((prev, cur) => {
        const field = find(item => item.id === cur.id, prev)
        if (!field) {
            prev.push({
                ...cur,
                visible: false
            })
        }
        return prev
    }, validFieldList)
}

export const getInnerTypeByCalcType: (field: AggregateResultField, calcType: StatisticsCalcType) => TypeInstanceMap = (field, calcType) => {
    if (calcType === 'TO_ARRAY') {
        return 'ARRAY'
    }

    if (['NUMBER', 'DATE'].includes(field.innerType) && ['MAX', 'MIN'].includes(calcType)) {
        return field.innerType
    }
    return 'NUMBER'
}

/**
 * 分组统计节点 结果生成器
 * @param config
 * @param fieldList
 * @returns
 */
export const groupStatisticsResultGenerator = (
    config: AggregatorGroupStatisticsConfig,
    fieldList: AggregateResultField[]
): AggregateResultField[] => {
    const { groupByFields, statisticsFields } = config
    const groupByResultFields =
        groupByFields?.reduce<AggregateResultField[]>((prev, cur) => {
            const field = find(f => f.id === cur.groupByFieldId, fieldList)
            if (!field) {
                return prev
            }
            prev.push({
                ...field,
                id: cur.fieldId,
                type: 'aggregation'
            })
            return prev
        }, []) ?? []
    const statisticsResultFields =
        statisticsFields?.reduce<AggregateResultField[]>((prev, cur) => {
            const field = find(f => f.id === cur.statisticsFieldId, fieldList)

            if (!field || !cur.calcType) {
                return prev
            }
            const innerType = getInnerTypeByCalcType(field, cur.calcType)
            prev.push({
                ...field,
                visible: true,
                name: cur.name || field.name,
                id: cur.fieldId,
                innerType,
                type: 'aggregation'
            })
            return prev
        }, []) ?? []
    return [...groupByResultFields, ...statisticsResultFields]
}

export const changeResultOfNode = (sequenceNode: AggregatorNode, prevNodes: AggregatorNode[]) => {
    const newNode = clone(sequenceNode)
    switch (newNode.type) {
        case AggregatorNodeType.FILTER:
        case AggregatorNodeType.JOIN: {
            const prevFieldList = otherNodeResultGenerator(prevNodes)
            newNode.data.result = {
                fieldList: prevFieldList
            }
            return newNode
        }
        case AggregatorNodeType.FIELD_SETTING: {
            const { config } = newNode.data
            const prevFieldList = otherNodeResultGenerator(prevNodes)
            const configFieldList = fieldSettingConfigGenerator(config, prevFieldList)
            const newConfig = {
                ...config,
                fieldList: configFieldList
            }
            const fieldList = fieldSettingResultGenerator(newConfig)
            newNode.data = {
                config: {
                    ...config,
                    fieldList: configFieldList
                },
                result: {
                    fieldList
                }
            }
            return newNode
        }
        case AggregatorNodeType.GROUP_STATISTICS: {
            const { config } = newNode.data
            const prevFieldList = otherNodeResultGenerator(prevNodes)
            const fieldList = groupStatisticsResultGenerator(config, prevFieldList)
            newNode.data.result = {
                fieldList
            }
            return newNode
        }

        default: {
            return sequenceNode
        }
    }
}

export const getAggregateFlowByNodes = (node: AggregatorNode, nodes: AggregatorNode[], edges: Edge[]) => {
    const newNodes = nodes.map(item => (item.id === node.id ? node : item))
    const queue: AggregatorNode[] = [node]
    while (queue.length > 0) {
        const currentNode = queue.shift() as AggregatorNode
        const { nodes: nextNodes } = peekNextNodes(currentNode, newNodes, edges)
        for (const item of nextNodes) {
            if (item.type === AggregatorNodeType.DATA_INPUT) {
                break
            }
            const { nodes: prevNodes } = peekPrevNodes(item, newNodes, edges)
            // const fieldList = currentNode.data?.result?.fieldList
            const result = changeResultOfNode(item, prevNodes)
            const index = findIndex(item => item.id === result.id, newNodes)
            newNodes[index] = result
            queue.push(result)
        }
    }
    return newNodes
}

export const getAllNextNodeIds = (node: AggregatorNode, nodes: AggregatorNode[], edges: Edge[]) => {
    const nodeIds = new Set<string>([node.id])
    const queue: AggregatorNode[] = [node]
    while (queue.length > 0) {
        const currentNode = queue.shift()
        const { nodes: nextNodes } = peekNextNodes(currentNode as AggregatorNode, nodes, edges)
        for (const item of nextNodes) {
            if (item.type === AggregatorNodeType.JOIN) {
                const { nodes: prevNodes } = peekPrevNodes(item, nodes, edges)
                const [, secondNode] = prevNodes
                nodeIds.add(secondNode.id)
            }
            queue.push(item)
            nodeIds.add(item.id)
        }
    }
    return nodeIds
}
