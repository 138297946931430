import { flex } from '@byecode/ui'
import type { StatusFile } from '@lighthouse/shared'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import TemplateLeftPanel from '../TemplateLeftPanel'
import TemplateList from '../TemplateList'
import type { Route, Template, TemplateList as TemplateListType } from '../types'

interface TemplateBodyProps {
    list: TemplateListType
    onLink: (value: Route) => void
    onCreate: (value: Template) => void
    onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
}

const SCXContainer = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    ${flex}
    overflow: hidden;
`

const TemplateBody: React.FunctionComponent<TemplateBodyProps> = ({ list, onLink, onCreate, onChangeFileStatus }) => {
    const [{ searchWord, pointIndex, isScrollHandle }, setState] = useImmer<{
        searchWord: string
        pointIndex: number
        isScrollHandle: boolean
    }>({
        searchWord: '',
        pointIndex: 0,
        isScrollHandle: false
    })

    useEffect(() => {
        setState(state => {
            state.pointIndex = searchWord.length > 0 ? -1 : 0
        })
    }, [searchWord, setState])

    return (
        <SCXContainer>
            <TemplateLeftPanel
                pointIndex={pointIndex}
                searchWord={searchWord}
                list={list}
                onChangePoint={val => {
                    setState(state => {
                        state.isScrollHandle = true
                        state.pointIndex = val
                    })
                }}
                onChangeSearch={val =>
                    setState(state => {
                        state.searchWord = val
                    })
                }
            />
            <TemplateList
                pointIndex={pointIndex}
                isScrollHandle={isScrollHandle}
                list={list}
                onChangePoint={val =>
                    setState(state => {
                        state.pointIndex = val
                    })
                }
                onLink={onLink}
                onCreate={onCreate}
                searchWord={searchWord}
                onChangeFileStatus={onChangeFileStatus}
                onScrollHandle={val =>
                    setState(state => {
                        state.isScrollHandle = val
                    })
                }
            />
        </SCXContainer>
    )
}

export default TemplateBody
