import { Text } from '@mantine/core'
import styled from 'styled-components'

import { getBrandInfo } from '../../../utils'

export const Content = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: space-between;
`

export const Aside = styled.aside`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`

export const Box = styled.div<{ width: number }>`
    position: relative;
    width: ${({ width }) => `${width}px`};
    /* height: 379px; */
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 40px;
    /* box-shadow: 0 4px 12px rgba(132, 146, 167, 0.12);
    border-radius: 12px; */
`

export const FormContainer = styled.div`
    flex: 1;
    width: 100%;
    background-color: #fff;
`

export const IntroduceContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 357px;
    background-image: url(${getBrandInfo().account.backgroundImage});
    background-size: 150% auto;
    background-position: bottom -66px right -195px;
    background-color: #e5e9f1;
`

export const IntroduceWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const IntroduceTitleHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: flex-start;
    transform: translateY(-20px);
`

export const IntroduceFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 25px;
`

export const LogoImg = styled.img``

export const IntroduceBackgroundImg = styled.img``

export const Filing = styled.div`
    position: absolute;
    bottom: 16px;
    display: flex;
    align-items: center;
    line-height: 20px;
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
`
export const GradientText = styled(Text)`
    background: linear-gradient(272deg, #2621ff 0.69%, #a2a0ff 94.32%);
    background-clip: text;
    line-height: 32px;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
