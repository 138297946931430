import { Select } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode, FlowNode, NodeTypes, WithBlockOption } from '@lighthouse/shared'
import {
    CollapseBox,
    CONTROLLABLE_BLOCK_TYPES,
    isCustomOrContainerNode,
    nodeTypeOptions,
    scroll2FlowNode,
    transformNode2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom, pageNodesAtom } from '@/atoms/page/state'

import * as SC from '../../styles'
import { ComponentSelector } from './ComponentSelector'
import { ControlActionSelector } from './ControlActionSelector'

export interface ControlActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

const generateOptions = (flowNodes: FlowLayoutNode[], pageBlocksMap: Record<string, BlockAbstract>) => {
    return flowNodes
        .map(pageNode => {
            const block = pageBlocksMap[pageNode.id]

            if (!CONTROLLABLE_BLOCK_TYPES.has(block.type)) {
                return false
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const children = (
                isCustomOrContainerNode(pageNode) && pageNode?.children ? generateOptions(pageNode.children, pageBlocksMap) : []
            ) as WithBlockOption[]

            return {
                label: block.title,
                value: pageNode.id,
                block,
                children
            }
        })
        .filter(Boolean) as WithBlockOption[]
}

export const ControlActionConfigure: React.FC<ControlActionConfigureProps> = ({ actionTypeSwitcher, prefixName = 'config' }) => {
    const { control, setValue } = useFormContext()
    const controlElementId = useWatch({ control, name: `${prefixName}.controlElementId` })

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [pageId, stackId, rootPageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.stackId || '', s?.rootPageId || '', s?.blockRuntimeState] as const, [])
    )

    const pageNodes = useAtomData(
        pageNodesAtom,
        useCallback(s => (pageId ? s[pageId] || [] : []), [pageId])
    )

    const pageBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(s => (pageId ? s[pageId] || [] : []), [pageId])
    )

    const flowNodes = useMemo(
        () => transformNode2FlowLayoutNode(pageNodes, pageBlocks, blockRuntimeState),
        [blockRuntimeState, pageBlocks, pageNodes]
    )

    const pageBlocksMap = useMemo(() => {
        return pageBlocks.reduce<Record<string, BlockAbstract>>((acc, b) => {
            acc[b.id] = b
            return acc
        }, {})
    }, [pageBlocks])

    const options = useMemo(() => {
        return generateOptions(flowNodes, pageBlocksMap)
    }, [flowNodes, pageBlocksMap])

    const handleScroll2FlowNode = useCallback(
        (scrollToId = '') => {
            setBlockHighlight(scrollToId ? [scrollToId] : [])
            scroll2FlowNode(scrollToId)
        },
        [setBlockHighlight]
    )

    const clearBlockHighlight = useCallback(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="控制规则">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>选择组件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.controlElementId`}
                                control={control}
                                render={({ field }) => (
                                    <ComponentSelector
                                        value={field.value}
                                        onChange={id => {
                                            field.onChange(id)
                                            setValue(`${prefixName}.controlAction`, undefined)
                                            id && setValue(`${prefixName}.controlElementType`, pageBlocksMap[id]?.type)
                                            clearBlockHighlight()
                                        }}
                                        options={options}
                                        onHover={handleScroll2FlowNode}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {controlElementId && (
                        <ControlActionSelector elementId={controlElementId} prefixName={prefixName} pageBlocksMap={pageBlocksMap} />
                    )}
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
