import type { FindSingleRecordActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, getFindSingleRecordVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleFindSingleRecord = async (node: ActionFlowNode<FindSingleRecordActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    // 查询单条记录暂时只能使用给定的数据源
    const { dataSourceId } = config

    if (!dataSourceId) {
        return excParams
    }

    const params = getFindSingleRecordVariableValue(config, excParams)

    const record = await srv.findSingleRecord(params)

    // if (!record?.id) {
    //     throw new Error('查询记录异常')
    // }

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        record
    })
}
