import { IconFont, Tooltip } from '@byecode/ui'
import type { IconicProtocol, SizedProtocol } from '@lighthouse/core'
import type { AvatarSize } from '@lighthouse/shared'
import { avatarSize } from '@lighthouse/shared'
import type { CSSProperties } from 'react'
import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

export type RoundAvatarProps = Pick<IconicProtocol, 'icon'> &
    Pick<CSSProperties, 'objectFit'> &
    Partial<SizedProtocol> & {
        type?: 'img' | 'icon'
        size?: AvatarSize
        name?: string
        iconSize?: number
        active?: boolean
        bordered?: boolean

        radius?: string
        activeRadius?: string

        color?: string
        iconColor?: string
        activeColor?: string

        background?: string
        activeBgColor?: string

        tooltipLabel?: string
        enableHover?: boolean
        enableTooltip?: boolean
        dot?: boolean
        className?: string
        onClick?: (e: React.MouseEvent) => void
        style?: React.CSSProperties

        children?: React.ReactNode
    }

const SCxContainer = styled.div<{
    size?: number
    active: boolean
    radius: string
    activeRadius: string
    background: string
    color: string
    activeBgColor: string
    enableHover?: boolean
    bordered?: boolean
    activeColor: string
}>`
    position: relative;
    width: ${({ size }) => (size ? `${size}px` : '40px')};
    height: ${({ size }) => (size ? `${size}px` : '40px')};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    flex-shrink: 0;

    ${({ active, radius, background, color, activeRadius, bordered, activeBgColor, activeColor }) => css`
        color: ${active ? activeColor : color};
        background-color: ${active ? activeBgColor : background};
        border: 1px solid ${!bordered || active ? 'transparent' : 'var(--color-gray-200)'};
        border-radius: ${active ? activeRadius : radius};
    `}
    ${({ enableHover, activeBgColor, activeColor }) =>
        enableHover &&
        css`
            transition: all 0.2s ease-in-out;
            &:hover {
                border-color: transparent;
                background-color: ${activeBgColor};
                color: ${activeColor};
            }
        `};
`

const Dot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d88987;
`

const SCxImg = styled.img<{ size?: number; radius: string; objectFit: CSSProperties['objectFit'] }>`
    display: block;
    width: ${({ size }) => (size ? `${size}px` : '44px')};
    height: ${({ size }) => (size ? `${size}px` : '44px')};
    border-radius: ${({ radius }) => radius};
    object-fit: ${({ objectFit }) => objectFit ?? 'cover'};
    object-position: center;
    flex-shrink: 0;
`

const SCxIcon = styled(IconFont)<{ iconSize: number }>`
    font-size: ${({ iconSize }) => `${iconSize}px`};
`

const SCxText = styled.div<{ size?: number; height?: number }>`
    display: block;
    width: ${({ size }) => (size ? `${size}px` : '40px')};
    height: ${({ size }) => (size ? `${size}px` : '40px')};
    text-align: center;
    font-size: 14px;
    line-height: ${({ size }) => (size ? `${size}px` : '40px')};
    flex-shrink: 0;
`

export const RoundAvatar = React.forwardRef<HTMLDivElement, RoundAvatarProps>(
    (
        {
            icon,
            name,
            type = 'img',
            active = false,
            size = 'large',
            iconSize = 24,
            background = 'var(--color-white)',
            color = 'var(--color-black)',
            iconColor = 'var(--color-gray-400)',
            activeBgColor = 'var(--color-main)',
            activeColor = 'var(--color-white)',
            enableHover = false,
            enableTooltip = false,
            tooltipLabel,
            radius = '100%',
            activeRadius = '8px',
            className,
            children,
            style,
            dot,
            bordered = true,
            objectFit,
            onClick
        },
        ref
    ) => {
        const disabledTooltip = !enableTooltip
        const [isHover, setHover] = useState(false)

        const iconfontColor = useMemo(() => {
            if (active || isHover) {
                return activeColor
            }
            return iconColor
        }, [active, activeColor, iconColor, isHover])

        const content = useMemo(() => {
            if (!icon) {
                return <SCxText size={typeof size === 'number' ? size : avatarSize[size]}>{name?.slice(0, 2)}</SCxText>
            }
            if (type === 'img') {
                return (
                    <SCxImg
                        size={typeof size === 'number' ? size : avatarSize[size]}
                        src={icon}
                        alt={name}
                        radius={radius}
                        objectFit={objectFit}
                    />
                )
            }
            return <SCxIcon iconSize={iconSize} fill={iconfontColor} type={icon} />
        }, [icon, type, iconSize, iconfontColor, size, name, radius, objectFit])

        const avatarActive = useMemo(() => {
            if (active) {
                return active
            }
            return enableHover && isHover
        }, [active, enableHover, isHover])

        return (
            <Tooltip disabled={disabledTooltip} withArrow offset={2} placement="right" title={tooltipLabel}>
                <SCxContainer
                    ref={ref}
                    background={background}
                    enableHover={enableHover}
                    color={color}
                    active={avatarActive}
                    size={typeof size === 'number' ? size : avatarSize[size]}
                    radius={radius}
                    activeRadius={activeRadius}
                    activeColor={activeColor}
                    activeBgColor={activeBgColor}
                    // onMouseEnter={() => enableHover && setHover(true)}
                    onMouseOver={() => enableHover && setHover(true)}
                    onMouseLeave={() => enableHover && setHover(false)}
                    onClick={onClick}
                    style={style}
                    bordered={bordered}
                    className={className}
                >
                    {content}
                    {children}
                    {dot && <Dot />}
                </SCxContainer>
            </Tooltip>
        )
    }
)
