import { Breadcrumbs, Group, IconFont, Select, Switch, Text } from '@byecode/ui'
import type { DataSourceAbstract, ViewField, ViewOptions } from '@lighthouse/core'
import { GalleryAvatarShape, GalleryAvatarSize } from '@lighthouse/core'
import { getFieldIcon, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import type { FieldOption } from '@/components/FieldSelect'
import { FieldSelect } from '@/components/FieldSelect'

import FormSegmentedControl from '../../Common/FormSegmentedControl'
import {
    ALIGN_CONTROL,
    AVATAR_SHAPE_CONTROL,
    AVATAR_SIZE_OPTIONS,
    COVER_RATIO_OPTIONS,
    IMAGE_FIT_OPTIONS,
    POSITION_CONTROL
} from '../../constants'
import { FieldListConfigure } from './FieldListConfigure'

interface GalleryLayoutConfigureProps {
    columns: ViewField[]
    onGoBack?: () => void
}

export const GalleryLayoutConfigure = ({ columns, onGoBack }: GalleryLayoutConfigureProps) => {
    const { control, getValues, setValue } = useFormContext<ViewOptions & { title?: string }>()

    const [avatarPosition] = getValues(['headAvatar.position'])

    // 所有字段
    const fieldOptions = useMemo<FieldOption[]>(() => {
        return columns.map(item => ({
            label: item.title,
            icon: getFieldIcon(item.fieldId, item.type, item.innerType),
            value: item.fieldId,
            type: item.type
        }))
    }, [columns])

    // 附件字段
    const fileFieldOptions = useMemo<FieldOption[]>(() => {
        return columns
            .filter(item => item.type === 'file')
            .map(item => ({
                label: item.title,
                icon: getFieldIcon(item.fieldId, item.type, item.innerType),
                value: item.fieldId,
                type: item.type
            }))
    }, [columns])

    /**
     * 开启封面时，自动选择附件字段
     * @date 7/4/2023 - 6:05:13 PM
     * @param {boolean} bool 是否开启封面
     * @type {*}
     */
    const onToggleCoverFeat = useCallback(
        (bool: boolean) => {
            const fileField = fileFieldOptions[0]

            setValue('headCover', {
                show: bool,
                field: getValues('headCover.field') || fileField?.value,
                objectFit: getValues('headCover.objectFit') || 'cover',
                ratio: getValues('headCover.ratio') || '4:3'
            })
        },
        [getValues, setValue, fileFieldOptions]
    )

    /**
     * 开启头像时，自动选择附件字段
     * @date 7/4/2023 - 6:06:42 PM
     * @param {boolean} bool 是否开启封面
     * @type {*}
     */
    const onToggleAvatarFeat = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const avatarField = getValues('headAvatar.field')
            if (avatarField) {
                return
            }

            const fileField = fileFieldOptions[0]
            if (!fileField) {
                return
            }

            setValue('headAvatar.field', fileField.value)
        },
        [getValues, setValue, fileFieldOptions]
    )

    /**
     * 开启标题时，自动选择文本字段
     * @date 7/14/2023 - 6:52:13 PM
     * @param {boolean} bool 是否开启
     * @type {*}
     */
    const onToggleHeadTitle = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const field = getValues('headTitle.field')
            if (field) {
                return
            }

            const textField = columns.find(item => item.type === 'text')
            if (!textField) {
                return
            }

            setValue('headTitle.field', textField.fieldId)
        },
        [columns, getValues, setValue]
    )

    /**
     * 开启头部标签时，自动选择文本字段或id字段
     * @date 7/14/2023 - 6:53:47 PM
     * @param {boolean} bool 是否开启
     * @type {*}
     */
    const onToggleHeadTag = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const field = getValues('headTags.fields')
            if (field) {
                return
            }

            const textField = columns.find(item => item.type === 'text')
            if (!textField) {
                const idField = columns.find(item => item.type === 'id')
                if (!idField) {
                    return
                }

                setValue('headTags.fields', [{ id: idField.fieldId, wrap: true }])
                return
            }

            setValue('headTags.fields', [{ id: textField.fieldId, wrap: true }])
        },
        [columns, getValues, setValue]
    )

    /**
     * 开启主体字段时，自动选择文本字段
     * @date 7/14/2023 - 6:53:47 PM
     * @param {boolean} bool 是否开启
     * @type {*}
     */
    const onToggleMainTag = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const field = getValues('contentTags.fields')
            if (field) {
                return
            }

            const textField = columns.find(item => item.type === 'text')
            if (!textField) {
                return
            }

            setValue('contentTags.fields', [{ id: textField.fieldId, wrap: true }])
        },
        [columns, getValues, setValue]
    )

    /**
     * 开启尾部字段时，自动选择文本字段或id字段
     * @date 7/14/2023 - 6:53:47 PM
     * @param {boolean} bool 是否开启
     * @type {*}
     */
    const onToggleFootTag = useCallback(
        (bool: boolean) => {
            if (!bool) {
                return
            }

            const field = getValues('footTags.fields')
            if (field) {
                return
            }

            const textField = columns.find(item => item.type === 'text')
            if (!textField) {
                const idField = columns.find(item => item.type === 'id')
                if (!idField) {
                    return
                }

                setValue('footTags.fields', [{ id: idField.fieldId, wrap: true }])
                return
            }

            setValue('footTags.fields', [{ id: textField.fieldId, wrap: true }])
        },
        [columns, getValues, setValue]
    )

    return (
        <>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '卡片内容排版', onClick: onGoBack }]} />
            <Divider color="var(--color-gray-200)" />

            {/* 封面 */}
            <Controller
                control={control}
                name="headCover.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="封面"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            onToggleCoverFeat(v)
                        }}
                    >
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>选择一个附件字段</div>
                            <Controller
                                name="headCover.field"
                                control={control}
                                render={({ field }) => <FieldSelect {...field} style={{ width: 180 }} options={fileFieldOptions} />}
                            />
                        </ListItem4ByecodeUi>

                        <Controller
                            control={control}
                            name="headCover.objectFit"
                            render={({ field }) => <FormSegmentedControl {...field} label="适应方式" data={IMAGE_FIT_OPTIONS} />}
                        />
                        {/* <Controller
                            control={control}
                            name="headCover.ratio"
                            defaultValue="4:3"
                            render={({ field }) => <FormSegmentedControl {...field} label="比例" data={COVER_RATIO_OPTIONS} />}
                        /> */}
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>比例</Text>
                            <Controller
                                control={control}
                                name="headCover.ratio"
                                render={({ field }) => <Select style={{ width: 180 }} options={COVER_RATIO_OPTIONS} {...field} />}
                            />
                        </ListItem4ByecodeUi>
                    </Group>
                )}
            />

            <Divider color="var(--color-gray-200)" />

            {/* 头像 */}
            <Controller
                control={control}
                name="headAvatar.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="头像"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)
                            onToggleAvatarFeat(v)
                        }}
                    >
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>选择一个附件字段</div>
                            <Controller
                                name="headAvatar.field"
                                control={control}
                                render={({ field }) => <FieldSelect {...field} style={{ width: 180 }} options={fileFieldOptions} />}
                            />
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name="headAvatar.size"
                            defaultValue={GalleryAvatarSize.md}
                            render={({ field }) => <FormSegmentedControl {...field} label="尺寸" data={AVATAR_SIZE_OPTIONS} />}
                        />
                        <Controller
                            control={control}
                            name="headAvatar.position"
                            defaultValue="top"
                            render={({ field }) => <FormSegmentedControl {...field} label="位置" data={POSITION_CONTROL} />}
                        />
                        {(!avatarPosition || avatarPosition === 'top') && (
                            <Controller
                                control={control}
                                name="headAvatar.align"
                                defaultValue="left"
                                render={({ field }) => <FormSegmentedControl {...field} label="对齐方式" data={ALIGN_CONTROL} />}
                            />
                        )}
                        <Controller
                            control={control}
                            name="headAvatar.shape"
                            defaultValue={GalleryAvatarShape.circle}
                            render={({ field }) => <FormSegmentedControl {...field} label="形状" data={AVATAR_SHAPE_CONTROL} />}
                        />
                    </Group>
                )}
            />

            <Divider color="var(--color-gray-200)" />

            {/* 标题 */}
            <Controller
                control={control}
                name="headTitle.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="标题"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)
                            onToggleHeadTitle(v)
                        }}
                    >
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>选择一个字段</div>
                            <Controller
                                name="headTitle.field"
                                control={control}
                                render={({ field }) => {
                                    return <FieldSelect {...field} style={{ width: 180 }} options={fieldOptions} />
                                }}
                            />
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name="headTitle.align"
                            render={({ field }) => <FormSegmentedControl {...field} label="对齐方式" data={ALIGN_CONTROL} />}
                        />
                    </Group>
                )}
            />

            <Divider color="var(--color-gray-200)" />

            {/* 副标题 */}
            <Controller
                control={control}
                name="headTags.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="副标题"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)
                            onToggleHeadTag(v)
                        }}
                    >
                        <Controller
                            control={control}
                            name="headTags.fields"
                            render={({ field }) => <FieldListConfigure value={field.value} onChange={field.onChange} columns={columns} />}
                        />
                        <Divider color="var(--color-gray-200)" my={12} />
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>显示字段标题</div>
                            <Controller
                                control={control}
                                name="headTags.showLabel"
                                render={({ field: showLabelField }) => (
                                    <Switch checked={showLabelField.value} onChange={e => showLabelField.onChange(e.target.checked)} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name="headTags.align"
                            render={({ field }) => <FormSegmentedControl {...field} label="对齐方式" data={ALIGN_CONTROL} />}
                        />
                    </Group>
                )}
            />

            <Divider color="var(--color-gray-200)" />

            {/* 主体字段 */}
            <Controller
                control={control}
                name="contentTags.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="主体字段"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)
                            onToggleMainTag(v)
                        }}
                    >
                        <Controller
                            control={control}
                            name="contentTags.fields"
                            render={({ field }) => <FieldListConfigure {...field} columns={columns} />}
                        />
                        <Divider color="var(--color-gray-200)" my={12} />
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>显示字段标题</div>
                            <Controller
                                control={control}
                                name="contentTags.showLabel"
                                render={({ field: showLabelField }) => (
                                    <Switch checked={showLabelField.value} onChange={e => showLabelField.onChange(e.target.checked)} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name="contentTags.align"
                            render={({ field }) => <FormSegmentedControl {...field} label="对齐方式" data={ALIGN_CONTROL} />}
                        />
                    </Group>
                )}
            />

            <Divider color="var(--color-gray-200)" />

            {/* 尾部标签 */}
            <Controller
                control={control}
                name="footTags.show"
                render={({ field }) => (
                    <Group
                        mode="switch"
                        label="尾部标签"
                        defaultOpen={false}
                        opened={field.value}
                        onCollapseChange={v => {
                            field.onChange(v)
                            onToggleFootTag(v)
                        }}
                    >
                        <Controller
                            control={control}
                            name="footTags.fields"
                            render={({ field }) => <FieldListConfigure {...field} columns={columns} />}
                        />
                        <Divider color="var(--color-gray-200)" my={12} />
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>显示字段标题</div>
                            <Controller
                                control={control}
                                name="footTags.showLabel"
                                render={({ field: showLabelField }) => (
                                    <Switch checked={showLabelField.value} onChange={e => showLabelField.onChange(e.target.checked)} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <Controller
                            control={control}
                            name="footTags.align"
                            render={({ field }) => <FormSegmentedControl {...field} label="对齐方式" data={ALIGN_CONTROL} />}
                        />
                    </Group>
                )}
            />
        </>
    )
}
