import { Divider, Flex, Group, IconFont, Select, Text } from '@byecode/ui'
import type { FieldType, SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol, type FieldInputType } from '@lighthouse/core'
import { FieldInputNameMap, getTableIcon, ListItemPaddingByecodeUi, useAtomData } from '@lighthouse/shared'
import { find, findIndex } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import { FieldSelect } from '../../../../FieldSelect'
import { CommonOpera, Editable } from '../../UserOperate'
import { FieldInputDescMap } from '../constants'
import { getSaveFieldOptions, isDisabledFieldInput } from '../helps'
import type { BaseFieldInputSetting, DataMode, FieldOptions } from '../types'
import { User } from '../User'
import { CascadeConfig, CascadeOperation } from './CascadeConfig'
import { CheckboxConfig } from './CheckboxConfig'
import PersonConfig from './PersonConfig'
import { PhoneNumberConfig } from './PhoneNumberConfig'
import RelativeSelectConfig from './RelativeSelectConfig'
import SliderConfig from './SliderConfig'
import { TextConfig } from './TextConfig'

interface DataProps extends Omit<BaseFieldInputSetting, 'blockId'> {
    mode: DataMode
    fieldOptions?: FieldOptions
}

const modeNameMap: Record<DataMode, string> = { field: '页面', form: '表单容器', subForm: '子表单' }

const SCxItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    padding: 0 16px;
    gap: 8px;
`

export const Data: React.FunctionComponent<DataProps> = ({ mode, fieldOptions = [], pointer, prefix }) => {
    const { watch, setValue, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [inputType, fieldPointer = '', canEdit] = watch([`${prefix}inputType`, `${prefix}fieldPointer`, `${prefix}canEdit`])
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const isParentNotSubForm = mode !== 'subForm'

    const dataSource = useDataSource(appId, envId, pointer)

    const field = dataSource?.schema?.[fieldPointer ?? '']

    const disableEdit = useMemo(() => isDisabledFieldInput(fieldPointer, fieldOptions), [fieldOptions, fieldPointer])

    const [saveFieldOptions, disableSaveFiledList]: [FieldOptions, FieldType[]] = useMemo(
        () => getSaveFieldOptions(inputType, fieldOptions, mode),
        [mode, fieldOptions, inputType]
    )

    const isShowAllowMultiple = canEdit && field && inputType === 'relativeSelect' && field?.type !== 'select' && field.type !== 'number'

    const operationEle = useMemo(() => {
        return (
            <>
                {isParentNotSubForm && (
                    <div style={{ padding: '0 8px' }}>
                        <Controller
                            name={`${prefix}canEdit`}
                            render={({ field }) => (
                                <Editable
                                    disabled={disableEdit}
                                    tooltipLabel="您选择的字段不支持编辑"
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                        !val && setValue(`${prefix}required`, false)
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                {inputType === 'person' && <PersonConfig prefix={prefix} />}

                {isShowAllowMultiple && (
                    <div style={{ padding: '0 8px' }}>
                        <Controller
                            name={`${prefix}${inputType}.canMultipleChoice`}
                            render={({ field }) => <CommonOpera label="多选" value={field.value} onChange={field.onChange} />}
                        />
                    </div>
                )}
                {field && inputType === 'cascade' && <CascadeOperation prefix={prefix} />}
            </>
        )
    }, [disableEdit, field, inputType, isParentNotSubForm, isShowAllowMultiple, prefix, setValue])

    const isOperationChildren = operationEle.props.children.some(Boolean)
    return (
        <>
            <Group
                label="字段"
                styles={{
                    collapse: {
                        padding: '0!important'
                    }
                }}
            >
                <ListItemPaddingByecodeUi enablePadding alignItems="center" justifyContent="space-between">
                    <Text>写入字段</Text>
                    <Controller
                        name={`${prefix}fieldPointer`}
                        render={({ field }) => {
                            return (
                                <FieldSelect
                                    options={saveFieldOptions}
                                    position="bottom-end"
                                    value={field.value}
                                    dsId={pointer}
                                    onChange={val => {
                                        field.onChange(val)
                                        const index = findIndex(item => item.value === val, saveFieldOptions)
                                        index !== -1 && setValue(`${prefix}title`, saveFieldOptions[index].label)
                                        const disabledEdit = isDisabledFieldInput(val, fieldOptions)
                                        if (disabledEdit) {
                                            setValue(`${prefix}canEdit`, false)
                                            setValue(`${prefix}required`, false)
                                        }
                                        const fieldType = find(item => item.value === val, saveFieldOptions)?.type
                                        if (inputType === 'relativeSelect' && fieldType === 'number') {
                                            setValue(`${prefix}${inputType}.canMultipleChoice`, false)
                                        }
                                    }}
                                    disableTitle={
                                        saveFieldOptions.length > 0 ? `${FieldInputNameMap[inputType]}输入框不支持以下字段：` : ''
                                    }
                                    disableList={saveFieldOptions.length > 0 ? disableSaveFiledList : []}
                                    dropdownWidth={250}
                                    emptyComponent={
                                        <Flex
                                            style={{ height: 100 }}
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={6}
                                        >
                                            <IconFont size={24} color="var(--color-gray-500)" type="Nodata-8i554976" />
                                            <Text size={14} color="var(--color-gray-500)">
                                                {FieldInputDescMap[inputType]}
                                            </Text>
                                        </Flex>
                                    }
                                />
                            )
                        }}
                    />
                </ListItemPaddingByecodeUi>
                <SCxItem>
                    {pointer ? (
                        <>
                            <Text size={12} color="var(--color-gray-500)">
                                字段来自
                            </Text>
                            <Flex gap={4}>
                                <IconFont size={16} color="var(--color-gray-400)" type={getTableIcon(dataSource)} />
                                <Text size={12} color="var(--color-gray-500)">
                                    {dataSource?.name}
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Text size={12} color="var(--color-red-500)">
                                {`无法选择字段，因为所在${modeNameMap[mode]}未指定数据表`}
                            </Text>
                            <IconFont size={16} color="var(--color-red-500)" type="Warning" />
                        </>
                    )}
                </SCxItem>
            </Group>

            {field && inputType === 'relativeSelect' && field.type !== 'select' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <RelativeSelectConfig mode={mode} fieldOptions={fieldOptions} prefix={prefix} />
                </>
            )}
            {field && inputType === 'slider' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <SliderConfig prefix={prefix} />
                </>
            )}
            {inputType === 'checkbox' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <CheckboxConfig prefix={prefix} />
                </>
            )}
            {inputType === 'cascade' && <CascadeConfig pointer={pointer} prefix={prefix} />}
            <Divider color="var(--color-gray-200)" />

            {isOperationChildren && (
                <>
                    <Group
                        label="操作"
                        styles={{
                            root: {
                                padding: '12px 0!important'
                            },
                            collapse: {
                                padding: '0!important'
                            }
                        }}
                    >
                        {operationEle}
                    </Group>
                    <Divider color="var(--color-gray-200)" />
                </>
            )}

            <User pointer={pointer} mode={mode} prefix={prefix} />

            {field && inputType === 'phoneNumber' && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <PhoneNumberConfig prefix={prefix} />
                </>
            )}
            {field && isParentNotSubForm && canEdit && inputType === 'text' && (
                <>
                    <Divider style={{ margin: 0 }} color="var(--color-gray-200)" />
                    <TextConfig prefix={prefix} />
                </>
            )}
        </>
    )
}
