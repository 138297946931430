import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { DataSourceAbstract, RecordLikeProtocol, TableColumn } from '@lighthouse/core'
import { ScrollArea } from '@mantine/core'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

import { EmptyNoRecordSvg } from '../EmptyNoRecordSvg'
import SimpleTableBody from './SimpleTableBody'
import { SimpleTableHeader } from './SimpleTableHeader'

const SCxTableWrapper = styled(ScrollArea)`
    height: 100%;
    width: 100%;
    user-select: none;
    overflow: visible;

    & .mantine-ScrollArea-viewport {
        max-height: 100%;
        height: auto;
        border: 1px solid var(--color-gray-200);
        border-radius: 8px;
        padding-bottom: 1px;
    }

    & .mantine-ScrollArea-scrollbar[data-orientation='horizontal'] {
        bottom: -26px !important;
    }
    & .mantine-ScrollArea-scrollbar[data-orientation='vertical'] {
        right: -26px !important;
    }
`

const SCxTableNoDataContent = styled.div<{ width?: number }>`
    width: 100%;
    padding: 40px 0;
    position: sticky;
    left: 0;
    top: 0;
`

export interface SimpleTableProps {
    dataSource: DataSourceAbstract
    records: RecordLikeProtocol[]
}

export const SimpleTable: React.FC<SimpleTableProps> = ({ dataSource, records }) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const {
        schema,
        viewOptions: { tableProps }
    } = dataSource
    const [columns, setColumns] = useState<TableColumn[]>(tableProps)

    const handleChangeTableProps = useCallback((columns: TableColumn[]) => {
        setColumns(columns)
    }, [])

    return (
        <SCxTableWrapper viewportRef={scrollRef}>
            <SimpleTableHeader tableProps={columns} schema={schema} onChangeTableProps={handleChangeTableProps} />

            <SimpleTableBody
                scrollRef={scrollRef}
                schema={schema}
                tableProps={columns}
                records={records}
                // blockWidth={blockWidth}
            />
            {records.length === 0 && (
                <SCxTableNoDataContent>
                    <Empty
                        icon={
                            <EmptyNoRecordSvg color='var(--color-app-main)' />
                        }
                        description="未找到数据"
                    />
                </SCxTableNoDataContent>
            )}
        </SCxTableWrapper>
    )
}
