import { IconFont } from '@byecode/ui'
import React from 'react'

export function formatSpaceFeaturesValue(value: boolean | number | string | undefined) {
    if (typeof value === 'boolean') {
        return {
            isNumber: false,
            value: value ? (
                <IconFont type="CheckboxTick" size={32} color="var(--color-main)" />
            ) : (
                <span style={{ color: 'var(--color-gray-400)' }}>-</span>
            )
        }
    }

    if (typeof value === 'number') {
        if (value === -1) {
            return { isNumber: false, value: '不限' }
        }
        if (value === -2) {
            return { isNumber: false, value: '咨询' }
        }
        // billy 要求大于5位数字才开始使用千分位
        return { isNumber: true, value: value > 9999 ? value.toLocaleString() : value }
    }

    return { isNumber: false, value }
}
