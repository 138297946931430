import { IconFont, Tooltip } from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import React from 'react'

import { TagIcon } from '../../../TagIcon'
import * as SC from './styles'

interface TextInputProps {
    placeholder?: string
    field?: Field
    data?: string | number
    defaultData?: string | number
    type?: 'number' | 'text'
    width?: string
    disabled?: boolean
    opened?: boolean

    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onChangeOpen?: () => void
}

export const TextInput: React.FunctionComponent<TextInputProps> = ({
    placeholder,
    data,
    opened,
    type = 'text',
    width,
    defaultData,
    disabled,
    onChangeOpen,
    onBlur,
    onChange
}) => {
    return (
        <SC.ParamsInput
            width={width}
            value={data}
            defaultValue={defaultData}
            type={type}
            disabled={disabled}
            placeholder={disabled ? '-' : placeholder}
            onChange={onChange}
            onBlur={onBlur}
            suffix={
                disabled ? null : (
                    <Tooltip title="选择数据">
                        <TagIcon
                            icon="DotsThreeVertical"
                            size={24}
                            radius={5}
                            isActive={opened}
                            iconSize={16}
                            enableHover
                            hoverColor="rgba(38, 65, 90, 0.06)"
                            style={{ marginRight: 4 }}
                            onClick={() => onChangeOpen?.()}
                        />
                    </Tooltip>
                )
            }
        />
    )
}
