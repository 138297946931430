import { Button, IconFont, Input, Toast } from '@byecode/ui'
import { FILE_ICON_MAP } from '@lighthouse/shared'
import { getFileSizeWithUnit } from '@lighthouse/tools'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import ExportTemplateChecker from './ExportTemplateChecker'

export type ExportTemplateFormValues = {
    templateName: string
    file: File | null
}

interface ExportTemplateClickDropZoneProps {
    dsId: string
    onSubmit?: (values: ExportTemplateFormValues) => Promise<void> | void
    onCancel?: () => void
}

const SCxUploaderArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    font-size: var(--font-size-normal);
    border-radius: 6px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);
    outline: none;
    cursor: pointer;

    &.drag-over {
        border-color: var(--color-main);
        background-color: var(--color-main-tint);
    }
`

const SCxUploaderAreaTip = styled.div``

const SCxTipWrapper = styled.div`
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 6px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-100);
`

const SCxTip = styled.div`
    position: relative;
    font-size: var(--font-size-normal);
    color: var(--color-gray-600);
    padding: 4px 10px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: var(--color-gray-800);
    }
`

const SCxFileViewerContainer = styled.div`
    display: flex;
    padding: 0 14px;
    height: 52px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
`

const SCxFileIcon = styled(IconFont)`
    margin-right: 6px;
    font-size: var(--font-size-lg);
`

const SCxFileName = styled.p`
    margin-right: 24px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const SCxFileSize = styled.p`
    flex: 1;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
`

const SCxClearFile = styled(IconFont)`
    font-size: var(--font-size-normal);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;

    ${SCxFileViewerContainer}:hover & {
        opacity: 1;
        transition: opacity 0.2s ease;
    }
`

const SCxFormItem = styled.div`
    margin-bottom: 12px;
`

const SCxFormItemLabel = styled.div`
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxFormItemLabelLeft = styled.span`
    &:after {
        content: '*';
        position: absolute;
        line-height: 18px;
        color: var(--color-red-500);
        margin-left: 6px;
    }
`
const SCxFormItemLabelRight = styled.span``

const SCxActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
`

const fileAccept = { 'application/vnd.ms-excel': ['.xlsx'] }
// 限制 5 M
const fileSizeLimit = 5
const ExportTemplateClickDropZone: React.FC<ExportTemplateClickDropZoneProps> = ({ dsId, onSubmit, onCancel }) => {
    const { control, register, setValue, reset, getValues, watch, handleSubmit } = useForm<ExportTemplateFormValues>()
    const file = watch('file')

    const handleFileClear = () => {
        reset({ file: null })
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: fileAccept,
        multiple: false,
        maxFiles: 1,
        onDropAccepted(files, event) {
            const file = files[0]
            if (!file) {
                return
            }
            if (file.size > fileSizeLimit * 1024 * 1024) {
                Toast.error(`请上传小于 ${fileSizeLimit} MB 的文件`)
                return
            }
            setValue('file', file)
        },
        onDropRejected(files, event) {
            const fileNames = files.map(({ file }) => file.name)
            Toast.error(`${fileNames.join(',')} 文件不支持，仅支持上传.xlsx、.csv 格式的文件`)
        }
    })

    const handleSave = useCallback(() => {
        handleSubmit(values => onSubmit?.(values))()
    }, [handleSubmit, onSubmit])

    const haveFile = !!file

    return (
        <div>
            <SCxTipWrapper>
                <SCxTip>请先在本地的 Excel 文件中编辑模板内容和样式</SCxTip>
                <SCxTip>若需要插入表单字段，请按照「数据代码对照表」将所需字段或数据表对应的数据代码复制、粘贴到Excel模板对应的位置</SCxTip>
                <SCxTip>查看「帮助手册」了解更多制作技巧和注意事项</SCxTip>
            </SCxTipWrapper>
            <SCxFormItem>
                <SCxFormItemLabel>
                    <SCxFormItemLabelLeft>模板名称</SCxFormItemLabelLeft>
                </SCxFormItemLabel>
                <Input size="lg" placeholder="请输入模板名" {...register('templateName', { required: true })} />
            </SCxFormItem>
            <SCxFormItem>
                <SCxFormItemLabel>
                    <SCxFormItemLabelLeft>上传模板</SCxFormItemLabelLeft>
                    <SCxFormItemLabelRight>
                        <ExportTemplateChecker dsId={dsId} />
                    </SCxFormItemLabelRight>
                </SCxFormItemLabel>
            </SCxFormItem>
            {haveFile ? (
                <SCxFileViewerContainer>
                    <SCxFileIcon type={FILE_ICON_MAP.xls} />
                    <SCxFileName>{file.name}</SCxFileName>
                    <SCxFileSize>{getFileSizeWithUnit(file.size)}</SCxFileSize>
                    <SCxClearFile type="Close" onClick={handleFileClear} />
                </SCxFileViewerContainer>
            ) : (
                <Controller
                    name="file"
                    control={control}
                    render={field => (
                        <SCxUploaderArea {...getRootProps({ className: isDragActive ? 'drag-over' : '' })}>
                            <input {...getInputProps()} />
                            <IconFont type={FILE_ICON_MAP.xls} size={32} style={{ marginBottom: 6 }} />
                            <SCxUploaderAreaTip>点击或拖拽 .xlsx、.csv 文件（5M以内）到此区域</SCxUploaderAreaTip>
                            <SCxUploaderAreaTip>文件中大小不能超过 5MB</SCxUploaderAreaTip>
                        </SCxUploaderArea>
                    )}
                />
            )}
            <SCxActionWrapper>
                <Button size="lg" style={{ marginRight: 8 }} onClick={onCancel}>
                    取消
                </Button>
                <Button size="lg" type="primary" onClick={handleSave}>
                    确定
                </Button>
            </SCxActionWrapper>
        </div>
    )
}

export default ExportTemplateClickDropZone
