import type { ColorPickerProps as ColorPickerDropDownProps } from '@byecode/ui'
import { ColorPicker as ColorPickerDropDown, IconFont, Popover } from '@byecode/ui'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React, { useState } from 'react'
import styled from 'styled-components'

export interface ColorPickProps extends ColorPickerDropDownProps {
    disable?: boolean
    width?: number
    style?: React.CSSProperties
}

const ColorPreview = styled.div`
    padding: 2px;
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    box-sizing: border-box;
`

const Color = styled.div<{ color: string; width: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ width }) => `${width}px`};
    background-color: ${({ color }) => color};
    border-radius: 5px;
`

const ColorPicker = React.forwardRef<HTMLDivElement, ColorPickProps>(
    ({ value = '#fff', disable = false, width = 24, noColorMode, showSystemColors, onChange, ...rest }, ref) => {
        // const popupState = usePopupState({ variant: 'popover', popupId: 'colorPickPopupState' })
        const [open, setOpen] = useState(false)

        return (
            <Popover width="auto" opened={open} onChange={setOpen} withinPortal disabled={disable}>
                <Popover.Target>
                    <ColorPreview {...rest}>
                        {value === '' ? <IconFont type="NoColor" size={width} /> : <Color width={width} color={value} />}
                    </ColorPreview>
                </Popover.Target>

                <Popover.Dropdown>
                    <ColorPickerDropDown noColorMode={noColorMode} showSystemColors={showSystemColors} value={value} onChange={onChange} />
                </Popover.Dropdown>
            </Popover>
        )
    }
)

export default ColorPicker
