import { Button, Flex, Text } from '@byecode/ui'
import { type FieldInputRules, getFieldInputError } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import type { ValidateInputProps } from '@/components/AppCreator/AppSet/ValidateInput'
import { ValidateInput } from '@/components/AppCreator/AppSet/ValidateInput'

interface DomainInputProps {
    domain: string
    placeholder?: string
    showEdit?: boolean
    validateInputProps: Omit<ValidateInputProps, 'value'> & {
        defaultValue?: string
        rules: FieldInputRules
    }
    rightSection?: React.ReactNode
    leftSection?: React.ReactNode
    onSubmit: (v: string) => void
    onCancel: () => void
}

const SCxPreview = styled.div`
    padding: 0 8px;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-gray-100);
    border-radius: 8px;
`

const SCxEdit = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxDomain = styled(Text)`
    flex: 1;
`
const SCxBtnGroup = styled(Flex)``
const DomainInput: React.FunctionComponent<DomainInputProps> = ({
    validateInputProps,
    showEdit,
    domain,
    leftSection,
    rightSection,
    placeholder,
    onSubmit,
    onCancel
}) => {
    const { rules, defaultValue, name } = validateInputProps

    const [value, setValue] = useState<string>(defaultValue ?? '')

    const [isCanUsed, setIsCanUsed] = useState<boolean | undefined>(undefined)

    const errors = useMemo(() => getFieldInputError({ fieldType: 'text', value: { type: 'text', value: 'value' }, rule: rules }), [rules])

    useUpdateEffect(() => {
        setValue(defaultValue ?? '')
    }, [defaultValue])

    const disabled = Object.keys(errors).length > 0 || value === '' || !isCanUsed

    return showEdit ? (
        <SCxEdit>
            <ValidateInput
                {...validateInputProps}
                disabled={false}
                value={value}
                onChange={e => setValue(e.target.value)}
                onChangeUsed={setIsCanUsed}
            />
            <SCxBtnGroup gap={8}>
                <Button
                    block
                    disabled={false}
                    onClick={() => {
                        setValue(defaultValue ?? '')
                        onCancel()
                    }}
                >
                    取消
                </Button>
                <Button block type="primary" disabled={disabled} onClick={() => onSubmit(value)}>
                    保存
                </Button>
            </SCxBtnGroup>
        </SCxEdit>
    ) : (
        <SCxPreview>
            {leftSection}

            {domain ? (
                <SCxDomain size={14} color="var(--color-main)">
                    {domain}
                </SCxDomain>
            ) : (
                <SCxDomain size={14} color="var(--color-gray-300)">
                    {placeholder}
                </SCxDomain>
            )}
            {rightSection}
        </SCxPreview>
    )
}

export default DomainInput
