import type {
    ApplicationAbstract,
    ApplicationPrivateTemplate,
    ApplicationVersionBranch,
    ApplicationVersionStatus,
    ApplicationWebsiteSetting,
    BaseApplicationAbstract,
    Environment
} from '@lighthouse/core'
import { ApplicationStruct, ApplicationType } from '@lighthouse/core'
import type { FindUseData } from '@lighthouse/shared'
import {
    type ApplicationPreviewEnum,
    APPLICATION_ENV_PROD,
    APPLICATION_VERSION_PROD,
    CHINA_LANG_LIST,
    DEFAULT_LANG
} from '@lighthouse/shared'
import i18n from 'i18next'
import { atom } from 'jotai'
import { clone, find, findIndex, mergeDeepRight } from 'rambda'
import { debounce } from 'throttle-debounce'

import http from '@/http'
import * as srv from '@/services'
import type { CreateApplicationPayload } from '@/services/types'

// import { dataSourceEnvIdAtom } from '../dataSource/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import { getNewDepartment } from '../utils/userSetting'
import { currentWorkSpaceIdAtom } from '../workSpace/state'
import { type ApplicationLayoutMode, type ApplicationModePageHistory } from './constants'
import {
    appAtom,
    appEnvListAtom,
    appLayoutModeAtom,
    applicationModePageHistoryAtom,
    applicationVersionAtom,
    appListAtom,
    appRoleAtom,
    currentAppIdAtom,
    currentEnvIdAtom,
    findUseAtom,
    languageAtom,
    previewTypeAtom,
    websiteApplicationSettingAtom
} from './state'
import type { CreateEnvPayload } from './type'

type UpdateAppPayload = {
    config?: Partial<Pick<ApplicationWebsiteSetting, 'advertising' | 'domain' | 'seo' | 'sms' | 'webApp' | 'webTag'>>
} & Partial<BaseApplicationAbstract>

// 更新app的共享配置
export const updateAppAtom = atom(null, (get, set, payload: UpdateAppPayload) => {
    const app = clone(get(appAtom))
    set(appAtom, mergeDeepRight(app, payload))

    return srv.updateAppV2({ ...payload, id: app.id })
})
type UpdateAppVersionPayload = {
    config: Partial<Pick<ApplicationWebsiteSetting, 'authentication' | 'language' | 'navbar' | 'theme'>>
}
const debounceRequestUpdateAppVersionConfig = debounce(300, (params: Parameters<typeof srv.updateAppVersion>[0]) => {
    srv.updateAppVersion(params)
})
// 更新app version的config配置，在前端是和其他config合并的，单独拎出来
export const updateAppVersionConfigAtom = atom(null, (get, set, payload: UpdateAppVersionPayload) => {
    const app = clone(get(appAtom))
    set(appAtom, mergeDeepRight(app, payload))

    return debounceRequestUpdateAppVersionConfig({ ...payload, id: app.id })
})
// 更新app version一般属性
export const updateAppVersionAtom = atom(null, (get, set, payload: Omit<Partial<ApplicationVersionBranch>, 'config'> & { id: string }) => {
    // 不是当前版本的不用更新app
    // const app = clone(get(appAtom))
    // set(appAtom, mergeDeepRight(app, { version: payload }))

    return srv.updateAppVersion(payload)
})

// 更新app privateTemplate 私人模版链接
export const updateAppPrivateTemplateAtom = atom(null, (get, set, payload: ApplicationPrivateTemplate) => {
    const app = clone(get(appAtom))
    set(appAtom, mergeDeepRight(app, { config: { privateTemplate: payload } }))
    return srv.updateAppV2({
        id: app.id,
        config: {
            privateTemplate: payload
        }
    })
})

export const switchAppVersionPreviewAtom = atom(null, (_, set, payload: boolean) => {
    set(appAtom, draft => {
        draft.version.preview = payload
    })

    return srv.switchAppVersionPreview(payload)
})

export const fetchApplicationAtom = atom(null, async (_, set) => {
    const data = await srv.getApp()
    // 设置应用版本 id
    set(appAtom, data)
    set(currentWorkSpaceIdAtom, data.spaceId)
    // set(dataSourceEnvIdAtom, data.version.envId)
    const lang = data?.type === ApplicationType.website ? data?.config?.language?.list?.[0]?.lang : ''
    if (lang) {
        set(languageAtom, lang)
        http.defaults.headers.common['lang'] = lang
    }

    http.defaults.headers.common['appId'] = data.id
    http.defaults.headers.common['envId'] = data.version.envId
    return data
})

export const createApplicationAtom = atom(null, async (_, set, payload: CreateApplicationPayload) => {
    const appId = await srv.createApp(payload)
    if (!appId) {
        return ''
    }
    // set(appListAtom, draft => {
    //     draft.push(ApplicationStruct({ ...payload, id: appId }))
    // })
    return appId
})

export const removeApplicationAtom = atom(null, (_, set, id: string) => {
    set(appListAtom, draft => {
        const index = findIndex(item => item.id === id, draft)
        if (index !== -1) {
            draft.splice(index, 1)
        }
    })
    return srv.deleteApp(id)
})

export const copyApplicationAtom = atom(null, (_, set, id: string) => {
    return srv.copyApp({ appId: id })
})

export const createAppTemplateAtom = atom(null, async (_, set, params: { spaceId: string; templateId: string }) => {
    const { spaceId } = params
    const appId = await srv.createAppTemplate(params)
    if (!appId) {
        return ''
    }
    set(appListAtom, draft => {
        draft.push(ApplicationStruct({ id: appId }))
    })
    return appId
})

export const linkApplicationAtom = atom(null, (_, set, params: { envId: string; linkAppId: string; linkEnvId: string }) => {
    const { envId, linkAppId, linkEnvId } = params
    return srv.bindAppUsers(envId, linkAppId, linkEnvId)
})

export const unLinkApplicationAtom = atom(null, (_, set, envId: string) => {
    return srv.unBindAppUsers(envId)
})

export const fetchApplicationListAtom = atom(null, async (_, set, spaceId: string) => {
    const data = await srv.getApplicationList(spaceId)
    if (data) {
        set(appListAtom, data)
    }
    return data
})

export const setApplicationListAtom = atom(null, (_, set, payload: (draft: ApplicationAbstract[]) => void) => {
    set(appListAtom, payload)
})

// type UpdateAppSettingPayload = {
//     appId: string
//     config?: Partial<ApplicationSetting>
//     version?: Partial<ApplicationVersionBranch>
// }
// export const updateAppSettingAtom = atom(null, async (get, set, payload: UpdateAppSettingPayload) => {
//     const { appId, version, config } = payload
//     const { name: versionName, ...restVersion } = version ?? {}

//     const requestPayload = {
//         appId,
//         versionName,
//         config,
//         ...restVersion
//     }

//     const isSuccess = await srv.setAppConfig(requestPayload)
//     if (isSuccess) {
//         const app = clone(get(appAtom))
//         set(appAtom, mergeDeepRight(app, payload))
//     }
//     return isSuccess
// })

// const debounceUpdateAppConfig = debounce(300, srv.setAppConfig)
// export const debounceUpdateAppSettingAtom = atom(null, (get, set, payload: UpdateAppSettingPayload) => {
//     const { appId, version, config } = payload
//     const app = clone(get(appAtom))
//     set(appAtom, mergeDeepRight(app, payload))

//     const { name: versionName, ...restVersion } = version ?? {}

//     const requestPayload = {
//         appId,
//         versionName,
//         config,
//         ...restVersion
//     }

//     return debounceUpdateAppConfig(requestPayload)
// })

// export const publishAppVersionAtom = atom(null, async (_, set) => {
//     const newVersionId = await srv.appPublish()

//     if (newVersionId) {
//         set(switchAppVersionAtom, newVersionId)
//     }

//     return newVersionId
// })

export const mergeAppVersionAtom = atom(null, (get, set) => {
    return srv.mergeAppVersion()
})
// export const checkAppEnvAtom = atom(null, async (get, set) => {
//     const fromEnvId = get(currentEnvIdAtom)
//     const toEnvId = APPLICATION_ENV_PROD
//     const envDiffs = await srv.getEnvDiffs(fromEnvId, toEnvId)
//     const isAdditions = !envDiffs?.additions || envDiffs.additions.length === 0
//     const isDeletions = !envDiffs?.deletions || envDiffs.deletions.length === 0
//     const isErrors = !envDiffs?.errors || envDiffs.errors.length === 0
//     const isUpdates = !envDiffs?.updates || envDiffs.updates.length === 0
//     return isUpdates && isDeletions && isErrors && !envDiffs?.updates
// })
/**
 * 创建一个应用版本
 */
export const createAppVersionAtom = atom(null, (get, set, payload: Pick<ApplicationVersionBranch, 'name' | 'remark'>) => {
    return srv.createAppVersion(payload)
})
export const switchAppVersionAtom = atom(null, async (_, set, payload: string) => {
    const isSuccess = await srv.switchAppVersion(payload)
    if (isSuccess) {
        set(appAtom, draft => {
            draft.version.id = payload
            if (payload === APPLICATION_VERSION_PROD) {
                draft.version.envId = APPLICATION_ENV_PROD
            }
        })
    }

    return isSuccess
})

export const deleteAppVersionAtom = atom(null, (get, set, payload: string) => {
    return srv.deleteAppVersion(payload)
})

export const updateAppStatusAtom = atom(null, async (_, set, payload: Extract<ApplicationVersionStatus, 'OFFLINE' | 'ONLINE'>) => {
    const isSuccess = await srv.updateAppStatus(payload)
    if (isSuccess) {
        set(appAtom, draft => {
            draft.version.status = payload
        })
    }
    return isSuccess
})

export const fetchAppSettingAtom = atom(null, async (get, set) => {
    const data = await srv.getApp()
    if (data) {
        set(appAtom, draft => {
            draft.config = data.config
        })
    }
    return data
})

export const setApplicationModePageHistoryAtom = atom(
    null,
    (_, set, payload: ((state: ApplicationModePageHistory) => void) | ApplicationModePageHistory) => {
        set(applicationModePageHistoryAtom, payload)
    }
)

export const setPreviewTypeAtom = atom(null, (_, set, payload: ApplicationPreviewEnum) => {
    set(previewTypeAtom, payload)
})

export const setAppLayoutModeAtom = atom(null, (_, set, payload: Partial<ApplicationLayoutMode>) => {
    set(appLayoutModeAtom, draft => {
        if (draft) {
            applyDraftPayload(draft, payload)
        }
    })
})

export const setAppRoleAtom = atom(null, (_, set, payload: string) => {
    http.defaults.headers.common['appUserId'] = payload
    set(appRoleAtom, payload)
})

export const setLanguageAtom = atom(null, async (get, set, language: string) => {
    const app = get(websiteApplicationSettingAtom)
    const languageList = app?.language.list
    const matchLang = languageList?.find(item => item.lang === language)?.lang
    const englishLang = languageList?.find(item => item.lang === 'en')?.lang
    const zhLang = languageList?.find(item => item.lang === 'zh-CN')?.lang
    // 默认切换为当前语言，当找不到时，国外有英文则切英语，国内则切中文，都没有默认切换为第一个语言
    const lang = matchLang ?? (CHINA_LANG_LIST.has(language) ? zhLang : englishLang) ?? languageList?.[0].lang ?? DEFAULT_LANG
    http.defaults.headers.common['lang'] = lang
    await i18n.changeLanguage(lang)
    // 管理端不修改html的lang
    // document.documentElement.lang = lang === 'zh-CN' ? 'zh-Hans-CN' : lang
    set(languageAtom, lang)
    set(fetchAppSettingAtom)
})

export const setFindUseAtom = atom(null, (_, set, payload: FindUseData | undefined) => {
    set(findUseAtom, payload)
})

export const fetchAppEnvListAtom = atom(null, async (get, set) => {
    const appId = get(currentAppIdAtom)
    const data = await srv.getAppListEnv(appId)
    // 获取应用环境列表
    set(appEnvListAtom, data)
    return data
})

export const createAppEnvAtom = atom(null, async (_, set, payload: CreateEnvPayload) => {
    const envId = await srv.createAppEnv(payload)
    // 获取应用环境列表
    if (envId) {
        const newEnv = {
            ...payload,
            envId
        }
        set(appEnvListAtom, draft => {
            draft.push(newEnv)
        })
    }
    return envId
})

export const updateAppEnvAtom = atom(null, async (_, set, payload: Environment) => {
    const isUpdate = await srv.updateAppEnv(payload)
    // 获取应用环境列表
    set(appEnvListAtom, draft => {
        const env = find(item => item.envId === payload.envId, draft)
        if (env) {
            applyDraftPayload(env, payload)
        }
    })
    return isUpdate
})

export const deleteAppEnvAtom = atom(null, async (_, set, id: string) => {
    const isDelete = await srv.deleteAppEnv(id)
    if (isDelete) {
        set(appEnvListAtom, draft => {
            const index = findIndex(item => item.envId === id, draft)
            if (index !== -1) {
                draft.splice(index, 1)
            }
        })
    }
    return isDelete
})

export const switchAppEnvAtom = atom(null, async (_, set, payload: string) => {
    const isSuccess = await srv.switchAppEnv(payload)
    if (isSuccess) {
        set(appAtom, draft => {
            draft.version.envId = payload
        })
    }

    return isSuccess
})
