import * as React from 'react'

import { SpaceMember } from '@/containers/SpaceMember'

interface MemberProps {}

const Member: React.FC<MemberProps> = () => {
    return <SpaceMember />
}

export default Member
