import { type BaseModalProps,DesktopModal } from '@byecode/ui'
import React from 'react'

import type { WeComAccountMode } from '../type'
import WeComAccount from '../WeComAccount'

interface WeComModalProps extends BaseModalProps {
    mode: WeComAccountMode
    id?: string
}

export const WeComModal: React.FunctionComponent<WeComModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <WeComAccount mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
