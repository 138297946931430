/**
 * trigger validate
 */

import type {
    ClickTriggerConfig,
    CreateRecordTriggerConfig,
    InitiateApproveTriggerConfig,
    MatchConditionTriggerConfig,
    ScheduledTriggerConfig,
    UpdateRecordTriggerConfig
} from '@lighthouse/shared'

import type { NodeValidator, TriggerValidator } from '../types'

// create record trigger
const createRecordTriggerValidator: NodeValidator<TriggerValidator<CreateRecordTriggerConfig>, 'CREATE_RECORD_TRIGGER'> = {
    nodeType: 'CREATE_RECORD_TRIGGER',
    validators: [
        node => {
            return !!node.data.config?.dataSourceId
        }
    ]
}
// update record trigger
const updateRecordTriggerValidator: NodeValidator<TriggerValidator<UpdateRecordTriggerConfig>, 'UPDATE_RECORD_TRIGGER'> = {
    nodeType: 'UPDATE_RECORD_TRIGGER',
    validators: [
        // dsId required
        node => {
            return !!node.data.config?.dataSourceId
        },
        // 监听字段变更
        node => {
            return !!node.data.config?.watchedFields?.length
        }
    ]
}
// match record trigger
const matchRecordTriggerValidator: NodeValidator<TriggerValidator<MatchConditionTriggerConfig>, 'MATCH_CONDITION_TRIGGER'> = {
    nodeType: 'MATCH_CONDITION_TRIGGER',
    validators: [
        // dsId required
        node => {
            return !!node.data.config?.dataSourceId
        }
    ]
}
// schedule trigger
const scheduleTriggerValidator: NodeValidator<TriggerValidator<ScheduledTriggerConfig>, 'SCHEDULED_TRIGGER'> = {
    nodeType: 'SCHEDULED_TRIGGER',
    validators: [
        // cron required
        node => {
            return !!node.data.config?.scheduleStartTime
        }
    ]
}

//
const initiateApproveTriggerValidator: NodeValidator<TriggerValidator<InitiateApproveTriggerConfig>, 'INITIATE_APPROVE_TRIGGER'> = {
    nodeType: 'INITIATE_APPROVE_TRIGGER',
    validators: [
        // dsId required
        node => {
            return !!node.data.config?.dataSourceId
        }
        // // form required
        // node => {
        //     return !!node.data.config?.formModuleId
        // },
        // // 存储状态字段 required
        // node => {
        //     return !!node.data.config?.stateFieldId
        // }
    ]
}

// click trigger
const clickTriggerValidator: NodeValidator<TriggerValidator<ClickTriggerConfig>, 'CLICK_TRIGGER'> = {
    nodeType: 'CLICK_TRIGGER',
    validators: [
        // clickType required
        node => {
            return !!node.data.config?.dataSourceId
        }
    ]
}

export const triggerValidators = [
    createRecordTriggerValidator,
    updateRecordTriggerValidator,
    matchRecordTriggerValidator,
    scheduleTriggerValidator,
    initiateApproveTriggerValidator,
    clickTriggerValidator
]
