import type { BaseFlowNode, DrawMode, FlowEdge, FlowNode, FlowNodeButtonClickPayload, WorkflowType } from '@lighthouse/shared'
import { ScrollArea } from '@mantine/core'
import React, { useMemo } from 'react'
import { useReactFlow } from 'reactflow'
import styled from 'styled-components'

import { NodeConfigurator } from '../NodeConfigurator'
import { NodeCreator } from '../NodeCreator'

interface FlowSettingProps {
    type: WorkflowType
    opened: boolean
    mode: DrawMode
    node: FlowNode | null
    edge: FlowNodeButtonClickPayload | null
    // nodes?: FlowNode[]
    edges?: FlowEdge[]
    onClose: () => void
    onChangeNodeConfig: (id: string, data: BaseFlowNode['data']) => void
    onNodesOrEdgesChange: (nodes: FlowNode[], edges: FlowEdge[], node?: FlowNode) => void
}

const SCxSettingWrapper = styled(ScrollArea)`
    width: 360px;
    height: 100%;
    padding-bottom: 62px;
    overflow-y: auto;
    border-left: 1px solid var(--color-gray-200);

    & .mantine-ScrollArea-viewport > div {
        display: block !important;
    }
`

export const FlowSetting: React.FC<FlowSettingProps> = ({
    type,
    opened,
    mode,
    node,
    edge,
    edges,
    onNodesOrEdgesChange,
    onChangeNodeConfig,
    onClose
}) => {
    const { getNodes } = useReactFlow()

    const nodes = getNodes() as FlowNode[]
    const renderContent = useMemo(() => {
        if (!nodes) {
            return
        }
        switch (mode) {
            case 'nodeConfigurator': {
                return (
                    node && (
                        <NodeConfigurator
                            flowType={type}
                            nodes={nodes}
                            edges={edges}
                            node={node}
                            onClose={onClose}
                            onChangeNodeConfig={data => onChangeNodeConfig(node.id, data)}
                        />
                    )
                )
            }
            case 'nodeCreator': {
                return (
                    edge && (
                        <NodeCreator
                            flowType={type}
                            nodes={nodes}
                            edge={edge}
                            onNodesOrEdgesChange={onNodesOrEdgesChange}
                            onClose={onClose}
                        />
                    )
                )
            }
            default: {
                return null
            }
        }
    }, [edge, edges, mode, node, nodes, onChangeNodeConfig, onClose, onNodesOrEdgesChange, type])

    if (!opened) {
        return null
    }

    return <SCxSettingWrapper>{renderContent}</SCxSettingWrapper>
}
