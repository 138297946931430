import { IconFont, Popover, SelectDropdown } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ChartDimensions, ChartType, SchemaProtocol } from '@lighthouse/core'
import { areaChartType, FindUseType, getFieldIcon, getIsFindUse, MoreSetting, useFindUseObjectContext } from '@lighthouse/shared'
import { arrayMove, nanoid } from '@lighthouse/tools'
import { filter, findIndex, map } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import type { FieldOption } from '../../../FieldSelect'
import { notDimensionFieldInnerType, notDimensionFieldType } from '../../constants'
import * as SC from './styles'

export interface DimensionSettingProps {
    dsId: string
    type: ChartType
    schema: SchemaProtocol['schema']
    value?: ChartDimensions
    disableAdd?: boolean
    limitIds?: string[]
    onChange?: (val: ChartDimensions) => void
}

interface DimensionSettingItemProps {
    dsId: string
    id: string
    value: string
    index: number
    options: FieldOption[]
    limitIds?: string[]
    disableCopy?: boolean
    onChange?: (index: number, id: string) => void
    onDelete?: (index: number) => void
    onCopy?: (index: number) => void
}

const DimensionSettingItem: React.FC<DimensionSettingItemProps> = ({
    dsId,
    id,
    value,
    index,
    options,
    limitIds = [],
    disableCopy,
    onChange,
    onDelete,
    onCopy
}) => {
    const findUseObject = useFindUseObjectContext()
    const { setNodeRef, activeIndex, attributes, listeners, transform, transition, isSorting } = useSortable({
        id
    })
    const isHighLight = useMemo(
        () =>
            getIsFindUse({
                findUseObject,
                type: FindUseType.FIELD,
                dsId,
                fieldId: value
            }),
        [dsId, findUseObject, value]
    )

    const limit = new Set(limitIds.filter(limitId => limitId !== value))

    const selectOptions = options.filter(item => !limit.has(item.value))

    const sortingStyle = {
        transform: CSS.Transform.toString(transform),
        transition: isSorting ? transition : 'none'
    }

    const handleOptions = useMemo(
        () => [
            {
                hidden: disableCopy,
                label: '复制',
                icon: 'Duplicate',
                onItemClick: () => onCopy?.(index)
            },
            {
                label: '删除',
                icon: 'Trash',
                onItemClick: () => onDelete?.(index)
            }
        ],
        [disableCopy, index, onCopy, onDelete]
    )

    return (
        <SC.SettingItem
            key={id}
            style={sortingStyle}
            {...attributes}
            ref={r => {
                setNodeRef(r)
            }}
        >
            <SC.Dragger type="DotsSix" {...listeners} />
            <SC.SettingItemContent>
                <SC.WhiteBgSelect
                    width="100%"
                    size="md"
                    highlighting={isHighLight}
                    styles={{ root: { flex: 1 } }}
                    value={value}
                    onChange={val => {
                        onChange?.(index, val)
                    }}
                    options={selectOptions}
                />
                <MoreSetting width={204} options={handleOptions}>
                    <SC.Setting>
                        <IconFont type="DotsThreeVertical" size={16} fill="var(--color-gray-400)" />
                    </SC.Setting>
                </MoreSetting>
            </SC.SettingItemContent>
        </SC.SettingItem>
    )
}

// 维度
export const DimensionSetting: React.FC<DimensionSettingProps> = ({
    dsId,
    type,
    value = [],
    limitIds = [],
    schema,
    disableAdd,
    onChange
}) => {
    const [open, setOpen] = useState(false)
    const [sortId, setSortId] = useState<string>('')

    const valueIds = useMemo(() => [...limitIds, ...map(({ fieldId }) => fieldId, value)], [limitIds, value])
    const fieldList = useMemo(() => {
        return Object.keys(schema)
            .filter(id => !notDimensionFieldType.has(schema[id].type) && !notDimensionFieldInnerType?.has(schema[id]?.innerType || 'NULL'))
            .map(id => {
                const { name, type, innerType, ...restSchema } = schema[id]
                const icon = getFieldIcon(id, type, innerType)
                return { value: id, label: name, type, icon, innerType, ...restSchema }
            })
    }, [schema])

    const dropDownOptions = useMemo(
        () =>
            fieldList
                .filter(({ value }) => !valueIds.includes(value))
                .map(item => ({
                    value: item.value,
                    label: item.label,
                    icon: <IconFont type={item.icon} size={16} fill="var(--color-gray-400)" />
                })),
        [fieldList, valueIds]
    )

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const isAllFieldUsed = useMemo(() => {
        const fieldListIds = map(({ value }) => value, fieldList)

        return filter(fieldId => !valueIds.includes(fieldId), fieldListIds).length === 0
    }, [fieldList, valueIds])

    const disableAddButton = useMemo(() => {
        if (areaChartType.has(type)) {
            return disableAdd || isAllFieldUsed
        }
        return isAllFieldUsed
    }, [disableAdd, isAllFieldUsed, type])
    // const isAddDisabled = isAllFieldUsed || (haveTwoDimension && value.length === 1)

    // const isAddButtonHidden = (isPieChart || isIndicatorChart) && value.length > 0

    // const getIsNumberType = useCallback((field: ChartField) => {
    //     const currentFieldType =  field.type
    //     const isSpecialField = ['formula'].includes(currentFieldType)
    //     if(!isSpecialField){
    //         return
    //     }
    //     return field?.innerType === 'NUMBER'
    // }, [])

    const handleAdd = useCallback(
        (fieldId: string) => {
            const newValue = [...value, { id: nanoid(), fieldId }]
            setOpen(false)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleDelete = useCallback(
        (index: number) => {
            const newValue = value.filter((item, i) => i !== index)
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleChange = useCallback(
        (index: number, val: string) => {
            const newValue = value.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        fieldId: val
                    }
                }
                return item
            })
            onChange?.(newValue)
        },
        [onChange, value]
    )

    const handleCopy = useCallback(
        (index: number) => {
            const list = [...value]
            const target = list[index]
            list.splice(index + 1, 0, { id: nanoid(), fieldId: target.fieldId })
            onChange?.(list)
        },
        [onChange, value]
    )

    const handleSortStart = useCallback((ev: DragStartEvent) => {
        if (!ev.active) {
            return
        }
        const id = ev.active.id as string
        setSortId(id)
    }, [])
    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const sourceIndex = findIndex(({ id }) => id === activeId, value)
            const targetIndex = findIndex(({ id }) => id === overId, value)
            const newList = arrayMove(value, sourceIndex, targetIndex)
            onChange?.(newList)
        },
        [onChange, value]
    )
    const handleSortCancel = useCallback(() => {
        setSortId('')
    }, [])

    const dragItem = useMemo(() => {
        if (!sortId) {
            return
        }
        const index = findIndex(item => item.id === sortId, value)
        return <DimensionSettingItem dsId={dsId} id={sortId} value={value[index].fieldId} index={index} options={fieldList} />
    }, [dsId, fieldList, sortId, value])

    return (
        <SC.Container>
            <DndContext
                sensors={sensors}
                modifiers={[restrictToParentElement]}
                onDragStart={handleSortStart}
                onDragEnd={handleSortEnd}
                onDragCancel={handleSortCancel}
            >
                <SortableContext items={value}>
                    {value.map(({ id, fieldId }, index) => (
                        <DimensionSettingItem
                            key={id}
                            dsId={dsId}
                            value={fieldId}
                            id={id}
                            index={index}
                            options={fieldList}
                            limitIds={valueIds}
                            disableCopy={disableAddButton}
                            onCopy={handleCopy}
                            onChange={handleChange}
                            onDelete={handleDelete}
                        />
                    ))}
                </SortableContext>
                {createPortal(<DragOverlay dropAnimation={{ duration: 0, easing: 'ease' }}>{dragItem}</DragOverlay>, document.body)}
            </DndContext>

            <Popover width={249} opened={open} onChange={setOpen} position="bottom-start" withinPortal>
                <Popover.Target>
                    <SC.AddButton
                        radius={100}
                        disabled={disableAddButton}
                        icon={<IconFont type="Add" size={16} fill="var(--color-gray-900)" />}
                    >
                        添加
                    </SC.AddButton>
                </Popover.Target>
                <Popover.Dropdown>
                    <SelectDropdown searchable onSelect={handleAdd} options={dropDownOptions} />
                </Popover.Dropdown>
            </Popover>
        </SC.Container>
    )
}
