import type { WechatPayStatus } from '@lighthouse/core'

export const wechatPayStatusMessageMap: Record<WechatPayStatus, string> = {
    SUCCESS: '支付成功',
    REFUND: '转入退款',
    NOTPAY: '未支付',
    CLOSED: '已关闭',
    REVOKED: '已撤销',
    USERPAYING: '用户支付中',
    PAYERROR: '支付失败'
}

export const CURRENCY_OPTIONS = [
    {
        label: 'CNY: 人民币',
        value: 'CNY'
    },
    {
        label: 'HKD: 港币',
        value: 'HKD'
    },
    {
        label: 'MOP: 澳门币',
        value: 'MOP'
    },
    {
        label: 'USD: 美元',
        value: 'USD'
    },
    {
        label: 'GBP: 英镑',
        value: 'GBP'
    },
    {
        label: 'CAD: 加拿大币',
        value: 'CAD'
    },
    {
        label: 'JPY: 日元',
        value: 'JPY'
    },
    {
        label: 'TWD: 新台币',
        value: 'TWD'
    }
]
