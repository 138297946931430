import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'

export const useStyles = createStyles(() => {
    return {
        searchWrapper: css({
            marginBottom: 16
        }),
        searchInput: css({}),
        listContainer: css({
            flex: 1,
            overflowY: 'auto',
            prettyScroll: true,
            display: 'flex',
            flexDirection: 'column'
        }),
        kindContainer: css({
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            padding: 2
        }),
        kindTitle: css({
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '$colorGray400',
            padding: 2
        }),
        listIcon: css({
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'hidden',
            gap: 12
        }),
        active: css({
            '&>*': {
                backgroundColor: 'rgba(50, 75, 99, 0.12)'
            }
        }),
        listKind: css({
            maxWidth: '100%',
            flex: 1,
            overflowX: 'auto',
            prettyScroll: true,

            gap: 8,
            '&::-webkit-scrollbar': {
                height: 0
            }
        }),
        kindLabel: css({
            flexShrink: 0,
            whiteSpace: 'nowrap',
            fontSize: 12,
            padding: '0 6px',
            lineHeight: '24px',
            borderRadius: 5,
            color: '$colorGray500',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '$colorGray200'
            }
        }),
        kindActive: css({
            backgroundColor: 'rgba(50, 75, 99, 0.12)'
        }),
        icon: css({
            '>:hover': {
                backgroundColor: 'rgba(50, 75, 99, 0.12)!important'
            }
        }),
        footer: css({
            paddingTop: 6
        }),
        arrow: css({
            height: 12,
            width: 12,
            borderRadius: 4,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'rgba(50, 75, 99, 0.12)'
            }
        }),
        empty: css({
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            lineHeight: '20px',
            color: '$colorGray400'
        })
    }
})
