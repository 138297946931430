import { Checkbox, IconFont, Menu } from '@byecode/ui'
import { type AppUserDepartment, TagItemPreviewer } from '@lighthouse/shared'
import { Collapse, Flex, Grid, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceDepartments, useDataSourceUsers } from '@/shared/reusable'

import { departmentGroupsTitle } from '../constant'
import { getDepartmentChildIds } from '../help'
import * as CM from '../styles'
import type { UserDepartmentTitleType } from '../type'

interface DepartmentGroupItemProps {
    disabled?: boolean
    department: AppUserDepartment
    level: number
    selectDepartmentIds: string[]
    onSelectDepartment?: (id: string[]) => void
    onEditDepartment?: (departmentId: string) => void
    onDeleteDepartment?: (departmentId: string[]) => void
    onAddDepartment?: (departmentId: string) => void
}

const SCxContainer = styled(Grid)<{ checked: boolean }>`
    margin: 0;
    background-color: ${({ checked }) => checked && 'var(--color-main-tint)'};
`

const DepartmentGroupItem: React.FunctionComponent<DepartmentGroupItemProps> = props => {
    const { department, level, selectDepartmentIds, disabled, ...events } = props
    const { departmentId, departmentManager, departmentChild = [], departmentName } = department
    const [opened, { toggle }] = useDisclosure(false)

    const appId = useCurrentAppID()
    const { data: users = [] } = useDataSourceUsers()

    const { data: departments } = useDataSourceDepartments()

    const [checked, allChildDepartmentIds] = useMemo(() => {
        const childIds = getDepartmentChildIds(departmentId, departments, [])
        return [selectDepartmentIds.includes(departmentId), [...childIds, departmentId]]
    }, [departmentId, departments, selectDepartmentIds])

    const renderColumn = useCallback(
        (type: UserDepartmentTitleType) => {
            switch (type) {
                case 'name': {
                    return (
                        <Flex onClick={toggle} gap={4} style={{ paddingLeft: 20 * level }}>
                            {departmentChild.length > 0 && <CM.Icon type={opened ? 'CaretDown' : 'CaretLeft'} size={16} />}
                            <Text>{departmentName}</Text>
                        </Flex>
                    )
                }
                case 'departmentManager': {
                    const user = find(item => item.userId === departmentManager, users)
                    return <>{user && <TagItemPreviewer data={user} />}</>
                }
                case 'personNum': {
                    const departmentUsers = users.filter(draft => {
                        return (draft.departmentIds ?? []).some(item => allChildDepartmentIds.includes(item))
                    })
                    return <span>{departmentUsers.length ?? 0}</span>
                }
                case 'checkbox': {
                    return (
                        <Checkbox
                            size="xs"
                            disabled={disabled}
                            checked={checked}
                            onChange={e => {
                                events?.onSelectDepartment?.(
                                    e.target.checked
                                        ? [...selectDepartmentIds, departmentId]
                                        : selectDepartmentIds.filter(item => item !== departmentId)
                                )
                            }}
                        />
                    )
                }
                case 'extra': {
                    return (
                        <Menu width={200} disabled={disabled}>
                            <Menu.Target>
                                <Flex justify="flex-end">
                                    <IconFont size={16} type="DotsThree" />
                                </Flex>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item onClick={() => events?.onAddDepartment?.(departmentId)}>
                                    <Text align="left">新建子部门</Text>
                                </Menu.Item>
                                <Menu.Item onClick={() => events?.onEditDepartment?.(departmentId)}>
                                    <Text align="left">编辑部门</Text>
                                </Menu.Item>
                                <Menu.Item onClick={() => events?.onDeleteDepartment?.([departmentId])}>
                                    <Text align="left">删除</Text>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )
                }
                default: {
                    return null
                }
            }
        },
        [
            allChildDepartmentIds,
            checked,
            departmentChild.length,
            departmentId,
            departmentManager,
            departmentName,
            disabled,
            events,
            level,
            opened,
            selectDepartmentIds,
            toggle,
            users
        ]
    )

    return (
        <>
            <SCxContainer checked={checked}>
                {departmentGroupsTitle.map(({ id, name, ...rest }) => (
                    <CM.Item key={id} {...rest}>
                        {renderColumn(id)}
                    </CM.Item>
                ))}
            </SCxContainer>
            <Collapse in={opened}>
                {departmentChild.map(data => (
                    <DepartmentGroupItem
                        key={data.departmentId}
                        department={data}
                        level={level + 1}
                        selectDepartmentIds={selectDepartmentIds}
                        {...events}
                    />
                ))}
            </Collapse>
        </>
    )
}

export default DepartmentGroupItem
