import { Checkbox } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Disabled: React.FC = () => {
    return (
        <Unit title="禁用">
            <Checkbox size="xs" disabled />
            <Checkbox size="sm" disabled />
            <Checkbox radius="50%" disabled />
            <Checkbox disabled checked />
            <Checkbox size="lg" disabled />
        </Unit>
    )
}
