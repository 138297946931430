import { Modal, Popover } from '@byecode/ui'
import { useAtomAction, UserAvatar, UserMenu } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { logoutAtom } from '@/atoms/auth/action'
import { guideStepList } from '@/components/SpaceGuide/constant'
import { useSpaceContext } from '@/contexts/SpaceContext'
import { useUser } from '@/hooks/useUser'

import { userMenuOpItems } from './constant'
import * as SC from './styles'

export const Profile: React.FC = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const { setOpen: setAccountSettingOpen } = useSpaceContext()
    const userData = useUser()
    const { run: logout } = useAtomAction(logoutAtom)

    const { userId, avatar, username = '' } = userData

    // 退出登录
    const handleLogout = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认退出',
            content: '确认退出登录',
            okStatus: 'error',
            okText: '退出'
        })
        if (isConfirm) {
            await logout()
            navigate(
                { pathname: '/account/login' },
                {
                    replace: true
                }
            )
        }
    }, [navigate, logout])

    const handleOpenAccountSetting = useCallback(() => {
        setAccountSettingOpen?.(true)
        setOpen(false)
    }, [setAccountSettingOpen])

    return (
        <SC.Container id={guideStepList.accountSetting.id}>
            <Popover opened={open} onChange={setOpen} withinPortal width="auto">
                <Popover.Target>
                    <SC.UserCenter className={cls({ active: open })}>
                        <SC.UserCenterContent>
                            <UserAvatar id={userId} avatar={avatar} name={username} size="large" />
                            <SC.UserName>{username}</SC.UserName>
                        </SC.UserCenterContent>
                        {/* <SC.UserCenterExtra>
                            <IconFont type="DotsThreeVertical" size={16} fill="var(--color-gray-400)" />
                        </SC.UserCenterExtra> */}
                    </SC.UserCenter>
                </Popover.Target>
                <Popover.Dropdown>
                    <UserMenu
                        options={userMenuOpItems}
                        onOpenAccountSetting={handleOpenAccountSetting}
                        onLogout={handleLogout}
                        userInfo={userData}
                        onClose={() => setOpen(false)}
                    />
                </Popover.Dropdown>
            </Popover>
        </SC.Container>
    )
}
