import { Toast } from '@byecode/ui'
import { emailRegex } from '@lighthouse/shared'
import React, { useCallback, useRef, useState } from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'

interface TagInputProps {
    value: string[]
    onChange: (v: string[]) => void
}

const SCxContainer = styled.div`
    min-height: 32px;
    background: var(--color-gray-100);
    border-radius: 6px;
    color: var(--color-black);
    padding: 4px 8px;

    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    font-size: var(--font-size-normal);
    width: 100%;
    overflow: hidden;

    &:focus-within {
        background: var(--color-white);
        box-shadow: 0 0 0 1px var(--color-theme-7);
    }
`
const SCxInput = styled.div`
    flex: 1;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    outline: none;
`

const SCxTag = styled.div`
    display: inline-flex;
    padding: 2px 4px;
    height: 24px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: var(--color-gray-200);
`

export const TagInput: React.FunctionComponent<TagInputProps> = ({ value, onChange }) => {
    const cursorRef = useRef<HTMLDivElement>(null)

    const [newValue, setNewValue] = useState('')

    const clearInput = useCallback(() => {
        setNewValue('')
        cursorRef.current?.textContent && (cursorRef.current.textContent = '')
    }, [])

    const inputFocus = useCallback(() => {
        cursorRef.current?.focus()
    }, [])

    const handleKeyDown = useCallback(
        (ev: React.KeyboardEvent) => {
            if (ev.key === 'Enter' && newValue) {
                ev.preventDefault()
                const isEmail = emailRegex.test(newValue)
                clearInput()
                if (!isEmail) {
                    Toast.error('请输入正确的邮箱格式')
                    return
                }
                onChange([...value, newValue])
            }
            if (ev.key === 'Backspace' && newValue === '') {
                onChange(value.slice(0, -1))
            }
        },
        [clearInput, newValue, onChange, value]
    )

    // useMount(() => {
    //     inputFocus()
    // })

    return (
        <SCxContainer onClick={inputFocus}>
            {value.map(email => (
                <SCxTag key={email}>{email}</SCxTag>
            ))}
            <SCxInput
                contentEditable
                placeholder="请输入"
                ref={cursorRef}
                onInput={ev => setNewValue(ev.currentTarget.textContent ?? '')}
                onKeyDown={handleKeyDown}
            />
        </SCxContainer>
    )
}
