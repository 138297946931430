import { Text } from '@mantine/core'
import React from 'react'

import { TagIcon } from '@/components/TagIcon'

import * as CM from '../../styles'
import * as SC from './styles'

interface SharedHeaderProps {
    title: string
    style?: React.CSSProperties
    onClose?: () => void
    onBack: () => void
}

export const SharedHeader: React.FunctionComponent<SharedHeaderProps> = ({ title, style, onBack, onClose }) => {
    return (
        <SC.Header style={style}>
            <TagIcon icon="ArrowLeftSmall" size={28} radius={6} enableHover onClick={onBack} style={{ cursor: 'pointer' }} />
            <Text size={16} weight={500}>
                {title}
            </Text>
            {onClose ? (
                <TagIcon icon="Close" size={28} radius={6} enableHover onClick={onClose} style={{ cursor: 'pointer' }} />
            ) : (
                <div style={{ width: 28 }} />
            )}
        </SC.Header>
    )
}
