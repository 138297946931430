import { flex, pointer, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    overflow: hidden;
    /* flex: 1; */
    border-radius: 8px;
    margin-top: 8px;
    ${tinyButtons}
`
export const Header = styled.div<{ open?: boolean }>`
    padding: 0 8px 0 16px;
    height: 44px;
    box-sizing: border-box;
    ${flex}
    justify-content: space-between;
    ${({ open }) =>
        open && {
            borderBottom: '1px solid var(--color-gray-200)'
        }}
    background-color: var(--color-gray-100);
`
export const LeftContain = styled.div`
    /* width: 150px; */
    position: relative;
    ${flex}
    align-items: center;
    justify-content: flex-start;
    ${pointer}
`
export const RightContain = styled.div`
    /* width: 37px; */
    height: inherit;
    ${flex}
    justify-content: center;
    align-items: center;
    gap: 8px;

    > *:last-child {
        cursor: pointer;
    }
`

export const Item = styled.div`
    height: 40px;
    ${flex}
    justify-content: space-between;
    align-items: center;
`
