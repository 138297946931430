import { Box } from '@byecode/ui/components/Box'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { ColumnType } from '@byecode/ui/components/Table'
import { Table } from '@byecode/ui/components/Table'
import { spaceVersionEnum } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { useAsyncRetry, useDebounce } from 'react-use'
import styled from 'styled-components'

import { VersionOverlay } from '@/components/SpaceGrade'
import type { AnalysisPageVisitsRes } from '@/services'
import * as srv from '@/services'

import { Card } from '../components'

const PlaceHolder = styled.div`
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    border-radius: 3px;
    background-color: var(--color-gray-100);
`
const Image = styled.img`
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    border-radius: 3px;
`

const Title = styled.div`
    font-size: 14px;
    line-height: 22px;
`
const Description = styled.div`
    font-size: 12px;
    color: var(--color-gray-600);
`

const Link = styled.a`
    color: var(--color-main);
    line-height: 1;
`

type ItemType = AnalysisPageVisitsRes['list'][number]

const COLUMNS: ColumnType<ItemType>[] = [
    {
        title: '页面',
        key: 'pageUrl',
        formatter(_, record) {
            return (
                <Flex alignItems="center" gap="8px">
                    {record.image ? <Image src={record.image} alt="" /> : <PlaceHolder />}
                    <Box>
                        <Flex alignItems="center" gap="4px">
                            <Title>{record.title}</Title>
                            <Link href={record.pageUrl} target="_blank">
                                <IconFont type="ArrowSquareIn" />
                            </Link>
                        </Flex>
                        <Description>{record.description}</Description>
                    </Box>
                </Flex>
            )
        }
    },
    {
        title: '平均访问时长',
        key: 'avgDuration',
        align: 'right',
        sorter: (a, b) => a.avgDuration - b.avgDuration
    },
    {
        title: '平均访问深度',
        key: 'avgDepth',
        align: 'right',
        sorter: (a, b) => a.avgDepth - b.avgDepth
    },
    {
        title: '独立访客',
        key: 'uv',
        align: 'right',
        sorter: (a, b) => a.uv - b.uv
    },
    {
        title: '来访次数',
        key: 'pv',
        align: 'right',
        sorter: (a, b) => a.pv - b.pv
    },
    {
        title: '跳出率',
        key: 'bounceRate',
        align: 'right',
        sorter: (a, b) => a.bounceRate - b.bounceRate,
        formatter(v) {
            return `${Number(v) * 100}%`
        }
    }
]

const PAGE_SIZE = 10
export const PageVisits = () => {
    const [searchWords, setSearchWords] = useState<string>()
    const [debounceSearchWords, setDebounceSearchWords] = useState<string>()

    const [pageNum, setPageNum] = useState(1)

    useDebounce(
        () => {
            setDebounceSearchWords(searchWords)
        },
        300,
        [searchWords]
    )

    const res = useAsyncRetry(() => srv.fetchAnalysisVisit(pageNum, PAGE_SIZE), [pageNum])

    const filterData = useMemo(() => {
        if (!res.value) {
            return
        }

        if (!debounceSearchWords) {
            return res.value.list
        }

        const hasWords = (r: ItemType) => r.title?.toLocaleLowerCase().includes(debounceSearchWords.toLocaleLowerCase().trim())

        return res.value.list.filter(hasWords)
    }, [res.value, debounceSearchWords])

    return (
        <VersionOverlay spaceVersion={spaceVersionEnum.STANDARD}>
            <Card title="页面访问" loading={res.loading}>
                <Table
                    data={filterData}
                    columns={COLUMNS}
                    rowKey="pageUrl"
                    search
                    onSearchValueChange={e => setSearchWords(e.target.value)}
                    pagination={{ pageNum, pageSize: PAGE_SIZE, total: res.value?.total, onChange: setPageNum }}
                />
            </Card>
        </VersionOverlay>
    )
}
