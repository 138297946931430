import { DatePicker, Select } from '@byecode/ui'
import type { ScheduledTriggerRule } from '@lighthouse/shared'
import { isValid } from 'date-fns'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import * as SC from '../../../styles'
import { CheckboxSelect } from '../CheckboxSelect'

interface CommonRuleProps {}

const SCxInlineItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const SCxInlineItemInsertion = styled.div`
    flex-shrink: 0;
`

export const CommonRuleSettingUnit: React.FC<CommonRuleProps> = () => {
    const { control } = useFormContext()

    const scheduleRule: ScheduledTriggerRule = useWatch({
        control,
        name: 'config.scheduleRule'
    })

    switch (scheduleRule) {
        case 'MINUTE': {
            const options = Array.from({ length: 60 }, (_, i) => {
                const v = `${i + 1}`
                return { label: v, value: v }
            })
            return (
                <SC.FormItem>
                    <SC.FormItemLabelWrapper>
                        <SC.FormItemLabel required>时机</SC.FormItemLabel>
                    </SC.FormItemLabelWrapper>
                    <SC.FormItemContent>
                        <SCxInlineItem>
                            <SCxInlineItemInsertion>每</SCxInlineItemInsertion>
                            <Controller
                                name="config.scheduleMinuteRule"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            dropdownProps={{
                                                styles: {
                                                    selectDropdown: { height: 300 }
                                                }
                                            }}
                                            value={field.value}
                                            options={options}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                            <SCxInlineItemInsertion>分钟</SCxInlineItemInsertion>
                        </SCxInlineItem>
                    </SC.FormItemContent>
                </SC.FormItem>
            )
        }
        case 'HOUR': {
            const options = Array.from({ length: 24 }, (_, i) => {
                const v = `${i + 1}`
                return { label: v, value: v }
            })
            return (
                <>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>时机</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <SCxInlineItem>
                                <SCxInlineItemInsertion>每</SCxInlineItemInsertion>
                                <Controller
                                    name="config.scheduleHourRule"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                dropdownProps={{
                                                    styles: {
                                                        selectDropdown: { height: 300 }
                                                    }
                                                }}
                                                value={field.value}
                                                options={options}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                                <SCxInlineItemInsertion>小时</SCxInlineItemInsertion>
                            </SCxInlineItem>
                        </SC.FormItemContent>
                    </SC.FormItem>
                </>
            )
        }
        case 'DAY': {
            const dayOptions = Array.from({ length: 31 }, (_, i) => {
                const v = `${i + 1}`
                return { label: v, value: v }
            })
            const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
                const hour = Math.floor(i / 4)
                const minute = 15 * (i % 4)
                const v = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
                return { label: v, value: v }
            })
            return (
                <>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>时机</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <SCxInlineItem>
                                <SCxInlineItemInsertion>每</SCxInlineItemInsertion>
                                <Controller
                                    name="config.scheduleDayRule.day"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                style={{ width: 68 }}
                                                dropdownProps={{
                                                    styles: {
                                                        selectDropdown: { height: 300 }
                                                    }
                                                }}
                                                value={field.value}
                                                options={dayOptions}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                                <SCxInlineItemInsertion>天的</SCxInlineItemInsertion>
                                <Controller
                                    name="config.scheduleDayRule.time"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                dropdownProps={{
                                                    styles: {
                                                        selectDropdown: { height: 300, width: 86 }
                                                    }
                                                }}
                                                value={field.value}
                                                options={timeOptions}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                            </SCxInlineItem>
                        </SC.FormItemContent>
                    </SC.FormItem>
                </>
            )
        }
        case 'WEEKDAY': {
            const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
                const hour = Math.floor(i / 4)
                const minute = 15 * (i % 4)
                const v = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
                return { label: v, value: v }
            })
            return (
                <>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>时机</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <SCxInlineItem>
                                <SCxInlineItemInsertion>周一到周五的</SCxInlineItemInsertion>
                                <Controller
                                    name="config.scheduleWeekdayRule"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                dropdownProps={{
                                                    styles: {
                                                        selectDropdown: { height: 300 }
                                                    }
                                                }}
                                                value={field.value}
                                                options={timeOptions}
                                                onChange={field.onChange}
                                            />
                                        )
                                    }}
                                />
                            </SCxInlineItem>
                        </SC.FormItemContent>
                    </SC.FormItem>
                </>
            )
        }
        case 'WEEK': {
            const weekOptions = Array.from({ length: 52 }, (_, i) => {
                const v = `${i + 1}`
                const l = `第 ${v} 周`
                return { label: l, value: v }
            })
            const dayOptions = [
                {
                    label: '一',
                    value: '0'
                },
                {
                    label: '二',
                    value: '1'
                },
                {
                    label: '三',
                    value: '2'
                },
                {
                    label: '四',
                    value: '3'
                },
                {
                    label: '五',
                    value: '4'
                },
                {
                    label: '六',
                    value: '5'
                },
                {
                    label: '日',
                    value: '6'
                }
            ]
            const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
                const hour = Math.floor(i / 4)
                const minute = 15 * (i % 4)
                const v = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
                return { label: v, value: v }
            })
            return (
                <>
                    {/* <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>重复周</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleWeekRule.week"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            dropdownProps={{
                                                styles: {
                                                    root: { height: 300 }
                                                }
                                            }}
                                            value={field.value}
                                            options={weekOptions}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem> */}
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>星期</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleWeekRule.days"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return <CheckboxSelect value={field.value} options={dayOptions} onChange={field.onChange} />
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>时间</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleWeekRule.time"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            dropdownProps={{
                                                styles: {
                                                    selectDropdown: { height: 300 }
                                                }
                                            }}
                                            value={field.value}
                                            options={timeOptions}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </>
            )
        }
        case 'MONTH': {
            const monthOptions = Array.from({ length: 12 }, (_, i) => {
                const v = `${i + 1}`
                const l = `第 ${v} 个月`
                return { label: l, value: v }
            })
            const dayOptions = Array.from({ length: 31 }, (_, i) => {
                const v = `${i + 1}`
                const l = `第 ${v} 天`
                return { label: l, value: v }
            })
            const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
                const hour = Math.floor(i / 4)
                const minute = 15 * (i % 4)
                const v = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
                return { label: v, value: v }
            })
            return (
                <>
                    {/* <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>重复月</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleMonthRule.month"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            dropdownProps={{
                                                styles: {
                                                    root: { height: 300 }
                                                }
                                            }}
                                            value={field.value}
                                            options={monthOptions}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem> */}
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>天</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleMonthRule.days"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return <CheckboxSelect value={field.value} options={dayOptions} onChange={field.onChange} />
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>时间</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.scheduleMonthRule.time"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            dropdownProps={{
                                                styles: {
                                                    selectDropdown: { height: 300 }
                                                }
                                            }}
                                            value={field.value}
                                            options={timeOptions}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </>
            )
        }

        case 'ONCE':
        default: {
            return (
                <SC.FormItem>
                    <SC.FormItemLabelWrapper>
                        <SC.FormItemLabel required>时机</SC.FormItemLabel>
                    </SC.FormItemLabelWrapper>
                    <SC.FormItemContent>
                        <Controller
                            name="config.onceTime"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                                const dateValue = field.value && isValid(new Date(field.value)) ? new Date(field.value) : undefined
                                return (
                                    <DatePicker
                                        clearable
                                        value={dateValue}
                                        showTime
                                        onChange={field.onChange}
                                        onClear={() => field.onChange('')}
                                    />
                                )
                            }}
                        />
                    </SC.FormItemContent>
                </SC.FormItem>
            )
        }
    }
}
