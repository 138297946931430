import { Button, Flex, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { SpaceVersion } from '@lighthouse/core'
import { getBrand, spaceGradeNameMap } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

import { openSaleSpaceGrade, openSpaceGrade } from './event'

interface VersionConfirmProps {
    url: string
    version: SpaceVersion
}
const SCxContainer = styled.div`
    display: flex;
    height: 127px;
    justify-content: center;
`

const SCxLogo = styled(Image)`
    position: absolute;
    top: -68px;
    /* z-index: 1; */
`
export const VersionConfirm: React.FunctionComponent<VersionConfirmProps> = ({ url, version }) => {
    return (
        <SCxContainer>
            <SCxLogo src={getAssetUrl('space', 'space_version_confirm_logo.png')} width={153} height={136} />
            <Flex gap={24} direction="column" alignItems="center" style={{ width: '100%' }} justifyContent="flex-end">
                <Text size={16} weight={500}>
                    {' '}
                    升级到「{spaceGradeNameMap[version]}」使用该功能
                </Text>
                <Flex gap={16} direction="column" alignItems="center" style={{ width: '100%' }}>
                    <Button
                        type="text"
                        style={{
                            background: 'linear-gradient(275deg, #FFE3BA 4.82%, #FDEAD7 95.05%)',
                            color: '#6A3119'
                        }}
                        block
                        radius={7}
                        size="lg"
                        onClick={() => {
                            const brand = getBrand()
                            brand === 'I_CLICK' ? openSaleSpaceGrade() : openSpaceGrade('version_upgrade')
                        }}
                    >
                        升级版本
                    </Button>
                    <Text
                        size={12}
                        color="var(--color-gray-400)"
                        style={{ cursor: 'pointer' }}
                        weight={500}
                        onClick={() => url && window.open(url, '_blank')}
                    >
                        查看功能介绍
                    </Text>
                </Flex>
            </Flex>
        </SCxContainer>
    )
}
