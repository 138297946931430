import { Button, Menu, Modal, pointer, tinyButtons, Toast } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Divider, Flex, Text } from '@mantine/core'
import cls from 'classnames'
import { find } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { fetchDataSourceDepartmentAtom, fetchDataSourceUserAtom } from '@/atoms/dataSource/action'
import { useDataSourceEnvId } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { useDataSourceDepartments } from '@/shared/reusable'

import { ALL_DEPARTMENT, DEFAULT_VALUES } from '../constant'
import type { FormState } from '../DepartmentModal'
import DepartmentModal from '../DepartmentModal'
import { DepartmentSelectItem } from '../DepartmentSelect'
import * as CM from '../styles'

interface UserDepartmentProps {
    value: string
    count: number
    onChange: (departmentId: string) => void
}

const SCxAllUser = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    color: var(--color-gray-500);
    border-radius: 6px;
    ${pointer}

    &.active {
        background-color: var(--color-theme-3);

        > :first-child {
            font-weight: 600;
            color: var(--color-theme-7);
        }
    }
`

const SCxRightSection = styled.div`
    position: absolute;
    right: 0px;
    padding: 0 12px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: var(--color-theme-3);
    border-radius: 6px;
    visibility: hidden;
`

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
`

const SCxListContainer = styled.div`
    width: 100%;
    margin-bottom: 24px;
    max-height: calc(100% - 126px);
    overflow: auto;
    ${tinyButtons}
`

interface UserDepartmentPanelState {
    opened: boolean
    operaOpened: boolean
    mode: 'add' | 'edit'
    defaultValues?: FormState
    currentId: string
}

const UserDepartment: React.FunctionComponent<UserDepartmentProps> = ({ value, count, onChange }) => {
    const envId = useDataSourceEnvId()
    const [state, setState] = useImmer<UserDepartmentPanelState>({
        opened: false,
        operaOpened: false,
        currentId: '',
        mode: 'add'
    })

    const { run: fetchDataSourceUser } = useAtomAction(fetchDataSourceUserAtom)
    const { run: fetchDataSourceDepartment } = useAtomAction(fetchDataSourceDepartmentAtom)

    const { data: departments, flatData: flatDepartments } = useDataSourceDepartments()
    // const { data: departments, flatData: flatDepartments, update: updateDepartments } = useDataSourceDepartments()

    const handleDelete = useCallback(
        (departmentIds: string[]) => {
            Modal.confirm({
                title: '确认删除',
                content: `部门删除后不可恢复，是否继续？？`,
                okStatus: 'error'
            })?.then(async s => {
                if (s) {
                    const isSuccess = await srv.deleteUserDepartment(envId, departmentIds)
                    if (isSuccess) {
                        fetchDataSourceDepartment()
                        fetchDataSourceUser()
                        onChange(ALL_DEPARTMENT)
                        Toast.success('删除成功')
                    }
                }
            })
        },
        [envId, fetchDataSourceDepartment, fetchDataSourceUser, onChange]
    )

    const handleOpenCreatModal = useCallback(
        (id?: string) => {
            setState(draft => {
                draft.opened = true
                draft.mode = 'add'
                draft.operaOpened = false
                draft.defaultValues = id
                    ? {
                          ...DEFAULT_VALUES,
                          departParentId: id,
                          departmentId: nanoid()
                      }
                    : { ...DEFAULT_VALUES, departmentId: nanoid() }
            })
        },
        [setState]
    )

    const handleOpenEditModal = useCallback(
        (id: string) => {
            setState(draft => {
                draft.opened = true
                draft.mode = 'edit'
                draft.operaOpened = false
                draft.defaultValues = find(item => item.departmentId === id, flatDepartments)
            })
        },
        [flatDepartments, setState]
    )

    return (
        <SCxContainer>
            <SCxAllUser className={cls({ active: value === ALL_DEPARTMENT })} onClick={() => onChange(ALL_DEPARTMENT)}>
                <Text size={14}>所有用户 </Text>
                <Text size={14}>{count} 人</Text>
            </SCxAllUser>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <SCxListContainer>
                {departments.map(department => (
                    <DepartmentSelectItem
                        key={department.departmentId}
                        enableMenu={false}
                        data={department}
                        activeDepartmentId={value}
                        defaultOpened
                        level={0}
                        renderRightSection={val => (
                            <SCxRightSection>
                                <Menu
                                    withinPortal
                                    opened={state.operaOpened && val.departmentId === state.currentId}
                                    onChange={opened =>
                                        setState(draft => {
                                            draft.operaOpened = opened
                                            draft.currentId = val.departmentId
                                        })
                                    }
                                    width={200}
                                >
                                    <Menu.Target>
                                        <Flex
                                            justify="flex-end"
                                            onClick={() =>
                                                setState(draft => {
                                                    draft.currentId = val.departmentId
                                                    draft.operaOpened = true
                                                })
                                            }
                                        >
                                            <CM.Icon size={16} type="DotsThree" color="var(--color-gray-500)" />
                                        </Flex>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item onClick={() => handleOpenCreatModal?.(val.departmentId)}>
                                            <Text align="left">新建子部门</Text>
                                        </Menu.Item>
                                        <Menu.Item onClick={() => handleOpenEditModal?.(val.departmentId)}>
                                            <Text align="left">编辑部门</Text>
                                        </Menu.Item>
                                        <Menu.Item onClick={() => handleDelete?.([val.departmentId])}>
                                            <Text align="left">删除</Text>
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                            </SCxRightSection>
                        )}
                        onChange={val => onChange(val[0])}
                    />
                ))}
            </SCxListContainer>
            <Button block onClick={() => handleOpenCreatModal()} size="lg" icon={<CM.Icon type="Add" color="var(--color-gray-400)" />}>
                新建部门
            </Button>
            <DepartmentModal
                mode={state.mode}
                opened={state.opened}
                envId={envId}
                data={state.defaultValues}
                onClose={() =>
                    setState(draft => {
                        draft.opened = false
                        Reflect.deleteProperty(draft, 'defaultValues')
                    })
                }
            />
        </SCxContainer>
    )
}

export default UserDepartment
