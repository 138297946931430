import { LoginType } from '@lighthouse/core'
import { ErrorMessage, getBrand, inviteCodeReg, mobileRegex, useAtomAction, VerificationCodeBtn } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import type { FormEvent } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { fetchUserAtom, loginAtom } from '@/atoms/auth/action'
import * as srv from '@/services'

import * as SC from '../../styles'
import type { BindForm, BindProps } from './types'

export const Bind: React.FC<BindProps> = ({ mark, inviteCode, onChangeMode }) => {
    const {
        register,
        handleSubmit,
        trigger,
        clearErrors,
        getValues,
        control,
        formState: { errors }
    } = useForm<BindForm>({ mode: 'onBlur', shouldFocusError: false, defaultValues: {
         invitationCode: inviteCode || ''
    } })
    const [loadingBtn, setLoadingBtn] = useState(false)
    const navigate = useNavigate()
    const branch = getBrand()
    const submitEl = useRef<HTMLInputElement>(null)
    const [searchParams] = useSearchParams()
    const redirect = searchParams.get('redirect')
    // const templateId = useMemo(() => {
    //     const redirect = searchParams.get('redirect')
    //     return redirect?.includes('templateId') ? redirect.replace('templateId-', '') : ''
    // }, [searchParams])

    const { run: login, loading } = useAtomAction(loginAtom)
    const { run: fetchUser, loading: userLoading } = useAtomAction(fetchUserAtom)

    // 表单提交
    const loginSubmit = useCallback(
        async (data: BindForm) => {
            const { phone, verificationCode } = data
            const isLogin = await login({
                authType: LoginType.WECHAT_OFFICIAL,
                mobile: phone,
                smsCode: verificationCode,
                invitationCode: inviteCode || undefined,
                mark,
                sourceParty: branch
            })
            if (!isLogin) {
                return
            }
            const user = await fetchUser()
            if (!user?.username) {
                navigate({ pathname: '/account/perfect', search: redirect ? `redirect=${redirect}`: undefined })
                return
            }
            navigate(redirect || '/', { replace: true })
        },
        [branch, fetchUser, inviteCode, login, mark, navigate, redirect]
    )

    // 提交行为
    const loginHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        return submitEl.current?.click()
    }

    // 跳转到账号密码登录
    const handleChangeLogin = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onChangeMode('login')
        },
        [onChangeMode]
    )

    // 跳转验证码登录
    const handleChangeRegister = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onChangeMode('register')
        },
        [onChangeMode]
    )

    // 获取验证码
    const handleSendCodeHandle = useCallback(
        async (resolve: () => void, reject: () => void) => {
            const result = await trigger('phone')
            if (result) {
                setLoadingBtn(true)
                const isSend = await srv.sendAuthCode({ mobile: getValues('phone') })
                setLoadingBtn(false)
                if (isSend) {
                    resolve()
                    return
                }
                reject()
            }
        },
        [getValues, trigger]
    )

    const handleValidateInviteCode = useCallback(async (val: string) => {
        if (!val) {
            return true
        }
        const isLocalValidate = inviteCodeReg.test(val)
        if (!isLocalValidate) {
            return '邀请码有误'
        }
        const isValidate = await srv.invitationCodeCheck(val)
        if (!isValidate) {
            return '邀请码无效'
        }
        return true
    }, [])

    const clearFormError = useCallback(
        (name: 'phone' | 'verificationCode' | 'invitationCode') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(loginSubmit)()
        },
        [handleSubmit, loginSubmit]
    )

    return (
        <SC.FormContainer onSubmit={handleFormSubmit}>
            <SC.InputWrapper>
                <ErrorMessage name="phone" errors={errors}>
                    <SC.AccountInput
                        placeholder="手机号"
                        // size="xl"
                        onFocus={() => {
                            clearFormError('phone')
                        }}
                        {...register('phone', {
                            required: '请输入手机号',
                            pattern: {
                                value: mobileRegex,
                                message: '请输入正确的手机号' // JS only: <p>error message</p> TS only support string
                            }
                        })}
                    />
                </ErrorMessage>
                <SC.CodeInputBox>
                    <ErrorMessage name="verificationCode" errors={errors}>
                        <SC.CodeInput
                            placeholder="验证码"
                            // size="xl"
                            onFocus={() => {
                                clearFormError('verificationCode')
                            }}
                            maxLength={6}
                            {...register('verificationCode', { required: '请输入验证码' })}
                        />
                    </ErrorMessage>
                    <VerificationCodeBtn loading={loadingBtn} style={{ marginLeft: 8 }} onGetCodeHandle={handleSendCodeHandle} />
                </SC.CodeInputBox>
                <ErrorMessage name="invitationCode" errors={errors}>
                    <SC.AccountInput
                        placeholder="邀请码"
                        // size="xl"
                        onFocus={() => {
                            clearFormError('invitationCode')
                        }}
                        {...register('invitationCode', {
                            validate: handleValidateInviteCode
                        })}
                    />
                </ErrorMessage>
                <SC.AccountButton
                    size="lg"
                    block
                    loading={loading || userLoading}
                    type="primary"
                    // color="var(--color-white)"
                    // backgroundColor="var(--color-main)"
                    // hoverBackgroundColor="var(--color-theme-8)"
                    onClick={loginHandle}
                >
                    完成
                </SC.AccountButton>
                <SC.HideSubmit ref={submitEl} type="submit" />
            </SC.InputWrapper>
            <Divider
                style={{ margin: '32px 0  16px 0', color: 'var(--color-gray-400)' }}
                my="xs"
                label="其他登录方式"
                labelPosition="center"
            />
            <SC.FooterBetween>
                <SC.AccountButton
                    icon={<SC.Icon type="DeviceMobileFilled" size={20} color="var(--color-main)" />}
                    block
                    onClick={handleChangeRegister}
                >
                    验证码登录/注册
                </SC.AccountButton>
                <SC.AccountButton icon={<SC.Icon type="Key" size={20} color="var(--color-blue-500)" />} block onClick={handleChangeLogin}>
                    账号密码登录
                </SC.AccountButton>
            </SC.FooterBetween>
        </SC.FormContainer>
    )
}
