import { IconFont, Input, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const ParamsInput = styled(Input)<{ width?: string }>`
    width: ${({ width }) => width || 'auto'};

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none !important;
        margin: 0;
    }
`

export const ParamsCustomInput = styled.div<{ width?: string }>`
    width: ${({ width }) => width || 'auto'};
    height: 32px;
`

export const Container = styled.div`
    display: flex;
    position: relative;
`

export const DateIcon = styled(IconFont)`
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 0;
    ${pointer}
`

export const Tag = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
`

export const TagWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

export const Icon = styled(IconFont)<{ size?: number; isAction?: boolean; color?: string }>`
    font-size: ${({ size }) => size ?? '16'}px;
    background-color: transparent;
    color: ${({ color }) => color ?? 'var(--color-gray-400)'};
    ${pointer}

    &:hover {
        color: ${({ isAction }) => isAction && 'var(--color-black)'};
    }
`

export const IconWrapper = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
`
export const ItemContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    padding: 0 8px;
    height: 32px;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;

    :hover {
        background-color: var(--color-gray-200);
    }
`

export const ItemWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
`
