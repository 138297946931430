import type { SegmentedControlItem } from '@byecode/ui'
import { IconFont, Tooltip } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { ChartBlockThumbImageMap } from '@lighthouse/block'
import type {
    BaseChartOption,
    BlockType,
    CardBlockAbstract,
    ChartValueCalcType,
    DesignProtocol,
    FieldBlockAbstract,
    FieldInputType,
    FieldType,
    TypeInstanceMap,
    ViewOptions
} from '@lighthouse/core'
import {
    ALIGNMENT,
    ARRANGEMENT,
    CARD_STYLE,
    ChartOptionProtocol,
    ChartType,
    DIRECTION,
    EDGE_MODE,
    FLEX_ALIGN,
    GalleryAvatarShape,
    GalleryAvatarSize,
    GalleryCardStyle,
    HEIGHT_RULE,
    MAX_CONTAINER_WIDTH_SIZE,
    TEXT_ALIGNMENT
} from '@lighthouse/core'
import { Enum, nanoid } from '@lighthouse/tools'
import React from 'react'

interface ChartTypeOption {
    label: string
    value: ChartType
    image: string
    color: string
    background: string
}

const dateInputImage = getAssetUrl('blocks', 'date_input.svg')
const emailInputImage = getAssetUrl('blocks', 'email_input.svg')
const fileInputImage = getAssetUrl('blocks', 'file_input.svg')
const urlInputImage = getAssetUrl('blocks', 'link_input.svg')
const notesInputImage = getAssetUrl('blocks', 'notes_input.svg')
const numberInputImage = getAssetUrl('blocks', 'number_input.svg')
const selectInputImage = getAssetUrl('blocks', 'option_input.svg')
const personInputImage = getAssetUrl('blocks', 'person_input.svg')
const phoneNumberInputImage = getAssetUrl('blocks', 'phone_input.svg')
const textInputImage = getAssetUrl('blocks', 'text_input.svg')

/**
 * 画廊卡片风格选项
 * @date 6/28/2023 - 5:20:34 PM
 */
export const GALLERY_CARD_STYLES = [
    { label: '色块', value: GalleryCardStyle.block },
    { label: '卡片', value: GalleryCardStyle.border },
    { label: '无背景', value: GalleryCardStyle.none }
]

/** 卡片布局 */
export const CARD_BLOCK_DIRECTION = [
    { label: '上下', value: 'vertical' },
    { label: '左右', value: 'horizontal' }
]
/**
 * 图文导航block风格
 */
export const CARD_BLOCK_STYLES = [
    {
        label: '默认',
        value: CARD_STYLE.default
    },
    {
        label: '色块',
        value: CARD_STYLE.block
    },
    {
        label: '卡片',
        value: CARD_STYLE.card
    }
]

/** 是否展示字段选项 */
export const DISPLAY_OPTIONS = [
    {
        label: '不显示',
        value: false
    },
    {
        label: '显示',
        value: true
    }
]

export const DIRECTION_OPTIONS = [
    {
        label: '垂直',
        value: DIRECTION.vertical,
        icon: <IconFont type="VerticallLayout" color="var(--color-gray-400)" />
    },
    {
        label: '水平',
        value: DIRECTION.horizontal,
        icon: <IconFont type="HorizontalLayout" color="var(--color-gray-400)" />
    }
]

/** 对齐选项 */
export const ALIGN_CONTROL: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="居左">
                <IconFont type="TextAlignLeft" size={16} />
            </Tooltip>
        ),
        value: 'left'
    },
    {
        label: (
            <Tooltip title="居中">
                <IconFont type="TextAlignCenter" size={16} />
            </Tooltip>
        ),
        value: 'center'
    },
    {
        label: (
            <Tooltip title="居右">
                <IconFont type="TextAlignRight" size={16} />
            </Tooltip>
        ),
        value: 'right'
    }
]
export const TEXT_ALIGN_OPTIONS: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="居左">
                <IconFont type="TextAlignLeft" size={16} />
            </Tooltip>
        ),
        value: TEXT_ALIGNMENT.left
    },
    {
        label: (
            <Tooltip title="居中">
                <IconFont type="TextAlignCenter" size={16} />
            </Tooltip>
        ),
        value: TEXT_ALIGNMENT.center
    },
    {
        label: (
            <Tooltip title="居右">
                <IconFont type="TextAlignRight" size={16} />
            </Tooltip>
        ),
        value: TEXT_ALIGNMENT.right
    },
    {
        label: (
            <Tooltip title="两端对齐">
                <IconFont type="TextAlignBothEnds" size={16} />
            </Tooltip>
        ),
        value: TEXT_ALIGNMENT.justify
    }
]
/** 位置对齐 */
export const POSITION_ALIGN_CONTROL: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="居左">
                <IconFont size={16} color="var(--color-gray-500)" type="AlignLeft" />
            </Tooltip>
        ),
        value: 'left'
    },
    {
        label: (
            <Tooltip title="居中">
                <IconFont size={16} color="var(--color-gray-500)" type="AlignCenter" />
            </Tooltip>
        ),
        value: 'center'
    },
    {
        label: (
            <Tooltip title="居右">
                <IconFont size={16} color="var(--color-gray-500)" type="AlignRight" />
            </Tooltip>
        ),
        value: 'right'
    }
]
/** 位置选项 */
export const POSITION_CONTROL: SegmentedControlItem[] = [
    {
        label: '上',
        value: 'top'
    },
    {
        label: '左',
        value: 'left'
    },
    {
        label: '右',
        value: 'right'
    }
]

/** 头像尺寸 */
export const AVATAR_SIZE_OPTIONS = [
    {
        label: 'S',
        value: GalleryAvatarSize.sm
    },
    {
        label: 'M',
        value: GalleryAvatarSize.md
    },
    {
        label: 'L',
        value: GalleryAvatarSize.lg
    },
    {
        label: 'XL',
        value: GalleryAvatarSize.xl
    }
]

/** 边距尺寸 */
export const PADDING_SIZE_OPTIONS = [
    {
        label: 'S',
        value: 'sm'
    },
    {
        label: 'M',
        value: 'md'
    },
    {
        label: 'L',
        value: 'lg'
    },
    {
        label: 'XL',
        value: 'xl'
    }
]

/** 头像形状 */
export const AVATAR_SHAPE_CONTROL: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="方形">
                <IconFont type="Square" size={16} />
            </Tooltip>
        ),
        value: GalleryAvatarShape.square
    },
    {
        label: (
            <Tooltip title="圆形">
                <IconFont type="Circle" size={16} />
            </Tooltip>
        ),
        value: GalleryAvatarShape.circle
    }
]

/** 分隔方式 */
export enum CardSpace {
    'CARD' = 'card',
    'LINE' = 'line'
}

/** 分隔方式选项 */
export const CARD_SPACE_CONTROL: SegmentedControlItem[] = [
    {
        label: '卡片',
        value: CardSpace.CARD
    },
    {
        label: '线条',
        value: CardSpace.LINE
    }
]

/** 排列方式 */
export const LAYOUT_CONTROL = [
    {
        label: '自动排列',
        value: ARRANGEMENT.auto
    },
    {
        label: '固定排列',
        value: ARRANGEMENT.fixed
    }
]

/** 字段block显示大小 */
export const FIELD_SIZE_OPTIONS = [
    {
        label: '标准',
        value: 'middle'
    },
    {
        label: '较大',
        value: 'large'
    }
]

/** 字段block布局方向 */
export const FIELD_DIRECTION_OPTIONS = [
    {
        label: '上下',
        value: 'column'
    },
    {
        label: '左右',
        value: 'row'
    }
]

/** 字段block是否展示边框 */
export const FIELD_SHOW_BORDER_OPTIONS = [
    {
        label: '有框',
        value: 'show'
    },
    {
        label: '无框',
        value: 'hidden'
    }
]

/** 字段block显示方式 */
export const FIELD_SHOW_WAY_OPTIONS = [
    {
        label: '平铺',
        value: 'tiled'
    },
    {
        label: '下拉',
        value: 'dropdown'
    }
]

/** 图片block 内容显示方式 */
export const IMAGE_VARIANT_OPTIONS: SegmentedControlItem[] = [
    {
        label: '单图',
        value: 'default'
    },
    {
        label: '轮播图',
        value: 'swiper'
    }
]

/** 图片block 图片适应方式 */
export const IMAGE_FIT_OPTIONS: SegmentedControlItem[] = [
    {
        label: '铺满',
        value: 'cover'
    },
    {
        label: '适应',
        value: 'contain'
    }
]

/** 视频block 图片适应方式 */
export const VIDEO_FIT_OPTIONS: SegmentedControlItem[] = [
    {
        label: '适应',
        value: 'auto'
    },
    {
        label: '铺满',
        value: 'cover'
    }
]

/** 封面图片比例 */
// export const COVER_RATIO_OPTIONS = [
//     {
//         label: (
//             <Tooltip title="1:1">
//                 <IconFont type="Square" size={16} />
//             </Tooltip>
//         ),
//         value: '1:1'
//     },
//     {
//         label: (
//             <Tooltip title="4:3">
//                 <IconFont type="Rectangle" size={16} />
//             </Tooltip>
//         ),
//         value: '4:3'
//     },
//     {
//         label: (
//             <Tooltip title="3:2">
//                 <IconFont type="32" size={16} />
//             </Tooltip>
//         ),
//         value: '3:2'
//     },
//     {
//         label: (
//             <Tooltip title="16:9">
//                 <IconFont type="169" size={16} />
//             </Tooltip>
//         ),
//         value: '16:9'
//     }
// ]
export const COVER_RATIO_OPTIONS = [
    {
        label: '1:1',
        value: '1:1'
    },
    {
        label: '3:4',
        value: '3:4'
    },
    {
        label: '4:3',
        value: '4:3'
    },
    {
        label: '3:2',
        value: '3:2'
    },
    {
        label: '9:16',
        value: '9:16'
    },
    {
        label: '16:9',
        value: '16:9'
    }
]

export const VIEW_LIKE_BLOCKS = new Set<BlockType>(['view', 'relatedView'])

/** block 尺寸 */
export const BLOCK_SIZE_OPTIONS = [
    {
        label: 'XS',
        value: 'xs'
    },
    {
        label: 'S',
        value: 'sm'
    },
    {
        label: 'M',
        value: 'md'
    },
    {
        label: 'L',
        value: 'lg'
    },
    {
        label: 'XL',
        value: 'xl'
    }
]

export const DEFAULT_VIEW_CONFIG: Omit<ViewOptions, 'appId' | 'viewType'> = {
    pointer: '',
    showTitle: true,
    canSearch: true,
    canEdit: false,
    canDisplay: true,
    canViewRecord: true,
    required: false,
    canSort: true,
    canDeleteRecord: false,

    // 系统表需要禁用,先统一关闭、使用后端返回的值
    canCreateRecord: false,
    creatingConfig: { page: '', label: '添加', openType: 'modal' },
    viewingConfig: { page: '', openType: 'modal' },
    groupConfigure: {
        groupByFieldId: '',
        groupConfig: []
    },
    viewFieldSettings: [],
    canPaginate: true,
    actions: {
        toolbar: {
            customized: false
        },
        recordOperator: {
            customized: false
        },
        recordClicked: {
            customized: false
        }
    },
    linkFilterController: {
        expression: {
            where: 'AND',
            conditions: []
        }
    },
    pagination: {
        pageSize: 10
    },
    quickFilter: { mode: 'normal', fieldIds: [] }
}

export const DEFAULT_FIELD_CONFIG: Partial<FieldBlockAbstract['config']> = {
    canDownload: false,
    canEdit: true
}
export const DEFAULT_CARD_CONFIG: Partial<CardBlockAbstract['config']> = {
    direction: 'horizontal',
    list: [
        {
            id: nanoid(),
            iconColor: '#649BFA',
            action: {
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            }
        }
    ],
    size: 'md'
}

export const PROMPT_TYPE_OPTIONS = [
    { label: '中立', value: 'normal' },
    { label: '成功', value: 'success' },
    { label: '警告', value: 'warning' },
    { label: '失败', value: 'error' }
]

export const TITLE_BLOCKS_OPTIONS = [
    { text: '超大', label: '超大标题', value: 'h1', size: 28, weight: 700 },
    { text: '大', label: '大标题', value: 'h2', size: 22, weight: 600 },
    { text: '中', label: '中标题', value: 'h3', size: 18, weight: 500 },
    { text: '小', label: '小标题', value: 'h4', size: 16 }
    // { text: '引述', label: '引述标题', value: 'quote', icon: 'BlockQuote' },
    // { text: '重要提示', label: '提示', value: 'prompt', icon: 'BlockText', size: 10 }
]
export const TITLE_BLOCKS_TITLE_MAP = {
    h1: '超大标题',
    h2: '大标题',
    h3: '中标题',
    h4: '小标题',
    quote: '引述标题',
    prompt: '提示',
    combination: '组合标题'
}

export const SHAPE_OPTIONS = [
    {
        label: '圆形',
        icon: 'Circle',
        value: 'round'
    },
    {
        label: '正方形',
        icon: 'Square',
        value: 'square'
    },
    {
        label: '矩形',
        icon: 'Rectangle',
        value: 'rectangle'
    }
]

//  block 设置分类
export const BLOCK_SETTING_KIND: SegmentedControlItem[] = [
    {
        label: '内容',
        value: 'content',
        icon: <IconFont type="ListBullets" />
    },
    {
        label: '设计',
        value: 'style',
        icon: <IconFont type="Palette" />
    }
]

export const tableRowStyles = [
    {
        label: '低',
        value: 'small'
    },
    {
        label: '中',
        value: 'middle'
    },
    {
        label: '高',
        value: 'large'
    }
]

//  图片block 图片来源
export const BLOCK_IMAGE_SOURCES = [
    {
        label: '本地文件',
        value: 'default'
    },
    {
        label: '使用变量数据',
        value: 'field'
    }
]

//  视频block 视频来源
export const BLOCK_VIDEO_SOURCES = [
    {
        label: '上传视频',
        value: 'default'
    },
    {
        label: '选择数据源',
        value: 'field'
    }
]

export const TabEnum = Enum('data', 'operation', 'style')

export const VIEW_TABS = [
    { value: TabEnum.data, label: '视图设置', icon: <IconFont type="SlidersHorizontal" /> },
    { value: TabEnum.operation, label: '用户操作', icon: <IconFont type="Cursor" /> },
    { value: TabEnum.style, label: '设计', icon: <IconFont type="Palette" /> }
]

export const TABS = [
    { value: TabEnum.data, label: '数据', icon: <IconFont type="DataSource" /> },
    { value: TabEnum.operation, label: '用户操作', icon: <IconFont type="Cursor" /> },
    { value: TabEnum.style, label: '设计', icon: <IconFont type="Palette" /> }
]

export const ButtonTabEnum = Enum('normal', 'style')

export const BUTTON_TABS = [
    { value: ButtonTabEnum.normal, label: '基础', icon: <IconFont type="SlidersHorizontal" /> },
    { value: ButtonTabEnum.style, label: '设计', icon: <IconFont type="Palette" /> }
]

export const SETTINGS_TAB_ENUM = Enum('normal', 'visibility')
export type SettingsTabEnum = Enum<typeof SETTINGS_TAB_ENUM>

export const SETTINGS_TABS = [
    { value: SETTINGS_TAB_ENUM.normal, label: '基础', icon: <IconFont type="SlidersHorizontal" size={16} /> },
    { value: SETTINGS_TAB_ENUM.visibility, label: '可见性', icon: <IconFont type="Eye" size={16} /> }
]

export const NUMBER_MODE_OPTIONS: {
    label: string
    value: 'percentage' | 'number'
}[] = [
    {
        label: '数值',
        value: 'number'
    },
    {
        label: '百分比',
        value: 'percentage'
    }
]

export const INPUT_IMAGE_LIST: {
    image: string
    type: FieldInputType
}[] = [
    {
        image: textInputImage,
        type: 'text'
    },
    {
        image: numberInputImage,
        type: 'number'
    },
    {
        image: emailInputImage,
        type: 'email'
    },
    {
        image: phoneNumberInputImage,
        type: 'phoneNumber'
    },
    {
        image: urlInputImage,
        type: 'url'
    },
    // {
    //     image: selectInputImage,
    //     type: 'select'
    // },
    {
        image: dateInputImage,
        type: 'date'
    },
    {
        image: fileInputImage,
        type: 'file'
    },
    {
        image: notesInputImage,
        type: 'notes'
    },
    {
        image: personInputImage,
        type: 'person'
    },
    {
        image: selectInputImage,
        type: 'relativeSelect'
    }
]

/** 图片block 点击图片响应方式 */
export const IMAGE_CLICK_OPTIONS: {
    label: string
    value: 'jump' | 'preview' | 'custom' | 'none'
}[] = [
    {
        label: '无反应',
        value: 'none'
    },
    {
        label: '预览图片',
        value: 'preview'
    },
    {
        label: '跳转链接/页面',
        value: 'jump'
    },
    {
        label: '自定义动作',
        value: 'custom'
    }
]

// 图表类型

export const ChartTabEnum = Enum('data', 'style')

export const notDimensionFieldType = new Set<FieldType>(['file', 'video', 'notes'])

export const notDimensionFieldInnerType = new Set<TypeInstanceMap>(['NULL'])

export const notIndicatorFieldType = new Set<FieldType>(['file', 'video', 'notes'])

export const notIndicatorFieldInnerType = new Set<TypeInstanceMap>(['NULL'])

export const CHART_TABS = [
    { value: TabEnum.data, label: '数据', icon: <IconFont size={16} type="DataSource" /> },
    { value: TabEnum.style, label: '设计', icon: <IconFont size={16} type="Palette" /> }
]

export const calcTypeOptions: { label: string; value: ChartValueCalcType }[] = [
    { label: '计数', value: 'count' },
    { label: '去重计数', value: 'countUnique' },
    { label: '求和', value: 'sum' },
    { label: '平均', value: 'average' },
    { label: '最大值', value: 'max' },
    { label: '最小值', value: 'min' }
]

export const chartThemeColorOptions: { value: string; label: string; colors: string[] }[] = [
    {
        label: '默认',
        value: 'multi_default',
        colors: ['#6160dc', '#54c5eb', '#ff4a55', '#ffb74a', '#45e2bf', '#cbbOe3']
    },
    {
        label: '海滨',
        value: 'multi_seashore',
        colors: ['#4379CE', '#99B8F1', '#FADB37', '#FC8602', '#FFA748', '#72B5EB', '#78CDED', '#AC9AE2', '#FAC36F', '#FD7F7F']
    },
    {
        label: '春日',
        value: 'multi_spring',
        colors: ['#04699F', '#317e9c', '#4D96C1', '#55A4A1', '#FE7F82', '#6FD0ED', '#BCC2C7', '#73A2E3', '#95B3DA', '#668A9F']
    },
    {
        label: '湖光山色',
        value: 'multi_natural',
        colors: [
            '#4B72C9',
            '#9DCE66',
            '#3FB27E',
            '#F7CB4A',
            '#F98E48',
            '#E26767',
            '#CE62D6',
            '#8956D4',
            '#5156B8',
            '#51B4F1',
            '#69D4DB',
            '#D77096'
        ]
    },
    {
        label: '正式场合',
        value: 'multi_business',
        colors: ['#2B475F', '#5A8298', '#F25C25', '#FCBA08', '#FC9C06', '#B1D6FF', '#FDD493', '#FFAB6F', '#8AB08F', '#C8C9C9']
    },
    {
        label: '维他命',
        value: 'multi_vitamin',
        colors: ['#56638a', '#42b977', '#f2b817', '#fac8cc', '#9ebb62', '#dfde92', '#8ec0ad', '#d7c38b', '#8bc1c3', '#8a98c1']
    },
    {
        label: '彩色乐章',
        value: 'multi_music',
        colors: ['#19a8e2', '#2fd7db', '#8c97db', '#fcb198', '#de88bc', '#d7b8e0', '#78cc97', '#9acbeb', '#75a5f0', '#aad8ba']
    },
    {
        label: '未来蓝',
        value: 'multi_futureBlur',
        colors: [
            '#3472ea',
            '#7bbafc',
            '#2bcdbf',
            '#9e69e2',
            '#cdbeff',
            '#cde1ff',
            '#6a84b7',
            '#7464d3',
            '#e3e3e3',
            '#ffd988',
            '#f7ac60',
            '#89aed5'
        ]
    },
    {
        label: '玻璃幕墙',
        value: 'multi_glass',
        colors: [
            '#405e94',
            '#3988c7',
            '#e2ca89',
            '#70aedf',
            '#9ec5e3',
            '#9d95d1',
            '#ffc93e',
            '#fc8600',
            '#a2c7c1',
            '#53aaa3',
            '#0844b5',
            '#04021e'
        ]
    },
    {
        label: '冬日午后',
        value: 'multi_winter',
        colors: ['#6e7acb', '#a2c8e5', '#dc97a9', '#5e9bb0', '#f5e0b6', '#ccccd4', '#6783c7', '#e7bcac', '#a4c2cd', '#559985']
    },
    {
        label: '薄荷',
        value: 'multi_mint',
        colors: ['#12abab', '#bae2e0', '#b2b2b2', '#7f807f', '#666666', '#4a4848']
    },
    {
        label: '魔术球',
        value: 'multi_magicBall',
        colors: ['#5253bf', '#89a5db', '#e3b936', '#7eabff', '#8ccf55', '#ff8732', '#5dab96', '#31d9e0', '#a7c5ff', '#e9e4c8', '#42b3d5']
    },
    {
        label: '游乐场',
        value: 'multi_carnie',
        colors: [
            '#4578de',
            '#0ad4b6',
            '#ffcf05',
            '#a4e427',
            '#c2fced',
            '#66e1e5',
            '#5db2ee',
            '#f89131',
            '#fbe98a',
            '#a475e3',
            '#f88a97',
            '#e94242'
        ]
    },
    {
        label: '野餐聚会',
        value: 'multi_picnic',
        colors: [
            '#028d6d',
            '#95ce76',
            '#ffcc40',
            '#00a6bb',
            '#93e0d9',
            '#ea7795',
            '#66a08f',
            '#276e5c',
            '#5db788',
            '#72d09f',
            '#81b522',
            '#bdd6d4'
        ]
    },
    {
        label: '公路旅行',
        value: 'multi_road',
        colors: ['#4a65bf', '#9bafd9', '#9fd989', '#f1436c', '#deb6a0', '#e69522', '#298e7f', '#93c066', '#6eb0ed', '#b1daf3']
    },
    {
        label: '摩登时代',
        value: 'multi_modern',
        colors: [
            '#0781c3',
            '#ff7500',
            '#83bff4',
            '#ffb86c',
            '#3fb27e',
            '#9bcc7e',
            '#f35352',
            '#ff9193',
            '#9d61c2',
            '#caaed8',
            '#8fa500',
            '#bfd000'
        ]
    },
    {
        label: '现代经典',
        value: 'multi_classics',
        colors: ['#4a90e2', '#ea7d58', '#4a72c9', '#fdd400', '#ffa633', '#a593e0', '#a3c9c7', '#4d8cae', '#d0d593', '#4d5fb0', '#89a0d3']
    },
    { label: '单宁红', value: 'single_red', colors: ['#DB3553', '#E56D83', '#EE9EAC', '#F5C7CF', '#F9DBE0', '#FBEBEE', '#FDF3F5'] },
    { label: '芭比粉', value: 'single_pink', colors: ['#FF537F', '#FF7599', '#FF98B2', '#FFBACC', '#FFCBD9', '#FFDDE5', '#FFE5EC'] },
    { label: '棕茶色', value: 'single_brown', colors: ['#F0B4B6', '#F3C3C5', '#F6D2D3', '#F9E1E2', '#FBE9E9', '#FCF0F0', '#FDF4F4'] },
    { label: '萱草黄', value: 'single_grassYellow', colors: ['#EF8C0B', '#F2A33C', '#F5BA6D', '#F9D19D', '#FADDB6', '#FCE8CE', '#FDEEDA'] },
    { label: '明黄', value: 'single_lightYellow', colors: ['#EEBB17', '#F1C945', '#F5D674', '#F8E4A2', '#FAEBB9', '#FCF1D1', '#FDF5DC'] },
    { label: '琥珀色', value: 'single_amber', colors: ['#DAA76B', '#E1B989', '#E9CAA6', '#F0DCC4', '#F4E5D3', '#F8EDE1', '#FAF2E9'] },
    { label: '秋黄绿', value: 'single_autumn', colors: ['#96B25A', '#ABC17B', '#C0D19C', '#D5E0BD', '#E0E8CE', '#EAF0DE', '#EFF4E6'] },
    { label: '青豆色', value: 'single_cyan', colors: ['#6EC395', '#8BCFAA', '#A8DBBF', '#C5E7D5', '#D4EDDF', '#E2F3EA', '#E9F6EF'] },
    { label: '深松绿', value: 'single_pine', colors: ['#00AA93', '#33BBA9', '#66CCBE', '#99DDD4', '#B3E6DF', '#CCEEE9', '#D9F2EF'] },
    { label: '空灰蓝', value: 'single_grayBlue', colors: ['#6492B7', '#83A8C5', '#A2BED4', '#C1D3E2', '#D1DEE9', '#E0E9F1', '#E8EFF4'] },
    { label: '碧蓝', value: 'single_blue', colors: ['#0D19FC', '#3D47FD', '#6E75FD', '#9EA3FE', '#B6BAFE', '#CFD1FE', '#DBDDFF'] },
    { label: '雪青紫', value: 'single_purple', colors: ['#7A12FF', '#9541FF', '#AF71FF', '#CAA0FF', '#D7B8FF', '#E4D0FF', '#EBDCFF'] },
    { label: '深玄色', value: 'single_grayBlack', colors: ['#08324E', '#395B71', '#6B8495', '#9CADB8', '#B5C1CA', '#CED6DC', '#DAE0E4'] }
]

export const chartThemeColorMap = Object.fromEntries(chartThemeColorOptions.map(({ value, colors }) => [value, colors]))

export const chartStackingOptions: { value: string; label: string }[] = [
    { value: 'stack', label: '堆叠' },
    { value: 'group', label: '并排' }
]

export const chartAxisOptions: { value: string; label: string }[] = [
    { value: 'X', label: '横轴值' },
    { value: 'Y', label: '纵轴值' }
]

export const chartSortOptions: { value: string; label: string }[] = [
    { value: 'ASC', label: '正序' },
    { value: 'DESC', label: '倒序' }
]

export const chartMarkLineOptions: { value: string; label: string }[] = [{ value: 'fixed', label: '固定值' }]

export const legendTranspose: { value: string; label: string }[] = [
    {
        label: '上',
        value: 'top'
    },
    {
        label: '下',
        value: 'bottom'
    },
    {
        label: '左',
        value: 'left'
    },
    {
        label: '右',
        value: 'right'
    }
]

export const transpose: { value: string; label: string }[] = [
    {
        label: '水平',
        value: 'horizontal'
    },
    {
        label: '垂直',
        value: 'vertical'
    }
]

export const chartLineType: { value: string; label: string }[] = [
    {
        label: '折线',
        value: 'straight'
    },
    {
        label: '曲线',
        value: 'smooth'
    }
]

export const chartTypeOptions: ChartTypeOption[] = [
    {
        label: '复合图',
        value: ChartType.composite,
        image: ChartBlockThumbImageMap.composite,
        color: 'var(--color-main)',
        background: 'rgba(85, 81, 255, 0.10)'
    },
    { label: '柱状图', value: ChartType.bar, image: ChartBlockThumbImageMap.bar, color: '#F04438', background: 'rgba(240, 68, 56, 0.10)' },
    {
        label: '漏斗图',
        value: ChartType.funnel,
        image: ChartBlockThumbImageMap.funnel,
        color: '#FF4A55',
        background: 'rgba(240, 98, 52, 0.10)'
    },
    {
        label: '条形图',
        value: ChartType.striation,
        image: ChartBlockThumbImageMap.striation,
        color: '#FFB74A',
        background: 'rgba(255, 183, 74, 0.10)'
    },
    {
        label: '指标卡',
        value: ChartType.indicator,
        image: ChartBlockThumbImageMap.indicator,
        color: '#54C5EB',
        background: 'rgba(84, 197, 235, 0.10)'
    },
    { label: '饼状图', value: ChartType.pie, image: ChartBlockThumbImageMap.pie, color: '#12B76A', background: 'rgba(85, 200, 27, 0.10)' },
    {
        label: '折线图',
        value: ChartType.line,
        image: ChartBlockThumbImageMap.line,
        color: '#FFB74A',
        background: 'rgba(255, 183, 74, 0.10)'
    }
]

export const dataSettingSwitchList: {
    label: string
    value: keyof Pick<BaseChartOption, 'aggregate' | 'showLegend'>
}[] = [{ label: '聚合值', value: 'aggregate' }]

export const DEFAULT_CONTAINER_DESIGN: DesignProtocol = {
    size: MAX_CONTAINER_WIDTH_SIZE.md,
    direction: DIRECTION.vertical,
    heightRule: HEIGHT_RULE.AUTO,
    gap: 0.7,
    padding: [1, 1, 1, 1],
    alignY: FLEX_ALIGN['flex-start']
}

export const SIZE_FIT_TYPE_CONTROL: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="包住内容">
                <IconFont type="ArrowsInLineHorizontal" />
            </Tooltip>
        ),
        value: 'contain'
    },
    {
        label: (
            <Tooltip title="撑满容器">
                <IconFont type="ArrowsOutLineHorizontalContain" />
            </Tooltip>
        ),
        value: 'fill'
    }
]
