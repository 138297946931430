import { CollapseBox } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useSWR from 'swr'

import PersonSelect from '@/components/PersonSelect'
import { useCurrentAppID } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../../styles'

interface CarbonCopyActionConfigureProps {}

export const CarbonCopyActionConfigure: React.FC<CarbonCopyActionConfigureProps> = () => {
    const { control, setValue } = useFormContext()
    const appId = useCurrentAppID()
    const { data } = useSWR(`/fetch/getUsersAndGroups/${appId}`, () => srv.getUsersAndGroups(), {
        revalidateOnFocus: false,
    })

    const [users, groups] = useMemo(() => {
        if (data) {
            return [data.users, data.groups]
        }
        return [[], []]
    }, [data])

    return (
        <SC.Container>
            <CollapseBox label="抄送人设置" required>
                <SC.Content>
                    <Controller
                        name="config.users"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <>
                                    <SC.Description style={{ marginBottom: 14 }}>添加抄送人（不能超过 100 人）</SC.Description>
                                    <PersonSelect value={field.value} onChange={field.onChange} userOptions={users} groupOptions={groups} />
                                </>
                            )
                        }}
                    />
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
