import { AggregatorNodeType } from '@lighthouse/shared'

export const NodeRectMap = new Map<AggregatorNodeType, { width: number; height: number }>([
    [AggregatorNodeType.DATA_INPUT, { width: 200, height: 66 }],
    [AggregatorNodeType.JOIN, { width: 72, height: 48 }],
    [AggregatorNodeType.FIELD_SETTING, { width: 72, height: 48 }],
    [AggregatorNodeType.FILTER, { width: 72, height: 48 }],
    [AggregatorNodeType.GROUP_STATISTICS, { width: 72, height: 48 }],
    [AggregatorNodeType.ADD_PLACEHOLDER, { width: 72, height: 48 }]
])

export const NodeTypeColorMap: Record<AggregatorNodeType, string> = {
    DATA_INPUT: 'var(--color-green-500)',
    JOIN: 'var(--color-purple-500)',
    FIELD_SETTING: 'var(--color-purple-500)',
    FILTER: 'var(--color-purple-500)',
    GROUP_STATISTICS: 'var(--color-purple-500)',
    ADD_PLACEHOLDER: 'var(--color-purple-500)'
}

export const defaultNodeTypeColor = 'var(--color-gray-400)'
