import { reduce } from 'rambda'
import React, { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

import { BlockCardList } from './BlockCardList'
import { BlockKindCardList } from './BlockKindCardList'
import FormCard from './Form'
import { ModuleList } from './ModuleList'

interface KindDropDownProps {
    items: BlockTreeItem[]
    activeId: string
    onCreate: (ev: React.MouseEvent<HTMLDivElement>, data: BlockTreeItem) => void
}

const SCxDropDownContainer = styled.div`
    height: 100%;
    width: 264px;
    padding: 16px;
`

const KindDropDown: React.FunctionComponent<KindDropDownProps> = ({ activeId, items, onCreate }) => {
    const dropDownEle = useMemo(() => {
        switch (activeId) {
            case 'components': {
                return <ModuleList items={items} onCreate={onCreate} />
            }
            case 'dataEnter': {
                return <FormCard items={items} onCreate={onCreate} />
            }
            default: {
                return <BlockCardList items={items} onCreate={onCreate} />
            }
        }
    }, [activeId, items, onCreate])

    return <SCxDropDownContainer>{dropDownEle}</SCxDropDownContainer>
}

export default KindDropDown
