import { Flex, IconFont, Input, MultiSelect, Text } from '@byecode/ui'
import type { MultiSelectProps } from '@byecode/ui/components/MultiSelect'
import { COUNTRY_LIST } from '@lighthouse/shared'
import React, { useMemo } from 'react'

interface CountrySelectProps extends MultiSelectProps {}

export const CountrySelect: React.FunctionComponent<CountrySelectProps> = ({ ...rest }) => {
    const options = useMemo(
        () =>
            COUNTRY_LIST.map(item => ({
                label: item.en,
                value: item.short,
                tel: item.tel,
                icon: <img width={16} src={item.flag} loading="lazy" alt="" />,
                extra: <Text color="var(--color-gray-400)">+{item.tel}</Text>
            })),
        []
    )
    return (
        <MultiSelect
            options={options}
            searchable
            customInputValueRender={({ selectOptions }) => (
                <Input
                    defaultValue={selectOptions.length > 0 ? `${selectOptions.length} 个` : ''}
                    readOnly
                    placeholder="请选择"
                    styles={{ input: { cursor: 'pointer' } }}
                    suffix={<IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />}
                />
            )}
            dropdownWidth={300}
            position="bottom-end"
            {...rest}
        />
    )
}
