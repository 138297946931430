import React from 'react'
import styled from 'styled-components'

import AddressBar from './AddressBar'
import { PreviewIcon } from './PreviewIcon'
import { RedoUndo } from './RedoUndo'
import { SelectRole } from './SelectRole'

interface PageToolsProps {
    style?: React.CSSProperties
}

const SCxContainer = styled.div`
    flex-shrink: 0;
    display: flex;
    height: 48px;
    flex-grow: 44px;
    padding: 0 8px;
    gap: 16px;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    align-items: center;
    background-color: var(--color-gray-25);
    /* border: 1px solid var(--color-gray-300); */
    /* border-radius: inherit; */
`

export const PageTool: React.FunctionComponent<PageToolsProps> = ({ style }) => {
    return (
        <SCxContainer style={style}>
            <SelectRole />
            <AddressBar hoverColor="var(--color-gray-200)" style={{ backgroundColor: 'var(--color-gray-100)' }} />
            <PreviewIcon />
            {/* <RedoUndo /> */}
        </SCxContainer>
    )
}
