import { flex } from '@byecode/ui'
import cls from 'classnames'
import React from 'react'
import styled, { css } from 'styled-components'

type SelectModeStyles = 'root' | 'item' | 'image' | 'label'

interface SelectModeProps {
    value?: string | number
    options?: { label: string; icon: string | React.ReactNode; value: string | number }[]
    itemWidth?: string | number
    styles?: Partial<Record<SelectModeStyles, React.CSSProperties>>
    isLabelActive?: boolean
    disabled?: boolean
    onChange?: (value: string | number) => void
}

const Contain = styled.div`
    ${flex}
    justify-content: space-around;
    align-items: center;
    gap: 14px;
`

const Image = styled.img`
    width: 100%;
    /* border: 1px solid transparent; */
    border-radius: 9px;
    background-color: var(--color-gray-100);
`
const Text = styled.div`
    margin-top: 12px;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
`
const Item = styled.button<{ width?: string; isLabelActive?: boolean }>`
    position: relative;
    width: ${({ width }) => width};
    ${flex}
    flex-direction: column;
    align-items: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-seize-normal);
    line-height: 16px;

    &.active {
        /* &::before {
            position: absolute;
            top: 8.5px;
            right:11.5px;
            content: '';
            width: 3px;
            height: 6px;
            border-color: #fff;
            border-style: solid;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            z-index: 2;
        }
        &::after {
            position: absolute;
            top: 5.5px;
            right: 6.5px;
            content: '';
            width: 13px;
            height: 13px;
            background-color: var(--color-main);
            border-radius: 100%;
        } */
        ${({ isLabelActive }) =>
            isLabelActive &&
            css`
                ${Text} {
                    font-weight: 600;
                    color: var(--color-main);
                }
            `}
        img {
            outline: var(--color-main) solid 2px !important;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
`
export const SelectMode = React.forwardRef<HTMLDivElement, SelectModeProps>(
    ({ value, onChange, itemWidth = '33%', isLabelActive = true, options = [], styles, disabled }, ref) => {
        return (
            <Contain ref={ref} style={styles?.root}>
                {options.map(({ label, icon, value: curVal }) => (
                    <Item
                        key={curVal}
                        disabled={disabled}
                        className={cls({ active: curVal === value })}
                        width={typeof itemWidth === 'number' ? `${itemWidth}px` : itemWidth}
                        onClick={() => onChange?.(curVal)}
                        type='button'
                        style={styles?.item}
                        isLabelActive={isLabelActive}
                    >
                        {typeof icon === 'string' ? <Image style={styles?.image} src={icon} /> : icon}
                        <Text style={styles?.label}>{label}</Text>
                    </Item>
                ))}
            </Contain>
        )
    }
)
