import { backgroundTransitionOnClick, flex, IconFont, pointer, singleTextEllipsis, tinyButtons } from '@byecode/ui'
import { Grid } from '@mantine/core'
import styled from 'styled-components'

export const Container = styled.div``

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? 16}px;
    ${pointer}
    background-color: transparent;
`
export const Text = styled.span<{ size?: number }>`
    flex-shrink: 0;
    line-height: 16px;
    ${singleTextEllipsis}
    font-size: ${({ size }) => size ?? 12}px;
`

export const Btn = styled.div`
    padding: 4px;
    ${flex}
    flex-direction: column;
    align-items: center;
    border-radius: 4px;

    ${pointer}

    ${Text} {
        margin-top: 8px;
    }
    ${backgroundTransitionOnClick}
`
export const BlueBtn = styled.button`
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    margin-left: 12px;
    background: var(--color-main);
    color: var(--color-white);
    font-size: var(--font-size-normal);
    border-color: var(--color-main);
    border-radius: 5px;
`

export const TableContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 48px);
    max-width: 100%;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    overflow: auto hidden;
    ${tinyButtons}
`

export const TableHeader = styled(Grid)`
    margin: 0;
    background-color: var(--color-gray-100);
    border-bottom: 1px solid var(--color-gray-200);
`

export const TableContent = styled.div`
    width: 100%;
    height: calc(100% - 47px);
    overflow: hidden auto;
    ${tinyButtons}
`

export const TableColumnName = styled.div`
    color: var(--color-black);
    font-size: var(--font-size-normal);
    font-weight: 400;
    /* padding: 12px 8px; */
`

export const Item = styled(Grid.Col)<Pick<React.CSSProperties, 'justifyContent' | 'textAlign'>>`
    height: 40px;
    /* line-height: 24px; */
    /* padding: 0px 8px; */
    padding: 0 12px;
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    ${flex}
    justify-content: ${({ justifyContent }) => justifyContent};
    text-align: ${({ textAlign }) => textAlign};
    align-items: center;

    > *:not(:first-child) {
        margin-left: 5px;
    }
`

export const WidthItem = styled.div<Pick<React.CSSProperties, 'justifyContent' | 'textAlign' | 'width'>>`
    height: 48px;
    /* line-height: 24px; */
    width: ${({ width }) => width}px;
    flex-shrink: 0;
    /* padding: 0px 8px; */
    padding: 0 12px;
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
    box-sizing: border-box;
    ${flex}
    justify-content: ${({ justifyContent }) => justifyContent};
    text-align: ${({ textAlign }) => textAlign};
    align-items: center;

    > *:not(:first-child) {
        margin-left: 5px;
    }
`

export const LeftToolFill = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`
