import { BlockThumbImageMap, ChartBlockThumbImageMap, FieldBlockThumbImageMap } from '@lighthouse/block'
import type { BlockAbstract, DataSourceAbstract, PageAbstract } from '@lighthouse/core'
import { BlockType, DataSourceType, PageType } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { reduce } from 'rambda'

import { getDefaultCombinationBlocksOrNode, getDefaultHighlightBlocksOrNode } from '@/constants/Block/combination'
import type { BlockInitConfigOption } from '@/constants/Block/help'
import { getBlockInitConfig, getNodeInitConfig } from '@/constants/Block/help'
import type { BlockTreeItem } from '@/constants/Block/type'

import type { PageItemTree } from './types'

/**
 * 获取block图标
 * @param blockData block 数据
 * @param dataSourceList 数据源
 * @returns
 */
export function getBlockThumbType(blockData: BlockAbstract) {
    // 获取图标
    switch (blockData.type) {
        case BlockType.field: {
            const { inputType } = blockData.config
            // if(!dataSourceList?.[appId]){
            //     return
            // }
            return FieldBlockThumbImageMap[inputType]
        }

        case BlockType.chart: {
            const { chartType } = blockData.config
            return ChartBlockThumbImageMap[chartType]
        }
        case BlockType.view: {
            const { viewType } = blockData.config
            return BlockThumbImageMap[viewType]
        }
        default: {
            return BlockThumbImageMap[blockData.type]
        }
    }
}

export function getBlockOrNode(params: { v: BlockTreeItem } & BlockInitConfigOption): [BlockAbstract[], FlowLayoutNode] | undefined {
    const { v, appId, dataSource, appList } = params
    if (v.type === 'combinationTitle') {
        const [blocks, node] = getDefaultCombinationBlocksOrNode() ?? []
        if (!node || !blocks) {
            return
        }
        return [blocks, node]
    }
    if (v.type === 'highlight') {
        const [blocks, node] = getDefaultHighlightBlocksOrNode() ?? []
        if (!node || !blocks) {
            return
        }
        return [blocks, node]
    }

    const block = getBlockInitConfig(v, {
        appId,
        dataSource,
        appList,
        containerOptions: { direction: v.type === BlockType.container ? v.subType : undefined }
    })
    if (!block) {
        return
    }
    const node = getNodeInitConfig(block)
    if (!node) {
        return
    }

    return [[block], node]
}

const getDatasourceGroup = function (dataSourceList: DataSourceAbstract[]) {
    return dataSourceList.reduce<[DataSourceAbstract[], DataSourceAbstract[]]>(
        (prev, item) => {
            if (item.type === DataSourceType.joinDataSource) {
                prev[0].push(item)
                return prev
            }
            prev[1].push(item)
            return prev
            // item.type === 5 ? [[...prev[0], item], prev[1]] : [prev[0], [...prev[1], item]]
        },
        [[], []]
    )
}

const getChildrenDataSource = (ordinaryDataSource: DataSourceAbstract, joinDataSource: DataSourceAbstract[], pageList: PageAbstract[]) => {
    const { id } = ordinaryDataSource
    return reduce<DataSourceAbstract, PageItemTree[]>(
        (prev, cur) => {
            if (cur.viewOptions.joinConfig?.primaryDsId === id) {
                prev.push({
                    id: cur.id,
                    name: cur.name,
                    data: pageList.filter(page => page.dsId === cur.id)
                })
            }
            return prev
        },
        [],
        joinDataSource
    )
}

export const getPageListGroup = (dataSourceList: DataSourceAbstract[], pageList: PageAbstract[]): PageItemTree[] => {
    const [joinDataSources, ordinaryDataSources] = getDatasourceGroup(dataSourceList)
    return ordinaryDataSources.map(item => {
        return {
            id: item.id,
            disableDrag: true,
            name: item.name,
            data: pageList.filter(page => page.dsId === item.id),
            children: getChildrenDataSource(item, joinDataSources, pageList)
        }
    })
}
