import { Flex, IconFont } from '@byecode/ui'
import { ActionIcon, Avatar, Text } from '@mantine/core'
import React from 'react'

interface ItemProps {
    name: string
    icon: string
    disabled?: boolean
    onRemove?: React.MouseEventHandler<HTMLButtonElement>
}

export const GroupItem: React.FunctionComponent<ItemProps> = ({ name, icon, disabled, onRemove }) => {
    return (
        <Flex py={4} justifyContent="space-between" alignItems="center">
            <Flex gap="8px" justifyContent="space-between" alignItems="center" style={{ width: 'calc(100% - 45px)' }}>
                <Avatar variant="light" sx={{ border: '1px solid var(--color-gray-200)', flex: 0 }} size={20}>
                    <IconFont type={icon} size={14} />
                </Avatar>
                <Text lineClamp={1} title={name} style={{ flex: 1 }} size={14}>
                    {name}
                </Text>
            </Flex>
            <ActionIcon disabled={disabled} variant="transparent" onClick={onRemove}>
                <IconFont type="ReduceCircle" size={24} fill="var(--color-red-500)" />
            </ActionIcon>
        </Flex>
    )
}
