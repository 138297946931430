import { aggregatorJoinNodeIconMap, aggregatorNodeTypeIconMap } from '../constants'
import type { AggregatorNode } from '../types'

export const geyAggregatorNodeIcon = (node: AggregatorNode) => {
    if (!node.type) {
        return ''
    }
    if (node.type === 'JOIN') {
        return aggregatorJoinNodeIconMap[node.data?.config?.joinType ?? 'LEFT_JOIN']
    }
    return aggregatorNodeTypeIconMap[node.type]
}
