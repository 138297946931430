import { IconFont } from '@byecode/ui'
import type { SchemaProtocol, ViewFieldProtocol } from '@lighthouse/core'
import { getFieldIcon, ListItemPaddingByecodeUi } from '@lighthouse/shared'
import type { ChangeEvent } from 'react'
import React, { useCallback, useMemo } from 'react'

import * as SC from './styles'

export interface BaseViewFieldConfigProps {
    value: ViewFieldProtocol
    schema: SchemaProtocol['schema']
    onChange?: (val: ViewFieldProtocol) => void
}

export const BaseViewFieldConfig: React.FC<BaseViewFieldConfigProps> = ({ value, schema, onChange }) => {
    const { type, title, fieldId, innerType } = value
    const dataSourceField = useMemo(() => schema[fieldId], [fieldId, schema])
    const handleChange = useCallback(
        (val: string) => {
            onChange?.({
                ...value,
                title: val
            })
        },
        [onChange, value]
    )
    const icon = useMemo(() => getFieldIcon(fieldId, type, innerType), [fieldId, innerType, type])

    return (
        <SC.Container>
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center" enablePadding>
                <SC.FieldIcon>
                    <IconFont size={16} type={icon} />
                </SC.FieldIcon>
                <SC.RightFill>
                    <SC.ViewSettingsInput
                        placeholder="请输入标题"
                        defaultValue={title}
                        styles={{ wrapper: { width: '100%' } }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                    />
                </SC.RightFill>
            </ListItemPaddingByecodeUi>
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center" enablePadding>
                <SC.Text color="var(--color-gray-600)">字段</SC.Text>
                <SC.Text>{dataSourceField?.name}</SC.Text>
            </ListItemPaddingByecodeUi>
        </SC.Container>
    )
}
