import { Anchor, Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'
import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { WeChatPayAccountFormState, WeChatPayAccountMode } from './type'

interface WeChatPayAccountProps {
    id?: string
    mode: WeChatPayAccountMode
    onClose?: () => void
}

const initData: WeChatPayAccountFormState = {
    id: '',
    name: '',
    appId: '',
    mchId: '',
    appSecret: '',
    apiV3Key: '',
    mchPrivateKey: '',
    mchSerialNo: ''
}

const WeChatPayAccount: React.FunctionComponent<WeChatPayAccountProps> = ({ id, mode, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()

    const [loading, setLoading] = useState(false)

    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform
            ? {
                  ...otherPlatform?.config,
                  name: otherPlatform.name,
                  id,
                  appSecret: PLACEHOLDER_PASSWORD,
                  apiV3Key: PLACEHOLDER_PASSWORD,
                  mchSerialNo: PLACEHOLDER_PASSWORD,
                  mchPrivateKey: PLACEHOLDER_PASSWORD
              }
            : initData
    }, [id, otherPlatforms])

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: WeChatPayAccountFormState) => {
            const { name, appId, appSecret, mchId, apiV3Key, mchPrivateKey, mchSerialNo } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'WECHAT_PAY',
                name,
                params: { appId, appSecret, mchId, apiV3Key, mchPrivateKey, mchSerialNo }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WECHAT_PAY']}-${title}」。`,
            okStatus: 'error'
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WECHAT_PAY']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: WeChatPayAccountFormState) => {
            const { name, id, appId, appSecret, mchId, apiV3Key, mchPrivateKey, mchSerialNo } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'WECHAT_PAY',
                name,
                id,
                params: {
                    appId,
                    mchId,
                    apiV3Key: apiV3Key === PLACEHOLDER_PASSWORD ? undefined : apiV3Key,
                    mchPrivateKey: mchPrivateKey === PLACEHOLDER_PASSWORD ? undefined : mchPrivateKey,
                    mchSerialNo: mchSerialNo === PLACEHOLDER_PASSWORD ? undefined : mchSerialNo,
                    appSecret: appSecret === PLACEHOLDER_PASSWORD ? undefined : appSecret
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} color="#12B76A" type="WechatPay" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}微信支付商户号</SC.Title>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Description>
                        开通微信支付商户号，开通后订单将结算至商户账户余额内，微信将收取每笔0.54%-0.6%的支付手续费，具体费率登录微信商户平台后台查看。
                    </SC.Description>
                </SC.Item>
                <SC.Tip>
                    <SC.Icon color="var(--color-main)" type="WarningCircle" />
                    <SC.Text>如果您添加账号遇到问题，请查看帮助文档：</SC.Text>
                    <Anchor
                        target="_blank"
                        style={{ fontWeight: 600, color: 'var(--color-main)' }}
                        to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pxnbuk2ax3qqbe25"
                    >
                        如何添加微信支付商户号
                    </Anchor>
                </SC.Tip>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>
                        账号标题
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>
                        微信公众号账号
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        微信支付商户号
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="mchId" errors={errors}>
                        <Input placeholder="请填入微信支付商户号" {...register('mchId', { required: '不能为空' })} />
                    </ErrorMessage>
                    <SC.Description>
                        需先登录
                        <Anchor
                            target="_blank"
                            style={{ color: 'var(--color-main)' }}
                            to="https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F"
                        >
                            「商户平台」
                        </Anchor>
                        {'>「产品中心」>「开发配置」页面找到对应的商户号，也就是商户在微信侧申请入驻的收款账号'}
                    </SC.Description>
                </SC.Item>
                {/* <SC.Item>
                    <SC.Text>
                        开发者ID(AppID)
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appId" errors={errors}>
                        <Input placeholder="请填入开发者ID(AppID)" {...register('appId', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        开发者密码(AppSecret)
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appSecret" errors={errors}>
                        <Input
                            placeholder="请填入开发者密码(AppSecret) "
                            type="password"
                            autoComplete="new-password"
                            {...register('appSecret', { required: '不能为空' })}
                        />
                    </ErrorMessage>
                </SC.Item> */}
                <SC.Item>
                    <SC.Text>
                        API v3 密钥
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="apiV3Key" errors={errors}>
                        <Controller
                            name="apiV3Key"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入 API v3 密钥 "
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>{'需先登录「商户平台」>「账户中心」>「账户设置」>「API安全」生成该密钥'}</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        商户证书私钥
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="mchPrivateKey" errors={errors}>
                        <Controller
                            name="mchPrivateKey"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入商户证书私钥"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        {'需先登录「商户平台」>「账户中心」>「账户设置」>「API安全」申请API证书，获取私钥，'}
                        <Anchor
                            target="_blank"
                            style={{ color: 'var(--color-main)' }}
                            to="https://pay.weixin.qq.com/docs/merchant/development/interface-rules/privatekey-and-certificate.html"
                        >
                            参考文档
                        </Anchor>
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        商户证书序列号
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="mchSerialNo" errors={errors}>
                        <Controller
                            name="mchSerialNo"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入商户证书序列号"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        {'需先登录「商户平台」>「账户中心」>「账户设置」>「API安全」>「API证书」>「查看证书」查看商户API证书序列号'}
                    </SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default WeChatPayAccount
