import { IconFont, Input } from '@byecode/ui'
import { isEmpty } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import ListItem from '../ListItem'

const SettingMenuContainer = styled.div`
    width: 224px;
    padding: 8px 0;
    flex: 1;
    overflow-y: auto;
`

const CustomPageSize = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const NumberPageSizeInput = styled(Input)`
    width: 60px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
`

const Unit = styled.div`
    margin-left: 4px;
`

const ItemDescription = styled.div`
    margin: 12px 0 6px 0;
    color: var(--color-gray-400);
    padding: 0 12px;
    font-size: var(--font-size-normal);
`

const RightIcon = styled(IconFont)``

interface List {
    label: string
    value: number
}

interface PageSizeListProps {
    size: number
    custom?: boolean
    disabled?: boolean
    onChangePageSize?: (size: number, custom?: boolean) => void
}

const list: List[] = [
    {
        label: '10条',
        value: 10
    },
    {
        label: '50条',
        value: 50
    },
    {
        label: '100条',
        value: 100
    }
]

export const PageSizeList: React.FC<PageSizeListProps> = ({ size, custom, disabled, onChangePageSize }) => {
    const [customPageSize, setCustomPageSize] = useState<string>(custom ? String(size) : '9')

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomPageSize(e.target.value)
    }, [])

    const formatNumber = useCallback((num: string) => {
        const ridReg = /^\d|-|\.$/u
        const dataNumber = num
        const judgeNum = [...dataNumber].filter(item => ridReg.test(item)).join('')
        if (isEmpty(judgeNum)) {
            return 1
        }
        const showNumArr = Number(judgeNum)
        return showNumArr ?? 1
    }, [])

    const handleBlur = useCallback(() => {
        let num = formatNumber(customPageSize)

        if (num < 1) {
            num = 1
        }
        if (num > 100) {
            num = 100
        }
        setCustomPageSize(String(num))
        if (custom) {
            onChangePageSize?.(num, custom)
        }
    }, [custom, customPageSize, formatNumber, onChangePageSize])

    const handleInputClick = useCallback((ev: React.MouseEvent<HTMLInputElement>) => {
        ev.stopPropagation()
    }, [])

    const customInput = useMemo(
        () => (
            <CustomPageSize>
                <NumberPageSizeInput
                    size="sm"
                    type="text"
                    disabled={disabled}
                    value={customPageSize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={handleInputClick}
                />
                <Unit>条</Unit>
            </CustomPageSize>
        ),
        [customPageSize, disabled, handleBlur, handleChange, handleInputClick]
    )

    const handleCustomPageSize = useCallback(() => {
        onChangePageSize?.(Number(customPageSize), true)
    }, [customPageSize, onChangePageSize])

    return (
        <SettingMenuContainer>
            <ItemDescription>每页最多显示记录</ItemDescription>
            {list.map(item => (
                <ListItem
                    disabled={disabled}
                    key={item.value}
                    title={item.label}
                    onClick={() => {
                        onChangePageSize?.(item.value)
                    }}
                    suffix={!custom && size === item.value && <RightIcon type="Tick" />}
                />
            ))}
            <ListItem disabled={disabled} onClick={handleCustomPageSize} title={customInput} suffix={custom && <RightIcon type="Tick" />} />
        </SettingMenuContainer>
    )
}
