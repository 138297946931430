import { flex, pointer } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingNavbarButton } from '@lighthouse/core'
import { getRandomIcon, nanoid } from '@lighthouse/tools'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import { useDefaultPageList } from '@/hooks/usePage'
import * as srv from '@/services'

import * as CM from '../../styles'
import { baseItem } from '../constant'
import type { NavBarBaseButtonProps } from '../NavBarBaseButton'
import NavBarBaseButton from '../NavBarBaseButton'

export type NavBarButtonType = 'button' | 'link'
interface NavBarButtonProps {
    disabled?: boolean
    // label: string
    // type: NavBarButtonType
    value: ApplicationSettingNavbarButton[]
    // maxLen?: number

    onChange?: (data: ApplicationSettingNavbarButton[]) => void
}

export const Container = styled.div`
    /* padding: 20px 12px; */
    font-size: var(--font-size-normal);
`
export const Content = styled.div`
    > * {
        margin-top: 12px;
    }
`
export const Header = styled.div`
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
`
export const Footer = styled.div`
    margin-top: 21px;
    ${flex};
    ${pointer}
    align-items: center;
    color: var(--color-main);
    > * {
        margin-left: 8px;
    }
`
export const ItemName = styled.div`
    width: 104px;
    color: var(--color-gray-300);
`
export const ItemTitle = styled.div`
    padding-left: 24px;
    ${flex}
    >* {
        margin-left: 8px;
    }
`

const SortableButton: React.FC<NavBarBaseButtonProps> = ({ disabled, value, level, ...rest }) => {
    const { id } = value
    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id,
        disabled: disabled || rest.disableDrag
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
            zIndex: 2
        }),
        [transform, transition]
    )

    return (
        <NavBarBaseButton
            disabled={disabled}
            value={value}
            style={style}
            ref={setNodeRef}
            handleDrag={{ listeners, attributes }}
            level={level}
            {...rest}
        />
    )
}

const NavBarButton = React.forwardRef<HTMLDivElement, NavBarButtonProps>(({ disabled, value: data = [], onChange }, ref) => {
    const pageList = useDefaultPageList()

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const handleCreate = useCallback(() => {
        const buttonItem = {
            ...baseItem,
            id: nanoid(16),
            pattern: 0,
            icon: getRandomIcon(),
            name: `按钮${data.length}`
        }
        onChange?.([...data, buttonItem])
    }, [data, onChange])

    const handleDelete = useCallback(
        (id: string) => {
            const delKey = findIndex(item => item.id === id, data)
            const newData = clone(data)
            newData.splice(delKey, 1)
            onChange?.(newData)
        },
        [data, onChange]
    )

    const handleChange = useCallback(
        (value: ApplicationSettingNavbarButton) => {
            const updateKey = findIndex(item => item.id === value.id, data)
            const newData = clone(data)
            newData[updateKey] = value
            onChange?.(newData)
        },
        [data, onChange]
    )

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = data.findIndex(item => item.id === active.id)
                const newIndex = data.findIndex(item => item.id === over?.id)
                const newData = arrayMove(data, oldIndex, newIndex)
                onChange?.(newData)
            }
        },
        [data, onChange]
    )

    // const handleDragStart = useCallback((event: DragStartEvent) => {}, [])

    // const handDragMove = useCallback((ev: DragMoveEvent) => {}, [])

    return (
        <Container ref={ref}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragMove={handDragMove}
                onDragEnd={handleDragEnd}
                // onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <Content>
                    {/* <ItemTitle>
                        <ItemName>{type === 1 ? '标题' : '按钮'}</ItemName>
                        <ItemName>点击后</ItemName>
                    </ItemTitle> */}
                    <SortableContext disabled={disabled} items={data.map(d => d.id)} strategy={verticalListSortingStrategy}>
                        {data.map((item, index) => (
                            <SortableButton
                                disabled={disabled}
                                value={item}
                                key={item.id}
                                allPages={pageList ?? []}
                                // onFetchAllViews={handleFetchAllViews}
                                onChange={val => handleChange({ ...val, id: item.id })}
                                onDelete={handleDelete}
                            />
                        ))}
                    </SortableContext>
                </Content>
            </DndContext>
            <Footer onClick={() => !disabled && handleCreate()}>
                <CM.Icon type="Add" color="var(--color-main)" size={20} />
                <CM.Text color="var(--color-main)">添加</CM.Text>
            </Footer>
        </Container>
    )
})

export default NavBarButton
