import { Button, IconFont, Input } from '@byecode/ui'
import type { NumberOption } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useEffect } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { SettingOption } from './SettingOption'

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px 8px 16px;
    flex: 1;
    overflow-y: auto;
`

const SCxHeader = styled.div`
    height: 28px;
    line-height: 28px;
    font-size: var(--font-size-normal);
    font-weight: 600;
`

const SCxList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxAdd = styled(Button)`
    width: fit-content;
    border-radius: 100px;
    flex-shrink: 0;
`

interface SettingOptionsProps {
    value: NumberOption[]
    onChange?: (val: NumberOption[]) => void
}

export const SettingOptions: React.FC<SettingOptionsProps> = ({ value, onChange }) => {
    const methods = useForm({
        defaultValues: {
            numberOption: value
        }
    })

    const { control, setValue, getValues, watch } = methods

    const { fields, append, remove, insert } = useFieldArray({
        name: 'numberOption',
        control,
        keyName: 'id'
    })

    const handleDuplicate = useCallback(
        (i: number) => {
            const duplicatedData = getValues(`numberOption.${i}`)
            insert(i, duplicatedData)
        },
        [getValues, insert]
    )

    const handleAdd = useCallback(() => {
        append({
            id: nanoid(),
            label: '',
            value: [undefined, undefined]
        })
    }, [append])

    useEffect(() => {
        if (!onChange) {
            return
        }
        const { unsubscribe } = watch(v => {
            onChange(v.numberOption as NumberOption[])
        })
        return unsubscribe
    }, [onChange, watch])

    return (
        <FormProvider {...methods}>
            <SCxContainer>
                <SCxHeader>预设数值范围</SCxHeader>
                <SCxList>
                    {fields?.map((item, index) => (
                        <SettingOption
                            key={item.id}
                            prefixName={`numberOption.${index}`}
                            onDuplicate={() => handleDuplicate(index)}
                            onDelete={() => remove(index)}
                        />
                    ))}
                </SCxList>
                <SCxAdd type="default" onClick={handleAdd} icon={<IconFont type="Add" size={16} fill="var(--color-gray-400)" />}>
                    添加
                </SCxAdd>
            </SCxContainer>
        </FormProvider>
    )
}
