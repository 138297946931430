import { USER_DATASOURCE } from '@lighthouse/shared'
import React from 'react'
import { redirect } from 'react-router-dom'

import { DataSourcePreviewer } from '@/containers/DataSourcePreviewer'
import { Department } from '@/containers/UserCenter/Department'
import { User } from '@/containers/UserCenter/User'
import { UserGroup } from '@/containers/UserCenter/UserGroup'
import DataSource from '@/views/Byecode/Application/[id]/DataSource'

export const dataSourceRoute = {
    path: 'dataSource',
    element: <DataSource />,
    children: [
        {
            index: true,
            loader: () => redirect(USER_DATASOURCE)
        },
        {
            path: 'user',
            element: <User />
        },
        {
            path: 'department',
            element: <Department />
        },
        {
            path: 'userGroup',
            element: <UserGroup />
        },
        // {
        //     index: true,
        //     element: <DataSourceGraphDetail />
        // },
        // {
        //     path: 'er',
        //     element: <DataSourceGraphDetail />
        // },
        {
            path: ':dataSourceId',
            element: <DataSourcePreviewer />
        }
    ]
}
