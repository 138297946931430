import type { ColumnType } from '@byecode/ui'
import { Table } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

const data = [
    {
        id: 1,
        name: '是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服是客服',
        sex: '男',
        age: 25,
        level: 'z2'
    },
    {
        id: 2,
        name: 'asf地方',
        sex: '女',
        age: 78,
        level: 'z4'
    },
    {
        id: 3,
        name: '广东省刚刚',
        sex: '男',
        age: 24,
        level: 'z1'
    },
    {
        id: 4,
        name: '发广告官方',
        sex: '女',
        age: 43,
        level: 'z2'
    },
    {
        id: 5,
        name: '地方很多',
        sex: '男',
        age: 46,
        level: 'z2'
    },
    {
        id: 6,
        name: '邓',
        sex: '男',
        age: 35,
        level: 'z3'
    },
    {
        id: 7,
        name: 'v吧v北国风光',
        sex: '女',
        age: 46,
        level: 'z3'
    }
]

const columns: ColumnType<typeof data[number]>[] = [
    {
        title: '姓名',
        key: 'name'
        // width: 320
    },
    {
        title: '性别',
        key: 'sex'
    },
    {
        title: '年龄',
        key: 'age'
    },
    {
        title: '职级',
        key: 'level'
    }
]

export const Basic: React.FC = () => {
    return (
        <Unit title="基础">
            <Table data={data} columns={columns} rowKey="id" />
        </Unit>
    )
}
