import { Divider, Switch } from '@byecode/ui'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { addPrefixNameToFormName } from '../utils'

interface OperatorConfiguratorProps {
    prefixName?: string
}

export const OperatorConfigurator: React.FC<OperatorConfiguratorProps> = ({ prefixName }) => {
    const { control } = useFormContext()
    const canExportFormName = addPrefixNameToFormName('canExport', prefixName)
    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi label="操作">
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <Text>允许导出数据</Text>
                    <Controller
                        control={control}
                        name={canExportFormName}
                        render={({ field }) => <Switch checked={field.value} onChange={field.onChange} />}
                    />
                </ListItem4ByecodeUi>
            </Group4ByecodeUi>
        </>
    )
}
