import type { DataSourceAbstract } from '@lighthouse/core'
import type { AggregatorNode } from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { type Edge, ReactFlowProvider } from 'reactflow'

import { AggregatorEditor } from './AggregatorEditor'
import { AggregatorModal } from './AggregatorModal'

interface AggregatorDetailProps {
    dataSource: DataSourceAbstract
    onChange: (nodes: AggregatorNode[], edges: Edge[]) => void
}

export const AggregatorDetail: React.FC<AggregatorDetailProps> = ({ dataSource, onChange }) => {
    const { id } = dataSource
    const [selectedNode, setSelectedNode] = useState<AggregatorNode | null>(null)

    const [nodes, edges] = useMemo(() => {
        const {
            viewOptions: { draftAnalyticConfig }
        } = dataSource
        if (!draftAnalyticConfig) {
            return [[], []]
        }
        const { nodes, edges } = draftAnalyticConfig
        return [nodes, edges]
    }, [dataSource])

    const handleSelectNode = useCallback((node: AggregatorNode | null) => {
        setSelectedNode(node)
    }, [])

    const handleClose = useCallback(() => {
        setSelectedNode(null)
    }, [])

    const handleChange = useCallback(
        (nodes: AggregatorNode[], edges: Edge[], newNode: AggregatorNode) => {
            onChange(nodes, edges)
            setSelectedNode(newNode)
        },
        [onChange]
    )

    return (
        <ReactFlowProvider>
            <AggregatorEditor
                selectedNode={selectedNode}
                onNodeSelected={handleSelectNode}
                nodes={nodes}
                edges={edges}
                onUpdate={onChange}
            />
            {selectedNode && (
                <AggregatorModal open={!!selectedNode} dsId={id} node={clone(selectedNode)} onChange={handleChange} onClose={handleClose} />
            )}
        </ReactFlowProvider>
    )
}
