import type { DataSourceAbstract, Field, SelectedMode, TableColumns, TableColumnWidth } from '@lighthouse/core'
import { getCachedItem, setCachedItem } from '@lighthouse/tools'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { getViewColumns } from '../../utils'
import { TableField } from './TableField'
import TableFieldAdder from './TableFieldAdder'
import { TableHeaderChecker } from './TableHeaderChecker'
import type { CreateFieldParams, InsertDirection } from './types'

type CreateWay = 'create' | 'insert'
export interface TableHeaderProps {
    id: string
    appId: string
    tableProps: TableColumns
    dataSource: DataSourceAbstract
    primaryDataSourceFieldIds?: Set<string>
    dataSourceList: DataSourceAbstract[]
    headerFixed?: boolean
    disableFieldCreatable?: boolean
    disableFieldConfigurable?: boolean
    allRecordSelected?: boolean
    scrollRef: React.RefObject<HTMLDivElement>
    createFieldParams?: CreateFieldParams
    borderBottom?: boolean
    disableSelect?: boolean
    disableSelectMode?: boolean
    disableEditField?: boolean
    disableFindUse?: boolean
    onAllRecordSelect?: (selected: boolean) => void
    onSelectModeChange?: (val?: SelectedMode) => void
    onCreateField?: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onUpdateField?: (fieldId: string, data: Field) => Promise<boolean>
    onDeleteField?: (field: Field) => Promise<boolean>
    onFindUse?: (fieldId: string) => void
    onTableColumnWidthChange?: (val: TableColumnWidth) => void
}

const SCxTableHeader = styled.div<{ headerFixed: boolean; borderBottom?: boolean }>`
    position: ${({ headerFixed }) => (headerFixed ? 'sticky' : 'unset')};
    top: 0;
    z-index: 1;
    background-color: var(--color-gray-50);
    display: flex;
    width: max-content;
    min-width: 100%;
    border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid var(--color-gray-200)' : 'unset')};
`

const SCxTableFieldAdderContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    position: sticky;
    right: 0;
    z-index: 10;
`

const SCxTableChecker = styled(TableHeaderChecker)`
    background-color: var(--color-gray-50);
`

const SCxLine = styled.div`
    position: absolute;
    bottom: -1px;
    width: 50px;
    height: 1px;
    background-color: var(--color-gray-200);
`

export const TableHeader: React.FC<TableHeaderProps> = ({
    id: dsId,
    appId,
    tableProps,
    dataSource,
    primaryDataSourceFieldIds,
    dataSourceList,
    headerFixed = false,
    disableFieldCreatable,
    disableFieldConfigurable,
    allRecordSelected,
    scrollRef,
    createFieldParams,
    borderBottom = true,
    disableSelect,
    disableSelectMode,
    disableEditField,
    disableFindUse,
    onCreateField,
    onUpdateField,
    onDeleteField,
    onAllRecordSelect,
    onSelectModeChange,
    onFindUse,
    onTableColumnWidthChange
}) => {
    const createWayRef = useRef<CreateWay>('insert')
    const { schema } = dataSource
    const insertTablePropsRef = useRef<TableColumns>(tableProps)

    useUpdateEffect(() => {
        if (tableProps.length > insertTablePropsRef.current.length) {
            if (!scrollRef.current) {
                insertTablePropsRef.current = tableProps
                return
            }
            const sourceId = createFieldParams?.sourceId
            const direction = createFieldParams?.direction
            const { scrollLeft, clientWidth } = scrollRef.current
            const elementWidth = clientWidth - 110
            if (sourceId && direction) {
                const index = findIndex(col => col.id === sourceId, tableProps)
                const createFieldIndex = direction === 'left' ? index - 1 : index + 2
                const offsetLeft = tableProps.reduce((acc, cur, i) => {
                    if (i < createFieldIndex && cur.visible) {
                        acc += cur.width || 160
                    }
                    return acc
                }, 0)
                if (offsetLeft < scrollLeft || scrollLeft + elementWidth < offsetLeft) {
                    const shouldScrollLeft = direction === 'left' ? offsetLeft : offsetLeft - elementWidth
                    scrollRef.current.scrollTo({ left: shouldScrollLeft })
                }
                return
            }

            const { scrollWidth } = scrollRef.current
            scrollRef.current.scrollTo({ left: scrollWidth })
            insertTablePropsRef.current = tableProps
        }
    }, [tableProps.length])

    const columns = useMemo(() => getViewColumns({ tableProps, schema }), [schema, tableProps])

    // const [relationFieldNames, setRelationFieldNames] = useImmer<Record<string, string>>({})
    const handleResizerChange = useCallback(
        (id: string, columnWidth: number) => {
            onTableColumnWidthChange?.({ [id]: columnWidth })
        },
        [onTableColumnWidthChange]
    )

    const handleCreateField = useCallback(
        (data: Field, sourceId = '', direction: InsertDirection = '') => {
            if (!onCreateField) {
                return Promise.resolve(false)
            }
            createWayRef.current = sourceId ? 'insert' : 'create'
            return onCreateField?.(data, sourceId, direction)
        },
        [onCreateField]
    )

    const handleUpdateField = useCallback(
        (id: string, data: Field) => {
            if (!onUpdateField) {
                return Promise.resolve(false)
            }
            return onUpdateField?.(id, data)
        },
        [onUpdateField]
    )

    return (
        <SCxTableHeader headerFixed={headerFixed} borderBottom={borderBottom}>
            <SCxTableChecker checked={allRecordSelected} disableSelect={disableSelect} disableSelectMode={disableSelectMode} onChange={onAllRecordSelect} onSelectModeChange={onSelectModeChange} />
            {tableProps.map(({ id, visible, width }, index) => {
                const field = schema[id]
                return (
                    field &&
                    visible && (
                        <TableField
                            key={id}
                            width={width}
                            field={{ ...field, id }}
                            columns={columns}
                            dataSource={dataSource}
                            dataSourceList={dataSourceList}
                            primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                            disableEditField={disableEditField}
                            disableFindUse={disableFindUse}
                            onUpdateField={config => handleUpdateField(id, config)}
                            onResizerChange={handleResizerChange}
                            onDeleteField={onDeleteField && (() => onDeleteField(field))}
                            onCreateField={handleCreateField}
                            onFindUse={onFindUse}
                            configurable={!disableFieldConfigurable}
                        />
                    )
                )
            })}
            <SCxTableFieldAdderContainer>
                {!disableFieldCreatable && (
                    <>
                        <TableFieldAdder
                            dataSource={dataSource}
                            dataSourceList={dataSourceList}
                            columns={columns}
                            onSubmit={handleCreateField}
                        />
                        {!borderBottom && <SCxLine />}
                    </>
                )}
            </SCxTableFieldAdderContainer>
        </SCxTableHeader>
    )
}
