import styled, { css } from 'styled-components'

export const RecordItem = styled.div<{ checked: boolean }>`
    margin: 0;
    display: flex;
    width: 100%;
    @media only screen and (min-width: 0) and (max-width: 2040px) {
        width: max-content;
    }
    /* border: 1px solid transparent; */
    /* :hover {
        background-color: ${({ checked }) => (checked ? 'var(--color-main-tint)' : 'var(--color-gray-100)')};
    } */
    background-color: ${({ checked }) => checked && 'var(--color-main-tint)'};

    > * {
        ${({ checked }) =>
            checked &&
            css`
                background-color: var(--color-main-tint) !important;
            `};
    }
`
