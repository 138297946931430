import { Tooltip } from '@byecode/ui'
import type { DataSourceAbstract, Field, ViewFieldProtocol } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import type { DraftFunction } from 'use-immer'
import { useImmer } from 'use-immer'

import {
    DATASOURCE_CELL_DATA_KEY,
    FieldTypeColorMap,
    signFieldIconColor,
    signFieldId,
    SYSTEM_FIELD,
    SYSTEM_USER_FIELD
} from '../../../constants'
import { getFieldActiveColor, getIsAppointField, getIsJoinField, selectionState } from '../../../utils'
import { FieldIcon } from '../../FieldIcon'
import type { ResizeOffset } from '../../Resize'
import { Resize } from '../../Resize'
import { SCxMoreIcon, TableFieldSetting } from '../TableFieldSetting'
import type { FieldActionType, InsertDirection, ModeType } from '../types'

export * from '../TableFieldEditor/types'
export interface TableTableProps {
    field: Field
    dataSource: DataSourceAbstract
    columns: ViewFieldProtocol[]
    width?: number
    configurable?: boolean
    dataSourceList: DataSourceAbstract[]
    primaryDataSourceFieldIds?: Set<string>
    disableEditField?: boolean
    disableFindUse?: boolean
    onCreateField: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onUpdateField?: (data: Field) => Promise<boolean>
    onResizerChange?: (id: string, width: number) => void
    onDeleteField?: () => Promise<boolean>
    onFindUse?: (fieldId: string) => void
}

export interface TableFieldState {
    mode: ModeType
    action: FieldActionType
    fieldId: string
    sourceId: string
    direction: InsertDirection
    open: boolean
}

const SCxHeaderFieldItemWrapper = styled.div<{ color?: string; configurable?: boolean; width?: number; activeColor?: string }>`
    flex-shrink: 0;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : '160px')};
    border-right: 1px solid var(--color-gray-200);

    & .field-extra {
        color: ${({ color }) => color || 'var(--color-gray-400)'};
    }

    &.field-active {
        color: var(--color-white);
        background-color: ${({ activeColor }) => activeColor || 'var(--color-blue-500)'};
    }

    &.cell-active {
        background-color: var(--color-gray-200);
    }

    &.field-active .field-extra {
        color: var(--color-white);
    }

    &:hover ${SCxMoreIcon} {
        opacity: 1 !important;
    }

    ${props =>
        props.configurable &&
        css`
            &:not(.field-active):hover {
                background-color: var(--color-gray-100);
            }
        `}
`

const SCxDataSource = styled.div`
    line-height: 20px;
    white-space: nowrap;
    font-size: var(--font-size-sm);
`

const SCxField = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex: 1;
`

const SCxHeaderFieldItem = styled.div<{ activeColor?: string }>`
    display: flex;
    flex-direction: column;
    padding: 8px 34px 8px 8px;
    height: 100%;
    gap: 2px;
`

const SCxHeaderLabel = styled.p`
    width: 100%;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-normal);
    font-weight: 500;
`

const FieldResizerWrapper = styled.div`
    position: absolute;
    height: 100%;
    z-index: 1;
    right: 0;
    top: 0;
`
// const nonEditableFields = new Set<FieldType>(['id', 'createdBy', 'updatedBy', 'createdTime', 'updatedTime'])

export const TableField: React.FC<TableTableProps> = ({
    field,
    columns,
    dataSource,
    width = 160,
    configurable,
    dataSourceList,
    primaryDataSourceFieldIds,
    disableEditField,
    disableFindUse,
    onUpdateField,
    onCreateField,
    onResizerChange,
    onDeleteField,
    onFindUse
}) => {
    const { id: dsId, sync: dataSourceSync, type: dataSourceType, name: currentDsName, viewOptions } = dataSource
    const dataField = useMemo(() => `${dsId}&${field.id}`, [dsId, field.id])
    const disableField = useMemo(() => {
        if (disableEditField) {
            return disableEditField
        }
        // 聚合字段不能编辑
        if (field.type === 'aggregation') {
            return true
        }

        // 系统字段不能编辑
        if (getIsAppointField(field.id, SYSTEM_FIELD) && field.id !== 'ID') {
            return true
        }
        // 连接表自身的公式字段才能编辑
        if (dataSourceType === DataSourceType.joinDataSource) {
            // const [fieldDsId, fieldId] = field.id.split('__')
            // const fieldDsId = field.join?.joinDsId
            const fieldId = field.id
            if (!fieldId) {
                return false
            }
            const isJoinField = getIsJoinField(field)
            if (isJoinField) {
                return true
            }
        }
    }, [dataSourceType, disableEditField, field])

    const showTableFieldSetting = useMemo(
        () => dataSourceType !== DataSourceType.aggregateDataSource && !disableEditField,
        [dataSourceType, disableEditField]
    )

    const isFormula = useMemo(() => {
        if (field.type !== 'aggregation') {
            return false
        }
        return field.aggregation?.isFormula
    }, [field])

    const initWidthRef = useRef(width)
    const [state, setState] = useImmer<TableFieldState>({
        mode: 'config',
        action: 'update',
        fieldId: field.id,
        sourceId: '',
        direction: '',
        open: false
    })
    const popoverState = usePopupState({
        variant: 'popover',
        popupId: 'tableFieldEditorPopover'
    })

    useEffect(() => {
        const { cellCheckedId } = selectionState.state
        const cDom = document.querySelector(`[${DATASOURCE_CELL_DATA_KEY}='${cellCheckedId}']`) as HTMLDivElement
        if (!cellCheckedId || !cDom) {
            return
        }
        if (popoverState.isOpen) {
            cDom.setAttribute('data-disable', 'true')
            return
        }
        cDom.removeAttribute('data-disable')
    }, [popoverState.isOpen])

    const { sourceId, direction } = state

    const isUserField = useMemo(() => getIsAppointField(field.id, SYSTEM_USER_FIELD), [field.id])

    const isJoinPrimaryField = useMemo(() => {
        return primaryDataSourceFieldIds?.has(field.id)
    }, [field.id, primaryDataSourceFieldIds])

    const handleEditPopoverOpen = useCallback(() => {
        if (disableField) {
            return
        }
        setState({
            mode: 'edit',
            action: 'update',
            fieldId: field.id,
            sourceId: '',
            direction: '',
            open: true
        })
    }, [disableField, field.id, setState])

    const handleResize = useCallback(
        (offset: ResizeOffset, resizing: boolean) => {
            const { x } = offset
            const result = Math.max(initWidthRef.current + x, 100)
            onResizerChange?.(field.id, result)
            if (!resizing) {
                initWidthRef.current = result
            }
        },
        [field.id, onResizerChange]
    )

    const activeColor = useMemo(() => getFieldActiveColor({ field, dataSource, dataSourceList }), [dataSource, dataSourceList, field])

    const handleStateChange = useCallback(
        (state: TableFieldState | DraftFunction<TableFieldState>) => {
            setState(state)
        },
        [setState]
    )

    const handleCreateField = useCallback(
        (data: Field) => {
            onCreateField(data, sourceId, direction)
        },
        [direction, onCreateField, sourceId]
    )

    const DataSourceOrigin = useMemo(() => {
        if (dataSourceType !== DataSourceType.joinDataSource || !field.join) {
            return null
        }
        const { joinDsId } = field.join
        const originDataSource = find(ds => ds.id === joinDsId, dataSourceList)
        const dsName = originDataSource?.name
        return <SCxDataSource>{dsName}</SCxDataSource>
    }, [dataSourceList, dataSourceType, field.join])

    const iconColor = useMemo(() => {
        const isSignField = getIsAppointField(field.id, signFieldId)
        // return isSignField || isJoinPrimaryField || isUserField ? signFieldIconColor : FieldTypeColorMap[field.type]
        return isSignField || isUserField ? signFieldIconColor : FieldTypeColorMap[field.type]
    }, [field.id, field.type, isUserField])

    return (
        <>
            <SCxHeaderFieldItemWrapper
                activeColor={activeColor}
                color={iconColor}
                data-field={dataField}
                configurable={configurable}
                width={width}
            >
                <Tooltip title="双击编辑字段" withArrow placement="top" disabled={disableField} zIndex={210}>
                    <SCxHeaderFieldItem
                        // data-key={dataField}
                        onDoubleClick={handleEditPopoverOpen}
                        // onClick={handleSelectField}
                    >
                        {DataSourceOrigin}
                        <SCxField>
                            <FieldIcon
                                id={field.id}
                                type={field.type}
                                innerType={field.innerType}
                                isFormula={isFormula}
                                className="field-extra"
                            />
                            <SCxHeaderLabel>{field.name}</SCxHeaderLabel>
                        </SCxField>
                        <FieldResizerWrapper>
                            <Resize onChange={handleResize} />
                        </FieldResizerWrapper>
                    </SCxHeaderFieldItem>
                </Tooltip>
                {showTableFieldSetting && (
                    <TableFieldSetting
                        width={width}
                        columns={columns}
                        currentFieldId={field.id}
                        config={state}
                        absolute
                        dataSource={dataSource}
                        configurable={configurable}
                        dataSourceList={dataSourceList}
                        disableFindUse={disableFindUse}
                        onCreateField={handleCreateField}
                        onStateChange={handleStateChange}
                        onUpdateField={onUpdateField}
                        onDeleteField={onDeleteField}
                        onFindUse={onFindUse}
                    />
                )}
            </SCxHeaderFieldItemWrapper>
        </>
    )
}
