import { Select } from '@byecode/ui'
import React from 'react'

import { Unit } from '../Mics'

export const Sizes: React.FC = () => {
    return (
        <Unit title="尺寸">
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
                size="lg"
            />
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
                size="md"
            />
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
                size="sm"
            />
            <Select
                placeholder="请选择"
                options={[
                    { label: '男', value: 'man' },
                    { label: '女', value: 'woman' }
                ]}
                size="xs"
            />
        </Unit>
    )
}
