import { Popover } from '@byecode/ui/components/Popover'
import styled, { css } from 'styled-components'

export const StyledPopover = styled(Popover)``

export const Item = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 180px;
    max-width: 360px;
    padding-left: 13px;
    height: 37px;
    line-height: 37px;
`

export const Text = styled.span<{ icon?: string }>`
    color: var(--color-black);
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    ${props =>
        props.icon &&
        css`
            margin-left: 10px;
        `};
`
