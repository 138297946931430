import { Button, IconFont, pointer } from '@byecode/ui'
import { Text } from '@mantine/core'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

const Item = (): FlattenSimpleInterpolation => css`
    position: relative;
    height: 100%;
    border-radius: 16px;
    min-width: 128px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const ResolveCaseItem = (): FlattenSimpleInterpolation => css`
    width: 88px;
    height: 110px;
`

export const Container = styled.div`
    height: 100%;
    background: var(--color-gray-50);
    padding: 40px 36px 24px 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
`

export const Title = styled.div<{ size: number }>`
    min-width: fit-content;
    font-weight: 500;
    height: 28px;
    display: flex;
    align-items: center;
    font-size: ${({ size }) => `${size}px`};
    color: var(--color-gray-700);
`

export const CreateAppBtn = styled(Button)`
    min-width: 104px;
    height: auto;
    line-height: 36px;
    padding: 0 12px;
    margin-left: 12px;
    border-color: var(--color-main);
    border-radius: 5px;
`

export const Utils = styled.div`
    min-width: fit-content;
    height: 229px;
    padding: 16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    flex-shrink: 0;
    gap: 16px;
    background: var(--color-white);
    border-radius: 20px;
`

export const TemplateItem = styled.div`
    ${Item}
    ${pointer}
    flex: 1;
    background: linear-gradient(180deg, rgba(248, 243, 255, 0.47) 0%, rgba(237, 232, 255, 0.47) 23%, rgba(198, 197, 255, 0.47) 100%);
`

export const AiItem = styled.div`
    ${Item}
    ${pointer}
    flex: 1;
`

export const ResolvedItem = styled.div<{ width?: number }>`
    ${Item}

    ${pointer}

    ${({ width }) =>
        width
            ? css`
                  width: ${width}px;
              `
            : css`
                  flex: 1;
              `}
`

export const HelpItem = styled.div<{ width?: number }>`
    ${Item}

    ${pointer}

    background: linear-gradient(90deg, #F7F7FF 0.95%, #F5FFFC 98.33%);

    ${({ width }) =>
        width
            ? css`
                  width: ${width}px;
              `
            : css`
                  flex: 1;
              `}
`

export const UtilsHead = styled.div`
    position: relative;
    z-index: 1;
    height: 42px;
    padding: 14px 14px 0 14px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const UtilsTitle = styled.div`
    font-size: 20px;
    color: var(--color-gray-700);
    font-weight: 500;
    min-width: 30px;
`

export const Arrow = styled(IconFont)`
    font-size: 24px;
    color: var(--color-gray-700);
`

export const UtilsContent = styled.div`
    width: 100%;
    flex: 1;
    transition: all 0.5s ease-in-out;
`

export const Applications = styled.div`
    display: grid;
    grid-gap: 20px;

    /* grid-row-gap: 12px;
    grid-column-gap: auto;
    grid-template-columns: repeat(7, 28px); */
`

export const ApplicationCard = styled.div`
    position: relative;
    display: flex;
    height: 180px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    background: var(--color-white);

    ${pointer}

    &:hover {
        box-shadow: 0px 0px 6px 0px rgba(56, 56, 56, 0.12);
    }
`

export const ApplicationCardImage = styled.div`
    padding-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    align-self: stretch;
`

export const ApplicationCardLogo = styled.div<{ background?: string; radius?: string }>`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ radius }) => radius || '8px'};
    background: ${({ background }) => background || 'none'};
`

export const ApplicationCardTitle: typeof Text = styled(Text)`
    color: var(--color-gray-700);
    font-size: var(--font-size-normal);
    font-weight: 400;
    margin-top: 12px;
`
export const ApplicationCardTool = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    ${pointer}
`

export const ApplicationCardDescribeText: typeof Text = styled(Text)`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    margin-top: 8px;
`

export const TemplateDesktopWebImg = styled.img`
    width: 212px;
    height: 157px;
    position: absolute;
    left: 50%;
    top: 63px;
    margin-left: -106px;
    z-index: 3;

    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 54px;
    }
`

export const TemplateMobileWebImg = styled.img`
    width: 70px;
    height: 153px;
    position: absolute;
    top: 109px;
    left: 50%;
    margin-left: 74px;
    z-index: 2;

    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 99px;
    }
`

export const LeftBlankImg = styled.img`
    width: 62px;
    height: 105px;
    position: absolute;
    left: 50%;
    top: 140px;
    margin-left: -137px;
    z-index: 1;
    transform: rotate(-5.5deg);

    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 134px;
        transform: rotate(-16.5deg);
    }
`

export const RightBlankImg = styled.img`
    width: 50px;
    height: 105px;
    position: absolute;
    left: 50%;
    top: 138px;
    margin-left: 116px;
    z-index: 1;
    transform: rotate(5.86deg);
    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 127px;
        transform: rotate(16.5deg);
    }
`

export const CartImg = styled.img`
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 60px;
    margin-left: -128px;
    z-index: 4;
    transform: rotate(15deg);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 40px;
        margin-left: -169px;
        transform: rotate(-15deg);
        opacity: 1;
    }
`

export const ChartImg = styled.img`
    width: 32px;
    height: 32px;
    position: absolute;
    left: 50%;
    top: 37px;
    margin-left: 119px;
    z-index: 4;
    transform: rotate(-15deg);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 35px;
        margin-left: 125px;
        transform: rotate(15deg);
        opacity: 1;
    }
`

export const PaperPlaneImg = styled.img`
    width: 36px;
    height: 36px;
    position: absolute;
    z-index: 4;
    left: 50%;
    top: 122px;
    margin-left: 182px;
    transform: rotate(15deg);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 111px;
        margin-left: 181px;
        transform: rotate(23.89deg);
        opacity: 1;
    }
`

export const UsersImg = styled.img`
    width: 28px;
    height: 28px;
    position: absolute;
    left: 50%;
    top: 152px;
    margin-left: -182px;
    z-index: 4;
    transform: rotate(15deg);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    ${TemplateItem}:hover & {
        top: 120px;
        margin-left: -201px;
        transform: rotate(-15deg);
        opacity: 1;
    }
`

export const BuildImg = styled.img`
    position: absolute;
    width: 160px;
    left: 50%;
    top: 76px;
    margin-left: -80px;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    ${AiItem}:hover & {
        top: 73px;
        transform: scale(1.13);
    }
`

export const MouseImg = styled.img`
    width: 23px;
    position: absolute;
    top: 130px;
    left: 50%;
    margin-left: 34.5px;
    z-index: 2;
`

export const ResolveCaseImg = styled.img`
    position: absolute;
    right: 50px;
    top: 82px;
    transition: all 0.3s ease-in-out;
    z-index: 3;
    ${ResolveCaseItem}

    ${ResolvedItem}:hover & {
        right: 70px;
    }
`

export const ResolveCaseTinyImg = styled.img`
    position: absolute;
    top: 70px;
    right: 36px;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    ${ResolveCaseItem}

    ${ResolvedItem}:hover & {
        top: 71px;
        right: 46px;
    }
`

export const ResolveCaseBackgroundImg = styled.img`
    position: absolute;
    top: 58px;
    right: 23px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    ${ResolveCaseItem}
`

export const HelpImg = styled.img`
    width: 75px;
    position: absolute;
    right: -20px;
    bottom: 0;
    transform: rotate(14.323deg);
    transition: all 0.3s ease-in-out;
    opacity: 0.48;

    ${HelpItem}:hover & {
        transform: scale(1.3);
        transform: rotate(0deg);
        right: 20%;
        bottom: 40px;
        opacity: 1;
    }
`

export const CyanBackground = styled.div`
    width: 226px;
    height: 226px;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(10, 207, 131, 0.17) 0%, rgba(10, 207, 131, 0) 100%);
    opacity: 0.4;
`

export const PurpleBackground = styled.div`
    width: 282px;
    height: 282px;
    border-radius: 50%;
    opacity: 0.4;
    background: radial-gradient(46.01% 46.01% at 50% 50%, rgba(162, 89, 255, 0.4) 0%, rgba(162, 89, 255, 0) 100%);
`
