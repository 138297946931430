import type { Option } from '@byecode/ui'
import { Breadcrumbs, Flex, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { FilterFormType, ViewOptions } from '@lighthouse/core'
import { type DataSourceAbstract, type FilterBlockAbstract, type ViewField, type ViewFieldProtocol, VariableType } from '@lighthouse/core'
import { DEFAULT_FILTER_VALUE_VARIABLE, FieldItemAdder, FILTER_OPERATOR, ListItem4ByecodeUi, useAtomAction } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'

import { blockHighlightAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { InnerDrawer } from '../../InnerDrawer'
import { FilterControlItem } from './FilterControlItem'

const StyledText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    ${singleTextEllipsis()}
`

const SCxFilterControlContainer = styled.div`
    padding: 16px;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

interface FilterControlConfigureProps {
    linkFilter?: FilterFormType
    columns: ViewFieldProtocol[]
    filterBlocks?: FilterBlockAbstract[]
    schema: DataSourceAbstract['schema']
    onBack: () => void
}

export const FilterControlConfigure: React.FC<FilterControlConfigureProps> = ({ linkFilter, columns, filterBlocks, schema, onBack }) => {
    const { control, setValue } = useFormContext<ViewOptions>()
    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'linkFilterController.expression.conditions'
    })
    const disabledWithVersion = useIsDisabledWithVersion()
    const handleMouseEnter = useCallback(
        (id: string) => {
            setBlockHighlight([id])
            document.querySelector(`div[data-node-id="${id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        },
        [setBlockHighlight]
    )

    const handleMouseLeave = useCallback(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    const filterBlockOptions: Option[] = useMemo(() => {
        if (!filterBlocks) {
            return []
        }
        return filterBlocks?.map(item => ({
            label: item.title,
            value: item.id,
            icon: <IconFont type="Filter" size={16} color="var(--color-gray-400)" />,
            onMouseEnter: () => handleMouseEnter(item.id),
            onMouseLeave: () => handleMouseLeave()
        }))
    }, [filterBlocks, handleMouseEnter, handleMouseLeave])

    const limitColumns = useMemo(() => {
        return columns.filter(
            item =>
                item.innerType !== 'NULL' &&
                item.type !== 'notes' &&
                item.type !== 'file' &&
                item.type !== 'video'
        )
    }, [columns])

    const selectedFieldIds = useMemo(() => {
        return (
            fields.reduce<string[]>((prev, cur) => {
                const fieldId = cur?.idVariable?.fieldIdVariable?.fieldId
                if (fieldId) {
                    prev.push(fieldId)
                }
                return prev
            }, []) || []
        )
    }, [fields])

    const handleAdd = useCallback(
        (id: string) => {
            if (!linkFilter) {
                setValue('linkFilterController', {
                    expression: {
                        where: 'AND',
                        conditions: [
                            {
                                idVariable: {
                                    type: VariableType.FIELD_ID,
                                    fieldIdVariable: { fieldId: id }
                                },
                                operator: FILTER_OPERATOR,
                                paramList: [DEFAULT_FILTER_VALUE_VARIABLE]
                            }
                        ]
                    }
                })
                return
            }

            if (!linkFilter.expression?.where) {
                setValue('linkFilterController', {
                    expression: {
                        where: 'AND',
                        conditions: [
                            ...(linkFilter.expression?.conditions || []),
                            {
                                idVariable: {
                                    type: VariableType.FIELD_ID,
                                    fieldIdVariable: { fieldId: id }
                                },
                                operator: FILTER_OPERATOR,
                                paramList: [DEFAULT_FILTER_VALUE_VARIABLE]
                            }
                        ]
                    }
                })
                return
            }

            append({
                idVariable: {
                    type: VariableType.FIELD_ID,
                    fieldIdVariable: { fieldId: id }
                },
                operator: FILTER_OPERATOR,
                paramList: [DEFAULT_FILTER_VALUE_VARIABLE]
            })
        },
        [append, linkFilter, setValue]
    )

    const handleDelete = useCallback(
        (id: string) => {
            const index = findIndex(item => item.id === id, fields)
            remove(index)
        },
        [fields, remove]
    )

    return (
        <InnerDrawer style={{ display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: '联动筛选', onClick: onBack }]} />
            <Divider color="var(--color-gray-200)" />
            <SCxFilterControlContainer>
                {fields.map((condition, index) => {
                    const fieldId = condition.idVariable?.fieldIdVariable?.fieldId
                    return (
                        <FilterControlItem
                            key={fieldId}
                            id={condition.id}
                            fieldId={fieldId}
                            schema={schema}
                            disabled={disabledWithVersion}
                            filterBlockOptions={filterBlockOptions}
                            filterBlocks={filterBlocks}
                            onDelete={handleDelete}
                            prefixName={`linkFilterController.expression.conditions.${index}`}
                        />
                    )
                })}
                <FieldItemAdder
                    text="添加"
                    // noSettingFields={noSettingFields}
                    onAdd={handleAdd}
                    columns={limitColumns}
                    selectedFieldIds={selectedFieldIds}
                />
            </SCxFilterControlContainer>
        </InnerDrawer>
    )
}

interface FilterControlProps {
    schema: DataSourceAbstract['schema']
    columns: ViewField[]
    filterBlocks?: FilterBlockAbstract[]
    compact?: boolean
    enablePadding?: boolean
    enableHover?: boolean
    disableIcon?: boolean
    style?: React.CSSProperties
}

export const FilterControl: React.FC<FilterControlProps> = ({
    schema,
    columns,
    filterBlocks,
    compact,
    enablePadding,
    enableHover,
    disableIcon,
    style
}) => {
    const { control } = useFormContext()
    const linkFilter = useWatch({ control, name: 'linkFilterController' })
    const [open, toggle] = useToggle(false)
    const text = useMemo(() => {
        const length = linkFilter?.expression?.conditions?.length ?? 0
        if (length > 0) {
            return `${length} 条筛选项`
        }
        return '未设置'
    }, [linkFilter?.expression?.conditions?.length])

    return (
        <>
            <ListItem4ByecodeUi
                justifyContent="space-between"
                compact={compact}
                enablePadding={enablePadding}
                alignItems="center"
                enableHover={enableHover}
                onClick={() => toggle(true)}
                style={style}
            >
                <Flex style={{ overflow: 'hidden' }} alignItems="center">
                    {!disableIcon && (
                        <Flex style={{ color: 'var(--color-gray-400)', marginRight: 8 }}>
                            <IconFont size={16} type="Binding" />
                        </Flex>
                    )}
                    <StyledText>联动筛选</StyledText>
                </Flex>
                <Flex alignItems="center" style={{ overflow: 'hidden', cursor: 'pointer' }}>
                    <StyledText color="var(--color-gray-400)">{text}</StyledText>
                    <IconFont color="var(--color-gray-400)" size={16} type="ArrowRightSmall" />
                </Flex>
            </ListItem4ByecodeUi>

            {open && (
                <FilterControlConfigure
                    linkFilter={linkFilter}
                    columns={columns}
                    filterBlocks={filterBlocks}
                    schema={schema}
                    onBack={() => toggle(false)}
                />
            )}
        </>
    )
}
