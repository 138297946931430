import type { Environment, EnvironmentAbstract, HttpResp } from "@lighthouse/core"

import http from "@/http"

import { JsonContentType } from "./constant"
import type { EnvDiffsResult } from "./types"





/**
* 获取环境
* @param envId
* @returns
*/
export async function getEnv(envId: string): Promise<EnvironmentAbstract> {
    const res = await http.get<unknown, HttpResp<EnvironmentAbstract>>('api/v1/env/getEnv', {
        params: {
            envId
        }
    })
    return res.content
}

/**
 * 获取应用环境列表
 */
export async function getAppListEnv(appId: string): Promise<Environment[]> {
    const resp = await http.get<unknown, HttpResp<Environment[]>>('api/v1/env/listEnv', {
        params: {
            appId
        }
    })

    return resp.content
}



/**
 * 创建应用环境
 * @param Environment
 */
export async function createAppEnv(payload: Omit<Environment, 'envId'>): Promise<string> {
    const resp = await http.post<unknown, HttpResp<string>>('api/v1/env/createEnv', { ...payload }, { headers: JsonContentType })

    return resp.content
}

/**
 * 更新应用环境
 * @param Environment
 */
export async function updateAppEnv(payload: Environment): Promise<boolean> {
    const resp = await http.put<unknown, HttpResp<boolean>>('api/v1/env/updateEnv', { ...payload }, { headers: JsonContentType })

    return resp.success
}

/**
 * 删除应用环境
 * @param id
 * @returns
 */
export async function deleteAppEnv(envId: string): Promise<boolean> {
    const resp = await http.delete<unknown, HttpResp<boolean>>('api/v1/env/deleteEnv', {
        params: {
            envId
        }
    })

    return resp.success
}

/**
 * 切换应用环境
 */
export async function switchAppEnv(envId: string) {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/env/switchEnv ', { envId })
    return res.success
}


/**
 * 合并环境
 */
export async function mergeEnv(fromEnvId: string, toEnvId: string) {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/env/mergeEnv ', { fromEnvId, toEnvId })
    return res.success
}

/**
 * 获取环境差异比较
 */
export async function getEnvDiffs(fromEnvId: string, toEnvId: string): Promise<EnvDiffsResult> {
    const res = await http.get<unknown, HttpResp<EnvDiffsResult>>('api/v1/env/getEnvDiffs', {
        params: {
            fromEnvId,
            toEnvId
        }
    })
    return res.content
}


/**
 * @description 绑定其他应用的用户
 * @param {string} templateId
 * @return {*}  {Promise<string | undefined>}
 */
export async function bindAppUsers(envId: string, linkAppId: string, linkEnvId: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp>('api/v1/env/bindUsers', { params: { envId, linkAppId, linkEnvId } })
    return res.success
}

/**
 * @description 解绑其他应用的用户
 * @param {string} templateId
 * @return {*}  {Promise<string | undefined>}
 */
export async function unBindAppUsers(envId: string): Promise<boolean> {
    const res = await http.get<unknown, HttpResp>('api/v1/env/unbindUsers', {
        params: { envId }
    })
    return res.success
}
