import type { JoinSetting } from '@lighthouse/core'
import { TitleInContentModal } from '@lighthouse/shared'
import React from 'react'

import { JoinConfigureContent } from './JoinConfigureContent'
import type { MatchConditionProps } from './MatchCondition'

interface JoinConfigureModalProps extends Omit<MatchConditionProps, 'prefixName' | 'joinedDs'> {
    open: boolean
    title?: string
    value?: JoinSetting
    loading?: boolean
    onConfirm: (val: JoinSetting) => void
    onClose: () => void
}

export const JoinConfigureModal: React.FC<JoinConfigureModalProps> = ({
    open,
    title = '连接配置',
    useDsIds,
    value,
    loading,
    dataSourceList,
    primaryDsId,
    index,
    onConfirm,
    onClose
}) => {
    return (
        <TitleInContentModal
            closeOnClickOverlay
            styles={{
                desktop: {
                    modal: {
                        maxHeight: 'calc(100% - 96px)'
                    }
                }
            }}
            open={open}
            width={769}
            title={title}
            description="此操作将会创建一个以当前表为主表的「连接表」；如果您想连接多张表，请先创建然后连接配置中添加连接表"
            onClose={onClose}
        >
            <JoinConfigureContent
                index={index}
                useDsIds={useDsIds}
                value={value}
                loading={loading}
                dataSourceList={dataSourceList}
                primaryDsId={primaryDsId}
                onConfirm={onConfirm}
                onClose={onClose}
            />
        </TitleInContentModal>
    )
}
