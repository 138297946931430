import { Loading } from '@byecode/ui'
import { LoginType } from '@lighthouse/core'
import { ScanStatus } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMount, useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import * as srv from '@/services'

import * as SC from '../../styles'
import type { Mode } from '../../types'

interface WechatState {
    qrcodeUrl?: string
    mark?: string
}
interface WechatProps {
    onChangeState: (openId: string) => void
    onChangeMode: (mode: Mode) => void
}

export const Wechat: React.FC<WechatProps> = ({ onChangeMode, onChangeState }) => {
    const navigate = useNavigate()
    const [state, setState] = useImmer<WechatState>({})
    const { qrcodeUrl, mark } = state
    const [searchParams] = useSearchParams()

    const redirect = searchParams.get('redirect')
    // const templateId = useMemo(() => {
    //     const redirect = searchParams.get('redirect')
    //     return redirect?.includes('templateId') ? redirect.replace('templateId-', '') : ''
    // }, [searchParams])
    // 跳转验证码登录
    const handleChangeRegister = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onChangeMode('register')
        },
        [onChangeMode]
    )

    // 跳转账号登录
    const handleChangeLogin = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onChangeMode('login')
        },
        [onChangeMode]
    )

    // const handleChangeState = useCallback(() => {
    //     onChangeMode('bind')
    //     onChangeState('openId')
    // }, [onChangeMode, onChangeState])

    const handleWechatLogin = useCallback((id: string) => {
        return srv.login({
            authType: LoginType.WECHAT_OFFICIAL,
            mark: id
        })
    }, [])

    const getQrcodeUrl = useCallback(async () => {
        const res = await srv.getLoginQR()
        if (res) {
            setState(draft => {
                draft.mark = res.mark
                draft.qrcodeUrl = res.url
            })
        }
    }, [setState])

    const handleGetLoginStatus = useCallback(
        async (id: string) => {
            const status = await srv.getLoginPoll(id)
            switch (status) {
                case ScanStatus.QR_EXPIRED: {
                    getQrcodeUrl()
                    return
                }

                case ScanStatus.LOGIN: {
                    const isLogin = await handleWechatLogin(id)
                    if (isLogin) {
                        navigate(redirect || '/', { replace: true })
                    }
                    return
                }

                case ScanStatus.REGISTER: {
                    onChangeMode('bind')
                    onChangeState(id)
                    return
                }

                default: {
                    return false
                }
            }
        },
        [getQrcodeUrl, handleWechatLogin, navigate, onChangeMode, onChangeState, redirect]
    )

    useMount(() => {
        getQrcodeUrl()
    })

    useUpdateEffect(() => {
        let timer: NodeJS.Timer | undefined
        if (mark) {
            timer = setInterval(() => handleGetLoginStatus(mark), 3000)
        }
        return () => {
            if (timer) {
                return clearInterval(timer)
            }
        }
    }, [mark])

    return (
        <SC.FormContainer>
            {qrcodeUrl ? (
                <SC.QrCodeWrapper>
                    <SC.QRCode src={qrcodeUrl} alt="qrcode" />
                    <SC.QrCodeRefresh onClick={getQrcodeUrl}>
                        刷新 <SC.RefreshIcon fill="var(--color-gray-900)" type="ArrowCounterClockwise" />
                    </SC.QrCodeRefresh>
                </SC.QrCodeWrapper>
            ) : (
                <Loading size={163} />
            )}
            <SC.FooterBetween>
                <SC.AccountButton
                    icon={<SC.Icon type="DeviceMobileFilled" size={20} color="var(--color-main)" />}
                    block
                    onClick={handleChangeRegister}
                >
                    验证码登录/注册
                </SC.AccountButton>
                <SC.AccountButton icon={<SC.Icon type="Key" size={20} color="var(--color-blue-500)" />} block onClick={handleChangeLogin}>
                    账号密码登录
                </SC.AccountButton>
            </SC.FooterBetween>
        </SC.FormContainer>
    )
}
