import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal } from '@byecode/ui'
import React from 'react'

import type { WxRobotFormState, WxRobotMode } from '../type'
import WxRobot from '../WxRobot'

interface WxRobotModalProps extends BaseModalProps {
    mode: WxRobotMode
    id?: string
}

export const WxRobotModal: React.FunctionComponent<WxRobotModalProps> = ({ mode, id, ...rest }) => {
    return (
        <DesktopModal
            width={676}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <WxRobot mode={mode} id={id} onClose={rest.onClose} />
        </DesktopModal>
    )
}
