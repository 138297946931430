import { PageType } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { useCallback } from 'react'

import { lastPageOfStackAtom } from '@/atoms/page/state'

import { usePageDataSourceForVariableSelector } from './usePage'

export const usePageCurrentDsAndRecord = () => {
    const [pageId, stackId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => {
            return [s?.pageId || '', s?.stackId || '']
        }, [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const isRecordPage = curr.page?.type === PageType.document || curr.page?.type === PageType.edit

    return {
        pageId,
        stackId,
        curr,
        prev,
        isRecordPage
    }
}
