import { backgroundTransitionOnClick, Flex, Group as ByecodeGroup } from '@byecode/ui'
import { Group } from '@mantine/core'
import styled, { css } from 'styled-components'

export const ListItem4ByecodeUi = styled(Flex).withConfig<{
    enablePadding?: boolean
    enableHover?: boolean
    compact?: boolean
    disabled?: boolean
    highlighting?: boolean
}>({
    shouldForwardProp: p => !['enablePadding', 'enableHover', 'compact', 'disabled', 'highlighting'].includes(p)
})`
    min-height: ${p => (p.compact ?? true ? '40px' : '48px')};
    font-size: 14px;
    border-radius: 8px;
    padding: ${p => (p.enablePadding ? '4px 8px' : '4px 0')};

    ${({ highlighting }) =>
        highlighting &&
        css`
            border: 1px solid var(--color-main);
        `}

    ${({ enableHover }) =>
        enableHover &&
        css`
            cursor: pointer;
            &:hover {
                background-color: var(--color-gray-100);
            }
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
`

export const Group4ByecodeUi = styled(ByecodeGroup).withConfig<{ padding?: number }>({
    shouldForwardProp: p => !['padding'].includes(p)
})`
    .byecode-group-collapse {
        padding: 0 8px;
    }
`

export const ListItemPaddingByecodeUi = styled(Flex).withConfig<{ enablePadding?: boolean }>({
    shouldForwardProp: p => p !== 'enablePadding'
})`
    min-height: 40px;
    font-size: 14px;
    padding: ${p => {
        return p.enablePadding ? '4px 16px' : undefined
    }};
`

/**
 * 与Menu一致样式的列表项
 */
export const BasicListItem = styled(Group).withConfig<{ clickable?: boolean; disablePadding?: boolean }>({
    shouldForwardProp: p => !['clickable', 'disablePadding'].includes(p)
})`
    min-height: 36px;
    font-size: 14px;
    padding: ${p => !p.disablePadding && '12px 16px'};
    border-radius: 4px;
    ${p => p.clickable && backgroundTransitionOnClick()}
`

export const BasicConfigurationItem = styled(Group).withConfig<{ clickable?: boolean; disablePadding?: boolean }>({
    shouldForwardProp: p => !['clickable', 'disablePadding'].includes(p)
})`
    min-height: 24px;
    font-size: 14px;
    padding: ${p => !p.disablePadding && '4px 16px'};
    border-radius: 4px;
    justify-content: space-between;
    ${p => p.clickable && backgroundTransitionOnClick()}
`

export const ButtonBasicConfigurationItem = styled(Group).withConfig<{ clickable?: boolean; disablePadding?: boolean }>({
    shouldForwardProp: p => !['clickable', 'disablePadding'].includes(p)
})`
    min-height: 24px;
    font-size: 14px;
    padding: ${p => !p.disablePadding && '4px 12px'};
    border-radius: 4px;
    justify-content: space-between;
    ${p => p.clickable && backgroundTransitionOnClick()}
`

export const BasicHeader = styled(Flex).withConfig<{ margin?: boolean }>({ shouldForwardProp: p => p !== 'margin' })`
    font-weight: 600;
    height: 32px;
    margin: ${({ margin }) => (margin ? '8px 0' : '0')};
    line-height: 32px;
    color: var(--color-gray-900);
    font-size: var(--font-size-normal);
    font-weight: 400;
`
