import type { FlowNode } from '@lighthouse/shared'
import React from 'react'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useDataSourceUsers } from '@/shared/reusable'

import { SettingFieldValue } from '../SettingFieldValue'

interface SettingRecordContentProps {
    prefixName?: string
    dataSourceId: string
    allParentNodes: FlowNode[]
    fieldsName?: string
}

export const SettingRecordContent: React.FC<SettingRecordContentProps> = ({
    prefixName = 'config',
    fieldsName = 'fields',
    dataSourceId,
    allParentNodes
}) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: personOptions } = useDataSourceUsers()
    const dataSource = useDataSource(appId, envId, dataSourceId)

    if (!dataSource) {
        return null
    }

    return (
        <SettingFieldValue
            appId={appId}
            envId={envId}
            prefixName={prefixName}
            fieldsName={fieldsName}
            allParentNodes={allParentNodes}
            dataSource={dataSource}
            personOptions={personOptions}
        />
    )
}
