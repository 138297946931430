import type { QuotaProtocols } from '@lighthouse/core'
import { Flex } from '@mantine/core'
import React from 'react'
import styled from 'styled-components'

import { RoundAvatar } from '@/components/RoundAvatar'

interface SpaceQuotaSliderProps {
    data: QuotaProtocols & { label: React.ReactNode; icon: string | React.ReactNode; primaryColor?: string }
}

const SCxSlider = styled.div<{ width: number; bgColor?: string }>`
    position: relative;
    width: 100%;
    height: 5px;
    background-color: var(--color-gray-200);
    border-radius: 100px;
    overflow: hidden;
    ::before {
        position: absolute;
        content: '';
        inset: 0;
        height: 5px;
        width: ${({ width }) => width}%;
        min-width: ${({ width }) => (width > 0 ? '5px' : 'unset')};
        background-color: ${({ bgColor }) => bgColor ?? 'var(--color-gray-600)'};
        border-radius: 100px;
        z-index: 1;
    }
`

export const SCxItem = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0;
`

export const SpaceQuotaSlider: React.FunctionComponent<SpaceQuotaSliderProps> = ({ data }) => {
    return (
        <SCxItem>
            {/* {typeof data.icon === 'string' ? (
                <RoundAvatar
                    icon={data.icon}
                    radius="8px"
                    type="icon"
                    background={data.usage >= data.quota ? 'var(--color-yellow-50)' : 'var(--color-gray-100)'}
                    size={32}
                    iconColor={data.usage >= data.quota ? 'var(--color-yellow-400)' : 'var(--color-gray-400)'}
                    iconSize={20}
                    bordered={false}
                    style={{ cursor: 'auto' }}
                />
            ) : (
                data.icon
            )} */}
            <Flex direction="column" justify="space-between" gap={8} style={{ height: '100%', flex: 1 }}>
                {data.label}
                <SCxSlider
                    width={(data.usage * 100) / data.quota}
                    bgColor={data.usage >= data.quota ? 'var(--color-yellow-500)' : undefined}
                />
            </Flex>
        </SCxItem>
    )
}
