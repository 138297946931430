import type { Option } from '@byecode/ui'
import { Button, Select } from '@byecode/ui'
import { type JoinSetting, VariableType } from '@lighthouse/core'
import { getTableIcon, innerTypeJoinOperatorMap, ListItem4ByecodeUi } from '@lighthouse/shared'
import { find } from 'rambda'
import type { FormEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { JoinFields } from '../JoinFields'
import type { MatchConditionProps } from '../MatchCondition'
import { MatchContent } from '../MatchContent'

const SCxForm = styled.form`
    position: relative;
    outline: none;
`

const SCxContainer = styled.div`
    padding: 16px 32px;
    min-height: 440px;
`

const SCxHeader = styled.div``

const SCxMatchWrapper = styled.div`
    margin: 16px 0;
`

const SCxRequire = styled.div`
    margin-left: 4px;
    color: var(--color-red-500);
`

const SCxMatchHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SCxMatchContent = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-weight: 600;
    font-size: var(--font-size-normal);
`

const SCxMatchRule = styled.div`
    color: var(--color-gray-500);
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

const SCxMatchRuleSelect = styled(Select)`
    width: 63px;
    margin: 0 8px;
    border-radius: 100px;
`

const SCxFooter = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 12px 20px;
    border-top: 1px solid var(--color-gray-200);
    display: flex;
    background-color: var(--color-white);
    flex-direction: row-reverse;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const SCxSubmit = styled(Button)`
    margin-left: 10px;
    height: 36px;
    width: 80px;
`

const SCxCancel = styled(Button)`
    height: 36px;
    width: 80px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

const ruleOptions: Option[] = [
    {
        label: '所有',
        value: 'AND'
    },
    {
        label: '任一',
        value: 'OR'
    }
]

interface JoinConfigureContentProps extends Omit<MatchConditionProps, 'prefixName' | 'joinedDs'> {
    value?: JoinSetting
    loading?: boolean
    onConfirm: (val: JoinSetting) => void
    onClose: () => void
}

export const JoinConfigureContent: React.FC<JoinConfigureContentProps> = ({
    useDsIds,
    value,
    loading,
    dataSourceList,
    primaryDsId,
    index,
    onConfirm,
    onClose
}) => {
    const methods = useForm<JoinSetting>({
        mode: 'onSubmit',
        defaultValues: { ...value, joinWhere: 'AND', joinType: 'left join' }
    })
    const { control, handleSubmit } = methods

    const joinedDsId = useWatch({
        control,
        name: 'joinedDsId'
    })

    const joinConditions = useWatch({
        control,
        name: 'joinConditions'
    })

    const joinFields = useWatch({
        control,
        name: 'joinFields'
    })

    const useDsIdsExtraCurrentDsId = useMemo(() => useDsIds.flat().filter(item => item !== joinedDsId), [joinedDsId, useDsIds])

    const joinedDs = useMemo(() => find(item => item.id === joinedDsId, dataSourceList), [dataSourceList, joinedDsId])

    const disableConfirm = useMemo(() => {
        if (!joinedDs) {
            return true
        }
        if (!joinConditions || joinConditions.length === 0) {
            return true
        }

        if (!joinFields || joinFields.length === 0) {
            return true
        }
        let bool = false

        joinConditions?.forEach(condition => {
            const leftField = joinedDs.schema[condition.leftFieldId || '']
            if (!leftField) {
                bool = true
                return
            }

            const operatorOptions = innerTypeJoinOperatorMap[leftField.innerType]
            const option = find(item => item.value === condition.operator, operatorOptions)
            if (!option) {
                bool = true
                return
            }

            if (!['isEmpty', 'isNotEmpty'].includes(option.value)) {
                if (!condition.right) {
                    bool = true
                    return
                }
                if (condition.right.type === VariableType.VALUE) {
                    const value = condition.right.valueVariable?.value
                    if (
                        !value &&
                        value !== 0 && // 处理 0 的情况
                        typeof value !== 'boolean'
                    ) {
                        bool = true
                        return
                    }
                }

                if (condition.right.type === VariableType.VARIABLE) {
                    const rightDsId = condition.right?.fieldVariable?.dsId
                    const rightFieldId = condition.right?.fieldVariable?.fieldId

                    const rightDs = find(item => item.id === rightDsId, dataSourceList)
                    if (!rightDs) {
                        bool = true
                        return
                    }
                    const rightField = rightDs.schema[rightFieldId || '']
                    if (!rightField || !rightField.innerType) {
                        bool = true
                        return
                    }
                }
            }

            bool = false
        })

        return bool
    }, [dataSourceList, joinConditions, joinFields, joinedDs])

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(onConfirm)()
            onClose()
        },
        [handleSubmit, onClose, onConfirm]
    )
    const dataSourceOptions = useMemo(() => {
        return dataSourceList.reduce<Option[]>((prev, cur) => {
            if (cur.id === primaryDsId || cur.type === 5 || useDsIdsExtraCurrentDsId.includes(cur.id)) {
                return prev
            }
            prev.push({
                label: cur.name,
                value: cur.id,
                icon: getTableIcon(cur)
            })
            return prev
        }, [])
    }, [dataSourceList, primaryDsId, useDsIdsExtraCurrentDsId])

    return (
        <FormProvider {...methods}>
            <SCxForm onSubmit={handleFormSubmit}>
                <SCxContainer>
                    <SCxHeader>
                        <ListItem4ByecodeUi direction="column" gap="12px">
                            <SCxMatchContent>
                                选择想要连接的表<SCxRequire>*</SCxRequire>
                            </SCxMatchContent>
                            <Controller
                                control={control}
                                name="joinedDsId"
                                render={({ field }) => <Select options={dataSourceOptions} value={field.value} onChange={field.onChange} />}
                            />
                        </ListItem4ByecodeUi>
                    </SCxHeader>
                    {/* <Divider color="var(--color-gray-200)" style={{ margin: '16px 0' }} /> */}
                    {joinedDs && (
                        <>
                            <SCxMatchWrapper>
                                <SCxMatchHeader>
                                    <SCxMatchContent>
                                        关联条件<SCxRequire>*</SCxRequire>
                                    </SCxMatchContent>
                                    <SCxMatchRule>
                                        满足下方
                                        <Controller
                                            control={control}
                                            name="joinWhere"
                                            render={({ field }) => (
                                                <SCxMatchRuleSelect
                                                    styles={{
                                                        input: {
                                                            color: 'var(--color-gray-500)'
                                                        }
                                                    }}
                                                    dropdownWidth="target"
                                                    options={ruleOptions}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        条件
                                    </SCxMatchRule>
                                </SCxMatchHeader>
                                <MatchContent
                                    index={index}
                                    useDsIds={useDsIds}
                                    primaryDsId={primaryDsId}
                                    dataSourceList={dataSourceList}
                                    joinedDs={joinedDs}
                                />
                            </SCxMatchWrapper>
                            <SCxMatchWrapper>
                                <SCxMatchContent>
                                    连接字段<SCxRequire>*</SCxRequire>
                                </SCxMatchContent>
                                <Controller
                                    control={control}
                                    name="joinFields"
                                    render={({ field }) => <JoinFields joinedDs={joinedDs} value={field.value} onChange={field.onChange} />}
                                />
                            </SCxMatchWrapper>
                        </>
                    )}
                </SCxContainer>
                <SCxFooter>
                    <SCxSubmit htmlType="submit" type="primary" loading={loading} disabled={disableConfirm}>
                        确认
                    </SCxSubmit>
                    <SCxCancel onClick={onClose}>取消</SCxCancel>
                </SCxFooter>
            </SCxForm>
        </FormProvider>
    )
}
