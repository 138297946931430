import { Button, Flex, Select } from '@byecode/ui'
import type { Field, FieldBlockAbstract, FieldBlockWithDsId } from '@lighthouse/core'
import { type DataSourceAbstract, type SelectDataSourceVariable, VariableType } from '@lighthouse/core'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { getFieldOptions } from '../../../../utils'
import { FieldTypeTag } from '../../../FieldTypeTag'
import { SelectDataSource } from '../../../SelectDataSource'
import { TitleInContentModal } from '../../../TitleInContentModal'
import { getInputOption } from '../../help'
import type { VariableTree } from '../../types'
import { MatchContent } from './MatchContent'
import { SortRule } from './SortRule'

interface SelectDataSourceModalProps {
    opened: boolean
    variable?: SelectDataSourceVariable
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList?: DataSourceAbstract[]
    validateFieldType?: (field: Field) => boolean
    onClose: () => void
    onConfirm?: (v: SelectDataSourceVariable) => void
}

const SCxForm = styled.form`
    font-size: 14px;
`

const SCxContent = styled.div`
    padding: 8px 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
`
const SCxTitle = styled(Flex)`
    height: 40px;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    gap: 4px;
`

const SCxFooter = styled.div`
    width: 100%;
    padding: 12px 20px;
    border-top: 1px solid var(--color-gray-200);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const SCxSubmit = styled(Button)`
    margin-left: 10px;
    height: 36px;
    width: 80px;
`

const SCxCancel = styled(Button)`
    height: 36px;
    width: 80px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

const emptyValue: SelectDataSourceVariable = {
    type: VariableType.SELECT_DATASOURCE,
    selectDataSourceVariable: {
        dsId: '',
        fieldId: '',
        sorts: [],
        filter: {
            expression: {
                where: 'AND',
                conditions: []
            }
        }
    }
}

const SelectDataSourceModal: React.FunctionComponent<SelectDataSourceModalProps> = ({
    opened,
    variable = emptyValue,
    dataSourceList,
    fieldBlocksWithDsId,
    validateFieldType,
    onClose,
    onConfirm
}) => {
    const methods = useForm<SelectDataSourceVariable>({
        mode: 'onSubmit',
        defaultValues: variable
    })
    const { control, handleSubmit, setValue, watch } = methods

    const [dsId, fieldId] = watch(['selectDataSourceVariable.dsId', 'selectDataSourceVariable.fieldId'])

    const dataSource = dataSourceList?.find(ds => ds.id === dsId)

    const disabled = !dsId || !fieldId

    const saveFieldOption = useMemo(
        () =>
            getFieldOptions(dataSource, validateFieldType).map(item => ({
                ...item,
                extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
            })),
        [dataSource, validateFieldType]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            onConfirm && handleSubmit(onConfirm)()
            onClose()
        },
        [handleSubmit, onClose, onConfirm]
    )
    return (
        <TitleInContentModal
            width={840}
            open={opened}
            onClose={onClose}
            title="查询数据表"
            styles={{
                desktop: {
                    modal: {
                        maxHeight: '80%!important'
                    }
                }
            }}
        >
            <FormProvider {...methods}>
                <SCxForm onSubmit={handleFormSubmit}>
                    <SCxContent>
                        <Flex direction="column">
                            <SCxTitle>想要查询的表</SCxTitle>
                            <div style={{ height: 44, width: '100%' }}>
                                <Controller
                                    control={control}
                                    name="selectDataSourceVariable.dsId"
                                    render={({ field }) => (
                                        <SelectDataSource
                                            width="100%"
                                            dataSourceList={dataSourceList ?? []}
                                            value={field.value}
                                            onChange={v => {
                                                field.onChange(v)
                                                setValue('selectDataSourceVariable.filter.expression.conditions', [])
                                                setValue('selectDataSourceVariable.sorts', [])
                                                setValue('selectDataSourceVariable.fieldId', '')
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Flex>

                        {dataSource && (
                            <>
                                <MatchContent
                                    dataSource={dataSource}
                                    dataSourceList={dataSourceList ?? []}
                                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                                />
                                <SortRule dataSource={dataSource} />
                            </>
                        )}

                        <Flex direction="column">
                            <SCxTitle>赋值</SCxTitle>
                            <Flex alignItems="center" gap={8} style={{ height: 44 }}>
                                将
                                <Controller
                                    control={control}
                                    name="selectDataSourceVariable.fieldId"
                                    render={({ field }) => (
                                        <Select
                                            style={{ width: 180 }}
                                            options={saveFieldOption}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                的值写入到当前输入框
                            </Flex>
                        </Flex>
                    </SCxContent>
                    <SCxFooter>
                        <SCxSubmit htmlType="submit" type="primary" disabled={disabled}>
                            确认
                        </SCxSubmit>
                        <SCxCancel onClick={onClose}>取消</SCxCancel>
                    </SCxFooter>
                </SCxForm>
            </FormProvider>

            {/* <Text size={24} lineHeight='32px' > 查询数据表</Text> */}
        </TitleInContentModal>
    )
}

export default SelectDataSourceModal
