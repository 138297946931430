import { Flex, IconFont, Switch } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import React, { forwardRef } from 'react'

import { listCommonStyles } from './constants'
import { StyledIconWrapper } from './styles'

interface SwitchItemProps {
    label: string
    icon: string
    value?: boolean
    disabled?: boolean
    onChange?: (v: boolean) => void
}

export const SwitchItem = forwardRef<HTMLDivElement, SwitchItemProps>(({ label, icon, value, disabled, onChange, ...rest }, ref) => (
    <ListItem4ByecodeUi
        ref={ref}
        styles={listCommonStyles}
        justifyContent="space-between"
        compact={false}
        enablePadding
        enableHover
        alignItems="center"
        {...rest}
    >
        <Flex gap="12px" alignItems="center">
            <StyledIconWrapper active={value}>
                <IconFont type={icon} size={18} fill={value ? 'var(--color-main)' : 'var(--color-gray-500)'} />
            </StyledIconWrapper>
            <div>{label}</div>
        </Flex>
        <Switch disabled={disabled} checked={value} onChange={e => onChange?.(e.currentTarget.checked)} />
    </ListItem4ByecodeUi>
))
