import { Anchor,Button, Input, Modal, Toast } from '@byecode/ui'
import { ErrorMessage } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import { usePageURI } from '@/hooks/usePageURI'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { WeChatWebsiteAccountFormState, WeChatWebsiteAccountMode } from './type'

interface WeChatWebsiteAccountProps {
    id?: string
    mode: WeChatWebsiteAccountMode
    onClose?: () => void
}

const initData: WeChatWebsiteAccountFormState = {
    id: '',
    name: '',
    appId: '',
    appSecret: ''
}
const WeChatWebsiteAccount: React.FunctionComponent<WeChatWebsiteAccountProps> = ({ id, mode, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()

    const { shareUrl } = usePageURI()

    const [loading, setLoading] = useState(false)

    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform ? { ...otherPlatform?.config, name: otherPlatform.name, id, appSecret: PLACEHOLDER_PASSWORD } : initData
    }, [id, otherPlatforms])

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: account
    })

    const handleAdd = useCallback(
        async (params: WeChatWebsiteAccountFormState) => {
            const { name, appId, appSecret } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'WECHAT_WEBSITE',
                name,
                params: { appId, appSecret }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」。`,
            okStatus: 'error'
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WE_COM_ROBOT']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: WeChatWebsiteAccountFormState) => {
            const { name, id, appId, appSecret } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'WECHAT_WEBSITE',
                name,
                id,
                params: {
                    appId,
                    appSecret: appSecret === PLACEHOLDER_PASSWORD ? undefined : appSecret
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} color="#12B76A" type="WeChatLogo" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}微信网站应用</SC.Title>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Description>在微信 APP 内的网页内弹出微信授权框，用户授权之后可以获取当前用户的信息。</SC.Description>
                </SC.Item>
                <SC.Tip>
                    <SC.Icon color="var(--color-main)" type="WarningCircle" />
                    <SC.Text>如果您添加账号遇到问题，请查看帮助文档：</SC.Text>
                    <Anchor
                        target="_blank"
                        style={{ fontWeight: 600, color: 'var(--color-main)' }}
                        to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/cuyzsdw9295f1amb"
                    >
                        如何添加微信网站应用
                    </Anchor>
                </SC.Tip>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>
                        账号标题
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        AppID
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appId" errors={errors}>
                        <Input placeholder="请填入微信公众号的开发者AppId" {...register('appId', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        AppSecret
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appSecret" errors={errors}>
                        <Controller
                            name="appSecret"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入微信公众号的开发者密码"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>Callback URL</SC.Text>
                    <Input readOnly defaultValue={shareUrl} disabled placeholder="请输入你的业务回调链接" />
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default WeChatWebsiteAccount
