import { flex, pointer } from '@byecode/ui'
import styled from 'styled-components'

export const Container = styled.div`
    /* padding: 20px 12px; */
    font-size: var(--font-size-normal);
`
export const Content = styled.div`
    margin-top: 24px;
    > * {
        margin-top: 12px;
    }
`
export const Header = styled.div`
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
`
export const Footer = styled.div`
    margin-top: 21px;
    ${flex};
    ${pointer}
    align-items: center;
    color: var(--color-main);
    > * {
        margin-left: 8px;
    }
`
export const ItemName = styled.div`
    width: 104px;
    color: var(--color-gray-300);
`
export const ItemTitle = styled.div`
    padding-left: 24px;
    ${flex}
    >* {
        margin-left: 8px;
    }
`
