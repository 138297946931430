import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@byecode/ui/components/Flex'
import { Input } from '@byecode/ui/components/Input'
import { DesktopModal, Modal } from '@byecode/ui/components/Modal'
import type { ApplicationSettingSearchEngine } from '@lighthouse/core'
import { Anchor } from '@mantine/core'
import { isEmpty } from 'rambda'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import type { PageSubmissionSearchEngineEnum } from '@/services/types'
import { SearchEngineType } from '@/services/types'

import { searchEngineNameMap } from '../constants'

interface SearchEngineConfigProps {
    opened: boolean
    type: PageSubmissionSearchEngineEnum
    defaultData?: Pick<ApplicationSettingSearchEngine, 'verificationInfo' | 'secret'>
    onClose: () => void
    onChange: (v: Pick<ApplicationSettingSearchEngine, 'verificationInfo' | 'secret'>) => void
}

const SCxFooter = styled.div`
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    height: 72px;
    width: 100%;
    gap: 12px;
    align-items: center;
    background: var(--color-gray-50);
    border-radius: 0px 0px inherit inherit;
`
const SCxContainer = styled.div`
    width: 100%;
    font-size: var(--font-size-normal);
`

const SCxTitle = styled.div`
    padding: 0 40px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
    margin-bottom: 24px;
`

const SCxContent = styled.div`
    padding: 0 40px;
`

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const SCxText = styled.span<{ size?: number; color?: string; isOmit?: boolean }>`
    font-family: 'PingFang SC';
    color: ${({ color }) => color ?? 'var(--color-black)'};
    font-size: ${({ size }) => size ?? '14'}px;
    line-height: ${({ size }) => size ?? 14 + 2}px;
`

const initData = {
    verificationInfo: '',
    secret: ''
}

export const SearchEngineConfig: React.FunctionComponent<SearchEngineConfigProps> = ({
    defaultData = initData,
    opened,
    type,
    onChange,
    onClose
}) => {
    const [isNext, setIsNext] = useState(false)
    const [data, setData] = useImmer(defaultData)

    useEffect(() => {
        if (opened && !defaultData.secret) {
            setIsNext(false)
            setData(initData)
        }
    }, [defaultData.secret, opened, setData])

    const contentEle = useMemo(() => {
        if (isNext) {
            return (
                <>
                    <SCxContent>
                        <SCxItem>
                            <Flex justifyContent="space-between" alignItems="center">
                                <SCxText>
                                    {searchEngineNameMap.get(type)}搜索秘钥
                                    <SCxText color="red" style={{ paddingLeft: 4 }}>
                                        *
                                    </SCxText>
                                </SCxText>

                                {/* <Anchor href="" color="var(--app-main)">
                                    如何获取百度搜索秘钥？
                                </Anchor> */}
                            </Flex>

                            <Input
                                value={data.secret}
                                size="lg"
                                name="secret"
                                onChange={e =>
                                    setData(draft => {
                                        draft.secret = e.target.value
                                    })
                                }
                            />
                        </SCxItem>
                    </SCxContent>
                    <SCxFooter>
                        <Button style={{ height: 40, width: 104 }} onClick={() => setIsNext(false)}>
                            上一步
                        </Button>
                        <Button
                            style={{ height: 40, width: 104 }}
                            type="primary"
                            disabled={isEmpty(data.secret)}
                            onClick={() => onChange(data)}
                        >
                            确定
                        </Button>
                    </SCxFooter>
                </>
            )
        }
        return (
            <>
                <SCxContent>
                    <SCxItem>
                        <Flex justifyContent="space-between" alignItems="center">
                            <SCxText>
                                网站验证信息
                                <SCxText color="red" style={{ paddingLeft: 4 }}>
                                    *
                                </SCxText>
                            </SCxText>

                            {/* <Anchor href="" color="var(--app-main)">
                                如何获取 HTML 标签验证信息？
                            </Anchor> */}
                        </Flex>
                        <Input
                            value={data.verificationInfo}
                            size="lg"
                            name="verificationInfo"
                            onChange={e =>
                                setData(draft => {
                                    draft.verificationInfo = e.target.value
                                })
                            }
                        />
                    </SCxItem>
                </SCxContent>
                <SCxFooter>
                    <Button
                        type="primary"
                        style={{ height: 40, width: 104 }}
                        disabled={isEmpty(data.verificationInfo)}
                        onClick={() => setIsNext(true)}
                    >
                        下一步
                    </Button>
                </SCxFooter>
            </>
        )
    }, [data, isNext, onChange, setData, type])
    //
    return (
        <DesktopModal
            open={opened}
            onClose={onClose}
            width={600}
            styles={{
                body: {
                    padding: '0px!important'
                },
                header: {
                    borderStyle: 'none!important'
                }
            }}
        >
            <SCxContainer>
                <SCxTitle>配置搜索引擎</SCxTitle>
                {contentEle}
            </SCxContainer>
        </DesktopModal>
    )
}
