import { IconFont } from '@byecode/ui'
import type { ValueVariable } from '@lighthouse/core'
import { getColorById, useApplicationContext } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const SCxIcon = styled(IconFont)`
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;
`

const SCxAvatar = styled(IconFont)`
    margin-right: 4px;
`

const SCxNode = styled.div<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);
    display: flex;
    height: 32px;
    line-height: 32px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    padding-right: 4px;
    margin: 0 3px;
    color: ${({ color }) => color || 'var(--color-black)'};
`

const PersonName = styled.span`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

const SCxPersonAvatar = styled.img`
    display: flex;
    width: 22px;
    height: 22px;
    min-width: 22px;
    margin-right: 4px;
    display: block;
`

const SCxPersonTextAvatar = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

interface PersonTagProps {
    data: ValueVariable
    withClose?: boolean
    onClose?: () => void
}

export const PersonTag: React.FC<PersonTagProps> = ({ data, withClose = true, onClose }) => {
    const { personOptions } = useApplicationContext()
    const valueUserId = data.valueVariable?.value && Array.isArray(data.valueVariable?.value) ? data.valueVariable?.value?.[0] : ''
    const person = find(item => item.userId === valueUserId, personOptions || [])

    const avatarContent = useMemo(() => {
        if (valueUserId === '{currentUserId}') {
            return <SCxAvatar size={22} type="UserCircle" fill="var(--color-gray-400)" />
        }
        if (!person) {
            return null
        }
        const { avatar, username, uniqueUserId } = person

        const background = getColorById(uniqueUserId)
        if (avatar) {
            return <SCxPersonAvatar src={avatar} />
        }
        return <SCxPersonTextAvatar background={background}>{username.charAt(0)}</SCxPersonTextAvatar>
    }, [person, valueUserId])

    const username = useMemo(() => {
        if (valueUserId === '{currentUserId}') {
            return '当前用户'
        }
        return person?.username
    }, [person?.username, valueUserId])

    return (
        <SCxNode>
            {avatarContent}
            <PersonName>{username}</PersonName>
            {withClose && <SCxIcon type="Close" onClick={onClose} />}
        </SCxNode>
    )
}
