import { IconFont, Popover } from '@byecode/ui'
import { AggregatorNodeType } from '@lighthouse/shared'
import cls from 'classnames'
import React, { useCallback, useState } from 'react'
import type { NodeProps } from 'reactflow'
import { Position } from 'reactflow'
import styled from 'styled-components'

import { useDataAggregator } from '@/hooks/useDataAggregator'

import { Handler } from '../Handler'

export type JoinNodeProps = NodeProps<{ something: string }>

const SCxNodeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-gray-200);
    border-radius: 100px;
    background-color: var(--color-white);
`

const SCxNodeContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--color-gray-200);
    border-radius: 100px;
    background-color: var(--color-white);

    &.active {
        border-color: transparent;
        box-shadow: 0 0 0 2px var(--color-purple-500), 0px 0px 0px 4px #5551ff33;
    }
`

const SCxDropdownContentWrapper = styled.div`
    padding: 12px 16px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px #1018281a;
`

const SCxDropdownTitle = styled.div`
    margin-bottom: 12px;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxDropdownContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`
const SCxDropdownItem = styled.div`
    display: flex;
    align-items: center;
    width: 156px;
    height: 44px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    font-size: var(--font-size-sm);
    border: 1px solid var(--color-gray-200);
    cursor: pointer;

    &:hover {
        background-color: #ededff;
    }
`

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-400);

    ${SCxDropdownItem}:hover & {
        opacity: 1 !important;
        color: var(--color-purple-500);
    }
`

const SCxLabel = styled.div`
    margin-left: 8px;
    flex: 1;
`

const nodes = [
    { value: AggregatorNodeType.JOIN, label: '横向连接', icon: 'RightConnection' },
    { value: AggregatorNodeType.FIELD_SETTING, label: '字段设置', icon: 'PropertyNote' },
    { value: AggregatorNodeType.FILTER, label: '数据筛选', icon: 'Filter' },
    { value: AggregatorNodeType.GROUP_STATISTICS, label: '分组统计', icon: 'Charts' }
]

export const AddPlaceholderNode: React.FC<JoinNodeProps> = ({ id }) => {
    const [open, setOpen] = useState(false)
    const methods = useDataAggregator()

    const handleAdd = useCallback(
        (type: AggregatorNodeType) => {
            setOpen(false)
            methods?.onAddNode(type)
        },
        [methods]
    )

    return (
        <SCxNodeWrapper>
            <Popover withinPortal opened={open} width={364} position="bottom-start" onChange={setOpen}>
                <Popover.Target>
                    <SCxNodeContent className={cls({ active: open })}>
                        <IconFont type="Add" size={16} color="var(--color-purple-500)" />
                        <Handler type="target" position={Position.Left} id={id} />
                    </SCxNodeContent>
                </Popover.Target>
                <Popover.Dropdown unstyled>
                    <SCxDropdownContentWrapper>
                        <SCxDropdownTitle>添加操作节点</SCxDropdownTitle>
                        <SCxDropdownContent>
                            {nodes.map(({ value, label, icon }) => (
                                <SCxDropdownItem key={value} onClick={() => handleAdd(value)}>
                                    <SCxIcon type={icon} size={20} />
                                    <SCxLabel>{label}</SCxLabel>
                                    <SCxIcon type="Add" size={16} style={{ opacity: 0 }} />
                                </SCxDropdownItem>
                            ))}
                        </SCxDropdownContent>
                    </SCxDropdownContentWrapper>
                </Popover.Dropdown>
            </Popover>
        </SCxNodeWrapper>
    )
}
